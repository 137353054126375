<template>
    <div>
        <PageHeader v-if="with_header" :title="title" :items="items" />
        <div v-if="!isLoading">
            <div class="row">
                <div class="col-12">

                    <div class="grid-list-button d-flex align-items-center" style="float: right;">
                        <div
                            v-for="(layout, key) in supportedLayouts"
                            :key="key"
                            @click="changeCustomersLayout(layout);"
                            :id="'grid-list-'+layout.name.toLowerCase()"
                            :class="layout.class + (customersSelectedLayout === layout.value ? ' active' : '')">
                        </div>
                    </div>

                    <div class="filter-wrap customers-sort-options d-flex align-items-center" style="float: right;">
                        <b-dropdown class="option" variant="outline-secondary">
                            <template slot="button-content">
                                Sort: <b>{{ sorting.sortType.text }}</b>
                                <i class="mdi mdi-chevron-down"></i>
                            </template>
                            <b-dropdown-item-button
                                v-for="option in sorting.sortOptions"
                                :key="option.value"
                                @click="saveSorting(option, $event)">
                                {{ option.text | titleize }}
                            </b-dropdown-item-button>
                        </b-dropdown>

                    </div>

                </div>
            </div>
            <div v-if="customersSelectedLayout === 0" class="row customer-list">
                <div v-for="customer in sortedCustomersList" class="col-lg-6 col-xl-3">
                    <!-- Simple card -->

                    <div
                        @click="!customer.is_current ? switchTo(customer.id, $event) : null"
                        class="card selected_card"
                        :class="customer.is_current ? 'active' :''">
                        <img :src="customer.logo + '/inside/270/90'" :alt="customer.name" class="card-img-top">
                        <div class="card-body">
                            <h6>{{ customer.name }}</h6>
                            <div class="list-of-details">
                                <div class="header-detail">Assets</div>
                                <div class="header-detail">Qty</div>
                                <div class="list-detail">{{ customer.assets_count }}</div>
                                <div class="list-detail">{{ customer.quantity }}</div>
                                <div class="header-detail">Users</div>
                                <div class="header-detail">Cost</div>
                                <div class="list-detail">{{ customer.users_count }}</div>
                                <div class="list-detail">£{{ customer.total_value }}</div>
                            </div>
                            <div class="row" v-if="false">
                                <div class="col-lg-12 asset-sustainability-progress">
                                    <h6>Asset Sustainability Progress</h6>
                                    <div class="list-of-details">
                                        <div v-for="(sustainability_column, key) in sustainability_columns"
                                             :key="key"
                                             class="list-item-wrapper">
                                            <div class="list-header">{{ sustainability_column.label }}</div>
                                            <div class="list-details" v-html="sustainability_percentage(customer, sustainability_column.key)"></div>
                                        </div>

                                        <div class="list-header accounting-total grand-total">Total</div>
                                        <div class="list-details accounting-total grand-total">{{ getSusTotalFor(customer).toFixed(2) }}%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-button v-if="customer.is_current === 0" href="javascript:void(0);" v-on:click="switchTo(customer.id, $event)" variant="primary">Switch</b-button>
                                <b-button v-else href="javascript:void(0);" disabled variant="primary">Current {{item_title | titleize}}</b-button>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- end col -->
            </div>
            <div v-else-if="false && customersSelectedLayout === 2" class="row customer-list">
                <div v-for="customer in sortedCustomersList" class="col-lg-6 col-xl-3">
                    <!-- Simple card -->

                    <div
                        @click="!customer.is_current ? switchTo(customer.id, $event) : null"
                        class="card selected_card"
                        :class="customer.is_current ? 'active' :''">
                        <img :src="customer.logo" :alt="customer.name" class="card-img-top">
                        <div class="card-body">
                            <div class="list-of-details">
                                <div class="header-detail">Assets</div>
                                <div class="header-detail">Qty</div>
                                <div class="list-detail">{{ customer.assets_count }}</div>
                                <div class="list-detail">{{ customer.quantity }}</div>
                                <div class="header-detail">Users</div>
                                <div class="header-detail">Cost</div>
                                <div class="list-detail">{{ customer.users_count }}</div>
                                <div class="list-detail">£{{ customer.total_value }}</div>
                            </div>
                            <div class="row" v-if="false">
                                <div class="col-lg-12 asset-sustainability-progress">
                                    <h6>Asset Sustainability Progress</h6>
                                    <af-radar-chart
                                        class="sustainability-graph"
                                        :custom_options="getOptionsFor(customer)">
                                    </af-radar-chart>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-button v-if="customer.is_current === 0" href="javascript:void(0);" v-on:click="switchTo(customer.id, $event)" variant="primary">Switch</b-button>
                                <b-button v-else href="javascript:void(0);" disabled variant="primary">Current {{item_title | titleize}}</b-button>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- end col -->
            </div>
            <div v-else class="row">
                <div class="col-12">

                    <po-lines :items="sortedCustomersList"
                              :current_page_type="item_type"
                              :object_type="'customers'"
                              table_class="switch-customer-table"
                              :get-poline-classes-dict="getPoLineClassForObject"
                              line_type="customer"
                              :get-on-click-row="onClickRow"
                              :keys_list="customers_list_keys_list">
                        <template v-slot:customers_actions="{ item }">
                            <b-button size="sm" v-if="item.is_current === 0" href="javascript:void(0);" v-on:click="switchTo(item.id, $event)" variant="primary">Switch</b-button>
                            <b-button size="sm" v-else href="javascript:void(0);" disabled variant="primary">Current {{item_title | titleize}}</b-button>
                        </template>

                    </po-lines>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching {{ item_title | titleize }}s...</h4>
        </div>
    </div>
</template>
<script>
import {MY_BASE_URL} from "@/utils/shared_constants";

import Resource from '@/api/resource';
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import {CustomersSortOptions} from "@/views/assetflow/form_data/CustomersSortOptions";
import AfRadarChart from "../components/charts/AfRadarChart";
import PageHeader from "../components/page-header";

const assetResource = new Resource('api/assetflow/assets');
export default {
    name: 'SwitchEntity',
    props: {
        item_type: {},
        with_header: false
    },
    components: {
        PageHeader,
        AfRadarChart,
        PoLines,
    },
    data() {
        return {
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: CustomersSortOptions,
            },
            sustainability_percentage: function (item, key) {
                if (key === 'cost'){
                    key = 'total_value';
                }
                return item.hasOwnProperty('sustainability_progress') ? item.sustainability_progress[key].toFixed(2)+'%' : '0%';
            },
            sustainability_value: function (item, key) {
                if (key === 'cost'){
                    key = 'total_value';
                }
                return item.hasOwnProperty('sustainability_progress') ? Math.round(item.sustainability_progress[key] * 100) / 100 : 0;
            },
            customers_list_keys_list:{
                initials: {
                    key: 'initials',
                    label: "",
                    class: "col-lg-1 initials",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let style = '';
                        if (item.hasOwnProperty('meta') && item.meta && item.meta.primary_colour){
                            let bg_colour = item.meta.accent_colour;
                            let text_colour = item.meta.primary_colour;

                            style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
                        }
                        return '<div class="text-dark avatar-sm"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item[key]+'</span></div>';
                    }
                },
                name: {
                    key: 'name',
                    label: "Customer Name",
                    class: "col-lg-2 name",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return Vue.options.filters.titleize(item.name);
                    }
                },
// Users, Assets, Qty, Cost, Sustainability
                users_count: {
                    key: 'users_count',
                    label: "Users",
                    class: "col-lg-1 text-center users_count",
                    show_on_mobile: true,
                    case: ''
                },
                assets_count: {
                    key: 'assets_count',
                    label: "Assets",
                    class: "col-lg-1 text-center assets_count",
                    show_on_mobile: false,
                    case: ''
                },
                quantity: {
                    key: 'quantity',
                    label: "Quantity",
                    class: "col-lg-1 text-center customers_quantity",
                    show_on_mobile: false,
                    case: ''
                },
                total_value: {
                    key: 'total_value',
                    label: "Cost",
                    class: "col-lg-1 text-center total_value",
                    show_on_mobile: false,
                    case: 'custom',
                    used_func: function (value) {
                        return '£' + value;
                    }
                },
                // sustainability_total:{
                //     key: 'sustainability_total',
                //     label: "Sustainability",
                //     class: "col-lg-3 text-center sustainability_total",
                //     show_on_mobile: false,
                //     case: 'custom_html',
                //     value_func: function (item, key) {}
                // },
                actions: {
                    key: 'actions',
                    label: "",
                    class: "col-lg-2 customers_actions text-center",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'customers_actions'
                },
            },
            customersSelectedLayout: 0,
            supportedLayouts:{
                grid: {
                    name: 'Grid',
                    value: 0,
                    class: 'grid',
                    isActive: true
                },
                list: {
                    name: 'List',
                    value: 1,
                    class: 'list',
                    isActive: false
                },
                // graph: {
                //     name: 'Graph',
                //     value: 2,
                //     class: 'font-size-24 graph-icon bx bx-radar',
                //     isActive: false
                // }
            },
            item_title: "Customer",
            base_url: MY_BASE_URL,
            title: "Switch " + this.item_title,
            items: [
                {
                    text: "Customers List",
                    href: "/"
                },
                {
                    text: "Switch " + this.item_title,
                    active: true
                }
            ],
            value: null,
            customers: [],
            isLoading: false,
            isSendingRequest: false,
            api_method: '',
            sustainability_columns: {
                final_disposition_planning:{
                    key: 'final_disposition_planning',
                    label: 'Planning'
                },
                acquisition_type:{
                    key: 'acquisition_type',
                    label: 'Acquisition',
                },
                location_id:{
                    key: 'location_id',
                    label: 'Assignments',
                },
                volume:{
                    key: 'volume',
                    label: 'Physical',
                },
                cost:{
                    key: 'total_value',
                    label: 'Cost',
                }
            }
        };
    },
    created() {
        this.setupPage();
    },
    computed: {
        sortedCustomersList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    // watch:{
    //     item_type:{
    //         deep: true,
    //         handler: function (newValue) {
    //             this.setupPage();
    //         }
    //     },
    // },
    methods: {
        onClickRow(item, line_type){
            this.switchTo(item.id, true);
        },
        getPoLineClassForObject(item, line_type){
            return {
                'is-current-entity': item.hasOwnProperty('is_current') && item.is_current,
                'is-other-entity': item.hasOwnProperty('is_current') && item.is_current,
                'entity-row': true,
                'table_inner_body': true,
                'd-flex-p': true
            };
        },
        setupPage(){
            this.customers = [];
            this.item_title = this.item_type === 'sp' ? 'Service Provider' : this.item_type;
            let current_customer = window.USER_INFO.current_customer;
            if (this.item_type === 'customer' && current_customer){
                let sector_ids = current_customer.sector_ids;
                if (sector_ids && sector_ids.includes("1")){
                    this.item_title = 'Production';
                }
            }

            if (this.item_type === 'character'){
                this.item_title = 'Character';
                this.customers_list_keys_list.name.label = 'Character Name';
                delete this.customers_list_keys_list.assets_count;
                delete this.customers_list_keys_list.users_count;
                delete this.customers_list_keys_list.quantity;
                delete this.customers_list_keys_list.total_value;
            }

            let titleized_item_title = Vue.options.filters.titleize(this.item_title);
            this.title = "Switch " + titleized_item_title;
            this.items[0].text = titleized_item_title + 's List';
            this.items[1].text = "Switch " + titleized_item_title;


            // let getSusTotalFor = this.getSusTotalFor;
            // this.customers_list_keys_list.sustainability_total.value_func = function (item, key) {
            //     return getSusTotalFor(item).toFixed(2)+'%';
            // };

            this.asyncFind();

            if (window.USER_INFO.user_meta.hasOwnProperty('preferred_customers_layout')) {
                this.customersSelectedLayout = window.USER_INFO.user_meta.preferred_customers_layout;
            }

            if (window.USER_INFO.user_meta.preferred_customers_sorting) {
                this.sorting.sortType = window.USER_INFO.user_meta.preferred_customers_sorting;
            }
            if (this.item_type === 'character'){
                this.customersSelectedLayout = 1;
                delete this.supportedLayouts.grid;
                delete this.supportedLayouts.list;
            }
        },
        getSectionColoursUsing(colour = '#72ACD1'){
            let sections_colours = [];
            for (const key in [0,1,2,3,4]) {
                sections_colours.push(this.convertHex(colour, (parseInt(key) + 1) * 20));
            }
            return sections_colours;
        },
        convertHex (color, opacity) {
            color = color.replace('#', '')
            const r = parseInt(color.substring(0, 2), 16)
            const g = parseInt(color.substring(2, 4), 16)
            const b = parseInt(color.substring(4, 6), 16)
            return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
        },
        getOptionsFor(customer){
            let data_values = [];
            for (let key in this.sustainability_columns) {
                if (key === 'cost'){
                    key = 'total_value';
                }

                data_values.push(this.sustainability_value(customer, key));
            }

            return {
                titles_colour: "#fff",
                titles_bgcolour: "#999",
                titles: [
                    {text: 'Planning', max: 100},
                    {text: 'Acquisition', max: 100},
                    {text: 'Assignments', max: 100},
                    {text: 'Physical', max: 100},
                    {text: 'Cost', max: 100}
                ],
                sections_colours: this.getSectionColoursUsing(customer.meta.accent_colour),
                lines_colour: 'yellow',
                area_colour: customer.meta.primary_colour,
                data_values: data_values
            };
        },
        sortItemsList(){
            return this.customers.sort(this.sortFunction(this.sorting.sortType, this.statusesList));
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('preferred_customers_sorting', sortType, message);
            this.refreshContent();
            // TODO: should change sorting using >> sortItemsList
            // this.sortItemsList();
        },
        getSusTotalFor(item){
            let sustainability_percentage_total = 0
            for (let key in this.sustainability_columns) {
                if (key === 'cost'){
                    key = 'total_value';
                }
                let value = 0;
                if (item.hasOwnProperty('sustainability_progress')){
                    value = item.sustainability_progress[key];
                }

                let count = Object.keys(this.sustainability_columns).length;
                sustainability_percentage_total += (1 / count) * value;
            }
            return sustainability_percentage_total;
        },
        changeCustomersLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_customers_layout', layout.value, message);

            this.customersSelectedLayout = layout.value
        },
        buttonClicked(){
        },
        asyncFind () {
            this.api_method = "user_" + this.item_type + "s";

            if (this.item_type === 'character'){
                this.api_method = 'characters/listCharacters';
            }
            this.isLoading = true;
            axios({
                method:'get',
                url:'/api/assetflow/' + this.api_method,
                baseURL: '/'
            }).then(response => {
                this.customers = response.data.data.items;
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
        switchTo(new_id, event) {
            if (event && !this.isSendingRequest) {
                // let element = event.target;
                if (new_id){
                    this.isSendingRequest = true;

                    if (this.item_type === 'character'){
                        this.set_usermeta('selected_character_id', new_id, "", true);
                    }else{
                        axios({
                            method:'post',
                            url:'/api/assetflow/switch-'+this.item_type,
                            baseURL: '/',
                            data: {
                                item_id: new_id,
                                item_type: this.item_type
                            },
                        }).then(response => {
                            this.isSendingRequest = false;
                            if (response.data.data.status === true){
                                location.reload();
                            }else{
                                console.log('Something went wrong');
                            }

                        }).catch(error => {
                            console.log(error);
                            this.isSendingRequest = false;
                        });
                    }


                }else{
                    console.log("shall show error?");
                }
            }
        }
    },
}
</script>


<style scoped>
.selected_card img{
    object-fit: contain;
    height: 50px;
    padding: 10px 10px 0px 10px;
}
.selected_card .card-body{
    text-align: center;
}
.sustainability-graph{
    height: 300px !important;
}
</style>
