<template>
    <Layout>

        <PageHeader :title="title" :items="items" />

        <div class="product-page-header dashboard-filters-list">
            <div class="customers-list">
                <div class="main-root-top">
                    <h3 class="filter_option">{{ selected_sp.name | titleize }}</h3>
                    <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                        <li v-for="(sp_item, index) in sps_list" :class="'root-top-menu' + (selected_sp && selected_sp.id === sp_item.id ? ' active' : '')">
                            <a @click="setSelectedSP(sp_item)">
                                {{ sp_item ? sp_item.name : '-' }}
                            </a>
                        </li>
                    </ul>
                </div>

                <span class="arrow-right-icon"></span>
                <div class="main-root-top">
                    <h3 class="filter_option">{{ selected_status.name | titleize }}</h3>
                    <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                        <li v-for="(status, key) in sp_statusesList"
                            :key="key"
                            class="root-top-menu">
                            <a @click="setSelectedStatus(status)">{{ status.name | titleize }}</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div class="cm_dashboard_page_block">
                <div class="cm_goodmoring_tristian_block">
                    <div class="cm_common_asset_box">
                        <div class="common_sub_col_bg">
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Currently Viewing</span>
                                        <p v-if="selected_sp && selected_sp.id !== null">
                                            <i class="bx bxs-cart"></i> Assets {{ assetsStatus | titleize }}at {{ selected_sp.name | titleize }}
                                        </p>
                                        <p v-else>
                                            <i class="bx bxs-cart"></i> All Service Providers
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Total Assets & Groups</span>
                                        <p><i class="bx bx-minus"></i> {{ totals_list.assets_count }} | {{ totals_list.asset_groups_count }}</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Orders Awaiting Confirmation</span>
                                        <p><i class="bx bxs-dashboard"></i> {{ totals_list.order_awaiting_confirmation_count }} Orders</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Storage Footprint</span>
                                        <p><i class="bx bxs-dashboard"></i> {{ totals_list.storage_footprint }} sq ft</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Search & Find</h3>
                        </div>

                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <!-- // Search -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Asset Keyword Search</h4>
                                        </div>
                                        <div class="cm_stock_intake">
                                            <div class="common--input-filed">
                                                <input placeholder="Enter Text" v-model="search_keyword" type="text" >
                                            </div>

                                            <div class="cm_search_clear_btn">
                                                <button @click="redirectToAssets()" type="button">search</button>
                                                <button @click="search_keyword = ''" type="button">clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- // Categories -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> View by Category</h4>
                                        </div>
                                        <div class="cm_customers_layout_view">
                                            <ul v-if="categories_list && categories_list.length > 0">
                                                <li v-for="(category, key) in categories_list"
                                                :key="key"
                                                v-if="category.hasOwnProperty('parent_category') && category.parent_category">
                                                    <div class="cm_customers_col_1">
                                                        <p>{{ category.parent_category.name }}</p>
                                                    </div>
                                                    <div class="cm_customers_col_2">
                                                        <p>{{ category.assets_count }}</p>
                                                    </div>
                                                    <div class="cm_customers_col_2">
                                                        <p>{{ category.total_quantity }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="cm_view_open_order_btn">
                                            <a :href="'/' + this.item_type + '/assets?queue=category'"><i class="bx bx-plus-circle"></i> View Categories</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- // Tags -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Popular Tags</h4>
                                        </div>
                                        <div class="cm_customers_layout_view">
                                            <ul v-if="tags_list && tags_list.length > 0">
                                                <li v-for="(tag, key) in tags_list"
                                                    :key="key">
                                                    <div class="cm_customers_col_1">
                                                        <p>{{ tag.name }}</p>
                                                    </div>
                                                    <div class="cm_customers_col_2">
                                                        <p>{{ tag.quantity_total }}</p>
                                                    </div>
                                                    <div class="cm_customers_col_2">
                                                        <p>{{ tag.quantity_available }}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="cm_view_open_order_btn">
                                            <a href="#0"><i class="bx bx-plus-circle"></i> View Tags</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- // Storage Orders -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Storage Orders</h4>
                                        </div>
                                        <div class="cm_ordering_table_view cm_order_overview table-responsive">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i> - Recyling</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">5</a> </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i> - Delivery</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">5</a> </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i> - Collection</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">5</a> </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i> - Sell</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">5</a> </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="cm_view_open_order_btn">
                                            <a href="#0"><i class="bx bx-plus-circle"></i> View All</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Assets & Groups</h3>
                        </div>
                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <!-- Latest Asset -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> (i) Latest (Storage Status Badge)</h4>
                                        </div>
                                        <div class="cm_common_confirm_content">
                                            <div class="cm_confirm_order_img">
                                                <img src="https://i.postimg.cc/mDJr4FTc/Group-265-1.png" alt="image">
                                            </div>
                                        </div>
                                        <div class="cm_common_confirm_detail">
                                            <h3>(i) Asset \ Group | Status</h3>
                                            <p>8 | 6 | 2</p>
                                            <p>Added By Tristian Roberts on 1/10/2021	</p>
                                        </div>

                                    </div>
                                </div>

                                <!-- Recently Added List -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> (i) Recently Added (Storage Status Badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view cm_order_overview table-responsive">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Asset \ Group</th>
                                                    <th>Quantities</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i>(i) Asset {{ item }} | Size</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">8 | 6 | 2</a> </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="cm_view_open_order_btn">
                                            <a href="#0"><i class="bx bx-plus-circle"></i> View All</a>
                                        </div>
                                    </div>
                                </div>


                                <!-- Recently Dispatched List -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> (i) Recently Dispatched (Storage Status Badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view cm_order_overview table-responsive">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Asset \ Group</th>
                                                    <th>Quantities</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i>(i) Asset {{ item }} | Size</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">8 | 6 | 2</a> </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="cm_view_open_order_btn">
                                            <a href="#0"><i class="bx bx-plus-circle"></i> View All</a>
                                        </div>
                                    </div>
                                </div>


                                <!-- Assets by Footprint List -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> (i) Assets by Footprint (Storage Status Badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view cm_order_overview table-responsive">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Asset \ Group</th>
                                                    <th>Quantities</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i>(i) Asset {{ item }} | Size</p>
                                                    </td>
                                                    <td><a href="#0" class="text-dark">8 | 6 | 2</a> </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="cm_view_open_order_btn">
                                            <a href="#0"><i class="bx bx-plus-circle"></i> View All</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Managed Storage Orders</h3>
                        </div>
                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <!-- Open -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Open (status badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive">
                                            <table>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>SP</td>
                                                    <td>Name\ID</td>
                                                    <td>Order Type</td>
                                                    <td>Total Assets</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Confirmed -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Confirmed (status badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive">
                                            <table>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>SP</td>
                                                    <td>Name\ID</td>
                                                    <td>Order Type</td>
                                                    <td>Total Assets</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- In Progress -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> In Progress (status badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive">
                                            <table>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>SP</td>
                                                    <td>Name\ID</td>
                                                    <td>Order Type</td>
                                                    <td>Total Assets</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Completed -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Completed (status badge)</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive">
                                            <table>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>SP</td>
                                                    <td>Name\ID</td>
                                                    <td>Order Type</td>
                                                    <td>Total Assets</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Managed Storage Providers</h3>
                        </div>

                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <div class="col-md-12 col-xl-12">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Managed Storage Providers</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Service Provider</th>
                                                    <th>Contracts</th>
                                                    <th>Assets</th>
                                                    <th>Groups</th>
                                                    <th>Contact</th>
                                                    <th>Phone</th>
                                                    <th>Website</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in [1, 2, 3, 4]" :key="item">
                                                    <td>Service Provider</td>
                                                    <td>Contracts</td>
                                                    <td>Assets</td>
                                                    <td>Groups</td>
                                                    <td>Contact</td>
                                                    <td>Phone</td>
                                                    <td>Website</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

    </Layout>
</template>


<script>

import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

import Resource from '@/api/resource';
const assetResource = new Resource('api/assetflow/assets');

export default {
    name: "Dashboard",
    components: {Layout, PageHeader },
    computed:{
        assetsStatus(){
            if (this.selected_status && this.selected_status.id !== null ){
                return this.selected_status.name + ' ';
            }
            return '';
        },
    },
    data() {
        return {
            title: "Dashboard",
            items: [
                {
                    text: "Customers",
                    href: "./customers"
                },
                {
                    text: "Dashboard",
                    active: true
                }
            ],
            listLoadingSPs: false,
            selected_sp: {
                id: null,
                name: 'All'
            },
            sps_list: [],
            selected_status: {
                id: null,
                name: 'Any'
            },
            sp_statusesList: [
                {
                    id: null,
                    name: 'Any',
                    value: 'any'
                },
                {
                    id: 2,
                    name: 'In Storage',
                    value: 'in_storage'
                },
                {
                    id: 1,
                    name: 'Intake In Progress',
                    value: 'intake_in_progress'
                },
                {
                    id: 0,
                    name: 'Dispatched',
                    value: 'dispatched'
                },
            ],
            categories_list: [],
            tags_list: [],
            search_keyword: '',
            totals_list: {
                assets_count: 10,
                asset_groups_count: 10,
                order_awaiting_confirmation_count: 10,
                storage_footprint: 110,
            },
            listQuery: {}
        };
    },
    props:['item_title', 'item_type', 'item_id', 'item_info_string'],
    created() {
        this.getSPSList();
        this.getListOf('categories');

        this.tags_list = [
            {
                id: 0,
                name: 'Tag 0',
                quantity_total: 10,
                quantity_available: 10
            },
            {
                id: 1,
                name: 'Tag 1',
                quantity_total: 10,
                quantity_available: 10
            },
            {
                id: 2,
                name: 'Tag 2',
                quantity_total: 10,
                quantity_available: 10
            },
        ];

    },
    methods: {
        redirectToAssets(){
            window.location = encodeURI('/' + this.item_type + '/assets?s=' + this.search_keyword);
        },
        handleQueryItems(query) {
            if (this.item_type === 'bss'){
                query.bss_status = true;
            }else{
                if (this.item_type === 'sp'){
                    query.warehouse_id = this.item_id !== '' ? this.item_id : "0";
                }
                if (this.item_type === 'customer') {
                    query.customer_id = this.item_id !== '' ? this.item_id : "0";
                }

                if (this.is_group){
                    if (this.selected_customer && this.selected_customer.id){
                        query.customer_id = this.selected_customer.id;
                    }
                    if (this.selected_status && this.selected_status.id && this.current_groupBy !== 2){
                        query.status_name = this.selected_status.name;
                    }
                    query.item_type = this.item_type;
                    query.filter_option = this.current_groupBy;
                }
            }

            return query;
        },
        async getListOf(item_type) {
            let requiredResource = null;
            this.listQuery = this.handleQueryItems({});
            switch (item_type){
                case 'categories':
                    this.listQuery.group_by = 'category_id';
                    requiredResource = assetResource;
                    break;
            }

            if (requiredResource){
                const {data} = await requiredResource.list(this.listQuery);
                let required_items = data.data.items;
                if (required_items.length > 0){
                    switch (item_type){
                        case 'categories':
                            this.categories_list = required_items;
                            break;
                    }
                }
            }

        },
        getSPSList: function () {
            this.listLoadingSPs = true;
            let listQuery = {
                item_type: this.item_type,
                customer_id: this.item_id
            }

            axios({
                method:'get',
                url:'/api/assetflow/service_providers',
                baseURL: '/',
                params: listQuery
            }).then(response => {
                let items_list = response.data.data.items;
                if (items_list){
                    this.sps_list = items_list.map(function (e) {
                        return {id: e.id, name: e.name};
                    });
                    let all_item = {
                        id: null,
                        name: 'All'
                    };
                    this.sps_list.splice(0, 0, all_item);
                }

            }).finally(() => {
                this.listLoadingSPs = false;
            });
        },

        setSelectedSP(sp_item){
            this.selected_sp = sp_item;
            // this.getList(this.selected_item);
        },
        setSelectedStatus(status_item){
            this.selected_status = status_item;
            // this.getList(this.selected_item);
        },
    }
};
</script>
