const FirstPath = window.location.pathname.split('/')[1];
export const SPStorageWrapperKeysList = {
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg storage-wrapper-name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a href="/' + FirstPath + '/sp_storage_wrappers/' + item.id + '/view" class="text-dark"><i class="'+item.icon+'"></i> ' + item.name + '</a>';
        }
    },
    type: {
        key: 'type',
        label: "Type",
        class: "col-lg-2 storage-wrapper-type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.type === 1 ? 'Virtual' : 'Physical';
        }
    },
    default_usage: {
        key: 'default_usage',
        label: "Default Usage",
        class: "col-lg-2 storage-wrapper-default_usage",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let storage_utilisation = item.default_storage_utilisation;
            return (storage_utilisation === 100 ? 'Full' : (storage_utilisation === 0 ? 'Empty' : storage_utilisation));
        }
    },
    naming: {
        key: 'naming',
        label: "Naming",
        class: "col-lg-2 storage-wrapper-naming",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] === 1 ? 'Auto ID' : 'User Defined';
        }
    },
    single_item: {
        key: 'single_item',
        label: "Single Item",
        class: "col-lg-1 storage-wrapper-single_item",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },
    occurrences: {
        key: 'occurrences',
        label: "Occurrences",
        class: "col-lg-1 storage-wrapper-occurrences",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('occurrences_info') && item.occurrences_info){
                return item.occurrences_info;
            }
            return 0;
        }
    }
};
