<template>
    <div class="cm_list_view_icon">

        <div class="icon-group-assets" v-if="(current_module && current_module.id !== 3) && (selected_view === undefined || [0, 2].includes(selected_view.id))">

            <a :href="'/' + item_type + '/assets/' + my_asset.id + '/view?t=asset_storage'" class="asset-storage-icon" v-if="checkIfAssetIsLocked(my_asset)">
                <i class="bx bx-lock icon-active" title="Is Locked"><div></div></i>
            </a>
            <a href="#" class="asset-storage-icon" v-else>
                <i class="bx bx-lock icon-inactive" title="Is Not Locked"><div></div></i>
            </a>

            <a :href="'/' + item_type + '/assets/' + my_asset.id + '/view?t=asset_storage'" class="asset-storage-icon" v-if="my_asset.storage_status === 1">
                <i class="bx bx-cuboid icon-active" title="In Storage"><div></div></i>
            </a>
            <a href="#" class="asset-storage-icon" v-else>
                <i class="bx bx-cuboid icon-inactive" title="Not In Storage"><div></div></i>
            </a>

            <a v-if="my_asset.hasOwnProperty('latest_open_orderline_order') && my_asset.latest_open_orderline_order"
               :href="'/' + item_type + '/orders/' + my_asset.latest_open_orderline_order + '/view'" class="asset-order-icon">
                <i class="bx bx-shopping-bag icon-active" title="Has Order"><div></div></i>
            </a>
            <a href="#" class="asset-order-icon" v-else>
                <i class="bx bx-shopping-bag icon-inactive" title="No Order"><div></div></i>
            </a>

        </div>
        <div class="icon-group-usage" v-if="selected_view === undefined || selected_view.id === 1">
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-usage-icon" v-if="my_asset.asset_usage === 1">
                <i class="bx bx-movie icon-active" title="On Camera"><div></div></i>
            </a>
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-usage-icon" v-else-if="my_asset.asset_usage === 0">
                <i class="bx bx-movie icon-inactive" title="Off Camera"><div></div></i>
            </a>
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-usage-icon" v-else>
                <i class="bx bx-movie icon-neutral" title="Asset Usage: Unknown"><div></div></i>
            </a>

            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-hero-icon" v-if="my_asset.is_hero === 1">
                <i class="bx bx-cool icon-active" title="Hero Item"><div></div></i>
            </a>
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-hero-icon" v-else-if="my_asset.is_hero === 0">
                <i class="bx bx-cool icon-inactive" title="Not Hero Item"><div></div></i>
            </a>
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-hero-icon" v-else>
                <i class="bx bx-cool icon-neutral" title="Is Hero: Unknown"><div></div></i>
            </a>

            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-is-asset-icon" v-if="my_asset.is_asset === 1">
                <i class="bx bx-money icon-active" title="Asset Item"><div></div></i>
            </a>
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-is-asset-icon" v-else-if="my_asset.is_asset === 0">
                <i class="bx bx-money icon-inactive" title="Not Asset Item"><div></div></i>
            </a>
            <a href="#" @click="performAction(my_asset, 'show_usage_action', $event)" class="asset-is-asset-icon" v-else>
                <i class="bx bx-money icon-neutral" title="Is Asset: Unknown"><div></div></i>
            </a>
        </div>
    </div>
</template>
<script>


export default {
    name: 'AssetIconList',
    components: {},
    mixins:[],
    props: ['my_asset', 'item_type', 'performAction', 'selected_view'],
    data() {
        return {
        }
    },
    created() {},
    methods:{}
}
</script>
