export const ItemLayoutOptions = [
    {
        value: 0,
        text: "Table"
    },
    {
        value: 1,
        text: "Grid"
    },
    {
        value: 2,
        text: "CSV"
    },
    {
        value: 3,
        text: "PDF"
    },
    {
        value: 4,
        text: "Print"
    },
];
