<template>
    <Layout>

        <EditAssetFields
            :item_id="item_id"
            :item_type="item_type"
            :action="action"
            :other_info_string="other_info_string"/>

    </Layout>
</template>
<script>
import Layout from "@/layouts/main";
import EditAssetFields from "./EditAssetFields";
import BulkPhotoUpload from "./BulkPhotoUpload";

export default {
    name: "EditAsset",
    props: ['item_id', 'item_type', 'action', 'other_info_string'],
    components: {BulkPhotoUpload, EditAssetFields, Layout},
    created() {
    }
}
</script>
