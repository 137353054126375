<template>
    <div :class="used_class ? used_class : 'st-radio-row'">
        <div class="locked-asset-alert alert text-center" :class="alert_class ? alert_class : 'alert-warning'" role="alert" style="margin-bottom:0px;width:100%">
            <span :class="icon"></span> <span v-html="message"></span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LockedAssetWarning',
    props: ['message', 'icon', 'used_class', 'alert_class']
}
</script>
