<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    :custom-label="CustomNameOrDesc"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>


                <div v-if="required_field.type === 'character_images'" class="st-drop-file-block">
                    <div class="st-drop-file-box">
                        <vue-dropzone id="imagesgallery"
                                      ref="myVueDropzone"
                                      :use-custom-slot="true"
                                      :include-styling="false"
                                      :options="characterDropzoneOptions"
                                      @vdropzone-thumbnail="plain_thumbnail"
                                      @vdropzone-file-added="fileAdded"
                                      @vdropzone-removed-file="fileRemoved"
                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                        >
                            <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <div class="st-upload-title">
                                    <h3>Drop files here or click to upload</h3>
                                    <p>... or click to select a file from your computer</p>
                                </div>
                            </div>
                        </vue-dropzone>
                        <b-button
                            @click="$refs.myVueDropzone[0].$el.click();"
                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                        >Add New Image</b-button>
                    </div>
                </div>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

            </b-form-group>
        </div>

        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-character' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const characterResource = new Resource('api/assetflow/characters');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";

export default {
    mixins: [multiSelect, dropzoneMixin],
    name: "AddNewCharacter",
    props: ['modal_title', 'item_type'],
    components: {AFButton, DatePicker},
    data(){
        return {
            characterDropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.character_template()
            },
            addForm:{
                name: '',
                actor_name: '',
                character_number: '',
                ledger_id: null,
                filled_asset_images:[],
                asset_images:[],
            },
            required_fields:{
                name: {
                    key: 'name',
                    label: 'Name',
                    type: 'text'
                },
                actor_name: {
                    key: 'actor_name',
                    label: 'Actor Name',
                    type: 'text'
                },
                character_number: {
                    key: 'character_number',
                    label: 'Character Number',
                    type: 'text'
                },
                ledger_id: {
                    key: 'ledger_id',
                    label: 'Default Ledger',
                    type: 'single-select',
                    options: []
                },
                character_images: {
                    key: 'character_images',
                    label: 'Images',
                    type: 'character_images'
                },
            },
            selected_item: null,
            list_disabled: null,
            lists_values: {}
        }
    },
    created() {
        this.getFieldsList();
    },
    methods:{
        character_template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                            <div class="dz-image">
                                <div data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                            </div>
                            <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        </div>`;
        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                ledger_id: 'ledgers_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

        resetSelectedItem(){
            this.setSelectedItem({});
        },

        setValueFromOptions: function ( field_name, item_id, default_value = null) {
            let found_item = _.find(this.required_fields[field_name].options, function (o) {
                return o.id === item_id;
            });

            this.addForm[field_name] = found_item ? found_item : default_value;
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.actor_name = this.selected_item.actor_name;
                this.addForm.character_number = this.selected_item.character_number;
                this.setValueFromOptions('ledger_id', this.selected_item.ledger_id);
            }
        },
        setSelectedItem(item){
            this.selected_item = item ? item : null;

            this.addForm = {
                name: '',
                actor_name: '',
                character_number: '',
                ledger_id: null,
                filled_asset_images:[],
                asset_images:[],
            };
            this.fillSelectedItem();
        },

        async addItem(event){
            this.isAddingItem = true;

            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (!all_files){
                return;
            }
            var params = {
                name: this.addForm.name,
                actor_name: this.addForm.actor_name,
                character_number: this.addForm.character_number,
                ledger_id: this.addForm.ledger_id ? this.addForm.ledger_id.id : null,
                asset_images: all_files,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await characterResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>
