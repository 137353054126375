<template>
    <Layout>
        <PageHeader :title="title" :items="items" />


        <div class="groups-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Group List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Groups.."></search-box>
                            <b-dropdown v-if="hasPermission('add_groups')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_groups')"
                                                 :href="'./groups/new'">Group
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">



                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->

                    <div v-if="groupsData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'groups'"
                                table_class="switch-customer-table"
                                :keys_list="groups_keys_list">
                                <template v-slot:groups_actions="{ item }">
                                    <b-button class="btn-sm" :href="'./groups/' + item.id + '/edit'" variant="primary">Edit</b-button>
                                    <b-button class="btn-sm" href="javascript:void(0);" v-on:click="deleteItem(item.id, item.name, 'groups', $event)" variant="outline-danger">Delete</b-button>

                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <div v-if="!groupsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Groups found here at the moment.</h4>

                    </div>
                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>


    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

import SearchBox from "@/components/search-box";
import PoLines from "../items/widgets/detailsViews/PoLines";
const groupResource = new Resource('api/assetflow/groups');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {GroupsSortOptions} from "@/views/assetflow/form_data/GroupsSortOptions";

export default {
    props: ['item_id', 'item_type'],
    name: 'GroupList',
    components: {PoLines, Layout, PageHeader, SearchBox },
    data() {
        return {
            groups_keys_list: {
                initials: {
                    key: 'initials',
                    label: "",
                    class: "col-lg-1 initials",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let style = '';
                        if (item.hasOwnProperty('meta') && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                            let bg_colour = item.meta.accent_colour;
                            let text_colour = item.meta.primary_colour;

                            style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
                        }
                        return '<a href="./groups/'+item.id+'/view" class="text-dark">' + '<div class="avatar-sm"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item[key]+'</span></div>' + '</a>'; //
                    }
                },
                name: {
                    key: 'name',
                    label: "Group Name",
                    class: "col-lg-2 name",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a href="./groups/'+item.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.name) + '</a>'; //
                    }
                },
                customers_count: {
                    key: 'customers_count',
                    label: "Customers",
                    class: "col-lg-1 text-center customerscount",
                    show_on_mobile: true,
                    case: ''
                },
                actions: {
                    key: 'actions',
                    label: "",
                    class: "col-lg-2 groups_actions text-center",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'groups_actions'
                },
            },
            total: 0,
            listLoading: true,
            groupsData: null,
            request_meta: {},
            breadcrumb_items: [],
            title: "Group List",
            items: [
                {
                    text: "Groups",
                    href: "/"
                },
                {
                    text: "Group List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 20
            },
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: GroupsSortOptions,
            },
        };
    },
    created() {

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_groups_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.group_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.group_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['name'];

                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";

                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.groupsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.groupsData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('group_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_groups_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.listQuery = this.addPaginationParams(this.listQuery)
            const { data } = await groupResource.list(this.listQuery);
            this.groupsData = data.data.items;
            this.total = data.meta.total;
            this.request_meta = data.meta;
            this.listLoading = false;


        },
        deleteItem(item_id, item_name, item_type, event) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    this.getList();
                });
        }
    },
}
</script>


