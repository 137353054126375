<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="label"
                    :custom-label="CustomMSLabel"
                    :placeholder="'Select ' + required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>
                <b-form-input v-else
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-module')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const moduleResource = new Resource('api/assetflow/modules');

import multiSelect from '@/mixins/MultiSelect';

export default {
    name: "AddNewModule",
    props: ['modal_title', 'item_type', 'modules_options'],
    mixins:[multiSelect],
    data(){
        return {
            addForm:{
                name: '',
                module_template_id: null,
                item_type: {id:'customer', name: 'Customer'}
            },
            required_fields:{
                name: {
                    key: 'name',
                    label: 'Module Name',
                    type: 'text'
                },
                module_template_id: {
                    key: 'module_template_id',
                    label: 'Module Template',
                    type: 'single-select',
                    options:[]
                },
                item_type: {
                    key: 'item_type',
                    label: 'Entity',
                    type: 'single-select',
                    options:[
                        {id:'customer', name: 'Customer'},
                        {id:'sp', name: 'SP'},
                        {id:'group', name: 'Group'},
                        {id:'assetflow', name: 'Assetflow'},
                        {id:'marketplace', name: 'Marketplace'},
                    ]
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        this.getFieldsList();
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                module_template_id: 'module_templates_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });

        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                let entity_type = this.selected_item.entity_type;
                let found_item = _.find(this.required_fields.item_type.options, function(o) { return o.id === entity_type; });
                if (found_item){
                    this.addForm.item_type = found_item;
                }else{
                    this.addForm.item_type = {id:'customer', name: 'Customer'};
                }
                let module_template_id = this.selected_item.module_template_id;
                let found_filter_type = _.find(this.required_fields.module_template_id.options, function(o) { return o.id === module_template_id; });
                if (found_filter_type){
                    this.addForm.module_template_id = found_filter_type;
                }else{
                    this.addForm.module_template_id = null;
                }
            }
        },


        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                item_type: this.addForm.item_type ? this.addForm.item_type.id : 'customer',
                module_template_id: this.addForm.module_template_id ? this.addForm.module_template_id.id : null
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await moduleResource.store(params);

            let new_item = data.data.items;
            let saved = data.data.status;
            this.presentToast((saved ? "Success!" : "Error!"),
                saved ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : (data.data.items),
                (saved ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-module');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


