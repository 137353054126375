<template>
    <b-modal id="set_order_service"
             size="lg"
             :title="modal_title ? modal_title : (selected_item && selected_item.service_id ? 'Change Service' : 'Set Order Service')"
             title-class="font-18"
             hide-footer>


        <SetServiceAndConfirm
            v-if="selected_item"
            :item_type="item_type"
            :received_item_info="selected_item"
            @after-saving="$emit('after-saving')"
            @on-cancel-clicked="$emit('on-cancel-clicked')"
            :shall_confirm="false"/>


    </b-modal>
</template>
<script>
import SetServiceAndConfirm from "../../customers/orders/SetServiceAndConfirm";
export default {
    props: ['modal_title'],
    name: 'SetOrderService',
    components: {SetServiceAndConfirm},
    data(){
        return {
            item_type: null,
            selected_item: null,
        }
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        setSelectedItem(item, item_type){
            if (item){
                this.selected_item = item;
                this.item_type = item_type;
            }else{
                this.selected_item = null;
            }
        },
    }
}
</script>
