<template>
    <div class="CharacterAssetsTab">
        <po-lines v-if="item_info.hasOwnProperty('assets_info') && item_info.assets_info && item_info.assets_info.length > 0"
                  :items="item_info.assets_info"
                  :current_page_type="item_info.current_page_type"
                  :object_type="'assets'"
                  :line_type="'assets'"
                  :get-poline-classes-dict="getPoLineClassFor"
                  :keys_list="asset_list_key_list">
            <template v-slot:asset_list_actions="{ item, index }">
                <div v-if="has_actions_permissions">
                    <asset-actions-dropdown
                        :item="item"
                        :item_type="item_info.current_page_type"
                        :perform-action="null"/>
                </div>
            </template>
        </po-lines>
        <div v-else class="text-center">
            <h6>No assets found here at the moment.</h6>
        </div>
    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines";
import AssetActionsDropdown from "../assets/subItems/AssetActionsDropdown";
export default {
    name: 'CharacterAssetsTab',
    components: {AssetActionsDropdown, PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            asset_list_key_list:{
                uid: {
                    key: 'uid',
                    label: "ID",
                    class: "col-lg-1 a_uid",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return '';
                    },
                    value_func: function (index, item, key) {}
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col a_name",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return (item.uid !== '' ? 'UID: ' + item.uid : '');
                    },
                    value_func: function (index, item, key) {}
                },
                status: {
                    key: 'status',
                    label: "Status",
                    class: "col-lg-2 a_status",
                    show_on_mobile: false,
                    case: 'asset_status'
                },
                quantity: {
                    key: 'quantity',
                    label: "Qty",
                    class: "col-lg-1 a_quantity",
                    show_on_mobile: false,
                    case: ''
                },
                total_value: {
                    key: 'total_value',
                    label: "Cost",
                    class: "col-lg-1 a_total_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                location_id: {
                    key: 'location_id',
                    label: "Location",
                    class: "col-lg-2 a_location_id",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return Vue.options.filters.titleize(item.address_location_name);
                    }
                },
                internal_reference: {
                    key: 'internal_reference',
                    label: "Ref No.",
                    class: "col-lg-1 a_reference",
                    show_on_mobile: false,
                    case: ''
                },
                asset_usage: {
                    key: 'asset_usage',
                    is_html_label: true,
                    label: "<span class='au-cam'>Cam</span><span class='au-is-hero'>Hero</span><span class='au-is-asset'>Asset</span>",
                    class: "col-lg-2 a_asset_usage",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item){
                        var html = '';
                        let required_items = ['asset_usage', 'is_hero', 'is_asset'];
                        for (const key in required_items) {
                            let assetKey = required_items[key];

                            let required_classes = '';
                            switch (item[assetKey]){
                                case 0:
                                    required_classes = 'text-danger bx-x';
                                    break;
                                case 1:
                                    required_classes = 'text-success bx-check';
                                    break;
                                case 2:
                                    required_classes = 'text-secondary bx-question-mark';
                                    break;
                            }

                            html += "<b><i class='bx font-size-22 " + required_classes + "'>" + "</i></b>"

                        }
                        return html;
                    }
                },
                actions: {
                    key: 'actions',
                    label: "Actions",
                    class: "col-lg-1 a_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'asset_list_actions'
                }
            },
        }
    },
    created() {
        this.has_actions_permissions = this.hasPermission('edit_assets') || this.hasPermission('delete_assets');

        let item_info = this.item_info;
        // let current_customer = this.current_customer;
        // this.asset_list_key_list.customer = {
        //     key: 'customer',
        //     label: "Customer",
        //     class: "col-lg-1 a_customer",
        //     show_on_mobile: false,
        //     case: 'custom_html',
        //     value_func: function (item, key) {}
        // }

        this.asset_list_key_list.uid.value_func = function (index, item, key) {
            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/assets/' + item.id + '/view">ASS-' + item[key] + '</a>' : '';
        };
        this.asset_list_key_list.name.value_func = function (index, item, key) {
            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/assets/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>' : '';
        };

        // this.asset_list_key_list.customer.value_func = function (item, key) {
        //     let current_customer_link = 'overview';
        //     if (current_customer && current_customer.id !== item.customer_id){
        //         current_customer_link = '/customers/' + item.customer_id + '/view';
        //     }
        //     return '<a style="' + (item.hasOwnProperty('customer_accent_colour') ? 'color: ' + item.customer_accent_colour + ' !important;' : '') +  '" href="/' + item_info.current_page_type +'/' + current_customer_link + '">' + item.customer_name + '</a>';
        // };

        if (!this.is_tv_film_asset){
            delete this.asset_list_key_list.asset_usage;
        }

    },
    methods: {
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },

    }

}
</script>
