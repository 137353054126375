<template>
    <Layout>
        <div v-html="this.componentHTML"></div>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
export default {
    name: "HtmlRenderer",
    components: {
        Layout
    },
    data(){
        return {
            componentName: '',
            componentHTML: ''
        }
    },
    methods: {
        getComponentContent(){
            axios({
                method:'get',
                url:'/c_names/' + this.componentName,
                baseURL: '/',
            }).then(response => {
                this.componentHTML = response.data;
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        getQueryVars(){
            let uri = window.location.href.split('?');
            let getVars = {};
            if (uri.length === 2)
            {
                let vars = uri[1].split('&');
                let tmp = '';
                vars.forEach(function(v){
                    tmp = v.split('=');
                    if(tmp.length === 2)
                        getVars[tmp[0]] = tmp[1];
                });
            }
            return getVars;
        },
    },
    created() {
        let queryVars = this.getQueryVars();
        this.componentName = queryVars.filename;
        this.getComponentContent();

    },

}
</script>


