<template>
    <div class="AssetUsageRightBox">
        <div class="row">
            <div class="col-xl-12">

                <div class="step-common-info-block">
                    <div class="st--common--box" v-for="(required_field, section_field) in required_fields" :key="section_field">
                        <div class="common-st-title-label">
                            <label>{{ required_field.name }}</label>
                        </div>

                        <div class="st-radio-row" v-if="required_field.type === 'radio-select'">
                            <div v-for="(option, index) in required_field.options"
                                 :key="index"
                                 :class="getClassFor(required_field)">
                                <div class="cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                    <label>
                                        <input
                                            v-model="addForm[section_field]"
                                            :key="index"
                                            :id="section_field + '_' + index"
                                            :name="section_field"
                                            :value="option"
                                            @click="changedRadioValue(section_field, option, $event)"
                                            type="radio"/>
                                        <div class="st-radio-box" :class="(addForm[section_field] && option.id === addForm[section_field].id) ? 'force-checked' : ''">
                                            <span></span>
                                            <p>{{ option.name| titleize }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="common--input-filed" v-else-if="['text', 'number'].includes(required_field.type)">
                            <input v-model="addForm[section_field]" placeholder="" :type="required_field.type">


                            <button v-if="section_field === 'external_reference'" @click="changedRadioValueOf(section_field, addForm[section_field])" type="button" class="btn af-btn-accent af-accent mt-2 btn-sm"><i class="bx bx-detail mr-2"></i>Save</button>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>

import AssetInfoSection from "../../assetForm/AssetInfoSection";
import StepCommonInfoBlock from "../../../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";
export default {
    components: {StepCommonInfoBlock, AssetInfoSection},
    mixins: [],
    name: 'ReconciliationRightBox',
    props: {
        my_asset: {},
        item_type: {},
        changedRadioValueOf: {},
        getClassFor: {},
    },
    data(){
        return {
            addForm: {
                reconciled: null,
                external_reference: null,
            },
            usage_fields: {
                name: '',
                minimized: false,
                fields: [
                    'reconciled',
                    'external_reference',
                ]
            },
            required_fields:{
                reconciled: {
                    name: 'Reconciled',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 0, name: 'No' },
                    options: [
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                    ]
                },
                external_reference: {
                    name: 'External Reference',
                    type: 'text',
                    has_permission: true
                },
            },
        }
    },
    computed: {

    },
    watch: {
        my_asset:{
            deep: true,
            handler: function (newValue) {
                this.setDefaultValues();
            }
        },
    },
    created() {
        this.setDefaultValues();
    },
    methods: {
        setDefaultValues() {
            let reconciled_property_value = this.getObjectOf(this.my_asset.reconciled, this.required_fields.reconciled.options);
            this.$set(this.addForm, 'reconciled', reconciled_property_value);
            this.$set(this.addForm, 'external_reference', this.my_asset.external_reference);
        },
        changedRadioValue(field_key, option, event) {
            if (event){
                this.changedRadioValueOf(field_key, option.id);
            }
        },

    }
}
</script>
