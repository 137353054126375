<template>
    <div>

        <!-- Assets Storage Status  -->
        <div class="assets-section box-border mb-3 p-2 p-lg-3">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left col-divider">
                    <div class="assets-common ">
                        <div class="asseta-common-title mb-1">
                            <strong>Current Storage Status</strong>
                        </div>
                        <div class="assets-detail">
                            <span :class="'status-btn status-btn-' + (item_info.storage_status === 1 ? 'active' : 'disposed')" style=" font-size: 20px!important; ">{{ item_info.storage_status === 1 ? 'In Storage' : 'Not In Storage' | titleize }}</span>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Managed Storage</strong>
                        </div>
                        <div class="assets-detail" v-if="item_info.hasOwnProperty('latest_managed_storage_info')">
                            <a :href="'/' + item_info.current_page_type + '/managed_storages/' + item_info.latest_managed_storage_info.id + '/view'" class="text-dark">{{ item_info.latest_managed_storage_info.uid | titleize }}</a>
                        </div>
                        <div class="assets-detail" v-else>-</div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Service Provider</strong>
                        </div>
                        <div class="assets-detail" v-if="item_info.hasOwnProperty('warehouse_name')">
                            <a :href="'/' + item_info.current_page_type + '/service_providers/' + item_info.warehouse_id + '/view'" class="text-dark">{{ item_info.warehouse_name | titleize }}</a>
                        </div>
                        <div class="assets-detail" v-else>-</div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Contract</strong>
                        </div>
                        <div class="assets-detail" v-if="item_info.hasOwnProperty('customer_contract_info')">
                            <a :href="'/' + item_info.current_page_type + '/customer_contracts/' + item_info.customer_contract_info.id + '/view'" class="text-dark">{{ item_info.customer_contract_info.uid | titleize }}</a>
                        </div>
                        <div class="assets-detail" v-else>-</div>

                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Date in Storage</strong>
                        </div>
                        <div class="assets-detail" v-if="item_info.hasOwnProperty('latest_managed_storage_info')">
                            {{ item_info.latest_managed_storage_info.start_date | moment('MMMM Do YYYY') }}
                        </div>
                        <div class="assets-detail" v-else>-</div>

                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center text-xl-right col-divider last-child">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Time in Storage</strong>
                        </div>
                        <div class="assets-detail" v-if="item_info.hasOwnProperty('latest_managed_storage_info')">
                            {{ item_info.latest_managed_storage_info.start_date | fromNow(true)}}
                        </div>
                        <div class="assets-detail" v-else>-</div>
                    </div>
                </div>
            </div>
        </div>
        <!--  Assets Storage Status Close -->

        <!-- Storage Template -->
        <div class="storage-template">
            <div class="row managed-storage-history-block">
                <custom-generic-block block_title="Managed Storage History" columns_count_withclasses="12">
                    <template v-slot:block_content>
                        <div class="col-12">
                            <ListManagedStoragesFor
                                :item_info="item_info"
                                :managed_storages="item_info.all_managed_storages_info"
                                :keys_list="managed_storage_list_keys_list"
                                object_type="managed_storages"
                            />
                        </div>
                    </template>
                </custom-generic-block>
            </div>

            <div class="row ordering-history-block">
                <custom-generic-block block_title="Ordering History for this Asset" columns_count_withclasses="12">
                    <template v-slot:block_content>
                        <div class="col-12">
                            <ListManagedStoragesFor :item_info="item_info"
                                                    :managed_storages="item_info.all_order_lines_info"
                                                    :keys_list="order_line_short_list_keys_list"
                                                    object_type="order_lines"
                            />
                        </div>
                    </template>
                </custom-generic-block>
            </div>
        </div>

        <!-- Storage Template Close-->
    </div>

</template>

<script>

import AssetRightBox from "./AssetRightBox";
import CustomGenericBlock from "./CustomGenericBlock";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import AssetLocationBlock from "./AssetBlocks/AssetLocationBlock";

import ListManagedStoragesFor from "../../../ordersContent/ListManagedStoragesFor";
import {ManagedStorageKeysList} from "@/views/assetflow/form_data/ManagedStorageKeysList";
import {OrderLineShortKeysList} from "@/views/assetflow/form_data/OrderLineShortKeysList";

export default {
    mixins:[AssetTabsShared],
    name: "AssetStorageInfo",
    components: {
        ListManagedStoragesFor,
        AssetLocationBlock,
        CustomGenericBlock,
        AssetRightBox
    },
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            managed_storage_list_keys_list: ManagedStorageKeysList,
            order_line_short_list_keys_list: OrderLineShortKeysList,
        }
    },
    created() {
        if (this.item_info.current_page_type === 'sp'){
            if (this.is_tv_film_asset){
                this.order_line_short_list_keys_list.customer_info.label = 'Production';
                this.managed_storage_list_keys_list.customer_info.label = 'Production';
            }
            delete this.order_line_short_list_keys_list.sp_info;
            delete this.managed_storage_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.order_line_short_list_keys_list.customer_info;
            delete this.managed_storage_list_keys_list.customer_info;
        }
    }
}
</script>
