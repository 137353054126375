<template>
    <div>
        <a :href="'/' + item_info.current_page_type + '/assets'">
            <div class="asset-details-back af-accent"></div>
        </a>

        <!-- Page-Content start-->
        <div id="disboard-content-wrapper">
            <div class="page-content-inner content-inner_scroll">
                <div class="tab_menu_item">
                    <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li v-for="tab in tabs" class="nav-item">
                            <a class="nav-link"
                               @click="tabClicked(tab.id)"
                               :class="isActiveTab(tab.id)"
                               data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content tab_content_design">
                    <div class="tab-pane" id="asset_overview" role="tabpanel" :class="isActiveTab('asset_overview')">
                        <AssetOverviewTab :item_info="item_info" :post_meta_keys="post_meta_keys"/>
                    </div>

                    <div class="tab-pane" id="asset_activity" role="tabpanel" :class="isActiveTab('asset_activity')">
                        <div class="row">
                            <div class="col-lg-8 col-md-12 col-12">
                                <div class="main_content_block">
                                    <div class="Post_An_Update">
                                        <h6 @click="hideForm"
                                            class="Post_An_Update_title Post_An_Update_dropdown"
                                            :class="formShown ? '' : 'this_active'">
                                            Post An Update:
                                        </h6>
                                        <add-item-post
                                            v-show="formShown"
                                            :with_reload="true"
                                            @on-cancel-post-adding="hideForm"
                                            post_type="asset"
                                            :item_id="item_info.id">

                                        </add-item-post>
                                    </div>
                                    <div class="Assets_Timeline">
                                        <div class="Assets_Timeline_title d-sm-flex align-items-center justify-content-between">
                                            <h6 class="Post_An_Update_title">Timeline:</h6>

                                            <div class="only_image_block">
                                                <div class="grid-list-button d-flex align-items-center justify-content-between mb-0">
                                                    <div
                                                        v-for="(layout, key) in supportedLayouts"
                                                        :key="key"
                                                        @click="changeTimelineLayout(layout, $event)"
                                                        :id="'grid-list-'+layout.class"
                                                        :class="layout.class + (layout.isActive ? ' active' : '')"></div>
                                                </div>

                                            </div>
                                        </div>

                                        <po-lines v-if="selected_layout && selected_layout === 1"
                                                  :items="item_info.asset_posts"
                                                  :current_page_type="item_info.current_page_type"
                                                  :object_type="'assets'"
                                                  :keys_list="asset_posts_list_key_list">
                                        </po-lines>
                                        <!-- Richard Created Asset 54 Html Strat -->
                                        <div v-else class="richard-text_block">
                                            <div v-for="item_post in item_info.asset_posts" class="personal_details">

                                                <div class="media media_b_a">
                                                    <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                                        <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                                    </div>

                                                    <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                                    <div class="media-body personal_media-body">
                                                        <h6 class="personal_details_title">
                                                            {{ item_post.user.name }} <span>{{ getItemPostTitle(item_info, item_post) }}</span>
                                                        </h6>
                                                        <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                                    </div>
                                                </div>

                                                <my-post-content :item_post="item_post">
                                                    <template v-slot:post_description="{ item }">
                                                        <div>
                                                            <div v-if="[2,3].includes(item_post.action_type) && item_post.hasOwnProperty('post_meta')" class="added-asset-info">
                                                                <div v-if="item_post.post_meta && Object.keys(item_post.post_meta).length > 3">
                                                                    <div v-for="(post_meta_key, key) in post_meta_keys"
                                                                         :key="key"
                                                                         v-if="item_post.post_meta.hasOwnProperty(key)"
                                                                    >
                                                                        <span class="post-text-heading">{{ post_meta_key.label }}</span>
                                                                        <span class="post-text-value" v-html="post_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                                    </div>
                                                                </div>
                                                                <div v-else>
                                                                    The record was saved. No changes were detected.
                                                                </div>
                                                            </div>
                                                            <div v-else-if="[7,8].includes(item_post.action_type) && item_post.hasOwnProperty('post_meta') && item_post.post_meta" class="added-asset-info">
                                                                <p v-html="item.description.replace(/ITEM_TYPE/g, item_info.current_page_type)"></p>
                                                                <div v-for="(disposition_meta_key, key) in (item_post.action_type === 7 ? disposition_meta_keys : acquisition_meta_keys)"
                                                                     :key="key"
                                                                     v-if="item_post.post_meta.hasOwnProperty(key) && item_post.post_meta[key]"
                                                                >
                                                                    <span class="post-text-heading">{{ disposition_meta_key.label }}</span>
                                                                    <span class="post-text-value" v-html="disposition_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="[6,21,28,29,30].includes(item_post.action_type) && item_post.hasOwnProperty('post_meta') && item_post.post_meta" class="added-asset-info">
                                                                <p v-html="item.description.replace(/ITEM_TYPE/g, item_info.current_page_type)"></p>
                                                                <div v-for="(disposition_meta_key, key) in ms_post_meta_keys"
                                                                     :key="key"
                                                                     v-if="item_post.post_meta.hasOwnProperty(key) && item_post.post_meta[key]"
                                                                >
                                                                    <span class="post-text-heading">{{ disposition_meta_key.label }}</span>
                                                                    <span class="post-text-value" v-html="disposition_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <p v-html="item.description.replace(/ITEM_TYPE/g, item_info.current_page_type)"></p>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </my-post-content>

                                            </div>
                                        </div>
                                        <!-- Richard Created Asset 54 Html End -->
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 col-12">
                                <div class="row">
                                    <NewCustomGenericBlock
                                        :block_title="item_info.name"
                                        :block_title_icon="item_info.ownership_type === 1 ? 'bx bx-cube-alt' : 'bx bx-time'"
                                        box_colour_v="-"
                                        columns_count_withclasses="12">
                                        <template v-slot:all_content>
                                            <asset-right-box :item_info="item_info" unique_id="asset-details"/>
                                        </template>
                                    </NewCustomGenericBlock>

                                    <NewCustomGenericBlock
                                        :block_title="block_contents.liability.label"
                                        :box_colour_v="'common_sub_col_bg color_v_accent ' + (item_info.ownership_status === 1 ? 'ownership-status-current ownership-status-current-bg' : 'ownership-status-ended ownership-status-ended-bg')"
                                        columns_count_withclasses="12">
                                        <template v-slot:all_content>
                                            <CustomGenericBlockContent :block_contents="block_contents.liability"/>
                                        </template>
                                    </NewCustomGenericBlock>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane" id="asset_db_fields" role="tabpanel" :class="isActiveTab('asset_db_fields')">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <tbody>
                                <tr
                                    v-for="(value, key) in required_fields"
                                    v-if="value !== 'description'"
                                    :key="key">
                                    <td>
                                        {{ getKeyName(value) | titleize }}
                                    </td>

                                    <td>
                                        {{getValueFor(value)}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table-responsive -->
                    </div>
                    <div class="tab-pane" id="Sustainability" role="tabpanel" :class="isActiveTab('Sustainability')">
                        <sustainability-tab v-if="item_info.ownership_status === 1" :item_info="item_info">
                        </sustainability-tab>
                        <DispositionTab
                            v-else-if="item_info.hasOwnProperty('dispositions_info')"
                            :item_info="item_info"
                        />
                    </div>
                    <div class="tab-pane" id="asset_accounting" role="tabpanel" :class="isActiveTab('asset_accounting')">
                        <accounting-tab :item_info="item_info">
                        </accounting-tab>
                    </div>

                    <div class="tab-pane" id="asset_intelligence" role="tabpanel" :class="isActiveTab('asset_intelligence')">
                        <intelligence-tab :item_info="item_info" :post_meta_keys="post_meta_keys">

                        </intelligence-tab>
                    </div>
                    <div class="tab-pane" id="asset_storage" role="tabpanel" :class="isActiveTab('asset_storage')">
                        <AssetStorageInfo :item_info="item_info" :post_meta_keys="post_meta_keys"/>
                    </div>
                    <div class="tab-pane" id="asset_media" role="tabpanel" :class="isActiveTab('asset_media')">
                        <media-tab :item_info="item_info" object_type="assets">
                        </media-tab>
                    </div>
                    <div class="tab-pane" id="asset_files" role="tabpanel" :class="isActiveTab('asset_files')">

                        <AddMSFile
                            :ref="add_ms_files"
                            :received_modal_id="add_ms_files"
                            modal_title="Add Files"
                            @on-new-item-adding="refreshAsset"
                        />

                        <NewCustomGenericBlock
                            block_title="Files"
                            box_colour_v="-"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <ObjectFilesTab
                                    :item_info="item_info"
                                    object_type="asset_files"
                                    page_unique_name="asset_files"
                                    required_files="asset_files"
                                    :template_category_id="-99"
                                    :perform-action="performAction"
                                />
                            </template>
                        </NewCustomGenericBlock>
                    </div>
                    <div class="tab-pane" id="asset_passport" role="tabpanel" :class="isActiveTab('asset_passport')">
                        <asset-passport-tab :item_info="item_info" :post_meta_keys="post_meta_keys" />
                    </div>
<!--                    TODO :// to be implemented-->
                    <div class="tab-pane" id="asset_actions" role="tabpanel" :class="isActiveTab('asset_actions')">
                        <ActionsTab :item_info="item_info"/>
                    </div>

                </div>
            </div>
        </div>
        <!-- Page-Content end-->


    </div>
</template>

<script>
import AssetActions from "@/mixins/assetActions.mixin";

import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import MyPostContent from "./PostContentTypes/MyPostContent";
import AddItemPost from "./AddItemPost";
import SustainabilityTab from "./AssetTabs/SustainabilityTab";
import AccountingTab from "./AssetTabs/AccountingTab";
import MediaTab from "./AssetTabs/MediaTab";
import IntelligenceTab from "./AssetTabs/IntelligenceTab";
import AssetRightBox from "./AssetTabs/AssetRightBox";
import IpCheckTab from "./AssetTabs/IpCheckTab";
import PoLines from "./PoLines";
import AssetPassportTab from "./AssetTabs/AssetPassportTab";
import ActionsTab from "./ActionsTab";
import AssetStorageInfo from "./AssetTabs/AssetStorageInfo";
import NewCustomGenericBlock from "./AssetTabs/NewCustomGenericBlock";
import CustomGenericBlockContent from "./AssetTabs/CustomGenericBlockContent";
import {PostMetaKeys} from "@/views/assetflow/form_data/PostMetaKeys";
import DispositionTab from "./DispositionTab";
import {MSPostMetaKeys} from "@/views/assetflow/form_data/MSPostMetaKeys";
import {DispositionMetaKeys} from "@/views/assetflow/form_data/DispositionMetaKeys";
import {AcquisitionMetaKeys} from "@/views/assetflow/form_data/AcquisitionMetaKeys";
import AssetOverviewTab from "./AssetTabs/AssetOverviewTab";
import ObjectFilesTab from "../../ObjectFilesTab";
import AddMSFile from "../../ordersContent/AddMSFile";

export default {
    name: "AssetDetailsNewAllTabs",
    mixins: [AssetActions, TotalsAndCurrencies, ImageGallery, TimelineCommons],
    components: {
        AddMSFile,
        ObjectFilesTab,
        AssetOverviewTab,
        DispositionTab,
        CustomGenericBlockContent,
        NewCustomGenericBlock,
        AssetStorageInfo,
        ActionsTab,
        AssetPassportTab,
        PoLines,
        IpCheckTab,
        AssetRightBox,
        IntelligenceTab,
        MediaTab,
        AccountingTab,
        SustainabilityTab,
        AddItemPost,
        MyPostContent
    },
    props: ['tabs', 'item_info'],
    data() {
        return {
            add_ms_files: 'add-ms-files',
            ms_post_meta_keys: MSPostMetaKeys,
            disposition_meta_keys: DispositionMetaKeys,
            acquisition_meta_keys: AcquisitionMetaKeys,
            required_meta_fields:[
                'vehicle',
                'make',
                'model',
                'engine_size',
                'mileage',
                'clothing',
                'description',
                'type_of_item',
                'colour',
                'size',
                'condition',
                'material',
                'notes'
            ],
            required_fields: [
                'name', 'description', 'created_by', 'created_method', 'internal_reference', 'product_id', 'folder_id', 'category_id', 'is_unique', 'quantity', 'status', 'location_id', 'contact_id', 'import_file_id', 'bss_status', 'user_id', 'customer_id', 'warehouse_id', 'container_id', 'condition', 'width', 'height', 'depth', 'volume', 'weight', 'colours', 'composition', 'tag_ids', 'modified_created_date', 'modified_updated_date', 'modified_planned_date', 'modified_acquired_date', 'modified_deployed_date', 'modified_last_serviced_date', 'modified_in_storage_date', 'modified_listed_date', 'modified_disposition_date'
            ],
            post_meta_keys: PostMetaKeys,
            all_tags_list: [],
            block_contents: {
                liability:{
                    label: 'Ownership',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                }
            }
        }
    },
    created() {
        let item_info = this.item_info;
        this.block_contents.liability.info_list = [
            {
                label: 'Ownership Type',
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.ownership_type === 1 ? 'Owned' : 'Temporary';
                }
            },
            {
                label: 'Ownership Status',
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.ownership_status === 1 ? 'Current' : 'Ended';
                }
            },
            {
                label: (item_info.ownership_status === 1 ? 'Acquired Date' : 'Ended Date'),
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    let age_key = item_info.ownership_status === 1 ? 'acquired_date' : 'ownership_end_date';
                    return item_info.hasOwnProperty(age_key) && item_info[age_key] && item_info[age_key] !== '';
                },
                info_value : function () {
                    let age_key = item_info.ownership_status === 1 ? 'acquired_date' : 'ownership_end_date';
                    return Vue.options.filters.moment(item_info[age_key], 'MMMM Do YYYY');
                }
            },
            {
                label: (item_info.ownership_status === 1 ? 'Age' : 'Lifespan'),
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    let age_key = item_info.ownership_status === 1 ? 'asset_age' : 'ownership_age';
                    return item_info.hasOwnProperty(age_key) && item_info[age_key] && item_info[age_key] !== '';
                },
                info_value : function () {
                    return item_info.ownership_status === 1 ? item_info.asset_age : item_info.ownership_age;
                }
            },
        ];

        let getItemPostTitle = this.getItemPostTitle;
        this.asset_posts_list_key_list.action_title.value_func = function (index, item, key) {
            return Vue.options.filters.capitalize(getItemPostTitle(item_info, item));
        }

        if (this.item_info.hasOwnProperty('asset_meta') && this.item_info.asset_meta.hasOwnProperty('custom_fields')){
            this.required_meta_fields = this.required_meta_fields.concat(this.item_info.asset_meta.custom_fields);
        }

        let user_meta = window.USER_INFO.user_meta;
        if (user_meta.hasOwnProperty('preferred_timeline_layout')) {
            this.selected_layout = window.USER_INFO.user_meta.preferred_timeline_layout;
        }

        if (user_meta.hasOwnProperty('post_update_form_appearance')){
            this.formShown = user_meta.post_update_form_appearance;
        }

        let asset_active_tab = localStorage.getItem('asset_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== '') {
            let selected_tab = queryVars.t;
            switch (selected_tab) {
                case 'overview':
                    this.item_info.active_tab = 'asset_overview';
                    break;
                case 'activity':
                    this.item_info.active_tab = 'asset_activity';
                    break;
                case 'db_fields':
                    this.item_info.active_tab = 'asset_db_fields';
                    break;
                case 'sustainability':
                    this.item_info.active_tab = 'Sustainability';
                    break;
                case 'accounting':
                    this.item_info.active_tab = 'asset_accounting';
                    break;
                case 'intelligence':
                    this.item_info.active_tab = 'asset_intelligence';
                    break;
                case 'storage':
                    this.item_info.active_tab = 'asset_storage';
                    break;
                case 'media':
                    this.item_info.active_tab = 'asset_media';
                    break;
                case 'files':
                    this.item_info.active_tab = 'asset_files';
                    break;
            }
        } else if (asset_active_tab && asset_active_tab !== ''){
            this.active_tab = asset_active_tab;
        }

    },
    computed: {
        unit_value(){
            return this.item_info.value ? this.item_info.value : '-';
        },
        total_value(){
            return this.item_info.total_value ? this.item_info.total_value : '-';
        },
    },
    methods: {

        refreshAsset(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            location.reload();
        },
        performAction(item, action_name, event) {
            if (action_name === 'add_ms_files'){
                let my_action_name = this[action_name];
                this.$refs[my_action_name].setSelectedItem(item);
                this.$bvModal.show(my_action_name);
            }
        },
        tabClicked(tab_id){
            this.set_usermeta('asset_active_tab', tab_id, '');
        },
        isActiveTab(tab_id){
            return this.item_info.active_tab === tab_id ? 'active' : '';
        },
        hasAnyMetaKey(){
            for (const requiredMetaFieldsKey in this.required_meta_fields) {
                let value = this.required_meta_fields[requiredMetaFieldsKey];
                if (this.item_info.asset_meta && this.item_info.asset_meta.hasOwnProperty(value)){
                    return true;
                }
            }
            return false;
        },
        getKeyName(value){
            if (value.indexOf('modified') !== -1){
                value = value.replace('modified_', '');
            }
            return value;
        },
        getValueFor(value){
            switch (value) {
                case 'colours':
                case 'tag_ids':
                case 'composition':
                    return this.item_info[value] ? this.item_info[value].join(', ') : this.item_info[value];
                default:
                    return this.item_info[value] != null && this.item_info[value] !== '' ? this.item_info[value] : '-';
            }
        },
    }
}
</script>


<style scoped>
.page-content-inner {
    padding: 0;
}

#disboard-content-wrapper {
    overflow: visible;
    height: auto;
}
.my-dots-horizontal .btn-none {
    padding: 0 !important;
}

.asset-details-actions {
    right: 50px !important;
    top: 150px !important;
}
</style>
