<template>
    <div>
        <input
            ref="myDropdowninput"
            v-model.trim="inputValue"
            type="search"
            :placeholder="$t('navbar.search.text')"
            :class="{ 'focused': focused }"
            @focus="changeDropdownInputWidth(true)"
            @blur="changeDropdownInputWidth(false)"
            class="form-control dropdownInput" />
        <span v-if="!is_mobile" class="bx bx-search-alt"></span>

        <div
            v-show="inputValue && apiLoaded"
            class="myDropdown-list"
            :class="{ 'focused': focused }"
        >
            <div class="col-md-12">
                <div class="text-right">
                    <a class="close-search" @click="inputValue = ''"><small>Close</small></a>
                </div>
            </div>
            <div v-for="(subItem, key) in grouped_itemList" class="row">

                <div class="col-md-12">
                    <div
                        class="myDropdown-item myDropdown-itemHheader"
                        :class="{'myRounder': key === Object.keys(grouped_itemList)[0] }"
                        :style="selected_colour !== '' ? ('background: '+selected_colour+'; color: '+(selected_colour != '#ffffff' ? '#a6b0cf' : '#495057' ) + ';') : ''"
                        v-if="key !== 'loading'"
                    >
                        {{(key === 'ledgers' ? 'P-Cards' : key) | titleize}}
                    </div>
                    <div
                        class="myDropdown-item"
                        :class="{'is_result': key === 'results' }"
                        v-if="key !== 'loading'"
                        v-for="item in subItem"
                        :key="item.id+item.type"
                        @click="key !== 'results' ? selectItem(item) : false;"
                    >
                        <div
                            class="search-location-item"
                            v-if="item.hasOwnProperty('sp_location_info') && item.sp_location_info"
                            v-html="getLocationHTML(item.sp_location_info)"></div>

                        <img v-if="item.logo" :src="item.logo" class="myDropdown-item-flag" />
                        <a class="search-customer-name"
                            v-if="(current_item_type === 'group' && item.hasOwnProperty('customer_name'))"
                            :style="(item.customer_accent_colour !== '' ? ('color:' + item.customer_accent_colour + ' !important') : '')"
                        >{{ item.customer_name }}</a>
                        {{item.uid && !item.hasOwnProperty('sp_location_info') ? item.uid+': ' : ''}}{{ !item.hasOwnProperty('sp_location_info') ? item.name : '' }}

                    </div>

                    <div v-else class="text-center loading-box">
                        {{ item.name | titleize }}
                    </div>

                    <div v-if="key !== 'results' && key !== 'loading' && itemMeta[key]" class="myDropdown-item view-all" @click="seeAll(key)">
                        {{itemMeta[key].hasOwnProperty('has_more') && itemMeta[key].has_more ? 'More than ' + (itemMeta[key].total_count - 1) + ' found. ':''}}See All
                    </div>
                    <div v-if="itemMeta[key] && itemMeta[key].hasOwnProperty('related_objects') && itemMeta[key].related_objects"
                         class="other-objects-list">
                        <div v-for="(related_object, ro_key) in itemMeta[key].related_objects"
                             :key="ro_key"
                             class="myDropdown-item"
                             :class="'all-for-' + related_object.object_type"
                             @click="seeAllOf(key, related_object.object_type, related_object.id)">
                            {{ related_object.message }}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Resource from '@/api/resource';
    const searchResource = new Resource('api/assetflow/search');
    import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";

    import { SearchResults } from './DummySearchResults';
    export default {
        name: "SearchDropdown",
        data () {
            return {
                selectedItem: {},
                inputValue: '',
                itemList: [],
                itemMeta: [],
                grouped_itemList: [],
                apiLoaded: false,
                apiUrl: '',
                selected_colour: '',
                current_layout: 'default',
                current_item_type: '',
                focused: false,
                is_mobile: false,
                cancelSource: null
            }
        },
        props:{
            mobile_only: {
                type: Boolean,
                default: false
            }
        },
        mounted () {
            this.getList();
        },
        created() {
            this.cancelSource = axios.CancelToken.source();

            this.is_mobile = this.mobile_only ?? false;
            const pathArray = window.location.pathname.split("/");
            this.current_item_type = pathArray.length > 2 && pathArray[1] !== '' ? pathArray[1] : 'customer';
            this.selected_colour = window.COLOUR_PLATE !== undefined && window.COLOUR_PLATE.hasOwnProperty('primary_colour') && window.COLOUR_PLATE.primary_colour ? COLOUR_PLATE.primary_colour : '';
            this.current_layout = CURENT_LAYOUT ? CURENT_LAYOUT : 'default';
        },
        methods: {
            getLocationHTML(sp_location_info){
                return getLocationDetails(sp_location_info, true, true, false);
            },
            changeDropdownInputWidth(focused){
                if (this.is_mobile || this.inputValue !== '') {
                    return;
                }
                this.focused = focused
            },
            selectItem (theItem) {
                this.selectedItem = theItem;
                this.inputValue = theItem.name;
                this.$emit('on-item-selected', theItem);
            },
            seeAll(item_type){
                // console.log("Should Go To " + item_type + " and Search from " + this.inputValue);
                // console.log(this.current_item_type + "/" + item_type + "?s=" + this.inputValue);

                let extra_params = '';
                if (item_type === 'ledgers'){
                    extra_params = '&c=buyer';
                }
                window.location = encodeURI("/" + this.current_item_type + "/" + item_type + "?s=" + this.inputValue + extra_params);
            },
            seeAllOf(item_type, queue_name, item_id){
                if (queue_name === 'user'){
                    queue_name = 'created_by';
                }
                if (item_type === 'managed_storages'){
                    item_type += '/in_storage';
                    switch (queue_name) {
                        case 'folder':
                        case 'tag':
                            queue_name = queue_name + 's';
                            break;
                        case 'category':
                            queue_name = 'categories';
                            break;
                    }
                }

                window.location = encodeURI("/" + this.current_item_type + "/" + item_type + '?queue='+queue_name + '&id='+item_id);
            },
            itemVisible (item) {
                let currentName = item.name.toLowerCase();
                let currentInput = this.inputValue.toLowerCase();
                return currentName.includes(currentInput);
            },
            async getList() {
                this.grouped_itemList = {
                    loading: [
                        {
                            id: -1,
                            name: 'Loading...',
                            type: 'loading'
                        }
                    ]
                };
                if (this.inputValue !== '') {

                    let params = {
                        keyword: this.inputValue,
                        current_item_type: this.current_item_type
                    };

                    const { data } = await searchResource.list(params, null, false, 'post');

                    if (data.hasOwnProperty('meta')){
                        this.itemList = data.data;
                        this.itemMeta = data.meta;
                        if (this.itemList){
                            this.grouped_itemList = this.itemList.reduce((r, a) => {
                                r[a.type] = [...r[a.type] || [], a];
                                return r;
                            }, {});
                        }
                        if (this.itemMeta){
                            for (const itemMetaKey in this.itemMeta) {
                                if (!this.grouped_itemList.hasOwnProperty(itemMetaKey)){
                                    this.grouped_itemList[itemMetaKey] = [];
                                }
                            }
                        }
                    }else{
                        // console.log('cancelled');
                    }
                }
                this.apiLoaded = true;
            }
        },
        watch:{
            inputValue(value){
                this.getList();
            }
        }
    }
</script>

<style>
    .myDropdown-list{
        position: absolute;
        width: 100%;
        max-height: 500px;
        margin-top: 4px;
        overflow-y: auto;
        background: #ffffff;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        overflow-x: hidden !important;
    }
    .myDropdown-item{
        display: flex;
        width: 100%;
        padding: 11px 16px;
    }
    .myDropdown-item:not(.myDropdown-itemHheader, .is_result){
        cursor: pointer;
    }
    .myDropdown-item.myRounded{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }


    body[data-layout=dark] .myDropdown-item:not(.myDropdown-itemHheader, .view-all){
        background: #2a3042 !important;
    }
    body[data-layout=dark] .myDropdown-item:hover{
        background: #222736 !important;
    }
    body[data-layout=dark] .view-all, .loading-box{
        background: #222736 !important;
    }

    .myDropdown-item:hover{
        background: #edf2f7;
    }

    .myDropdown-item-flag{
        max-width: 24px;
        max-height: 18px;
        margin: auto 12px auto 0px;
    }

    .view-all{
        background: #edf2f7;
        align-items: center !important;
        justify-content: center !important;
    }

    .dropdownInput{
        transition:width 0.2s;
        -webkit-transition:width 0.2s;
        -moz-transition:width 0.2s;
        -o-transition:width 0.2s;
    }

    .focused { width: 500px; }
    .dropdownInput:not(.focused){ width: 100%; }

    .close-search{
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 8px;
        color: #fff;
        z-index: 1;
    }
    .close-search:hover{ color: #fff; }

    .myDropdown-item .location-middle span.item-label, .myDropdown-item .location-middle span.item-header{
        position: relative !important;
        line-height: unset !important;
        z-index: unset !important;
        font-size: 10px!important;
        font-weight: 300!important;
        left: unset !important;
        top: unset !important;
    }

</style>
