<template>
    <div class="intelligence-template">

        <div class="row mb-2">
            <div class="col-12">
                <div class="common_col_desc" v-if="item_info.notes && item_info.notes !== ''">
                    <p>{{ item_info.notes }}</p>
                </div>
            </div>
        </div>


        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="acq_overview_tab" role="tabpanel" :class="isActiveTab('acq_overview_tab')">
                <AcquisitionDetailsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="acq_line_items_tab" role="tabpanel" :class="isActiveTab('acq_line_items_tab')">
                <AcquisitionLineItemsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="acq_activity_tab" role="tabpanel" :class="isActiveTab('acq_activity_tab')">
                <AcquisitionActivityTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="acq_media_tab" role="tabpanel" :class="isActiveTab('acq_media_tab')">
                <MediaTab :item_info="item_info" object_type="acquisitions"/>
            </div>
            <div class="tab-pane" id="acq_defaults_tab" role="tabpanel" :class="isActiveTab('acq_defaults_tab')">
                <AcquisitionDefaultsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="acq_assets_tab" role="tabpanel" :class="isActiveTab('acq_assets_tab')">
                <AcquisitionAssetsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="acq_dispositions_tab" role="tabpanel" :class="isActiveTab('acq_dispositions_tab')">
                <AcquisitionDispositionsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="acq_transactions_tab" role="tabpanel" :class="isActiveTab('acq_transactions_tab')">
                <AcquisitionTransactionsTab :item_info="item_info"/>
            </div>
        </div>


    </div>
</template>
<script>
import AcquisitionActivityTab from "./AcquisitionActivityTab";
import AcquisitionDetailsTab from "./AcquisitionDetailsTab";
import AcquisitionAssetsTab from "./AcquisitionAssetsTab";
import AcquisitionTransactionsTab from "./AcquisitionTransactionsTab";
import AcquisitionDefaultsTab from "./AcquisitionDefaultsTab";

import AcquisitionLineItemsTab from "./AcquisitionLineItemsTab";
import AcquisitionDispositionsTab from "./AcquisitionDispositionsTab";
import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";

export default {
    name: 'CustomAcquisitionDetails',
    components: {
        MediaTab,
        AcquisitionDispositionsTab,
        AcquisitionLineItemsTab,
        AcquisitionDefaultsTab,
        AcquisitionTransactionsTab, AcquisitionAssetsTab, AcquisitionDetailsTab, AcquisitionActivityTab},
    props: ['item_info'],
    data(){
        return {
            active_tab: 'acq_activity_tab',
            tabs: {
                'acq_overview_tab': {
                    'id': "acq_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "acq_overview_tab",
                    'isActive': false
                },
                'acq_line_items_tab': {
                    'id': "acq_line_items_tab",
                    'name': "Line Items",
                    'icon': "user",
                    'type': "acq_line_items_tab",
                    'isActive': false
                },
                'acq_assets_tab': {
                    'id': "acq_assets_tab",
                    'name': "Assets",
                    'icon': "user",
                    'type': "acq_assets_tab",
                    'isActive': false
                },
                'acq_dispositions_tab': {
                    'id': "acq_dispositions_tab",
                    'name': "Dispositions",
                    'icon': "user",
                    'type': "acq_dispositions_tab",
                    'isActive': false
                },
                'acq_transactions_tab': {
                    'id': "acq_transactions_tab",
                    'name': "Transactions",
                    'icon': "user",
                    'type': "acq_transactions_tab",
                    'isActive': false
                },
                'acq_media_tab': {
                    'id': "acq_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "acq_media_tab",
                    'isActive': false
                },
                'acq_activity_tab': {
                    'id': "acq_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "acq_activity_tab",
                    'isActive': false
                },
                'acq_defaults_tab': {
                    'id': "acq_defaults_tab",
                    'name': "Defaults",
                    'icon': "user",
                    'type': "acq_defaults_tab",
                    'isActive': false
                },
            },
        }
    },
    created() {
        let acquisitions_active_tab = localStorage.getItem('acquisitions_active_tab');

        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'assets':
                    this.active_tab = 'acq_assets_tab';
                    break;
                case 'media':
                    this.active_tab = 'acq_media_tab';
                    break;
                case 'payments':
                    this.active_tab = 'acq_transactions_tab';
                    break;
            }
        }else if (acquisitions_active_tab && acquisitions_active_tab !== ''){
            this.active_tab = acquisitions_active_tab;
        }
    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('acquisitions_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
