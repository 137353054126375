<template>
    <b-modal :id="received_modal_id"
             title="Change Folder"
             title-class="font-18"
             hide-footer>

        <b-form-group label="Destination Folder" label-for="destination_folders_list">
            <b-form-select
                id="destination_folders_list"
                name="destination_folders_list"
                placeholder="Select Folder"
                v-model="selected_destination_folder"
                :options="folders_list"
                :loading="isLoading"
            >
            </b-form-select>
        </b-form-group>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="performChangeFolder">
                Move Assets
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(received_modal_id)">Cancel</b-button>
        </div>
    </b-modal>
</template>
<script>
import AssetActions from "@/mixins/assetActions.mixin";
import Resource from '@/api/resource';

const folderResource = new Resource('api/assetflow/folders');

export default {
    mixins: [AssetActions],
    name: 'ChangeAssetFolder',
    props: ['item_type', 'item_info', 'received_modal_id'],
    data(){
        return {
            selected_destination_folder: null,
            folders_list: null,
            isLoading: false,
        }
    },
    created() {
    },
    methods:{

        async getOptionsList(){
            this.listLoading = true;
            let customer_folder = [
                this.customer_item
            ];

            let queryList = {};
            if (this.item_type === 'customer' && this.current_customer){
                queryList.customer_id = this.current_customer.id;
            }else if (this.is_group && this.item_type === 'group' && this.selected_customer.id){
                queryList.customer_id = this.selected_customer.id;
            }

            queryList.flat_only = 0;
            const { data } = await folderResource.list(queryList);


            if (Object.keys(data).includes('data')){
                this.folders_list = customer_folder.concat(this.getFolderObject(data.all_folders.items, 1, this.item_type));
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },
        set_item(item){
            if (item){
                this.selected_destination_folder = item.folder_id ? item.folder_id : null;
                this.getOptionsList();
            }
        },
        performChangeFolder(){
            let message = "Changed Folder of Asset {"+this.item_info.name+"} Successfully";
            this.$bvModal.hide(this.received_modal_id);
            this.set_item_value('assets', this.item_info.id, 'folder_id', this.selected_destination_folder, message, true);
        }
    }
}
</script>
