<template>
    <div class="cm_common_table_box" v-if="children_assets.length > 0">
        <div class="cm_ordering_table_view table-responsive">
            <table>
                <thead>
                <tr>
                    <th>Asset Name</th>
                    <th>cAFID</th>
                    <th>{{ is_tv_film_asset ? 'Production' : 'Customer' }}</th>
                    <th>Qty</th>
                    <th>Location</th>
                    <th>Current Status</th>
                </tr>
                </thead>
                <tbody>

                <tr v-if="isLoading" class="text-center">
                    <td colspan="6"><b-spinner class="text-af-accent m-2"></b-spinner><br />Loading...<small>Please wait.</small></td>
                </tr>
                <tr v-else
                    v-for="(asset, key) in children_assets"
                    :key="key"
                    :class="(asset.id === (selected_asset_id ? selected_asset_id : item_info.id)) ? 'af-soft-accent current-asset' : ''">
                    <td><a :href="'/'+ item_info.current_page_type + '/assets/' + asset.id + '/view'" class="text-dark">{{ asset.name }}</a></td>
                    <td>{{ asset.cAFID }}</td>
                    <td v-html="getCustomerNameFor(asset)"></td>
                    <td>{{ asset.quantity }}</td>
                    <td><a :href="'/'+ item_info.current_page_type + '/addresses/' + asset.address_id + '/view'" v-b-tooltip :title="asset.hasOwnProperty('parent_address') && asset.parent_address ? asset.parent_address.name : ''" class="text-dark">{{ (asset.hasOwnProperty('address_location') && asset.address_location ? asset.address_location.name : '-') | titleize }}</a></td>
                    <td v-html="getAssetStatusFrom(asset)"></td>
                </tr>

                </tbody>
            </table>
        </div>
    </div>
    <div v-else>
        <div class="col-12 mb-3 text-center">
            There is no related items yet.
        </div>
    </div>

</template>
<script>
import Resource from '@/api/resource';
const AFIDChildrenResource = new Resource('api/assetflow/AFIDChildren');

export default {
    data(){
        return {
            isLoading: false,
            is_asset_passport: false,
            children_assets: []
        }
    },
    name: 'AssetRelatedSplits',
    props: {
        item_info: {},
        item_type: '', // instances Or Related Items
        selected_asset_id: null,
    },
    created() {
        if (this.cAFID_info && !this.is_asset_passport){
            this.children_assets = [this.item_info];
        }
        this.getChildrenAssets();
    },
    computed:{
        cAFID_info(){
            if (this.item_info.hasOwnProperty('cAFID_info')){
                this.is_asset_passport = false;
                return this.item_info.cAFID_info;
            }
            this.is_asset_passport = true;
            return this.item_info;
        }
    },
    methods:{
        getCustomerNameFor(asset){
            let style = '';
            let item = asset.hasOwnProperty('customer') ? asset.customer : null;
            if (!item){
                return '';
            }
            if (item.hasOwnProperty('meta') && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                style = 'color:'+item.meta.accent_colour+' !important;"';
            }
            return '<a style="' + style +  '" href="/'+ this.item_info.current_page_type + '/customers/'+item.id+'/view" class="text-dark">' + (Vue.options.filters.titleize(item.name)) + '</a>';
        },
        async getChildrenAssets(){
            this.isLoading = true;

            let queryList = {};

            if (this.is_asset_passport){
                queryList = {
                    child_id: this.item_info.id,
                    item_type: this.item_type,
                    is_asset_passport: this.is_asset_passport
                };
            }else{
                queryList = {
                    child_id: this.item_info.cAFID,
                    item_type: this.item_type
                };
            }

            const { data } = await AFIDChildrenResource.list(queryList, null, true);
            if (data.data.items.length !== 0){
                this.children_assets = data.data.items;
            }

            this.isLoading = false;
        }
    }
}
</script>
