<template>
    <div class="row">
        <div class="col-12">
            <form>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }}</h4>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="item_currency">Currency</label>
                                    <b-form-select id="item_currency"
                                                   v-model="addForm.meta.currency"
                                                   :options="currencies">
                                        <template v-slot:first>
                                            <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>

                                <div class="form-group">
                                    <label for="item_timezone">Timezone</label>
                                    <b-form-select id="item_timezone"
                                                   v-model="addForm.meta.timezone"
                                                   :options="timezones">
                                        <template v-slot:first>
                                            <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-body">
                        <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                        <a href="./" class="btn btn-secondary">Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {Currencies} from "../../../form_data/Currencies";
import {Timezones} from "../../../form_data/Timezones";

export default {
    name: 'currency_timezone',
    props:['selected_setting'],
    data(){
        return{
            currencies: Currencies,
            timezones: Timezones,
            isSendingRequest: false,
            addForm:{
                meta:{}
            },
        }
    },
    computed: {
        object_name() {
            return this.current_customer ? 'customer' : 'group';
        },
    },
    created() {
        if(this.current_customer && this.current_customer.id !== ''){
            this.fetchInfoOf();
        }
    },
    methods: {
        addNew(event) {
            if (event && !this.isSendingRequest) {
                this.isSendingRequest = true;
                let meta_keys_list = [
                    'currency',
                    'timezone'
                ];
                axios({
                    method:(this.current_customer.id !== '' ? 'put' : 'post'),
                    url:'/api/assetflow/' + this.object_name + 's' + (this.current_customer.id !== '' ? ('/'+this.current_customer.id) : ''),
                    baseURL: '/',
                    data: {
                        meta_keys_list: meta_keys_list,
                        customer_id: this.current_customer.id,
                        currency: this.addForm.meta.currency,
                        timezone: this.addForm.meta.timezone,
                    },
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true){
                        if (this.object_name === 'group'){
                            let message = "Updated Settings Successfully";
                            this.handleResponse(response, message, false, true);
                        }else{
                            let added_item = response.data.data.items;
                            if (added_item){
                                let message = "Updated Settings Successfully";
                                this.handleResponse(response, message, false, true);
                            }
                        }

                    }else{
                        console.log('Something went wrong');
                    }

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });

            }
        },
        fetchInfoOf() {
            var customer_info = this.current_customer;
            this.addForm.meta.currency = customer_info.meta.hasOwnProperty('currency') ? customer_info.meta.currency : 'gbp';
            this.addForm.meta.timezone = customer_info.meta.hasOwnProperty('timezone') ? customer_info.meta.timezone : '+00:00';
        }
    }
}
</script>

