<template>
    <div>
        <task-list
            v-show="false"
            ref="myTaskList"
            :completed_tasks_list="completed_tasks_list"
            :uncompleted_tasks_list="uncompleted_tasks_list"
            :item_info="item_info"
        ></task-list>

        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="'intelligence-action-tasks'"
            :sub_modal_id="'actions'"
            modal_title="Complete task"
            :received_asset_info="item_info"
            @on-post-adding="postAdded"
            @on-completing-task="taskCompleted"
        />

        <!-- Intelligence Template -->
        <div class="intelligence-template">
            <div class="row">
                <span class="what-we-know">Here's What We Know...</span>
            </div>
            <div class="row overview-block">
                <div class="col-12 col-lg-8">
                    <div class="row">

                        <NewCustomGenericBlock
                            v-if="is_tv_film_asset"
                            :block_title="block_contents.asset_usage.label"
                            columns_count_withclasses="6"
                            :action_required_title="block_contents.asset_usage.action_required_title"
                            :action_required="block_contents.asset_usage.action_required"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_usage"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.final_disposition_planning.label"
                            :columns_count_withclasses="is_tv_film_asset ? 6 : 12"
                            :action_required_title="block_contents.final_disposition_planning.action_required_title"
                            :action_required="block_contents.final_disposition_planning.action_required"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.final_disposition_planning"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            :block_title="block_contents.costs.label"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.costs"/>
                            </template>
                        </NewCustomGenericBlock>



                        <NewCustomGenericBlock
                            v-if="item_info.asset_meta.hasOwnProperty('custom_fields') && item_info.asset_meta.custom_fields.length !== 0"
                            block_title="Custom Fields"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <div class="row">
                                    <div
                                        v-for="(custom_field, key) in item_info.asset_meta.custom_fields"
                                        class="col-6" :class="customFieldsClass()">
                                        <div class="cm_list_inner_common">
                                            <span class="cm_list_inner_common_label">{{ custom_field }}</span>
                                            <p>
                                                <i class=""></i> <span class="cm_list_inner_common_value">{{ item_info.asset_meta.hasOwnProperty(custom_field) ? item_info.asset_meta[custom_field] : '' }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </NewCustomGenericBlock>




                        <NewCustomGenericBlock
                            :block_title="block_contents.acquisition.label"
                            columns_count_withclasses="12"
                            :action_required_title="block_contents.acquisition.action_required_title"
                            :action_required="block_contents.acquisition.action_required"
                        >
                            <template v-slot:all_content>
                                <AcquisitionAllBox :my_asset="item_info" :item_type="item_info.current_page_type" item_columns="col-md-3"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            v-if="item_info.status === 'disposed'"
                            :block_title="block_contents.disposition.label"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.disposition"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.afid_properties.label"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.afid_properties"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            :block_title="block_contents.asset_flow.label"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_flow"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="'Related Items ' + (item_info.pAFID ? '- '+item_info.pAFID + ' ' : '') + '(Splits)'"
                            box_colour_v="-"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <asset-related-splits :item_info="item_info" item_type="related_items"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            v-if="item_info.hasOwnProperty('asset_events')"
                            block_title="Planned Events | Date"
                            box_colour_v="-"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <div v-if="item_info.asset_events.length === 0" class="card-row">
                                    <span class="no-tasks-text">There are currently no tasks planned. <a href="#">Add a task Now</a></span>
                                </div>
                                <div v-else class="card-row"
                                     v-for="(asset_event, key) in item_info.asset_events"
                                     :key="key">
                                    <div class="col-md-6 col-xl-2">
                                        <span class="mr-1 round-color " :class="(asset_event.status === 1 ? 'green' : 'yellow') + '-round'"></span> {{ asset_event.name }}
                                    </div>
                                    <div class="col-md-6 col-xl-4">
                                        ({{ asset_event.start_date | moment('MMMM Do YYYY')  }} - {{ asset_event.finish_date | moment('MMMM Do YYYY') }})
                                    </div>
                                    <div class="col-md-6 col-xl-2">
                                        <p class="tasks-number-text mb-0" v-if="asset_event.hasOwnProperty('event_tasks')"> {{ asset_event.event_tasks.length + ' Task' + (asset_event.event_tasks.length === 1 ? '' : 's') }} </p>
                                    </div>
                                    <div v-if="asset_event.event_tasks.length !== 0" class="col-md-6 col-xl-4">
                                        <simplebar>
                                            <ul class="verti-timeline list-unstyled">
                                                <li
                                                    class="event-list"
                                                    v-for="(event_task, event_task_key) in asset_event.event_tasks"
                                                    :key="event_task_key"
                                                >
                                                    <div class="event-timeline-dot">
                                                        <span class="mr-1 round-color " :class="(event_task.status === 1 ? 'green' : 'yellow') + '-round'"></span>
                                                    </div>
                                                    <div class="media">
                                                        <div class="me-3">
                                                            <h5 v-if="event_task.status === 1" class="font-size-14" v-b-tooltip title="Completed At">
                                                                {{ event_task.completed_at | moment('MM-DD') }}
                                                                <i class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                                            </h5>
                                                            <h5 v-else class="font-size-14" v-b-tooltip title="Created At">
                                                                {{ event_task.created_at | moment('MM-DD') }}
                                                                <i class="bx bx-left-arrow-alt font-size-16 text-info align-middle ms-2"></i>
                                                            </h5>
                                                        </div>
                                                        <div class="media-body">
                                                            <div>{{ event_task.name }}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </simplebar>

                                    </div>
                                </div>
                            </template>
                        </NewCustomGenericBlock>

                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row">

                        <NewCustomGenericBlock
                            :block_title="item_info.name"
                            :block_title_icon="item_info.ownership_type === 1 ? 'bx bx-cube-alt' : 'bx bx-time'"
                            box_colour_v="-"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <asset-right-box :item_info="item_info" unique_id="asset-intelligence"/>
                            </template>
                        </NewCustomGenericBlock>
                        <NewCustomGenericBlock
                            :block_title="block_contents.liability.label"
                            :box_colour_v="'common_sub_col_bg color_v_accent ' + (item_info.ownership_status === 1 ? 'ownership-status-current ownership-status-current-bg' : 'ownership-status-ended ownership-status-ended-bg')"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.liability"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            :block_title="block_contents.structure.label"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.structure"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.assignments.label"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.assignments"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.current_location.label"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.current_location"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.activity.label"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.activity"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.marketplace.label"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.marketplace"/>
                            </template>
                        </NewCustomGenericBlock>

                    </div>

                </div>
            </div>
        </div>

        <!-- Intelligance Template Close-->
    </div>

</template>

<script>

import AssetRightBox from "./AssetRightBox";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import simplebar from "simplebar-vue";
import TaskList from "./TaskList";
import AssetLocationBlock from "./AssetBlocks/AssetLocationBlock";
import AfidPropertiesBlock from "./AfidPropertiesBlock";
import AssetAssetflowBlock from "./AssetAssetflowBlock";
import AssetRelatedSplits from "./AssetRelatedSplits";
import NewCustomGenericBlock from "./NewCustomGenericBlock";
import GenericTasksForm from "./GenericTasksForm";
import AssetTasks from "@/mixins/AssetTasks.mixin";
import {AcquisitionOptions} from "@/views/assetflow/form_data/AcquisitionOptions";
import CustomGenericBlockContent from "./CustomGenericBlockContent";
import CustomGenericBlock from "./CustomGenericBlock";
import AcquisitionAllBox from "../../../../assets/subItems/AssetLayouts/AcquisitionAllBox";


export default {
    mixins:[AssetTabsShared, AssetTasks],
    name: "IntelligenceTab",
    components: {
        AcquisitionAllBox,
        CustomGenericBlock,
        CustomGenericBlockContent,
        GenericTasksForm,
        NewCustomGenericBlock,
        AssetRelatedSplits,
        AssetAssetflowBlock,
        AfidPropertiesBlock,
        AssetLocationBlock,
        TaskList,
        AssetRightBox,
        simplebar
    },
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            completedTasksTotal:0,
            completed_tasks_list: [],
            uncompleted_tasks_list: [],
            sustainability_type: '',
            block_contents: {
                asset_usage:{
                    label: 'Film & TV',
                    action_required_title: 'Set Usage',
                    action_name: 'Film & TV',
                    has_action: true,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
                final_disposition_planning:{
                    label: 'Final Disposition Planning',
                    action_required_title: 'Fill FDP',
                    action_name: 'Final Disposition Planning',
                    has_action: true,
                    action_required: function () {},
                    items_class: 'col-md-3',
                    info_list: []
                },
                costs:{
                    label: 'Costs',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                acquisition:{
                    label: 'Acquisition',
                    action_required_title: 'Fill Acquisition',
                    action_name: 'Fill Acquisition',
                    has_action: true,
                    action_required: function () {},
                    items_class: 'col-md-3',
                    info_list: []
                },
                afid_properties:{
                    label: 'AFID Properties',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-3',
                    info_list: []
                },
                asset_flow:{
                    label: 'Asset Flow',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-3',
                    info_list: []
                },
                structure:{
                    label: 'Structure',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                },
                current_location:{
                    label: 'Current Location',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: ['col-md-12', 'col-md-6', 'col-md-6'],
                    info_list: []
                },
                marketplace:{
                    label: 'Marketplace',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                activity:{
                    label: 'Activity',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                },
                disposition:{
                    label: 'Disposition',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                assignments:{
                    label: 'Assignments',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                },
                liability:{
                    label: 'Ownership',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                }
            }
        }
    },
    created() {
        let item_info = this.item_info;
        let used_functions = {
            getAcquisitionUsing: this.getAcquisitionUsing,
            getAcquisitionType: this.getAcquisitionType,
            getCurrencyByValue: this.getCurrencyByValue,
            toUserCurrencyValue: this.toUserCurrencyValue,
            getTaskFor: this.getTaskFor,
            completeTask: this.completeTask,
            post_meta_keys: this.post_meta_keys,
            completedTasksTotal: this.getCompletedTasksTotal,
            getCondition: this.getCondition,
            getDispositionType: this.getDispositionType,
            getSustainabilityClassFor: this.getSustainabilityClassFor,
            getSustainabilityActionFor: this.getSustainabilityActionFor,
        }

        // final_disposition_planning
        let final_disposition_planning_block = this.block_contents.final_disposition_planning;
        this.block_contents.final_disposition_planning.action_required = function () {
            let event_task = used_functions.getTaskFor(final_disposition_planning_block.action_name);
            if (event_task){
                used_functions.completeTask(event_task, 'intelligence-action-tasks');
            }
        };
        this.block_contents.final_disposition_planning.info_list = [
            {
                label: 'Plan',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return used_functions.getSustainabilityActionFor(item_info, true);
                }
            },
            {
                label: 'Status',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    let status_class = (item_info.fdp_status === 1 ? 'active confirmed-fdp-action' : 'planned waiting-fdp-action');
                    let status_text = (item_info.fdp_status === 1 ? 'Confirmed' : 'Waiting');
                    return '<i class="font-size-18 bx bxs-circle status-icon status-icon-'+status_class+'"></i> ' + status_text;
                }
            },
            {
                label: 'Updated At',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('fdp_updated_at') && item_info.fdp_updated_at;
                },
                info_value : function () {
                    return Vue.options.filters.moment(item_info.fdp_updated_at, 'MMMM Do YYYY');
                }
            },
            {
                label: 'Updated By',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('fdp_updated_by');
                },
                info_value : function () {
                    if (item_info.fdp_updated_by !== 0 && item_info.hasOwnProperty('fdp_updated_by_user')){
                        let updated_by_link = '/' + item_info.current_page_type + '/users/'+item_info.fdp_updated_by+'/view';
                        return '<i class=""></i> <a href="' + updated_by_link + '" class="text-dark">' + Vue.options.filters.titleize(item_info.fdp_updated_by_user.name) + '</a>';
                    }else{
                        return '<span>AF Intelligence</span>'
                    }

                }
            },
        ];


        let empty_cost = used_functions.getCurrencyByValue(item_info.used_currency).icon + '0.00';
        this.block_contents.costs.info_list = [
            {
                label: 'QTY',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.quantity;
                }
            },
            {
                label: 'Cost For 1',
                icon: '',
                is_html_value: true,
                block_class: 'totals-net-gross',
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    let html = '';
                    if(item_info.net_unit && item_info.used_currency){
                        html += '<span class="total-net cost-per-one">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.net_unit).toFixed(2) + '</span>';
                    }else if((item_info.net_total / item_info.quantity) && item_info.used_currency){
                        html += '<span class="total-net cost-per-one">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + ((item_info.net_total / item_info.quantity).toFixed(2)) + '</span>';
                    }else{
                        html += '<span class="total-net cost-per-one">' + empty_cost + '</span>';
                    }
                    if((item_info.value) && item_info.used_currency){
                        html += '<span class="total-gross">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.value).toFixed(2) + '</span>';
                    }else{
                        html += '<span class="total-gross">' + empty_cost + '</span>';
                    }
                    return html;
                }
            },
            {
                label: ('Cost For ' + item_info.quantity),
                icon: '',
                is_html_value: true,
                block_class: 'totals-net-gross',
                info_condition: function () {
                    return item_info.total_value;
                },
                info_value : function () {
                    let html = '';
                    if(item_info.net_total && item_info.used_currency){
                        html += '<span class="total-net cost-per-one">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.net_total).toFixed(2) + '</span>';
                    }else{
                        html += '<span class="total-net cost-per-one">' + empty_cost + '</span>';
                    }
                    if((item_info.total_value) && item_info.used_currency){
                        html += '<span class="total-gross">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.total_value).toFixed(2) + '</span>';
                    }else{
                        html += '<span class="total-gross">' + empty_cost + '</span>';
                    }
                    return html;
                }
            },
        ];

        // asset_usage
        let asset_usage_block = this.block_contents.asset_usage;
        this.block_contents.asset_usage.label = (this.current_customer ? this.current_customer.name : 'Asset') + ' Usage';
        this.block_contents.asset_usage.action_required = function () {
            let event_task = used_functions.getTaskFor(asset_usage_block.action_name);
            if (event_task){
                used_functions.completeTask(event_task, 'intelligence-action-tasks');
            }
        };
        this.block_contents.asset_usage.info_list = [
            {
                label: 'Usage',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return used_functions.post_meta_keys['asset_usage'].cast_value(item_info, 'asset_usage');
                }
            },
            {
                label: 'Is Hero',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return used_functions.post_meta_keys['is_hero'].cast_value(item_info, 'is_hero');
                }
            },
            {
                label: 'Is Asset',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return used_functions.post_meta_keys['is_asset'].cast_value(item_info, 'is_asset');
                }
            },
        ];

        // acquisition
        let acquisition_block = this.block_contents.acquisition;
        if (this.item_info.hasOwnProperty('acquisition_id') && this.item_info.acquisition_id !== null){
            this.block_contents.acquisition.has_action = false;
            this.block_contents.acquisition.action_required_title = '';
        }else{
            this.block_contents.acquisition.action_required = function () {
                let acquisition_task = used_functions.getTaskFor(acquisition_block.action_name);
                if (acquisition_task){
                    used_functions.completeTask(acquisition_task, 'intelligence-action-tasks');
                }
            };
        }
        this.block_contents.acquisition.info_list = [
            {
                label: 'Acquired',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('acquired_date') && item_info.acquired_date;
                },
                info_value : function () {
                    return Vue.options.filters.moment(item_info.acquired_date, 'MMMM Do YYYY') + ' - ' + Vue.options.filters.fromNow(item_info.acquired_date);
                }
            },
            {
                label: 'Method',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.asset_meta.hasOwnProperty('acquired_using');
                },
                info_value : function () {
                    return used_functions.getAcquisitionUsing();
                }
            },
            {
                label: 'Type',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.asset_meta.hasOwnProperty('acquisition_type');
                },
                info_value : function () {
                    return used_functions.getAcquisitionType();
                }
            },
            {
                label: 'Reference',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('internal_reference') && item_info.internal_reference;
                },
                info_value : function () {
                    return item_info.internal_reference;
                }
            },

            {
                label: 'Supplier',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('supplier_info') && item_info.supplier_info;
                },
                info_value : function () {
                    let supplier_link = '/' + item_info.current_page_type + '/suppliers/'+item_info.supplier_info.id+'/view';
                    return '<i class=""></i> <a href="' + supplier_link + '" class="text-dark">' + item_info.supplier_info.name + '</a>';
                }
            },
            {
                label: 'Qty at Acquisition',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('quantity') && item_info.quantity;
                },
                info_value : function () {
                    return item_info.quantity;
                }
            },
            {
                label: 'Currency',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('used_currency') && item_info.used_currency;
                },
                info_value : function () {
                    return used_functions.getCurrencyByValue(item_info.used_currency, true);
                }
            },
            {
                label: 'Total Cost',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('total_value') && item_info.total_value;
                },
                tooltip_title : function () {
                    return item_info.total_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.used_currency, item_info.total_value);
                },
                info_value : function () {
                    let currency_icon = used_functions.getCurrencyByValue(item_info.used_currency).icon;
                    let total_value = item_info.total_value === '' ? '-' : item_info.total_value;
                    return currency_icon + ' ' + total_value;
                }
            },
        ];

        // AFID Properties
        let cAFID_info = item_info.cAFID_info;
        if (cAFID_info){
            let preferred_unit = ' <span class="used_unit">' + (cAFID_info.preferred_unit ? cAFID_info.preferred_unit : 'm') + '</span>';
            // afid_properties
            this.block_contents.afid_properties.info_list = [
                {
                    label: 'Category',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('parent_category') && cAFID_info.parent_category;
                    },
                    info_value : function () {
                        return cAFID_info.parent_category ? cAFID_info.parent_category.name : '-';
                    }
                },
                {
                    label: 'Colours',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return cAFID_info.colours_info && cAFID_info.colours_info.length > 0;
                    },
                    info_value : function () {
                        let colours_info = cAFID_info.colours_info;
                        if (colours_info){
                            let html = '<div class="cm_list_inner_common_value">';
                            if (colours_info.length > 0){
                                for (const itemElementKey in colours_info) {
                                    let colour = colours_info[itemElementKey];
                                    // let colour_html = '<div class="cm-color-round" style="background-color:' + colour.hex + '; color:white" title="' + colour.name + '"><i></i></span>';
                                    // html += '<li><div class="st-color-box"><label>' + colour_html +'</label></div></li>';
                                    html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                                }
                            }else{
                                html += '-';
                            }
                            html += '</div>';
                            return html;
                        }
                        return '-';
                    }
                },
                {
                    label: 'Composition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('composition_info') && cAFID_info.composition_info;
                    },
                    info_value : function () {
                        return cAFID_info.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ');
                    }
                },
                {
                    label: 'Sustainability',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return used_functions.completedTasksTotal();
                    }
                },
                {
                    label: 'Waste Stream',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('waste_stream_type') && [0,1].includes(cAFID_info.waste_stream_type);
                    },
                    info_value : function () {
                        return cAFID_info.waste_stream_type === 0 ? 'Product' : 'Material';
                    }
                },
                {
                    label: 'Product Waste Stream',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('waste_stream_info') && cAFID_info.waste_stream_info;
                    },
                    info_value : function () {
                        return cAFID_info.waste_stream_info.name;
                    }
                },
                {
                    label: 'Height',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('height') && item_info.height;
                    },
                    info_value : function () {
                        return cAFID_info.height + preferred_unit;
                    }
                },
                {
                    label: 'Depth',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('depth') && item_info.depth;
                    },
                    info_value : function () {
                        return cAFID_info.depth + preferred_unit;
                    }
                },
                {
                    label: 'Width',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('width') && item_info.width;
                    },
                    info_value : function () {
                        return cAFID_info.width + preferred_unit;
                    }
                },
                {
                    label: 'Area',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('area') && item_info.area;
                    },
                    info_value : function () {
                        return cAFID_info.area + ' <span class="used_unit">m<sup>2</sup></span>';
                    }
                },
                {
                    label: 'Volume',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('volume') && item_info.volume;
                    },
                    info_value : function () {
                        return cAFID_info.volume + ' <span class="used_unit">m<sup>3</sup></span>';
                    }
                },
                {
                    label: 'Condition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return cAFID_info.hasOwnProperty('condition') && cAFID_info.condition;
                    },
                    info_value : function () {
                        return used_functions.getCondition();
                    }
                },
            ];
        }

        // asset_flow
        this.block_contents.asset_flow.info_list = [
            {
                label: 'No of Assignments',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.assignments_count;
                }
            },
            {
                label: 'First Acquired',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('first_acquired_asset') && item_info.first_acquired_asset.hasOwnProperty('acquired_date') && item_info.first_acquired_asset.acquired_date;
                },
                info_value : function () {
                    return Vue.options.filters.moment(item_info.first_acquired_asset.acquired_date, 'MMMM Do YYYY');
                }
            },
            {
                label: 'Age',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('first_acquired_asset') && item_info.first_acquired_asset.hasOwnProperty('asset_age') && item_info.first_acquired_asset.asset_age;
                },
                info_value : function () {
                    return item_info.first_acquired_asset.asset_age;
                }
            },
            {
                label: 'Asset Flow',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('first_acquired_asset') && item_info.first_acquired_asset;
                },
                info_value : function () {
                    let first_acquired_asset_link = '/' + item_info.current_page_type + '/assets/' + item_info.first_acquired_asset.id+'/view';
                    return '<i class=""></i> <a href="' + first_acquired_asset_link + '" class="text-dark">View</a>';
                }
            },
        ];

        this.block_contents.structure.info_list = [
            {
                label: 'Customer Name',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    let link_style = item_info.hasOwnProperty('customer_accent_colour') ? ('color: ' + item_info.customer_accent_colour + ' !important;') : '';
                    let customer_link = '/' + item_info.current_page_type + '/customers/'+item_info.customer_id+'/view';
                    return '<i class=""></i> <a href="' + customer_link + '" style="' + link_style +'">' + item_info.customer_name + '</a>';
                }
            },
            {
                label: 'Liability',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return Vue.options.filters.titleize(item_info.asset_liability);
                }
            },
            {
                label: 'Folder',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('parent_folder') && item_info.parent_folder;
                },
                info_value : function () {
                    return item_info.parent_folder.name;
                }
            },
            {
                label: 'Category',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('parent_category') && item_info.parent_category;
                },
                info_value : function () {
                    return item_info.parent_category.name;
                }
            },
        ];
        if (this.is_tv_film_asset){
            this.block_contents.structure.info_list[0].label = 'Production Name';
        }


        this.block_contents.current_location.info_list = [
            {
                label: 'Address',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.parent_address && item_info.parent_address.name;
                },
                info_value : function () {
                    let address_link = '/' + item_info.current_page_type + '/addresses/'+item_info.parent_address.id+'/view';
                    return '<i class=""></i> <a href="' + address_link + '" class="text-dark">' + Vue.options.filters.titleize(item_info.parent_address.name) + '</a>';
                }
            },
            {
                label: 'Latitude',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.address_location_latitude;
                },
                info_value : function () {
                    return item_info.address_location_latitude;
                }
            },
            {
                label: 'Longitude',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.address_location_longitude;
                },
                info_value : function () {
                    return item_info.address_location_longitude;
                }
            },
        ];

        this.block_contents.marketplace.info_list = [
            {
                label: 'Current Status',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return false;
                },
                info_value : function () {
                    return '';
                }
            },
            {
                label: 'List Date',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return false;
                },
                info_value : function () {
                    return '';
                }
            },
            {
                label: 'Duration',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return false;
                },
                info_value : function () {
                    return '';
                }
            },
        ];


        this.block_contents.activity.info_list = [
            {
                label: 'Post Updates',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('asset_posts') && item_info.asset_posts;
                },
                info_value : function () {
                    return item_info.asset_posts.length;
                }
            },
            {
                label: 'Total Images',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('asset_images') && item_info.asset_images;
                },
                info_value : function () {
                    return item_info.asset_images.length;
                }
            },
            {
                label: '- Private',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return false;
                },
                info_value : function () {
                    return '';
                }
            },
            {
                label: '- Public',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return false;
                },
                info_value : function () {
                    return '';
                }
            },
        ];

        if (item_info.hasOwnProperty('disposition_info') && item_info.disposition_info){
            let disposition_info = item_info.disposition_info;
            this.block_contents.disposition.info_list = [
                {
                    label: 'Date',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('disposition_date') && disposition_info.disposition_date;
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(disposition_info.disposition_date, 'MMMM Do YYYY') + ' - ' + Vue.options.filters.fromNow(disposition_info.disposition_date);
                    }
                },
                {
                    label: 'Disposition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('disposition_type_info') && disposition_info.disposition_type_info;
                    },
                    info_value : function () {
                        return disposition_info.disposition_type_info.name;
                    }
                },
                {
                    label: 'Recipient',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('recipient_type') && disposition_info.recipient_type !== '';
                    },
                    info_value : function () {
                        let recipient_link = '/' + item_info.current_page_type + '/' + disposition_info.recipient_type + '/' + disposition_info.recipient_id + '/view';
                        return '<a href="' + recipient_link + '" class="text-dark">' + disposition_info.recipient_name + '</a>';
                    }
                },
                {
                    label: 'Currency',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.currency;
                    },
                    info_value : function () {
                        return used_functions.getCurrencyByValue(disposition_info.currency, true);
                    }
                },
                {
                    label: 'Pro Rata Per Unit',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('value') && disposition_info.value && disposition_info.value !== 0;
                    },
                    tooltip_title : function () {
                        return disposition_info.value === '' ? '' : used_functions.toUserCurrencyValue(item_info.used_currency, disposition_info.value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.used_currency).icon;
                        let total_value = disposition_info.value === '' ? '-' : disposition_info.value;
                        return currency_icon + ' ' + total_value;
                    }
                },
                {
                    label: 'Method',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('disposition_type_info') && disposition_info.disposition_type_info;
                    },
                    info_value : function () {
                        return disposition_info.disposition_type_info.name;
                    }
                },
                {
                    label: 'Reference',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('reference') && disposition_info.reference;
                    },
                    info_value : function () {
                        return disposition_info.reference;
                    }
                },
                {
                    label: 'Qty at Disposition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('quantity') && disposition_info.quantity;
                    },
                    info_value : function () {
                        return disposition_info.quantity;
                    }
                },
                {
                    label: 'Pro Rata Total',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('total_value') && disposition_info.total_value && disposition_info.total_value !== 0;
                    },
                    tooltip_title : function () {
                        return disposition_info.total_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.used_currency, disposition_info.total_value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.used_currency).icon;
                        let total_value = disposition_info.total_value === '' ? '-' : disposition_info.total_value;
                        return currency_icon + ' ' + total_value;
                    }
                },
            ];
        }


        this.block_contents.liability.info_list = [
            {
                label: 'Ownership Type',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.ownership_type === 1 ? 'Owned' : 'Temporary';
                }
            },
            {
                label: 'Ownership Status',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.ownership_status === 1 ? 'Current' : 'Ended';
                }
            },
            {
                label: (item_info.ownership_status === 1 ? 'Acquired Date' : 'Ended Date'),
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    let age_key = item_info.ownership_status === 1 ? 'acquired_date' : 'ownership_end_date';
                    return item_info.hasOwnProperty(age_key) && item_info[age_key] && item_info[age_key] !== '';
                },
                info_value : function () {
                    let age_key = item_info.ownership_status === 1 ? 'acquired_date' : 'ownership_end_date';
                    return Vue.options.filters.moment(item_info[age_key], 'MMMM Do YYYY');
                }
            },
            {
                label: (item_info.ownership_status === 1 ? 'Age' : 'Lifespan'),
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    let age_key = item_info.ownership_status === 1 ? 'asset_age' : 'ownership_age';
                    return item_info.hasOwnProperty(age_key) && item_info[age_key] && item_info[age_key] !== '';
                },
                info_value : function () {
                    return item_info.ownership_status === 1 ? item_info.asset_age : item_info.ownership_age;
                }
            },
        ];
        this.block_contents.assignments.info_list = [
            {
                label: 'Address',
                icon: 'bx bx-map',
                is_html_value: true,
                info_condition: function () {
                    return item_info.parent_address && item_info.parent_address.name;
                },
                info_value : function () {
                    let address_link = '/' + item_info.current_page_type + '/addresses/'+item_info.parent_address.id+'/view';
                    return '<i class="bx bx-map"></i> <a href="' + address_link + '" class="text-dark">' + Vue.options.filters.titleize(item_info.parent_address.name) + '</a>';
                }
            },
            {
                label: 'Location',
                icon: 'bx bx-map',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('address_location_name');
                },
                info_value : function () {
                    let address_link = '/' + item_info.current_page_type + '/addresses/'+item_info.parent_address.id+'/view';
                    return '<i class="bx bx-map"></i> <a href="' + address_link + '" class="text-dark">' + Vue.options.filters.titleize(item_info.address_location_name) + '</a>';
                }
            },
            {
                label: 'Department',
                icon: 'bx bx-sitemap',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('parent_department_name');
                },
                info_value : function () {
                    let supplier_link = '/' + item_info.current_page_type + '/departments/' + item_info.parent_department_id+'/view';
                    return '<i class="bx bx-sitemap"></i> <a href="' + supplier_link + '" class="text-dark">' + item_info.parent_department_name + '</a>';
                }
            },
            {
                label: 'User',
                icon: 'bx bx-user-circle',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('contact_name');
                },
                info_value : function () {
                    let user_link = '/' + item_info.current_page_type + '/users/'+item_info.contact_id+'/view';
                    return '<i class="bx bx-user-circle"></i> <a href="' + user_link + '" class="text-dark">' + Vue.options.filters.titleize(item_info.contact_name) + '</a>';
                }
            },
        ];

        switch (this.item_info.status){
            case 'planned':
                this.sustainability_type = 'planning';
                break;
            case 'acquired':
            case 'active':
            case 'in_storage':
                this.sustainability_type = 'readiness';
                let location_task, po_task, volume_task, cost_task, final_disposition_planning_task;
                let staticScore = 100/5;

                if (this.item_info.location_id){
                    let {completed_at, completed_by_user} = this.getUserAndDate([3, 2]);
                    location_task = {
                        title: 'Location Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(location_task);
                }else{
                    location_task = {
                        title: 'Location Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(location_task);
                }

                if (this.item_info.po_item_info){
                    let {completed_at, completed_by_user} = this.getUserAndDate([4, 2]);

                    po_task = {
                        title: 'Purchase Order Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(po_task);
                }else{
                    po_task = {
                        title: 'Purchase Order Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(po_task);
                }

                if (this.item_info.volume){
                    let {completed_at, completed_by_user} = this.getUserAndDate([5, 2]);
                    volume_task = {
                        title: 'Volume Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(volume_task);
                }else{
                    volume_task = {
                        title: 'Volume Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(volume_task);
                }

                if (this.item_info.total_value){
                    let {completed_at, completed_by_user} = this.getUserAndDate([6, 2]);
                    cost_task = {
                        title: 'Cost Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(cost_task);
                }else{
                    cost_task = {
                        title: 'Cost Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(cost_task);
                }


                if (this.item_info.hasOwnProperty('final_disposition_planning') && this.item_info.final_disposition_planning){
                    let {completed_at, completed_by_user} = this.getUserAndDate([7, 2]);
                    final_disposition_planning_task = {
                        title: 'Final Disposition Planning Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(final_disposition_planning_task);
                }else{
                    final_disposition_planning_task = {
                        title: 'Final Disposition Planning Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(final_disposition_planning_task);
                }

                break;
            case 'disposed':
                this.sustainability_type = 'reporting';
                break;
        }
    },
    watch:{
        // getCompletedTasksTotal:{
        //     deep: true,
        //     handler: function (newValue) {
        //     }
        // },
    },
    mounted(){
        this.completedTasksTotal = this.$refs['myTaskList'].getTotalScore();
    },
    methods:{
        getCondition(){
            let conditions_list = {
                '1': 'New',
                '2': 'Excellent',
                '3': 'Good',
                '4': 'Attention Required'
            };
            return conditions_list[this.item_info.cAFID_info.condition];
        },
        getCompletedTasksTotal(){
            return this.completedTasksTotal;
        },
        getTaskFor(task_name){
            if (this.item_info.event_tasks){
                return this.getObjectOf(task_name, this.item_info.event_tasks, 'name');
            }
            return null;
        },
        getAcquisitionType(){
            let acquisition_type = this.getObjectOf(this.item_info.asset_meta.acquisition_type, this.acquisition_types);
            if (acquisition_type){
                return acquisition_type.name;
            }
            return '-';
        },
        getAcquisitionUsing(){
            let acquired_using = this.getObjectOf(this.item_info.asset_meta.acquired_using, AcquisitionOptions);
            if (acquired_using){
                return acquired_using.name;
            }
            return '-';
        },
        getDispositionType(){
            return this.item_info.hasOwnProperty('dispositions_info') && this.item_info.dispositions_info ? this.item_info.dispositions_info[0].name : '';
        },
        getUserAndDate(action_types_filters = []) {
            var completed_at, completed_by_user;
            if (this.item_info.asset_posts) {
                var location_posts;
                for (const key in action_types_filters) {
                    let action_type = action_types_filters[key];
                    if (!location_posts || location_posts.length === 0) {
                        location_posts = this.item_info.asset_posts.filter(function (e) {
                            return e.action_type === action_type;
                        });
                    }else{
                        continue;
                    }
                }

                if (location_posts.length > 0) {
                    completed_at = location_posts[0].created_at;
                    completed_by_user = location_posts[0].user;
                }
            }
            return {completed_at, completed_by_user};
        },
    }
}
</script>
