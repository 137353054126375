<template>
    <div>
        <div v-if="addForm.hasOwnProperty('asset_meta')">
            <div
                v-for="(field, index) in addForm.asset_meta[required_field_name]"
                :key="index"
            >
                <template v-if="required_field_name === 'custom_fields'">
                    <div class="row">
                        <div class="mb-3" :class="'col-lg-' + (static_list ? '6' : '4')">
                            <label for="field_name">Field Name</label>
                            <input
                                id="field_name"
                                v-model="field.field_name"
                                type="text"
                                name="untyped-input"
                                class="form-control"
                            />
                        </div>

                        <div class="mb-3 col-lg-6">
                            <label for="field_value">Field Value</label>
                            <input
                                id="field_value"
                                v-model="field.field_value"
                                type="text"
                                class="form-control"
                            />
                        </div>

                        <div class="col-lg-2 align-self-center">
                            <div class="d-grid">
                                <input
                                    v-if="!static_list"
                                    type="button"
                                    class="btn btn-danger btn-block"
                                    value="Delete"
                                    @click="deleteRow(index)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="required_field_name === 'asset_links'">
                    <div class="row">
                        <div class="mb-3" :class="'col-lg-' + (static_list ? '6' : '4')">
                            <label for="field_name">Title</label>
                            <input
                                id="field_name"
                                v-model="field.field_name"
                                type="text"
                                name="untyped-input"
                                class="form-control"
                            />
                        </div>

                        <div class="mb-3 col-lg-6">
                            <label for="field_value">URL</label>
                            <input
                                id="field_value"
                                v-model="field.field_value"
                                type="text"
                                class="form-control"
                            />
                        </div>

                        <div class="col-lg-2 align-self-center">
                            <div class="d-grid">
                                <input
                                    v-if="!static_list"
                                    type="button"
                                    class="btn btn-danger btn-block"
                                    value="Delete"
                                    @click="deleteRow(index)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="required_field_name === 'asset_files'">
                    <div class="row">
                        <div class="mb-3" :class="'col-lg-' + (static_list ? '6' : '4')">
                            <label for="field_name">File Name</label>
                            <input
                                id="field_name"
                                v-model="field.field_name"
                                type="text"
                                name="untyped-input"
                                class="form-control"
                            />
                        </div>

                        <div class="mb-3 col-lg-6">
                            <label for="field_value">File</label>
                            <input
                                type="file"
                                id="field_value"
                                ref="field_value"
                                class="form-control"
                                @change="onFileChange(field, index, $event)"
                            />

                        </div>

                        <div class="col-lg-2 align-self-center">
                            <div class="d-grid">
                                <input
                                    v-if="!static_list"
                                    type="button"
                                    class="btn btn-danger btn-block"
                                    value="Delete"
                                    @click="deleteRow(index)"
                                />
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </div>
        <input
            v-if="!static_list"
            type="button"
            class="btn btn-success mt-3 mt-lg-0"
            value="Add"
            @click="AddformData"
        />
    </div>
</template>
<script>
export default {
    name: 'CustomFieldsRepeater',
    props: {
        addForm: {},
        force_refresh: false,
        static_list: false,
        required_field_name: {
            type: String,
            required: false,
            default: () => "custom_fields"
        },
    },

    created() {
        if (!this.addForm.hasOwnProperty('asset_meta')){
            this.$set(this.addForm, 'asset_meta', {});
        }
        let singleItem = {field_name: "", field_value: ""};
        if (!this.addForm.asset_meta.hasOwnProperty(this.required_field_name)){
            this.addForm.asset_meta[this.required_field_name] = (this.static_list ? [] : [singleItem]);
        }else{
            if (this.addForm.asset_meta[this.required_field_name].length === 0){
                this.addForm.asset_meta[this.required_field_name] = (this.static_list ? [] : [singleItem]);
            }
        }
    },
    methods:{
        onFileChange(field, index, event) {
            let file_info = event.target.files[0];
            this.addForm.asset_meta[this.required_field_name][index].field_value = file_info;
            if (this.addForm.asset_meta[this.required_field_name][index].field_name === ''){
                this.$set(this.addForm.asset_meta[this.required_field_name][index], 'field_name', file_info.name);
            }
            if (this.force_refresh){
                this.addForm.asset_meta = Object.assign({}, this.addForm.asset_meta, {});
            }
        },
        AddformData() {
            this.addForm.asset_meta[this.required_field_name].push({field_name: "", field_value: ""});
            if (this.force_refresh){
                this.addForm.asset_meta = Object.assign({}, this.addForm.asset_meta, {});
            }
        },
        deleteRow(index) {
            this.addForm.asset_meta[this.required_field_name].splice(index, 1);
            if (this.force_refresh){
                this.addForm.asset_meta = Object.assign({}, this.addForm.asset_meta, {});
            }
        }
    }
}
</script>
