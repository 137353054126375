<template>
    <!-- Sidebar start-->
    <div class="sidebar" id="sidebar-wrapper">
        <div class="folder-wrapper">
            <div class="main-root-top">
                <h3 class="filter_option">{{ selected_filter ? selected_filter.label : '' | titleize }}</h3>
                <ul class="root-folder filters_list" style="display: none">
                    <li v-for="(filter_option, index) in required_group_options" :class="'root-top-menu' + (filter_option && filter_type === filter_option.id ? ' active' : '')" v-if="!filter_option.hasOwnProperty('is_hidden')">
                        <a @click="changeFilter(filter_option, index, $event)">
                            {{ filter_option ? filter_option.label : '-' }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="sub-root-top folder-menu active">

                <ul v-if="selected_filter.hasOwnProperty('is_all') && selected_filter.is_all">
                    <li class="mr-auto">
                        <p>The 'Main Page' is currently showing All {{ object_type | titleize }}.</p>
                        <p>Nothing to see here :)</p>
                    </li>
                </ul>
                <ul v-else-if="shall_select_customer_first">
                    <li class="mr-auto">
                        <div class="loading-spinner text-center">
                            <div class="center">
                                <i class="display-4 bx bx-info-circle"></i>
                            </div>
                            <small>Please Select a Customer First...</small>
                        </div>
                    </li>
                </ul>
                <ul v-else :class="'root-folder custom-icons-root root-' + filter_classes[filter_type+'']">
                    <li class="active filled">
                        <a class="root-heading customer-root"  @click="changeGroup(customer_item, $event)">
                            <i class="font-size-18 bx bxs-circle customer-status-icon"></i>
                            <span class="pl-1">{{customer_item.text | titleize}}</span>
                        </a>
                        <objects-sidebar-row
                            v-if="!is_listing_sidebar"
                            :items="sidebar_list"
                            :filter_type="filter_type"
                            :item_type="item_type"
                            :object_type="object_type"
                            :received_item_names="received_item_names"
                            :ul_class="'subfolder ' + filter_classes[filter_type] + '-list custom-icons-list'"
                            :is_group="is_group"
                            :is_first="true"
                            :selected_item="selected_item"
                            :selected_customer="selected_customer"
                            @on-group-changing="changeGroup"
                        />

                        <ul v-else>
                            <li class="mr-auto">
                                <div class="loading-spinner text-center">
                                    <div class="center">
                                        <b-spinner class="text-af-accent m-2"></b-spinner>
                                    </div>
                                    Loading...
                                    <br />
                                    <small>Please wait.</small>
                                </div>
                            </li>
                        </ul>

                    </li>
                </ul>
            </div>


        </div>
    </div>
    <!-- Sidebar end-->

</template>

<script>
import ObjectsSidebarRow from "./ObjectsSidebarRow";

export default {
    name: "ObjectsSidebar",
    components: {ObjectsSidebarRow},
    props: [
        'sidebar_list',
        'filter_type',
        'item_type',
        'object_type',
        'is_group',
        'selected_item',
        'selected_customer',
        'is_listing_sidebar',
        'groupBy_options',
        'received_filter_classes',
        'shall_select_customer_first',
    ],
    data() {
        return {
            received_item_names: {},
            filter_classes: {
                '0': 'statuses',
                '1': 'item_type',
                '2': 'categories',
                '3': 'tags'
            },
            customer_item:null,
            filemanager_items: null,
            hidden_sidebar: false,
            selected_filter: null,
            customer_name: null,
            af_initials: null
        };
    },
    computed: {
        required_group_options() {
            return this.groupBy_options;
        },
        selected_customer_top_filter() {
            return this.selected_customer;
        }
    },
    created() {
        if (this.groupBy_options && this.groupBy_options.length > 0){
            for (const groupByOptionsKey in this.groupBy_options) {
                let groupOption = this.groupBy_options[groupByOptionsKey];
                this.received_item_names[groupOption.id] = groupOption.label;
            }
        }

        if (this.received_filter_classes){
            this.filter_classes = this.received_filter_classes;
        }
        if (this.is_group){
            this.filter_classes = {
                '0': 'statuses',
                '1': 'item_type',
            };
        }

        this.customer_name = window.AF_NAME;

        this.af_initials = (window.AF_INITIALS ? window.AF_INITIALS : "");
        this.customer_item = {
            id:null,
            value: 0,
            text: this.customer_name,
            name: this.customer_name
        };

        if (this.is_group){
            let group_name = window.AF_GROUP_NAME;
            this.customer_item = {
                id:null,
                value: 0,
                text: group_name,
                name: group_name
            }
        }

        this.setSelectedFilter();
    },
    watch:{
        filter_type: {
            deep: true,
            handler: function(newValue, oldValue){
                this.setSelectedFilter();
            }
        },
        selected_customer_top_filter: {
            deep: true,
            handler: function(newValue, oldValue){
                if (newValue && (newValue.id !== null) && this.selected_filter && ['Tags', 'Folders', 'Categories'].includes(this.selected_filter.label)){
                    this.customer_item = {
                        id:null,
                        value: 0,
                        text: this.selected_customer.name,
                        name: this.selected_customer.name
                    }
                }
            }
        },
    },
    methods: {
        setSelectedFilter: function () {
            if (this.filter_type) {
                let filter_type = this.filter_type;
                let found_item = _.find(this.required_group_options, function (o) {
                    return o.id === filter_type;
                });
                if (found_item) {
                    this.selected_filter = found_item;
                } else {
                    this.selected_filter = this.required_group_options[0];
                }
            } else {
                this.selected_filter = this.required_group_options[0];
            }
        },
        deleteFolder(item_id='', item_type='folders', event){
            this.$emit('on-folder-deletion', item_id, item_type, event);
        },
        createTag(){
            this.$emit('on-tag-creation');
        },
        editTag(item=null, item_type='tags'){
            this.$emit('on-tag-editing', item, item_type);
        },
        addLocation(item=null){
            this.$emit('on-add-location', item);
        },
        untagAssets(item='', item_type, event){
            this.$emit('on-untag-assets', item, item_type);
        },
        createFolder(parent_id=''){
            this.$emit('on-folder-creation', parent_id);
        },
        editFolder(item=null){
            this.$emit('on-folder-editing', item);
        },
        moveFolder(item=''){
            this.$emit('on-folder-moving', item);
        },
        changeFilter(selected_item, index, event){
            event.preventDefault();
            this.selected_filter = selected_item;
            this.$emit('on-filter-changing', selected_item, 'filter');
        },
        changeGroup(selected_item){
            if (selected_item.id !== -99 || ("fetch_meta_key" in selected_item)){
                this.set_usermeta((this.object_type + '_filter_type' + (this.is_group ? '_group' : '') + '_id'), (selected_item.hasOwnProperty('value') ? selected_item.value : selected_item.id), '');
                this.$emit('on-group-changing', selected_item);
            }
        },
        moveContent(item='', event){
            this.$emit('on-content-moving', item, event);
        },
    }
}
</script>

<style scoped>

.main-root-top{
    position: relative;
}

.main-root-top ul{
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    padding: 15px;
}

.main-root-top > ul > li a:before{
    display: none;
}
.main-root-top > ul.root-folder > li{
    font-size: 20px;
    margin-bottom: 10px;

}
.main-root-top > ul.root-folder > li a{
    font-weight: 600;
}
.sub-root-top  .root-heading{
    margin-bottom: 15px;
    padding-top: 15px;
}
.sub-root-top{
    display: none;
}
.sub-root-top.active{
    display: block;
}

.root-top-menu.active a{
    color: #0D5296;
}

</style>
