<template>
    <div class="cm_list_view">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="asset_passport" asset_images_name="filtered_asset_images" :show-captiom-img="showCaptiomImg" :required_image_size="'asset_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-5">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <h3><a href="#0"><i class="bx bx-user-circle"></i> <a class="text-dark" :href="'/' + item_type + '/asset_passports/' + asset_passport.id + '/view'">{{ asset_passport.universal_asset_name !== '' ? asset_passport.universal_asset_name : asset_passport.uid }}</a></a></h3>
                            </div>
                            <div class="cm_list_view_icon">
                                <p>

                                    <a v-if="asset_passport.hasOwnProperty('ws_defined_status') && asset_passport.ws_defined_status && asset_passport.ws_defined_status !== '0'" class="assetpassport-wastestream-icon">
                                        <i class="bx bx-notepad icon-active" title="Has Waste Stream"><div></div></i>
                                    </a>
                                    <a href="#" class="assetpassport-wastestream-icon" v-else>
                                        <i class="bx bx-notepad icon-inactive" title="No Waste Stream"><div></div></i>
                                    </a>

                                    <a v-if="asset_passport.hasOwnProperty('ph_defined_status') && asset_passport.ph_defined_status && asset_passport.ph_defined_status !== '0'" class="assetpassport-physicalproperties-icon">
                                        <i class="bx bx-gift icon-active" title="Has Physical Properties"><div></div></i>
                                    </a>
                                    <a href="#" class="assetpassport-physicalproperties-icon" v-else>
                                        <i class="bx bx-gift icon-inactive" title="No Physical Properties"><div></div></i>
                                    </a>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-7">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title cm_pro_status_tag">
                                <h3><a href="#0" :class="'status-btn status-btn-active ap-status-' + asset_passport.passport_status"> {{ asset_passport.passport_status === 0 ? 'Assigned' : '-' }}</a></h3>
                            </div>
                            <div class="cm_status_ul">
                                <ul>
                                    <li>
                                        <p>Total <span class="total_box_bg">{{ asset_passport.quantity ? asset_passport.quantity : '-' }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-5">
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Passport</span>
                                            <p>
                                                <i class="bx bx-detail"></i> {{ asset_passport.uid }}
                                            </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Passport Type</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.quantity > 1 ? 'Shared' : 'Unique' }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Category</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="asset_passport.hasOwnProperty('parent_category') && asset_passport.parent_category">
                                                <a class="text-dark" :href="'/' + item_type + '/assets?queue=category&id=' + asset_passport.parent_category.id">{{ asset_passport.parent_category.name | titleize }}</a>
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage">
                                        <span>Template</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="asset_passport.hasOwnProperty('asset_template') && asset_passport.asset_template">
                                                {{ asset_passport.asset_template.name | titleize }}
                                            </span>
                                            <span v-else>-</span>
                                        </p></div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_desc" v-if="asset_passport.notes && asset_passport.notes !== ''">
                            <p>{{ asset_passport.notes }}</p>
                        </div>

                    </div>
                    <div class="col-md-6 col-xl-7 cm_order">
                        <div class="common_sub_col_bg">
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Owner</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.customer_info ? asset_passport.customer_info.name : '-' }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Contact</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.customer_info ? asset_passport.customer_info.contact : '-' }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Instances</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.instances_count }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Related</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.related_items_count }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="common_sub_col_bg">
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Condition</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ getCondition(asset_passport)}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Colours</span>
                                        <p v-html="getColours(asset_passport)"></p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Area per Unit</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.area ? (asset_passport.area) : '-' }} <span v-if="asset_passport.area">m<sup>2</sup></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Total Area</span>
                                        <p>
                                            <i class="bx bx-detail"></i> {{ asset_passport.total_area ? (asset_passport.total_area) : '-' }} <span v-if="asset_passport.total_area">m<sup>2</sup></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cm_list_last_update mt-1" v-if="asset_passport.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText()"></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">
            <div class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-none dropdown-toggle">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" style="">
                    <ul>
                        <li role="presentation">
                            <a role="menuitem" target="_self" :href="'/' + item_type + '/asset_passports/' + asset_passport.id + '/edit'" class="dropdown-item">Edit</a>
                        </li>

                        <li role="presentation">
                            <a role="menuitem" target="_self" :href="'/' + item_type + '/asset_passports/' + asset_passport.id + '/view'" class="dropdown-item">View</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/asset_passports/' + asset_passport.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import MyBCarouselSlide from "../../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import MyBCarouselImages from "../../../assets/subItems/AssetLayouts/MyBCarouselImages";
export default {
    name: 'AssetPassportListView',
    components: {MyBCarouselImages, MyBCarouselSlide},
    props:[ 'asset_passport', 'item_type', 'showCaptiomImg' ],
    methods:{
        getPostText(){
            let latest_post_update = this.asset_passport.latest_post_update;
            if (latest_post_update){
                let user = latest_post_update.user;
                if (user){
                    let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                    return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(this.asset_passport, latest_post_update) + " on " + Vue.options.filters.moment(latest_post_update.created_at , 'DD/MM/YYYY');
                }
            }

            return '';
        },
        getCondition(asset_passport){
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return conditions_list[asset_passport.condition - 1];
        },
        getColours(asset_passport){
            let colours_info = asset_passport.colours_info;
            if (colours_info){
                let html = '<div class="cm_list_inner_common_value">';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '</div>';
                return html;
            }
            return '-';

        }
    }
}
</script>
