import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";

export default {
    data(){
        return {
        }
    },
    created: function () {
    },
    methods: {

        getLocationBlock(storage_info){
            let location_info = storage_info.location_info;
            if (location_info){
                if(storage_info) {
                    if (storage_info.hasOwnProperty('current_warehouse_info') && storage_info.current_warehouse_info){
                        location_info.warehouse_info = storage_info.current_warehouse_info;
                    }
                    if (storage_info.hasOwnProperty('current_space_info') && storage_info.current_space_info){
                        location_info.space_info = storage_info.current_space_info;
                    }
                }
                return getLocationDetails(location_info);
            }
            return '-';
        },
        getLocationStatus(location_status){
            let status_list = [
                {
                    name: 'Free',
                    class: 'status-background-green',
                },
                {
                    name: 'Occupied',
                    class: 'status-background-orange',
                },
                {
                    name: 'Unavailable',
                    class: 'status-background-grey',
                },
            ];
            if (status_list[location_status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[location_status].class) + '" style="color: white!important;">' + status_list[location_status].name + '</span></div></div>';
            }
            return '';
        },
        getSpStorageStatus(status){
            let status_list = [
                {
                    name: 'Free',
                    class: 'status-background-green',
                },
                {
                    name: 'Assigned',
                    class: 'status-background-orange',
                },
                {
                    name: 'Reserved',
                    class: 'status-background-grey',
                },
                {
                    name: 'Archived',
                    class: 'status-background-red',
                },
            ];
            if (status_list[status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[status].class) + '" style="color: white!important;">' + status_list[status].name + '</span></div></div>';
            }
            return '';
        },
        goToRelocateWizard(item_id, event){
            event.preventDefault();
            window.location.href = '/' + this.item_type + '/sp_storages/'+item_id+'/relocate';
        },
        performAction(sp_storage, action, sp_storageKey){
            this.selected_object_id = sp_storage.id;
            if (action === ('delete'+this.permission_name)){
                if (sp_storage.id !== ''){
                    this.deleteItemByID(sp_storage.id, sp_storage.name, this.object_type, sp_storage)
                        .then((response) => {
                            if (response.data.data.status){
                                this.sp_storagesData.splice(sp_storageKey, 1);
                            }
                        });
                }
            }
        },
        showModal(selected_item, modal_ref, other_info={}){
            this.selected_object_id = selected_item.id;
            if (this.$refs[modal_ref]){
                if (modal_ref === 'assign-sp_storages-customer'){
                    if (this.$refs['TopFiltersBlock']){
                        this.$refs[modal_ref].setCustomersList(this.$refs['TopFiltersBlock'].customers_list);
                    }else{
                        this.$refs[modal_ref].getCustomersList();
                    }
                }

                this.$refs[modal_ref].setSelectedItem(selected_item);
                this.$bvModal.show(modal_ref);
            }
        },

    }
}
