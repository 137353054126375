export const CreatedDatesList = [
    {id: null, name: 'All Days', icon: 'mdi mdi-calendar-month'},
    {id: 0, name: 'Today', icon: 'mdi mdi-calendar-today'},
    {id: 1, name: 'Yesterday', icon: 'mdi mdi-calendar-today'},
    {id: 2, name: '2 Days Ago', icon: 'mdi mdi-numeric-2-box-outline'},
    {id: 3, name: '3 Days Ago', icon: 'mdi mdi-numeric-3-box-outline'},
    {id: 4, name: '4 Days Ago', icon: 'mdi mdi-numeric-4-box-outline'},
    {id: 5, name: '5 Days Ago', icon: 'mdi mdi-numeric-5-box-outline'},
    {id: 6, name: '6 Days Ago', icon: 'mdi mdi-numeric-6-box-outline'},
    {id: 7, name: '7 Days Ago', icon: 'mdi mdi-numeric-7-box-outline'},
    {id: 14, name: '2 Weeks Ago', icon: 'mdi mdi-calendar-week'},
    {id: 30, name: '1 Month Ago', icon: 'mdi mdi-calendar-month'},
    {id: 60, name: '2 Months Ago', icon: 'mdi mdi-calendar-month'},
    {id: 90, name: '3 Months Ago', icon: 'mdi mdi-calendar-month'},
    {id: 99, name: '3 Months+', icon: 'mdi mdi-calendar-month'},
];
