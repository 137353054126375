const FirstPath = window.location.pathname.split('/')[1];
export const SPWarehouseKeysList = {
    uid: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 war_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                'status-btn-acquired',
                'status-btn-active',
                'status-btn-disposed'
            ];
            return '<span class="button_size ' + (status_list[item.status]) + '">' + item[key] + '</span>';
        }
    },
    initials: {
        key: 'initials',
        label: "Initials",
        class: "col-lg-1 war_initials",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<div class="warehouse-top warehouse-colour" style="background-color: '+item.colour+' !important;">' + item[key] + '</div>';
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg war_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/sp_warehouses/' + item.id + '/view">' + item.name + '</a>';
        }
    },
    spaces_count: {
        key: 'spaces_count',
        label: "Spaces",
        class: "col-lg-1 war_spaces_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let spaces_count = '-';
            if (item.hasOwnProperty('spaces_count')){
                spaces_count = item.spaces_count;
            }
            return spaces_count;
        }
    },
    locations_count: {
        key: 'locations_count',
        label: "Locations",
        class: "col-lg-1 war_locations_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let locations_count = '-';
            if (item.hasOwnProperty('locations_count')){
                locations_count = item.locations_count;
            }
            return locations_count;
        }
    },
    user_info: {
        key: 'user_info',
        label: "Created By",
        class: "col-lg-2 war_user_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('user_info') && item.user_info){
                let user_info = item.user_info;
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
            }
            return '';
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-2 war_created_at",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'DD/MM/YYYY') : '';
        }
    },
};
