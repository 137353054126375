<template>
    <div>
        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <div class="products" v-if="item_info.asset_images && item_info.asset_images.length > 0">

            <EditImageNotes
                ref="edit-image-notes"
                item_type="image-notes"
                modal_title="Edit Image Notes"
                :with_item_details="false"
                @on-new-item-adding="addNewImageNotes"
            />

            <div class="product-row grid af-images">
                <div class="product-item"
                     v-for="(image, index) in item_info.asset_images"
                     :key="index"
                >
                    <div class="product-block">
                        <!-- image-->
                        <div class="product-image">
                            <b-carousel class="product-image-slider"
                                        style="text-shadow: 0px 0px 2px #000"
                                        :interval="0"
                            >
                                <a @click="() => showCaptiomImg(index, item_info.asset_images)">
                                    <my-b-carousel-slide :with_no_controls="true" :image_index="index" :images_count="item_info.asset_images.length" :src="item_info.asset_images[index]" :image_size="(image_sizes.hasOwnProperty('media_tab') ? image_sizes.media_tab : '')"/>
                                </a>
                            </b-carousel>
                        </div>

                        <!-- details -->
                        <ImageObjectExtraInfo
                            :image="image"
                            :index="index"
                            :perform-action="imagePerformAction"
                            :object_type="object_type"
                            @on-save-item-image="refreshItemImage"
                            :image_size="(image_sizes.hasOwnProperty('media_tab') ? image_sizes.media_tab : '')"
                            :item_type="item_info.current_page_type"/>


                    </div>
                </div>
            </div>

            <div class="row mb-3" v-if="details_page_type === 'managed_storage'">
                <div class="col-12">
                        <span class="asset-files-details add-new-asset-file asset-file-details-btn">
                            <a v-if="item_info"
                               href="javascript:void(0);"
                               class="text-af-accent"
                               @click="performAction(item_info, 'edit_images', 0)">
                                <i class="fas fa-images"></i> Edit Images
                            </a>
                        </span>
                </div>
            </div>

        </div>

        <div v-else class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Media found here at the moment<a v-if="details_page_type === 'managed_storage' && item_info" class="text-af-accent" href="javascript:void(0);" @click="performAction(item_info, 'edit_images', 0)"><span class="text-dark">, </span>Edit Images?</a><span v-else>.</span></h4>
        </div>
    </div>

</template>

<script>


import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import MyBCarouselSlide from "./AssetPassportBlocks/MyBCarouselSlide";
import ImageObjectExtraInfo from "./ImageObjectExtraInfo";
import EditImageNotes from "../../../../customers/extras/EditImageNotes";

export default {
    mixins:[ImageGallery],
    name: "MediaTab",
    components: {EditImageNotes, ImageObjectExtraInfo, MyBCarouselSlide, VueEasyLightbox},
    props:['item_info', 'object_type', 'details_page_type', 'performAction'],
    data(){
        return {
        }
    },
    created() {
        this.gallery_object_type = this.object_type;
    },
    mounted(){

    },
    methods:{
    }
}
</script>

