<template>
    <div>
        <h4> {{ menu_title !== '' ? menu_title : 'Export'}}</h4>
        <div class="row customer-list">
            <div class="col-lg-4 col-xl-4 text-center" @click="performAction('export_pdf')">
                <div class="card selected_card">
                    <div class="card-body">
                        <h5 class="card-title">Export PDF</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssetsExportTab',
    props: ['menu_title'],
    data(){
        return {}
    },
    methods: {
        performAction(action_name){
            switch (action_name) {
                case 'export_pdf':
                    this.$emit('on-export-pdf');
                    break;
            }
        }
    },
}
</script>
