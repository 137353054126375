<template>
    <div class="cm_col_gird smaller-grid-item">
        <div class="cm_product_item_box" :class="getAssetClasses(my_asset, selected_asset_id)">
            <AssetIconList :my_asset="my_asset" :item_type="item_type" :perform-action="performAction"/>

            <MyBCarouselImages parent_class="cm_product_grid_img" :my_asset="my_asset" :show-captiom-img="showCaptiomImg" :required_image_size="'asset_list_grid'"/>

            <div class="cm_product_content">
                <div class="cm_product_grid_view_btn">
                    <a :href="'/' + item_type + '/assets/' + my_asset.id + '/view'"><i class="bx bx-chevron-right"></i></a>
                </div>
                <div class="cm_product_grid_name">
                    <h3 class="cm_product_title"><a v-b-tooltip :title="'UID: ' + my_asset.uid" :href="'/' + item_type + '/assets/' + my_asset.id + '/view'"><i class="bx bx-user-circle"></i> {{ my_asset.name | titleize }}</a></h3>
                </div>
                <div class="cm_product_status_grid">
                    <div class="cm_pro_status_tag">
                        <a href="#" :class="'status-btn status-btn-' + my_asset.status" v-html="getAssetStatusFrom(my_asset)"></a>
                    </div>
                    <div class="cm_qty_display">
                        <p class="cm_qty_label" v-b-tooltip title="Total Qty">Qty <span>{{ my_asset.quantity }}</span></p>
                        <p class="cm_qty_box_num">
                            <span class="cm_qg_line" v-b-tooltip title="Available Qty">{{ my_asset.available_quantity }}</span>
                            <span class="cm_qo_line" v-b-tooltip title="Reserved Qty">{{ my_asset.reserved_quantity }}</span>
                        </p>
                    </div>
                </div>
                <div class="cm_product_cost">
                    <div class="common_col_bg_box" v-if="character_info">
                        <div class="row">
                            <div class="col-md-12">
                                <a :href="'/' + item_type + '/characters/'+character_info.id+'/view'" class="badge text-af-primary af-soft-accent font-size-11 m-1">({{ character_info.character_number || '' }}) {{ character_info.name }}</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="[1].includes(selected_view.id)" class="common_sub_col_bg color_v_accent">
                        <div class="row">
                            <div class="col-6">
                                <div class="cm_list_inner_common">
                                    <span>ADDRESS</span>
                                    <p>
                                        <i class="bx bx-map"></i><a class="text-dark" :href="'/' + item_type + '/addresses/' + my_asset.address_id + '/view'">{{ my_asset.hasOwnProperty('parent_address') ? my_asset.parent_address : '-' }}</a>
                                        <a href="#" v-if="!asset_is_locked"><small>{{ my_asset.hasOwnProperty('parent_address') ? 'Change' : 'Assign Address'}}</small></a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="cm_list_inner_common">
                                    <span>LOCATION</span>
                                    <p>
                                        <i class="bx bx-map"></i><a class="text-dark" :href="'/' + item_type + '/addresses/' + my_asset.address_id + '/view'">{{ my_asset.hasOwnProperty('address_location_name') ? my_asset.address_location_name : '-' }}</a>
                                        <a href="#" v-if="!asset_is_locked"><small>{{ my_asset.hasOwnProperty('address_location_name') ? 'Change' : 'Assign Location'}}</small></a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="cm_list_inner_common">
                                    <span>DEPARTMENT</span>
                                    <p>
                                        <i class="bx bx-sitemap"></i><a class="text-dark" :href="'/' + item_type + '/departments/' + my_asset.parent_department_id + '/view'">{{ my_asset.hasOwnProperty('parent_department_name') ? my_asset.parent_department_name : '-' }}</a>
                                        <a href="#"><small>{{ my_asset.hasOwnProperty('parent_department_name') ? 'Change' : 'Assign Department'}}</small></a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="cm_list_inner_common">
                                    <span>USER</span>
                                    <p>
                                        <i class="bx bx-user-circle"></i><a class="text-dark" :href="'/' + item_type + '/users/' + my_asset.user_id + '/view'">{{ my_asset.hasOwnProperty('user_name') ? my_asset.user_name : '-' }}</a>
                                        <a href="#"><small>{{ my_asset.hasOwnProperty('user_name') ? 'Change' : 'Assign User'}}</small></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cm_product_more_btn">
                <div v-if="hasPermission('edit_assets') || hasPermission('delete_assets')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                    <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-dots-horizontal"></i>
                    </button>
                    <AssetsActionsList :item="my_asset" :item_type="item_type" :perform-action="performAction"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssetsActionsList from "../AssetsActionsList";
import AssetIconList from "./AssetIconList";
import MyBCarouselImages from "./MyBCarouselImages";

export default {
    name: 'AssetSmallerGridView',
    components: {MyBCarouselImages, AssetIconList, AssetsActionsList},
    props:[
        'item_type',
        'my_asset',
        'selected_asset_id',
        'assetKey',
        'selected_view',
        'showCaptiomImg',
        'performAction',
    ],
    computed:{
        asset_is_locked(){
            return this.checkIfAssetIsLocked(this.my_asset);
        },
        character_info(){
            return this.getValueOrDefault(this.my_asset, 'character_info', null);
        },
    },
    methods: {
        getValueOrDefault(item, key, default_value){
            return item && item.hasOwnProperty(key) ? item[key] : default_value;
        },
    }
}
</script>
