import {getSpStorageInfoBlock} from "./getSpStorageInfoBlock";
import {getLocationDetails} from "./SpLocationSharedFunctions";
import {getOrderOrOrderLineWithStatus} from "./getOrderOrOrderLineWithStatus";

const FirstPath = window.location.pathname.split('/')[1];

export const SPMovementKeysList = {
    movement_date: {
        key: 'movement_date',
        label: "Date",
        class: "col-lg-1 whg_movement_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'DD/MM/YYYY HH:mm');
        }
    },
    item_type:{
        key: 'item_type',
        label: "Movement",
        class: "col-lg-2 whg_item_type",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let movement_item_types = [
                {
                    class: 'w_asset',
                    name: 'Asset'
                },
                {
                    class: 'w_storage',
                    name: 'Storage'
                }
            ];

            let movement_item_type = movement_item_types[item.item_type];

            if (item.hasOwnProperty('instruction_type') && item.instruction_type === 1){
                movement_item_type.name = 'Managed Storage';
            }

            return movement_item_type ? '<span class="button_size status-btn-active ' + movement_item_type.class + '">' + movement_item_type.name + '</span>' : '-';
        }
    },
    movement_type_info:{
        key: 'movement_type_info',
        label: "Type",
        class: "col-lg-1 whg_movement_type_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let movement_types = [
                {
                    class: 'w_park',
                    name: 'Park'
                },
                {
                    class: 'w_vacate',
                    name: 'Vacate'
                },
                {
                    class: 'w_vacate_park',
                    name: 'Vacate & Park'
                },
                {
                    class: 'w_intake',
                    name: 'Intake'
                },
                {
                    class: 'w_relocation',
                    name: 'Relocation'
                },
                {
                    class: 'w_dispatched',
                    name: 'Dispatched'
                },
            ];
            let movement_type = movement_types[item.movement_type];
            return movement_type ? '<span class="button_size status-btn-active ' + movement_type.class + '">' + movement_type.name + '</span>' : '-';
        }
    },
    item_info:{
        key: 'item_info',
        label: "Object",
        class: "col-lg-2 whg_item_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_info = item.item_info;
            if (item.hasOwnProperty('instruction_type') && item.instruction_type === 1){
                return '<a class="text-dark" href="/' + FirstPath + '/managed_storages/' + item_info.id + '/view">' + Vue.options.filters.titleize(item_info.name) + ' (' + item_info.uid + ')</a>';
            }
            if (item_info){
                if (item.item_type === 0){
                    // asset
                    return '<a class="text-dark" href="/' + FirstPath + '/assets/' + item_info.id + '/view">' + Vue.options.filters.titleize(item_info.name) + ' (ASS-' + item_info.uid + ')</a>';
                }else if (item.item_type === 1){
                    // SpStorage
                    return getSpStorageInfoBlock(item_info, false);
                }
            }
            return '-';
        }
    },
    reason_info:{
        key: 'reason_info',
        label: "Reason",
        class: "col-lg-2 whg_reason_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_info = item[key];
            if (item.instruction_source === 1){
                // order?
                if (item_info){
                    return getOrderOrOrderLineWithStatus(item_info, 'orders', FirstPath);
                }
                return '-';
            }else{
                return 'On Demand';
            }
        }
    },
    source_info:{
        key: 'source_info',
        label: "Source",
        class: "col-lg-2 whg_source_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_info = item[key];
            switch (item.movement_source_type) {
                case 0:
                    return 'Homeless';
                case 1:
                    return getLocationDetails(item_info);
                case 2:
                    return 'Delivery';
                case 3:
                    return 'Collection';
                case 4:
                    return getSpStorageInfoBlock(item_info, false);
                case 5:
                    if (item_info){
                        // order? show its order line
                        return getOrderOrOrderLineWithStatus(item_info, 'order_lines', FirstPath);
                    }
                    return 'Order';
                default:
                    return '-';
            }
        }
    },
    target_info:{
        key: 'target_info',
        label: "Target",
        class: "col-lg-2 whg_target_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_info = item[key];
            switch (item.movement_target_type) {
                case 0:
                    return 'Homeless';
                case 1:
                    return getLocationDetails(item_info);
                case 2:
                    return 'Delivery';
                case 3:
                    return 'Collection';
                case 4:
                    return getSpStorageInfoBlock(item_info, false);
                case 5:
                    return 'Order';
                case 6:
                    return 'Recycle';
                case 7:
                    return 'Archived';
                case 8:
                    return 'User Defined';
                default:
                    return '-';
            }
        }
    },
};
