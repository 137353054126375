const FirstPath = window.location.pathname.split('/')[1];
export const SceneKeysList = {
    name: {
        key: 'name',
        label: "Scene",
        class: "col-sm-1 col-lg-1 s_name",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (value){
            return Vue.options.filters.titleize(value);
        }
    },
    custom_order: {
        key: 'name',
        label: "Custom Order",
        class: "col-sm-1 col-lg-1 s_custom_order",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let html = '';

            if (item.hasOwnProperty('original_target_scene_info') && item.original_target_scene_info){
                let original_target_scene_info = item.original_target_scene_info;
                if (item.original_scene_order === -1){
                    html += 'Before Scene ';
                }else if (item.original_scene_order === 1){
                    html += 'After Scene ';
                }
                html += '<a class="text-dark target-scene-name" href="/' + FirstPath + '/scenes/'+original_target_scene_info.id+'/view">' + original_target_scene_info.name + '</a>';
            }

            return html
        }
    },
    parts: {
        key: 'parts',
        label: "Parts",
        class: "col-sm-1 col-lg-1 s_parts",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            // return '<a class="text-dark scene-parts-link" href="/' + FirstPath + '/parts?queue=scenes&id=' + item.id +'">View Parts</a>'

            if (item.hasOwnProperty('parts_info') && item.parts_info){
                let html = '';
                for (const itemKey in item.parts_info) {
                    let part_info = item.parts_info[itemKey];
                    html += '<a href="/' + FirstPath + '/parts/' + part_info.id + '/view" class="text-dark scene-part-name">' + (part_info.name || part_info.uid || '-')+'</a> ';
                }
                return html;
            }
            return '-';
        }
    },
    scene_locations: {
        key: 'scene_locations',
        label: "Main Locations",
        class: "col-sm-1 col-lg-1 s_scene_locations",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {

            if (item.hasOwnProperty('scene_locations_info') && item.scene_locations_info){
                let html = '';
                for (const itemKey in item.scene_locations_info) {
                    let scene_location_info = item.scene_locations_info[itemKey];
                    html += '<a href="/' + FirstPath + '/scene_locations/' + scene_location_info.id + '/view" class="text-dark scene-scene_location-name">' + (scene_location_info.name || scene_location_info.uid || '-')+'</a> ';
                }
                return html;
            }
            return '-';
        }
    },
    characters: {
        key: 'characters',
        label: "Cast",
        class: "col-sm-1 col-lg-1 s_characters",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark scene-scene_locations-link" href="/' + FirstPath + '/characters">View Cast</a>';
        }
    },
    shoot_days: {
        key: 'shoot_days',
        label: "Shoot Days",
        class: "col-sm-1 col-lg-1 s_shoot_days",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark scene-scene_locations-link" href="#">View Shoot Days</a>';
        }
    },
};
