<template>
    <b-modal :id="received_modal_id"
             :title="modal_title + extra_title"
             size="xl"
             title-class="font-18"
             hide-footer>

        <div class="common-step-panel use-section" v-if="!isLoadingLists">
            <SharedFormFieldsMultipleSections
                ref="acquisition-shared-multiple-sections"
                :item_info="item_info" :add-form="addForm" :item_type="item_type" :required_fields="required_fields" :section_fields="section_fields" :object_type="object_type" :required_select_lists="required_select_lists"/>
        </div>
        <div class="loading-spinner text-center" v-else>
            <div class="center">
                <b-spinner class="text-af-accent m-2"></b-spinner>
            </div>
            Loading...
            <br />
            <small>Please wait.</small>
        </div>
        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="saveAction">
                {{ modal_title }}
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(received_modal_id)">Cancel</b-button>
        </div>

    </b-modal>
</template>
<script>

import SharedFormFieldsMultipleSections from "../acquisitionForm/SharedFormFieldsMultipleSections";
export default {
    components: {SharedFormFieldsMultipleSections},
    mixins: [],
    name: 'DefineSupplierModal',
    props: ['item_type', 'received_modal_id', 'modal_title', 'object_type'],
    data(){
        return {
            extra_title: '',
            isLoadingLists: false,
            item_info: {},
            addForm: {
                item_details: {},
                filled_asset_images:[],
                asset_images:[],
            },
            section_fields:{
                buyer_section: {
                    name: 'Buyer',
                    minimized: false,
                    fields: [
                        'buyer_id',
                        'buyer_ledger_id',
                        'notes',
                        'payment_date',
                        'payment_ledger_id'
                    ]
                },
                supplier_section: {
                    name: 'Supplier',
                    minimized: false,
                    fields: [
                        'supplier_id',
                        'purchase_order_id',
                        'item_return_policy',
                    ]
                },
                images_section: {
                    name: 'Images',
                    minimized: false,
                    fields: [
                        'item_images',
                    ]
                },
            },
            required_fields: {
                buyer_id: {
                    name: 'Buyer',
                    type: 'single-select',
                    name_plural:'buyers',
                    link_name:'',
                    has_permission: true,
                    options:[]
                },
                buyer_ledger_id: {
                    name: 'Buyer Ledger (P-CARD)',
                    type: 'single-select',
                    name_plural:'Buyer Ledgers',
                    link_name:'',
                    has_permission: true,
                    options:[]
                },
                notes: {
                    name: 'Notes',
                    type: 'text',
                    has_permission: true
                },
                payment_date: {
                    name: 'Payment Date',
                    type: 'date',
                    has_permission: true,
                    default_value: new Date()
                },
                payment_ledger_id: {
                    name: 'Payment Account',
                    type: 'single-select',
                    name_plural:'Payment Accounts',
                    link_name:'ledgers',
                    has_permission: true,
                    options:[]
                },


                supplier_id: {
                    name: 'supplier',
                    name_plural:'suppliers',
                    link_name:'suppliers',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                purchase_order_id: {
                    name: 'Purchase Order',
                    name_plural:'Purchase Orders',
                    link_name:'purchaseOrders',
                    type: 'single-select',
                    has_permission: true,
                    options:[]
                },
                item_return_policy: {
                    name: 'Item Return Policy',
                    type: 'single-select',
                    has_permission: true,
                    options:[
                        { id: 0, name: '0' },
                        { id: 1, name: '1' },
                        { id: 2, name: '2' },
                        { id: 3, name: '3' },
                        { id: 4, name: '4' },
                        { id: 5, name: '5' },
                        { id: 6, name: '6' },
                        { id: 7, name: '7' },
                        { id: 14, name: '14' },
                        { id: 28, name: '28' },
                        { id: 30, name: '30' },
                        { id: 60, name: '60' },
                        { id: 90, name: '90' }
                    ]
                },

                item_images: {
                    key: 'item_images',
                    label: 'Images',
                    type: 'item_images'
                },
            },
            required_select_lists:  {
                buyer_id: 'buyers_list', //[],
                buyer_ledger_id: 'buyer_ledgers_list', //[],
                payment_ledger_id: 'payment_ledgers_list', //[],
                supplier_id: 'suppliers_list',
            }
        }
    },
    mounted() {
        this.section_fields.images_section.name = 'Images';
    },
    methods:{
        saveAction(){
            this.$emit('onFormSubmit', 'define_supplier', this.item_info, this.addForm, this.object_type, this.item_type, this.received_modal_id);
        },
        set_item(item){
            this.item_info = item;
            this.addForm.item_details = {
                buyer_id: this.item_info.buyer_info ? this.item_info.buyer_info : null,
                buyer_ledger_id: this.item_info.buyer_ledger_info ? this.item_info.buyer_ledger_info : null,
                notes: this.item_info.notes,
                supplier_id: this.item_info.supplier_info ? this.item_info.supplier_info : null,
                item_return_policy: this.getObjectOf(this.item_info.item_return_policy, this.required_fields.item_return_policy.options),
                purchase_order_id: this.item_info.purchase_orders_info ? this.item_info.purchase_orders_info[this.item_info.purchase_orders_info.length-1] : null,
            };

            let payment_info = this.item_info.hasOwnProperty('payment_info') && this.item_info.payment_info ? this.item_info.payment_info : null;
            if (payment_info){
                this.addForm.item_details.payment_date = payment_info.payment_date;
                this.addForm.item_details.payment_ledger_id = payment_info.payment_ledger_id;
            }
            this.extra_title = ' to {' + item.uid + '}';

            this.section_fields.images_section.name = 'Add More Images';

        },
    }
}
</script>
