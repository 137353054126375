import Vue from "vue";
window.events = new Vue;

window.flash = function (message, level='success', action_name='') {
    let flashMessage = {
        message:message,
        level: level,
        action_name: action_name
    };
    localStorage.setItem('flashMessage', JSON.stringify(flashMessage));
}
export default {
    created(){
        this.checkForFlashableMessages();
    },
    methods: {
        checkForFlashableMessages(){
            let flashMessageItem = localStorage.getItem('flashMessage');
            if (flashMessageItem){
                flashMessageItem = JSON.parse(flashMessageItem);

                let title, variant, delay;
                if (flashMessageItem.level === 'success'){
                    title = 'Success';
                    variant = 'success';
                    delay = 1500;
                } else if (flashMessageItem.level === 'info'){
                    title = 'Info';
                    variant = 'info';
                    delay = 3000;
                } else if (flashMessageItem.level === 'danger'){
                    title = 'Error';
                    variant = 'danger';
                    delay = 3000;
                }else{
                    title = 'Success';
                    variant = 'success';
                    delay = 1500;
                }

                if (flashMessageItem.action_name === 'added_disposition' && this.hasOwnProperty('afterSavingDisposition')){
                    this.afterSavingDisposition();
                }

                this.presentToast(title,
                    flashMessageItem.message,
                    variant,
                    delay,
                    true,
                    true);

                localStorage.removeItem('flashMessage');
            }

        }
    }
}
