<template>
    <b-modal :id="received_modal_id"
             title="Add To Group"
             title-class="font-18"
             @hide="$emit('on-modal-cancel')"
             hide-footer>

        <b-form-group label="Destination Group" label-for="destination_groups_list">
            <b-form-select
                id="destination_groups_list"
                name="destination_groups_list"
                placeholder="Select Group"
                v-model="selected_destination_group"
                :options="groups_list"
                :loading="isLoading"
            >
            </b-form-select>
        </b-form-group>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="performChangeGroup()">
                Add To Group</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(received_modal_id)">Cancel</b-button>
        </div>
    </b-modal>

</template>
<script>
import AssetActions from "@/mixins/assetActions.mixin";
import Resource from '@/api/resource';

const assetGroupsResource = new Resource('api/assetflow/asset_groups');

export default {
    mixins: [AssetActions],
    name: 'ChangeAssetGroup',
    props: ['item_type', 'received_item_info', 'received_modal_id'],
    data(){
        return {
            selected_destination_group: null,
            item_info: null,
            groups_list: null,
            isLoading: false,
        }
    },
    created() {
        this.item_info = this.received_item_info;
    },
    methods:{

        async getOptionsList(){
            this.listLoading = true;

            let queryList = {};
            if (this.item_type === 'customer' && this.current_customer){
                queryList.customer_id = this.current_customer.id;
            }else if (this.is_group && this.item_type === 'group' && this.selected_customer.id){
                queryList.customer_id = this.selected_customer.id;
            }

            const { data } = await assetGroupsResource.list(queryList);


            if (Object.keys(data).includes('data')){
                this.groups_list = data.data.items.map(function(e) { return {value: e.id, text: e.name}; })

                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },
        set_item(item){
            if (item){
                this.item_info = item;
                this.getOptionsList();
            }
        },
        performChangeGroup(){
            let group_name = this.selected_destination_group;
            let message = "Added {"+this.item_info.name+"} to group {"+group_name+"} Successfully";
            this.$bvModal.hide(this.received_modal_id);
            this.set_item_value('assets', this.item_info.id, 'asset_group_id', this.selected_destination_group, message, true);
        }
    }
}
</script>
