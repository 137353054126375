<template>
    <div class="step-form-content-block row">

        <add-new-modal
            ref="add-new-address"
            item_type='address'
            modal_title="Create New Address"
            @on-new-item-adding="addressLocationChange"
            @on-cancel-clicked="cancelClicked('address_id')"
        ></add-new-modal>

        <add-new-modal
            ref="add-new-location"
            item_type='location'
            modal_title="Create New Location"
            @on-new-item-adding="addressLocationChange"
            @on-cancel-clicked="cancelClicked('location_id')"
        ></add-new-modal>

        <add-new-department
            ref="add-new-department"
            item_type='department'
            modal_title="Create New Department"
            :without_status="true"
            @on-new-item-adding="addressLocationChange"
        >
        </add-new-department>

        <div class="col-xl-6 st-col-6">
            <asset-info-section
                v-for="(field_key, index) in address_info_fields"
                :key="index"
                v-if="required_fields[field_key].has_permission"
                :title="required_fields[field_key].name">
                    <div class="st--common--box">

                        <div class="st-status-radio-box">
                            <div v-if="['location_id', 'address_id'].includes(field_key) && asset_is_locked">
                                <LockedAssetWarning icon="bx bx-lock" message="This Asset is Locked, 'Changing Location Info' is not available."/>
                            </div>
                            <div v-else class="st-radio-row">
                                <div v-for="(option, index) in required_fields[field_key].options"
                                     :key="index"
                                     class="col-3">
                                    <div class="cm-radio-btn" :class="'mt-1 '+field_key+'-' + option.id">
                                        <label>
                                            <input
                                                v-model="addForm.single_select_v_models[field_key]"
                                                :key="index"
                                                :id="'_'+index"
                                                :name="field_key"
                                                :value="option"
                                                :disabled="field_key === 'location_id' && !addForm.single_select_v_models.address_id"
                                                @input="changedAddressInfo(field_key, option, $event)"
                                                type="radio" />
                                            <div class="st-radio-box">
                                                <span></span>
                                                <p>{{ option.name | titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </asset-info-section>

        </div>
        <div v-if="$parent.useTooltips" class="col-xl-6 st-col-6">
            <div class="validation-box-block">
                <div class="validation-box">
                    <div class="validation-title">
                        <img :src="getAssetBaseUrl + '/images/new_images/enter-weight-ic.png'" alt="Weight">
                        <p>Landmark</p>
                    </div>
                    <div class="validation-desc">
                        <p>include all the necessary details needed to complte your request.</p>
                    </div>
                    <div class="all-validation-desc">
                        <h3>For Example:</h3>
                        <p>If you are looking for a logo, you can specify your company name, business type, preferred color, Weight etc.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import multiSelect from '@/mixins/MultiSelect';
import AssetLocation from "@/mixins/AssetLocation";

import AddNewModal from "../../customers/extras/AddNewModal";
import AddNewDepartment from "../../customers/extras/AddNewDepartment";
import LockedAssetWarning from "./LockedAssetWarning";
export default {
    name: "LocationInfo",
    mixins: [multiSelect, AssetLocation],
    components: {LockedAssetWarning, AddNewDepartment, AddNewModal, AssetInfoSection},
    props: ['addForm', 'required_fields', 'asset_is_locked', 'getAssetInfoUsingKey'],
    data(){
        return {
            address_info_fields: [
                'contact_id', 'department_id', 'address_id', 'location_id'
            ],
        }
    },
    created() {
        this.getDefaultAndPrimary();
    },
    methods:{
        changedAddressInfo(field, option, event){
            if (option.id === -99){
                // shall add new
                switch (field){
                    case 'department_id':
                        this.$refs['add-new-department'].setSelectedItem(null);
                        break;
                    case 'address_id':
                        // console.log("shall add new address");
                        this.$refs['add-new-address'].setSelectedItem(null);
                        this.setupAddressLookup();
                        break;
                    case 'location_id':

                        let selected_address = this.addForm.single_select_v_models.address_id;
                        this.$refs['add-new-location'].setSelectedItem(null);
                        this.$refs['add-new-location'].set_addresses_list([{value: selected_address.id, text: selected_address.name}], true);
                        this.$refs['add-new-location'].setAddressID(selected_address.id);

                        break;
                }

                this.addForm.single_select_v_models[field] = null;
                this.$bvModal.show('create-'+field.replace('_id', ''));
            }else if (field === 'address_id'){
                // should filter items in locations list.
                this.$refs['add-new-location'].set_addresses_list([{value: option.id, text: option.name}], true);
                this.$refs['add-new-location'].setAddressID(option.id);
                this.setSelectedLocationFor(option);

            }

        },
        reset_default_items(item_id_name, new_option, default_value, default_text = '') {
            // shall reset other primaries
            let new_options = this.required_fields[item_id_name].options.map(item => {
                if (item[default_value]) {
                    item[default_value] = false;
                    item.name = item.name.replace(default_text, '');
                }
                return item;
            });
            this.required_fields[item_id_name].options = new_options;
        },
        cancelClicked(item_id_key){

            this.addForm.single_select_v_models[item_id_key] = null;
            if (item_id_key === 'address_id'){
                this.addForm.single_select_v_models.location_id = null;
            }
        },
        addressLocationChange(new_item, item_type) {
            if (new_item) {
                // should append this to the list
                let item_type_key = item_type + '_id';
                let new_option = {id: new_item.id, name: new_item.name};

                if (item_type === 'address') {
                    new_option.is_default = new_item.is_default;
                    if (new_option.is_default) {
                        let default_text = ' <= Default';
                        new_option.name = new_option.name + default_text;

                        this.reset_default_items(item_type_key, new_option, 'is_default', default_text);
                    }

                    if (new_item.locations) {
                        let new_location = new_item.locations[0];
                        let new_location_option = {
                            id: new_location.id,
                            name: new_location.name,
                            is_primary: new_location.is_primary,
                            parent_address: {id: new_item.id}
                        };

                        if (new_location_option.is_primary) {
                            let default_text = ' <= Primary';
                            new_location_option.name = new_location_option.name + default_text;
                        }
                        this.required_fields.location_id.options = [
                            {id: -99, name: 'Add New'},
                            new_location_option
                        ];
                        this.addForm.single_select_v_models.location_id = new_location_option;
                    }

                } else if (item_type === 'location') {
                    new_option.is_primary = new_item.is_primary;
                    if (new_option.is_primary) {
                        let default_text = ' <= Primary';
                        new_option.name = new_option.name + default_text;
                        this.reset_default_items(item_type_key, new_option, 'is_primary', default_text);
                    }

                }

                this.required_fields[item_type_key].options.push(new_option);
                this.addForm.single_select_v_models[item_type_key] = new_option;

            }
        },
    }
}
</script>


