<template>
    <b-modal :id="received_modal_id"
             :title="modal_title + extra_title"
             size="xl"
             title-class="font-18"
             hide-footer>

        <div class="common-step-panel use-section">
            <SharedFormFieldsMultipleSections :item_info="item_info" :add-form="addForm" :item_type="item_type" :required_fields="required_fields" :section_fields="section_fields" :object_type="object_type" :required_select_lists="required_select_lists"/>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="saveAction">
                {{ modal_title }}
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(received_modal_id)">Cancel</b-button>
        </div>

    </b-modal>
</template>
<script>

import SharedFormFieldsMultipleSections from "../acquisitionForm/SharedFormFieldsMultipleSections";
export default {
    components: {SharedFormFieldsMultipleSections},
    mixins: [],
    name: 'AddPaymentModal',
    props: ['item_type', 'received_modal_id', 'modal_title', 'object_type'],
    data(){
        return {
            extra_title: '',
            item_info: {},
            addForm: {
                item_details: {}
            },
            section_fields:{
                add_payment:{
                    name: 'Payment',
                    minimized: false,
                    fields: [
                        'payment_date',
                        'payment_type',
                        'payment_purchase_order_id',
                        'payment_nominal_code_id',
                        'payment_amount',
                        'payment_reference',
                        'payment_reconciliation'
                    ]
                },
            },
            required_fields: {
                payment_date: {
                    name: 'date',
                    type: 'date',
                    has_permission: true
                },
                payment_type: {
                    name: 'Payment Type',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 0, name: 'Account' },
                        { id: 1, name: 'Cash' },
                        { id: 2, name: 'Credit Card' },
                        { id: 3, name: 'Expenses' }
                    ]
                },
                payment_purchase_order_id: {
                    name: 'Purchase Order',
                    name_plural:'Purchase Orders',
                    link_name:'purchaseOrders',
                    type: 'radio-select',
                    has_permission: true,
                    options:[]
                },
                payment_nominal_code_id: {
                    name: 'Payment Account',
                    name_plural:'ledger accounts',
                    link_name:'nominal_codes',
                    type: 'radio-select',
                    has_permission: true,
                    options:[]
                },
                payment_amount: {
                    name: 'Amount',
                    type: 'number',
                    has_permission: true
                },
                payment_reference: {
                    name: 'Reference',
                    type: 'text',
                    has_permission: true
                },
                payment_reconciliation: {
                    name: 'Reconcile Payment with External Ledger?',
                    type: 'checkbox',
                    has_permission: true
                },
            },
            required_select_lists:  {
                payment_purchase_order_id: 'purchase_orders_list',
                payment_nominal_code_id: 'nominal_codes_list',
            }
        }
    },
    created() {
    },
    methods:{
        saveAction(){
            this.$emit('onFormSubmit', 'add_payment', this.item_info, this.addForm, this.object_type, this.item_type, this.received_modal_id);
        },
        set_item(item){
            this.item_info = item;
            this.extra_title = ' to {' + item.uid + '}';
        }
    }
}
</script>
