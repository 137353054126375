<template>
    <b-modal id="set_order_subscribers"
             size="lg"
             :title="modal_title"
             title-class="font-18"
             hide-footer>


        <div class="row">
            <div class="col-sm-12">
                <div class="card" v-for="(required_section, k) in required_sections" :key="k">
                    <div role="alert" class="alert alert-info order-service-msg" v-if="!selected_customer_id && k === 'subscriber_setup'">
                        <p><i class="mdi mdi-alert-circle-outline me-2"></i><em>To set subscribers, please select a customer first.</em></p>
                    </div>
                    <template v-else>
                        <div class="card-header af-soft-accent text-af-primary">
                            {{ required_section.label | titleize }}
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                                    <ChangeCommonFieldsAdvanced
                                        :item_type="item_type"
                                        :add-form="addForm"
                                        :required_fields="required_section.fields" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="text-right mt-2">
            <b-button type="submit"
                      variant="success"
                      v-on:click.once="addItem">
                Save
            </b-button>
            <b-button variant="outline-danger" @click="$emit('on-cancel-clicked')">Cancel</b-button>
        </div>

    </b-modal>
</template>
<script>

import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import SharedServiceFunctions from '@/mixins/SharedServiceFunctions.mixin';
import SettingInfoSection from "../settings/settingsForm/SettingInfoSection"
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";
import AddNewModal from "../extras/AddNewModal";

const orderResource = new Resource('api/assetflow/orders');

export default {
    name: 'SetOrderSubscribers',
    components: {AddNewModal, ChangeCommonFieldsAdvanced, SettingInfoSection},
    mixins: [multiSelect, SharedServiceFunctions],
    props: ['is_full', 'item_type', 'modal_title', 'received_order_type'],
    data(){
        return {
            selected_item: null,
            addForm:{
                customer_id:null,
                subscriber_id:null,
            },
            required_sections: {
                subscriber_setup:{
                    'label' : 'Subscribers',
                    fields: {
                        subscriber_id:{
                            key: 'subscriber_id',
                            label: 'Subscribers',
                            placeholder: 'Select Users',
                            type: 'multi-select',
                            is_required:false,
                            allow_empty:true,
                            has_message: true,
                            message_html: '<div role="alert" class="alert alert-info order-service-msg"><p><i class="mdi mdi-alert-circle-outline me-2"></i><em>Select Users who can see this Order and will receive Order Notifications.</em></p></div>',
                            options: [],
                            has_html_note: ''
                        },
                    }
                },
            },
            isAddingItem: false
        }
    },
    created() {
        this.setFields();
    },
    computed:{
        selected_customer_id() {
            return this.addForm.customer_id;
        },
    },
    watch: {
        selected_customer_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal) {
                    this.addForm.subscriber_id = null;
                    this.shallGetListFor('subscriber', 'subscriber_setup');
                }
            }
        },
    },
    methods:{
        setFields(){
            // shall get #customers/sps list

            let order_type_name = (this.received_order_type === 0 ? 'Move To Storage' : 'Storage Order') || 'Order';
            this.required_sections.subscriber_setup.fields.subscriber_id.has_html_note = '<p>Note: <strong>' + order_type_name + ' Managers</strong> see all Orders</p>'
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        setValueFromOptions: function (section_name, field_name, item_id, default_value = null) {
            let found_item = _.find(this.required_sections[section_name].fields[field_name].options, function (o) {
                return o.id === item_id;
            });

            this.addForm[field_name] = found_item ? found_item : default_value;
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.customer_id = this.selected_item.customer_info ? this.selected_item.customer_info : (this.selected_item.customer ? this.selected_item.customer : null);
                this.addForm.name = this.selected_item.name;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                name: this.addForm.name,
                subscriber_id: this.addForm.subscriber_id ? this.getIDs(this.addForm.subscriber_id) : null
            };

            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await orderResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item && this.selected_item.hasOwnProperty('name') ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$emit('after-saving-is-done', new_item);
        },
    }
}
</script>
