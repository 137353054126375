<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div v-if="item_type === 'location'" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-info">
            <i class="mdi mdi-alert-circle-outline me-2"></i>
            A Location is somewhere within an Address where you can track Assets (e.g. office, floor number, meeting room, bedroom, stage, etc.). Large premises could have 1000's of Locations within a single Address if required
        </div>
        <div class="row">
            <b-form-group
                v-for="required_field in required_items[item_type]"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                :class="['address', 'addressactions'].includes(item_type) ? required_field.class : 'col-sm-12'"
            >
                <b-form-select
                    v-if="required_field.key === 'address_id'"
                    v-model="addForm.address_id"
                    type="text"
                    id="address_id"
                    name="address_id"
                    :disabled="list_disabled"
                    :placeholder="required_field.label"
                    :options="required_addresses_options"
                ></b-form-select>


                <div v-else-if="required_field.key === (item_type + '_friendly_name')"
                     class="common--input-filed st-unique-input">
                    <input
                        v-model="addForm[required_field.key]"
                        class="st-input-uq"
                        :id="required_field.key"
                        :placeholder="required_field.label"
                        autocomplete="none"
                        @input="show_error = false"
                        type="text"
                        :class="addForm[required_field.key] === '' && show_error ? 'error' : ''"
                    >
                    <div class="st-unique-input-box">
                        <input
                            v-model="addForm[['address', 'addressactions'].includes(item_type) ? 'is_default' : 'is_primary']"
                            id="styled-checkbox-2" class="styled-checkbox"
                            type="checkbox" value="1">
                        <label for="styled-checkbox-2">Default {{ item_type.replace('actions', '') | titleize }}?</label>
                    </div>
                    <small v-if="addForm[required_field.key] === '' && show_error" style="color: #f46a6a;">This Field is Required.</small>
                </div>


                <b-form-input
                    v-else
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="'address_' + required_field.key"
                    :placeholder="required_field.placeholder ? required_field.placeholder : required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click.once="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const addressResource = new Resource('api/assetflow/addresses');
const locationResource = new Resource('api/assetflow/address_locations');

export default {
    name: "AddNewModal",
    props: ['modal_title', 'item_type', 'addresses_options'],
    data(){
        return {
            show_error: false,
            selected_customer_id: null,
            addForm:{
                location_friendly_name: '',
                location_description: '',
                address_id: null,
                is_primary: 0,
                address_friendly_name: '',
                address_description: '',
                is_default: 0,
                company: '',
                line_1: '',
                line_2: '',
                city: '',
                state: '',
                zip_code: '',
                country: '',
                latitude: '',
                longitude: '',
            },
            required_items:{
                location: {
                    address_id: {
                        key: 'address_id',
                        label: 'Address',
                        type: 'select'
                    },
                    location_friendly_name: {
                        key: 'location_friendly_name',
                        label: 'Friendly Name',
                        type: 'text'
                    }
                },
                address: {
                    address_friendly_name: {
                        key: 'address_friendly_name',
                        label: 'Friendly Name',
                        type: 'text',
                        class: 'col-sm-12'
                    },
                    selected_addresses:{
                        key: 'selected_addresses',
                        label: 'Autocomplete. Start typing your address:',
                        placeholder: 'Search Addresses..',
                        type: 'text',
                        class: 'col-sm-12'
                    },
                    company: {
                        key: 'company',
                        label: 'Company',
                        type: 'text',
                        class: 'col-sm-12'
                    },
                    line_1: {
                        key: 'line_1',
                        label: 'Line 1',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    line_2: {
                        key: 'line_2',
                        label: 'Line 2',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    city: {
                        key: 'city',
                        label: 'City',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    state: {
                        key: 'state',
                        label: 'State \\ County',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    zip_code: {
                        key: 'zip_code',
                        label: 'Zip \\ Post Code',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    country: {
                        key: 'country',
                        label: 'Country',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    latitude: {
                        key: 'latitude',
                        label: 'Latitude',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    longitude: {
                        key: 'longitude',
                        label: 'Longitude',
                        type: 'text',
                        class: 'col-sm-6'
                    }
                },
                addressactions: {
                    address_friendly_name: {
                        key: 'addressactions_friendly_name',
                        label: 'Friendly Name',
                        type: 'text',
                        class: 'col-sm-12'
                    },
                    selected_addresses:{
                        key: 'selected_addresses',
                        label: 'Autocomplete. Start typing your address:',
                        placeholder: 'Search Addresses..',
                        type: 'text',
                        class: 'col-sm-12'
                    },
                    company: {
                        key: 'company',
                        label: 'Company',
                        type: 'text',
                        class: 'col-sm-12'
                    },
                    line_1: {
                        key: 'line_1',
                        label: 'Line 1',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    line_2: {
                        key: 'line_2',
                        label: 'Line 2',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    city: {
                        key: 'city',
                        label: 'City',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    state: {
                        key: 'state',
                        label: 'State \\ County',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    zip_code: {
                        key: 'zip_code',
                        label: 'Zip \\ Post Code',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    country: {
                        key: 'country',
                        label: 'Country',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    latitude: {
                        key: 'latitude',
                        label: 'Latitude',
                        type: 'text',
                        class: 'col-sm-6'
                    },
                    longitude: {
                        key: 'longitude',
                        label: 'Longitude',
                        type: 'text',
                        class: 'col-sm-6'
                    }
                },
            },
            loqate_keys: {
                address: 'GD47-RC99-DK97-JG97',
                geocode: 'PF88-MG39-BN31-AF88'
            },
            selected_item: null,
            required_addresses_options: null,
            list_disabled: null
        }
    },
    created() {

        this.required_addresses_options = this.addresses_options;


        var fields = [
            { element: "selected_addresses", field: "" },
            { element: "address_company", field: "Company", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE },
            { element: "address_line_1", field: "Line1" },
            { element: "address_line_2", field: "Line2", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE },
            { element: "address_city", field: "City", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE },
            { element: "address_state", field: "Province", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE },
            { element: "address_zip_code", field: "PostalCode", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE },
            { element: "address_country", field: "CountryName", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE }
        ];

        var options = {
            key: this.loqate_keys.address,
            // countries: {
            //     codesList:"USA,CAN"
            // },
            setCountryByIP: false,
            GeoLocationEnabled : false,
            suppressAutocomplete: false
        }

        if (['address', 'addressactions'].includes(this.item_type)){
            window[this.item_type + '_control'] = new pca.Address(fields, options);
            window[this.item_type + '_control'].listen("populate", this.handleAddress);
        }
    },
    methods:{
        set_addresses_list(addresses_list, disabled=false){
            this.required_addresses_options = addresses_list;
            if (disabled){
                this.list_disabled = disabled;
            }
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.$emit('on-modal-cancel');
        },
        fillSelectedItem: function () {
            if (this.selected_item) {
                if (this.item_type === 'address'){
                    this.addForm.address_friendly_name = this.selected_item.name;
                    this.addForm.address_description = this.selected_item.description;
                    this.addForm.company = this.selected_item.company;
                    this.addForm.line_1 = this.selected_item.line_1;
                    this.addForm.line_2 = this.selected_item.line_2;
                    this.addForm.city = this.selected_item.city;
                    this.addForm.state = this.selected_item.state;
                    this.addForm.zip_code = this.selected_item.zip_code;
                    this.addForm.country = this.selected_item.country;
                    this.addForm.latitude = this.selected_item.latitude;
                    this.addForm.longitude = this.selected_item.longitude;
                    this.addForm.is_default = this.selected_item.is_default ? this.selected_item.is_default : 0;
                }else{
                    this.addForm.location_friendly_name = this.selected_item.name;
                    this.addForm.location_description = this.selected_item.description;
                    this.addForm.is_primary = this.selected_item.is_primary ? this.selected_item.is_primary : 0;
                }
            }
        },
        setAddressID(address_id){
            this.addForm.address_id = address_id;
        },
        setSelectedCustomer(customer_id){
            this.selected_customer_id = customer_id;
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.setAddressID(null);
            }
        },
        getAddressLocation(address, location_str, selectedCountryName=''){
            let post_params = {
                Key: this.loqate_keys.geocode,
                Location: location_str,

            };
            var params = '';
            params += "?Key=" + encodeURIComponent(post_params.Key);
            params += "&Location=" + encodeURIComponent(post_params.Location);

            let url = "https://api.addressy.com/Geocoding/UK/Geocode/v2.10/json3.ws";

            if (selectedCountryName !== 'United Kingdom'){
                url = 'https://api.addressy.com/Geocoding/International/Geocode/v1.10/json3.ws';
                post_params.Country = selectedCountryName;
                params += "&Country=" + encodeURIComponent(post_params.Country);
            }

            axios.post(url+params, post_params)
            .then((response) => {
                let data = response.data;

                // Test for an error
                if (data.Items.length === 1 && typeof(data.Items[0].Error) != "undefined") {
                    // Show the error message
                    console.log("data.Items[0].Description");
                    console.log(data.Items[0].Description);
                }else {
                    // Check if there were any items found
                    if (data.Items.length !== 0) {
                        let found_object = data.Items[0];
                        this.addForm.latitude = found_object.Latitude;
                        this.addForm.longitude = found_object.Longitude;
                    }
                }

            });


        },
        handleAddress(address, variations) {
            let keys = {
                company: 'Company',
                line_1: 'Line1',
                line_2: 'Line2',
                city: 'City',
                state: 'Province',
                zip_code: 'PostalCode',
                country: 'CountryName',
            }

            let locations = [];
            for (let af_key in keys) {
                let address_key = keys[af_key];

                let value = address[address_key];
                this.addForm[af_key] = value;
                if (af_key !== 'company' && value !== ''){
                    locations.push(value) ;
                }
            }

            this.getAddressLocation(address, locations.join(", "), address.CountryName);
        },
        async addItem(event){
            this.isAddingItem = true;

            if (
                (this.item_type === 'address' && this.addForm.address_friendly_name === '') ||
                (this.item_type === 'location' && this.addForm.location_friendly_name === '')
            ){
                this.show_error = true;
                return;
            }
            this.show_error = false;
            var params;
            switch (this.item_type){
                case 'address':
                    params = {
                        name: this.addForm.address_friendly_name,
                        description: this.addForm.address_description,
                        company: this.addForm.company,
                        line_1: this.addForm.line_1,
                        line_2: this.addForm.line_2,
                        city: this.addForm.city,
                        state: this.addForm.state,
                        zip_code: this.addForm.zip_code,
                        country: this.addForm.country,
                        latitude: this.addForm.latitude,
                        longitude: this.addForm.longitude,
                        is_default: this.addForm.is_default,
                        item_type: this.item_type
                    };
                    if (this.selected_item){
                        params.item_id = this.selected_item.id;
                    }
                    break;
                case 'location':
                    params = {
                        name: this.addForm.location_friendly_name,
                        description: this.addForm.location_description,
                        address_id: this.addForm.address_id,
                        is_primary: this.addForm.is_primary,
                        item_type: this.item_type
                    };

                    if (this.selected_item){
                        params.item_id = this.selected_item.id;
                    }

                    break;
            }

            if (this.selected_customer_id){
                params.selected_customer_id = this.selected_customer_id;
            }
            const { data } = await addressResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-' + this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        },
        cancelClicked(){
            this.$bvModal.hide('create-'+this.item_type);
            this.$emit('on-cancel-clicked');
        }
    }
}
</script>


