export const DropZoneOptions = {
        url: "required_url",
        acceptedFiles: "image/*",
        thumbnailWidth: 160,
        thumbnailHeight: 160,
        maxFilesize: 10, // 10MB
        manuallyAddFile: true,
        autoProcessQueue: false,
        maxFiles: 1
    };
