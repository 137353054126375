<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="sp_location_overview_tab" role="tabpanel" :class="isActiveTab('sp_location_overview_tab')">


                <ParkStorageInLocation
                    ref="park-location-item"
                    modal_id="park-location-item"
                    item_type='sp_locations'
                    modal_title="Park Storage"
                    @on-park-storage="refreshItem"
                >
                </ParkStorageInLocation>

                <RemoveStorageLocation
                    ref="vacate-location-item"
                    modal_id="vacate-location-item"
                    item_type='sp_locations'
                    modal_title="Remove Storage"
                    @on-remove-storage="refreshItem"
                >
                </RemoveStorageLocation>


                <SpLocationOverviewTab
                    :item_info="item_info"
                    :perform-action="performAction"
                    :show-modal="showModal"
                    :get-location-status="getLocationStatus"
                />
            </div>
            <div class="tab-pane" id="sp_location_movements_tab" role="tabpanel" :class="isActiveTab('sp_location_movements_tab')">
                <ObjectMovementList :item_info="item_info" object_type="sp_locations"/>
            </div>
            <div class="tab-pane" id="sp_location_activity_tab" role="tabpanel" :class="isActiveTab('sp_location_activity_tab')">
                <SpSharedActivityTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="sp_location_media_tab" role="tabpanel" :class="isActiveTab('sp_location_media_tab')">
                <MediaTab :item_info="item_info" object_type="sp_locations"/>
            </div>
        </div>
    </div>
</template>
<script>

import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";
import SpSharedActivityTab from "./SpSharedActivityTab";
import ObjectMovementList from "./ObjectMovementList";
import SpLocationOverviewTab from "./SpLocationOverviewTab";
import SpLocationShared from "@/mixins/SpLocationShared.mixin";
import ParkStorageInLocation from "../customers/sp_locations/ParkStorageInLocation";
import RemoveStorageLocation from "../customers/sp_storages/RemoveStorageLocation";

export default {
    name: 'CustomSpLocationDetails',
    components: {
        RemoveStorageLocation,
        ParkStorageInLocation, SpLocationOverviewTab, ObjectMovementList, SpSharedActivityTab, MediaTab },
    props: ['item_info'],
    mixins: [SpLocationShared],
    data(){
        return {
            active_tab: 'sp_location_overview_tab',
            tabs: {
                'sp_location_overview_tab': {
                    'id': "sp_location_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "sp_location_overview_tab",
                    'isActive': false
                },
                'sp_location_movements_tab': {
                    'id': "sp_location_movements_tab",
                    'name': "Movements",
                    'icon': "user",
                    'type': "sp_location_movements_tab",
                    'isActive': false
                },
                'sp_location_activity_tab': {
                    'id': "sp_location_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "sp_location_activity_tab",
                    'isActive': false
                },
                'sp_location_media_tab': {
                    'id': "sp_location_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "sp_location_media_tab",
                    'isActive': false
                }
            },
        }
    },
    created() {
        let sp_locations_active_tab = localStorage.getItem('sp_locations_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'movements':
                    this.active_tab = 'sp_location_movements_tab';
                    break;
                case 'activity':
                    this.active_tab = 'sp_location_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'sp_location_media_tab';
                    break;
            }
        }else if (sp_locations_active_tab && sp_locations_active_tab !== ''){
            this.active_tab = sp_locations_active_tab;
        }

    },
    mounted() {
        /*Function Calls*/
        this.setupJQueryMethods();
    },
    methods:{

        refreshItem(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_asset){ return; }
            if (required_keys){
                for (const requiredKeysKey in required_keys) {
                    let required_key = required_keys[requiredKeysKey];
                    this.item_info[required_key] = edited_asset[required_key];
                }
            }else{
                let message = edited_asset ? ('Saved Successfully') : '';
                window.flash(message);
                location.reload();
            }
        },
        tabClicked(tab_id){
            localStorage.setItem('sp_locations_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
