<template>
    <Layout>
        <main>

            <div class="testItems-wrapper">
                <!-- Page-Content start-->
                <div id="page-content-wrapper">
                    <div class="page-content-inner">
                        <div>
                            <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                        </div>

                        <div class="product-page-header">
                            <h2 class="title">Test Item List</h2>
                            <div class="filter-searchbar dropdown">
                                <search-box :ref="'search-box'"
                                            placeholder_text="Search Test Items.."></search-box>
                                <b-dropdown dropleft
                                            class="add-filter" variant="danger">
                                    <template slot="button-content">
                                        <span class="add-filter-button"></span>
                                    </template>
                                    <b-dropdown-item>Test Item</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="page-value-info">
                                <ul class="d-flex align-items-center">
                                    <li class="d-flex align-items-center">
                                        Total: <b>10</b>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="product-filter-option-wrapper clearfix d-flex">

                            <div >Items <span>1-10</span> of <span>10</span></div>

                            <div class="filter-wrap d-flex align-items-center ml-auto">


                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Sort: <b>name</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                </b-dropdown>


                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Show: <b>10 per page</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                </b-dropdown>
                            </div>

                        </div>
                        <!-- Product Section Start-->

                        <div class="main-table-wrapper">
                            <!-- Desktop View  Start-->
                            <div class="table-container">
                                <div class="table-responsive scrollbar scroll-style">
                                    <div id="main_table">
                                        <div class="table_heding">
                                            <div class="table_inner_heading d-flex-p">
                                                <div class="header_item check_box_input_title">
                                                    <div class="form-group">
                                                        <input class="styled-checkbox" id="styled-checkbox-99" type="checkbox" value="value1">
                                                        <label for="styled-checkbox-99"></label>
                                                    </div>
                                                </div>
                                                <div class="header_item">
                                                    <span>Title</span>
                                                </div>
                                                <div class="header_item"><span>Status</span></div>
                                                <div class="header_item text_center_block"><span>Qty</span></div>
                                                <div class="header_item"><span>Usage</span></div>
                                                <div class="header_item"><span>Location</span></div>
                                                <div class="header_item"><span>Ref No.</span></div>
                                                <div class="header_item"><span>Assigned</span></div>
                                                <div class="header_item"><span>Value</span></div>
                                                <div class="header_item text_center_block"><span>Hero</span></div>
                                                <div class="header_item text_center_block"><span>Asset</span></div>
                                                <div class="header_item text_center_block"></div>
                                            </div>
                                        </div>
                                        <div class="table_body">
                                            <div v-for="(product, key) in [1,2,3,4,5,6,7,8,9,10]" class="table_inner_body d-flex-p">
                                                <div class="check_box_input_title tbody_item">
                                                    <div class="form-group">
                                                        <input class="styled-checkbox" :id="'styled-checkbox-' + key" type="checkbox" value="value2">
                                                        <label :for="'styled-checkbox-' + key"></label>
                                                    </div>
                                                </div>
                                                <div class="tbody_item">
                                                    <span>Asset Tiger</span>
                                                </div>
                                                <div class="tbody_item">
                                                    <span class="active_button button_size">Active</span>
                                                </div>
                                                <div class="text_center_block tbody_item">
                                                    <span class="romans">8</span>
                                                </div>
                                                <div class="tbody_item">
                                                    <span>Off Camera</span>
                                                </div>
                                                <div class="tbody_item">
                                                    <span>Bedroom 1</span>
                                                </div>
                                                <div class="tbody_item">
                                                    <span>123 / 456 / 789</span>
                                                </div>
                                                <div class="tbody_item">
                                                    <span>Tristian Roberts</span>
                                                </div>
                                                <div class="tbody_item">
                                                    <span class="bold_font">£6,352.00</span>
                                                </div>
                                                <div class="text_center_block tbody_item">
                                                    <span><img src="/images/check_icon.png" alt="check_icon"></span>
                                                </div>
                                                <div class="text_center_block tbody_item">
                                                    <span><img src="/images/check_icon.png" alt="check_icon"></span>
                                                </div>
                                                <div class="text_center_block tbody_item">
                                                    <a href="JavaScript:void(0)"><img src="/images/dots_icon.png" alt="dots_icon"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Desktop View End -->

                            <!-- Mobile Table View Start -->
                            <div class="table-accordion-block">
                                <div class="title-qty-heading">
                                    <div class="mt-title">
                                        <p>Title</p>
                                    </div>
                                    <div class="mt-qty">
                                        <p>Qty</p>
                                    </div>
                                </div>
                                <div class="ct_accordion_block">
                                    <div v-for="(product, key) in [1,2,3,4,5,6,7,8,9,10]"
                                         class="ct_accordion_wrap"
                                         :class="key % 2 === 0 ? 'ct_bg_color' : ''"
                                    >
                                        <div class="ct_accordion_lable">
                                            <div class="mt-item-title">
                                                <span class="active_dots"></span>
                                                <h4>Asset Tiger</h4>
                                            </div>
                                            <div class="mt-qty-number">
                                                <p>8</p>
                                            </div>
                                        </div>
                                        <div class="ct_accordion_info">
                                            <div class="mt-content-desc">
                                                <ul>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Status</p>
                                                        </div>
                                                        <div class="mt-info-details active-df">
                                                            <div class="mt-active-label">
                                                                <span>Active</span>
                                                            </div>
                                                            <div class="mt-more-link">
                                                                <a href="#0"><img src="/images/m_dots_icon.png" alt="Dost"> </a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Usage</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>On Camera</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Location</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>Bedroom 1</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Ref No.</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>123 / 456 / 789</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Assigned</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>Tristian Roberts</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Value</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label-b">
                                                                <p>£6,352.00</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Hero</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label-icon">
                                                                <img src="/images/m_check_icon.png" alt="Check Icon">
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Asset</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label-icon">
                                                                <img src="/images/m_check_icon.png" alt="Check Icon">
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ct_accordion_wrap">
                                        <div class="ct_accordion_lable">
                                            <div class="mt-item-title">
                                                <span class="active_dots"></span>
                                                <h4>Eurovision</h4>
                                            </div>
                                            <div class="mt-qty-number">
                                                <p>1</p>
                                            </div>
                                        </div>
                                        <div class="ct_accordion_info">
                                            <div class="mt-content-desc">
                                                <ul>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Status</p>
                                                        </div>
                                                        <div class="mt-info-details active-df">
                                                            <div class="mt-active-label">
                                                                <span>Active</span>
                                                            </div>
                                                            <div class="mt-more-link">
                                                                <a href="#0"><img src="/images/m_dots_icon.png" alt="Dost"> </a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Usage</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>On Camera</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Location</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>Bedroom 1</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Ref No.</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>123 / 456 / 789</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Assigned</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label">
                                                                <p>Tristian Roberts</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Value</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label-b">
                                                                <p>£6,352.00</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Hero</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label-icon">
                                                                <img src="/images/m_check_icon.png" alt="Check Icon">
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="mt-info-title">
                                                            <p>Asset</p>
                                                        </div>
                                                        <div class="mt-info-details">
                                                            <div class="mt-info-label-icon">
                                                                <img src="/images/m_check_icon.png" alt="Check Icon">
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Mobile Table View End -->
                        </div>


                    </div>
                </div>
            </div>
        </main>
    </Layout>
</template>



<script>

import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";

export default {
    name: "ListViewTest",
    components: {Layout, SearchBox},
    data(){
        return {
            breadcrumb_items: [],
        }
    },
}
</script>


