export const FileShortKeysList = {
    uid: {
        key: 'id',
        label: "ID",
        class: "col-lg-2 fil_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                'status-background-grey',
                'status-background-green',
                'status-background-red'
            ];
            return '<span class="file-uid-span button_size ' + (status_list[item.status]) + '">' + item[key] + '</span>';
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-8 fil_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.status === 2){
                return '<a href="javascript:void(0);" class="text-dark">' + item[key] + '</a>';
            }
            return '<a href="'+item.file_url+'" class="text-dark">' + item[key] + '</a>';
        }
    },
};
