<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">

            <b-form-group v-if="item_info.item_type === 'users' && item_info.current_page_type === 'assetflow'" label="Choose Customer/SP" label-for="owners_list">

                <b-form-select
                    id="owners_list"
                    name="customer_sp"
                    v-model="customer_sp"
                    @change="changedOwner(false)">
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                    <option v-for="(owner, key) in owners_list" :key="key" :value="owner">
                        {{ owner.item_type | titleize }} => {{ owner.text }}
                    </option>
                </b-form-select>

            </b-form-group>

            <b-form-group label="Choose Module" label-for="modules_list">
                <b-form-select
                    id="modules_list"
                    name="modules_list"
                    :disabled="modulesListLoading"
                    v-model="selected_module"
                    :options="modules_list"
                    @change="changedOwner(true)"
                    value-field="id"
                    text-field="name"
                >
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>

            <b-form-group label="Choose Role" label-for="roles_list">
                <b-form-select
                    id="roles_list"
                    name="role"
                    v-model="$v.form.item.$model"
                    :options="received_items_list"
                    :disabled="modulesListLoading"
                    :state="validateState('item')"
                    aria-describedby="live-feedback"
                    value-field="id"
                    text-field="name"
                >
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('roles-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    import Resource from '@/api/resource';
    import { validationMixin } from "vuelidate";
    import Commons from "@/mixins/common_methods.mixin";
    import { required } from "vuelidate/lib/validators";

    export default {
        name: "AssignToRole",
        mixins: [validationMixin, Commons],
        props: {
            items: Array,
            item_info: Object
        },
        data() {
            return {
                item_id: null,
                selected_item: "",
                form: {
                    item: ""
                },
                received_items_list: null,
                customer_sp: '',
                owners_list: null,
                modulesListLoading: false,
                selected_module:"",
                modules_list:[]
            }
        },
        validations: {
            form: {
                item: {
                    required
                }
            }
        },
        created() {
            this.getModulesList(this.item_info.id, this.item_info.item_type);

            this.item_id = this.item_info.id;
            if (this.item_info.item_type === 'users' && this.item_info.current_page_type === 'assetflow'){
                this.received_items_list = null;
                this.getOwnersList();
            }else{
                this.received_items_list = this.items;
            }
        },
        methods: {
            async getModulesList(item_id, item_type) {
                this.modulesListLoading = true;
                let listQuery = {
                    owner_item_type: item_type,
                    owner_id: item_id,
                    current_page_type: this.item_info.current_page_type,
                }

                const moduleResource = new Resource('api/assetflow/modules');

                const { data } = await moduleResource.list(listQuery);

                this.modules_list =  data.data.items;
                this.received_items_list = [];
                this.modulesListLoading = false;
            },
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    item: ""
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                console.log("Form submitted! " + this.form.item + " >> " + this.item_id);

                this.$emit('assigned-role', this.form.item);
                this.resetForm();

            },
            async getOwnersList() {
                this.getCustomersSpsList(this.item_info)
                    .then(response => {
                        let status = response.data.data.status;
                        if (status) {
                            var items = response.data.data.items;
                            this.owners_list = items;
                            if (items.length > 0){
                                var first_object = items[0];
                                var owner_id = first_object.value,
                                    item_type = first_object.item_type;
                                this.$emit('changed-owner', owner_id, item_type);
                            }

                        }
                    }
                );
            },
            changedOwner(is_module=false) {
                let owner_id = this.item_info.id,
                    owner_type = this.item_info.item_type;

                if (this.customer_sp){
                    owner_id = this.customer_sp.value;
                    owner_type = this.customer_sp.item_type;
                }else if (this.item_info.item_type === 'users'){
                    owner_id = this.current_entity.id;
                    owner_type = this.current_entity.entity_type;
                }
                if (is_module){
                    this.$emit('changed-owner', owner_id, owner_type, this.selected_module);
                }else{
                    this.getModulesList(this.customer_sp.value, this.customer_sp.item_type);
                }
            },
            refreshRolesList(items){
                // console.log("should refresh roles??");
                // console.log(items);
                this.received_items_list = items;
            }

        }
    }
</script>
