<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_intelligence",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    data(){
        return {
            addForm:{
                intelligence_parent_group: 1,
                intelligence_industry_usage: 1,
                intelligence_custom_fields: 1,
                intelligence_policy: 1
            },
            sidebar_navigation: {
                intelligence: {
                    label: 'Intelligence',
                    settings: {
                        intelligence_parent_group: {
                            id: 'intelligence_parent_group',
                            label: 'Parent Group Specific Content',
                            functionality: 'core'
                        },
                        intelligence_industry_usage: {
                            id: 'intelligence_industry_usage',
                            label: 'Industry Usage',
                            functionality: 'core'
                        },
                        intelligence_custom_fields: {
                            id: 'intelligence_custom_fields',
                            label: 'Custom Fields',
                            functionality: 'core'
                        },
                        intelligence_policy: {
                            id: 'intelligence_policy',
                            label: 'Asset Policy',
                            functionality: 'core'
                        },
                    }
                },
            }
        }
    }
}
</script>

