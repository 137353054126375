<template>
    <div class="row" v-if="item_info">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title_html="getHtmlTitleFor('sp_storages')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Name</span>
                                        <p v-html="getHtmlTitleFor('sp_storages')"></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Storage Type</span>
                                        <p>
                                            <span v-if="item_info.hasOwnProperty('storage_wrapper_info') && item_info.storage_wrapper_info">
                                                <i :class="item_info.storage_wrapper_info.icon"></i>{{ item_info.storage_wrapper_info.singular_name }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Location Status</span>
                                        <p v-html="getLocationStatus(item_info.location_status)"></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Location</span>
                                        <div v-if="item_info.hasOwnProperty('location_info') && item_info.location_info" v-html="getLocationBlock(item_info)"></div>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Status</span>
                                        <p v-html="getSpStorageStatus(item_info.status)"></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Customer</span>
                                        <p v-if="item_info.hasOwnProperty('customer_info') && item_info.customer_info">
                                            <i class="fa fa-star"></i> <a :href="'/' + item_info.current_page_type + '/customers/'+item_info.customer_info.id+'/view'" class="text-dark">{{ item_info.customer_info.name }}</a>
                                        </p>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Assigned Date</span>
                                        <p v-if="item_info.hasOwnProperty('latest_assignment_info') && item_info.latest_assignment_info">
                                            {{ item_info.latest_assignment_info.start_date | moment('DD/MM/YYYY') }}
                                        </p>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>No of Assets</span>
                                        <p v-if="item_info.hasOwnProperty('assets_count')">
                                            {{ item_info.assets_count }}
                                        </p>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Stock</span>
                                        <div class="cm_status_ul">
                                            <ul>
                                                <li>
                                                    <p>Total <span class="total_box_bg">{{ item_info.total_quantity || 0 }}</span></p>
                                                </li>
                                                <li>
                                                    <p>Available <span class="available_box_bg">{{ item_info.available_quantity || 0 }}</span></p>
                                                </li>
                                                <li>
                                                    <p>Reserved <span class="reserved_box_bg">{{ item_info.reserved_quantity || 0 }}</span></p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Area</span>
                                        <p><span>{{ item_info.total_area || 0 }}<sup>m2</sup></span></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Volume</span>
                                        <p><span>{{ item_info.total_volume || 0 }}<sup>m3</sup></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title_html="getHtmlTitleFor('sp_storages')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>

                        <div class="cm_product_more_btn" v-if="has_sp_storage_actions_permissions">
                            <div class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                    <i class="mdi mdi-dots-horizontal"></i>
                                </button>

                                <SpStorageActions
                                    :go-to-relocate-wizard="goToRelocateWizard"
                                    :index="0"
                                    :item="item_info"
                                    :perform-action="performAction"
                                    :permission_name="permission_name"
                                    :permissions_list="permissions_list"
                                    :show-modal="showModal"/>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="cm_product_content">
                                    <div class="cm_product_status_grid">
                                        <div class="cm_list_view_title"></div>
                                        <div class="cm_status_ul">
                                            <ul>
                                                <li>
                                                    <p>Total <span class="total_box_bg">{{ item_info.total_quantity || 0 }}</span></p>
                                                </li>
                                                <li>
                                                    <p>Available <span class="available_box_bg">{{ item_info.available_quantity || 0 }}</span></p>
                                                </li>
                                                <li>
                                                    <p>Reserved <span class="reserved_box_bg">{{ item_info.reserved_quantity || 0 }}</span></p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>
    </div>
</template>
<script>

import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import SpStorageActions from "../customers/sp_storages/SpStorageActions";
export default {
    name: 'SpStorageOverviewTab',
    components: {SpStorageActions, NewCustomGenericBlock},
    mixins:[],
    data(){
        return {
            permission_name: 'sp_storages',
            has_sp_storage_actions_permissions: false,
            permissions_list: []
        }
    },
    props: {
        item_info: {},
        showModal: {},
        performAction: {},
        goToRelocateWizard: {},
        getLocationStatus: {},
        getLocationBlock: {},
        getSpStorageStatus: {},
    },
    created() {
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);
        this.has_sp_storage_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];
    },
    methods:{
    }
}
</script>
