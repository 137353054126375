<template>

    <ul :class="ul_class + ([8,9, 10].includes(filter_type) && is_first ? (' ' + filter_classes[filter_type] + '-first') : '') " v-if="items && items.length > 0"
        :style="!is_first ? 'display: none' : ''">
        <li v-for="item in items" :class="getSidebarItemClass(item)">

            <div v-if="item.hasOwnProperty('items_count')" class="stock-badge-block">
                <span class="badge badge-secondary badge-stock af-accent">{{item.items_count ? item.items_count : 0}}</span>
            </div>

            <a class="folder-item" @click="changeGroup(item, $event)" :ref="'item-' + item.id">

                <i v-if="item.hasOwnProperty('icon') && item.icon !== ''" :class="'font-size-18 ' + item.icon"></i>
                <i v-else-if="[0, 1].includes(filter_type) && item.key && item.key !== ''" :class="'font-size-18 bx bxs-circle status-icon status-icon-' + item.key"></i>

                <img v-if="item.hasOwnProperty('avatar_url') && item.avatar_url !== ''"
                     class="rounded-circle header-profile-user"
                     :src="item.avatar_url"
                     :alt="item.name"
                />
                <img v-else-if="item.hasOwnProperty('avatar_name') && item.avatar_name !== ''"
                     class="rounded-circle header-profile-user"
                     :src="item.avatar_name"
                     :alt="item.name"
                />

                <span v-if="item.hasOwnProperty('character_number')" class="character_number">({{item.character_number}}) </span>
                <span v-else-if="item.hasOwnProperty('shoot_date')" class="shoot_date"> {{item.shoot_date | moment('MMMM Do YYYY')}} </span>

                <span v-if="item.hasOwnProperty('supplier_id')" class="purchase_order_item filter-item-name pl-1">
                    {{ POWithUIDs(item) }}
                </span>
                <span v-else-if="item.hasOwnProperty('nominal_code')" class="p-card-account"> {{item.nominal_code}} </span>
                <span v-else class="filter-item-name pl-1">
                    {{ item.name || item.label || item.description }}
                    <sup v-if="item.hasOwnProperty('subfolders') && item.subfolders && item.subfolders.length > 0"> {{item.subfolders.length}}</sup>
                </span>

            </a>

            <objects-sidebar-row
                v-if="item.subfolders"
                :items="item.subfolders"
                :filter_type="filter_type"
                :item_type="item_type"
                :selected_customer="selected_customer"
                :is_group="is_group"
                :is_first="false"
                :ul_class="'subfolder ' + filter_classes[filter_type] + '-list custom-icons-list'"
                @on-group-changing="changeGroup"
            ></objects-sidebar-row>
        </li>
    </ul>
    <ul v-else-if="is_first" :class="ul_class">
        <li v-if="is_group && [12, 13].includes(filter_type) && !selected_customer.id">
            <span class="mr-auto">For this list please first select a {{ is_group_tv_film ? 'production' : 'customer' }}.</span>
        </li>
        <li v-else-if="item_names[filter_type] !== ''">
            <span class="mr-auto">No {{item_names[filter_type]}} Yet.</span>
        </li>
    </ul>
</template>

<script>



export default {
    name: "ObjectsSidebarRow",
    props: [
        'items',
        'object_type',
        'ul_class',
        'is_first',
        'filter_type',
        'item_type',
        'is_group',
        'selected_item',
        'selected_customer',
        'received_item_names'
    ],
    mounted() {
        // if (this.is_first && this.items){
        //     this.changeGroup(this.items[0]);
        // }

        this.has_folders_actions_permissions = this.hasPermission('edit_folders') || this.hasPermission('delete_folders') || this.hasPermission('add_folders');
        this.has_tags_actions_permissions = this.hasPermission('edit_tags') || ((this.hasPermission('delete_tags') || this.hasPermission('edit_assets')));

        this.has_addresses_actions_permissions = this.hasPermission('edit_addresses') || (this.hasPermission('delete_addresses') || this.hasPermission('edit_assets'));

    },
    data() {
        return {
            has_folders_actions_permissions: false,
            has_tags_actions_permissions: false,
            has_addresses_actions_permissions: false,
            filter_classes: {
                '0': 'all',
                '1': '',
                '2': 'statuses',
                '3': 'af-categories',
                '4': 'tags',
                '5': 'users',
                '6': 'addresses-filter',
                '7': 'suppliers-filter',
                '8': 'industry-sector-filter',
                '9': 'fdp-filter',
                '10': 'custom-fields-filter',
                '12': 'events-filter',
                '13': 'departments-filter',
                '14': 'liability-filter',
                '15': 'fdp_status-filter',
                '16': 'characters-filter',
                '18': 'reconciliation-filter',
            },
            item_names: {
                '0': '',
                '1': 'Folders',
                '2': 'Statuses',
                '3': 'Categories',
                '4': 'Tags',
                '5': 'Users',
                '6': 'Addresses',
                '7': 'Suppliers',
                '8': 'Industry \\ Sectors',
                '9': '',
                '15': 'FDP Status',
                '10': '',
                '12': 'Events',
                '13': 'Departments',
                '14': 'Liability',
                '16': 'Characters',
                '18': 'Reconciliation'
            },
            one_item_class: ''
        }
    },
    created() {
        if (this.ul_class){
            this.one_item_class = this.ul_class.replace('subfolder ', '').replace('-list custom-icons-list', '-item');
        }
        if (this.is_group){
            this.filter_classes = {
                '0': 'all',
                '2': 'group-statuses',
                '3': 'group-categories',
                '10': 'group-asset-types',
                '12': 'group-asset-events',
                '13': 'group-asset-departments',
                '14': 'group-asset-liability',
                '15': 'group-asset-fdp_status'
            };
            this.item_names = {
                '0': '',
                '2': 'Statuses',
                '3': 'Categories',
                '10': 'Asset Types',
                '12': 'Events',
                '13': 'Departments',
                '14': 'Liability',
                '15': 'FDP Status'
            };
        }
        if (this.received_item_names){
            this.item_names = this.received_item_names;
        }
    },
    methods: {
        getSidebarItemClass(item){
            let class_dict = {
                'filled' : item.subfolders && item.subfolders.length > 0,
                'active' : this.selected_item && item.id === this.selected_item.id,
            };

            if (this.one_item_class !== ''){
                class_dict[this.one_item_class] = true;
            }
            if (item.id === 0){
                class_dict['not-defined-item'] = true;
            }
            return class_dict;
        },
        canDelete(item, item_type='folders'){
            if (item_type === 'folders'){
                return item.subfolders.length === 0 && item.assets_count === 0;
            }
            return item.assets_count === 0;
        },
        changeGroup(selected_item, event){
            this.$emit('on-group-changing', selected_item);
        },
        createFolder(parent_id=''){
            this.$emit('on-folder-creation', parent_id);
        },
        editTag(item=null, item_type='tags'){
            this.$emit('on-tag-editing', item, item_type);
        },
        addLocation(item=null){
            this.$emit('on-add-location', item);
        },
        untagAssets(item='', item_type='tags', event){
            this.$emit('on-untag-assets', item, item_type);
        },
        deleteTag(item_id='', item_type='', event){
            this.$emit('on-folder-deletion', item_id, item_type, event);
        },
        editFolder(item=null){
            this.$emit('on-folder-editing', item);
        },
        moveFolder(item=null){
            this.$emit('on-folder-moving', item);
        },
        deleteFolder(item_id='', item_type='folders', event){
            this.$emit('on-folder-deletion', item_id, item_type, event);
        },
        moveContent(item='', item_type='folders', event){
            this.$emit('on-content-moving', item, item_type, event);
        },

    }

}
</script>

<style scoped>


</style>
