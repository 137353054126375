<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <add-new-form
            ref="add-new-address"
            item_type="address"
            modal_title="Create New Address"
        >
            <template v-slot:cancel-button>
                <b-button href="./" variant="outline-danger">Cancel</b-button>
            </template>
        </add-new-form>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import AddNewForm from "./AddNewForm";
export default {
    name: "NewAddress",
    props: ['item_id', 'item_type', 'action'],
    components: {AddNewForm, Layout, PageHeader},
    data() {
        return {
            isLoadingItem:false,
            title: "Add New Address",
            items: [
                {
                    text: "Addresses",
                    href: "/"
                },
                {
                    text: "Add Address",
                    active: true
                }
            ],
        }
    },
    created() {
        if((this.action === 'edit') && this.item_id !== ''){
            this.fetchInfoOf();
            this.title = this.title.replace('Add New', 'Edit');
            this.items[1].text = this.items[1].text.replace('Add', 'Edit');
        }
    },
    methods:{
        fetchInfoOf() {
            this.isLoadingItem = true;
            axios({
                method:'get',
                url:'/api/assetflow/addresses/' + this.item_id + "?action=" + this.action,
                baseURL: '/'
            }).then(response => {
                var item_info = response.data;

                this.$refs['add-new-address'].setSelectedItem(item_info);
                this.isLoadingItem = false;
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });

        },
    }
}
</script>


