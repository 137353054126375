<script>
import UserMeta from "@/mixins/usermeta.mixin";

export default {
    mixins: [UserMeta],
    components: { },
    props: ['sidebar_list', 'items_list'],
    data() {
        return {
            sidebar_items: null,
            filemanager_items: null,
            hidden_sidebar: false,
            selected_item: null,
        };
    },
    created() {
        this.sidebar_items = this.sidebar_list;
        this.filemanager_items = this.items_list;
        this.selected_item = this.filemanager_items[0];
    },
    methods: {
        changeGroup(selected_item, event){
            event.preventDefault();

            this.$emit('on-group-changing', selected_item);
        },
        toggleSideBar(){
            this.hidden_sidebar = !this.hidden_sidebar;
        },
        deleteItem(item_id, item_name, item_type, event) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    this.$emit('on-item-deleted');
                });
        },
    }
};
</script>
<template>
    <div>
        <div class="row mb-3">
            <div class="col-lg-12">
                <b-button @click="toggleSideBar"><i class="fa fa-fw fa-bars"></i> Hide Sidebar</b-button>
            </div>
        </div>

        <div class="d-xl-flex">
            <div class="w-100">
                <div class="d-md-flex">
                    <div v-if="!this.hidden_sidebar" class="card filemanager-sidebar mr-md-2">
                        <div class="card-body">
                            <div class="d-flex flex-column h-100">
                                <div class="mb-4">
                                    <div class="mb-3">
                                        <b-dropdown
                                            toggle-class="btn-block"
                                            variant="light"
                                            class="btn-block"
                                        >
                                            <template #button-content>
                                                <i class="mdi mdi-plus mr-1"></i> Create New
                                            </template>
                                            <b-dropdown-item href="./assets/new">
                                                <i class="bx bx-cube mr-1"></i> Asset
                                            </b-dropdown-item>
                                            <b-dropdown-item href="./folders/new">
                                                <i class="bx bx-folder mr-1"></i>Folder
                                            </b-dropdown-item>
                                            <b-dropdown-item href="./assets/csv_import">
                                                <i class="bx bx-spreadsheet mr-1"></i>CSV Import
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    <ul v-if="sidebar_items" class="list-unstyled categories-list tree-children">
                                        <li v-for="sidebar_item in sidebar_items"
                                            :class="{'dropdown-item' : !sidebar_item.children_list}">
                                            <div v-if="sidebar_item.children_list" class="custom-accordion">
                                                <a class="text-body font-weight-medium py-1 d-flex align-items-center"
                                                   data-toggle="collapse"
                                                   v-b-toggle.categories-collapse
                                                   role="button"
                                                   aria-expanded="false"
                                                   aria-controls="categories-collapse">
                                                    <i class="mdi mdi-folder font-size-16 text-warning mr-2">
                                                    </i>
                                                    {{sidebar_item.name | titleize}}
                                                    <i class="mdi mdi-chevron-up accor-down-icon ml-auto">
                                                    </i>
                                                </a>
                                                <b-collapse visible
                                                            class="collapse show"
                                                            id="categories-collapse">
                                                    <div class="card border-0 shadow-none pl-2 mb-0">
                                                        <ul class="list-unstyled mb-0">
                                                            <li v-for="child_item in sidebar_item.children_list" class="dropdown-item">
                                                                <a :href="child_item.link"
                                                                   @click="changeGroup(child_item, $event)"
                                                                   class="text-body d-flex align-items-center">
                                                                    <i :class="child_item.classes"></i>
                                                                    <span class="mr-auto">{{child_item.name | titleize}}</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </b-collapse>
                                            </div>

                                            <a v-else
                                               href="#"
                                               @click="changeGroup(sidebar_item, $event)"
                                               class="text-body d-flex align-items-center">
                                                <i :class="sidebar_item.classes"></i>
                                                <span class="mr-auto">{{sidebar_item.name | titleize}}</span>
                                            </a>

                                        </li>
                                    </ul>

                                    <ul v-else class="list-unstyled categories-list">
                                        <li>
                                            <span class="mr-auto">Nothing To Show.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filemanager-leftsidebar -->
                    <div class="w-100">
                        <div class="card">
                            <div class="card-body">
                                <div>
                                    <div class="row mb-3">
                                        <div class="col-xl-3 col-sm-6">
                                            <div class="mt-2">
                                                <h5>My Files</h5>
                                            </div>
                                        </div>
                                        <div class="col-xl-9 col-sm-6">
                                            <form class="mt-4 mt-sm-0 float-sm-right form-inline">
                                                <div class="search-box mb-2 mr-2">
                                                    <div class="position-relative">
                                                        <input
                                                            type="text"
                                                            class="form-control bg-light border-light rounded"
                                                            placeholder="Search..."
                                                        />
                                                        <i class="bx bx-search-alt search-icon"></i>
                                                    </div>
                                                </div>
                                                <b-dropdown
                                                    class="mb-0"
                                                    toggle-class="btn btn-link text-muted"
                                                    right
                                                    variant="white"
                                                >
                                                    <template #button-content>
                                                        <i class="mdi mdi-dots-vertical font-size-20"></i>
                                                    </template>
                                                    <b-dropdown-item href="#"
                                                    >Share Files</b-dropdown-item
                                                    >
                                                    <b-dropdown-item href="#"
                                                    >Share with me</b-dropdown-item
                                                    >
                                                    <b-dropdown-item href="#"
                                                    >Other Actions</b-dropdown-item
                                                    >
                                                </b-dropdown>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <hr class="mb-4" />
                                <div>
                                    <div v-if="this.filemanager_items.length != 0" class="row">
                                        <div v-for="item in filemanager_items" class="col-xl-4 col-sm-6">
                                            <div :class="'card shadow-none border ' + (selected_item.id === item.id ? 'border-af-accent' : '')">
                                                <div class="card-body p-3" @click="selected_item = item;">
                                                    <div>
                                                        <div class="float-right ml-2">
                                                            <b-dropdown
                                                                toggle-class="font-size-16 text-muted p-0"
                                                                class="mb-2"
                                                                variant="white"
                                                                right
                                                            >
                                                                <template #button-content>
                                                                    <i class="mdi mdi-dots-horizontal"></i>
                                                                </template>
                                                                <b-dropdown-item @click="selected_item = item;" >Preview</b-dropdown-item>
                                                                <b-dropdown-item :href="'./assets/'+item.id + '/view'">Open</b-dropdown-item>
                                                                <b-dropdown-item :href="'./assets/'+item.id + '/edit'">Edit</b-dropdown-item>
                                                                <b-dropdown-divider></b-dropdown-divider>
                                                                <b-dropdown-item variant="danger" @click="deleteItem(item.id, item.name, 'assets', $event)"
                                                                >Remove</b-dropdown-item
                                                                >
                                                            </b-dropdown>
                                                        </div>

                                                        <div class="avatar-xs mr-3 mb-3">
                                                            <span v-b-tooltip.hover.top title="Customer Logo" class="avatar-title bg-transparent rounded">
                                                                <img v-if="item.customer_logo" :src="item.customer_logo" alt width="50" />
                                                                <img v-else src="images/logo-light.png" alt height="30" />
                                                            </span>
                                                        </div>

                                                        <div class="d-flex">
                                                            <div class="overflow-hidden mr-auto">
                                                                <h5 class="font-size-14 text-truncate mb-1">
                                                                    <a
                                                                        :href="'assets/' + item.id + '/view'"
                                                                        class="text-body"
                                                                    >{{ item.name }}</a
                                                                    >
                                                                </h5>
                                                                <p class="text-muted text-truncate mb-0">
                                                                    <i class="bx bx-cube"></i> {{ item.volume }}
                                                                </p>
                                                            </div>
                                                            <div class="align-self-end ml-2">
                                                                <p class="text-muted mb-0">{{ item.customer_name }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else class="row">
                                        <!-- end col -->
                                        <div class="col-xl-12">
                                            <div class="card shadow-none border">
                                                <div class="card-body p-3">
                                                    Nothing To Show!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->
                                </div>
                                <div class="mt-4">
                                    <div class="d-flex flex-wrap">
                                        <h5 class="font-size-14 mr-3">Recent Files</h5>
                                        <div class="ml-auto">
                                            <a href="javascript: void(0);">View All</a>
                                        </div>
                                    </div>
                                    <hr class="mt-2" />
                                    <div class="table-responsive">
                                        <table
                                            class="table table-centered table-nowrap table-hover mb-0"
                                        >
                                            <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Date modified</th>
                                                <th scope="col" colspan="2">Size</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in filemanager_items">
                                                <td>
                                                    <a
                                                        href="javascript: void(0);"
                                                        class="text-dark font-weight-medium"
                                                    ><i
                                                        class="mdi mdi-file-document font-size-16 align-middle text-primary mr-2"
                                                    ></i>
                                                        index.html</a
                                                    >
                                                </td>
                                                <td>12-10-2020, 09:45</td>
                                                <td>09 KB</td>
                                                <td>
                                                    <b-dropdown
                                                        toggle-class="font-size-16 text-muted p-0"
                                                        variant="white"
                                                        right
                                                    >
                                                        <template #button-content>
                                                            <i class="mdi mdi-dots-horizontal"></i>
                                                        </template>
                                                        <b-dropdown-item href="#">Open</b-dropdown-item>
                                                        <b-dropdown-item href="#">Edit</b-dropdown-item>
                                                        <b-dropdown-item href="#">Rename</b-dropdown-item>
                                                        <b-dropdown-divider></b-dropdown-divider>
                                                        <b-dropdown-item href="#">Remove</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end w-100 -->
                </div>
            </div>
            <div v-if="selected_item" class="card filemanager-sidebar ml-lg-2">
                <div class="card-body">
                    <div class="mt-4">

                        <div class="card border shadow-none mb-2">
                            <a href="javascript: void(0);" class="text-body">
                                <div class="p-2">
                                    <div class="d-flex">
                                        <div class="avatar-xs align-self-center mr-2">
                                            <div class="avatar-title rounded bg-transparent text-danger font-size-20">
                                                <i class="mdi mdi-play-circle-outline"></i>
                                            </div>
                                        </div>
                                        <div class="overflow-hidden mr-auto">
                                            <h5 class="font-size-13 text-truncate mb-1">{{ selected_item.name }}</h5>
                                            <p class="text-muted mb-0">{{ selected_item.description }}</p>
                                        </div>
                                        <div class="ml-2">
                                            <p class="text-muted">{{selected_item.quantity}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="mt-4">
                        <a :href="'assets/' + selected_item.id + '/view'" class="btn btn-success btn-rounded mb-2 mr-2">
                            View <i class="mdi mdi-arrow-right ml-1"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.categories-list li ul li a{
    padding: 4px 0px !important;
}
</style>
