<template>
    <div class="DispositionLeftBox">
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Passport</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="my_asset.hasOwnProperty('cAFID_info') && my_asset.cAFID_info" :href="'/' + item_type + '/asset_passports/' + my_asset.cAFID_info.id + '/view'" class="text-dark">{{ 'PAS-' + my_asset.cAFID_info.uid }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Colours</span>
                        <div v-html="getColourHTML()"></div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Weight</span>
                        <p>
                            <span
                                v-if="my_asset.hasOwnProperty('weight') && my_asset.weight">
                                {{ my_asset.weight }}kg
                            </span>
                            <span v-else>0kg</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Height</span>
                        <p>
                            <span
                                v-if="my_asset.hasOwnProperty('height') && my_asset.height">
                                {{ my_asset.height }}{{ my_asset.preferred_unit }}
                            </span>
                            <span v-else>0m</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Width</span>
                        <p>
                            <span
                                v-if="my_asset.hasOwnProperty('width') && my_asset.width">
                                {{ my_asset.width }}{{ my_asset.preferred_unit }}
                            </span>
                            <span v-else>0m</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Depth</span>
                        <p>
                            <span
                                v-if="my_asset.hasOwnProperty('depth') && my_asset.depth">
                                {{ my_asset.depth }}{{ my_asset.preferred_unit }}
                            </span>
                            <span v-else>0m</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Area/Volume</span>
                        <p>
                            <span
                                v-if="my_asset.hasOwnProperty('total_area') && my_asset.total_area">
                                {{ (my_asset.area * my_asset.quantity).toFixed(2) }}m<sup>2</sup>
                            </span>
                            <span v-else>0m<sup>2</sup></span>
                            <span v-if="my_asset.hasOwnProperty('total_volume') && my_asset.total_volume">
                                {{ (my_asset.volume * my_asset.quantity).toFixed(2) }}m<sup>3</sup>
                            </span>
                            <span v-else>0m<sup>3</sup></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Waste Stream</span>
                        <p><b>{{ my_asset.waste_stream_type === 0 ? 'Product' : 'Material' }}</b></p>
                        <p>
                            <span v-if="my_asset.waste_stream_type === 0 && my_asset.hasOwnProperty('waste_stream_info') && my_asset.waste_stream_info">{{ my_asset.waste_stream_info.name }}</span>
                            <span v-else-if="my_asset.waste_stream_type === 1 && my_asset.hasOwnProperty('composition_info') && my_asset.composition_info">
                                                {{my_asset.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ') }}
                                            </span>
                            <span v-else></span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Current Condition</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="my_asset.hasOwnProperty('condition')">{{ getConditionText(my_asset) }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'SustainabilityRightBox',
    props: {
        my_asset: {},
        item_type: {}
    },
    computed:{
        colours_info(){
            return this.getValueOrDefault(this.my_asset, 'colours_info', null);
        }
    },
    methods:{
        getColourHTML(){
            let colours_info = this.colours_info;
            if (colours_info){
                let html = '';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                return html;
            }
            return '-';
        },
        getConditionText(asset){
            let condition = asset.condition;
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },
    }
}
</script>
