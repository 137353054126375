<template>
    <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
            <h4 class="entity_row_title entity_name mb-3">{{ entity_info.title }}</h4>
        </div>

        <template v-for="(block, key) in entity_info.blocks">
            <div class="col-sm-12 col-lg-12 col-xl-12" v-if="block.hasOwnProperty('custom_title') && block.custom_title !== ''">
                <div v-html="block.custom_title"></div>
            </div>

            <div class="col-sm-12" :key="key" :class="block.required_class">
                <div class="card">
                    <div class="card-header af-custom-colour af-primary" :class="block.hasOwnProperty('header_action_text') && block.header_action_text ? 'split-header' : ''">
                        <div v-if="block.icon === 'iconize' && block.hasOwnProperty('object_type')" v-iconize="block.object_type">{{ block.name }}</div>
                        <div v-else><i :class="block.icon || ''"></i> {{ block.name }}</div>
                        <div v-if="block.hasOwnProperty('header_action_text') && block.header_action_text" :class="block.header_action_class">{{ block.header_action_text }}</div>
                    </div>

                    <div class="card-body" v-if="['modules_info', 'users_info', 'roles_info', 'sp_info', 'orders_info', 'managed_storages_info', 'sp_storages_info'].includes(key)">
                        <DashboardBlockTable :block_info="block" :current_page_type="item_info.current_page_type"/>
                    </div>

                    <div class="card-body" v-else-if="['created_orders_info', 'confirmed_orders_info', 'free_storages_info', 'homeless_storages_info', 'assigned_empty_storages_info', 'storages_movements_info', 'assets_movements_info'].includes(key)">
                        <DashboardBlockTableCustom :block_info="block" :current_page_type="item_info.current_page_type"/>
                    </div>

                    <div class="card-body" v-else v-html="block.content"></div>
                    <div class="card-footer" v-if="block.hasOwnProperty('action')" v-html="block.action"></div>
                </div>
            </div>

        </template>

    </div>
</template>
<script>
import DashboardBlockTable from "../DashboardBlockTable";
import DashboardBlockTableCustom from "../DashboardBlockTableCustom";
export default {
    name: 'DashboardEntityRow',
    components: {DashboardBlockTableCustom, DashboardBlockTable},
    props: {
        item_info: {},
        entity_info: {},
        index: {}
    }
}
</script>
