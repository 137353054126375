<template>
    <Layout>
        <div class="" id="wrapper">
            <div id="sidebar-wrapper" class="sidebar">
                <div class="folder-wrapper">
                    <div class="sub-root-top folder-menu active">
                        <ul class="root-folder custom-icons-root root-statuses">
                            <li class="active filled">

                                <a class="root-heading customer-root">
                                    <i class="font-size-18 bx bx-cog settings-title-icon"></i>
                                    <span class="pl-1">{{ current_customer ? current_customer.name : 'Customer Settings' }}</span>
                                </a>

                                <ul class="subfolder statuses-list custom-icons-list">
                                    <li v-for="setting_item in settings_list" :class="(setting_item.hasOwnProperty('children') && setting_item.children.length > 0 ? 'filled' : '') + getActiveStatus(setting_item)">
                                        <a class="folder-item" @click="clickedSetting(setting_item, $event)">
                                            <i :class="'font-size-18 bx '+setting_item.icon+' settings-icon-' + setting_item.id"></i>
                                            <span class="filter-item-name pl-1">{{ setting_item.name }}<small class="coming_soon text-af-accent" v-if="setting_item.soon"> Soon</small></span>
                                        </a>
                                        <ul v-if="setting_item.hasOwnProperty('children') && setting_item.children.length > 0" class="subfolder statuses-list custom-icons-list" style="display: none;">
                                            <li v-for="child_item in setting_item.children"
                                                :class="getActiveStatus(child_item)" >
                                                <a class="folder-item" @click="clickedSetting(child_item, $event)">
                                                    <i :class="'font-size-18 bx bx-minus settings-icon-' + child_item.id"></i>
                                                    <span class="filter-item-name pl-1">{{ child_item.name }}<small class="coming_soon text-af-accent" v-if="child_item.soon"> Soon</small></span>
                                                </a>

                                                <ul v-if="child_item.hasOwnProperty('children') && child_item.children.length > 0" class="subfolder statuses-list custom-icons-list" style="display: none;">
                                                    <li v-for="subchild_item in child_item.children"
                                                        :class="getActiveStatus(subchild_item)"
                                                    >
                                                        <a class="folder-item" @click="clickedSetting(subchild_item, $event)">
                                                            <i :class="'font-size-18 bx bx-minus settings-icon-' + subchild_item.id"></i>
                                                            <span class="filter-item-name pl-1">{{ subchild_item.name }}<small class="coming_soon text-af-accent" v-if="subchild_item.soon"> Soon</small></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="page-content-wrapper" v-if="selected_setting">
                <setting-content
                    v-if="!selected_setting.hasOwnProperty('children')"
                    :selected_setting="selected_setting" />
                <ul v-else>
                    <li v-for="child in selected_setting.children">
                        <a  @click="clickedSetting(child, $event)">{{ child.name | titleize }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "@/layouts/main";
import SettingContent from "./SettingContent";

export default {
    name: "SettingsTabs",
    mixins: [],
    components: {SettingContent, Layout},
    data(){
        return{
            selected_setting: null,
            selected_setting_name: 'Customer Settings',
            icon: 'bx-cog',
            settings_list:{
                overview:{
                    id: 'overview',
                    name: 'Overview',
                    icon: 'bx-grid',
                    children: [
                        {
                            id: 'customer_status',
                            name: 'Customer & Status',
                        },
                        {
                            id: 'currency_timezone',
                            name: 'Currency & Timezone',
                        },
                        {
                            id: 'logo_appearance',
                            name: 'Logo & Appearance',
                        },
                        {
                            id: 'parent_group',
                            name: 'Parent Group',
                            children:[
                                {
                                    id: 'pg_specific_fields',
                                    name: 'Parent Group Specific Fields & Functionality',
                                    soon: true
                                },
                                {
                                    id: 'pg_inheritance',
                                    name: 'Inheritance',
                                },
                            ]
                        },
                        {
                            id: 'industry',
                            name: 'Industry',
                        }
                    ]
                },
                billing:{
                    id: 'billing',
                    name: 'Billing',
                    icon: 'bx-credit-card',
                    children: [
                        {
                            id: 'group_usage_limits',
                            name: 'Group Usage & Limits',
                        },
                        {
                            id: 'subscriptions_pricing',
                            name: 'Subscriptions & Pricing',
                            soon: true
                        },
                        {
                            id: 'billing',
                            name: 'Billing',
                            soon: true
                        }
                    ]
                },
                features: {
                    id: 'features',
                    name: 'Features',
                    icon: 'bx-receipt',
                    children: [
                        {
                            id: 'features_status',
                            name: 'Status',
                        }
                    ]
                },
                users_access: {
                    id: 'users_access',
                    name: 'Users & Access',
                    icon: 'bx-user-check',
                    children: [
                        {
                            id: 'users_access_users',
                            name: 'Users',
                        },
                        {
                            id: 'inherited_users',
                            name: 'Inherited Users',
                            soon: true
                        },
                        {
                            id: 'users_access_user_roles',
                            name: 'User Roles',
                        },
                        {
                            id: 'inherited_user_roles',
                            name: 'Inherited User Roles',
                            soon: true
                        },
                        {
                            id: 'users_access_service_providers',
                            name: 'Service Providers',
                        },
                        {
                            id: 'inherited_service_providers',
                            name: 'Inherited Service Providers',
                            soon: true
                        }
                    ]
                },
                assets: {
                    id: 'assets',
                    name: 'Assets',
                    icon: 'bx-cube',
                    children: [
                        {
                            id: 'assets_core',
                            name: 'Core',
                        },
                        {
                            id: 'assets_defaults',
                            name: 'Defaults',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assets_structure',
                            name: 'Structure',
                        },
                        {
                            id: 'assets_tracking',
                            name: 'Tracking',
                        },
                        {
                            id: 'assets_workflow',
                            name: 'Workflow',
                        },
                        {
                            id: 'assets_intelligence',
                            name: 'Intelligence',
                        },
                        {
                            id: 'assets_lifecycle',
                            name: 'Lifecycle',
                        },
                        {
                            id: 'assets_labelling',
                            name: 'Labelling',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assets_incoming_assets',
                            name: 'Incoming Assets',
                            soon: true,
                            use_template: true
                        }
                    ],
                },
                asset_flow_roi: {
                    id: 'asset_flow_roi',
                    name: 'Asset Flow ROI',
                    icon: 'bx-line-chart',
                    soon: true,
                    children: [
                        {
                            id: 'assetflow_passports',
                            name: 'Asset Flow Passports',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assetflow_splits',
                            name: 'Asset Flow Splits',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assetflow_bank',
                            name: 'Asset Flow Bank',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assetflow_transfers',
                            name: 'Asset Flow Transfers',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assetflow_loans',
                            name: 'Asset Flow Loans',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'assetflow_marketplace',
                            name: 'Asset Flow Marketplace',
                            soon: true,
                            use_template: true
                        }
                    ]
                },
                accounting: {
                    id: 'accounting',
                    name: 'Accounting',
                    icon: 'bx-bar-chart',
                    soon: true,
                    children: [
                        {
                            id: 'accounting_general',
                            name: 'General',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'accounting_suppliers',
                            name: 'Suppliers',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'accounting_purchase_orders',
                            name: 'Purchase Orders',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'accounting_ledgers',
                            name: 'Ledgers',
                            soon: true,
                            use_template: true
                        }
                    ]
                },
                events: {
                    id: 'events',
                    name: 'Events',
                    icon: 'bx-calendar-alt',
                    soon: true,
                    use_template: true
                },
                address_locations: {
                    id: 'address_locations',
                    name: 'Address & Locations',
                    icon: 'bx-map',
                    soon: true,
                    children: [
                        {
                            id: 'delivery_address',
                            name: 'Delivery Address',
                            soon: true,
                            use_template: true
                        },
                        {
                            id: 'invoicing_address',
                            name: 'Invoicing Address',
                            soon: true,
                            use_template: true
                        },
                    ]
                },
                data_management: {
                    id: 'data_management',
                    name: 'Data Management',
                    icon: 'bx-data',
                    soon: true
                },
                notifications: {
                    id: 'notifications',
                    name: 'Notifications',
                    icon: 'bx-bell',
                    soon: true
                },
                queues: {
                    id: 'queues',
                    name: 'Queues',
                    icon: 'bx-task',
                    soon: true
                },
                ordering: {
                    id: 'ordering',
                    name: 'Ordering',
                    icon: 'bx-list-ol',
                    soon: true
                },
                esg_reporting: {
                    id: 'esg_reporting',
                    name: 'ESG Reporting',
                    icon: 'bx-stats',
                    soon: true
                },
                storage: {
                    id: 'storage',
                    name: 'Storage',
                    icon: 'bx-cuboid',
                    soon: true
                },
                wrap: {
                    id: 'wrap',
                    name: 'Wrap',
                    icon: 'bx-box',
                    soon: true
                },
                zero_waste: {
                    id: 'zero_waste',
                    name: 'Zero Waste',
                    icon: 'bx-revision',
                    soon: true
                },
                system: {
                    id: 'system',
                    name: 'System',
                    icon: 'bx-detail',
                    children: [
                        {
                            id: 'raw_features',
                            name: 'RAW Features'
                        },
                        {
                            id: 'raw_settings',
                            name: 'RAW Settings'
                        }
                    ]
                },
            }
        }
    },
    created() {
    },
    methods:{
        getActiveStatus(setting_item){
            return (this.selected_setting && setting_item.id === this.selected_setting.id ? ' active' : '');
        },
        clickedSetting(item, event){
            this.selected_setting_name = item.name;
            this.selected_setting = item;
        }
    }
}
</script>

