<template>
    <Layout>

        <div class="service_providers-wrapper switch-service_provider-table">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div class="product-page-header">
                        <h2 class="title"><span v-if="current_entity" class="current_entity_name" :class="current_entity.entity_type + '-entity'">{{ current_entity.name }}</span> Service Provider List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        :placeholder_text="'Search Service Providers..'"></search-box>
                            <b-dropdown v-if="hasPermission('add_service_providers')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_service_providers')"
                                                 :href="'./service_providers/new'">Service Provider
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">
                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->

                    <div v-if="service_providersData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'service_providers'"
                                :keys_list="service_provider_list_keys_list">

                                <template v-slot:sp_actions="{ item, index }">

                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item
                                                v-if="permissions_list['view_' + object_type] && item_type !== 'customer'"
                                                :href="'/' + item_type + '/'+object_type+'/' + item.id + '/view'">
                                                View
                                            </b-dropdown-item>

                                            <b-dropdown-item
                                                v-if="permissions_list['edit_' + object_type]"
                                                :href="'/' + item_type + '/'+object_type+'/' + item.id + '/edit'">
                                                Edit
                                            </b-dropdown-item>

                                            <b-dropdown-item
                                                v-if="item.status && !item.is_current"
                                                @click="performAction(item, ('set_current_' + object_type), index, $event)">
                                                Set as Current
                                            </b-dropdown-item>

                                            <b-dropdown-item
                                                v-if="permissions_list['delete_' + object_type]"
                                                variant="danger"
                                                @click="performAction(item, ('delete_' + object_type), index, $event)">
                                                Delete
                                            </b-dropdown-item>

                                        </div>
                                    </div>

                                </template>

                            </po-lines>
                        </div>
                    </div>

                    <div v-if="!service_providersData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Service Providers found here at the moment.</h4>

                    </div>
                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import SearchBox from "@/components/search-box";

const service_providerResource = new Resource('api/assetflow/service_providers');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../items/widgets/detailsViews/PoLines";
import {ServiceProviderKeysList} from "@/views/assetflow/form_data/ServiceProviderKeysList";



export default {
    name: 'ServiceProvidersList',
    props: ['item_id', 'item_type'],
    components: {PoLines, SearchBox, Layout, PageHeader},
    data() {
        return {
            service_provider_list_keys_list: ServiceProviderKeysList,
            total: 0,
            service_providersData: [],
            request_meta: {},
            listQuery: {
                page: 1,
                limit: 100
            },
            has_service_provider_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            object_type: 'service_providers'
        };
    },
    created() {

        this.permissions_list['view_' + this.object_type] = this.hasPermission('browse_' + this.object_type);
        this.permissions_list['add_' + this.object_type] = this.hasPermission('add_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);

        this.has_service_provider_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['delete_' + this.object_type];

        if (this.has_service_provider_actions_permissions){
            this.service_provider_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "sp_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_actions'
            }
        }

        if (!this.isSuperAdmin){
            delete this.service_provider_list_keys_list.status;
            delete this.service_provider_list_keys_list.contact;
            delete this.service_provider_list_keys_list.phone;
            delete this.service_provider_list_keys_list.website;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_service_providers_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.service_provider_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.service_provider_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        performAction(service_provider, action, service_providerKey, event){
            if (action === ('set_current_' + this.object_type)) {
                if (event) {
                    axios({
                        method:'post',
                        url:'/api/assetflow/sp/set_current',
                        baseURL: '/',
                        data: { warehouse_id: service_provider.id },
                    }).then(response => {
                        console.log(response);
                        if (response.data.status === true){
                            location.reload();
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }

            }else if (action === ('delete_' + this.object_type)){
                if (service_provider.id !== ''){
                    this.deleteItemByID(service_provider.id, service_provider.name, this.object_type, event)
                        .then((response) => {
                            if (response.data.data.status){
                                this.service_providersData.splice(service_providerKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['name'];

                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";

                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.service_providersData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.service_providersData;
            }

            return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('service_provider_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_service_providers_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery)
            const {data} = await service_providerResource.list(this.listQuery);

            this.service_providersData = data.data.items;
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.request_meta = data.meta;
            }else{
                this.total = this.service_providersData.length;
            }
            this.listLoading = false;

        },
        handleQueryItems() {
            if (this.item_id !== '') {
                if (this.item_type === 'sp'){
                    this.listQuery.warehouse_id = this.item_id;
                }else if (this.item_type === 'group'){
                    this.listQuery.group_id = this.item_id;
                    this.listQuery.item_type = this.item_type;
                }
            }
        },

    },
}
</script>


