<template>
    <div>
        <h2 class="mb-2">{{ selected_item ? ('Edit "' + (selected_item.name !== '' ? selected_item.name : ('ORD-'+selected_item.uid)) + '"') : 'Add Order' }}</h2>


        <div v-if="!!selected_item" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-info">
            <i class="fas fa-exclamation-circle me-2"></i>
            These changes will only be applied to new items added to this Order. Existing items can be updated manually if required.
        </div>
        <div v-if="selected_item && selected_item.status !== 0" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-warning">
            <i class="fas fa-exclamation-triangle me-2"></i>
            Only open orders can be edited.
        </div>

        <OrderFormFields
            v-else
            :item_type="item_type"
            :received_order_type="received_order_type"
            :is_full="true"
            ref="add-new-order-fields"
            @after-saving-is-done="afterSaving"
            @on-cancel-clicked="cancelClicked"
        />
    </div>
</template>
<script>


import OrderFormFields from "./OrderFormFields";

export default {
    components: {OrderFormFields},
    props: ['item_id', 'item_type', 'received_order_type'],
    mixins: [],
    name: 'AddNewOrderForm',
    data(){
        return {
            selected_item: null,
        }
    },
    methods:{
        setSelectedItem(item_info){
            this.selected_item = item_info;
            this.$refs['add-new-order-fields'].setSelectedItem(item_info);
        },
        cancelClicked(){
            window.location = encodeURI('/' + this.item_type + '/orders');
        },
        afterSaving(new_item){
            if (new_item){
                // shall redirect to view order ?
                window.location = encodeURI('/' + this.item_type + '/orders/' + new_item.id + '/view');
            }
        }
    }


}
</script>
