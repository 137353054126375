<template>
    <div class="row">
        <div class="col-12">
            <form>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }}</h4>
                        <div class="row">
                            <div class="col-sm-6">
                                <div
                                    v-for="(item, key) in addForm.meta"
                                    v-if="!['currency','timezone'].includes(key)"
                                    class="form-group">
                                    <label :for="'item_' + key">{{key | titleize}}</label>
                                    <input v-model="addForm.meta[key]" :id="'item_' + key" :name="'item_' + key" :type="((key.includes('colour') || key === 'logo_background') ? 'color' : 'text')" class="form-control" />
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="logo">Logo</label>
                                    <div class="col-sm-6">
                                        <vue-dropzone id="logo" ref="myVueDropzone" :use-custom-slot="true"
                                                      :options="dropzoneOptions"
                                                      @vdropzone-file-added="fileAdded"
                                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                                        >
                                            <div class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </vue-dropzone>
                                        <b-button
                                            @click="$refs.myVueDropzone.$el.click();"
                                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                        >Select New Logo</b-button>
                                        <b-button
                                            @click="$refs.myVueDropzone.removeAllFiles();"
                                            variant="outline-danger"
                                            class="btn btn-sm mt-1 btn-block"
                                        >Remove Selected</b-button>
                                    </div>

                                    <div class="col-sm-6">
                                        <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px; width: 100%; margin-top: 8px;">
                                            <p><strong>Logo Dimensions</strong></p>
                                            <br>
                                            <p>Logos should be uploaded in the ratio 3:1.</p>
                                            <br>
                                            <p>For example, 3000 pixels wide by 1000 pixels high. Logos will be scaled automatically. Logos outside these dimensions maybe truncated.</p>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-body">
                        <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                        <a href="./" class="btn btn-secondary">Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {DropZoneOptions} from "../../../form_data/DropZoneOptions";
import vue2Dropzone from "vue2-dropzone";

export default {
    name: 'logo_appearance',
    components: {
        vueDropzone: vue2Dropzone,
    },
    props:['selected_setting'],
    data(){
        return{
            dropzoneOptions: DropZoneOptions,
            isSendingRequest: false,
            addForm:{
                logo: "",
                meta:{}
            },
        }
    },
    computed: {
        object_name() {
            return this.current_customer ? 'customer' : 'group';
        },
    },
    mounted() {
        if(this.current_customer && this.current_customer.id !== ''){
            this.fetchInfoOf();
        }
    },
    methods: {
        fileAdded(file) {
            this.addForm.logo = file;
            this.saveFileToS3(file, null, 'logo', 'customer');
        },
        maxFilesExceeded(file){
            this.$refs.myVueDropzone.removeAllFiles();
            this.$refs.myVueDropzone.addFile(file);
        },
        addNew(event) {
            if (event && !this.isSendingRequest) {
                this.isSendingRequest = true;
                let keys_list = [
                    'meta'
                ];
                axios({
                    method:(this.current_customer.id !== '' ? 'put' : 'post'),
                    url:'/api/assetflow/' + this.object_name + 's' + (this.current_customer.id !== '' ? ('/'+this.current_customer.id) : ''),
                    baseURL: '/',
                    data: {
                        keys_list: keys_list,
                        customer_id: this.current_customer.id,
                        meta: this.addForm.meta,
                        logo: this.addForm.logo
                    },
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true){
                        if (this.object_name === 'group'){
                            let message = "Updated Settings Successfully";
                            this.handleResponse(response, message, true);
                        }else{
                            let added_item = response.data.data.items;
                            if (added_item){
                                let message = "Updated Settings Successfully";
                                this.handleResponse(response, message, true);
                            }
                        }

                    }else{
                        console.log('Something went wrong');
                    }

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });

            }
        },
        fetchInfoOf() {
            var customer_info = this.current_customer;
            this.addForm.meta = customer_info.meta;

            if (customer_info.logo_url){
                this.addForm.logo = customer_info.logo_url;

                var file = { size: customer_info.image_size, name: customer_info.name, type: "image/png" };
                var url = customer_info.logo_url;

                if (customer_info.image_size){
                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                }

            }
        }
    }
}
</script>

<style scoped>
#logo .dz-preview .dz-progress {
    opacity: 0 !important;
}

.vue-dropzone > .dz-preview .dz-image {
    height: 160px !important;
    width: 160px !important;
}

.vue-dropzone .dz-preview .dz-details .dz-size {
    display: none
}
</style>

