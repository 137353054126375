<template>

    <div class="dropdown-menu dropdown-menu-right">
        <b-dropdown-item v-if="hasPermission('browse_' + object_type)"
                         :href="'/' + item_type + '/changes/' + item.id + '/view'">
            View Change
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'change_notes', index, $event)">
            Change Notes
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'change_scenes', index, $event)">
            Change Scenes
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'change_setup', index, $event)">
            Change Setup
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('browse_' + object_type)"
                         @click="performAction(item, 'generate_pdf', index, $event)">
            Generate PDF
        </b-dropdown-item>

        <b-dropdown-item v-if="hasPermission('delete_' + object_type)"
                         variant="danger"
                         @click="performAction(item, 'delete', index, $event)">
            Delete Change
        </b-dropdown-item>
    </div>
</template>
<script>
export default {
    name: 'ChangeActionsList',
    props: {
        item: {},
        item_type: {},
        object_type: {},
        index: {},
        performAction: {}
    }
}
</script>
