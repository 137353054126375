<template>
    <Layout>
        <main>
            <add-new-modal
                ref="add-new-address"
                item_type='address'
                modal_title="Create New Address"
                @on-new-item-adding="getList"
            ></add-new-modal>
            <add-new-modal
                ref="add-new-location"
                item_type='location'
                :addresses_options="addresses_options"
                modal_title="Create New Location"
                @on-new-item-adding="getList"
            ></add-new-modal>
            <div class="addresses-wrapper">
                <!-- Page-Content start-->
                <div id="page-content-wrapper">
                    <div class="page-content-inner">

                        <div class="product-page-header">
                            <TopFiltersBlock
                                ref="TopFiltersBlock"
                                object_type="tags"
                                current_title="Address List"
                                block_class="customers-warehouses-block both-customers-warehouses"
                                :required_items.sync="required_items"
                                @onSelectingTopFilter="onSelectingTopFilter"
                            />

                            <div class="filter-searchbar dropdown">
                                <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                            placeholder_text="Search Assets.."></search-box>
                                <b-dropdown v-if="hasPermission('add_addresses')" dropleft
                                            class="add-filter" variant="danger">
                                    <template slot="button-content">
                                        <span class="add-filter-button"></span>
                                    </template>
                                    <b-dropdown-item v-if="hasPermission('add_addresses')"
                                                     @click="addNewItem('address')">Address
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="hasPermission('add_locations')"
                                                     @click="addNewItem('location')">Location
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="page-value-info">
                                <ul class="d-flex align-items-center">
                                    <li class="d-flex align-items-center">
                                        Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="product-filter-option-wrapper clearfix d-flex">

                            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                    request_meta.from + '-' + request_meta.to
                                }}</span> of <span>{{ request_meta.total }}</span></div>

                            <div class="filter-wrap d-flex align-items-center ml-auto">


                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Sort: <b>{{ sorting.sortType.text }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in sorting.sortOptions"
                                        :key="option.value"
                                        @click="saveSorting(option, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>

                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Show: <b>{{
                                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                        }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in perpage.pageOptions"
                                        :key="option.value"
                                        @click="savePerPage(option.value, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>

                            <div class="addresses-layout grid-list-button d-flex align-items-center">
                                <div class="grid active" @click="showOneMapView(false, 0)"></div>
                                <div class="full-map" @click="showOneMapView(true, 1)"></div>
                                <div class="smaller-list" @click="showOneMapView(false, 2)"></div>
                                <div class="list" @click="showOneMapView(false, 3)"></div>
                            </div>
                        </div>

                        <!-- Product Section Start-->
                        <LotsOfItemsAlert :perpage="perpage"/>

                        <div v-if="addressesData" class="products">
                            <po-lines v-if="show_smaller_list"
                                      :items="addressesData"
                                      :current_page_type="item_type"
                                      :object_type="'addresses'"
                                      :keys_list="address_list_key_list">
                            </po-lines>
                            <div v-show="show_one_map_view" class="address-map-block full-map-block">
                                <gmap-map
                                    ref="fullMapRef"
                                    v-if="addressesData.length > 0"
                                    :center="one_map_viewCenter"
                                    :zoom="one_map_zoom"
                                    class="address-map"
                                    :options="mapOptions"
                                    @click="onMapClick">
                                    <gmap-info-window v-if="infoContent" :options="infoOptions" :position="infoPosition"
                                                      :opened="infoOpened" @closeclick="infoOpened=false">
                                        <div class="map-info-window" v-html="infoContent.html"></div>

                                        <div class="m-2 text-right">
                                            <a :href="'/' + item_type + '/addresses/' + infoContent.address.id + '/view'"
                                               class="btn btn-sm af-btn-accent af-accent">View</a>
                                        </div>

                                    </gmap-info-window>

                                    <gmap-marker
                                        v-for="(address, i) in addressesData"
                                        :clickable="true"
                                        @click="toggleInfo(address, i)"
                                        :key="i"
                                        :position="getPosition(address)"></gmap-marker>
                                </gmap-map>
                            </div>
                            <div v-show="!show_one_map_view && !show_smaller_list" class="product-row grid">
                                <div v-for="(address, addressKey) in sortedItemsList" class="product-item">
                                    <div class="product-block">
                                        <!-- Address Map Start-->
                                        <div class="address-map-block">
                                            <gmap-map
                                                v-if="address && checkCoordinates(address)"
                                                :center="getPosition(address)"
                                                :zoom="9"
                                                class="address-map"
                                                :options="mapOptions"
                                                @click="onMapClick">
                                                <gmap-marker :position="getPosition(address)"></gmap-marker>
                                            </gmap-map>
                                            <div v-else class="placeholder-ai text-af-accent">
                                                <i class="bx bx-map"></i>
                                            </div>
                                        </div>
                                        <!-- Address Map End-->

                                        <!-- Product Details Start-->
                                        <div class="product-details-left">
                                            <h3 class="product-title">
                                                <a :href="'/' + item_type + '/addresses/' + address.id + '/view'">{{
                                                        address.name | titleize
                                                    }}</a>
                                            </h3>
                                            <a :href="'/' + item_type + '/addresses/' + address.id + '/view'">
                                                <div class="view-asset-details af-accent"></div>
                                            </a>
                                            <div class="product-status grid-show">
                                                <div class="address-locations-count">
                                                    {{ address.locations.length }}
                                                </div>
                                                <div class="quantity">
                                                    {{ address.assets_sum_quantity }}
                                                </div>
                                                <div class="address-total-value" v-b-tooltip.hover.bottom :title="toUserCurrencyValueTitle(0, address, 'assets_sum_value')">
                                                    {{ getCurrencyByValueText(0, address, 'assets_sum_value') }}
                                                </div>
                                            </div>
                                            <div class="product-series list-show">
                                                <p>{{ address.parent_product_name }}</p>
                                                <p>{{ address.parent_category_name }}</p>
                                            </div>
                                            <div class="product-description list-show">
                                                <p>{{ address.description }}</p>
                                            </div>
                                            <div class="product-usage">
                                                <div class="usage-inner-wrap">
                                                    <div>City<b>{{ address.city | titleize }}</b></div>
                                                    <div>State<b>{{ address.state | titleize }}</b></div>
                                                    <div>Country<b>{{ address.country | titleize }}</b></div>
                                                </div>
                                            </div>
                                            <div class="row statuses-row">
                                                <div class="address-types af-accent">
                                                        <span :class="'address-type-' + address.address_type.class">
                                                            {{ address.address_type.value | titleize }}
                                                        </span>
                                                </div>

                                                <div v-if="address.is_default" class="af-accent default-address">
                                                    <span>Default</span>
                                                </div>

                                                <div :class="'address-status ' + (checkAddressStatus(address) ? 'active' : 'inactive')">
                                                    <span>{{ checkAddressStatus(address) ? 'Active' : "Inactive" }}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="product-details-right list-show">
                                            <div class="life-cycle-wrapper">
                                                <h4 class="d-flex align-items-center">Locations<a
                                                    class="ml-auto"></a></h4>
                                                <ul class="address-locations">
                                                    <li v-for="location in address.locations">
                                                        {{ location.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="tag-wrap list-show">
                                                <ul>
                                                    <li :class="'af-accent address-type-' + address.address_type.class + ' mr-1 mt-1'">
                                                        <a href="">{{ address.address_type.value | titleize }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- Product Details End-->


                                        <div v-if="has_address_actions_permissions"
                                             class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                                            <button type="button" class="btn btn-none dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                <i class="mdi mdi-dots-horizontal"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <b-dropdown-item v-if="hasPermission('edit_addresses')"
                                                                 @click="performAction(address, 'edit', addressKey, $event)">
                                                    Edit
                                                </b-dropdown-item>

                                                <b-dropdown-item v-if="hasPermission('edit_addresses')"
                                                                 @click="addLocation(address, $event)">
                                                    Add Location
                                                </b-dropdown-item>

                                                <b-dropdown-item v-if="hasPermission('delete_addresses') && address.assets_count === 0" variant="danger"
                                                                 @click="performAction(address, 'delete', addressKey, $event)">
                                                    Delete
                                                </b-dropdown-item>

                                                <b-dropdown-item v-else-if="hasPermission('delete_addresses') && address.assets_count !== 0" variant="danger" v-b-tooltip.hover :title="'Only empty addresses can be deleted. {A: ' + address.assets_count + '}'" disabled>
                                                    Delete
                                                </b-dropdown-item>
                                            </div>
                                        </div>


                                        <a :href="'/' + item_type + '/addresses/' + address.id + '/view'">
                                            <div class="view-asset-details af-accent"></div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!addressesData || total === 0 || sortedItemsList.length === 0"
                             class="products text-center">
                            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                            <h4>No Addresses found here at the moment.</h4>

                        </div>
                        <!-- Product Section End-->
                        <div v-if="!show_one_map_view" class="s">
                            <b-pagination
                                v-if="perpage.perPage !== 0"
                                v-model="perpage.currentPage"
                                :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                class="pagination d-flex align-items-center justify-content-center"
                                :per-page="perpage.perPage"
                            ></b-pagination>
                        </div>

                    </div>
                </div>
                <!-- Page-Content end-->

            </div>
        </main>

    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import GmapCustomMarker from "../extras/gmap-custom-marker";
import AddNewModal from "../extras/AddNewModal";
import SearchBox from "@/components/search-box";
import UserMeta from "@/mixins/usermeta.mixin";
import AddressActions from "@/mixins/addressActions.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";


const addressResource = new Resource('api/assetflow/addresses');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import TopFiltersBlock from "../sp_storages/TopFiltersBlock";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";

export default {
    name: 'AddressesList',
    mixins: [UserMeta, AddressActions, TotalsAndCurrencies, TopFiltersMixin],
    props: ['item_id', 'item_type'],
    components: {
        TopFiltersBlock,
        LotsOfItemsAlert, PoLines, SearchBox, AddNewModal, GmapCustomMarker, Layout, PageHeader},
    data() {
        return {
            address_list_key_list:{
                uid: {
                    key: 'uid',
                    label: "ID",
                    class: "col-lg a_uid",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a class="text-dark" href="./addresses/'+item.id+'/view">' + item[key] + '</a>';
                    }
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg a_name",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return (item.uid !== '' ? 'UID: ' + item.uid : '');
                    },
                    value_func: function (index, item, key) {
                        return '<a class="text-dark" href="./addresses/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
                    }
                },
                description: {
                    key: 'description',
                    label: "Description",
                    class: "col-lg a_description",
                    show_on_mobile: true,
                    case: ''
                },
                company: {
                    key: 'company',
                    label: "Company",
                    class: "col-lg a_company",
                    show_on_mobile: false,
                    case: ''
                },

                city: {
                    key: 'city',
                    label: "City",
                    class: "col-lg a_city",
                    show_on_mobile: false,
                    case: ''
                },

                state: {
                    key: 'state',
                    label: "State",
                    class: "col-lg a_state",
                    show_on_mobile: false,
                    case: ''
                },

                country: {
                    key: 'country',
                    label: "Country",
                    class: "col-lg a_country",
                    show_on_mobile: false,
                    case: ''
                },

                locations: {
                    key: 'locations',
                    label: "Lcs",
                    class: "col-lg a_locations",
                    show_on_mobile: false,
                    case: 'custom',
                    used_func: function (value){
                        return value.length;
                    }
                },

                assets_sum_quantity: {
                    key: 'assets_sum_quantity',
                    label: "Qty",
                    class: "col-lg a_quantity",
                    show_on_mobile: false,
                    case: ''
                },

                assets_sum_value: {
                key: 'assets_sum_value',
                label: "Cost",
                class: "col-lg a_total_value",
                show_on_mobile: false,
                case: 'custom_tooltip',
                title_func: this.toUserCurrencyValueTitle,
                value_func: this.getCurrencyByValueText
                },
            },
            total: 0,
            addressesData: [],
            all_locations: [],
            addresses_options: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Addresses & Locations",
            items: [
                {
                    text: "Addresses & Locations",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_address_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },

            show_one_map_view: false,
            show_smaller_list: false,
            one_map_viewCenter: {},
            one_map_zoom: 9,

        };
    },
    created() {

        this.required_items = [];

        this.has_address_actions_permissions = this.hasPermission('edit_addresses') || this.hasPermission('delete_addresses');

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_addresses_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);

            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);

        }

        if (window.USER_INFO.user_meta.address_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.address_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        totalAssetsValue() {
            return this.addressesData ? this.addressesData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        addLocation(address){
            this.$refs['add-new-location'].setSelectedItem(null);
            this.$refs['add-new-location'].setAddressID(address.id);
            this.$refs['add-new-location'].set_addresses_list(this.addresses_options);
            this.$bvModal.show('create-location');
        },
        performAction(address, action, addressKey, event){
            if (action === 'edit'){
                this.$refs['add-new-address'].setSelectedItem(address);
                this.$bvModal.show('create-address');
                this.setupAddressLookup();
            }else if (action === 'delete'){
                if (address.id !== '' && address.assets_count === 0){
                    this.deleteItemByID(address.id, address.name, 'addresses', event)
                    .then((response) => {
                        if (response.data.data.status){
                            this.addressesData.splice(addressKey, 1);
                        }else{
                            console.log("response.data");
                            console.log(response.data);
                        }
                    });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['name'];

                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";

                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.addressesData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.addressesData;
            }


             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('address_preferred_sorting', sortType, message);
            this.refreshContent();
            // this.sortItemsList();
        },
        savePerPage(perPage) {
            if (perPage === 0) {
                this.perpage.perPageStr = 'All';
            } else {
                this.perpage.perPageStr = perPage;
            }
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_addresses_per_page', perPage, message);
            this.refreshContent();
            // TODO: should change Per Page in Pagination
        },
        showOneMapView(should_show, index) {
            this.show_one_map_view = should_show;

            if (index === 2){
                this.show_smaller_list = true;
                return;
            }else{
                this.show_smaller_list = false;
            }

            if (!should_show){ return; }

            let bounds = new window.google.maps.LatLngBounds();
            for (const dataKey in this.addressesData) {
                let address = this.addressesData[dataKey];


                if (parseFloat(address.latitude) && parseFloat(address.longitude)) {
                    let newPos = new window.google.maps.LatLng(parseFloat(address.latitude), parseFloat(address.longitude));
                    bounds.extend(newPos);
                }
            }

            this.one_map_viewCenter = bounds.getCenter();


            // window.google.maps.event.addListener(infowindow, "domready", function(){
            //     $('.gm-style-iw button').css('display', 'none');
            //     $('.gm-style-iw').css('padding','0px');
            //     $('.gm-style-iw-d').css('overflow','hidden');
            // });



            const map = this.$refs.fullMapRef.$mapObject; //get map instance

            map.fitBounds(bounds);
            var listener = window.google.maps.event.addListener(map, "idle", function() {

                if (map.getZoom() > 16) map.setZoom(16);
                if (map.getZoom() < 2) map.setZoom(2);
                window.google.maps.event.removeListener(listener);
            });



            // console.log("here?");
            //
            // console.log("this.$refs.fullMapRef");
            // console.log(this.$refs.fullMapRef);
            // console.log("$mapObject");
            // console.log(this.$refs.fullMapRef.$mapObject);
            // let map = this.$refs.fullMapRef.$mapObject;
            // map.fitBounds(b);


            // let b = new window.google.maps.LatLngBounds();
            // console.log(b.toString());


            // this.$refs.fullMapRef.$mapObject.then(() => {
            //     let b = new window.google.maps.LatLngBounds();
            //     console.log(b.toString())
            //     console.log("here");
            // })

            // this.$refs.fullMapRef.$mapPromise.then(() => {
            //     const {$circleObject} = this.$refs.fullMapRef;
            //     console.log("$circleObject");
            //     console.log($circleObject);
            //
            //     const map = $circleObject.getMap();
            //     map.fitBounds($circleObject.getBounds());
            // })


            // console.log("this.$refs");
            // console.log(this.$refs);
            //
            // console.log("this.$refs['full-map']");
            // console.log(this.$refs.fullmap);
            // console.log("this.$refs['map-vue']");
            // console.log(this.$refs['map-vue']);
            //
            //
            // this.$refs["fullmap"].$mapCreated.then(() => {
            //     let b = new window.google.maps.LatLngBounds();
            //     console.log(b.toString())
            //     console.log("here");
            // })


        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();

            this.listQuery = this.addPaginationParams(this.listQuery)
            this.listQuery = this.appendTopFiltersValues(this.listQuery);

            const {data} = await addressResource.list(this.listQuery);
            this.total = data.meta.total;
            this.addressesData = data.data.items;
            if (this.addressesData.length !== 0){
                this.one_map_viewCenter = this.getPosition(this.addressesData[0]);
            }

            this.addresses_options = this.addressesData.map(function (e) {
                return {value: e.id, text: e.name};
            });

            this.request_meta = data.meta;


            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem(item_type) {
            this.$refs['add-new-' + item_type].setSelectedItem(null);
            if (item_type === 'location'){
                this.$refs['add-new-location'].set_addresses_list(this.addresses_options);
            }
            this.$bvModal.show('create-' + item_type);
            this.setupAddressLookup();
        }
    },
}
</script>

<style scoped>
.full-map-block {
    width: 100%;
    height: 450px;
}
</style>
