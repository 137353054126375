<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="text-sm-right">
                <a :href="'./'+item_type+'/new'" class="btn btn-success btn-rounded mb-2 mr-2">
                    <i class="mdi mdi-plus mr-1"></i> Add New {{item_type | titleizeToSingle}}
                </a>
            </div>
        </div>

        <div class="col-12">
            <po-lines
                :table_class="'settings_' + item_type + '_list'"
                :items="itemsData"
                :keys_list="listing_info[item_type]"
            >
                <template v-for="slot in slot_names" v-slot:[slot.name]="{ item }">
                    <b-button class="btn-sm" :href="'./'+slot.type+'/'+item.id+'/edit'" v-b-tooltip.hover :title="'Edit ' + item.name" variant="primary">Edit</b-button>
                    <b-button class="btn-sm" :href="'./'+slot.type+'/'+item.id+'/view'" v-b-tooltip.hover :title="'View ' + item.name" variant="info">View</b-button>


                    <b-button class="btn-sm" href="javascript:void(0);" v-b-tooltip.hover :title="'Delete ' + item.name + '?'" v-on:click="deleteItem(item.id, item.name, slot.type, $event)" variant="outline-danger">Delete</b-button>

                    <b-button v-if="item_type === 'service_providers' && item.status && !item.is_current" class="btn-sm" href="javascript:void(0);" v-b-tooltip.hover :title="'Set' + item.name + ' as Current?'" v-on:click="setAsCurrent(item.id, $event)" variant="outline-secondary">Set as Current</b-button>

                </template>

            </po-lines>
        </div>
    </div>
</template>
<script>

import PoLines from "../../../items/widgets/detailsViews/PoLines";
export default {
    name: 'ItemsList',
    mixins: [],
    components: {PoLines},
    props: ['item_type'],
    data() {
        return {
            listLoading: false,
            itemsData: [],
            slot_names:[],
            listing_info: {
                users: {
                    id: {
                        key: 'id',
                        label: "ID",
                        class: "col-lg-1 u_id",
                        show_on_mobile: true,
                        case: ''
                    },
                    name: {
                        key: 'name',
                        label: "Name",
                        class: "col-lg u_name",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {}
                    },
                    email: {
                        key: 'email',
                        label: "Email",
                        class: "col-lg-2 u_email",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return '<a href="mailto:'+item[key]+'">'+item[key]+'</a>';
                        }
                    },
                    can_switch: {
                        key: 'can_switch',
                        label: "Switch Customers",
                        class: "col-lg-1 u_switch_customers",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return '<span class="text-dark">'+item[key]+'</span>';
                        }
                    },
                    can_switch_sp: {
                        key: 'can_switch_sp',
                        label: "Switch SP",
                        class: "col-lg-1 u_switch_sp",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return '<span class="text-dark">'+item[key]+'</span>';
                        }
                    },
                    warehouse_id: {
                        key: 'warehouse_id',
                        label: "Current SP",
                        class: "col-lg-1 u_current_sp",
                        show_on_mobile: true,
                        case: 'custom_html_tooltip',
                        title_func: function (index, item, key) {
                            return item.hasOwnProperty('warehouse_name') ? item.warehouse_name : '';
                        },
                        value_func: function (index, item, key) {
                            const firstPath = window.location.pathname.split('/')[1];
                            return '<a class="text-dark" href="/' + firstPath + '/service_providers/' + item[key] + '/view">' + Vue.options.filters.titleize(item.warehouse_name) + '</a>';
                        }
                    },
                    roles: {
                        key: 'roles',
                        label: "Roles",
                        class: "col-lg-2 u_roles",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            let html = '';
                            const firstPath = window.location.pathname.split('/')[1];
                            for (const rolesKey in item.roles) {
                                let role_info = item.roles[rolesKey];
                                html += '<div><a href="/' + firstPath + '/roles/' + item[key] + '/view" class="text-dark">'+role_info.name+'</a></div>';
                            }
                            return html;
                        }
                    },
                    actions: {
                        key: 'actions',
                        label: "",
                        class: "col-lg-3 u_users_actions text-center",
                        show_on_mobile: false,
                        case: 'custom_slot',
                        slot_name: 'users_actions'
                    },
                },
                roles: {
                    id: {
                        key: 'id',
                        label: "ID",
                        class: "col-lg-1 r_id",
                        show_on_mobile: true,
                        case: ''
                    },
                    name: {
                        key: 'name',
                        label: "Name",
                        class: "col-lg r_name",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {}
                    },
                    is_default: {
                        key: 'is_default',
                        label: "Default",
                        class: "col-lg r_is_default",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return '<span class="text-dark">'+item[key]+'</span>';
                        }
                    },
                    sharing_type: {
                        key: 'sharing_type',
                        label: "Sharing Scope",
                        class: "col-lg r_sharing_type",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return '<span class="text-dark">'+item[key].toUpperCase()+'</span>';
                        }
                    },
                    related_items: {
                        key: 'related_items',
                        label: "Owner",
                        class: "col-lg-2 r_related_items",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            let html = '';
                            for (const itemKey in item.related_items) {
                                let item_info = item.related_items[itemKey];
                                html += '<div><a href="'+ item_info.item_url + '/'+item_info.id +'/view" class="text-dark">'+item_info.name+'</a></div>';
                            }
                            return html;
                        }
                    },
                    actions: {
                        key: 'actions',
                        label: "",
                        class: "col-lg-3 r_roles_actions text-center",
                        show_on_mobile: false,
                        case: 'custom_slot',
                        slot_name: 'roles_actions'
                    },
                },
                service_providers: {
                    id: {
                        key: 'id',
                        label: "ID",
                        class: "col-lg-1 sp_id",
                        show_on_mobile: true,
                        case: ''
                    },
                    name: {
                        key: 'name',
                        label: "Name",
                        class: "col-lg sp_name",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {}
                    },
                    status: {
                        key: 'status',
                        label: "Status",
                        class: "col-lg sp_status",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {}
                    },
                    actions: {
                        key: 'actions',
                        label: "",
                        class: "col-lg-3 sp_sps_actions text-center",
                        show_on_mobile: false,
                        case: 'custom_slot',
                        slot_name: 'service_providers_actions'
                    },
                },
            }
        }
    },
    created() {
        this.slot_names = [
            {
                name: this.item_type + '_actions',
                type: this.item_type,
            }
        ];
        this.getList();
        let item_type = this.item_type;

        let name_func = function (item, key) {
            const firstPath = window.location.pathname.split('/')[1];
            return '<a class="text-dark" href="/' + firstPath + '/'+item_type+'/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
        };
        let status_func = function (item, key) {
            let status_list = [
                {
                    name: 'Inactive',
                    class: 'status-btn-disposed',
                },
                {
                    name: 'Active',
                    class: 'status-btn-active',
                }
            ];
            return '<span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span>';
        };

        this.listing_info.users.name.value_func = name_func;

        this.listing_info.roles.name.value_func = name_func;
        this.listing_info.service_providers.name.value_func = name_func;
        this.listing_info.service_providers.status.value_func = status_func;
    },
    methods: {
        deleteItem(item_id, item_name, item_type, event){
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    this.getList();
                });
        },
        async setAsCurrent(warehouse_id, event) {
            if (event) {
                axios({
                    method:'post',
                    url:'/api/assetflow/sp/set_current',
                    baseURL: '/',
                    data: { warehouse_id: warehouse_id },
                }).then(response => {
                    console.log(response);
                    if (response.data.status == true){
                        location.reload();
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        getList() {
            if (!this.listLoading) {
                this.listLoading = true;
                axios({
                    method: 'get',
                    url: '/api/assetflow/' + this.item_type + '?customer_id=' + this.current_customer.id ,
                    baseURL: '/',
                    data: {
                    },
                }).then(response => {
                    this.listLoading = false;
                    this.itemsData = response.data.data.items;
                    this.total = response.data.data.total;
                    this.listLoading = false;

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });
            }
        },
    }
}

</script>
