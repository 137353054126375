export const SortOptions = [
    {
        value: 1,
        text: 'A~Z',
        name: 'name',
        reversed: false
    },
    {
        value: 2,
        text: 'Z~A',
        name: 'name',
        reversed: true
    },
    {
        value: 3,
        text: 'Status',
        name: 'status',
        reversed: false
    },
    {
        value: 4,
        text: 'Location',
        name: 'address_location_name',
        reversed: false
    },
    {
        value: 5,
        text: 'Qty: Hi to Lo',
        name: 'quantity',
        reversed: true
    },
    {
        value: 6,
        text: 'Qty: Lo to Hi',
        name: 'quantity',
        reversed: false
    },
    {
        value: 7,
        text: 'Created Date: New to Old',
        name: 'created_at',
        reversed: true
    },
    {
        value: 8,
        text: 'Created Date: Old to New',
        name: 'created_at',
        reversed: false
    },
    {
        value: 9,
        text: 'Modified Date: New to Old',
        name: 'updated_at',
        reversed: true
    },
    {
        value: 10,
        text: 'Modified Date: Old to New',
        name: 'updated_at',
        reversed: false
    },
];
