const FirstPath = window.location.pathname.split('/')[1];
export const SceneLocationKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-sm-1 col-lg-1 a_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/scene_locations/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-sm-8 col-lg-8 p_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/scene_locations/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    script_id: {
        key: 'script_id',
        label: "Script",
        class: "col-sm-2 col-lg-2 p_script_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('script_info') && item.script_info){
                return '<a class="text-dark" href="/' + FirstPath + '/scripts">' + item.script_info.name + '</a>';
            }
            return '-';
        }
    },
    actions: {
        key: 'actions',
        label: "",
        class: "col-sm-1 col-lg-1 p_actions",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_class: '',
        slot_name: 'scene_locations_actions'
    }
};
