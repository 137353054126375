<template>
    <div class="row">

        <PageHeaderCustom
            :title="current_modal_title"
            :items="items">
            <div v-if="false && item_info" class="action-button">
                <b-dropdown dropleft class="add-filter" variant="danger">
                    <template slot="button-content">
                        <i class="mdi mdi-dots-horizontal text-white"></i>
                    </template>
                    <b-dropdown-item
                        v-if="hasPermission('browse_' + item_info.item_type)"
                        :href="'/' + item_info.current_page_type + '/'+item_info.item_type+'/' + item_info.id + '/view'">View
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </PageHeaderCustom>


        <div class="col-12" v-if="older_storage_in_location.length > 0">
            <div class="card">
                <div class="card-header af-soft-accent text-af-primary">
                    This Storage item became free, would you like to park it?
                </div>
                <div class="card-body">
                    <div v-for="(storage, key) in older_storage_in_location"
                         :key="key"
                    >
                        <a :href="'/'+ item_info.group + '/sp_storages/' + storage.id + '/relocate'" @click="setStorageItem(storage, $event)" class="btn btn-primary btn-sm" v-html="'Park ' + getStorageName(storage)"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12" v-if="storage_info">
            <div class="row" v-for="(required_section, k) in required_sections" :key="k">

                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header af-soft-accent text-af-primary" v-html="required_section.section_name"></div>

                        <div class="card-body">
                            <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          v-on:click="addItem($event)">
                    {{ storage_info ? ('Relocate {' + storage_info.name + '}') : ''}}</b-button>

                <b-button variant="outline-danger" :href="'/'+ item_info.group + '/sp_storages'" >Cancel</b-button>
            </div>
        </div>



        <div class="col-lg-12" v-if="item_info.hasOwnProperty('latest_10_movements_info') && item_info.latest_10_movements_info">
            <po-lines
                :header_html="'Latest Movements'"
                :items="item_info.latest_10_movements_info"
                :current_page_type="item_info.current_page_type"
                object_type="sp_movements"
                :keys_list="sp_movement_keys_list">
            </po-lines>
        </div>



    </div>

</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFields from "../extras/ChangeCommonFields";
import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";
import PageHeaderCustom from "../../../../components/page-header-custom";
import {SPMovementKeysList} from "@/views/assetflow/form_data/SPMovementKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";


export default {
    name: "RelocateStorage",
    props: ['item_info', 'setItemInfo'],
    components: {PoLines, PageHeaderCustom, ChangeCommonFields},
    data(){
        return {
            sp_movement_keys_list: SPMovementKeysList,
            items: [
                {
                    text: "Storage",
                    href: "/sp_storages"
                },
                {
                    text: "Storage",
                    href: "/"
                },
                {
                    text: "Relocate",
                    active: true
                }
            ],
            older_storage_in_location:[],
            storage_info:null,
            addForm:{
                current_warehouse_id: null,
                current_space_id: null,
                location_id: null,
                show_occupied: null,
                space_assigned_at: null,
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Relocate Storage',
                    required_items:{
                        show_occupied: {
                            key: 'show_occupied',
                            label: 'Show Occupied',
                            type: 'radio-select',
                            options:[
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                }
                            ]
                        },
                        current_warehouse_id: {
                            key: 'current_warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select-label',
                            action_text: 'Change Warehouse',
                            options:[]
                        },
                        current_space_id: {
                            key: 'current_space_id',
                            label: 'Space',
                            type: 'single-select-label',
                            action_text: 'Change Space',
                            options:[]
                        },
                        location_id: {
                            key: 'location_id',
                            label: 'Location',
                            type: 'single-select',
                            options:[]
                        },
                        space_assigned_at: {
                            key: 'space_assigned_at',
                            label: 'Park Date',
                            type: 'datetime'
                        }
                    },
                },
            },
            lists_values: {
            }
        }
    },
    created() {
        this.items[0].href = '/'+ this.item_info.group + '/sp_storages/';

        this.setStorageItem(this.item_info);
        this.required_sections.storage_setup.required_items.location_id.custom_html_slot = true;
        this.required_sections.storage_setup.required_items.location_id.custom_label = function (location_info){
            if ( location_info.id === null ){
                return location_info.name;
            }
            return getLocationDetails(location_info, true, true, true);
        };
    },
    computed:{
        show_occupied(){
            return this.addForm.show_occupied;
        },
        current_warehouse_id(){
            return this.addForm.current_warehouse_id;
        },
        current_space_id(){
            return this.addForm.current_space_id;
        },
        current_modal_title(){
            return this.getStorageName(this.storage_info);
        }
    },
    watch:{
        show_occupied: {
            deep: true,
            handler: function (newValue, oldVal) {
                // shall filter locations list
                this.setLocationOptions();
            }
        },
        current_warehouse_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue){
                    this.filterSpacesOptions();
                }else{
                    this.resetLocationsList();
                }
            }
        },
        current_space_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue){
                    this.getLocationsList();
                }else{
                    this.resetLocationsList();
                }
            }
        },
    },
    methods:{
        getStorageName(storage){
            return storage && storage.hasOwnProperty('name') ? ( (storage.hasOwnProperty('storage_wrapper_info') ? ('<i class="' + storage.storage_wrapper_info.icon +'"></i> ') : '')+storage.name + '') : ''
        },
        setStorageItem(storage, event){
            if (event){
                event.preventDefault();
                this.older_storage_in_location = [];
                let newUrl = window.location.protocol + "//" + window.location.host + '/'+ this.item_info.group + '/sp_storages/' + storage.id + '/relocate';
                window.history.replaceState({url: newUrl}, null, newUrl);
            }

            this.resetModalValues();
            this.setSelectedItem(storage);
            this.items[1].href = '/'+ this.item_info.group + '/sp_storages/' + storage.id + '/view';
            this.items[1].text = storage.name;
        },
        resetLocationsList() {
            let msg = '';
            if (!this.addForm.current_warehouse_id){
                msg = 'Warehouse';
            }

            if (!this.addForm.current_space_id){
                msg = msg === '' ? 'Space' : 'Warehouse & Space';
            }

            this.required_sections.storage_setup.required_items.location_id.options = [
                {
                    id: null,
                    name: 'Please select a ' + msg
                }
            ];
        },
        setLocationOptions() {
            if (this.lists_values.hasOwnProperty('location_id')) {
                if (this.addForm.show_occupied && this.addForm.show_occupied.id === 1) {
                    this.required_sections.storage_setup.required_items.location_id.options = this.lists_values.location_id;

                    if (this.storage_info && this.storage_info.hasOwnProperty('current_location_id') && this.storage_info.current_location_id){
                        this.addForm.location_id = this.getObjectOf(this.storage_info.current_location_id, this.required_sections.storage_setup.required_items.location_id.options);
                    }else{
                        this.addForm.location_id = null;
                    }
                } else {
                    this.required_sections.storage_setup.required_items.location_id.options = this.lists_values.location_id.filter(location => location.status === 0);
                    this.addForm.location_id = null;
                }


            }
        },
        filterSpacesOptions() {
            if (this.lists_values.hasOwnProperty('current_space_id')) {
                let warehouse_info = this.addForm.current_warehouse_id;
                if (warehouse_info && warehouse_info.id) {
                    this.required_sections.storage_setup.required_items.current_space_id.options = this.lists_values.current_space_id.filter(sp_space => sp_space.sp_warehouse_id === warehouse_info.id);
                    let current_space_id = this.getObjectOf(this.storage_info.current_space_id, this.required_sections.storage_setup.required_items.current_space_id.options);

                    this.addForm.current_space_id = current_space_id;
                    if (!current_space_id){
                        this.required_sections.storage_setup.required_items.current_space_id.type = 'toggle-single-select';
                    }

                } else {
                    this.required_sections.storage_setup.required_items.current_space_id.options = [];
                    this.addForm.current_space_id = null;
                }
            }
        },
        async getLocationsList() {
            this.required_sections.storage_setup.required_items.location_id.listLoading = true;
            let listQuery = {
                item_type: this.item_type,
                item_id: this.item_id,
                current_warehouse_id: this.addForm.current_warehouse_id ? this.addForm.current_warehouse_id.id : null,
                current_space_id: this.addForm.current_space_id ? this.addForm.current_space_id.id : null
            }
            const locationResource = new Resource('api/assetflow/sp_locations');
            const { data } = await locationResource.list(listQuery);

            this.lists_values.location_id = data.data.items;
            this.setLocationOptions();
            this.required_sections.storage_setup.required_items.location_id.listLoading = false;

        },
        async resetModalValues() {
            this.required_sections.storage_setup.required_items.current_warehouse_id.type = 'single-select-label';
            this.required_sections.storage_setup.required_items.current_space_id.type = 'single-select-label';
            await this.getFieldsList();
        },
        fillSelectedItem() {
            if (this.storage_info) {
                if (this.storage_info.hasOwnProperty('current_warehouse_info')){
                    this.addForm.current_warehouse_id = this.storage_info.current_warehouse_info;
                }
                if (this.storage_info.hasOwnProperty('current_space_info')){
                    this.addForm.current_space_id = this.storage_info.current_space_info;
                }

                if (this.storage_info.current_location_id){
                    this.addForm.show_occupied = this.required_sections.storage_setup.required_items.show_occupied.options[0];
                }

                let space_assigned_at = this.storage_info.space_assigned_at ? this.storage_info.space_assigned_at : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');
                this.$set(this.addForm, 'space_assigned_at', space_assigned_at);

            }
        },
        setSelectedItem(item){
            this.addForm.show_occupied = this.required_sections.storage_setup.required_items.show_occupied.options[1];
            this.$set(this.addForm, 'space_assigned_at', Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm'));
            if (item){
                this.storage_info = item;
                this.fillSelectedItem();
            }else{
                this.storage_info = null;
            }
        },
        async getFieldsList(){
            this.required_sections.storage_setup.required_items.current_warehouse_id.listLoading = true;
            this.required_sections.storage_setup.required_items.current_space_id.listLoading = true;

            let required_select_lists =  {
                current_warehouse_id: 'sp_warehouses_list',
                current_space_id: 'sp_spaces_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_space_modal',
                    object_type: 'sp_spaces',
                    action: 'new_sp_space',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                let current_warehouse_options = this.lists_values.current_warehouse_id;
                this.required_sections.storage_setup.required_items.current_warehouse_id.options = current_warehouse_options;
                this.addForm.current_warehouse_id = this.getObjectOf(this.storage_info.current_warehouse_id, current_warehouse_options);
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            }).finally(() => {
                this.required_sections.storage_setup.required_items.current_warehouse_id.listLoading = false;
                this.required_sections.storage_setup.required_items.current_space_id.listLoading = false;
            });
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                current_warehouse_id: this.addForm.current_warehouse_id ? this.addForm.current_warehouse_id.id : null,
                current_space_id: this.addForm.current_space_id ? this.addForm.current_space_id.id : null,
                location_id: this.addForm.location_id ? this.addForm.location_id.id : null,
                space_assigned_at: this.addForm.space_assigned_at,
                action: 'assign_location'
            };
            if (this.storage_info){
                params.item_id = this.storage_info.id;
            }

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            if (data.data.hasOwnProperty('other_info')){
                let other_info = data.data.other_info;
                if (other_info && other_info.hasOwnProperty('older_storage_in_location')){
                    this.older_storage_in_location = other_info.older_storage_in_location;
                }
            }

            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Parked Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
        }
    }
}
</script>


