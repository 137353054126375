<template>
    <div class="ModalsBlock">

        <UpdateCostsModal
            :ref="update_asset_costs_id"
            :modal_id="update_asset_costs_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="$emit('on-modal-cancel')"
            @on-cost-updates="refreshAsset" />


        <UpdateDetailsModal
            :ref="update_asset_details_id"
            :modal_id="update_asset_details_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="$emit('on-modal-cancel')"
            @on-cost-updates="refreshAsset" />

        <UpdateImagesModal
            :ref="update_asset_images_id"
            :modal_id="update_asset_images_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="$emit('on-modal-cancel')"
            @on-cost-updates="refreshAsset" />

    </div>
</template>
<script>
import UpdateCostsModal from "../../assets/assetForm/UpdateCostsModal";
import UpdateDetailsModal from "../../assets/assetForm/UpdateDetailsModal";
import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";
export default {
    name: 'SharedModalsBlock',
    components: {UpdateImagesModal, UpdateDetailsModal, UpdateCostsModal},
    props: {
        item_type: '',
        update_asset_costs_id: '',
        update_asset_details_id: '',
        update_asset_images_id: '',
        refreshAsset: {},
    }
}
</script>
