<template>
    <div>
        <add-new-purchase-order
            ref="add-new-purchaseOrder"
            :item_type="'purchaseOrder' + all_sub_modal_id"
            :only_po="true"
            modal_title="Create New Purchase Order"
            @on-new-item-adding="addedNewPO"
        ></add-new-purchase-order>
        <add-new-modal
            ref="add-new-address"
            :item_type="'address' + all_sub_modal_id"
            modal_title="Create New Address"
            @on-new-item-adding="addressLocationChange"
            @on-cancel-clicked="cancelClicked('address_id')"
        ></add-new-modal>
        <add-new-modal
            ref="add-new-location"
            :item_type="'location' + all_sub_modal_id"
            modal_title="Create New Location"
            @on-new-item-adding="addressLocationChange"
            @on-cancel-clicked="cancelClicked('location_id')"
        ></add-new-modal>
        <add-new-department
            ref="add-new-department"
            :item_type="'department' + all_sub_modal_id"
            modal_title="Create New Department"
            :without_status="true"
            @on-new-item-adding="addressLocationChange"
        >
        </add-new-department>

        <b-modal
            :id="modal_id"
            class="tasks-generic-form"
            size="lg"
            :title="is_closet ? 'Add Asset To Closet' : (is_inventory ? 'Add Asset To Inventory' : ((required_task ? (' {' + required_task.name +'}') : '') + (asset_info ? ('Asset {' + asset_info.name +'}') : '')))"
            title-class="font-18"
            :no-close-on-backdrop="true"
            @hide="$emit('on-modal-cancel')"
            hide-footer>

            <p v-if="is_event_task" class="tasks-form-hint m-1">Updating this form will automatically complete this task.</p>
            <div class="row">
                <b-form-group
                    v-for="(required_field, key) in required_fields"
                    v-show="required_field.name === 'location_id' ? !!addForm.address_id : true"
                    :key="required_field.name"
                    :label="(required_field.name === 'contact_id' ? 'User' : (required_field.name === 'bss_status' ? 'Marketplace' : required_field.name.replace('_id', '') ) ) | titleize"
                    :label-for="required_field.name"
                    class="col-sm-12"
                >

                    <div v-if="required_field.type === 'checkbox'" class="st--common--box">
                        <div class="dimensions-btn">
                            <a
                                v-for="(option, index) in required_field.options"
                                :key="required_field.name + '_' + option.id"
                                :value="option.id"
                                @click="checkBoxClicked(required_field, option, $event)"
                                :class="[{'active': addForm[required_field.name] === option.id}]"
                                class="dimensions-auto">
                                <span></span>{{ option.name | titleize }}
                            </a>
                        </div>
                    </div>

                    <div class="st-radio-row"
                         v-else-if="required_field.type === 'radio-select'">
                        <div v-for="(option, index) in required_field.options"
                             :key="index"
                             class="col-3">
                            <div class="cm-radio-btn" :class="'mt-1 '+required_field.name+'-' + option.id">
                                <label>
                                    <input
                                        v-model="addForm[required_field.name]"
                                        :key="'_'+required_field.name"
                                        :id="'_'+required_field.name"
                                        :name="required_field.name"
                                        @input="changedSelectField(required_field, option)"
                                        :value="option"
                                        type="radio" />
                                    <div class="st-radio-box">
                                        <span></span>
                                        <p>{{ option.name | titleize }}</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <date-picker
                        v-else-if="required_field.type === 'date'"
                        v-model="addForm[required_field.name]"
                        lang="en" :first-day-of-week="1"
                        format="DD-MM-YYYY"
                        value-type="DD-MM-YYYY"
                        :placeholder="required_field.name | titleize" />


                    <multiselect
                        v-else-if="required_field.name === 'po_id'"
                        v-model="addForm.po_id"
                        track-by="id"
                        label="name"
                        placeholder="Select Purchase Orders"
                        class="mySingleSelect"
                        :custom-label="POWithUIDs"
                        :loading="isLoadingPOs"
                        :options="required_fields.po_id.options"
                        @input="changedSelectField(required_field, $event)"
                        :allow-empty="false"
                        deselect-label=""
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>

                    <multiselect
                        v-else-if="required_field.type === 'single-select'"
                        v-model="addForm[required_field.name]"
                        track-by="id"
                        label="name"
                        :custom-label="['character_id', 'closet_character_id'].includes(required_field.name) ? CustomCharacterName : CustomNameOrDesc"
                        :placeholder="(required_field.name.replace('_id', '')) | titleize"
                        class="mySingleSelect"
                        :options="required_field.options"
                        @input="changedSelectField(required_field, $event)"
                        :allow-empty="false"
                        deselect-label=""
                    >
                    </multiselect>

                    <multiselect
                        v-else-if="required_field.type === 'multi-select'"
                        v-model="addForm[required_field.name]"
                        track-by="id"
                        label="name"
                        :multiple="true"
                        :placeholder="required_field.name | titleize"
                        class="mySingleSelect"
                        :options="required_fields[required_field.name].options"
                        deselect-label=""
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>

                    <div class="st-radio-row"
                         v-else-if="required_field.type === 'single-checkbox'">
                        <div class="checkbox-hint col-12 m-1" v-if="required_field.message">{{ required_field.message }}</div>
                        <div v-for="(option, index) in required_fields[required_field.name].options"
                             :key="index"
                             :class="getClassFor(required_field)"
                             class="st-radio-col-2">
                            <div class="cm-radio-btn" :class="'item_status-' + option.id">
                                <label>
                                    <input
                                        v-model="addForm[required_field.name]"
                                        :key="index"
                                        :id="'_'+required_field.name"
                                        :name="'_'+required_field.name"
                                        :value="option.id"
                                        @input="checkBoxClicked(required_field, option, $event)"
                                        type="radio" />
                                    <div class="st-radio-box">
                                        <span></span>
                                        <p>{{ option.name | titleize }}</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="required_field.type === 'custom_fields'">
                        <CustomFieldsRepeater :force_refresh="true" :add-form="addForm" />
                    </div>

                    <div v-else-if="required_field.type === 'asset_post'">
                        <add-item-post
                            :with_reload="false"
                            post_type="asset"
                            :item_id="asset_info.id"
                            @on-post-adding="cancelGenericModal"
                            @on-cancel-post-adding="cancelGenericModal()">
                        </add-item-post>
                    </div>

                    <div v-else-if="required_field.type === 'events-repeater'">
                        <event-fields-repeater :force_refresh="true" :add-form="addForm" :my_lists_values="lists_values" />
                    </div>

                    <b-form-input
                        v-else-if="required_field.type === 'text'"
                        v-model="addForm[required_field.name]"
                        type="text"
                        :id="required_field.name"
                        :placeholder="required_field.name | titleize"
                        autocomplete="none"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="text-right" v-if="(required_task && required_task.id !== 11) || custom_keys_only">
                <b-button type="submit"
                          variant="success"
                          v-on:click.once="saveValues($event)">Save</b-button>
                <b-button variant="outline-danger" @click="cancelGenericModal()">Cancel</b-button>
            </div>
        </b-modal>
    </div>

</template>

<script>
import {LiabilityOptions} from "@/views/assetflow/form_data/LiabilityOptions";
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import AssetLocation from "@/mixins/AssetLocation";
import AssetActions from "@/mixins/assetActions.mixin";
import Resource from '@/api/resource';
import AddNewPurchaseOrder from "../../../../customers/extras/AddNewPurchaseOrder";
import AddNewModal from "../../../../customers/extras/AddNewModal";
import AddNewDepartment from "../../../../customers/extras/AddNewDepartment";
import CustomFieldsRepeater from "../../../../assets/assetForm/CustomFieldsRepeater";
import EventFieldsRepeater from "../../../../assets/assetForm/EventFieldsRepeater";
import AddItemPost from "../AddItemPost";

const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');

export default {
    mixins: [multiSelect, AssetLocation, AssetActions],
    components: {
        AddItemPost,
        EventFieldsRepeater,
        CustomFieldsRepeater, AddNewDepartment, AddNewModal, AddNewPurchaseOrder, DatePicker},
    name: 'GenericTasksForm',
    props:['modal_id', 'sub_modal_id','modal_title', 'received_asset_info', 'custom_keys_only', 'is_event_task'],
    data(){
        return {
            all_sub_modal_id: null,
            asset_direct_values: [
                'forecast_value'
            ],
            asset_id_values: [
                'asset_liability',
                'asset_usage',
                'is_hero',
                'is_asset',
                'status',
                'bss_status',
                'fdp_status',
                'valuation_method'
            ],
            asset_object_values: [
                'contact_id',
                'department_id',
                'folder_id',
                'character_id',
                'closet_character_id',
                'ledger_id',
                'address_id',
                'location_id',
                'final_disposition_planning',
            ],
            asset_info:null,
            required_task: null,
            required_keys: null,
            isLoadingPOs: false,
            formShown: true,
            addForm:{},
            required_fields:{},
            all_fields: {
                status: {
                    name: 'status',
                    message: 'Usage',
                    type: 'single-checkbox',
                    has_permission: true,
                    default_value: '',
                    options: []
                },
                bss_status: {
                    name: 'bss_status',
                    message: 'List On Marketplace?',
                    type: 'single-checkbox',
                    has_permission: true,
                    default_value: '',
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
                is_unique: {
                    name: 'is_unique',
                    type: 'single-checkbox',
                    has_permission: true,
                    default_value: '',
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
                asset_usage: {
                    name: 'asset_usage',
                    has_permission: true,
                    type: 'single-checkbox',
                    options:[
                        { id: 1, name: 'On Camera'},
                        { id: 0, name: 'Off Camera'},
                        { id: 2, name: 'Unknown' }
                    ]
                },
                is_hero: {
                    name: 'is_hero',
                    has_permission: true,
                    type: 'single-checkbox',
                    options: [
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                is_asset: {
                    name: 'is_asset',
                    has_permission: true,
                    type: 'single-checkbox',
                    options: [
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                custom_fields: {
                    name: 'custom_fields',
                    type: 'custom_fields',
                    has_permission: true,
                    default: ''
                },
                folder_id: {
                    name: 'folder_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                character_id: {
                    name: 'character_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                closet_character_id: {
                    name: 'closet_character_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                ledger_id: {
                    name: 'ledger_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                tag_ids: {
                    name: 'tag_ids',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                contact_id: {
                    name: 'contact_id',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                department_id: {
                    name: 'department_id',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                address_id: {
                    name: 'address_id',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                location_id: {
                    name: 'location_id',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                acquired_date: {
                    name: 'acquired_date',
                    type: 'date',
                    has_permission: true,
                    default: ''
                },
                acquisition_type: {
                    name: 'acquisition_type',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: [
                        {id: 1, name: 'Purchase'},
                        {id: 2, name: 'Rental'},
                        {id: 3, name: 'Product Placement'},
                        {id: 4, name: 'Manufactured'},
                        {id: 5, name: 'Borrowed'},
                        {id: 6, name: 'Free'}
                    ]
                },
                asset_liability: {
                    name: 'asset_liability',
                    type: 'single-checkbox',
                    has_permission: true,
                    default: '',
                    options: LiabilityOptions
                },
                acquired_using: {
                    name: 'acquired_using',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: [
                        {id: 1, name: 'Purchase Order'},
                        {id: 2, name: 'Cash'},
                        {id: 3, name: 'Credit Card'},
                        {id: 4, name: 'Expenses'},
                        {id: 5, name: 'Cheque'}
                    ]
                },
                supplier_id: {
                    name: 'supplier_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                po_id: {
                    name: 'po_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                events: {
                    name: 'events',
                    type: 'events-repeater',
                    has_permission: true,
                    default: '',
                    options: []
                },
                final_disposition_planning: {
                    name: 'final_disposition_planning',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                fdp_status: {
                    name: 'fdp_status',
                    type: 'single-checkbox',
                    has_permission: true,
                    default: '',
                    message: 'Confirm Final Disposition Plan for this Asset?',
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
                valuation_method: {
                    name: 'valuation_method',
                    type: 'single-checkbox',
                    has_permission: true,
                    default: '',
                    options: [
                        { id: 0, name: 'Fixed Price'},
                        { id: 1, name: 'Fixed Percentage'}
                    ]
                },
                forecast_value:{
                    name: 'forecast_value',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                asset_post: {
                    name: 'asset_post',
                    type: 'asset_post',
                    has_permission: true,
                    default_value: ''
                },
            }
        }
    },
    computed: {
        is_closet(){
            return Object.keys(this.required_fields).includes('closet_character_id');
        },
        is_inventory(){
            return Object.keys(this.required_fields).includes('character_id');
        },
        selected_character(){
            return this.addForm.hasOwnProperty('character_id') ? this.addForm.character_id : null;
        },

    },
    watch: {
        selected_character: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal){
                    if (newValue && newValue.hasOwnProperty('ledger_id')){
                        this.addForm.ledger_id = this.getValueOf('ledger_id', newValue.ledger_id);
                    }
                }
            }
        },
    },
    created() {
        this.all_sub_modal_id = this.modal_id + this.sub_modal_id;
        this.asset_info = this.received_asset_info;
        this.form_lists = this.$root.form_lists;
        if (this.form_lists && this.form_lists.hasOwnProperty('status_list')){
            this.lists_values = {
                status: this.form_lists.status_list,
                address_id: this.form_lists.addresses_list,
                location_id: this.form_lists.locations_list,
                department_id: this.form_lists.departments_list,
                contact_id: this.form_lists.contacts_list,
                folder_id: this.form_lists.folders_list,
                character_id: this.form_lists.character_short_list,
                closet_character_id: this.form_lists.character_short_list,
                ledger_id: this.form_lists.ledgers_short_list,
                category_id: this.form_lists.categories_list,
                tag_ids: this.form_lists.tags_list,
                colours: this.form_lists.colours_list,
                composition: this.form_lists.compositions_list,
                asset_type: this.form_lists.assetTypes_list,
                supplier_id: this.form_lists.suppliers_list,
                events: this.form_lists.events_list,
                tasks: this.form_lists.tasks_list,
                final_disposition_planning: this.form_lists.final_disposition_planning,
                disposition_type: this.form_lists.dispositions_list
            }
        }else{
            this.getFieldsList();
        }
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};

            let required_select_lists =  {
                final_disposition_planning: 'final_disposition_planning',
                character_id: 'character_short_list',
                closet_character_id: 'character_short_list',
                ledger_id: 'ledgers_short_list',
                status: 'status_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'asset-generic-form',
                    object_type: 'assets',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }
                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

            }).catch(error => {
                console.log(error);
            });
        },
        addressLocationChange(new_item, item_type) {
            if (new_item) {
                // should append this to the list
                let item_type_key = item_type + '_id';
                let new_option = {id: new_item.id, name: new_item.name};

                if (item_type === 'address_id') {
                    new_option.is_default = new_item.is_default;
                    if (new_option.is_default) {
                        let default_text = ' <= Default';
                        new_option.name = new_option.name + default_text;

                        this.reset_default_items(item_type_key, new_option, 'is_default', default_text);
                    }

                    if (new_item.locations) {
                        let new_location = new_item.locations[0];
                        let new_location_option = {
                            id: new_location.id,
                            name: new_location.name,
                            is_primary: new_location.is_primary,
                            parent_address: {id: new_item.id}
                        };

                        if (new_location_option.is_primary) {
                            let default_text = ' <= Primary';
                            new_location_option.name = new_location_option.name + default_text;
                        }
                        this.required_fields.location_id.options = [
                            {id: -99, name: 'Add New'},
                            new_location_option
                        ];
                        this.addForm.location_id = new_location_option;
                    }

                } else if (item_type === 'location_id') {
                    new_option.is_primary = new_item.is_primary;
                    if (new_option.is_primary) {
                        let default_text = ' <= Primary';
                        new_option.name = new_option.name + default_text;
                        this.reset_default_items(item_type_key, new_option, 'is_primary', default_text);
                    }

                }

                this.required_fields[item_type_key].options.push(new_option);
                this.addForm[item_type_key] = new_option;

            }
        },
        reset_default_items(item_id_name, new_option, default_value, default_text = '') {
            // shall reset other primaries
            let new_options = this.required_fields[item_id_name].options.map(item => {
                if (item[default_value]) {
                    item[default_value] = false;
                    item.name = item.name.replace(default_text, '');
                }
                return item;
            });
            this.required_fields[item_id_name].options = new_options;
        },
        cancelGenericModal(item=null, message=null, status=null) {
            this.addForm = {};
            this.$bvModal.hide(this.modal_id);
            this.asset_info = this.asset_info;
            if (item){
                this.$emit('on-post-adding', item);
            }
            if (message){
                this.presentToast("Success!",
                    message,
                    status,
                    3000,
                    true,
                    true);
            }
        },
        cancelClicked(item_id_key){
            this.addForm[item_id_key] = null;
            if (item_id_key === 'address_id'){
                this.addForm.location_id = null;
            }
        },
        handleQueryItems(listQuery = {}) {
            if (this.current_sp && this.item_type === 'sp') {
                listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                listQuery.customer_id = this.current_customer.id;
            }
            return listQuery;
        },
        async getPOsListThenFill(supplier_id, selected_po_id=null){
            this.isLoadingPOs = true;
            let listQuery = this.handleQueryItems();
            listQuery.supplier_id = supplier_id;
            listQuery.only_ids = true;
            const {data} = await purchaseOrderResource.list(listQuery);
            let add_new_option = [];
            if (this.hasPermission('add_purchaseOrders')){
                add_new_option.push({
                    id: -99,
                    name: 'Add New'
                });
            }

            let pos_list = data.data.items;
            this.required_fields.po_id.options = add_new_option.concat(pos_list);

            if (!selected_po_id && this.addForm.po_id) {
                selected_po_id = this.addForm.po_id.id;
            }
            let po_options = this.required_fields.po_id.options;
            let selected_po_index = po_options.map(function (e) {
                return e.id;
            }).indexOf(parseInt(selected_po_id));

            if (selected_po_index > -1){
                this.addForm.po_id = po_options[selected_po_index];
            }else{
                console.log("not found!");
                console.log(selected_po_id);
            }

            this.isLoadingPOs = false;
        },
        addedNewPO(new_item) {
            if (new_item.supplier_id) {
                this.required_fields.po_id.options.push(new_item);
                this.addForm.po_id = new_item;
            }
        },
        changedSelectField(field, event){
            let field_name = field.name;
            let event_id = event.id;

            this.checkBoxClicked(field, event);

            if (event_id === -99){
                // shall add new
                switch (field_name){
                    case 'department_id':
                        this.$refs['add-new-department'].setSelectedItem(null);
                        break;
                    case 'address_id':
                        this.addForm.location_id = null;
                        this.$refs['add-new-address'].setSelectedItem(null);
                        this.setupAddressLookup('address_actions');
                        break;
                    case 'location_id':
                        let selected_address = this.addForm.address_id;
                        this.$refs['add-new-location'].setSelectedItem(null);
                        this.$refs['add-new-location'].set_addresses_list([{value: selected_address.id, text: selected_address.name}], true);
                        this.$refs['add-new-location'].setAddressID(selected_address.id);
                        break;
                    case 'po_id':
                        let selectedValues = {
                            supplier_id: this.addForm.supplier_id
                        };
                        this.$refs['add-new-purchaseOrder'].setSelectedItem(selectedValues);
                        this.$refs['add-new-purchaseOrder'].setSuppliersList([selectedValues.supplier_id]);
                        this.$bvModal.show('create-purchaseOrder' + this.all_sub_modal_id);
                        this.addForm.po_id = null;
                        break;
                }

                this.addForm[field_name] = null;
                this.$bvModal.show('create-' + field_name.replace('_id', '') + this.all_sub_modal_id);
            }else if (field_name === 'address_id'){
                // should filter items in locations list.
                this.$refs['add-new-location'].set_addresses_list([{value: event_id, text: event.name}], true);
                this.$refs['add-new-location'].setAddressID(event.id);
                this.setSelectedLocationFor(event, true);
            }else if (field_name === 'supplier_id'){
                this.getPOsListThenFill(event_id);
            }
        },
        checkBoxClicked(required_field, option, event){
            let new_val = {};
            if (this.asset_id_values.includes(required_field.name)){
                new_val[required_field.name] = option.id;
            }else{
                new_val[required_field.name] = option;
            }

            this.addForm = Object.assign({}, this.addForm, new_val);
            this.asset_info = Object.assign({}, this.asset_info, new_val);

        },
        fillOptionsList: function () {
            for (let key in this.lists_values) {
                if (['events', 'tasks'].includes(key)) {
                    continue;
                }
                this.getOptionsForKey(key);
            }

            if (typeof this.lists_values.asset_type === 'undefined') {
                this.lists_values.asset_type = [];
            }
        },
        assignValueToForm: function (key_name, value) {
            let tag_ids_value = {}
            tag_ids_value[key_name] = value;

            this.addForm = Object.assign({}, this.addForm, tag_ids_value);
        },

        setSelectedAssetInfo(item){
            this.asset_info = item;
        },
        setSelectedTask(task, field_keys){
            this.required_task = task;
            this.addForm = {};

            let field_key = task && task.field_key ? task && task.field_key : field_keys;
            this.required_keys = field_key;

            if (field_key !== ''){
                let field_names = field_key.split(',');
                this.required_fields = {};
                let keys_to_fill = [];
                for (const fieldNamesKey in field_names) {
                    let field_name = (field_names[fieldNamesKey]).trim();
                    let required_field = this.all_fields[field_name];
                    if (required_field && required_field.hasOwnProperty('name')){
                        this.required_fields[field_name] = required_field;
                        keys_to_fill.push(field_name);
                        this.addForm[field_name] = null;
                    }
                }

                this.fillOptionsList();

                if (this.asset_info){
                    for (const adKey in this.asset_direct_values) {
                        let direct_value = this.asset_direct_values[adKey];
                        if (keys_to_fill.includes(direct_value)){
                            this.assignValueToForm(direct_value, this.asset_info[direct_value]);
                        }
                    }

                    for (const adKey in this.asset_id_values) {
                        let direct_value = this.asset_id_values[adKey];

                        if (keys_to_fill.includes(direct_value)){
                            let value_object = this.getValueOf(direct_value, this.asset_info[direct_value]);
                            this.assignValueToForm(direct_value, (value_object ? value_object.id : null));
                        }
                    }

                    for (const adKey in this.asset_object_values) {
                        let direct_value = this.asset_object_values[adKey];
                        if (keys_to_fill.includes(direct_value)){
                            this.assignValueToForm(direct_value, this.getValueOf(direct_value, this.asset_info[direct_value]));
                        }
                    }

                    if (keys_to_fill.includes('tag_ids')){
                        this.assignValueToForm('tag_ids', this.getValueOf('tag_ids', this.asset_info.tag_ids, true));
                    }
                    if (keys_to_fill.includes('custom_fields')){

                        let asset_meta = this.parseCustomFieldsFrom(this.asset_info.asset_meta);

                        this.assignValueToForm('asset_meta', asset_meta);
                    }

                    if (this.asset_info.hasOwnProperty('asset_meta') && this.asset_info.asset_meta){
                        let meta_keys = this.meta_keys_for_acquisition;
                        for (const key in meta_keys) {
                            let value_name = meta_keys[key];
                            if (!this.asset_id_values.includes(value_name) && keys_to_fill.includes(value_name)){
                                if (value_name === 'po_id') {
                                    this.addForm.po_id = {
                                        id: this.asset_info.asset_meta[value_name],
                                        uid: '',
                                        reference: ''
                                    }
                                }else{
                                    this.addForm[value_name] = this.getValueOf(value_name, this.asset_info.asset_meta[value_name]);


                                    if (value_name === 'supplier_id' && this.required_fields.supplier_id.options){
                                        let supplier_id = this.addForm[value_name] ? this.addForm[value_name].id : (this.required_fields.supplier_id.options.length > 0 ? this.required_fields.supplier_id.options[0].id : null);
                                        if (supplier_id){
                                            this.getPOsListThenFill(supplier_id);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }

        },
        updateParentAsset (key, required_value, is_meta) {
            if (is_meta) {
                // shall save to meta
                if (!this.asset_info.hasOwnProperty('asset_meta')) {
                    this.asset_info.asset_meta = {};
                }
                this.asset_info.asset_meta[key] = required_value;
                if (key === 'supplier_id'){
                    let object_value = this.getValueOf(key, required_value);
                    this.asset_info.supplier_info = object_value;
                }
            } else {
                if (key === 'asset_meta' && required_value.hasOwnProperty('custom_fields')){
                    let copied_value = Object.assign({}, required_value);
                    let custom_fields = [];
                    for (const customFieldsKey in copied_value.custom_fields) {
                        let customField = copied_value.custom_fields[customFieldsKey];
                        custom_fields.push(customField.field_name)
                    }
                    copied_value.custom_fields = custom_fields;
                    this.asset_info[key] = copied_value;
                }else{
                    this.asset_info[key] = required_value;
                    if (this.asset_object_values.includes(key)){
                        let object_value = this.getValueOf(key, required_value);
                        if (key === 'folder_id'){
                            this.asset_info.parent_folder = object_value;
                        }else if (['character_id', 'closet_character_id'].includes(key)){
                            this.asset_info.character_info = object_value;
                        }else if (key === 'ledger_id'){
                            this.asset_info.ledger_info = object_value;
                        }else if (key === 'contact_id'){
                            this.asset_info.contact_id = object_value.id;
                            this.asset_info.contact_name = object_value.name;
                            this.asset_info.contact_initials = object_value.initials;
                        }else if (key === 'address_id'){
                            this.asset_info.parent_address = object_value;
                            this.asset_info.address_location_longitude = object_value.longitude;
                            this.asset_info.address_location_latitude = object_value.latitude;
                        }else if (key === 'location_id'){
                            this.asset_info.address_location_id = object_value.id;
                            this.asset_info.address_location_name = object_value.name;
                        }else if (key === 'final_disposition_planning'){
                            this.asset_info.fdp_type_info = object_value;
                        }
                    }else if (key === 'tag_ids'){
                        let object_value = this.getValueOf(key, required_value, true);
                        this.asset_info.tags_info = object_value;
                    }
                }
            }

            this.asset_info = this.asset_info;
        },
        markTaskAsDone(task){
            if (task && task.hasOwnProperty('pivot')){
                this.mark_task_as_done(this.asset_info.id, task.pivot.event_id, task.id);
            }
        },
        async saveValues(event){
            this.isAddingItem = true;
            let found_one = false;
            for (var key in this.addForm) {
                let value = this.addForm[key];
                if (value !== null){
                    // shall handle custom saving processes
                    if (key === 'custom_fields'){
                        if (value.length !== 0){
                            value = value.filter(e => (e.field_name !== "" || e.field_value !== "") );
                        }
                    }else if (key === 'asset_meta'){
                        if (value.hasOwnProperty('custom_fields')){
                            let custom_fields = value.custom_fields.filter(e => (e.field_name !== "" || e.field_value !== "") );
                            for (const customFieldsKey in custom_fields) {
                                let customField = custom_fields[customFieldsKey];
                                if (customField.hasOwnProperty('field_name')){
                                    value[customField.field_name] = customField.field_value;
                                }
                            }
                        }

                    }else if (key === 'tag_ids'){
                        value = this.getIDs(value);
                    }else if (key === 'events_fields_list'){
                        if (value.length !== 0){
                            let events = [];
                            for (const eventsFieldsListKey in value) {
                                let event_field = value[eventsFieldsListKey];
                                let edited_field = {
                                    event_id: event_field.event_id.id,
                                    tasks: event_field.tasks.map((x) => x.id)
                                };
                                events.push(edited_field);
                            }
                            value = events;
                        }
                    }
                    let is_meta = key !== 'asset_liability' && this.meta_keys_for_acquisition.includes(key);
                    let required_value = (value.hasOwnProperty('id') ? value.id : value);
                    this.set_item_value('assets', this.asset_info.id, key, required_value, '', false, is_meta);

                    this.updateParentAsset(key, required_value, is_meta);

                    if (!found_one){
                        found_one = true;
                    }
                }
            }

            if (found_one){
                let message = '';
                if (this.custom_keys_only){
                    message = "Updated Successfully";
                }else{
                    message = "Updated Task {" + Vue.options.filters.titleize(this.required_task.name) + "} of {"+this.asset_info.name+"} Successfully";
                }

                this.presentToast("Success!",
                    message,
                    'success',
                    3000,
                    true,
                    true);
            }


            this.markTaskAsDone(this.required_task);

            this.isAddingItem = false;
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-completing-task', this.required_task, this.asset_info, this.required_keys);
        }
    }
}
</script>
