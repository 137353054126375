<template>
    <div v-if="addForm[form_key]">
        <div class="st--common--box">
            <div class="common-st-title-label">
                <b-form-checkbox
                    v-model="addForm['assign_' + item_name +'_by_default']"
                    :id="'assign_' + item_name +'_by_default'"
                    :name="'assign_' + item_name +'_by_default'"
                    :value="1"
                    :unchecked-value="0"
                    class="custom-checkbox mb-3">
                    Assign Assets by Default
                </b-form-checkbox>
            </div>

            <small v-if="addForm['assign_' + item_name +'_by_default']">Select Default {{item_name | titleizeToSingle}}</small>

            <multiselect
                v-if="addForm['assign_' + item_name +'_by_default']"
                v-model="addForm['default_assigned_' + item_name]"
                track-by="id"
                :custom-label="CustomMSLabel"
                :class="item_name + '-options'"
                :options="itemsData"
                :allow-empty="false"
                :placeholder="getSelectPlaceholder()">
                <span slot="noResult">No data found.</span>
            </multiselect>
        </div>
    </div>
</template>
<script>
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: 'AssignItemByDefault',
    props: {
        addForm: {},
        form_key: {},
        setting: {},
    },
    data(){
        return {
            item_name: '',
            itemsData: [],
            listLoading: false,
        }
    },
    created() {
        switch (this.setting.id){
            case 'assets_asset_location':
                this.item_name = 'locations';
                break;
            case 'assets_asset_department':
                this.item_name = 'departments';
                break;
            case 'assets_asset_user':
                this.item_name = 'users';
                break;
            case 'assets_folders':
                this.item_name = 'folders';
                break;
        }

        this.getList();

    },
    methods:{
        getSelectPlaceholder(){
            return 'Select Default ' + Vue.options.filters.titleizeToSingle(this.item_name);
        },

        getList() {
            if (!this.listLoading) {
                let item_name = this.item_name;
                if (item_name === 'locations'){
                    item_name = 'address_locations';
                }
                this.listLoading = true;
                axios({
                    method: 'get',
                    url: '/api/assetflow/' + item_name + '?customer_id=' + this.current_customer.id ,
                    baseURL: '/',
                    data: {
                    },
                }).then(response => {
                    this.itemsData = response.data.data.items;
                    this.listLoading = false;

                    if (this.itemsData.length > 0 && this.addForm['assign_' + this.item_name +'_by_default']){

                        let assigned_item_id = this.addForm['default_assigned_' + this.item_name];

                        let selected_item_index = this.itemsData.map(function (e) {
                            return e.id;
                        }).indexOf(assigned_item_id);
                        let formCopy = this.addForm;
                        formCopy['default_assigned_' + this.item_name] = this.itemsData[selected_item_index];

                        this.addForm = Object.assign({}, formCopy);
                        this.$emit('update:addForm', Object.assign({}, formCopy));
                    }
                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });
            }
        },
    },

}
</script>
