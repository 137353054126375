<template>
    <div class="AssetLayoutLeftBox">
        <div class="common_col_bg_box">
            <div class="row">
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span v-iconize="'suppliers'">Supplier</span>
                        <span v-if="supplier_info" class="change-supplier-action" style="float: right;">
                            <a href="javascript:void(0);" @click="performAction(my_asset, 'changeSupplier', $event)">Change</a>
                        </span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="supplier_info && supplier_info.hasOwnProperty('name')" :href="'/' + item_type + '/suppliers/' + supplier_info.id + '/view'">{{ supplier_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>Purchase Order</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="purchase_orders_info && purchase_orders_info.length > 0">{{ POWithUIDs(purchase_orders_info[purchase_orders_info.length-1]) }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>Acquisition Method</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_info && item_info.hasOwnProperty('acquisition_method') && acquisition_methods[item_info.acquisition_method]">{{ acquisition_methods[item_info.acquisition_method].name }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>{{ no_of_assets ? no_of_assets + ' ' : '' }}Assets</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="no_of_assets && no_of_assets > 0"
                                  v-b-tooltip:hover.bottom
                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.assets_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.assets_value)}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>{{ no_of_payments ? no_of_payments + ' ' : '' }}Payments</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="no_of_payments && no_of_payments > 0"
                                  v-b-tooltip:hover.bottom
                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.payments_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.payments_value)}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>Required Payment</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-b-tooltip:hover.bottom
                                  v-if="required_payment"
                                  :title="toUserCurrencyValue(item_info_currency.value, required_payment)">
                                                {{item_info_currency.icon + parseFloat(required_payment)}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>{{ no_of_assets ? 'Asset Name' : 'Assets' }}</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="no_of_assets === 1 && my_asset">
                                <a :href="'/' + item_type + '/assets/'+my_asset.id+'/view'">{{ my_asset.name }}</a>
                            </span>
                            <span v-else-if="item_info"><a :href="'/' + item_type + '/acquisitions/' + item_info.id + '/view?t=assets'">View Assets</a></span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>Returns Deadline</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_return_policy">{{ item_info.return_expiry_date | moment('MMMM Do YYYY')}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :class="item_class">
                        <span>Returned To Date</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span>{{item_info_currency.icon + '0'}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'AcquisitionRightBox',
    props: {
        my_asset: {},
        item_type: {},
        item_class: {},
        received_acquisition_methods: {},
        item_columns: { default: 'col-md-4'},
        performAction: {}
    },
    data(){
        return {
            acquisition_methods: [],
        }
    },
    computed:{
        item_info(){
            return this.getValueOrDefault(this.my_asset, 'acquisition_info', null);
        },
        supplier_info(){
            return this.getValueOrDefault(this.my_asset, 'supplier_info', null);
        },
        acquisition_type_info(){
            return this.getValueOrDefault(this.item_info, 'acquisition_type_info', null);
        },
        purchase_orders_info(){
            return this.getValueOrDefault(this.item_info, 'purchase_orders_info', null);
        },
        no_of_assets(){
            return this.getValueOrDefault(this.item_info, 'no_of_assets', null);
        },
        no_of_payments(){
            return this.getValueOrDefault(this.item_info, 'no_of_payments', null);
        },
        required_payment(){
            return this.getValueOrDefault(this.item_info, 'required_payment', null);
        },
        item_return_policy(){
            return this.getValueOrDefault(this.item_info, 'item_return_policy', null);
        },
        item_info_currency(){
            let currency = this.item_info && this.item_info.currency !== '' ? this.item_info.currency : 'gbp';
            return this.getCurrencyByValue(currency);
        },
    },
    created() {
        this.acquisition_methods = this.received_acquisition_methods;
    },
    methods:{
    }
}
</script>
