export default {
    data() {
        return {
        }
    },
    methods: {
        resetField(field){
            this.addForm.single_select_v_models[field] = null;
        },
        addedDispositionType(new_item, field) {
            if (new_item) {
                let options_count = this.required_fields.disposition_type.options.length;
                this.required_fields.disposition_type.options.splice(options_count - 1,0, new_item);

                let fdp_count = this.required_fields.final_disposition_planning.options.length;
                this.required_fields.final_disposition_planning.options.splice(fdp_count - 1,0, new_item);
                this.addForm.single_select_v_models[field] = new_item;
            }
        },
        selectedRadioButton(field, option, event){
            if (option.id === -99){
                if (field === 'disposition_type'){
                    this.$refs['add-new-disposition-type'].setSelectedItem(null);
                    this.addForm.single_select_v_models.disposition_type = null;
                    this.$bvModal.show('add-new-disposition-type');
                }else if (field === 'final_disposition_planning'){
                    this.$refs['add-new-fdp'].setSelectedItem(null);
                    this.addForm.single_select_v_models.final_disposition_planning = null;
                    this.$bvModal.show('add-new-fdp');
                }
            }
        },
    }
}
