// -----------------------------------------------------------------------------
// KoolChart License Key
//
// Product Name : KoolChart v6.5
// License Type : Premium License (trial)
// Product No : C76B-15F4-BE96-7401
// Authenticated Server : *
// Expiration Date : 2021/05/01
//
KoolChartLicense = "84afecd9504ccd603d6ddf752d6ce8454c6bc5909c5651d8e80e1b82b93559a5:3500660b34434e3a4f4220504c2056313a3032342e37302d2036503956453a424b2d35342d46503552312d2d36422e3635372d43453a564c41204c312030452f4c353a30662f203143323a303232303a32453120302a333a324834";
