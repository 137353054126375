<script>

    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import DetailsSection from "./reusables/DetailsSection";

    import UsersList from "./reusables/UsersList";
    import SpsList from "./reusables/SpsList";

    export default {
        name: "Overview",
        components: {SpsList, UsersList, Layout, PageHeader, DetailsSection },
        data() {
            return {
                title: "Overview",
                items: [
                    {
                        text: "Customers",
                        href: "./customers"
                    },
                    {
                        text: "Overview",
                        active: true
                    }
                ],
                image_width: process.env.MIX_DEFAULT_IMGS_WIDTH,
                api_url:"",
                usersData:[],
                usersListLoading: false,
                primary_colour_style:{
                    backgroundColor: "#"
                },
                accent_colour_style:{
                    backgroundColor: "#"
                },

            };
        },
        created() {
            this.items[0].text = this.item_title;
            this.item_info = JSON.parse(this.item_info_string);
            this.items[1].text = this.item_info.name;

            this.primary_colour_style.backgroundColor = "#" + this.item_info.meta.primary_colour;
            this.accent_colour_style.backgroundColor = "#" + this.item_info.meta.accent_colour;

            this.api_url = '/api/assetflow/customers/' + this.item_info.id + '/service_providers';
            this.getUsersList();

        },
        props: {
            item_title: {
                type: String,
                required: false,
                default: () => null
            },
            item_info_string: {
                type: String,
                required: false,
                default: () => null
            }
        },
        methods: {
            getUsersList() {
                if (!this.usersListLoading) {
                    this.usersListLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/customers/' + this.item_info.id + '/users' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20,
                            warehouse_id: this.item_info.sp_id
                        },
                    }).then(response => {
                        this.usersData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.usersListLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="this.title" :items="items" />
        <h3>
            <slot />
        </h3>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="bg-soft-primary">
                            <div class="row">
                                <div class="col-7">
                                    <div class="text-primary p-3">
                                        <h5 class="text-primary">{{this.item_info.name}}</h5>
                                        <img :src="this.item_info.logo" :alt="this.item_info.name" :width="this.image_width">
                                    </div>
                                </div>
                                <div class="col-5 align-self-end">
                                    <img :src="getAssetBaseUrl + '/images/profile-img.png'" alt class="img-fluid" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <details-section header_title="Overview">
                <div class="row">
                    <div class="col-xl-4">
                        <div class="text-center p-4 border-right">
                            <div class="avatar-sm mx-auto mb-4">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">{{this.item_info.initials}}</span>
                            </div>
                            <h5 class="text-truncate">{{this.item_info.name}}</h5>
                        </div>
                    </div>

                    <div class="col-xl-8">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Service Providers</p>
                                    <h5>{{this.item_info.assigned_containers_count}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Assets in Storage</p>
                                    <h5>{{this.item_info.assigned_assets_count}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Orders</p>
                                    <h5>{{this.item_info.assigned_containers_count}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Users Assigned</p>
                                    <h6>
                                        <a class="text-secondary" href="#">All <span class="badge badge-primary">{{this.item_info.active_users_count + this.item_info.inactive_users_count}}</span></a>
                                        |
                                        <a class="text-secondary" href="#">Active <span class="badge badge-success">{{this.item_info.active_users_count}}</span></a>
                                        |
                                        <a class="text-secondary" href="#">Inactive <span class="badge badge-danger">{{this.item_info.inactive_users_count}}</span></a>
                                    </h6>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </details-section>

            <div v-if="this.item_info.currentAdminRoles.isSuperAdmin" class="col-12 text-right">
                <div class="mt-4">
                    <a :href="'./customers/' + this.item_info.id + '/edit'" class="btn btn-primary btn-sm">
                        Edit {{this.item_info.name}}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                    </a>
                </div>
            </div>

            <details-section v-if="this.item_info.currentAdminRoles.isSuperAdmin" header_title="Appearance">
                <div class="row">
                    <div class="col-xl-4">
                        <div class="text-center p-4 border-right">
                            <p>
                                <img :src="this.item_info.logo" :alt="this.item_info.name" width="300px" />
                            </p>
                            <h5 class="text-truncate">{{this.item_info.name}}</h5>
                        </div>
                    </div>

                    <div v-if="this.item_info.meta" class="col-xl-8">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Primary Colour</p>
                                    <div class="avatar-sm mb-2">
                                        <span class="avatar-title rounded-circle" :style="primary_colour_style"></span>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Secondary Colour</p>
                                    <div class="avatar-sm mb-2">
                                        <span class="avatar-title rounded-circle" :style="accent_colour_style"></span>
                                    </div>
                                </div>


                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Theme Settings</p>
                                    <h5>Theme Settings Placehodler..</h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </details-section>

            <details-section v-if="this.item_info.currentAdminRoles.isSuperAdmin" header_title="Functionality & Defaults">
                <div class="row">
                    <div v-if="this.item_info.meta" class="col-xl-12">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="text-muted mb-2 text-truncate">Currency</p>
                                    <h5>{{this.item_info.meta.currency}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </details-section>


            <details-section header_title="Details">
                <div class="row">

                    <div class="col-xl-12">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Contact</p>
                                    <h5>{{this.item_info.contact}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Phone</p>
                                    <h5>{{this.item_info.phone}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Website</p>
                                    <h5><a :href="this.item_info.website" target="_blank">{{this.item_info.website}}</a></h5>
                                </div>
                                <div class="col-md-6"></div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Company Registration No</p>
                                    <h5>{{this.item_info.company_registration_no}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">VAT Registration No</p>
                                    <h5>{{this.item_info.vat_registration_no}}</h5>
                                </div>


                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Created Date</p>
                                    <h6>{{this.item_info.created_at | moment("h:mm:ss a")}}</h6>
                                    <h5>{{this.item_info.created_at | moment("dddd, MMMM Do YYYY")}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Modified Date</p>
                                    <h6>{{this.item_info.updated_at | moment("h:mm:ss a")}}</h6>
                                    <h5>{{this.item_info.updated_at | moment("dddd, MMMM Do YYYY")}}</h5>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </details-section>

            <details-section header_title="Users">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="text-muted mb-2 text-truncate">Users Assigned</p>
                                    <h6>
                                        <a class="text-secondary" href="#">All <span class="badge badge-primary">{{this.item_info.active_users_count + this.item_info.inactive_users_count}}</span></a>
                                        |
                                        <a class="text-secondary" href="#">Active <span class="badge badge-success">{{this.item_info.active_users_count}}</span></a>
                                        |
                                        <a class="text-secondary" href="#">Inactive <span class="badge badge-danger">{{this.item_info.inactive_users_count}}</span></a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-12">
                                    <users-list :item_info="item_info" :usersData="usersData"></users-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </details-section>

            <details-section v-if="this.item_info.currentAdminRoles.isSuperAdmin" header_title="Assigned SP's">

                <div class="row">
                    <div class="col-xl-12">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">
                                <div class="col-md-12">
                                    <sps-list :item_info="item_info" :api_url="this.api_url" :with_sp="true"></sps-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </details-section>

        </div>


    </Layout>
</template>
