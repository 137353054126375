var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:'create-'+_vm.modal_id,attrs:{"id":_vm.modal_id,"size":"xl","title-class":"font-18","hide-footer":""},on:{"hide":_vm.resetSelectedItem},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',[_vm._v("Change Asset Storage")])]},proxy:true}])},[_vm._v(" "),_c('ChangeStorageUtilisation',{ref:"change-sp_storages-utilisation",attrs:{"modal_id":"change-sp_storages-utilisation","item_type":"sp_storages","modal_title":"Change Storage Utilisation"},on:{"on-utilisation-change":_vm.refreshItem}}),_vm._v(" "),_c('ParkStorageItem',{ref:"assign-sp_storages-location",attrs:{"modal_id":"assign-sp_storages-location","item_type":"sp_storages","modal_title":"Park Storage"},on:{"on-park-storage":_vm.refreshItem}}),_vm._v(" "),_c('RemoveStorageLocation',{ref:"remove-sp_storages-location",attrs:{"modal_id":"remove-sp_storages-location","item_type":"sp_storages","modal_title":"Remove Storage"},on:{"on-remove-storage":_vm.refreshItem}}),_vm._v(" "),_c('AssignAndGetStorage',{ref:"assign-and-get-sp_storages",attrs:{"modal_id":"assign-and-get-sp_storages","item_type":"sp_storages","modal_title":"Assign Storage"},on:{"on-assign-to-customer":_vm.refreshItem}}),_vm._v(" "),_vm._l((_vm.required_sections),function(required_section,k){return _c('div',{key:k,staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header af-soft-accent text-af-primary"},[_vm._v("\n                    "+_vm._s(required_section.section_name)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('ChangeCommonFieldsAdvanced',{attrs:{"add-form":_vm.addForm,"required_fields":required_section.required_items},scopedSlots:_vm._u([{key:"recent_storage",fn:function(ref){
var index = ref.index;
var required_field = ref.required_field;
return _vm._l((required_field.options),function(storage,idx){return (required_field.options.length)?_c('div',{key:idx,staticClass:"recent-storage-item"},[_c('a',{staticClass:"text-dark",attrs:{"href":"#"},on:{"click":function($event){return _vm.setSelectedStorage(storage, $event)}}},[(storage && storage.hasOwnProperty('storage_wrapper_info') && storage.storage_wrapper_info)?_c('i',{class:storage.storage_wrapper_info.icon}):_vm._e(),_vm._v(" "+_vm._s(storage.name)+"\n                                ")])]):_vm._e()})}},{key:"selected_storage",fn:function(ref){
var index = ref.index;
var required_field = ref.required_field;
return [(_vm.selected_storage_info)?_c('po-lines',{attrs:{"custom_header":"Selected Storage:","custom_header_classes":"col-12","items":[_vm.selected_storage_info],"current_page_type":_vm.item_type,"object_type":"sp_storages","keys_list":_vm.sp_storage_keys_list}}):(_vm.isLoadingItem)?_c('div',[_vm._v("\n                                Loading Storage Info...\n                            ")]):_vm._e()]}},{key:"assign_storage",fn:function(ref){
var index = ref.index;
var required_field = ref.required_field;
return [(_vm.addForm.customer_id)?_c('div',[_c('b-button',{staticClass:"af-btn-accent af-accent mt-2",on:{"click":function($event){return _vm.assignStorage($event)}}},[_vm._v("Assign Storage to '"+_vm._s(_vm.addForm.customer_id.name)+"'")])],1):_vm._e()]}}],null,true)})],1)])])])}),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","variant":"success"},on:{"click":function($event){return _vm.addItem($event)}}},[_vm._v("\n            "+_vm._s(_vm.selected_item ? ('Update {' + _vm.selected_item.name + '}') : 'Create'))]),_vm._v(" "),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.cancelClicked}},[_vm._v("Cancel")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }