const FirstPath = window.location.pathname.split('/')[1];
export const IntakeKeysList = {
    id: {
        key: 'id',
        label: "SID",
        class: "col-lg-1 int_id",
        show_on_mobile: true,
        case: ''
    },
    uid: {
        key: 'uid',
        label: "Intake No",
        class: "col-lg-1 int_uid",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg int_name",
        show_on_mobile: true,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {
            return 'Created at: ' + Vue.options.filters.moment(item.created_at, 'MMMM Do YYYY');
        },
        value_func: function (index, item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-1 int_customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_info = item.customer_info;
            if (customer_info){
                return '<a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a>';
            }
            return '';
        }
    },
    sp_info: {
        key: 'sp_info',
        label: "Service Provider",
        class: "col-lg-1 int_sp_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let sp_info = item.sp_info;
            if (sp_info){
                return '<a class="text-dark" href="/' + FirstPath + '/service_providers/' + sp_info.id + '/view">' + Vue.options.filters.titleize(sp_info.name) + '</a>';
            }
            return '';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 int_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                {
                    name: 'Open',
                    class: 'order-status-open',
                },
                {
                    name: 'Confirmed',
                    class: 'order-status-confirmed',
                },
                {
                    name: 'In Progress',
                    class: 'order-status-in_progress',
                },
                {
                    name: 'Completed',
                    class: 'order-status-completed',
                },
                {
                    name: 'Cancelled',
                    class: 'order-status-cancelled',
                },
                {
                    name: 'Awaiting Transport',
                    class: 'order-status-awaiting_transport',
                },
            ];
            return '<span class="button_size status-btn-active ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span>';
        }
    },
    reference: {
        key: 'reference',
        label: "Ref",
        class: "col-lg-1 int_reference",
        show_on_mobile: false,
        case: ''
    },
    managed_storage_count: {
        key: 'managed_storage_count',
        label: "Managed Storage Count",
        class: "col-lg-1 int_managed_storage_count",
        show_on_mobile: false,
        case: ''
    },
    managed_storage_quantity: {
        key: 'managed_storage_quantity',
        label: "Managed Storage Qty",
        class: "col-lg-1 int_managed_storage_quantity",
        show_on_mobile: false,
        case: ''
    },
    user_info: {
        key: 'user_info',
        label: "Created By",
        class: "col-lg-1 int_user_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let user_info = item.user_info;
            if (user_info){
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
            }
            return '';
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-1 int_created_at",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'MMMM Do YYYY') : '';
        }
    },
};
