<template>
    <div class="tv-film-section">
        <div class="row">
            <div class="col-xl-6 st-col-6">

                <asset-info-section title="Usage">
                    <div v-if="asset_is_ended || asset_is_disposed">
                        <LockedAssetWarning alert_class="alert-primary" icon="bx bx-time" :message="'This Asset is ' + (asset_is_ended ? 'Ended' : 'Disposed') + ', \'Changing Usage\' is not available.'"/>
                    </div>

                    <div v-else class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Current Usage</h5>
                        </div>
                        <div class="st-status-radio-box">
                            <div class="st-radio-row">
                                <div v-for="(option, index) in required_fields.status.options"
                                     :key="index"
                                     class="st-radio-col-2">
                                    <div class="cm-radio-btn" :class="'status-' + option.id">
                                        <label>
                                            <input
                                                v-model="addForm.item_details.status"
                                                :key="index"
                                                :id="'_'+index"
                                                name="asset_status"
                                                :value="option.id"
                                                type="radio" />
                                            <div class="st-radio-box">
                                                <span></span>
                                                <p>{{ option.name | titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </asset-info-section>

                <asset-info-section title="TV & Film" v-if="is_tv_film_asset">
                    <div>
                        <div
                            v-for="tv_film_item in tv_film_items"
                            class="st--common--box">
                            <div class="common-st-title-label">
                                <h5>{{ tv_film_item.label }}</h5>
                            </div>

                            <div v-if="tv_film_item.type === 'checkbox'" class="st--common--box">
                                <div class="dimensions-btn">
                                    <a
                                        v-for="(option, index) in tv_film_item.options"
                                        :key="option.id"
                                        :value="option.id"
                                        @click="addForm.item_details[tv_film_item.key] !== option.id ? (addForm.item_details[tv_film_item.key] = option.id) : (addForm.item_details[tv_film_item.key] = null)"
                                        :class="[{'active': addForm.item_details[tv_film_item.key] === option.id}]"
                                        class="dimensions-auto">
                                        <span></span>{{ option.name | titleize }}
                                    </a>
                                </div>
                            </div>

                            <div v-else-if="tv_film_item.type === 'radio'" class="st-status-radio-box">
                                <div class="st-radio-row">
                                    <div v-for="(option, index) in tv_film_item.options"
                                         :key="index"
                                         class="st-radio-col-2">
                                        <div class="cm-radio-btn" :class="tv_film_item.key + '-' + option.id">
                                            <label>
                                                <input
                                                    v-model="addForm.item_details[tv_film_item.key]"
                                                    :key="index"
                                                    :id="'_'+index"
                                                    :name="tv_film_item.key"
                                                    :value="option.id"
                                                    type="radio" />
                                                <div class="st-radio-box">
                                                    <span></span>
                                                    <p>{{ option.name | titleize }}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </asset-info-section>

                <asset-info-section title="Custom Fields">
                    <CustomFieldsRepeater
                        :add-form="addForm"
                    />
                </asset-info-section>
            </div>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import CustomFieldsRepeater from "./CustomFieldsRepeater";
import LockedAssetWarning from "./LockedAssetWarning";
export default {
    name: "TVFilmInfo",
    components: {LockedAssetWarning, CustomFieldsRepeater, AssetInfoSection},
    props: ['addForm', 'required_fields', 'item_id', 'asset_is_ended', 'asset_is_disposed'],
    watch: {
        addForm: {
            deep: true,
            handler: function (newValue) {
                if (this.addForm.check_if_shall_activate_asset && this.addForm.item_details.status !== 'active' && this.item_id === ''){

                    let any_of_asset_tv_fields = this.addForm.item_details.asset_usage !== 2 || this.addForm.item_details.is_asset !== 2 || this.addForm.item_details.is_hero !== 2;
                    let filled_custom_fields = false;
                    if (this.addForm.hasOwnProperty('asset_meta') && this.addForm.asset_meta.hasOwnProperty('custom_fields')){
                        filled_custom_fields = this.addForm.asset_meta.custom_fields.length > 1;
                    }
                    if (any_of_asset_tv_fields || filled_custom_fields){
                        this.addForm.check_if_shall_activate_asset = false;
                        this.presentConfirmBox('Change Status', this.htmlToVNode('<p>Would you like to mark this Asset as <strong>\'Active\'</strong>?</p>'), 'primary', 'md')
                        .then(value => {
                            if (value){
                                this.addForm.item_details.status = this.required_fields.status.options[1].id;
                                this.addForm.item_details = Object.assign({}, this.addForm.item_details);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });

                    }

                }
            }
        },
    },
    data(){
        return {
            tv_film_items: {
                asset_usage: {
                    key: 'asset_usage',
                    label: 'Asset Usage',
                    type: 'checkbox',
                    options:[
                        { id: 1, name: 'On Camera'},
                        { id: 0, name: 'Off Camera'}
                    ]
                },
                is_hero: {
                    key: 'is_hero',
                    label: 'Is Hero',
                    type: 'checkbox',
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
                is_asset: {
                    key: 'is_asset',
                    label: 'Is Asset',
                    type: 'checkbox',
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
            }
        }
    },

}
</script>


