<template>
    <div class="ChangeSceneTasksTab">

        <div class="row">
            <NewCustomGenericBlock
                block_title="Tasks"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>

                    <po-lines v-if="item_info.hasOwnProperty('tasks') && item_info.tasks && item_info.tasks.length > 0"
                              :items="item_info.tasks"
                              :current_page_type="item_info.current_page_type"
                              :object_type="'tasks'"
                              :line_type="'tasks'"
                              :keys_list="tasks_key_list">

                    </po-lines>
                    <div v-else class="text-center">
                        <h6>No Tasks found here at the moment.</h6>
                    </div>
                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";

export default {
    name: 'ChangeSceneTasksTab',
    components: {NewCustomGenericBlock, PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            tasks_key_list: {}
        }
    },
    created() {
    },
    methods: {
        performAction(item, action_name, event) {
        },

    }

}
</script>
