<template>
    <div class="btn-group bl-auto">
        <button type="button" class="btn btn-none dropdown-toggle" :class="other_classes"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
        </button>
        <AssetsActionsList
            :is_closet_asset="is_closet_asset"
            :item="item" :item_type="item_type" :perform-action="performAction"/>
    </div>
</template>
<script>
import AssetsActionsList from "./AssetsActionsList";

export default {
    name: 'AssetActionsDropdown',
    components: {AssetsActionsList},
    props: {
        other_classes: '',
        item: {},
        is_closet_asset: {},
        item_type: {},
        performAction: {}
    }
}
</script>
