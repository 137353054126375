<template>
    <Layout>

        <EditPartShootDate
            ref="edit-part-shoot-date"
            item_type='part-shoot-date'
            modal_title="Edit Shoot Date"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </EditPartShootDate>

        <div class="parts-wrapper" id="wrapper">


            <AddNewChange
                ref="add-new-change"
                :item_type="'changes' + unique_id"
                modal_title="Create New Change"
                :with_item_details="true"
                @on-new-item-adding="addNewItemToOptions"
            >
            </AddNewChange>


            <AddSceneChange
                ref="add-change-to-scene"
                :item_type="'scene-change' + unique_id"
                modal_title="Assign Existing Change"
                :with_item_details="false"
                @on-new-item-adding="addNewItemToOptions"
            >
            </AddSceneChange>

            <AddSceneCharacter
                ref="add-character-to-scene"
                :item_type="'scene-character' + unique_id"
                modal_title="Add Character to Scene"
                :with_item_details="false"
                @on-new-item-adding="addNewItemToOptions"
            >
            </AddSceneCharacter>


            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Scene List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Parts.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>

                                <b-dropdown-item href="./scenes-costume/new">
                                    Part
                                </b-dropdown-item>

                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-if="supportedLayouts && Object.keys(supportedLayouts).length > 0"
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                    </div>


                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Parts...</h4>
                    </div>
                    <div v-else-if="!partsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Parts found here at the moment.</h4>

                    </div>
                    <!-- Product Section Start-->
                    <div v-else-if="[0, 1].includes(selectedLayout) && partsData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="permission_name"
                                :keys_list="part_keys_list">

                                <template v-slot:new_line="{ item, index }" v-if="selectedLayout === 0">
                                    <div class="tbody_item col-xs-12 col-sm-12 col-lg-12 scene_notes" v-if="item.cast_info.length > 0">
                                        <ul class="scene-notes-header">
                                            <li class="scene-notes-heading">Characters &amp; Changes</li>
                                            <li class="scene-notes-heading">Actions</li>
                                        </ul>

                                        <ul class="scene-notes-content" v-for="(character_info, key) in item.cast_info">

                                            <li class="change-status" v-if="character_info.hasOwnProperty('changes_info') && character_info.changes_info && character_info.changes_info.length > 0">
                                                <span v-for="(change_info, index) in character_info.changes_info">
                                                    <i class="font-size-18 bx bxs-circle status-icon" :class="change_info.status === 1 ? 'status-icon-green' : 'status-icon-orange'"></i> {{ change_info.status === 1 ? 'Established' : 'Planned' }}
                                                </span>
                                            </li>
                                            <li class="change-status" v-else>
                                                <span>
                                                    <i class="font-size-18 bx bxs-circle status-icon status-icon-red"></i> Required
                                                </span>
                                            </li>

                                            <li class="character-name">
                                                <a :href="'/' + item_type + '/characters/' + character_info.id + '/view'" class="text-dark">
                                                    ({{ character_info.hasOwnProperty('character_number') ? character_info.character_number : '' }}) {{character_info.name }}
                                                </a>
                                            </li>

                                            <li class="change-name" v-if="character_info.hasOwnProperty('changes_info') && character_info.changes_info && character_info.changes_info.length > 0">
                                                <a :href="'/' + item_type + '/changes/' + character_info.changes_info[0].id + '/view'" class="text-dark">Change #{{character_info.changes_info[0].name }}</a>
                                            </li>
                                            <li class="change-name" v-else>-</li>

                                            <li class="change-status" v-if="character_info.hasOwnProperty('changes_info') && character_info.changes_info && character_info.changes_info.length > 0">
                                                <a :href="'/' + item_type + '/changes/' + character_info.changes_info[0].id + '/view'" target="_blank">Change Notes</a> |
                                                <a :href="'/' + item_type + '/changes/' + character_info.changes_info[0].id + '/scene/' + item.id" target="_blank">Scene Notes</a> |
                                                <a :href="'/' + item_type + '/shoot-day-live?scene='+item.id+'&character='+character_info.id+'&change='+character_info.changes_info[0].id" target="_blank">SDL</a>
                                            </li>
                                            <li class="change-status" v-else><a href="javascript:void(0)" @click="performAction(item, 'create_change', index, key)">Create New</a> | <a href="javascript:void(0)" @click="performAction(item, 'assign_change', index, key)">Assign Existing</a></li>

                                        </ul>


                                    </div>
                                    <div v-else class="tbody_item col-xs-12 col-sm-12 col-lg-12 scene_notes">
                                        <ul class="scene-notes-footer">
                                            <li>Scene does not have Characters Yet. <a v-if="hasPermission('edit_' + permission_name)" href="javascript:void(0)" @click="performAction(item, 'add_character', index)">Add One Now?</a></li>
                                        </ul>
                                    </div>
                                </template>

                                <template v-slot:parts_actions="{ item, index }">
                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item v-if="hasPermission('edit_' + permission_name)" :href="'./scenes-costume/'+item.id + '/edit'">Edit</b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('edit_' + permission_name)" href="javascript:void(0)" @click="performAction(item, 'shoot_date', index)">Edit Shoot Date</b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('edit_' + permission_name)" href="javascript:void(0)" @click="performAction(item, 'add_character', index)">Add Character to Scene</b-dropdown-item>

                                            <b-dropdown-item v-if="hasPermission('delete_' + permission_name)"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete'+permission_name, index)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>
                                </template>

                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";

const partResource = new Resource('api/assetflow/parts');
import {PartKeysList} from "@/views/assetflow/form_data/PartKeysList";
import {ReturnDatesList} from "@/views/assetflow/form_data/ReturnDatesList";
import EditPartShootDate from "../extras/EditPartShootDate";
import AddNewChange from "../extras/AddNewChange";
import AddSceneChange from "../extras/AddSceneChange";
import AddSceneCharacter from "../extras/AddSceneCharacter";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";



export default {
    name: 'PartList',
    props: ['item_id', 'item_type'],
    components: {
        LotsOfItemsAlert,
        AddSceneChange, AddSceneCharacter, AddNewChange, EditPartShootDate, ObjectsSidebar, PoLines, SearchBox, Layout},
    mixins: [TimelineCommons, ObjectsSidebarShared],
    data() {
        return {
            listLoading: false,
            unique_id: 'parts_costume',
            total: 0,
            partsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Scenes",
            items: [
                {
                    text: "Scenes",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_part_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 8,
                    text: 'Scene Order',
                    first_criteria: 'target_scene',
                    first_reversed: false,
                    name: 'part_order',
                    second_criteria: 'scene_order',
                    second_reversed: false,
                    third_criteria: 'part_order',
                    third_reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'parts',

            forced_object_type: 'parts_costume',
            object_type: 'parts',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Scenes',
                    backend_key: 'scene_id'
                },
                {
                    id: 2,
                    label: 'Locations',
                    backend_key: 'scene_location_id'
                },
                {
                    id: 3,
                    label: 'Characters',
                    backend_key: 'character_id'
                },
                {
                    id: 4,
                    label: 'Shoot Day',
                    backend_key: 'shoot_day_id'
                },
                {
                    id: 5,
                    label: 'Shoot Schedule',
                    backend_key: 'shoot_schedule_id'
                },
            ],
            items_list: [
                [],
                // Scenes
                [],
                // Locations
                [],
                // Characters
                [],
                // Shoot Date
                [],
                ReturnDatesList
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'scenes'
                },
                {
                    index: 2, // index in items_list
                    object_name: 'scene_locations'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'characters'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'shoot_days'
                },
            ],
            part_keys_list: PartKeysList
        };
    },
    created() {

        this.sorting.sortOptions.splice(0,3);

        this.sorting.sortOptions.push(this.sorting.sortType);

        // let shoot_day_sort_new_to_old = {
        //     value: 9,
        //     text: 'Shoot Day: New to Old',
        //     first_criteria: 'shoot_day_date',
        //     first_reversed: true,
        //     name: 'shoot_day_order',
        //     second_criteria: 'target_scene',
        //     second_reversed: false,
        //     third_criteria: 'scene_order',
        //     third_reversed: false
        // };
        //
        // let shoot_day_sort_old_to_new = {
        //     value: 10,
        //     text: 'Shoot Day: Old to New',
        //     first_criteria: 'shoot_day_date',
        //     first_reversed: false,
        //     name: 'shoot_day_order',
        //     second_criteria: 'target_scene',
        //     second_reversed: false,
        //     third_criteria: 'scene_order',
        //     third_reversed: false
        // };
        //
        // let location_sort_a_z = {
        //     value: 11,
        //     text: 'Location: A~Z',
        //     // TODO Shall use name: 'scene_location_name',
        //     name: 'name',
        //     reversed: false
        // }
        // let location_sort_z_a = {
        //     value: 12,
        //     text: 'Location: Z~A',
        //     // TODO Shall use name: 'scene_location_name',
        //     name: 'name',
        //     reversed: true
        // }
        //
        //
        // this.sorting.sortOptions.push(shoot_day_sort_new_to_old);
        // this.sorting.sortOptions.push(shoot_day_sort_old_to_new);
        // this.sorting.sortOptions.push(location_sort_a_z);
        // this.sorting.sortOptions.push(location_sort_z_a);



        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: true
            },
            smaller_list: {
                name: 'Smaller List',
                value: 1,
                class: 'smaller-list',
                isActive: false
            }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);



        this.has_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];

        if (!this.has_actions_permissions){
            delete this.part_keys_list.actions;
        }


        // if (this.item_type === 'sp'){
        //     if (this.is_tv_film_asset){
        //         this.part_keys_list.customer_info.label = 'Production';
        //     }
        //     delete this.part_keys_list.sp_info;
        // }else if (this.item_type === 'customer') {
        //     delete this.part_keys_list.customer_info;
        // }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_parts_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.part_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.part_preferred_sorting;
        }


        this.getList(this.selected_item);
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        addNewItemToOptions(new_item, item_type, part_index, character_index){
            if (new_item && this.partsData.length > part_index) {
                if (item_type === ('scene-character' + this.unique_id)){
                    if (!this.partsData[part_index].hasOwnProperty('cast_info')){
                        this.partsData[part_index].cast_info = []
                    }
                    this.partsData[part_index].cast_info.push(new_item);
                } else if (this.partsData[part_index].cast_info && this.partsData[part_index].cast_info.length > character_index){
                    if (!this.partsData[part_index].cast_info[character_index].hasOwnProperty('changes_info')){
                        this.partsData[part_index].cast_info[character_index].changes_info = [];
                    }
                    this.partsData[part_index].cast_info[character_index].changes_info.push(new_item);
                }
            }
        },
        performAction(part, action, partKey, second_key){
            if (action === ('delete'+this.permission_name)){
                if (part.id !== ''){
                    this.deleteItemByID(part.id, (part.name || part.uid), this.object_type, part)
                        .then((response) => {
                            if (response.data.data.status){
                                this.partsData.splice(partKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }else if (action === 'shoot_date'){
                // shall open modal to edit shoot date

                this.$refs['edit-part-shoot-date'].setSelectedItem(part);
                this.$bvModal.show('create-part-shoot-date');
            }else if (action === 'add_character'){
                let change_modal_info = {};
                change_modal_info.part_id = part.id;
                let character_info = part.cast_info[second_key];
                if (character_info){
                    change_modal_info.character_info = character_info;
                }
                change_modal_info.part_index = partKey;

                this.$refs['add-character-to-scene'].setSelectedItem(change_modal_info);
                this.$bvModal.show('create-scene-character' + this.unique_id);

            }else if (action === 'create_change'){
                let character_info = part.cast_info[second_key];
                this.presentAddNewChange(null, character_info, part, partKey, second_key);
            }else if (action === 'assign_change'){
                // shall open modal to edit shoot date

                let change_modal_info = {};
                change_modal_info.part_id = part.id;
                let character_info = part.cast_info[second_key];
                if (character_info){
                    change_modal_info.character_info = character_info;
                }


                change_modal_info.part_index = partKey;
                change_modal_info.character_index = second_key;


                this.$refs['add-change-to-scene'].setSelectedItem(change_modal_info);
                this.$bvModal.show('create-scene-change' + this.unique_id);

            }
        },
        presentAddNewChange: function (change_info, character_info, part_info, first_key, second_key) {

            let change_info_for_modal = {};

            change_info_for_modal.part_index = first_key;
            change_info_for_modal.character_index = second_key;

            if (change_info){
                change_info_for_modal = Object.assign({}, change_info);
            }
            if (character_info){
                change_info_for_modal.character_id = character_info.id;
            }

            if (part_info){
                change_info_for_modal.part_ids = [part_info.id];
            }

            change_info_for_modal.change_status = 0;

            if (change_info_for_modal === {}){
                change_info_for_modal = null;
            }else{
                change_info_for_modal.id = -99;
            }

            this.$refs['add-new-change'].setSelectedItem(change_info_for_modal);
            this.$bvModal.show('create-changes' + this.unique_id);
        },
        searchFor(keyword, part) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'name'];
                    return this.handleSearch(searchable_values, element, wordToCompare);
                }
                filtered_list = this.partsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.partsData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('part_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_parts_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
                is_parts_costume_page: true
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.forced_object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await partResource.list(required_query);
            if (data.hasOwnProperty('meta')){
                this.request_meta = data.meta;
                this.total = data.meta.total;
            }else{
                this.total = 0;
                this.request_meta = {total: 0}
            }

            this.partsData = data.data.items;
            this.listLoading = false;
        },

    },
}
</script>


