<template>
    <div class="upload_ditels_box">
        <div class="textarea_and_upload row">
            <div class="text_block col-md-8 col-12">
                <textarea id="post_text" v-model="addForm.post_text" placeholder="Post an update here..." class="w-100"></textarea>
            </div>
            <div class="text_block col-md-4 col-12 pl-0">
                <div class="st-drop-file-block upload-image-and-file w-100">
                    <div class="st-drop-file-box">
                        <vue-dropzone id="imagesgallery"
                                      ref="myVueDropzone"
                                      :use-custom-slot="true"
                                      :include-styling="false"
                                      :options="dropzoneOptions"
                                      @vdropzone-thumbnail="thumbnail"
                                      @vdropzone-file-added="fileAdded"
                                      @vdropzone-removed-file="fileRemoved"
                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                        >
                            <div v-if="asset_images.length === 0" class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <div class="st-upload-title">
                                    <h3>Drop files here or click to upload</h3>
                                    <p>... or click to select a file from your computer</p>
                                </div>
                            </div>
                        </vue-dropzone>
                        <b-button
                            @click="$refs.myVueDropzone.$el.click();"
                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                        >Add New Image</b-button>
                        <b-button
                            @click="resetDropZone"
                            variant="outline-danger"
                            class="btn btn-sm mt-1 btn-block"
                        >Remove All</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="false">
            <label><input type="checkbox" v-model="addForm.add_to_ap" /> Add to Asset Passport?</label>
        </div>
        <div class="st-file-upload-btn text-upload_file_btn row d-flex align-items-center justify-content-end">
            <b-button
                @click="cancelAddingPost"
                variant="outline-danger"
                class="btn btn-sm mt-1 btn-block st-file-btn"
            >Cancel</b-button>



            <AFButton
                tag_name="button"
                :key_prefix="'save-post' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="save_item_post"
                required_text="Submit"
                item_classes="btn btn-sm af-btn-accent af-accent mt-2 btn-block st-file-btn"
            />

        </div>
    </div>
</template>

<script>

import dropzoneMixin from "@/mixins/dropzoneMixin.mixin";
import AFButton from "@/components/AFButton";

export default {
    name: "AddItemPost",
    components: {AFButton},
    mixins:[dropzoneMixin],
    props: ['post_type', 'item_id', 'with_reload'],
    data() {
        return {
            addForm: {
                post_text: '',
                selected_files: [],
                add_to_ap: null
            }
        }
    },
    methods: {
        cancelAddingPost(){
            this.$emit('on-cancel-post-adding');
        },
        previewFiles(event) {
            this.addForm.selected_files = event.target.files;
        },
        save_item_post(){
            var allfiles = this.checkAndGetFiles(this.asset_images);

            if (!allfiles){
                return;
            }
            this.is_saving_enabled = false;
            var data = {
                item_id: this.item_id,
                post_type: this.post_type,
                post_text: this.addForm.post_text,
                add_to_ap: this.addForm.add_to_ap,
                asset_images: allfiles
            };

            axios({
                method:'post',
                url:'/api/assetflow/items/addPost',
                baseURL: '/',
                data: data,
            }).then(response => {

                this.is_saving_enabled = true;
                if (this.with_reload){
                    location.reload();
                }else{
                    let data = response.data.data;
                    let status = data.status;
                    let item = data.items;

                    this.addForm = {
                        post_text: '',
                        selected_files: [],
                        add_to_ap: null
                    }
                    this.buttonkey++;
                    this.resetDropZone();
                    this.asset_images_previewElements = Object.assign({}, this.asset_images_previewElements);

                    let msg = status ? 'Added Post Successfully' : 'Something Went Wrong';
                    this.$emit('on-post-adding', item, msg, (status ? 'success' : 'danger'));
                }


            }).catch(error => {
                this.is_saving_enabled = true;
                console.log(error);
            });
        }
    }
}
</script>
