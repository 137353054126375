<template>
    <Layout>

        <AddNewSpStorage
            ref="add-new-sp_storages"
            item_type='sp_storages'
            modal_title="Create New SP Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-new-item-adding="getList(selected_item)"
        >
        </AddNewSpStorage>

        <ChangeStorageUtilisation
            ref="change-sp_storages-utilisation"
            modal_id="change-sp_storages-utilisation"
            item_type='sp_storages'
            modal_title="Change Storage Utilisation"
            @on-modal-cancel="selected_object_id = null"
            @on-utilisation-change="refreshItem"
        >
        </ChangeStorageUtilisation>

        <ParkStorageItem
            ref="assign-sp_storages-location"
            modal_id="assign-sp_storages-location"
            item_type='sp_storages'
            modal_title="Park Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-park-storage="refreshItem"
        >
        </ParkStorageItem>

        <AssignStorageCustomer
            ref="assign-sp_storages-customer"
            modal_id="assign-sp_storages-customer"
            item_type='sp_storages'
            modal_title="Assign Customer"
            @on-modal-cancel="selected_object_id = null"
            @on-assign-to-customer="refreshItem"
        >
        </AssignStorageCustomer>

        <RemoveStorageLocation
            ref="remove-sp_storages-location"
            modal_id="remove-sp_storages-location"
            item_type='sp_storages'
            modal_title="Remove Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-remove-storage="refreshItem"
        >
        </RemoveStorageLocation>

        <RelocateAllSpStorageItems
            ref="relocate-sp_storages-items"
            modal_id="relocate-sp_storages-items"
            modal_title="Relocate All Items"
            :item_type="item_type"
            @on-modal-cancel="selected_object_id = null"
            @on-storage-update="refreshItem"
        />

        <ReserveStorageLocation
            ref="reserve-sp_storages"
            modal_id="reserve-sp_storages"
            item_type='sp_storages'
            modal_title="Reserve Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-remove-storage="refreshItem"
        >
        </ReserveStorageLocation>

        <ArchiveStorageLocation
            ref="archive-sp_storages"
            modal_id="archive-sp_storages"
            item_type='sp_storages'
            modal_title="Archive Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-remove-storage="refreshItem"
        >
        </ArchiveStorageLocation>

        <RemoveStorageCustomer
            ref="remove-sp_storages-customer"
            modal_id="remove-sp_storages-customer"
            item_type='sp_storages'
            :customers_list="customers_list"
            modal_title="Remove Storage Customer"
            @on-modal-cancel="selected_object_id = null"
            @on-remove-storage="refreshItem"
        >
        </RemoveStorageCustomer>



        <div class="sp_storages-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :received_filter_classes="filter_classes"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <PageHeaderCustom title="" :items="items" class="list-header-block" />
                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            object_type="sp_storages"
                            current_title="Storage List"
                            block_class="customers-warehouses-block both-customers-warehouses"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                            />
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Storage.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem">Storage
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>

                        <div class="page-value-info"></div>
                    </div>

                    <div class="current-page-filters">
                        <span v-if="is_sp_or_cross_or_admin && selected_customer && selected_customer.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Customer: <b>{{ selected_customer.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_customer')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_sp_warehouse && selected_sp_warehouse.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Warehouse: <b>{{ selected_sp_warehouse.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_warehouse')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_sp_space && selected_sp_space.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Space: <b>{{ selected_sp_space.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_space')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_status_storage && selected_status_storage.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Status: <b>{{ selected_status_storage.name || selected_status_storage.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_status_storage')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="![1,4].includes(current_groupBy) && selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.label) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_dashboard_item && selected_dashboard_item.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ selected_dashboard_item.label }}: <b>{{ (selected_dashboard_item.name) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('dashboard_item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>

                    <div class="tab_menu_item">
                        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li v-for="tab in listTabs" class="nav-item">
                                <a class="nav-link"
                                   @click="tabClicked(tab.id)"
                                   :class="isActiveTab(tab.id)"
                                   data-toggle="tab" :href="'#'+tab.id" role="tab">
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                                    <span class="d-none d-sm-inline-block">
                                {{ tab.name | titleize}}
                            </span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div class="tab-content tab_content_design">
                        <div class="sidebad-toggle af-accent"></div>
                        <div class="tab-pane" id="list_dashboard" role="tabpanel" :class="isActiveTab('list_dashboard')">
                            <ListItemsDashboardTab :dashboard_info="dashboard_info" @on-dashboard-clicked="dashboardItemClicked" item_name="Storage"/>
                        </div>

                        <div class="tab-pane" id="list_items" role="tabpanel" :class="isActiveTab('list_items')">

                            <div class="product-filter-option-wrapper clearfix d-flex">

                                <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                        request_meta.from + '-' + request_meta.to
                                    }}</span> of <span>{{ request_meta.total }}</span></div>

                                <div class="filter-wrap d-flex align-items-center ml-auto">

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Sort: <b>{{ sorting.sortType.text }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in sorting.sortOptions"
                                            :key="option.value"
                                            @click="saveSorting(option, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Show: <b>{{
                                                perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                            }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in perpage.pageOptions"
                                            :key="option.value"
                                            @click="savePerPage(option.value, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                        <div
                                            v-if="supportedLayouts && Object.keys(supportedLayouts).length > 0"
                                            v-for="(layout, key) in supportedLayouts"
                                            :key="key"
                                            @click="changeObjectLayout(layout);"
                                            :id="'grid-list-'+layout.name.toLowerCase()"
                                            :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- Product Section Start-->
                            <LotsOfItemsAlert :perpage="perpage"/>

                            <div v-if="listLoading" class="products text-center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                                <h4>Fetching Storage...</h4>
                            </div>
                            <div v-else-if="!sp_storagesData || total === 0 || sortedItemsList.length === 0"
                                 class="products text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>No storages found here at the moment.</h4>

                            </div>
                            <div v-else-if="selectedLayout === 0 && sp_storagesData" class="row">
                                <div class="col-lg-12">
                                    <po-lines
                                        :items="sortedItemsList"
                                        :current_page_type="item_type"
                                        :object_type="permission_name"
                                        :get-poline-classes-dict="getPoLineClassForObject"
                                        :keys_list="sp_storage_keys_list">

                                        <template v-slot:sp_storages_actions="{ item, index }">
                                            <div class="btn-group bl-auto">
                                                <button type="button" class="btn btn-none dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                                </button>
                                                <SpStorageActions
                                                    :go-to-relocate-wizard="goToRelocateWizard"
                                                    :index="index" :item="item"
                                                    :perform-action="performAction"
                                                    :permission_name="permission_name"
                                                    :permissions_list="permissions_list"
                                                    :show-modal="showModal"/>
                                            </div>
                                        </template>
                                    </po-lines>
                                </div>
                            </div>


                            <!-- Product Section End-->
                            <div class="pagination">
                                <b-pagination
                                    v-if="request_meta && perpage.perPage !== 0"
                                    v-model="perpage.currentPage"
                                    :total-rows="request_meta.total"
                                    @change="goToPage($event)"
                                    class="pagination d-flex align-items-center justify-content-center"
                                    :per-page="perpage.perPage"
                                ></b-pagination>
                            </div>

                        </div>


                        <div class="tab-pane" id="list_add" role="tabpanel" :class="isActiveTab('list_add')">
                            <SpItemAddTab v-if="hasPermission('add_'+permission_name)" @on-item-add="addNewItem" menu_title="Add Storage" object_name="Storage"/>
                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>

                        <div class="tab-pane" id="list_export" role="tabpanel" :class="isActiveTab('list_export')">
                            <SpItemExportTab
                                v-if="hasPermission('add_'+permission_name)"
                                :required_actions="export_actions"
                                @on-export-pdf="exportOrder"
                                :menu_title="'Export Storage'"/>

                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import SpStorageShared from "@/mixins/SpStorageShared.mixin";

import {SPStorageKeysList} from "@/views/assetflow/form_data/SPStorageKeysList";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import AddNewSpStorage from "./AddNewSpStorage";
import SearchBox from "@/components/search-box";
import PageHeaderCustom from "../../../../components/page-header-custom";
import ListItemsDashboardTab from "../sp_items_shared/ListItemsDashboardTab";
import SpItemExportTab from "../sp_items_shared/SpItemExportTab";
import SpItemAddTab from "../sp_items_shared/SpItemAddTab";
import TopFiltersBlock from "./TopFiltersBlock";
import ChangeStorageUtilisation from "./ChangeStorageUtilisation";
import ParkStorageItem from "./ParkStorageItem";
import RemoveStorageLocation from "./RemoveStorageLocation";
import RemoveStorageCustomer from "./RemoveStorageCustomer";
import AssignStorageCustomer from "./AssignStorageCustomer";
import ReserveStorageLocation from "./ReserveStorageLocation";
import RelocateAllSpStorageItems from "./RelocateAllSpStorageItems";
import SpStorageActions from "./SpStorageActions";
import ArchiveStorageLocation from "./ArchiveStorageLocation";

const sp_storageResource = new Resource('api/assetflow/sp_storages');


export default {
    name: 'StorageList',
    props: ['item_id', 'item_type'],
    components: {
        ArchiveStorageLocation,
        SpStorageActions,
        RelocateAllSpStorageItems,
        ReserveStorageLocation,
        AssignStorageCustomer,
        RemoveStorageCustomer,
        RemoveStorageLocation,
        ParkStorageItem,
        ChangeStorageUtilisation,
        TopFiltersBlock,
        SpItemAddTab,
        SpItemExportTab,
        ListItemsDashboardTab,
        PageHeaderCustom, AddNewSpStorage, LotsOfItemsAlert, ObjectsSidebar, PoLines, SearchBox, Layout},
    mixins: [TimelineCommons, TopFiltersMixin, SpStorageShared, ObjectsSidebarShared],
    data() {
        return {

            total: 0,
            sp_storagesData: [],
            request_meta: {},
            listLoading: false,
            breadcrumb_items: [],
            title: "",
            items: [
                {
                    text: "Asset Flow",
                    href: "/"
                },
                {
                    text: "Storage",
                    href: "#"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_sp_storage_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'sp_storages',
            object_type: 'sp_storages',
            filter_classes: {
                '0': 'storage-all',
                '1': 'storage-status-row',
                '2': 'storage-location-status',
                '3': 'storage-storage-wrapper',
                '4': 'storage-customer'
            },
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
                {
                    id: 2,
                    label: 'Location Status',
                    backend_key: 'location_status'
                },
                {
                    id: 3,
                    label: 'Storage Wrappers',
                    backend_key: 'storage_wrapper_id'
                },
                {
                    id: 4,
                    label: 'Customers',
                    backend_key: 'current_customer_id'
                },
                {
                    id: 5,
                    label: 'Usage',
                    backend_key: 'storage_usage'
                }
            ],
            items_list: [
                // All
                [],
                // Status
                [],
                // Location Status
                [],
                // Storage Wrappers
                [],
                // Customers
                [],
                // Storage Usage
                [],
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'item_status'
                },
                {
                    index: 2, // index in items_list
                    object_name: 'location_status'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'sp_storage_wrappers'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'customers'
                },
                {
                    index: 5, // index in items_list
                    object_name: 'storage_usage'
                }
            ],
            sp_storage_keys_list: SPStorageKeysList,

            active_tab: 'list_dashboard',
            totals_only:0,
            dashboard_info: null,
            listTabs:{
                list_dashboard:{
                    'id': "list_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                list_items:{
                    'id': "list_items",
                    'name': 'Storage',
                    'icon': "user",
                    'isActive': false
                },
                list_add:{
                    'id': "list_add",
                    'name': 'Add',
                    'icon': "user",
                    'isActive': false
                },
                list_export:{
                    'id': "list_export",
                    'name': 'Export',
                    'icon': "user",
                    'isActive': false
                }
            },
        };
    },
    mounted(){
        /*Function Calls*/
        this.setupJQueryMethods();
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        this.required_items = ['customers', 'sp_warehouses', 'status_storage', 'sp_spaces'];
        this.sorting.sortOptions[0].name = 'uid';
        this.sorting.sortOptions[1].name = 'uid';
        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
            // grid: {
            //     name: 'Grid',
            //         value: 1,
            //         class: 'grid',
            //         isActive: true
            // }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_sp_storages_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.sp_storage_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.sp_storage_preferred_sorting;
        }


        let sp_items_list_active_tab = localStorage.getItem('sp_items_list_active_tab');
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'list_dashboard';
                    break;
                case 'items':
                    this.active_tab = 'list_items';
                    break;
                case 'add':
                    this.active_tab = 'list_add';
                    break;
                case 'export':
                    this.active_tab = 'list_export';
                    break;
            }
        }else if (sp_items_list_active_tab && sp_items_list_active_tab !== ''){
            this.active_tab = sp_items_list_active_tab;
        }
        if (this.permissions_list[('edit_' + this.permission_name)]){
            this.sp_storage_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 war_sp_storages_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_storages_actions'
            };
        }

        if (!this.permissions_list[('add_' + this.permission_name)]){
            delete this.listTabs.list_add;
            delete this.listTabs.list_export;
            this.active_tab = ['list_add', 'list_export'].includes(this.active_tab) ? 'list_dashboard' : this.active_tab;
            localStorage.setItem('sp_items_list_active_tab', this.active_tab);
        }

        this.getActiveTabInfo(this.active_tab);

    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        refreshItem(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_asset){ return; }

            if (['assign-sp_storages-location','remove-sp_storages-location'].includes(modal_id)){
                this.getObjectsList('location_status', 2, this.current_groupBy === 2);
                if (this.selected_item && this.selected_item.id !== null){
                    this.getList(this.selected_item);
                    return;
                }

            }else if (modal_id === 'change-sp_storages-utilisation'){
                this.getObjectsList('storage_usage', 5, this.current_groupBy === 5);
                if (this.selected_item && this.selected_item.id !== null){
                    this.getList(this.selected_item);
                    return;
                }
            }else{
                if (['assign-sp_storages-customer', 'remove-sp_storages-customer'].includes(modal_id)){
                    this.getObjectsList('customers', 4, this.current_groupBy === 4);
                }
                // shall refresh counts?
                this.getObjectsList('item_status', 1, this.current_groupBy === 1);
                if (this.selected_item && this.selected_item.id !== null){
                    this.getList(this.selected_item);
                    return;
                }
            }

            let index = _.findIndex(this.sp_storagesData, {id: edited_asset.id});

            if (index > -1) {
                if (required_keys){
                    for (const requiredKeysKey in required_keys) {
                        let required_key = required_keys[requiredKeysKey];
                        this.sp_storagesData[index][required_key] = edited_asset[required_key];
                    }
                }else{
                    if(edited_asset.hasOwnProperty('current_location_id') && edited_asset.current_location_id){
                        // shall refresh all other storages that have same current location id
                        this.sp_storagesData = this.sp_storagesData.map(function (sp_storage) {
                            if (
                                sp_storage.hasOwnProperty('current_location_id') &&
                                sp_storage.current_location_id &&
                                edited_asset.hasOwnProperty('current_location_id') &&
                                sp_storage.current_location_id === edited_asset.current_location_id
                            ){
                                sp_storage.current_location_id = null;
                                sp_storage.location_status = 0;
                            }
                            return sp_storage;
                        })
                    }
                    this.$set(this.sp_storagesData, index, edited_asset);
                }
            }else{
                console.log("index is -1?");
            }
        },
        setupJQueryMethods(){
            let strongThis = this;
            let action_names = [
                'change-sp_storages-utilisation',
                'assign-sp_storages-location',
                'remove-sp_storages-location',
                'assign-sp_storages-customer',
                'remove-sp_storages-customer',
            ];
            for (const actionNamesKey in action_names) {
                let action_name = action_names[actionNamesKey];
                $(document).on('click', '.'+action_name, function(event) {
                    strongThis.getItemAndShowModal(action_name, event);
                });
            }
        },
        getItemAndShowModal(modal_ref, event){
            event.preventDefault();
            let currentTarget = event.currentTarget;
            let item_id = currentTarget.getAttribute('data-space_id');
            if (item_id){
                let index = _.findIndex(this.sp_storagesData, {id: parseInt(item_id)});
                if (index > -1) {
                    let selected_item = this.sp_storagesData[index];
                    this.showModal(selected_item, modal_ref);
                }
            }
        },
        exportStorage(){
            console.log("shall export??");
        },
        tabClicked(tab_id){
            localStorage.setItem('sp_items_list_active_tab', tab_id);
            this.getActiveTabInfo(tab_id);
        },
        getActiveTabInfo(tab_id){
            switch (tab_id) {
                case 'list_dashboard':
                case 'list_items':
                    // refresh list_dashboard and list_items?
                    this.getList(this.selected_item);
                    break;
                case 'list_add':
                    // shall get info of list_add?
                    break;
                case 'list_export':
                    // shall get info of list_export?
                    break;
            }
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        goToPage(page){
            this.perpage.currentPage = page;
            this.getList(this.selected_item ? this.selected_item : null)
        },
        addNewItem() {
            let default_values_object =  {};
            if (this.selected_sp_warehouse && this.selected_sp_warehouse.id){
                default_values_object.current_warehouse_id = this.selected_sp_warehouse.id;
            }
            if (this.selected_sp_space && this.selected_sp_space.id){
                default_values_object.current_space_id = this.selected_sp_space.id;
            }
            this.$refs['add-new-sp_storages'].setSelectedItem(default_values_object);
            this.$bvModal.show('create-sp_storages');
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.sp_storagesData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('sp_storage_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_sp_storages_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            this.request_meta = null;

            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };


            let active_tab = localStorage.getItem('sp_items_list_active_tab');
            if (!active_tab) {
                active_tab = 'list_dashboard';
            }

            this.totals_only = active_tab === 'list_dashboard' ? 1 : 0;

            if (!this.is_export){
                this.listLoading = true;
            }


            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
                this.selected_item = null
            }

            if (selected_item){
                if (this.current_groupBy === 4){
                    let edited_required_customer = selected_item;
                    if (edited_required_customer && !edited_required_customer.id){
                        edited_required_customer.name = 'All Customers';
                    }
                    this.selected_customer = edited_required_customer;
                    if (this.$refs['TopFiltersBlock']){
                        this.$refs['TopFiltersBlock'].selected_customer = edited_required_customer;
                    }
                } else if (this.current_groupBy === 1){
                    let selected_status_storage = selected_item;
                    if (selected_status_storage && selected_status_storage.id === null){
                        selected_status_storage.name = 'All Statuses';
                    }
                    this.selected_status_storage = selected_status_storage;
                    if (this.$refs['TopFiltersBlock']){
                        this.$refs['TopFiltersBlock'].selected_status_storage = selected_status_storage;
                    }
                }
            }

            required_query = this.SBHandleQueryItems(required_query, selected_item);


            required_query = this.addPaginationParams(required_query);
            required_query.s_keyword = this.search_keyword;
            required_query.totals_only = this.totals_only;


            required_query = this.appendTopFiltersValues(required_query);
            const {data} = await sp_storageResource.list(required_query);


            if (Object.keys(data.data).includes('status')) {
                if (this.totals_only) {
                    // shall refresh totals
                    this.dashboard_info = data.data.items;
                    this.listLoading = false;
                    return;
                }
                if (this.is_export){
                    let new_item = data.data.status;
                    this.presentToast((new_item ? "Success!" : "Error!"),
                        data.data.msg,
                        (new_item ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    this.listLoading = false;
                    this.setActionsStatus(true, new_item);
                    return;
                }
            }

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.sp_storagesData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
            this.is_export = 0;

        },

    },
}
</script>

