<script>
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import EditImageNotes from "../views/assetflow/customers/extras/EditImageNotes";
import ImageEditorModal from "../views/assetflow/customers/extras/ImageEditorModal";

export default {
    props:['parent_class', 'main_content_id'],
  components: {ImageEditorModal, EditImageNotes, Vertical, Horizontal },
  data() {
    return {};
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    }
  },
  methods: {
      afterEditingImage(image_info, index){
          location.reload();
      }
  }
};
</script>

<template>
  <div ref="mainnnn">

      <EditImageNotes
          ref="shared-edit-image-notes"
          item_type="shared-image-notes"
          modal_title="Edit Image Notes"
          @on-new-item-adding="afterEditingImage"
      />

      <ImageEditorModal
          ref="shared-image-editor"
          item_type="shared-image-editor"
          modal_title="Edit Image"
          @on-new-item-adding="afterEditingImage"
      />

    <vertical v-if="layoutType === 'vertical'" :parent_class="parent_class" :main_content_id="main_content_id" ref="verticalLayout">
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" ref="horizontalLayout">
      <slot />
    </Horizontal>
  </div>
</template>
