<template>
    <div class="step-form-content-block row">
        <div class="col-xl-6 st-col-6">

            <asset-info-section
                title="Asset Overview"
                :is_afid_field="true"
                :selected_value="getValueFor('category_id')">
                <div>
                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Asset Passport Name</h5>
                        </div>

                        <div class="common--input-filed">
                            <input
                                v-model="addForm.item_details.universal_asset_name"
                                aria-describedby="_universal_asset_name"
                                id="_universal_asset_name"
                                name="_universal_asset_name"
                                type="text"
                                placeholder="Asset Passport Name"
                                class="form-control"
                            />
                        </div>

                    </div>

                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Category</h5>
                        </div>
                        <multiselect
                            v-model="addForm.single_select_v_models.category_id"
                            track-by="id"
                            :custom-label="CustomMSLabel"
                            class="category-options"
                            :options="getOptionsFor('category_id')"
                            :allow-empty="false"
                            :placeholder="'Select Category'"
                        >
                            <span slot="noResult">No data found.</span>
                        </multiselect>
                    </div>

                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Condition</h5>
                        </div>
                        <multiselect
                            v-model="addForm.single_select_v_models.condition"
                            :options="condition_options"
                            track-by="id"
                            :custom-label="CustomMSLabel"
                            class="condition-options"
                            :allow-empty="false"
                            :show-labels="false"
                            placeholder="Select Condition"
                        ></multiselect>
                    </div>

                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Colour</h5>
                        </div>
                        <div class="st-color-block">
                            <ul>
                                <li
                                    v-for="(colour, key) in required_fields.colours.options"
                                    :key="key"
                                >
                                    <div class="st-color-box">
                                        <label>
                                            <input
                                                v-model="addForm.multi_select_v_models.colours"
                                                :id="'colours_' + colour.id"
                                                :value="colour"
                                                type="checkbox"
                                                name="">
                                            <div class="cm-color-round"
                                                 :style="'background-color:' + colour.hex"
                                                 v-b-tooltip.hover.bottom
                                                 :title="colour.name | titleize">
                                                <img :src="getAssetBaseUrl + '/images/new_images/color_check-ic.png'" alt="Color Check" />
                                            </div>
                                        </label>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Note</h5>
                        </div>
                        <div class="common--input-filed">
                            <textarea
                                v-model="addForm.item_details.asset_note"
                                placeholder="Asset Note"></textarea>
                        </div>
                    </div>

                </div>
            </asset-info-section>

            <asset-info-section
                v-if="addForm.single_select_v_models.asset_type"
                title="Asset Details"
                :is_afid_field="true"
                :selected_value="getValueFor('asset_type')">
                <div>
                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Asset Type</h5>
                        </div>
                        <h6>{{ addForm.single_select_v_models.asset_type.name }}</h6>
                    </div>
                    <div v-if="selected_asset_type_keys && selected_asset_type_keys.length !== 0"
                         v-for="(assetType_meta, assetType_metaKey) in selected_asset_type_keys"
                         class="form-group st--common--box asset-types-fields">

                        <div class="common-st-title-label">
                            <h5>{{ assetType_meta.name | titleize }}</h5>
                        </div>

                        <input
                            v-if="['text', 'number'].includes(assetType_meta.type)"
                            v-model="addForm.asset_meta[assetType_meta.name]"
                            :id="assetType_metaKey"
                            :name="assetType_metaKey"
                            :type="assetType_meta.type"
                            class="form-control"
                            :class="'asset-types-field-' + assetType_metaKey"/>


                        <div v-else-if="assetType_meta.type === 'long_text'" class="common--input-filed">
                            <textarea
                                v-model="addForm.asset_meta[assetType_meta.name]"
                                :id="assetType_metaKey"
                                :name="assetType_metaKey"
                                :class="'asset-types-field-' + assetType_metaKey"
                                spellcheck="false"></textarea>
                        </div>


                        <div v-else-if="assetType_metaKey === 'colour' && assetType_meta.options && assetType_meta.options.length !== 0" class="st-color-block">
                            <ul>
                                <li
                                    v-for="(colour, key) in assetType_meta.options"
                                    :key="key"
                                >
                                    <div class="st-color-box" :class="'asset-types-field-' + assetType_metaKey">
                                        <label>
                                            <input
                                                v-model="addForm.asset_meta[assetType_meta.name]"
                                                :id="'colours_' + colour.id"
                                                :value="colour"
                                                type="checkbox"
                                                name="'colours_[]'">
                                            <div class="cm-color-round"
                                                 :style="'background-color:' + colour.hex"
                                                 v-b-tooltip.hover.bottom
                                                 :title="colour.name | titleize">
                                                <img :src="getAssetBaseUrl + '/images/new_images/color_check-ic.png'" alt="Color Check" />
                                            </div>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <multiselect
                            v-else-if="assetType_meta.type === 'single-select' && assetType_meta.options && assetType_meta.options.length !== 0"
                            v-model="addForm.asset_meta[assetType_meta.name]"
                            track-by="id"
                            label="name"
                            class="mySingleSelect"
                            :class="'asset-types-field-' + assetType_metaKey"
                            :options="assetType_meta.options"
                            :allow-empty="false"
                            deselect-label=""
                        >
                            <span slot="noResult">No data found.</span>
                        </multiselect>

                        <h5 v-else>
                            {{ assetType_meta }}
                            {{ assetType_meta.type }}
                            {{ assetType_meta.options }}
                        </h5>
                    </div>
                </div>

            </asset-info-section>

            <asset-info-section
                title="Dimensions"
                :is_afid_field="true">
                <div class="st--common--box">
                    <div class="dimensions-block">
                        <div class="common-st-title-label">
                            <h5>Preferred Input</h5>
                        </div>
                        <div class="dimensions-btn">
                            <a
                                v-for="(dimensions_type, key) in dimensions_types"
                                :key="key"
                                @click="changeDimensionsType(dimensions_type.name)"
                                :class="[{'active': selected_dimensions_type === dimensions_type.name}, dimensions_type.class]"
                               class="dimensions-auto">
                                <span></span>{{ dimensions_type.name | titleize}}
                            </a>
                        </div>
                        <div class="dimensions-content-block">
                            <div
                                v-if="selected_dimensions_type === 'auto'"
                                id="dimensions-auto"
                                class="dimensions-auto-content-block active">
                                <div class="challenge-note">
                                    <p><span>Challenge:</span>  Click the image that is closest in physical size to your item</p>
                                </div>
                                <div class="dimensions-auto-item-list">
                                    <div class="st-item-radio-item">
                                        <ul>
                                            <li
                                            v-for="(auto_dimension_item, key) in auto_dimensions_list"
                                            :key="'d_'+auto_dimension_item.id"
                                            >
                                                <div class="st-auto-radio">
                                                    <div class="cm-radio-btn">
                                                        <label>
                                                            <input
                                                                v-model="selected_auto_dimensions"
                                                                :id="key + '_' + auto_dimension_item.id"
                                                                :value="auto_dimension_item"
                                                                type="radio"
                                                                name="auto_dimensions">
                                                            <div class="img-item">
                                                                <img :src="auto_dimension_item.image"
                                                                     :alt="auto_dimension_item.name">
                                                            </div>
                                                            <span class="cm-ro-box">{{ auto_dimension_item.name }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="st--common--box mb-2">
                                    <div class="common-st-title-label">
                                        <h5>Area</h5>
                                    </div>
                                    <div class="common--input-filed unit-input">
                                        <input
                                            v-model="addForm.item_details.area"
                                            aria-describedby="_area_auto_desc"
                                            id="_area_auto"
                                            name="_area_auto"
                                            readonly
                                            type="text"
                                            placeholder="Click an Image"
                                            class="form-control area-auto-input"
                                        />

                                        <span class="u-mm">
                                            m<sup>2</sup>
                                        </span>
                                    </div>
                                </div>

                                <div class="st--common--box mb-2">
                                    <div class="common-st-title-label">
                                        <h5>Volume</h5>
                                    </div>
                                    <div class="common--input-filed unit-input">
                                        <input
                                            v-model="addForm.item_details.volume"
                                            aria-describedby="_volume_auto_desc"
                                            id="_volume_auto"
                                            name="_volume_auto"
                                            readonly
                                            type="text"
                                            placeholder="Click an Image"
                                            class="form-control volume-auto-input"
                                             />

                                        <span class="u-mm">
                                            m<sup>3</sup>
                                        </span>
                                    </div>
                                </div>


                            </div>
                            <div
                                v-else
                                id="dimensions-manual"
                                class="dimensions-manual-content-block active">


                                <div class="st--common--box">
                                    <div class="dimensions-btn">
                                        <a
                                            v-for="option in (parseInt(addForm.item_details.quantity) !== 1 ? value_type_options : [value_type_options[0]])"
                                            :key="option.id"
                                            :value="option.id"
                                            @click="value_type = option.id"
                                            :class="[{'active': value_type === option.id}]"
                                            class="dimensions-auto">
                                            <span></span>{{ getValueTypeTitle(option) | titleize }}
                                        </a>
                                    </div>
                                </div>

                                <div class="st--common--box">
                                    <div class="common-st-title-label">
                                        <h5>Preferred Unit</h5>
                                    </div>
                                    <multiselect
                                        v-model="preferred_dimension_unit"
                                        :options="dimension_units"
                                        track-by="id"
                                        label="name"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        placeholder="Select a unit"
                                    ></multiselect>
                                </div>

                                <div
                                    v-for="(dimension_field, key) in dimension_fields"
                                    :key="key"
                                    class="st--common--box"
                                    :class="(total_dimension_fields.includes(dimension_field) ? 'read-input' : '')">
                                    <div class="common-st-title-label">
                                        <h5>{{ dimension_field | titleize }}</h5>
                                    </div>
                                    <div class="common--input-filed unit-input">
                                        <input
                                            v-model="addForm.item_details[dimension_field]"
                                            :aria-describedby="dimension_field"
                                            :id="'_'+dimension_field"
                                            :name="'_'+dimension_field"
                                            @input="changeVolume"
                                            :readonly="total_dimension_fields.includes(dimension_field)"
                                            :type="total_dimension_fields.includes(dimension_field) ? 'text' : 'number'"
                                            :placeholder="total_dimension_fields.includes(dimension_field) ? 'Please enter height, width & depth' : ''"
                                            class="form-control"
                                            :class="dimension_field + '-input'" />

                                        <span class="u-mm">
                                            {{ !total_dimension_fields.includes(dimension_field) ? preferred_dimension_unit.name : 'm' }}
                                            <sup v-if="['volume', 'total_volume'].includes(dimension_field)">3</sup>
                                            <sup v-if="['area', 'total_area'].includes(dimension_field)">2</sup>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </asset-info-section>

        </div>
        <div class="composition-block col-xl-12">
            <asset-info-section
                title="Waste Stream"
                :is_afid_field="true">
                <div>
                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Waste Stream</h5>
                        </div>
                        <multiselect
                            v-model="addForm.single_select_v_models.waste_stream_type"
                            :options="required_fields.waste_stream_type.options"
                            track-by="id"
                            :custom-label="CustomMSLabel"
                            class="waste-stream-options"
                            :allow-empty="false"
                            :show-labels="false"
                            placeholder="Select Condition"
                        ></multiselect>
                    </div>

                    <div class="st--common--box" v-if="addForm.single_select_v_models.waste_stream_type && is_waste_stream_product">
                        <div class="common-st-title-label">
                            <h5>Product Waste Stream</h5>
                        </div>
                        <multiselect
                            v-model="addForm.single_select_v_models.waste_stream_id"
                            track-by="id"
                            :custom-label="CustomMSLabel"
                            class="category-options"
                            :options="getOptionsFor('waste_stream_id')"
                            :allow-empty="false"
                            placeholder="Select Product Waste Stream"
                        >
                            <span slot="noResult">No data found.</span>
                        </multiselect>
                    </div>

                    <div class="st--common--box" v-else-if="addForm.single_select_v_models.waste_stream_type">
                            <div class="common-st-title-label">
                                <h5>Composition</h5>
                            </div>
                            <div class="composition--item-block">
                                <div class="row">

                                    <div
                                        v-for="(composition, key) in required_fields.composition.options"
                                        :key="key"
                                        class="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                        <div class="composition-item-box">
                                            <div class="common-checkbox-img">
                                                <label>
                                                    <input
                                                        v-model="addForm.multi_select_v_models.composition"
                                                        :id="'compositions_' + composition.id"
                                                        :value="composition"
                                                        type="checkbox"
                                                        name="">
                                                    <div class="common-item-img">
                                                        <img :src="composition.image" />
                                                    </div>
                                                    <span class="common-check">{{ composition.name | titleize }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                </div>
            </asset-info-section>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import multiSelect from '@/mixins/MultiSelect';
import find from 'lodash/find';
import {AutoDimensionsList} from "../../form_data/AutoDimensionsList";

export default {
    name: "PhysicalProperties",
    components: {AssetInfoSection},
    mixins: [multiSelect],
    props: ['addForm', 'required_fields', 'my_lists_values'],
    data(){
        return {
            selected_dimensions_type: 'auto',
            dimensions_types: [
                {
                    name: 'auto',
                    class: 'dimension-type-auto'
                },
                {
                    name: 'manual',
                    class: 'dimension-type-manual'
                }
            ],
            dimension_fields:[
                'height', 'width', 'depth', 'area', 'total_area', 'volume', 'total_volume'
            ],
            total_dimension_fields:[
                'area', 'total_area', 'volume', 'total_volume'
            ],
            preferred_dimension_unit: null,
            dimension_units:[
                {id: 1000, name: 'mm'},
                {id: 100, name: 'cm'},
                {id: 1, name: 'm'},
            ],
            selected_auto_dimensions: null,
            auto_dimensions_list: AutoDimensionsList,
            condition_options:[
                { id: 4, name: 4 },
                { id: 3, name: 3 },
                { id: 2, name: 2 },
                { id: 1, name: 1 },
            ],
            value_type: 0,
            value_type_options:[
                {
                    id: 0,
                    name: 'Dimensions for 1 item'
                },
                {
                    id: 1,
                    name: 'Dimensions for (x) items'
                }
            ],
        }
    },
    created() {
        let current_item_details = this.addForm.item_details;
        if (current_item_details){
            this.selected_dimensions_type = current_item_details.preferred_input ?? 'manual';
            this.preferred_dimension_unit = current_item_details.preferred_unit ? _.find(this.dimension_units, ['name', current_item_details.preferred_unit]) : this.dimension_units[0];
            if (this.selected_dimensions_type === 'auto'){
                this.selected_auto_dimensions = current_item_details.auto_dimension_id ? _.find(this.auto_dimensions_list, ['id', current_item_details.auto_dimension_id]) : null;
            }
        }else{
            this.selected_dimensions_type = window.USER_INFO.user_meta.dimensions_type ?? 'auto';
            this.preferred_dimension_unit = window.USER_INFO.user_meta.preferred_dimension_unit ?? this.dimension_units[0];
        }

    },
    methods:{
        getValueTypeTitle(option) {
            if (this.addForm.item_details && option.id === 1) {
                return option.name.replace('(x)', this.addForm.item_details.quantity);
            }
            return option.name
        },
        setItemDimensions(selected_dimensions) {
            let current_item_details = this.addForm.item_details;
            current_item_details.width = selected_dimensions.width;
            current_item_details.height = selected_dimensions.height;
            current_item_details.depth = selected_dimensions.depth;
            this.addForm.item_details = current_item_details;
            this.changeVolume();
        },
        reformatNumbers(number){
            let new_value = number ? parseFloat((number+"").replace(',', '')) : '';
            return new_value !== '' ? new_value / this.preferred_dimension_unit.id : '';
        },
        changeVolume(){
            let item_details = this.addForm.item_details,
                width = this.reformatNumbers(item_details.width),
                height = this.reformatNumbers(item_details.height),
                depth = this.reformatNumbers(item_details.depth);

            if (width === '' && height === '' && depth === ''){
                return;
            }
            this.addForm.item_details.preferred_input = this.selected_dimensions_type ?? 'manual';
            this.addForm.item_details.preferred_unit = this.preferred_dimension_unit ? this.preferred_dimension_unit.name : 'm';
            this.addForm.item_details.auto_dimension_id = this.selected_auto_dimensions ? this.selected_auto_dimensions.id : null;

            let dimensions = [width, height, depth];
            let volume = dimensions.reduce( (a,b) => a * b );
            dimensions = dimensions.sort(function(a, b) {
                return a - b;
            }).slice(-2);
            let area = dimensions.reduce( (a,b) => a * b );
            let quantity = this.addForm.item_details.quantity;

            if (this.value_type === 0) {
                this.addForm.item_details.area = 1 * area.toFixed(2);
                this.addForm.item_details.volume = 1 * volume.toFixed(2);

                // for one item!
                let total_area = (this.addForm.item_details.area * quantity).toFixed(2);
                let total_volume = (this.addForm.item_details.volume * quantity).toFixed(2);

                this.addForm.item_details.total_area = 1 * total_area;
                this.addForm.item_details.total_volume = 1 * total_volume;

            } else {
                this.addForm.item_details.total_area = 1 * area.toFixed(2);
                this.addForm.item_details.total_volume = 1 * volume.toFixed(2);

                // for all
                let area_for_one = (this.addForm.item_details.total_area / quantity).toFixed(2);
                let volume_for_one = (this.addForm.item_details.total_volume / quantity).toFixed(2);
                this.addForm.item_details.area = 1 * area_for_one;
                this.addForm.item_details.volume = 1 * volume_for_one;
            }

        },
        changeDimensionsType(dimensions_type_name) {
            this.selected_dimensions_type = dimensions_type_name;
            this.set_usermeta('dimensions_type', dimensions_type_name, 'Saved Successfully');
        },
        getOptionsFor(field_key){
            if (['category_id'].includes(field_key)){
                let folders_list = this.required_fields[field_key].options;
                let item_type = field_key.replace('_id', '') + 's';
                return this.getFolderObject(folders_list, 1, item_type, 'id', 'name');
            }
            return this.required_fields[field_key].options;
        },
        getValueFor(field_id){
            if (field_id === 'category_id') {
                return this.addForm.single_select_v_models[field_id] ? this.addForm.single_select_v_models[field_id].name : '';
            }
            return this.addForm.item_details[field_id] ? this.getValueFrom(this.addForm.item_details[field_id].id, this.required_fields[field_id].options, 'name', 'id') : '';
        },
        getValueFrom(value_id, items_list, required_value, used_key='id'){
            let foundItem = find(items_list, function(o) { return o[used_key] === value_id; });
            if (foundItem){
                return foundItem[required_value];
            }
            return value_id;
        }
    },
    computed: {

        selected_asset_type_keys(){
            if (this.my_lists_values){
                return this.addForm.single_select_v_models.asset_type ? this.my_lists_values.asset_type[this.addForm.single_select_v_models.asset_type.id].asset_template_fields : [];
            }
            return [];
        },
        is_waste_stream_product(){
            if (this.addForm.single_select_v_models.waste_stream_type){
                return this.addForm.single_select_v_models.waste_stream_type.id === 0;
            }
            return false;
        },
    },
    watch:{
        value_type:{
            deep: true,
            handler: function (newValue) {
                this.changeVolume();
            }
        },
        selected_auto_dimensions: {
            deep: true,
            handler: function(newValue){
                let selected_auto_unit_index = this.dimension_units.map(function(e) { return e.name; }).indexOf(this.selected_auto_dimensions.unit);

                this.preferred_dimension_unit = this.dimension_units[selected_auto_unit_index > -1 ? selected_auto_unit_index : 0]
                this.setItemDimensions(this.selected_auto_dimensions.dimensions);
            }
        },
        preferred_dimension_unit: {
            deep: true,
            handler: function(newValue){
                this.changeVolume();
            }
        }
    }
}
</script>

