<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_workflow",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    data(){
        return {
            addForm:{
                workflow_defaults: 1,
                workflow_assigned: 1,
                workflow_imported: 1,
                workflow_asset_flow: 1
            },
            sidebar_navigation: {
                workflow: {
                    label: 'Workflow',
                    settings: {
                        workflow_defaults: {
                            id: 'workflow_defaults',
                            label: 'Defaults',
                            functionality: 'core'
                        },
                        workflow_assigned: {
                            id: 'workflow_assigned',
                            label: 'Assigned',
                            functionality: 'core'
                        },
                        workflow_imported: {
                            id: 'workflow_imported',
                            label: 'Imported',
                            functionality: 'core'
                        },
                        workflow_asset_flow: {
                            id: 'workflow_asset_flow',
                            label: 'Asset Flow',
                            functionality: 'core'
                        },
                    }
                },
            }
        }
    }
}
</script>

