const FirstPath = window.location.pathname.split('/')[1];

export const OrderLineShortKeysList = {
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-1 ordl_created_at",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'MMMM Do YYYY') : '';
        }
    },
    order_type: {
        key: 'order_type',
        label: "Type",
        class: "col-lg-1 ordl_order_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            switch (item[key]){
                case 0:
                    if (FirstPath === 'sp'){
                        return 'Intake';
                    }
                    return 'Move To Storage';
                case 1:
                    return 'Storage Order';
                case 99:
                    return 'Local Order (Demo)';
                default:
                    return 'Order';
            }
        }
    },
    uid: {
        key: 'uid',
        label: "Order Line ID",
        class: "col-lg-1 ordl_uid",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_class = 'action-missing';
            if (item.status === 1){
                status_class = 'action-populated';
            }
            return '<a class="text-dark" href="/' + FirstPath + '/order_lines/' + item.id + '/view"><div class="order_lines-status ' + status_class + '"><span></span><span class="font-size-12">' + item[key] + '</span></div></a>';
        }
    },
    parent_order: {
        key: 'parent_order',
        label: "Order",
        class: "col-lg-1 ordl_parent_order",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let order_info = item.order_info;
            if (order_info){

                let status_class = 'action-missing';
                switch (order_info.status) {
                    case 1:
                        status_class = 'action-populated order-confirmed';
                        break;
                    case 2:
                        status_class = 'action-populated order-in_progress';
                        break;
                    case 3:
                        status_class = 'action-populated order-completed';
                        break;
                    case 4:
                        status_class = 'action-populated order-cancelled';
                        break;
                    case 5:
                        status_class = 'action-populated order-awaiting_transport';
                        break;
                }
                return '<a class="text-dark" href="/' + FirstPath + '/orders/' + order_info.id + '/view"><div class="orders-status ' + status_class + '"><span></span><span class="font-size-12">ORD-' + order_info.uid + '</span></div></a>';
            }
            return '';
        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-1 ordl_customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_info = item.customer_info;
            if (customer_info){
                return '<a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a>';
            }
            return '';
        }
    },
    sp_info: {
        key: 'sp_info',
        label: "Service Provider",
        class: "col-lg-1 ordl_sp_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let sp_info = item.sp_info;
            if (sp_info){
                return '<a class="text-dark" href="/' + FirstPath + '/service_providers/' + sp_info.id + '/view">' + Vue.options.filters.titleize(sp_info.name) + '</a>';
            }
            return '';
        }
    },
};
