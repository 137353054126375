const FirstPath = window.location.pathname.split('/')[1];
export const PartChangeKeysList = {
    scene_id: {
        key: 'scene_id',
        label: "Scene",
        class: "col-lg-1 p_scene_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('scene_info') && item.scene_info){
                return '<a class="text-dark" href="/' + FirstPath + '/parts?queue=scenes&id='+item.scene_info.id+'">' + item.scene_info.name + ((item.name !== '' && item.name !== null) ? ' ' + item.name : '') + '</a>';
            }
            return '-';
        }
    },
    day: {
        key: 'day',
        label: "Day",
        class: "col-lg-1 p_day",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let int_ext = item.int_ext === 0 ? 'Int' : 'Ext';
            return (item.scene_time !== null ? item.scene_time : '?') + '' + (item.day_number !== null ? item.day_number : '?') + ' ' + int_ext;
        }
    },
    scene_location_id: {
        key: 'scene_location_id',
        label: "Location",
        class: "col-lg-2 p_scene_location_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let html = '';
            if (item.hasOwnProperty('scene_location_info') && item.scene_location_info){
                html += '<a class="text-dark" href="/' + FirstPath + '/scene_locations/'+item.scene_location_info.id+'/view">' + item.scene_location_info.name;
                html += '</a>';
            }

            return html !== '' ? html : '-';
        }
    },
    description: {
        key: 'description',
        label: "Description",
        class: "col-lg-5 p_description",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },

    cast_info: {
        key: 'cast_info',
        label: "Cast",
        class: "col-lg-2 p_cast_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('cast_info') && item.cast_info){
                let html = '';
                for (const characterKey in item.cast_info) {
                    let character_info = item.cast_info[characterKey];
                    html += '<a href="/' + FirstPath + '/characters/' + character_info.id + '/view" class="badge text-af-primary af-soft-accent font-size-11 m-1">'+(character_info.hasOwnProperty('character_number') ? character_info.character_number : character_info.name) +'</a>';
                }
                return html;
            }
            return '-';
        }
    },
    shoot_day_date: {
        key: 'shoot_day_date',
        label: "Shoot Date",
        class: "col-lg-1 p_shoot_day_date",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.moment(item[key], 'MMMM Do YYYY');
        }
    }
};
