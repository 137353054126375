<template>
    <Layout>
        <add-new-supplier
            ref="add-new-supplier"
            item_type='supplier'
            modal_title="Create New Supplier"
            @on-new-item-adding="getList"
        >
        </add-new-supplier>

        <div class="suppliers-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :received_filter_classes="filter_classes"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />
                <!-- Page-Content start-->
                <div id="page-content-wrapper">
                    <div class="page-content-inner">
                        <div>
                            <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                        </div>

                        <div class="product-page-header">
                            <h2 class="title">Supplier List</h2>
                            <div class="filter-searchbar dropdown">
                                <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                            placeholder_text="Search Suppliers.."></search-box>
                                <b-dropdown v-if="hasPermission('add_suppliers')" dropleft
                                            class="add-filter" variant="danger">
                                    <template slot="button-content">
                                        <span class="add-filter-button"></span>
                                    </template>
                                    <b-dropdown-item v-if="hasPermission('add_suppliers')"
                                                     @click="addNewItem('supplier')">Supplier
                                    </b-dropdown-item>


                                    <b-dropdown-item v-if="hasPermission('add_file_imports') && hasPermission('add_suppliers')" :href="'/' + (item_type === 'sp' ? 'sp' : 'customer') + '/files/file-import-export?object_type=supplier'">
                                        Import Suppliers
                                    </b-dropdown-item>

                                </b-dropdown>
                            </div>
                            <div class="page-value-info">
                                <ul class="d-flex align-items-center">
                                    <li class="d-flex align-items-center">
                                        Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="product-filter-option-wrapper clearfix d-flex">

                            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                    request_meta.from + '-' + request_meta.to
                                }}</span> of <span>{{ request_meta.total }}</span></div>

                            <div class="filter-wrap d-flex align-items-center ml-auto">



                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Sort: <b>{{ sorting.sortType.text }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in sorting.sortOptions"
                                        :key="option.value"
                                        @click="saveSorting(option, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>

                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Show: <b>{{
                                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                        }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in perpage.pageOptions"
                                        :key="option.value"
                                        @click="savePerPage(option.value, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>

                        </div>

                        <!-- Product Section Start-->


                        <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                            <b-spinner class="text-af-accent m-2"></b-spinner>
                            <h4>Fetching Suppliers...</h4>
                        </div>

                        <div v-else-if="!suppliersData || total === 0 || sortedItemsList.length === 0"
                             class="products text-center">
                            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                            <h4>No Suppliers found here at the moment.</h4>
                        </div>

                        <div v-else-if="suppliersData" class="row">
                            <div class="col-lg-12">
                                <po-lines
                                    :items="sortedItemsList"
                                    :current_page_type="item_type"
                                    :object_type="'suppliers'"
                                    :keys_list="supplier_list_keys_list">
                                </po-lines>
                            </div>
                        </div>

                        <!-- Product Section End-->
                        <div class="pagination">
                            <b-pagination
                                v-if="perpage.perPage !== 0"
                                v-model="perpage.currentPage"
                                :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item !== undefined ? selected_item : null)"
                                class="pagination d-flex align-items-center justify-content-center"
                                :per-page="perpage.perPage"
                            ></b-pagination>
                        </div>

                    </div>
                </div>
                <!-- Page-Content end-->

            </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import GmapCustomMarker from "../extras/gmap-custom-marker";
import AddNewSupplier from "../extras/AddNewSupplier";
import SearchBox from "@/components/search-box";

const supplierResource = new Resource('api/assetflow/suppliers');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";



import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
export default {
    name: 'SuppliersList',
    props: ['item_id', 'item_type'],
    mixins:[ObjectsSidebarShared],
    components: {
        ObjectsSidebar,
        LotsOfItemsAlert, PoLines, AddNewSupplier, SearchBox, GmapCustomMarker, Layout, PageHeader},
    data() {
        return {
            listLoading: false,
            supplier_list_keys_list:{
                uid: {
                    key: 'uid',
                    label: "ID",
                    class: "col-lg-1 s_uid",
                    show_on_mobile: true,
                    case: ''
                },
                account_no_ref: {
                    key: 'account_no_ref',
                    label: "Ref No.",
                    class: "col-lg-2 s_ref",
                    show_on_mobile: true,
                    case: ''
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg-2 s_name",
                    show_on_mobile: true,
                    case: 'custom',
                    used_func: function (value){
                        return Vue.options.filters.titleize(value);
                    }
                },
                status: {
                    key: 'status',
                    label: "Status",
                    class: "col-lg-2 s_status",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<span class="active_button button_size data_status'+(item.status === 1 ? 'active_status' : 'inactive_status')+'">'+(item.status === 1 ? 'Active' : 'Inactive')+'</span>';
                    }
                },
                phone: {
                    key: 'phone',
                    label: "Phone",
                    class: "col-lg-2 s_phone",
                    show_on_mobile: false,
                    case: ''
                },
                website: {
                    key: 'website',
                    label: "Website",
                    class: "col-lg-2 s_website",
                    show_on_mobile: false,
                    case: ''
                },
            },
            total: 0,
            suppliersData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Suppliers",
            items: [
                {
                    text: "Suppliers",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_supplier_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },

            permission_name: 'suppliers',
            object_type: 'suppliers',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
            ],
            items_list: [
                [],
            ],
            filter_classes: {
                '0': 'supplier-all',
            },


        };
    },
    created() {
        this.has_supplier_actions_permissions = this.hasPermission('edit_suppliers') || this.hasPermission('delete_suppliers');

        if (this.has_supplier_actions_permissions){
            this.supplier_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "po_actions",
                show_on_mobile: false
            }
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_suppliers_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }


    },
    computed: {
        totalAssetsValue() {
            return this.suppliersData ? this.suppliersData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        checkSupplierStatus(supplier){
            return parseInt(supplier.assets_sum_quantity) !== 0;
        },

        performAction(supplier, action, supplierKey, event){
            if (action === 'edit'){
                this.$refs['add-new-supplier'].setSelectedItem(supplier);
                this.$bvModal.show('create-supplier');
                this.setupAddressLookup('supplier');
            }else if (action === 'delete'){
                if (supplier.id !== '' && supplier.assets_count === 0){
                    this.deleteItemByID(supplier.id, supplier.name, 'suppliers', event)
                    .then((response) => {
                        if (response.data.data.status){
                            this.suppliersData.splice(supplierKey, 1);
                        }else{
                            console.log("response.data");
                            console.log(response.data);
                        }
                    });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
             return this.suppliersData;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('supplier_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_suppliers_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null, changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };


            if (this.search_keyword){
                required_query.s_keyword = this.search_keyword;
            }

            this.listLoading = true;

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);
            required_query.with_flat_results = 0;
            const {data} = await supplierResource.list(required_query);

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.suppliersData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                // console.log("no meta info");
            }
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-supplier'].setSelectedItem(null);
            this.$bvModal.show('create-supplier');
            this.setupAddressLookup('supplier');
        }
    },
}
</script>


