<template>
    <div class="intelligence-template">
        <div class="mt-1">
            <a :href="'/' + item_info.current_page_type + '/' + item_info.item_type" class="btn btn-primary btn-sm af-accent" style="width:inherit;">
                <i class="fas fa-arrow-left"></i> Return to {{ item_info.item_type | titleizeToSingle }} Listing Page
            </a>
            <a  :href="'/' + item_info.current_page_type + '/managed_storages/in_storage?queue=' + item_info.item_type + '&id='+item_info.id" class="btn btn-primary btn-sm af-accent ml-1" style="width:inherit;">
                <i class="fa fa-stop-circle"></i> View In Assets
            </a>
        </div>

        <add-new-folder
            v-show="item_info.item_type === 'folders'"
            ref="add-new-folders"
            item_type="folders"
            modal_title="Create New Folder"
            @on-new-item-adding="refreshPage('create-folders')"
         />
        <add-new-tag
            v-show="item_info.item_type === 'tags'"
            ref="add-new-tags"
            item_type="tags"
            modal_title="Create New Tag"
            @on-new-item-adding="refreshPage('create-tags')"
        />
        <div class="row">
            <div class="col-12">
                <div class="cm_list_view_wrapper">
                    <div class="cm_list_view af-primary cm_list_view-header">
                        <div class="cm_list_row row">
                            <div class="col-md-2 col-xl-2" v-iconize="item_info.item_type">{{ item_info.item_type | titleize }}</div>
                            <div class="col-md-2 col-xl-2"></div>
                            <div class="col-md-1 col-xl-1">Created At</div>
                            <div class="col-md-2 col-xl-2">Created By</div>
                            <div class="col-md-1 col-xl-1">Assets</div>
                            <div class="col-md-4 col-xl-4">Stock</div>
                        </div>
                    </div>

                    <ParentAwareObject
                        v-for="(object, objectKey) in [item_info]"
                        :key="objectKey"
                        :object="object"
                        :item_type="item_info.current_page_type"
                        :object_type="item_info.item_type"
                        :object_index="0"
                        :performAction="performAction"/>
                </div>
            </div>
        </div>

        <div class="tab_menu_item" v-if="item_info.item_type === 'tags'">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>

        <div v-if="item_info.item_type === 'tags'" class="tab-content tab_content_design">
            <div class="tab-pane" id="assets_in_parent_tab" role="tabpanel" :class="isActiveTab('assets_in_parent_tab')">
                <ParentAssetsListBlock :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="activity_tab" role="tabpanel" :class="isActiveTab('activity_tab')">
                <TagActivityTab :item_info="item_info"/>
            </div>
        </div>

        <div v-else class="tab-content">
            <ParentAssetsListBlock :item_info="item_info"/>
        </div>


    </div>
</template>
<script>

import {FolderKeysList} from "../form_data/FolderKeysList";
import {TagKeysList} from "../form_data/TagKeysList";
import {CategoryKeysList} from "../form_data/CategoryKeysList";
import ParentAwareObject from "../customers/folders/ParentAwareObject";
import AddNewFolder from "../customers/extras/AddNewFolder";
import AddNewTag from "../customers/extras/AddNewTag";
import ParentAssetsListBlock from "./ParentAssetsListBlock";
import TagActivityTab from "./TagActivityTab";

export default {
    name: 'CustomAssetsParentDetails',
    components: {
        TagActivityTab,
        ParentAssetsListBlock,
        AddNewTag,
        AddNewFolder,
        ParentAwareObject},
    props: ['item_info'],
    data(){
        return {
            folders_list_keys_list: FolderKeysList,
            tags_list_keys_list: TagKeysList,
            categories_list_keys_list: CategoryKeysList,
            active_tab: 'assets_in_parent_tab',
            tabs: {
                'assets_in_parent_tab': {
                    'id': "assets_in_parent_tab",
                    'name': "Assets",
                    'icon': "user",
                    'type': "assets_in_parent_tab",
                    'isActive': false
                },
                'activity_tab': {
                    'id': "activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "activity_tab",
                    'isActive': false
                },
            },
            required_keys_list: null
        }
    },
    created() {
        this.required_keys_list = this[this.item_info.item_type + '_list_keys_list'];

        let sp_storages_active_tab = localStorage.getItem('assets_parent_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'assets':
                    this.active_tab = 'assets_in_parent_tab';
                    break;
                case 'overview':
                    this.active_tab = 'activity_tab';
                    break;
            }
        }else if (sp_storages_active_tab && sp_storages_active_tab !== ''){
            this.active_tab = sp_storages_active_tab;
        }

    },
    methods:{
        refreshPage(modal_id){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            location.reload();
        },
        performAction(item, action, folderKey, e){
            if (action === 'edit'){
                let object_type = this.item_info.item_type;
                this.$refs['add-new-'+object_type].setSelectedItem(item);
                this.$bvModal.show('create-'+object_type);
            }
        },
        tabClicked(tab_id){
            localStorage.setItem('assets_parent_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
