<template>
    <div class="row">


        <add-new-modal
            ref="add-new-address"
            item_type='address'
            modal_title="Create New Address"
            @on-new-item-adding="onAddingNewAddress"
        ></add-new-modal>



        <NewCustomGenericBlock
            v-if="customer_overview"
            :block_title="customer_overview.label"
            columns_count_withclasses="12">
            <template v-slot:all_content>
                <CustomGenericBlockContent :block_contents="customer_overview"/>
            </template>
        </NewCustomGenericBlock>

        <div class="col-sm-12">
            <div class="card" v-for="(required_section, k) in required_sections" :key="k">
                <div role="alert" class="alert alert-info order-service-msg" v-if="k === 'service_setup' && !item_info.hasOwnProperty('customer_id')">
                    <p><i class="mdi mdi-alert-circle-outline me-2"></i><em>To set order service, please select a customer first.</em></p>
                </div>
                <template v-else>
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.label | titleize }}
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.fields" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="col-sm-12">
            <div class="text-right mt-2">
                <AFButton
                    v-if="service_id_not_empty"
                    tag_name="button"
                    tag_type="submit"
                    :key_prefix="'set-service-' + buttonkey"
                    :is_enabled="is_saving_enabled"
                    :required_action="addItem"
                    :required_text="shall_confirm ? 'Confirm' : 'Save'"
                    item_classes="btn btn-success"
                />
                <b-button v-else disabled>{{shall_confirm ? 'Confirm' : 'Save'}}</b-button>
                <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
            </div>
        </div>

    </div>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";
import NewCustomGenericBlock from "../../items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import CustomGenericBlockContent from "../../items/widgets/detailsViews/AssetTabs/CustomGenericBlockContent";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import SetServiceAndConfirm from "./SetServiceAndConfirm";

import SharedServiceFunctions from '@/mixins/SharedServiceFunctions.mixin';
import AddNewModal from "../extras/AddNewModal";
import AFButton from "../../../../components/AFButton";

export default {
    mixins: [OrdersAndLinesShared, SharedServiceFunctions],
    name: "SetServiceAndConfirm",
    props: ['item_type', 'received_item_info', 'customer_overview', 'shall_confirm'],
    components: {
        AFButton,
        AddNewModal,
        SetServiceAndConfirm,
        CustomGenericBlockContent,
        NewCustomGenericBlock,
        ChangeCommonFieldsAdvanced
    },
    data(){
        return {
            item_info: null,
            addForm:{
                has_errors: null,
                service_id:null,
                address_id:null,
            },
            required_sections: {
                service_setup:{
                    'label' : 'Service',
                    fields: {
                        service_id:{
                            key: 'service_id',
                            label: 'Service',
                            placeholder: 'Select Required Service',
                            type: 'single-select',
                            is_required:false,
                            allow_empty:true,
                            has_message: true,
                            message_html: '<div role="alert" class="alert alert-info order-service-msg"><p><i class="mdi mdi-alert-circle-outline me-2"></i><em>Setting a service is required before confirmation.</em></p></div>',
                            options: []
                        },
                        address_id:{
                            key: 'address_id',
                            label: 'Delivery Address',
                            placeholder: 'Select Delivery Address',
                            type: 'hidden_text',
                            is_required:true,
                            allow_empty:true,
                            options: []
                        },
                    }
                },
            },
        }
    },
    created() {
        if (this.received_item_info){
            this.setSelectedItem(this.received_item_info);
        }
    },
    computed:{
        selected_service_id() {
            return this.addForm.service_id;
        },
        service_id_not_empty() {
            if (this.addForm && this.selected_service_id){
                return Object.keys(this.selected_service_id).length !== 0;
            }
            return false;
        },
    },
    watch: {
        selected_service_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal){
                    if (newValue && newValue.hasOwnProperty('service_type_info') && newValue.service_type_info){
                        let service_type_info = newValue.service_type_info;
                        if (
                            service_type_info &&
                            service_type_info.hasOwnProperty('order_attributes') &&
                            service_type_info.order_attributes &&
                            service_type_info.order_attributes.includes(1) // delivery address field === 1?
                        ){
                            this.shallGetListFor('address', 'service_setup');
                            this.required_sections.service_setup.fields.address_id.type = 'single-select';
                            return;
                        }
                    }
                }
                this.required_sections.service_setup.fields.address_id.type = 'hidden_text';
            }
        },
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        setSelectedItem(item){
            this.item_info = item ? item : null;
            this.shallGetListFor('service', 'service_setup');

            if (this.item_info.service_id){
                this.setValueFromOptions('service_setup', 'service_id', this.item_info.service_id);
            }
            if (this.item_info.address_id){
                this.setValueFromOptions('service_setup', 'address_id', this.item_info.address_id);
            }
            this.required_sections.service_setup.fields.address_id.onChangingValue = this.handleSelectingAddress;
        },
        setValueFromOptions(section_name, field_name, item_id, default_value = null) {
            let found_item = _.find(this.required_sections[section_name].fields[field_name].options, function (o) {
                return o.id === item_id;
            });

            this.addForm[field_name] = found_item ? found_item : default_value;
        },
        addItem(){

            if (this.shall_confirm){
                let service_address_was_set;
                let message = '';
                if (this.addForm.service_id){
                    service_address_was_set = this.checkIfServiceAddressWasSet();
                    message = 'Delivery Address For (' + this.addForm.service_id.name + ') is required for confirmation.';
                }else{
                    service_address_was_set = false;
                    message = 'Service is required for confirmation.';
                }

                if (!service_address_was_set){
                    this.buttonkey++;
                    this.presentToast("Error!",
                        message,
                        'danger',
                        3000,
                        true,
                        true);
                    return;
                }
            }

            let other_info = {
                service_id: this.addForm.service_id ? this.addForm.service_id.id : null,
                address_id: this.addForm.address_id ? this.addForm.address_id.id : null
            };

            let nextStatus = this.item_info.status;
            if (this.shall_confirm){
                nextStatus = 1;
            }
            let strongThis = this;
            this.set_item_property_new('orders', this.item_info.id, 'status', nextStatus, other_info, function () {
                strongThis.$emit('after-saving')
            });
        },
        cancelClicked(){
            this.$emit('on-cancel-clicked');
        },

    }
}
</script>
