<template>
    <div class="DispositionLeftBox">

    </div>
</template>
<script>
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'DispositionLeftBox',
    props: {
        my_asset: {},
        item_type: {},
        performAction: {}
    },
    computed:{
    }
}
</script>
