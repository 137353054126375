<template>
    <b-modal :id="'create-'+item_type"
             size="xl"
             :ref="'create-'+item_type"
             :title="selected_item && selected_item.hasOwnProperty('name') ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="common-step-panel use-section">
            <div class="row">

                <AddNewLedger
                    ref="add-new-ledger"
                    item_type='ledger'
                    modal_title="Create New Ledger Account"
                    @on-new-item-adding="addNewItemToOptions"
                >
                </AddNewLedger>


                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :required_fields="required_fields"
                        :received_toggle="section_group.minimized">

                        <div class="step-common-info-right-block" v-if="key === 'image_upload_section'">
                            <div class="st-drop-file-block">
                                <div class="st-drop-file-box">
                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="dropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-removed-file="fileRemoved"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <div class="st-upload-title">
                                                <h3>Drop files here or click to upload</h3>
                                                <p>... or click to select a file from your computer</p>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone[0].$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New Image</b-button>
                                </div>
                            </div>
                        </div>

                        <StepCommonInfoBlock
                            v-else
                            :add-form="addForm"
                            :block_key="key"
                            :item_info="acquisition_info"
                            :changed-radio-value="changedRadioValue"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :my_lists_values="lists_values"/>
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item && selected_item.hasOwnProperty('name') ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const characterResource = new Resource('api/assetflow/characters');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetInfoSection from "../../../../assets/assetForm/AssetInfoSection";
import StepCommonInfoBlock from "../StepCommonInfoBlock";
import AddNewLedger from "../../../extras/AddNewLedger";

export default {
    mixins: [multiSelect, dropzoneMixin],
    name: "AcquisitionLineModal",
    props: ['modal_title', 'item_type', 'acquisition_info'],
    components: {AddNewLedger, StepCommonInfoBlock, AssetInfoSection, DatePicker},
    data(){
        return {
            addForm: {
                asset_meta: {},
                asset_images: [],
                filled_asset_images: [],
                item_details: {},
                added_acquisition_types: [],
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {
                        name_section: {
                            name: 'Line Item Name',
                            minimized: false,
                            fields: [
                                'name'
                            ]
                        },
                        costume_section: {
                            name: 'Costume',
                            minimized: false,
                            fields: [
                                'ledger_id',
                            ]
                        },
                        cost_section: {
                            name: 'Quantity & Costs',
                            minimized: false,
                            fields: [
                                'quantity',
                                'total_price',
                                'unit_price',
                                'net_total',
                                'unit_net',
                                'tax_rate',
                                'tax',
                                'unit_tax',
                            ]
                        },
                    }
                },
            },
            required_fields:{
                name: {
                    name: 'Description',
                    type: 'text',
                },
                ledger_id: {
                    name: 'Ledger Account',
                    type: 'single-select',
                    name_plural:'ledger accounts',
                    link_name:'ledgers',
                    has_permission: true,
                    options:[]
                },
                ledger_group_id: {
                    is_hidden:true,
                    options:[]
                },
                quantity: {
                    name: 'Quantity',
                    type: 'number',
                    default_value: 1,
                    has_permission: true
                },
                total_price: {
                    name: '1 Unit',
                    main_title: 'Totals With Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                unit_price: {
                    name: 'Unit Price',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                net_total: {
                    name: '1 Unit',
                    main_title: 'Totals without Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_net: {
                    name: 'Unit Net',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                tax_rate: {
                    name: 'Tax Rate',
                    main_title: 'Tax',
                    type: 'number',
                    default_value: 0.2,
                    has_permission: true
                },
                tax: {
                    name: '1 Unit',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_tax: {
                    name: 'Unit Tax',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
            },
            selected_item: null,
            selected_index: null,
            list_disabled: null,
            lists_values: {},
            all_location_options: []
        }
    },
    mounted(){
        this.fillAssetImages();
    },
    created() {
        this.getFieldsList();
        this.setDefaultValues();
    },
    methods:{


        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                ledger_id: 'ledgers_list',
                ledger_group_id: 'ledger_groups_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets', ['ledger_id']);
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

        addNewItemToOptions(new_item, item_type){
            if (new_item) {
                if (item_type === 'ledger'){
                    let new_option = {id: new_item.id, name: new_item.name};
                    this.required_fields.ledger_id.options.push(new_option);
                    this.addForm.item_details.ledger_id = new_option;
                }
            }
        },
        changedRadioValue(field, event) {
            let option = this.addForm.item_details[field];

            if (option && option.hasOwnProperty('id') && option.id === -99) {
                let custom_id = '';
                switch (field) {
                    case 'ledger_id':
                        let ledger_group_ids = this.required_fields.ledger_group_id.options;
                        if (ledger_group_ids){
                            this.$refs['add-new-ledger'].setLedgerGroups(this.required_fields.ledger_group_id.options);
                        }else{
                            this.$refs['add-new-ledger'].setLedgerType('ledger');
                        }

                        this.$refs['add-new-ledger'].setSelectedItem(null);
                        custom_id = 'ledger';
                        break;
                }
                this.addForm.item_details[field] = null;
                this.$bvModal.show('create-' + custom_id);

            }
        },
        setDefaultValues() {
            let addForm = this.addForm;
            _.forOwn(this.required_fields, function (field, key) {
                if (field.hasOwnProperty('default_value')) {
                    if (!addForm.item_details.hasOwnProperty(key)){
                        addForm.item_details[key] = field.default_value;
                    }
                }
            });

            this.addForm = Object.assign({}, addForm);
        },
        resetSelectedItem(){
            this.setSelectedItem(null, this.selected_index);
        },
        setValueFromOptions: function ( field_name, item_id, default_value = null) {
            if (this.required_fields[field_name]){
                let found_item = _.find(this.required_fields[field_name].options, function (o) {
                    return o.id === item_id;
                });

                this.addForm[field_name] = found_item ? found_item : default_value;
            }
        },
        setSelectedItem(item, index=0){
            this.selected_item = item;
            let addForm = {};
            this.selected_index = index;
            if (item){
                addForm.item_details = item;
                if (item.hasOwnProperty('ledger_info') && item.ledger_info){
                    addForm.item_details.ledger_id = item.ledger_info;
                }
            }else{
                addForm.item_details = {};
            }

            this.addForm = Object.assign({}, addForm);

            this.setDefaultValues();
        },

        async addItem(event){
            let item_details_values = Object.assign({}, this.addForm.item_details);

            let single_select_keys = [
                'ledger_id'
            ];

            for (const selectKeysKey in single_select_keys) {
                let select_key = single_select_keys[selectKeysKey];
                if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){
                    item_details_values[select_key] = item_details_values[select_key].id;
                }
            }

            item_details_values.line_type = 'Line';
            item_details_values.ledger_info = this.addForm.item_details.ledger_id;
            this.addForm.item_details = {}

            this.$bvModal.hide('create-'+this.item_type);

            this.resetSelectedItem();
            this.$emit('on-new-item-adding', item_details_values, this.selected_index);

        },
        fillAssetImages() {
            if (this.addForm.asset_images.length > 0) {
                for (const assetImagesKey in this.addForm.asset_images) {
                    let asset_image = this.addForm.asset_images[assetImagesKey];
                    var file = {
                        id: asset_image.id,
                        size: asset_image.image_name_size,
                        name: asset_image.name,
                        type: "image/png",
                        is_featured: asset_image.is_featured,
                        add_to_ap: asset_image.add_to_ap
                    };
                    var url = asset_image.image_name;

                    if (asset_image.image_name_size) {
                        this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    }
                }
            }
        },
    },
    computed: {
        asset_images_list() {
            return this.addForm.asset_images;
        },
    },
    watch: {
        asset_images_list:{
            deep: true,
            handler: function (newValue) {
                if (this.first_time){
                    this.fillAssetImages();
                    this.first_time = false;
                }
            }
        },
    }
}
</script>
