<template>
    <Layout>
        <AddNewSceneLocation
            ref="add-new-scene_locations"
            item_type='scene_locations'
            modal_title="Create New Location"
            @on-new-item-adding="getList"
        >
        </AddNewSceneLocation>

        <div class="scene_locations-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Locations List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Locations.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('scene_location')">Location
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-if="supportedLayouts && supportedLayouts.length > 0"
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                    </div>

                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Locations...</h4>
                    </div>
                    <div v-else-if="!scene_locationsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Locations found here at the moment.</h4>

                    </div>
                    <!-- Product Section Start-->
                    <div v-else-if="selectedLayout === 0 && scene_locationsData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="permission_name"
                                :keys_list="scene_location_keys_list">

                                <template v-slot:scene_locations_actions="{ item, index }">
                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item v-if="hasPermission('delete_' + permission_name)"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete', index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>
                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewOrderLine from "../extras/AddNewOrderLine";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";

const scene_locationResource = new Resource('api/assetflow/scene_locations');
import {SceneLocationKeysList} from "@/views/assetflow/form_data/SceneLocationKeysList";
import AddNewSceneLocation from "../extras/AddNewSceneLocation";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";


export default {
    name: 'SceneLocationList',
    props: ['item_id', 'item_type'],
    components: {LotsOfItemsAlert, AddNewSceneLocation, ObjectsSidebar, PoLines, AddNewOrderLine, SearchBox, Layout},
    mixins: [TimelineCommons, ObjectsSidebarShared],
    data() {
        return {
            listLoading: false,
            total: 0,
            scene_locationsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Locations",
            items: [
                {
                    text: "Locations",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_scene_location_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'scene_locations',

            object_type: 'scene_locations',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
            ],
            items_list: [
                [],
                // status
                [
                    {
                        id: 1,
                        label: 'Active',
                        key: 'active'
                    },
                    {
                        id: 0,
                        label: 'Inactive',
                        key: 'inactive'
                    }
                ],
            ],
            scene_location_keys_list: SceneLocationKeysList
        };
    },
    created() {
        this.sorting.sortOptions[0].name = 'scene_location_name';
        this.sorting.sortOptions[1].name = 'scene_location_name';

        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
            // grid: {
            //     name: 'Grid',
            //         value: 1,
            //         class: 'grid',
            //         isActive: true
            // }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);


        this.has_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];
        if (!this.has_actions_permissions){
            delete this.scene_location_keys_list.actions;
        }


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_scene_locations_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.scene_location_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.scene_location_preferred_sorting;
        }



        this.getList(this.selected_item);
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        performAction(scene_location, action, scene_locationKey){
            if (action === 'edit'){
                this.$refs['add-new-scene_locations'].setSelectedItem(scene_location);
                this.$bvModal.show('create-scene_locations');
            }else if (action === ('delete'+this.permission_name)){
                if (scene_location.id !== '' && scene_location.scene_location_lines_count === 0){
                    this.deleteItemByID(scene_location.id, scene_location.name, this.object_type, scene_location)
                        .then((response) => {
                            if (response.data.data.status){
                                this.scene_locationsData.splice(scene_locationKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, scene_location) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'name'];
                    return this.handleSearch(searchable_values, element, wordToCompare);
                }
                filtered_list = this.scene_locationsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.scene_locationsData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('scene_location_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_scene_locations_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await scene_locationResource.list(required_query);
            this.total = data.meta.total;
            this.scene_locationsData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },

        addNewItem(scene_location_type) {
            this.$refs['add-new-scene_locations'].setSelectedItem(null);
            this.$bvModal.show('create-scene_locations');
        }
    },
}
</script>


