import Resource from '@/api/resource';
const tagsResource = new Resource('api/assetflow/tags');

export default {
    data(){
        return {
            all_tags_list: null,
            is_loading_tags: [],
            listQuery:{},
            current_customer: null,
            current_user_info: null,
            current_sp: null
        }
    },
    created: function () {
        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
            this.current_sp = this.current_user_info.current_sp;
        }

    },
    methods: {
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getTagsList(customer_ids=null, force_allow=false){
            this.listQuery = {};
            this.handleQueryItems();
            this.is_loading_tags = true;
            this.listQuery.is_short_list = true;
            this.listQuery.item_class = 'App\\Asset';

            if (this.object_type && ['managed_storages', 'order_lines'].includes(this.object_type)){
                this.listQuery.limit = -1;
                this.listQuery.customer_ids = customer_ids;
            }

            const { data } = await tagsResource.list(this.listQuery, null, force_allow);

            if (Object.keys(data).includes('data')){
                this.all_tags_list = data.data.items;
            }else{
                console.log('Response Error');
            }
            this.is_loading_tags = false;
        },
        getTagsListFor(asset){
            if (!asset || !this.all_tags_list){
                return null;
            }

            let customer_id;
            if (asset.hasOwnProperty('customer_info') && asset.customer_info){
                customer_id = asset.customer_info.id;
            }else if (asset.hasOwnProperty('customer_id') && asset.customer_id){
                customer_id = asset.customer_id;
            }else{
                return [];
            }
            return this.all_tags_list.filter(e => e.customer_id && customer_id && (e.customer_id === customer_id));
        },
        saveTags(asset, assetKey){
            let message = "Updated Tags List of {"+asset.name+"} Successfully";
            axios({
                method:'post',
                url:'/api/assetflow/assets/save_item_value',
                baseURL: '/',
                data: {
                    item_type: 'assets',
                    item_id: asset.id,
                    key: 'tag_ids',
                    value: asset.tag_ids,
                    is_meta: false
                },
            }).then(response => {
                this.handleResponse(response, message, false);
            }).catch(error => {
                console.log(error);
            });

        },
        async myAddTag(inputAttrs, asset, assetKey, resolve) {
            if (inputAttrs.value){
                const tags_info_index = this.all_tags_list.map(function (e) {
                    return e.name;
                }).indexOf(inputAttrs.value);

                if (tags_info_index !== -1){
                    this.presentToast("Error!",
                        'Tag name already in use',
                        'danger',
                        3000,
                        true,
                        true);
                    return;
                }
            }

            this.isAddingTag = true;
            let params = {
                name: inputAttrs.value,
                description: inputAttrs.value,
                item_class: 'App\\Asset',
                customer_id: asset.hasOwnProperty('customer_id') ? asset.customer_id : ''
            };
            const { data } = await tagsResource.store(params);

            let new_tag = data.data.items;
            let status = data.data.status;


            let item_info = asset;
            if (status){
                if (!item_info.tag_ids){
                    item_info.tag_ids = [];
                }

                if (!item_info.tags_info){
                    item_info.tags_info = [];
                }
                item_info.tag_ids.push(new_tag.id+"");
                item_info.tags_info.push(new_tag);
                if (this.object_type && ['managed_storages', 'order_lines'].includes(this.object_type)){
                    this.$set(this.sortedItemsList[assetKey], 'item_info', item_info);
                }else{
                    this.$set(this.sortedItemsList, assetKey, item_info);
                }

                this.all_tags_list.push(new_tag);
                this.all_tags_list = [... this.all_tags_list];
                resolve(this.all_tags_list);
            }else{
                this.presentToast("Error!",
                    data.data.msg || '',
                    'danger',
                    3000,
                    true,
                    true);
                this.isAddingTag = false;
                return;
            }
            this.isAddingTag = false;
            this.saveTags(item_info, assetKey);
        },
        myRemoveTag(tag_id, asset, assetKey) {

            const tagIndex = this.all_tags_list.map(function (e) {
                return e.id;
            }).indexOf(tag_id);

            let tag = this.all_tags_list[tagIndex];

            if (!tag.id){
                tag = JSON.parse(tag);
            }

            let item_info = asset;
            let older_tags = item_info.tag_ids;
            let older_tags_info = item_info.tags_info;

            if (older_tags && older_tags.includes(tag_id+"")){
                // shall remove it
                tag.is_selected = false;
                const index = older_tags.indexOf(tag.id+"");

                if (index !== -1){
                    older_tags.splice(index, 1);
                }


                const tags_info_index = older_tags_info.map(function (e) {
                    return e.id;
                }).indexOf(tag.id);

                if (tags_info_index !== -1){
                    older_tags_info.splice(tags_info_index, 1);
                }

            }else{
                //shall add it
                tag.is_selected = true;
                if (!older_tags){
                    older_tags = [];
                    older_tags_info = [];
                }

                older_tags.push(tag_id+"");
                older_tags_info.push(tag);

            }

            item_info.tag_ids = older_tags;
            item_info.tags_info = older_tags_info;

            if (this.object_type && ['managed_storages', 'order_lines'].includes(this.object_type)){
                this.$set(this.sortedItemsList[assetKey], 'item_info', item_info);
            }else{
                this.$set(this.sortedItemsList, assetKey, item_info);
            }

            this.all_tags_list[tagIndex] = tag;
            this.saveTags(item_info, assetKey);
        },
    }
}
