<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form.sync="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_structure",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    mixins: [],
    data(){
        return {
            addForm:{
                default_assigned_folders: null,
                assign_folders_by_default: false,
                assets_asset_groups: 1,
                assets_folders: 1,
                assets_tags_categories: 1,
            },
            sidebar_navigation: {
                asset_structure: {
                    label: 'Asset Structure',
                    settings: {
                        assets_asset_groups: {
                            id: 'assets_asset_groups',
                            label: 'Asset Groups',
                            functionality: 'core'
                        },
                        assets_folders: {
                            id: 'assets_folders',
                            label: 'Folders',
                            functionality: 'core',
                            type: 'checkbox',
                            with_assigning:true,
                            status: false,
                            options: [
                                {
                                    id: 0,
                                    name: 'Folder 1'
                                },
                                {
                                    id: 1,
                                    name: 'Folder 2'
                                },
                            ]
                        },
                        assets_tags_categories: {
                            id: 'assets_tags_categories',
                            label: 'Tags & Categories',
                            functionality: 'core'
                        },
                    }
                },
            }
        }
    },
    created() {
        let required_meta_keys = [
            'default_assigned_folders',
            'assign_folders_by_default'
        ];
        for (const key in required_meta_keys) {
            let meta_key = required_meta_keys[key];
            this.addForm[meta_key] = this.settings_dict.hasOwnProperty(meta_key) ? this.settings_dict[meta_key] : 0;
        }
        this.addForm = Object.assign({}, this.addForm);
    }
}
</script>


