<template>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="main_content_block" v-if="item_info_asset_posts && item_info_asset_posts.length > 0">
                <div class="Assets_Timeline asset-passport-activity-block">
                    <div class="Assets_Timeline_title d-sm-flex align-items-center justify-content-between">
                        <h6 class="Post_An_Update_title">Timeline:</h6>

                        <div class="only_image_block">
                            <div class="grid-list-button d-flex align-items-center justify-content-between mb-0">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeTimelineLayout(layout, $event)"
                                    :id="'grid-list-'+layout.class"
                                    :class="layout.class + (layout.isActive ? ' active' : '')"></div>
                            </div>

                        </div>
                    </div>

                    <po-lines v-if="selected_layout && selected_layout === 1"
                              :items="item_info_asset_posts"
                              :current_page_type="item_info.current_page_type"
                              :object_type="'assets'"
                              :keys_list="asset_posts_list_key_list">
                    </po-lines>
                    <!-- Richard Created Asset 54 Html Strat -->
                    <div v-else class="richard-text_block">
                        <div v-for="item_post in item_info_asset_posts" class="personal_details">

                            <div class="media media_b_a">
                                <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                    <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                </div>

                                <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                <div class="media-body personal_media-body">
                                    <h6 class="personal_details_title">
                                        {{ item_post.user.name }} <span>{{ getItemPostTitle(item_info, item_post) }}</span>
                                    </h6>
                                    <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                </div>
                            </div>

                            <my-post-content :item_post="item_post">
                                <template v-slot:post_description="{ item }">
                                    <div>
                                        <div v-if="[2,3].includes(item_post.action_type) && item_post.hasOwnProperty('post_meta')" class="added-asset-info">
                                            <div v-if="Object.keys(item_post.post_meta).length > 3">
                                                <div v-for="(post_meta_key, key) in post_meta_keys"
                                                     :key="key"
                                                     v-if="item_post.post_meta.hasOwnProperty(key)"
                                                >
                                                    <span class="post-text-heading">{{ post_meta_key.label }}</span>
                                                    <span class="post-text-value" v-html="post_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                The record was saved. No changes were detected.
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p v-html="item.description.replace(/ITEM_TYPE/g, item_info.current_page_type)"></p>
                                        </div>
                                    </div>
                                </template>
                            </my-post-content>

                        </div>
                    </div>
                    <!-- Richard Created Asset 54 Html End -->
                </div>
            </div>
            <div class="text-center mt-5" v-else>
                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                <h4>No Activities found here at the moment.</h4>
            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">

            <div class="row" v-if="cAFID_info">
                <NewCustomGenericBlock
                    :block_title_html="getHtmlTitleFor('asset_passport')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <asset-passport-right-box :item_info="item_info"/>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>
    </div>
</template>
<script>
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import AssetPassportRightBox from "./AssetPassportRightBox";
import MyPostContent from "../../PostContentTypes/MyPostContent";
import PoLines from "../../PoLines";
import AddItemPost from "../../AddItemPost";
import NewCustomGenericBlock from "../NewCustomGenericBlock";
export default {
    name: 'AssetPassportActiviyTab',
    components: {NewCustomGenericBlock, AddItemPost, PoLines, MyPostContent, AssetPassportRightBox},
    mixins:[TimelineCommons],
    props:['item_info'],
    data(){
        return {
            post_meta_keys: {
                name:{
                    label: 'Name',
                    cast_value: function (item, key) {
                        return Vue.options.filters.titleize(item[key]);
                    }
                },
                status:{
                    label: 'Status',
                    cast_value: function (item, key) {
                        return Vue.options.filters.titleize(item[key]);
                    }
                },
                quantity:{
                    label: 'Qty',
                    cast_value: function (item, key) {
                        return item[key];
                    }
                },
                internal_reference:{
                    label: 'Ref',
                    cast_value: function (item, key) {
                        return item[key];
                    }
                },
                images_count:{
                    label: 'No. of images',
                    cast_value: function (item, key) {
                        return item[key];
                    }
                },
                asset_usage:{
                    label: 'Asset Usage',
                    cast_value: function (item, key) {
                        return item[key] === 1 ? 'On Camera' : 'Off Camera';
                    }
                },
                is_hero:{
                    label: 'Is Hero',
                    cast_value: function (item, key) {
                        return item[key] === 1 ? 'Yes' : 'No';
                    }
                },
                is_asset:{
                    label: 'Is Asset',
                    cast_value: function (item, key) {
                        return item[key] === 1 ? 'Yes' : 'No';
                    }
                },
                contact_id:{
                    label: 'User',
                    cast_value: function (item, key) {
                        return '<a class="text-dark" href="./users/' + item[key] + '/view">' + Vue.options.filters.titleize(item.contact_name) + '</a>';
                    }
                },
                department_id:{
                    label: 'Department',
                    cast_value: function (item, key) {
                        return '<a class="text-dark" href="./departments/' + item[key] + '/view">' + Vue.options.filters.titleize(item.contact_name) + '</a>';
                    }
                },
                location_id:{
                    label: 'Location',
                    cast_value: function (item, key) {
                        return Vue.options.filters.titleize(item.location_name);
                    }
                },
                final_disposition_planning:{
                    label: 'FDP',
                    cast_value: function (item, key) {
                        return item.final_disposition_planning_text;
                    }

                },
                custom_fields:{
                    label: 'Custom Fields',
                    cast_value: function (item, key) {
                        let html = '<ul>';
                        for (const itemElementKey in item[key]) {
                            let custom_field = item[key][itemElementKey];
                            html += '<li>' + custom_field.field_name + ' <b>' + custom_field.field_value + '</b></li>';
                        }
                        html += '<ul>';
                        return html;
                    }
                },
            },
        }
    },
    computed:{
        item_info_asset_posts(){
            return this.cAFID_info && this.cAFID_info.hasOwnProperty('asset_posts') ? this.cAFID_info.asset_posts : null;
        },
        cAFID_info(){
            if (this.item_info.hasOwnProperty('cAFID_info')){
                return this.item_info.cAFID_info;
            }
            return this.item_info;
        }
    },
    created() {

        let item_info = this.item_info;

        let getItemPostTitle = this.getItemPostTitle;
        this.asset_posts_list_key_list.action_title.value_func = function (index, item, key) {
            return Vue.options.filters.capitalize(getItemPostTitle(item_info, item));
        }

        let user_meta = window.USER_INFO.user_meta;
        if (user_meta.hasOwnProperty('preferred_timeline_layout')) {
            this.selected_layout = window.USER_INFO.user_meta.preferred_timeline_layout;
        }

        if (user_meta.hasOwnProperty('post_update_form_appearance')){
            this.formShown = user_meta.post_update_form_appearance;
        }
    },
    methods:{
    }
}
</script>

<style>
.asset-passport-activity-block{
    margin-top: 0px;
}
</style>
