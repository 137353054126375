<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="character_activity_tab" role="tabpanel" :class="isActiveTab('character_activity_tab')">
                <CharacterActivityTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="character_media_tab" role="tabpanel" :class="isActiveTab('character_media_tab')">
                <MediaTab :item_info="item_info" object_type="characters"/>
            </div>
            <div class="tab-pane" id="character_measurements_tab" role="tabpanel" :class="isActiveTab('character_measurements_tab')">
                <CharacterMeasurementsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="character_assets_tab" role="tabpanel" :class="isActiveTab('character_assets_tab')">
                <CharacterAssetsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="character_scenes_tab" role="tabpanel" :class="isActiveTab('character_scenes_tab')">
                <CharacterScenesTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="character_changes_tab" role="tabpanel" :class="isActiveTab('character_changes_tab')">
                <CharacterChangesTab :item_info="item_info"/>
            </div>
        </div>


    </div>
</template>
<script>

import CharacterActivityTab from "./CharacterActivityTab";
import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";
import CharacterMeasurementsTab from "./CharacterMeasurementsTab";
import CharacterScenesTab from "./CharacterScenesTab";
import CharacterChangesTab from "./CharacterChangesTab";
import CharacterAssetsTab from "./CharacterAssetsTab";

export default {
    name: 'CustomCharacterDetails',
    components: {
        CharacterAssetsTab,
        CharacterChangesTab,
        CharacterScenesTab, CharacterMeasurementsTab, MediaTab, CharacterActivityTab},
    props: ['item_info'],
    data(){
        return {
            active_tab: 'character_activity_tab',
            tabs: {
                'character_activity_tab': {
                    'id': "character_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "character_activity_tab",
                    'isActive': false
                },
                'character_media_tab': {
                    'id': "character_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "character_media_tab",
                    'isActive': false
                },
                'character_measurements_tab': {
                    'id': "character_measurements_tab",
                    'name': "Measurements",
                    'icon': "user",
                    'type': "character_measurements_tab",
                    'isActive': false
                },
                'character_assets_tab': {
                    'id': "character_assets_tab",
                    'name': "Closet",
                    'icon': "user",
                    'type': "character_assets_tab",
                    'isActive': false
                },
                'character_scenes_tab': {
                    'id': "character_scenes_tab",
                    'name': "Scenes",
                    'icon': "user",
                    'type': "character_scenes_tab",
                    'isActive': false
                },
                'character_changes_tab': {
                    'id': "character_changes_tab",
                    'name': "Changes",
                    'icon': "user",
                    'type': "character_changes_tab",
                    'isActive': false
                }
            },
        }
    },
    created() {
        let characters_active_tab = localStorage.getItem('characters_active_tab');

        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'activity':
                    this.active_tab = 'character_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'character_media_tab';
                    break;
                case 'measurements':
                    this.active_tab = 'character_measurements_tab';
                    break;
                case 'scenes':
                    this.active_tab = 'character_scenes_tab';
                    break;
                case 'closet':
                case 'assets':
                    this.active_tab = 'character_assets_tab';
                    break;
                case 'changes':
                    this.active_tab = 'character_changes_tab';
                    break;
            }
        }else if (characters_active_tab && characters_active_tab !== ''){
            this.active_tab = characters_active_tab;
        }

    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('characters_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
