export const OrderLinesSortOptions = [
    {
        value: 1,
        text: 'A~Z',
        name: 'name',
        reversed: false
    },
    {
        value: 2,
        text: 'Z~A',
        name: 'name',
        reversed: true
    },
    {
        value: 3,
        text: 'Status',
        name: 'status',
        reversed: false,
        first_criteria: 'is_default',
        first_reversed: true,
        second_criteria: 'name',
        second_reversed: false
    },
    {
        value: 4,
        text: 'Created Date: New to Old',
        name: 'created_at',
        reversed: true
    },
    {
        value: 5,
        text: 'Created Date: Old to New',
        name: 'created_at',
        reversed: false
    },
    {
        value: 6,
        text: 'Modified Date: New to Old',
        name: 'updated_at',
        reversed: true
    },
    {
        value: 7,
        text: 'Modified Date: Old to New',
        name: 'updated_at',
        reversed: false
    },
    {
        value: 8,
        text: 'Storage ID: Ascending',
        name: 'sp_storages.id',
        reversed: false
    },
    {
        value: 9,
        text: 'Storage ID: Descending',
        name: 'sp_storages.id',
        reversed: true
    },
    {
        value: 10,
        text: 'Storage Name: A~Z',
        name: 'sp_storages.name',
        reversed: false
    },
    {
        value: 11,
        text: 'Storage Name: Z~A',
        name: 'sp_storages.name',
        reversed: true
    },
    {
        value: 12,
        text: 'Location ID: Ascending',
        name: 'sp_locations.id',
        reversed: false
    },
    {
        value: 13,
        text: 'Location ID: Descending',
        name: 'sp_locations.id',
        reversed: true
    },
    {
        value: 14,
        text: 'Location Name: A~Z',
        name: 'sp_locations.name',
        reversed: false
    },
    {
        value: 15,
        text: 'Location Name: Z~A',
        name: 'sp_locations.name',
        reversed: true
    },
];
