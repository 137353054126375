<template>
    <div>
        <!-- Storage Template -->
        <div class="storage-template">
            <div class="row mts-orders-block">

                <NewCustomGenericBlock
                    block_title="Move To Storage Orders"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <div class="col-12">
                            <po-lines
                                :items="item_info.mts_orders"
                                :current_page_type="item_info.current_page_type"
                                :object_type="object_type"
                                :keys_list="order_list_keys_list">
                                <template v-slot:orders_actions="{ item, index }">
                                    <OrderActionsList
                                        :index="index"
                                        :item="item"
                                        :item_type="item_info.current_page_type"
                                        :object_type="object_type"
                                        :perform-action="performAction"
                                        :permissions_list="permissions_list"
                                    />
                                </template>
                            </po-lines>

                        </div>
                    </template>
                </NewCustomGenericBlock>

            </div>

            <div class="row storage-orders-block">
                <NewCustomGenericBlock
                    block_title="Storage Orders"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <div class="col-12">
                            <po-lines
                                :items="item_info.storage_orders"
                                :current_page_type="item_info.current_page_type"
                                :object_type="object_type"
                                :keys_list="order_list_keys_list">
                                <template v-slot:orders_actions="{ item, index }">

                                    <OrderActionsList
                                        :index="index"
                                        :item="item"
                                        :item_type="item_info.current_page_type"
                                        :object_type="object_type"
                                        :perform-action="performAction"
                                        :permissions_list="permissions_list"
                                    />

                                </template>
                            </po-lines>
                        </div>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>
        <!-- Storage Template Close-->
    </div>

</template>

<script>

import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";

import PoLines from "../../items/widgets/detailsViews/PoLines";
import {OrderKeysList} from "@/views/assetflow/form_data/OrderKeysList";
import NewCustomGenericBlock from "../widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import OrderActionsList from "../../customers/orders/OrderActionsList";

export default {
    mixins:[AssetTabsShared],
    name: "ManagedStorageOrdersInfo",
    components: {
        OrderActionsList,
        PoLines,
        NewCustomGenericBlock,
    },
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            permissions_list: {},
            object_type: 'orders',
            order_list_keys_list: OrderKeysList,
        }
    },
    created() {

        this.permissions_list['view_' + this.object_type] = false; // this.hasPermission('browse_' + this.object_type);
        this.permissions_list['add_' + this.object_type] = this.hasPermission('add_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);
        this.permissions_list['browse_users'] = this.hasPermission('browse_users');
        this.permissions_list['manage_orders'] = this.hasPermission('manage_orders');

        this.has_order_actions_permissions = this.permissions_list['view_' + this.object_type] || this.permissions_list['edit_' + this.object_type] || this.permissions_list['browse_users'] || this.permissions_list['manage_orders'] || this.permissions_list['delete_' + this.object_type];

        if (this.has_order_actions_permissions){
            this.order_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'orders_actions'
            }
        }

        if (this.item_info.current_page_type === 'sp'){
            if (this.is_tv_film_asset){
                this.order_list_keys_list.customer_info.label = 'Production';
            }
            delete this.order_list_keys_list.sp_info;
        }else if (this.item_info.current_page_type === 'customer') {
            delete this.order_list_keys_list.customer_info;
        }
    },
    methods:{
        performAction(order, action, orderKey, e){
            if (action === 'edit'){
                // shall edit?
                console.log("Shall Edit");
            }else if (action === ('delete'+this.object_type)){
                if (order.id !== ''){
                    this.deleteItemByID(order.id, order.name, this.object_type, order)
                        .then((response) => {
                            if (response.data.data.status){
                                this.ordersData.splice(orderKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
    }
}
</script>
