<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <form>
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Basic Information</h4>
                            <p class="card-title-desc">Fill all information below</p>


                            <div class="row">
                                <div class="col-sm-6">

                                    <b-form-group label="Initials" description="Leave empty to auto generate initials." label-for="initials">
                                        <b-form-input id="initials" v-model="addForm.initials"></b-form-input>
                                    </b-form-group>

                                    <div class="form-group">
                                        <label for="storage_provider_name">Service Provider Name</label>
                                        <input v-model="addForm.name" id="storage_provider_name" name="storage_provider_name" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="co_status">Status</label>
                                        <b-form-select
                                            id="co_status"
                                            v-model="addForm.status"
                                            :options="this.statuses"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </div>


                                    <div class="form-group">
                                        <label for="contact">Contact</label>
                                        <input v-model="addForm.contact" id="contact" name="contact" type="text" class="form-control" />
                                    </div>


                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input v-model="addForm.phone" id="phone" name="phone" type="text" class="form-control" />
                                    </div>


                                    <div class="form-group">
                                        <label for="website">Website</label>
                                        <input v-model="addForm.website" id="website" name="website" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="company_registration_no">Company Registration No</label>
                                        <input v-model="addForm.company_registration_no" id="company_registration_no" name="company_registration_no" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="vat_registration_no">VAT Registration No</label>
                                        <input v-model="addForm.vat_registration_no" id="vat_registration_no" name="vat_registration_no" type="text" class="form-control" />
                                    </div>


                                    <div class="form-group">
                                        <label>Demo Mode</label>
                                        <b-form-radio-group v-model="addForm.demo_only">
                                            <b-form-radio
                                                v-for="(option, key) in statuses"
                                                :key="option.value"
                                                :value="option.value"
                                                class="custom-radio mb-3"
                                            >{{ option.text | titleize }}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </div>

                                </div>


                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="logo">Logo</label>
                                        <div class="col-sm-6">
                                            <vue-dropzone id="logo" ref="myVueDropzone" :use-custom-slot="true"
                                                          :options="dropzoneOptions"
                                                          @vdropzone-file-added="fileAdded"
                                                          @vdropzone-max-files-exceeded="maxFilesExceeded"
                                            >
                                                <div class="dropzone-custom-content">
                                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                            </vue-dropzone>
                                            <b-button
                                                v-if="action === 'edit'"
                                                @click="$refs.myVueDropzone.$el.click();"
                                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                            >Select New Logo</b-button>
                                            <b-button
                                                @click="$refs.myVueDropzone.removeAllFiles();"
                                                variant="outline-danger"
                                                class="btn btn-sm mt-1 btn-block"
                                            >Remove Selected</b-button>
                                        </div>
                                        <div class="col-sm-6">
                                            <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px; width: 100%; margin-top: 8px;">
                                                <p><strong>Logo Dimensions</strong></p>
                                                <br>
                                                <p>Logos should be uploaded in the ratio 3:1.</p>
                                                <br>
                                                <p>For example, 3000 pixels wide by 1000 pixels high. Logos will be scaled automatically. Logos outside these dimensions maybe truncated.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Appearance, Functionality & Defaults</h4>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div v-for="(item, key) in addForm.meta">
                                        <div class="form-group">
                                            <label :for="'item_' + key">{{key | titleize}}</label>

                                            <b-form-select v-if="key === 'currency'"
                                                           id="currency"
                                                           v-model="addForm.meta[key]"
                                                           :options="currencies"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <input v-else v-model="addForm.meta[key]" :id="'item_' + key" :name="'item_' + key" :type="((key.includes('colour') || key === 'logo_background') ? 'color' : 'text')" class="form-control" />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                            <a href="./" class="btn btn-secondary">Cancel</a>

                        </div>
                    </div>


                </form>


            </div>
        </div>
    </Layout>
</template>


<script>

    import vue2Dropzone from "vue2-dropzone";
    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import Multiselect from 'vue-multiselect';

    import Resource from '@/api/resource';
    import { DropZoneOptions } from "../form_data/DropZoneOptions";
    import {Currencies} from "../form_data/Currencies";

    const storage_providerResource = new Resource('api/assetflow/service_providers');


    export default {
        props:['item_id', 'item_type', 'action'],
        name: 'NewCustomer',
        components: {
            vueDropzone: vue2Dropzone,
            Layout,
            PageHeader,
            Multiselect
        },
        data() {
            return {
                title: "Add New Service Provider",
                items: [
                    {
                        text: "Service Providers",
                        href: "/"
                    },
                    {
                        text: "Add Service Provider",
                        active: true
                    }
                ],
                statuses:[
                    {
                        value: 0,
                        text: "Disabled",
                    },
                    {
                        value: 1,
                        text: "Active",
                    }
                ],
                addForm:{
                    initials: "",
                    name: "",
                    logo: "",
                    status: 1,
                    contact: "",
                    phone: "",
                    website: "",
                    company_registration_no: "",
                    vat_registration_no: "",
                    demo_only: 1,
                    meta:[]
                },
                dropzoneOptions: DropZoneOptions,
                isLoadingItem: false,
                shall_select_service_providers: false,
                currencies: Currencies,
            };
        },
        created() {
            if(this.action === 'edit' && this.item_id !== ''){
                this.fetchInfoOf();
                this.title = this.title.replace('Add New', 'Edit');
                this.items[1].text = this.items[1].text.replace('Add', 'Edit');
            }
        },
        methods: {
            fileAdded(file) {
                this.addForm.logo = file;
                this.saveFileToS3(file, null, 'logo', 'sp');
            },
            maxFilesExceeded(file){
                this.$refs.myVueDropzone.removeAllFiles();
                this.$refs.myVueDropzone.addFile(file);
            },
            fetchInfoOf() {
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/service_providers/' + this.item_id,
                    baseURL: '/',
                }).then(response => {
                    var storage_provider_info = response.data;

                    this.addForm.initials = storage_provider_info.initials;
                    this.addForm.name = storage_provider_info.name;
                    this.addForm.logo = storage_provider_info.logo;
                    this.addForm.status = storage_provider_info.status;
                    this.addForm.demo_only = storage_provider_info.demo_only;

                    if (storage_provider_info.logo){
                        this.addForm.logo = storage_provider_info.logo;

                        var file = { size: storage_provider_info.image_size, name: storage_provider_info.name, type: "image/png" };
                        var url = this.addForm.logo;
                        if (storage_provider_info.image_size){
                            this.$refs.myVueDropzone.manuallyAddFile(file, url);
                        }
                    }

                    this.addForm.contact = storage_provider_info.contact;
                    this.addForm.phone = storage_provider_info.phone;
                    this.addForm.website = storage_provider_info.website;
                    this.addForm.company_registration_no = storage_provider_info.company_registration_no;
                    this.addForm.vat_registration_no = storage_provider_info.vat_registration_no;
                    this.addForm.meta = storage_provider_info.meta;

                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });

            },

            addNew(event) {
                if (event && !this.isSendingRequest) {
                    this.isSendingRequest = true;
                    console.log(this.addForm);

                    axios({
                        method:((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                        url:'/api/assetflow/service_providers' + ((this.action === 'edit' && this.item_id !== '') ? ('/'+this.item_id) : ''),
                        baseURL: '/',
                        data: {
                            storage_provider_id: this.item_id,
                            initials: this.addForm.initials,
                            storage_provider_name: this.addForm.name,
                            add_to_bss: this.addForm.add_to_bss,
                            item_type: this.item_type,
                            status: this.addForm.status,
                            contact: this.addForm.contact,
                            phone: this.addForm.phone,
                            website: this.addForm.website,
                            demo_only: this.addForm.demo_only,
                            company_registration_no: this.addForm.company_registration_no,
                            vat_registration_no: this.addForm.vat_registration_no,
                            meta: this.addForm.meta,
                            logo: this.addForm.logo
                        },
                    }).then(response => {
                        this.isSendingRequest = false;
                        if (response.data.data.status === true){
                            window.location.href = './';
                        }else{
                            console.log('Something went wrong');
                        }

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });

                }
            }
        },

    }
</script>

<style scoped>

#logo .dz-preview .dz-progress {
    opacity: 0 !important;
}

.vue-dropzone > .dz-preview .dz-image {
    height: 160px !important;
    width: 160px !important;
}

.vue-dropzone .dz-preview .dz-details .dz-size {
    display: none
}
</style>
