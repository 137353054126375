<script>
    import Layout from "../../layouts/main";
    import PageHeader from "../../components/page-header";
    export default {
        name: "PlaceholderPage",
        components: {
            Layout,
            PageHeader
        },
        props: ['item_id', 'item_type', 'action', 'other_item_id'],
        data() {
            return {
                timer: 10,
                title: "Asset Flow",
                items: [{
                    text: "Dashboards",
                    href: "javascript:void(0)"
                },
                    {
                        text: "Asset Flow",
                        active: true
                    }
                ],
            };
        },

        methods: {
            startTimer() {
                const countdown = setInterval(() => {
                    this.timer--;
                    if (this.timer <= 0) {
                        clearInterval(countdown);
                        if (!this.other_item_id) {
                            location.reload(); // Reload the page
                        }
                    }
                }, 1000); // Update every second
            },
        },
        mounted() {
            this.startTimer();
        },
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mb-5">
                            <h1 class="display-2 font-weight-medium">
                                <i class="bx text-primary display-3 text-af-accent" :class="other_item_id ? 'bx-check' : 'bx-loader bx-spin'"></i>
                            </h1>

                            <h4 v-if="other_item_id">The file <strong>{{ item_id }}</strong> is ready.</h4>
                            <h4 v-else>The file <strong>{{ item_id }}</strong> is not currently available.</h4>
                            
                            <p v-if="!other_item_id" class="mt-3 file-note">It is still being processed or might have been removed.<br>Please wait and refresh the page or check the status from <a :href="'/' + item_type + '/files'">Files</a></p>
                            <p v-if="!other_item_id" class="mt-3 reloading-note">Reloading page in {{ timer }} seconds...</p>
                            
                            <div class="mt-5 text-center">
                                <a class="btn btn-sm af-btn-accent af-accent" onclick="window.history.back(); return false;">Back</a>
                                <a class="btn btn-sm af-btn-accent af-accent" :href="'/' + item_type + '/files'">Files</a>
                                <a v-if="other_item_id" class="btn btn-sm af-btn-accent af-accent" :href="other_item_id">{{ action | titleize }}</a>
                                <a v-else class="btn btn-sm af-btn-accent af-accent" onclick="window.location.reload()">Refresh</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>
