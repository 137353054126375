<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    placeholder="Select Status"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-event')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const eventResource = new Resource('api/assetflow/events');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    components: {DatePicker},
    name: "AddNewEvent",
    props: ['modal_title', 'item_type', 'events_options', 'shall_assign_to', 'shall_assign_to_id'],
    data(){
        return {
            addForm:{
                name: '',
                status:null,
                start_date: '',
                finish_date: ''
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Event Name',
                    type: 'text'
                },
                status:{
                    key: 'status',
                    label: 'Status',
                    type: 'single-select',
                    options: [
                        {
                            id: 0,
                            name: 'Inactive'
                        },
                        {
                            id: 1,
                            name: 'Active'
                        },
                    ]
                },
                start_date: {
                    key: 'start_date',
                    label: 'Start Date',
                    type: 'date'
                },
                finish_date: {
                    key: 'finish_date',
                    label: 'Finish Date',
                    type: 'date'
                }
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                let status_id = this.selected_item.status;
                let found_item = _.find(this.required_items.status.options, function(o) { return o.id === status_id; });
                if (found_item){
                    this.addForm.status = found_item;
                }else{
                    this.addForm.status = null;
                }
                if (this.selected_item.hasOwnProperty('start_date') && this.selected_item.start_date !== ''){
                    this.addForm.start_date = Vue.options.filters.moment(this.selected_item.start_date, 'DD-MM-YYYY');
                }else{
                    this.addForm.start_date = '';
                }

                if (this.selected_item.hasOwnProperty('finish_date') && this.selected_item.finish_date !== ''){
                    this.addForm.finish_date = Vue.options.filters.moment(this.selected_item.finish_date, 'DD-MM-YYYY');
                }else{
                    this.addForm.finish_date = '';
                }

            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                status: this.addForm.status ? this.addForm.status.id : 1,
                start_date: this.addForm.start_date,
                finish_date: this.addForm.finish_date,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }
            if (this.shall_assign_to){
                params.assign_item_type = this.shall_assign_to;
                params.assign_to_id = this.shall_assign_to_id;
            }

            const { data } = await eventResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-event');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


