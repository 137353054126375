<template>
    <SharedFormFieldsMultipleSections :add-form="addForm" :item_type="item_type" :required_fields="required_fields" :section_fields="section_fields" :object_type="object_type"/>
</template>

<script>

import SharedFormFieldsMultipleSections from "./SharedFormFieldsMultipleSections";
export default {
    name: "SupplierStep",
    components: {SharedFormFieldsMultipleSections},
    mixins:[],
    props:['item_type', 'addForm', 'required_fields', 'object_type'],
    data(){
        return {
            section_fields:{
                supplier_info:{
                    name: 'Supplier',
                    minimized: false,
                    fields: [
                        'supplier_id',
                        'purchase_order_id',
                        'supplier_reference'
                    ]
                },
            }
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
    },
    watch: {
    },
    methods:{

    }
}
</script>
