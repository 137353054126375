<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">


                <form>
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Basic Information</h4>
                            <p class="card-title-desc">Fill all information below</p>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label for="rolename">Role Name</label>
                                            <input v-model="addForm.role_name" id="rolename" name="rolename" type="text" class="form-control" />
                                        </div>

                                        <div class="form-group">
                                            <label for="role_description">Role Description</label>
                                            <input v-model="addForm.description" id="role_description" name="role_description" type="text" class="form-control" />
                                        </div>



                                        <div class="form-group">
                                            <label for="roletype">Sharing Scope</label>
                                            <select v-model="addForm.sharing_type" id="sharingscope" name="sharingscope" class="custom-select">
                                                <option value="">None</option>
                                                <option value="customer">Customer</option>
                                                <option value="group">Group</option>
                                                <option value="sp">Service Provider</option>
                                                <option value="assetflow">Assetflow</option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label for="roletype">Role Type</label>
                                            <select v-model="addForm.role_type" id="roletype" name="roletype" class="custom-select" @change="getPermissionsList">
                                                <option value="customer">Customer</option>
                                                <option value="group">Group</option>
                                                <option value="sp">Service Provider</option>
                                                <option value="assetflow">Assetflow</option>
                                                <option value="bss">Marketplace</option>
                                            </select>
                                        </div>


                                        <b-form-group label="Module" label-for="modules_list">
                                            <b-form-select
                                                id="modules_list"
                                                name="modules_list"
                                                :disabled="modulesListLoading"
                                                v-model="addForm.selected_module"
                                                :options="modules_list"
                                                value-field="id"
                                                text-field="name"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>


                                        <div class="form-group">
                                            <b-form-checkbox
                                                v-model="addForm.is_default"
                                                unchecked-value="0"
                                                class="custom-checkbox mb-3"
                                            >Is Default Role?</b-form-checkbox>
                                        </div>

                                    </div>
                                </div>

                            <a v-if="!this.can_see_permissions_list" v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                            <a v-if="!this.can_see_permissions_list" href="./" class="btn btn-secondary">Cancel</a>


                        </div>
                    </div>

                    <div v-if="this.can_see_permissions_list" class="card">
                        <div class="card-body">
                            <h4 class="card-title">{{addForm.role_type | titleize}} Permissions List</h4>
                            <p class="card-title-desc">Select the required permissions for {{addForm.role_type | titleize}}</p>

                            <div v-if="permissions_list" class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <b-form-checkbox-group stacked id="checkbox-group" v-model="selected_permissions" name="selectedUsers">
                                            <ul v-for="(group, key) in this.permissions_list">
                                                <li>
                                                    <p>{{(key !== '' ? key : 'Admin') | titleize}}</p>
                                                    <b-form-checkbox v-for="(permission, index) in group"
                                                                     :key="index"
                                                                     :value="permission.id"
                                                                     unchecked-value="false"
                                                                     class="custom-checkbox-af-accent mb-2"
                                                    >
                                                        {{permission.display_name}}
                                                    </b-form-checkbox>
                                                </li>
                                            </ul>

                                        </b-form-checkbox-group>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="col-sm-12 mb-3">
                                    <ul>
                                        <li>
                                            Loading..
                                        </li>
                                    </ul>
                                    <b-spinner class="text-af-accent m-2"></b-spinner>
                                </div>
                            </div>

                            <a v-on:click="addNew($event)" class="btn af-btn-accent af-accent">Save</a>
                            <a href="./" class="btn btn-secondary">Cancel</a>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Layout>
</template>



<script>

    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import Resource from '@/api/resource';

    export default {
        props: ['item_id', 'item_type', 'action'],
        name: 'NewRole',
        components: { Layout, PageHeader },
        data() {
            return {
                title: "Add New Role",
                items: [
                    {
                        text: "Roles",
                        href: "/"
                    },
                    {
                        text: "Add Role",
                        active: true
                    }
                ],
                isSendingRequest: false,
                addForm:{
                    role_name: "",
                    description: "",
                    is_default: false,
                    role_type: "customer",
                    sharing_type: "",
                    selected_module: ""
                },
                modules_list: [],
                modulesListLoading: false,
                isLoadingItemPerms: false,
                permissions_list: null,
                selected_permissions: [],
                can_see_permissions_list: true
            };
        },
        created() {
            // console.log(this.item_type);
            // console.log(this.action);
            // console.log(this.item_id);

            this.getModulesList(this.addForm.role_type);
            if(this.action === 'edit' && this.item_id !== ''){
                this.fetchInfoOf();
                this.title = this.title.replace('Add New', 'Edit');
                this.items[1].text = this.items[1].text.replace('Add', 'Edit');
            }else{
                if (this.can_see_permissions_list){
                    this.getPermissionsList();
                }
            }
        },
        methods: {
            async getModulesList(item_type) {
                this.modulesListLoading = true;
                let listQuery = {
                    item_type: item_type
                }
                const moduleResource = new Resource('api/assetflow/modules');
                const { data } = await moduleResource.list(listQuery);

                this.modules_list =  data.data.items;
                this.modulesListLoading = false;
            },
            fetchInfoOf() {
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/roles/' + this.item_id,
                    baseURL: '/',
                }).then(response => {
                    var role_info = response.data;
                    this.addForm.role_name = role_info.name;
                    this.addForm.description = role_info.description;
                    this.addForm.is_default = role_info.is_default === 1;
                    this.addForm.role_type = role_info.role_type;
                    this.addForm.sharing_type = role_info.sharing_type;
                    this.addForm.selected_module = role_info.module_id;

                    this.isLoadingItem = false;

                    if (this.can_see_permissions_list){
                        this.getPermissionsList();
                    }

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            },
            addNew(event) {
                if (event && !this.isSendingRequest) {
                    this.isSendingRequest = true;
                    axios({
                        method:((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                        url:'/api/assetflow/roles' + (this.item_id !== '' ? ('/'+this.item_id) : ''),
                        baseURL: '/',
                        data: {
                            role_id: this.item_id,
                            role_name: this.addForm.role_name,
                            description: this.addForm.description,
                            is_default: this.addForm.is_default,
                            item_type: this.item_type,
                            selected_permissions : this.can_see_permissions_list ? JSON.stringify(this.selected_permissions) : null,
                            role_type: this.addForm.role_type,
                            module_id: this.addForm.selected_module,
                            sharing_type: this.addForm.sharing_type
                        },
                    }).then(response => {
                        this.isSendingRequest = false;
                        if (response.data.data.status === true){
                            window.location.href = './';
                        }else{
                            console.log('Something went wrong');
                        }

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });

                }
            },
            getPermissionsList(){
                this.getModulesList(this.addForm.role_type);
                this.permissions_list = null;
                axios({
                    method:'get',
                    url:'/api/assetflow/permissions?role_type=' + this.addForm.role_type + (this.item_id ? ('&role_id='+this.item_id) : ''),
                    baseURL: '/',
                }).then(response => {
                    this.permissions_list = response.data.data.items;
                    if (response.data.data.status === false){
                        this.can_see_permissions_list = false;
                        this.isLoadingItemPerms = false;
                        return;
                    }

                    var all_permissions = [].concat.apply([], Object.values(this.permissions_list));

                    this.selected_permissions = all_permissions.filter( permission => {
                        return permission.is_selected === true;
                    }).map(x => x.id);

                    this.isLoadingItemPerms = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            },
            printAllPermissions(perm_id){
                console.log(this.permissions_list);
                return this.selected_permissions.includes(perm_id);
            }
        },
        watch: {
            selected_permissions: {
                deep: true,
                handler: function(newValue){
                    // console.log("Selected Permissions changed", newValue);
                }
            }
        },

    }
</script>


