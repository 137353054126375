<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="resetModalValues"
             hide-footer>

        <template #modal-title>
            <div v-html="current_modal_title"></div>
        </template>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Park Storage in {' + current_modal_title + '}') : 'Park Storage'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFields from "../extras/ChangeCommonFields";

import {getLocationDetailsTextOnly} from "@/views/assetflow/form_data/SpLocationTextOnly";
export default {
    name: "ParkStorageInLocation",
    props: ['modal_id', 'modal_title', 'item_type'],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                sp_warehouse_id: null,
                space_id: null,
                storage_id: null,
                show_parked_storage: null,
                space_assigned_at: null,
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Park Storage',
                    required_items:{
                        show_parked_storage: {
                            key: 'show_parked_storage',
                            label: 'Show Parked Storage',
                            type: 'radio-select',
                            options:[
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                }
                            ]
                        },
                        sp_warehouse_id: {
                            key: 'sp_warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select-label',
                            action_text: 'Change Warehouse',
                            options:[]
                        },
                        space_id: {
                            key: 'space_id',
                            label: 'Space',
                            type: 'single-select-label',
                            action_text: 'Change Space',
                            options:[]
                        },
                        storage_id: {
                            key: 'storage_id',
                            label: 'Storage',
                            type: 'single-select',
                            options:[]
                        },
                        space_assigned_at: {
                            key: 'space_assigned_at',
                            label: 'Park Date',
                            type: 'datetime'
                        }
                    },
                },
            },
            selected_item: null,
            lists_values: {
            }
        }
    },
    created() {

        this.required_sections.storage_setup.required_items.storage_id.custom_html_slot = true;

        this.required_sections.storage_setup.required_items.storage_id.custom_label = function (storage_info){
            return storage_info.name;
        };
    },
    computed:{
        show_parked_storage(){
            return this.addForm.show_parked_storage;
        },
        sp_warehouse_id(){
            return this.addForm.sp_warehouse_id;
        },
        space_id(){
            return this.addForm.space_id;
        },
        current_modal_title(){
            return this.selected_item ? getLocationDetailsTextOnly(this.selected_item, true, true, true) : this.modal_title;
        }
    },
    watch:{
        show_parked_storage: {
            deep: true,
            handler: function (newValue, oldVal) {
                // shall filter storages list
                this.getStoragesList(this.show_parked_storage.id);
            }
        },
        sp_warehouse_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue){
                    this.filterSpacesOptions();
                }else{
                    this.resetStoragesList();
                }
            }
        },
        space_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue){
                    this.getStoragesList(this.show_parked_storage.id);
                }else{
                    this.resetStoragesList();
                }
            }
        },
    },
    methods:{
        resetStoragesList() {
            let msg = '';
            if (!this.addForm.sp_warehouse_id){
                msg = 'Warehouse';
            }

            if (!this.addForm.space_id){
                msg = msg === '' ? 'Space' : 'Warehouse & Space';
            }

            this.required_sections.storage_setup.required_items.storage_id.options = [
                {
                    id: null,
                    name: 'Please select a ' + msg
                }
            ];
        },
        setStorageOptions() {
            if (this.lists_values.hasOwnProperty('storage_id')) {
                if (this.addForm.show_parked_storage && this.addForm.show_parked_storage.id === 1) {
                    this.required_sections.storage_setup.required_items.storage_id.options = this.lists_values.storage_id;

                    if (this.selected_item && this.selected_item.hasOwnProperty('current_storage_id') && this.selected_item.current_storage_id){
                        this.addForm.storage_id = this.getObjectOf(this.selected_item.current_storage_id, this.required_sections.storage_setup.required_items.storage_id.options);
                    }else{
                        this.addForm.storage_id = null;
                    }
                } else {
                    this.required_sections.storage_setup.required_items.storage_id.options = this.lists_values.storage_id.filter(storage => storage.location_status === 0);
                    this.addForm.storage_id = null;
                }


            }
        },
        filterSpacesOptions() {
            if (this.lists_values.hasOwnProperty('space_id')) {
                let warehouse_info = this.addForm.sp_warehouse_id;
                if (warehouse_info && warehouse_info.id) {
                    this.required_sections.storage_setup.required_items.space_id.options = this.lists_values.space_id.filter(sp_space => sp_space.sp_warehouse_id === warehouse_info.id);
                    let space_id = this.getObjectOf(this.selected_item.space_id, this.required_sections.storage_setup.required_items.space_id.options);

                    this.addForm.space_id = space_id;
                    if (!space_id){
                        this.required_sections.storage_setup.required_items.space_id.type = 'toggle-single-select';
                    }

                } else {
                    this.required_sections.storage_setup.required_items.space_id.options = [];
                    this.addForm.space_id = null;
                }
            }
        },
        async getStoragesList(show_parked_storage=0) {

            this.required_sections.storage_setup.required_items.storage_id.listLoading = true;
            let listQuery = {
                item_type: this.item_type,
                is_short_list: 1,
                item_id: this.item_id,
                current_warehouse_id: this.addForm.sp_warehouse_id ? this.addForm.sp_warehouse_id.id : null,
                current_space_id: this.addForm.space_id ? this.addForm.space_id.id : null,
                sort_by: 'name',
                sort_dir: 'asc'
            }

            if (show_parked_storage === 0){
                listQuery.location_status = show_parked_storage;
            }

            const storageResource = new Resource('api/assetflow/sp_storages');
            const { data } = await storageResource.list(listQuery);

            this.lists_values.storage_id = data.data.items;
            this.setStorageOptions();
            this.required_sections.storage_setup.required_items.storage_id.listLoading = false;

        },
        async resetModalValues() {
            this.required_sections.storage_setup.required_items.sp_warehouse_id.type = 'single-select-label';
            this.required_sections.storage_setup.required_items.space_id.type = 'single-select-label';
            await this.getFieldsList();
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                if (this.selected_item.hasOwnProperty('current_warehouse_info')){
                    this.addForm.sp_warehouse_id = this.selected_item.current_warehouse_info;
                }
                if (this.selected_item.hasOwnProperty('current_space_info')){
                    this.addForm.space_id = this.selected_item.current_space_info;
                }

                if (this.selected_item.current_storage_id){
                    this.addForm.show_parked_storage = this.required_sections.storage_setup.required_items.show_parked_storage.options[0];
                }

                let space_assigned_at = this.selected_item.space_assigned_at ? this.selected_item.space_assigned_at : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');
                this.$set(this.addForm, 'space_assigned_at', space_assigned_at);

            }
        },
        setSelectedItem(item){
            this.addForm.show_parked_storage = this.required_sections.storage_setup.required_items.show_parked_storage.options[1];
            this.$set(this.addForm, 'space_assigned_at', Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm'));
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        async getFieldsList(){
            this.required_sections.storage_setup.required_items.sp_warehouse_id.listLoading = true;
            this.required_sections.storage_setup.required_items.space_id.listLoading = true;

            let required_select_lists =  {
                sp_warehouse_id: 'sp_warehouses_list',
                space_id: 'sp_spaces_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_space_modal',
                    object_type: 'sp_spaces',
                    action: 'new_sp_space',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                let current_warehouse_options = this.lists_values.sp_warehouse_id;
                this.required_sections.storage_setup.required_items.sp_warehouse_id.options = current_warehouse_options;
                this.addForm.sp_warehouse_id = this.getObjectOf(this.selected_item.sp_warehouse_id, current_warehouse_options);
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            }).finally(() => {
                this.required_sections.storage_setup.required_items.sp_warehouse_id.listLoading = false;
                this.required_sections.storage_setup.required_items.space_id.listLoading = false;
            });
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                sp_warehouse_id: this.addForm.sp_warehouse_id ? this.addForm.sp_warehouse_id.id : null,
                space_id: this.addForm.space_id ? this.addForm.space_id.id : null,
                location_id: this.selected_item.id,
                space_assigned_at: this.addForm.space_assigned_at,
                return_object: this.item_type,
                action: 'assign_location'
            };
            if (this.selected_item){
                params.item_id = this.addForm.storage_id ? this.addForm.storage_id.id : null;
            }

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Parked Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-park-storage', this.modal_id, new_item);
        }
    }
}
</script>


