export default {
    data() {
        return {
            enabling_options:  [
                { id: 1, name: 'Enabled'},
                { id: 0, name: 'Disabled'}
            ],
            enabled_features:[],
            available_features: {
                '6' : 0,
                '7' : 0,
                '8' : 0,
                '9' : 0,
                '10' : 0,
                '11' : 0,
                '12' : 0,
                '13' : 0,
                '14' : 0,
                '15' : 0,
                '16' : 0,
                '17' : 0,
                '18' : 0,
                '19' : 0,
                '20' : 0,
                '21' : 0,
                '22' : 0,
            }
        };
    },
    created() {

    },
    methods: {
        getEnabledFeatures: function (af) {
            let enabled_features = [];
            for (const afKey in af) {
                let afValue = af[afKey];
                let af_keys = Object.keys(this.available_features);
                if (afValue === 1 && af_keys.includes(afKey)) {
                    enabled_features.push(afKey);
                }
            }
            return enabled_features;
        },
        shallSaveMeta(meta_key, value=null, with_reload=false){
            if (!value){
                value = this.addForm[meta_key];
            }
            this.set_item_meta('customers', meta_key, value, 'data.msg', with_reload);
        },
        shallSaveMetas(meta_object=null, with_reload=false){
            this.set_item_metas('customers', meta_object, 'data.msg', with_reload);
        },
        saveSettings(settings_object=null, with_reload=false){
            this.save_settings('customers', settings_object, 'data.msg', with_reload);
        },
        get_asset_types: function (value) {
            let asset_types = [
                'Asset',
                'Consumable',
                'Digital',
                'Capital Asset'
            ]
            if (value){
                let selected_types = value.split(',');
                let required_asset_types = [];
                for (const selectedTypesKey in selected_types) {
                    let value = selected_types[selectedTypesKey];
                    if (value === '1') {
                        required_asset_types.push(asset_types[selectedTypesKey]);
                    }
                }
                return required_asset_types.join(', ');
            }
            return '';
        }
    }
}
