<template>
    <b-modal :id="'create-'+item_type"
             size="xl"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Set Navigation Items of {' + selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>


        <div class="row">
            <CustomMNRepeater :add-form="addForm" :required_fields="required_fields" />
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Set Navigation Items of {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-module_navigations')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import CustomMNRepeater from "./CustomMNRepeater";

const moduleResource = new Resource('api/assetflow/modules');

export default {
    name: "AddNewModuleNavigations",
    components: {CustomMNRepeater},
    props: ['modal_title', 'item_type'],
    mixins:[multiSelect],
    data(){
        return {
            addForm:{
                module_navigations: []
            },
            selected_item: null,
            list_disabled: null,
            lists_values: {},
            required_fields:{
                navigation_item: { options: [] }
            },
        }
    },
    created() {

    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
            this.addForm.module_navigations = [];
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        fillSelectedItem() {
            if (this.selected_item) {
                let entity_type = this.selected_item.entity_type;


                if (entity_type){
                    this.addForm.entity_type = entity_type;
                    this.getNavigationItemsFor(entity_type);
                }
            }
        },
        async getNavigationItemsFor(entity_type){
            this.required_fields.navigation_item.options = [];

            this.lists_values = {};
            this.required_fields.navigation_item.isLoadingItem = true;

            let required_select_lists =  {
                navigation_item: 'module_navigations_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'module_modal',
                    object_type: 'modules',
                    action: 'new_module',
                    entity_type: entity_type,
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.required_fields.navigation_item.isLoadingItem = false;

                let form_lists = response.data.data;
                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }
                this.required_fields.navigation_item.options = this.lists_values.navigation_item;



                let navigation_info = this.selected_item.navigation_info;
                if (navigation_info && navigation_info.length > 0){
                    let strongThis = this;
                    this.addForm.module_navigations = navigation_info.map(function (navigation_item_info) {
                        let navigation_order = navigation_item_info.order;
                        let navigation_item_id = navigation_item_info.id;
                        let navigation_item = strongThis.getObjectOf(navigation_item_id, strongThis.required_fields.navigation_item.options);
                        return {navigation_item: navigation_item, navigation_order: navigation_order};
                    })
                }

            }).catch(error => {
                console.log(error);
                this.required_fields.navigation_item.isLoadingItem = false;
            });

        },
        async addItem(event){
            this.isAddingItem = true;
            let edited_module_navigations = [... this.addForm.module_navigations];
            edited_module_navigations = edited_module_navigations.filter(e => !!e.navigation_item || e.hasOwnProperty('item_type') ).map(function (module_navigation) {
                let navigation_order = (module_navigation.navigation_order !== '') ? module_navigation.navigation_order : edited_module_navigations.length;

                if (module_navigation.hasOwnProperty('item_type') && module_navigation.item_type){
                    return {new_nav_item: module_navigation, order: navigation_order};
                }
                return {nav_item_id: module_navigation.navigation_item.id, order: navigation_order};
            })

            var params = {
                module_navigations: edited_module_navigations,
                action: 'set_module_navigations'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await moduleResource.store(params);

            let new_item = data.data.items;
            let saved = data.data.status;
            this.presentToast((saved ? "Success!" : "Error!"),
                saved ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : (data.data.items),
                (saved ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-module_navigations');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


