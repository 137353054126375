<template>
    <Layout>
        <switch-entity :item_type="item_type" :with_header="true"/>
    </Layout>
</template>



<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import SwitchEntity from "../SwitchEntity";

export default {
    props: ['item_id', 'item_type'],
    name: "SwitchCustomer",
    components: {
        SwitchEntity,
        Layout,
        PageHeader
    },
    data(){
        return {

        }
    }
}

</script>
