<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_movementResource = new Resource('api/assetflow/sp_movements');
import ChangeCommonFields from "../extras/ChangeCommonFields";
import AFButton from "@/components/AFButton";

export default {
    name: "AddNewSpMovement",
    props: ['modal_title', 'item_type', 'sp_movements_options'],
    components: {AFButton, ChangeCommonFields},
    data(){
        return {
            addForm:{
                movement_wrapper_id: null,
                movement_wrapper_icon: '',
                name: '',
            },
            required_sections:{
                movement_setup: {
                    section_name: 'Movement Setup',
                    required_items:{
                        movement_wrapper_id: {
                            key: 'movement_wrapper_id',
                            label: 'Movement Wrapper',
                            type: 'single-select',
                            options:[]
                        },
                        movement_wrapper_icon: {
                            key: 'movement_wrapper_icon',
                            label: 'Icon',
                            type: 'hidden_text',
                            is_readonly: true
                        },
                        name: {
                            key: 'name',
                            label: 'Name',
                            type: 'hidden_text'
                        },
                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
    },
    computed:{
        movement_wrapper_id() {
            return this.addForm.movement_wrapper_id;
        },
    },
    watch:{
        movement_wrapper_id(value) {
            // movement_wrapper_id
            if (value){
                this.addForm.movement_wrapper_icon = this.addForm.movement_wrapper_id.icon;
                if (this.addForm.movement_wrapper_id.naming === 0){
                    this.addForm.name = '';
                    this.required_sections.movement_setup.required_items.name.type = 'text';
                }else{
                    this.addForm.name = null;
                    this.required_sections.movement_setup.required_items.name.type = 'hidden_text';
                }

                this.required_sections.movement_setup.required_items.movement_wrapper_icon.type = 'icon';
            }else{
                this.required_sections.movement_setup.required_items.movement_wrapper_icon.type = 'hidden_text';
            }
        },
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;
            this.is_saving_enabled = true;

            let required_select_lists =  {
                movement_wrapper_id: 'sp_movement_wrappers_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_movement_modal',
                    object_type: 'sp_movements',
                    action: 'new_sp_movement',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                this.required_sections.movement_setup.required_items.movement_wrapper_id.options = this.lists_values.movement_wrapper_id;

                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.required_sections.movement_setup.required_items.name.type = 'hidden_text';
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.movement_wrapper_id = this.getObjectOf(this.selected_item.movement_wrapper_id, this.required_sections.movement_setup.required_items.movement_wrapper_id.options);
                this.addForm.movement_wrapper_icon = this.selected_item.name;
                this.addForm.name = this.selected_item.name;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                movement_wrapper_id: this.addForm.movement_wrapper_id ? this.addForm.movement_wrapper_id.id : null,
                movement_wrapper_icon: this.addForm.movement_wrapper_icon,
                name: this.addForm.name
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_movementResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>


