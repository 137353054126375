<template>
    <div class="common_col_bg_box">
        <div class="row">
            <div class="col-md-3">
                <div class="cm_usage">
                    <span>Order</span>
                    <p v-if="order_line_info && order_line_info.hasOwnProperty('order') && order_line_info.order">
                        <a class="text-dark" :href="'/' + item_type + '/orders/' + order_line_info.order.id + '/view'">
                            <i v-if="order_line_info.hasOwnProperty('status')"
                               class="font-size-10 fa fa-circle intake-status-circle"
                               :class="order_line_info.order.status === 0 ? 'status-grey' : (order_line_info.order.status === 1 ? 'status-brown' : (order_line_info.order.status === 2 ? 'status-orange' : (order_line_info.order.status === 3 ? 'status-green' : ((order_line_info.order.status === 4 ? 'status-red' : 'status-black')))))"></i>
                            ORD-{{ order_line_info.order.uid }}
                        </a>
                    </p>
                    <p v-else>-</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="cm_usage">
                    <span>Task</span>
                    <p v-if="order_line_info">
                        <a class="text-dark" :href="'/' + item_type + '/order_lines/' + order_line_info.id + '/view'">
                            <i v-if="order_line_info.hasOwnProperty('status')"
                               class="font-size-10 fa fa-circle intake-status-circle"
                               :class="order_line_info.status === 0 ? 'status-orange' : (order_line_info.status === 1 ? 'status-grey' : (order_line_info.status === 2 ? 'status-brightgreen' : (order_line_info.status === 3 ? 'status-green' : 'status-red')))"></i>
                            {{ order_line_info.uid }}
                        </a>
                    </p>
                    <p v-else>-</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="cm_usage border_r">
                    <span>Intake by</span>
                    <p>
                        <span v-if="intake_profile_info && intake_profile_info.user_info">
                            <a class="text-dark"
                               :href="'/' + item_type + '/users/' + intake_profile_info.user_info.id + '/view'">{{ intake_profile_info.user_info.name }}</a>
                        </span>
                        <span v-else>-</span>
                    </p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="cm_usage">
                    <span>Date in</span>
                    <p>
                        <span v-if="order_line_info && order_line_info.hasOwnProperty('intake_date') && order_line_info.intake_date">
                            {{ order_line_info.intake_date | moment('DD-MM-YYYY') }}
                        </span>
                        <span v-else>-</span>
                    </p>
                </div>
            </div>

        </div>
        <div class="row" v-if="asset.notes && asset.notes !== ''">
            <div class="col-md-12 col-xl-12">
                <div class="cm_usage intake-notes">
                    <span>Intake Notes</span>
                    <p class="common_col_desc">
                        {{ asset.notes }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagedStorageIntakeInfo',
    props: {
        asset: {},
        item_type: {},
    },
    computed:{
        intake_profile_info(){
            return this.asset.hasOwnProperty('intake_profile_info') && this.asset.intake_profile_info;
        },
        order_line_info(){
            return this.asset.hasOwnProperty('order_line_info') && this.asset.order_line_info;
        },
    }
}
</script>
