<template>
    <Layout parent_class="asset-form-wrap">

        <div v-if="this.item_type === 'customer' && !this.user_info.has_current_customer">
            <b-alert show variant="danger">
                There's nothing to show, Please "
                <a href="/customer/switch">
                    Select Customer
                </a>
                " to proceed
            </b-alert>
        </div>

        <div v-else-if="this.item_type === 'sp' && !this.user_info.has_current_sp">
            <b-alert show variant="danger">
                There's nothing to show, Please "
                <a href="/sp/switch">
                    Select Service Provider
                </a>
                " to proceed
            </b-alert>
        </div>
        <!-- Start here -->
        <div id="main-wrapper" class="">

            <!-- Sidebar start-->
            <div id="side_bar_main_section" class="side_bar_block">
                <div class="step-progress-bar-block">
                    <!-- progressbar -->
                    <ul id="progressbar">
                        <li v-for="(step, index) in steps_list"
                            @click="selected_step_index = index"
                            :class="getStepClass(step)">
                            <span>{{ step.name }}</span>
                        </li>
                    </ul>
                </div>
                <div class="sustainability-progress-block">
                    <p class="cauge-text" v-html="caugeText"></p>
                    <div id="chartHolder" style="height:240px; width:100%;"></div>
                </div>
            </div>
            <!-- Sidebar end-->

            <!-- Page-Content start-->
            <div id="disboard-content-wrapper">
                <div class="sidebad-toggle af-accent asset_form"></div>


                <div v-if="asset_is_ended"
                     class="locked-asset-alert alert alert-primary text-center" role="alert" style="margin-bottom:0px;">
                    <span class="bx bx-lock"></span>
                    This Asset is Ended & Locked Some actions won't be available.
                </div>
                <div v-else-if="asset_is_locked"
                     class="locked-asset-alert alert alert-warning text-center" role="alert" style="margin-bottom:0px;">
                    <span class="bx bx-lock"></span>
                    This Asset is Locked, Some actions won't be available.
                </div>

                <div v-if="asset_is_locked">
                    <div class="locked-asset-reasons">
                        <ul>
                            <li v-for="reason in getReasonsForLockingAsset(asset_info)"
                                class="locked-asset-reason">
                                <span v-html="reason.message"></span>

                            </li>
                        </ul>
                    </div>
                </div>


                <div class="common-inner-section">
                    <div class="common-title-block">
                        <h2>Add New Assets</h2>
                    </div>
                    <div class="main-step-wrapper">
                        <!-- Multi step form -->

                        <fieldset class="common-step-block step-1">
                            <div class="use-section-df">
                                <div class="common-title-block">
                                    <h4>{{ steps_list[selected_step_index].name }}</h4>
                                    <p>Fill all information below</p>
                                </div>

                                <div v-if="false" class="use-section-btn">
                                    <a @click="useTooltips = false" class="st-common-btn use-st-btn" :class="!useTooltips ? 'af-accent af-primary-border active' : 'af-soft-accent text-af-primary af-primary-border af-accent-hover'">Use Sections</a>
                                    <a @click="useTooltips = true" class="st-common-btn show-tool-btn" :class="useTooltips ? 'af-accent af-primary-border active' : 'af-soft-accent text-af-primary af-primary-border af-accent-hover'">Show Tooltips</a>
                                </div>
                            </div>

                            <transition name="fade" mode="out-in">
                                <step-panel
                                    ref="step-panel-ref"
                                    :current_step="steps_list[selected_step_index]"
                                    :use-tooltips="useTooltips"
                                    :add-form="addForm"
                                    :required_fields="required_fields"
                                    :listQuery="listQuery"
                                    :my_lists_values="lists_values"
                                    :item_id="item_id"
                                    :asset_is_locked="asset_is_locked"
                                    :asset_is_ended="asset_is_ended"
                                    :asset_is_disposed="asset_is_disposed"
                                    :getAssetInfoUsingKey="getAssetInfoUsingKey"
                                />
                            </transition>

                            <div class="common-step-button-block">

                                <div class="save-cancel-btn">
                                    <div class="btn-group dropup">
                                        <a aria-expanded="false" aria-haspopup="true" class="st-common-btn dropdown-toggle"
                                           data-toggle="dropdown" href="#0">
                                            <span class="save-view-btn-text">Save & View</span>
                                            <i class="bx bx-chevron-down font-size-12"></i>
                                        </a>
                                        <div class="dropdown-menu">

                                            <AFButton tag_name="a"
                                                      :key_prefix="'save-view' + '-' + buttonkey"
                                                      :is_enabled="is_saving_enabled"
                                                      :required_action="addNew"
                                                      :first_argument="false"
                                                      item_classes="btn dropdown-item"
                                                      required_text="Save & View"/>

                                            <AFButton tag_name="a"
                                                      :key_prefix="'save-add' + '-' + buttonkey"
                                                      :is_enabled="is_saving_enabled"
                                                      :required_action="addNew"
                                                      :first_argument="true"
                                                      item_classes="btn dropdown-item"
                                                      required_text="Save & Add Another"/>
                                            <a :href="item_id !== '' ? './view' : './'" class="btn dropdown-item">Cancel</a>

                                        </div>
                                    </div>
                                    <a class="st-cancel-btn" :href="item_id !== '' ? './view' : './'">Cancel</a>
                                </div>
                                <div class="prev-next--btn">


                                    <button v-if="selected_step_index !== 0"
                                            class="action-button previous_button st-common-btn"
                                            type="button"
                                            @click="moveSteps('prev')">
                                        <i class="bx bx-left-arrow-alt font-size-12"></i>
                                        <span class="prev-btn-text">Previous</span>
                                    </button>
                                    <button v-if="selected_step_index !== steps_list.length - 1"
                                            class="action-button next_button st-common-btn"
                                            type="button"
                                            @click="moveSteps('next')">
                                        <span class="next-btn-text">Next</span>
                                        <i class="bx bx-right-arrow-alt font-size-12"></i>
                                    </button>

                                    <AFButton
                                        tag_name="button"
                                        :key_prefix="'save-only' + '-' + buttonkey"
                                        :is_enabled="is_saving_enabled"
                                        :required_action="addNew"
                                        :first_argument="'edit_page'"
                                        required_text="Save"
                                        item_classes="action-button next_button st-common-btn"
                                        span_classes="save-btn-text"
                                    />

                                </div>
                            </div>
                        </fieldset>

                    </div>
                </div>
            </div>

        </div>
    </Layout>
</template>


<script>
import AssetLocation from "@/mixins/AssetLocation";
import FlashableMessages from "@/mixins/FlashableMessages.mixin";
import Layout from "@/layouts/main";
import Resource from '@/api/resource';
import {AutoDimensionsList} from "@/views/assetflow/form_data/AutoDimensionsList";
import {LiabilityOptions} from "@/views/assetflow/form_data/LiabilityOptions";
import {AcquisitionOptions} from "@/views/assetflow/form_data/AcquisitionOptions";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import StepPanel from "./StepPanel";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";

const assetResource = new Resource('api/assetflow/assets');

export default {
    props: ['item_id', 'item_type', 'action'],
    mixins: [TotalsAndCurrencies, AssetLocation, dropzoneMixin, FlashableMessages],
    name: 'NewAsset',
    components: {
        AFButton,
        StepPanel,
        Layout
    },
    data() {
        return {
            caugeID: "chart1",
            caugeValue: 0,
            caugeText: '',
            selected_step_index: 0,
            useTooltips: false,
            steps_list: [
                {
                    id: 'asset_details_step',
                    name: 'Overview',
                    class: 'form-steps details-step',
                    isActive: false,
                    current_status: 'completed'
                },
                {
                    id: 'tv_film_step',
                    name: 'Customer Usage',
                    class: 'form-steps tv_film-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'structure_step',
                    name: 'Structure',
                    class: 'form-steps structure-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'location_step',
                    name: 'Assignments',
                    class: 'form-steps assignments-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'acquisition_step',
                    name: 'Acquisition',
                    class: 'form-steps acquisition-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'sustainability_step',
                    name: 'Planning',
                    class: 'form-steps planning-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'fdp_step',
                    name: 'Final Disposition Planning',
                    class: 'form-steps fdp-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'physical_properties_step',
                    name: 'Properties',
                    class: 'form-steps physical-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'disposal_step',
                    name: 'Disposition',
                    class: 'form-steps disposal-step',
                    isActive: false,
                    current_status: 'empty'
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            sectors_list: [],
            errors_list: {
                status: false,
            },
            conditionsList: [
                {id: 4, name: 4},
                {id: 3, name: 3},
                {id: 2, name: 2},
                {id: 1, name: 1},
            ],
            dropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.template()
            },

            basic_info_fields: [
                'name', 'description', 'is_unique', 'quantity', 'internal_reference', 'status'
            ],
            acquisition_fields: [
                'acquisition_type', 'acquired_using', 'supplier_id', 'po_id',
                'used_currency', 'value_type', 'value', 'total_value'
            ],
            tv_film_fields: [
                'asset_usage', 'is_hero', 'is_asset'
            ],
            demo_industry_fields: [
                'demo_field'
            ],
            asset_type_fields: [
                'asset_type'
            ],
            required_fields: {
                name: {
                    name: 'name',
                    type: 'text',
                    has_permission: true,
                    default_value: ''
                },
                description: {
                    name: 'description',
                    type: 'longtext',
                    has_permission: true,
                    default_value: ''
                },
                is_unique: {
                    name: 'is_unique',
                    type: 'toggle',
                    has_permission: true,
                    default_value: false,
                    msg: 'Is Unique?'
                },
                quantity: {
                    name: 'quantity',
                    type: 'number',
                    has_permission: true,
                    default_value: '1'
                },
                internal_reference: {
                    name: 'internal_reference',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                af_asset_type: {
                    name: 'status',
                    type: 'radio',
                    has_permission: true,
                    default_value: 'planned',
                    options: []
                },
                ownership_type: {
                    name: 'ownership_type',
                    type: 'radio',
                    has_permission: true,
                    default_value: 1,
                    options: []
                },
                status: {
                    name: 'status',
                    type: 'radio',
                    has_permission: true,
                    default_value: 'planned',
                    options: []
                },
                contact_id: {
                    name: 'User',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                department_id: {
                    name: 'department',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                address_id: {
                    name: 'address',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                location_id: {
                    name: 'location',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                asset_type: {
                    name: 'asset_type',
                    type: '',
                    has_permission: true,
                    default: '',
                    options: []
                },
                folder_id: {
                    name: 'folder_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                category_id: {
                    name: 'category_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                waste_stream_type: {
                    name: 'waste_stream_type',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options:[
                        { id: 0, name: 'Product' },
                        { id: 1, name: 'Material' },
                    ]
                },
                waste_stream_id: {
                    name: 'waste_stream_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                tag_ids: {
                    name: 'tag_ids',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                colours: {
                    name: 'colours',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                composition: {
                    name: 'composition',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },


                value_types: {
                    key: 'value_types',
                    label: '',
                    type: 'value_types',
                    options:[]
                },
                width: {
                    name: 'width',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                height: {
                    name: 'height',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                depth: {
                    name: 'depth',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                area: {
                    name: 'area',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                total_area: {
                    name: 'total_area',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                volume: {
                    name: 'volume',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                total_volume: {
                    name: 'total_volume',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                acquired_date: {
                    name: 'acquired_date',
                    type: 'date',
                    has_permission: true,
                    default: ''
                },

                acquisition_type: {
                    name: 'acquisition_type',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: [
                        {id: 1, name: 'Purchase'},
                        {id: 4, name: 'Manufactured'},
                        {id: 6, name: 'Free'},
                        {id: 2, name: 'Rental'}, // >> Tempo
                        {id: 3, name: 'Product Placement'}, // >> Tempo
                        {id: 5, name: 'Borrowed'} // >> Tempo
                    ]
                },
                asset_liability: {
                    name: 'asset_liability',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: LiabilityOptions
                },
                acquired_using: {
                    name: 'acquired_using',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: AcquisitionOptions
                },
                supplier_id: {
                    name: 'supplier_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                po_id: {
                    name: 'po_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                value: {
                    name: 'value',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                total_value: {
                    name: 'total_value',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                value_type: {
                    name: 'value_type',
                    type: 'radio',
                    has_permission: true,
                    default_value: 0
                },
                used_currency: {
                    name: 'Currency',
                    type: 'single-select',
                    has_permission: true
                },
                asset_usage: {
                    name: 'asset_usage',
                    type: 'toggle',
                    has_permission: true,
                    default: '',
                    options: []
                },
                is_hero: {
                    name: 'is_hero',
                    type: 'toggle',
                    has_permission: true,
                    default: '',
                    msg: 'Is Hero?'
                },
                is_asset: {
                    name: 'is_asset',
                    type: 'toggle',
                    has_permission: true,
                    default: '',
                    msg: 'Is Asset?'
                },
                demo_field: {
                    name: 'demo_field',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                final_disposition_planning: {
                    name: 'final_disposition_planning',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                fdp_status: {
                    name: 'fdp_status',
                    type: 'checkbox',
                    has_permission: true,
                    default: '',
                    label: 'Confirm Final Disposition Plan for this Asset?',
                    options: [
                        { id: 1, name: 'Yes'},
                        { id: 0, name: 'No'}
                    ]
                },
                valuation_method: {
                    name: 'valuation_method',
                    type: 'checkbox',
                    has_permission: true,
                    default: '',
                    options: [
                        { id: 0, name: 'Fixed Price'},
                        { id: 1, name: 'Fixed Percentage'}
                    ]
                },
                forecast_value:{
                    name: 'forecast_value',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                dispose_asset:{
                    name: 'dispose_asset',
                    type: 'checkbox',
                    has_permission: true,
                    default: ''
                },
                disposition_type:{
                    name: 'disposition_type',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_category:{
                    name: 'disposition_category',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_name:{
                    name: 'disposition_name',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                disposition_date:{
                    name: 'disposition_date',
                    type: 'date',
                    has_permission: true,
                    default: ''
                },
                disposition_details:{
                    name: 'disposition_details',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                sale_price:{
                    name: 'sale_price',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                movement_info:{
                    name: 'movement',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                recipient_info:{
                    name: 'recipient_info',
                    type: 'recipient_info',
                    has_permission: true,
                    default: ''
                },
                reference_info:{
                    name: 'reference', // dispositionb
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
            },
            title: "Add New Asset",
            items: [
                {
                    text: "Assets",
                    href: "/"
                },
                {
                    text: "Add Asset",
                    active: true
                }
            ],
            customer_value: null,
            customers: [],
            isLoadingCustomers: false,
            asset_images_previewElements: {},
            asset_info: null,
            addForm: {
                check_if_shall_activate_asset: true,
                assign_to_sp: false,
                dispose_asset: false,
                valuation_percentage:100,
                forecast_value_per_unit:null,
                item_details: {},
                asset_meta: {},
                events_fields_list: [],
                asset_images: [],
                filled_asset_images: [],
                single_select_v_models: {
                    waste_stream_type: null,
                    waste_stream_id: null,
                    condition: null,
                    contact_id: null,
                    department_id: null,
                    address_id: null,
                    location_id: null,
                    folder_id: null,
                    category_id: null,
                    af_asset_type: null,
                    asset_type: null,
                    used_currency: null,
                    supplier_id: null,
                    po_id: null,
                    acquisition_type: null,
                    acquisition_id: null,
                    asset_liability: null,
                    acquired_using: null,
                    final_disposition_planning: null,
                    disposition_type: null,
                },
                multi_select_v_models: {
                    colours: [],
                    composition: [],
                    tag_ids: []
                }
            },
            isLoadingItem: false,
            wizard_colour: '#556ee6',
            selected_dimensions_type: 'auto',
            dimensions_types: [
                {
                    name: 'auto',
                    variant: 'outline-success'
                },
                {
                    name: 'manual',
                    variant: 'outline-primary'
                }
            ],
            selected_auto_dimensions: null,
            auto_dimensions_list: AutoDimensionsList,
            meta_keys_for_asset_type: ['asset_type'],
        };
    },
    mounted() {
        this.updateCaugeValue();
        // this.createAndShowKoolChart();
    },
    created() {

        this.setSelectedStepIndex();

        this.addForm.item_details.quantity_disabled = this.action === 'edit' && this.item_id !== '';

        this.selected_dimensions_type = window.USER_INFO.user_meta.dimensions_type ?? 'auto';


        this.wizard_colour = window.COLOUR_PLATE.accent_colour ? window.COLOUR_PLATE.accent_colour : '';

        this.form_lists = this.$root.form_lists;
        this.lists_values = {
            status: this.form_lists.status_list,
            ownership_type: this.form_lists.ownership_type_list,
            af_asset_type: this.form_lists.asset_types_list,
            address_id: this.form_lists.addresses_list,
            location_id: this.form_lists.locations_list,
            department_id: this.form_lists.departments_list,
            contact_id: this.form_lists.contacts_list,
            folder_id: this.form_lists.folders_list,
            category_id: this.form_lists.categories_list,
            waste_stream_id: this.form_lists.product_waste_streams_list,
            tag_ids: this.form_lists.tags_list,
            colours: this.form_lists.colours_list,
            composition: this.form_lists.compositions_list,
            asset_type: this.form_lists.assetTypes_list,
            supplier_id: this.form_lists.suppliers_list,
            events: this.form_lists.events_list,
            tasks: this.form_lists.tasks_list,
            final_disposition_planning: this.form_lists.final_disposition_planning,
            disposition_type: this.form_lists.dispositions_list,
            acquisition_id: this.form_lists.acquisitions_list,
            disposition_category: this.form_lists.disposition_categories_list,

        }


        for (let key in this.lists_values) {
            if (['events', 'tasks'].includes(key)){
                continue;
            }
            this.getOptionsForKey(key);
        }

        if (typeof this.lists_values.asset_type === 'undefined'){
            this.lists_values.asset_type = [];
        }

        if ( this.lists_values.asset_type.length === 0) {
            this.asset_type_fields = [];
        }

        if (this.action === 'edit' && this.item_id !== '') {
            this.title = this.title.replace('Add New', 'Edit');
            this.items[1].text = this.items[1].text.replace('Add', 'Edit');
            this.fetchInfoOf();
        } else {
            this.setDefaults();
        }

        let tv_step_name = 'Usage';
        if (this.item_type === 'customer' && this.current_customer){
            tv_step_name = this.current_customer.name + ' Usage';
        }
        this.steps_list[1].name = tv_step_name;


    },
    methods: {
        afterSavingDisposition(){
            // shall open disposition section
            this.selected_step_index = 8;
        },
        getAssetInfoUsingKey(asset_key, required_object = 'asset_info', fromForm=false, property_if_object=null){
            if (this[required_object] && this[required_object].hasOwnProperty(asset_key)){
                return this.asset_info[asset_key];
            }else if(fromForm){
                if (property_if_object){
                    return this.addForm[required_object][asset_key] ? this.addForm[required_object][asset_key][property_if_object] : null;
                }
                return this.addForm[required_object][asset_key];
            }else if(required_object === 'this'){
                return this[asset_key] ? this[asset_key] : null;
            }
            return null;
        },
        handleChangingCategory() {
            let selected_asset_template_id = null;
            if (this.selected_category){
                if (this.selected_category.hasOwnProperty('category') && this.selected_category.category){
                    if (this.selected_category.category.hasOwnProperty('asset_template') && this.selected_category.category.asset_template){
                        selected_asset_template_id = this.selected_category.category.asset_template.id;
                    }
                }else if (this.selected_category.hasOwnProperty('asset_template')){
                    selected_asset_template_id = this.selected_category.asset_template.id;
                }

                if (selected_asset_template_id){
                    let asset_type_index = this.lists_values.asset_type.map(function (e) {
                        return e.id;
                    }).indexOf(selected_asset_template_id);

                    this.addForm.single_select_v_models.asset_type = this.lists_values.asset_type[asset_type_index];
                }else{
                    this.addForm.single_select_v_models.asset_type = null;
                }
                this.addForm.item_details.asset_template_id = selected_asset_template_id;
            }

        },
        handleValuationPercentage() {
            if (this.addForm.item_details.valuation_method === 1) {
                let quantity = this.addForm.item_details.quantity;
                let valuation_percentage = parseFloat(this.addForm.valuation_percentage);
                if (valuation_percentage && this.addForm.item_details.total_value) {
                    this.addForm.item_details.forecast_value = this.addForm.item_details.total_value * valuation_percentage / 100;
                    this.addForm.forecast_value_per_unit = (this.addForm.item_details.forecast_value / quantity).toFixed(2);
                } else {
                    this.addForm.item_details.forecast_value = 0;
                    this.addForm.forecast_value_per_unit = 0;
                }
            }
        },
        updateCaugeValue(){

            this.caugeValue = 0;
            this.caugeText = '';

            let additionValue = 100 / 5;
            if (this.addForm.single_select_v_models.location_id){
                this.caugeValue += additionValue;
                this.caugeText += "<p><b>Location is Set {"+additionValue+"%}</b></p>";
            }

            if (this.addForm.item_details.volume){
                this.caugeValue += additionValue;
                this.caugeText += "<p><b>Volume is Set {"+additionValue+"%}</b></p>";
            }

            if (this.addForm.single_select_v_models.acquisition_type){
                this.caugeValue += additionValue;
                this.caugeText += "<p><b>Acquisition is Set {"+additionValue+"%}</b></p>";
            }

            if (this.addForm.item_details.total_value){
                this.caugeValue += additionValue;
                this.caugeText += "<p><b>Cost is Set {"+additionValue+"%}</b></p>";
            }


            if (this.addForm.single_select_v_models.final_disposition_planning){
                this.caugeValue += additionValue;
                this.caugeText += "<p><b>Sustainability Planning is Set {"+additionValue+"%}</b></p>";
            }

            if (this.caugeValue > 99){
                this.caugeValue = 100;
                this.caugeText += "<p><b>All is Set {100%}</b></p>";
            }else{
                this.caugeText += "<p><small class='pl-1'>{" + this.caugeValue + "%}</small></p>";
                this.caugeText += "<p><small class='pl-1'>Set (Volume, Location, Acquisition, Cost) For {100%}</small></p>";
            }

            let caugeObject = document.getElementById(this.caugeID);
            if (caugeObject){
                caugeObject.setData([this.caugeValue]);
            }

        },
        createAndShowKoolChart() {
            /*================================ Sustainability progress Start ====================================*/
            window.chartReadyHandler = function chartReadyHandler(id) {
                document.getElementById(id).setLayout(layoutStr);
                document.getElementById(id).setData(chartData);
            }
            window.chartData = [this.caugeValue];
            window.dataTipJsFunction = function chartReadyHandler(str) {
                return "";
            }

            window.chartVars = "KoolOnLoadCallFunction=chartReadyHandler";
            KoolChart.create(this.caugeID, 'chartHolder', window.chartVars, "100%", "80%");

            let trackValues = [0, 20, 40, 60, 80, 100];

            let trackLabels = ["VERY POOR", "POOR", "FAIR", "GOOD", "EXCELLENT"];

            var layoutStr =
                '<KoolChart frameColor="#EEEEEE"  borderStyle="none">'
                + '<Options>'
                + '<Caption text="Sustainability Progress" />'
                + '</Options>'
                + '<NumberFormatter id="numFmt" precision="0"/>'
                + '<HalfCircularGauge frameType="flat" trackLabelStyleName="trackText" minimumAngle="180" maximumAngle="360" minimum="0" maximum="100" interval="20" minorInterval="20" formatter="{numFmt}" tickGap="0" padding="-30" showValueLabel="false" tickLabelStyleName="tickText" valueLabelStyleName="valueText" dataTipJsFunction="dataTipJsFunction" editMode="false" liveDragging="false" showDataTip="false" needleThickness="20" pointThickness="1" needleLengthRatio="0.35" needlePointStyle="steeple" needleBackLengthRatio="0" showTickLabels="false" tickLabelPosition="inside" showTrackColor="true" trackColors="[#e14159,#fa9c42,#facf4c,#88b14b,#5587a2]" trackAlphas="[1,1,1,1,1]" trackValues="[' + trackValues.toString() + ']" trackInnerRadius="0.55" trackOuterRadius="1" bounceAnimating="true" majorTickType="line" tickRadius="60" needleCoverRadius="18" trackLabels="[' + trackLabels.toString() + ']" trackLabelsXOffset="[0.2,0,0,0,0.3]" trackLabelsYOffset="[0.2,0,-0.2,0,0.2]" needleTrackColorEnable="true">'
                + '<stroke>'
                + '<Stroke color="#CCCCCC" weight="1" alpha="0.5"/>'
                + '</stroke>'
                + '<frameStroke>'
                + '<Stroke color="#ffffff" weight="0" alpha="0"/>'
                + '</frameStroke>'
                + '<tickStroke>'
                + '<Stroke color="#ffffff" weight="4"/>'
                + '</tickStroke>'
                + '<frameFill>'
                + '<SolidColor color="#ffffff"/>'
                + '</frameFill>'
                + '<needleFill>'
                + '<SolidColor color="#555555"/>'
                + '</needleFill>'
                + '<needleStroke>'
                + '<SolidColor color="#555555"/>'
                + '</needleStroke>'
                + '<needleCoverStroke>'
                + '<Stroke color="#555555" weight="3"/>'
                + '</needleCoverStroke>'
                + '</HalfCircularGauge>'
                + '<Style>'
                + '.tickText{fontSize:11;color:0xFF6600;}'
                + '.trackText{fontSize:8;color:#ffffff;fontWeight:bold;}'
                + '</Style>'
                + '</KoolChart>';

            /*================================ Sustainability progress End ====================================*/

        },
        setSelectedStepIndex: function () {
            this.steps_list = this.steps_list.map(e => ({...e, isActive: false}));
            this.steps_list[this.selected_step_index].isActive = true;
        },
        moveSteps(type) {
            if (type === 'next') {
                this.selected_step_index += 1;
            } else if (type === 'prev') {
                this.selected_step_index -= 1;
            }

            // document.getElementById('chart1').setData([100]);
        },
        getStepClass(step) {
            var classList = [];
            if (step.isActive) {
                classList.push('blank_round active');
            }

            var result = false;
            let required_fields = [];
            let myForm = this.addForm;
            let required_single_select;

            switch (step.id){
                case 'asset_details_step':
                    required_fields = ['name', 'status'];
                    delete required_fields[2];
                    result = required_fields.every((k) => myForm.item_details[k]);
                    break
                case 'physical_properties_step':
                    required_fields = ['volume'];
                    result = required_fields.every((k) => myForm.item_details[k]);

                    required_single_select = ['category_id'];
                    result_single = required_single_select.every((k) => myForm.single_select_v_models[k]);

                    let required_multi_select = []; //['colours', 'composition'];
                    let result_multi = required_multi_select.every((k) => myForm.multi_select_v_models[k].length > 0);

                    result = result && result_single && result_multi;
                    break
                case 'location_step':
                    required_single_select = ['location_id', 'address_id'];
                    result = required_single_select.every((k) => myForm.single_select_v_models[k]);
                    break
                case 'acquisition_step':
                    required_fields = ['value', 'total_value'];
                    result = required_fields.every((k) => myForm.item_details[k]);

                    required_single_select = ['acquisition_type', 'acquired_using', 'po_id', 'supplier_id', 'used_currency'];
                    let result_single = required_single_select.every((k) => myForm.single_select_v_models[k]);

                    result = result && result_single;
                    break
                case 'tv_film_step':
                    required_fields = ['asset_usage', 'is_hero', 'is_asset'];
                    result = required_fields.every((k) => (myForm.item_details[k] !== 2 && myForm.item_details[k] !== null));
                    break

            }

            if (result){
                classList.push('completed');
            }

            return classList;
        },

        toggleColour(colour, field_key) {
            let index = this.addForm.multi_select_v_models[field_key].map(function (e) {
                return e.id;
            }).indexOf(colour.id);
            if (index > -1) {
                // found, remove it!
                this.addForm.multi_select_v_models[field_key].splice(index, 1);
            } else {
                // not found, add it
                this.addForm.multi_select_v_models[field_key].push(colour);
            }
        },

        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },

        changedAddressInfo(field, event) {
            if (event.id === -99) {
                // shall add new
                switch (field) {
                    case 'address_id':
                        // console.log("shall add new address");
                        this.$refs['add-new-address'].setSelectedItem(null);
                        this.setupAddressLookup();
                        break;
                    case 'location_id':

                        let selected_address = this.addForm.single_select_v_models.address_id;
                        this.$refs['add-new-location'].setSelectedItem(null);
                        this.$refs['add-new-location'].set_addresses_list([{
                            value: selected_address.id,
                            text: selected_address.name
                        }], true);
                        this.$refs['add-new-location'].setAddressID(selected_address.id);

                        break;
                }

                this.addForm.single_select_v_models[field] = null;
                this.$bvModal.show('create-' + field.replace('_id', ''));
            } else if (field === 'address_id') {
                // should filter items in locations list.
                this.$refs['add-new-location'].set_addresses_list([{value: event.id, text: event.name}], true);
                this.$refs['add-new-location'].setAddressID(event.id);
                this.setSelectedLocationFor(event);

            }

        },
        tabChanged() {
            if (!this.addForm.item_details.status) {
                this.errors_list.status = true;
                return false;
            }
            return true;
        },
        onComplete() {
            console.log("'form completed'");
        },
        handle_sector_details(asset_meta) {
            let a_meta = {};
            if (!Array.isArray(asset_meta)){
                a_meta = asset_meta;
            }

            if (!a_meta.hasOwnProperty('colour')){
                a_meta.colour = [];
            }


            a_meta = this.parseCustomFieldsFrom(a_meta);

            this.addForm.asset_meta = a_meta;


            for (const key in this.assetTypesSelects) {
                let assetTypesSelectKey = this.assetTypesSelects[key];
                if (a_meta.hasOwnProperty(assetTypesSelectKey)){
                    this.addForm.asset_meta[assetTypesSelectKey] = this.getValueOf(assetTypesSelectKey, a_meta[assetTypesSelectKey]);
                }
            }

        },
        fetchInfoOf() {
            if (this.item_id) {
                this.isLoadingItem = true;
                axios({
                    method: 'get',
                    url: '/api/assetflow/assets/' + this.item_id,
                    baseURL: '/',
                }).then(response => {
                    let asset_info = response.data;
                    this.asset_info = asset_info;
                    this.addForm.dispose_asset = asset_info.status === 'disposed';
                    this.addForm.item_details = {
                        name: asset_info.name,
                        description: asset_info.description,
                        internal_reference: asset_info.internal_reference,
                        is_unique: asset_info.is_unique === 1,
                        preferred_input: asset_info.preferred_input,
                        preferred_unit: asset_info.preferred_unit,
                        auto_dimension_id: asset_info.auto_dimension_id,
                        height: asset_info.height,
                        width: asset_info.width,
                        depth: asset_info.depth,
                        area: asset_info.area,
                        volume: asset_info.volume,
                        total_area: asset_info.total_area,
                        total_volume: asset_info.total_volume,
                        quantity: asset_info.quantity,
                        value: asset_info.value,
                        total_value: asset_info.total_value,
                        status: asset_info.status,
                        asset_usage: asset_info.asset_usage,
                        is_hero: asset_info.is_hero,
                        is_asset: asset_info.is_asset,
                        valuation_method: asset_info.valuation_method ?? null,
                        forecast_value: asset_info.forecast_value ?? '',
                        sale_price: asset_info.sale_price ?? '',
                        disposition_date: asset_info.modified_disposition_date ?? '',
                        disposition_name: asset_info.hasOwnProperty('dispositions_info') && asset_info.dispositions_info && asset_info.dispositions_info.length > 0 ? asset_info.dispositions_info[0].name : '',
                        disposition_details: asset_info.disposition_details ?? '',
                        acquired_date: asset_info.modified_acquired_date,
                        fdp_status: this.getValueOf('fdp_status', asset_info.fdp_status),
                        ownership_type: asset_info.ownership_type
                    };

                    if (asset_info.valuation_method && asset_info.valuation_method === 1 && asset_info.forecast_value !== 0){
                        this.addForm.valuation_percentage = asset_info.forecast_value * 100 / asset_info.total_value;
                    }


                    this.handle_sector_details(asset_info.asset_meta);

                    let selected_po_id = asset_info.asset_meta.po_id;
                    for (const value_name in this.addForm.single_select_v_models) {
                        if (value_name === 'used_currency') {
                            this.addForm.single_select_v_models.used_currency = this.getCurrencyByValue(asset_info.used_currency ?? 'gbp');
                        } else if (this.meta_keys_for_acquisition.includes(value_name) || this.meta_keys_for_asset_type.includes(value_name)) {
                            if (value_name !== 'po_id') {
                                let value = this.getValueOf(value_name, asset_info.asset_meta[value_name]);
                                this.addForm.single_select_v_models[value_name] = value ; //  ?? this.required_fields[value_name].options[0];
                            }else{
                                this.addForm.single_select_v_models.po_id = {
                                    id: selected_po_id,
                                    uid: '',
                                    reference: ''
                                }
                            }
                        } else {
                            this.addForm.single_select_v_models[value_name] = this.getValueOf(value_name, asset_info[value_name]);
                        }
                    }

                    for (const value_name in this.addForm.multi_select_v_models) {

                        this.addForm.multi_select_v_models[value_name] = this.getValueOf(value_name, asset_info[value_name], true);
                    }


                    if (asset_info.asset_images) {
                        this.addForm.asset_images = [... asset_info.asset_images];
                    }

                    if (this.addForm.item_details.status !== 'planned') {
                        let current_status = this.addForm.item_details.status.id;
                        // should splice statuses list
                        let statusesList = this.lists_values.status;
                        let current_status_index = statusesList.map(function (e) {
                            return e.id;
                        }).indexOf(current_status);
                        statusesList.splice(0, (current_status_index));
                        this.required_fields.status.options = statusesList;

                    }

                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        },
        addNew(add_another, event) {
            var allfiles = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (event && !this.isSendingRequest && allfiles) {
                this.isSendingRequest = true;

                var data = {
                    asset_id: this.item_id,
                    item_details: this.addForm.item_details,
                    asset_meta: this.addForm.asset_meta,
                    item_type: this.item_type,
                    asset_images: allfiles,
                    customer_id: this.customer_value ? this.customer_value.id : ""
                };

                if (!this.addForm.item_details.acquired_date){
                    this.addForm.item_details.acquired_date = this.$moment().format('DD-MM-YYYY');
                }


                if (this.addForm.events_fields_list.length !== 0){
                    let events = [];
                    for (const eventsFieldsListKey in this.addForm.events_fields_list) {
                        let event_field = this.addForm.events_fields_list[eventsFieldsListKey];
                        let edited_field = {
                            event_id: event_field.event_id.id,
                            tasks: event_field.tasks.map((x) => x.id)
                        };
                        events.push(edited_field);
                    }
                    this.addForm.item_details.events = events;
                }


                for (const value_name in this.addForm.single_select_v_models) {
                    if (this.addForm.single_select_v_models[value_name]) {
                        if (value_name === 'used_currency') {
                            data.item_details.used_currency = this.addForm.single_select_v_models.used_currency.value;
                        } else {
                            if (this.meta_keys_for_acquisition.includes(value_name) ||
                                this.meta_keys_for_asset_type.includes(value_name) ) {
                                data.asset_meta[value_name] = this.addForm.single_select_v_models[value_name].id;
                            }else {
                                data.item_details[value_name] = this.addForm.single_select_v_models[value_name].id;
                            }
                        }
                    }
                }
                for (const value_name in this.addForm.multi_select_v_models) {
                    if (this.addForm.multi_select_v_models[value_name]) {
                        data.item_details[value_name] = this.getIDs(this.addForm.multi_select_v_models[value_name], value_name === 'composition');
                    }
                }

                if (!!this.addForm.dispose_asset){
                    data.item_details.status = 'disposed';
                }
                if (data.item_details.hasOwnProperty('fdp_status')){
                    data.item_details.fdp_status = data.item_details.fdp_status.id;
                }
                if (data.asset_meta.hasOwnProperty('colour') && data.asset_meta.colour.length === 0){
                    delete data.asset_meta.colour;
                }


                if (data.asset_meta.hasOwnProperty('custom_fields') && data.asset_meta.custom_fields.length > 0){
                    data.asset_meta.custom_fields = data.asset_meta.custom_fields.filter(e => (e.field_name !== "" || e.field_value !== "") );
                }



                for (const key in this.assetTypesSelects) {
                    let assetTypesSelectKey = this.assetTypesSelects[key];
                    if (data.asset_meta.hasOwnProperty(assetTypesSelectKey) && data.asset_meta[assetTypesSelectKey]){
                        data.asset_meta[assetTypesSelectKey] = data.asset_meta[assetTypesSelectKey].name;
                    }
                }


                axios({
                    method: ((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                    url: '/api/assetflow/assets' + (this.item_id !== '' ? ('/' + this.item_id) : ''),
                    baseURL: '/',
                    data: data,
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true) {
                        let added_asset = response.data.data.items;
                        let message;
                        if (added_asset && added_asset.hasOwnProperty('id')){
                            let asset_name = ', ASS-' +added_asset.uid + ' {' + added_asset.name + '}';
                            message='Added Successfully' + asset_name;
                            if (this.action === 'edit' && this.item_id !== '') {
                                message = 'Edited Successfully' + asset_name;
                            }
                        }else{
                            console.log(added_asset);
                        }

                        if (add_another === 'edit_page') {
                            if (added_asset) {
                                window.flash(message, 'success', event);
                                let asset_id = added_asset.id;
                                window.location.href = '/' + this.item_type + '/assets/' + asset_id + '/edit';
                            } else {
                                window.location.href = '/' + this.item_type + '/assets';
                            }
                        }else if (add_another) {
                            if (this.action === 'edit' && this.item_id !== '') {
                                window.flash(message);
                                window.location.href = '/' + this.item_type + '/assets/new';
                            } else {
                                window.flash(message);
                                location.reload();
                            }
                        } else {
                            if (added_asset && added_asset.hasOwnProperty('id')) {
                                window.flash(message);
                                let asset_id = added_asset.id;
                                window.location.href = '/' + this.item_type + '/assets/' + asset_id + '/view';
                            } else {
                                console.log(added_asset);
                                window.location.href = '/' + this.item_type + '/assets';
                            }
                        }
                    } else {
                        console.log('Something went wrong');
                    }
                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });

            }
        },


        setDefaults() {

            this.addForm.item_details = {
                name: this.required_fields.name.default_value,
                description: this.required_fields.description.default_value,
                is_unique: this.required_fields.is_unique.default_value,
                quantity: this.required_fields.quantity.default_value,
                ownership_type: this.required_fields.ownership_type.default_value,
                valuation_method: null,
                asset_usage: 2,
                is_hero: 2,
                is_asset: 2
            }

            this.addForm.asset_meta = {
                colour: [],
                custom_fields: []
            }

            this.addForm.single_select_v_models.category_id = this.lists_values.category_id[0];

            this.addForm.single_select_v_models.used_currency = this.getCurrencyByValue(window.USER_INFO.user_meta.currency ?? 'gbp');


            // for (const key in this.meta_keys_for_acquisition) {
            //     let meta_key = this.meta_keys_for_acquisition[key];
            //     this.addForm.single_select_v_models[meta_key] = this.required_fields[meta_key].options[0];
            // }

        }
    },
    computed: {
        asset_is_locked(){
            return this.asset_info ? this.checkIfAssetIsLocked(this.asset_info) : false;
        },
        asset_is_ended(){
            return this.asset_info ? this.asset_info.hasOwnProperty('ownership_status') && this.asset_info.ownership_status === 0 : false;
        },
        asset_is_disposed(){
            return this.asset_info ? this.asset_info.hasOwnProperty('disposition_id') && this.asset_info.disposition_id : false;
        },
        selected_category(){
            return this.addForm.single_select_v_models.hasOwnProperty('category_id') ? this.addForm.single_select_v_models.category_id : null;
        },
        is_unique_asset() {
            return this.addForm.item_details.is_unique;
        },
        addressPercentage() {
            let total_values = this.address_info_fields.length;
            var completed_values = 0;
            for (const key in this.address_info_fields) {
                let field = this.address_info_fields[key];
                if (this.addForm.single_select_v_models[field]) {
                    completed_values += 1;
                }
            }

            return completed_values !== 0 ? (completed_values / total_values) * 100 : 0;
        },
        shall_dispose_asset(){
            return this.addForm.dispose_asset;
        },
        added_location_id(){
            return this.addForm.single_select_v_models.location_id;
        },
        // added_total(){
        //     return this.addForm.item_details.total_value;
        // },
        added_volume(){
            return this.addForm.item_details.volume;
        },
        added_acquisition_type(){
            return this.addForm.single_select_v_models.acquisition_type;
        },
        asset_valuation_percentage(){
            return this.addForm.valuation_percentage;
        },

    },
    watch: {
        selected_category:{
            deep: true,
            handler: function(newValue){
                this.handleChangingCategory();
            }
        },
        asset_valuation_percentage:{
            deep: true,
            handler: function (newValue) {
                this.handleValuationPercentage();
            }
        },
        selected_step_index:{
            deep: true,
            handler: function (newValue) {
                if (this.selected_step_index === 5){
                    this.handleValuationPercentage();
                }
                this.setSelectedStepIndex();
            }
        },
        added_location_id(value) {
            this.updateCaugeValue();
        },
        added_volume(value) {
            this.updateCaugeValue();
        },
        added_acquisition_type(value) {
            switch (this.addForm.single_select_v_models.acquisition_type.id){
                case 2:
                case 3:
                case 5:
                    this.addForm.item_details.ownership_type = this.required_fields.ownership_type.options[1].id;
                    break;
                default:
                    this.addForm.item_details.ownership_type = this.required_fields.ownership_type.options[0].id;
                    break;

            }

            this.updateCaugeValue();
        },
        is_unique_asset(value) {
            if (!!value) {
                this.addForm.item_details.quantity = 1;
                this.addForm.item_details.quantity_disabled = true;
            } else {
                this.addForm.item_details.quantity_disabled = false;
            }
        }
    },

}
</script>

<style>

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

</style>
