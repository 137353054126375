<template>
    <div class="acquisition-section">

        <add-new-acquisition
            ref="add-new-acquisition"
            object_type="acquisition"
            object_type_link="acquisitions"
            object_type_shortcut="ACQ"
            :current_page_type="getAssetInfoUsingKey('item_type', 'this')"
            modal_title="Create New Acquisition"
            @on-modal-save="addedNewAcquisition"
        />

        <div class="row">

            <div class="col-xl-6 st-col-6">
                <asset-info-section title="Acquisition List">
                    <div>
                        <po-lines
                            ref="acquisitions-list"
                            v-if="used_lists_values.hasOwnProperty('acquisition_id') && used_lists_values.acquisition_id.length > 0"
                            :items="getAssetInfoUsingKey('action', 'this') === 'edit' && selected_acquisition_id ? [selected_acquisition_id] : used_lists_values.acquisition_id"
                            :current_page_type="getAssetInfoUsingKey('item_type', 'this')"
                            :object_type="'acquisitions'"
                            :keys_list="acquisition_short_list_keys_list"
                            line_type="acquisition"
                            :get-on-click-row="onClickRow"
                        >
                        </po-lines>

                        <span v-else>No Acquisitions Yet. <a @click="shallCreateAcquisition()" href="#">Create One?</a></span>

                        <div class="text-center">
                            <a class="btn af-btn-accent af-accent btn-sm quick-add-order-line-btn" @click="shallCreateAcquisition()">
                                Quick Add
                            </a>
                        </div>
                    </div>
                </asset-info-section>
            </div>


            <div class="col-xl-6 st-col-6 mt-2">
                <asset-info-section title="Quantity" :selected_value="'Qty:' + addForm.item_details.quantity +  (addForm.item_details.is_unique ?  ' | ' + 'Unique' : '')">
                    <div class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>Product Quantity</h5>
                        </div>
                        <div class="common--input-filed st-unique-input">
                            <input
                                v-model="addForm.item_details.quantity"
                                :readonly="addForm.item_details.quantity_disabled"
                                class="st-input-uq"
                                type="number">
                            <div class="st-unique-input-box">
                                <input
                                    v-model="addForm.item_details.is_unique"
                                    id="styled-checkbox-2" class="styled-checkbox"
                                    type="checkbox" value="1">
                                <label for="styled-checkbox-2">Is Unique?</label>
                            </div>
                        </div>
                    </div>
                </asset-info-section>
            </div>

            <div class="col-xl-6 st-col-6 mt-2" v-if="selected_acquisition_id">
                <asset-info-section title="Cost" :selected_value="addForm.single_select_v_models.used_currency ? 'Total Cost: ' + addForm.single_select_v_models.used_currency.icon + addForm.item_details.total_value : ''">
                    <div>
                        <div class="st--common--box">
                            <div class="dimensions-btn">
                                <a
                                    v-for="option in (parseInt(addForm.item_details.quantity) !== 1 ? value_type_options : [value_type_options[0]])"
                                    :key="option.id"
                                    :value="option.id"
                                    @click="value_type = option.id"
                                    :class="[{'active': value_type === option.id}]"
                                    class="dimensions-auto">
                                    <span></span>{{ getValueTypeTitle(option) | titleize }}
                                </a>
                            </div>
                        </div>

                        <div class="st--common--box">
                            <div class="common-st-title-label">
                                <h5>Currency</h5>
                            </div>
                            <div class="st-status-radio-box">
                                <div class="st-radio-row">
                                    <div v-for="(option, index) in currencies_list"
                                         :key="index"
                                         class="col-3">
                                        <div class="cm-radio-btn" :class="'mt-1 used-currency currency-' + option.value">
                                            <label>
                                                <input
                                                    v-model="addForm.single_select_v_models.used_currency"
                                                    :key="index"
                                                    :id="'_'+index"
                                                    name="used_currency"
                                                    :value="option"
                                                    type="radio" />
                                                <div class="st-radio-box">
                                                    <span></span>
                                                    <p>{{ currencyWithIcon(option) }}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="selected_acquisition_id">

                        </div>

                        <div class="st--common--box" v-if="selected_acquisition_id">
                            <div class="common-st-title-label">
                                <h5 v-if="selected_acquisition_id.mode === 0">
                                    Unallocated Funds
                                </h5>
                                <h5 v-else>
                                    Required Payment
                                </h5>
                            </div>
                            <div class="unallocated-required-payments">
                                <span v-b-tooltip:hover.bottom
                                    v-if="selected_acquisition_id.mode === 0 && selected_acquisition_id.unallocated_funds"
                                    class="asset-form-unallocated-funds"
                                    :title="toUserCurrencyValue(selected_acquisition_id_currency.value, selected_acquisition_id.unallocated_funds)">
                                    {{selected_acquisition_id_currency.icon + parseFloat(selected_acquisition_id.unallocated_funds)}}
                                </span>
                                <span v-b-tooltip:hover.bottom
                                    v-else-if="selected_acquisition_id.mode === 1 && selected_acquisition_id.required_payment"
                                    class="asset-form-required-payment"
                                    :title="toUserCurrencyValue(selected_acquisition_id_currency.value, selected_acquisition_id.required_payment)">
                                    {{selected_acquisition_id_currency.icon + parseFloat(selected_acquisition_id.required_payment)}}
                                </span>
                                <span v-else>-</span>
                            </div>
                        </div>

                        <div v-for="(value_item, key) in ['value', 'total_value']"
                             :key="key"
                             class="st--common--box">
                            <div class="common-st-title-label">
                                <h5>{{ value_item === 'total_value' ? 'Total ' : '' }}Cost</h5>
                            </div>
                            <div class="common--input-filed unit-input">
                                <input
                                    v-model="addForm.item_details[value_item]"
                                    :aria-describedby="value_item"
                                    :id="'_'+value_item"
                                    :name="'_'+value_item"
                                    :readonly="handleValueReadonly(value_item)"
                                    @input="handleValueChanging"
                                    :type="handleValueReadonly(value_item) ? 'text' : 'number'"
                                    :placeholder="value_item | titleize"
                                >
                                <span class="u-mm">{{ addForm.single_select_v_models.used_currency ? addForm.single_select_v_models.used_currency.icon : '' }}</span>
                            </div>
                        </div>



                    </div>
                </asset-info-section>
            </div>

        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import multiSelect from '@/mixins/MultiSelect';
import {Currencies} from "../../form_data/Currencies";
import Resource from '@/api/resource';
const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');
import DatePicker from "vue2-datepicker";
import PoLines from "../../items/widgets/detailsViews/PoLines";

import {AcquisitionShortKeysList} from "@/views/assetflow/form_data/AcquisitionShortKeysList";
import AddNewAcquisition from "../../customers/extras/AddNewAcquisition";

export default {
    name: "AcquisitionInfo",
    mixins: [multiSelect],
    components: {AddNewAcquisition, PoLines, AssetInfoSection, DatePicker},
    props: ['addForm', 'required_fields', 'listQuery', 'my_lists_values', 'getAssetInfoUsingKey'],
    data(){
        return {
            acquisition_short_list_keys_list: AcquisitionShortKeysList,
            value_type: 0,
            value_type_options:[
                {
                    id: 0,
                    name: 'Value for 1 item'
                },
                {
                    id: 1,
                    name: 'Value for (x) items'
                }
            ],
            currencies_list: Currencies,
            used_lists_values: null,
        }
    },
    methods:{
        onClickRow(item, line_type){
            if (item){
                this.selected_item = item;
                this.$set(this.addForm.single_select_v_models, 'acquisition_id', item);
                let quantity = item.quantity ? item.quantity : this.addForm.item_details.quantity;
                this.$set(this.addForm.item_details, 'quantity', quantity);
                this.$set(this.addForm.single_select_v_models, 'used_currency', this.getCurrencyByValue(item.currency));
                this.$set(this.addForm.item_details, 'value', (item.assets_value > 0 ? ((item.assets_value / quantity).toFixed(2)) : null));
                this.$set(this.addForm.item_details, 'total_value', item.assets_value > 0 ? item.assets_value : null);
            }
        },
        shallCreateAcquisition(){
            this.$set(this.addForm.single_select_v_models, 'acquisition_id', null);
            this.$bvModal.show('create-acquisition');
        },
        addedNewAcquisition(new_item) {
            if (new_item) {
                let acquisition_list = [... this.used_lists_values.acquisition_id];
                acquisition_list.splice(0, 0, new_item);
                this.$set(this.used_lists_values, 'acquisition_id', acquisition_list);
                this.$set(this.addForm.single_select_v_models, 'acquisition_id', new_item);
                this.$bvModal.hide('create-acquisition');
            }
        },
        handleValueReadonly(acquisition_field) {
            return ((acquisition_field === 'total_value' && this.value_type === 0) || (acquisition_field === 'value' && this.value_type === 1));
        },
        handleValueChanging() {
            let item_details = this.addForm.item_details,
                quantity = item_details.quantity,
                value = item_details.value,
                total_value = item_details.total_value;

            if ((!value && !total_value) || (value + '' + total_value) === '') {
                return;
            }

            if (this.value_type === 0) {
                // for one item!
                this.addForm.item_details.total_value = value * quantity;
            } else {
                // for all
                this.addForm.item_details.value = total_value / quantity
            }
        },
        getValueTypeTitle(option) {
            if (option.id === 1) {
                return option.name.replace('(x)', this.addForm.item_details.quantity);
            }
            return option.name
        },
    },
    mounted() {
        if (this.$refs['acquisitions-list']){
            this.$refs['acquisitions-list'].onClickRow(this.selected_acquisition_id, 'acquisition');
        }
    },
    created() {
        this.used_lists_values = Object.assign({}, this.my_lists_values);

        this.handleValueChanging();
    },
    computed:{
        asset_quantity() {
            return this.addForm.item_details.quantity;
        },
        selected_acquisition_id() {
            return this.addForm.single_select_v_models.acquisition_id;
        },
        selected_acquisition_id_currency(){
            let currency = this.selected_acquisition_id.currency && this.selected_acquisition_id.currency !== '' ? this.selected_acquisition_id.currency : 'gbp';
            return this.getCurrencyByValue(currency);
        },
    },
    watch:{
        asset_quantity(value) {
            this.handleValueChanging();
        },
        value_type: {
            deep: true,
            handler: function (newValue) {
                this.handleValueChanging();
            }
        },
        selected_acquisition_id: {
            deep: true,
            handler: function (newValue) {
                if (this.$refs['acquisitions-list']){
                    this.$refs['acquisitions-list'].onClickRow(this.selected_acquisition_id, 'acquisition');
                }
            }
        },
    }
}
</script>

