export const TimeFormats = [
    {
        value: 'h:i A',
        text: "H:M 12hrs"
    },
    {
        value: 'H:i',
        text: "H:M 24hrs"
    },
    {
        value: 'h:i:s A',
        text: "H:M:S 12hrs"
    },
    {
        value: 'H:i:s',
        text: "H:M:S 24hrs"
    }
];
