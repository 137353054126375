
export default {
    data(){
        return {
        }
    },
    methods:{
        setSelectedLocationFor: function (address, direct_to_form=false, form_value='addForm') {
            this.getOptionsForKey('location_id');

            this.required_fields.location_id.options = this.required_fields.location_id.options.filter(function (e) {
                return e.id === -99 || (e.parent_address ? e.parent_address.id === address.id : false);
            });
            if (direct_to_form){
                this[form_value].location_id = this.getFirstOrDefault('location_id', 'is_primary');
            }else{
                this[form_value].single_select_v_models.location_id = this.getFirstOrDefault('location_id', 'is_primary');
            }

        },

        getFirstOrDefault(item_type, is_default) {
            return this.required_fields[item_type].options.find(obj => {
                return obj[is_default] === 1;
            });
        },
        getDefaultAndPrimary(direct_to_form = false) {
            let selected_address = this.getFirstOrDefault('address_id', 'is_default');

            if (direct_to_form){
                this.addForm.address_id = selected_address;
            }else{
                this.addForm.single_select_v_models.address_id = selected_address;
            }


            if (selected_address) {
                this.setSelectedLocationFor(selected_address);
            }
        },
    }
}
