<template>
    <SharedFormFieldsMultipleSections :add-form="addForm" :item_type="item_type" :required_fields="required_fields" :section_fields="section_fields" :object_type="object_type"/>
</template>

<script>


import SharedFormFieldsMultipleSections from "./SharedFormFieldsMultipleSections";

export default {
    name: "AssetsStep",
    components: {SharedFormFieldsMultipleSections},
    props:['item_type', 'addForm', 'required_fields', 'object_type'],
    data(){
        return {
            section_fields:{
                create_assets_automatically:{
                    name: 'Automatically Create Assets',
                    minimized: false,
                    fields: [
                        'create_assets_automatically',
                    ]
                },
                assets:{
                    name: 'Assets',
                    minimized: false,
                    fields: [
                        'asset_name',
                        'create_assets_number_of_assets',
                        'create_assets_asset_quantities',
                        'asset_notes',
                    ]
                },
                create_assets:{
                    name: 'Acquisition\\Asset Specific Functionality',
                    minimized: false,
                    fields: [
                        'create_assets_asset_distribution'
                    ]
                },
                usage:{
                    name: 'Usage',
                    minimized: true,
                    fields: [
                        'create_assets_usage',
                        'create_assets_asset_usage',
                        'create_assets_is_hero',
                        'create_assets_is_asset',
                    ]
                },
                structure:{
                    name: 'Structure',
                    minimized: true,
                    fields: [
                        'create_assets_folder_id',
                        'create_assets_category_id',
                        'create_assets_tag_ids',
                    ]
                },
                assignment:{
                    name: 'Assignment',
                    minimized: true,
                    fields: [
                        'create_assets_department_id',
                        'create_assets_contact_id',
                        'create_assets_address_id',
                        'create_assets_location_id',
                    ]
                },
                fdp:{
                    name: 'FDP',
                    minimized: true,
                    fields: [
                        'create_assets_final_disposition_planning',
                        'create_assets_confirm_fdp'
                    ]
                },
            }
        }
    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods:{
    }
}
</script>

