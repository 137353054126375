<template>
    <div class="custom-html-content">
        <slot name="first_row_content"></slot>

        <OrderLineRelatedObject
            v-if="!order_lines_without_info"
            :order_line="order_line"
            :index="index"
            :show-captiom-img="showCaptiomImg"
            :current_page_type="current_page_type"
        />
        <OrderLineRelatedAction
            v-if="!order_lines_without_info && order_line.status !== 4"
            :order_line="order_line"
            :index="index"
            :current_page_type="current_page_type">

            <template v-slot:order_lines_task_actions="{ order_line, index }">
                <slot name="order_lines_task_actions" v-bind:order_line="order_line" v-bind:index="index"></slot>
            </template>

        </OrderLineRelatedAction>
    </div>
</template>
<script>
import OrderLineRelatedObject from "./OrderLineRelatedObject";
import OrderLineRelatedAction from "./OrderLineRelatedAction";

export default {
    name: 'CustomOrderLineContent',
    components: {OrderLineRelatedAction, OrderLineRelatedObject},
    props:[
        'order_line',
        'order_lines_without_info',
        'index',
        'showCaptiomImg',
        'current_page_type'
    ]
}
</script>
