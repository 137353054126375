const FirstPath = window.location.pathname.split('/')[1];
export const WardrobeKeysList = {
    asset_name: {
        key: 'asset_name',
        label: "Qty & Asset Name",
        class: "col-lg-4 cw_asset_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return (item.quantity > 0 ? ('<span class="total_box_bg line-asset-quantity">'+item.quantity+'</span> x ') : '') + item.name;
        }
    },
    asset_brand: {
        key: 'asset_brand',
        label: "Brand",
        class: "col-lg-2 cw_asset_brand",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('asset_meta') && item.asset_meta){
                if (item.asset_meta.hasOwnProperty('brand') && item.asset_meta.brand){
                    return item.asset_meta.brand;
                }
            }
            return '-';
        }
    },
    asset_size: {
        key: 'asset_size',
        label: "Size",
        class: "col-lg-1 cw_asset_size",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('asset_meta') && item.asset_meta){
                if (item.asset_meta.hasOwnProperty('size') && item.asset_meta.size){
                    return item.asset_meta.size;
                }
            }
            return '-';
        }
    },
    colours: {
        key: 'colours',
        label: "Colours",
        class: "col-lg-1 cw_colours",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let colours_info = item.hasOwnProperty('colours_info') ? item.colours_info : null;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        }
    },
    character_id: {
        key: 'character_id',
        label: "Character",
        class: "col-lg-1 cw_character_id",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {}
    },
    internal_reference: {
        key: 'internal_reference',
        label: "Ref",
        class: "col-lg-1 cw_internal_reference",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    action_button: {
        key: 'action_button',
        label: "",
        class: "col-lg-1 ca_action_button",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_class: '',
        slot_name: 'action_button'
    },
    actions: {
        key: 'actions',
        label: "",
        class: "col-lg-1 cw_actions",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_class: '',
        slot_name: 'wardrobe_actions'
    }
};
