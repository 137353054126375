<template>
    <div class="intelligence-template">

        <AddMSFile
            ref="add_ms_files"
            received_modal_id="add_ms_files"
            modal_title="Add Files"
            @on-new-item-adding="refreshStatusAndStock">
        </AddMSFile>

        <UpdateImagesModal
            ref="edit-managed_storage-images"
            modal_id="managed_storage-images"
            :received_item_info="managed_storage"
            :item_type="managed_storage.current_page_type"
            @on-cost-updates="refreshStatusAndStock" />


        <div class="row mb-2">
            <div class="col-12">
                <p class="ms-notes">{{ managed_storage.notes }}</p>
            </div>
        </div>

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="ms_overview_tab" role="tabpanel" :class="isActiveTab('ms_overview_tab')">
                <ManagedStorageOverviewTab :managed_storage="managed_storage" box_unique_id="overview-right"/>
            </div>

            <div class="tab-pane" id="ms_activity_tab" role="tabpanel" :class="isActiveTab('ms_activity_tab')">
                <ManagedStorageActivityTab :item_info="managed_storage" :post_meta_keys="post_meta_keys"/>
            </div>
            <div class="tab-pane" id="ms_media_tab" role="tabpanel" :class="isActiveTab('ms_media_tab')">
                <media-tab :item_info="managed_storage" details_page_type="managed_storage" :perform-action="performAction">
                </media-tab>
            </div>
            <div class="tab-pane" id="ms_files_tab" role="tabpanel" :class="isActiveTab('ms_files_tab')">
                <NewCustomGenericBlock
                    block_title="Files"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <ObjectFilesTab
                            :item_info="managed_storage"
                            object_type="asset_files"
                            page_unique_name="managed_storage_files"
                            required_files="managed_storage_files"
                            :template_category_id="-99"
                            :perform-action="performAction"
                        />
                    </template>
                </NewCustomGenericBlock>
            </div>
            <div class="tab-pane" id="ms_orders_tab" role="tabpanel" :class="isActiveTab('ms_orders_tab')">
                <ManagedStorageOrdersInfo :item_info="managed_storage" :post_meta_keys="post_meta_keys"/>
            </div>
            <div class="tab-pane" id="ms_asset_passport_tab" role="tabpanel" :class="isActiveTab('ms_asset_passport_tab')">
                <asset-passport-tab
                    v-if="managed_storage.hasOwnProperty('asset_passport') && managed_storage.asset_passport"
                    :item_info="managed_storage.asset_passport"
                    :post_meta_keys="post_meta_keys"
                    :selected_asset_id="managed_storage.item_id"
                />
            </div>
            <div class="tab-pane" id="ms_intake_tab" role="tabpanel" :class="isActiveTab('ms_intake_tab')">
                <div class="row overview-block">
                    <custom-generic-block block_title="Intake Profiles" columns_count_withclasses="12">
                        <template v-slot:block_content>
                            <div class="col-12">
                                <po-lines
                                    v-if="managed_storage.intake_profile_info"
                                    :items="[managed_storage.intake_profile_info]"
                                    :current_page_type="managed_storage.current_page_type"
                                    object_type="intake_profiles"
                                    :keys_list="intake_profiles_list_keys_list">
                                </po-lines>
                                <span v-else>Nothing To Show.</span>
                            </div>
                        </template>
                    </custom-generic-block>
                </div>
            </div>
            <div class="tab-pane" id="ms_movements_tab" role="tabpanel" :class="isActiveTab('ms_movements_tab')">

                <div class="row overview-block">
                    <custom-generic-block block_title="Warehousing Movements" columns_count_withclasses="12">
                        <template v-slot:block_content>
                            <div class="col-12">
                                <po-lines
                                    v-if="managed_storage.warehousing_movement_info"
                                    :items="managed_storage.warehousing_movement_info"
                                    :current_page_type="managed_storage.current_page_type"
                                    object_type="warehousing_movements"
                                    :keys_list="warehousing_movements_list_keys_list">
                                </po-lines>
                                <span v-else>Nothing To Show.</span>
                            </div>
                        </template>
                    </custom-generic-block>
                </div>

            </div>
        </div>


    </div>
</template>
<script>

import {IntakeProfilesKeysList} from "@/views/assetflow/form_data/IntakeProfilesKeysList";
import {SPMovementKeysList} from "@/views/assetflow/form_data/SPMovementKeysList";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import CustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/CustomGenericBlock";

import AssetPassportTab from "../widgets/detailsViews/AssetTabs/AssetPassportTab";
import {PostMetaKeys} from "@/views/assetflow/form_data/PostMetaKeys";
import {MSPostMetaKeys} from "@/views/assetflow/form_data/MSPostMetaKeys";
import ManagedStorageOrdersInfo from "./ManagedStorageOrdersInfo";
import MediaTab from "../widgets/detailsViews/AssetTabs/MediaTab";
import ManagedStorageActivityTab from "./ManagedStorageActivityTab";
import ManagedStorageOverviewTab from "./ManagedStorageOverviewTab";
import NewCustomGenericBlock from "../widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import ObjectFilesTab from "../ObjectFilesTab";
import ManagedStorageShared from "@/mixins/ManagedStorageShared.mixin";
import AddMSFile from "./AddMSFile";
import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";

export default {
    name: 'CustomManagedStorageDetails',
    components: {
        UpdateImagesModal,
        AddMSFile,
        ObjectFilesTab,
        NewCustomGenericBlock,
        ManagedStorageOverviewTab,
        ManagedStorageActivityTab,
        MediaTab,
        ManagedStorageOrdersInfo,
        AssetPassportTab, CustomGenericBlock, PoLines},
    props: ['managed_storage'],
    mixins: [ManagedStorageShared],
    data(){
        return {
            active_tab: 'ms_overview_tab',
            tabs: {
                'ms_overview_tab': {
                    'id': "ms_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "ms_overview_tab",
                    'isActive': true
                },
                'ms_media_tab': {
                    'id': "ms_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "ms_media_tab",
                    'isActive': false
                },
                'ms_files_tab': {
                    'id': "ms_files_tab",
                    'name': "Files",
                    'icon': "user",
                    'type': "ms_files_tab",
                    'isActive': false
                },
                'ms_intake_tab': {
                    'id': "ms_intake_tab",
                    'name': "Intake",
                    'icon': "user",
                    'type': "ms_intake_tab",
                    'isActive': false
                },
                'ms_orders_tab': {
                    'id': "ms_orders_tab",
                    'name': "Orders",
                    'icon': "user",
                    'type': "ms_orders_tab",
                    'isActive': false
                },
                'ms_asset_passport_tab': {
                    'id': "ms_asset_passport_tab",
                    'name': "Asset Passport",
                    'icon': "user",
                    'type': "ms_asset_passport_tab",
                    'isActive': false
                },
                'ms_activity_tab': {
                    'id': "ms_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "ms_activity_tab",
                    'isActive': false
                },
                'ms_movements_tab': {
                    'id': "ms_movements_tab",
                    'name': "Movements",
                    'icon': "user",
                    'type': "ms_movements_tab",
                    'isActive': false
                },
            },
            intake_profiles_list_keys_list: IntakeProfilesKeysList,
            warehousing_movements_list_keys_list: SPMovementKeysList,
            permissions_list:{},
            object_type: 'orders',
            has_order_actions_permissions: false,
            post_meta_keys: PostMetaKeys,
            ms_post_meta_keys: MSPostMetaKeys,
        }
    },
    created() {

        let managed_storages_active_tab = localStorage.getItem('managed_storages_active_tab');

        if (!['sp', 'assetflow'].includes(this.managed_storage.current_page_type) && !this.is_cross_entity){
            delete this.tabs.ms_movements_tab;
            if (managed_storages_active_tab && ['ms_movements_tab'].includes(managed_storages_active_tab)){
                managed_storages_active_tab = '';
            }
        }

        if(!this.hasPermission('browse_files')){
            delete this.tabs.ms_files_tab;
            if (managed_storages_active_tab && 'ms_files_tab' === managed_storages_active_tab){
                managed_storages_active_tab = '';
            }
        }

        if (!this.managed_storage.hasOwnProperty('asset_passport')){
            delete this.tabs.ms_asset_passport_tab;
            if (managed_storages_active_tab && managed_storages_active_tab === 'ms_asset_passport_tab'){
                managed_storages_active_tab = '';
            }
        }

        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== '') {
            let selected_tab = queryVars.t;
            switch (selected_tab) {
                case 'overview':
                    this.active_tab = 'ms_overview_tab';
                    break;
                case 'activity':
                    this.active_tab = 'ms_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'ms_media_tab';
                    break;
                case 'files':
                    this.active_tab = 'ms_files_tab';
                    break;
                case 'orders':
                    this.active_tab = 'ms_orders_tab';
                    break;
                case 'asset_passport':
                    this.active_tab = 'ms_asset_passport_tab';
                    break;
                case 'intake':
                    this.active_tab = 'ms_intake_tab';
                    break;
                case 'movements':
                    this.active_tab = 'ms_movements_tab';
                    break;
            }
        } else if (managed_storages_active_tab && managed_storages_active_tab !== ''){
            this.active_tab = managed_storages_active_tab;
        }


        this.permissions_list['view_' + this.object_type] = this.hasPermission('browse_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);

        this.has_order_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['delete_' + this.object_type];


        if (this.managed_storage.current_page_type === 'sp'){
            if (this.is_tv_film_asset){
                this.intake_profiles_list_keys_list.customer_info.label = 'Production';
                this.warehousing_movements_list_keys_list.customer_info.label = 'Production';
            }
            delete this.intake_profiles_list_keys_list.sp_info;
            delete this.warehousing_movements_list_keys_list.sp_info;
        }else if (this.managed_storage.current_page_type === 'customer') {
            delete this.intake_profiles_list_keys_list.customer_info;
            delete this.warehousing_movements_list_keys_list.customer_info;
        }

    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('managed_storages_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }

}
</script>
