<template>
    <b-modal :id="'create-'+object_type"
             size="xl"
             :ref="'create-'+object_type"
             :title="selected_item && selected_item.hasOwnProperty('name') ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div v-if="selected_item && selected_item.hasOwnProperty('name')" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-info">
            <i class="mdi mdi-alert-circle-outline me-2"></i>
            These changes will only be applied to new items added to this Order. Existing items can be updated manually if required.
        </div>

        <OrderFormFields
            :item_type="item_type"
            :received_selected_item="selected_item"
            :received_order_type="order_type"
            :is_full="true"
            ref="add-new-order-fields"
            @after-saving-is-done="afterSaving"
            @on-cancel-clicked="$emit('on-cancel-clicked')"
        />

    </b-modal>
</template>

<script>
import OrderFormFields from "../orders/OrderFormFields";

export default {
    mixins: [],
    name: "AddNewOrder",
    props: ['modal_title', 'item_type', 'object_type', 'received_order_type'],
    components: {OrderFormFields},
    data(){
        return {
            selected_item: null,
            order_type: null
        }
    },
    created() {
        this.order_type = this.received_order_type;
    },
    methods:{
        resetSelectedItem(){
            this.selected_item = null;
        },
        setSelectedItem(item_info){
            if(item_info){
                this.order_type = item_info.order_type;
            }
            this.selected_item = item_info;
        },
        afterSaving(new_item, status){
            this.$bvModal.hide('create-' + this.object_type);
            if (status){
                this.$emit('on-new-item-adding', new_item);
            }
        }
    }
}
</script>


