<template>
    <div class="AcquisitionDispositionsTab">


        <div class="row">
            <NewCustomGenericBlock
                block_title="Summary"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>
                    <TotalsBlocksForAcquisitionDispositions
                        v-if="item_info.hasOwnProperty('dispositions_info') && item_info.dispositions_info && item_info.dispositions_info.length > 0"
                        :items_list="item_info.dispositions_info" total_property_name="total_value"/>
                </template>
            </NewCustomGenericBlock>
        </div>

        <div class="row">
            <NewCustomGenericBlock
                block_title="Dispositions"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>
                    <po-lines
                        v-if="item_info.hasOwnProperty('dispositions_info') && item_info.dispositions_info && item_info.dispositions_info.length > 0"
                        :items="item_info.dispositions_info"
                        :current_page_type="item_info.current_page_type"
                        :object_type="'dispositions'"
                        :keys_list="disposition_list_keys_list">
                    </po-lines>

                    <div v-else class="text-center">
                        <h6>No dispositions found here at the moment.</h6>
                    </div>

                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines";
import AssetActionsDropdown from "../assets/subItems/AssetActionsDropdown";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import LineItemTotalsBlocksForAcquisition from "./LineItemTotalsBlocksForAcquisition";

import {DispositionKeysList} from "@/views/assetflow/form_data/DispositionKeysList";
import TotalsBlocksForAcquisitionDispositions from "./TotalsBlocksForAcquisitionDispositions";

export default {
    name: 'AcquisitionDispositionsTab',
    components: {
        TotalsBlocksForAcquisitionDispositions,
        LineItemTotalsBlocksForAcquisition, NewCustomGenericBlock, AssetActionsDropdown, PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            disposition_list_keys_list: DispositionKeysList,
        }
    },
    created() {

        this.has_disposition_actions_permissions = this.hasPermission('browse_dispositions');
        if (this.has_disposition_actions_permissions){
            this.disposition_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 po_actions",
                show_on_mobile: false
            }
        }

        this.disposition_list_keys_list.total_value.title_func = this.toUserCurrencyValueTitle;
        this.disposition_list_keys_list.total_value.value_func = this.getCurrencyByValueText;

    },
    methods: {
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },

    }

}
</script>
