<template>
    <Layout>
        <div class="dispositions-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :received_filter_classes="filter_classes"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />
                <!-- Page-Content start-->
                <div id="page-content-wrapper">
                    <div class="page-content-inner">
                        <div>
                            <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                        </div>

                        <div class="product-page-header">
                            <div class="main-root-top" v-if="has_departments">
                                <h3 class="filter_option header-department" :class="[0,1].includes(departments_list.length) ? 'single-option' : ''">{{ selected_department.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;" v-if="departments_list.length !== 1">
                                    <li v-for="(department_item, index) in departments_list" :class="'root-top-menu' + (selected_department && selected_department.id === department_item.id ? ' active' : '')">
                                        <a @click="setSelectedDepartment(department_item)">
                                            {{ department_item ? department_item.name : '-' }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon" v-if="has_departments"></span>
                            <div class="main-root-top">
                                <h3 class="header-selected-item">Disposition List</h3>
                            </div>

                            <div class="filter-searchbar dropdown">
                                <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                            placeholder_text="Search Dispositions.."></search-box>
                                <b-dropdown v-if="hasPermission('add_dispositions')" dropleft
                                            class="add-filter" variant="danger">
                                    <template slot="button-content">
                                        <span class="add-filter-button"></span>
                                    </template>
                                    <b-dropdown-item v-if="hasPermission('add_dispositions')"
                                                     href="./assets/new">Disposition
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="page-value-info">
                                <ul class="d-flex align-items-center">
                                    <li class="d-flex align-items-center">
                                        Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="product-filter-option-wrapper clearfix d-flex">

                            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                    request_meta.from + '-' + request_meta.to
                                }}</span> of <span>{{ request_meta.total }}</span></div>

                            <div class="filter-wrap d-flex align-items-center ml-auto">


                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Sort: <b>{{ sorting.sortType.text }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in sorting.sortOptions"
                                        :key="option.value"
                                        @click="saveSorting(option, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>

                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Show: <b>{{
                                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                        }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in perpage.pageOptions"
                                        :key="option.value"
                                        @click="savePerPage(option.value, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                        <!-- Product Section Start-->

                        <div class="popup-gallery">
                            <vue-easy-lightbox
                                :visible="visibleCaption"
                                :imgs="captionimgs"
                                :index="index"
                                @hide="handleCaptionHide">
                            </vue-easy-lightbox>
                        </div>


                        <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                            <b-spinner class="text-af-accent m-2"></b-spinner>
                            <h4>Fetching Dispositions...</h4>
                        </div>
                        <div v-else-if="!dispositionsData || total === 0 || sortedItemsList.length === 0"
                             class="products text-center">
                            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                            <h4>No Dispositions found here at the moment.</h4>

                        </div>

                        <div v-else-if="dispositionsData && selectedLayout === 0" class="row">
                            <div class="col-lg-12" v-if="selectedLayout === 0">
                                <po-lines
                                    :items="sortedItemsList"
                                    :current_page_type="item_type"
                                    :object_type="'dispositions'"
                                    :keys_list="disposition_list_keys_list">
                                </po-lines>
                            </div>
                        </div>


                        <div v-else-if="dispositionsData && selectedLayout === 1" class="cm_list_view_wrapper">
                            <div class="cm_list_view_wrapper">
                                <DispositionListView
                                    v-for="(disposition, dispositionKey) in sortedItemsList"
                                    :key="dispositionKey"
                                    :item_type="item_type"
                                    :item_key="dispositionKey"
                                    :item_info="disposition"
                                    :show-captiom-img="showCaptiomImg"
                                    :perform-action="performAction"
                                />

                            </div>
                        </div>

                        <!-- Product Section End-->
                        <div class="pagination">
                            <b-pagination
                                v-if="perpage.perPage !== 0"
                                v-model="perpage.currentPage"
                                :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                class="pagination d-flex align-items-center justify-content-center"
                                :per-page="perpage.perPage"
                            ></b-pagination>
                        </div>

                    </div>
                </div>
                <!-- Page-Content end-->

            </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";

import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";

import {DispositionKeysList} from "@/views/assetflow/form_data/DispositionKeysList";
import DispositionListView from "./DispositionListView";

const dispositionResource = new Resource('api/assetflow/dispositions');
import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";

import AMDepartmentShared from "@/mixins/AMDepartmentShared.mixin";
export default {
    name: 'DispositionsList',
    mixins: [TotalsAndCurrencies, ImageGallery, ObjectsSidebarShared, AMDepartmentShared],
    props: ['item_id', 'item_type'],
    components: {ObjectsSidebar, LotsOfItemsAlert, DispositionListView, PoLines, SearchBox, Layout, VueEasyLightbox},
    data() {
        return {
            listLoading: false,
            disposition_list_keys_list: DispositionKeysList,
            total: 0,
            dispositionsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Dispositions",
            items: [
                {
                    text: "Dispositions",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_disposition_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            selectedLayout: 0,
            supportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                grid: {
                    name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
                }
            },
            permission_name: 'dispositions',
            object_type: 'dispositions',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
            ],
            items_list: [
                [],
            ],
            filter_classes: {
                '0': 'disposition-all',
            },

        };
    },
    created() {
        this.has_disposition_actions_permissions = this.hasPermission('delete_dispositions');

        if (this.has_disposition_actions_permissions){
            this.disposition_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 d_actions",
                show_on_mobile: false,
                actions_list: ['delete']
            }
        }

        this.disposition_list_keys_list.total_value.title_func = this.toUserCurrencyValueTitle;
        this.disposition_list_keys_list.total_value.value_func = this.getCurrencyByValueText;


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_dispositions_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }



        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_'+this.object_type + '_layout')) {
            this.selectedLayout = window.USER_INFO.user_meta['preferred_'+this.object_type + '_layout'];
        }

    },
    computed: {
        totalAssetsValue() {
            return this.dispositionsData ? this.dispositionsData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_'+this.object_type + '_layout', layout.value, message);
            this.selectedLayout = layout.value
        },

        performAction(disposition, action, dispositionKey, event){
            if (action === 'edit'){
                this.$refs['add-new-disposition'].setSelectedItem(disposition);
                this.$bvModal.show('create-disposition');
                this.setupAddressLookup('disposition');
            }else if (action === 'delete'){
                if (disposition.id !== '' && disposition.assets_count === 0){
                    this.deleteItemByID(disposition.id, disposition.name, 'dispositions', event)
                    .then((response) => {
                        if (response.data.data.status){
                            this.dispositionsData.splice(dispositionKey, 1);
                        }else{
                            console.log("response.data");
                            console.log(response.data);
                        }
                    });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
             return this.dispositionsData;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('disposition_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_dispositions_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null, changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };


            if (this.search_keyword){
                required_query.s_keyword = this.search_keyword;
            }

            this.listLoading = true;

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await dispositionResource.list(required_query);

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.dispositionsData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                // console.log("no meta info");
            }
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-disposition'].setSelectedItem(null);
            this.$bvModal.show('create-disposition');
            this.setupAddressLookup('disposition');
        }
    },
}
</script>


