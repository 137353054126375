<template>
    <div class="cm_list_view CharacterListView">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'character_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p">
                                    <div>
                                        <a v-b-tooltip.bottom :title="'UID: ' + item_info.uid" :href="'/' + item_type + '/characters/' + item_info.id + '/view'">
                                            <span class="character-title">({{ item_info.character_number }}) {{ item_info.name }}</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title"></div>
                            <div class="cm_status_ul character_details_qty">
                                <ul>
                                    <li>
                                        <p>Scenes <span class="total_box_bg">{{ item_info.hasOwnProperty('scenes_count') && item_info.scenes_count !== '' ? item_info.scenes_count : '-' }}</span></p>
                                    </li>
                                    <li>
                                        <p>Changes <span class="total_box_bg">{{ item_info.hasOwnProperty('changes_count') && item_info.changes_count !== '' ? item_info.changes_count : '-' }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Actor</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span>{{ item_info.actor_name}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Ledger</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('ledger_info') && item_info.ledger_info" :href="'/' + item_type + '/ledgers/' + item_info.ledger_id + '/view'"
                                               :title="item_info.uid"
                                               v-b-tooltip.bottom
                                            >
                                                {{ item_info.ledger_info.nominal_code + ': ' + item_info.ledger_info.description }}
                                            </a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Assets</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span><a :href="'/' + item_type + '/characters/' + item_info.id + '/view?t=assets'">View {{ item_info.assets_count > 0 ? item_info.assets_count : '' }} Assets</a></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Acquisitions</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span><a :href="'/' + item_type + '/characters/' + item_info.id + '/view?t=assets'">View Acquisitions</a></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div>
                                        <ul>

                                            <li v-for="(change, key) in item_info.changes_info" :key="key">
                                                <a class="ch-ch-latest-scene-name"
                                                    v-if="change.hasOwnProperty('latest_part_info') && change.latest_part_info && change.latest_part_info.hasOwnProperty('part_info')" :href="'/' + item_type + '/parts/' + change.latest_part_info.part_info.id + '/view'">{{ PartDetailsItem(change.latest_part_info.part_info)}}</a>
                                                <a class="ch-ch-name"
                                                    :href="'/' + item_type + '/changes/' + change.id + '/view'">Change #{{change.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cm_list_last_update mt-1" v-if="item_info.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText()"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">

            <div v-if="hasPermission('edit_characters') || hasPermission('delete_characters')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">

                    <b-dropdown-item v-if="hasPermission('edit_characters')"
                                     @click="performAction(item_info, 'edit', item_key, $event)">
                        Edit
                    </b-dropdown-item>
                    <b-dropdown-item v-if="hasPermission('edit_characters')"
                                     @click="performAction(item_info, 'set_measurements', item_key, $event)">
                        Set Measurements
                    </b-dropdown-item>
                    <b-dropdown-item v-if="hasPermission('delete_characters')"
                                     variant="danger"
                                     @click="performAction(item_info, 'delete', item_key, $event)">
                        Delete
                    </b-dropdown-item>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import MyBCarouselSlide from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'CharacterListView',
    components: {MyBCarouselImages, MyBCarouselSlide},
    props: [
        'item_type',
        'item_key',
        'item_info',
        'showCaptiomImg',
        'performAction',
    ],
    methods:{
        PartDetailsItem({ id, name }){
            if (id === -99){
                return name;
            }
            return name || '';
        },
        getPostText(){
            if (this.item_info.hasOwnProperty('latest_post_update')){
                let user = this.item_info.latest_post_update.user;
                let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(this.asset, this.item_info.latest_post_update, '', this.item_type) + " on " + Vue.options.filters.moment(this.item_info.latest_post_update.created_at , 'DD/MM/YYYY');
            }
            return '';
        }
    }
}
</script>
