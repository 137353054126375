const FirstPath = window.location.pathname.split('/')[1];
export const TransactionKeysList = {

    payment_date: {
        key: 'payment_date',
        label: "Date",
        class: "col-lg-1 a_payment_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 a_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/' + item.item_type +'/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    source: {
        key: 'source',
        label: "Source",
        class: "col-lg-1 p_source",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    ledger_id: {
        key: 'ledger_id',
        label: "Ledger",
        class: "col-lg-1 a_ledger_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('ledger_info') && item.ledger_info){
                return '<a class="text-dark" href="/' + FirstPath + '/ledgers/'+item.ledger_info.id+'/view">' + item.ledger_info.description + '</a>';
            }
            return '-';
        }
    },
    supplier_id: {
        key: 'supplier_id',
        label: "Entity",
        class: "col-lg-2 a_supplier_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('supplier_info') && item.supplier_info){
                return '<a class="text-dark" href="/' + FirstPath + '/suppliers/'+item.supplier_info.id+'/view">' + item.supplier_info.name + '</a>';
            }
            return '-';
        }
    },
    document_id: {
        key: 'document_id',
        label: "Document",
        class: "col-lg-1 a_document_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('document_info') && item.document_info){
                return '<a class="text-dark" href="/' + FirstPath + '/suppliers/'+item.document_info.id+'/view">' + item.document_info.name + '</a>';
            }
            return '-';
        }
    },
    payment_ledger_id: {
        key: 'payment_ledger_id',
        label: "Account",
        class: "col-lg-2 a_payment_ledger_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('payment_ledger_info') && item.payment_ledger_info){
                return '<a class="text-dark" href="/' + FirstPath + '/ledgers/'+item.payment_ledger_info.id+'/view">' + item.payment_ledger_info.description + '</a>';
            }
            return '-';
        }
    },
    reconciled: {
        key: 'reconciled',
        label: "Reconciled",
        class: "col-lg-1 p_reconciled",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },

    cost: {
        key: 'cost',
        label: "Value",
        class: "col-lg-1 p_cost",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: function () {},
        value_func: function () {},
    },
    actions: {
        key: 'actions',
        label: "Actions",
        class: "col-lg-1 a_actions",
        show_on_mobile: false,
        case: ''
    }
};
