<template>
    <b-modal :id="'create-'+object_type"
             :title="modal_title"
             size="xl"
             title-class="font-18"
             hide-footer>

        <AddNewForm v-if="!isLoadingItem"
            item_id=""
            :item_type="current_page_type"
            source_type="modal"
            object_type="acquisition"
            object_type_link="acquisitions"
            object_type_shortcut="ACQ"
            action=""
            :with_sidebar="false"
            :received_lists_values.sync="lists_values"
            @on-save-item="savedItem"
            @on-cancel-item="$bvModal.hide('create-'+object_type)"
        />
    </b-modal>
</template>

<script>
import AddNewForm from "../acquisitions/AddNewForm";
export default {
    name: "AddNewAcquisition",
    components: {AddNewForm},
    props:['object_type', 'object_type_link', 'object_type_shortcut', 'modal_title', 'current_page_type'],
    data(){
        return {
            isLoadingItem: false
        }
    },
    created() {
    },
    methods: {
        savedItem(item){
            this.$emit('on-modal-save', item);
        },
    }

}
</script>


