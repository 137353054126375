<template>
    <div v-if="dashboard_info">
        <h4>Dashboard</h4>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <ul class="summary-log-items">
                            <li>Assets: <b>{{ dashboard_info.items_count }}</b></li>
                            <li>Qty: <b>{{ dashboard_info.total_quantity }}</b></li>
                            <li>Total Value: <span><b v-b-tooltip:hover.bottom :title="toUserCurrencyValue('gbp', dashboard_info.total_cost)">{{ dashboard_info.total_currency }}{{ dashboard_info.total_cost }}</b></span></li>
                            <li>Totals by Status:
                                <ol class="mt-3">
                                    <li v-for="status_info in dashboard_info.totals_by_status" v-if="status_info.status !== ''">{{ status_info.status | titleize }}: <span><b v-b-tooltip:hover.bottom :title="toUserCurrencyValue('gbp', status_info.net_total)">{{ dashboard_info.total_currency }}{{ status_info.net_total }}</b></span></li>
                                </ol>
                            </li>
                            <li>Ownership Status:
                                <ol class="mt-3">
                                    <li v-for="ownership_status_info in dashboard_info.totals_by_ownership">{{ ownership_status_info.ownership_status === 1  ? 'Current' : 'Ended' }}: <span><b v-b-tooltip:hover.bottom :title="toUserCurrencyValue('gbp', ownership_status_info.net_total)">{{ dashboard_info.total_currency }}{{ ownership_status_info.net_total }}</b></span></li>
                                </ol>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else class="products text-center">
        <b-spinner class="text-af-accent m-2"></b-spinner>
        <h4>Fetching Dashboard...</h4>
    </div>
</template>
<script>
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";

export default {
    name: 'AssetsDashboardTab',
    mixins: [TotalsAndCurrencies],
    props: ['dashboard_info']
}
</script>
