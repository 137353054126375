<template>
    <div v-if="user_info && user_info.hasOwnProperty('can_bulk_update')">

        <div v-if="user_info.can_bulk_update === 2" class="alert alert-success" role="alert">
            <div class="row">
                <div class="col-12 text-right">
                    <a style="color:unset;" href="javascript:void(0);"
                       @click="enableBulkActions($event)">
                        Enable Bulk Actions for your account
                    </a>
                </div>
            </div>
        </div>
        <div v-else-if="user_info.can_bulk_update === 1" class="alert alert-success" role="alert"
             :class="is_actions_bar_opened ? 'af-enabled-selecting af-primary' : 'af-disabled-selecting af-accent'">
            <div class="row" :class="is_actions_bar_opened ? 'text-af-accent' : 'text-af-primary'">
                <div class="col-6" v-if="is_actions_bar_opened && required_bulk_actions_customer_id">
                    <template v-if="selected_objects">
                        <span>{{selected_objects.length}} Items Selected</span>
                        <span>
                            <a style="color:unset;"
                               href="javascript:void(0);"
                               @click="continueClicked($event)"
                               v-if="selected_objects.length > 0"> | <b>Continue</b>
                            </a>
                            <a style="color:unset;"
                               v-else-if="required_bulk_actions_customer_id"
                               href="javascript:void(0);"
                               @click="continueClicked($event)"
                            > | <b>Continue with All {{ all_items_count }} Items</b>
                            </a>
                        </span>
                        <span>
                            <a style="color:unset;"
                               href="javascript:void(0);"
                               @click="continueClicked($event, 'export_item')"
                               v-if="selected_objects.length > 0"> | <b>Export</b>
                            </a>
                            <a style="color:unset;"
                               v-else-if="required_bulk_actions_customer_id"
                               href="javascript:void(0);"
                               @click="continueClicked($event, 'export_item')"
                            > | <b>Export All {{ all_items_count }} Items</b>
                            </a>
                        </span>
                    </template>
                </div>
                <div class="col-6" v-else-if="is_actions_bar_opened">
                    <span class="bu-no-customer-defined">Please select a Customer to enable Bulk Actions</span>
                </div>
                <div class="col-6" v-else></div>

                <div class="col-6 text-right">
                    <span v-if="is_selecting_items && selected_objects.length > 0">
                        <a style="color:unset;" href="javascript:void(0);"
                           @click="performSelectionAction('clear_selections')">
                            Clear Selections
                        </a> |
                    </span>
                    <span v-if="(is_selecting_items && selected_objects.length > 0) || should_hide_unselected" >
                        <a style="color:unset;" href="javascript:void(0);"
                           class="control-unselected"
                           :class="should_hide_unselected ? 'unselected-hidden' : 'unselected-shown'"
                           @click="performSelectionAction('hide_unselected')">
                            {{should_hide_unselected ? 'Show' : 'Hide' }} Unselected
                        </a> |
                    </span>
                    <a style="color:unset;" href="javascript:void(0);"
                       @click="shouldToggleSelecting($event)">
                        Turn {{ is_actions_bar_opened ? 'Off' : 'On' }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BulkActionsBar',
    props: [
        'is_actions_bar_opened',
        'is_selecting_items',
        'shouldToggleSelecting',
        'selected_objects',
        'continueClicked',
        'performSelectionAction',
        'should_hide_unselected',
        'enableBulkActions',
        'all_items_count',
        'required_bulk_actions_customer_id',
    ]
}
</script>
