<template>
    <Layout ref="mylayout">
        <div class="cm_dashboard_page_block">
            <!-- Content Goes here-->
            <!-- Start here -->
            <div class="cm_goodmoring_tristian_block">
                <div class="cm_common_asset_box">
                    <img class="my_avatar" :src="current_user_info.avatar ? current_user_info.avatar : '/images/users/avatar-1.jpg'" alt="image" style="width: 115px;height: 115px;">
                    <h3 class="cm_gm_tris_title" v-if="current_user_info && current_user_info.hasOwnProperty('name')">{{ new Date().greeting() }}, {{ current_user_info.name }}...</h3>
                </div>


                <b-alert v-if="this.warning !== ''"
                         :show="dismissCountDown"
                         dismissible
                         variant="warning"
                         @dismissed="dismissCountDown=0"
                         @dismiss-count-down="countDownChanged"
                         class="mt-3">{{this.warning}}</b-alert>


                <div class="cm_common_ordering_block">
                    <div class="cm_ordering_inner_row">
                        <div class="row">
                            <div class="col-md-6 col-xl-3" v-if="has_multiple_entity_types">
                                <div class="cm_common_table_box">
                                    <div class="cm_common_table_heading af-primary">
                                        <h4><i class="fa fa-star"></i> You are currently viewing:</h4>
                                    </div>
                                    <div class="cm_common_confirm_detail">
                                        <div class="st-status-radio-box">
                                            <div class="st-radio-row">
                                                <div v-for="(entity, key) in available_entities"
                                                     :key="key"
                                                     class="col-md-6 col-xl-6 p-1">
                                                    <div class="cm-radio-btn entity-radio-btn" :class="'entity-' + entity.id">
                                                        <label>
                                                            <input
                                                                v-model="selected_entity_id"
                                                                :key="key"
                                                                :id="'_'+key"
                                                                name="selected_entity_id"
                                                                :value="entity.id"
                                                                @input="entityChanged(entity)"
                                                                type="radio" />
                                                            <div class="st-radio-box entity-radio-box">
                                                                <span></span>
                                                                <p>{{ entity.label | titleize }}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" :class="multiple_entity_types_class">

                                <EntityInfo
                                    :is_assetflow="is_assetflow"
                                    :selected_entity="selected_entity"
                                    :current_entity_info="current_entity_info"
                                />

                            </div>

                            <div class="col-md-6" :class="multiple_entity_types_class">
                                <div class="cm_common_table_box">
                                    <div class="cm_common_table_heading af-primary">
                                        <h4><i class="bx bx-user-check"></i> Current Role</h4>
                                    </div>
                                    <div class="cm_common_confirm_detail">
                                        <div class="st-status-radio-box" v-if="modules_list.length > 0">
                                            <div class="st-radio-row">
                                                <div v-for="(module, key) in modules_list"
                                                     :key="key"
                                                     class="col-md-6 col-xl-6 p-1">
                                                    <div class="cm-radio-btn entity-radio-btn module-radio-btn" :class="'module-' + module.id">
                                                        <label>
                                                            <input
                                                                v-model="current_module_id"
                                                                :key="'module_'+key"
                                                                :id="'module_'+key"
                                                                name="current_module"
                                                                @input="setModule(module)"
                                                                :value="module.id"
                                                                type="radio" />
                                                            <div class="st-radio-box entity-radio-box module-radio-box">
                                                                <i v-if="module.icon" :class="module.icon"></i> <p class="ml-1">{{`${module.name}`}}</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="no-module-message">
                                            You do not have any modules, please switch to a different {{ selected_entity.label.toLowerCase() }} or contact support.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" :class="multiple_entity_types_class">
                                <div class="cm_common_table_box">
                                    <div class="cm_common_table_heading af-primary">
                                        <h4><i class="bx bx-user"></i> My Profile</h4>
                                        <a href="/profile" class="text-white">Change</a>
                                    </div>

                                    <div class="cm_common_list_detail">
                                        <span>Name</span><p>{{ current_user_info.name }}</p>
                                        <span>Email</span><p>{{ current_user_info.email }}</p>
                                        <span>Password</span>
                                        <p><a href="/profile">Change Password</a></p>
                                        <span>My Roles</span>
                                        <p><a href="/my_roles">View My Roles</a></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!is_assetflow">
            <switch-entity :item_type="selected_entity.item_type" :with_header="true"/>
        </div>
    </Layout>
</template>

<script>
import SwitchEntity from "./SwitchEntity";
import Layout from "@/layouts/main";
import EntityInfo from "./EntityInfo";
import {DateFormats} from "@/views/assetflow/form_data/DateFormats";

Date.prototype.greeting = function(){
    let time = this.getHours();
    return 'Good '+ (time<12 ? 'Morning' : time<18 ? 'Afternoon' : 'Evening');
};
export default {
name: "Dashboard",
    components: {EntityInfo, SwitchEntity, Layout},
    data(){
        return{
            warning: "",
            dismissSecs: 5,
            dismissCountDown: 5,
            selected_entity_id: 0,
            current_entity_info: null,
            available_entities:[
                {
                    id: 0,
                    item_type: 'customer',
                    label: 'Customer'
                },
                {
                    id: 1,
                    item_type: 'group',
                    label: 'Group'
                },
                {
                    id: 2,
                    item_type: 'sp',
                    label: 'Service Provider'
                },
                {
                    id: 3,
                    item_type: 'assetflow',
                    label: 'Asset Flow'
                },
            ],
            date_formats: DateFormats,
            modules_list: [],
            current_module_id: null,

        }
    },

    created() {
        this.warning = window.AF_WARNING;

        this.modules_list = window.MODULES_LIST ? window.MODULES_LIST : [];
        this.current_module_id = this.current_module ? this.current_module.id : null;


        if (this.is_tv_film_asset){
            this.available_entities[0].label = 'Production';
        }

        let current_user_info = this.current_user_info;
        if (current_user_info && current_user_info.hasOwnProperty('user_meta') &&
            current_user_info.user_meta &&
            current_user_info.user_meta.hasOwnProperty('selected_entity_id') &&
            current_user_info.user_meta.selected_entity_id){
            this.selected_entity_id = current_user_info.user_meta.selected_entity_id;
        }

        if (!current_user_info.isSuperAdmin){
            if (this.selected_entity_id === 3){
                this.selected_entity_id = 0;
            }
            this.available_entities.splice(3, 1);
        }
        if (!current_user_info.can_switch_sp){
            if (this.selected_entity_id === 2){
                this.selected_entity_id = 0;
            }
            this.available_entities.splice(2, 1);
        }
        if (!current_user_info.can_switch_group){
            if (this.selected_entity_id === 1){
                this.selected_entity_id = 0;
            }
            this.available_entities.splice(1, 1);
        }

        this.setCurrentEntity(this.selected_entity);

    },
    computed:{

        current_date_format(){
            if (this.current_user_info.user_meta){
                let selected_date_format = this.current_user_info.user_meta.date_format;
                if (selected_date_format !== ''){
                    let date_obj = this.getObjectOf(selected_date_format, this.date_formats, 'value');
                    if (date_obj){
                        return date_obj.text;
                    }
                }
            }
            return 'DD/MM/YYYY';
        },
        is_assetflow(){
            return this.selected_entity.id === 3;
        },
        selected_entity(){
            return this.getObjectOf(this.selected_entity_id, this.available_entities);
        },
        has_multiple_entity_types(){
            return this.available_entities.length > 1;
        },
        multiple_entity_types_class(){
            return this.has_multiple_entity_types ? 'col-xl-3' : 'col-xl-4';
        }
    },
    methods:{
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        setCurrentEntity: function (entity) {
            switch (entity.id) {
                case 0:
                    this.current_entity_info = this.current_customer;
                    break;
                case 1:
                    this.current_entity_info = this.current_group;
                    break;
                case 2:
                    this.current_entity_info = this.current_sp;
                    break;
                case 3:
                    this.current_entity_info = this.current_assetflow;
                    break;
                default:
                    break;
            }

            if ('mylayout' in this.$refs){
                let layout_type = this.$refs.mylayout.layoutType;
                this.$refs.mylayout.$refs[layout_type + 'Layout'].$refs.mySideBar.$refs.mySideNav.refreshMenu();
            }

        },
        setModule(module) {
            this.current_module_id = module.id;
            let message = "Saved New Module {"+module.name+"} Successfully";
            window.flash(message);
            this.set_usermeta('current_module', module.id, "", true);
        },
        entityChanged(entity){
            let success_message = "Saved Entity {" + entity.label + "} Successfully";
            window.flash(success_message);
            this.set_usermeta('selected_entity_id', entity.id, "", true);
        }
    }
}
</script>

<style>
.entity-radio-btn label [type="radio"]:checked + .entity-radio-box p{
    color: white
}
</style>

