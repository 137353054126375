const FirstPath = window.location.pathname.split('/')[1];
export const ReceiptKeysList = {
    uid: {
        key: 'id',
        label: "ID",
        class: "col-lg-2 rct_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    source: {
        key: 'source',
        label: "Source",
        class: "col-lg-1 rct_source",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    source_type: {
        key: 'source_type',
        label: "Source Type",
        class: "col-lg rct_source_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    supplier_item_type: {
        key: 'supplier_item_type',
        label: "Supplier Type",
        class: "col-lg-1 rct_supplier_item_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    payment_method: {
        key: 'payment_method',
        label: "Payment Method",
        class: "col-lg-2 rct_payment_method",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    cost: {
        key: 'cost',
        label: "Cost",
        class: "col-lg-2 rct_cost",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    reconciled: {
        key: 'reconciled',
        label: "Reconciled",
        class: "col-lg-2 rct_reconciled",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },
    reference: {
        key: 'reference',
        label: "Reference",
        class: "col-lg-2 rct_reference",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] && item[key] !== '' ? item[key] : '-';
        }
    },
};
