<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <h3>
            Customer Info.
        </h3>

    </Layout>
</template>

<script>
    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";

    export default {
        props:['item_id', 'item_type', 'action'],
        name: "ViewCustomer",
        components: { Layout, PageHeader },
        data() {
            return {
                title: "View ",
                items: [
                    {
                        text: "Customers",
                        href: "/"
                    },
                    {
                        text: "View Customer",
                        active: true
                    }
                ],
            };
        },
        created() {
            this.title = this.title + this.item_type + ' - ' + this.item_id + ' - ' + this.action;
        },
        methods: {

        }
    }
</script>


