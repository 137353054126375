const FirstPath = window.location.pathname.split('/')[1];
export const DispositionMetaKeys = {
    name:{
        label: 'Name',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    ownership:{
        label: 'Ownership',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    details:{
        label: 'Details',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    disposition_date:{
        label: 'Date',
        cast_value: function (item, key) {
            if (item.disposition_date){
                return Vue.options.filters.moment(item.disposition_date, 'MMMM Do YYYY');
            }
            return '';
        }
    },
    movement_info:{
        label: 'Movement',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    quantity:{
        label: 'Qty',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    currency:{
        label: 'Currency',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    value:{
        label: 'Value',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    total_value:{
        label: 'Total Value',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    reference:{
        label: 'Ref',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    recipient_id:{
        label: 'Recipient',
        cast_value: function (item, key) {
            if (item.recipient_id){
                return '<a class="text-dark" href="/' + FirstPath + '/' + item.recipient_type + '/' + item[key] + '/view">' + Vue.options.filters.titleize(item.recipient_name) + '</a>';
            }
            return '';
        }
    },
    assignments:{
        label: 'Assignments',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    contact_id:{
        label: 'Contact',
        cast_value: function (item, key) {
            if (item.contact_id){
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + item[key] + '/view">' + Vue.options.filters.titleize(item.contact_name) + '</a>';
            }
            return '';
        }
    },
    department_id:{
        label: 'Department',
        cast_value: function (item, key) {
            if (item.department_id){
                return '<a class="text-dark" href="/' + FirstPath + '/departments/' + item[key] + '/view">' + Vue.options.filters.titleize(item.department_name) + '</a>';
            }
            return '';
        }
    },
    address_id:{
        label: 'Address',
        cast_value: function (item, key) {
            if (item.address_id){
                return '<a class="text-dark" href="/' + FirstPath + '/addresses/' + item[key] + '/view">' + Vue.options.filters.titleize(item.address_name) + '</a>';
            }
            return '';
        }
    },
    location_id:{
        label: 'Location',
        cast_value: function (item, key) {
            if (item.address_id && item.location_id){
                return '<a class="text-dark" href="/' + FirstPath + '/addresses/' + item.address_id + '/view">' + Vue.options.filters.titleize(item.location_name) + '</a>';
            }
            return '';
        }
    },
    user_id:{
        label: 'Created By',
        cast_value: function (item, key) {
            if (item.user_id){
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + item[key] + '/view">' + Vue.options.filters.titleize(item.user_name) + '</a>';
            }
            return '';
        }
    },
};
