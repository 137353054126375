<template>
    <div class="AcquisitionTransactionsTab">
        <po-lines v-if="item_info.hasOwnProperty('payments_info') && item_info.payments_info && item_info.payments_info.length > 0"
                  :items="item_info.payments_info"
                  :current_page_type="item_info.current_page_type"
                  :object_type="'payments'"
                  :line_type="'payments'"
                  :keys_list="payment_list_key_list">
        </po-lines>
        <div v-else class="text-center">
            <h6>No payments found here at the moment.</h6>
        </div>
    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines";
import {TransactionKeysList} from "@/views/assetflow/form_data/TransactionKeysList";

export default {
    name: 'AcquisitionTransactionsTab',
    components: {PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            payment_list_key_list:TransactionKeysList,
        }
    },
    created() {
        this.has_actions_permissions = this.hasPermission('edit_payments') || this.hasPermission('delete_payments');

        this.payment_list_key_list.cost.title_func = this.toUserCurrencyValueTitle;
        this.payment_list_key_list.cost.value_func = this.getCurrencyByValueText;

    },
    methods: {
        performAction(item, action_name, event) {
        },
    }

}
</script>
