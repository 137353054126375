<template>
    <div :class="used_classes">

        <add-asset-to-order
            :ref="add_to_order_id"
            :received_modal_id="add_to_order_id"
            :item_type="item_info.current_page_type"
            object_type="asset"
            @on-modal-save="refreshPage(add_to_order_id)"
        />
        <ChangeAssetFolder
            :ref="change_folder_id"
            :received_modal_id="change_folder_id"
            :item_type="item_info.current_page_type"
            :item_info="item_info"
        />

        <ChangeAssetGroup
            :ref="change_group_id"
            :received_modal_id="change_group_id"
            :item_type="item_info.current_page_type"
            :received_item_info="item_info"
        />


        <UpdateCostsModal
            :ref="update_asset_costs_id"
            :modal_id="update_asset_costs_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />

        <UpdateDetailsModal
            :ref="update_asset_details_id"
            :modal_id="update_asset_details_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />

        <UpdateDepartmentModal
            :ref="update_asset_department_id"
            :modal_id="update_asset_department_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />

        <UpdateSupplierModal
            :ref="update_asset_supplier_id"
            :modal_id="update_asset_supplier_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />

        <UpdateImagesModal
            :ref="update_asset_images_id"
            :modal_id="update_asset_images_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />

        <ReturnAssetModal
            :ref="return_asset_id"
            :modal_id="return_asset_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />

        <SellAssetModal
            :ref="sell_asset_id"
            :modal_id="sell_asset_id"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="refreshAsset" />


        <split-assets-modal
            :with_refresh="true"
            :ref="split_asset_id"
            :modal_id="split_asset_id"
            :selected_asset="item_info"
            @after-split-asset="refreshPage(split_asset_id)"
        />

        <duplicate-assets-modal
            :with_refresh="true"
            :ref="duplicate_asset_id"
            :modal_id="duplicate_asset_id"
            :selected_asset="item_info"
            @after-duplicate-asset="refreshPage(duplicate_asset_id)"
        />


        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="change_usage_id"
            sub_modal_id="right-box"
            :custom_keys_only="true"
            modal_title="Complete task"
            @on-post-adding="postAdded"
            @on-completing-task="taskCompleted"
        />

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <div class="cm_product_more_btn">
            <div v-if="hasPermission('edit_assets') || hasPermission('delete_assets')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>

                <AssetsActionsList :item="item_info" :item_type="item_info.current_page_type" :perform-action="performAction"/>
            </div>
        </div>

        <div class="asset-character-name" v-if="character_info">
            <a :href="'/' + item_info.current_page_type + '/characters/'+character_info.id+'/view'" class="badge text-af-primary af-soft-accent font-size-11 m-1">({{ character_info.character_number || '' }}) {{ character_info.name }}</a>
        </div>

        <div class="asset-reference" v-if="item_info.internal_reference"><span class="badge text-af-primary af-soft-accent font-size-11 m-1">{{ item_info.internal_reference }}</span></div>

        <AssetIconList :my_asset="item_info" :item_type="item_info.current_page_type" :perform-action="performAction"/>

        <MyBCarouselImages parent_class="cm_product_grid_img" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_pro_status_tag">
                    <a href="#" :class="'status-btn status-btn-' + item_info.status" v-html="getAssetStatusFrom(item_info)"></a>
                </div>
                <div class="cm_status_ul asset_details_qty">
                    <ul>
                        <li>
                            <p>Total <span class="total_box_bg">{{ item_info.quantity }}</span></p>
                        </li>
                        <li v-if="(selected_filtering_module_id || current_module.id) !== 3">
                            <p>Available <span class="available_box_bg">{{ item_info.available_quantity || item_info.quantity }}</span></p>
                        </li>
                        <li v-if="(selected_filtering_module_id || current_module.id) !== 3">
                            <p>Reserved <span class="reserved_box_bg">{{ item_info.reserved_quantity || 0 }}</span></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="common_col_desc asset-right-box-description" v-if="item_info.description && item_info.description !== ''">
            <p>{{ item_info.description }}</p>
        </div>

        <div class=" cm_asset_view_tag">
            <div class="tag-wrap list-show">
                <h3 class="cm_asset_tag_title">Tags</h3>
                <ul v-if="item_info.tags_info">
                    <li v-for="tag in item_info.tags_info" class="d-inline-block">
                        <span><a :href="'/' + item_info.current_page_type + '/tags/' + tag.id + '/view'">{{ tag.name }}</a></span>
                    </li>
                </ul>
            </div>

            <tags-manager
                class="d-inline-block"
                ref="tags-manager2"
                :product="item_info"
                :all_tags_list="all_tags_list"
                :asset_key="0"
                @on-add-tag="myAddTag"
                @on-remove-tag="myRemoveTag"
            ></tags-manager>

        </div>

    </div>
</template>
<script>

import AssetActions from "@/mixins/assetActions.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";
import TagsManager from "@/views/assetflow/assets/subItems/TagsManager";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "./AssetPassportBlocks/MyBCarouselSlide";
import AssetsActionsList from "../../../../assets/subItems/AssetsActionsList";
import AddAssetToOrder from "../../../../customers/extras/AddAssetToOrder";
import ChangeAssetFolder from "./ActionModals/ChangeAssetFolder";
import SplitAssetsModal from "../../../../customers/extras/SplitAssetsModal";
import ChangeAssetGroup from "./ChangeAssetGroup";
import AssetIconList from "../../../../assets/subItems/AssetLayouts/AssetIconList";
import AssetTasks from "@/mixins/AssetTasks.mixin";
import GenericTasksForm from "./GenericTasksForm";
import UpdateCostsModal from "../../../../assets/assetForm/UpdateCostsModal";
import UpdateDetailsModal from "../../../../assets/assetForm/UpdateDetailsModal";
import UpdateImagesModal from "../../../../assets/assetForm/UpdateImagesModal";
import ReturnAssetModal from "../../../../assets/assetForm/ReturnAssetModal";
import MyBCarouselImages from "../../../../assets/subItems/AssetLayouts/MyBCarouselImages";
import SellAssetModal from "../../../../assets/assetForm/SellAssetModal";
import DuplicateAssetsModal from "../../../../customers/extras/DuplicateAssetsModal";
import UpdateDepartmentModal from "../../../../assets/assetForm/UpdateDepartmentModal";
import UpdateSupplierModal from "../../../../assets/assetForm/UpdateSupplierModal";

export default {
    name: 'AssetRightBox',
    mixins: [AssetTasks, AssetActions, TotalsAndCurrencies, TagsManagerMixin, ImageGallery],
    components: {
        UpdateSupplierModal,
        UpdateDepartmentModal,
        DuplicateAssetsModal,
        SellAssetModal,
        MyBCarouselImages,
        ReturnAssetModal,
        UpdateImagesModal,
        UpdateDetailsModal,
        UpdateCostsModal,
        GenericTasksForm,
        AssetIconList,
        ChangeAssetGroup,
        SplitAssetsModal,
        ChangeAssetFolder,
        AddAssetToOrder,
        AssetsActionsList,
        MyBCarouselSlide,
        TagsManager,
        VueEasyLightbox
    },
    props: {
        item_info: {},
        unique_id: '',
        used_classes:''
    },
    data() {
        return {
            add_to_order_id:'',
            change_folder_id:'',
            split_asset_id:'',
            duplicate_asset_id:'',
            change_group_id:'',
            update_asset_costs_id:'',
            update_asset_details_id:'',
            update_asset_department_id:'',
            update_asset_supplier_id:'',
            update_asset_images_id:'',
            return_asset_id:'',
            sell_asset_id:'',
            change_usage_id:'',
        }
    },
    created() {
        this.add_to_order_id = 'add-to-order-' + this.unique_id;
        this.change_folder_id = 'change-folder-' + this.unique_id;
        this.split_asset_id = 'split-asset-' + this.unique_id;
        this.duplicate_asset_id = 'duplicate-asset-' + this.unique_id;
        this.change_group_id = 'change-group-' + this.unique_id;
        this.update_asset_costs_id = 'update-costs-' + this.unique_id;
        this.update_asset_details_id = 'update-details-' + this.unique_id;
        this.update_asset_department_id = 'update-department-' + this.unique_id;
        this.update_asset_supplier_id = 'update-supplier-' + this.unique_id;
        this.update_asset_images_id = 'update-images-' + this.unique_id;
        this.return_asset_id = 'return-asset-' + this.unique_id;
        this.sell_asset_id = 'sell-asset-' + this.unique_id;
        this.change_usage_id = 'change-usage-' + this.unique_id;
    },
    computed: {
        unit_value(){
            return this.item_info.value ? this.item_info.value : '-';
        },
        total_value(){
            return this.item_info.total_value ? this.item_info.total_value : '-';
        },
        character_info(){
            return this.item_info.character_info ? this.item_info.character_info : null;
        },
    },
    methods:{
        refreshPage(modal_id){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            location.reload();
        },
        refreshAsset(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (required_keys){
                for (const requiredKeysKey in required_keys) {
                    let required_key = required_keys[requiredKeysKey];
                    if (['asset_images', 'colours_info', 'colours', 'asset_meta', 'disposition_info', 'latest_post_update'].includes(required_key)){
                        if (required_key === 'latest_post_update' && Array.isArray(edited_asset[required_key]) && edited_asset[required_key].length === 1){
                            edited_asset[required_key] = edited_asset[required_key][0];
                        }
                        this.$set(this.item_info, required_key, edited_asset[required_key]);
                    }else{
                        this.item_info[required_key] = edited_asset[required_key];
                    }
                }
            }else{
                this.item_info = edited_asset;
            }

        },
        performAction(item, action_name, event) {

            switch (action_name) {
                case 'deleteAsset':
                    this.deleteItem(item.id, item.name, 'assets', event, 'flash');
                    break;
                case 'addToOrder':
                    this.$refs[this.add_to_order_id].set_item(item);
                    this.$bvModal.show(this.add_to_order_id);
                    break;
                case 'resetGroup':
                    let message = "Removed {"+item.name+"} from Group Successfully";
                    this.set_item_value('assets', item.id, 'asset_group_id', null, message, false);
                    this.item_info.asset_group_id = null
                    break;
                case 'changeFolder':
                    this.$refs[this.change_folder_id].set_item(item);
                    this.$bvModal.show(this.change_folder_id);
                    break;
                case 'splitAsset':
                    this.$bvModal.show(this.split_asset_id);
                    break;
                case 'duplicateAsset':
                    this.$bvModal.show(this.duplicate_asset_id);
                    break;
                case 'changeGroup':
                    this.$refs[this.change_group_id].set_item(item);
                    this.$bvModal.show(this.change_group_id);
                    break;
                case 'show_usage_action':
                    this.completeTask(null, this.change_usage_id, 'asset_usage, is_hero, is_asset', item);
                    break;
                case 'addToCharacter':
                    this.completeTask(null, this.change_usage_id, 'character_id,ledger_id', item);
                    break;
                case 'addToCloset':
                    this.completeTask(null, this.change_usage_id, 'closet_character_id', item);
                    break;
                case 'updateCosts':
                    this.$bvModal.show(this.update_asset_costs_id);
                    break;
                case 'changeDepartment':
                    this.$bvModal.show(this.update_asset_department_id);
                    break;
                case 'changeSupplier':
                    this.$bvModal.show(this.update_asset_supplier_id);
                    break;
                case 'updateDetails':
                    this.$bvModal.show(this.update_asset_details_id);
                    break;
                case 'updateImages':
                    this.$bvModal.show(this.update_asset_images_id);
                    break;
                case 'returnAsset':
                    this.$bvModal.show(this.return_asset_id);
                    break;
                case 'sellAsset':
                    this.$bvModal.show(this.sell_asset_id);
                    break;
            }
        }
    }
}
</script>
