<template>
    <b-modal :id="received_modal_id"
             title="Register Disposition"
             title-class="font-18"
             size="xl"
             @hide="$emit('on-modal-cancel')"
             hide-footer>


        <RegisterDispositionFields
            :required_fields="required_fields"
            :received_modal_id="received_modal_id"
            :other_info="edited_other_info"
            :item_type="item_type"
            @on-registering-disposition="onRegisteringDisposition"
            :cancel-clicked="cancelClicked"
            :get-asset-info-using-key="getAssetInfoUsingKey"
            :shall_load_lists="true"
            :isRegisteringDisposition="isRegisteringDisposition"
        />


    </b-modal>

</template>
<script>
import Resource from '@/api/resource';
import RegisterDispositionFields from "./RegisterDispositionFields";

export default {
    components: {RegisterDispositionFields},
    mixins: [],
    name: 'RegisterDispositionModal',
    props: ['required_fields', 'other_info', 'received_modal_id', 'item_type', 'shall_add'],
    data(){
        return {
            edited_other_info: {},
            my_asset: {},
            isRegisteringDisposition: false,
        }
    },
    created() {
        this.edited_other_info = this.other_info;
    },
    methods:{
        getAssetInfoUsingKey(asset_key){
            return this.my_asset[asset_key];
        },
        onRegisteringDisposition(disposition_info){
            if (this.shall_add === true){
                // add then hide and continue
                this.registeringDisposition(disposition_info);
            }else{
                this.$bvModal.hide(this.received_modal_id);
                this.$emit('on-registering-disposition', disposition_info);
            }
        },
        async registeringDisposition(disposition_info){
            // add then hide and continue
            this.isRegisteringDisposition = true;
            let params = {
                asset_id: this.my_asset.id,
                customer_id: this.my_asset.customer_id,
                item_details:{
                    disposition_info: disposition_info,
                }
            };

            let that = this;
            axios({
                method: 'put',
                url: '/api/assetflow/assets/' + this.my_asset.id,
                baseURL: '/',
                data: params,
            }).then(response => {
                if (response){
                    let data = response.data.data;

                    let status = data.status;
                    let new_item = data.items;

                    that.presentToast((status ? "Success!" : "Error!"),
                        status ? 'Registered Successfully' : data.data.msg,
                        (status ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    that.$bvModal.hide(this.received_modal_id);
                    that.$emit('on-registering-disposition', this.received_modal_id, new_item);
                }
            }).finally(()=> {
                this.isRegisteringDisposition = false;
            })
        },
        set_item(asset){
            this.my_asset = asset;
            this.edited_other_info = { used_currency: asset.used_currency };
        }

    }
}
</script>
