<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? getTitleFor(selected_item, 'changes') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>


        <ChangeCommonFields :add-form="addForm" :required_fields="required_fields"/>

        <div class="text-right">
            <b-button type="submit" variant="success" :disabled="isSavingItem" v-on:click.once="addItem($event)">
                <span v-show="!isSavingItem"> Update  </span>
                <div v-show="isSavingItem" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Saving...</span>
                </div>
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFields from "./ChangeCommonFields";

const changeResource = new Resource('api/assetflow/changes/editChange');
import ChangeFormsMixin from "@/mixins/ChangeFormsMixin";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";


export default {

    name: "EditChangeNotes",
    props: ['modal_title', 'item_type'],
    mixins: [ChangeFormsMixin, dropzoneMixin],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                change_status: null,
                description: '',
                filled_asset_images:[],
                asset_images:[],
            },
            required_fields:{
                change_status: {
                    key: 'change_status',
                    label: 'Status',
                    type: 'radio-select',
                    options:[
                        {id: 0, name: 'Planned'},
                        {id: 1, name: 'Established'}
                    ]
                },
                description: {
                    key: 'description',
                    label: 'Change Notes',
                    type: 'textarea'
                },
                change_images: {
                    key: 'change_images',
                    label: 'Images',
                    type: 'change_images'
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('change_status', this.selected_item.change_status, 0);
                this.addForm.description = this.selected_item.description;

                this.required_fields.change_images.label = 'Add More Images';
            }
        },
        resetFormValues(){
            this.required_fields.change_images.label = 'Images';
            this.addForm = {
                change_status: this.required_fields.change_status.options[0],
                description: '',
                filled_asset_images:[],
                asset_images:[],
            };
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.resetFormValues();
            }
        },

        async addItem(event){
            this.isSavingItem = true;
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (!all_files){
                return;
            }

            var params = {
                change_status: this.addForm.change_status ? this.addForm.change_status.id : 0,
                description: this.addForm.description,
                asset_images: all_files,
                edit_type : 'change_notes'
            };
            if (this.selected_item && this.selected_item.id !== -99){
                params.item_id = this.selected_item.id;
            }

            const { data } = await changeResource.store(params);


            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = (this.selected_item && this.selected_item.id !== -99 ? 'Edited' : 'Added') + ' Successfully';
                status_class = 'success';
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

            this.isSavingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();

            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>
