<template>
    <Layout>
        <AddNewForm
            :item_id="item_id"
            :item_type="item_type"
            object_type="acquisition"
            object_type_link="acquisitions"
            object_type_shortcut="ACQ"
            :action="action"
            :with_sidebar="true"
            :received_lists_values="lists_values"
        />
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import AddNewForm from "./AddNewForm";
export default {
    name: "NewAcquisition",
    props: ['item_id', 'item_type', 'action'],
    components: {AddNewForm, Layout},
    data() {
        return {}
    },
    created() {

        let form_lists = this.$root.form_lists;
        this.lists_values = {
            department_id: form_lists.departments_list,

            acquisition_type: form_lists.acquisition_types_list,
            ownership_type: form_lists.ownership_type_list,

            nominal_code_id: form_lists.nominal_codes_list,
            payment_nominal_code_id: form_lists.nominal_codes_list,

            supplier_id: form_lists.suppliers_list,

            purchase_order_id: form_lists.purchase_orders_list,
            payment_purchase_order_id: form_lists.purchase_orders_list,

            tracking_reconciliation_by: form_lists.contacts_list,

            create_assets_folder_id: form_lists.folders_list,
            category_id: form_lists.categories_list,
            acquisition_category_id: form_lists.acquisition_categories_list,
            create_assets_category_id: form_lists.categories_list,
            create_assets_tag_ids: form_lists.tags_list,
            create_assets_department_id: form_lists.departments_list,
            create_assets_contact_id: form_lists.contacts_list,
            create_assets_address_id: form_lists.addresses_list,
            create_assets_location_id: form_lists.locations_list,
            create_assets_final_disposition_planning: form_lists.final_disposition_planning,

            currency: form_lists.currencies_list,

        }
    },
    methods:{

    }
}
</script>


