<template>
    <div class="step-form-content-block row">

        <add-new-acquisition-type
            custom_id="acquisition_form"
            ref="add-new-acquisition-type"
            item_type='acquisition_type'
            modal_title="Create New Acquisition Type"
            :my_lists_values="my_lists_values"
            @on-new-item-adding="addNewItemToOptions"
        >
        </add-new-acquisition-type>


        <div class="col-xl-12">
            <asset-info-section
                title="Details"
                :selected_fields="section_fields"
                :add-form="addForm"
                :required_fields="required_fields">
                <div class="step-common-info-block">
                <div class="st--common--box" v-for="section_field in section_fields">
                    <div class="common-st-title-label">
                        <h5>{{ required_fields[section_field].name | titleize}}</h5>
                    </div>
                    <div class="st-status-radio-box" v-if="required_fields[section_field].type === 'radio-select'">
                        <div :class="'st-radio-row ' + section_field+'-radio'" v-if="required_fields[section_field].options.length > 0">
                            <div v-for="(option, index) in required_fields[section_field].options"
                                 :key="index"
                                 class="col-3">
                                <div class="cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                    <label>
                                        <input
                                            v-model="addForm.item_details[section_field]"
                                            :key="index"
                                            :id="'_'+index"
                                            :name="section_field"
                                            :disabled="section_field === 'ownership_type'"
                                            :value="option"
                                            @click="changedRadioValue(section_field, option, $event)"
                                            type="radio" />
                                        <div class="st-radio-box">
                                            <span></span>
                                            <p>{{ (option.name || option.description) | titleize }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="st-radio-row" v-else-if="required_fields[section_field].hasOwnProperty('name_plural') && required_fields[section_field].hasOwnProperty('link_name')">
                            <div class="col-12">
                                There are no {{ required_fields[section_field].name_plural | titleize}} yet, <a :href="'/' + item_type + '/' + required_fields[section_field].link_name">Add New?</a>
                            </div>

                        </div>
                    </div>

                    <div v-else-if="required_fields[section_field].type === 'date'"
                         class="common--input-filed">
                        <date-picker
                            v-model="addForm.item_details[section_field]"
                            lang="en" :first-day-of-week="1"
                            format="DD-MM-YYYY"
                            value-type="DD-MM-YYYY"
                            :placeholder="required_fields[section_field].name | titleize" />
                    </div>

                    <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'text'">
                        <input v-model="addForm.item_details[section_field]" placeholder="" type="text">
                    </div>
                </div>

            </div>
            </asset-info-section>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "../../../assets/assetForm/AssetInfoSection";
import DatePicker from "vue2-datepicker";
import AddNewAcquisitionType from "../../extras/AddNewAcquisitionType";
export default {
    name: "DetailsStep",
    components: {AddNewAcquisitionType, AssetInfoSection, DatePicker},
    mixins:[],
    props:['item_type', 'addForm', 'required_fields', 'object_type', 'my_lists_values'],
    data(){
        return {
            section_fields:[
                'acquisition_type',
                'ownership_type',
                'acquired_date',
                'end_date',
                'nominal_code_id'
            ]
        }
    },
    mounted() {
    },
    created() {
        this.setupFields();
    },
    computed: {
        acquisition_type(){
            return this.addForm.item_details.hasOwnProperty('acquisition_type') ? this.addForm.item_details.acquisition_type : null;
        },
    },
    watch: {
        acquisition_type:{
            deep: true,
            handler: function(newValue){
                if (newValue){
                    let index = this.required_fields.ownership_type.options.map(function(e) { return e.id; }).indexOf(newValue.ownership_type);
                    this.addForm.item_details.ownership_type = this.required_fields.ownership_type.options[index];
                    this.setupFields();
                }
            }
        },
    },
    methods:{
        changedRadioValue(field, option, event) {
            if (option.id === -99) {
                // shall add new
                let custom_id = '';
                switch (field) {
                    case 'acquisition_type':
                        this.$refs['add-new-acquisition-type'].setSelectedItem(null);
                        custom_id = 'acquisition_form';
                        break;
                }
                this.addForm.item_details[field] = null;
                this.$bvModal.show('create-' + custom_id + field.replace('_id', ''));
            }
        },
        addNewItemToOptions(new_item, item_type){
            if (new_item) {
                // should append this to the list
                let item_type_key = item_type;
                let new_option = {id: new_item.id, name: new_item.name};
                if (item_type === 'acquisition_type'){
                    this.addForm.added_acquisition_types.push(new_option.id);
                }
                this.required_fields[item_type_key].options.push(new_option);

                this.addForm.item_details[item_type_key] = new_option;
            }
        },

        setupFields(){

            // TODO?

            let index = this.section_fields.indexOf('end_date');

            if (this.addForm.item_details.hasOwnProperty('ownership_type') && this.addForm.item_details.ownership_type && this.addForm.item_details.ownership_type.id === 0){
                if (index === -1) {
                    this.section_fields.splice(-1, 0, 'end_date');
                }
            }else{
                if (index > -1){
                    this.section_fields.splice(index,1);
                }
            }
        }
    }
}
</script>

