<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form.sync="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "pg_inheritance",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    data(){
        return {
            addForm:{
                customer_inheritance: 0,
            },
            sidebar_navigation: {
                inheritance: {
                    label: 'Inheritance',
                    settings: {
                        customer_inheritance: {
                            id: 'customer_inheritance',
                            label: 'Inheritance',
                            functionality: 'settings',
                            status: true
                        }
                    }
                },
            }
        }
    },
    created() {
        let required_meta_keys = [
            'customer_inheritance',
        ];
        for (const key in required_meta_keys) {
            let meta_key = required_meta_keys[key];
            this.addForm[meta_key] = this.settings_dict.hasOwnProperty(meta_key) ? this.settings_dict[meta_key] : 0;
        }
        this.addForm = Object.assign({}, this.addForm);
    }
}
</script>
