<template>
    <div class="row">
        <div class="col-md-3">
            <div class="cm_usage border_r">
                <span>MS ID</span>
                <p>
                    <span v-if="asset">{{asset.uid}}</span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="cm_usage">
                <span>Reference</span>
                <p>
                    <span v-if="asset && asset.item_reference">
                        {{ asset.item_reference }}
                    </span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="cm_usage border_r">
                <span>Condition</span>
                <p>
                    <span
                        v-if="intake_profile_info && intake_profile_info.hasOwnProperty('condition')">
                        {{ getConditionText }}
                    </span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="cm_usage">
                <span>Colour</span>
                <p>
                    <span v-html="getColourHTML"></span>
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="cm_usage">
                <span>Height</span>
                <p>
                    <span
                        v-if="asset.object_item_type === 0 && intake_profile_info && intake_profile_info.hasOwnProperty('height') && intake_profile_info.height">
                        {{ (parseFloat(intake_profile_info.height)).toFixed(2) }}{{ intake_profile_info.preferred_unit }}
                    </span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="cm_usage">
                <span>Width</span>
                <p>
                    <span
                        v-if="asset.object_item_type === 0 && intake_profile_info && intake_profile_info.hasOwnProperty('width') && intake_profile_info.width">
                        {{ (parseFloat(intake_profile_info.width)).toFixed(2) }}{{ intake_profile_info.preferred_unit }}
                    </span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="cm_usage">
                <span>Depth</span>
                <p>
                    <span
                        v-if="asset.object_item_type === 0 && intake_profile_info && intake_profile_info.hasOwnProperty('depth') && intake_profile_info.depth">
                        {{ (parseFloat(intake_profile_info.depth)).toFixed(2) }}{{ intake_profile_info.preferred_unit }}
                    </span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>

        <div class="col-md-3">
            <div class="cm_usage">
                <span>Area\Volume</span>
                <p>
                    <span
                        v-if="asset.object_item_type === 0 && intake_profile_info && intake_profile_info.hasOwnProperty('area')">
                        {{ (intake_profile_info.area * (asset.status === 0 ? asset.dispatched_quantity : asset.total_quantity)).toFixed(2) }}m<sup>2</sup>
                    </span>
                    <span v-else>-</span>
                    <span
                        v-if="asset.object_item_type === 0 && intake_profile_info && intake_profile_info.hasOwnProperty('volume')">
                        {{ (intake_profile_info.volume * (asset.status === 0 ? asset.dispatched_quantity : asset.total_quantity)).toFixed(2) }}m<sup>3</sup>
                    </span>
                    <span v-else>-</span>
                </p>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'ManagedStorageIntakePPInfo',
    props: {
        asset: {},
        getColourHTML: {},
        getConditionText: {}
    },
    computed:{
        intake_profile_info(){
            return this.asset.hasOwnProperty('item_info') && this.asset.item_info;
        },
    }
}
</script>
