<template>
    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab v-for="(tab, index) in tabs" :key="index" :active="tab.isActive">
            <template v-slot:title>
                <span class="d-inline-block d-sm-none" v-iconize-icon="tab.name.toLowerCase()"></span>
                <span class="d-none d-sm-inline-block" v-iconize="tab.name.toLowerCase()">{{ tab.name }}</span>
            </template>

            <ListEntityTab v-if="['sps', 'modules', 'roles', 'users', 'customers', 'sp_customers', 'entity_subscribers'].includes(tab.type)" :item_info="item_info" :tab="tab"/>

            <div v-else class="AdministrationDashboardTemplate">
                <DashboardEntityRow
                    v-for="(entity_info, index) in entity_info_list"
                    :key="index"
                    :entity_info="entity_info"
                    :item_info="item_info"
                    :index="index"/>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>

import DashboardBlockTable from "../DashboardBlockTable";
import DashboardEntityRow from "./DashboardEntityRow";
import SharedModuleDashboard from "@/mixins/SharedModuleDashboard.mixin";
import ListEntityTab from "./ListEntityTab";

export default {
    mixins: [SharedModuleDashboard],
    name: 'AdministrationDashboardTemplate',
    components: {ListEntityTab, DashboardEntityRow, DashboardBlockTable},
    props:{
        item_info: {},
        tabs: {}
    },
    created() {
        if (this.item_info) {
            let item_info = this.item_info;
            let current_page_type = '/' + item_info.current_page_type;

            let entity_block_title = '',
                group_logo_name = '',
                entity_users_count='',
                entity_modules_count='',
                entity_roles_count='',
                entity_entities_count='',
                entity_entities_label='',
                entity_entities_object_name='',
                entity_entities_tab_name='',
                industry_classification_block = '';


            if (item_info.item_type === 'service_providers'){
                entity_block_title = 'Service Provider';

                entity_users_count = item_info.hasOwnProperty('users_count') ? item_info.users_count : 0;
                entity_modules_count = item_info.hasOwnProperty('entity_modules_count') ? item_info.entity_modules_count : 0;
                entity_roles_count = item_info.hasOwnProperty('roles_count') ? item_info.roles_count : 0;
                entity_entities_count = item_info.hasOwnProperty('customers_count') ? item_info.customers_count : 0;
                entity_entities_label = 'Customers';
                entity_entities_object_name = 'customers';
                entity_entities_tab_name = 'sp_customers';

            }else if (item_info.item_type === 'customers'){

                let sector_ids = item_info.sector_ids;
                entity_block_title = 'Customer';

                if (sector_ids && sector_ids.includes('1')) {
                    entity_block_title = 'Production';
                    industry_classification_block = '<div class="cm_common_list_detail"><p class="heading-text">Film &amp; TV</p></div>';
                }

                group_logo_name = item_info.hasOwnProperty('parent_group') ? this.getEntityLogoAndName(item_info.parent_group) : 'No Group';

                entity_users_count = (item_info.hasOwnProperty('active_users_count') && item_info.hasOwnProperty('inactive_users_count')) ? (item_info.active_users_count + item_info.inactive_users_count) : 0;
                entity_modules_count = item_info.hasOwnProperty('entity_modules_count') ? item_info.entity_modules_count : 0;
                entity_roles_count = item_info.hasOwnProperty('customer_roles_count') ? item_info.customer_roles_count : 0;
                entity_entities_count = item_info.hasOwnProperty('customer_warehouses_count') ? item_info.customer_warehouses_count : 0;
                entity_entities_label = 'Service Providers';
                entity_entities_object_name = 'service_providers';
                entity_entities_tab_name = 'sps';
            }

            let entity_logo_name = this.getEntityLogoAndName(item_info);
            let style = '';
            if (item_info.hasOwnProperty('meta') && item_info.meta) {
                let item_meta = item_info.meta;
                if (item_meta.hasOwnProperty('primary_colour') && item_meta.primary_colour) {
                    let bg_colour = item_meta.primary_colour;
                    let text_colour = item_meta.accent_colour;
                    this.item_info.style = 'background-color: ' + bg_colour + ' !important; color:' + text_colour + ' !important;display: flex!important;';
                    style = 'style="'+this.item_info.style+'"';
                }
            }

            let entity_overview_link = current_page_type + '/' + item_info.item_type + '/' + item_info.id + '/edit';
            let current_page_link = item_info.hasOwnProperty('current_url') ? item_info.current_url : window.location.protocol + "//" + window.location.host + window.location.pathname;

            let entity_currency = this.getObjectForKey('currency', false, true);
            let required_currency = '-';
            if (entity_currency) {
                required_currency = entity_currency.icon + ' - ' + entity_currency.text;
            }
            let customer_details_colours = '<div class="entity-overview-colours"><a href="" class="text-dark">' + '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" ' + style + '>' + item_info.initials + '</span></div></a></div>';

            let customer_details_block = '<div class="cm_common_list_detail"><span>Currency</span><p>' + required_currency + '</p> <span>Branding</span>' + customer_details_colours + ' </div>';

            let main_logo = (window.AF_SITE_LOGO ? window.AF_SITE_LOGO : '/images/logo-dark.png') + '/inside/300/200';
            let site_name = (window.AF_NAME ? window.AF_NAME : (process.env.MIX_SITE_NAME ? process.env.MIX_SITE_NAME : 'Asset Flow'));
            let logo_image = '<img src="' + main_logo + '" alt="' + site_name + '" width="' + this.image_width + '"/>';

            let assetflow_info_block = '<div class="entity-assetflow_info_block">' + logo_image + '</div>';
            let created_at = Vue.options.filters.moment(item_info.created_at, 'DD/MM/YYYY') + ' (' + Vue.options.filters.fromNow(item_info.created_at) + ')';

            let entity_info_block = '<div class="entity-entity_info_block">' +
                '<div class="cm_common_list_detail">' +
                '<span>Entity Type</span>' +
                '<p>' + entity_block_title + '</p>' +
                '<span>Entity ID</span>' +
                '<p>' + item_info.id + '</p>' +
                '<span>Created Date:</span>' +
                '<p>' + created_at + '</p>' +
                '</div>' +
                '</div>';

            let has_edit_permission = this.hasPermission('edit_' + item_info.item_type);
            entity_overview_link = has_edit_permission ? '<a href="' + entity_overview_link + '">Edit</a>' : '';

            this.entity_info_list = {
                objects: {
                    title: 'Modules, Users & Roles',
                    blocks: {
                        modules_info: {
                            name: 'Modules',
                            content: '',
                            action: '<a href="' + current_page_link + '?t=modules">View All ' + entity_modules_count + ' Modules</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            header_action_text: 'Popular',
                            header_action_class: 'split-header-sort',
                            object_type: 'modules',
                            object_type_icon: 'iconize',
                            table_headers: ['Module', 'No of Roles', 'No of Users'],
                            required_items: item_info.hasOwnProperty('modules_info') ? item_info.modules_info : []
                        },
                        users_info: {
                            name: 'Users',
                            content: '',
                            action: '<a href="' + current_page_link + '?t=users">View All ' + entity_users_count + ' Users</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            header_action_text: 'Recent',
                            header_action_class: 'split-header-sort',
                            object_type: 'users',
                            object_type_icon: 'fa fa-user',
                            table_headers: ['Name', 'Email', 'View'],
                            required_items: item_info.hasOwnProperty('users_info') ? item_info.users_info : []
                        },
                        roles_info: {
                            name: 'Roles',
                            content: '',
                            action: '<a href="' + current_page_link + '?t=roles">View All ' + entity_roles_count + ' Roles</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            header_action_text: 'Popular',
                            header_action_class: 'split-header-sort',
                            object_type: 'roles',
                            object_type_icon: 'iconize',
                            table_headers: ['Role', 'Module', 'No of Users'],
                            required_items: item_info.hasOwnProperty('roles_info') ? item_info.roles_info : []
                        },
                    }
                },
                sp_info: {
                    title: entity_entities_label,
                    blocks: {
                        sp_info: {
                            name: entity_entities_label,
                            content: '',
                            action: '<a href="' + current_page_link + '?t='+entity_entities_tab_name+'">View All ' + entity_entities_count + ' '+entity_entities_label+'</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            header_action_text: 'Recent',
                            header_action_class: 'split-header-sort',
                            object_type: entity_entities_object_name,
                            object_type_icon: 'iconize',
                            table_headers: ['Name', 'Status'],
                            required_items: item_info.hasOwnProperty('entities_info') ? item_info.entities_info : []

                        }
                    }
                },
                overview: {
                    title: item_info.name + ' Overview',
                    blocks: {
                        customer_info: {
                            name: entity_block_title,
                            content: entity_logo_name,
                            action: entity_overview_link,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                        customer_details: {
                            name: 'Details',
                            content: customer_details_block,
                            action: entity_overview_link,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                        group_info: {
                            name: 'Group',
                            content: group_logo_name,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                    }
                },
                statistics:{
                    title: 'Statistics',
                    blocks: {},
                },
                info_for_sp:{
                    title: 'SP\\' + entity_block_title + ' Assignment',
                    blocks: {},
                },
                assetflow_info: {
                    title: 'Asset Flow',
                    blocks: {
                        assetflow_info: {
                            name: 'Asset Flow',
                            content: assetflow_info_block,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                        entity_info: {
                            name: 'Entity Details',
                            content: entity_info_block,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                        industry_classification: {
                            name: 'Industry Classification',
                            content: industry_classification_block,
                            required_class: 'col-lg-4 col-xl-4'
                        }
                    }
                }
            };


            if (item_info.item_type === 'service_providers'){

                if (this.item_info.current_page_type === 'customer'){
                    delete this.entity_info_list.statistics;
                    delete this.entity_info_list.info_for_sp;
                }else{
                    delete this.entity_info_list.statistics;
                    delete this.entity_info_list.info_for_sp;
                    delete this.entity_info_list.overview.blocks.group_info;

                    this.entity_info_list.overview.blocks.customer_info.required_class = 'col-lg-6 col-xl-6';
                    this.entity_info_list.overview.blocks.customer_details.required_class = 'col-lg-6 col-xl-6';

                }

            }else if (item_info.item_type === 'customers'){
                if (this.item_info.current_page_type === 'sp') {
                    delete this.entity_info_list.objects;
                    delete this.entity_info_list.sp_info;

                    let info_for_sp_block ='';
                    if (this.item_info.hasOwnProperty('current_sp_info') && this.item_info.current_sp_info){
                        let current_sp_info = this.item_info.current_sp_info;
                        let relation_meta = current_sp_info.meta;

                        let default_address = this.item_info.default_address;
                        let default_address_info = '-';
                        if (default_address){
                            default_address_info = '<a href="' + current_page_type + '/addresses/' + default_address.id + '/view">' + default_address.name + '</a>';
                        }
                        let acc_number = '-';
                        if (relation_meta){
                            acc_number = relation_meta.hasOwnProperty('account_no') ? relation_meta.account_no : '';
                        }

                        let created_at = Vue.options.filters.moment(current_sp_info.created_at, 'DD/MM/YYYY') + ' (' + Vue.options.filters.fromNow(current_sp_info.created_at) + ')';
                        let updated_at = Vue.options.filters.moment(current_sp_info.updated_at, 'DD/MM/YYYY') + ' (' + Vue.options.filters.fromNow(current_sp_info.updated_at) + ')';

                        info_for_sp_block = '<div class="cm_common_list_detail">' +
                            '<span>Account Number</span><p>'+acc_number+'</p>' +
                            '<span>Default Delivery Address</span><p>' + default_address_info +'</p>' +
                            '<span>Default Billing Address</span><p>-</p>' +
                            '<span>Created Date</span><p>'+created_at+'</p>' +
                            '<span>Modified Date</span><p>'+updated_at+'</p>' +
                            '</div>';
                    }

                    let all_storage_counts = item_info.hasOwnProperty('all_storage_counts') ? item_info.all_storage_counts : 0;
                    this.entity_info_list.statistics.blocks = {
                        sp_storages_info: {
                            name: 'Storage',
                            content: '',
                            action: '<a href="' + current_page_type + '/sp_storages">View All ' + all_storage_counts + ' Storage</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            header_action_text: 'Popular',
                            header_action_class: 'split-header-sort',
                            object_type: 'sp_storages',
                            object_type_icon: 'iconize',
                            table_headers: ['Storage Wrapper', 'Count'],
                            required_items: item_info.hasOwnProperty('storage_counts') ? item_info.storage_counts : []
                        },
                        orders_info: {
                            name: 'Orders',
                            content: '',
                            action: '<a href="' + current_page_type + '/orders">View All Orders</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            // header_action_text: 'Recent',
                            // header_action_class: 'split-header-sort',
                            object_type: 'orders',
                            object_type_icon: 'iconize',
                            table_headers: ['Order Status', 'Count'],
                            required_items: item_info.hasOwnProperty('orders_info') ? item_info.orders_info : []
                        },
                        managed_storages_info: {
                            name: 'Managed Storage',
                            content: '',
                            action: '<a href="' + current_page_type + '/managed_storages">View All Managed Storage</a>',
                            required_class: 'col-lg-4 col-xl-4',
                            icon: 'iconize',
                            // header_action_text: 'Recent',
                            // header_action_class: 'split-header-sort',
                            object_type: 'managed_storages',
                            object_type_icon: 'iconize',
                            table_headers: ['Managed Storage Status', 'Count'],
                            required_items: item_info.hasOwnProperty('managed_storages_info') ? item_info.managed_storages_info : []
                        },
                    }

                    this.entity_info_list.info_for_sp.blocks = {
                        info_for_sp: {
                            name: item_info.name,
                            content: info_for_sp_block,
                            required_class: 'col-lg-12 col-xl-12'
                        },
                    }
                }else{
                    delete this.entity_info_list.statistics;
                    delete this.entity_info_list.info_for_sp;
                }
            }



        }else{
            console.log("'no item info?'");
            console.log('no item info?');

        }
    },
}
</script>
