const FirstPath = window.location.pathname.split('/')[1];
export const SubscriberKeysList = {
    id: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 fil_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status = item.user_state;
            if (item.status === 0){
                status = item.status;
            }
            let user_icons_list = [
                'red',
                'green',
                'brown',
                'grey'
            ];
            return '<span class="button_size status-background-' + user_icons_list[status] + '">' + item[key] + '</span>';
        }
    },
    name: {
        key: 'name',
        label: "Subscriber",
        class: "col-lg fil_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('id') && item.id){
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + item.id + '/view">' + Vue.options.filters.titleize(item.name) + '</a>';
            }
            return '';
        }
    },
};
