<template>
    <div class="BulkChildRecordsTab">

        <div class="product-filter-option-wrapper clearfix d-flex">

            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                    request_meta.from + '-' + request_meta.to
                }}</span> of <span>{{ request_meta.total }}</span></div>

            <div class="filter-wrap d-flex align-items-center ml-auto">

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Show: <b>{{
                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                        }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in perpage.pageOptions"
                        :key="option.value"
                        @click="savePerPage(option.value, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

            </div>

        </div>

        <LotsOfItemsAlert :perpage="perpage"/>

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching Files...</h4>
        </div>
        <div v-else-if="!filesData || total === 0 || filesData.length === 0"
             class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Line Items found here at the moment.</h4>

        </div>
        <!-- Product Section Start-->
        <div v-else-if="filesData && filesData.length > 0" class="row">
            <div class="col-12">
                <po-lines
                    :items="filesData"
                    :current_page_type="item_info.current_page_type"
                    :object_type="object_type"
                    :line_type="object_type"
                    :get-poline-classes-dict="getPoLineClassFor"
                    :keys_list="child_records_key_list">

                    <template v-slot:item_number="{ item, index }">
                        <div>{{ ((perpage.currentPage-1) * perpage.perPage) + (index + 1) }}</div>
                    </template>

                </po-lines>

                <div class="pagination mb-3">
                    <b-pagination
                        v-if="perpage.perPage !== 0"
                        v-model="perpage.currentPage"
                        :total-rows="request_meta.total"
                        @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                        class="pagination d-flex align-items-center justify-content-center"
                        :per-page="perpage.perPage"
                    ></b-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import PoLines from "./widgets/detailsViews/PoLines";

import Resource from '@/api/resource';
const childRecordResource = new Resource('api/assetflow/bulk_update_child_records');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'BulkChildRecordsTab',
    components: {LotsOfItemsAlert, PoLines},
    props: {
        object_type: {},
        item_info: {},
        page_unique_name: {}
    },
    data(){
        return {
            child_records_key_list:{},
            total: 0,
            filesData: [],
            request_meta: {},
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            listLoading: false,
            selected_item: null,
            permissions_list:{},
            permission_name: 'files',
        }
    },
    created() {
        let item_info = this.item_info;
        this.child_records_key_list = {
            bulk_action_no: {
                key: 'bulk_action_no',
                label: "No.",
                class: "col-lg-1 bur_bulk_action_no",
                show_on_mobile: true,
                case: 'custom_slot',
                slot_name: 'item_number'
            },
            requested_date: {
                key: 'requested_date',
                label: "Date",
                class: "col-lg-1 bur_requested_date",
                show_on_mobile: false,
                case: 'custom_html',
                value_func: function (item, key) {
                    return Vue.options.filters.moment(item_info.created_at, 'DD-MM-YYYY');
                }
            },
            user: {
                key: 'user',
                label: "User",
                class: "col-lg-2 bur_user",
                show_on_mobile: true,
                case: 'custom_html',
                value_func: function (item, key) {
                    return item_info.user_info.name;
                }
            },
            entity: {
                key: 'entity',
                label: "Entity",
                class: "col-lg-1 bur_entity",
                show_on_mobile: false,
                case: 'custom_html',
                value_func: function (item, key) {
                    return item_info.entity_info ? item_info.entity_info.name : '-';
                }
            },
            module: {
                key: 'module',
                label: "Module",
                class: "col-lg-2 bur_module",
                show_on_mobile: false,
                case: 'custom_html',
                value_func: function (item, key) {
                    return item_info.module_info ? item_info.module_info.name : '-';
                }
            },
            id: {
                key: 'id',
                label: "Item",
                class: "col-lg-1 bur_id",
                show_on_mobile: true,
                case: 'custom_html',
                value_func: function (item, key) {
                    const FirstPath = window.location.pathname.split('/')[1];
                    if (item_info.object_type === 'managed_storages'){
                        let managed_storage_info = item.managed_storage_info;
                        if (managed_storage_info){
                            return '<a class="text-dark" href="/' + FirstPath + '/managed_storages/' + managed_storage_info.id + '/view">' + managed_storage_info.uid + '</a>';
                        }
                    }else if (item_info.object_type === 'order_lines'){
                        let order_line_info = item.order_line_info;
                        if (order_line_info){
                            return '<a class="text-dark" href="/' + FirstPath + '/order_lines/' + order_line_info.id + '/view">' + order_line_info.uid + '</a>';
                        }
                    }
                    return item.item_id;
                }
            },
            action: {
                key: 'action',
                label: "Action",
                class: "col-lg-1 bur_action",
                show_on_mobile: false,
                case: 'custom_html',
                value_func: function (item, key) {
                    return Vue.options.filters.titleize(item_info.action_name);
                }
            },
            status: {
                key: 'status',
                label: "Status",
                class: "col-lg-1 bur_status",
                show_on_mobile: false,
                case: 'custom_html',
                value_func: function (item, key) {
                    return '<span class="status-btn status-background-'+(item.action_status === 'success' ? 'green' : 'red')+'">'+Vue.options.filters.titleize(item.action_status)+'</span>';
                }
            },
            log: {
                key: 'log',
                label: "Log",
                class: "col-lg-2 bur_log",
                show_on_mobile: false,
                case: 'custom_html',
                value_func: function (item, key) {
                    return '<p>' + item.action_log + '</p>';
                }
            },

        };
    },
    mounted() {
        // shall get files
        let preferredItemsPerPage = window.USER_INFO.user_meta['preferred_'+this.page_unique_name+'s_per_page'];
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta[this.page_unique_name+'_preferred_sorting']) {
            this.sorting.sortType = window.USER_INFO.user_meta[this.page_unique_name+'_preferred_sorting'];
        }

        this.getList();
    },
    methods: {
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta(this.page_unique_name+'_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_'+this.page_unique_name+'s_per_page', perPage, message);
            this.refreshContent();
        },
        handleQueryItems(required_query) {
            if (this.current_sp && this.item_info.current_page_type === 'sp') {
                required_query.sp_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_info.current_page_type === 'customer') {
                required_query.customer_id = this.current_customer.id;
            }
            return required_query;
        },

        async getList() {
            let required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
                parent_bulk_update_id: this.item_info.id
            };

            this.listLoading = true;

            required_query = this.handleQueryItems(required_query);

            const {data} = await childRecordResource.list(required_query, null, true);

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.filesData = data.data.items;
                this.request_meta = data.meta;
            }

            this.listLoading = false;
        },
    }
}
</script>
