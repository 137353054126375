<template>
    <h4>
        Asset Flow Logs...
    </h4>
</template>

<script>
    export default {
        name: "List"
    }
</script>


