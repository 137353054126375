<template>
    <b-modal :id="modal_id"
             title="Update Details"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             @show="setDefaultValues"
             hide-footer>

        <div class="common-step-panel use-section">
            <div class="row">

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :required_fields="required_fields"
                        :received_toggle="section_group.minimized">

                        <StepCommonInfoBlock
                            :add-form="addForm"
                            :block_key="key"
                            :item_info="item_info"
                            :changed-radio-value="changedRadioValue"
                            :mark-as-checked="markAsChecked"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :my_lists_values="lists_values"
                            :without_asset_passport_name="true"
                        />
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-asset-acquisition' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="item_info && item_info.hasOwnProperty('name') ? ('Update {' + item_info.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>

    </b-modal>

</template>
<script>

import AFButton from "@/components/AFButton";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetInfoSection from "./AssetInfoSection";
import StepCommonInfoBlock from "../../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";
export default {
    components: {StepCommonInfoBlock, AssetInfoSection, AFButton},
    mixins: [dropzoneMixin],
    name: 'UpdateDetailsModal',
    props: ['received_item_info', 'modal_id', 'item_type', 'is_list'],
    data(){
        return {
            item_info: null,
            addForm: {
                asset_meta: {},
                item_details: {},
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {

                        edit_name_section: {
                            name: 'Asset Name',
                            minimized: false,
                            fields: [
                                'name', // >>> shall be saved as meta
                                'external_reference',
                                'description'
                            ]
                        },
                        folder_section: {
                            name: 'Folder',
                            minimized: false,
                            fields: [
                                'folder_id'
                            ]
                        },
                        asset_details_section: {
                            name: 'Asset Details',
                            minimized: false,
                            fields: [
                                'category_id',
                                'asset_template_id',
                                'colours',
                            ]
                        },
                    }
                },
            },
            required_fields: {
                name: {
                    name: 'Proposed',
                    type: 'text',
                    label: 'Keeping proposed Asset Names will standardise your Inventory and search results',
                    has_action: true,
                    action_title: 'Automatically Generate Asset Name',
                    action_name: 'setNameAutomatically'
                },
                external_reference: {
                    name: 'Asset Reference',
                    type: 'text',
                    label: 'Please, use your referencing system, this can be used in the reconciliation process.'
                },
                description: {
                    name: 'Description',
                    type: 'textarea',
                    label: ''
                },
                folder_id: {
                    name: 'folder',
                    name_plural:'folders',
                    link_name:'folders',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                category_id: {
                    name: 'Category',
                    name_plural:'categories',
                    link_name:'assets',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                    with_asset_template_keys: true
                },
                asset_template_id: {
                    name: 'Asset Template',
                    type: 'single-select',
                    has_permission: true,
                    is_hidden: true,
                    options:[]
                },
                colours: {
                    name: 'colours',
                    name_plural:'colours',
                    link_name:'',
                    type: 'multi-select',
                    has_permission: true,
                    options:[],
                },
            }
        }
    },
    created() {
        this.getFieldsList();
        this.item_info = this.received_item_info;
    },
    computed: {
        selected_category(){
            return this.addForm.item_details.hasOwnProperty('category_id') ? this.addForm.item_details.category_id : null;
        },
    },
    watch: {
        selected_category: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal){
                    this.handleChangingCategory();
                }

            }
        },
    },
    methods:{

        handleChangingCategory() {
            let selected_asset_template_id = null;
            if (this.selected_category){

                if (this.selected_category.hasOwnProperty('category') && this.selected_category.category){
                    if (this.selected_category.category.hasOwnProperty('asset_template') && this.selected_category.category.asset_template){
                        selected_asset_template_id = this.selected_category.category.asset_template.id;
                    }else if (this.selected_category.category.hasOwnProperty('asset_template_id')){
                        selected_asset_template_id = this.selected_category.category.asset_template_id;
                    }
                }else if (this.selected_category.hasOwnProperty('asset_template')){
                    selected_asset_template_id = this.selected_category.asset_template.id;
                }

                if (selected_asset_template_id){
                    let asset_type_index = this.lists_values.asset_template_id.map(function (e) {
                        return e.id;
                    }).indexOf(selected_asset_template_id);

                    if (asset_type_index > -1){
                        this.$set(this.addForm.item_details, 'asset_template_id', this.lists_values.asset_template_id[asset_type_index]);
                    }else{
                        this.$set(this.addForm.item_details, 'asset_template_id', null);
                    }

                }else{
                    this.$set(this.addForm.item_details, 'asset_template_id', null);
                }
            }

        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                category_id: 'categories_list',
                folder_id: 'folders_list',
                asset_template_id: 'assetTemplates_list',
                // status: 'status_list',
                // character_id: 'characters_list',
                // ledger_id: 'ledgers_list',
                colours: 'colours_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];

                    if (['category_id', 'folder_id'].includes(list_key)){
                        let item_type = list_key.replace('_id', '') + 's';
                        this.lists_values[list_key] = this.getFolderObject(form_lists[select_name], 1, item_type, 'id', 'name');
                    }else{
                        this.lists_values[list_key] = form_lists[select_name];
                    }
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

                this.setDefaultValues();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        set_item(item){
            this.item_info = item;
            this.setDefaultValues();
        },
        setDefaultValues() {

            let addForm = this.addForm;

            _.forOwn(this.required_fields, function (field, key) {
                if (field.hasOwnProperty('default_value')) {
                    addForm.item_details[key] = field.default_value;
                }
            });

            if (this.item_info){
                if (this.item_info.hasOwnProperty('asset_meta')){
                    addForm.asset_meta = {...this.item_info.asset_meta};
                }

                addForm.item_details.name = this.item_info.name;
                addForm.item_details.external_reference = this.item_info.external_reference;
                addForm.item_details.description = this.item_info.description;


                addForm.item_details.category_id = this.getObjectOf(this.item_info.category_id, this.required_fields.category_id.options);

                if (this.item_info.hasOwnProperty('categories_info') && this.item_info.categories_info){
                    let last_category_info = _.last(this.item_info.categories_info);
                    addForm.item_details.category_id = this.getObjectOf(last_category_info.id, this.required_fields.category_id.options);
                }else if (this.item_info.hasOwnProperty('category_id') && this.item_info.category_id){
                    addForm.item_details.category_id = this.getObjectOf(this.item_info.category_id, this.required_fields.category_id.options);
                }
                this.handleChangingCategory();

                if (this.item_info.hasOwnProperty('folders_info') && this.item_info.folders_info){
                    let last_folder_info = _.last(this.item_info.folders_info);
                    addForm.item_details.folder_id = this.getObjectOf(last_folder_info.id, this.required_fields.folder_id.options);
                }else if (this.item_info.hasOwnProperty('folder_id') && this.item_info.folder_id){
                    addForm.item_details.folder_id = this.getObjectOf(this.item_info.folder_id, this.required_fields.folder_id.options);
                }

                addForm.item_details.asset_template_id = this.getObjectOf(this.item_info.asset_template_id, this.required_fields.asset_template_id.options);

                let asset_colours = [];
                if (this.item_info.hasOwnProperty('colours_info') && this.item_info.colours_info){
                    asset_colours = this.item_info.colours_info.map(user => (user.id+""));
                }else if (this.item_info.hasOwnProperty('colours') && this.item_info.colours){
                    asset_colours = this.item_info.colours;
                }
                if (asset_colours){
                    addForm.item_details.colours = this.getValueOf('colours', asset_colours, true);
                }
            }

            this.addForm.item_details = Object.assign({}, addForm.item_details);

        },
        changedRadioValue(field, event) {
            if (field === 'asset_template_id'){
                this.$set(this.addForm, 'asset_meta', {});
            }
        },
        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        async addItem(event){

            this.isLoadingItem = true;

            let item_details_values = {
                name: this.addForm.item_details.name,
                external_reference: this.addForm.item_details.external_reference,
                description: this.addForm.item_details.description,

                category_id: this.addForm.item_details.category_id ? this.addForm.item_details.category_id.id : null,
                folder_id: this.addForm.item_details.folder_id ? this.addForm.item_details.folder_id.id : null,
                asset_template_id: this.addForm.item_details.asset_template_id ? this.addForm.item_details.asset_template_id.id : null,
                colours: this.getIDs(this.addForm.item_details.colours, false),
            };

            let asset_id = this.item_info.id;

            let params = {
                asset_id: asset_id,
                asset_meta: this.addForm.asset_meta,
                item_details: item_details_values
            };

            axios({
                method: 'put',
                url: '/api/assetflow/assets/' + asset_id,
                baseURL: '/',
                data: params,
            }).then(response => {
                this.isLoadingItem = false;
                if (response.data.data.status === true) {
                    let added_item = response.data.data.items;
                    let message;

                    if (added_item && added_item.hasOwnProperty('id')){
                        let asset_name = ', ASS-' + added_item.uid + ' {' + added_item.name + '}';
                        message = 'Done' + asset_name;
                        if (asset_id !== '') {
                            message = 'Updated Details Successfully' + asset_name;
                        }

                        let status = response.data.data.status;
                        this.presentToast("Success!",
                            message,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);

                        let required_keys = [
                            'name',
                            'external_reference',
                            'description',
                            'category_id',
                            'categories_info',
                            'folder_id',
                            'folders_info',
                            'asset_meta',
                            'colours',
                            'colours_info',
                        ]
                        this.$emit('on-cost-updates', this.modal_id, added_item, required_keys);

                    }else{
                        console.log(added_item);
                    }

                } else {
                    console.log('Something went wrong');
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

    }
}
</script>
