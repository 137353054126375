const FirstPath = window.location.pathname.split('/')[1];
export const AcquisitionLineItemKeysList = {
    line_type: {
        key: 'line_type',
        label: "Line Type",
        class: "col-lg-1 line_item_line_type",
        show_on_mobile: false,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-3 line_item_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.line_type === 'Asset'){
                return '<a class="text-dark" href="/' + FirstPath + '/assets/'+item.id+'/view">' + item[key] + '</a>';
            }
            return item[key];
        }
    },
    reference: {
        key: 'reference',
        label: "Ref",
        class: "col-lg-1 line_item_ref",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.line_type === 'Asset'){
                return '<a class="text-dark" href="/' + FirstPath + '/assets/'+item.id+'/view">' + item[key] + '</a>';
            }
            return item[key];
        }
    },
    character_info: {
        key: 'character_info',
        label: "Character",
        class: "col-lg-1 line_item_character_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('character_info') && item.character_info){
                let character_info = item.character_info;
                return '<a class="text-dark" href="/' + FirstPath + '/characters/'+character_info.id+'/view">' + '(' + character_info.character_number + ') ' + character_info.name + '</a>';
            }
            return '-';
        }
    },
    ledger_info: {
        key: 'ledger_info',
        label: "Ledger",
        class: "col-lg-1 line_item_ledger_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('ledger_info') && item.ledger_info){
                let ledger_info = item.ledger_info;
                return '<a class="text-dark" href="/' + FirstPath + '/ledgers/'+ledger_info.id+'/view">' + (ledger_info.name || ledger_info.nominal_code) + '</a>';
            }
            return '-';
        }
    },
    quantity: {
        key: 'quantity',
        label: "Qty",
        class: "col-lg-1 line_item_qty",
        show_on_mobile: false,
        case: ''
    },
    net_total: {
        key: 'net_total',
        label: "Net",
        class: "col-lg-1 line_item_net_total",
        show_on_mobile: false,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {},
        value_func: function (index, item, key) {}
    },
    tax: {
        key: 'tax',
        label: "Tax",
        class: "col-lg-1 line_item_tax",
        show_on_mobile: false,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {},
        value_func: function (index, item, key) {}
    },
    total_price: {
        key: 'total_price',
        label: "Total",
        class: "col-lg-1 line_item_total",
        show_on_mobile: false,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {},
        value_func: function (index, item, key) {}
    },
    actions: {
        key: 'actions',
        label: "",
        class: "col-lg-1 a_actions",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_name: 'lines_actions'
    },
};
