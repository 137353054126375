<template>
    <div class="mr-2 mb-2 d-inline-block" v-if="[0, 1, 2, 3].includes(item_info.status) && hasPermission('manage_orders')">


        <SetOrderService
            ref="set_order_service"
            :modal_title="item_info.service_id ? 'Change Service' : 'Set Order Service'"
            @after-saving="goToDetailPage(true)"
            @on-cancel-clicked="$bvModal.hide('set_order_service')"
        />

        <button
            v-if="item_info.status === 0"
            @click="shouldSetService($event)"
            :class="'btn af-btn-accent af-accent change-order-status-btn order-status-btn-unconfirm'"
        >{{ item_info.service_id ? 'Change Service' : 'Set Order Service' }}</button>

        <button
            v-if="item_info.status === 1 && completed_order_lines_count === 0"
            @click="shallChangeStatus(0, $event)"
            :class="'btn af-btn-accent af-accent change-order-status-btn order-status-btn-unconfirm'"
        >Unconfirm</button>

        <button
            v-if="(item_info.current_page_type !== 'customer' || (item_info.current_page_type === 'customer' && ![1,2].includes(item_info.status)))"
            @click="handleOrderStatus($event)"
            :disabled="is_status_action_enabled"
            :class="'btn af-btn-accent af-accent change-order-status-btn order-status-btn-' + getButtonText(true)"
            >
            <p :id="is_status_action_enabled ? 'disabled-action-button' : ''">{{ getButtonText() }}</p>
        </button>

        <b-tooltip v-else-if="is_status_action_enabled" target="disabled-action-button" placement="left" variant="info">Please first complete all Order Tasks.</b-tooltip>

    </div>
</template>
<script>
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import SetOrderService from "./ordersContent/SetOrderService";
export default {
    name: 'OrderStatusButtons',
    components: {SetOrderService},
    props: {
        item_info: {}
    },
    mixins: [OrdersAndLinesShared],
    computed:{
        order_lines_count(){
            if(this.item_info.hasOwnProperty('order_lines_count') && this.item_info.order_lines_count){
                return this.item_info.order_lines_count;
            }
            return 0;
        },
        completed_order_lines_count(){
            if(this.item_info.hasOwnProperty('completed_order_lines_count') && this.item_info.completed_order_lines_count){
                return this.item_info.completed_order_lines_count;
            }
            return 0;
        },
        ready_order_lines_ratio(){
            if(this.order_lines_count){
                return this.completed_order_lines_count / this.order_lines_count;
            }
            return 0;
        },
        is_status_action_enabled(){
            let status = this.item_info.status;
            if (status === 0){
                return false;
            }
            if ([1,2].includes(status)){
                if ((status === 2 && this.item_info.current_page_type === 'customer')){
                    return true;
                }
                if (this.order_lines_count === 0){
                    return false;
                }
                return this.ready_order_lines_ratio !== 1;
            }
            return false;
        }
    },
    methods:{
        goToDetailPage(modal_id=null){
            if (modal_id){
                this.$bvModal.hide('set_order_service');
            }
            window.location.href = '/' + this.item_info.current_page_type + '/orders/' + this.item_info.id + '/view';
        },
        shouldSetService(event){
            // after done, set service_id
            this.$refs['set_order_service'].setSelectedItem(this.item_info, this.item_info.current_page_type);
            this.$bvModal.show('set_order_service');
        },
        shallChangeStatus(nextStatus, event){
            this.set_item_property_new('orders', this.item_info.id, 'status', nextStatus);
        },
        handleOrderStatus(event){
            let order_type = this.getOrderTypeNew(this.item_info);
            let title_message = '';
            let html_node = '';
            let variant = '';
            let size = '';
            let okTitle = '';
            let cancelTitle = '';
            let successAction = null;
            let nextStatus = null;

            switch (this.item_info.status){
                case 0:
                    // Open >> Confirm
                    // 0  >> 1
                    window.location.href = '/' + this.item_info.current_page_type + '/orders/' + this.item_info.id + '/confirm';
                    return;
                    // nextStatus = 1;
                    // title_message = 'Confirm Order #' + this.item_info.id;
                    // html_node = this.htmlToVNode('<p>Confirm this <strong>\'' + order_type + '\'</strong> Order?</p>');
                    // variant = 'primary';
                    // size = 'md';
                    // okTitle = 'Confirm';
                    // cancelTitle = 'Cancel';
                    // successAction = this.shallChangeStatus;
                    // break;
                case 1:
                    // Confirmed >> Complete
                    // 1 >> 3
                    nextStatus = 3;
                    title_message = 'Complete Order #' + this.item_info.id;
                    html_node = this.htmlToVNode('<p>Complete this <strong>\'' + order_type + '\'</strong> Order?</p>');
                    variant = 'primary';
                    size = 'md';
                    okTitle = 'Complete';
                    cancelTitle = 'Cancel';
                    successAction = this.shallChangeStatus;
                    break;
                case 2:
                    // In Progress >> Complete
                    // 2 >> 3
                    nextStatus = 3;
                    title_message = 'Complete Order #' + this.item_info.id;
                    html_node = this.htmlToVNode('<p>Complete this <strong>\'' + order_type + '\'</strong> Order?</p>');
                    variant = 'primary';
                    size = 'md';
                    okTitle = 'Complete';
                    cancelTitle = 'Cancel';
                    successAction = this.shallChangeStatus;
                    break;
                case 3:
                    // Completed
                    // Completed >> 'Cancel'
                    // 3 >> 4
                    nextStatus = 4;
                    title_message = 'Cancel Order #' + this.item_info.id;
                    html_node = this.htmlToVNode('<p>Cancel this <strong>\'' + order_type + '\'</strong> Order?</p>');
                    variant = 'primary';
                    size = 'md';
                    okTitle = 'Cancel Order';
                    cancelTitle = 'Cancel';
                    successAction = this.shallChangeStatus;
                    break;
                case 4:
                    // Cancelled
                    break;
                case 5:
                    // Awaiting Transport
                    break;
                default:
                    // open
                    break;
            }

            if (title_message !== ''){
                this.presentConfirmBox(title_message, html_node, variant, size, okTitle, cancelTitle)
                    .then(value => {
                        if (value){
                            successAction(nextStatus);
                        }else{
                            // nothing!
                        }
                    }).catch((error) => {
                    console.log(error);
                });
            }

        },
        getButtonText(just_stu_id=false){
            // 0:Open, 1:Confirmed, 2:In Progress, 3:Completed, 4:Cancelled, 5:Awaiting Transport
            switch (this.item_info.status){
                case 0:
                    // Open
                    return just_stu_id ? 1 : 'Confirm';
                case 1:
                    // Confirmed
                    return just_stu_id ? 3 : 'Complete';
                case 2:
                    // In Progress
                    return just_stu_id ? 3 : 'Complete';
                case 3:
                    // Completed
                    return just_stu_id ? 4 : 'Cancel';
                case 4:
                    // Cancelled
                    break;
                case 5:
                    // Awaiting Transport
                    break;
                default:
                    // open
                    break;
            }
        }
    }
}
</script>
