<template>
    <Layout main_content_id="dsl_main_page">

        <div class="intelligence-template sdl-live">


            <SetChangeSceneDescription
                ref="set-change-scene-description"
                item_type='description-dashboard'
                modal_title="Update Scene Notes"
                :received_item_id="item_info.id"
                @on-new-item-adding="AfterSettingDescription"
            >
            </SetChangeSceneDescription>


            <AddNewChange
                ref="add-new-change"
                :item_type="'changes' + unique_id"
                modal_title="Create New Change"
                :with_item_details="true"
                @on-new-item-adding="addNewItemToOptions"
            >
            </AddNewChange>


            <EditChangeNotes
                ref="edit-change-notes"
                :item_type="'changes-notes' + unique_id"
                modal_title="Edit Change Notes"
                :with_item_details="false"
                @on-new-item-adding="reloadPage"
            >
            </EditChangeNotes>

            <EditChangeScenes
                ref="edit-change-scenes"
                :item_type="'changes-scenes' + unique_id"
                modal_title="Edit Change Scenes"
                :with_item_details="false"
                @on-new-item-adding="reloadPage"
            >
            </EditChangeScenes>

            <EditChangeSetup
                ref="edit-change-setup"
                :item_type="'changes-setup' + unique_id"
                modal_title="Edit Change Setup"
                :with_item_details="false"
                @on-new-item-adding="reloadPage"
            >
            </EditChangeSetup>

            <EditGeneratePdf
                ref="edit-generate-pdf"
                :item_type="'generate-pdf' + unique_id"
                modal_title="Generate PDF"
                object_type="change"
                :with_item_details="false"
                @on-new-item-adding="reloadPage"
            >
            </EditGeneratePdf>


            <div class="row">
                <div class="col-lg-12">
                    <div class="page-title-box">
                        <div class="d-flex align-items-center">
                            <a href="#" @click="clearAll($event)">
                                <h2 v-if="addForm.change_id" class="title" title="Stop Shoot Day Live"><span class="live_icon"></span>Shoot Day: Live</h2>
                                <h2 v-else class="title"><span class="live_icon_off"></span>Shoot Day: Live</h2>
                            </a>
                            <div class="ns_date_icon" v-if="addForm.part_id && addForm.change_id && addForm.part_id.shoot_day_date">
                                <p>{{ addForm.part_id.shoot_day_date | moment('DD/MM/YYYY') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="ns_dropdown_block">
                        <div class="ns_col_left">
                            <div class="ns_drop_select_box">

                                <div
                                    v-for="(required_field, key) in required_fields"
                                    :key="required_field.key"
                                    class="ns_select_box">

                                    <multiselect
                                        v-if="required_field.type === 'single-select'"
                                        v-model="addForm[required_field.key]"
                                        track-by="id"
                                        label="name"
                                        :loading="isLoadingItem"
                                        :disabled="required_field.key === 'change_id' && !(addForm.character_id && addForm.part_id)"
                                        :custom-label="required_field.hasOwnProperty('custom_label') ? required_field.custom_label : CustomMSLabel"
                                        :multiple="required_field.type === 'multi-select'"
                                        :placeholder="required_field.key === 'change_id' ? getChangeLabel() : required_field.label"
                                        class="mySingleSelect"
                                        :options="required_field.options"
                                        @input="changedSelectField(required_field.key, null, $event)"
                                    >
                                    </multiselect>
                                </div>

                                <div class="cm_pro_status_tag" v-if="addForm.change_id && addForm.part_id">
                                    <a @click="addDescriptionModal($event)" class="status-btn " :class="'status-btn-' + (addForm.change_id.status === 1 ? 'active' : 'planned') +' ' + (addForm.change_id.status === 1 ? 'established' : 'planned') + '-change-status'">
                                        <span>{{ addForm.change_id.status === 1 ? 'Established' : 'Planned' }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="ns_col_right" v-if="parts_info">
                            <div class="ns_ptc_box_content">

                                <SceneColouredBlock
                                    v-for="(coloured_block, key) in scene_coloured_blocks"
                                    :key="key"
                                    :coloured_block="coloured_block"
                                    :items_count="parts_count"
                                    :scene-clicked="sceneClicked"
                                />

                                <div class="ns_ptc_box" v-if="first_image">
                                    <span><img
                                        :src="first_image.image_name + (image_sizes.hasOwnProperty('sdl_change_image') ? image_sizes.sdl_change_image : '')"
                                        :alt="first_image.image_name + (image_sizes.hasOwnProperty('sdl_change_image') ? image_sizes.sdl_change_image : '')"
                                        @error="setPlaceholderImage($event)"
                                    ></span>
                                </div>

                                <div class="ns_ptc_box_actions">
                                    <div class="cm_product_more_btn">

                                        <div class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                                            <button type="button" class="btn btn-none dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">

                                                <b-dropdown-group id="dropdown-group-1" header="Change">
                                                    <b-dropdown-item target="_blank" :href="'/' + item_type + '/changes/'+change_info.id +'/view'">View Change</b-dropdown-item>
                                                    <b-dropdown-item @click="editChange(change_info, 'change_notes', $event)">Change Notes</b-dropdown-item>
                                                    <b-dropdown-item @click="editChange(change_info, 'change_scenes', $event)">Change Scenes</b-dropdown-item>
                                                    <b-dropdown-item @click="editChange(change_info, 'change_setup', $event)">Change Setup</b-dropdown-item>
                                                    <b-dropdown-item @click="editChange(change_info, 'generate_pdf', $event)">Generate PDF</b-dropdown-item>
                                                </b-dropdown-group>
                                                <b-dropdown-divider></b-dropdown-divider>
                                                <b-dropdown-group id="dropdown-group-2" header="Scene Notes">
                                                    <b-dropdown-item @click="addDescriptionModal()">Add Scene Notes</b-dropdown-item>
                                                    <b-dropdown-item v-if="addForm.part_id" target="_blank" :href="'/' + item_type + '/changes/'+change_info.id +'/scene/' + addForm.part_id.id">View Scene Notes Details</b-dropdown-item>
                                                </b-dropdown-group>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="new_scene_details_block" v-if="isLoadingRelation">
                <div class="text-center">
                    <h6>Loading...</h6>
                </div>
            </div>
            <div class="new_scene_details_block" v-else-if="addForm.part_id && addForm.change_id">
                <div class="tab_menu_item">
                    <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li v-for="tab in tabs" class="nav-item" v-if="!tab.isHidden">
                            <a class="nav-link"
                               @click="tabClicked(tab.id)"
                               :class="isActiveTab(tab.id)"
                               data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content tab_content_design">

                    <div class="tab-pane" id="change_live_tab" role="tabpanel" v-if="!tabs.change_live_tab.isHidden" :class="isActiveTab('change_live_tab')">
                        <ChangeSceneLiveTab :item_info="item_info" :item_type="item_type" @on-post-adding="addNewPost"/>
                    </div>
                    <div class="tab-pane" id="change_activity_tab" role="tabpanel" v-if="!tabs.change_activity_tab.isHidden" :class="isActiveTab('change_activity_tab')">
                        <ChangeSceneActivityTab :item_info="item_info" :item_type="item_type" @on-post-adding="addNewPost"/>
                    </div>
                    <div class="tab-pane" id="change_tasks_tab" role="tabpanel" v-if="tabs.hasOwnProperty('change_tasks_tab') && !tabs.change_tasks_tab.isHidden" :class="isActiveTab('change_tasks_tab')">
                        <ChangeSceneTasksTab :item_info="item_info"/>
                    </div>
                    <div class="tab-pane" id="change_media_tab" role="tabpanel" v-if="!tabs.change_media_tab.isHidden" :class="isActiveTab('change_media_tab')">
                        <MediaTab :item_info="item_info" object_type="changes-dashboard"/>
                    </div>
                    <div class="tab-pane" id="change_costume_tab" role="tabpanel" v-if="!tabs.change_costume_tab.isHidden" :class="isActiveTab('change_costume_tab')">
                        <ChangeSceneCostumeTab :change_info="change_info" :item_type="item_type" :item_info="item_info" @description-changed="AfterSettingDescription" @after-item-moving="moveItemTo"/>
                    </div>
                    <div class="tab-pane" id="change_wardrobe_tab" role="tabpanel" v-if="!tabs.change_wardrobe_tab.isHidden" :class="isActiveTab('change_wardrobe_tab')">
                        <ChangeSceneWardrobeTab :item_info="change_info" :item_type="item_type" @after-item-moving="moveItemTo"/>
                    </div>
                    <div class="tab-pane" id="change_scene_scenes_tab" role="tabpanel" v-if="!tabs.change_scene_scenes_tab.isHidden" :class="isActiveTab('change_scene_scenes_tab')">
                        <ChangeSceneScenesTab :item_info="item_info" :item_type="item_type"/>
                    </div>
                    <div class="tab-pane" id="change_scene_prev_scene_tab" role="tabpanel" v-if="!tabs.change_scene_prev_scene_tab.isHidden" :class="isActiveTab('change_scene_prev_scene_tab')">
                        <ChangeScenePrevSceneTab :item_info="item_info"/>
                    </div>
                    <div class="tab-pane" id="change_scene_next_scene_tab" role="tabpanel" v-if="!tabs.change_scene_next_scene_tab.isHidden" :class="isActiveTab('change_scene_next_scene_tab')">
                        <ChangeSceneNextSceneTab :item_info="item_info"/>
                    </div>
                </div>
            </div>
            <div class="new_scene_details_block" v-else>
                <div class="text-center">
                    <h6>Select a Change using the Scene, Character and Change controls, or <a href="javascript:void(0);" @click="presentAddNewChange(addForm.change_id, addForm.character_id, addForm.part_id)">Create a New Change</a></h6>
                </div>
            </div>


        </div>
    </Layout>
</template>
<script>
import Layout from "@/layouts/main";
import ChangeSceneActivityTab from "@/views/assetflow/items/ChangeSceneRel/ChangeSceneActivityTab";
import ChangeSceneTasksTab from "@/views/assetflow/items/ChangeSceneRel/ChangeSceneTasksTab";

import ChangeSceneCostumeTab from "@/views/assetflow/items/ChangeSceneRel/ChangeSceneCostumeTab";
import ChangeSceneWardrobeTab from "@/views/assetflow/items/ChangeSceneRel/ChangeSceneWardrobeTab";
import ChangeSceneScenesTab from "@/views/assetflow/items/ChangeSceneRel/ChangeSceneScenesTab";
import ChangeScenePrevSceneTab from "@/views/assetflow/items/ChangeSceneRel/ChangeScenePrevSceneTab";
import ChangeSceneNextSceneTab from "@/views/assetflow/items/ChangeSceneRel/ChangeSceneNextSceneTab";
import ChangeSceneLiveTab from "./ChangeSceneLiveTab";
import SceneColouredBlock from "./SceneColouredBlock";
import multiSelect from '@/mixins/MultiSelect';
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import AddNewChange from "../extras/AddNewChange";
import SetChangeSceneDescription from "@/views/assetflow/items/ChangeSceneRel/SetChangeSceneDescription";
import EditChangeNotes from "../extras/EditChangeNotes";
import EditChangeScenes from "../extras/EditChangeScenes";
import EditChangeSetup from "../extras/EditChangeSetup";
import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";
import EditGeneratePdf from "../extras/EditGeneratePdf";

export default {
    name: 'ShootDayLiveDashboard',
    mixins: [multiSelect],
    props: ['item_id', 'item_type'],
    components: {
        EditGeneratePdf,
        MediaTab,
        EditChangeSetup,
        EditChangeScenes,
        EditChangeNotes,
        SetChangeSceneDescription,
        AddNewChange,
        NewCustomGenericBlock,
        SceneColouredBlock,
        Layout,
        ChangeSceneLiveTab,
        ChangeSceneActivityTab,
        ChangeSceneTasksTab,
        ChangeSceneCostumeTab,
        ChangeSceneWardrobeTab,
        ChangeSceneScenesTab,
        ChangeScenePrevSceneTab,
        ChangeSceneNextSceneTab
    },
    data(){
        return {
            unique_id: 'dashboard',
            addForm:{
                part_id: null,
                character_id: null,
                change_id: null
            },
            newly_added_items:{
                change_id: []
            },

            required_fields: {
                part_id: {
                    key: 'part_id',
                    label: 'Scene',
                    type: 'single-select',
                    custom_label: null,
                    options: []
                },
                character_id: {
                    key: 'character_id',
                    label: 'Character',
                    type: 'single-select',
                    custom_label: null,
                    options: []
                },
                change_id: {
                    key: 'change_id',
                    label: 'Change',
                    type: 'single-select',
                    custom_label: null,
                    options: []
                },
            },
            item_info: {
                change_info: {}
            },
            active_tab: 'change_live_tab',
            tabs: {
                'change_live_tab': {
                    'id': "change_live_tab",
                    'name': "Live",
                    'icon': "user",
                    'type': "change_live_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'change_activity_tab': {
                    'id': "change_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "change_activity_tab",
                    'isActive': false,
                    'isHidden': false
                },
                // 'change_tasks_tab': {
                //     'id': "change_tasks_tab",
                //     'name': "Tasks",
                //     'icon': "user",
                //     'type': "change_tasks_tab",
                //     'isActive': false,
                //     'isHidden': false
                // },
                'change_media_tab': {
                    'id': "change_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "change_media_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'change_costume_tab': {
                    'id': "change_costume_tab",
                    'name': "Costume",
                    'icon': "user",
                    'type': "change_costume_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'change_wardrobe_tab': {
                    'id': "change_wardrobe_tab",
                    'name': "Closet",
                    'icon': "user",
                    'type': "change_wardrobe_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'change_scene_scenes_tab': {
                    'id': "change_scene_scenes_tab",
                    'name': "Scenes",
                    'icon': "user",
                    'type': "change_scene_scenes_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'change_scene_prev_scene_tab': {
                    'id': "change_scene_prev_scene_tab",
                    'name': "Prev Scene",
                    'icon': "user",
                    'type': "change_scene_prev_scene_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'change_scene_next_scene_tab': {
                    'id': "change_scene_next_scene_tab",
                    'name': "Next Scene",
                    'icon': "user",
                    'type': "change_scene_next_scene_tab",
                    'isActive': false,
                    'isHidden': false
                },
            },
            lists_values: {},
            isLoadingItem: false,
            isLoadingRelation: false,
            partSortType:{
                value: 0,
                text: 'Part Order',
                first_criteria: 'target_scene',
                first_reversed: false,
                name: 'part_order',
                second_criteria: 'scene_order',
                second_reversed: false,
                third_criteria: 'part_order',
                third_reversed: false
            },
        }
    },
    created() {

        this.required_fields.part_id.custom_label = this.PartDetailsItem;
        this.required_fields.character_id.custom_label = this.CustomCharacterName;
        this.required_fields.change_id.custom_label = this.ChangeDetailsItem;
        this.getFieldsList();
        this.handleListingTabs();

    },
    computed:{
        change_info(){
            return this.item_info.hasOwnProperty('change_info') && this.item_info.change_info ? this.item_info.change_info : null;
        },
        parts_info(){
            return this.change_info.hasOwnProperty('parts_info') && this.change_info.parts_info ? this.change_info.parts_info : null;
        },
        parts_count(){
            return this.parts_info ? this.parts_info.length : 0;
        },
        first_image(){
            return this.change_info.hasOwnProperty('first_image') && this.change_info.first_image ? this.change_info.first_image : null;
        },
        scene_coloured_blocks(){
            return this.getSceneColouredBlocks();
        }
    },
    methods:{
        addDescriptionModal(){
            this.$refs['set-change-scene-description'].setSelectedItem({description: this.item_info.description});
            this.$bvModal.show('create-description-dashboard');
        },
        AfterSettingDescription(new_item, item_type){
            this.item_info.description = new_item.description;
        },
        moveItemTo(new_item, move_to_item_type, index){
            if (this.change_info.hasOwnProperty(move_to_item_type) && this.change_info[move_to_item_type]){
                this.change_info[move_to_item_type].push(new_item);

                this.change_info[move_to_item_type === 'wardrobe_items' ? 'costume_items' : 'wardrobe_items'].splice(index, 1);

                this.tabs.change_wardrobe_tab.name = 'Closet (' + this.change_info.wardrobe_items.length + ')';
                this.tabs.change_costume_tab.name = 'Costume (' + this.change_info.costume_items.length + ')';
            }
        },
        addNewPost(item, msg, status){

            if (this.item_info.asset_posts.length > 0){
                this.item_info.asset_posts.splice(0, 0, item);
            }else{
                this.item_info.asset_posts = [item];
            }

            let post_images = item.post_images;
            if (post_images){
                if (!this.item_info.asset_images){
                    this.item_info.asset_images = [];
                }
                this.item_info.asset_images = this.item_info.asset_images.concat(post_images);
            }

        },
        getColouredBlockFor: function (current_part_index, block_name) {
            let parts_info = this.parts_info;

            let current_part_info = parts_info[current_part_index];

            let current_part_name = current_part_info.hasOwnProperty('scene_info') && current_part_info.scene_info ? current_part_info.scene_info.name : '-';

            if (current_part_info.name !== null && current_part_info.name !== ''){
                current_part_name += ' ' + current_part_info.name;
            }

            let current_part_date = '-';
            let block_class = 'ns_bor_red';
            if (current_part_info.hasOwnProperty('shoot_day_date') && current_part_info.shoot_day_date) {
                current_part_date = Vue.options.filters.moment(current_part_info.shoot_day_date, 'DD/MM/YYYY');

                let shootDate = this.$moment(current_part_info.shoot_day_date);
                if (this.$moment() > shootDate) {
                    block_class = 'ns_bor_green';
                }
            }

            if (block_name === 'Current'){
                block_class = 'ns_bor_yellow';
            }

            return {
                type: block_name,
                index: current_part_index + 1,
                item_id: current_part_info.id,
                block_class: block_class,
                name: current_part_name,
                date: current_part_date,
                part_info: current_part_info
            };
        },
        getSceneColouredBlocks(){
            let part_info = this.addForm.part_id
            let previous_part_block = null;
            let current_part_block = null;
            let next_part_block = null;

            if (part_info){
                let parts_info_ids = this.parts_info.map(function(e) { return e.id; });
                let current_part_index = parts_info_ids.indexOf(part_info.id);

                if (current_part_index > -1){
                    current_part_block = this.getColouredBlockFor(current_part_index, 'Current');

                    if (current_part_index > 0){
                        previous_part_block = this.getColouredBlockFor(current_part_index-1, 'Prev');
                    }

                    if (current_part_index !== (this.parts_count - 1)){
                        next_part_block = this.getColouredBlockFor(current_part_index+1, 'Next');
                    }
                }
            }

            let coloured_blocks = [];

            if (previous_part_block){
                coloured_blocks.push(previous_part_block)
            }

            if (current_part_block){
                coloured_blocks.push(current_part_block)
            }

            if (next_part_block){
                coloured_blocks.push(next_part_block)
            }


            return coloured_blocks;
        },
        handleListingTabs() {
            let changes_active_tab = localStorage.getItem('live_changes_active_tab');

            if (this.item_info) {

                if (this.change_info) {

                    let change_info = this.change_info;
                    if (change_info.hasOwnProperty('costume_items')) {
                        if (change_info.costume_items && change_info.costume_items.length > 0){
                            this.tabs.change_costume_tab.name = 'Costume (' + change_info.costume_items.length + ')';
                        }
                        this.tabs.change_costume_tab.isHidden = false;
                    } else {
                        this.tabs.change_costume_tab.isHidden = true;
                    }

                    if (change_info.hasOwnProperty('wardrobe_items')) {
                        if (change_info.wardrobe_items && change_info.wardrobe_items.length > 0){
                            this.tabs.change_wardrobe_tab.name = 'Closet (' + change_info.wardrobe_items.length + ')';
                        }
                        this.tabs.change_wardrobe_tab.isHidden = false;
                    } else {
                        this.tabs.change_wardrobe_tab.isHidden = true;
                    }

                    let parts_info = this.parts_info;
                    if (parts_info) {
                        if (parts_info && this.parts_count > 0){
                            this.tabs.change_scene_scenes_tab.name = 'Scenes (' + this.parts_count + ')';
                        }
                        this.tabs.change_scene_scenes_tab.isHidden = false;
                    } else {
                        this.tabs.change_scene_scenes_tab.isHidden = true;
                    }
                } else {
                    this.tabs.change_costume_tab.isHidden = true;
                    this.tabs.change_wardrobe_tab.isHidden = true;
                    this.tabs.change_scene_scenes_tab.isHidden = true;
                }


                // if (this.item_info.hasOwnProperty('tasks_list') && this.item_info.tasks_list && this.item_info.tasks_list.length > 0) {
                //     this.tabs.change_tasks_tab.name = 'Tasks (' + this.item_info.tasks_list.length + ')';
                // }

                if (this.item_info.hasOwnProperty('prev_scene_info') && this.item_info.prev_scene_info) {
                    this.tabs.change_scene_prev_scene_tab.name = 'Prev: (' + this.item_info.prev_scene_info.name + ')';
                    this.tabs.change_scene_prev_scene_tab.isHidden = false;
                } else {
                    this.tabs.change_scene_prev_scene_tab.isHidden = true;
                }

                if (this.item_info.hasOwnProperty('next_scene_info') && this.item_info.next_scene_info) {
                    this.tabs.change_scene_next_scene_tab.name = 'Next: (' + this.item_info.next_scene_info.name + ')';
                    this.tabs.change_scene_next_scene_tab.isHidden = false;
                } else {
                    this.tabs.change_scene_next_scene_tab.isHidden = true;
                }
            }

            let queryVars = this.getQueryVars();

            if (queryVars.hasOwnProperty('t') && queryVars.t !== '') {
                let selected_tab = queryVars.t;
                switch (selected_tab) {
                    case 'live':
                        this.active_tab = 'change_live_tab';
                        break;
                    case 'activity':
                        this.active_tab = 'change_activity_tab';
                        break;
                    case 'tasks':
                        this.active_tab = 'change_tasks_tab';
                        break;
                    case 'media':
                        this.active_tab = 'change_media_tab';
                        break;
                    case 'costume':
                        this.active_tab = 'change_costume_tab';
                        break;
                    case 'wardrobe':
                        this.active_tab = 'change_wardrobe_tab';
                        break;
                    case 'scene_scenes':
                        this.active_tab = 'change_scene_scenes_tab';
                        break;
                    case 'prev_scene':
                        this.active_tab = 'change_scene_prev_scene_tab';
                        break;
                    case 'next_scene':
                        this.active_tab = 'change_scene_next_scene_tab';
                        break;
                }
            } else if (changes_active_tab && changes_active_tab !== '') {
                if (!this.tabs[changes_active_tab].isHidden){
                    this.active_tab = changes_active_tab;
                }
            }

            this.handleMainWrapClass(changes_active_tab ? changes_active_tab : 'change_live_tab');

        },
        getChangeLabel(){
            if (this.addForm.character_id && this.addForm.part_id){
                if (this.required_fields.change_id.options.length > 1){
                    return 'Select Now';
                }
                return 'No Changes';
            }else{
                return 'Waiting';
            }
        },
        resetItemInfo() {
            this.item_info = {
                change_info: {}
            }
            this.handleListingTabs();
        },
        filterCharactersForPart(part_info, all_characters) {
            let part_cast = part_info.cast_info;
            if (part_cast) {
                let part_cast_ids = part_cast.map(function (e) {
                    return e.id;
                });
                if (part_cast_ids.length > 0) {
                    this.required_fields.character_id.options = all_characters.filter((character) => {
                        return part_cast_ids.includes(character.id);
                    });
                } else {
                    this.required_fields.character_id.options = [];
                }
            } else {
                this.required_fields.character_id.options = [];
            }
        },
        filterPartsForCharacter: function (all_parts, character_id) {
            this.required_fields.part_id.options = all_parts.filter((part) => {
                let part_cast_ids = part.cast_info.map(function (e) {
                    return e.id;
                });
                return part_cast_ids.includes(character_id);
            });
        },
        addAddNewOptionTo: function (key, options_list) {
            let new_option = [
                {
                    id: -99,
                    name: 'Add New'
                }
            ];
            this.required_fields[key].options = options_list.concat(new_option);
        },
        addNewItemToOptions(new_item, item_type){
            if (new_item) {
                if (item_type === 'changes'){
                    this.newly_added_items.change_id.push(new_item);

                    let selected_character_info = this.addForm.character_id;
                    let selected_part_info = this.addForm.part_id;

                    if (selected_character_info && selected_part_info){
                        let is_same_character = new_item.character_id === selected_character_info.id;
                        let has_same_part = new_item.parts_info.map(function(e) { return e.id; }).includes(selected_part_info.id);
                        if (is_same_character && has_same_part){
                            // shall check if the new change is added to the current selected character before appending the new option
                            this.required_fields.change_id.options.splice(this.required_fields.change_id.options.length - 2,0, new_item);
                            this.addForm.change_id = new_item;
                            if (this.addForm.part_id){
                                this.getRelationInfo(this.addForm.change_id, this.addForm.part_id);
                            }
                        }
                    }

                }
            }
        },
        getRelationIfAllValuesExist: function () {
            let item_keys = ['part_id', 'change_id', 'character_id'];
            for (const itemKeysKey in item_keys) {
                let key_name = item_keys[itemKeysKey];
                let change_value = this.addForm[key_name] ?? null
                if (change_value) {
                    let value_index = this.required_fields[key_name].options.map(function (e) {
                        return e.id;
                    }).indexOf(change_value.id);
                    if (value_index === -1) {
                        this.addForm[key_name] = null;
                    }
                }
            }
        },
        presentAddNewChange: function (change_info, character_info, part_info, last_added_change = null) {

            let change_info_for_modal = {};

            if (change_info){
                change_info_for_modal = Object.assign({}, change_info);
            }
            if (character_info){
                change_info_for_modal.character_id = character_info.id;
            }

            if (part_info){
                change_info_for_modal.part_ids = [part_info.id];
            }

            change_info_for_modal.change_status = 0;

            if (last_added_change){
                change_info_for_modal.number = parseInt(last_added_change.name) + 1;
            }

            if (change_info_for_modal === {}){
                change_info_for_modal = null;
            }else{
                change_info_for_modal.id = -99;
            }
            console.log("change_info_for_modal");
            console.log(change_info_for_modal);


            this.$refs['add-new-change'].setSelectedItem(change_info_for_modal);
            this.addForm.change_id = null;
            this.$bvModal.show('create-changes' + this.unique_id);
        },
        changedSelectField(field, change_default, event){

            let all_characters = this.lists_values.character_id;
            let all_changes = this.lists_values.change_id;
            let last_added_change = null;
            if (this.newly_added_items.change_id.length){
                all_changes = all_changes.concat(this.newly_added_items.change_id);
            }


            // this is to set the new change number
            let available_change_options = this.required_fields.change_id.options;
            let changes_list_count = available_change_options.length;
            if (changes_list_count > 1){
                last_added_change = available_change_options[changes_list_count - 2];
            }

            let all_parts = this.lists_values.part_id;

            let character_info = this.addForm.character_id ?? null;
            let part_info = this.addForm.part_id ?? null;
            let change_info = this.addForm.change_id ?? null;

            if (field === 'change_id' && change_info && change_info.id === -99 && part_info && character_info){
                this.presentAddNewChange(change_info, character_info, part_info, last_added_change);
                return;
            }

            if (['character_id', 'part_id'].includes(field)){
                let character_id = character_info ? character_info.id : null;
                let part_id = part_info ? part_info.id : null;

                if (character_id && part_id){
                    // console.log("shall filter changes");
                    let change_options = all_changes.filter((change) => {
                        let parts_info = change.parts_info;
                        let parts_info_ids = [];
                        if (parts_info){
                            parts_info_ids = parts_info.map(function(e) { return e.id; });
                        }
                        return (change.character_id === character_id && parts_info_ids.includes(part_id));
                    });
                    this.addAddNewOptionTo('change_id', change_options);

                    if (field === 'part_id'){
                        this.filterCharactersForPart(part_info, all_characters);
                    }else if (field === 'character_id'){
                        this.filterPartsForCharacter(all_parts, character_id);
                    }

                    if (change_options.length === 1){
                        // shall select the only change
                        this.addForm.change_id = change_options[0];
                    }else{
                        this.addForm.change_id = change_default;
                    }

                    this.resetItemInfo();

                }else if (field === 'part_id'){
                    if (part_info){
                        this.filterCharactersForPart(part_info, all_characters);
                    }else{
                        this.required_fields.character_id.options = all_characters;
                    }

                    // console.log("shall reset changes");
                    this.addAddNewOptionTo('change_id', []);
                    this.addForm.change_id = null;
                    this.resetItemInfo();

                }else if (field === 'character_id'){
                    if (character_id){
                        // console.log("shall filter parts");
                        this.filterPartsForCharacter(all_parts, character_id);
                    }else{
                        this.required_fields.part_id.options = all_parts;
                    }

                    // console.log("shall reset changes");
                    this.addAddNewOptionTo('change_id', []);
                    this.addForm.change_id = null;
                    this.resetItemInfo();
                }
            }else if (field === 'change_id' && change_info && !this.addForm.character_id){
                // console.log('shall set character_id to change\'s character');

                let character_id = change_info.character_id;
                let character_index = all_characters.map(function(e) { return e.id; }).indexOf(character_id);
                if (character_index > -1){
                    this.addForm.character_id = all_characters[character_index];
                }
            }

            this.getRelationIfAllValuesExist();

            if (this.addForm.change_id && this.addForm.part_id){
                this.getRelationInfo(this.addForm.change_id, this.addForm.part_id);
            }

        },
        getRelationInfo(change_info, part_info){

            let character_info = this.addForm.character_id;
            this.isLoadingRelation = true;

            axios({
                method:'post',
                url:'/api/assetflow/changes/getChangeSceneInfo',
                baseURL: '/',
                data: {
                    part_id: part_info.id,
                    change_id: change_info.id,
                    item_type: this.item_type
                }
            }).then(response => {

                this.isLoadingRelation = false;

                let received_item_info = response.data.data;

                if (received_item_info){
                    this.item_info = received_item_info;

                    let selected_sdl_filtering = {
                        character_id: character_info.id,
                        part_id: part_info.id,
                        change_id: change_info.id
                    }
                    this.set_usermeta('selected_sdl_filtering', selected_sdl_filtering, "", false);

                    this.handleListingTabs();
                }else{
                    this.resetItemInfo();
                }

            }).catch(error => {
                // console.log(error);
                this.isLoadingRelation = false;
            });
        },
        editChange(change, action, event){
            let change_info_for_modal = Object.assign({}, change);

            switch (action) {
                case 'change_notes':
                    change_info_for_modal.change_status = change.status;
                    this.$refs['edit-change-notes'].setSelectedItem(change_info_for_modal);
                    this.$bvModal.show('create-changes-notes' + this.unique_id);
                    break;
                case 'change_scenes':
                    change_info_for_modal.part_ids = change.parts_info.map(function(e) { return e.id; });
                    this.$refs['edit-change-scenes'].setSelectedItem(change_info_for_modal);
                    this.$bvModal.show('create-changes-scenes' + this.unique_id);
                    break;
                case 'change_setup':
                    change_info_for_modal.character_id = change.character_id;
                    change_info_for_modal.number = change.name;
                    this.$refs['edit-change-setup'].setSelectedItem(change_info_for_modal);
                    this.$bvModal.show('create-changes-setup' + this.unique_id);
                    break;
                case 'generate_pdf':
                    this.$refs['edit-generate-pdf'].setSelectedItem(change_info_for_modal);
                    this.$bvModal.show('create-generate-pdf' + this.unique_id);
                    break;
            }
        },
        clearAll(event){
            this.addForm.character_id = null;
            this.addForm.change_id = null;
            this.addForm.part_id = null;

            this.required_fields.character_id.options = this.lists_values.character_id;
            this.required_fields.change_id.options = this.lists_values.change_id;
            this.required_fields.part_id.options = this.lists_values.part_id;

            this.resetItemInfo();
        },

        PartDetailsItem({ scene_info, name }){
            let scene_name = '';
            if (scene_info){
                scene_name = scene_info.name;
            }
            if (name !== null && name !== ''){
                scene_name += ' => ' + name;
            }
            return scene_name;
        },

        ChangeDetailsItem({ id, name }){
            return (id !== -99 ? 'Change ' : '') + name;
        },
        setSelectedSDLFiltering: function (selected_sdl_filtering) {
            let item_ids = [];
            for (const item_key in selected_sdl_filtering) {
                let item_id = selected_sdl_filtering[item_key];
                if (item_id !== null) {
                    let all_items = this.lists_values[item_key];
                    let item_index = all_items.map(function (e) {
                        return e.id;
                    }).indexOf(item_id);
                    if (item_index > -1) {
                        this.addForm[item_key] = all_items[item_index];
                    }
                    item_ids.push(item_id);
                }
            }


            if (item_ids.length === 3) {
                this.getRelationIfAllValuesExist();
                this.changedSelectField('character_id', this.addForm.change_id);
            }
        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                part_id: 'parts_with_cast_list',
                character_id: 'character_short_list',
                change_id: 'changes_short_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }




                let queryVars = this.getQueryVars();

                let part_id = queryVars.scene || '';
                let character_id = queryVars.character || '';
                let change_id = queryVars.change || '';

                let current_user_info = this.current_user_info;
                let selected_sdl_filtering = {};
                if (part_id !== '' && character_id !== '' && change_id !== '') {
                    selected_sdl_filtering = {
                        part_id: parseInt(part_id),
                        character_id: parseInt(character_id),
                        change_id: parseInt(change_id)
                    };
                }else if (current_user_info && current_user_info.hasOwnProperty('user_meta') &&
                    current_user_info.user_meta &&
                    current_user_info.user_meta.hasOwnProperty('selected_sdl_filtering') &&
                    current_user_info.user_meta.selected_sdl_filtering){
                    selected_sdl_filtering = window.USER_INFO.user_meta.selected_sdl_filtering;
                }

                this.setSelectedSDLFiltering(selected_sdl_filtering);


            }).catch(error => {
                // console.log(error);
                this.isLoadingItem = false;
            });
        },
        sceneClicked(coloured_block, event){
            // console.log("coloured_block");
            // console.log(coloured_block);
        },
        handleMainWrapClass: function (tab_id) {
            let element = document.getElementById("dsl_main_page");
            if (element){
                if (tab_id === 'change_live_tab') {
                    element.classList.add("sdl-main-wrap-new");
                } else {
                    element.classList.remove("sdl-main-wrap-new");
                }
            }
        },
        tabClicked(tab_id){
            localStorage.setItem('live_changes_active_tab', tab_id);
            this.handleMainWrapClass(tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
