<template>

    <ul v-if="filter_type === 0">
        <li class="mr-auto">
            <p>The 'Main Page' is currently showing All Assets.</p>
            <p>Nothing to see here :)</p>
        </li>
    </ul>
    <ul :class="ul_class + ([8,9, 10].includes(filter_type) && is_first ? (' ' + filter_classes[filter_type] + '-first') : '') " v-else-if="items && items.length > 0"
        :style="getStyleForRow(parent_item)">
        <li v-for="item in items" :class="{
            'filled' : item.subfolders && item.subfolders.length > 0,
            'active' : selected_item && (!is_first ? (selected_item.hasOwnProperty('child_only') && parseInt(item.id) === parseInt(selected_item.child_only)) : item.id === selected_item.id)
        }">

            <b-dropdown dropleft v-if="has_folders_actions_permissions && filter_type === 1" class="ml-auto sidebar-action-dropdown" variant="none">
                <template slot="button-content">
                    <i class="mdi mdi-dots-horizontal"></i>
                </template>
                <b-dropdown-item v-if="hasPermission('edit_folders')" @click="editFolder(item)">Edit</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('edit_folders')" @click="moveFolder(item)">Move</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('add_folders')" @click="createFolder(item.id)">New Sub-Folder</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('delete_folders') && canDelete(item)" variant="danger" @click="deleteFolder(item.id, 'folders', $event)">Delete</b-dropdown-item>

                <b-dropdown-item v-else-if="!canDelete(item)" v-b-tooltip.hover :title="'Only empty folders can be deleted.' + getCounts(item)" variant="danger" disabled >Delete</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('edit_folders') && item.subfolders && item.subfolders.length !== 0" @click="moveContent(item, 'folders', $event)">Move Assets</b-dropdown-item>
            </b-dropdown>

            <b-dropdown dropleft v-else-if="filter_type === 4 && has_tags_actions_permissions" class="ml-auto sidebar-action-dropdown" variant="none">
                <template slot="button-content">
                    <i class="mdi mdi-dots-horizontal"></i>
                </template>
                <b-dropdown-item v-if="hasPermission('edit_tags')" @click="editTag(item)">Edit</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('edit_tags') && item.assets_count !== 0" @click="moveContent(item, 'tags', $event)">Move Assets</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('edit_assets') && !canDelete(item, 'tags')" variant="danger" @click="untagAssets(item, 'tags', $event)">Un-Tag Assets</b-dropdown-item>

                <b-dropdown-item v-if="hasPermission('delete_tags') && canDelete(item, 'tags')" variant="danger" @click="deleteTag(item.id, 'tags', $event)">Delete</b-dropdown-item>
            </b-dropdown>

            <b-dropdown dropleft v-else-if="filter_type === 6 && has_addresses_actions_permissions" class="ml-auto sidebar-action-dropdown" variant="none">
                <template slot="button-content">
                    <i class="mdi mdi-dots-horizontal"></i>
                </template>
                <b-dropdown-item v-if="hasPermission('edit_addresses')" @click="editTag(item, 'addresses')">Edit</b-dropdown-item>

                <b-dropdown-item v-if="hasPermission('edit_addresses')" @click="addLocation(item, 'addresses')">Add Location</b-dropdown-item>

                <b-dropdown-item v-if="hasPermission('edit_addresses') && item.assets_count !== 0" @click="moveContent(item, item.address_id ? 'address_locations' : 'addresses', $event)">Move Assets</b-dropdown-item>
                <b-dropdown-item v-if="hasPermission('edit_assets') && !canDelete(item, 'addresses') && item.address_id" variant="danger" @click="untagAssets(item, 'addresses', $event)">Un-Assign Assets</b-dropdown-item>

                <b-dropdown-item v-if="hasPermission('delete_addresses') && canDelete(item, 'addresses')" variant="danger" @click="deleteFolder(item, 'addresses', $event)">Delete</b-dropdown-item>
            </b-dropdown>

            <div v-if="false && [1,3, 4, 6, 7].includes(filter_type)" class="stock-badge-block">
                <span class="badge badge-secondary badge-stock af-accent">{{ getAssetsTotalQty(item) }}</span>
            </div>

            <a class="folder-item" @click="changeGroup(item, $event)" :ref="'item-' + item.id">
                <i v-if="[3, 8, 9, 10, 17].includes(filter_type) && item.icon && item.icon !== ''"
                       :class="'font-size-22 ' + item.icon"></i>
                <i v-else-if="filter_type === 2" :class="'font-size-18 bx bxs-circle status-icon status-icon-' + item.value"></i>
                <i v-else-if="filter_type === 4" class="fas fa-tag"></i>

                <img v-else-if="16 === filter_type && item.avatar_name !== ''"
                    class="rounded-circle header-profile-user"
                    :src="item.avatar_name"
                    :alt="item.name"
                />
                <img v-else-if="[5, 18].includes(filter_type) && item.avatar_url !== ''"
                     class="rounded-circle header-profile-user"
                     :src="item.avatar_url"
                     :alt="item.name"
                />
                <i v-else-if="filter_type === 6" :class="'font-size-18 bx ' + (item.address_id ? 'bx-map' : 'bx-map-pin')"></i>

                <span v-if="item.hasOwnProperty('character_number')" class="character_number">({{item.character_number}}) </span>

                <span class="filter-item-name pl-1">
                    {{item.name | titleize}}
                    <sup v-if="[1, 3, 6].includes(filter_type) && item.subfolders && item.subfolders.length > 0"> {{item.subfolders.length}}</sup>
                </span>

            </a>

            <folder-row
                v-if="item.subfolders"
                :items="item.subfolders"
                :parent_item="item"
                :filter_type="filter_type"
                :item_type="item_type"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :is_group="is_group"
                :is_first="false"
                :ul_class="'subfolder' + (filter_type !== 1 ? (' ' + filter_classes[filter_type] + '-list custom-icons-list') : '')"
                @on-add-location="addLocation"
                @on-group-changing="changeGroup"
                @on-tag-editing="editTag"
                @on-untag-assets="untagAssets"
                @on-folder-creation="createFolder"
                @on-folder-editing="editFolder"
                @on-folder-moving="moveFolder"
                @on-folder-deletion="deleteFolder"
                @on-content-moving="moveContent"
            ></folder-row>
        </li>
    </ul>
    <ul v-else-if="is_first" :class="ul_class">
        <li v-if="is_group && [12, 13].includes(filter_type) && !selected_customer.id">
            <span class="mr-auto">For this list please first select a {{ is_group_tv_film ? 'production' : 'customer' }}.</span>
        </li>
        <li v-else-if="item_names[filter_type] !== ''">
            <span class="mr-auto">No {{item_names[filter_type]}} Yet. <a v-if="filter_type === 1" href="#" @click="createFolder">Create One?</a></span>
        </li>
    </ul>
</template>

<script>

import GlobalMeta from "@/mixins/global.mixin";

export default {
    name: "FolderRow",
    mixins: [GlobalMeta],
    props: ['items', 'parent_item', 'ul_class', 'is_first', 'filter_type', 'item_type', 'is_group', 'selected_item', 'selected_customer'],
    mounted() {
        this.has_folders_actions_permissions = this.hasPermission('edit_folders') || this.hasPermission('delete_folders') || this.hasPermission('add_folders');
        this.has_tags_actions_permissions = this.hasPermission('edit_tags') || ((this.hasPermission('delete_tags') || this.hasPermission('edit_assets')));

        this.has_addresses_actions_permissions = this.hasPermission('edit_addresses') || (this.hasPermission('delete_addresses') || this.hasPermission('edit_assets'));

    },
    data() {
        return {
            has_folders_actions_permissions: false,
            has_tags_actions_permissions: false,
            has_addresses_actions_permissions: false,
            filter_classes: {
                '0': 'all',
                '1': '',
                '2': 'statuses',
                '3': 'af-categories',
                '4': 'tags',
                '5': 'users',
                '6': 'addresses-filter',
                '7': 'suppliers-filter',
                '8': 'industry-sector-filter',
                '9': 'fdp-filter',
                '10': 'custom-fields-filter',
                '12': 'events-filter',
                '13': 'departments-filter',
                '15': 'fdp_status-filter',
                '16': 'characters-filter',
            },
            item_names: {
                '0': '',
                '1': 'Folders',
                '2': 'Usage',
                '3': 'Categories',
                '4': 'Tags',
                '5': 'Users',
                '6': 'Addresses',
                '7': 'Suppliers',
                '8': 'Industry \\ Sectors',
                '9': '',
                '15': 'FDP Status',
                '10': '',
                '12': 'Events',
                '13': 'Departments',
                '14': 'Liability',
                '16': 'Characters',
            },
        }
    },
    created() {
        if (this.is_group){
            this.filter_classes = {
                '0': 'all',
                '2': 'group-statuses',
                '3': 'group-categories',
                '10': 'group-asset-types',
                '12': 'group-asset-events',
                '13': 'group-asset-departments',
                '15': 'group-asset-fdp_status'
            };
            this.item_names = {
                '0': '',
                '2': 'Usage',
                '3': 'Categories',
                '10': 'Asset Types',
                '12': 'Events',
                '13': 'Departments',
                '15': 'FDP Status'
            };

        }
    },
    methods: {
        canDelete(item, item_type='folders'){
            if (item_type === 'folders'){
                return item.subfolders && item.subfolders.length === 0 && item.assets_count === 0;
            }
            return item.assets_count === 0;
        },
        getCounts(item){

            let subfolders_length = item.subfolders ? item.subfolders.length : 0;
            let subfolders_text = [1,3].includes(this.filter_type) ? ( this.filter_type === 1 ? 'F' : 'C' ) + ':' + subfolders_length + ', ' : '';
            return '{' + subfolders_text + 'A: ' + item.assets_count + ', Qty: ' + this.getAssetsTotalQty(item) + '}';
        },
        getAssetsTotalQty(item){
            return item.assets_sum_quantity ? item.assets_sum_quantity : 0;
        },
        changeGroup(selected_item, event){
            this.set_usermeta('filter_type_id' + (this.is_group ? '_group' : ''), (selected_item.hasOwnProperty('value') ? selected_item.value : selected_item.id), '');
            this.$emit('on-group-changing', selected_item);
        },
        createFolder(parent_id=''){
            this.$emit('on-folder-creation', parent_id);
        },
        editTag(item=null, item_type='tags'){
            this.$emit('on-tag-editing', item, item_type);
        },
        addLocation(item=null){
            this.$emit('on-add-location', item);
        },
        untagAssets(item='', item_type='tags', event){
            this.$emit('on-untag-assets', item, item_type);
        },
        deleteTag(item_id='', item_type='', event){
            this.$emit('on-folder-deletion', item_id, item_type, event);
        },
        editFolder(item=null){
            this.$emit('on-folder-editing', item);
        },
        moveFolder(item=null){
            this.$emit('on-folder-moving', item);
        },
        deleteFolder(item_id='', item_type='folders', event){
            this.$emit('on-folder-deletion', item_id, item_type, event);
        },
        moveContent(item='', item_type='folders', event){
            this.$emit('on-content-moving', item, item_type, event);
        },
        getStyleForRow(item){

            if (item && this.selected_item ){
                if ( (item.id === this.selected_item.id) &&
                    this.selected_item.hasOwnProperty('force_open') &&
                    this.selected_item.force_open){
                    return 'display: block';
                }
            }
            if (!this.is_first){
                return 'display: none';
            }
            return '';
        }
    }

}
</script>

<style scoped>


</style>
