<template>
    <div>
        <div class="common-step-panel use-section">
            <div class="row">
                <div class="col-xl-12">
                    <div class="alert alert-info">
                        <i class="mdi mdi-alert-circle-outline me-2"></i> Please Note: We recommend uploading photos in smaller batches as performance may vary (based on your device, browser, image size and bandwidth).
                    </div>
                </div>

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :received_toggle="section_group.minimized">

                        <div class="step-common-info-right-block" v-if="key === 'image_upload_section'">
                            <div class="st-drop-file-block">
                                <div class="st-drop-file-box">
                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="dropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-removed-file="fileRemoved"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <div class="st-upload-title">
                                                <h3>Drop files here or click to upload</h3>
                                                <p>... or click to select a file from your computer</p>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone[0].$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New Image</b-button>
                                </div>
                            </div>
                        </div>

                        <StepCommonInfoBlock
                            v-else
                            :add-form.sync="addForm"
                            :block_key="key"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :changed-radio-value="changedRadioValue"
                            :my_lists_values="lists_values"
                            :without_asset_passport_name="true"
                        />
                    </AssetInfoSection>

                </div>

            </div>
        </div>
        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'bulk-photo-uploader' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="'Upload'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import Layout from "@/layouts/main";
import Resource from '@/api/resource';
const referenceResource = new Resource('api/assetflow/assets/getNewRef');
const assetResource = new Resource('api/assetflow/assets');
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";
import AssetInfoSection from "./assetForm/AssetInfoSection";

import AMDepartmentShared from "@/mixins/AMDepartmentShared.mixin";
import StepCommonInfoBlock from "../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";

export default {
    components: {StepCommonInfoBlock, AssetInfoSection, AFButton, Layout},
    mixins: [dropzoneMixin, AMDepartmentShared],
    name: 'BulkPhotoUpload',
    props: ['item_type'],
    data(){
        return {
            required_fields:{
                department_id: {
                    name: 'department',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
            },
            confirmation_message: 'Please do not leave this page whilst your images are processed. You will be redirected when all Assets have been created.',
            show_confirmation_box: false,
            item_info: null,
            addForm: {
                asset_images: [],
                filled_asset_images: [],
                item_details: {
                    department_id: null,
                },
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {
                        department_section: {
                            name: 'Department',
                            minimized: false,
                            fields: [
                                'department_id',
                            ]
                        },
                        image_upload_section: {
                            name: 'Image Upload',
                            minimized: false,
                            fields: {}
                        },
                    }
                },
            },
        }
    },
    created() {
        if (!this.has_departments){
            delete this.columns_class.left_column.fields_list.department_section;
        }
    },
    methods:{
        changedRadioValue(field, event) {

        },
        cancelClicked(){
            window.location.href = '/' + this.item_type + '/assets?t=assets';
        },
        async addItem(event){
            if (this.has_departments && !this.addForm.item_details.department_id){
                this.presentToast("Error!",
                    'To proceed, Please select a department.',
                    'danger',
                    3000,
                    true,
                    true);
                this.buttonkey++;
                return;
            }

            this.is_saving_enabled = false;
            this.show_confirmation_box = true;
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (!all_files){
                return;
            }

            // Display initial progress indicator
            this.$swal.fire({
                customClass: {
                    title: 'text-af-accent af-primary p-3',
                    timerProgressBar: 'af-primary p-1'
                },
                allowOutsideClick:false,
                showCancelButton:false,
                showCloseButton:false,
                showConfirmButton:false,
                title: '<span class="af-swal-title mt-3">Please wait...</span>',
                html: '<p class="text-center mt-3 processed-items">Processing item 0 / ' + all_files.length + '</p><p class="text-center mt-3 confirmation-msg">' + this.confirmation_message + '</p>',
                width: '50%',
                // timerProgressBar: true,
                // timer: all_files.length*3000 // Initial timer (adjust as needed)
            });


            for (const allFilesKey in all_files) {
                let new_image_asset = all_files[allFilesKey];

                await this.$swal.update({
                    html: '<p class="text-center mt-3 processed-items">Adding Asset ' + (parseInt(allFilesKey) + 1) + ' / ' + all_files.length + '</p><p class="text-center mt-3 confirmation-msg">' + this.confirmation_message + '</p>',
                });

                let edited_all_files = [new_image_asset];
                edited_all_files = this.organizeAssetImages(edited_all_files);

                let params = {
                    item_type: 'assets'
                }
                const { data } = await referenceResource.store(params);
                let asset_internal_reference = data.data.items;
                let image_name = new_image_asset.image_title;
                if (image_name.includes(".")){
                    image_name = image_name.split('.').slice(0, -1).join('.');
                }

                let item_details = {
                    name: image_name,
                    description: new_image_asset.image_description,
                    internal_reference: asset_internal_reference,
                    is_unique: 1,
                    quantity: 1,
                    department_id: this.addForm.item_details.department_id ? this.addForm.item_details.department_id.id : 6
                }

                let asset_params = {
                    asset_images: edited_all_files,
                    item_details: item_details,
                    asset_meta: {},
                }

                const response = await assetResource.store(asset_params);
                if (!response){
                    return;
                }
                const asset_response = response.data;
                let new_item = asset_response.data.items;

                // shall send request to add, then get asset
                let message = new_item ? ('Added Asset { ASS-' + new_item.uid +' } Successfully') : asset_response.data.msg;
                let level = (new_item ? 'success' : 'danger');

                this.presentToast((new_item ? "Success!" : "Error!"),
                    message,
                    level,
                    3000,
                    true,
                    true);


                if (parseInt(allFilesKey) === (all_files.length - 1)){
                    window.flash('Finished Uploading All Images', 'success');
                    window.location.href = '/' + this.item_type + '/assets?t=assets';
                    // this.is_saving_enabled = true;
                    // this.show_confirmation_box = false;
                    // // Hide the alert when all items are processed
                    // this.$swal.close();
                    // this.buttonkey++;
                }
            }
        },

    }
}
</script>
