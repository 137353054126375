<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item" v-if="!tab.isHidden">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="ledger_overview_tab" role="tabpanel" :class="isActiveTab('ledger_overview_tab')" v-if="!tabs.ledger_overview_tab.isHidden">
                <LedgerOverviewTab :block_contents="block_contents"/>
            </div>

            <div class="tab-pane" id="ledger_receipts_tab" role="tabpanel" :class="isActiveTab('ledger_receipts_tab')" v-if="!tabs.ledger_receipts_tab.isHidden">

                <div class="row">
                    <NewCustomGenericBlock
                        block_title="Summary"
                        columns_count_withclasses="12"
                        box_colour_v="-"
                    >
                        <template v-slot:all_content>
                            <AcquisitionTotalsBlocksForLedger :item_info="item_info" :get-ledger-total-for="getLedgerTotalFor" />
                        </template>
                    </NewCustomGenericBlock>
                </div>

                <LedgerReceiptsList :item_info="item_info" @after-item-moving="moveItemTo"/>
            </div>

            <div class="tab-pane" id="ledger_pending_receipts_tab" role="tabpanel" :class="isActiveTab('ledger_pending_receipts_tab')" v-if="!tabs.ledger_pending_receipts_tab.isHidden">
                <LedgerPendingReceiptsList :item_info="item_info" @after-item-moving="moveItemTo"/>
            </div>

            <div class="tab-pane" id="ledger_transactions_tab" role="tabpanel" :class="isActiveTab('ledger_transactions_tab')" v-if="!tabs.ledger_transactions_tab.isHidden">
                <LedgerTransactionsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="ledger_activity_tab" role="tabpanel" :class="isActiveTab('ledger_activity_tab')" v-if="!tabs.ledger_activity_tab.isHidden">
                <LedgerActivityTab :item_info="item_info"/>
            </div>
        </div>


    </div>
</template>
<script>

import LedgerOverviewTab from "./LedgerOverviewTab";
import LedgerActivityTab from "./LedgerActivityTab";
import LedgerTransactionsTab from "./LedgerTransactionsTab";
import LedgerReceiptsList from "./LedgerReceiptsList";
import LedgerPendingReceiptsList from "./LedgerPendingReceiptsList";
import AcquisitionTotalsBlocksForLedger from "./AcquisitionTotalsBlocksForLedger";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";

export default {
    name: 'CustomLedgerDetails',
    components: {
        NewCustomGenericBlock,
        LedgerPendingReceiptsList,
        AcquisitionTotalsBlocksForLedger,
        LedgerReceiptsList, LedgerTransactionsTab, LedgerActivityTab, LedgerOverviewTab},
    props: ['item_info'],
    computed:{
    },
    data(){
        return {
            active_tab: 'ledger_overview_tab',
            tabs: {
                'ledger_overview_tab': {
                    'id': "ledger_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "ledger_overview_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'ledger_receipts_tab': {
                    'id': "ledger_receipts_tab",
                    'name': "Receipts",
                    'icon': "user",
                    'type': "ledger_receipts_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'ledger_pending_receipts_tab': {
                    'id': "ledger_pending_receipts_tab",
                    'name': "Pending Receipts",
                    'icon': "user",
                    'type': "ledger_pending_receipts_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'ledger_transactions_tab': {
                    'id': "ledger_transactions_tab",
                    'name': "Transactions",
                    'icon': "user",
                    'type': "ledger_transactions_tab",
                    'isActive': false,
                    'isHidden': false
                },
                'ledger_activity_tab': {
                    'id': "ledger_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "ledger_activity_tab",
                    'isActive': false,
                    'isHidden': false
                }
            },
            block_contents: {
                module_group_info:{
                    label: 'P-Card',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-6',
                    info_list: []
                },
                summary_info:{
                    label: 'Summary',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
            },
            is_buyer:false
        }
    },
    created() {
        this.is_buyer = this.item_info.category === 'buyer';

        let ledgers_active_tab = localStorage.getItem('ledgers_active_tab_' + this.is_buyer);

        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'overview':
                    this.active_tab = 'ledger_overview_tab';
                    break;
                case 'transactions':
                    this.active_tab = 'ledger_transactions_tab';
                    break;
                case 'activity':
                    this.active_tab = 'ledger_activity_tab';
                    break;
            }
        }else if (ledgers_active_tab && ledgers_active_tab !== ''){
            this.active_tab = ledgers_active_tab;
        }

        let item_info = this.item_info;

        if (item_info.hasOwnProperty('receipts_info')) {
            if (item_info.receipts_info && item_info.receipts_info.length > 0){
                this.tabs.ledger_receipts_tab.name = 'Receipts (' + item_info.receipts_info.length + ')';
            }
            this.tabs.ledger_receipts_tab.isHidden = false;
        } else {
            this.tabs.ledger_receipts_tab.isHidden = true;
        }


        if (item_info.hasOwnProperty('pending_receipts_info')) {
            if (item_info.pending_receipts_info && item_info.pending_receipts_info.length > 0){
                this.tabs.ledger_pending_receipts_tab.name = 'Pending Receipts (' + item_info.pending_receipts_info.length + ')';
            }
            this.tabs.ledger_pending_receipts_tab.isHidden = false;
        } else {
            this.tabs.ledger_pending_receipts_tab.isHidden = true;
        }


        let item_info_currency = this.getCurrencyByValue(item_info.currency !== '' ? item_info.currency : 'gbp');
        let item_info_currency_icon = '£';
        if (item_info_currency){
            item_info_currency_icon = item_info_currency.icon;
        }

        this.block_contents.module_group_info.info_list = [
            {
                label: 'Account', //'Nominal Code',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.nominal_code !== '';
                },
                info_value : function () {
                    return item_info.nominal_code;
                }
            },
            {
                label: 'Description',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.description !== '';
                },
                info_value : function () {
                    return item_info.description;
                }
            },
        ];
        this.setLedgerSummary();
    },
    methods:{
        setLedgerSummary: function () {

            let used_functions = {
                getCurrencyByValue: this.getCurrencyByValue,
                toUserCurrencyValue: this.toUserCurrencyValue
            }
            let item_info = this.item_info;
            used_functions.net_value = this.getLedgerTotalFor('net_value');
            used_functions.tax_value = this.getLedgerTotalFor('tax_value');
            used_functions.all_items_value = this.getLedgerTotalFor('all_items_value');

            this.block_contents.summary_info.info_list = [
                {
                    label: 'Receipts:',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('receipts_info');
                    },
                    info_value: function () {
                        return item_info.receipts_info.length;
                    }
                },
                {
                    label: 'Total (Net):',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return used_functions.net_value !== '';
                    },
                    tooltip_title: function () {
                        return used_functions.net_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.currency, used_functions.net_value);
                    },
                    info_value: function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        let net_value = used_functions.net_value === '' ? '-' : used_functions.net_value.toFixed(2);
                        return currency_icon + ' ' + net_value;
                    }
                },
                {
                    label: 'Tax:',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return used_functions.tax_value !== '';
                    },
                    tooltip_title: function () {
                        return used_functions.tax_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.currency, used_functions.tax_value);
                    },
                    info_value: function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        let tax_value = used_functions.tax_value === '' ? '-' : used_functions.tax_value.toFixed(2);
                        return currency_icon + ' ' + tax_value;
                    }
                },
                {
                    label: 'Total (Gross):',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return used_functions.all_items_value !== '';
                    },
                    tooltip_title: function () {
                        return used_functions.all_items_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.currency, used_functions.all_items_value);
                    },
                    info_value: function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        let all_items_value = used_functions.all_items_value === '' ? '-' : used_functions.all_items_value.toFixed(2);
                        return currency_icon + ' ' + all_items_value;
                    }
                },
            ];
        },
        getLedgerTotalFor(acquisition_value){
            if (this.item_info.hasOwnProperty('receipts_info') && this.item_info.receipts_info){
                return this.item_info.receipts_info.reduce((a, b) => +a + +b[acquisition_value], 0).toFixed(2);
            }
            return 0;
        },
        moveItemTo(new_item, move_to_item_type, index){
            if (this.item_info.hasOwnProperty(move_to_item_type) && this.item_info[move_to_item_type]){
                this.item_info[move_to_item_type].push(new_item);
                this.item_info[move_to_item_type === 'receipts_info' ? 'pending_receipts_info' : 'receipts_info'].splice(index, 1);
                this.tabs.ledger_receipts_tab.name = 'Receipts (' + this.item_info.receipts_info.length + ')';
                this.tabs.ledger_pending_receipts_tab.name = 'Pending Receipts (' + this.item_info.pending_receipts_info.length + ')';

                this.setLedgerSummary();
            }
        },
        tabClicked(tab_id){
            localStorage.setItem('ledgers_active_tab_' + this.is_buyer, tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
