<template>
    <Layout>
        <div class="" id="wrapper">
            <div id="sidebar-wrapper" class="sidebar">
                <div class="folder-wrapper">
                    <div class="sub-root-top folder-menu active">
                        <ul class="root-folder custom-icons-root root-statuses">
                            <li class="active filled">

                                <a class="root-heading customer-root">
                                    <i class="font-size-18 bx bx-grid settings-title-icon"></i>
                                    <span class="pl-1">Settings</span>
                                </a>

                                <ul class="subfolder statuses-list custom-icons-list">
                                    <li v-for="setting_item in settings_list" :class="setting_item.children.length > 0 ? 'filled' : ''">
                                        <a class="folder-item">
                                            <i :class="'font-size-18 bx bx-minus settings-icon-' + setting_item.id"></i>
                                            <span class="filter-item-name pl-1">{{ setting_item.name }}</span>
                                        </a>
                                        <ul v-if="setting_item.children.length > 0" class="subfolder statuses-list custom-icons-list" style="display: none;">
                                            <li class="" v-for="child_item in setting_item.children">
                                                <a class="folder-item">
                                                    <i :class="'font-size-18 bx bx-minus settings-icon-' + setting_item.id"></i>
                                                    <span class="filter-item-name pl-1">{{ child_item.name }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="page-content-wrapper">
                Content goes here.
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "@/layouts/main";
export default {
    name: "GroupSettingsTabs",
    mixins: [],
    components: {Layout },
    data(){
        return{
            settings_list:{
                parent_group:{
                    id: 'parent_group',
                    name: 'Parent Group',
                    children: [
                        {
                            id: 'overview',
                            name: 'Overview',
                        },
                        {
                            id: 'status',
                            name: 'Status',
                        },
                        {
                            id: 'currency_timezone',
                            name: 'Currency & Timezone',
                        },
                        {
                            id: 'logo_appearance',
                            name: 'Logo & Appearance',
                        },
                    ],
                },
                billing:{
                    id: 'billing',
                    name: 'Billing',
                    children: [
                        {
                            id: 'group_usage_limits',
                            name: 'Group Usage & Limits',
                        },
                        {
                            id: 'subscriptions_pricing',
                            name: 'Subscriptions & Pricing',
                        },
                        {
                            id: 'billing',
                            name: 'Billing',
                        },
                    ],
                },
                users_access: {
                    id: 'users_access',
                    name: 'Users & Access',
                    children: [
                        {
                            id: 'users',
                            name: 'Users',
                        },
                        {
                            id: 'user_roles',
                            name: 'User Roles',
                        }
                    ],
                },
                production_customer_list: {
                    id: 'production_customer_list',
                    name: 'Production\\Customer List',
                    children: [
                        {
                            id: 'production_list',
                            name: 'Production List',
                        }
                    ],
                },
                inheritance_policy: {
                    id: 'inheritance_policy',
                    name: 'Inheritance & Policy',
                    children: [
                        {
                            id: 'overview',
                            name: 'Overview',
                        },
                        {
                            id: 'billing',
                            name: 'Billing',
                        },
                        {
                            id: 'features',
                            name: 'Features',
                        },
                        {
                            id: 'assets',
                            name: 'Assets',
                        },
                        {
                            id: 'asset_flow',
                            name: 'Asset Flow',
                        }
                    ],
                },
            }
        }
    },
    created() {
    },
    methods:{

    }
}
</script>


