<template>
    <Layout>
    <div>
        <div class="row">
            <div class="col-6">
                <v-chart class="chart" :option="pieChart" autoresize/>
            </div>
            <div class="col-6">
                <v-chart class="chart" :option="option" />
            </div>
            <div class="col-6">
                <v-chart class="chart" :option="radarChart" />
            </div>

            <div class="col-6">
                <af-radar-chart
                    :custom_options="customChart"
                ></af-radar-chart>
            </div>
        </div>
    </div>
    </Layout>

</template>

<script>

import Layout from "@/layouts/main";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { GaugeChart } from "echarts/charts";
import { RadarChart } from "echarts/charts";

import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import AfRadarChart from "../../components/charts/AfRadarChart";

use([
    CanvasRenderer,
    PieChart,
    GaugeChart,
    RadarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

export default {
    name: "HelloWorld",
    components: {
        AfRadarChart,
        Layout,
        VChart
    },
    provide: {
        [THEME_KEY]: "ecTheme" //"dark"
    },
    created() {
        this.customChart.sections_colours = this.getSectionColoursUsing('#72ACD1');
    },
    methods:{
        getSectionColoursUsing(colour = '#72ACD1'){
            let sections_colours = [];
            for (const key in [0,1,2,3,4]) {
                sections_colours.push(this.convertHex(colour, (parseInt(key) + 1) * 20));
            }
            return sections_colours;
        },
        convertHex (color, opacity) {
            color = color.replace('#', '')
            const r = parseInt(color.substring(0, 2), 16)
            const g = parseInt(color.substring(2, 4), 16)
            const b = parseInt(color.substring(4, 6), 16)
            return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
        }
    },
    data() {
        return {
            customChart: {
                titles_colour: "#fff",
                titles_bgcolour: "#999",
                titles: [
                    { text: 'Planning' },
                    { text: 'Acquisition' },
                    { text: 'Assignments' },
                    { text: 'Physical' },
                    { text: 'Cost' }
                ],
                sections_colours: [], // >> should be in this format #xxxxxx
                lines_colour: 'yellowgreen',
                area_colour: 'red',
                data_values: [60, 5, 0.30, -100, 1500]
            },
            radarChart: {
                radar: {
                    // shape: 'circle',
                    axisName: {
                        color: '#fff',
                        backgroundColor: '#999',
                        borderRadius: 3,
                        padding: [3, 5]
                    },
                    indicator: [
                        { name: '销售（sales）', max: 6500},
                        { name: '管理（Administration）', max: 16000},
                        { name: '信息技术（Information Techology）', max: 30000},
                        { name: '客服（Customer Support）', max: 38000},
                        { name: '研发（Development）', max: 52000},
                        { name: '市场（Marketing）', max: 25000}
                    ]
                },
                series: [{
                    name: '预算 vs 开销（Budget vs spending）',
                    type: 'radar',
                    // areaStyle: {normal: {}},
                    data: [
                        {
                            value: [4300, 10000, 28000, 35000, 50000, 19000],
                            name: '预算分配（Allocated Budget）'
                        },
                        {
                            value: [5000, 14000, 28000, 31000, 42000, 21000],
                            name: '实际开销（Actual Spending）'
                        }
                    ]
                }]
            },
            option: {
                tooltip: {
                    formatter: '{a} <br/>{b} : {c}%'
                },
                toolbox: {
                    feature: {
                        restore: {},
                        saveAsImage: {}
                    }
                },
                series: [{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 50,
                            color: [
                                [0.20, '#FF6E76'],
                                [0.40, '#FDDD60'],
                                [0.60, '#58D9F9'],
                                [0.80, '#7CFFB2'],
                                [1, '#9267cf']
                            ]
                        }
                    },
                    pointer: {
                        // icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '50%',
                        width: 5,
                        offsetCenter: [0, '-20%'],
                        itemStyle: {
                            color: 'inherit'
                        }
                    },
                    axisTick: {
                        lineStyle: {
                            width: 0
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            width: 0
                        }
                    },
                    axisLabel: {
                        color: '#464646',
                        fontSize: 20,
                        distance: -60,
                        formatter: function (value) {
                            if (value === 1.00) {
                                return 'E';
                            }
                            else if (value === 0.750) {
                                return 'D';
                            }
                            else if (value === 0.500) {
                                return 'C';
                            }
                            else if (value === 0.250) {
                                return 'B';
                            }
                            else if (value === 0.00) {
                                return 'A';
                            }
                        }
                    },
                    // title: {
                    //     offsetCenter: [0, '-20%'],
                    //     fontSize: 30
                    // },
                    detail: {
                        fontSize: 50,
                        offsetCenter: [0, '0%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            return Math.round(value * 100) + '%';
                        },
                        color: 'inherit'
                    },
                    data: [{
                        value: 0.50
                    }]
                }]
            },
            pieChart: {
                title: {
                    text: "Traffic Sources",
                    left: "center"
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                legend: {
                    orient: "vertical",
                    left: "left",
                    data: [
                        "Direct",
                        "Email",
                        "Ad Networks",
                        "Video Ads",
                        "Search Engines"
                    ]
                },
                series: [
                    {
                        name: "Traffic Sources",
                        type: "pie",
                        radius: "55%",
                        center: ["50%", "60%"],
                        data: [
                            { value: 335, name: "Direct" },
                            { value: 310, name: "Email" },
                            { value: 234, name: "Ad Networks" },
                            { value: 135, name: "Video Ads" },
                            { value: 1548, name: "Search Engines" }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                        }
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.chart {
    height: 100vh;
}
</style>

