<template>
    <div class="intelligence-template custom-change-details">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">

            <div class="tab-pane" id="change_overview_tab" role="tabpanel" :class="isActiveTab('change_overview_tab')">
                <ChangeSceneOverviewTab :item_info="item_info" :go-to-tab="goToTab"/>
            </div>
            <div class="tab-pane" id="change_activity_tab" role="tabpanel" :class="isActiveTab('change_activity_tab')">
                <ChangeSceneActivityTab :item_info="item_info" @on-post-adding="addNewPost"/>
            </div>
            <div class="tab-pane" id="change_tasks_tab" role="tabpanel" :class="isActiveTab('change_tasks_tab')">
                <ChangeSceneTasksTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="change_media_tab" role="tabpanel" :class="isActiveTab('change_media_tab')">
                <MediaTab :item_info="item_info" object_type="changes-scenes"/>
            </div>
            <div class="tab-pane" id="change_costume_tab" role="tabpanel" :class="isActiveTab('change_costume_tab')">
                <ChangeSceneCostumeTab :change_info="item_info.change_info" :item_type="item_info.current_page_type" :item_info="item_info" @after-item-moving="moveItemTo"/>
            </div>
            <div class="tab-pane" id="change_closet_tab" role="tabpanel" :class="isActiveTab('change_closet_tab')">
                <ChangeSceneClosetTab :item_info="item_info.change_info" :item_type="item_info.current_page_type" @after-item-moving="moveItemTo"/>
            </div>
            <div class="tab-pane" id="change_scene_scenes_tab" role="tabpanel" :class="isActiveTab('change_scene_scenes_tab')">
                <ChangeSceneScenesTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="change_scene_prev_scene_tab" role="tabpanel" :class="isActiveTab('change_scene_prev_scene_tab')">
                <ChangeScenePrevSceneTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="change_scene_next_scene_tab" role="tabpanel" :class="isActiveTab('change_scene_next_scene_tab')">
                <ChangeSceneNextSceneTab :item_info="item_info"/>
            </div>
        </div>

    </div>
</template>
<script>
import SetChangeSceneDescription from "./ChangeSceneRel/SetChangeSceneDescription";
import ChangeSceneActivityTab from "./ChangeSceneRel/ChangeSceneActivityTab";
import ChangeSceneTasksTab from "./ChangeSceneRel/ChangeSceneTasksTab";
import ChangeSceneCostumeTab from "./ChangeSceneRel/ChangeSceneCostumeTab";
import ChangeSceneClosetTab from "./ChangeSceneRel/ChangeSceneClosetTab";
import ChangeSceneScenesTab from "./ChangeSceneRel/ChangeSceneScenesTab";
import ChangeScenePrevSceneTab from "./ChangeSceneRel/ChangeScenePrevSceneTab";
import ChangeSceneNextSceneTab from "./ChangeSceneRel/ChangeSceneNextSceneTab";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import ChangeSceneOverviewTab from "./ChangeSceneRel/ChangeSceneOverviewTab";
import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";

export default {
    name: 'CustomChangeSceneDetails',
    components: {
        MediaTab,
        ChangeSceneOverviewTab,
        SetChangeSceneDescription,
        NewCustomGenericBlock,
        ChangeSceneActivityTab,
        ChangeSceneTasksTab,
        ChangeSceneCostumeTab,
        ChangeSceneClosetTab,
        ChangeSceneScenesTab,
        ChangeScenePrevSceneTab,
        ChangeSceneNextSceneTab
    },
    props: ['item_info'],
    data(){
        return {
            active_tab: 'change_overview_tab',
            part_change_description: '',
            tabs: {
                'change_overview_tab': {
                    'id': "change_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "change_overview_tab",
                    'isActive': false
                },
                'change_activity_tab': {
                    'id': "change_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "change_activity_tab",
                    'isActive': false
                },
                // 'change_tasks_tab': {
                //     'id': "change_tasks_tab",
                //     'name': "Tasks",
                //     'icon': "user",
                //     'type': "change_tasks_tab",
                //     'isActive': false
                // },
                'change_media_tab': {
                    'id': "change_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "change_media_tab",
                    'isActive': false
                },
                'change_costume_tab': {
                    'id': "change_costume_tab",
                    'name': "Costume",
                    'icon': "user",
                    'type': "change_costume_tab",
                    'isActive': false
                },
                'change_closet_tab': {
                    'id': "change_closet_tab",
                    'name': "Closet",
                    'icon': "user",
                    'type': "change_closet_tab",
                    'isActive': false
                },
                'change_scene_scenes_tab': {
                    'id': "change_scene_scenes_tab",
                    'name': "Scenes",
                    'icon': "user",
                    'type': "change_scene_scenes_tab",
                    'isActive': false
                },
                'change_scene_prev_scene_tab': {
                    'id': "change_scene_prev_scene_tab",
                    'name': "Prev Scene",
                    'icon': "user",
                    'type': "change_scene_prev_scene_tab",
                    'isActive': false
                },
                'change_scene_next_scene_tab': {
                    'id': "change_scene_next_scene_tab",
                    'name': "Next Scene",
                    'icon': "user",
                    'type': "change_scene_next_scene_tab",
                    'isActive': false
                },
            },
        }
    },
    computed: {
        change_info() {
            return this.item_info.hasOwnProperty('change_info') && this.item_info.change_info ? this.item_info.change_info : null;
        },
    },
    created() {
        let changes_active_tab = localStorage.getItem('changes_active_tab');

        this.part_change_description = this.item_info.description;

        if (this.change_info){
            let change_info = this.change_info;
            if (change_info.hasOwnProperty('costume_items') && change_info.costume_items && change_info.costume_items.length > 0){
                this.tabs.change_costume_tab.name = 'Costume (' + change_info.costume_items.length + ')';
            }

            if (change_info.hasOwnProperty('closet_items') && change_info.closet_items && change_info.closet_items.length > 0){
                this.tabs.change_closet_tab.name = 'Closet (' + change_info.closet_items.length + ')';
            }

            if (change_info.hasOwnProperty('parts_info') && change_info.parts_info && change_info.parts_info.length > 0){
                this.tabs.change_scene_scenes_tab.name = 'Scenes (' + change_info.parts_info.length + ')';
            }
        }


        // if (this.item_info.hasOwnProperty('tasks_list') && this.item_info.tasks_list && this.item_info.tasks_list.length > 0){
        //     this.tabs.change_tasks_tab.name = 'Tasks (' + this.item_info.tasks_list.length + ')';
        // }

        if (this.item_info.hasOwnProperty('prev_scene_info') && this.item_info.prev_scene_info){
            this.tabs.change_scene_prev_scene_tab.name = 'Prev: (' + this.item_info.prev_scene_info.name + ')';
        }

        if (this.item_info.hasOwnProperty('next_scene_info') && this.item_info.next_scene_info){
            this.tabs.change_scene_next_scene_tab.name = 'Prev: (' + this.item_info.next_scene_info.name + ')';
        }else{
            delete this.tabs.change_scene_next_scene_tab;
        }

        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'activity':
                    this.active_tab = 'change_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'change_media_tab';
                    break;
                case 'measurements':
                    this.active_tab = 'change_measurements_tab';
                    break;
                case 'scenes':
                    this.active_tab = 'change_scenes_tab';
                    break;
                case 'assets':
                    this.active_tab = 'change_assets_tab';
                    break;
                case 'changes':
                    this.active_tab = 'change_changes_tab';
                    break;
            }
        }else if (changes_active_tab && changes_active_tab !== ''){
            this.active_tab = changes_active_tab;
        }

    },
    methods:{
        addNewPost(item, msg, status){

            if (this.item_info.asset_posts.length > 0){
                this.item_info.asset_posts.splice(0, 0, item);
            }else{
                this.item_info.asset_posts = [item];
            }

            let post_images = item.post_images;
            if (post_images){
                if (!this.item_info.asset_images){
                    this.item_info.asset_images = [];
                }
                this.item_info.asset_images = this.item_info.asset_images.concat(post_images);
            }

        },
        goToTab(tab_id){
            this.tabClicked(tab_id);
        },
        moveItemTo(new_item, move_to_item_type, index){
            if (this.change_info.hasOwnProperty(move_to_item_type) && this.change_info[move_to_item_type]){
                this.change_info[move_to_item_type].push(new_item);

                this.change_info[move_to_item_type === 'closet_items' ? 'costume_items' : 'closet_items'].splice(index, 1);

                this.tabs.change_closet_tab.name = 'Closet (' + this.change_info.closet_items.length + ')';
                this.tabs.change_costume_tab.name = 'Costume (' + this.change_info.costume_items.length + ')';
            }
        },
        tabClicked(tab_id){
            this.active_tab = tab_id;
            localStorage.setItem('changes_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
