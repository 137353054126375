<template>
    <div v-if="(final_disposition_planning.hasOwnProperty(field_name) && final_disposition_planning[field_name]) || ['disposition_details', 'contact_id', 'department_id', 'address_id', 'location_id'].includes(field_name)"
         class="col-xl-12 mt-1 mb-1">



        <div class="changes" v-if="false && ['contact_id', 'department_id', 'address_id', 'location_id'].includes(field_name)">
            <div v-if="field_name === 'contact_id'">
                <small><b>Current Contact</b>: {{ my_asset.contact_info ? my_asset.contact_info.name : 'Not Set' }}</small>
                <br>
                <small><b>New Contact</b>: {{ addForm.contact_id ? (addForm.contact_id.hasOwnProperty('user') ? addForm.contact_id.user.name : addForm.contact_id.name) : 'Not Set' }}</small>
            </div>

            <div v-if="field_name === 'department_id'">
                <small><b>Current Department</b>: {{ my_asset.department_info ? my_asset.department_info.name : 'Not Set' }}</small>
                <br>
                <small><b>New Department</b>: {{ addForm.department_id ? addForm.department_id.name : 'Not Set' }}</small>
            </div>

            <div v-if="field_name === 'address_id'">
                <small><b>Current Address</b>: {{ my_asset.address_info ? my_asset.address_info.name : 'Not Set' }}</small>
                <br>
                <small><b>New Address</b>: {{ addForm.address_id ? addForm.address_id.name : 'Not Set' }}</small>
            </div>

            <div v-if="field_name === 'location_id'">
                <small><b>Current Location</b>: {{ my_asset.location_info ? my_asset.location_info.name : 'Not Set' }}</small>
                <br>
                <small><b>New Location</b>: {{ addForm.location_id ? addForm.location_id.name : 'Not Set' }}</small>
            </div>
        </div>


        <div class="common-st-title-label"><h5>{{ field_label }}</h5></div>
        <div class="common--input-filed">
            <date-picker
                v-if="field_type === 'date'"
                v-model="addForm[field_name]"
                lang="en" :first-day-of-week="1"
                format="DD-MM-YYYY"
                value-type="DD-MM-YYYY"
                :placeholder="field_label"/>



            <div v-else-if="['text', 'number'].includes(field_type)"  :class="field_name === 'has_amount' ? 'unit-input' : ''">
                <input
                    v-model="addForm[field_name]"
                    aria-describedby="_sale_price_auto_desc"
                    :id="field_name"
                    :name="field_name"
                    :class="'form-control disposal-field-' + field_name"
                    :type="field_type"
                />
                <span v-if="field_name === 'has_amount' && my_asset.used_currency" class="u-mm">{{ getCurrencyByValue(my_asset.used_currency).icon || ''}}</span>
            </div>

            <div v-else-if="field_type === 'textarea'" class="common--input-filed">
                <textarea
                    v-model="addForm[field_name]"
                    :id="field_name"
                    :name="field_name"
                    :class="'disposal-field-' + field_name"
                    spellcheck="false"></textarea>
            </div>

            <div v-else-if="['radio-select', 'single-select'].includes(field_type)" class="common--input-filed unit-input mb-1">
                <div class="st-status-radio-box">
                    <div class="st-radio-row">
                        <div v-for="(option, index) in organised_options"
                             :key="index"
                             class="col-3">
                            <div class="cm-radio-btn" :class="'mt-1 '+field_name+'-' + option.id">
                                <label>
                                    <input
                                        v-model="addForm[field_name]"
                                        :key="index"
                                        :id="'_'+index"
                                        :name="field_name"
                                        :value="option"
                                        :disabled="field_name === 'location_id' && !addForm.address_id"
                                        @input="selectedRadioButton(field_name, option, $event)"
                                        type="radio" />
                                    <div class="st-radio-box">
                                        <p v-if="field_name === 'contact_id' && option.hasOwnProperty('user')">{{ option.user.name | titleize }}</p>
                                        <p v-else>{{ option.name | titleize }}</p>
                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import DatePicker from "vue2-datepicker"

export default {
    name: 'handleDispositionField',
    components: {DatePicker},
    props: {
        my_asset: {},
        addForm: {},
        final_disposition_planning: {},
        field_label: {},
        field_name: {},
        field_type: {},
        received_field_options: {},
    },
    data(){
        return {
            organised_options:[],
            field_options:[],
        }
    },
    created() {
        if (this.received_field_options){
            this.updateLocationsList(this.received_field_options);
        }
    },
    methods:{
        updateLocationsList(locations_list){
            this.field_options = locations_list;
            this.organised_options = this.getCustomOptionsForKey();
        },
        getCustomOptionsForKey(){
            // shall ??
            let options_list = [...this.field_options];
            let first_options = [
                {
                    id: -98,
                    name: 'No Change'
                },
                {
                    id: -97,
                    name: 'Reset'
                }
            ];
            return first_options.concat(options_list);
        },

        selectedRadioButton(field, option, event){
            if (field === 'address_id'){
                this.$emit('update_locations_list', option);
            }
            this.$emit('set_field', field, option);
        },
    }
}
</script>
