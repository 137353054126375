<template>
    <div>

        <div v-if="supplier" class="products">
            <div class="product-item">
                <div class="product-block">
                    <!-- Supplier Initials Start-->
                    <div class="supplier-map-block">
                        <div v-if="supplier.initials" class="avatar-sm mx-auto mb-4">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">{{supplier.initials | capitalize}}</span>
                        </div>
                        <div v-else class="placeholder-ai text-af-accent">
                            <i class="bx bx-cube"></i>
                        </div>
                    </div>
                    <!-- Supplier Initials End-->

                    <!-- Product Details Start-->
                    <div class="product-details-left">
                        <h3 class="product-title">
                            {{ supplier.name | titleize }}
                        </h3>

                        <div class="product-usage">
                            <div class="usage-inner-wrap">
                                <div>Contact<b>{{ supplier.contact | titleize }}</b></div>
                                <div>Phone<b>{{ supplier.phone | titleize }}</b></div>
                                <div>Website<b><a :href="supplier.website">{{ supplier.website | titleize }}</a></b></div>
                            </div>
                        </div>
                        <div class="row statuses-row">
                            <div class="supplier-types af-accent">
                                                        <span class="supplier-status">
                                                            {{ (supplier.status ? 'Active' : "Inactive") | titleize }}
                                                        </span>
                            </div>
                        </div>

                    </div>
                    <div class="product-details-right list-show">
                        <div class="tag-wrap list-show">
                            <ul>
                                <li :class="'af-accent supplier-status mr-1 mt-1'">
                                    <a href="">{{ (supplier.status ? 'Active' : "Inactive")  | titleize }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Product Details End-->


                    <div v-if="has_supplier_actions_permissions"
                         class="btn-group my-dots-horizontal bl-auto af-accent">
                        <button type="button" class="btn btn-none dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-horizontal text-white"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <b-dropdown-item v-if="hasPermission('edit_assets')"
                                             :href="'/' + supplier.current_page_type + '/supplier/' + supplier.id + '/edit'">
                                Edit
                            </b-dropdown-item>
                            <b-dropdown-item v-if="hasPermission('delete_assets')"
                                             @click="performAction(product, 'deleteAsset', $event)">
                                Delete
                            </b-dropdown-item>
                            <b-dropdown-item v-if="hasPermission('edit_assets')"
                                             @click="performAction(product, 'changeFolder', $event)">
                                Change
                                Folder
                            </b-dropdown-item>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="supplier.address_info && checkCoordinates(supplier.address_info)" class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h5>Supplier Address</h5>
                    <!-- Address Map Start-->
                    <div class="address-map-block">
                        <gmap-map
                            :center="getPosition(supplier.address_info)"
                            :zoom="9"
                            class="address-map"
                            :options="mapOptions"
                            @click="onMapClick">
                            <gmap-marker :position="getPosition(supplier.address_info)"></gmap-marker>
                        </gmap-map>
                    </div>
                    <!-- Address Map End-->
                </div>
            </div>
            <!-- end card -->
        </div>

    </div>
</template>

<script>

import AddressActions from "@/mixins/addressActions.mixin";

export default {
    name: "SupplierDetails",
    mixins: [AddressActions],
    props: {
        supplier: {
            type: Object
        },
    },
    data() {
        return {
            has_supplier_actions_permissions: false
        };
    },
    created() {
    },
    methods: {
        getKeyName(value){
            if (value.indexOf('modified') !== -1){
                value = value.replace('modified_', '');
            }
            return value;
        },
        getValueFor(value){
            switch (value) {
                case 'colours':
                case 'tag_ids':
                case 'composition':
                    return this.supplier[value] ? this.supplier[value].join(', ') : this.supplier[value];
                default:
                    return this.supplier[value] != null && this.supplier[value] != '' ? this.supplier[value] : '-';
            }
        },
        performAction(item, action_name, event) {
            switch (action_name) {
                case 'deleteSupplier':
                    this.deleteItem(item.id, item.name, 'supplier', event);
                    break;
                case 'changeFolder':
                    console.log("should changeFolder?");
                    break;
            }
        },
        deleteItem(item_id, item_name, item_type, event) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    window.location = encodeURI("/" + this.supplier.current_page_type + "/" + this.supplier.current_page_type);
                });
        },
    }
}
</script>


