export const AddressesSortOptions = [
    {
        value: 1,
        text: 'A~Z',
        name: 'name',
        reversed: false
    },
    {
        value: 2,
        text: 'Z~A',
        name: 'name',
        reversed: true
    },
    {
        value: 3,
        text: 'Status',
        name: 'status',
        reversed: false,
        first_criteria: 'is_default',
        first_reversed: true,
        second_criteria: 'name',
        second_reversed: false
    },
    {
        value: 4,
        text: 'Created Date: New to Old',
        name: 'created_at',
        reversed: true
    },
    {
        value: 5,
        text: 'Created Date: Old to New',
        name: 'created_at',
        reversed: false
    },
    {
        value: 6,
        text: 'Modified Date: New to Old',
        name: 'updated_at',
        reversed: true
    },
    {
        value: 7,
        text: 'Modified Date: Old to New',
        name: 'updated_at',
        reversed: false
    },
];
