<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <settings-features-list item_type="settings"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemsList from "./ItemsList";
import PoLines from "../../../items/widgets/detailsViews/PoLines";
import SettingsFeaturesList from "./SettingsFeaturesList";

export default {
    name: "raw_settings",
    components: {SettingsFeaturesList, PoLines, ItemsList},
    props:['selected_setting']
}
</script>

