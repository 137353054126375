export default {
    data(){
        return {
            isSavingItem: false,
        }
    },
    created: function () {
    },
    methods: {
        resetSelectedItem(){
            this.setSelectedItem({});
            this.isSavingItem = false;
        },
        setValueFromOptions: function ( field_name, item_id, default_value = null, is_multi=false) {
            let found_item;
            if (item_id !== undefined){
                if (is_multi){
                    found_item = this.required_fields[field_name].options.filter((item) => {
                        return item_id.includes(item.id);
                    });
                }else{
                    found_item = _.find(this.required_fields[field_name].options, function (o) {
                        return o.id === item_id;
                    });
                }

                if (found_item){
                    this.addForm[field_name] = found_item ? found_item : default_value;
                }
            }
        },
    }
}
