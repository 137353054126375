<template>
    <div :class="used_classes">

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <EditChangeNotes
            ref="edit-change-notes"
            :item_type="'changes-notes' + unique_id"
            modal_title="Edit Change Notes"
            :with_item_details="false"
            @on-new-item-adding="reloadPage"
        >
        </EditChangeNotes>

        <EditChangeScenes
            ref="edit-change-scenes"
            :item_type="'changes-scenes' + unique_id"
            modal_title="Edit Change Scenes"
            :with_item_details="false"
            @on-new-item-adding="reloadPage"
        >
        </EditChangeScenes>

        <EditChangeSetup
            ref="edit-change-setup"
            :item_type="'changes-setup' + unique_id"
            modal_title="Edit Change Setup"
            :with_item_details="false"
            @on-new-item-adding="reloadPage"
        >
        </EditChangeSetup>


        <EditGeneratePdf
            ref="edit-generate-pdf"
            :item_type="'generate-pdf' + unique_id"
            modal_title="Generate PDF"
            object_type="change"
            :with_item_details="false"
            @on-new-item-adding="reloadPage"
        >
        </EditGeneratePdf>


        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_list_view_title">
                    <div class="d-flex-p">
                        <div>
                            <span class="button_size acq-status" :class="getChangeStatus(true)">{{getChangeStatus()}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="cm_product_more_btn mt-4">

            <div v-if="hasPermission('edit_changes') || hasPermission('delete_changes')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>

                    <ChangeActionsList :item="change_item_info" :index="0" :item_type="item_type" object_type="changes" :perform-action="performAction"/>

                </button>
                <AddChangeAssetsModal
                    :ref="add_assets_modal_id + unique_id"
                    :received_modal_id="add_assets_modal_id + unique_id"
                    object_type="changes"
                    :item_type="change_item_info.current_page_type"
                    :modal_title="add_assets_modal_title"
                    @onFormSubmit="onFormSubmit"
                />

            </div>
        </div>

        <MyBCarouselImages parent_class="cm_product_grid_img mt-4" :my_asset="change_item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_status_ul change_details_qty">
                    <ul>
                        <li>
                            <p>Assets <span class="total_box_bg">-</span></p>
                        </li>
                        <li>
                            <p>Tasks <span class="total_box_bg">-</span></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import AddChangeAssetsModal from "../customers/changes/Modals/AddChangeAssetsModal";
import ChangeActionsList from "./ChangeActionsList";
import EditChangeSetup from "../customers/extras/EditChangeSetup";
import EditChangeScenes from "../customers/extras/EditChangeScenes";
import EditChangeNotes from "../customers/extras/EditChangeNotes";
import MyBCarouselImages from "../assets/subItems/AssetLayouts/MyBCarouselImages";
import EditGeneratePdf from "../customers/extras/EditGeneratePdf";

export default {
    name: 'ChangeRightBox',
    mixins: [AddChangeAssetsModal, TotalsAndCurrencies, ImageGallery],
    components: {
        EditGeneratePdf,
        MyBCarouselImages,
        EditChangeNotes,
        EditChangeScenes,
        EditChangeSetup,
        ChangeActionsList,
        AddChangeAssetsModal,
        MyBCarouselSlide,
        VueEasyLightbox
    },
    props: {
        change_item_info: {},
        unique_id: '',
        used_classes:''
    },
    data(){
        return {
            add_assets_modal_id: 'add_assets',
            add_assets_modal_title: 'Add Assets',
        }
    },
    computed:{
    },
    methods:{
        getChangeStatus(just_classes=false){
            if (just_classes){
                return 'status-btn-' + (this.change_item_info.status === 1 ? 'active' : 'planned') +' ' + (this.change_item_info.status === 1 ? 'established' : 'planned') + '-change-status';
            }
            return (this.change_item_info.status === 1 ? 'Established' : 'Planned');
        },
        onFormSubmit(modal_action, change_item_info, addForm, object_type, entity_type, received_modal_id){
            console.log("modal_action");
            console.log(modal_action);
        },

        performAction(change, action, changeKey){

            if (action === 'delete'){
                if (change.id !== ''){
                    this.deleteItemByID(change.id, change.name, this.object_type, change)
                        .then((response) => {
                            if (response.data.data.status){
                                this.changesData.splice(changeKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }else{
                let change_info_for_modal = Object.assign({}, change);

                switch (action) {
                    case 'change_notes':
                        change_info_for_modal.change_status = change.status;
                        this.$refs['edit-change-notes'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-notes' + this.unique_id);
                        break;
                    case 'change_scenes':
                        change_info_for_modal.part_ids = change.parts_info.map(function(e) { return e.id; });
                        this.$refs['edit-change-scenes'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-scenes' + this.unique_id);
                        break;
                    case 'change_setup':
                        change_info_for_modal.character_id = change.character_id;
                        change_info_for_modal.number = change.name;
                        this.$refs['edit-change-setup'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-setup' + this.unique_id);
                        break;
                    case 'generate_pdf':
                        this.$refs['edit-generate-pdf'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-generate-pdf' + this.unique_id);
                        break;
                }
            }

        },
    },
    created() {
    },

}
</script>

