<template>
    <div class="row justify-content-center">
        <div class="col-12">

            <h3>Details</h3>
            <div class="table-container">
                <div class="table-responsive scrollbar scroll-style">
                    <div id="main_table">
                        <div class="table_heding af-primary">
                            <div class="table_inner_heading d-flex-p">
                                <div class="header_item col-xs-6 col-sm-2 col-lg-1 a_uid"><span>ID</span></div>
                                <div class="header_item col-xs-6 col-sm-2 col-lg-1 a_qty"><span>Qty</span></div>
                                <div class="header_item col-xs-6 col-sm-2 col-lg-2 a_status"><span>Status</span></div>
                                <div class="header_item col-xs-6 col-sm-2 col-lg-2 a_age"><span>Age</span></div>
                                <div class="header_item col-xs-6 col-sm-2 col-lg-2 a_currency"><span>Currency</span>
                                </div>
                                <div class="header_item col-xs-6 col-sm-2 col-lg-2 a_valuation_method"><span>Valuation Method</span>
                                </div>
                                <div class="header_item col-xs-6 col-sm-2 col-lg-2 a_disposal_method"><span>Disposal Method</span>
                                </div>
                            </div>
                        </div>
                        <div class="table_body">
                            <div class="table_inner_body d-flex-p">
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-1 a_uid">
                                    <span>{{ item_info.uid_text }}</span>
                                </div>
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-1 a_qty">
                                    <span>{{ item_info.quantity }}</span>
                                </div>
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-2 a_status">
                                    <span class="status-btn" :class="'status-btn-' + item_info.status">{{ item_info.status | titleize }}</span>
                                </div>
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-2 a_age">
                                    <span>{{ item_info.asset_age }}</span>
                                </div>
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-2 a_currency">
                                    <span>{{ getCurrencyByValue(item_info.used_currency, true) }}</span>
                                </div>
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-2 a_valuation_method">
                                    <span>{{ getValuationMethodText() }}</span>
                                </div>
                                <div class="tbody_item col-xs-6 col-sm-2 col-lg-2 a_disposal_method">
                                    <span>{{ getDisposalMethodText() }}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3>Profit & Loss</h3>
            <div class="list-of-details asset-accounting">
                <div class="header-detail"></div>
                <div class="header-detail accounting-header">Date</div>
                <div class="header-detail accounting-header">Method</div>
                <div class="header-detail accounting-header">User</div>
                <div v-if="isUniqueAsset()" class="header-detail accounting-header">1 Unit</div>
                <div v-else class="header-detail accounting-header"></div>

                <div class="header-detail accounting-header">{{ item_info.quantity + ' Unit' + (isUniqueAsset() ? 's' : '') }}</div>

                <div class="header-detail">Acquisition Cost</div>
                <div class="list-detail">{{item_info.acquired_date | moment('DD/MM/YYYY')}}</div>
                <div class="list-detail">{{ getAcquistionText(getMetaValue('acquisition_type')) }}</div>

                <div class="list-detail">
                    <a v-if="getMetaValue('acquired_by_id') !== '-'"
                       :href="'/' + item_info.current_page_type + '/users/' + getMetaValue('acquired_by_id') + '/view'">{{ getMetaValue('acquired_by') }}</a>
                </div>

                <div v-if="isUniqueAsset()" class="list-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, unit_value)">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{unit_value}}
                </div>
                <div v-else class="list-detail"></div>


                <div class="list-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, total_value)">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{total_value}}
                </div>



                <div class="header-detail">Valuation</div>
                <div class="list-detail">{{ getMetaValue('valuation_date') }}</div>
                <div class="list-detail">{{ getValuationMethodText() }}</div>
                <div class="list-detail">
                    <a v-if="getMetaValue('valuated_by_id') !== '-'"
                       :href="'/' + item_info.current_page_type + '/users/' + getMetaValue('valuated_by_id') + '/view'">{{ getMetaValue('valuated_by') }}</a>
                </div>
                <div v-if="isUniqueAsset()" class="list-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getValuationDisposalValue('valuation_method', item_info.quantity))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getValuationDisposalValue('valuation_method', item_info.quantity).toFixed(2)}}
                </div>
                <div v-else class="list-detail"></div>


                <div class="list-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getValuationDisposalValue('valuation_method', 1))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getValuationDisposalValue('valuation_method', 1).toFixed(2)}}
                </div>


                <div class="header-detail">Disposal</div>
                <div class="list-detail">{{ getMetaValue('disposal_date') }}</div>
                <div class="list-detail">{{ getDisposalMethodText() }}</div>
                <div class="list-detail">
                    <a v-if="getMetaValue('disposed_by_id') !== '-'"
                       :href="'/' + item_info.current_page_type + '/users/' + getMetaValue('disposed_by_id') + '/view'">{{ getMetaValue('disposed_by') }}</a>
                    </div>
                <div v-if="isUniqueAsset()" class="list-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getValuationDisposalValue('disposal_method', item_info.quantity))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getValuationDisposalValue('disposal_method', item_info.quantity).toFixed(2)}}
                </div>
                <div v-else class="list-detail"></div>


                <div class="list-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getValuationDisposalValue('disposal_method', 1))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getValuationDisposalValue('disposal_method', 1).toFixed(2)}}
                </div>


                <div class="header-detail accounting-total">Forecast P&amp;L</div>
                <div class="list-detail"></div>
                <div class="list-detail"></div>
                <div class="list-detail"></div>
                <div v-if="isUniqueAsset()" class="list-detail accounting-total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getForecastActualValue('valuation_method', item_info.quantity))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getForecastActualValue('valuation_method', item_info.quantity).toFixed(2)}}
                </div>
                <div v-else class="list-detail"></div>

                <div class="list-detail accounting-total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getForecastActualValue('valuation_method', 1))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getForecastActualValue('valuation_method', 1).toFixed(2)}}
                </div>




                <div class="header-detail accounting-total">Actual P&amp;L</div>
                <div class="list-detail"></div>
                <div class="list-detail"></div>
                <div class="list-detail"></div>
                <div v-if="isUniqueAsset()" class="list-detail accounting-total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getForecastActualValue('disposal_method', item_info.quantity))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getForecastActualValue('disposal_method', item_info.quantity).toFixed(2)}}
                </div>
                <div v-else class="list-detail"></div>

                <div class="list-detail accounting-total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.used_currency, getForecastActualValue('disposal_method', 1))">
                    {{getCurrencyByValue(item_info.used_currency).icon}}
                    {{getForecastActualValue('disposal_method', 1).toFixed(2)}}
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";

export default {
    name: "AccountingTab",
    mixins: [TotalsAndCurrencies],
    components: {},
    props: ['item_info'],
    data() {
        return {
            acquisition_types:[
                {id: 1, name: 'Purchase'},
                {id: 2, name: 'Rental'},
                {id: 3, name: 'No Cost'},
                {id: 4, name: 'Internal Transfer'}
            ]
        }
    },
    created() {

    },
    computed: {
        unit_value(){
            return this.item_info.value ? this.item_info.value : 0;
        },
        total_value(){
            return this.item_info.total_value ? this.item_info.total_value : 0;
        },
    },
    methods: {
        isUniqueAsset(){
            return this.item_info.quantity !== 1;
        },
        getAcquistionText(acquisition_type){
            if (acquisition_type !== '-'){
                let index = this.acquisition_types.map(function(e) { return e.id; }).indexOf(acquisition_type);
                if (index > -1){
                    return this.acquisition_types[index].name;
                }
            }
            return acquisition_type;
        },
        getMetaValue(key){
            if (this.item_info.hasOwnProperty('asset_meta') && this.item_info.asset_meta.hasOwnProperty(key)){
                return this.item_info.asset_meta[key];
            }
            return '-';
        },
        getForecastActualValue(method_key='valuation_method', forQuantity=1){
            let value_key = this.getMethodValueKey(method_key);

            if ( this.item_info[value_key] !== 0){
                let total_value_for_quantity = this.item_info.total_value / forQuantity;
                if (this.item_info[method_key] === 0 || method_key === 'disposal_method'){
                    return (this.item_info[value_key] / forQuantity - total_value_for_quantity);
                }else if (this.item_info[method_key] === 1){
                    return (((this.item_info[value_key] * this.item_info.total_value / 100) / forQuantity) - total_value_for_quantity);
                }
            }
            return 0;


        },
        getMethodValueKey: function (method_key) {
            return method_key === 'disposal_method' ? 'sale_price' : 'forecast_value';
        },
        getValuationDisposalValue(method_key='valuation_method', forQuantity = 1){
            let value_key = this.getMethodValueKey(method_key);

            if ( this.item_info[value_key] !== 0){
                if (this.item_info[method_key] === 0 || method_key === 'disposal_method'){
                    return this.item_info[value_key] / forQuantity;
                }else if (this.item_info[method_key] === 1){
                    return (this.item_info[value_key] * this.item_info.total_value / 100) / forQuantity;
                }
            }
            return 0;
        },
        getValuationMethodText(){
            if (this.item_info.valuation_method === 0){
                return 'Fixed Price';
            }else if (this.item_info.valuation_method === 1){
                return 'Fixed Percentage';
            }else{
                return '-';
            }
        },
        getDisposalMethodText(){
            return '-';
        },
    }
}
</script>


