<template>
    <b-modal :id="'change-'+item_type+'-modal'"
             @hide="$emit('on-modal-cancel')"
             :title="modal_title"
             title-class="font-18"
             hide-footer>
        <b-form-group :label="list_title | titleize" :label-for="list_title">
            <b-form-select
                :id="list_title"
                :name="list_title"
                v-model="received_selected_item"
                :options="items_list">
            </b-form-select>
        </b-form-group>


        <div v-if="with_caution" class="form-group">
            <b-form-checkbox
                v-if="caution_title !== ''"
                v-model="accepted_caution"
                class="custom-checkbox mb-3"
            >{{ caution_title }}</b-form-checkbox>
            <span v-if="caution_error" class="caution-error text-danger">Please tick the checkbox to continue</span>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click.once="performChange">
                {{ button_title }}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('change-'+item_type+'-modal')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: "MoveAssetsModal",
    props:['item_type', 'list_title', 'options_list', 'selected_item', 'modal_title', 'button_title', 'caution_title'],
    data(){
        return {
            items_list: [],
            received_selected_item: null,
            accepted_caution: false,
            caution_error: false,
            with_caution: false
        }
    },
    created() {
        this.items_list = this.options_list;
        this.received_selected_item = this.selected_item;

        this.with_caution = this.caution_title && this.caution_title !== '';
    },
    methods: {
        setSelectedOption(){
            let item_option_index = this.options_list.map(function(e) { return e.id; }).indexOf(this.current_item.id);
            if (item_option_index > -1){
                let item_option = this.options_list[item_option_index];
                if (!this.received_selected_item && item_option.id === this.current_item.id){
                    let primary_locations = item_option.subfolders.filter(function(e) { return e.is_primary; });
                    let selected_location;
                    if (primary_locations.length !== 0){
                        selected_location = primary_locations[0];
                    }else{
                        selected_location = item_option.subfolders[0];
                    }
                    this.received_selected_item = selected_location.id;
                }
            }

        },
        setGroupedOptions(){
            this.accepted_caution = false;
            this.caution_error = false;

            let list_options = [];
            for (const optionsListKey in this.options_list) {
                let item_option = this.options_list[optionsListKey];
                let option;
                if (item_option.subfolders.length !== 0){
                    let sub_options = item_option.subfolders.map(function(e) { return {value: e.id, text: e.name + (e.is_primary ? ' <= Primary' : '')}; })
                    option = {
                        label: item_option.name,
                        options: sub_options
                    }
                }else{
                    option = {
                        value: item_option.id,
                        text: item_option.name,
                        disabled: true
                    };
                }

                list_options.push(option);
            }

            this.setSelectedOption();
            this.items_list = list_options;
        },
        set_item(item){
            this.received_selected_item = item.address_id ? item.id : null;
            this.current_item = item;
        },
        performChange(){
            this.$emit('on-modal-save', this.item_type, this.current_item, this.received_selected_item, (this.with_caution ? this.accepted_caution : true));
        },
        showCautionError(value){
            this.caution_error = value;
        },
    }

}
</script>


