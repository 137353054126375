export default {
    data(){
        return {
        }
    },
    created: function () {
    },
    methods: {
        getLocationStatus(location_info){
            let status_list = [
                {
                    name: 'Free',
                    class: 'status-background-green',
                },
                {
                    name: 'Occupied',
                    class: 'status-background-orange',
                },
                {
                    name: 'Unavailable',
                    class: 'status-background-grey',
                },
            ];
            if (status_list[location_info.status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[location_info.status].class) + '" style="color: white!important;">' + status_list[location_info.status].name + '</span></div></div>';
            }
            return '';
        },
        setupJQueryMethods(){
            console.log("setupJQueryMethods?");
            let strongThis = this;
            let action_names = [
                'park-location-item',
                'vacate-location-item'
            ];
            for (const actionNamesKey in action_names) {
                let action_name = action_names[actionNamesKey];
                $(document).on('click', '.'+action_name, function(event) {
                    strongThis.getItemAndShowModal(action_name, event);
                });
            }
        },
        getItemAndShowModal(modal_ref, event){
            event.preventDefault();
            let currentTarget = event.currentTarget;
            let item_id = currentTarget.getAttribute('data-location_id');
            if (item_id){
                if (this.sp_locationsData){
                    let index = _.findIndex(this.sp_locationsData, {id: parseInt(item_id)});
                    if (index > -1) {
                        let selected_item = this.sp_locationsData[index];
                        this.showModal(selected_item, modal_ref);
                    }
                }else{
                    this.showModal(this.item_info, modal_ref);
                }
            }
        },
        performAction(sp_location, action, sp_locationKey){
            this.selected_object_id = sp_location.id;
            if (action === ('delete'+this.permission_name)){
                if (sp_location.id !== ''){
                    this.deleteItemByID(sp_location.id, sp_location.name, this.object_type, sp_location)
                        .then((response) => {
                            if (response.data.data.status){
                                this.sp_locationsData.splice(sp_locationKey, 1);
                            }
                        });
                }
            }
        },

        showModal(selected_item, modal_ref, other_info={}){

            if (['make-unavailable', 'make-free'].includes(modal_ref)){
                let nextStatus = modal_ref === 'make-free' ? 0 : 2;
                let item_id = selected_item.id;
                let strongThis = this;
                let other_info = {
                    success_message: 'Edited \'' + selected_item.full_location_name + '\' Successfully'
                };
                this.set_item_property_new('sp_locations', item_id, 'status', nextStatus, other_info, function () {
                    if (strongThis.sp_locationsData){
                        let index = _.findIndex(strongThis.sp_locationsData, {id: parseInt(item_id)});
                        if (index > -1) {
                            strongThis.sp_locationsData[index].status = nextStatus;
                        }
                    }else if (strongThis.item_info){
                        strongThis.item_info.status = nextStatus;
                    }
                });
            } else if (this.$refs[modal_ref]){
                this.selected_object_id = selected_item.id;
                if (['vacate-location-item'].includes(modal_ref)){
                    let location_info = {...selected_item};
                    delete location_info.storage_info;

                    selected_item = selected_item.storage_info;
                    selected_item.location_id = this.selected_object_id;
                    selected_item.location_info = location_info;
                }

                this.$refs[modal_ref].setSelectedItem(selected_item);
                this.$bvModal.show(modal_ref);
            }
        },

    }
}
