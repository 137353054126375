<template>
    <div>
        <add-new-event
            ref="add-new-event"
            item_type='event'
            modal_title="Create New Event"
            @on-new-item-adding="eventsListChanged"
        >
        </add-new-event>

        <div
            v-for="(field, index) in addForm.events_fields_list"
            :key="index"
            class="row"
        >
            <div class="mb-3 col-lg-4">
                <label :for="index">Event</label>

                <multiselect
                    :id="index"
                    v-model="field.event_id"
                    track-by="id"
                    label="name"
                    placeholder="Select Task"
                    class="mySingleSelect"
                    :options="getListOf('events')"
                    @input="update_events_list()"
                    deselect-label=""
                >
                    <span slot="noResult">No data found.</span>
                </multiselect>
            </div>

            <div class="mb-3 col-lg-6">
                <label :for="index">Task</label>
                <multiselect
                    :id="index"
                    v-model="field.tasks"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    placeholder="Select Task"
                    class="mySingleSelect"
                    @input="forceRefreshEventsList"
                    :options="getListOf('tasks')"
                    deselect-label=""
                >
                    <span slot="noResult">No data found.</span>
                </multiselect>
            </div>


            <div class="col-lg-2 align-self-center">
                <div class="d-grid">
                    <input
                        type="button"
                        class="btn btn-danger btn-block"
                        value="Delete"
                        @click="deleteRow(index)"
                    />
                </div>
            </div>

        </div>

        <div class="row mt-3">
            <div class="col">
                <input
                    type="button"
                    class="btn btn-success"
                    value="Add"
                    @click="AddformData"
                />
            </div>
            <div class="col text-right">
                <input
                    type="button"
                    class="btn btn-primary"
                    value="Add New Event"
                    @click="open_create_event_dialog"
                />
            </div>

        </div>

    </div>
</template>
<script>
import multiSelect from '@/mixins/MultiSelect';
import AddNewEvent from "../../customers/extras/AddNewEvent";

export default {
    components: {AddNewEvent},
    name: 'EventFieldsRepeater',
    mixins: [multiSelect],
    props: ['addForm', 'my_lists_values', 'force_refresh'],
    data(){
        return {
            events_list:[],
            tasks_list:[]
        }
    },
    created() {

        this.tasks_list = this.my_lists_values.tasks;
        this.events_list = this.my_lists_values.events;
        let singleItem = {event_id: "", tasks: []};
        if (!this.addForm.hasOwnProperty('events_fields_list')){
            if (this.force_refresh){
                this.$set(this.addForm, 'events_fields_list', [singleItem]);
            }else{
                this.addForm.events_fields_list = [singleItem];
            }

        }else{
            if (this.addForm.events_fields_list.length === 0){
                this.addForm.events_fields_list = [singleItem];
            }
        }
    },
    methods:{
        getListOf(item){
            switch (item){
                case 'tasks':
                    return this.tasks_list;
                case 'events':
                    return this.events_list;
            }
        },
        update_events_list(){
            let required_list = this.my_lists_values.events.map((x) => x);
            for (const eventsFieldsListKey in this.addForm.events_fields_list) {
                let event_field = this.addForm.events_fields_list[eventsFieldsListKey];
                if (event_field.hasOwnProperty('event_id') && event_field.event_id && event_field.event_id.hasOwnProperty('id') && event_field.event_id.id !== ''){
                    let index = _.findIndex(required_list, {id: event_field.event_id.id});
                    if (index > -1){
                        required_list.splice(index, 1);
                    }
                }
            }
            this.events_list = required_list;
        },
        open_create_event_dialog: function () {
            this.$refs['add-new-event'].setSelectedItem(null);
            this.$bvModal.show('create-event');
        },
        forceRefreshEventsList: function () {
            if (this.force_refresh) {
            }
        },
        AddformData() {
            this.update_events_list();

            if (this.events_list.length === 0){
                this.presentConfirmBox('Events list is Empty', this.htmlToVNode('<p>Would you like to create a new <strong>\'Event\'</strong>?</p>'), 'primary', 'md')
                    .then(value => {
                        if (value){
                            this.open_create_event_dialog();
                        }
                    }).catch((error) => {
                    console.log(error);
                });
                return;
            }
            this.addForm.events_fields_list.push({event_id: null, tasks: []});
            this.forceRefreshEventsList();
        },
        deleteRow(index) {
            this.addForm.events_fields_list.splice(index, 1);
            this.update_events_list();
        },
        eventsListChanged(new_item, item_type) {
            if (new_item) {
                let new_option = {id: new_item.id, name: new_item.name, start_date: new_item.start_date, finish_date: new_item.finish_date};
                this.my_lists_values.events.push(new_option);
                this.update_events_list();
            }
        },
    }
}
</script>
