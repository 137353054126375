<template>
    <b-modal :id="received_modal_id"
             size="lg"
             :ref="received_modal_id"
             :title="modal_title + (selected_item ? (' of {'+selected_item.name + '}') : '')"
             title-class="font-18"
             @hide="cancelClicked"
             hide-footer>

        <ChangeCommonFields :add-form="addForm" :required_fields="required_items"/>

        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'edit-ms-asset' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const editMSResource = new Resource('api/assetflow/managed_storages/editMS');
import multiSelect from '@/mixins/MultiSelect';
import AFButton from "@/components/AFButton";
import ChangeCommonFields from "../extras/ChangeCommonFields";

export default {
    mixins: [multiSelect],
    name: "UpdateMSStatus",
    props: ['modal_title', 'received_modal_id'],
    components: {ChangeCommonFields, AFButton},

    computed:{
        // status() {
        //     return this.addForm.status;
        // },
    },
    watch:{
        // status(value) {
        //     if (value && value.id === 0){
        //         this.required_items.asset_status.type = 'single-select';
        //     }else{
        //         this.addForm.asset_status = null;
        //         this.required_items.asset_status.type = 'hidden_text';
        //     }
        // },
    },
    data(){
        return {
            index: null,
            addForm:{
                current_status:{id: null, name: 'No Change'},
                status:{id: null, name: 'No Change'},
                asset_status:null,
            },
            required_items:{
                current_status: {
                    key: 'current_status',
                    label: 'Current Status',
                    type: 'single-select',
                    no_limit: true,
                    options: []
                },
                status: {
                    key: 'status',
                    label: 'New Status',
                    type: 'single-select',
                    no_limit: true,
                    has_html_note: '',
                    options: [
                        {id: 0, name: 'Dispatched'},
                        {id: 1, name: 'Intake In Progress'},
                        {id: 2, name: 'In Storage'}
                    ]
                },
                asset_status: {
                    key: 'asset_status',
                    label: 'Asset Status',
                    type: 'single-select',
                    no_limit: true,
                    options: [
                        {id: 'active', name: 'Active'},
                        {id: 'disposed', name: 'Disposed'},
                    ]
                },
            },
            selected_item: null
        }
    },
    methods:{
        set_index(index){
            this.index = index;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {

            let current_status_id = this.selected_item.ms_status;
            let found_index = this.required_items.status.options.map(function (e) {
                return e.id;
            }).indexOf(current_status_id);
            if (found_index < 0){
                found_index = 0;
            }
            let current_status = this.required_items.status.options[found_index];
            this.required_items.current_status.options = [current_status];
            this.required_items.current_status.is_readonly = true;

            this.addForm.current_status = current_status;
            this.addForm.status = current_status;

            let asset_status = this.selected_item.status;
            let found_as_index = this.required_items.asset_status.options.map(function (e) {
                return e.id;
            }).indexOf(asset_status);
            if (found_as_index < 0){
                found_as_index = 0;
            }
            this.addForm.asset_status = this.required_items.asset_status.options[found_as_index];

            const firstPath = window.location.pathname.split('/')[1];
            let ms_orders_tab_link = '/' + firstPath + '/managed_storages/' + this.selected_item.managed_storage_id + '/view?t=orders';
            this.required_items.status.has_html_note = 'Do not manually change statuses if they impact Move To Storage or Storage Orders.<br> <a href="'+ms_orders_tab_link+'" target="_blank">Check orders prior to updating status</a>';

            // this.addForm.status = null;
            // this.addForm.asset_status = null;

            // this.addForm.status = this.selected_item.ms_status;// single select?
            // this.addForm.asset_status = this.selected_item.status;// single select?
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            if (this.addForm.status){
                let params = {
                    status: this.addForm.status.id,
                    asset_status: this.addForm.asset_status ? this.addForm.asset_status.id : null,
                };

                let managed_storage_id = null;
                if (this.selected_item){
                    params.item_id = this.selected_item.id;

                    managed_storage_id = this.selected_item.managed_storage_id;
                    params.managed_storage_id = managed_storage_id;
                }
                params.action = 'status_update';

                const { data } = await editMSResource.store(params);

                let new_item = data.data.items;
                if (!new_item){
                    this.buttonkey++;
                }
                this.presentToast((new_item ? "Success!" : "Error!"),
                    new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                    (new_item ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
                this.isAddingItem = false;
                this.resetSelectedItem();
                this.$emit('on-new-item-adding', this.received_modal_id, new_item);
            }

        }
    }
}
</script>


