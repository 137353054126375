<template>
    <div>
        <add-new-modal
            ref="add-address-modal"
            item_type='address'
            modal_title="Create New Address"
            @on-new-item-adding="addressLocationChange"
        ></add-new-modal>

        <div v-if="address" class="products">
            <div class="product-item">
                <div class="product-block">
                    <!-- Address Map Start-->
                    <div class="address-map-block">
                        <gmap-map
                            v-if="address && checkCoordinates(address)"
                            :center="getPosition(address)"
                            :zoom="9"
                            class="address-map"
                            :options="mapOptions"
                            @click="onMapClick">
                            <gmap-marker :position="getPosition(address)"></gmap-marker>
                        </gmap-map>
                        <div v-else class="placeholder-ai text-af-accent">
                            <i class="bx bx-map"></i>
                        </div>
                    </div>
                    <!-- Address Map End-->

                    <!-- Product Details Start-->
                    <div class="product-details-left">
                        <h3 class="product-title">
                            <a :href="'/' + address.current_page_type + '/assets/' + address.id + '/view'">{{
                                    address.name | titleize
                                }}</a>
                        </h3>
                        <div class="product-status grid-show">
                            <div class="address-locations-count">
                                {{ address.locations.length }}
                            </div>
                            <div class="quantity">
                                {{ address.assets_sum_quantity }}
                            </div>
                            <div class="address-total-value">
                                {{ address.assets_sum_total_value }}
                            </div>
                        </div>
                        <div class="product-series list-show">
                            <p>{{ address.parent_product_name }}</p>
                            <p>{{ address.parent_category_name }}</p>
                        </div>
                        <div class="product-description list-show">
                            <p>{{ address.description }}</p>
                        </div>
                        <div class="product-usage">
                            <div class="usage-inner-wrap">
                                <div>City<b>{{ address.city | titleize }}</b></div>
                                <div>State<b>{{ address.state | titleize }}</b></div>
                                <div>Country<b>{{ address.country | titleize }}</b></div>
                            </div>
                        </div>
                        <div class="row statuses-row">
                            <div class="address-types af-accent">
                                <span :class="'address-type-' + address.address_type.class">
                                    {{ address.address_type.value | titleize }}
                                </span>
                            </div>

                            <div v-if="address.is_default" class="af-accent default-address">
                                <span>Default</span>
                            </div>

                            <div :class="'address-status ' + (checkAddressStatus(address) ? 'active' : 'inactive')">
                                <span>{{ checkAddressStatus(address) ? 'Active' : "Inactive" }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="product-details-right list-show">
                        <div class="life-cycle-wrapper">
                            <h4 class="d-flex align-items-center">Locations<a
                                class="ml-auto"></a></h4>
                            <ul>
                                <li v-for="location in address.locations">
                                    {{ location.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="tag-wrap list-show">
                            <ul>
                                <li v-for="status in statusesList"
                                    :class="'status-btn status-btn-'+status+' mr-1 mt-1'">
                                    <a href="">{{ status | titleize }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Product Details End-->



                    <a :href="'/' + address.current_page_type + '/assets?filter=address&id=' + address.id">
                        <div v-b-tooltip.hover title="Assets List" class="view-asset-details af-accent address-assets-list"></div>
                    </a>

                </div>
            </div>
        </div>

        <div v-if="address.description" class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h5>Address Description</h5>
                    <p>{{address.description}}</p>
                </div>
            </div>
            <!-- end card -->
        </div>

    </div>
</template>

<script>

import AddressActions from "@/mixins/addressActions.mixin";
import UserMeta from "@/mixins/usermeta.mixin";
import AddNewModal from "../../../customers/extras/AddNewModal";

export default {
    name: "AddressDetails",
    components: {AddNewModal},
    mixins: [UserMeta, AddressActions],
    props: {
        item_info: Object,
        address: Object
    },
    data() {
        return {
        };
    },
    created() {
        this.setupAddressFieldsWithLoqate();
    },
    methods: {
        addressLocationChange(){
            location.reload();
        },
        getKeyName(value){
            if (value.indexOf('modified') !== -1){
                value = value.replace('modified_', '');
            }
            return value;
        },
        getValueFor(value){
            switch (value) {
                case 'colours':
                case 'tag_ids':
                case 'composition':
                    return this.address[value] ? this.address[value].join(', ') : this.address[value];
                default:
                    return this.address[value] != null && this.address[value] !== '' ? this.address[value] : '-';
            }
        },
        performAction(item, action_name, event) {
            switch (action_name) {
                case 'deleteAddress':
                    this.deleteItem(item.id, item.name, 'addresses', event);
                    break;
                case 'editAddress':

                    this.$refs['add-address-modal'].setSelectedItem(null);
                    this.$refs['add-address-modal'].setSelectedItem(item);
                    this.setupAddressLookup();
                    this.$bvModal.show('create-address');
                    break;
                case 'changeFolder':
                    console.log("should changeFolder?");
                    break;
            }
        },
        deleteItem(item_id, item_name, item_type, event) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    window.location = encodeURI("/" + this.address.current_page_type + "/addresses");
                });
        },
    }
}
</script>


