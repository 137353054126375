import {getSpStorageInfoBlock} from "./getSpStorageInfoBlock";
import {getLocationDetails} from "./SpLocationSharedFunctions";
import {getWarehouseSpaceInfoBlock} from "./getWarehouseSpaceInfoBlock";

export const SPStorageShortKeysList = {
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 storage-name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return getSpStorageInfoBlock(item, false);
        }
    },

    initials: {
        key: 'initials',
        label: "",
        class: "col-lg-1 storage-war_initials",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return getWarehouseSpaceInfoBlock(item, 'current_');
        }
    },
    usage: {
        key: 'usage',
        label: "Usage",
        class: "col-lg-1 storage-usage",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let html = '';
            let storage_utilisation = item.storage_utilisation;

            let progress_content = '<div class="progress progress-lg af-accent"><div class="progress-bar af-primary" role="progressbar" aria-label="Usage" style="width: '+storage_utilisation+'%" aria-valuenow="'+storage_utilisation+'" aria-valuemin="0" aria-valuemax="100"></div></div>';
            html += '<div class="storage-usage-info">' +
                    '<span class="object-title-list">'+(storage_utilisation === 100 ? 'Full' : (storage_utilisation === 0 ? 'Empty' : (storage_utilisation+'%') ))+'</span>' +
                    '<span class="object-title-detail"><a href="#" class="inline-action change-sp_storages-utilisation" data-space_id="' + item.id + '">Change</a></span>' +
                '</div>';
            html += progress_content;
            return html;
        }
    },
    items: {
        key: 'items',
        label: "Items",
        class: "col-lg-1 war_items",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let assets_count = 0;
            if (item.hasOwnProperty('assets_count') && item.assets_count){
                assets_count = item.assets_count;
            }

            return '<p class="object-title-list" style="text-align: center;">'+assets_count+'</p><p class="object-title-detail" style="text-align: center;">Assets</p>';
        }
    },
    location: {
        key: 'location',
        label: "Location",
        class: "col-lg-3 war_location",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let space_assignment_data = 'data-space_id="' + item.id + '"';
            let parked_at = '<a href="#" class="inline-action assign-sp_storages-location" ' + space_assignment_data + '>Park Now</a>';

            switch (item.location_status) {
                case 0:
                    return '<p class="object-title-detail"><span class="item-header homeless-location"><i class="fa fa-circle status-red"></i> Homeless</span></p><p class="object-title-detail">' + parked_at + '</p>';
                case 1:
                    if (item.hasOwnProperty('location_info') && item.location_info){
                        let location_info = item.location_info;

                        let html = '';
                        if (location_info.hasOwnProperty('space_assigned_at') && location_info.space_assigned_at){
                            let parked_at_date = Vue.options.filters.moment(location_info.space_assigned_at, 'DD/MM/YYYY');
                            parked_at = '<i class="fa fa-circle status-orange"></i> Parked on ' + parked_at_date + ' <a class="inline-action remove-sp_storages-location" href="#" ' + space_assignment_data +' >remove</a><a class="inline-action assign-sp_storages-location" href="#" ' + space_assignment_data +' >relocate</a>';
                        }

                        let other_info = {};
                        other_info.warehouse_info = item.current_warehouse_info;
                        other_info.space_info = item.current_space_info;

                        html += getLocationDetails(location_info, true, true, false, other_info);
                        html += '<p class="object-title-detail">' + parked_at+ '</p>';
                        return html;
                    }
                    return '';
                case 2:
                    return '<p class="object-title-detail"><i class="fa fa-circle status-grey"></i>Archived</p>';
            }

        }
    },
};
