<template>
    <!-- Page-Content start-->
    <div id="page-content-wrapper">

        <generic-tasks-form
            ref="generic-tasks-form"
            modal_id="icons-action-tasks"
            sub_modal_id="actions"
            :custom_keys_only="true"
            modal_title="Complete task"
            :get-class-for="getClassFor"
            @on-completing-task="itemTaskCompleted"
            @on-modal-cancel="selected_asset_id = null"
        />

        <add-asset-to-order
            ref="add-asset-to-order"
            received_modal_id="add-asset-to-order"
            :item_type="item_type"
            object_type="asset"
            @on-modal-save="refreshPage('add-asset-to-order')"
        />

        <ChangeAssetGroup
            :ref="change_group_id"
            :received_modal_id="change_group_id"
            :item_type="item_type"
        />



        <AcquisitionAssetModal
            :ref="add_new_asset_id"
            item_type="asset"
            modal_title="Add New Asset"
            :should_add="true"
            @on-new-item-adding="appendAsset"
        />



        <UpdateCostsModal
            :ref="update_asset_costs_id"
            :modal_id="update_asset_costs_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />


        <UpdateDetailsModal
            :ref="update_asset_details_id"
            :modal_id="update_asset_details_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />

        <UpdateDepartmentModal
            :ref="update_asset_department_id"
            :modal_id="update_asset_department_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />

        <UpdateSupplierModal
            :ref="update_asset_supplier_id"
            :modal_id="update_asset_supplier_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />

        <UpdateImagesModal
            :ref="update_asset_images_id"
            :modal_id="update_asset_images_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />
        <AddMSFile
            :ref="add_ms_files"
            :received_modal_id="add_ms_files"
            modal_title="Add Files"
            @on-new-item-adding="refreshAsset"
            @on-modal-cancel="selected_asset_id = null"
         />
        <AddMSLink
            :ref="add_ms_link"
            :received_modal_id="add_ms_link"
            modal_title="Add URL"
            @on-new-item-adding="refreshAsset"
            @on-modal-cancel="selected_asset_id = null"
        />

        <ReturnAssetModal
            :ref="return_asset_id"
            :modal_id="return_asset_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />

        <SellAssetModal
            :ref="sell_asset_id"
            :modal_id="sell_asset_id"
            :received_item_info="null"
            :is_list="true"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-cost-updates="refreshAsset" />


        <QrCodeModal
            :ref="qr_modal_id"
            :modal_id="qr_modal_id"
            :current_page_type="item_type"
            :object_name="object_type"
            @on-modal-cancel="selected_asset_id = null"
        />

        <RegisterDispositionModal
            :ref="register_disposition_modal_id"
            :received_modal_id="register_disposition_modal_id"
            :required_fields="required_fields"
            :item_type="item_type"
            @on-modal-cancel="selected_asset_id = null"
            @on-registering-disposition="refreshAsset"
            :shall_add="true"
        />



        <div class="page-content-inner">
            <div>
                <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
            </div>

            <div class="product-page-header">
                <slot name="customer-header-content"></slot>
                <div class="filter-searchbar">
                    <search-box :ref="'search-box'" @on-value-changed="searchFor" placeholder_text="Search Assets.."></search-box>
                    <b-dropdown v-if="hasPermission('add_assets') || hasPermission('add_folders')" dropleft
                                class="add-filter" variant="danger">
                        <template slot="button-content">
                            <span class="add-filter-button"></span>
                        </template>
                        <b-dropdown-item v-if="hasPermission('add_assets')" :href="'/' + item_type + '/assets/new_asset'">
                            Add Asset
                        </b-dropdown-item>
                        <b-dropdown-item v-if="hasPermission('add_assets')" :href="'/' + item_type + '/assets/bulk_photo_upload'">
                            Bulk Photo Upload
                        </b-dropdown-item>
                        <b-dropdown-item v-if="hasPermission('add_assets') && hasPermission('add_acquisitions')" :href="'/' + item_type + '/acquisitions/new_acquisition'">
                            Add Receipt
                        </b-dropdown-item>

                        <b-dropdown-item v-if="hasPermission('add_file_imports') && hasPermission('add_assets')" :href="'/' + (item_type === 'sp' ? 'sp' : 'customer') + '/files/file-import-export?object_type=asset'">
                            Import Assets
                        </b-dropdown-item>

                        <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="performAction(null, 'exportAssets')">
                            Export Assets
                        </b-dropdown-item>

                        <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="performAction(null, 'exportAssetsCSV')">
                            Export Assets (CSV)
                        </b-dropdown-item>
                        <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="performAction(null, 'exportAssetsXLSX')">
                            Export Assets (XLSX)
                        </b-dropdown-item>
                        <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="performAction(null, 'exportAssetsXLSXWithImages')">
                            Export Assets with Images (XLSX)
                        </b-dropdown-item>

                        <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="performAction(null, 'exportAssetImages')">
                            Export Asset Images
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <div v-if="selected_item && groupBy === 1" class="title-description">
                    <p>{{ selected_item.description }}</p>
                </div>
                <div class="page-value-info"></div>
            </div>

            <slot name="current-page-filters" v-bind:feature_views="feature_views"></slot>


            <div class="tab_menu_item">
                <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                    <li v-for="tab in assetsListTabs" class="nav-item">
                        <a class="nav-link"
                           @click="tabClicked(tab.id)"
                           :class="isActiveTab(tab.id)"
                           data-toggle="tab" :href="'#'+tab.id" role="tab">
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">
                                {{ tab.name | titleize}}
                            </span>
                        </a>
                    </li>

                </ul>
            </div>

            <div class="tab-content tab_content_design">
                <div class="sidebad-toggle af-accent"></div>
                <div class="tab-pane" id="assets_dashboard" role="tabpanel" :class="isActiveTab('assets_dashboard')">
                    <AssetsDashboardTab :dashboard_info="dashboard_info"/>
                </div>

                <div class="tab-pane" id="assets_list" role="tabpanel" :class="isActiveTab('assets_list')">
                    <div v-if="items_list" class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && items_list.length !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">
                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{ perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '') }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    View: <b>{{ feature_views.selected_view.name }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in feature_views.viewOptions"
                                    :key="option.id"
                                    @click="saveView(option, $event)">
                                    {{ option.name }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                        <div class="grid-list-button d-flex align-items-center">
                            <div
                                v-for="(layout, key) in supportedLayouts"
                                :key="key"
                                @click="changeLayout(layout, $event)"
                                :id="'grid-list-'+layout.class"
                                :class="layout.class + (layout.isActive ? ' active' : '')"></div>
                        </div>
                    </div>
                    <div class="popup-gallery">
                        <vue-easy-lightbox
                            :visible="visibleCaption"
                            :imgs="captionimgs"
                            :index="index"
                            @hide="handleCaptionHide">
                        </vue-easy-lightbox>
                    </div>

                    <LotsOfItemsAlert :perpage="perpage"/>


                    <div class="product-filter-option-wrapper clearfix d-flex" v-if="!(is_managed_storage_module) && feature_views.selected_view.id === 2">
                        <div role="alert" class="customer-alert alert alert-info" style="margin-bottom: 0px;width: 100%;margin-top: 8px;">
                            <span class="bx bx-cuboid"></span>
                            <a href="/customer/orders" class="text-dark">Only Assets currently in <strong>'Managed Storage'</strong> are being shown</a>
                        </div>
                    </div>
                    <!-- Product Section Start-->
                    <div v-if="items_list && selected_layout" class="products">

                        <div class="col-lg-12">
                            <BulkActionsBar
                                :is_actions_bar_opened="is_actions_bar_opened"
                                :is_selecting_items="is_selecting_items"
                                :shouldToggleSelecting="shouldToggleSelecting"
                                :selected_objects="selected_objects"
                                :continueClicked="continueClicked"
                                :performSelectionAction="performSelectionAction"
                                :should_hide_unselected="should_hide_unselected"
                                :enableBulkActions="enableBulkActions"
                                :all_items_count="total"
                                :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                            />
                        </div>

                        <div class="cm_list_view_wrapper" v-if="[0,1,2].includes(selected_layout.value)">
                            <div class="cm_grid_view" :class="selected_layout.value === 1 ? 'cm_small_grid_view' : ''" v-if="[0,1].includes(selected_layout.value)">
                                <div class="cm_grid_row row">
                                    <AssetGridView
                                        v-if="selected_layout.value === 0"
                                        v-for="(asset, assetKey) in sortedItemsList"
                                        :key="assetKey"
                                        :item_type="item_type"
                                        :my_asset="asset"
                                        :selected_asset_id="selected_asset_id"
                                        :asset-key="assetKey"
                                        :selected_view="feature_views.selected_view"
                                        :show-captiom-img="showCaptiomImg"
                                        :perform-action="performAction"
                                    />
                                    <AssetSmallerGridView
                                        v-if="selected_layout.value === 1"
                                        v-for="(asset, assetKey) in sortedItemsList"
                                        :key="assetKey"
                                        :item_type="item_type"
                                        :my_asset="asset"
                                        :selected_asset_id="selected_asset_id"
                                        :asset-key="assetKey"
                                        :selected_view="feature_views.selected_view"
                                        :show-captiom-img="showCaptiomImg"
                                        :perform-action="performAction"
                                    />
                                </div>
                            </div>

                            <AssetListViewNew v-else-if="selected_layout.value === 2"
                                v-for="(asset, assetKey) in sortedItemsList"
                                :key="assetKey"
                                :item_type="item_type"
                                :my_asset="asset"
                                :selected_asset_id="selected_asset_id"
                                :asset-key="assetKey"
                                :selected_view="feature_views.selected_view"
                                :show-captiom-img="showCaptiomImg"
                                :all_tags_list="all_tags_list"
                                :acquisition_methods="acquisition_methods"
                                :my-add-tag="myAddTag"
                                :my-remove-tag="myRemoveTag"
                                :perform-action="performAction"
                                :isLoadingAssetDetails="isLoadingAssetDetails"
                                :set_item_value="set_item_value"
                                :getPostTextOf="getPostTextOf"
                                :getClassFor="getClassFor"

                                :is_actions_bar_opened="is_actions_bar_opened"
                                :is_selecting_items="is_selecting_items"
                                :selected_ids="selected_ids"
                                :addToSelectedList="addToSelectedList"
                                :should_hide_unselected="should_hide_unselected"
                                :preform-bulk-action="preformBulkAction"
                                :bulk_actions_customer_id="bulk_actions_customer_id"

                                :labelAddForm="labelAddForm"
                                :changedFormValue="changedFormValue"
                                :getBulkUpdateInfo="getBulkUpdateInfo"
                                :file_short_keys_list="file_short_keys_list"
                            />
                        </div>

                        <po-lines v-else-if="selected_layout.value === 3"
                                  :items="sortedItemsList"
                                  :current_page_type="item_type"
                                  :object_type="object_type"
                                  :line_type="object_type"
                                  :get-poline-classes-dict="getPoLineClassFor"
                                  :keys_list="asset_list_key_list">
                            <template v-slot:asset_list_actions="{ item, index }">
                                <div v-if="has_actions_permissions">
                                    <asset-actions-dropdown
                                        :item="item"
                                        :item_type="item_type"
                                        :perform-action="performAction"/>
                                </div>
                            </template>
                        </po-lines>

                        <div v-else ref="assetRow" class="product-row grid">
                            <div v-for="(product, assetKey) in sortedItemsList" class="product-item">
                                <div class="product-block" :class="{'outofstock': product.quantity === 0}">
                                    <!-- Product image Start-->
                                    <div class="product-image">

                                        <b-carousel v-if="product.asset_images && product.asset_images.length > 0"
                                                    class="product-image-slider"
                                                    style="text-shadow: 0px 0px 2px #000"
                                                    :interval="0"
                                                    controls
                                        >
                                            <a v-for="(src, index) in product.asset_images"
                                               :key="index"
                                               @click="() => showCaptiomImg(index, product.asset_images)">
                                                <my-b-carousel-slide :image_index="index" :images_count="product.asset_images.length" :src="src"/>
                                            </a>
                                        </b-carousel>
                                        <a v-else>
                                            <div class="placeholder-ai text-af-accent">
                                                <i class="bx bx-cube"></i>
                                            </div>
                                        </a>

                                        <div class="outofstock-wrap"><a class="status-btn red"> Out of Stock</a></div>
                                    </div>
                                    <!-- Product image End-->


                                    <!-- Product Details Start-->
                                    <div class="product-details-left">
                                        <div
                                            v-if="item_type !== 'customer' && product.hasOwnProperty('customer_name')"
                                            class="product-customer-name">
                                            <a
                                                :style="product.hasOwnProperty('customer_accent_colour') ? 'color: ' + product.customer_accent_colour + ' !important;' : ''"
                                                :href="'./customers/'+product.customer_id+'/view'">{{ product.customer_name }}</a>
                                        </div>
                                        <a class="view-details-1" :href="'/' + item_type + '/assets/' + product.id + '/view'">
                                            <div class="view-asset-details af-accent"></div>
                                        </a>
                                        <h3 class="product-title text-truncate">
                                            <a v-b-tooltip :title="'UID: ' + product.uid" :href="'/' + item_type + '/assets/' + product.id + '/view'">{{
                                                    product.name | titleize
                                                }}</a>
                                        </h3>
                                        <div class="product-status grid-show">
                                            <a :class="'status-btn status-btn-' + product.status" v-html="getAssetStatusFrom(product)"></a>
                                            <div class="quantity" data-toggle="tooltip" data-placement="top" title="QTY">
                                                {{ product.quantity }}
                                            </div>
                                        </div>
                                        <div class="product-series list-show">
                                            <p>{{ product.parent_product_name }}</p>
                                            <p>{{ product.parent_category_name }}</p>
                                        </div>
                                        <div class="product-description list-show">
                                            <p>{{ product.description }}</p>
                                        </div>
                                        <div v-if="is_tv_film_asset" class="product-usage">
                                            <div class="usage-inner-wrap">
                                                <div>Usage<b>{{ product.asset_usage === 2 ? '?' : (product.asset_usage === 1 ? 'On Camera' : 'Off Camera') }}</b></div>
                                                <div>Hero<b>{{ product.is_hero === 2 ? '?' : (product.is_hero === 1 ? 'Yes' : 'No') }}</b></div>
                                                <div>Asset<b>{{ product.is_asset === 2 ? '?' : (product.is_asset === 1 ? 'Yes' : 'No') }}</b></div>
                                            </div>
                                        </div>
                                        <div class="tag-wrap list-show">
                                            <ul v-if="product.tags_info">
                                                <li v-for="tag in product.tags_info">
                                                    <a :href="'/' + item_type + '/assets?queue=tag&id=' + tag.id + ''">{{ tag.name }}</a>
                                                </li>
                                            </ul>
                                            <tags-manager
                                                ref="tags-manager1"
                                                :product="product"
                                                :all_tags_list="all_tags_list"
                                                :asset_key="assetKey"
                                                @on-add-tag="myAddTag"
                                                @on-remove-tag="myRemoveTag"
                                            ></tags-manager>

                                        </div>
                                    </div>
                                    <div class="product-details-right list-show">
                                        <div class="product-status list-show">
                                            <a :class="'status-btn status-btn-' + product.status" v-html="getAssetStatusFrom(product)"></a>
                                            <div class="quantity" data-toggle="tooltip" data-placement="top" title="QTY">
                                                {{ product.quantity }}
                                            </div>
                                        </div>
                                        <div class="product-loaction">
                                            <div class="usage-inner-wrap">
                                                <div>Location<b>{{
                                                        (product.address_location_name ? product.address_location_name : '-') | titleize
                                                    }}</b></div>
                                                <div v-if="product.hasOwnProperty('contact_name')">Assigned<b>
                                                    <a :href="'/' + item_type + '/users/'+product.contact_id + '/view'" class="text-dark">
                                                        {{ product.contact_name | titleize }}
                                                    </a>
                                                </b></div>

                                                <div v-if="product.hasOwnProperty('user_name')">Created By<b>
                                                    <a :href="'/' + item_type + '/users/'+product.user_id + '/view'" class="text-dark">
                                                        {{ product.user_name | titleize }}
                                                    </a>
                                                </b></div>

                                                <div>Age<b>{{
                                                        (product.asset_age ? product.asset_age : '-') | titleize
                                                    }}</b></div>

                                                <div>Ref No<b>{{
                                                        (product.internal_reference ? product.internal_reference : '-')
                                                    }}</b></div>
                                                <div class="asset_unit_cost">Unit Cost
                                                    <b v-b-tooltip:hover.bottom :title="toUserCurrencyValue(product.used_currency, (product.value ? product.value : 0))">
                                                        {{getCurrencyByValue(product.used_currency).icon}}
                                                        {{product.value ? product.value.toFixed(2) : '-'}}
                                                    </b>
                                                </div>
                                                <div class="asset_total_cost">Total Cost
                                                    <b v-b-tooltip:hover.bottom :title="toUserCurrencyValue(product.used_currency, (product.total_value ? product.total_value : 0))">
                                                        {{getCurrencyByValue(product.used_currency).icon}}
                                                        {{product.total_value ? product.total_value.toFixed(2) : '-'}}
                                                    </b>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="life-cycle-wrapper">
                                            <h4 class="d-flex align-items-center">Timeline<a class="ml-auto"></a></h4>

                                            <div class="richard-text_block assets-list">
                                                <div v-for="item_post in product.asset_posts" class="personal_details">

                                                    <div class="media media_b_a">
                                                        <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                                            <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                                        </div>

                                                        <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                                        <div class="media-body personal_media-body">
                                                            <h6 class="personal_details_title">
                                                                {{ item_post.user.name }} <span>{{ getItemPostTitle(product, item_post) }}</span>
                                                            </h6>
                                                            <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="tag-wrap list-show">
                                            <ul v-if="product.tags_info">
                                                <li v-for="tag in product.tags_info">
                                                    <a :href="'/' + item_type + '/assets?queue=tag&id=' + tag.id + ''">{{ tag.name }}</a>
                                                </li>
                                            </ul>
                                            <b-overlay :show="isAddingTag" opacity="0.4" />
                                            <tags-manager
                                                ref="tags-manager2"
                                                :product="product"
                                                :all_tags_list="all_tags_list"
                                                :asset_key="assetKey"
                                                @on-add-tag="myAddTag"
                                                @on-save-tags="saveTags"
                                                @on-remove-tag="myRemoveTag"
                                            ></tags-manager>

                                        </div>
                                    </div>
                                    <!-- Product Details End-->


                                    <div v-if="has_actions_permissions" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                                        <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal"></i>
                                        </button>
                                        <AssetsActionsList :item="product" :item_type="item_type" :perform-action="performAction"/>
                                    </div>

                                    <a class="view-details-2" :href="'/' + item_type + '/assets/' + product.id + '/view'">
                                        <div class="view-asset-details af-accent"></div>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="groupBy === 8 && [-99, '1', 0].includes(selected_item.id) && !selected_item.hasOwnProperty('type')" class="products text-center">
                        <h4>Please Select Industry\Sector...</h4>
                    </div>
                    <div v-else-if="isLoadingAssets" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Assets...</h4>
                    </div>
                    <div v-else-if="(!items_list || items_list.length === 0 || sortedItemsList.length === 0)" class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Assets found here at the moment.</h4>
                    </div>
                    <!-- Product Section End-->
                    <div class="s">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta ? request_meta.total : 0"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                            @change="perpage.currentPage = $event; $emit('on-pagination-changed', selected_item)"
                        ></b-pagination>
                    </div>
                </div>

                <div class="tab-pane" id="new_tab" role="tabpanel" :class="isActiveTab('new_tab')">
                    <AddAssetMenuContent :item_type="item_type" class="assets-menu add-asset-menu-content" menu_title="Add New"/>
                </div>



                <div class="tab-pane" id="export_item" role="tabpanel" :class="isActiveTab('export_item')">
                    <div class="row">
                        <div class="col-12">
                            <BulkActionsBlock
                                ref="bulk-actions-block"
                                :required_actions="export_required_actions"
                                :category_id="6"
                                :object_type="object_type"
                                :is_selecting_items="is_selecting_items"
                                :selected_objects="selected_objects"
                                :add-to-selected-list="addToSelectedList"
                                :perform-bulk-action="performBulkAction"
                                :item_type="item_type"
                                :is-selected="isSelected"
                                :toggle-accordion="toggleAccordion"
                                :selected_items_mode="selected_items_mode"
                                :setSelectedItemsMode="setSelectedItemsMode"
                                :startSelectingItems="startSelectingItems"
                                :setBulkActionOptions="setBulkActionOptions"
                                :selected_customer="current_customer"
                                :all_items_count="total"
                                :bulk_actions_customer_id="bulk_actions_customer_id"
                                :listLoading="listLoading"
                                :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                            />
                        </div>
                    </div>
                </div>

                <div v-if="can_bulk_update" class="tab-pane" id="list_bulk_actions" role="tabpanel" :class="isActiveTab('list_bulk_actions')">
                    <div class="row">
                        <div class="col-12">
                            <BulkActionsBlock
                                ref="bulk-actions-block"
                                :required_actions="required_actions"
                                :object_type="object_type"
                                :is_selecting_items="is_selecting_items"
                                :selected_objects="selected_objects"
                                :add-to-selected-list="addToSelectedList"
                                :perform-bulk-action="performBulkAction"
                                :item_type="item_type"
                                :is-selected="isSelected"
                                :toggle-accordion="toggleAccordion"
                                :selected_items_mode="selected_items_mode"
                                :setSelectedItemsMode="setSelectedItemsMode"
                                :startSelectingItems="startSelectingItems"
                                :setBulkActionOptions="setBulkActionOptions"
                                :selected_customer="current_customer"
                                :all_items_count="total"
                                :bulk_actions_customer_id="bulk_actions_customer_id"
                                :listLoading="listLoading"
                                :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                            />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- Page-Content end-->
</template>

<script>
import SearchBox from "../../../../components/search-box";
import VueEasyLightbox from 'vue-easy-lightbox';
import UserMeta from "@/mixins/usermeta.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import AssetActions from "@/mixins/assetActions.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {SortOptions} from "@/views/assetflow/form_data/SortOptions";
import {ViewOptions} from "@/views/assetflow/form_data/ViewOptions";
import TagsManager from "./TagsManager";

import Resource from '@/api/resource';
import PoLines from "../../items/widgets/detailsViews/PoLines";
import AssetActionsDropdown from "./AssetActionsDropdown";
import AssetsActionsList from "./AssetsActionsList";
import MyBCarouselSlide from "../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import AssetListViewNew from "./AssetLayouts/AssetListViewNew";
import AssetGridView from "./AssetLayouts/AssetGridView";
import AssetSmallerGridView from "./AssetLayouts/AssetSmallerGridView";
import AssetTasks from "@/mixins/AssetTasks.mixin";
import GenericTasksForm from "../../items/widgets/detailsViews/AssetTabs/GenericTasksForm";
import LotsOfItemsAlert from "./LotsOfItemsAlert";
import {AcquisitionMethodsList} from "@/views/assetflow/form_data/AcquisitionMethodsList";
import UpdateCostsModal from "../assetForm/UpdateCostsModal";
import UpdateDetailsModal from "../assetForm/UpdateDetailsModal";
import UpdateImagesModal from "../assetForm/UpdateImagesModal";
import AcquisitionAssetModal
    from "../../customers/acquisitions/CostumeAcquisitionForm/AcquisitionModals/AcquisitionAssetModal";
import ReturnAssetModal from "../assetForm/ReturnAssetModal";
import SellAssetModal from "../assetForm/SellAssetModal";
import AddAssetMenuContent from "../../AssetMenu/AddAssetMenuContent";
import AssetsExportTab from "./AssetsExportTab";
import BulkUpdatesTab from "./BulkUpdatesTab";
import AssetsDashboardTab from "./AssetsDashboardTab";
import AddAssetToOrder from "../../customers/extras/AddAssetToOrder";
import AddMSFile from "../../items/ordersContent/AddMSFile";
import AddMSLink from "../../items/ordersContent/AddMSLink";
import RegisterDispositionModal from "../assetForm/RegisterDispositionModal";
import UpdateDepartmentModal from "../assetForm/UpdateDepartmentModal";
import UpdateSupplierModal from "../assetForm/UpdateSupplierModal";

const tagsResource = new Resource('api/assetflow/tags');


import SharedBulkUpdate from "@/mixins/SharedBulkUpdate.mixin";
import SharedBulkUpdateFetchLists from "@/mixins/SharedBulkUpdateFetchLists.mixin";
import BulkActionsBlock from "../../customers/managed_storages/BulkActionsBlock";
import BulkActionsBar from "../../customers/managed_storages/BulkActionsBar";
import ChangeAssetGroup from "../../items/widgets/detailsViews/AssetTabs/ChangeAssetGroup";

import {FileShortKeysList} from "@/views/assetflow/form_data/FileShortKeysList";
import QrCodeModal from "../../items/QrCodeModal";

export default {
    name: "PageContent",
    mixins: [AssetTasks, AssetTabsShared, UserMeta, AssetActions, TotalsAndCurrencies, ImageGallery, TimelineCommons, SharedBulkUpdate, SharedBulkUpdateFetchLists],
    components: {
        ChangeAssetGroup,
        BulkActionsBar,
        BulkActionsBlock,
        UpdateSupplierModal,
        UpdateDepartmentModal,
        RegisterDispositionModal,
        AddMSLink,
        AddMSFile,
        AddAssetToOrder,
        AssetsDashboardTab,
        BulkUpdatesTab,
        AssetsExportTab,
        AddAssetMenuContent,
        SellAssetModal,
        ReturnAssetModal,
        AcquisitionAssetModal,
        UpdateImagesModal,
        UpdateDetailsModal,
        UpdateCostsModal,
        LotsOfItemsAlert,
        GenericTasksForm,
        AssetSmallerGridView,
        AssetGridView,
        AssetListViewNew,
        MyBCarouselSlide,
        AssetsActionsList,
        AssetActionsDropdown,
        PoLines,
        TagsManager,
        SearchBox,
        VueEasyLightbox,
        QrCodeModal
    },
    props: ['items_list', 'item_type', 'groupBy', 'selected_item', 'request_meta', 'is_group', 'totals_info', 'total', 'listLoading', 'myHandleQueryItems'],
    data() {
        return {
            file_short_keys_list: FileShortKeysList,
            labelAddForm: {
                type: 'pdf',
                with_entity_group_logo:0,
                with_entity_logo:0,
                with_department:0,
                with_wrap_box:0,
                with_date:0,
                first_image:0,
                all_images:0,
                asset_identification:1,
                with_usage:0,
                asset_details:0,
                with_tags:0,
                where_to:1,
                send_email:1,
            },
            object_type: 'assets',
            required_actions: [],
            export_required_actions: [],
            required_fields: {
                contact_id: {
                    name: 'User',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                department_id: {
                    name: 'department',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                address_id: {
                    name: 'address',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                location_id: {
                    name: 'location',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_type:{
                    name: 'disposition_type',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_category:{
                    name: 'disposition_category',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_name:{
                    name: 'disposition_name',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                disposition_date:{
                    name: 'disposition_date',
                    type: 'date',
                    has_permission: true,
                    default: ''
                },
                disposition_details:{
                    name: 'disposition_details',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                sale_price:{
                    name: 'sale_price',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                movement_info:{
                    name: 'movement',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                recipient_info:{
                    name: 'recipient_info',
                    type: 'recipient_info',
                    has_permission: true,
                    default: ''
                },
                reference_info:{
                    name: 'reference', // dispositionb
                    type: 'text',
                    has_permission: true,
                    default: ''
                },

            },
            selected_asset_id: null,
            change_group_id: 'change-asset-group',
            add_new_asset_id: 'add-asset-list',
            update_asset_department_id: 'update-department_id-list',
            update_asset_supplier_id: 'update-supplier_id-list',
            update_asset_costs_id: 'update-costs-list',
            update_asset_details_id: 'update-details-list',
            update_asset_images_id: 'update-images-list',
            add_ms_files: 'add-ms-files',
            add_ms_link: 'add-ms-link',
            return_asset_id: 'return-asset-list',
            sell_asset_id: 'sell-asset-list',
            qr_modal_id: 'qrcode_generator',
            register_disposition_modal_id: 'register-disposition',
            isLoadingAssets: true,
            isLoadingAssetDetails: true,
            active_tab: 'assets_dashboard',
            dashboard_info: null,
            assetsListTabs:{
                assets_dashboard:{
                    'id': "assets_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                assets_list:{
                    'id': "assets_list",
                    'name': 'Assets',
                    'icon': "user",
                    'isActive': false
                },
                new_tab:{
                    'id': "new_tab",
                    'name': 'New',
                    'icon': "user",
                    'isActive': false
                },
                // export_tab:{
                //     'id': "export_tab",
                //     'name': 'Export',
                //     'icon': "user",
                //     'isActive': false
                // },
                // update_tab:{
                //     'id': "update_tab",
                //     'name': 'Update',
                //     'icon': "user",
                //     'isActive': false
                // },
            },
            asset_accounting_key_list:{
                uid: {
                    key: 'uid',
                    label: "ID",
                    class: "col-lg-1 a_uid",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a class="text-dark" href="./assets/'+item.id+'/view">' + item[key] + '</a>';
                    }
                },
                quantity: {
                    key: 'quantity',
                    label: "Qty",
                    class: "col-lg-1 a_quantity",
                    show_on_mobile: false,
                    case: ''
                },
                status: {
                    key: 'status',
                    label: "Status",
                    class: "col-lg-2 a_status",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<span class="status-btn status-btn-'+item.status+'">'+Vue.options.filters.titleize(item.status)+'</span>';
                    }
                },
                used_currency: {
                    key: 'used_currency',
                    label: "Currency",
                    class: "col-lg-1 a_used_currency",
                    show_on_mobile: false,
                    case: 'custom',
                    used_func: function (value){}
                },
                total_value: {
                    key: 'total_value',
                    label: "Cost",
                    class: "col-lg-1 a_total_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                forecast_value: {
                    key: 'forecast_value',
                    label: "Valuation",
                    class: "col-lg-1 a_forecast_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                sale_price: {
                    key: 'sale_price',
                    label: "Disposal",
                    class: "col-lg-1 a_sale_price",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                forecast_pl: {
                    key: 'forecast_pl',
                    label: "Forecast P&L",
                    class: "col-lg-1 a_forecast_pl",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText

                },
                actual_pl: {
                    key: 'actual_pl',
                    label: "Actual P&L",
                    class: "col-lg-1 a_actual_pl",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
            },
            asset_list_key_list:{
                uid: {
                    key: 'uid',
                    label: "ID",
                    class: "col-lg-1 a_uid",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a class="text-dark" href="./assets/'+item.id+'/view">' + item[key] + '</a>';
                    }
                },
                category_id: {
                    key: 'category_id',
                    label: "Category",
                    class: "col-lg-1 a_category_id",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        if (item.hasOwnProperty('category_info') && item.category_info){
                            return '<a class="text-dark" href="./assets?queue=category&id='+item.category_id+'">' + item.category_info.name + '</a>';
                        }
                        return '-';
                    }
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg-2 a_name",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return (item.uid !== '' ? 'UID: ' + item.uid : '');
                    },
                    value_func: function (index, item, key) {
                        return '<a class="text-dark" href="./assets/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
                    }
                },
                ledger: {
                    key: 'ledger',
                    label: "",
                    class: "",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '';
                    }
                },
                character: {
                    key: 'character',
                    label: "",
                    class: "",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '';
                    }
                },
                asset_usage: {
                    key: 'asset_usage',
                    is_html_label: true,
                    label: "<span class='au-cam'>Cam</span><span class='au-is-hero'>Hero</span><span class='au-is-asset'>Asset</span>",
                    class: "col-lg-1 a_asset_usage",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item){
                        var html = '';
                        let required_items = ['asset_usage', 'is_hero', 'is_asset'];
                        for (const key in required_items) {
                            let assetKey = required_items[key];

                            let required_classes = '';
                            switch (item[assetKey]){
                                case 0:
                                    required_classes = 'text-danger bx-x';
                                    break;
                                case 1:
                                    required_classes = 'text-success bx-check';
                                    break;
                                case 2:
                                    required_classes = 'text-secondary bx-question-mark';
                                    break;
                            }

                            html += "<b><i class='bx font-size-22 " + required_classes + "'>" + "</i></b>"

                        }
                        return html;
                    }
                },

                quantity: {
                    key: 'quantity',
                    label: "Qty",
                    class: "col-lg-1 a_quantity",
                    show_on_mobile: false,
                    case: ''
                },
                value: {
                    key: 'value',
                    label: "Unit",
                    class: "col-lg-1 a_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                total_value: {
                    key: 'total_value',
                    label: "Cost",
                    class: "col-lg-1 a_total_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                internal_reference: {
                    key: 'internal_reference',
                    label: "Ref No.",
                    class: "col-lg-1 a_reference",
                    show_on_mobile: false,
                    case: ''
                },
                actions: {
                    key: 'actions',
                    label: "Actions",
                    class: "col-lg-1 a_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'asset_list_actions'
                }
            },
            asset_list_fdp_key_list:{
                uid: {
                    key: 'uid',
                    label: "ID FDP",
                    class: "col-lg-1 a_uid",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a class="text-dark" href="./assets/'+item.id+'/view">' + item[key] + '</a>';
                    }
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col a_name",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return (item.uid !== '' ? 'UID: ' + item.uid : '');
                    },
                    value_func: function (index, item, key) {
                        return '<a class="text-dark" href="./assets/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
                    }
                },
                quantity: {
                    key: 'quantity',
                    label: "Qty",
                    class: "col-lg-1 a_quantity",
                    show_on_mobile: false,
                    case: ''
                },
                total_value: {
                    key: 'total_value',
                    label: "Cost",
                    class: "col-lg-1 a_total_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                status: {
                    key: 'status',
                    label: "Status",
                    class: "col-lg-2 a_status",
                    show_on_mobile: false,
                    case: 'asset_status'
                },
                fdp_status: {
                    key: 'fdp_status',
                    label: "FDP Status",
                    class: "col-lg-1 a_fdp_status",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let fdp_status = (item.fdp_status === 1 ? 'approved' : 'pending');
                        let fdp_status_class = (item.fdp_status === 1 ? 'active' : 'disposed');
                        return '<span class="status-btn status-btn-'+fdp_status_class+'">'+Vue.options.filters.titleize(fdp_status)+'</span>';
                    }
                },
                final_disposition_planning: {
                    key: 'fdplanning',
                    label: "FDP",
                    class: "col-lg-1 a_fdplanning",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'fdp_planning'
                },
                actions: {
                    key: 'actions',
                    label: "",
                    class: "col-lg-1 a_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'fdp_actions'
                },
                asset_actions: {
                    key: 'asset_actions',
                    label: "",
                    class: "col-lg-1 a_asset_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'asset_actions'
                }
            },

            breadcrumb_items: [],
            has_actions_permissions: false,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: SortOptions,
            },
            feature_views: {
                selected_view: {
                    id: 0,
                    name: 'Overview'
                },
                viewOptions: ViewOptions,
            },
            show_tag_manager: false,
            all_tags_list: [],
            acquisition_methods: AcquisitionMethodsList,
            shown_tags_lists: [],
            isAddingTag: false,
            selected_layout: 0,
            supportedLayouts: {
                grid: {
                    name: 'Grid',
                    value: 0,
                    class: 'grid',
                    isActive: true
                },
                smaller_grid: {
                    name: 'Smaller Grid',
                    value: 1,
                    class: 'smaller-grid',
                    isActive: false
                },
                small_list: {
                    name: 'Smaller List',
                    value: 3,
                    class: 'smaller-list',
                    isActive: false
                },
                list: {
                    name: 'List',
                    value: 2,
                    class: 'list',
                    isActive: false
                },
            },
            should_set_layout: false
        }
    },

    computed: {
        totalAssetsValue() {
            // shall work here?
            // this.getTotalsGroupedByCurrency(this.items_list);
            return this.getTotalOfProperty(this.items_list, 'total_value');
        },
        averageAssetsAge(){
            let ages_sum = this.items_list ? this.items_list.reduce((a, b) => +a + +b.asset_age_int, 0) : 0;
            return (ages_sum ? ages_sum / this.items_list.length : 0).toFixed();
        },
        assetsStatusFigures(){
            let status_figures = '';
            if (this.items_list){
                let status_groups = _.countBy(this.items_list, 'status');

                status_figures = Object.keys(status_groups).map(function (status) {
                    return '<span class="status-figures status-'+status+'">' + (Vue.options.filters.titleize(status)) + ' (' + status_groups[status] + ')</span>';
                });
                status_figures = _.join(status_figures, ', ');
            }
            return status_figures;
        },
        usedCurrenciesList(){

            let currency_figures = '';
            if (this.items_list){
                let currency_groups = _.countBy(this.items_list, 'used_currency');

                let getCurrencyByValue = this.getCurrencyByValue;
                currency_figures = Object.keys(currency_groups).map(function (currency) {
                    let currency_info = getCurrencyByValue(currency);
                    return '<span class="currency-figures currency-'+currency_info.value+'">' + ( currency_info.icon + ' - ' + currency_info.text) + ' (' + currency_groups[currency] + ')</span>';
                });
                currency_figures = _.join(currency_figures, ', ');
            }
            return currency_figures;
        },
        totalAssetsQty(){
            return this.getQuantityOf(this.items_list);
        },
        totalAssetsVolume(){
            return '<span>' + (this.items_list ? this.items_list.reduce((a, b) => +a + +b.volume, 0) : 0).toFixed(2) + '</span> m<sup>3</sup>';
        },
        totalsByCurrency(){

            // let minfied_items_list = this.items_list.map();
            let currency_groups = _.groupBy(this.items_list, 'used_currency');
            let all_currency_groups = {};

            for (const currencyGroupsKey in currency_groups) {

                let currency_group = currency_groups[currencyGroupsKey];

                var groupedByProperties = _.groupBy(currency_group, function(item) {
                    let key = '';
                    if (!!item.total_value){
                        key += '1';
                    }else{
                        key += '0';
                    }
                    if (!!item.forecase_value){
                        key += '1';
                    }else{
                        key += '0';
                    }
                    if (!!item.sale_price){
                        key += '1';
                    }else{
                        key += '0';
                    }
                    return key;
                });

                let total_count = currency_group.length;
                let currencyItem = this.getCurrencyByValue(currencyGroupsKey);

                let currency_totals = {
                    quantity_total: 0,
                    cost_sum: 0,
                    forecast_value_sum: 0,
                    sale_price_sum: 0,
                    forecast_value_total_value:0,
                    sale_price_total_value: 0
                };

                let items = {};
                let group_name_index = 0
                for (const propertiesGroupKey in groupedByProperties) {
                    group_name_index += 1;
                    let assetsGroup = groupedByProperties[propertiesGroupKey];
                    let cost_sum = this.getTotalOfProperty(assetsGroup, 'total_value');
                    let forecast_value_sum = this.getTotalOfProperty(assetsGroup, 'forecast_value');
                    let sale_price_sum = this.getTotalOfProperty(assetsGroup, 'sale_price');

                    let forecast_value_total_value = forecast_value_sum - cost_sum;
                    let sale_price_total_value = sale_price_sum - cost_sum;

                    let currency_icon = currencyItem.icon;

                    let forecast_value_total = this.handleValueWithIconAndSign(forecast_value_sum, cost_sum, currency_icon);
                    let sale_price_total = this.handleValueWithIconAndSign(sale_price_sum, cost_sum, currency_icon);


                    let quantity_total = this.getQuantityOf(assetsGroup);
                    currency_totals.quantity_total += quantity_total;
                    currency_totals.cost_sum += cost_sum;
                    currency_totals.forecast_value_sum += forecast_value_sum;
                    currency_totals.sale_price_sum += sale_price_sum;


                    items[propertiesGroupKey] = {
                        items: assetsGroup,
                        quantity_total: quantity_total,
                        cost_sum: currency_icon + cost_sum,
                        forecast_value_sum: currency_icon + forecast_value_sum,
                        sale_price_sum: currency_icon + sale_price_sum,
                        forecast_value_total: forecast_value_total,
                        forecast_value_total_value: forecast_value_total_value,
                        sale_price_total: sale_price_total,
                        sale_price_total_value: sale_price_total_value,
                        group_name_index: group_name_index
                    }
                }

                currency_totals.total_count = total_count;
                currency_totals.forecast_value_total = this.handleValueWithIconAndSign(currency_totals.forecast_value_sum, currency_totals.cost_sum, currencyItem.icon);
                currency_totals.sale_price_total = this.handleValueWithIconAndSign(currency_totals.sale_price_sum, currency_totals.cost_sum, currencyItem.icon);
                currency_totals.forecast_value_total_value = currency_totals.forecast_value_sum - currency_totals.cost_sum;
                currency_totals.sale_price_total_value = currency_totals.sale_price_sum - currency_totals.cost_sum;

                all_currency_groups[currencyGroupsKey] = {
                    currencyItem: currencyItem,
                    items: items,
                    currency_totals: currency_totals
                };
            }

            return all_currency_groups;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    created() {

        this.tab_list_name = 'assetsListTabs';

        let export_permission_name =  'browse_assets'/*'can_export_assets'*/;
        if (this.hasPermission(export_permission_name)){
            this.appendExportItemsTab(this.tab_list_name);
            this.export_required_actions.push('export_assets');
        }else{
            // export_assets_unavailable?
        }


        // if (this.hasPermission('view_files')){
            this.file_short_keys_list.files_download = {
                key: 'files_download',
                label: "Download",
                class: "col-lg-2 fil_files_download text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'files_download'
            };
        // }

        if (this.user_info){

            if (this.user_info.hasOwnProperty('can_bulk_update')){
                // shall check for other bulk update actions' permissions
                if (this.user_info.can_bulk_update === 1 && this.hasPermission(export_permission_name)){
                    this.appendBulkActionsTab(this.tab_list_name);
                    this.can_bulk_update = true;
                }
            }


            if(this.user_info.hasOwnProperty('user_meta') && this.user_info.user_meta){
                let label_options = Object.keys(this.labelAddForm);
                for (const key in label_options) {
                    const asset_label = label_options[key];
                    if (this.user_info.user_meta.hasOwnProperty(asset_label)) {
                        this.labelAddForm[asset_label] = this.user_info.user_meta[asset_label];
                    }
                }
            }
        }


        if (this.current_module && this.current_module.id === 3){
            this.feature_views.viewOptions.splice(1, 1);
            this.feature_views.viewOptions.splice(1, 1);
        }
        let getCurrencyByValue = this.getCurrencyByValue;
        let handleValueWithIconAndSign = this.handleValueWithIconAndSign;
        this.asset_accounting_key_list.used_currency.used_func = function (value){
            return getCurrencyByValue(value).text;
        }

        let item_type = this.item_type;
        this.asset_list_key_list.character = {
            key: 'character',
            label: "Character",
            class: "col-lg-1 a_character",
            show_on_mobile: false,
            case: 'custom_html',
            value_func: function (item, key) {
                let character_info = item.character_info;
                if (character_info){
                    return '<a class="text-dark" href="/' + item_type + '/characters/' + character_info.id + '/view">' + `(${character_info.character_number}) ${character_info.name}` + '</a>';
                }
                return '';
            }
        }
        this.asset_list_key_list.ledger = {
            key: 'ledger',
            label: "Ledger Account",
            class: "col-lg-1 a_ledger",
            show_on_mobile: false,
            case: 'custom_html',
            value_func: function (item, key) {
                let ledger_info = item.ledger_info;
                if (ledger_info){
                    return '<a class="text-dark" href="/' + item_type + '/ledgers">' + `${ledger_info.name}` + '</a>';
                }
                return '';
            }
        }

        //
        // this.asset_accounting_key_list.actual_pl.value_func = function (item, key) {
        //     let currency_icon = getCurrencyByValue(item.used_currency).icon;
        //     return handleValueWithIconAndSign(item.sale_price, item.total_value, currency_icon);
        // }
        //
        // this.asset_accounting_key_list.forecast_pl.value_func = function (item, key) {
        //     let currency_icon = getCurrencyByValue(item.used_currency).icon;
        //     return handleValueWithIconAndSign(item.forecast_value, item.total_value, currency_icon);
        // }


        this.has_actions_permissions = this.hasPermission('edit_assets') || this.hasPermission('delete_assets');

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_items_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);

            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);

        }

        if (window.USER_INFO.user_meta.preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.preferred_sorting;
        }

        if (this.is_managed_storage_module){
            let managed_storage_view = {
                id: 2,
                name: 'Managed Storage'
            };
            this.feature_views.selected_view = managed_storage_view;
            this.feature_views.viewOptions = [managed_storage_view];
        }else if (window.USER_INFO.user_meta.preferred_view) {
            this.feature_views.selected_view = window.USER_INFO.user_meta.preferred_view;
        }


        if (!this.is_tv_film_asset){
            delete this.asset_list_key_list.asset_usage;
        }


        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_assets_layout')) {
            window.selectedLayout = window.USER_INFO.user_meta.preferred_assets_layout;
        }else{
            window.selectedLayout = 2;
            this.set_usermeta('preferred_assets_layout', 2, '');
        }
        this.should_set_layout = true;



        let assets_list_active_tab = localStorage.getItem('assets_list_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('v') && queryVars.v !== ''){
            let selected_view_index = this.feature_views.viewOptions.map(function(e) { return e.id+""; }).indexOf(queryVars.v);
            if (selected_view_index !== -1){
                this.feature_views.selected_view = this.feature_views.viewOptions[selected_view_index];
            }
        }

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'assets_dashboard';
                    break;
                case 'assets':
                    this.active_tab = 'assets_list';
                    break;
                case 'new':
                    this.active_tab = 'new_tab';
                    break;
                // case 'export':
                //     this.active_tab = 'export_tab';
                //     break;
                // case 'update':
                //     this.active_tab = 'update_tab';
                //     break;
            }
        }else if (assets_list_active_tab && assets_list_active_tab !== ''){
            this.active_tab = assets_list_active_tab;
        }

        this.getActiveTabInfo(this.active_tab);
    },
    methods: {
        changedFormValue(field){
            this.set_usermeta(field, this.labelAddForm[field], '');
        },
        forceResetSelectingItems(){
            this.resetSelectingItems();
        },
        applyBulkAction(bulk_action, item_info){
            this.$emit('on-bulk-action', bulk_action, item_info);
        },
        tabClicked(tab_id){
            let older_active_tab = this.active_tab;
            this.active_tab = tab_id;
            localStorage.setItem('assets_list_active_tab', tab_id);
            this.getActiveTabInfo(tab_id, older_active_tab);
        },
        getActiveTabInfo(tab_id, older_active_tab=''){
            switch (tab_id) {
                case 'assets_dashboard':
                    this.$emit('on-fetch-totals');
                    break;

                case 'assets_list':
                    if (!['list_bulk_actions', 'export_item'].includes(older_active_tab)){
                        // don't reload content if older tab was bulk actions
                        if (!this.isLoadingAssets){
                            this.$emit('on-fetch-assets');
                        }else{
                            // console.log("'is already loading assets?");
                            // console.log('is already loading assets?');
                        }
                    }
                    break;
                case 'new_tab':
                    // shall get info of new_tab?
                    break;

                case 'export_item':
                case 'list_bulk_actions':
                    if (older_active_tab === ''){
                        if (!this.isLoadingAssets){
                            this.$emit('on-fetch-assets');
                        }
                    }
                    break;
            }
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        onAddingToOrder(received_item){
            this.$bvModal.hide('add-asset-to-order');
            location.reload();
        },
        setIsLoadingAssets(isLoadingAssets){
            this.isLoadingAssets = isLoadingAssets;
        },
        setIsLoadingAssetDetails(isLoadingAssetDetails){
            this.isLoadingAssetDetails = isLoadingAssetDetails;
        },
        getTotalOfVisibleItems(used_property='forecast_value', currency_icon='£'){
            let cost_sum = this.getTotalOfPropertyCustom(this.sortedItemsList, 'total_value');
            let value_sum = this.getTotalOfPropertyCustom(this.sortedItemsList, used_property);

            return this.handleValueWithIconAndSign(value_sum, cost_sum, currency_icon);
        },
        handleValueWithIconAndSign(forecast_value_sum, cost_sum, currency_icon) {
            let forecast_value_total = forecast_value_sum - cost_sum;
            let forecast_sign = '';
            if (forecast_value_total < 0) {
                forecast_sign = '-';
                forecast_value_total = forecast_value_total * -1;
            }
            forecast_value_total = forecast_sign + currency_icon + forecast_value_total;
            return forecast_value_total;
        },
        getTotalOfProperty(items_list, used_property='total_value'){
            return items_list ? items_list.reduce((a, b) => +a + +(b[used_property] ? b[used_property] : 0), 0) : 0;
        },
        getTotalOfPropertyCustom(items_list, used_property='total_value'){
            let total = 0;
            if (items_list.length !== 0){
                total = items_list.reduce(function(accumulator, currentValue, currentIndex, array) {
                    let increment = currentValue[used_property] ? currentValue[used_property] : 0;
                    return accumulator + increment;
                }, 0);
            }
            return total;
        },
        getQuantityOf(items_list){
            return items_list ? items_list.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        countToPercentage: function (count_of_items) {
            let total_count = this.sortedItemsList.length;
            if (count_of_items !== 0) {
                let value = (count_of_items * 100 / total_count).toFixed(2);
                return value === '0' ? '0.00' : value;
            }
            return '0.00';
        },
        assetsWithSusPlanning(sp_id){
            if (!this.sortedItemsList){
                return '0.00';
            }

            let count_of_items =  _.sumBy( this.sortedItemsList,
                ({ final_disposition_planning }) => Number(final_disposition_planning === sp_id)
            );

            return this.countToPercentage(count_of_items);
        },
        assetsWithSusPercentage(required_field){
            if (!this.sortedItemsList){
                return '0.00';
            }

            let count_of_items =  _.sumBy( this.sortedItemsList,
                (asset) => !!asset[required_field]
            );
            return this.countToPercentage(count_of_items);
        },
        getCheckedOrNot(checked){
            return "<i class='bx font-size-22 text-" + (checked ? 'success bx-check' : 'danger bx-x') + " '>" + "</i>";
        },
        changeLayout(layout, event){
            if (event){
                event.preventDefault();
            }
            this.selected_layout = layout;

            this.$nextTick(() => {
                let element = this.$refs.assetRow;

                if (element){
                    switch (layout.value){
                        case 0: // grid
                            element.classList.remove('list', 'grid-8')
                            element.classList.add('grid')
                            // addClass('grid').removeClass('list grid-8');
                            break
                        case 1: // 'smaller-grid':
                            // document.body.classList.add("sidebar-toggle");
                            element.classList.remove('list');
                            element.classList.add('grid');
                            element.classList.add('grid-8');
                            break
                        case 2: // 'list':
                            element.classList.remove('grid', 'grid-8')
                            element.classList.add('list')
                            // $('.product-row').addClass('list').removeClass('grid grid-8');
                            break
                    }
                    if (this.should_set_layout){
                        this.should_set_layout = false;
                        return;
                    }
                    this.setAsActive(layout.value);
                    this.changedLayout(layout);
                }else{
                    if (this.should_set_layout){
                        this.should_set_layout = false;
                        return;
                    }
                    this.setAsActive(layout.value);
                    this.changedLayout(layout);

                }
            });
        },
        setAsActive(value, with_layout_changing=false) {
            for (let key in this.supportedLayouts) {
                let layout = this.supportedLayouts[key];

                if (layout.value == value) {
                    if (with_layout_changing){
                        this.changeLayout(layout);
                    }
                    layout.isActive = true;
                } else {
                    layout.isActive = false;
                }
            }
        },
        getSortedItemsList(){
            return this.sortedItemsList;
        },
        setSearchKeyword(keyword){
            this.search_keyword = keyword;
            this.$refs['search-box'].setInputValue(keyword);
            this.$emit('on-search-keyword-change', keyword);
        },
        saveTags(asset, assetKey){
            // console.log("tags list for asset #" + asset.id);
            // console.log(this.sortedItemsList[assetKey].tag_ids);
            if (this.groupBy === 4){
                this.$emit('on-updating-tags', false, false);
            }

            let message = "Updated Tags List of {"+asset.name+"} Successfully";
            this.set_item_value('assets', asset.id, 'tag_ids', this.sortedItemsList[assetKey].tag_ids, message, false);

        },
        async myAddTag(inputAttrs, asset, assetKey, resolve) {

            if (inputAttrs.value){
                const tags_info_index = this.all_tags_list.map(function (e) {
                    return e.name;
                }).indexOf(inputAttrs.value);

                if (tags_info_index !== -1){
                    this.presentToast("Error!",
                        'Tag name already in use',
                        'danger',
                        3000,
                        true,
                        true);
                    return;
                }
            }

            this.isAddingTag = true;
            let params = {
                name: inputAttrs.value,
                description: inputAttrs.value
            };
            const { data } = await tagsResource.store(params);

            let new_tag = data.data.items;

            if (new_tag){
                if (!this.sortedItemsList[assetKey].tag_ids){
                    this.sortedItemsList[assetKey].tag_ids = [];
                }
                if (!this.sortedItemsList[assetKey].tags_info){
                    this.sortedItemsList[assetKey].tags_info = [];
                }

                this.sortedItemsList[assetKey].tag_ids.push(new_tag.id+"");

                this.sortedItemsList[assetKey].tags_info.push(new_tag);


                this.all_tags_list.push(new_tag);
                resolve(this.all_tags_list);
            }else{
                this.presentToast((data.data.status ? "Success!" : "Error!"),
                    data.data.msg,
                    (data.data.status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
            }
            this.isAddingTag = false;

            this.saveTags(asset, assetKey);
        },
        myRemoveTag(tag_id, asset, assetKey) {

            const tagIndex = this.all_tags_list.map(function (e) {
                return e.id;
            }).indexOf(tag_id);

            let tag = this.all_tags_list[tagIndex];

            if (!tag.id){
                tag = JSON.parse(tag);
            }

            let older_tags = this.sortedItemsList[assetKey].tag_ids;
            let older_tags_info = this.sortedItemsList[assetKey].tags_info || [];

            if (older_tags && older_tags.includes(tag_id+"")){
                // shall remove it
                tag.is_selected = false;
                const index = older_tags.indexOf(tag.id+"");

                if (index !== -1){
                    older_tags.splice(index, 1);
                }


                const tags_info_index = older_tags_info.map(function (e) {
                    return e.id;
                }).indexOf(tag.id);

                if (tags_info_index !== -1){
                    older_tags_info.splice(tags_info_index, 1);
                }

            }else{
                //shall add it
                tag.is_selected = true;
                if (!older_tags){
                    older_tags = [];
                    older_tags_info = [];
                }

                older_tags.push(tag_id+"");
                older_tags_info.push(tag);

            }

            this.sortedItemsList[assetKey].tag_ids = older_tags;
            this.sortedItemsList[assetKey].tags_info = older_tags_info;

            this.all_tags_list[tagIndex] = tag;
            this.all_tags_list.push({id:'', name:''});
            this.all_tags_list.splice(-1, 1);


            this.saveTags(asset, assetKey);

        },
        searchAsset(keyword) {
            return function (item) {
                let searchable_values = [
                    'name', 'description', 'internal_reference', 'total_value'
                ];

                return item['name'].toLowerCase().includes(keyword.toLowerCase()) !== -1;

            }
        },
        sortItemsList() {
            if (this.is_actions_bar_opened && this.should_hide_unselected){
                let strongThis = this;
                return this.items_list.filter(function(asset) {
                    return strongThis.selected_ids.includes(asset.id);
                });
            }else{
                let filtered_list=this.items_list;
                if (this.should_set_layout){
                    this.setAsActive(window.selectedLayout, true);
                }
                return filtered_list;
            }
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('preferred_sorting', sortType, message);
            this.refreshContentCustom();
            // TODO: should change sorting using >> sortItemsList
            // this.sortItemsList();
        },
        saveView(view) {
            let message = "Saved View {" + view.name + "} Successfully";
            this.feature_views.selected_view = view;
            this.set_usermeta('preferred_view', view, message);
            this.$emit('on-change-feature-view', view);
            // TODO: should change sorting using >> sortItemsList
        },

        changedLayout(layout){
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_assets_layout', layout.value, message);
        },
        savePerPage(perPage) {

            if (perPage === 0) {
                this.perpage.perPageStr = 'All';
            } else {
                this.perpage.perPageStr = perPage;
            }
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_items_per_page', perPage, message);
            this.refreshContentCustom();
            // TODO: should change Per Page in Pagination
        },
        set_tags_list(tags_list = []) {
            this.all_tags_list = tags_list;
        },
        set_dashboard_info(dashboard_info = []) {
            this.dashboard_info = dashboard_info;
        },
        set_breadcrumb_items(items = []) {
            if (items.length > 0) {
                var last_item = items[items.length - 1];
                last_item = {
                    text: last_item.text,
                    active: true
                }

                items[items.length - 1] = last_item;
            }
            this.breadcrumb_items = null;
            this.breadcrumb_items = items;
        },
        refreshAssetItem(item, key, value) {
            let index = _.findIndex(this.items_list, {id: item.id});
            if (index > -1) {
                this.items_list[index][key] = value;
            }
        },
        async refreshAssetAfterDisposition(response, asset_id, received_then_action) {

            let data = response.data.data;
            let status = data.status;
            let selected_view = this.feature_views.selected_view.id;

            const asset_response = await axios.get(`/api/assetflow/assets/${asset_id}/getAssetInfo`, {
                params: {
                    selected_view: selected_view,
                }
            });
            if (asset_response.data && asset_response.data.hasOwnProperty('items')) {
                let edited_asset = asset_response.data.items;

                if (edited_asset){
                    this.refreshAsset('', edited_asset, null);
                }

                this.presentToast((status ? "Success!" : "Error!"),
                    status ? 'Registered Successfully' : data.data.msg,
                    (status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);

                if (received_then_action) {
                    received_then_action(edited_asset);
                }
            }
        },

        async performAction(item, action_name, event, addForm=null, received_then_action=null) {
            if (!['editAsset', 'resetGroup', 'exportAsset'].includes(action_name) && item){
                this.selected_asset_id = item.id;
            }

            let that = this;

            switch (action_name) {
                case 'addAsset':
                    this.$refs[this.add_new_asset_id].setSelectedItem(null, null);
                    this.$bvModal.show('create-asset');
                    console.log("should add?");
                    break;
                case 'editAsset':
                    window.location.href = '/' + this.item_type + '/assets/' + item.id + '/edit_asset';
                    break;
                case 'exportAsset':
                    this.exportAsset(item, addForm);
                    break;
                case 'deleteAsset':
                    this.deleteItem(item.id, item.name, 'assets', event);
                    break;
                case 'changeFolder':
                    this.$emit('on-change-folder', item);
                    break;
                case 'splitAsset':
                    this.$emit('on-split-asset', item);
                    break;
                case 'duplicateAsset':
                    this.$emit('on-duplicate-asset', item);
                    break;
                case 'resetGroup':
                    let message = "Removed {"+item.name+"} from Group Successfully";
                    this.set_item_value('assets', item.id, 'asset_group_id', null, message, false);

                    this.refreshAssetItem(item, 'asset_group_id', null);

                    break;
                case 'changeGroup':
                    this.$refs[this.change_group_id].set_item(item);
                    this.$bvModal.show(this.change_group_id);

                    break;
                case 'show_usage_action':
                    this.completeTask(null, 'icons-action-tasks', 'asset_usage, is_hero, is_asset', item);
                    break;
                case 'add_fdp_info':
                    this.completeTask(null, 'icons-action-tasks', 'final_disposition_planning, fdp_status, valuation_method, forecast_value', item);
                    break;
                case 'addToOrder':
                    this.$refs['add-asset-to-order'].set_item(item);

                    this.$bvModal.show('add-asset-to-order');
                    break;
                case 'addToCharacter':
                    this.completeTask(null, 'icons-action-tasks', 'character_id,ledger_id', item);
                    break;
                case 'addToCloset':
                    this.completeTask(null, 'icons-action-tasks', 'closet_character_id', item);
                    break;
                case 'changeDepartment':
                    this.$refs[this.update_asset_department_id].set_item(item);
                    this.$bvModal.show(this.update_asset_department_id);
                    break;
                case 'changeSupplier':
                    this.$refs[this.update_asset_supplier_id].set_item(item);
                    this.$bvModal.show(this.update_asset_supplier_id);
                    break;
                case 'updateCosts':
                    this.$refs[this.update_asset_costs_id].set_item(item);
                    this.$bvModal.show(this.update_asset_costs_id);
                    break;
                case 'updateDetails':
                    this.$refs[this.update_asset_details_id].set_item(item);
                    this.$bvModal.show(this.update_asset_details_id);
                    break;
                case 'updateUsage':
                    this.completeTask(null, 'icons-action-tasks', 'status, asset_usage, is_hero, is_asset', item);
                    break;
                case 'updateImages':
                    this.$refs[this.update_asset_images_id].set_item(item);
                    this.$bvModal.show(this.update_asset_images_id);
                    break;
                case 'add_ms_files':
                case 'add_ms_link':
                    let my_action_name = this[action_name];
                    this.$refs[my_action_name].setSelectedItem(item);
                    this.$bvModal.show(my_action_name);
                    break;
                case 'returnAsset':
                    this.$refs[this.return_asset_id].set_item(item);
                    this.$bvModal.show(this.return_asset_id);
                    break;
                case 'sellAsset':
                    this.$refs[this.sell_asset_id].set_item(item);
                    this.$bvModal.show(this.sell_asset_id);
                    break;
                case 'generateQRCode':
                    this.$refs[this.qr_modal_id].setQrCodeURL(item);
                    this.$bvModal.show(this.qr_modal_id);
                    break;
                case 'registerDisposition':

                    let required_asset_id = item.asset_id;
                    if (!required_asset_id || !this.hasPermission('action_fdp')){
                        return;
                    }
                    // shall send and 'Edit' request with disposition_info value

                    let disposition_info = {
                        contact_id: item.contact_id && item.contact_id.hasOwnProperty('user') ? item.contact_id.user.id : null,
                        department_id: item.department_id ? item.department_id.id : null,
                        address_id: item.address_id ? item.address_id.id : null,
                        location_id: item.location_id ? item.location_id.id : null,
                        sale_price: item.has_amount || null,
                        disposition_date: item.has_date || null,
                        reference_info: item.has_reference || null,
                        movement_info: item.has_movement || null,
                        disposition_details: item.disposition_details || null,
                        currency: 'gbp',
                        disposition_type: item.disposition_type ? item.disposition_type.id : 0,
                        disposition_images: [],
                    }
                    let recipient_info = item.has_recipient;
                    if (recipient_info){
                        disposition_info.recipient_type = item.has_recipient.item_type;
                        disposition_info.recipient_id = item.has_recipient.id;
                    }
                    let disposition_params = {
                        asset_id: required_asset_id,
                        customer_id: item.customer_id,
                        item_details:{
                            disposition_info: disposition_info,
                        }
                    };

                    axios({
                        method: 'put',
                        url: '/api/assetflow/assets/' + required_asset_id,
                        baseURL: '/',
                        data: disposition_params,
                    }).then(response => {
                        if (response){
                            this.refreshAssetAfterDisposition(response, required_asset_id, received_then_action);
                        }
                    })
                    break;

                case 'clearFDP':
                case 'proposeFDP':
                    let params = {};
                    let asset_id = item.asset_id;
                    if (!asset_id){
                        return;
                    }
                    params.item_id = asset_id;


                    // handle refreshing asset after update.
                    let then_action = async function (){
                        let selected_view = that.feature_views.selected_view.id;
                        const response = await axios.get(`/api/assetflow/assets/${asset_id}/getAssetInfo`, {
                            params: {
                                selected_view: selected_view,
                            }
                        });
                        if (response.data && response.data.hasOwnProperty('items')){
                            let edited_asset = response.data.items;
                            that.refreshAsset('', edited_asset, null);
                            if (received_then_action){
                                received_then_action(edited_asset);
                            }
                        }
                    }

                    if (action_name === 'clearFDP'){
                        this.set_item_value('assets', asset_id, 'clear_fdp_proposal', params, 'Cleared FDP Successfully', false, false, then_action);
                        break;
                    }

                    params.contact_id = item.contact_id && item.contact_id.hasOwnProperty('user') ? item.contact_id.user.id : null;
                    params.department_id = item.department_id ? item.department_id.id : null;
                    params.address_id = item.address_id ? item.address_id.id : null;
                    params.location_id = item.location_id ? item.location_id.id : null;

                    params.fdp_amount = item.has_amount || null;
                    params.fdp_date = item.has_date || null;
                    params.fdp_reference = item.has_reference || null;
                    if (item.has_recipient){
                        params.fdp_recipient = item.has_recipient.id;
                        params.fdp_recipient_type = item.has_recipient.item_type;
                    }else{
                        params.fdp_recipient = null;
                        params.fdp_recipient_type = null;
                    }

                    params.fdp_movement = item.has_movement || null;
                    params.fdp_details = item.disposition_details || null;
                    params.final_disposition_planning = item.final_disposition_planning ? item.final_disposition_planning.id : 0;
                    params.fdp_status = item.fdp_status ? item.fdp_status.id : 0;



                    // save fdp info as meta values?
                    this.set_item_value('assets', asset_id, 'fdp_proposal', params, 'Saved FDP Successfully', false, false, then_action);

                    break;
                case 'exportAssets':
                    this.$emit('on-export-assets', 'pdf');
                    break;
                case 'exportAssetsCSV':
                    this.$emit('on-export-assets', 'csv', 10);
                    break;
                case 'exportAssetsXLSX':
                    this.$emit('on-export-assets', 'xlsx', 11);
                    break;
                case 'exportAssetsXLSXWithImages':
                    this.$emit('on-export-assets', 'xlsx', 12);
                    break;
                case 'exportAssetImages':
                    this.$emit('on-export-asset-images');
                    break;
                case 'setLabelAddForm':
                    this.labelAddForm = item;
                    break;
            }
        },
        refreshPage(modal_id){
            this.selected_asset_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            location.reload();
        },
        appendAsset(asset_info){
            // console.log("asset_info");
            // console.log(asset_info);

            this.$emit('on-adding-new-asset');
        },
        refreshAsset(modal_id, edited_asset, required_keys=null){
            this.selected_asset_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_asset){
                this.$emit('on-adding-new-asset');
            }
            let index = _.findIndex(this.items_list, {id: edited_asset.id});
            if (index > -1) {
                if (required_keys){
                    if (required_keys != ['asset_links']){
                        required_keys.push('latest_post_update');
                    }
                    for (const requiredKeysKey in required_keys) {
                        let required_key = required_keys[requiredKeysKey];

                        if (['asset_images', 'colours_info', 'colours', 'asset_meta', 'disposition_info', 'ledger_info', 'latest_post_update', 'asset_links'].includes(required_key)){
                            if (required_key === 'latest_post_update' && Array.isArray(edited_asset[required_key]) && edited_asset[required_key].length === 1){
                                edited_asset[required_key] = edited_asset[required_key][0];
                            }

                            if (required_key === 'asset_links'){
                                this.$set(this.items_list[index].asset_meta, required_key, edited_asset.asset_meta[required_key]);
                            }else{
                                this.$set(this.items_list[index], required_key, edited_asset[required_key]);
                            }
                        }else{
                            this.items_list[index][required_key] = edited_asset[required_key];
                        }

                    }
                }else{
                    this.items_list[index] = edited_asset;
                }
            }
        },
        searchFor(keyword, event) {
            // backend search
            this.$emit('on-search-keyword-change', keyword);

            // this.search_keyword = keyword;
            // this.sortItemsList();
        },
        createTag() {
            this.$emit('on-tag-creation');
        },
        createFolder(parent_id = '') {
            this.$emit('on-folder-creation', parent_id);
        },
        deleteItem(item_id, item_name, item_type, event) {
            this.deleteItemByID(item_id, item_name, item_type, event)
                .then((response) => {
                    this.$emit('on-item-deleted');
                });
        },
        refreshContentCustom: function () {
            this.perpage.currentPage = 1;
            this.$emit('on-pagination-changed', this.selected_item);
        }
    }
}
</script>

<style>
.placeholder-ai {
    font-size: 120px;
    text-align: center;
}

.my-dots-horizontal .btn-none {
    padding: 0 !important;
}

.my-dots-horizontal {
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 5;
    width: 24px;
    height: 24px;
    display: flex;
    opacity: 1.0;
    padding: 0;
    align-items: center;
    cursor: pointer;
    background: #fff;
}

</style>
