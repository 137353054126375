<template>
    <Layout>
        <main>

            <div class="purchaseOrders-wrapper">
                <!-- Page-Content start-->
                <div id="page-content-wrapper">
                    <div class="page-content-inner">
                        <div>
                            <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                        </div>

                        <div class="product-page-header">
                            <h2 class="title">Purchase Order List</h2>
                            <div class="page-value-info">
                                <ul class="d-flex align-items-center">
                                    <li class="d-flex align-items-center">
                                        Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="product-filter-option-wrapper clearfix d-flex">

                            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                    request_meta.from + '-' + request_meta.to
                                }}</span> of <span>{{ request_meta.total }}</span></div>

                            <div class="filter-wrap d-flex align-items-center ml-auto">


                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Sort: <b>{{ sorting.sortType.text }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in sorting.sortOptions"
                                        :key="option.value"
                                        @click="saveSorting(option, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>

                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Show: <b>{{
                                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                        }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in perpage.pageOptions"
                                        :key="option.value"
                                        @click="savePerPage(option.value, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>

                        </div>

                        <!-- Product Section Start-->
                        <LotsOfItemsAlert :perpage="perpage"/>

                        <div v-if="purchaseOrdersData" class="row">
                            <div class="col-lg-12">
                                <po-lines
                                    object_type="purchaseOrders"
                                    :items="sortedItemsList"
                                    :current_page_type="item_type"
                                    :keys_list="po_list_keys_list">
                                </po-lines>
                            </div>
                        </div>


                        <div v-if="!purchaseOrdersData || total === 0 || sortedItemsList.length === 0"
                             class="products text-center">
                            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                            <h4>No Purchase Orders found here at the moment.</h4>

                        </div>
                        <!-- Product Section End-->
                        <div class="pagination">
                            <b-pagination
                                v-if="perpage.perPage !== 0"
                                v-model="perpage.currentPage"
                                :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                class="pagination d-flex align-items-center justify-content-center"
                                :per-page="perpage.perPage"
                            ></b-pagination>
                        </div>

                    </div>
                </div>
                <!-- Page-Content end-->

            </div>
        </main>

    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import GmapCustomMarker from "../extras/gmap-custom-marker";
import AddNewPurchaseOrder from "../extras/AddNewPurchaseOrder";
import SearchBox from "@/components/search-box";
import UserMeta from "@/mixins/usermeta.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";

const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');
const supplierResource = new Resource('api/assetflow/suppliers');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";



export default {
    name: 'PurchaseOrdersList',
    mixins: [UserMeta, TotalsAndCurrencies],
    props: ['item_id', 'item_type'],
    components: {LotsOfItemsAlert, PoLines, AddNewPurchaseOrder, SearchBox, GmapCustomMarker, Layout, PageHeader},
    data() {
        return {
            po_list_keys_list:{
                uid: {
                    key: 'uid',
                    label: "PO No.",
                    class: "col-lg-1 po_uid",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a class="text-dark" href="./purchaseOrders/'+item.id+'/view">' + item.uid + '</a>';
                    }
                },
                created_at: {
                    key: 'created_at',
                    label: "Date",
                    class: "col-lg-1 po_created_at",
                    show_on_mobile: true,
                    case: ''
                },
                created_by: {
                    key: 'created_by',
                    label: "Created By",
                    class: "col-lg-2 po_created_by",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a href="./users/'+item['user_info'].id+'/view">' + Vue.options.filters.titleize(item['user_info'].name) + '</a>';
                    }
                },
                reference: {
                    key: 'reference',
                    label: "Ref",
                    class: "col-lg-1 po_reference",
                    show_on_mobile: true,
                    case: ''
                },
                department_id: {
                    key: 'department_id',
                    label: "Department",
                    class: "col-lg-1 po_department",
                    show_on_mobile: false,
                    case: ''
                },
                supplier_id: {
                    key: 'supplier_id',
                    label: "Supplier",
                    class: "col-lg-2 po_supplier",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a class="text-dark" href="./suppliers/'+item['supplier_info'].id+'/view">' + Vue.options.filters.titleize(item['supplier_info'].name) + '</a>';
                    }
                },
                status: {
                    key: 'status',
                    label: "Status",
                    class: "col-lg-1 po_status",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<span class="active_button button_size data_status'+(item.status === 1 ? 'active_status' : 'inactive_status')+'">'+(item.status === 1 ? 'Active' : 'Inactive')+'</span>';
                    }
                },
                po_items_count: {
                    key: 'po_items_count',
                    label: "Items",
                    class: "col-lg-1 po_items_count",
                    show_on_mobile: false,
                    case: ''
                },
                total_value: {
                    key: 'total_value',
                    label: "Total",
                    class: "col-lg-1 a_total_value",
                    show_on_mobile: true,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText,
                },
            },
            total: 0,
            purchaseOrdersData: [],
            suppliersList:null,
            request_meta: {},
            breadcrumb_items: [],
            title: "Purchase Orders",
            items: [
                {
                    text: "Purchase Orders",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_purchaseOrder_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },


        };
    },
    created() {
        this.has_purchaseOrder_actions_permissions = this.hasPermission('edit_purchaseOrders') || this.hasPermission('delete_purchaseOrders');

        if (this.has_purchaseOrder_actions_permissions){
            this.po_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false
            }
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_purchaseOrders_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.purchaseOrder_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.purchaseOrder_preferred_sorting;
        }

        this.getList();
        this.getSuppliersList();

    },
    computed: {
        getItemValue(item, value){
            console.log("item");
            console.log(item);

            switch (value){
                case 'customer':
                    return item.customer_info.name;
                default:
                    return item[value];
            }
        },
        totalAssetsValue() {
            return this.purchaseOrdersData ? this.purchaseOrdersData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        performAction(purchaseOrder, action, purchaseOrderKey, event){
            if (action === 'edit'){
                this.$refs['add-new-purchaseOrder'].setSelectedItem(purchaseOrder);
                this.$refs['add-new-purchaseOrder'].setSuppliersList(this.suppliersList);
                this.$bvModal.show('create-purchaseOrder');
                this.setupAddressLookup('purchaseOrder');
            }else if (action === 'delete'){
                if (purchaseOrder.id !== '' && purchaseOrder.assets_count === 0){
                    this.deleteItemByID(purchaseOrder.id, purchaseOrder.name, 'purchaseOrders', event)
                    .then((response) => {
                        if (response.data.data.status){
                            this.purchaseOrdersData.splice(purchaseOrderKey, 1);
                        }else{
                            console.log("response.data");
                            console.log(response.data);
                        }
                    });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['name'];

                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";

                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.purchaseOrdersData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.purchaseOrdersData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('purchaseOrder_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_purchaseOrders_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery)
            const {data} = await purchaseOrderResource.list(this.listQuery);
            this.total = data.meta.total;
            this.purchaseOrdersData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        async getSuppliersList(){
            this.handleQueryItems();
            this.listQuery.page = 1;
            this.listQuery.limit = 100;
            const {data} = await supplierResource.list(this.listQuery);
            this.suppliersList = data.data.items.map(function(e) { return {id: e.id, name: e.name}; });
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-purchaseOrder'].setSelectedItem(null);
            this.$refs['add-new-purchaseOrder'].setSuppliersList(this.suppliersList);
            this.$bvModal.show('create-purchaseOrder');
            this.setupAddressLookup('purchaseOrder');
        }
    },
}
</script>


