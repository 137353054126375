export const Currencies = [
    {
        value: 'gbp',
        text: "GBP",
        icon: '£',
        rate: 1
    },
    {
        value: 'usd',
        text: "USD",
        icon: '$',
        rate: 1.39
    },
    {
        value: 'eur',
        text: "EUR",
        icon: '€',
        rate: 1.17
    }
];
