import request from '../utils/request';

/**
 * Simple RESTful resource class
 */
class Resource {
    constructor(uri) {
        this.uri = uri;
        this.cancelSource = axios.CancelToken.source();
    }
    cancelSearch() {
        if (this.cancelSource) {
            this.cancelSource.cancel('Start new process, stop active process');
        }
    }
    resetToken() {
        this.cancelSource = null;
    }
    appendToUrl(params) {
        this.uri = this.uri + params;
    }
    list(query, cancelToken= null, force_allow=false, method='get') {
        let options = {
            url: '/' + this.uri,
            method: method,
            params: query,

        };

        if (!force_allow){
            if (cancelToken){
                options.cancelToken = cancelToken;
            }else{
                this.cancelSearch();
                this.cancelSource = axios.CancelToken.source();
                options.cancelToken = this.cancelSource.token;
            }
        }

        // let that = this;
        return request(options).catch(function (error) {
            /// console.log('Received: ' + error.message);

            return {
                data:{
                    items: [],
                    data:{
                        total: 0,
                        items: [],
                    }
                }
            };
        }).finally(()=> {
            // might have to check if it's assets, or maybe item search? then disable this
            // that.resetToken();
        });
    }
    get(id) {
        return request({
            url: '/' + this.uri + '/' + id,
            method: 'get',
        });
    }
    saveFile(formData) {
        return request({
            url: '/' + this.uri,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    store(resource) {
        return request({
            url: '/' + this.uri,
            method: 'post',
            data: resource,
        });
    }
    update(id, resource) {
        return request({
            url: '/' + this.uri + '/' + id,
            method: 'put',
            data: resource,
        });
    }
    destroy(id) {
        return request({
            url: '/' + this.uri + '/' + id,
            method: 'delete',
        });
    }
}

export { Resource as default };
