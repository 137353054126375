<template>
    <div class="row">
        <p v-if="this.item_type !== 'users'">Roles that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

        <p v-else>Roles that&nbsp;<a :href="base_url +'/'+ item_info.group + '/users/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>

        <div class="col-lg-12">
            <div class="text-sm-right">
                <a v-if="item_type === 'users' && hasPermission('add_users')" :href="'/'+item_info.current_page_type+'/users/new?email='+item_info.email" class="btn btn-rounded af-btn-accent af-accent">
                    <i class="fa fa-users"></i> Wizard
                </a>

                <span :id="this.items.length === 0 && this.selected_owner_id === null ? 'disabled-wrapper' : 'enabled-wrapper'">
                    <b-button :disabled="this.items.length === 0 && this.selected_owner_id === null" v-b-modal.roles-modal variant="success" class="btn btn-success btn-rounded">
                        <i class="mdi mdi-plus"></i> Assign Role
                    </b-button>
                </span>
                <b-tooltip target="disabled-disabled-wrapper" placement="left" variant="info">No roles to assign.</b-tooltip>
                <b-modal id="roles-modal"
                         ref="assign-role-modal"
                         title="Assign Role"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-role
                        ref="assign-to-role"
                        :items="item_info.item_type === 'users' && item_info.current_page_type === 'assteflow' ? null : this.items"
                        :item_info="item_info"
                        @assigned-role="assignToRole"
                        @changed-owner="changeOwner"
                    ></assign-to-role>
                </b-modal>
            </div>
        </div>


        <div class="col-lg-12">
            <po-lines v-if="rolesData && rolesData.length > 0"
                      :items="rolesData"
                      :current_page_type="item_info.current_page_type"
                      :object_type="'role'"
                      :line_type="'role'"
                      :get-poline-classes-dict="getPoLineClassFor"
                      :keys_list="role_list_key_list">

                <template v-slot:role_actions="{ item, index }">
                    <a v-if="permissions_list['browse_' + permission_name]" :href="base_url +'/'+ item_info.group + '/roles/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                    <a v-if="permissions_list['edit_' + permission_name] && !item.users_count" v-on:click="unAssignFromRole($event, item.id, item.owner_id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                </template>
            </po-lines>
            <div v-else class="text-center">
                <h6>No roles found here at the moment.</h6>
            </div>
        </div>


    </div>
</template>

<script>
    import SearchBox from "@/components/search-box";
    import Resource from '@/api/resource';

    import AssignToRole from "../forms/AssignToRole";
    import PoLines from "./detailsViews/PoLines";
    import {RoleKeysList} from "@/views/assetflow/form_data/RoleKeysList";
    export default {
        name: "ListRoles",
        components: {PoLines, AssignToRole, SearchBox},
        props: {
            item_info: Object,
        },
        data() {
            return {
                base_url: "",
                rolesData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 20
                },
                assignData: {
                    owner_id: "",
                    role_id: ""
                },
                selected_owner_id: null,
                assigned_module: null,
                role_list_key_list: RoleKeysList,
                has_role_actions_permissions: false,
                permissions_list:{},
                permission_name: 'roles',
            }
        },
        created() {
            delete this.role_list_key_list.is_default;
            delete this.role_list_key_list.sharing_type;

            this.permissions_list['browse_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
            this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
            this.has_role_actions_permissions = this.permissions_list['browse_' + this.permission_name] || this.permissions_list['edit_' + this.permission_name];

            if (!this.isSuperAdmin){
                delete this.role_list_key_list.entity_type;
                delete this.role_list_key_list.entity;
                this.role_list_key_list.name.class = 'rol_name col-lg-' + (this.has_role_actions_permissions ? '5' : '6');
                this.role_list_key_list.module.class = 'rol_module col-lg-5';
            }

            if (this.has_role_actions_permissions){
                this.role_list_key_list.actions = {
                    key: 'actions',
                    label: "",
                    class: "col-lg-1 c_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'role_actions'
                }
            }


            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;

            if (this.item_type !== 'users'){
                delete this.role_list_key_list.entity_type;
                delete this.role_list_key_list.entity;
            }

            this.getList();
            this.getRolesList();
        },
        computed:{
            should_refresh_roles_list(){
                return this.item_info.refresh_roles_list;
            }
        },
        watch:{
            should_refresh_roles_list: {
                deep: true,
                handler: function (newValue) {
                    if (this.item_info.refresh_roles_list){
                        this.getList();
                        this.item_info.refresh_roles_list = false
                    }
                }
            },
        },
        methods: {
            getList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/roles' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20,
                            current_page_type: this.item_info.current_page_type
                        },
                    }).then(response => {
                        this.listLoading = false;
                        this.rolesData = response.data.data.items;
                        this.total = response.data.data.total;

                        this.item_info.customer_roles = this.rolesData;
                        this.listLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            changeOwner(owner_id='', item_type='', assigned_module=''){
                this.getRolesList(owner_id, item_type, assigned_module);
                this.selected_owner_id = owner_id;
                this.assigned_module = assigned_module;
            },
            assignToRole(role_id){
                this.$refs['assign-role-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: role_id,
                    item_type: 'roles',
                    selected_owner_id: this.selected_owner_id
                };

                this.assign_unassign_role();

                console.log("should assign user to role " + role_id + this.item_id);
            },
            assign_unassign_role() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_role',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getRolesList();
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromRole(event, role_id, owner_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: role_id,
                    item_type: 'roles',
                    unassign: true,
                    selected_owner_id: owner_id
                };
                this.assign_unassign_role();
                console.log("should unassign user from role >> " + role_id);

            },
            async getRolesList(owner_id='', item_type='', assigned_module='') {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;
                this.listQuery.current_page_type = this.item_info.current_page_type;
                this.listQuery.assigned_module = assigned_module;

                let owner_text = '';
                if (owner_id !== '') {
                    owner_text = '-' + owner_id;
                    this.listQuery.owner_id = owner_id;
                    if (item_type === 'service_providers'){
                        item_type = 'sp';
                    }else{
                        item_type = item_type.slice(0, -1);
                    }
                    this.listQuery.owner_item_type = item_type;
                }
                const roleResource = new Resource('api/assetflow/roles');

                const { data } = await roleResource.list(this.listQuery);


                this.items =  data.data.items.filter((role) => {
                    return !this.rolesData.map(x => x.id + ( owner_text !== '' && x.hasOwnProperty('module_info') && x.module_info ? ('-'+x.module_info.id) : '')).includes(role.id + owner_text);
                    // return !this.rolesData.map(x => x.id).includes(role.id);
                });

                if (this.$refs['assign-to-role']){
                    this.$refs['assign-to-role'].refreshRolesList(this.items);
                }

                this.listLoading = false;
            },
        }
    }
</script>


