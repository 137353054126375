<template>
    <b-modal :id="received_modal_id"
             :title="modal_title + extra_title"
             size="xl"
             title-class="font-18"
             hide-footer>

        <div class="common-step-panel use-section">
            Assets List
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="saveAction">
                {{ modal_title }}
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(received_modal_id)">Cancel</b-button>
        </div>

    </b-modal>
</template>
<script>

export default {
    components: {},
    mixins: [],
    name: 'AddChangeAssetsModal',
    props: ['item_type', 'received_modal_id', 'modal_title', 'object_type'],
    data(){
        return {
            extra_title: '',
            item_info: {},
            addForm: {
                item_details: {}
            },
            section_fields:{},
            required_fields: {},
            required_select_lists: {}
        }
    },
    created() {
    },
    methods:{
        saveAction(){
            this.$emit('onFormSubmit', 'add_assets', this.item_info, this.addForm, this.object_type, this.item_type, this.received_modal_id);
        },
        set_item(item){
            this.item_info = item;
            this.extra_title = ' to {' + item.uid + '}';
        }
    }
}
</script>
