<template>
    <div class="row">
        <p v-if="this.item_type !== 'users'">Permissions that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

        <p v-else>Permissions that&nbsp;<a :href="base_url +'/'+ item_info.group + '/users/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>


        <div class="col-lg-12">
            <div class="text-sm-right" v-if="hasPermission('add_permissions')">
                <span :id="this.items.length === 0 ? 'disabled-wrapper' : 'enabled-wrapper'">
                    <b-button :disabled="this.items.length === 0" v-b-modal.permissions-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign Permission</b-button>
                </span>

                <b-tooltip target="disabled-disabled-wrapper" placement="left" variant="info">No permissions to assign.</b-tooltip>

                <b-modal id="permissions-modal"
                         ref="assign-permission-modal"
                         title="Assign Permission"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-permission :items="this.items" :item_id="item_info.id" @assigned-permission="assignToPermission"></assign-to-permission>
                </b-modal>
            </div>
        </div>

        <div class="col-lg-12">
            <po-lines
                :items="permissionsData"
                :current_page_type="item_info.current_page_type"
                :object_type="'permissions'"
                :keys_list="permission_list_keys_list">

                <template v-slot:permission_actions="{ item, index }">
                    <a v-if="hasPermission('add_permissions')" @click="unAssignFromPermission($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                </template>
            </po-lines>
        </div>

    </div>
</template>

<script>
    import SearchBox from "@/components/search-box";
    import Resource from '@/api/resource';
    import AssignToPermission from "../forms/AssignToPermission";
    import PoLines from "./detailsViews/PoLines";

    export default {
        name: "ListPermissions",
        components: {
            PoLines,
            AssignToPermission,
            SearchBox
        },
        props: {
            item_info: Object,
        },
        data() {
            return {
                permission_list_keys_list: {
                    id: {
                        key: 'id',
                        label: "#",
                        class: "col-lg-1 perm_id",
                        show_on_mobile: false,
                        case: ''
                    },
                    display_name: {
                        key: 'display_name',
                        label: "Name",
                        class: "col-lg perm_display_name",
                        show_on_mobile: false,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return Vue.options.filters.titleize(item.display_name);
                        }
                    },
                },
                base_url: "",
                permissionsData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 20
                },
                assignData: {
                    user_id: "",
                    permission_id: ""
                }
            }
        },
        created() {
            this.has_permission_actions_permissions = this.hasPermission('delete_permissions');
            if (this.has_permission_actions_permissions){
                this.permission_list_keys_list.actions = {
                    key: 'actions',
                    label: "",
                    class: "col-lg-1 perm_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_class: '',
                    slot_name: 'permission_actions'
                }
            }

            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;
            this.getList();
            this.getPermissionsList();
        },
        methods: {
            getList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/permissions' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20
                        },
                    }).then(response => {
                        this.listLoading = false;
                        this.permissionsData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            assignToPermission(permission_id){
                this.$refs['assign-permission-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: permission_id,
                    item_type: 'permissions'
                };

                this.assign_unassign_permission();

                console.log("should assign user to permission " + permission_id + this.item_id);
            },
            assign_unassign_permission() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_permission',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getPermissionsList();
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromPermission(event, permission_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: permission_id,
                    item_type: 'permissions',
                    unassign: true
                };
                this.assign_unassign_permission();
                console.log("should unassign user from permission >> " + permission_id);

            },
            async getPermissionsList() {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;
                this.listQuery.is_grouped = false;


                const permissionResource = new Resource('api/assetflow/permissions');

                const { data } = await permissionResource.list(this.listQuery);

                if (data.data.items.items){
                    this.items = data.data.items.items.filter((permission) => {
                        return !this.permissionsData.map(x => x.id).includes(permission.id);
                    });
                }else{
                    this.items = [];
                }


                this.listLoading = false;
            },
        }
    }
</script>


