
export default {
    data() {
        return {
            selected_storage_info: null,
            isLoadingItem: false,
        }
    },
    computed:{
        selected_storage_id(){
            return this.addForm.storage_id;
        }
    },
    watch:{
        selected_storage_id:{
            deep: true,
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue){
                    this.getAllSPStorageInfo();
                }
            }
        },
    },
    methods: {
        getAllSPStorageInfo(){
            this.selected_storage_info = null;
            if (this.selected_storage_id){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/sp_storages/' + this.selected_storage_id.id,
                    baseURL: '/',
                }).then(response => {
                    this.selected_storage_info = response.data;
                    this.isLoadingItem = false;
                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        },
        getItemAndShowModal(modal_ref, event){
            event.preventDefault();
            let selected_item = this.selected_storage_info;
            if (selected_item){
                this.showModal(selected_item, modal_ref);
            }
        },
    }
}
