import {ActivityLogKeysList} from "@/views/assetflow/form_data/ActivityLogKeysList";
import {MSPostMetaKeys} from "@/views/assetflow/form_data/MSPostMetaKeys";

export default {
    data(){
        return{
            formShown: true,
            selected_layout: null,
            supportedLayouts: {
                small_list: {
                    name: 'Timeline',
                    value: 0,
                    class: 'smaller-list',
                    isActive: false
                },
                list: {
                    name: 'List',
                    value: 1,
                    class: 'list',
                    isActive: false
                },
            },
            asset_posts_list_key_list:ActivityLogKeysList,
            ms_post_meta_keys: MSPostMetaKeys,
        }
    },
    created: function () {
    },
    methods: {
        hideForm() {
            this.formShown = !this.formShown;
            this.set_usermeta('post_update_form_appearance', this.formShown, '');
        },
        changeTimelineLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_timeline_layout', layout.value, message);

            this.selected_layout = layout.value;
        },
    }
}
