<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_defaults",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    mounted() {
        if(this.current_customer && this.current_customer.id !== ''){
            this.fetchInfoOf();
        }
    },
    methods: {
        fetchInfoOf() {
            if (this.current_customer.hasOwnProperty('customer_meta')){
                this.addForm.asset_meta = this.parseCustomFieldsFrom(this.current_customer.customer_meta, false);
            }
        },
    },
    data(){
        return {
            addForm:{
                defaults_defaults: 1,
                asset_meta: {
                    custom_fields: []
                }
            },
            sidebar_navigation: {
                defaults: {
                    label: 'Defaults',
                    settings: {
                        defaults_defaults: {
                            id: 'defaults_defaults',
                            label: 'Defaults',
                            functionality: 'core'
                        }
                    }
                },
                custom_fields: {
                    label: 'Custom Fields',
                    settings: {
                        defaults_defaults: {
                            id: 'custom_fields',
                            label: 'Custom Fields',
                            functionality: 'core'
                        }
                    }
                },
            }
        }
    }
}
</script>

