const FirstPath = window.location.pathname.split('/')[1];
export const AssetListKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 a_uid",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/assets/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col a_name",
        show_on_mobile: true,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {
            return (item.uid !== '' ? 'UID: ' + item.uid : '');
        },
        value_func: function (index, item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/assets/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 a_status",
        show_on_mobile: false,
        case: 'asset_status'
    },
    quantity: {
        key: 'quantity',
        label: "Qty",
        class: "col-lg-1 a_quantity",
        show_on_mobile: false,
        case: ''
    },
    total_value: {
        key: 'total_value',
        label: "Cost",
        class: "col-lg-1 a_total_value",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: null,
        value_func: null
    },
    location_id: {
        key: 'location_id',
        label: "Location",
        class: "col-lg-1 a_location_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.titleize(item.address_location_name);
        }
    },
    internal_reference: {
        key: 'internal_reference',
        label: "Ref No.",
        class: "col-lg-1 a_reference",
        show_on_mobile: false,
        case: ''
    },
    asset_usage: {
        key: 'asset_usage',
        is_html_label: true,
        label: "<span class='au-cam'>Cam</span><span class='au-is-hero'>Hero</span><span class='au-is-asset'>Asset</span>",
        class: "col-lg-2 a_asset_usage",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item){
            var html = '';
            let required_items = ['asset_usage', 'is_hero', 'is_asset'];
            for (const key in required_items) {
                let assetKey = required_items[key];

                let required_classes = '';
                switch (item[assetKey]){
                    case 0:
                        required_classes = 'text-danger bx-x';
                        break;
                    case 1:
                        required_classes = 'text-success bx-check';
                        break;
                    case 2:
                        required_classes = 'text-secondary bx-question-mark';
                        break;
                }

                html += "<b><i class='bx font-size-22 " + required_classes + "'>" + "</i></b>"

            }
            return html;
        }
    },
    actions: {
        key: 'actions',
        label: "Actions",
        class: "col-lg-2 a_actions",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_name: 'asset_list_actions'
    }
};
