<script>
import AssetIconList from "../views/assetflow/assets/subItems/AssetLayouts/AssetIconList";
export default {
  components: {AssetIconList},
    created() {
    },
    computed:{
      edited_title(){
          let title = this.title;
          if (this.status_class !== '' && this.status_text !== ''){
              title += ' <label class="' + this.status_class + '">' + this.status_text+'</label>';
          }
          return title;
      }
    },
    props: {
    title: {
      type: String,
      default: '',
    },
    status_class: {
      type: String,
      default: '',
    },
    status_text: {
      type: String,
      default: '',
    },
    item_info: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->


  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
          <div class="d-flex align-items-center justify-content-between">
              <div>
                  <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
                  <h2 v-if="edited_title !== ''" class="title" v-html="edited_title"></h2>
              </div>
              <div>
                  <slot/>
              </div>
          </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
