import be from "vue2-datepicker/locale/es/be";
import {CreatedDatesList} from "@/views/assetflow/form_data/CreatedDatesList";
import {FileTypesList} from "@/views/assetflow/form_data/FileTypesList";
import {FileTemplateCategoriesList} from "@/views/assetflow/form_data/FileTemplateCategoriesList";

export default {
    data(){
        return {
            customer_item: {
                id:0,
                name: window.AF_NAME,
            },
            selected_customer: {
                id: null,
                name: 'All Customers'
            },
            customers_list: [],
            selected_sp_warehouse: {
                id: null,
                name: 'All Warehouses'
            },
            sp_warehouses_list: [],
            selected_sp_space: {
                id: null,
                name: 'All Spaces'
            },
            sp_spaces_list: [],
            selected_sp_storage: {
                id: null,
                name: 'All Storage'
            },
            sp_storages_list: [],
            selected_sp_movement_type: {
                id: null,
                name: 'All'
            },
            sp_movement_types_list: [
                { id: 0, name: 'Asset' },
                { id: 1, name: 'Storage' },
            ],
            selected_customer_status: {
                id: null,
                name: 'All'
            },
            customer_statuss_list: [
                { id: null, name: 'All Customers' },
                { id: 1, name: 'Active Customers' },
                { id: 0, name: 'Inactive Customers' },
            ],
            selected_user_status: {
                id: null,
                name: 'All'
            },
            user_statuss_list: [
                { id: null, name: 'All Users' },
                { id: 3, name: 'Created Users' },
                { id: 2, name: 'Invited Users' },
                { id: 1, name: 'Active Users' },
                { id: 0, name: 'Disabled Users' },
            ],
            selected_created_at: {
                id: null,
                name: 'All Days'
            },
            created_ats_list: CreatedDatesList,
            selected_file_type: {
                id: null,
                name: 'All Types'
            },
            file_types_list: FileTypesList,
            selected_category_id: {
                id: null,
                name: 'All Categories'
            },
            category_ids_list: FileTemplateCategoriesList,

            selected_order: {
                id: null,
                name: 'All Orders'
            },
            orders_list: [],
            selected_status_storage: {
                id: null,
                name: 'All Statuses'
            },
            status_storages_list: [],
            selected_status_order: {
                id: null,
                name: 'All Order Statuses'
            },
            status_orders_list: [],
            selected_order_service: {
                id: null,
                name: 'All Services'
            },
            order_services_list: [],
            required_items: [],
            shall_select_customer_message: false,
            is_order: false,
        }
    },
    created: function () {
    },
    methods: {
        setupSelectedStatus: function (selected_item) {
            let selected_status_storage = selected_item;
            if (selected_status_storage && selected_status_storage.id === null) {
                selected_status_storage.name = 'All Statuses';
            }
            this.selected_status_storage = selected_status_storage;
            if (this.$refs['TopFiltersBlock']) {
                this.$refs['TopFiltersBlock'].selected_status_storage = selected_status_storage;
            }
        },
        setTopFilterValue: function (selected_item) {
            if (selected_item && ['order_lines', 'orders'].includes(this.object_type)) {
                switch (this.current_groupBy){
                    case 0:
                        // All
                        break;
                    case 1:
                        // status
                        this.setupSelectedStatus(selected_item);
                        break;
                    case 2:
                        // Order Type
                        break;
                    case 3:
                        // due date?
                        break;
                    case 4:
                        // service
                        let selected_order_service = selected_item;
                        if (selected_order_service && selected_order_service.id === null) {
                            selected_order_service.name = 'All Services';
                        }

                        this.selected_order_service = selected_order_service;
                        if (this.$refs['TopFiltersBlock']) {
                            this.$refs['TopFiltersBlock'].selected_order_service = selected_order_service;
                        }
                        break;
                }
            } else if (selected_item && this.current_groupBy === 0) {
                this.setupSelectedStatus(selected_item);
            }

        },
        onSelectingTopFilter(required_item, item_type){

            if (this.object_type === 'managed_storages' && item_type === 'customer'){
                this.selected_item = null;
            }

            if (this.object_type === 'sp_storages'){
                if ((item_type === 'customer' && this.current_groupBy === 4) || (item_type === 'status_storage' && this.current_groupBy === 1)){
                    // ?? set group by option
                    this.selected_item = required_item;
                }
            }else if (['orders', 'order_lines'].includes(this.object_type)){
                if (
                    (item_type === 'status_storage' && this.current_groupBy === 1) ||
                    (item_type === 'order_service' && this.current_groupBy === 4)
                ){
                    this.selected_item = required_item;
                }
            }

            if (item_type === 'sp_warehouse' && this.hasOwnProperty('selected_sp_space') && this.selected_sp_space){
                this.selected_sp_space = {
                    id: null,
                    name: 'All Spaces'
                };
            }

            if (item_type === 'customer' && this.hasOwnProperty('selected_order') && this.selected_order){
                this.selected_sp_space = {
                    id: null,
                    name: 'All Orders'
                };
            }

            this['selected_'+item_type] = required_item;
            // 1?

            this.handleGettingList();

        },
        setSearchKeyword(keyword){
            this.$refs['search-box'].setInputValue(keyword);
            this.searchFor(keyword);
        },
        appendTopFiltersValues(required_query={}, $is_bulk_action=false, shouldRefreshEverything=true){
            let sidebar_query = {};
            this.top_filters_text = '';

            if (this.required_items.length > 0){
                if (this.required_items.includes('sp_warehouses') && this.selected_sp_warehouse && this.selected_sp_warehouse.hasOwnProperty('id') && this.selected_sp_warehouse.id){
                    required_query.current_warehouse_id = this.selected_sp_warehouse.id;
                    this.top_filters_text += this.selected_sp_warehouse.name + '-';
                    sidebar_query.current_warehouse_id = required_query.current_warehouse_id;
                }
                if (this.required_items.includes('sp_spaces') && this.selected_sp_space && this.selected_sp_space.hasOwnProperty('id') && this.selected_sp_space.id){
                    required_query.current_space_id = this.selected_sp_space.id;
                    this.top_filters_text += this.selected_sp_space.name + '-';
                    sidebar_query.current_space_id = required_query.current_space_id;
                }
                if (this.required_items.includes('sp_storages') && this.selected_sp_storage && this.selected_sp_storage.hasOwnProperty('id') && this.selected_sp_storage.id){
                    required_query.current_storage_id = this.selected_sp_storage.id;
                    this.top_filters_text += this.selected_sp_storage.name + '-';
                    sidebar_query.current_storage_id = required_query.current_storage_id;
                }
                if (this.required_items.includes('sp_movement_type') && this.selected_sp_movement_type && this.selected_sp_movement_type.hasOwnProperty('id') && this.selected_sp_movement_type.id !== null){
                    required_query.sp_movement_type = this.selected_sp_movement_type.id;
                    this.top_filters_text += this.selected_sp_movement_type.name + '-';
                    sidebar_query.sp_movement_type = required_query.sp_movement_type;
                }
                if (this.required_items.includes('customer_status') && this.selected_customer_status && this.selected_customer_status.hasOwnProperty('id')){
                    required_query.customer_status = this.selected_customer_status.id;
                    this.top_filters_text += this.selected_customer_status.name + '-';
                    sidebar_query.customer_status = required_query.customer_status;
                }
                if (this.required_items.includes('user_status') && this.selected_user_status && this.selected_user_status.hasOwnProperty('id')){
                    required_query.user_status = this.selected_user_status.id;
                    this.top_filters_text += this.selected_user_status.name + '-';
                    sidebar_query.user_status = required_query.user_status;
                }
                if (this.required_items.includes('created_at') && this.selected_created_at && this.selected_created_at.hasOwnProperty('id')){
                    required_query.created_at = this.selected_created_at.id;
                    this.top_filters_text += this.selected_created_at.name + '-';
                    sidebar_query.created_at = required_query.created_at;
                }
                if (this.required_items.includes('file_type') && this.selected_file_type && this.selected_file_type.hasOwnProperty('id')){
                    required_query.file_type = this.selected_file_type.id;
                    this.top_filters_text += this.selected_file_type.name + '-';
                    sidebar_query.file_type = required_query.file_type;
                }
                if (this.required_items.includes('category_id') && this.selected_category_id && this.selected_category_id.hasOwnProperty('id')){
                    required_query.category_id = this.selected_category_id.id;
                    this.top_filters_text += this.selected_category_id.name + '-';
                    sidebar_query.category_id = required_query.category_id;
                }
                if (this.required_items.includes('customers') && this.selected_customer && this.selected_customer.hasOwnProperty('id')){
                    if (this.selected_customer.id){
                        required_query.current_customer_id = this.selected_customer.id;
                        this.top_filters_text += this.selected_customer.name + '-';
                        sidebar_query.current_customer_id = required_query.current_customer_id;
                    }else{
                        required_query.customer_id = null;
                    }
                }
                if (this.required_items.includes('orders') && this.selected_order && this.selected_order.hasOwnProperty('id') && this.selected_order.id){
                    required_query.order_id = this.selected_order.id;
                    this.top_filters_text += this.selected_order.name + '-';
                    sidebar_query.order_id = required_query.order_id;
                }
                if (this.required_items.includes('status_storage') && this.selected_status_storage && this.selected_status_storage.hasOwnProperty('id') && this.selected_status_storage.id !== null){
                    required_query.status_id = this.selected_status_storage.id;
                    this.top_filters_text += (this.selected_status_storage.name || this.selected_status_storage.label) + '-';
                    sidebar_query.status_id = required_query.status_id;
                }

                if (this.required_items.includes('status_order') && this.selected_status_order && this.selected_status_order.hasOwnProperty('id') && this.selected_status_order.id !== null){
                    this.top_filters_text += (this.selected_status_order.name || this.selected_status_order.label) + '-';
                    required_query.order_status_id = this.selected_status_order.id;
                    sidebar_query.order_status_id = required_query.order_status_id;
                }

                if (this.required_items.includes('order_service') && this.selected_order_service && this.selected_order_service.hasOwnProperty('id') && this.selected_order_service.id !== null){
                    this.top_filters_text += this.selected_order_service.name + '-';
                    let service_id = this.selected_order_service.id;
                    if (['order_lines', 'orders'].includes(this.object_type)){
                        required_query.order_service_type_id = service_id;
                        sidebar_query.order_service_type_id = required_query.order_service_type_id;
                    }
                }
            }

            if (this.selected_dashboard_item && this.selected_dashboard_item.hasOwnProperty('backend_key')){
                if (required_query.hasOwnProperty(this.selected_dashboard_item.backend_key)){
                    this.selected_dashboard_item = null;
                }else{
                    required_query[this.selected_dashboard_item.backend_key] = this.selected_dashboard_item.id;
                    this.top_filters_text += (this.selected_dashboard_item.hasOwnProperty('name') ? this.selected_dashboard_item.name : this.selected_dashboard_item.backend_key) + '-';
                }
            }
            if (required_query.hasOwnProperty('s_keyword')){
                sidebar_query.s_keyword = required_query.s_keyword;
                this.top_filters_text += required_query.s_keyword + '-';
            }

            if (!this.is_export && !$is_bulk_action){
                if (
                    (
                        ['orders', 'order_lines', 'sp_storages', 'sp_locations', 'sp_spaces', 'sp_warehouses', 'sp_storage_wrappers', 'sp_location_types', 'sp_assignments', 'sp_movements'].includes(this.object_type) ||
                        (this.item_type === 'sp' && this.object_type === 'managed_storages' && shouldRefreshEverything)
                    ) && !this.is_order
                ){
                    this.refreshSidebarLists(sidebar_query, true);
                }
            }


            required_query.is_export = this.is_export;
            if (this.is_export){
                required_query.export_type = this.export_type;
                required_query.template_id = this.template_id;
                required_query.top_filters_text = this.top_filters_text;
            }

            return required_query;
        },
        handleGettingList: function () {
            if (this.is_order) {
                // handle order logic here
                if (this.$refs['OrderOrderLineList'] && this.$refs['OrderOrderLineList'].length > 0){
                    this.$refs['OrderOrderLineList'][0].getOrderLineList();
                }
            } else {
                this.getList(this.selected_item);
            }
        },
        resetFilter(required_item=''){
            switch (required_item) {
                case 'selected_customer':
                    let selected_customer = {
                        id: null,
                        name: 'All Customers'
                    };

                    if (this.object_type === 'managed_storages' || this.current_groupBy === 4){
                        this.selected_item = null;
                    }

                    this.set_usermeta('top_filter_'+required_item, selected_customer.id, "", this.is_cross_entity);
                    this.selected_customer = selected_customer;
                    this.$refs['TopFiltersBlock'].selected_customer = selected_customer;
                    this.resetFilter('selected_order');
                    this.resetFilter('selected_status_order');
                    break;
                case 'selected_order':
                    let selected_order = {
                        id: null,
                        name: 'All Orders'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_order.id, "", false);
                    this.selected_order = selected_order;
                    this.$refs['TopFiltersBlock'].selected_order = selected_order;
                    break;
                case 'selected_sp_warehouse':
                    let selected_sp_warehouse = {
                        id: null,
                        name: 'All Warehouses'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_sp_warehouse.id, "", false);
                    this.selected_sp_warehouse = selected_sp_warehouse;
                    let all_sp_space = {
                        id: null,
                        name: 'All Spaces'
                    }
                    this.selected_sp_space = all_sp_space;
                    this.$refs['TopFiltersBlock'].selected_sp_space = all_sp_space;
                    this.$refs['TopFiltersBlock'].selected_sp_warehouse = selected_sp_warehouse;
                    break;
                case 'selected_sp_space':
                    let selected_sp_space = {
                        id: null,
                        name: 'All Spaces'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_sp_space.id, "", false);
                    this.selected_sp_space = selected_sp_space;
                    this.$refs['TopFiltersBlock'].selected_sp_space = selected_sp_space;
                    break;
                case 'selected_sp_storage':
                    let selected_sp_storage = {
                        id: null,
                        name: 'All Storage'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_sp_storage.id, "", false);
                    this.selected_sp_storage = selected_sp_storage;
                    this.$refs['TopFiltersBlock'].selected_sp_storage = selected_sp_storage;
                    break;
                case 'selected_sp_movement_type':
                    let selected_sp_movement_type = {
                        id: null,
                        name: 'All'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_sp_movement_type.id, "", false);
                    this.selected_sp_movement_type = selected_sp_movement_type;
                    this.$refs['TopFiltersBlock'].selected_sp_movement_type = selected_sp_movement_type;
                    break;
                case 'selected_customer_status':
                    let selected_customer_status = {
                        id: null,
                        name: 'All'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_customer_status.id, "", false);
                    this.selected_customer_status = selected_customer_status;
                    this.$refs['TopFiltersBlock'].selected_customer_status = selected_customer_status;
                    break;
                case 'selected_user_status':
                    let selected_user_status = {
                        id: null,
                        name: 'All'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_user_status.id, "", false);
                    this.selected_user_status = selected_user_status;
                    this.$refs['TopFiltersBlock'].selected_user_status = selected_user_status;
                    break;
                case 'selected_created_at':
                    let selected_created_at = {
                        id: null,
                        name: 'All'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_created_at.id, "", false);
                    this.selected_created_at = selected_created_at;
                    this.$refs['TopFiltersBlock'].selected_created_at = selected_created_at;
                    break;
                case 'selected_file_type':
                    let selected_file_type = {
                        id: null,
                        name: 'All'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_file_type.id, "", false);
                    this.selected_file_type = selected_file_type;
                    this.$refs['TopFiltersBlock'].selected_file_type = selected_file_type;
                    break;
                case 'selected_category_id':
                    let selected_category_id = {
                        id: null,
                        name: 'All'
                    };
                    this.set_usermeta('top_filter_'+required_item, selected_category_id.id, "", false);
                    this.selected_category_id = selected_category_id;
                    this.$refs['TopFiltersBlock'].selected_category_id = selected_category_id;
                    break;
                case 'selected_status_storage':
                    let selected_status_storage = {
                        id: null,
                        name: 'All Statuses'
                    };

                    if (this.current_groupBy === 1){
                        this.selected_item = null;
                    }
                    this.set_usermeta('top_filter_'+required_item, selected_status_storage.id, "", false);
                    this.selected_status_storage = selected_status_storage;
                    this.$refs['TopFiltersBlock'].selected_status_storage = selected_status_storage;
                    break;
                case 'selected_status_order':
                    let selected_status_order = {
                        id: null,
                        name: 'All Order Statuses'
                    };

                    this.set_usermeta('top_filter_'+required_item, selected_status_order.id, "", false);
                    this.selected_status_order = selected_status_order;
                    this.$refs['TopFiltersBlock'].selected_status_order = selected_status_order;
                    break;
                case 'selected_order_service':
                    let selected_order_service = {
                        id: null,
                        name: 'All Services'
                    };

                    if (this.current_groupBy === 4){
                        this.selected_item = null;
                    }
                    this.set_usermeta('top_filter_'+required_item, selected_order_service.id, "", false);
                    this.selected_order_service = selected_order_service;
                    this.$refs['TopFiltersBlock'].selected_order_service = selected_order_service;
                    break;
                case 'item':
                    this.selected_item = null;
                    this.set_usermeta((this.object_type + '_filter_type_id'), null, "", false);
                    break;
                case 'dashboard_item':
                    this.selected_dashboard_item = null;
                    break;
                default:
                    break;
            }

            this.handleGettingList();

        }
    }
}
