<script>

    /**
     * Lock screen page -component
     */
    export default {
        name: "lockscreen",
        data(){
            return {
                isAuthError: false,
                user_info: {
                    id: null,
                    name: "AssetFlow",
                    can_switch_customer: false,
                    can_switch_sp: false
                },
            }
        },

        created() {
            this.user_info = window.USER_INFO ? window.USER_INFO : this.user_info;
        },
        props: {
            submitUrl: {
                type: String,
                required: true
            },
            authError: {
                type: String,
                required: false,
                default: () => null
            }
        },
        mounted() {
            this.isAuthError = !!this.authError;
        }
    };
</script>

<template>
    <div>
        <div class="home-btn d-none d-sm-block">
            <a href="/" class="text-dark">
                <i class="fas fa-home h2"></i>
            </a>
        </div>
        <div class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card overflow-hidden">
                            <div class="bg-soft-primary">
                                <div class="row">
                                    <div class="col-7">
                                        <div class="text-primary p-4">
                                            <h5 class="text-primary">Lock screen</h5>
                                            <p>Enter your password to unlock the screen!</p>
                                        </div>
                                    </div>
                                    <div class="col-5 align-self-end">
                                        <img :src="getAssetBaseUrl + '/images/profile-img.png'" alt class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <div>
                                    <a href="/">
                                        <div class="avatar-md profile-user-wid mb-4">
                                          <span class="avatar-title rounded-circle bg-light">
                                            <img :src="getAssetBaseUrl + '/images/logo-sm-dark.png'" alt class="rounded-circle" height="34" />
                                          </span>
                                        </div>
                                    </a>
                                </div>
                                <div class="p-2">
                                    <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{authError}}</b-alert>
                                    <b-form class="p-2" :action="submitUrl" method="POST">
                                        <slot />
                                        <div class="user-thumb text-center mb-4">
                                            <img
                                                :src="user_info.avatar ? user_info.avatar : '/images/users/avatar-1.jpg'"
                                                class="rounded-circle img-thumbnail avatar-md"
                                                alt="thumbnail"
                                            />
                                            <h5 class="font-size-15 mt-3">{{user_info.name}}</h5>
                                        </div>

                                        <div class="form-group">
                                            <label for="userpassword">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                class="form-control"
                                                id="userpassword"
                                                placeholder="Enter password"
                                            />
                                        </div>

                                        <div class="form-group row mb-0">
                                            <div class="col-12 text-right">
                                                <button class="btn btn-primary w-md" type="submit">Unlock</button>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 text-center">
                            <p>
                                Not you ?
                                <a
                                    href="./login"
                                    class="font-weight-medium text-primary"
                                >Sign In</a>
                            </p>
                            <p>
                                © {{ new Date().getFullYear() }} Asset Flow. Crafted with
                                <i class="mdi mdi-heart text-danger"></i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
