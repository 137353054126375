<template>

    <b-modal :id="modal_id"
             :title="modal_title.replace('ORL-UID', order_line_info ? order_line_info.uid : 'Order Line')"
             :size="modal_size"
             title-class="font-18"
             @hide="cancelClicked"
             hide-footer>

        <div v-if="order_line_info && order_line_info.hasOwnProperty('item_info') && order_line_info.item_info">

            <span v-if="order_line_info.hasOwnProperty('managed_storage_info') && order_line_info.managed_storage_info" :class="'order_line item_info managed_storage_info'">
                <a class="text-dark" :href="'/' + current_page_type +'/managed_storages/' + order_line_info.item_id + '/view'">
                    <span v-if="order_line_info.hasOwnProperty('item_info') && order_line_info.item_info">{{ (order_line_info.item_info.name + ' (ASS-' + order_line_info.item_info.uid + ')') }}</span>
                    <span v-else>{{ order_line_info.managed_storage_info.uid }}</span>
                </a>
            </span>
            <span v-else :class="'order_line item_info ' + (order_line_info.item_type === 0 ? 'asset_info' : 'asset_group_info')">
                <a class="text-dark" :href="'/' + current_page_type +'/asset' + ((order_line_info.item_type === 1 ? '_group' : '')) + 's/' + order_line_info.item_info.id + '/view'">{{order_line_info.item_info.name + (order_line_info.item_info.uid !== '' ? ('(' + (order_line_info.item_type === 0 ? 'ASS' : 'GRP') + '-' + order_line_info.item_info.uid + ')') : '')}}</a>
            </span>
        </div>

        <OrderLineProcessingFieldsIntakeOnly
            ref="order-line-fields"
            :current_page_type="current_page_type"
            :received_parent_order="parent_order"
            :received_selected_item="order_line_info"
            @after-saving-is-done="afterSaving"
            @on-cancel-clicked="cancelClicked"/>

    </b-modal>

</template>

<script>
import AssetActions from "@/mixins/assetActions.mixin";
import OrderLineProcessingFieldsIntakeOnly from "./OrderLineProcessingFieldsIntakeOnly";

export default {
    components: {OrderLineProcessingFieldsIntakeOnly},
    mixins: [AssetActions],
    name: "AddIntakeProfile",
    props:['modal_id', 'modal_title', 'modal_size', 'item_type', 'received_parent_order', 'current_page_type'],
    data(){
        return {
            order_line_info: null,
            parent_order: null
        }
    },
    created() {
        this.parent_order = this.received_parent_order;
    },
    methods:{
        setItemInfo(order_line_info, parent_order=null){
            if (parent_order){
                this.parent_order = parent_order;
            }
            this.order_line_info = order_line_info;
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        afterSaving(new_item){
            this.$bvModal.hide(this.modal_id);
            this.$emit('after-saving-modal', new_item);
        }
    }

}
</script>

