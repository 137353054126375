<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_lifecycle",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    data(){
        return {
            addForm:{
                lifecycle_statuses: 1,
                lifecycle_acquisitions: 1,
                lifecycle_dispositions: 1,
                lifecycle_planning: 1,
                lifecycle_fdp: 1
            },
            sidebar_navigation: {
                lifecycle: {
                    label: 'Lifecycle',
                    settings: {
                        lifecycle_statuses: {
                            id: 'lifecycle_statuses',
                            label: 'Statuses',
                            functionality: 'core'
                        },
                        lifecycle_acquisitions: {
                            id: 'lifecycle_acquisitions',
                            label: 'Acquisitions',
                            functionality: 'core'
                        },
                        lifecycle_dispositions: {
                            id: 'lifecycle_dispositions',
                            label: 'Dispositions',
                            functionality: 'core'
                        },
                        lifecycle_planning: {
                            id: 'lifecycle_planning',
                            label: 'Planning',
                            functionality: 'core'
                        },
                        lifecycle_fdp: {
                            id: 'lifecycle_fdp',
                            label: 'Final Disposition Planning',
                            functionality: 'core'
                        },
                    }
                },
            }
        }
    }
}
</script>

