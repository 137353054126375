<template>
    <div class="row">
        <p v-if="this.item_type !== 'users'">Modules that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

        <p v-else>Modules that&nbsp;<a :href="base_url +'/'+ item_info.group + '/users/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>


        <div class="col-lg-12">

            <div class="text-sm-right">
                            <span :id="this.items.length === 0 && this.selected_owner_id === null ? 'disabled-wrapper' : 'enabled-wrapper'">
                                <b-button :disabled="this.items.length === 0 && this.selected_owner_id === null" v-b-modal.modules-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign Module</b-button>
                            </span>

                <b-tooltip target="disabled-disabled-wrapper" placement="left" variant="info">No modules to assign.</b-tooltip>

                <b-modal id="modules-modal"
                         ref="assign-module-modal"
                         title="Assign Module"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-module
                        ref="assign-to-module"
                        :items="item_info.item_type === 'users' && item_info.current_page_type === 'assteflow' ? null : this.items"
                        :item_info="item_info"
                        @assigned-module="assignToModule"
                        @changed-owner="changeOwner"
                    ></assign-to-module>

                </b-modal>
            </div>
        </div>

        <div class="col-lg-12">
            <po-lines
                :items="modulesData"
                :current_page_type="item_info.current_page_type"
                :object_type="'modules'"
                :keys_list="module_list_keys_list">
                <template v-slot:module_actions="{ item, index }">
                    <a v-if="hasPermission('browse_modules')" :href="base_url +'/'+ item_info.group + '/modules/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                    <a v-if="hasPermission('edit_modules') && !item.roles_count" v-on:click="unAssignFromModule($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                </template>
            </po-lines>
        </div>

    </div>
</template>

<script>
    import SearchBox from "@/components/search-box";
    import Resource from '@/api/resource';

    import AssignToModule from "../forms/AssignToModule";
    import PoLines from "./detailsViews/PoLines";
    import {ModuleKeysList} from "@/views/assetflow/form_data/ModuleKeysList";

    export default {
        name: "ListModules",
        components: {PoLines, AssignToModule, SearchBox},
        props: {
            item_info: Object,
        },
        data() {
            return {
                module_list_keys_list: ModuleKeysList,
                base_url: "",
                modulesData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 20
                },
                assignData: {
                    owner_id: "",
                    module_id: ""
                },
                selected_owner_id: null,
                has_module_actions_permissions: null
            }
        },
        created() {

            this.has_module_actions_permissions = this.hasPermission('browse_modules');

            if (this.has_module_actions_permissions){
                this.module_list_keys_list.actions = {
                    key: 'actions',
                    label: "",
                    class: "col-lg-1 c_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'module_actions'
                }
            }

            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;
            this.getList();
            this.getModulesList();
        },
        methods: {
            getList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/modules' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20,
                            current_page_type: this.item_info.current_page_type
                        },
                    }).then(response => {
                        this.listLoading = false;
                        this.modulesData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            changeOwner(owner_id='', item_type=''){

                this.getModulesList(owner_id, item_type);
                this.selected_owner_id = owner_id;
            },
            assignToModule(module_id, roles_list=[]){
                this.$refs['assign-module-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: module_id,
                    item_type: 'modules',
                    selected_owner_id: this.selected_owner_id,
                    roles_list: roles_list
                };

                this.assign_unassign_module();

                console.log("should assign user to module " + module_id + this.item_id);
            },
            assign_unassign_module() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_module',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getModulesList();
                        if (this.assignData.roles_list){
                            this.$set(this.item_info, 'refresh_roles_list', true);
                        }
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromModule(event, module_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: module_id,
                    item_type: 'modules',
                    unassign: true,
                    selected_owner_id: this.selected_owner_id
                };
                this.assign_unassign_module();
                console.log("should unassign user from module >> " + module_id);

            },
            async getModulesList(owner_id='', item_type='') {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;
                this.listQuery.current_page_type = this.item_info.current_page_type;

                if (owner_id !== '') {
                    this.listQuery.owner_id = owner_id;
                    this.listQuery.owner_item_type = item_type;
                }
                const moduleResource = new Resource('api/assetflow/modules');

                const { data } = await moduleResource.list(this.listQuery);


                this.items =  data.data.items.filter((module) => {
                    return !this.modulesData.map(x => (x.hasOwnProperty('module_id') ? x.module_id : x.id)).includes(module.id);
                });

                if (this.$refs['assign-to-module']){
                    this.$refs['assign-to-module'].refreshModulesList(this.items);
                }

                this.listLoading = false;
            },
        }
    }
</script>


