<template>
    <b-modal :id="modal_id"
             title="Change Department"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             @show="setDefaultValues"
             hide-footer>


        <AddNewDepartment
            ref="add-new-department"
            item_type="department"
            modal_title="Create New Department"
            :without_status="true"
            @on-new-item-adding="addNewDepartment"
        />

        <div class="common-step-panel use-section">
            <div class="row">

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :required_fields="required_fields"
                        :received_toggle="section_group.minimized">

                        <StepCommonInfoBlock
                            :add-form="addForm"
                            :block_key="key"
                            :item_info="item_info"
                            :changed-radio-value="changedRadioValue"
                            :mark-as-checked="markAsChecked"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :my_lists_values="lists_values"
                            :without_asset_passport_name="true"
                        />

                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'update-department' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="item_info && item_info.hasOwnProperty('name') ? ('Change Department of {' + item_info.name + '}') : 'Save'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>

    </b-modal>

</template>
<script>

import AFButton from "@/components/AFButton";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetInfoSection from "./AssetInfoSection";
import StepCommonInfoBlock from "../../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";
import AddNewDepartment from "../../customers/extras/AddNewDepartment";
export default {
    components: {AddNewDepartment, StepCommonInfoBlock, AssetInfoSection, AFButton},
    mixins: [dropzoneMixin],
    name: 'UpdateDepartmentModal',
    props: ['received_item_info', 'modal_id', 'item_type', 'is_list'],
    data(){
        return {
            item_info: null,
            addForm: {
                asset_meta: {},
                item_details: {},
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {
                        department_section: {
                            name: 'Department',
                            minimized: false,
                            fields: [
                                'department_id'
                            ]
                        },
                    }
                },
            },
            required_fields: {
                department_id: {
                    name: 'department',
                    name_plural:'departments',
                    link_name:'departments',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                    label: 'Changing a department does not change Acquisitions, Dispositions or affect category, tags, or folders.',
                },
            }
        }
    },
    created() {
        this.getFieldsList();
        this.item_info = this.received_item_info;
    },
    computed: {
    },
    watch: {
    },
    methods:{

        addNewDepartment(new_department){
            this.required_fields.department_id.options.push(new_department);
        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                department_id: 'user_departments_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

                if (!this.hasPermission('add_departments')){
                    delete this.required_fields.department_id.options[0];
                }
                this.setDefaultValues();
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        set_item(item){
            this.item_info = item;
            this.setDefaultValues();
        },
        setDefaultValues() {

            let addForm = this.addForm;

            _.forOwn(this.required_fields, function (field, key) {
                if (field.hasOwnProperty('default_value')) {
                    addForm.item_details[key] = field.default_value;
                }
            });

            if (this.item_info){
                if (this.item_info.hasOwnProperty('asset_meta')){
                    addForm.asset_meta = {...this.item_info.asset_meta};
                }
                addForm.item_details.department_id = this.getObjectOf(this.item_info.department_id, this.required_fields.department_id.options);
            }
            this.addForm.item_details = Object.assign({}, addForm.item_details);
        },
        changedRadioValue(field, event) {
            let selected_value = this.addForm.item_details[field];
            if (field === 'department_id' && selected_value.id === -99){
                this.$refs['add-new-department'].setSelectedItem(null);
                this.addForm.item_details[field] = null;
                this.$bvModal.show('create-'+field.replace('_id', ''));
            }
        },
        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        async addItem(event){

            this.isLoadingItem = true;

            let item_details_values = {
                department_id: this.addForm.item_details.department_id ? this.addForm.item_details.department_id.id : null
            };

            let asset_id = this.item_info.id;

            let params = {
                asset_id: asset_id,
                asset_meta: this.addForm.asset_meta,
                item_details: item_details_values
            };

            axios({
                method: 'put',
                url: '/api/assetflow/assets/' + asset_id,
                baseURL: '/',
                data: params,
            }).then(response => {
                this.isLoadingItem = false;
                if (response.data.data.status === true) {
                    let added_item = response.data.data.items;
                    let message;

                    if (added_item && added_item.hasOwnProperty('id')){
                        let asset_name = ', ASS-' + added_item.uid + ' {' + added_item.name + '}';
                        message = 'Done' + asset_name;
                        if (asset_id !== '') {
                            message = 'Updated Department Successfully' + asset_name;
                        }

                        let status = response.data.data.status;
                        this.presentToast("Success!",
                            message,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);

                        let required_keys = [
                            'department_id',
                            'department_info'
                        ]


                        this.$emit('on-cost-updates', this.modal_id, added_item, required_keys);

                    }else{
                        console.log(added_item);
                    }

                } else {
                    console.log('Something went wrong');
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

    }
}
</script>
