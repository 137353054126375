const FirstPath = window.location.pathname.split('/')[1];
export const ChangeKeysList = {
    character_info: {
        key: 'character_info',
        label: "Character",
        class: "col-lg-2 c_character_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let character_image = '';
            let character_name = '';
            if (item.hasOwnProperty('character_info') && item.character_info){
                let character_info = item.character_info;

                if (character_info.hasOwnProperty('avatar_name') && character_info.avatar_name !== ''){
                    character_image = '<img class="rounded-circle header-profile-user" src="' + character_info.avatar_name +'" alt="' + character_info.name + '"/>';
                }

                character_name = '<span class="character">' + '('+character_info.character_number+') ' + character_info.name + '</span>';
                return character_image + ' <a class="text-dark" href="/' + FirstPath + '/characters/'+character_info.id+'/view">' + character_name + '</a>';
            }
            return '';
        }
    },
    name: {
        key: 'name',
        label: "Change #",
        class: "col-lg-1 c_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/changes/'+item.id+'/view"><span class="change">' + item.name +'</span></a>';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 c_status",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {

            let status_class = 'planned';
            let button_status_class = 'planned';

            if (item.status === 1){
                status_class = 'established';
                button_status_class = 'active';
            }
            return '<span class="button_size status-btn-' + button_status_class + ' ' + status_class + '-change-status">' + (item.status === 1 ? 'Established' : 'Planned') + '</span></div>';
        }
    },
    description: {
        key: 'description',
        label: "Description",
        class: "col-lg-5 c_description",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets",
        class: "col-lg-1 c_assets_count",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('assets_count') ? item.assets_count : '-';
        }
    },
    tasks_count: {
        key: 'tasks_count',
        label: "Assets",
        class: "col-lg-1 c_tasks_count",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.hasOwnProperty('tasks_count') ? item.tasks_count : '-';
        }
    },
    actions: {
        key: 'actions',
        label: "",
        class: "col-lg-1 c_actions",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_class: '',
        slot_name: 'changes_actions'
    }
};
