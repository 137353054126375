<template>
    <div>
        <div class="product-filter-option-wrapper clearfix d-flex">
            <div class="filter-wrap d-flex align-items-center ml-auto">
                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>
            </div>
        </div>

        <div v-if="subscribersData.length === 0">
            No Subscribers{{order.status === 0 ? ' Yet' : ''}}. <a v-if="order.status === 0 && hasPermission('browse_users') && hasPermission('manage_orders')" @click="shallSetSubscribers()" href="#">Add One?</a>
        </div>
        <div v-else-if="subscribersData" class="cm_list_view_wrapper">
            <div>
                <po-lines
                    :items="sortedItemsList"
                    :current_page_type="order.current_page_type"
                    object_type="users"
                    line_type="user"
                    :keys_list="subscriber_list_keys_list">
                </po-lines>
            </div>
        </div>

    </div>
</template>
<script>
import {SubscriberKeysList} from "@/views/assetflow/form_data/SubscriberKeysList";
import PoLines from "../widgets/detailsViews/PoLines";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";

import ImageGallery from "@/mixins/ImageGallery.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'OrderSubscribersList',
    components: {LotsOfItemsAlert, PoLines},
    props: ['order', 'shallSetSubscribers'],
    mixins:[ImageGallery],
    data(){
        return {
            permission_name: 'subscribers',
            object_type: 'subscribers',
            subscribersData:[],
            subscriber_list_keys_list: SubscriberKeysList,

            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
        }
    },
    created() {
        this.sorting.sortOptions.splice(0,3);
        let user_meta = window.USER_INFO.user_meta;
        if (user_meta.hasOwnProperty('subscriber_preferred_sorting')) {
            this.sorting.sortType = user_meta.subscriber_preferred_sorting;
        }
    },
    computed:{
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    mounted(){
        this.getSubscriberList();
    },
    methods:{
        sortItemsList(){
            return this.subscribersData.sort(this.sortFunction(this.sorting.sortType));
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('subscriber_preferred_sorting', sortType, message);
            this.sortItemsList();
        },
        async getSubscriberList() {
            if (this.order.hasOwnProperty('subscribers')){
                this.subscribersData = this.order.subscribers;
            }else{
                this.subscribersData = [];
            }
        },
    }
}
</script>
