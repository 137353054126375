<template>
    <Layout>
        <add-new-order-line
            ref="add-new-activity_log"
            item_type='activity_log'
            modal_title="Create New Order Line"
            @on-new-item-adding="getList"
        >
        </add-new-order-line>

        <div class="activity_logs-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Activity Logs List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Activity Logs.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('activity_log')">Order Line
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ (request_meta ? request_meta.total : 0) }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                    </div>


                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Activity...</h4>
                    </div>
                    <div v-else-if="!activity_logsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Activity Logs found here at the moment.</h4>
                    </div>
                    <div v-else-if="selectedLayout === 1" class="row">
                        <div class="col-lg-12">
                            <!-- Richard Created Asset 54 Html Strat -->
                            <div class="richard-text_block">
                                <div v-for="item_post in sortedItemsList" class="personal_details">

                                    <div class="media media_b_a">
                                        <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                            <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                        </div>

                                        <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                        <div class="media-body personal_media-body">
                                            <h6 class="personal_details_title">
                                                {{ item_post.user.name }} <span v-html="getItemPostTitle(null, item_post)" ></span>
                                            </h6>
                                            <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                        </div>
                                    </div>

                                    <my-post-content :item_post="item_post">
                                        <template v-slot:post_description="{ item }">
                                            <div>
                                                <div v-if="item_post.hasOwnProperty('post_meta') && item_post.post_meta" class="added-asset-info">
                                                    <p v-html="item.description.replace(/ITEM_TYPE/g, item_type)"></p>
                                                    <div v-for="(character_meta_key, key) in activity_meta_keys[item_post.item_class.replace('App\\', '')]"
                                                         :key="key"
                                                         v-if="item_post.post_meta.hasOwnProperty(key)"
                                                    >
                                                        <span class="post-text-heading">{{ character_meta_key.label }}</span>
                                                        <span class="post-text-value" v-html="character_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <p v-html="item.description.replace(/ITEM_TYPE/g, item_type)"></p>
                                                </div>
                                            </div>
                                        </template>
                                    </my-post-content>

                                </div>
                            </div>
                            <!-- Richard Created Asset 54 Html End -->
                        </div>
                    </div>

                    <!-- Product Section Start-->
                    <div v-else-if="selectedLayout === 0 && activity_logsData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="permission_name"
                                :keys_list="asset_posts_list_key_list">
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewOrderLine from "../extras/AddNewOrderLine";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import MyPostContent from "../../items/widgets/detailsViews/PostContentTypes/MyPostContent";

const activity_logResource = new Resource('api/assetflow/activity_logs');

import {ChangeMetaKeys} from "@/views/assetflow/form_data/ChangeMetaKeys";
import {AcquisitionMetaKeys} from "@/views/assetflow/form_data/AcquisitionMetaKeys";
import {CharacterMetaKeys} from "@/views/assetflow/form_data/CharacterMetaKeys";
import {DispositionMetaKeys} from "@/views/assetflow/form_data/DispositionMetaKeys";
import {LedgerMetaKeys} from "@/views/assetflow/form_data/LedgerMetaKeys";
import {PostMetaKeys} from "@/views/assetflow/form_data/PostMetaKeys";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'ActivityLogList',
    props: ['item_id', 'item_type'],
    components: {LotsOfItemsAlert, MyPostContent, ObjectsSidebar, PoLines, AddNewOrderLine, SearchBox, Layout},
    mixins: [TimelineCommons, ObjectsSidebarShared],
    data() {
        return {
            listLoading: false,
            total: 0,
            activity_logsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Activity Logs",
            items: [
                {
                    text: "Activity Logs",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_activity_log_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'activity_logs',

            object_type: 'activity_logs',
            groupBy_options: [
                {
                    id: 0,
                    label: 'Objects',
                    backend_key: 'item_class'
                },
                {
                    id: 1,
                    label: 'Users',
                    backend_key: 'user_id'
                }
            ],
            items_list: [
                // Objects
                [
                    {
                        id: 'App\\Asset',
                        label: 'Assets',
                        key: 'assets'
                    },
                    {
                        id: 'App\\Acquisition',
                        label: 'Acquisitions',
                        key: 'acquisitions'
                    },
                    {
                        id: 'App\\Disposition',
                        label: 'Dispositions',
                        key: 'dispositions'
                    },
                    {
                        id: 'App\\Character',
                        label: 'Characters',
                        key: 'characters'
                    },
                    {
                        id: 'App\\Change',
                        label: 'Changes',
                        key: 'changes'
                    },
                    {
                        id: 'App\\Part',
                        label: 'Parts',
                        key: 'parts'
                    },
                    {
                        id: 'App\\PartChange',
                        label: 'Scene Notes',
                        key: 'part_changes'
                    }
                ],
                // Users
                []
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'users'
                },
            ],
            activity_meta_keys: {
                Change: ChangeMetaKeys,
                Acquisition: AcquisitionMetaKeys,
                Character: CharacterMetaKeys,
                Disposition: DispositionMetaKeys,
                Ledger: LedgerMetaKeys,
                Asset: PostMetaKeys,
            }
        };
    },
    created() {

        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
            },
            grid: {
                name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
            }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        let getItemPostTitle = this.getItemPostTitle;
        this.asset_posts_list_key_list.action_title.value_func = function (index, item, key) {
            if (getItemPostTitle){
                let desc = Vue.options.filters.capitalize(getItemPostTitle(item.item_info, item));
                return desc.length > 90 ? desc.substr(0, 100) + '..' : desc;
            }
            return '';
        }

        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.asset_posts_list_key_list.customer_info.label = 'Production';
            }
            delete this.asset_posts_list_key_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.asset_posts_list_key_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_activity_logs_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.activity_log_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.activity_log_preferred_sorting;
        }



        this.getList(this.selected_item);
    },
    computed: {
        totalAssetsValue() {
            return this.activity_logsData ? this.activity_logsData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        checkOrderLineStatus(activity_log){
            return parseInt(activity_log.assets_sum_quantity) !== 0;
        },
        performAction(activity_log, action, activity_logKey){
            if (action === 'edit'){
                this.$refs['add-new-activity_log'].setSelectedItem(activity_log);
                this.$bvModal.show('create-activity_log');
            }else if (action === ('delete'+this.permission_name)){
                if (activity_log.id !== '' && activity_log.activity_log_lines_count === 0){
                    this.deleteItemByID(activity_log.id, activity_log.name, this.object_type, activity_log)
                        .then((response) => {
                            if (response.data.data.status){
                                this.activity_logsData.splice(activity_logKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, activity_log) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['name', 'item_info.name', 'customer_info.name', 'sp_info.name'];
                    return this.handleSearch(searchable_values, element, wordToCompare);
                }
                filtered_list = this.activity_logsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.activity_logsData;
            }

            return filtered_list; //.sort(this.sortFunction(this.sorting.sortType));
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('activity_log_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_activity_logs_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await activity_logResource.list(required_query);
            this.total = data.meta.total;
            this.activity_logsData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },

        addNewItem(activity_log_type) {
            this.$refs['add-new-activity_log'].setSelectedItem(null);
            this.$bvModal.show('create-activity_log');
        }
    },
}
</script>


