const FirstPath = window.location.pathname.split('/')[1];
export const ActivityLogKeysList = {
    user_id: {
        key: 'user_id',
        label: "Created By",
        class: "col-lg-2 a_user_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<img src="'+(item.user.hasOwnProperty('avatar_url') ? item.user.avatar_url : item.user.avatar)+'" alt="'+item.user.name+'" class="rounded-circle item-post-image" width="25" height="25"> <a href="/' + FirstPath + '/users/'+item.user.id+'/view">' + Vue.options.filters.titleize(item.user.name) + '</a>';
        }
    },
    action_type: {
        key: 'action_type',
        label: "Action",
        class: "col-lg-1 a_action_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let action = '';
            switch (item[key]){
                case 2:
                case 17:
                    action = 'New';
                    break;
                default:
                    action = 'Update';
                    break;
            }
            return '<span class="asset-action asset-action-'+(action.toLowerCase())+'">' + action +'</span>';
        }
    },
    action_title: {
        key: 'action_title',
        label: "Title",
        class: "col-lg a_action_title",
        show_on_mobile: false,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {
            let desc = item.description.replace(/<\/?[^>]+(>|$)/g, "");
            return (desc.length > 90 ? (desc.substr(0, 100) + '..') : desc);
        },
        value_func: function (index, item, key) {
            return '';
        }
    },
    no_of_images: {
        key: 'no_of_images',
        label: "No of Images",
        class: "col-lg-1 a_no_of_images text-center",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.post_images ? item.post_images.length : 0;
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-2 a_created_at",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.moment(item.created_at, 'DD/MM/YYYY') + ' (' + Vue.options.filters.fromNow(item.created_at) + ')';
        }
    },
};
