<template>
    <b-modal :id="modal_id"
             title="Import from File"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             hide-footer>

        <div class="common-step-panel use-section">
            <div class="row">

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :received_toggle="section_group.minimized">

                        <div class="step-common-info-right-block" v-if="key === 'image_upload_section'">
                            <div class="st-drop-file-block">
                                <div class="st-drop-file-box">

                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="fileDropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-removed-file="fileRemoved"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <div class="st-upload-title">
                                                <h3>Drop files here or click to upload</h3>
                                                <p>... or click to select a file from your computer</p>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone[0].$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New File</b-button>
                                </div>
                            </div>
                        </div>
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'import-from-file' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="'Import'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>

    </b-modal>

</template>
<script>

import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";
import AssetInfoSection from "../assets/assetForm/AssetInfoSection";
export default {

    components: {AssetInfoSection, AFButton},
    mixins: [dropzoneMixin],
    name: 'ImportFromFile',
    props: ['modal_id'],
    data(){
        return {

            fileDropzoneOptions: {
                url: "required_url",
                acceptedFiles: "application/pdf,.xls,.xlsx,.csv",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.file_template()
            },
            item_info: null,
            addForm: {
                asset_images: [],
                filled_asset_images: [],
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {
                        image_upload_section: {
                            name: 'File Upload',
                            minimized: false,
                            fields: {}
                        },
                    }
                },
            },
        }
    },
    created() {
    },
    methods:{
        file_template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                            <div class="dz-details">
                                <div class="dz-image_title">
                                    <div class="common--input-filed">
                                        <input type="text" data-dz-image_title name="image_title" placeholder="File Title" />
                                    </div>
                                </div>
                                <div class="dz-image_description">
                                    <div class="common--input-filed">
                                        <textarea data-dz-image_description name="image_description" placeholder="File Description"></textarea>
                                    </div>
                                </div>

                                <div class="dz-size">
                                    <label><input type="checkbox" data-dz-is_featured name="is_featured[]" /> Is Featured?</label>
                                </div>
                                <div class="dz-size">
                                    <label><input type="checkbox" data-dz-add_to_ap name="add_to_ap[]" /> Add to Asset Passport?</label>
                                </div>
                            </div>
                            <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                            <a class="dz-remove btn btn btn-sm mt-1 btn-block btn-outline-danger" data-dz-remove="">Remove file</a>
                        </div>`;
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        async addItem(event){
            // logic goes here
        },

    }
}
</script>
