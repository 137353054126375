<template>
    <div class="lots-of-items-alert">
        <div class="alert alert-danger" role="alert" v-if="[50, 100, 200].includes(perpage.perPage)">
            You are viewing {{ perpage.perPage }} items per page, intended for browsing galleries or large lists when needed. This will affect performance so remember to switch to 10 or 25 items where possible.
        </div>
    </div>
</template>
<script>
export default {
  name: 'LotsOfItemsAlert',
  props: {
    perpage: {}
  }
}
</script>
