<template>
    <div v-if="['sp'].includes(item_info.current_page_type) && tabs[0].type === 'customer_details'">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab v-for="(customer_tab, customer_index) in customer_tabs" :key="customer_index" :active="customer_tab.isActive">
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none" v-iconize-icon="customer_tab.name.toLowerCase()"></span>
                    <span class="d-none d-sm-inline-block" v-iconize="customer_tab.name.toLowerCase()">{{ customer_tab.name }}</span>
                </template>
                <div v-if="customer_index === 'entity_subscribers'">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-sm-right">
                                <template>
                                    <span>
                                        <b-button v-b-modal.users-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign User</b-button>
                                    </span>
                                    <b-modal id="users-modal"
                                             ref="assign-user-modal"
                                             title="Assign User"
                                             title-class="font-18"
                                             hide-footer>
                                        <AddNotifiedUser
                                            ref="assign-to-notified-user"
                                            :item_info="item_info"
                                            item_type="sp"
                                            :is_customer="true"
                                            :item_id="current_sp.id+''"
                                            @refresh-users-list="reloadPage()"
                                        />
                                    </b-modal>
                                </template>
                            </div>
                        </div>
                    </div>
                    <EntitySubscribersList :item_info="item_info" @refresh-users-list="reloadPage()"/>
                </div>
                <EntityDashboardBlock v-else-if="customer_index === 'overview'" :item_info="item_info" :tabs="tabs"/>
            </b-tab>
        </b-tabs>
    </div>
    <EntityDashboardBlock v-else-if="tabs.length >= 1 && ['customer_details', 'group_details', 'sps_details'].includes(tabs[0].type)" :item_info="item_info" :tabs="tabs"/>
    <div v-else>
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab v-for="(tab, index) in tabs" :key="index" :active="tab.isActive">
                <template v-slot:title>
                    <span class="d-inline-block d-sm-none" v-iconize-icon="tab.name.toLowerCase()"></span>
                    <span class="d-none d-sm-inline-block" v-iconize="tab.name.toLowerCase()">{{ tab.name }}</span>
                </template>

                <address-details v-if="tab.type === 'address_details'" :item_info="item_info" :address="item_info"></address-details>

                <purchase-order-details v-else-if="tab.type === 'purchaseOrder_details'" :po="item_info"></purchase-order-details>

                <supplier-details v-else-if="tab.type === 'supplier_details'" :supplier="item_info"></supplier-details>
                <list-locations v-else-if="tab.type === 'locations_list'" :item_info="item_info" :locations="item_info.locations"></list-locations>

                <list-entity-tab v-else-if="['sps', 'modules', 'roles', 'users', 'customers', 'sp_customers', 'departments'].includes(tab.type)" :item_info="item_info" :tab="tab"/>

                <list-assets-new v-else-if="['assets', 'ag_assets'].includes(tab.type)" :item_info="item_info"></list-assets-new>
                <list-permissions v-else-if="tab.type === 'permissions'" :item_info="item_info"></list-permissions>

                <sustainability-tab v-else-if="tab.type === 'sustainability_tab'" :item_info="item_info"></sustainability-tab>

                <RoleModuleDetails v-else-if="tab.type === 'role_module_details'" :item_info="item_info"/>

                <item-details v-else-if="['user_details', 'department_details', 'role_details', 'permission_details', 'customer_sp_relation', 'related_items',  'listing_preview', 'po_timeline', 'po_approvals', 'po_assets'].includes(tab.type)" :tab_name="tab.type" :item_info="item_info"></item-details>

            </b-tab>
        </b-tabs>
    </div>

</template>


<script>
import ItemDetails from "./ItemDetails";
import ListPermissions from "./ListPermissions";
import ListLocations from "./ListLocations";
import AddressDetails from "./detailsViews/AddressDetails";
import SustainabilityTab from "./detailsViews/AssetTabs/SustainabilityTab";
import SupplierDetails from "./detailsViews/SupplierDetails";
import PurchaseOrderDetails from "./detailsViews/PoDetails";
import ListAssetsNew from "./ListAssetsNew";
import RoleModuleDetails from "./RoleModuleDetails";
import EntityDashboardBlock from "./ModuleDashboards/EntityDashboardBlock";
import ListEntityTab from "./ModuleDashboards/ListEntityTab";
import EntitySubscribersList from "./EntitySubscribersList";
import AddNotifiedUser from "../../customers/orders/AddNotifiedUser";

export default {
    name: "ItemTabs",
    components: {
        AddNotifiedUser,
        EntitySubscribersList,
        ListEntityTab,
        EntityDashboardBlock,
        RoleModuleDetails,
        ListAssetsNew,
        PurchaseOrderDetails,
        SupplierDetails,
        SustainabilityTab,
        AddressDetails,
        ListLocations,
        ListPermissions,
        ItemDetails,
    },
    props: {
        item_info: Object,
        tabs: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data(){
        return {
            customer_tabs: {
                overview:{
                    name: 'Overview',
                    isActive: true,
                },
                entity_subscribers:{
                    name: 'Order Notifications',
                    isActive: false,
                },
            }
        }
    }
}
</script>
