<template>
    <div>
        <h4> {{ menu_title !== '' ? menu_title : 'Bulk'}}</h4>
        <div class="row customer-list">
            <div class="col-lg-4 col-xl-4 text-center" @click="revealExtraContent('remove_order_lines')">
                <div class="card selected_card" :class="{active : required_action === 'remove_order_lines'}">
                    <div class="card-body">
                        <h5 class="card-title">Remove All Order Lines <small class="coming_soon text-af-accent">(soon)</small></h5>
                    </div>
                </div>
            </div>
        </div>


        <div class="row" v-show="required_action">
            <div class="col-lg-12">
                <div class="card" v-show="required_action === 'remove_order_lines'">
                    <div class="card-body">
                        <p>Set Extra Information for the action.</p>
                        <ol>
                            <li style="list-style: unset;">Notify Subscribers?</li>
                            <li style="list-style: unset;">Order Status?</li>
                            <li style="list-style: unset;">Order Line Status?</li>
                        </ol>

                        <b-button
                            class="af-btn-accent af-accent"
                            @click="performAction(required_action)">{{ required_action | titleize }}</b-button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'SpItemBulkUpdatesTab',
    props: {
        menu_title: String
    },
    data(){
        return {
            required_action: null
        }
    },
    methods: {
        revealExtraContent(action_name){
            if (this.required_action === action_name){
                this.required_action = null;
                return;
            }
            return; //
            // switch (action_name) {
            //     case 'remove_order_lines':
            //         this.required_action = action_name;
            //         break;
            // }
        },
        performAction(action_name){
            return;
            // switch (action_name) {
            //     case 'remove_order_lines':
            //         this.$emit('on-bulk-action', action_name);
            //         break;
            // }
        },

    },
}
</script>
