<template>
    <Layout>
        <add-new-module
            ref="add-new-module"
            item_type='module'
            modal_title="Create New Module"
            @on-new-item-adding="getList"
        >
        </add-new-module>

        <AddNewSearchableItems
            ref="add-new-searchable_items"
            item_type="searchable_items"
            modal_title="Set Searchable Objects"
            @on-new-item-adding="getList"
        >
        </AddNewSearchableItems>

        <SetModuleDashboardTemplate
            ref="add-new-dashboard_template"
            item_type="dashboard_template"
            modal_title="Set Dashboard Template"
            @on-new-item-adding="getList"
        >
        </SetModuleDashboardTemplate>

        <AddNewModuleNavigations
            ref="add-new-module_navigations"
            item_type="module_navigations"
            modal_title="Set Module Navigations"
            @on-new-item-adding="getList"
        >
        </AddNewModuleNavigations>

        <div class="modules-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title"><span v-if="current_entity" class="current_entity_name" :class="current_entity.entity_type + '-entity'">{{ current_entity.name }}</span> Module List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Modules.."></search-box>
                            <b-dropdown v-if="hasPermission('add_modules')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_modules')"
                                                 @click="addNewItem('module')">Module
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">



                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->

                    <div v-if="modulesData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'modules'"
                                :keys_list="module_list_keys_list">
                                <template v-slot:modules_actions="{ item, index }">
                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">

                                            <b-dropdown-item v-if="hasPermission('set_searchable_items')"
                                                             @click="performAction(item, 'set_searchable_items', index, $event)">
                                                Set Searchable Objects
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('set_module_navigations')"
                                                             @click="performAction(item, 'set_module_navigations', index, $event)">
                                                Set Module Navigations
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('set_dashboard_template')"
                                                             @click="performAction(item, 'set_dashboard_template', index, $event)">
                                                Set Dashboard Template
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('edit_modules')"
                                                             @click="performAction(item, 'edit', index, $event)">
                                                Edit
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('delete_modules')"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete', index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>
                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <div v-if="!modulesData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Modules found here at the moment.</h4>

                    </div>
                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";

import SearchBox from "@/components/search-box";
import AssetActions from "@/mixins/assetActions.mixin";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../items/widgets/detailsViews/PoLines";
import AddNewModule from "./AddNewModule";
import AddNewSearchableItems from "./AddNewSearchableItems";
import AddNewModuleNavigations from "./AddNewModuleNavigations";
import SetModuleDashboardTemplate from "./SetModuleDashboardTemplate";

const moduleResource = new Resource('api/assetflow/modules');

import {DashboardTemplates} from "@/views/assetflow/form_data/DashboardTemplates";

export default {
    name: 'ModulesList',
    mixins: [AssetActions],
    props: ['item_id', 'item_type'],
    components: {
        SetModuleDashboardTemplate,
        AddNewModuleNavigations, AddNewSearchableItems, AddNewModule, PoLines, SearchBox, Layout},
    data() {
        return {
            module_list_keys_list:{
                id: {
                    key: 'id',
                    label: "ID",
                    class: "col-lg-2 m_uid",
                    show_on_mobile: true,
                    case: ''
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg m_name",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let module_name = '<a href="./modules/'+item.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.name) + '</a>';
                        return Vue.options.filters.iconize(module_name, 'modules');
                    }
                },
                roles_count: {
                    key: 'roles_count',
                    label: "Roles",
                    class: "col-lg-2 m_roles_count",
                    show_on_mobile: true,
                    case: ''
                },
                users_count: {
                    key: 'users_count',
                    label: "Users",
                    class: "col-lg-2 m_users_count",
                    show_on_mobile: true,
                    case: ''
                },
                module_template_info: {
                    key: 'module_template_info',
                    label: "Module",
                    class: "col-lg-1 m_module_template_info",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        if (item.module_template_info){
                            return '<a href="./modules/'+item.module_template_info.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.module_template_info.name) + '</a>';
                        }
                        return '-';
                    }
                },
                dashboard_template_info: {
                    key: 'dashboard_template_info',
                    label: "Dashboard",
                    class: "col-lg-1 m_dashboard_template_info",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        if (item.dashboard_template_id){
                            let found_item = _.find(DashboardTemplates, function(o) { return o.id === item.dashboard_template_id; });
                            if (found_item){
                                return found_item.name;
                            }
                        }
                        return '-';
                    }
                },
                entity_type: {
                    key: 'entity_type',
                    label: "Entity",
                    class: "col-lg-2 m_name",
                    show_on_mobile: true,
                    case: 'custom',
                    used_func: function (value){
                        return Vue.options.filters.titleize(value);
                    }
                },
                entities_count: {
                    key: 'entities_count',
                    label: "Entities",
                    class: "col-lg-2 m_entities_count",
                    show_on_mobile: true,
                    case: ''
                },
            },
            total: 0,
            modulesData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Modules",
            items: [
                {
                    text: "Modules",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'all_modules'
            },
            has_module_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },


        };
    },
    created() {
        this.has_module_actions_permissions = this.hasPermission('edit_modules') || this.hasPermission('delete_modules');

        if (this.has_module_actions_permissions){
            this.module_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-2 t_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_class: '',
                slot_name: 'modules_actions'
            }
        }

        if (!window.USER_INFO.isSuperAdmin){
            delete this.module_list_keys_list.module_template_info;
            delete this.module_list_keys_list.entity_type;
            delete this.module_list_keys_list.entities_count;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_modules_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.module_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.module_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        totalAssetsValue() {
            return this.modulesData ? this.modulesData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    methods: {
        checkModuleStatus(module){
            return parseInt(module.assets_sum_quantity) !== 0;
        },

        getModulesList(){
            this.getList();
        },
        performAction(module, action, moduleKey, event){
            if (action === 'set_searchable_items'){
                this.$refs['add-new-searchable_items'].setSelectedItem(module);
                this.$bvModal.show('create-searchable_items');
            }else if (action === 'set_dashboard_template'){
                this.$refs['add-new-dashboard_template'].setSelectedItem(module);
                this.$bvModal.show('create-dashboard_template');
            }else if (action === 'set_module_navigations'){
                this.$refs['add-new-module_navigations'].setSelectedItem(module);
                this.$bvModal.show('create-module_navigations');
            }else if (action === 'edit'){
                this.$refs['add-new-module'].setSelectedItem(module);
                this.$bvModal.show('create-module');
            }else if (action === 'delete'){
                if (module.id !== '' ){
                    this.deleteItemByID(module.id, module.name, 'modules', 'event')
                    .then((response) => {
                        if (response.data.data.status){
                            this.modulesData.splice(moduleKey, 1);
                        }else{
                            console.log("response.data");
                            console.log(response.data);
                        }
                    });
                }else{
                    this.presentToast("Failed!",
                        'Something went wrong.',
                        'danger',
                        3000,
                        true,
                        true);
                }
            }else if (action === 'empty'){
                this.header_message = 'Unmodule Assets of ';
                this.confirm_message = 'Are you sure you want to Un-module Assets of ';
                this.item_name = 'Module';
                this.unmoduleAssets(module,'modules');
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
             return this.modulesData;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('module_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_modules_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery);
            this.listQuery.s_keyword = this.search_keyword;
            const {data} = await moduleResource.list(this.listQuery);
            this.total = data.meta.total;
            this.modulesData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-module'].setSelectedItem(null);
            this.$bvModal.show('create-module');
        }
    },
}
</script>


