<template>
    <b-modal :id="received_modal_id"
             :title="modal_title + extra_title"
             size="xl"
             title-class="font-18"
             hide-footer>

        <div class="common-step-panel use-section">
            <SharedFormFieldsMultipleSections :item_info="item_info" :add-form="addForm" :item_type="item_type" :required_fields="required_fields" :section_fields="section_fields" :object_type="object_type" :required_select_lists="required_select_lists"/>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="saveAction">
                {{ modal_title }}
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(received_modal_id)">Cancel</b-button>
        </div>

    </b-modal>
</template>
<script>

import SharedFormFieldsMultipleSections from "../acquisitionForm/SharedFormFieldsMultipleSections";
export default {
    components: {SharedFormFieldsMultipleSections},
    mixins: [],
    name: 'AddAssetsModal',
    props: ['item_type', 'received_modal_id', 'modal_title', 'object_type'],
    data(){
        return {
            extra_title: '',
            item_info: {},
            addForm: {
                item_details: {}
            },
            section_fields:{
                create_assets_automatically:{
                    name: 'Automatically Create Assets',
                    minimized: false,
                    fields: [
                        'create_assets_automatically',
                    ]
                },
                assets:{
                    name: 'Assets',
                    minimized: false,
                    fields: [
                        'asset_name',
                        'create_assets_number_of_assets',
                        'create_assets_asset_quantities',
                        'asset_notes',
                    ]
                },
                create_assets:{
                    name: 'Acquisition\\Asset Specific Functionality',
                    minimized: false,
                    fields: [
                        'create_assets_asset_distribution'
                    ]
                },
                usage:{
                    name: 'Usage',
                    minimized: true,
                    fields: [
                        'create_assets_usage',
                        'create_assets_asset_usage',
                        'create_assets_is_hero',
                        'create_assets_is_asset',
                    ]
                },
                structure:{
                    name: 'Structure',
                    minimized: true,
                    fields: [
                        'create_assets_folder_id',
                        'create_assets_category_id',
                        'create_assets_tag_ids',
                    ]
                },
                assignment:{
                    name: 'Assignment',
                    minimized: true,
                    fields: [
                        'create_assets_department_id',
                        'create_assets_contact_id',
                        'create_assets_address_id',
                        'create_assets_location_id',
                    ]
                },
                fdp:{
                    name: 'FDP',
                    minimized: true,
                    fields: [
                        'create_assets_final_disposition_planning',
                        'create_assets_confirm_fdp'
                    ]
                },
            },
            required_fields: {

                /* AssetsStep */
                asset_name: {
                    name: 'Name',
                    type: 'text',
                    has_permission: true
                },
                asset_initial_quantity: {
                    name: 'Initial Qty',
                    type: 'number',
                    has_permission: true
                },
                asset_notes: {
                    name: 'Notes',
                    type: 'textarea',
                    has_permission: true
                },
                asset_state: {
                    name: 'Asset State',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 1, name: 'New' },
                        { id: 0, name: 'Used' }
                    ]
                },

                /* Create Assets */
                /* TODO: // Create Assets HERE */


                create_assets_automatically: {
                    name: 'Automatically Create Assets?',
                    type: 'checkbox',
                    has_permission: true
                },
                create_assets_number_of_assets: {
                    name: 'Quantity',
                    type: 'number',
                    has_permission: true
                },
                create_assets_asset_quantities: {
                    name: 'Asset Quantities',
                    type: 'single-select',
                    has_permission: true,
                    options:[
                        { id: 0, name: '1 Asset With Multiple Qty' },
                        { id: 1, name: 'Multiple Assets with Qty of 1' },
                    ]
                },
                create_assets_asset_distribution: {
                    name: 'Distribute Costs to Assets',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 'null', name: 'None' },
                        { id: 'auto', name: 'Auto' },
                        { id: 'manual', name: 'Manual' },
                        { id: 'proportional', name: 'Proportional' },
                    ]
                },
                create_assets_update_settings: {
                    name: 'Update Asset Settings?',
                    type: 'checkbox',
                    has_permission: true
                },
                create_assets_usage: {
                    name: 'Usage',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 'acquired', name: 'Acquired (Not Used)' },
                        { id: 'active', name: 'Active (In Use)' }
                    ]
                },
                create_assets_asset_usage: {
                    name: 'Asset usage',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 1, name: 'On Camera' },
                        { id: 0, name: 'Off Camera' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                create_assets_is_hero: {
                    name: 'Hero Item',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                create_assets_is_asset: {
                    name: 'Is Asset',
                    type: 'radio-select',
                    has_permission: true,
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                create_assets_folder_id: {
                    name: 'folder',
                    name_plural:'folders',
                    link_name:'folders',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_category_id: {
                    name: 'category',
                    name_plural:'categories',
                    link_name:'assets',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_tag_ids: {
                    name: 'tags',
                    name_plural:'tags',
                    link_name:'tags',
                    type: 'multi-select',
                    has_permission: true,
                    options:[],
                },

                create_assets_department_id: {
                    name: 'department',
                    name_plural:'departments',
                    link_name:'departments',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_contact_id: {
                    name: 'User',
                    name_plural:'Users',
                    link_name:'Users',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_address_id: {
                    name: 'Address',
                    name_plural:'Addresses',
                    link_name:'Addresses',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_location_id: {
                    name: 'Location',
                    name_plural:'Locations',
                    link_name:'Locations',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_final_disposition_planning: {
                    name: 'Final Disposition Planning',
                    type: 'single-select',
                    has_permission: true,
                    options:[],
                },
                create_assets_confirm_fdp: {
                    name: 'Confirm FDP Plan',
                    type: 'single-select',
                    has_permission: true,
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' }
                    ]
                },

            },
            required_select_lists: {
                create_assets_folder_id: 'folders_list',
                create_assets_category_id: 'categories_list',
                create_assets_tag_ids: 'tags_list',
                create_assets_department_id: 'departments_list',
                create_assets_contact_id: 'contacts_list',
                create_assets_address_id: 'addresses_list',
                create_assets_location_id: 'locations_list',
                create_assets_final_disposition_planning: 'final_disposition_planning'
            }
        }
    },
    created() {
    },
    methods:{
        saveAction(){
            this.$emit('onFormSubmit', 'add_assets', this.item_info, this.addForm, this.object_type, this.item_type, this.received_modal_id);
        },
        set_item(item){
            this.item_info = item;
            this.extra_title = ' to {' + item.uid + '}';
        }
    }
}
</script>
