<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item && selected_item.name ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                v-if="!required_field.is_hidden"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <div class="st-radio-row"
                    v-if="required_field.type === 'radio-select'">
                    <div v-for="(option, index) in required_field.options"
                         :key="index"
                         class="col-3">
                        <div class="cm-radio-btn" :class="'mt-1 '+required_field.key+'-' + option.id">
                            <label>
                                <input
                                    v-model="addForm[required_field.key]"
                                    :key="index"
                                    :id="'_'+index"
                                    :name="required_field.key"
                                    :value="option"
                                    @input="selectedRadioButton(required_field.key, option, $event)"
                                    type="radio" />
                                <div class="st-radio-box">
                                    <span></span>
                                    <p>{{ option.name | titleize }}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <p><small class="shall_fill_name" v-if="required_field.key === 'name' && shall_fill_name">Name field is required.</small></p>
                <p><small class="shall_fill_liability" v-if="required_field.key === 'ownership' && shall_fill_liability">Ownership field is required.</small></p>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item && selected_item.name ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const dispositionTypeResource = new Resource('api/assetflow/addDispositionType');
import multiSelect from '@/mixins/MultiSelect';
import AssetLocation from "@/mixins/AssetLocation";
export default {
    mixins: [multiSelect, AssetLocation],
    name: "AddNewDisposition",
    props: ['modal_id', 'modal_title', 'item_type', 'getCustomOptionsForKey', 'setField', 'getAssetInfoUsingKey'],
    data(){
        return {
            shall_fill_name: false,
            shall_fill_liability: false,
            addForm:{
                name: '',
                planning_name: null,
                disposition_category: null,
                has_date: null,
                has_amount: null,
                ends_ownership: null,
                has_movement: null,
                has_recipient: null,
                has_reference: null,
                contact_id: null,
                department_id: null,
                address_id: null,
                location_id: null,
            },
            required_fields:{
                name: {
                    key: 'name',
                    label: 'Disposition Name',
                    type: 'text',
                    is_hidden: false
                },
                planning_name: {
                    key: 'planning_name',
                    label: 'Planning Name',
                    type: 'text',
                    is_hidden: false
                },
                disposition_category: {
                    key: 'disposition_category',
                    label: 'Category',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
                has_date: {
                    key: 'has_date',
                    label: 'Has Date',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
                has_amount: {
                    key: 'has_amount',
                    label: 'Has Amount',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
                ends_ownership: {
                    key: 'ends_ownership',
                    label: 'End Ownership',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
                contact_id: {
                    key: 'contact_id',
                    label: 'Contact',
                    type: 'radio-select',
                    is_hidden: true,
                    options: []
                },
                department_id: {
                    key: 'department_id',
                    label: 'Department',
                    type: 'radio-select',
                    is_hidden: true,
                    options: []
                },
                address_id: {
                    key: 'address_id',
                    label: 'Address',
                    type: 'radio-select',
                    is_hidden: true,
                    options: []
                },
                location_id: {
                    key: 'location_id',
                    label: 'Location',
                    type: 'radio-select',
                    is_hidden: true,
                    options: []
                },
                has_movement: {
                    key: 'has_movement',
                    label: 'Has Movement',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
                has_recipient: {
                    key: 'has_recipient',
                    label: 'Has Recipient',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
                has_reference: {
                    key: 'has_reference',
                    label: 'Has Reference',
                    type: 'radio-select',
                    is_hidden: false,
                    options: []
                },
            },
            selected_item: null,
            list_disabled: null,
            assignment_keys: ['contact_id', 'department_id', 'address_id', 'location_id']
        }
    },
    created() {
        let yes_no_options = [
            { id: 1, name: 'Yes'},
            { id: 0, name: 'No'}
        ];

        this.required_fields.has_date.options = yes_no_options;
        this.required_fields.has_amount.options = yes_no_options;
        this.required_fields.ends_ownership.options = yes_no_options;
        this.required_fields.has_movement.options = yes_no_options;
        this.required_fields.has_recipient.options = yes_no_options;
        this.required_fields.has_reference.options = yes_no_options;
        this.required_fields.disposition_category.options = yes_no_options;
        if (this.getCustomOptionsForKey){
            this.required_fields.disposition_category.options = this.getCustomOptionsForKey('disposition_category');
            for (const assignmentKeysKey in this.assignment_keys) {
                let assignment_key = this.assignment_keys[assignmentKeysKey];
                if (assignment_key !== 'location_id'){
                    this.required_fields[assignment_key].options = this.getCustomOptionsForKey(assignment_key);
                }
            }
        }

    },

    computed:{
        ends_ownership(){
            return this.addForm.ends_ownership;
        }
    },
    watch:{
        ends_ownership:{
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue && (!oldVal || oldVal.id !== newValue.id)){

                    for (const assignmentKeysKey in this.assignment_keys) {
                        let assignment_key = this.assignment_keys[assignmentKeysKey];
                        if (assignment_key !== 'location_id'){
                            this.required_fields[assignment_key].is_hidden = newValue.id === 1;
                        }
                    }

                }
            }
        },
    },
    methods:{

        selectedRadioButton(field, option, event){
            if (field === 'address_id'){
                this.setSelectedLocationFor(option, true);
                if ([-98, -97].includes(option.id)){
                    this.required_fields.location_id.is_hidden = true;
                }else{
                    this.required_fields.location_id.is_hidden = false;
                    this.required_fields.location_id.options = this.getCustomOptionsForKey('location_id');
                }
            }
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-hiding-modal');
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.$emit('on-hiding-modal');
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.planning_name = this.selected_item.planning_name;
                this.addForm.disposition_category = this.selected_item.disposition_category;

                this.addForm.has_date = this.selected_item.has_date;
                this.addForm.has_amount = this.selected_item.has_amount;
                this.addForm.ends_ownership = this.selected_item.ends_ownership;
                this.addForm.has_movement = this.selected_item.has_movement;
                this.addForm.has_recipient = this.selected_item.has_recipient;
                this.addForm.has_reference = this.selected_item.has_reference;

            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            this.shall_fill_name = !this.addForm.name;
            this.shall_fill_liability = this.addForm.ownership_status === null;
            if (this.shall_fill_name || this.shall_fill_liability){
                return;
            }
            var params = {
                name: this.addForm.name,
                planning_name: this.addForm.planning_name,
                disposition_category: this.addForm.disposition_category ? this.addForm.disposition_category.id : 0,
                has_date: this.addForm.has_date ? this.addForm.has_date.id : 0,
                has_amount: this.addForm.has_amount ? this.addForm.has_amount.id : 0,
                ends_ownership: this.addForm.ends_ownership ? this.addForm.ends_ownership.id : 0,
                has_movement: this.addForm.has_movement ? this.addForm.has_movement.id : 0,
                has_recipient: this.addForm.has_recipient ? this.addForm.has_recipient.id : 0,
                has_reference: this.addForm.has_reference ? this.addForm.has_reference.id : 0,


                contact_id: this.addForm.contact_id ? this.addForm.contact_id.id : 0,
                department_id: this.addForm.department_id ? this.addForm.department_id.id : 0,
                address_id: this.addForm.address_id ? this.addForm.address_id.id : 0,
                location_id: this.addForm.location_id ? this.addForm.location_id.id : 0,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await dispositionTypeResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide(this.modal_id);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>


