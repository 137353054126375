<template>
    <Layout>
        <div class="acquisitions-wrapper" id="wrapper">

            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :received_filter_classes="filter_classes"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

                <!-- Page-Content start-->
                <div id="page-content-wrapper">
                    <div class="page-content-inner">
                        <div>
                            <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                        </div>

                        <div class="product-page-header">

                            <div class="main-root-top" v-if="has_departments">
                                <h3 class="filter_option header-department" :class="[0,1].includes(departments_list.length) ? 'single-option' : ''">{{ selected_department.name | titleize }}</h3>
                                <ul class="root-folder filters_list" style="display: none; padding: 15px;" v-if="departments_list.length !== 1">
                                    <li v-for="(department_item, index) in departments_list" :class="'root-top-menu' + (selected_department && selected_department.id === department_item.id ? ' active' : '')">
                                        <a @click="setSelectedDepartment(department_item)">
                                            {{ department_item ? department_item.name : '-' }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <span class="arrow-right-icon" v-if="has_departments"></span>
                            <div class="main-root-top">
                                <h3 class="header-selected-item">Acquisition List</h3>
                            </div>

                            <div class="filter-searchbar dropdown">
                                <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                            placeholder_text="Search Acquisitions.."></search-box>
                                <b-dropdown v-if="hasPermission('add_acquisitions')" dropleft
                                            class="add-filter" variant="danger">
                                    <template slot="button-content">
                                        <span class="add-filter-button"></span>
                                    </template>

                                    <b-dropdown-item v-if="hasPermission('add_assets') && hasPermission('add_acquisitions')" :href="'/' + item_type + '/acquisitions/new_acquisition'">
                                        Asset Receipt
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="hasPermission('add_acquisitions')"
                                                     href="./acquisitions/new">Other Acquisition Type
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="hasPermission('add_file_imports') && hasPermission('add_acquisitions')" :href="'/' + (item_type === 'sp' ? 'sp' : 'customer') + '/files/file-import-export?object_type=acquisition'">
                                        Import Acquisitions
                                    </b-dropdown-item>

                                </b-dropdown>
                            </div>
                            <div class="page-value-info">
                                <ul class="d-flex align-items-center">
                                    <li class="d-flex align-items-center">
                                        Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="current-page-filters">
                            <span v-if="selected_item && selected_item.id !== null && selected_item.id !== 0" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.nominal_code) | titleize }}</b></span>
                                <button type="button" class="close ml-1" @click="getList()"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>

                            <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                                <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                                <button type="button" class="close ml-1" @click="search_keyword = ''"><i class="text-white bx font-size-22 bx-x"></i></button>
                            </span>
                        </div>

                        <div class="sidebad-toggle af-accent"></div>
                        <div class="product-filter-option-wrapper clearfix d-flex">

                            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                    request_meta.from + '-' + request_meta.to
                                }}</span> of <span>{{ request_meta.total }}</span></div>

                            <div class="filter-wrap d-flex align-items-center ml-auto">


                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Sort: <b>{{ sorting.sortType.text }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in sorting.sortOptions"
                                        :key="option.value"
                                        @click="saveSorting(option, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>

                                <b-dropdown class="option" variant="outline-secondary">
                                    <template slot="button-content">
                                        Show: <b>{{
                                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                        }}</b>
                                        <i class="mdi mdi-chevron-down"></i>
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="option in perpage.pageOptions"
                                        :key="option.value"
                                        @click="savePerPage(option.value, $event)">
                                        {{ option.text | titleize }}
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                        <!-- Product Section Start-->

                        <div class="popup-gallery">
                            <vue-easy-lightbox
                                :visible="visibleCaption"
                                :imgs="captionimgs"
                                :index="index"
                                @hide="handleCaptionHide">
                            </vue-easy-lightbox>
                        </div>

                        <AddPaymentModal
                            :ref="add_payment_modal_id"
                            :received_modal_id="add_payment_modal_id"
                            object_type="acquisitions"
                            :item_type="item_type"
                            :modal_title="add_payment_modal_title"
                            @onFormSubmit="onFormSubmit"
                        />

                        <AddAssetsModal
                            :ref="add_assets_modal_id"
                            :received_modal_id="add_assets_modal_id"
                            object_type="acquisitions"
                            :item_type="item_type"
                            :modal_title="add_assets_modal_title"
                            @onFormSubmit="onFormSubmit"
                        />

                        <DefineSupplierModal
                            :ref="define_supplier_modal_id"
                            :received_modal_id="define_supplier_modal_id"
                            object_type="acquisitions"
                            :item_type="item_type"
                            :modal_title="define_supplier_modal_title"
                            @onFormSubmit="onFormSubmit"
                        />

                        <CloseAcquisitionModal
                            :ref="close_acquisition_modal_id"
                            :received_modal_id="close_acquisition_modal_id"
                            object_type="acquisitions"
                            :item_type="item_type"
                            :modal_title="close_acquisition_modal_title"
                            @onFormSubmit="onFormSubmit"
                        />


                        <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                            <b-spinner class="text-af-accent m-2"></b-spinner>
                            <h4>Fetching Acquisitions...</h4>
                        </div>
                        <div v-else-if="!acquisitionsData || total === 0 || sortedItemsList.length === 0"
                             class="products text-center">
                            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                            <h4>No Acquisitions found here at the moment.</h4>

                        </div>

                        <div v-else-if="acquisitionsData && selectedLayout === 0" class="row">
                            <div class="col-lg-12" v-if="selectedLayout === 0">
                                <po-lines
                                    :items="sortedItemsList"
                                    :current_page_type="item_type"
                                    :object_type="'acquisitions'"
                                    :keys_list="acquisition_list_keys_list">

                                    <template v-slot:acquisition_actions="{ item, index }">
                                        <div class="btn-group bl-auto">
                                            <button type="button" class="btn btn-none dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                            </button>
                                            <AcquisitionActionsList :item="item" :index="index" :item_type="item_type" object_type="acquisitions" :perform-action="acquisitionPerformAction"/>
                                        </div>
                                    </template>

                                </po-lines>
                            </div>
                        </div>


                        <div v-else-if="acquisitionsData && selectedLayout === 1" class="cm_list_view_wrapper">
                            <div class="cm_list_view_wrapper">
                                <AcquisitionListView
                                    v-for="(acquisition, acquisitionKey) in sortedItemsList"
                                    :key="acquisitionKey"
                                    :item_type="item_type"
                                    :item_key="acquisitionKey"
                                    :item_info="acquisition"
                                    :received_acquisition_methods="acquisition_methods"
                                    :show-captiom-img="showCaptiomImg"
                                    :perform-action="acquisitionPerformAction"
                                />

                            </div>
                        </div>


                        <!-- Product Section End-->
                        <div class="pagination">
                            <b-pagination
                                v-if="perpage.perPage !== 0"
                                v-model="perpage.currentPage"
                                :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                class="pagination d-flex align-items-center justify-content-center"
                                :per-page="perpage.perPage"
                            ></b-pagination>
                        </div>

                    </div>
                </div>
                <!-- Page-Content end-->

            </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";

import AcquisitionShared from "@/mixins/AcquisitionShared.mixin";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";

import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";

import {AcquisitionKeysList} from "@/views/assetflow/form_data/AcquisitionKeysList";
import AcquisitionListView from "./AcquisitionListView";

const acquisitionResource = new Resource('api/assetflow/acquisitions');
import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import AcquisitionActionsList from "./AcquisitionActionsList";
import AddPaymentModal from "./Modals/AddPaymentModal";
import AddAssetsModal from "./Modals/AddAssetsModal";
import DefineSupplierModal from "./Modals/DefineSupplierModal";
import CloseAcquisitionModal from "./Modals/CloseAcquisitionModal";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import {AcquisitionMethodsList} from "@/views/assetflow/form_data/AcquisitionMethodsList";

import AMDepartmentShared from "@/mixins/AMDepartmentShared.mixin";

export default {
    name: 'AcquisitionsList',
    mixins: [AcquisitionShared, dropzoneMixin, TotalsAndCurrencies, ImageGallery, ObjectsSidebarShared, AMDepartmentShared],
    props: ['item_id', 'item_type'],
    components: {
        LotsOfItemsAlert,
        ObjectsSidebar,
        CloseAcquisitionModal,
        DefineSupplierModal,
        AddAssetsModal,
        AddPaymentModal,
        AcquisitionActionsList, AcquisitionListView, PoLines, SearchBox, Layout, VueEasyLightbox},
    data() {
        return {
            search_keyword: null,
            acquisition_methods: AcquisitionMethodsList,
            listLoading: false,
            acquisition_list_keys_list: AcquisitionKeysList,
            total: 0,
            acquisitionsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Acquisitions",
            items: [
                {
                    text: "Acquisitions",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_acquisition_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            sorting: {
                sortType: {
                    value: 4,
                    text: 'Created Date: New to Old',
                    name: 'created_at',
                    reversed: true
                },
                sortOptions: AddressesSortOptions,
            },
            object_type: 'acquisitions',
            selectedLayout: 0,
            supportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                grid: {
                    name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
                }
            },
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Reconciliation',
                    backend_key: 'is_reconciled',
                    sidebar_list_class: 'is_reconciled'
                },
                {
                    id: 2,
                    label: 'Ledgers',
                    backend_key: 'ledger_id',
                    sidebar_list_class: 'ledger_id'
                },
                {
                    id: 3,
                    label: 'P-Cards',
                    backend_key: 'buyer_ledger_id',
                    sidebar_list_class: 'buyer_ledger_id'
                },
                {
                    id: 4,
                    label: 'Buyers',
                    backend_key: 'buyer_id',
                    sidebar_list_class: 'buyer_id'
                },
                {
                    id: 5,
                    label: 'Purchase Orders',
                    backend_key: 'purchase_order_id',
                    sidebar_list_class: 'purchase_order_id'
                },
            ],
            items_list: [
                [],
                // is_reconciled
                [
                    {
                        id: 1,
                        label: 'Yes',
                        key: '1'
                    },
                    {
                        id: 0,
                        label: 'No',
                        key: '0'
                    }
                ],

                // Ledgers
                [],
                // Buyer Ledgers
                [],
                // Buyers
                [],
                // Purchase Orders
                []
            ],
            loadableOptions: [
                {
                    index: 2, // index in items_list
                    object_name: 'ledgers'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'buyer_ledgers'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'buyers'
                },
                {
                    index: 5, // index in items_list
                    object_name: 'purchaseOrders'
                },
            ],
            filter_classes: {
                '0': 'change-all',
                '1': 'acq-reconciliation',
                '2': 'acq-ledgers',
                '3': 'acq-buyer-ledgers',
                '4': 'acq-buyers',
                '5': 'acq-purchase-orders'
            },
        };
    },

    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        this.has_acquisition_actions_permissions = this.hasPermission('delete_acquisitions');

        if (this.has_acquisition_actions_permissions){
            this.acquisition_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 d_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'acquisition_actions'
            }
        }

        this.acquisition_list_keys_list.assets_value.title_func = this.toUserCurrencyValueTitle;
        this.acquisition_list_keys_list.assets_value.value_func = this.getCurrencyByValueText;


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_acquisitions_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }


        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_'+this.object_type + '_layout')) {
            this.selectedLayout = window.USER_INFO.user_meta['preferred_'+this.object_type + '_layout'];
        }

    },
    computed: {
        totalAssetsValue() {
            return this.acquisitionsData ? this.acquisitionsData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_'+this.object_type + '_layout', layout.value, message);
            this.selectedLayout = layout.value
        },

        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'acquired_date', 'user_id', 'acquisition_type'];

                    for (let key in searchable_values) {
                        let required_key = searchable_values[key];
                        let value = element[required_key] + "";
                        if (required_key === 'asset_name'){
                            value = element.asset_info.name;
                        }else if (required_key === 'asset_ref'){
                            value = element.asset_info.internal_reference;
                        }else if (required_key === 'asset_status'){
                            value = element.name;
                        }else if (required_key === 'acquisition_date'){
                            let date = element.acquisition_date;
                            value = date ? Vue.options.filters.moment(date, 'MMMM Do YYYY') : ''
                        }

                        if (value && value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.acquisitionsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.acquisitionsData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('acquisition_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_acquisitions_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };


            if (this.search_keyword){
                required_query.s_keyword = this.search_keyword;
            }

            this.listLoading = true;

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await acquisitionResource.list(required_query);
            this.total = data.meta.total;
            this.acquisitionsData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-acquisition'].setSelectedItem(null);
            this.$bvModal.show('create-acquisition');
            this.setupAddressLookup('acquisition');
        }
    },
}
</script>


