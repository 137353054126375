const FirstPath = window.location.pathname.split('/')[1];
export const AcquisitionShortKeysList = {

    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 a_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.name;
        }
    },
    department_info: {
        key: 'department_info',
        label: "Department",
        class: "col-lg-1 a_department_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('department_name') && item.department_name !== ''){
                return item.department_name;
            }
            return '-';
        }
    },
    acquisition_type_info: {
        key: 'acquisition_type',
        label: "Type",
        class: "col-lg-1 a_acquisition_type",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.acquisition_type_info && item.acquisition_type_info.hasOwnProperty('name')){
                return item.acquisition_type_info.name;
            }
            return '-';
        }
    },
    acquired_date: {
        key: 'acquired_date',
        label: "Acquisition Date",
        class: "col-lg-1 a_acquired_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    supplier_info: {
        key: 'supplier_info',
        label: "Supplier",
        class: "col-lg-1 a_supplier_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('supplier_name') && item.supplier_name !== ''){
                return item.supplier_name;
            }
            return '-';
        }
    },
    pos_info: {
        key: 'pos_info',
        label: "PO",
        class: "col-lg-1 a_pos_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let po_info = item.purchase_orders_info;
            if (po_info){
                let html = '<div class="cm_list_inner_common_value po-objects-list">';
                for (const itemElementKey in po_info) {
                    let po_object = po_info[itemElementKey];
                    html += '<span class="badge po-object-badge af-accent text-white">' + po_object.uid + '</span> ';
                }
                html += '</div>';
                return html;
            }
            return '-';
        }
    },
    currency: {
        key: 'currency',
        label: "Currency",
        class: "col-lg-1 a_currency",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.currency ? item.currency.toUpperCase() : '-';
        }
    },
    created_at: {
        key: 'created_at',
        label: "Created",
        class: "col-lg-1 a_created_at",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.fromNow(item.created_at);
        }
    }
};
