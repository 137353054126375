const FirstPath = window.location.pathname.split('/')[1];
export const FileKeysList = {
    uid: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 fil_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                'status-background-grey',
                'status-background-green',
                'status-background-red'
            ];
            return '<span class="file-uid-span button_size ' + (status_list[item.status]) + '">' + item[key] + '</span>';
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-4 fil_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.status === 2){
                return '<a href="javascript:void(0);" class="text-dark">' + item[key] + '</a>';
            }
            return '<a href="'+item.file_url+'" class="text-dark">' + item[key] + '</a>';
        }
    },
    file_type: {
        key: 'file_type',
        label: "File Type",
        class: "col-lg-1 fil_file_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return (item[key] + "").toUpperCase();
        }
    },
    category_info: {
        key: 'category_info',
        label: "Category",
        class: "col-lg-1 fil_category_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('category_info') && item.category_info){
                return Vue.options.filters.titleize(item.category_info.name);
            }
            return '';
        }
    },
    template_name: {
        key: 'template_name',
        label: "Template",
        class: "col-lg-1 fil_template_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    user_info: {
        key: 'user_info',
        label: "Created By",
        class: "col-lg-1 fil_user_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('user_info') && item.user_info){
                let user_info = item.user_info;
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
            }
            return '';
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-1 fil_created_at",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'MMMM Do YYYY h:mm:ss a') : '';
        }
    },
};
