<template>
    <AdministrationDashboardTemplate v-if="dashboard_template_id === 1" :item_info="item_info" :tabs="tabs"/>
    <WarehouseManagementDashboard v-else-if="dashboard_template_id === 2" :item_info="item_info" :tabs="tabs"/>
    <StorageManagementDashboard v-else-if="dashboard_template_id === 3" :item_info="item_info" :tabs="tabs"/>
    <DefaultTemplateContent v-else :item_info="item_info" :tabs="tabs"/>
</template>
<script>
import AdministrationDashboardTemplate from "./AdministrationDashboardTemplate";
import DefaultTemplateContent from "./DefaultTemplateContent";
import WarehouseManagementDashboard from "./WarehouseManagementDashboard";
import StorageManagementDashboard from "./StorageManagementDashboard";
import ListEntityTab from "./ListEntityTab";

export default {
    name: 'EntityDashboardBlock',
    components: {
        ListEntityTab,
        StorageManagementDashboard,
        WarehouseManagementDashboard, DefaultTemplateContent, AdministrationDashboardTemplate},
    props:{
        item_info: {},
        tabs: {}
    },
    computed:{
        dashboard_template_id(){
            return this.current_module && this.current_module.hasOwnProperty('dashboard_template_id') ? this.current_module.dashboard_template_id : null
        }
    },
    created() {
        // console.log("this.current_module");
        // console.log(this.current_module);

    }
}
</script>
