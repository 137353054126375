<template>
    <div class="common_col_bg_box">
        <div class="row" v-if="['sp', 'assetflow'].includes(item_type)">
            <div class="col-6 col-md-4">
                <div class="cm_usage border_r">
                    <span>{{ is_tv_film_asset ? 'Customer' : 'Production' }}</span>
                    <p>
                        <span>
                            <a v-if="asset.customer_info" class="text-dark" :href="'/' + item_type + '/customers/' + asset.customer_info.id + '/view'">{{asset.customer_info.name | titleize}}</a>
                        </span>
                    </p>
                </div>
            </div>

            <div v-if="[1,2].includes(asset.status)" class="col-6 col-md-4">
                <div class="cm_usage border_r">
                    <span>Storage</span>
                    <p v-if="storage_info" v-html="getStorageBlock"></p>
                    <p v-else>-</p>
                </div>
            </div>
            <div v-else class="col-6 col-md-4">
                <div class="cm_usage border_r">
                    <span>Storage Order</span>
                    <p v-if="asset.hasOwnProperty('storage_order_info') && asset.storage_order_info">
                        <span>
                            <a class="text-dark"
                               :href="'/' + item_type + '/orders/' + asset.storage_order_info.id + '/view'">{{ asset.storage_order_info.uid }}</a>
                        </span>
                    </p>
                    <p v-else>
                        <span>-</span>
                    </p>
                </div>
            </div>

            <div v-if="[1,2].includes(asset.status)" class="col-6 col-md-4">
                <div class="cm_usage">
                    <span>Location</span>
                    <p v-if="storage_info && location_info" v-html="getLocationBlock"></p>
                    <p v-else-if="asset.hasOwnProperty('current_warehouse_info') && asset.current_warehouse_info && asset.hasOwnProperty('current_space_info') && asset.current_space_info"
                       v-html="getSPLocationBlock"></p>
                    <p v-else>-</p>
                </div>
            </div>

            <div v-else class="col-6 col-md-4">
                <div class="cm_usage">
                    <span>Completed Date</span>
                    <p v-if="asset.storage_order_info">
                        <span>
                            {{asset.storage_order_info.completed_date | moment('DD-MM-YYYY HH:mm')}}
                        </span>
                    </p>
                    <p v-else>-</p>
                </div>
            </div>


        </div>
        <div class="row" v-if="['customer', 'assetflow'].includes(item_type)">
            <div class="col-6 " :class="[1,2].includes(asset.status) ? 'col-md-6' : 'col-md-4'">
                <div class="cm_usage border_r">
                    <span>SP</span>
                    <p><span><a v-if="asset.sp_info" class="text-dark"
                                :href="'/' + item_type + '/service_providers/' + asset.sp_info.id + '/view'">{{asset.sp_info.name | titleize}}</a></span>
                    </p>
                </div>
            </div>
            <div v-if="[1,2].includes(asset.status)" class="col-6 col-md-6">
                <div class="cm_usage">
                    <span>Address</span>
                    <p v-if="asset.hasOwnProperty('current_warehouse_address_info') && asset.current_warehouse_address_info">
                        <a class="text-dark"
                           :href="'/' + item_type + '/addresses/' + asset.current_warehouse_address_info.id + '/view'">{{
                            asset.current_warehouse_address_info.name }}</a>
                    </p>
                    <p v-else>-</p>
                </div>
            </div>
            <div v-else class="col-6 col-md-4">
                <div class="cm_usage border_r">
                    <span>Storage Order</span>
                    <p v-if="asset.hasOwnProperty('storage_order_info') && asset.storage_order_info">
                        <span>
                            <a class="text-dark"
                               :href="'/' + item_type + '/orders/' + asset.storage_order_info.id + '/view'">{{ asset.storage_order_info.uid }}</a>
                        </span>
                    </p>
                    <p v-else>
                        <span>-</span>
                    </p>
                </div>
            </div>


            <div v-if="asset.status === 0" class="col-6 col-md-4">
                <div class="cm_usage">
                    <span>Completed Date</span>
                    <p v-if="asset.storage_order_info">
                        <span>
                            {{asset.storage_order_info.completed_date | moment('DD-MM-YYYY HH:mm')}}
                        </span>
                    </p>
                    <p v-else>-</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagedStorageEntityBlock',
    props: {
        asset: {},
        getLocationBlock: {},
        getSPLocationBlock: {},
        getStorageBlock: {},
        item_type: {},
    },
    computed:{
        storage_info(){
            return this.asset.hasOwnProperty('storage_info') && this.asset.storage_info;
        },
        location_info(){
            return this.asset.hasOwnProperty('location_info') && this.asset.location_info;
        }
    }
}
</script>
