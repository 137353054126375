<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    placeholder="Select Status"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-intake')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const intakeResource = new Resource('api/assetflow/intakes');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewIntake",
    props: ['modal_title', 'item_type', 'intakes_options'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                reference: '',
                name: '',
                status:null,
                notes: ''
            },
            required_items:{
                reference: {
                    key: 'reference',
                    label: 'Ref',
                    type: 'text'
                },
                name: {
                    key: 'name',
                    label: 'Intake Name',
                    type: 'text'
                },
                status:{
                    key: 'status',
                    label: 'Status',
                    type: 'single-select',
                    options: [
                        {
                            id: 0,
                            name: 'Open',
                        },
                        {
                            id: 1,
                            name: 'Confirmed',
                        },
                        {
                            id: 2,
                            name: 'In Progress',
                        },
                        {
                            id: 3,
                            name: 'Completed',
                        },
                        {
                            id: 4,
                            name: 'Cancelled',
                        },
                        {
                            id:5,
                            name: 'Awaiting Transport',
                        },
                    ]
                },
                notes: {
                    key: 'notes',
                    label: 'Notes',
                    type: 'textarea'
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.reference = this.selected_item.reference;
                this.addForm.name = this.selected_item.name;
                this.addForm.notes = this.selected_item.notes;
                let status_id = this.selected_item.status;
                let found_item = _.find(this.required_items.status.options, function(o) { return o.id === status_id; });
                if (found_item){
                    this.addForm.status = found_item;
                }else{
                    this.addForm.status = null;
                }
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                reference: this.addForm.reference,
                name: this.addForm.name,
                status: this.addForm.status ? this.addForm.status.id : 1,
                notes: this.addForm.notes,
                order_type: 0
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await intakeResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-intake');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


