<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <items-list item_type="service_providers"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemsList from "./ItemsList";
export default {
    name: "users_access_service_providers",
    components: {ItemsList},
    props:['selected_setting'],
}
</script>


