<template>
    <div>
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="item" :show-captiom-img="showCaptiomImg" :required_image_size="'closet_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-5">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <h3>
                                    <a :href="'/' + item_type + '/assets/' + item.id + '/view'"><i class="bx" :class="item.ownership_type === 1 ? 'bx-cube-alt' : 'bx-time'"></i>{{ item.name | titleize }}</a>
                                </h3>
                            </div>
                            <AssetIconList :my_asset="item" :item_type="item_type" :perform-action="performAction"/>

                        </div>
                    </div>
                    <div class="col-md-6 col-xl-7">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title"><h3>
                                <a href="#" :class="'status-btn status-btn-' + item.status" v-html="getAssetStatusFrom(item)"></a></h3></div>
                            <div class="cm_status_ul">
                                <ul>
                                    <li>
                                        <p>Total <span class="total_box_bg">{{ item.quantity }}</span></p>
                                    </li>
                                    <li v-if="(selected_filtering_module_id || current_module.id) !== 3">
                                        <p>Available <span class="available_box_bg">{{ item.available_quantity || item.quantity }}</span>
                                        </p>
                                    </li>
                                    <li v-if="(selected_filtering_module_id || current_module.id) !== 3">
                                        <p>Reserved <span
                                            class="reserved_box_bg">{{ item.reserved_quantity || 0 }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-5">

                        <div class="common_col_desc asset-description"
                             v-if="item.description && item.description !== ''">
                            <p>{{ item.description }}</p>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Brand</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item.asset_meta && item.asset_meta.hasOwnProperty('brand')">{{item.asset_meta.brand}}</span>
                                            <span
                                                v-else-if="item.asset_meta && item.asset_meta.hasOwnProperty('make') && item.asset_meta.make">{{item.asset_meta.make.name}}</span>
                                            <span
                                                v-else-if="item.asset_meta && item.asset_meta.hasOwnProperty('make_brand') && item.asset_meta.make_brand">{{item.asset_meta.make_brand}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Size</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item.asset_meta && item.asset_meta.hasOwnProperty('size')">{{item.asset_meta.size}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage">
                                        <span>Colours</span>
                                        <div v-html="getColourHTML(item)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-7">
                        <div class="common_col_bg_box">
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Character</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item.hasOwnProperty('character_info') && item.character_info"
                                               :href="'/' + item_type + '/characters/'+item.character_info.id+'/view'">({{
                                                item.character_info.character_number || '' }}) {{
                                                item.character_info.name }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Asset Reference</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item.internal_reference">{{ item.internal_reference }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <slot name="action_button" v-bind:index="index" v-bind:asset="item"></slot>
                                </div>
                            </div>
                        </div>

                        <div class="cm_list_last_update mt-1" v-if="item.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText(item)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">

            <div v-if="hasPermission('edit_assets') || hasPermission('delete_assets')"
                 class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <AssetsActionsList
                    :is_closet_asset="true"
                    :item="item" :item_type="item_type" :perform-action="performAction"/>
            </div>

        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/assets/' + item.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import AssetIconList from "../../assets/subItems/AssetLayouts/AssetIconList"
import AssetsActionsList from "../../assets/subItems/AssetsActionsList"
import MyBCarouselSlide from "../widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide"
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'AssetClosetItem',
    components: {MyBCarouselImages, AssetIconList, AssetsActionsList, MyBCarouselSlide},
    props: {
        item: {},
        item_type: {},
        index: {},
        performAction: {},
        showCaptiomImg: {}
    },
    methods:{

        getPostText(my_asset){
            if (my_asset && my_asset.hasOwnProperty('latest_post_update')){
                let user = my_asset.latest_post_update.user;
                if (user){
                    let image_tag = '';
                    if (user.hasOwnProperty('avatar_url') && user.avatar_url){
                        image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                    }

                    let post_title = this.getItemPostTitle(my_asset, my_asset.latest_post_update);
                    return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + post_title + " on " + Vue.options.filters.moment(my_asset.latest_post_update.created_at , 'DD/MM/YYYY');
                }
            }
            return '';

        },
        getColourHTML(my_asset){
            if (my_asset && my_asset.hasOwnProperty('colours_info')) {
                let colours_info = my_asset.colours_info;
                if (colours_info) {
                    let html = '';
                    if (colours_info.length > 0) {
                        for (const itemElementKey in colours_info) {
                            let colour = colours_info[itemElementKey];
                            html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                        }
                    } else {
                        html += '-';
                    }
                    return html;
                }
            }
            return '-';
        },
    }
}
</script>
