const FirstPath = window.location.pathname.split('/')[1];
export const AcquisitionMetaKeys = {
    name:{
        label: 'Name',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    details:{
        label: 'Details',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    acquired_date:{
        label: 'Date',
        cast_value: function (item, key) {
            if (item.acquired_date){
                return Vue.options.filters.moment(item.acquired_date, 'MMMM Do YYYY');
            }
            return '';
        }
    },
    quantity:{
        label: 'Qty',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    currency:{
        label: 'Currency',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    supplier_ref:{
        label: 'Ref',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    user_id:{
        label: 'Created By',
        cast_value: function (item, key) {
            if (item.user_id){
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + item[key] + '/view">' + Vue.options.filters.titleize(item.user_name) + '</a>';
            }
            return '';
        }
    },
    asset_name:{
        label: 'Asset Name',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    asset_uid:{
        label: 'Asset ID',
        cast_value: function (item, key) {
            if (item.asset_id){
                return '<a class="post-update-link-value" href="/' + FirstPath + '/assets/' + item.asset_id + '/view" target="_blank">' + item[key] + '</a>';
            }
            return '';
        }
    },
    ms_uid:{
        label: 'MS ID',
        cast_value: function (item, key) {
            if (item.ms_id){
                return '<a class="post-update-link-value" href="/' + FirstPath + '/managed_storages/' + item.ms_id + '/view" target="_blank">' + item[key] + '</a>';
            }
            return '';
        }
    },
    order_uid:{
        label: 'Order',
        cast_value: function (item, key) {
            if (item.order_id){
                return '<a class="post-update-link-value" href="/' + FirstPath + '/orders/' + item.order_id + '/view" target="_blank">' + item[key] + '</a>';
            }
            return '';
        }
    },
    task_uid:{
        label: 'Task',
        cast_value: function (item, key) {
            if (item.task_id){
                return '<a class="post-update-link-value" href="/' + FirstPath + '/order_lines/' + item.task_id + '/view" target="_blank">' + item[key] + '</a>';
            }
            return '';
        }
    },
    ms_quantity:{
        label: 'Qty',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    ms_status:{
        label: 'Status',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    ms_note:{
        label: 'Note',
        cast_value: function (item, key) {
            return 'Stock levels have been updated accordingly';
        }
    },
};
