const FirstPath = window.location.pathname.split('/')[1];
export const SPAssignmentKeysList = {
    uid: {
        key: 'id',
        label: "Assignment",
        class: "col-lg-2 assignment-uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                'status-background-orange',
                'status-background-green',
                'status-background-grey'
            ];
            return '<a class="text-dark" href="/' + FirstPath + '/sp_assignments/' + item.id + '/view"><span class="button_size ' + (status_list[item.status]) + '">' + item[key] + '</span></a>';
        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-2 assignment-customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('customer_info') && item.customer_info){
                let customer_info = item.customer_info;
                return '<p class="object-title-detail"><i class="fa fa-star"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a></span></p>';
            }
            return '-';
        }
    },
    storage_info: {
        key: 'storage_info',
        label: "Storage",
        class: "col-lg-2 assignment-storage_info",
        show_on_mobile: false,
        case: 'custom_html',
        required_class: 'object-title-block',
        value_func: function (item, key) {
            if (item.hasOwnProperty('storage_info') && item.storage_info){
                let storage_info = item.storage_info;

                let storage_wrapper_info = storage_info.storage_wrapper_info;
                let icon = 'fa fa-th-large';
                if (storage_wrapper_info){
                    icon = storage_wrapper_info.icon;
                }

                return '<p class="object-title-detail"><i class="'+icon+'"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/sp_storages/' + storage_info.id + '/view">' + Vue.options.filters.titleize(storage_info.name) + '</a></span></p>';
            }
            return '-';
        }
    },
    start_date: {
        key: 'start_date',
        label: "Start Date",
        class: "col-lg-1 assignment-start_date",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'DD-MM-YYYY HH:mm') : '-';
        }
    },
    last_usage_tracked_date: {
        key: 'last_usage_tracked_date',
        label: "Last Usage Tracked",
        class: "col-lg-1 assignment-last_usage_tracked_date",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'DD-MM-YYYY HH:mm') : '-';
        }
    },
    finish_date: {
        key: 'finish_date',
        label: "Finish Date",
        class: "col-lg-1 assignment-finish_date",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'DD-MM-YYYY HH:mm') : '-';
        }
    },
    usage_increment: {
        key: 'usage_increment',
        label: "Usage Iteration",
        class: "col-lg-1 assignment-usage_increment",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('usage_increment') && item.usage_increment){
                return item.usage_increment;
            }
            return 0;
        }
    },
};
