<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="['text', 'number'].includes(required_field.type)"
                    v-model="addForm[required_field.key]"
                    :type="required_field.type"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

                <span v-if="required_field.hasOwnProperty('note') && required_field.note !== ''">{{ required_field.note }}</span>
            </b-form-group>

        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const scriptResource = new Resource('api/assetflow/scripts');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewScript",
    props: ['modal_title', 'item_type'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                name: '',
                description: '',
                script_date: '',
                status: null,
                script_type: null,
                script_pages: '',
                story_days: '',
            },
            required_fields:{
                name: {
                    key: 'name',
                    label: 'Name',
                    type: 'text'
                },
                description: {
                    key: 'description',
                    label: 'Description',
                    type: 'textarea'
                },
                script_date: {
                    key: 'script_date',
                    label: 'Script Date',
                    type: 'date'
                },
                status: {
                    key: 'status',
                    label: 'Status',
                    type: 'single-select',
                    options:[
                        {id: 0, name: 'Draft'},
                        {id: 1, name: 'Final'}
                    ]
                },
                script_type: {
                    key: 'script_type',
                    label: 'Type',
                    type: 'single-select',
                    options:[
                        {id: 0, name: 'Feature Film'},
                        {id: 1, name: 'Episodic'}
                    ]
                },
                script_pages: {
                    key: 'script_pages',
                    label: 'Script Pages',
                    type: 'number'
                },
                story_days: {
                    key: 'story_days',
                    label: 'Story Days',
                    type: 'number'
                },
            },
            selected_item: null,
            list_disabled: null,
            lists_values: {}
        }
    },
    created() {
    },
    methods:{
        setDefaultValues() {
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },

        setValueFromOptions: function ( field_name, item_id, default_value = null) {
            let found_item = _.find(this.required_fields[field_name].options, function (o) {
                return o.id === item_id;
            });

            this.addForm[field_name] = found_item ? found_item : default_value;
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('status', this.selected_item.status);
                this.setValueFromOptions('script_type', this.selected_item.script_type);
                this.addForm.name = this.selected_item.name;
                this.addForm.description = this.selected_item.description;
                this.addForm.script_date = this.selected_item.script_date;
                this.addForm.script_pages = this.selected_item.script_pages;
                this.addForm.story_days = this.selected_item.story_days;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
            this.setDefaultValues();
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                status: this.addForm.status ? this.addForm.status.id : null,
                script_type: this.addForm.script_type ? this.addForm.script_type.id : null,
                name: this.addForm.name,
                description: this.addForm.description,
                script_date: this.addForm.script_date,
                script_pages: this.addForm.script_pages,
                story_days: this.addForm.story_days,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await scriptResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>
