<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem;$emit('on-modal-cancel');"
             @show="getFieldsList"
             hide-footer>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <b-form-group
                                v-for="(required_field, key) in required_section.required_items"
                                :key="key"
                                :label="required_field.label"
                                :label-for="required_field.key"
                                class="col-sm-12"
                            >
                                <multiselect
                                    v-if="required_field.type === 'single-select'"
                                    v-model="addForm[required_field.key]"
                                    track-by="id"
                                    label="name"
                                    :placeholder="'Select ' + required_field.label"
                                    class="mySingleSelect"
                                    :options="required_field.options"
                                    :custom-label="required_field.custom_label || CustomMSLabel"
                                    :allow-empty="false"
                                    deselect-label=""
                                >
                                </multiselect>

                                <b-form-input
                                    v-else-if="['text', 'color'].includes(required_field.type)"
                                    v-model="addForm[required_field.key]"
                                    :type="required_field.type"
                                    :id="required_field.key"
                                    :placeholder="required_field.label"
                                    autocomplete="none"
                                ></b-form-input>

                                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                                    <div class="common--input-filed">
                                        <textarea v-model="addForm[required_field.key]" :id="required_field.key" :placeholder="required_field.label"></textarea>
                                    </div>
                                </div>

                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_spaceResource = new Resource('api/assetflow/sp_spaces');
import multiSelect from '@/mixins/MultiSelect';
import AFButton from "@/components/AFButton";

export default {
    mixins: [multiSelect],
    name: "AddNewSpSpace",
    props: ['modal_title', 'item_type', 'sp_spaces_options'],
    components: {AFButton},
    data(){
        return {
            addForm:{
                warehouse_id: null,
                name: '',
                initials: '',
                colour: '',
                description: '',
                status: null,
                size: null,
                location_type: null,
            },
            required_sections:{
                space_setup: {
                    section_name: 'Space Setup',
                    required_items:{
                        warehouse_id: {
                            key: 'warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select',
                            options:[]
                        },
                        name: {
                            key: 'name',
                            label: 'Name',
                            type: 'text'
                        },
                        initials: {
                            key: 'initials',
                            label: 'Initials',
                            type: 'text'
                        },
                        colour: {
                            key: 'colour',
                            label: 'Colour',
                            type: 'color'
                        },
                        description: {
                            key: 'description',
                            label: 'Description',
                            type: 'textarea'
                        },
                        status: {
                            key: 'status',
                                label: 'Status',
                                type: 'single-select',
                                options:[
                                {
                                    id: 1,
                                    name: 'Active'
                                },
                                {
                                    id: 0,
                                    name: 'Inactive'
                                }
                            ]
                        },
                        size: {
                            key: 'size',
                            label: 'Size',
                            type: 'text'
                        },
                    },
                },
                location_setup: {
                    section_name: 'Location Setup',
                    required_items:{
                        location_type: {
                            key: 'location_type',
                            label: 'Location Type',
                            type: 'single-select',
                            options:[]
                        },
                    },
                }
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
    },
    methods:{

        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;
            this.is_saving_enabled = true;

            let required_select_lists =  {
                warehouse_id: 'sp_warehouses_list',
                location_type: 'sp_location_types_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_space_modal',
                    object_type: 'sp_spaces',
                    action: 'new_sp_space',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                this.required_sections.space_setup.required_items.warehouse_id.options = this.lists_values.warehouse_id;
                this.required_sections.location_setup.required_items.location_type.options = this.lists_values.location_type;

                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.initials = this.selected_item.initials;
                this.addForm.colour = this.selected_item.colour;
                this.addForm.description = this.selected_item.description;
                this.addForm.size = this.selected_item.size;
                this.addForm.warehouse_id = this.getObjectOf(this.selected_item.sp_warehouse_id, this.required_sections.space_setup.required_items.warehouse_id.options);
                this.addForm.status = this.getObjectOf(this.selected_item.status, this.required_sections.space_setup.required_items.status.options);

                this.addForm.location_type = this.getObjectOf(this.selected_item.location_type, this.required_sections.location_setup.required_items.location_type.options);
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.addForm.status = this.required_sections.space_setup.required_items.status.options[0];
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            let params = {
                name: this.addForm.name,
                initials: this.addForm.initials,
                colour: this.addForm.colour,
                description: this.addForm.description,
                size: this.addForm.size,
                warehouse_id: this.addForm.warehouse_id ? this.addForm.warehouse_id.id : null,
                status: this.addForm.status ? this.addForm.status.id : 1,
                location_type: this.addForm.location_type ? this.addForm.location_type.id : null,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_spaceResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>


