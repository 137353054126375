<template>
    <new-user :item_id="current_user_id" action="profile"/>
</template>

<script>
    import NewUser from "../assetflow/users/New";

    export default {
        props:['item_id', 'item_type', 'action'],
        name: "Profile",
        components: {NewUser },
        data() {
            return {
                current_user_id: "",
                user_info: {
                    id: null,
                },
            };
        },
        created() {
            this.user_info = window.USER_INFO ? window.USER_INFO : this.user_info;
            this.current_user_id = this.user_info.id;
        },
        methods: {

        }
    }
</script>


