<template>
    <div class="row">
        <p>Assets that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+item_type+'/'+item_id+'/view'">{{item_name}}</a></p>

        <div class="col-12">
            <po-lines :items="assetsData"
                      :current_page_type="item_type"
                      :object_type="'assets'"
                      :keys_list="asset_list_key_list">
                <template v-slot:asset_list_actions="{ item, index }">
                    <div v-if="has_actions_permissions">
                        <a :href="base_url +'/'+ item_info.group + '/assets/' + item.id + '/view'" class="btn btn-outline-primary btn-sm">View</a>
                        <a :href="base_url +'/'+ item_info.group + '/assets/' + item.id + '/edit'" class="btn btn-outline-secondary btn-sm">Edit</a>
                        <a v-on:click="unAssignFromAsset($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                    </div>
                </template>
            </po-lines>
        </div>

    </div>
</template>

<script>
    import Resource from '@/api/resource';
    import PoLines from "./detailsViews/PoLines";
    import {AssetListKeysList} from "@/views/assetflow/form_data/AssetListKeysList";
    import AssetActionsDropdown from "../../assets/subItems/AssetActionsDropdown";

    export default {
        name: "ListAssetsNew",
        components: {AssetActionsDropdown, PoLines},
        props: {
            item_info: Object,
        },
        data() {
            return {
                currentUserPermissions: [],
                asset_list_key_list: AssetListKeysList,
                has_actions_permissions: false,
                base_url: "",
                assetsData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1,
                    limit: 20
                },
                assignData: {
                    user_id: "",
                    asset_id: ""
                }
            }
        },
        created() {

            this.has_actions_permissions = this.hasPermission('edit_assets') || this.hasPermission('delete_assets');

            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;

            this.asset_list_key_list.total_value.title_func = this.toUserCurrencyValueTitle;
            this.asset_list_key_list.total_value.value_func = this.getCurrencyByValueText;
            this.getList();
            this.getAssetsList();
        },
        methods: {
            getList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/assets' ,
                        baseURL: '/',
                        data: {
                            page: 1,
                            limit: 20
                        },
                    }).then(response => {
                        this.listLoading = false;
                        this.assetsData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            assignToAsset(asset_id){
                this.$refs['assign-asset-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: asset_id,
                    item_type: 'assets'
                };

                this.assign_unassign_asset();

                console.log("should assign user to asset " + asset_id + this.item_id);
            },
            assign_unassign_asset() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_asset',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {
                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getAssetsList();
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromAsset(event, asset_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: asset_id,
                    item_type: 'assets',
                    unassign: true
                };
                this.assign_unassign_asset();
                console.log("should unassign user from asset >> " + asset_id);

            },
            async getAssetsList() {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;


                const assetResource = new Resource('api/assetflow/assets');

                const { data } = await assetResource.list(this.listQuery);

                this.items =  data.data.items.filter((asset) => {
                    return !this.assetsData.map(x => x.id).includes(asset.id);
                });

                this.listLoading = false;
            },

            performAction(item, action_name, event) {
                console.log("action_name");
                console.log(action_name);
            },
        }
    }
</script>


