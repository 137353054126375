<template>
    <div class="row">
        <div class="col-12">
            <div class="cm_list_view_wrapper">
                <div class="cm_list_view af-primary cm_list_view-header">
                    <div class="cm_list_row row">
                        <div class="col-md-12 col-xl-12" v-iconize="item_info.item_type">'{{item_info.name}}' Asset
                            List
                        </div>
                    </div>
                </div>
                <ObjectManagedStorageList :item_info="item_info" :parent_object_type="item_info.item_type"/>
            </div>
        </div>
    </div>
</template>
<script>
import ObjectManagedStorageList from "./ObjectManagedStorageList"

export default {
    name: 'ParentAssetsListBlock',
    components: {ObjectManagedStorageList},
    props: {
        item_info: {}
    }
}
</script>