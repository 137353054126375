<template>
    <div class="ns_main_block">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-6">
                <div class="main_content_block">
                    <div class="Post_An_Update">
                        <h6 @click="hideForm"
                            class="Post_An_Update_title Post_An_Update_dropdown"
                            :class="formShown ? '' : 'this_active'">
                            Post An Update:
                        </h6>
                        <add-item-post
                            v-show="formShown"
                            @on-cancel-post-adding="hideForm"
                            post_type="part_change"
                            :item_id="item_info.id"
                            @on-post-adding="addNewPost"
                        >
                        </add-item-post>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-6">
                <div class="main_content_block" v-if="item_info_asset_posts && item_info_asset_posts.length > 0">
                    <div class="Assets_Timeline managed-storage-activity-block">
                        <div class="Assets_Timeline_title d-sm-flex align-items-center justify-content-between">
                            <h6 class="Post_An_Update_title">Timeline:</h6>

                            <div class="only_image_block">
                                <div class="grid-list-button d-flex align-items-center justify-content-between mb-0">
                                    <div
                                        v-for="(layout, key) in supportedLayouts"
                                        :key="key"
                                        @click="changeTimelineLayout(layout, $event)"
                                        :id="'grid-list-'+layout.class"
                                        :class="layout.class + (layout.isActive ? ' active' : '')"></div>
                                </div>

                            </div>
                        </div>

                        <po-lines v-if="selected_layout && selected_layout === 1"
                                  :items="item_info_asset_posts"
                                  :current_page_type="item_info.current_page_type || item_type"
                                  :object_type="'assets'"
                                  :keys_list="asset_posts_list_key_list">
                        </po-lines>
                        <!-- Richard Created Asset 54 Html Strat -->
                        <div v-else class="richard-text_block">
                            <div v-for="item_post in item_info_asset_posts" class="personal_details">

                                <div class="media media_b_a">
                                    <div class=" media_b_a_img d-flex align-items-center justify-content-center">
                                        <img src="https://assetflow-public-bucket.s3.eu-west-2.amazonaws.com/public/css/images/Vector-document.png">
                                    </div>

                                    <img :src="item_post.user.avatar_url" :alt="item_post.user.name" class="rounded-circle item-post-image" width="40" height="40">
                                    <div class="media-body personal_media-body">
                                        <h6 class="personal_details_title">
                                            {{ item_post.user.name }} <span v-html="getItemPostTitle(item_info, item_post)" ></span>
                                        </h6>
                                        <p class="personal_details_sub_title">{{ item_post.created_at | moment("dddd, MMMM Do YYYY") }} - {{item_post.created_at | fromNow}}</p>
                                    </div>
                                </div>

                                <my-post-content :item_post="item_post" :onPostImageClicked="postImageClicked">
                                    <template v-slot:post_description="{ item }">
                                        <div>
                                            <div v-if="item_post.hasOwnProperty('post_meta') && item_post.post_meta" class="added-asset-info">
                                                <p v-html="item.description.replace(/ITEM_TYPE/g, item_info.current_page_type || item_type)"></p>
                                                <div v-for="(change_meta_key, key) in change_meta_keys"
                                                     :key="key"
                                                     v-if="item_post.post_meta.hasOwnProperty(key)"
                                                >
                                                    <span class="post-text-heading">{{ change_meta_key.label }}</span>
                                                    <span class="post-text-value" v-html="change_meta_key.cast_value(item_post.post_meta, key)"></span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p v-html="item.description.replace(/ITEM_TYPE/g, item_info.current_page_type || item_type)"></p>
                                            </div>
                                        </div>
                                    </template>
                                </my-post-content>

                            </div>
                        </div>
                        <!-- Richard Created Asset 54 Html End -->
                    </div>
                </div>
                <div class="text-center mt-5" v-else>
                    <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                    <h4>No Activities found here at the moment.</h4>
                </div>
            </div>
        </div>
        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>
    </div>
</template>
<script>
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import MyPostContent from "@/views/assetflow/items/widgets/detailsViews/PostContentTypes/MyPostContent";

import {ChangeMetaKeys} from "@/views/assetflow/form_data/ChangeMetaKeys";
import AddItemPost from "../../items/widgets/detailsViews/AddItemPost";

import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
    name: 'ChangeSceneLiveTab',
    components: {
        AddItemPost,
        VueEasyLightbox,
        MyPostContent,
        PoLines,
        NewCustomGenericBlock
    },
    mixins:[TimelineCommons, ImageGallery],
    props:['item_info', 'post_meta_keys', 'item_type'],
    data(){
        return {
            change_meta_keys: ChangeMetaKeys
        }
    },
    computed:{
        item_info_asset_posts(){
            return this.item_info && this.item_info.hasOwnProperty('asset_posts') ? this.item_info.asset_posts : null;
        },
    },
    created() {

        let item_info = this.item_info;

        let getItemPostTitle = this.getItemPostTitle;
        this.asset_posts_list_key_list.action_title.value_func = function (index, item, key) {
            return Vue.options.filters.capitalize(getItemPostTitle(item_info, item));
        }

        let user_meta = window.USER_INFO.user_meta;
        if (user_meta.hasOwnProperty('preferred_timeline_layout')) {
            this.selected_layout = window.USER_INFO.user_meta.preferred_timeline_layout;
        }

        if (user_meta.hasOwnProperty('post_update_form_appearance')){
            this.formShown = user_meta.post_update_form_appearance;
        }
    },
    methods:{
        addNewPost(item, msg, status){
            this.$emit('on-post-adding', item, msg, (status ? 'success' : 'danger'));
        }
    }
}
</script>

