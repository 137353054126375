<template>
    <div class="step-form-content-block row">

        <div class="col-xl-6 st-col-6">
            <asset-info-section title="Disposition" :selected_fields="disposal_fields" :addForm="addForm">

                <div v-if="getAssetInfoUsingKey('action', 'this') !== 'edit'">
                    <LockedAssetWarning icon="bx bx-info" message="Before Registering a Disposition you must first create this Asset."/>
                </div>

                <div v-else-if="asset_is_locked">
                    <LockedAssetWarning icon="bx bx-lock" message="This Asset is Locked, 'Registering Disposition' is not available."/>
                </div>

                <div v-else>
                    <div class="common--input-filed unit-input mb-1">
                        <div class="dimensions-btn">
                            <a @click="startRegisteringDisposition($event)"
                                :class="isRegisteringDisposition ? 'active' : ''"
                                class="dimensions-auto">
                                <span></span>Register Disposition
                            </a>
                        </div>
                    </div>
                    <RegisterDispositionModal
                        :ref="register_disposition_modal_id"
                        :received_modal_id="register_disposition_modal_id"
                        :required_fields="required_fields"
                        :item_type="getAssetInfoUsingKey('item_type', 'this')"
                        :other_info="{used_currency: addForm.single_select_v_models.used_currency}"
                        :getAssetInfoUsingKey="getAssetInfoUsingKey"
                        @on-modal-cancel="isRegisteringDisposition = false"
                        @on-registering-disposition="onRegisteringDisposition"
                    />
                </div>
            </asset-info-section>
        </div>

        <div class="col-xl-6 st-col-6 mt-2">
            <asset-info-section title="Dispositions List">
                <po-lines
                    v-if="dispositions_info && dispositions_info.length > 0"
                    :items="dispositions_info"
                    :current_page_type="getAssetInfoUsingKey('item_type', 'this')"
                    :object_type="'dispositions'"
                    :keys_list="disposition_list_keys_list"
                    :get-line-class-using="getLineDispositionClass">
                </po-lines>

                <div v-else class="text-center">
                    <h6>No dispositions found here at the moment.</h6>
                </div>
            </asset-info-section>
        </div>

    </div>
</template>

<script>
import multiSelect from '@/mixins/MultiSelect';
import DispositionsShared from '@/mixins/DispositionsShared.mixin';

import AssetInfoSection from "./AssetInfoSection";

import AddNewDisposition from "../../customers/extras/AddNewDispositionType";
import LockedAssetWarning from "./LockedAssetWarning";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import {DispositionKeysList} from "@/views/assetflow/form_data/DispositionKeysList";
import RegisterDispositionModal from "./RegisterDispositionModal";

export default {
    mixins: [multiSelect, DispositionsShared],
    name: "DisposalInfo",
    components: {RegisterDispositionModal, PoLines, LockedAssetWarning, AddNewDisposition, AssetInfoSection},
    props: ['addForm', 'required_fields', 'listQuery', 'asset_is_locked', 'getAssetInfoUsingKey'],
    data(){
        return {
            register_disposition_modal_id: 'register-disposition',
            disposition_list_keys_list: DispositionKeysList,
            isRegisteringDisposition: false,
            disposal_fields:[]
        }
    },
    created() {
        this.disposition_list_keys_list.total_value.title_func = this.toUserCurrencyValueTitle;
        this.disposition_list_keys_list.total_value.value_func = this.getCurrencyByValueText;
        this.has_disposition_actions_permissions = this.hasPermission('browse_dispositions');
        if (this.has_disposition_actions_permissions){
            this.disposition_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 po_actions",
                show_on_mobile: false
            }
        }

        this.handleSalePrice();
    },
    computed:{
        dispositions_info(){
            return this.getAssetInfoUsingKey('dispositions_info');
        },
        shall_dispose_asset(){
            return this.addForm.dispose_asset;
        },

        disposition_type(){
            return this.addForm.single_select_v_models.disposition_type;
        }
    },
    watch:{
        disposition_type:{
            deep: true,
            handler: function (newValue) {
                this.handleSalePrice();
            }
        },
        shall_dispose_asset:{
            deep: true,
            handler: function (newValue) {
                this.handleSalePrice();
            }
        },
    },
    methods:{
        onRegisteringDisposition(disposition_info=null){
            // shall save disposition and refresh page,
            this.addForm.item_details.disposition_info = disposition_info;
            this.addForm.item_details = Object.assign({}, this.addForm.item_details);
            let addNew = this.getAssetInfoUsingKey('addNew', 'this')
            addNew('edit_page', 'added_disposition');
        },
        getLineDispositionClass(item, line_type){
            let disposition_id = this.getAssetInfoUsingKey('disposition_id');
            let is_current = false;
            if (disposition_id){
                is_current = item.id === disposition_id ? ' is-latest-disposition' : '';
            }
            return 'po-line' + is_current;
        },
        handleSalePrice: function () {

            if (this.addForm.dispose_asset) {
                this.disposal_fields = [
                    'dispose_asset',
                    'disposition_date',
                    'disposition_details',
                    'disposition_type'
                ]

                if (this.addForm.single_select_v_models.hasOwnProperty('disposition_type') && this.addForm.single_select_v_models.disposition_type && this.addForm.single_select_v_models.disposition_type.has_amount) {
                    this.disposal_fields.push('sale_price');
                }

            }else{
                this.disposal_fields = ['dispose_asset']
            }

        },
        startRegisteringDisposition(){
            this.isRegisteringDisposition = true;
            this.$bvModal.show(this.register_disposition_modal_id);
        }
    },
}
</script>

