<template>
    <div>
        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="row">
                        <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items">
                                </ChangeCommonFieldsAdvanced>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('second_required_items')" :class="required_section.second_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced ref="secondBlock" :add-form="addForm" :required_fields="required_section.second_required_items"/>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('third_required_items')" :class="required_section.third_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.third_required_items"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="$v.addForm.$anyError">
            <div class="col-12">
                <div class="card border border-warning af-warnings-block">
                    <div class="card-header bg-transparent border-warning"><h5 class="my-0 text-warning"><i class="mdi mdi-alert-outline me-3"></i> Couldn't proceed</h5></div>
                    <div class="card-body">
                        <h5 class="card-title mt-0">Please check the following:</h5>
                        <div class="card-text text-muted mt-4">
                            <p v-if="$v.addForm.waste_stream_type.$dirty && !$v.addForm.waste_stream_type.required"><i class="mdi mdi-chevron-right text-primary me-1"></i> Waste stream must not be empty.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="text-right mt-2">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-change' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :first_argument="true"
                required_text="Save"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="$emit('on-cancel-clicked')">Cancel</b-button>
        </div>

    </div>
</template>
<script>

import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import ChangeCommonFieldsAdvanced from "./ChangeCommonFieldsAdvanced";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import IntakeProfileShared from "@/mixins/IntakeProfileShared.mixin";

import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";

import AFButton from "@/components/AFButton";
import Resource from '@/api/resource';

export default {
    name: 'OrderLineProcessingFieldsIntakeOnly',
    components: {AFButton, PoLines, ChangeCommonFieldsAdvanced},
    mixins: [validationMixin, IntakeProfileShared, dropzoneMixin],
    props: [
        'current_page_type',
        'received_order_type',
        'received_parent_order',
        'received_selected_item',
    ],
    validations: {
        addForm: {
            waste_stream_type: {
                required
            },
        }
    },
    data(){
        return {
            intake_profile_info: null,
            order_line: null,
            addForm:{
                filled_asset_images:[],
                asset_images:[],
                quantity: 1,
                value_type: 0,
                intake_images: null,
                value_types: null,
                preferred_unit: null,
                width: null,
                height: null,
                depth: null,
                area: null,
                total_area: null,
                volume: null,
                total_volume: null,
                weight: null,
                total_weight: null,
                colours:[],
                composition:[],
                condition: null,
                waste_stream:[],
                waste_stream_type:null,
                waste_stream_id:null,
                shall_update_parent: {id: 1, name: 'Yes'},
                notes: null,
            },
            required_sections:{
                intake_profile: {
                    section_name: 'Intake Profile',
                    required_items:{
                        // TODO ?? value_types??
                        value_types: {
                            key: 'value_types',
                            label: '',
                            type: 'value_types',
                            options:[]
                        },
                        preferred_unit: {
                            key: 'preferred_unit',
                            label: 'Preferred Unit',
                            type: 'radio-select',
                            is_lower_case: true,
                            required_options_class: 'col-4',
                            options:[]
                        },
                        height: {
                            key: 'height',
                            label: 'Height',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        width: {
                            key: 'width',
                            label: 'Width',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        depth: {
                            key: 'depth',
                            label: 'Depth',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        weight: {
                            key: 'weight',
                            html_label: 'Weight <sup>kg</sup> for 1 Item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        total_weight: {
                            key: 'total_weight',
                            html_label: 'Weight <sup>kg</sup> for 1 item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        area: {
                            key: 'area',
                            label: 'Area For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_area: {
                            key: 'total_area',
                            label: 'Total Area',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        volume: {
                            key: 'volume',
                            label: 'Volume For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_volume: {
                            key: 'total_volume',
                            label: 'Total Volume',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                    },
                    required_items_class: 'col-sm-6',
                    second_required_items_class: 'col-sm-6',
                    second_required_items:{
                        intake_images: {
                            key: 'asset_images',
                            label: '',
                            type: 'intake_images',
                        },
                    },
                    third_required_items_class: 'col-sm-12',
                    third_required_items:{
                        colours: {
                            key: 'colours',
                            label: 'Colours',
                            type: 'custom-multi-select',
                            options: [
                            ]
                        },
                        condition: {
                            key: 'condition',
                            label: 'Condition',
                            type: 'radio-select',
                            required_options_class: 'col-3',
                            options:[
                                { id: 1, name: 'New' },
                                { id: 2, name: 'Excellent' },
                                { id: 3, name: 'Good' },
                                { id: 4, name: 'Attention Required' },
                            ]
                        },

                        waste_stream_type: {
                            key: 'waste_stream_type',
                            label: 'Waste Stream Type',
                            type: 'radio-select',
                            options:[
                                { id: 0, name: 'Product' },
                                { id: 1, name: 'Material' },
                            ]
                        },
                        waste_stream_id: {
                            key: 'waste_stream_id',
                            label: 'Product Waste Stream',
                            type: 'hidden_text',
                            options: []
                        },
                        composition: {
                            key: 'composition',
                            label: 'composition',
                            type: 'hidden_text',
                            options: []
                        },
                        shall_update_parent: {
                            key: 'shall_update_parent',
                            label: 'Update Asset Passport',
                            type: 'radio-select',
                            options: [
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                },
                            ]
                        },
                        notes: {
                            key: 'notes',
                            label: 'Notes',
                            type: 'textarea',
                            placeholder: 'Enter any intake notes about this item (cosmetic notes, observations, etc.)',
                            is_required:false
                        },
                    },
                },
            },
            isAddingIntakeProfile: false,
            addedIntakeProfile: null,
            failedAddingIntakeProfile: false,
            value_type_options:[
                {
                    id: 0,
                    name: 'Dimensions for 1 item'
                },
                {
                    id: 1,
                    name: 'Dimensions for (x) items'
                }
            ],
        }
    },
    created() {
        this.setSelectedItem(this.received_selected_item);
    },
    computed:{

    },
    watch: {

    },
    methods:{
        async getFieldsList(){
            this.prepareOtherFields();

            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                colours: 'colours_list',
                waste_stream_id: 'product_waste_streams_list',
                composition: 'compositions_list',
            }

            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'intake_modal',
                    object_type: 'intakes',
                    action: 'new_intake',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }
                this.required_sections.intake_profile.third_required_items.colours.options = this.lists_values.colours;
                this.required_sections.intake_profile.third_required_items.waste_stream_id.options = this.lists_values.waste_stream_id;
                this.required_sections.intake_profile.third_required_items.composition.options = this.lists_values.composition;

                if (this.intake_profile_info){
                    let intake_profile_info = this.intake_profile_info;
                    this.setFormValue('waste_stream_id', this.getObjectOf(intake_profile_info.waste_stream_id, this.required_sections.intake_profile.third_required_items.waste_stream_id.options));

                    this.addForm.colours = this.getValueOf('colours', intake_profile_info.colours, true);
                    this.addForm.composition = this.getValueOf('composition', intake_profile_info.composition, true, 'name');
                }


            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setSelectedItem(order_line){
            this.order_line = order_line;
            if(order_line.hasOwnProperty('intake_profile_info') && order_line.intake_profile_info){
                // shall fill intake_profile_info info
                let intake_profile_info = order_line.intake_profile_info;


                this.addForm.width = intake_profile_info.width;
                this.addForm.height = intake_profile_info.height;
                this.addForm.depth = intake_profile_info.depth;
                this.addForm.area = intake_profile_info.area;
                this.addForm.total_area = intake_profile_info.total_area;
                this.addForm.volume = intake_profile_info.volume;
                this.addForm.total_volume = intake_profile_info.total_volume;
                this.addForm.weight = intake_profile_info.weight;
                this.addForm.total_weight = intake_profile_info.total_weight;

                this.addForm.notes = intake_profile_info.notes;
                this.addForm.value_type = this.value_type_options[1].id;

                this.setFormValue('preferred_unit', this.getObjectOf(intake_profile_info.preferred_unit, this.required_sections.intake_profile.required_items.preferred_unit.options, 'name'));
                this.setFormValue('waste_stream_type', this.getObjectOf(intake_profile_info.waste_stream_type, this.required_sections.intake_profile.third_required_items.waste_stream_type.options));
                this.setFormValue('condition', this.getObjectOf(intake_profile_info.condition, this.required_sections.intake_profile.third_required_items.condition.options));

                if (intake_profile_info.hasOwnProperty('asset_images')){
                    this.addForm.asset_images = [... intake_profile_info.asset_images];

                    this.fillAssetImages();

                }
                this.intake_profile_info = intake_profile_info;
            }else{
                // shall add intake?
            }

            this.addForm.quantity = order_line.total_quantity;

            this.getFieldsList();
        },
        async addItem(event){
            this.$v.addForm.$touch();
            if (this.$v.addForm.$anyError) {
                return;
            }

            this.addedIntakeProfile = null;

            this.failedAddingIntakeProfile = null;

            this.is_saving_enabled = false;


            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (!all_files){ return; }


            await this.addIntakeProfile(all_files)
            .then(added_intake_profile => {
                this.is_saving_enabled = true;
                this.$emit('after-saving-is-done', added_intake_profile);
            });
        },


        async addIntakeProfile(all_files){
            return new Promise( async (resolve, reject) => {
                this.isAddingIntakeProfile = true;
                let params = {
                    order_line_id: this.order_line.id,
                    asset_images: all_files,
                    preferred_unit: this.addForm.preferred_unit ? this.addForm.preferred_unit.name : 'm',
                    value_type: this.addForm.value_type, // 0 for one item >> do nothing, 1 for all items > divide the biggest by quantity
                    width: this.addForm.width,
                    height: this.addForm.height,
                    depth: this.addForm.depth,
                    area: this.addForm.area,
                    total_area: this.addForm.total_area,
                    volume: this.addForm.volume,
                    total_volume: this.addForm.total_volume,
                    weight: this.addForm.weight,
                    total_weight: this.addForm.total_weight,
                    colours: this.addForm.colours ? this.getIDs(this.addForm.colours) : null,

                    waste_stream_type: this.addForm.waste_stream_type ? this.addForm.waste_stream_type.id : null,
                    waste_stream_id: this.addForm.waste_stream_id ? this.addForm.waste_stream_id.id : null,
                    composition: this.addForm.composition ? this.getIDs(this.addForm.composition, false, 'name') : null,

                    condition: this.addForm.condition ? this.addForm.condition.id : null,
                    shall_update_parent: this.addForm.shall_update_parent ? this.addForm.shall_update_parent.id : null,
                    notes: this.addForm.notes
                };

                if (this.intake_profile_info && this.intake_profile_info.hasOwnProperty('id')){
                    params.ip_item_id = this.intake_profile_info.id;
                }

                const intake_profileResource = new Resource('api/assetflow/intake_profiles');
                const { data } = await intake_profileResource.store(params);
                this.isAddingIntakeProfile = false;
                let success_message = 'Added Intake Profile Successfully';
                this.handleResponseForObject(data, success_message, resolve, reject, 'IntakeProfile');
            });
        },

        handleResponseForObject(data, success_message, resolve, reject, object_name) {
            let added_object = data.data.items;
            this[('added' + object_name)] = added_object;
            this.presentToast((added_object ? "Success!" : "Error!"),
                added_object ? success_message : data.data.msg,
                (added_object ? 'success' : 'danger'),
                3000,
                true,
                true);
            if (added_object) {
                resolve(added_object);
            } else {
                this[('failedAdding' + object_name)] = {message: data.hasOwnProperty('data') && data.data.hasOwnProperty('msg') && data.data.msg ? data.data.msg : ''};
                reject();
            }
        },
    }
}
</script>
