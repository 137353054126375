<template>
    <div class="intelligence-template">

        <div class="row mb-2">
            <div class="col-12">
                <h5 class="order-name">{{ item_info.name }}</h5>
            </div>
        </div>


        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="disp_activity_tab" role="tabpanel" :class="isActiveTab('disp_activity_tab')">
                <DispositionActivityTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="disp_details_tab" role="tabpanel" :class="isActiveTab('disp_details_tab')">
                <DispositionDetailsTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="disp_assets_tab" role="tabpanel" :class="isActiveTab('disp_assets_tab')">
                <DispositionAssetsTab :item_info="item_info"/>
            </div>
        </div>


    </div>
</template>
<script>
import DispositionActivityTab from "./DispositionActivityTab";
import DispositionDetailsTab from "./DispositionDetailsTab";
import DispositionAssetsTab from "./DispositionAssetsTab";

export default {
    name: 'CustomDispositionDetails',
    components: {DispositionAssetsTab, DispositionDetailsTab, DispositionActivityTab},
    props: ['item_info'],
    data(){
        return {
            active_tab: 'disp_activity_tab',
            tabs: {
                'disp_activity_tab': {
                    'id': "disp_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "disp_activity_tab",
                    'isActive': false
                },
                'disp_details_tab': {
                    'id': "disp_details_tab",
                    'name': "Details",
                    'icon': "user",
                    'type': "disp_details_tab",
                    'isActive': false
                },
                'disp_assets_tab': {
                    'id': "disp_assets_tab",
                    'name': "Assets",
                    'icon': "user",
                    'type': "disp_assets_tab",
                    'isActive': false
                }
            },
        }
    },
    created() {
        let dispositions_active_tab = localStorage.getItem('dispositions_active_tab');

        if (dispositions_active_tab && dispositions_active_tab !== ''){
            this.active_tab = dispositions_active_tab;
        }
    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('dispositions_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
