<template>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="row" v-if="item_info.hasOwnProperty('acquisition_defaults') && item_info.acquisition_defaults">
                <NewCustomGenericBlock
                    block_title="Acquisition Defaults"
                    columns_count_withclasses="12"
                    action_required_title="Add Asset"
                    :action_required="addAssetModal"
                >
                    <template v-slot:all_content>
                        <div class="row">
                            <div
                                v-for="(value, key) in item_info.acquisition_defaults"
                                class="col-6 col-md-3">
                                <div class="cm_list_inner_common">
                                    <span class="cm_list_inner_common_label">{{ key | titleize }}</span>
                                    <p class="cm_list_inner_common_value">
                                        <span>{{ value }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </NewCustomGenericBlock>
            </div>
            <div class="text-center mt-5" v-else>
                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                <h4>No Defaults found here at the moment.</h4>
            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <div class="row" v-if="item_info">
                <NewCustomGenericBlock
                    block_title=""
                    :block_title_html="getHtmlTitleFor('acquisitions')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <AcquisitionRightBox :item_info="item_info" unique_id="defaults"/>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

    </div>
</template>
<script>
import AcquisitionRightBox from "./AcquisitionRightBox";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";

export default {
    name: 'Defaults',
    components: {
        NewCustomGenericBlock,
        AcquisitionRightBox
    },
    mixins:[],
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
        }
    },
    computed:{
    },
    created() {

    },
    methods:{
        addAssetModal(){
            console.log("shall open add new asset modal");

        }
    }
}
</script>

