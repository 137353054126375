import Resource from '@/api/resource';
const categoryResource = new Resource('api/assetflow/categories');
const folderResource = new Resource('api/assetflow/folders');
const sceneResource = new Resource('api/assetflow/scenes');
const scene_locationsResource = new Resource('api/assetflow/scene_locations');
const charactersResource = new Resource('api/assetflow/characters');
const usersResource = new Resource('api/assetflow/users');
const shoot_daysResource = new Resource('api/assetflow/shoot_days');
const ledgerResource = new Resource('api/assetflow/ledgers_with_groups');
const buyerResource = new Resource('api/assetflow/buyers');
const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');
const storageWrapperResource = new Resource('api/assetflow/sp_storage_wrappers');
const customerResource = new Resource('api/assetflow/customers');
const orderResource = new Resource('api/assetflow/orders');
const spStorageResource = new Resource('api/assetflow/sp_storages');
const tagResource = new Resource('api/assetflow/tags');

import {CreatedDatesList} from "@/views/assetflow/form_data/CreatedDatesList";

export default {
    props:['action'],
    data() {
        return {
            selected_dashboard_item: null,
            selected_object_id: null,
            is_content_loaded_using_queue: false,
            treeList: null,
            filter_type_id: null,
            is_listing_sidebar: false,
            current_groupBy: 0,
            global_saved_filter_type: null,
            current_groupBy_option: null,
            specific_object_name: null,
            specific_object_name_single: null,
            received_order_type: null,
            groupBy_options: [],
            object_type: null,
            is_group: false,
            selected_item: null,
            selected_customer: {
                id: null,
                name: 'All'
            },
            selectedLayout: 0,
            supportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                grid: {
                    name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
                }
            },
            customers_list: [],
            listLoadingCustomers: false,
            loadableOptions: [],
            is_export:0,
            template_id:10,
            top_filters_text:'',
            export_actions: {},
            action_name: null,
            shall_select_customer_first: false,
        }
    },
    created: function () {
        let queryVars = this.getQueryVars();
        let category_index = -1;
        let filtering_option = '';
        if ("queue" in queryVars && queryVars["queue"] !== '') {
            this.is_content_loaded_using_queue = true;


            filtering_option = queryVars["queue"];
            let filtering_item_id = "id" in queryVars && queryVars["id"] !== '' ? queryVars["id"] : null;
            if ("day" in queryVars){
                switch (queryVars["day"]) {
                    case 'today':
                        filtering_item_id = 0;
                        break;
                    case 'tomorrow':
                        filtering_item_id = 1;
                        break;
                    case 'previous':
                        filtering_item_id = -1;
                        break;
                }

            }

            if (filtering_option === 'schedule' && filtering_item_id !== null){
                let required_id = parseInt(filtering_item_id);
                category_index = this.items_list[4].map(function (e) {
                    return e.id;
                }).indexOf(required_id);
            }
        }


        if (this.item_type === 'group'){
            this.is_group = true;
            this.getCustomersListForGroup();
        }

        let object_type = this.object_type;
        if (this.hasOwnProperty('forced_object_type')){
            object_type = this.forced_object_type;
        }

        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_'+object_type + '_layout')) {
            this.selectedLayout = window.USER_INFO.user_meta['preferred_'+object_type + '_layout'];
        }

        let filter_type_name = object_type + '_filter_type' + (this.is_group ? '_group' : '');
        let saved_filter_type = localStorage.getItem(filter_type_name) || '0';
        if (this.object_type === 'managed_storages'){
            this.export_actions = {
                export_csv: {
                    id: 10,
                    file_type: 'CSV',
                    block_class: 'col-xl-4',
                    action_label_html: 'CSV',
                    description: 'Generates an Asset List in CSV format',
                    is_enabled: 1,
                },
                export_xlsx: {
                    id: 11,
                    file_type: 'XLSX',
                    block_class: 'col-xl-4',
                    action_label_html: 'Asset Listing (XLXS)',
                    description: 'Generates an Asset List in Excel format',
                    is_enabled: 1,
                },
                // export_with_images:{
                //     id: 12,
                //     file_type: 'XLSX',
                //     block_class: 'col-xl-4',
                //     action_label_html: 'Asset Listing (XLXS) <small class="coming_soon text-af-accent">with Images</small>',
                //     description: 'Generates an Asset List with images in Excel format',
                //     is_enabled: 1,
                // },
            }
        }else if (this.object_type === 'sp_storages'){
            this.export_actions = {
                export_csv: {
                    id: 10,
                    file_type: 'CSV',
                    block_class: 'col-xl-4',
                    action_label_html: 'CSV',
                    description: 'Generates an Storage List in CSV format',
                    is_enabled: 1,
                },
                export_xlsx: {
                    id: 11,
                    file_type: 'XLSX',
                    block_class: 'col-xl-4',
                    action_label_html: 'Storage Listing (XLXS)',
                    description: 'Generates an Storage List in Excel format',
                    is_enabled: 1,
                },
            }
        }else if (this.object_type === 'orders'){
            this.export_actions = {
                export_pdf: {
                    id: 8,
                    file_type: 'PDF',
                    block_class: 'col-xl-4',
                    action_label_html: 'PDF <small class="coming_soon text-af-accent">(soon)</small>',
                    is_enabled: 0,
                },
                export_csv: {
                    id: 10,
                    file_type: 'CSV',
                    block_class: 'col-xl-4',
                    action_label_html: 'CSV',
                    is_enabled: 1,
                },
                export_xlsx: {
                    id: 11,
                    file_type: 'XLSX',
                    block_class: 'col-xl-4',
                    action_label_html: 'XLSX',
                    is_enabled: 1,
                }
            };
        }
        // if (['managed_storages', 'orders', 'order_lines', 'sp_storages'].includes(this.object_type)){
        //     this.export_actions = {
        //         export_pdf: {
        //             id: 8,
        //             file_type: 'PDF',
        //             block_class: 'col-xl-4',
        //             action_label_html: 'PDF <small class="coming_soon text-af-accent">(soon)</small>'
        //         },
        //         export_csv: {
        //             id: 10,
        //             file_type: 'CSV',
        //             block_class: 'col-xl-4',
        //             action_label_html: 'CSV'
        //         },
        //         export_xlsx: {
        //             id: 11,
        //             file_type: 'XLSX',
        //             block_class: 'col-xl-4',
        //             action_label_html: 'XLSX'
        //         }
        //     };
        //
        //     if (this.object_type !== 'sp_storages'){
        //         this.export_actions.export_with_images = {
        //             id: 12,
        //             file_type: 'XLSX',
        //             block_class: 'col-xl-4',
        //             action_label_html: 'XLSX with Images'
        //         }
        //     }
        // }


        if (['managed_storages', 'orders', 'order_lines'].includes(this.object_type) && this.action !== ''){

            let required_filter = this.action;

            // TODO:// should remove Status filter??? ID 0
            if (['in_storage', 'dispatched'].includes(required_filter)){

                this.groupBy_options[1].is_hidden = true;

                // this.items_list.splice(0, 1);
                // this.groupBy_options.splice(0, 1);

                if (saved_filter_type === '1'){
                    saved_filter_type = '0';
                }
                this.current_groupBy = parseInt(saved_filter_type);

            }else if (this.object_type !== 'managed_storages' && (['move_to_storage', 'storage_orders'].includes(required_filter))){
                // TODO:// should remove Order Types filter??? ID 1
                // this.items_list.splice(1, 1);
                // this.groupBy_options.splice(1, 1);

                this.loadableOptions.splice(2, 1);
                this.groupBy_options[2].is_hidden = true;

                if (saved_filter_type === '2'){
                    saved_filter_type = '0';
                }
                this.current_groupBy = parseInt(saved_filter_type);
            }
        }

        // if (this.object_type === 'managed_storages'){
        //     if (this.item_type === 'sp'){
        //         this.items_list = [[], []];
        //         this.groupBy_options = [
        //             {
        //                 id: 0,
        //                 label: 'All',
        //                 is_all:true,
        //                 sidebar_list_class: 'all'
        //             },
        //             {
        //                 id: 1,
        //                 label: 'Categories',
        //                 backend_key: 'category_id'
        //             }
        //         ];
        //
        //         this.loadableOptions = [
        //             {
        //                 index: 1, // index in items_list
        //                 object_name: 'categories'
        //             }
        //         ]
        //
        //         this.current_groupBy = 0;
        //         saved_filter_type = '0';
        //     }
        // }


        if (this.object_type === 'changes'){
            saved_filter_type = '0';
        }

        let filter_type_key = filter_type_name + '_id';


        if (window.USER_INFO.user_meta.hasOwnProperty(filter_type_key)){
            let queryVars = this.getQueryVars();
            if (!("s" in queryVars && queryVars["s"] !== '')) {
                this.filter_type_id = window.USER_INFO.user_meta[filter_type_key];
            }
        }




        if (saved_filter_type !== undefined){
            this.current_groupBy = saved_filter_type ? parseInt(saved_filter_type) : 0;
        }else if (this.is_group){
            this.current_groupBy = 0;
        }

        if (['acquisitions', 'dispositions', 'suppliers', 'files'].includes(this.object_type)){
            let first_index = 6;
            switch (this.object_type) {
                case 'dispositions':
                    first_index = 1;
                    break;
                case 'suppliers':
                    first_index = 1;
                    break;
                case 'files':
                    first_index = 1;
                    break;
            }
            let second_index = first_index + 1;

            this.items_list.push([]);
            this.groupBy_options.push({
                    id: first_index,
                    label: 'Created By',
                    backend_key: 'user_id',
                    sidebar_list_class: 'created-by'
                }
            );
            this.loadableOptions.push({
                index: first_index,
                object_name: 'createdBy'
            });

            this.items_list.push(CreatedDatesList);
            this.groupBy_options.push({
                    id: second_index,
                    label: 'Created Date',
                    backend_key: 'created_at',
                    sidebar_list_class: 'created-at'
                }
            );

            this.filter_classes[first_index+''] = this.object_type + '-createdBy';
            this.filter_classes[second_index+''] = this.object_type + '-createdDate';
        }


        // this works somehow, it needs testing
        // if (['managed_storages'].includes(this.object_type)){
        //     this.items_list.splice(0, 0, []);
        //     // should push all loadableOptions indexes +1
        //     this.loadableOptions = this.loadableOptions.map(function(option) {
        //         option.index = option.index + 1
        //         return option;
        //     })
        //     this.groupBy_options = this.groupBy_options.map(function(option) {
        //         option.id = option.id + 1
        //         return option;
        //     })
        //
        //     this.groupBy_options.splice(0, 0, {
        //         id: 0,
        //         label: 'All',
        //         is_all:true,
        //         sidebar_list_class: 'all'
        //     });
        //     if (this.filter_classes){
        //         this.filter_classes[all_index+''] = this.object_type + '-all';
        //     }
        // }

        if (!saved_filter_type){
            saved_filter_type = '0';
        }

        this.global_saved_filter_type = saved_filter_type;

        for (const loadableOptionsKey in this.loadableOptions) {
            let loadableOption = this.loadableOptions[loadableOptionsKey];
            this.getObjectsList(loadableOption.object_name, loadableOption.index, !this.is_content_loaded_using_queue && ((loadableOption.index + '') === saved_filter_type));
        }


        if (!this.is_content_loaded_using_queue){
            this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
        }

        if (filtering_option === 'schedule' && category_index >= 0){
            this.current_groupBy = 4;
            this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
            this.selected_item = this.items_list[this.current_groupBy][category_index];
            this.getList(this.selected_item);
        }

        this.treeList = this.items_list[this.current_groupBy];


        if (!this.filter_type_id && ['acquisitions', 'dispositions', 'suppliers', 'files'].includes(this.object_type)){

            let sort_type_key = this.object_type.slice(0, -1) + '_preferred_sorting';
            if (window.USER_INFO.user_meta[sort_type_key]) {
                this.sorting.sortType = window.USER_INFO.user_meta[sort_type_key];
            }

            this.getList(this.selected_item);
        }

    },
    methods: {
        applySPItemsBulkAction(){
            console.log("shall applySPItemsBulkAction?");
            console.log("this.object_type");
            console.log(this.object_type);
        },
        setActionsStatus(is_enabled, export_status = null) {
            let _actions_list = {};
            let action_name = this.action_name;


            _.each(this.export_actions, (obj, key) => {
                obj.is_enabled = 1;
                if( !is_enabled ){
                    obj.is_enabled = action_name !== key ? 0 : 2;
                }
                _actions_list[key] = obj;
            })
            _actions_list = Object.assign(_actions_list, {});

            if (is_enabled && action_name){
                let action_info = _actions_list[action_name];
                if (export_status !== null){
                    action_info = {...action_info, exported: export_status, is_enabled: 3};
                    this.action_name = null
                }
                _actions_list[action_name] = action_info;
            }
            this.export_actions = Object.assign(_actions_list, {});
        },
        exportOrder(action_name){
            this.action_name = action_name;
            this.setActionsStatus(false);
            switch (action_name) {
                case 'export_csv':
                    this.exportObjects(10);
                    break;
                case 'export_xlsx':
                    this.exportObjects(11);
                    break;
                case 'export_with_images':
                    this.exportObjects(12);
                    break;
            }
        },
        exportObjects(template_id=10){
            // export is enabled for these objects only
            if (['managed_storages', 'orders', 'order_lines', 'sp_storages'].includes(this.object_type)){
                this.is_export = 1;
                this.template_id = template_id;
                this.getList(this.selected_item);
            }
        },

        dashboardItemClicked(status_text, status_key, status_value){
            let tab = 'list_items';
            if ( status_value !== null ){

                let selected_dashboard_items = {
                    status: {
                        label: 'Status',
                        backend_key: 'status_id',
                    },
                    location_type: {
                        label: 'Location Type',
                        backend_key: 'location_type',
                    },
                    type: {
                        label: 'Type',
                        backend_key: 'type',
                    },
                }
                let dashboard_item = selected_dashboard_items[status_key];
                dashboard_item.id = status_value;
                dashboard_item.name = status_text;
                this.selected_dashboard_item = dashboard_item;
            }
            // check if it's type? or other values

            this.active_tab = tab;
            this.tabClicked(tab);
            $('.nav-tabs a[href="#' + tab + '"]').tab('show');
        },

        refreshObject(modal_id, edited_object, list_name='', required_keys=[]){
            this.selected_object_id = null;
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            let index = _.findIndex(this[list_name], {id: edited_object.id});
            if (index > -1) {
                if (required_keys){
                    for (const requiredKeysKey in required_keys) {
                        let required_key = required_keys[requiredKeysKey];
                        this.$set(this[list_name][index], required_key, edited_object[required_key]);
                    }
                }
            }
        },
        getPoLineClassForObject(item, line_type){
            // can check line_type??
            let classes_list = {
                'active_check': item.id === this.selected_object_id,
                'table_inner_body': true,
                'd-flex-p': true,
            };
            classes_list[line_type + '-row'] = true;

            if (line_type === 'sp_locations'){
                classes_list['parts-row'] = true;
            }
            return classes_list;
        },
        refreshSidebarLists(required_query, only_sidebar=false){
            let is_current = false;
            for (const loadableOptionsKey in this.loadableOptions) {
                let loadableOption = this.loadableOptions[loadableOptionsKey];
                is_current = ((loadableOption.index + '') === this.global_saved_filter_type);
                this.getObjectsList(loadableOption.object_name, loadableOption.index, is_current, required_query, only_sidebar);
            }
        },
        async getObjectsList(object_name, index, is_current=false, required_query={}, only_sidebar=false) {
            let queryList = {... required_query};

            if (is_current){
                this.is_listing_sidebar = true;
            }

            let requiredResource, not_defined_option_name;

            switch (object_name){
                case 'categories':
                    if (this.item_type === 'sp'){
                        if (this.object_type === 'categories'){
                            return;
                        }

                        if (this.object_type === 'managed_storages' && !(this.selected_customer && this.selected_customer.id)){
                            // console.log(`can\'t load ${object_name}, shall select customer first`);
                            this.shall_select_customer_first = true;
                            return;
                        }
                    }
                    this.shall_select_customer_first = false;
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = categoryResource;
                    queryList.without_counts = true;

                    queryList.sort_by = 'id';
                    queryList.sort_dir = 'asc';
                    break;
                case 'folders':
                    if (this.item_type === 'sp') {
                        if (this.object_type === 'folders') {
                            return;
                        }
                        if (this.object_type === 'managed_storages' && !(this.selected_customer && this.selected_customer.id)) {
                            // console.log(`can\'t load ${object_name}, shall select customer first`);
                            this.shall_select_customer_first = true;
                            return;
                        }
                    }
                    this.shall_select_customer_first = false;
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.use_current_module = 0;
                    requiredResource = folderResource;
                    break;
                case 'scenes':
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = sceneResource;
                    queryList.is_short_list = true;
                    break;
                case 'scene_locations':
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = scene_locationsResource;
                    queryList.is_short_list = true;
                    break;
                case 'characters':
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = charactersResource;
                    queryList.is_short_list = true;
                    break;
                case 'users':
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = usersResource;
                    queryList.is_short_list = true;
                    break;
                case 'createdBy':
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = usersResource;
                    queryList.is_short_list = true;
                    queryList.object_type = this.object_type;
                    break;
                case 'shoot_days':
                    queryList = this.SBHandleQueryItems(queryList);
                    requiredResource = shoot_daysResource;
                    queryList.is_short_list = true;
                    break;
                case 'ledgers':
                case 'buyer_ledgers':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    queryList.object_name = object_name;
                    requiredResource = ledgerResource;

                    if (object_name === 'buyer_ledgers'){
                        not_defined_option_name = 'No P-Card Defined';
                    }
                    break;
                case 'buyers':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = buyerResource;
                    break;
                case 'purchaseOrders':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = purchaseOrderResource;
                    not_defined_option_name = 'No Purchase Order Defined';
                    break;
                case 'sp_storage_wrappers':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = storageWrapperResource;
                    not_defined_option_name = 'No Storage Wrappers Defined';
                    break;
                case 'customers':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = customerResource;
                    not_defined_option_name = 'No Storage Wrappers Defined';
                    break;
                case 'item_status':
                case 'storage_usage':
                case 'due_date':
                case 'order_service':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = new Resource('api/assetflow/'+this.object_type+'/itemStatus');
                    not_defined_option_name = 'No Statuses Defined';
                    break;
                case 'location_status':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = new Resource('api/assetflow/'+this.object_type+'/itemStatus');
                    not_defined_option_name = 'No Location Statuses Defined';
                    break;
                case 'order_type':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.is_short_list = true;
                    requiredResource = new Resource('api/assetflow/'+this.object_type+'/itemStatus');
                    not_defined_option_name = 'No Order Types Defined';
                    break;
                case 'orders':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.status_id = 0;
                    queryList.order_type = 1;
                    queryList.order_by = 'created_at';
                    queryList.order_dir = 'desc';
                    requiredResource = orderResource;
                    break;
                case 'all_orders':
                    queryList = this.SBHandleQueryItems(queryList);
                    queryList.status_id = 0;
                    if (this.object_type === 'managed_storages'){
                        queryList.order_type = 1;
                    }
                    queryList.order_by = 'created_at';
                    queryList.order_dir = 'desc';
                    requiredResource = orderResource;
                    break;
                case 'all_storages':
                    queryList = this.SBHandleQueryItems(queryList);
                    delete queryList.status_id;
                    queryList.limit = 500;
                    queryList.is_short_list = true;
                    queryList.order_by = 'created_at';
                    queryList.order_dir = 'desc';
                    requiredResource = spStorageResource;
                    break;
                case 'tags':
                    if (this.item_type === 'sp') {
                        if (this.object_type === 'tags') {
                            return;
                        }
                        if (this.object_type === 'managed_storages' && !(this.selected_customer && this.selected_customer.id)) {
                            // console.log(`can\'t load ${object_name}, shall select customer first`);
                            this.shall_select_customer_first = true;
                            return;
                        }
                    }
                    this.shall_select_customer_first = false;
                    queryList = this.SBHandleQueryItems(queryList);
                    // queryList.limit = 1000;
                    queryList.is_short_list = true;
                    queryList.item_class = 'App\\Asset';
                    requiredResource = tagResource;
                    if (this.object_type === 'managed_storages'){
                        queryList.limit = -1;
                    }
                    break;
                case 'addresses':
                    // requiredResource = addressResource;
                    break;
                case 'suppliers':
                    // requiredResource = supplierResource;
                    break;
                default:
                    return;
            }

            if (requiredResource){

                if (['sp_storages'].includes(this.object_type) || ['item_status', 'location_status', 'storage_usage', 'due_date', 'order_service', 'order_type'].includes(object_name)){
                    queryList.with_sidebar_counts = true;
                    queryList.required_object = this.object_type;
                    queryList.object_type = object_name;
                }

                if (['folders', 'tags'].includes(object_name) && this.object_type === 'managed_storages'){
                    delete queryList.status_id;
                }

                const { data } = await requiredResource.list(queryList);
                if (Object.keys(data).includes('data')){
                    let required_list = data.data.items;
                    if (['categories', 'folders', 'tags', 'ledgers', 'buyer_ledgers', 'buyers', 'purchaseOrders', 'scenes', 'scene_locations', 'characters', 'shoot_days', 'users', 'createdBy', 'sp_storage_wrappers', 'customers', 'item_status', 'location_status', 'storage_usage', 'due_date', 'order_service', 'order_type'].includes(object_name)){

                        if (['buyer_ledgers','purchaseOrders'].includes(object_name)){
                            let not_defined_option = {
                                id: 0,
                                name: not_defined_option_name
                            }
                            required_list.splice(0,0, not_defined_option);
                        }
                        if (['folders', 'tags'].includes(object_name) && this.item_type !== 'customer'){
                            required_list = required_list.map(function (e) {
                                let folder = e;
                                if (folder.hasOwnProperty('customer') && folder.customer){
                                    folder.name = folder.customer.name + ': ' + folder.name;
                                }
                                return folder;
                            });
                        }
                        if (object_name === 'tags'){
                            let add_new_item = {id: -98, name: 'Untagged', icon: 'text-af-accent fa fa-tag'};
                            required_list.splice(0, 0, add_new_item);
                        }
                        this.items_list[index] = required_list;
                    }else if (['orders', 'all_orders'].includes(object_name)){
                        this.orders_list = required_list;
                        return;
                    }else if (object_name === 'all_storages'){
                        this.storages_list = required_list;
                        return;
                    }else{
                        this.items_list[index] = required_list.map(function (e) {
                            return {value: e.id, text: e.name};
                        });
                    }
                    let queryVars = this.getQueryVars();
                    if ("queue" in queryVars && queryVars["queue"] !== '') {
                        let filtering_option = queryVars["queue"];
                        let filtering_item_id = "id" in queryVars && queryVars["id"] !== '' ? queryVars["id"] : null;

                        if (filtering_option === 'category' && index === 2){
                            is_current = true;

                            let required_id = parseInt(filtering_item_id);

                            let category_index = this.items_list[2].map(function (e) {
                                return e.id;
                            }).indexOf(required_id);

                            if (category_index >= 0){
                                this.current_groupBy = 2;
                                this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
                                this.selected_item = this.items_list[this.current_groupBy][category_index];
                                if (!only_sidebar){
                                    this.getList(this.selected_item);
                                }
                            }
                        }else if (filtering_option === 'customer' && index === 4 || (this.object_type === 'sp_storages' && filtering_option === 'storage_wrapper' && index === 3)){
                            is_current = true;

                            let required_id = parseInt(filtering_item_id);

                            let category_index = this.items_list[index].map(function (e) {
                                return e.id;
                            }).indexOf(required_id);

                            if (category_index >= 0){
                                this.current_groupBy = index;
                                this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
                                this.selected_item = this.items_list[this.current_groupBy][category_index];
                                if (!only_sidebar){
                                    this.getList(this.selected_item);
                                }
                            }
                        }

                        if (filtering_option === object_name && this.items_list.length > index){
                            is_current = true;
                            let required_id = parseInt(filtering_item_id);

                            let category_index = this.items_list[index].map(function (e) {
                                return e.id;
                            }).indexOf(required_id);

                            if (category_index >= 0){
                                this.current_groupBy = index;
                                this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
                                this.selected_item = this.items_list[this.current_groupBy][category_index];
                                // 2?
                                if (!only_sidebar){
                                    this.getList(this.selected_item);
                                }
                            }
                        }

                    }else if (this.filter_type_id){
                        let required_id;
                        if (this.current_groupBy_option.hasOwnProperty('no_parse_to_int') && this.current_groupBy_option.no_parse_to_int){
                            required_id = this.filter_type_id;
                        }else{
                            required_id = parseInt(this.filter_type_id);
                        }
                        let category_index = this.items_list[this.current_groupBy].map(function (e) {
                            return e.id;
                        }).indexOf(required_id);
                        if (category_index >= 0){
                            this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
                            this.selected_item = this.items_list[this.current_groupBy][category_index];
                            if (!only_sidebar){
                                this.getList(this.selected_item);
                            }
                            this.filter_type_id = null;
                        }
                    }

                    if (is_current){
                        this.current_groupBy_option = this.groupBy_options[this.current_groupBy];
                        this.treeList = this.items_list[this.current_groupBy];
                        this.is_listing_sidebar = false;
                    }

                }else{
                    this.items_list[index] = [];
                }
            }

        },
        async getCustomersListForGroup(for_sidebar= false) {
            return await new Promise( (resolve, reject) => {
                this.listLoadingCustomers = true;

                let listQuery = {
                    item_type: this.item_type,
                    item_id: this.item_id
                }
                axios({
                    method:'get',
                    url:'/api/assetflow/customers',
                    baseURL: '/',
                    params: listQuery
                }).then(response => {
                    let items_list = response.data.data.items;
                    if (!for_sidebar){
                        this.customers_list = items_list.map(function (e) {
                            return { id: e.id, name: e.name };
                        });
                        let all_item = {
                            id: null,
                            name: 'All'
                        };
                        this.customers_list.splice(0, 0, all_item);
                    }
                    resolve(response);
                }).catch(error => {
                    reject(new Error('Response Error!'));
                }).finally(() => {
                    this.listLoadingCustomers = false;
                });
            })

        },
        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            let object_type = this.object_type;
            if (this.hasOwnProperty('forced_object_type')){
                object_type = this.forced_object_type;
            }
            this.set_usermeta('preferred_' + object_type + '_layout', layout.value, message);
            this.selectedLayout = layout.value
        },

        SBHandleQueryItems(query, selected_item = null) {


            let item_type = this.item_type;
            if (this.current_entity){
                this.current_page_type = this.current_entity.current_page_type ? this.current_entity.current_page_type : 'customer';
                this.top_filter_selected_entity = this.getUserMetaValueIfExist('top_filter_selected_' + this.current_page_type);

                if (this.is_cross_entity && this.top_filter_selected_entity){
                    query[(item_type === 'sp' ? 'warehouse' : item_type) + '_id'] = this.top_filter_selected_entity;
                }else{
                    if (item_type === 'sp'){
                        query.warehouse_id = this.current_sp ? this.current_sp.id : "0";
                    }
                    if (item_type === 'customer') {
                        query.customer_id = this.current_customer ? this.current_customer.id : "0";
                    }
                }
            }

            if (this.is_group){
                if (this.selected_customer && this.selected_customer.id){
                    query.customer_id = this.selected_customer.id;
                }
                if (this.selected_status && this.selected_status.id && this.current_groupBy !== 2){
                    query.status_id = this.selected_status.name;
                }
                query.item_type = this.item_type;
            }

            if (this.selected_sp && this.selected_sp.id){
                query.warehouse_id = this.selected_sp.id;
            }

            if (this.selected_status && this.selected_status.id !== null){
                query.status_id = this.selected_status.id;
            }

            if (['orders', 'order_lines', 'managed_storages', 'activity_logs', 'asset_passports', 'nominal_codes', 'payments', 'receipts', 'sp_warehouses', 'sp_spaces', 'sp_locations', 'sp_movements', 'sp_location_types', 'sp_storages', 'sp_storage_wrappers', 'customers', 'item_status', 'location_status', 'storage_usage', 'due_date', 'order_service', 'order_type', 'sp_assignments', 'files', 'acquisitions', 'dispositions', 'suppliers', 'ledgers', 'buyer_ledgers', 'buyers', 'purchaseOrders', 'parts', 'changes', 'activity_logs', 'folders', 'categories', 'tags'].includes(this.object_type) && selected_item && selected_item.hasOwnProperty('id') && selected_item.id !== null){
                query[this.current_groupBy_option.backend_key] = selected_item.id;
            }
            if (['orders', 'order_lines'].includes(this.object_type)){
                query.just_service_types = 1;
            }



            if (this.action !== ''){
                let required_filter = this.action;

                switch (this.object_type){
                    case 'managed_storages':
                        switch (required_filter) {
                            case 'in_storage':
                                this.specific_object_name = "'In Storage' Managed Storage";
                                query.status_id = 2;
                                break;
                            case 'in_progress':
                                this.specific_object_name = "'In Progress' Managed Storage";
                                query.status_id = 1;
                                break;
                            case 'dispatched':
                                this.specific_object_name = "'Dispatched' Managed Storage";
                                query.status_id = 0;
                                break;
                        }
                        this.specific_object_name_single = this.specific_object_name;
                        break;
                    case 'orders':
                        switch (required_filter) {
                            case 'move_to_storage':
                                this.specific_object_name = 'Move to Storage Orders';
                                query.order_type = 0;
                                this.received_order_type = 0;
                                break;
                            case 'storage_orders':
                                this.specific_object_name = 'Storage Orders';
                                query.order_type = 1;
                                this.received_order_type = 1;
                                break;
                        }
                        this.specific_object_name_single = this.specific_object_name.slice(0, -1);
                        break;
                    case 'order_lines':
                        switch (required_filter) {
                            case 'move_to_storage':
                                this.specific_object_name = 'Intakes';
                                query.order_type = 0;
                                break;
                            case 'storage_orders':
                                this.specific_object_name = 'Tasks';
                                query.order_type = 1;
                                break;
                        }
                        this.specific_object_name_single = this.specific_object_name.slice(0, -1);
                        break;
                }

                if (this.specific_object_name){
                    this.title = this.specific_object_name;
                }
            }

            return query;
        },
        handleSearch: function (searchable_values, element, wordToCompare) {
            for (let key in searchable_values) {
                let searchable_value = searchable_values[key];

                let value = '';
                if (searchable_value.includes('.')) {
                    let splits = searchable_value.split('.');
                    if (splits.length === 2 && element.hasOwnProperty(splits[0])) {
                        value = element[splits[0]][splits[1]] + "";
                    }
                } else {
                    value = element[searchable_value] + "";
                }

                if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                    return true;
                }
            }
            return false;
        },
    }
}
