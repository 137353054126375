var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.title !== '')?_c('h3'):_vm._e(),_vm._v(" "),(_vm.currentUserAllRoles && _vm.currentUserAllRoles.length > 0)?_c('po-lines',{attrs:{"items":_vm.currentUserAllRoles,"object_type":'role',"line_type":'role',"keys_list":_vm.role_list_key_list},scopedSlots:_vm._u([{key:"switch_entity_actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[(item.hasOwnProperty('owner_info') &&
                              item.owner_info &&
                              (item.owner_info.id === _vm.current_entity.id) &&
                              (item.role_type === _vm.current_entity.original_entity_type) &&
                              item.module_info &&
                              (item.module_info.id === _vm.current_module.id))?_c('b-button',{attrs:{"size":"sm","href":"javascript:void(0);","disabled":"","variant":"primary"}},[_vm._v("Current "+_vm._s(_vm._f("titleize")(item.role_type)))]):_c('b-button',{attrs:{"size":"sm","href":"javascript:void(0);","variant":"primary"},on:{"click":function($event){return _vm.switchTo(item, $event)}}},[_vm._v("Switch "+_vm._s(_vm._f("titleize")(item.role_type)))])],1)]}}],null,false,1019611176)}):_c('div',{staticClass:"text-center"},[_c('h6',[_vm._v("No roles found here at the moment.")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }