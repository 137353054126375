import {MapOptions} from "@/views/assetflow/form_data/MapOptions";

export default {
    data(){
        return {
            has_address_actions_permissions: false,
            statusesList: ['in_storage', 'acquired', 'planned', 'in_storage', 'acquired', 'planned'],
            mapOptions: MapOptions,
            infoPosition: null,
            infoContent: null,
            infoOpened: false,
            infoCurrentKey: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            loqate_keys: {
                supplier: 'GD47-RC99-DK97-JG97',
                geocode: 'PF88-MG39-BN31-AF88'
            },
        }
    },
    created: function () {
    },
    methods: {
        checkCoordinates(address) {
            return address.latitude !== '' && address.longitude !== '';
        },
        getPosition: function (address) {
            return {
                lat: parseFloat(address.latitude),
                lng: parseFloat(address.longitude)
            }
        },
        getInfoWindowContent: function (address) {
            return (
                `
                    <div class="m-2">
                        <span style="font-weight: bold;">Name: </span>
                        ${address.name}
                    </div>
                    <div class="m-2">
                        <span style="font-weight: bold;">Location:  </span>
                        ${address.latitude} ${address.longitude}
                        <p>${address.description}</p>
                    </div>
                `);
        },
        toggleInfo: function (address, key) {
            this.infoPosition = this.getPosition(address);
            this.infoContent = {
                address: address,
                html: this.getInfoWindowContent(address)
            };

            if (this.infoCurrentKey === key) {
                this.infoOpened = !this.infoOpened
            } else {
                this.infoOpened = true
                this.infoCurrentKey = key
            }
        },
        onMapClick() {
            console.log("should expand address");
        },

        handleAddress(supplier, variations) {
            let keys = {
                company: 'Company',
                line_1: 'Line1',
                line_2: 'Line2',
                city: 'City',
                state: 'Province',
                zip_code: 'PostalCode',
                country: 'CountryName',
            }

            let locations = [];
            for (let af_key in keys) {
                let supplier_key = keys[af_key];

                let value = supplier[supplier_key];
                this.addForm[af_key] = value;
                if (af_key !== 'company' && value !== ''){
                    locations.push(value) ;
                }
            }

            this.getAddressLocation(supplier, locations.join(", "), supplier.CountryName);
        },
        getAddressLocation(supplier, location_str, selectedCountryName=''){
            let post_params = {
                Key: this.loqate_keys.geocode,
                Location: location_str
            };
            var params = '';
            params += "?Key=" + encodeURIComponent(post_params.Key);
            params += "&Location=" + encodeURIComponent(post_params.Location);

            let url = "https://api.addressy.com/Geocoding/UK/Geocode/v2.10/json3.ws";

            if (selectedCountryName !== 'United Kingdom'){
                url = 'https://api.addressy.com/Geocoding/International/Geocode/v1.10/json3.ws';
                post_params.Country = selectedCountryName;
                params += "&Country=" + encodeURIComponent(post_params.Country);
            }

            axios.post(url+params, post_params)
                .then((response) => {
                    let data = response.data;

                    // Test for an error
                    if (data.Items.length === 1 && typeof(data.Items[0].Error) != "undefined") {
                        // Show the error message
                        console.log("data.Items[0].Description");
                        console.log(data.Items[0].Description);
                    }else {
                        // Check if there were any items found
                        if (data.Items.length !== 0) {
                            let found_object = data.Items[0];
                            this.addForm.latitude = found_object.Latitude;
                            this.addForm.longitude = found_object.Longitude;
                        }
                    }

                });
        },
        setupAddressFieldsWithLoqate(item_name='address') {
            var fields = [
                {element: "selected_addresses", field: ""},
                {element: (item_name + "_company"), field: "Company", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE},
                {element: (item_name + "_line_1"), field: "Line1"},
                {element: (item_name + "_line_2"), field: "Line2", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE},
                {element: (item_name + "_city"), field: "City", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE},
                {element: (item_name + "_state"), field: "Province", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE},
                {element: (item_name + "_zip_code"), field: "PostalCode", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE},
                {element: (item_name + "_country"), field: "CountryName", mode: pca.fieldMode.DEFAULT | pca.fieldMode.PRESERVE}
            ];

            var options = {
                key: this.loqate_keys.supplier,
                setCountryByIP: false,
                GeoLocationEnabled: false,
                suppressAutocomplete: false
            }

            window[item_name+'_control'] = new pca.Address(fields, options);
            window[item_name+'_control'].listen("populate", this.handleAddress);
        },
        checkAddressStatus(address){
            return parseInt(address.assets_sum_quantity) !== 0;
        },

    }
}
