import {StatusesList} from "../views/assetflow/assets/Extras/StatusesList";
import {Currencies} from "../views/assetflow/form_data/Currencies";

export default {
    data(){
        return {
            with_used_file: false,
            statusesList: [],
            customer_meta:{},
            features_dict:{},
            settings_dict:{},
            isSuperAdmin:null,
            selected_entity_id:0,
            image_sizes:{},
            user_info: {
                has_current_customer: false,
                has_current_sp: false
            },
            current_module: null,
            is_managed_storage_module: null,
            filtering_modules: [],
            cancelTokens: [],
            selected_filtering_module: null,
            selected_filtering_module_id: null,
            current_module_info: null,
            has_departments: null,
            has_all_departments: null,
            user_has_many_departments: null,
            current_department_id: null,
            current_customer: null,
            current_entity: null,
            current_sp: null,
            current_assetflow: null,
            current_group: null,
            sectors_list: [],
            is_tv_film_asset:false,
            is_group_tv_film:false,
            is_demo_industry_asset: false,
            currencies_list: Currencies,
            assetTypesSelects: ['model', 'type_of_item', 'condition', 'material'],
            meta_keys_for_acquisition: ['acquisition_type', 'asset_liability', 'acquired_using', 'supplier_id', 'po_id'],
            keys_for_acquisition: [],
            lists_values: null,
            buttonkey: 0,
            is_saving_enabled: true,
            is_cross_entity: false,
            is_sp_or_cross_or_admin: false,

        }
    },
    created: function () {
        this.image_sizes = window && window.hasOwnProperty('IMAGE_SIZES') ? window.IMAGE_SIZES : {};

        this.statusesList = StatusesList.map(function(status) { return status.name });

        this.user_info = window.USER_INFO ?? this.user_info;


        this.isSuperAdmin = this.user_info.isSuperAdmin ?? false;

        if (this.user_info.hasOwnProperty('customer_meta')){
            this.customer_meta = this.user_info.customer_meta;
        }

        this.current_customer = this.user_info.hasOwnProperty('current_customer') ? this.user_info.current_customer : null;

        this.current_entity = this.user_info.hasOwnProperty('current_entity') ? this.user_info.current_entity : null;

        let entity_item_type;
        if(this.current_entity){
            this.is_cross_entity = this.current_entity.is_cross_entity;
            entity_item_type = this.current_entity.entity_item_type;
            this.is_sp_or_cross_or_admin = !this.is_cross_entity && entity_item_type !== 'customer';
        }
        if (this.isSuperAdmin){
            this.is_sp_or_cross_or_admin = true;
        }

        if (this.user_info.hasOwnProperty('current_module')){
            this.current_module = this.user_info.current_module;
            this.is_managed_storage_module = this.current_module.hasOwnProperty('is_managed_storage') && this.current_module.is_managed_storage;

            this.current_module_info = this.user_info.current_module;
            this.has_departments = this.current_module_info.has_departments;
            this.has_all_departments = this.current_module_info.has_all_departments;
            this.user_has_many_departments = this.has_all_departments || (this.current_module_info.hasOwnProperty('departments_count') ? (this.current_module_info.departments_count > 1) : false);
            if (this.has_all_departments){
                this.current_department_id = this.current_module_info.current_department_id;
            }

            if (this.current_module_info.hasOwnProperty('other_filtering_modules') &&
                this.current_module_info.other_filtering_modules
            ){
                this.filtering_modules = this.current_module_info.other_filtering_modules;

                if (this.user_info.user_meta.hasOwnProperty('selected_filtering_module_id')) {
                    this.selected_filtering_module_id = this.user_info.user_meta.selected_filtering_module_id;
                    this.selected_filtering_module = this.get_selected_filtering_module(this.selected_filtering_module_id);
                }
            }
        }

        let current_user_info = this.user_info;
        if (current_user_info && current_user_info.hasOwnProperty('user_meta') &&
            current_user_info.user_meta &&
            current_user_info.user_meta.hasOwnProperty('selected_entity_id') &&
            current_user_info.user_meta.selected_entity_id){
            this.selected_entity_id = current_user_info.user_meta.selected_entity_id;
        }


        this.sectors_list = [];
        this.is_tv_film_asset = false;
        this.is_demo_industry_asset = false;
        if (this.current_customer){
            this.sectors_list = this.current_customer.sector_ids;
            if (this.sectors_list){
                this.is_tv_film_asset = this.sectors_list.includes('1');
                this.is_demo_industry_asset = this.sectors_list.includes('2');
            }

            if (this.current_customer.hasOwnProperty('settings_dict')){
                this.settings_dict = this.current_customer.settings_dict;
            }
            if (this.current_customer.hasOwnProperty('features_dict')){
                this.features_dict = this.current_customer.features_dict;
            }
        }


        this.current_sp = this.user_info.hasOwnProperty('current_sp') ? this.user_info.current_sp : null;
        this.current_assetflow = this.user_info.hasOwnProperty('current_assetflow') ? this.user_info.current_assetflow : null;

        this.current_group = this.user_info.hasOwnProperty('current_group') ? this.user_info.current_group : null;
        if (this.current_group){
            if (this.current_group.sector_ids){
                this.is_group_tv_film = this.current_group.sector_ids.includes('1');
            }
        }

    },
    methods: {
        getUserMetaValueIfExist(meta_key){

            let current_user_info = this.user_info;
            if (current_user_info && current_user_info.hasOwnProperty('user_meta') &&
                current_user_info.user_meta &&
                current_user_info.user_meta.hasOwnProperty(meta_key) &&
                current_user_info.user_meta[meta_key] !== ''){
                return current_user_info.user_meta[meta_key];
            }
            return '';
        },
        resetCurrentPage(changing_type, selected_item){
            if (changing_type !== 'filter' && this.selected_item !== selected_item){
                this.selected_item = selected_item;
                this.perpage.currentPage = 1;
            }
        },
        addPaginationParams(required_query = {}){
            if (required_query.hasOwnProperty('page') && required_query.hasOwnProperty('limit') && this.hasOwnProperty('perpage')){
                required_query.page = this.perpage.currentPage;
                required_query.limit = this.perpage.perPage;
                required_query.sort_by = this.sorting.sortType.name;
                required_query.sort_dir = this.sorting.sortType.reversed ? 'desc' : 'asc';
            }
            if (this.hasOwnProperty('item_type') && this.item_type){
                required_query.current_page_type = this.item_type;
            }
            return required_query;
        },
        refreshContent: function () {
            this.perpage.currentPage = 1;
            if (this.hasOwnProperty('getList')){
                this.getList(this.selected_item);
            }
        },
        getObjectPathFrom(object_name){
            switch (object_name) {
                case 'App\\Asset':
                    return 'assets';
                case 'App\\Change':
                    return 'changes';
                case 'App\\Character':
                    return 'characters';
                case 'App\\Acquisition':
                    return 'acquisitions';
                case 'App\\Disposition':
                    return 'dispositions';
                default:
                    return '';
            }

        },
        async uploadFileToS3(file, progressHandler, once_only) {

            if (once_only){
                if (this.cancelTokens['excel_import']) {
                    this.cancelTokens['excel_import'].cancel('Start new process, stop active process');
                }

                this.cancelTokens['excel_import'] = axios.CancelToken.source();
            }

            return new Promise( (resolve, reject) => {
                // Stream the file to S3
                Vapor.store(file, {
                    // visibility: 'public-read',
                    cancelToken: once_only ? this.cancelTokens['excel_import'].token : '',
                    progress: progressHandler
                }).then(response => {
                    if (response){
                        resolve(response);
                    }else{
                        reject();
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });

            });
        },
        saveFileToS3: function (file, index=null, assets_list = 'filled_asset_images', item_type='asset', container='addForm') {

            let file_id = file.id;

            if (this.cancelTokens[file_id]) {
                this.cancelTokens[file_id].cancel('Start new process, stop active process');
            }

            this.cancelTokens[file_id] = axios.CancelToken.source();

            Vapor.store(file, {
                cancelToken: this.cancelTokens[file_id].token,
                progress: progress => {
                    let upload_progress = Math.round(progress * 100);
                    this.is_saving_enabled = false;

                    if (file.previewElement) {
                        let progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                        progressElement.style.width = upload_progress + "%";
                        progressElement.setAttribute("aria-valuenow", upload_progress);

                        if (upload_progress === 100){

                                this.$nextTick(() => {
                                    let that = this;
                                    setTimeout(function(){
                                        progressElement.textContent = 'Done';
                                        that.is_saving_enabled = true;
                                    }, 3000);
                                });

                        }else{
                            progressElement.textContent = upload_progress + "%";
                        }
                    }

                    file.upload_progress = upload_progress;

                }
            }).then(response => {

                if (response.hasOwnProperty('uuid')) {
                    let file_item = {
                        item_type: item_type,
                        id: file.id,
                        uuid: response.uuid,
                        key: response.key,
                        dataURL: response.key,
                        bucket: response.bucket,
                        name: file.name,
                        width: file.width,
                        height: file.height,
                        size: file.upload ? file.upload.total : 0,
                        upload_progress: file.upload_progress,
                    };

                    if (this.with_used_file){
                        file_item.used_file = file;
                    }

                    if (file.hasOwnProperty('previewElement')){
                        file_item.previewElement = file.previewElement;
                    }

                    if (container !== ''){
                        if (index !== null){
                            this[container][assets_list][index] = file_item;
                        }else{
                            this[container][assets_list] = file_item;
                        }
                    }else{
                        if (index !== null){
                            this[assets_list][index] = file_item;
                        }else{
                            this[assets_list] = file_item;
                        }
                    }


                }
            });


        },
        get_selected_filtering_module(selected_filtering_module_id) {
            if (this.filtering_modules){
                let filtering_module_index = this.filtering_modules.map(function (e) {
                    return e.id;
                }).indexOf(selected_filtering_module_id);

                if (filtering_module_index >= 0) {
                    let selected_filtering_module = this.filtering_modules[filtering_module_index];
                    this.selected_filtering_module = selected_filtering_module;
                    return selected_filtering_module;
                }
            }
            return null;
        },
        getAcquisitionStatus(status, classes_only=false){
            if (classes_only){
                let classes_list = {};
                switch (status) {
                    case 0:
                        classes_list = {
                            'status-btn-disposed': true,
                            'acquisition-created': true,
                        }
                        break;
                    case 1:
                        classes_list = {
                            'status-btn-planned': true,
                            'acquisition-open': true,
                        }
                        break;
                    case 2:
                        classes_list = {
                            'status-btn-in_progress': true,
                            'acquisition-in_progress': true,
                        }
                        break;
                    case 3:
                        classes_list = {
                            'status-btn-acquired': true,
                            'acquisition-awaiting_completion': true,
                        }
                        break;
                    case 4:
                        classes_list = {
                            'status-btn-completed': true,
                            'acquisition-completed': true,
                        }
                        break;
                }
                return classes_list;
            }

            let statusesLst = [
                'Created',
                'Open',
                'In Progress',
                'Awaiting Completion',
                'Completed'
            ];
            return statusesLst[status];
        },
        POWithUIDs({ id, uid, reference, name }){
            if (uid === '') {
                return '';
            }
            return `${uid} ` + (reference !== '' ? `(Ref: ${reference})` : '');
        },
        getTitleFor(item_info, item_type){
            if (item_info){
                if (['folders', 'categories', 'tags'].includes(item_type)){
                    let list_single_name_capitalized = Vue.options.filters.titleizeToSingle(item_type);
                    let list_name_lowercased = list_single_name_capitalized.toLowerCase();
                    let names_info_name = list_name_lowercased + '_names_info';
                    if (item_info.hasOwnProperty(names_info_name) && item_info[names_info_name] && item_info[names_info_name].length){
                        return list_single_name_capitalized + ' List > ' + item_info[names_info_name].join(" > ");
                    }
                    return list_single_name_capitalized + ' List';
                }else if (item_type === 'changes'){
                    if (item_info.hasOwnProperty('character_info') && item_info.character_info){
                        return '('+item_info.character_info.character_number+') ' +item_info.character_info.name + ': Change ' +item_info.name;
                    }
                    return item_info.name !== '' ? item_info.name : 'CHG-' + item_info.uid;
                }else if (item_type === 'parts'){
                    if (item_info.hasOwnProperty('scene_info') && item_info.scene_info){
                        return '<a class="text-dark" href="/' + item_info.current_page_type + '/parts?queue=scenes&id='+item_info.scene_info.id+'">' + item_info.scene_info.name + ((item_info.name !== '' && item_info.name !== null) ? ' ' + item_info.name : '') + '</a>';
                    }
                    return item_info.name !== '' ? item_info.name : 'PRT-' + item_info.uid;
                }
            }
            if (['folders', 'categories', 'tags'].includes(item_type)){
                return Vue.options.filters.titleizeToSingle(item_type) + ' List';
            }
            return '';
        },
        reloadPage(new_item){
            let message = new_item ? ('Saved Successfully') : '';
            window.flash(message);
            location.reload();
        },
        getHtmlTitleFor(item_type){
            let item_info = this.item_info;
            if (item_info && item_info.uid !== ''){
                switch (item_type){
                    case 'acquisitions':
                        let acquisition_class_name = 'bx ' + (item_info.ownership_type === 1 ? 'bx-cube-alt' : 'bx-time');
                        return '<i class="' + acquisition_class_name + '"></i> ' + item_info.uid;
                    case 'dispositions':
                        let dispositions_class_name = 'bx bx-cube-alt';
                        return '<i class="' + dispositions_class_name + '"></i> ' + item_info.uid;
                    case 'sp_storages':
                        return Vue.options.filters.iconize(item_info.name, 'sp_storages');
                    case 'sp_locations':
                        return Vue.options.filters.iconize((item_info.hasOwnProperty('full_location_name') ? item_info.full_location_name : item_info.name), 'sp_locations');
                    case 'changes':

                        let html = '<span class="character-change">';

                        let character_image = '';
                        let character_name = '';
                        if (item_info.hasOwnProperty('character_info') && item_info.character_info){
                            if (item_info.character_info.hasOwnProperty('avatar_name') && item_info.character_info.avatar_name !== ''){
                                character_image = '<img class="rounded-circle character-image header-profile-user" src="' + item_info.character_info.avatar_name +'" alt="' + item_info.character_info.name + '"/>';
                            }
                            character_name = '<span class="character-name">' + item_info.character_info.name + '</span>: ';
                        }

                        html += character_image + ' <span class="change"><a class="text-dark" href="/' + item_info.current_page_type + '/changes/'+item_info.id+'/view">' + character_name + item_info.name + '</a></span>';
                        html += '</span>';
                        return html;

                    case 'part_changes':

                        let change_info = item_info.hasOwnProperty('change_info') && item_info.change_info;
                        let part_info = item_info.hasOwnProperty('part_info') && item_info.part_info;

                        let part_name = '';
                        if (part_info){
                            part_name = ': Scene ' + (part_info.hasOwnProperty('scene_info') && part_info.scene_info ? part_info.scene_info.name + ' ' : '') + (part_info.name || '');
                        }
                        if (change_info && change_info.hasOwnProperty('character_info') && change_info.character_info){
                            return '('+change_info.character_info.character_number+') ' +change_info.character_info.name + ': Change #' +change_info.name + part_name;
                        }
                        return 'Scene Notes';

                    case 'asset_passport':
                        let asset_passport_class_name = 'bx bx-cube-alt';
                        let uid = '';
                        let item_name = '';
                        if (item_info.hasOwnProperty('cAFID_info')){
                            uid = item_info.cAFID_info.uid;
                            item_name = 'passport_item';
                        }else if (this.cAFID_info){
                            uid = this.cAFID_info.uid;
                            item_name = 'cAFID_info';
                        }else if (this.is_asset_passport){
                            uid = item_info.uid;
                            item_name = 'passport_item';
                        }
                        if (uid !== ''){
                            uid = (""+uid).replace('PASS-', '');
                            if (this[item_name] && this[item_name].hasOwnProperty('universal_asset_name') && this[item_name].universal_asset_name){
                                return '<i class="' + asset_passport_class_name + '"></i> PASS-' + uid + ': ' + this[item_name].universal_asset_name;
                            }else{
                                return '<i class="' + asset_passport_class_name + '"></i> PASS-' + uid;
                            }
                        }
                }
            }
            return '';
        },
        getPoLineClassFor(item, line_type){
            let asset_classes = {};
            if (line_type === 'assets'){
                asset_classes = this.getAssetClasses(item);
            }else if (line_type === 'sp_movements'){
                if (item.hasOwnProperty('is_source') && item.is_source){
                    asset_classes['is_source'] = true;
                }else{
                    asset_classes['is_target'] = true;
                }
            }else if (line_type === 'transactions'){
                if (item.hasOwnProperty('item_type')){
                    asset_classes['transaction-' + item.item_type +'-line'] = true;
                }
            }
            asset_classes['table_inner_body'] = true;
            asset_classes['d-flex-p'] = true;
            return asset_classes;
        },
        getAssetClasses(asset, selected_asset_id){
            return {
                'active_check': asset.id === selected_asset_id,
                'ownership-status-current' : !!asset.ownership_status,
                'ownership-status-ended' : !asset.ownership_status,
                'asset-added-to-order' : !!(asset.hasOwnProperty('latest_open_orderline_order') && asset.latest_open_orderline_order), // to confirm getting a truthy value
                'asset-is-locked': this.checkIfAssetIsLocked(asset)
            };
        },
        setDefaultPerPage(perPage){
            return perPage === undefined ? 25 : perPage;
        },
        checkIfAssetIsLocked(asset){
            if (asset){
                if(asset.hasOwnProperty('is_locked')){
                    return asset.is_locked === 1;
                }else{
                    let checkingKeys = ['asset_group_id', 'latest_open_orderline_order', 'storage_status'];
                    for (const checkingKey in checkingKeys) {
                        let checking_key = checkingKeys[checkingKey];
                        if (asset.hasOwnProperty(checking_key) && asset[checking_key]){
                            return true;
                        }
                    }

                    if(asset.hasOwnProperty('reserved_quantity') && asset.reserved_quantity !== '' && asset.reserved_quantity > 0){
                        return true;
                    }
                    if(asset.hasOwnProperty('ownership_status') && asset.ownership_status === 0){
                        return true;
                    }
                }

            }
            return false;
        },
        getReasonsForLockingAsset(asset){
            let reasons = [];
            let asset_group_link = '';
            if (asset.hasOwnProperty('asset_group_info') && asset.asset_group_info){
                asset_group_link = ' (<a href="/' + this.item_type +'/asset_groups/'+asset.asset_group_info.id+'/view">GRP-' + asset.asset_group_info.uid + '</a>)';
            }
            let order_link = '';
            if (asset.hasOwnProperty('latest_open_orderline_order') && asset.latest_open_orderline_order){
                order_link = ' (<a href="/' + this.item_type +'/orders/'+asset.latest_open_orderline_order.id+'/view">ORD-' + asset.latest_open_orderline_order.uid + '</a>)';
            }

            let sp_link = '';
            if (asset.hasOwnProperty('warehouse_name') && asset.warehouse_name){
                sp_link = ' (<a href="/' + this.item_type +'/storage_providers/'+asset.warehouse_id +'/view">' + asset.warehouse_name + '</a>)';
            }

            let reason_info = {
                asset_group_id: {
                    message: 'Part of an Asset Group' + asset_group_link
                },
                latest_open_orderline_order: {
                    message: 'Added to Order' + order_link
                },
                storage_status: {
                    message: 'In Managed Storage' + sp_link
                },
                reserved_quantity: {
                    message: 'Has Reserved Stock'
                },
                ownership_status: {
                    message: 'Has \'Ended\' Ownership Status'
                },
            };

            if (asset){
                let checkingKeys = ['asset_group_id', 'latest_open_orderline_order', 'storage_status'];
                for (const checkingKey in checkingKeys) {
                    let checking_key = checkingKeys[checkingKey];
                    if (asset.hasOwnProperty(checking_key) && asset[checking_key]){
                        reasons.push(reason_info[checking_key]);
                    }
                }
                if(asset.hasOwnProperty('reserved_quantity') && asset.reserved_quantity !== '' && asset.reserved_quantity > 0){
                    reasons.push(reason_info['reserved_quantity']);
                }
                if(asset.hasOwnProperty('ownership_status') && asset.ownership_status === 0){
                    reasons.push(reason_info['ownership_status']);
                }
            }
            return reasons;
        },
        setPlaceholderImage(event){
            if (event.target.src !== window.AF_DEFAULT_IMAGE){
                event.target.src = window.AF_DEFAULT_IMAGE; //https://aasetflow-php8.tst/af_image_path/images/default-asset.jpg
            }
        },
        getFolderObject(folders, level = 1, item_type='folders', value_id='value', text_id='text'){
            var allFoldersObjects = [];
            if (folders.length){
                for (let i = 0; i < folders.length; i++) {
                    let folder = folders[i];

                    let folderObject = {};
                    folderObject[value_id] = folder.id;
                    folderObject.plain_name = folder.name;
                    let repeater;
                    if (item_type === 'categorys' && folder.hasOwnProperty('icon') && folder.icon ){
                        repeater = '&nbsp;&nbsp;'.repeat(level) + '<i class="'+folder.icon+' font-size-13"></i>';
                    }else{
                        repeater = '-'.repeat(level);
                    }

                    let folder_name = folder.name;
                    if (item_type === 'categorys' && level === 1){
                        folder_name = '<strong>'+folder_name+'</strong>';
                    }

                    folderObject[text_id] = folder.id !== 0 ? (repeater + ' ' + folder_name) : folder_name;


                    if (item_type === 'categorys'){
                        folderObject.category = folder;

                    }
                    if (item_type === 'categories'){
                        folderObject.category = folder;
                        folderObject.level = level;
                    }
                    allFoldersObjects.push(folderObject);
                    let hierarchy = null;

                    if (item_type === 'addresses' || item_type === 'categories'){
                        if (folder.subfolders){
                            hierarchy = folder.subfolders;
                        }
                    }else{
                        if (folder.hierarchy){
                            hierarchy = folder.hierarchy;
                        }
                    }

                    if (hierarchy){
                        allFoldersObjects = allFoldersObjects.concat(this.getFolderObject(hierarchy, level + 1, item_type, value_id, text_id));
                    }
                }
            }
            return allFoldersObjects;
        },

        getDictValues(obj, key) {
            if (!obj){
                return [];
            }
            if (key === 'asset_type'){
                return Object.keys(obj).map(function(e) {
                    return {id: obj[e].id, name: obj[e].name}
                });
            }

            return Object.values(obj) || Object.keys(obj).map(function(e) {
                return obj[e]
            });
        },
        getValueFromObjectIfExists(property, required_object, value='name', default_val='', is_multi=false) {
            let required_obj = required_object.hasOwnProperty(property) && required_object[property] ? required_object[property] : null;
            if (!required_obj) { return default_val; }

            if (is_multi){
                return required_obj.map(item => { return item[value]; }).join(', ');
            }
            return value === 'object_only' ? required_obj : required_obj[value];
        },
        getObjectOf(value_id, required_list, id_name='id', is_case_sensitive= false, removed_text = '', or_id=null) {
            let index;
            if (is_case_sensitive && value_id){
                index = required_list.map(function (e) {
                    // e[id_name] = e[id_name].replace(/<\/[^>]+(>|$)/g, '').trim();
                    if (removed_text !== ''){
                        let re = new RegExp(removed_text, 'g');
                        return (e[id_name] + "").replace(re,'').trim().toLowerCase();
                    }
                    return (e[id_name] + "").toLowerCase();
                }).indexOf((value_id + "").toLowerCase());
            }else{
                index = required_list.map(function (e) {
                    return e[id_name] + "";
                }).indexOf(value_id + "");
            }

            if (required_list[index]){
                return required_list[index];
            }

            if (or_id !== null){
                if (['yes'].includes((value_id + "").toLowerCase())){
                    value_id = 1;
                }else if (['no'].includes((value_id + "").toLowerCase())){
                    value_id = 0;
                }
                return this.getObjectOf(value_id, required_list, or_id, is_case_sensitive, removed_text);
            }
            return null;
        },
        getIDs(required_array, to_string=false, required_value) {
            if (required_array){
                return required_array.map(item => {
                    if (required_value){
                        if (to_string){
                            return item[required_value] + '';
                        }
                        return item[required_value];
                    }
                    if (to_string){
                        return item.id + '';
                    }
                    return item.id;
                });
            // }else{
            //     console.log("required_array");
            //     console.log(required_array);
            //
            //     console.log("'somethign is wrong'");
            //     console.log('somethign is wrong');

            }
            return null;
        },
        getValueOf(value_name, value_id, is_multi = false, id_name='id') {
            var required_list = this.lists_values[value_name];

            let asset_id_values = [
                'asset_usage',
                'is_hero',
                'is_asset',
                'status',
                'bss_status',
                'fdp_status',
                'valuation_method',
                'asset_liability',
                'waste_stream_type',
                'ownership_type'
            ];

            if (value_name === 'asset_type') {
                required_list = this.getDictValues(required_list, value_name);
            } else if (this.assetTypesSelects.includes(value_name)) {
                // i.e. 'model', 'type_of_item', 'condition', 'material'
                let selected_asset_type = this.addForm.asset_meta.asset_type;
                if (this.lists_values.hasOwnProperty('asset_type') &&
                    this.lists_values.asset_type &&
                    this.lists_values.asset_type[selected_asset_type] &&
                    this.lists_values.asset_type[selected_asset_type].hasOwnProperty('asset_template_fields')
                ){
                    let meta_values = this.lists_values.asset_type[selected_asset_type].asset_template_fields;
                    let index = meta_values.map(function (e) {
                        return e.name;
                    }).indexOf(value_name);

                    if (index >= 0){
                        required_list = meta_values[index].options;
                    }else{
                        required_list = [];
                    }

                }else{
                    required_list = [];
                }

                id_name = 'name';

            } else if (Object.keys(this.keys_for_acquisition).includes(value_name) && this.required_fields.hasOwnProperty(this.object_type)) {
                required_list = this.required_fields[this.object_type][value_name].options;
            } else if (this.meta_keys_for_acquisition.includes(value_name) || (asset_id_values.includes(value_name))) {
                required_list = this.required_fields[value_name].options;
            }

            if (required_list) {
                if (is_multi) {
                    var foundItems = [];
                    for (const v_id in value_id) {
                        let foundItem = this.getObjectOf(value_id[v_id], required_list, id_name);
                        if (foundItem) {
                            foundItems.push(foundItem);
                        }
                    }
                    return foundItems;
                } else {
                    let foundItem = this.getObjectOf(value_id, required_list, id_name);

                    if (foundItem) {
                        return foundItem;
                    }
                }
            }
            return null;
        },
        getOptionsForKey(key, object_type='assets', with_add_new= [], used_required_fields=null) {

            let required_fields = used_required_fields ? used_required_fields : this.required_fields;


            if (!['assets', 'acquisition_modal'].includes(object_type)){
                required_fields = required_fields[object_type];
            }


            let lists_values = this.lists_values || this.getAssetInfoUsingKey('lists_values', 'this');
            if (!lists_values || !required_fields){
                return;
            }

            if (!required_fields.hasOwnProperty(key)){
                return;
            }


            let options_list = lists_values[key];
            if (key === 'asset_type') {
                options_list = this.getDictValues(options_list, key);
            }

            if (options_list) {
                let new_option = {
                    id: -99,
                    name: 'Add New'
                };
                let add_new_option = [];
                let all_with_new_option = ['department_id', 'address_id', 'location_id', 'events', 'disposition_type', 'acquisition_type', 'final_disposition_planning', 'payment_purchase_order_id', 'buyer_ledger_id'];
                if (
                    with_add_new &&
                    (with_add_new.length > 0 && with_add_new.includes(key) ||
                    all_with_new_option.includes(key))
                ) {
                    add_new_option.push(
                        new_option
                    )
                }


                let options;
                if (['final_disposition_planning', 'disposition_type'].includes(key)){
                    options = options_list.concat(add_new_option);
                }else{
                    options = add_new_option.concat(options_list);
                }
                if (required_fields.hasOwnProperty(key) && required_fields[key]){
                    required_fields[key].options = options;
                }


            } else {
                required_fields[key].options = [];
            }


            if (object_type !== 'assets'){
                this.required_fields[object_type] = required_fields;
            }else{
                this.required_fields = required_fields;
            }


        },
        setupAddressLookup(item_name="address") {
            setTimeout(function () {
                if (window[item_name + '_control']) {
                    window[item_name + '_control'].load();
                } else {
                    console.log("window.address_control is not defined!");
                }
            }, 300);
        },
        getQueryVars(){
            let uri = window.location.href.split('?');
            let getVars = {};
            if (uri.length === 2)
            {
                let vars = uri[1].split('&');
                let tmp = '';
                vars.forEach(function(v){
                    tmp = v.split('=');
                    if(tmp.length === 2)
                        getVars[tmp[0]] = tmp[1];
                });
            }
            return getVars;
        },
        setPerPageOptions(pageOptions, preferredItemsPerPage){
            let editedPageOptions = pageOptions;
            if ((editedPageOptions.filter(function(e) { return e.value === preferredItemsPerPage; }).length === 0) && preferredItemsPerPage !== 0) {
                editedPageOptions.splice(0, 0, {value: preferredItemsPerPage, text: preferredItemsPerPage});
            }
            return editedPageOptions;
        },
        setPerPage(new_value){
            this.perPage = new_value;
        },
        set_usermeta(key, value, success_message, with_reload = false, then_function = null) {
            this.set_item_meta('users', key, value, success_message, with_reload, then_function);
        },

        set_item_property_new(item_type, item_id, property_name, property_value, other_info = null, then_function = null){

            let params = {
                item_id_name: 'id',
                item_id: item_id,
                property_name: property_name,
                property_value: property_value
            };
            let msg = 'Updated ' + Vue.options.filters.titleizeToSingle(item_type) + ' #' + item_id + ' Successfully.' ;//response.data.data.msg;
            if (other_info){
                params = _.merge(params, other_info);
                if (other_info.hasOwnProperty('success_message')){
                    msg = other_info.success_message;
                }
            }
            axios({
                method:'post',
                url:'/api/assetflow/'+item_type+'/set_item_property',
                baseURL: '/',
                data: params
            }).then(response => {
                let status = response.data.data.status;
                let server_message = response.data.data.msg;

                this.presentToast((status ? "Success!" : "Error!"),
                    status ? msg : server_message,
                    (status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);

                if (status){
                    if (then_function){
                        then_function();
                    }else{
                        location.reload();
                    }
                }
            })
        },
        handleSavingMetaResponse: function (success_message, response, with_reload, then_function = null) {
            if (success_message !== '') {
                if (response.data.saved === 1) {
                    this.presentToast("Success",
                        success_message !== '' && success_message !== 'data.msg' ? success_message : response.data.msg,
                        'success',
                        1500,
                        true,
                        true);
                } else {
                    this.presentToast("Error!",
                        response.data.msg,
                        'danger',
                        3000,
                        true,
                        true);
                }
            }

            if (then_function !== null){
                then_function();
            }else if (with_reload === '/'){
                window.location.href = '/';
            } else if (with_reload) {
                location.reload();
            }
        },
        set_item_meta(metable_name, key, value, success_message='', with_reload = false, then_function = null) {
            axios({
                method:'post',
                url:'/api/assetflow/'+metable_name+'/save_meta_value',
                baseURL: '/',
                data: {
                    meta_key: key,
                    meta_value: value,
                },
            }).then(response => {
                this.handleSavingMetaResponse(success_message, response, with_reload, then_function);
            }).catch(error => {
                console.log(error);
            });

        },
        set_item_metas(metable_name, object, success_message='', with_reload = false) {
            axios({
                method:'post',
                url:'/api/assetflow/'+metable_name+'/save_meta_values',
                baseURL: '/',
                data: {
                    meta_object: object
                },
            }).then(response => {
                this.handleSavingMetaResponse(success_message, response, with_reload);
            }).catch(error => {
                console.log(error);
            });

        },
        save_settings(metable_name, object, success_message='', with_reload = false) {
            axios({
                method:'post',
                url:'/api/assetflow/'+metable_name+'/save_settings',
                baseURL: '/',
                data: {
                    settings_object: object
                },
            }).then(response => {
                this.handleSavingMetaResponse(success_message, response, with_reload);
            }).catch(error => {
                console.log(error);
            });

        },
        sortFunction: (sortyType, list= null) => {

            const key = sortyType.name;
            const reverse = sortyType.reversed;

            const first_criteria = sortyType.first_criteria;
            const first_reversed = sortyType.first_reversed;

            const second_criteria = sortyType.second_criteria;
            const second_reversed = sortyType.second_reversed;

            const third_criteria = sortyType.hasOwnProperty('third_criteria') ? sortyType.third_criteria : null;
            const third_reversed = sortyType.hasOwnProperty('third_reversed') ? sortyType.third_reversed : null;



            const moveSmaller = reverse ? 1 : -1;
            const moveLarger = reverse ? -1 : 1;
            const isDate = ['updated_at', 'created_at', 'shoot_day_date'].includes(key);

            const isStatus = key === 'status';


            let getFilterFor = (first_criteria, a, b, reverse) => {
                const moveSmaller = reverse ? 1 : -1;
                const moveLarger = reverse ? -1 : 1;

                let fc = false;
                if (first_criteria) {
                    var a_fc_key = a[first_criteria];
                    var b_fc_key = b[first_criteria];
                    if (a_fc_key < b_fc_key) {
                        fc = moveSmaller;
                    }
                    if (a_fc_key > b_fc_key) {
                        fc = moveLarger;
                    }
                }
                return fc;
            }


            return (a, b) => {
                var a_key = a[key];
                var b_key = b[key];

                if (isStatus && list){
                    a_key = list.indexOf(a_key);
                    b_key = list.indexOf(b_key);
                }else if (isDate){
                    a_key = new Date(a_key);
                    b_key = new Date(b_key);
                }

                var fc = false;
                if (first_criteria){
                    fc = getFilterFor(first_criteria, a, b, first_reversed);
                }

                var sc = false;
                if (second_criteria){
                    sc = getFilterFor(second_criteria, a, b, second_reversed);
                }

                var tc = false;
                if (third_criteria){
                    tc = getFilterFor(third_criteria, a, b, third_reversed);
                }

                if (a_key < b_key) {
                    return fc ? fc : (moveSmaller ? moveSmaller : (sc ? sc : (moveSmaller ? moveSmaller : tc)));
                }
                if (a_key > b_key) {
                    return fc ? fc : (moveLarger ? moveLarger : (sc ? sc : (moveLarger ? moveLarger : tc)));
                }

                return fc ? fc : (sc ? sc : tc);
            };
        },
        getAssetStatusFrom(asset){
            let status_text = asset.status;

            if (asset.hasOwnProperty('disposition_info') && asset.disposition_info && asset.disposition_info.name !== ''){
                status_text = asset.disposition_info.name;
            }

            return '<span class="status-btn status-btn-'+asset.status+'">' + Vue.options.filters.titleize(status_text) + '</span>';
        },
        handleResponse: function (response, success_message, with_reload=false, force_saved=false, then_action=null) {
            if (success_message === ''){
                return;
            }
            let saved = response.data.saved === true || response.data.saved > 0;
            saved = saved || force_saved;
            if (saved) {
                this.presentToast("Success",
                    success_message,
                    'success',
                    1500,
                    true,
                    true);

                if (then_action){
                    then_action();
                }
            } else {
                this.presentToast("Error!",
                    response.data.msg,
                    'danger',
                    3000,
                    true,
                    true);
                this.buttonkey++;
            }
            if (with_reload) {
                location.reload();
            }
        },
        getItemPostTitle(item_info, item_post, item_type='Asset', current_page_type=''){
            let asset_name = item_info ? item_info.name : '';
            let title = 'updated '+item_type+' ' + asset_name;

            if (!item_post) { return '';}
            if (item_post.hasOwnProperty('asset_name')){
                asset_name = item_post.asset_name;
            }
            let addition_text = '';
            if (item_post.images_count > 0){
                addition_text = item_post.images_count + ' Images';
                if (item_post.images_count === 1){
                    addition_text = 'an Image';
                }
            }

            switch(item_post.action_type){
                case 1:
                    title = 'Added ' + addition_text;
                    break;
                case 2:
                    title = 'Added a new '+item_type+' {' + asset_name + '}';
                    break;
                case 3:
                    title = 'Updated '+item_type+' {' + asset_name + '}';
                    // updated all asset
                    break;
                case 4:
                    title = 'Commented on {' + asset_name + '}';
                    // updated all asset
                    break;
                case 5:
                    title = 'Commented on ' + asset_name + ' with ' + addition_text;
                    // commented on asset
                    break;
                case 6:
                    title = 'Split '+item_type+' {' + asset_name + '}';
                    break;
                case 7:
                    title = 'Registered Disposition';
                    break;
                case 8:
                    title = 'Registered Acquisition';
                    break;
                case 10:
                    // change
                    title = 'Added a New Change';
                    break;
                case 11:
                    // part change
                    title = 'Added an Update';
                    break;
                case 12:
                    // add character change
                    title = 'Edited a Change';
                    break;
                case 13:
                    // add character change
                    title = 'Added a Scene Note';
                    break;
                case 14:
                    // add character change
                    title = 'Added a Change to Scene';
                    break;
                case 15:
                    // add character change
                    title = 'Added a Character to Scene';
                    break;
                case 16:
                    title = 'Updated Scene Note Images';
                    break;
                case 17:
                    title = 'Imported a new file';
                    break;
                case 18:
                    title = 'Updated Storage';
                    break;
                case 19:
                    title = 'Updated Location';
                    break;
                case 20:
                    title = 'Added a Managed Storage to Order';
                    break;
                case 21:
                    title = 'Added a Managed Storage';
                    break;
                case 22:
                    title = 'Edited Managed Storage';
                    break;
                case 23:
                    title = 'Assigned Storage to Customer';
                    break;
                case 24:
                    title = 'Unassigned Storage from Customer';
                    break;
                case 25:
                    title = 'User Logged in';
                    break;
                case 26:
                    title = 'Tagged Asset';
                    break;
                case 27:
                    title = 'Untagged Asset';
                    break;
                case 28:
                    title = 'Asset added to order';
                    break;
                case 29:
                    title = 'Asset removed from order';
                    break;
                case 30:
                    title = 'Asset approved';// completed
                    break;
                case 31:
                    title = 'Asset split';
                    break;
                case 32:
                    title = 'Created Tag';
                    break;
                case 33:
                    title = 'Updated Tag';
                    break;
                case 34:
                    title = 'Removed Image';
                    break;
                case 35:
                    title = 'Deleted Tag';
                    break;
                case 36:
                    title = 'Added an Asset using an Import File';
                    break;
                default:
                    title = item_post.description.replace(/ITEM_TYPE/g, current_page_type);
                    break;
            }
            return title;
        },

        customFieldsClass(field_name = 'custom_fields'){
            if (this.item_info && this.item_info.hasOwnProperty('asset_meta') && this.item_info.asset_meta){
                if (this.item_info.asset_meta.hasOwnProperty(field_name) && this.item_info.asset_meta[field_name]){
                    let custom_fields_count = this.item_info.asset_meta[field_name].length;
                    if (custom_fields_count === 0){
                        return '';
                    }else if (custom_fields_count < 4){
                        return 'col-md-' + (12/custom_fields_count);
                    }
                    return 'col-md-3';
                }
            }
            return '';
        },
        parseCustomFieldsFrom: function (asset_meta, is_empty=true) {
            if (!asset_meta){
                asset_meta = {};
            }
            if (!asset_meta.hasOwnProperty('custom_fields')) {
                asset_meta.custom_fields = is_empty ? [] : [{field_name: "", field_value: ""}];
            } else {
                if (asset_meta.custom_fields.length > 0) {
                    let custom_fields = [];
                    for (const customFieldsKey in asset_meta.custom_fields) {
                        let customField = asset_meta.custom_fields[customFieldsKey];
                        if (customField.hasOwnProperty('field_name')){
                            custom_fields.push(customField);
                            asset_meta[customField.field_name] = customField.field_value;
                        }else{
                            custom_fields.push({field_name: customField, field_value: asset_meta[customField]});
                            asset_meta[customField] = asset_meta[customField];
                        }
                    }
                    asset_meta.custom_fields = custom_fields;
                }
            }
            return asset_meta;
        },
    },
    computed: {
        getAssetBaseUrl() {
            return window.hasOwnProperty('ASSET_BASE_URL') && window.ASSET_BASE_URL !== '' ? window.ASSET_BASE_URL : '';
        }
    }
}
