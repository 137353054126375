<template>
    <div v-if="managed_storage">
        <div class="common_col_bg_box color_v_accent">
            <div class="row">
                <div class="col-6 col-md-3">
                    <div class="cm_usage border_r">
                        <span v-if="item_type === 'assetflow'">SP \ {{ is_tv_film_asset ? 'Customer' : 'Production' }}</span>
                        <span v-else-if="item_type === 'sp'">{{ is_tv_film_asset ? 'Customer' : 'Production' }}</span>
                        <span v-else-if="item_type === 'customer'">SP</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span>
                            <a v-if="['customer', 'assetflow'].includes(item_type) && managed_storage.sp_info" class="text-dark" :href="'/' + item_type + '/service_providers/' + managed_storage.sp_info.id + '/view'">{{managed_storage.sp_info.name | titleize}}</a>
                            <span v-if="item_type === 'assetflow'"> | </span>
                            <a v-if="['sp', 'assetflow'].includes(item_type) && managed_storage.customer_info" class="text-dark" :href="'/' + item_type + '/customers/' + managed_storage.customer_info.id + '/view'">{{managed_storage.customer_info.name | titleize}}</a>
                        </span>
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="cm_usage border_r">
                        <span>Storage ID</span>
                        <p>
                            <i class="bx bx-detail"></i> <a class="text-dark" :href="'/' + item_type + '/managed_storages/' + managed_storage.id + '/view'">{{ managed_storage.uid }}</a>
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="cm_usage border_r">
                        <span>Ref</span>
                        <p>
                            <i class="bx bx-detail"></i> {{ managed_storage.item_reference }}
                        </p>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="cm_usage">
                        <span>Location</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="managed_storage.item_info.hasOwnProperty('address_location') && managed_storage.item_info.address_location && managed_storage.item_info.hasOwnProperty('parent_address') && managed_storage.item_info.parent_address">
                                                <a :href="'/'+ item_type + '/addresses/' + managed_storage.item_info.address_id + '/view'" v-b-tooltip.bottom :title="managed_storage.item_info.parent_address.name" class="text-dark">{{ managed_storage.item_info.address_location.name | titleize }}</a>
                                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="managed_storage.status === 1" class="common_col_bg_box color_v_accent">
            <div class="row">
                <div class="col-md-12">
                    <div class="cm_list_inner_common">
                        <span>In Storage Ordering:</span>
                        <p></p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="cm_list_inner_common">
                        <span>Qty</span>
                        <p v-if="managed_storage.available_quantity > 0">
                            <multiselect
                                v-model="selected_quantity_to_add"
                                :options="getNumbers(1, managed_storage.available_quantity)"
                                placeholder="Qty"
                                :show-labels="false"
                            >
                                <span slot="noResult">No data found.</span>
                            </multiselect>
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_list_inner_common">
                        <span>Orders</span>
                        <p v-if="orders_list && orders_list.length > 0">
                            <multiselect
                                v-model="selected_order_to_add_to"
                                :options="orders_list"
                                :custom-label="orderDetails"
                                placeholder="Order"
                                :show-labels="false"
                            >
                                <span slot="noResult">No data found.</span>
                            </multiselect>
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_list_inner_common">
                        <span></span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span><button :disabled="isAddingItem" @click="addToOrder" class="btn btn-link">Add To Order</button></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
    name: 'StorageOrderRightBox',
    components: {
        Multiselect
    },
    props: {
        my_asset: {},
        item_type: {},
        received_orders_list: [],
    },
    data(){
        return {
            orders_list: [],
            selected_order_to_add_to: null,
            selected_quantity_to_add: null,
            isAddingItem: false,
        }
    },
    computed:{
        managed_storage(){
            if (this.my_asset.hasOwnProperty('latest_managed_storage_info') && this.my_asset.latest_managed_storage_info){
                return this.my_asset.latest_managed_storage_info;
            }
            return null;
        }
    },
    created() {
        this.orders_list = this.received_orders_list;
    },
    methods:{
        async addToOrder(){
            if (this.isAddingItem){
                return;
            }
        },
        orderDetails({ name, sp_info }){
            return name + (sp_info ? ' - ' + sp_info.name : '');
        },
        getNumbers:function(start,stop,step = 1){
            return new Array(stop / step).fill(start).map((n,i)=>(i+1)*step);
        },
    }
}
</script>
