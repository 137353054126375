const FirstPath = window.location.pathname.split('/')[1];
export const FolderKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 t_uid",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 t_name",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (value){
            return Vue.options.filters.titleize(value);
        }
    },
    customer: {
        key: 'customer',
        label: "Customer",
        class: "col-lg-2 t_customer",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('customer') && item.customer){
                return '<a href="/' + FirstPath + '/customers/'+item.customer.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.customer.name) + '</a>';
            }
            return '-';
        }
    },
    user: {
        key: 'user',
        label: "Created By",
        class: "col-lg-2 t_created_by",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('user') && item.user){
                return '<a href="/' + FirstPath + '/users/'+item.user.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.user.name) + '</a>';
            }
            return '-';
        }
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets",
        class: "col-lg-1 t_assets_count",
        show_on_mobile: false,
        case: ''
    },
    assets_total_quantity: {
        key: 'assets_total_quantity',
        label: "Qty",
        class: "col-lg-1 t_assets_total_quantity",
        show_on_mobile: false,
        case: ''
    },
    assets_available_quantity: {
        key: 'assets_available_quantity',
        label: "Qty",
        class: "col-lg-1 t_assets_available_quantity",
        show_on_mobile: false,
        case: ''
    },
    assets_reserved_quantity: {
        key: 'assets_reserved_quantity',
        label: "Qty",
        class: "col-lg-1 t_assets_reserved_quantity",
        show_on_mobile: false,
        case: ''
    },
};
