<template>
    <div class="common-step-panel" :class="useTooltips ? 'show-tooltips' : 'use-section'">
        <component :is="currentStep.id"
                   :item_id="item_id"
                   :item_type="item_type"
                   :object_type="object_type"
                   :add-form="addForm"
                   :required_fields="required_fields"
                   :my_lists_values="my_lists_values"
                   :listQuery="listQuery"
                   :class="currentStep.class"
        />
    </div>
</template>
<script>

import overview_step from "./acquisitionForm/OverviewStep";
import supplier_step from "./acquisitionForm/SupplierStep";
import details_step from "./acquisitionForm/DetailsStep";
import assets_step from "./acquisitionForm/AssetsStep";
import payment_step from "./acquisitionForm/PaymentStep";
import confirmation_step from "./acquisitionForm/ConfirmationStep";
import costume_asset_acquisition_step from "./CostumeAcquisitionForm/CostumeAssetAcquisitionStep";


export default {
    name: 'AcquisitionStepPanel',
    components: {
        overview_step,
        details_step,
        supplier_step,
        payment_step,
        assets_step,
        confirmation_step,
        costume_asset_acquisition_step
    },
    props: ['addForm', 'currentStep', 'useTooltips', 'required_fields', 'my_lists_values', 'listQuery', 'item_id', 'item_type', 'object_type']
}
</script>
<style>

</style>
