<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="text-left" v-if="is_resetMSStocks">
                    <b-spinner class="text-af-accent mr-1"></b-spinner>
                    <small>Resetting MS Stocks...</small>
                </div>

                <div class="text-left" v-else>
                    <span><b-button @click="fireAction('resetMSStocks')" variant="success" class="btn btn-success btn-rounded">Reset MS Stocks</b-button></span>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <div class="text-left" v-if="is_fixMSStatuses">
                    <b-spinner class="text-af-accent mr-1"></b-spinner>
                    <small>Fixing MS Statuses...</small>
                </div>

                <div class="text-left" v-else>
                    <span><b-button @click="fireAction('fixMSStatuses')" variant="success" class="btn btn-success btn-rounded">Fix MS Statuses</b-button></span>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>

import Layout from "@/layouts/main";
export default {
    name: 'AdminTools',
    props: ['item_id', 'other_item_id', 'item_type'],
    components: {
        Layout
    },
    data(){
        return {
            is_resetMSStocks: false,
            is_fixMSStatuses: false,
        }
    },
    methods:{
        fireAction(action=''){
            if (this.item_id && ['assetflow', 'sp']){
                this['is_' + action] = true;
                axios({
                    method:'post',
                    url:'/api/assetflow/service_providers/' + action,
                    baseURL: '/',
                }).then(response => {
                    let status = response.data.data.status;
                    let message = response.data.data.msg;
                    this.presentToast("Success!",
                        message,
                        (status ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    this['is_' + action] = false;
                }).catch(error => {
                    console.log(error);
                    this['is_' + action] = false;
                });
            }
        }
    }
}
</script>
