<template>
    <b-modal :id="received_modal_id"
             size="lg"
             :ref="received_modal_id"
             :title="modal_title + (selected_item ? (' to {'+selected_item.name + '}') : '')"
             title-class="font-18"
             @hide="cancelClicked"
             hide-footer>

        <div class="card">
            <div class="card-header af-soft-accent text-af-primary">
                URLs
            </div>
            <div class="row mt-1">
                <div class="col-xl-12">
                    <div class="alert alert-info">
                        <i class="mdi mdi-alert-circle-outline me-2"></i> Please enter the full URL including 'https'. For example: https://portal.assetflow.co.uk
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-body">
                        <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'edit-ms-files' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                required_text="Save"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import AFButton from "@/components/AFButton";
import ChangeCommonFieldsAdvanced from "../../customers/extras/ChangeCommonFieldsAdvanced";
import CustomFieldsRepeater from "../../assets/assetForm/CustomFieldsRepeater";
const editAssetResource = new Resource('api/assetflow/managed_storages/editAsset');
export default {
    mixins: [],
    name: "AddMSLink",
    props: ['modal_title', 'received_modal_id'],
    components: {CustomFieldsRepeater, ChangeCommonFieldsAdvanced, AFButton},
    data(){
        return {
            index: null,
            addForm:{
                asset_meta:{
                    asset_links: [],
                },
            },
            required_items:{
                asset_links: {
                    key: 'asset_links',
                    label: '',
                    type: 'asset_links'
                },
            },

            selected_item: null
        }
    },
    methods:{
        set_index(index){
            this.index = index;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item.hasOwnProperty('asset_meta')){
                let asset_meta = this.selected_item.asset_meta;
                if (asset_meta && asset_meta.hasOwnProperty('asset_links') && asset_meta.asset_links && asset_meta.asset_links.length > 0){
                    this.$set(this.addForm.asset_meta, 'asset_links', asset_meta.asset_links);
                }
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        async addItem(event){
            this.isAddingItem = true;
            let asset_links = [];
            if (this.addForm.asset_meta.hasOwnProperty('asset_links') && this.addForm.asset_meta.asset_links){
                asset_links = [... this.addForm.asset_meta.asset_links];
            }


            let files_are_empty = asset_links.length === 0 || (asset_links.length === 1 && asset_links[0].field_name === '' && asset_links[0].field_value === '');
            if (files_are_empty){
                asset_links = [];
            }

            // adding asset files
            let asset_id = this.selected_item.id;
            if (!asset_id){
                return;
            }

            let params = {
                asset_links: asset_links,
            };
            params.item_id = asset_id;

            const { data } = await editAssetResource.store(params);

            let new_item = data.data.items;
            if (!new_item){
                this.buttonkey++;
            }
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            let required_keys = ['asset_meta'];
            if (this.received_modal_id === 'add-ms-link'){
                required_keys = ['asset_links'];
            }

            this.$emit('on-new-item-adding', this.received_modal_id, new_item, required_keys, null, this.index);


        }
    }
}
</script>


