
export function getWarehouseSpaceInfoBlock(item, prefix='', no_html=false) {
    let warehouse_name = '';
    let warehouse_colour = '';
    let space_name = '';
    let space_colour = '';
    if (item.hasOwnProperty(prefix+'warehouse_info') && item[prefix+'warehouse_info']) {
        let warehouse_info = item[prefix+'warehouse_info'];
        warehouse_name = warehouse_info.initials;
        warehouse_colour = warehouse_info.colour;
    }
    if (item.hasOwnProperty(prefix+'space_info') && item[prefix+'space_info']) {
        let space_info = item[prefix+'space_info'];
        space_name = space_info.initials;
        space_colour = space_info.colour;
    }
    return '<div class="warehouse-space">' +
        '<div class="ws-border" style="background-color: ' + warehouse_colour + ' !important;">' +
            '<div class="warehouse-top">' + warehouse_name + (no_html ? ':' : '') + '</div>' +
            '<div class="space-bottom">' + space_name + '</div>' +
        '</div>' +
        '</div>';
}
