<template>
    <div class="StorageManagementDashboard">
        <DashboardEntityRow
            v-for="(entity_info, index) in entity_info_list"
            :key="index"
            :entity_info="entity_info"
            :item_info="item_info"
            :index="index"/>
    </div>
</template>

<script>

import DashboardBlockTable from "../DashboardBlockTable";
import DashboardEntityRow from "./DashboardEntityRow";

import SharedModuleDashboard from "@/mixins/SharedModuleDashboard.mixin";

export default {
    mixins: [SharedModuleDashboard],
    name: 'StorageManagementDashboard',
    components: {DashboardEntityRow, DashboardBlockTable},
    props: {
        item_info: {},
        tabs: {}
    },
    created() {
        if (this.item_info) {
            let item_info = this.item_info;
            let current_page_type = '/' + item_info.current_page_type;
            let entity_block_title = '',
                group_logo_name = '';

            if (item_info.item_type === 'service_providers') {
                entity_block_title = 'Service Provider';
            } else if (item_info.item_type === 'customers') {
                let sector_ids = item_info.sector_ids;
                entity_block_title = 'Customer';
                if (sector_ids && sector_ids.includes('1')) {
                    entity_block_title = 'Production';
                }
                group_logo_name = item_info.hasOwnProperty('parent_group') ? this.getEntityLogoAndName(item_info.parent_group) : 'No Group';
            }

            let entity_logo_name = this.getEntityLogoAndName(item_info);
            let style = '';
            if (item_info.hasOwnProperty('meta') && item_info.meta) {
                let item_meta = item_info.meta;
                if (item_meta.hasOwnProperty('primary_colour') && item_meta.primary_colour) {
                    let bg_colour = item_meta.primary_colour;
                    let text_colour = item_meta.accent_colour;
                    this.item_info.style = 'background-color: ' + bg_colour + ' !important; color:' + text_colour + ' !important;display: flex!important;';
                    style = 'style="' + this.item_info.style + '"';
                }
            }

            let entity_overview_link = current_page_type + '/' + item_info.item_type + '/' + item_info.id + '/edit';

            let entity_currency = this.getObjectForKey('currency', false, true);
            let required_currency = '-';
            if (entity_currency) {
                required_currency = entity_currency.icon + ' - ' + entity_currency.text;
            }
            let customer_details_colours = '<div class="entity-overview-colours"><a href="" class="text-dark">' + '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" ' + style + '>' + item_info.initials + '</span></div></a></div>';

            let customer_details_block = '<div class="cm_common_list_detail"><span>Currency</span><p>' + required_currency + '</p> <span>Branding</span>' + customer_details_colours + ' </div>';


            let has_edit_permission = this.hasPermission('edit_' + item_info.item_type);
            entity_overview_link = has_edit_permission ? '<a href="' + entity_overview_link + '">Edit</a>' : '';

            let current_page_link = current_page_type;

            this.entity_info_list = {
                overview: {
                    title: item_info.name + ' Overview',
                    blocks: {
                        created_orders_info: {
                            custom_title: '<h5 class="overview-sub-title mb-2 mt-2"><i class="fa fa-shopping-cart"></i> Orders</h4>',
                            name: 'Created Orders',
                            content: '',
                            action: '<a href="' + current_page_link + '/orders">View All Created Orders</a>',
                            required_class: 'col-lg-6 col-xl-6',
                            icon: 'iconize',
                            header_action_text: 'Latest',
                            header_action_class: 'split-header-sort',
                            object_type: 'orders',
                            object_type_icon: 'iconize',
                            table_headers: ['Order NO', 'Customer', 'Service', 'Activity', 'Items'],
                            object_keys: ['order_number', 'customer_info', 'service_name', 'activity', 'items'],
                            required_items: item_info.hasOwnProperty('created_orders') ? item_info.created_orders : []
                        },
                        confirmed_orders_info: {
                            name: 'Confirmed Orders',
                            content: '',
                            action: '<a href="' + current_page_link + '/orders">View All Confirmed Orders</a>',
                            required_class: 'col-lg-6 col-xl-6',
                            icon: 'iconize',
                            header_action_text: 'Latest',
                            header_action_class: 'split-header-sort',
                            object_type: 'orders',
                            object_type_icon: 'iconize',
                            table_headers: ['Order NO', 'Customer', 'Service', 'Activity', 'Items'],
                            object_keys: ['order_number', 'customer_info', 'service_name', 'activity', 'items'],
                            required_items: item_info.hasOwnProperty('confirmed_orders') ? item_info.confirmed_orders : []
                        },
                        customer_info: {
                            name: entity_block_title,
                            content: entity_logo_name,
                            action: entity_overview_link,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                        customer_details: {
                            name: 'Details',
                            content: customer_details_block,
                            action: entity_overview_link,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                        group_info: {
                            name: 'Group',
                            content: group_logo_name,
                            required_class: 'col-lg-4 col-xl-4'
                        },
                    }
                },
            };


            if (item_info.item_type === 'service_providers') {
                if (this.item_info.current_page_type !== 'customer') {
                    delete this.entity_info_list.overview.blocks.group_info;
                    this.entity_info_list.overview.blocks.customer_info.required_class = 'col-lg-6 col-xl-6';
                    this.entity_info_list.overview.blocks.customer_details.required_class = 'col-lg-6 col-xl-6';
                }
            } else if (item_info.item_type === 'customers') {
                if (this.item_info.current_page_type === 'sp') {
                    let info_for_sp_block = '';
                    if (this.item_info.hasOwnProperty('current_sp_info') && this.item_info.current_sp_info) {
                        let current_sp_info = this.item_info.current_sp_info;
                        let relation_meta = current_sp_info.meta;

                        let default_address = this.item_info.default_address;
                        let default_address_info = '-';
                        if (default_address) {
                            default_address_info = '<a href="' + current_page_type + '/addresses/' + default_address.id + '/view">' + default_address.name + '</a>';
                        }
                        let acc_number = '-';
                        if (relation_meta) {
                            acc_number = relation_meta.hasOwnProperty('account_no') ? relation_meta.account_no : '';
                        }

                        let created_at = Vue.options.filters.moment(current_sp_info.created_at, 'DD/MM/YYYY') + ' (' + Vue.options.filters.fromNow(current_sp_info.created_at) + ')';
                        let updated_at = Vue.options.filters.moment(current_sp_info.updated_at, 'DD/MM/YYYY') + ' (' + Vue.options.filters.fromNow(current_sp_info.updated_at) + ')';

                        info_for_sp_block = '<div class="cm_common_list_detail">' +
                            '<span>Account Number</span><p>' + acc_number + '</p>' +
                            '<span>Default Delivery Address</span><p>' + default_address_info + '</p>' +
                            '<span>Default Billing Address</span><p>-</p>' +
                            '<span>Created Date</span><p>' + created_at + '</p>' +
                            '<span>Modified Date</span><p>' + updated_at + '</p>' +
                            '</div>';
                    }
                }
            }


        } else {
            console.log("'no item info?'");
            console.log('no item info?');

        }
    },
}
</script>
