<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? getTitleFor(selected_item, 'changes') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>


        <ChangeCommonFields :add-form="addForm" :required_fields="required_fields"/>

        <div class="text-right">
            <b-button variant="success" @click="addItem('queue')">Open (Queue)</b-button>
            <b-button variant="success" @click="addItem('open')">Open</b-button>
            <b-button variant="primary" @click="addItem('download')">Download</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFields from "./ChangeCommonFields";

const changeResource = new Resource('api/assetflow/changes/editChange');
import ChangeFormsMixin from "@/mixins/ChangeFormsMixin";


export default {
    name: "EditGeneratePdf",
    props: ['modal_title', 'item_type', 'object_type'],
    mixins: [ChangeFormsMixin],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                file_template_id: '',
            },
            required_fields:{
                file_template_id: {
                    key: 'file_template_id',
                    label: 'Template',
                    type: 'single-select',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                file_template_id: 'file_templates_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    category_id: 1,
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }


            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetFormValues(){
            this.addForm = {
                part_ids: ''
            };
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
            }else{
                this.selected_item = null;
            }
            this.resetFormValues();
        },

        async addItem(action){
            if (this.addForm.file_template_id){
                let required_link = '/af_files/' + this.selected_item.id + '/' + this.object_type + '/' + action + '/' + this.addForm.file_template_id.id;
                window.open(required_link, '_blank').focus();

                this.$bvModal.hide('create-'+this.item_type);
                this.resetSelectedItem();

            }else{
                this.presentToast("Error!",
                    'Please select a valid template',
                    'danger',
                    3000,
                    true,
                    true);
            }

        }
    }
}
</script>
