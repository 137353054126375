<template>
    <Layout>

        <AddNewSpWarehouse
            ref="add-new-sp_warehouses"
            item_type='sp_warehouses'
            modal_title="Create New SP Warehouse"
            @on-new-item-adding="selected_object_id = null;getList(selected_item);"
            @on-modal-cancel="selected_object_id = null"
        >
        </AddNewSpWarehouse>

        <div class="sp_warehouses-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div class="product-page-header">
                        <PageHeaderCustom :title="title" :items="items" class="list-header-block" />
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Warehouses.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem">Warehouse
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>

                        <div class="page-value-info"></div>
                    </div>

                    <div class="current-page-filters">
                        <span v-if="selected_sp_warehouse && selected_sp_warehouse.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Warehouse: <b>{{ selected_sp_warehouse.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_warehouse')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.label) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_dashboard_item && selected_dashboard_item.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ selected_dashboard_item.label }}: <b>{{ (selected_dashboard_item.name) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('dashboard_item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>

                    <div class="tab_menu_item">
                        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li v-for="tab in listTabs" class="nav-item">
                                <a class="nav-link"
                                   @click="tabClicked(tab.id)"
                                   :class="isActiveTab(tab.id)"
                                   data-toggle="tab" :href="'#'+tab.id" role="tab">
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                                    <span class="d-none d-sm-inline-block">
                                {{ tab.name | titleize}}
                            </span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div class="tab-content tab_content_design">
                        <div class="sidebad-toggle af-accent"></div>
                        <div class="tab-pane" id="list_dashboard" role="tabpanel" :class="isActiveTab('list_dashboard')">
                            <ListItemsDashboardTab :dashboard_info="dashboard_info" @on-dashboard-clicked="dashboardItemClicked" item_name="Warehouses"/>
                        </div>

                        <div class="tab-pane" id="list_items" role="tabpanel" :class="isActiveTab('list_items')">

                            <div class="product-filter-option-wrapper clearfix d-flex">

                                <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                        request_meta.from + '-' + request_meta.to
                                    }}</span> of <span>{{ request_meta.total }}</span></div>

                                <div class="filter-wrap d-flex align-items-center ml-auto">

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Sort: <b>{{ sorting.sortType.text }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in sorting.sortOptions"
                                            :key="option.value"
                                            @click="saveSorting(option, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Show: <b>{{
                                                perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                            }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in perpage.pageOptions"
                                            :key="option.value"
                                            @click="savePerPage(option.value, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                        <div
                                            v-if="supportedLayouts && supportedLayouts.length > 0"
                                            v-for="(layout, key) in supportedLayouts"
                                            :key="key"
                                            @click="changeObjectLayout(layout);"
                                            :id="'grid-list-'+layout.name.toLowerCase()"
                                            :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- Product Section Start-->
                            <LotsOfItemsAlert :perpage="perpage"/>

                            <div v-if="listLoading" class="products text-center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                                <h4>Fetching Warehouses...</h4>
                            </div>
                            <div v-else-if="!sp_warehousesData || total === 0 || sortedItemsList.length === 0"
                                 class="products text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>No warehouses found here at the moment.</h4>

                            </div>
                            <div v-else-if="selectedLayout === 0 && sp_warehousesData" class="row">
                                <div class="col-lg-12">
                                    <po-lines
                                        :items="sortedItemsList"
                                        :current_page_type="item_type"
                                        :object_type="permission_name"
                                        :get-poline-classes-dict="getPoLineClassForObject"
                                        :keys_list="sp_warehouse_keys_list">

                                        <template v-slot:sp_warehouses_actions="{ item, index }">
                                            <div class="btn-group bl-auto">
                                                <button type="button" class="btn btn-none dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <b-dropdown-item v-if="permissions_list['edit_' + permission_name]"
                                                                     @click="performAction(item, ('edit' + permission_name), index, $event)">
                                                        Edit
                                                    </b-dropdown-item>
                                                    <b-dropdown-item v-if="permissions_list['delete_' + permission_name]"
                                                                     variant="danger"
                                                                     @click="performAction(item, ('delete' + permission_name), index)">
                                                        Delete
                                                    </b-dropdown-item>
                                                </div>
                                            </div>
                                        </template>
                                    </po-lines>
                                </div>
                            </div>


                            <!-- Product Section End-->
                            <div class="pagination">
                                <b-pagination
                                    v-if="request_meta && perpage.perPage !== 0"
                                    v-model="perpage.currentPage"
                                    :total-rows="request_meta.total"
                                    @change="goToPage($event)"
                                    class="pagination d-flex align-items-center justify-content-center"
                                    :per-page="perpage.perPage"
                                ></b-pagination>
                            </div>

                        </div>


                        <div class="tab-pane" id="list_add" role="tabpanel" :class="isActiveTab('list_add')">
                            <SpItemAddTab v-if="hasPermission('add_'+permission_name)" @on-item-add="addNewItem" menu_title="Add Warehouses" object_name="Warehouse"/>
                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>

                        <div class="tab-pane" id="list_export" role="tabpanel" :class="isActiveTab('list_export')">
                            <SpItemExportTab v-if="hasPermission('add_'+permission_name)" @on-export-pdf="exportWarehouses" menu_title="Export Warehouses"/>
                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import {SPWarehouseKeysList} from "@/views/assetflow/form_data/SPWarehouseKeysList";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import AddNewSpWarehouse from "./AddNewSpWarehouse";
import SearchBox from "@/components/search-box";
import PageHeaderCustom from "../../../../components/page-header-custom";
import ListItemsDashboardTab from "../sp_items_shared/ListItemsDashboardTab";
import SpItemExportTab from "../sp_items_shared/SpItemExportTab";
import SpItemAddTab from "../sp_items_shared/SpItemAddTab";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";

const sp_warehouseResource = new Resource('api/assetflow/sp_warehouses');


export default {
    name: 'WarehouseList',
    props: ['item_id', 'item_type'],
    components: {
        SpItemAddTab,
        SpItemExportTab,
        ListItemsDashboardTab,
        PageHeaderCustom, AddNewSpWarehouse, LotsOfItemsAlert, ObjectsSidebar, PoLines, SearchBox, Layout},
    mixins: [TimelineCommons, TopFiltersMixin, ObjectsSidebarShared],
    data() {
        return {
            total: 0,
            sp_warehousesData: [],
            request_meta: {},
            listLoading: false,
            breadcrumb_items: [],
            title: "Warehouses List",
            items: [
                {
                    text: "Asset Flow",
                    href: "/"
                },
                {
                    text: "Warehouses",
                    href: "#"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_sp_warehouse_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'sp_warehouses',
            object_type: 'sp_warehouses',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
            ],
            items_list: [
                [],
                // status
                []
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'item_status'
                },
            ],
            sp_warehouse_keys_list: SPWarehouseKeysList,

            active_tab: 'list_dashboard',
            totals_only:0,
            dashboard_info: null,
            listTabs:{
                list_dashboard:{
                    'id': "list_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                list_items:{
                    'id': "list_items",
                    'name': 'Warehouses',
                    'icon': "user",
                    'isActive': false
                },
                list_add:{
                    'id': "list_add",
                    'name': 'Add',
                    'icon': "user",
                    'isActive': false
                },
                list_export:{
                    'id': "list_export",
                    'name': 'Export',
                    'icon': "user",
                    'isActive': false
                }
            },
        };
    },
    created() {
        this.sorting.sortOptions[0].name = 'uid';
        this.sorting.sortOptions[1].name = 'uid';
        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
            // grid: {
            //     name: 'Grid',
            //         value: 1,
            //         class: 'grid',
            //         isActive: true
            // }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_sp_warehouses_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.sp_warehouse_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.sp_warehouse_preferred_sorting;
        }


        let sp_items_list_active_tab = localStorage.getItem('sp_items_list_active_tab');
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'list_dashboard';
                    break;
                case 'items':
                    this.active_tab = 'list_items';
                    break;
                case 'add':
                    this.active_tab = 'list_add';
                    break;
                case 'export':
                    this.active_tab = 'list_export';
                    break;
            }
        }else if (sp_items_list_active_tab && sp_items_list_active_tab !== ''){
            this.active_tab = sp_items_list_active_tab;
        }
        if (this.permissions_list[('edit_' + this.permission_name)]){
            this.sp_warehouse_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 war_sp_warehouses_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_warehouses_actions'
            };
        }

        if (!this.permissions_list[('add_' + this.permission_name)]){
            delete this.listTabs.list_add;
            delete this.listTabs.list_export;
            this.active_tab = ['list_add', 'list_export'].includes(this.active_tab) ? 'list_dashboard' : this.active_tab;
            localStorage.setItem('sp_items_list_active_tab', this.active_tab);
        }

        this.getActiveTabInfo(this.active_tab);

    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        exportWarehouses(){
            console.log("shall export??");
        },
        tabClicked(tab_id){
            localStorage.setItem('sp_items_list_active_tab', tab_id);
            this.getActiveTabInfo(tab_id);
        },
        getActiveTabInfo(tab_id){
            switch (tab_id) {
                case 'list_dashboard':
                case 'list_items':
                    // refresh list_dashboard and list_items?
                    this.getList(this.selected_item);
                    break;
                case 'list_add':
                    // shall get info of list_add?
                    break;
                case 'list_export':
                    // shall get info of list_export?
                    break;
            }
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        goToPage(page){
            this.perpage.currentPage = page;
            this.getList(this.selected_item ? this.selected_item : null)
        },
        addNewItem() {
            this.$refs['add-new-sp_warehouses'].setSelectedItem(null);
            this.$bvModal.show('create-sp_warehouses');
            this.setupAddressLookup('sp_warehouse');
        },
        performAction(sp_warehouse, action, sp_warehouseKey){

            this.selected_object_id = sp_warehouse.id;
            if (action === ('edit'+this.permission_name)){
                this.$refs['add-new-'+ this.permission_name].setSelectedItem(sp_warehouse);
                this.$bvModal.show('create-'+ this.permission_name);
            }else if (action === ('delete'+this.permission_name)){
                if (sp_warehouse.id !== ''){
                    this.deleteItemByID(sp_warehouse.id, sp_warehouse.name, this.object_type, sp_warehouse)
                        .then((response) => {
                            if (response.data.data.status){
                                this.sp_warehousesData.splice(sp_warehouseKey, 1);
                            }
                        });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.sp_warehousesData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('sp_warehouse_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_sp_warehouses_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            this.request_meta = null;

            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };


            let active_tab = localStorage.getItem('sp_items_list_active_tab');
            if (!active_tab) {
                active_tab = 'list_dashboard';
            }

            this.totals_only = active_tab === 'list_dashboard' ? 1 : 0;

            this.listLoading = true;


            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
                this.selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            required_query = this.addPaginationParams(required_query);
            required_query.s_keyword = this.search_keyword;
            required_query.totals_only = this.totals_only;
            required_query = this.appendTopFiltersValues(required_query);
            const {data} = await sp_warehouseResource.list(required_query);


            if (Object.keys(data.data).includes('status')) {
                if (this.totals_only) {
                    // shall refresh totals
                    this.dashboard_info = data.data.items;
                    this.listLoading = false;
                    return;
                }
            }
            this.total = data.meta.total;
            this.sp_warehousesData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },

    },
}
</script>


