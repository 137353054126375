export const AvailableSectors = [
    {
        id: '1',
        name: "Film & TV",
        icon: 'bx bx-tv',
        subfolders: [
            {
                id: -99,
                name: 'Camera',
                icon: 'bx bx-camera',
                subfolders:[
                    {
                        id: 1,
                        name: 'On Camera',
                        type: 'asset_usage',
                        icon: 'bx bx-check'
                    },
                    {
                        id: 0,
                        name: 'Off Camera',
                        type: 'asset_usage',
                        icon: 'bx bx-x'
                    },
                    {
                        id: 2,
                        name: 'Unknown',
                        type: 'asset_usage',
                        icon: 'bx bx-question-mark'
                    }
                ]
            },
            {
                id: -99,
                name: 'Hero Item',
                icon: 'bx bx-body',
                subfolders:[
                    {
                        id: 1,
                        name: 'Yes',
                        type: 'is_hero',
                        icon: 'bx bx-check'
                    },
                    {
                        id: 0,
                        name: 'No',
                        type: 'is_hero',
                        icon: 'bx bx-x'
                    },
                    {
                        id: 2,
                        name: 'Unknown',
                        type: 'is_hero',
                        icon: 'bx bx-question-mark'
                    }
                ]
            },
            {
                id: -99,
                name: 'Is Asset',
                icon: 'bx bx-cube',
                subfolders:[
                    {
                        id: 1,
                        name: 'Yes',
                        type: 'is_asset',
                        icon: 'bx bx-check'
                    },
                    {
                        id: 0,
                        name: 'No',
                        type: 'is_asset',
                        icon: 'bx bx-x'
                    },
                    {
                        id: 2,
                        name: 'Unknown',
                        type: 'is_asset',
                        icon: 'bx bx-question-mark'
                    }
                ]
            }
        ]
    },
    {
        id: '2',
        name: "Demo Industry",
        icon: '',
        subfolders: []
    }
];
