<template>
    <div class="cm_list_view_icon">

        <div class="icon-group-assets" v-if="asset">

            <a :href="'/' + item_type + '/assets/' + asset.id + '/view?t=asset_passport'" class="asset-passport-icon"
               v-if="(asset.hasOwnProperty('cAFID') && asset.cAFID)">
                <i class="bx bx-detail icon-active" title="Has Asset Passport"><div></div></i>
            </a>
            <a href="#" class="asset-passport-icon" v-else>
                <i class="bx bx-detail icon-inactive" title="No Asset Passport"><div></div></i>
            </a>

            <a :href="'/' + item_type + '/assets/' + asset.id + '/view?t=asset_storage'" class="asset-storage-icon" v-if="asset.storage_status === 1">
                <i class="bx bx-cuboid icon-active" title="In Storage"><div></div></i>
            </a>
            <a href="#" class="asset-storage-icon" v-else>
                <i class="bx bx-cuboid icon-inactive" title="Not In Storage"><div></div></i>
            </a>

            <a href="#" class="asset-marketplace-icon" v-if="asset.bss_status">
                <i class="bx bx-store icon-active" title="In Markteplace"><div></div></i>
            </a>
            <a href="#" class="asset-marketplace-icon" v-else>
                <i class="bx bx-store icon-inactive" title="Not In Markteplace"><div></div></i>
            </a>

            <a v-if="item_info.hasOwnProperty('reserved_quantity') && item_info.reserved_quantity && item_info.hasOwnProperty('total_quantity') && item_info.total_quantity && item_info.total_quantity === item_info.reserved_quantity" class="asset-order-icon">
                <i class="bx bx-shopping-bag icon-active" title="All Reserved Stock"><div></div></i>
            </a>
            <a v-else-if="item_info.hasOwnProperty('reserved_quantity') && item_info.reserved_quantity === 0" class="asset-order-icon">
                <i class="bx bx-shopping-bag icon-inactive" title="No Reserved Stock"><div></div></i>
            </a>
            <a href="#" class="asset-order-icon" v-else>
                <i class="bx bx-shopping-bag icon-mixed" title="Has Reserved Stock"><div></div></i>
            </a>

        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagedStorageIconList',
    components: {},
    mixins:[],
    props: ['item_info', 'item_type', 'performAction'],
    data() {
        return {
            asset: null
        }
    },
    created() {
        if (this.item_info.hasOwnProperty('item_info') && this.item_info.item_info){
            this.asset = this.item_info.item_info;
        }

    },
    methods:{}
}
</script>
