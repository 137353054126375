<template>
    <div>


        <po-lines
            header="Overview"
            :items="itemsData"
            :keys_list="keys_list"
        >
        </po-lines>


    </div>
</template>
<script>
import PoLines from "../../../items/widgets/detailsViews/PoLines";
export default {
    name: 'SettingsFeaturesOverview',
    components: {PoLines},
    data(){
        return {
            itemsData: [
                {}
            ],
            keys_list:{
                customer_status: {
                    key: 'customer_status',
                    label: "Customer Status",
                    class: "col-lg-6 f_customer_status",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '';
                    }
                },
                customer_inheritance: {
                    key: 'customer_inheritance',
                    label: "Customer Inheritance",
                    class: "col-lg-6 f_customer_inheritance",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '';
                    }
                },
            }
        }
    },
    created() {
        if (this.is_tv_film_asset){
            this.keys_list.customer_status.label = 'Production Status';
            this.keys_list.customer_inheritance.label = 'Production Inheritance';
        }
        let current_group = this.current_group;
        let appended_list = {};
        if (current_group){
            appended_list.parent_group = {
                key: 'parent_group',
                    label: "Parent Group Name",
                    class: "col-lg-6 f_parent_group",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return current_group ? current_group.name : '';
                    }
            };
            appended_list.parent_status = {
                key: 'parent_status',
                    label: "Parent Group Status",
                    class: "col-lg-6 f_parent_status",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        if (current_group){
                            let status = current_group.status;
                            return status === 1 ? 'Enabled' : 'Disabled';
                        }
                        return '-';
                    }
            };
        }

        this.keys_list = Object.assign(appended_list, this.keys_list);

        let current_customer = this.current_customer;


        this.keys_list.customer_status.value_func = function (item, key) {
            if (current_customer){
                let status = current_customer.status;
                let status_text = '';
                switch (status){
                    case 0:
                        status_text = 'Archived';
                        break;
                    case 1:
                        status_text = 'Active';
                        break;
                }
                return status_text;
            }
            return '-';
        };

        let customer_inheritance = this.current_customer.hasOwnProperty('customer_inheritance') ? this.current_customer.customer_inheritance : 'Local';
        this.keys_list.customer_inheritance.value_func = function (item, key) {
            return customer_inheritance === 1 ? 'Enabled' : 'Disabled';
        };

    }
}
</script>
