<template>
    <b-modal :id="'create-'+ (custom_id ? custom_id : '') + item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    placeholder="Select Status"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(('create-' + (custom_id ? custom_id : '') + item_type))">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const departmentResource = new Resource('api/assetflow/departments');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';


// In your component file
import { EventBus } from '../../../../events/eventBus';


export default {
    mixins: [multiSelect],
    name: "AddNewDepartment",
    props: ['modal_title', 'item_type', 'departments_options', 'without_status', 'custom_id'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                name: '',
                status:null
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Department Name',
                    type: 'text'
                }
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        if (!this.without_status){
            this.required_items.status = {
                key: 'status',
                label: 'Status',
                type: 'single-select',
                options: [
                    {
                        id: 0,
                        name: 'Inactive'
                    },
                    {
                        id: 1,
                        name: 'Active'
                    },
                ]
            };
        }
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;

                if (!this.without_status){
                    let status_id = this.selected_item.status;
                    let found_item = _.find(this.required_items.status.options, function(o) { return o.id === status_id; });
                    if (found_item){
                        this.addForm.status = found_item;
                    }else{
                        this.addForm.status = null;
                    }
                }else{
                    this.addForm.status = null;
                }

            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                status: this.without_status ? 1 : this.addForm.status.id
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            if (this.selected_customer && this.selected_customer.id){
                params.customer_id = this.selected_customer.id;
            }

            const { data } = await departmentResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);


            // Inside a method or lifecycle hook
            EventBus.$emit('addedNewDepartment', new_item);

            this.isAddingItem = false;
            this.$bvModal.hide('create-' + (this.custom_id ? this.custom_id : '') + this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>


