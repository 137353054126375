<template>
    <div class="LedgerReceiptsTab">


        <div class="product-filter-option-wrapper clearfix d-flex">
            <div class="filter-wrap d-flex align-items-center ml-auto">
                <div class="grid-list-button d-flex align-items-center" style="float: right;">
                    <div
                        v-if="supportedLayouts && Object.keys(supportedLayouts).length > 0"
                        v-for="(layout, key) in supportedLayouts"
                        :key="key"
                        @click="changeObjectLayout(layout);"
                        :id="'grid-list-'+layout.name.toLowerCase()"
                        :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                    </div>
                </div>
            </div>

        </div>

        <po-lines v-if="item_info.receipts_info && item_info.receipts_info.length > 0"
                  :items="item_info.receipts_info"
                  :current_page_type="item_info.current_page_type"
                  :object_type="'receipts'"
                  :line_type="'receipts'"
                  :keys_list="receipts_list_key_list">

            <template v-slot:new_line="{ item, index }" v-if="selectedLayout === 0">
                <div v-if="item.hasOwnProperty('assets_info') && item.assets_info && Object.keys(item.assets_info).length > 0" class="tbody_item col-xs-12 col-sm-12 col-lg-12 notes ledger-receipts">
                    <ul class="notes-header">
                        <li class="notes-heading">Account</li>
                        <li>Character</li>
                        <li>No of Assets</li>
                        <li>NET</li>
                        <li>Tax</li>
                        <li>Gross</li>
                    </ul>
                    <ul class="notes-description" v-for="(asset_info, k) in item.assets_info" :key="k">

                        <li v-if="asset_info.hasOwnProperty('ledger_info') && asset_info.ledger_info">{{ asset_info.ledger_info.name }}</li>
                        <li v-else>-</li>

                        <li v-if="asset_info.hasOwnProperty('character_info') && asset_info.character_info">{{ `(${asset_info.character_info.character_number}) ${asset_info.character_info.name}` }}</li>
                        <li v-else>-</li>
                        <li>{{ asset_info.assets_count }} Assets</li>
                        <li class="net_total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(asset_info.currency, asset_info.net_total)">{{ getCurrencyByValue(asset_info.currency).icon + asset_info.net_total }}</li>
                        <li class="tax_total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(asset_info.currency, asset_info.tax_total)">{{ getCurrencyByValue(asset_info.currency).icon + asset_info.tax_total }}</li>
                        <li class="gross_total" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(asset_info.currency, asset_info.gross_total)">{{ getCurrencyByValue(asset_info.currency).icon + asset_info.gross_total }}</li>
                    </ul>
                </div>
                <div v-else class="tbody_item col-xs-12 col-sm-12 col-lg-12 notes ledger-receipts" :class="!item.hasOwnProperty('assets_info') ? 'new-pending-receipt' : ''">
                    <ul class="notes-header">
                        <li class="notes-heading">Account</li>
                        <li>Character</li>
                        <li>No of Assets</li>
                        <li>NET</li>
                        <li>Tax</li>
                        <li>Gross</li>
                    </ul>
                    <ul>
                        <li>-</li>
                        <li>-</li>
                        <li>-</li>
                        <li>-</li>
                        <li>-</li>
                        <li>-</li>
                    </ul>
                </div>
            </template>

            <template v-slot:receipts_actions="{ item, index }">
                <b-button
                    class="btn btn-sm btn-danger mt-2 btn-block"
                    @click="removeFromLedger(item, index)"
                >Remove</b-button>
            </template>
        </po-lines>
        <div v-else class="text-center">
            <h6>No Receipts found here at the moment.</h6>
        </div>
    </div>
</template>
<script>

import PoLines from "./widgets/detailsViews/PoLines";
import {AcquisitionReceiptsKeysList} from "@/views/assetflow/form_data/AcquisitionReceiptsKeysList";

import Resource from '@/api/resource';
const removeFromLedgerResource = new Resource('api/assetflow/ledgers/removeFromLedger');

export default {
    name: 'LedgerReceiptsList',
    components: {PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            selectedLayout:0,
            supportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                list_parts: {
                    name: 'List - No Receipts',
                    value: 1,
                    class: 'smaller-list',
                    isActive: false
                }
            },
            object_type: 'ledger_receipts',
            receipts_list_key_list: AcquisitionReceiptsKeysList,
        }
    },
    created() {

        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_'+this.object_type + '_layout')) {
            this.selectedLayout = window.USER_INFO.user_meta['preferred_'+this.object_type + '_layout'];
        }


        this.receipts_list_key_list = Object.assign({}, this.receipts_list_key_list);

        this.receipts_list_key_list.net_value.title_func = this.toUserCurrencyValueTitle;
        this.receipts_list_key_list.net_value.value_func = this.getCurrencyByValueText;

        this.receipts_list_key_list.tax_value.title_func = this.toUserCurrencyValueTitle;
        this.receipts_list_key_list.tax_value.value_func = this.getCurrencyByValueText;

        this.receipts_list_key_list.all_items_value.title_func = this.toUserCurrencyValueTitle;
        this.receipts_list_key_list.all_items_value.value_func = this.getCurrencyByValueText;

        if (this.hasPermission('edit_acquisitions')){
            this.receipts_list_key_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 a_receipts_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'receipts_actions'
            };
        }
    },
    methods: {

        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            let object_type = this.object_type;

            this.set_usermeta('preferred_' + object_type + '_layout', layout.value, message);
            this.selectedLayout = layout.value
        },
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },
        async removeFromLedger(item, index) {

            let params = {
                ledger_id: this.item_info.id,
                acquisition_id: item.id,
                item_type: 'acquisition',
            }

            const { data } = await removeFromLedgerResource.store(params);
            let new_item = data.data.saved;


            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Removed From Ledger Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);

            if (new_item){
                this.$emit('after-item-moving', item, 'pending_receipts_info', index);
            }



        },

    }

}
</script>
