<template>
    <b-modal id="change-filtering_module"
             ref="change-filtering_module"
             title="Filtering Module"
             title-class="font-18"
             hide-footer>

        <b-form-group label="Filtering Module" label-for="filtering_modules_list">
            <b-form-select
                id="filtering_modules_list"
                name="filtering_modules_list"
                v-model="selected_filtering_module_id"
                :options="filtering_modules"
                value-field="id"
                text-field="name"
            >
            </b-form-select>
        </b-form-group>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click.once="performChangeFilteringModule">
                Save
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('change-filtering_module')">Cancel</b-button>
        </div>
    </b-modal>
</template>
<script>
export default {
    name: 'ChangeFilteringModule',
    methods:{
        performChangeFilteringModule(){
            if (this.selected_filtering_module_id){
                let selected_filtering_module = this.get_selected_filtering_module(this.selected_filtering_module_id);
                if (selected_filtering_module){
                    this.$emit('changed-filtering_module', selected_filtering_module);
                }
            }
            this.$bvModal.hide('change-filtering_module');
        },
    }
}
</script>
