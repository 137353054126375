<template>
    <b-carousel-slide class="af-image-block">
        <template slot="img">
            <img
                class="d-block img-fluid w-100 clickable-image af-image"
                :src="full_image_url"
                :alt="full_image_url"
                @error="setPlaceholderImage($event)">
        </template>
        <div v-if="!with_no_controls && src.hasOwnProperty('name') && src.name !== ''" class="af-image-description-block">
            <h5 class="af-image-title">{{src.name}}</h5>
            <p class="af-image-description">{{src.description}}</p>
            <button type="button" @click.stop.prevent="$root.presentModal('edit_image_info', src, '', fireDoneAction)" class="btn btn-sm btn-success af-image-edit-info"><i class="fas fa-pen font-size-11"></i></button>
            <button type="button" @click.stop.prevent="$root.presentModal('edit_image', src, image_size, fireDoneAction)" class="btn btn-sm btn-success af-image-edit-image"><i class="fas fa-crop font-size-11"></i></button>
            <button type="button" @click.stop.prevent="$root.presentModal('show_full_image', src, '', fireDoneAction)" class="btn btn-sm btn-success af-image-preview-info"><i class="fas fa-image font-size-11"></i></button>
            <button type="button" @click.stop.prevent="$root.presentModal('show_media', src, '', fireDoneAction)" class="btn btn-sm btn-success af-image-preview-info"><i class="fas fa-images font-size-11"></i></button>
            <button type="button" v-if="hasPermission('delete_images')" @click.stop.prevent="$root.presentModal('delete_image', src, '', fireDoneAction)" class="btn btn-sm btn-success af-image-delete-image"><i class="fas fa-trash-alt font-size-11"></i></button>
        </div>
    </b-carousel-slide>
</template>
<script>
export default {
    name: 'MyBCarouselSlide',
    props: {
        src: {},
        image_size: {},
        done_action: {},
        with_no_controls: null,
        image_index: null
    },
    data(){
        return {
            default_image_size: '/inside/750/750',
            full_image_url: null,
        }
    },
    watch:{
        src:{
            deep: true,
            handler: function (newValue) {
                this.full_image_url = this.src.image_name + (this.required_image_size) + '?t=' + Date.now();
            }
        },
    },
    computed:{
        required_image_size(){
            if (this.src.image_name.includes('af_image')){
                return this.image_size && this.image_size !== '' ? this.image_size: this.default_image_size;
            }
            return '';
        }
    },
    methods:{
        fireDoneAction(edited_image){
            this.full_image_url = (edited_image.image_name + (this.required_image_size)) + '?t=' + Date.now();
            this.done_action(this.image_index, edited_image);
        }
    },
    mounted() {
        this.full_image_url = this.src.image_name + (this.required_image_size);
    }
}
</script>
