<template>
    <div class="ObjectFilesTab">

        <div class="product-filter-option-wrapper clearfix d-flex">

            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                    request_meta.from + '-' + request_meta.to
                }}</span> of <span>{{ request_meta.total }}</span></div>

            <div class="filter-wrap d-flex align-items-center ml-auto">

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Show: <b>{{
                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                        }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in perpage.pageOptions"
                        :key="option.value"
                        @click="savePerPage(option.value, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

            </div>

        </div>

        <LotsOfItemsAlert :perpage="perpage"/>

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching Files...</h4>
        </div>
        <div v-else-if="!filesData || total === 0 || filesData.length === 0"
             class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Files found here at the moment<a v-if="object_type === 'asset_files' && hasPermission('add_asset_files') && item_info" href="javascript:void(0);" @click="performAction(item_info, 'add_ms_files', 0)"><span class="text-dark">, </span>Add Files?</a><span v-else>.</span>
            </h4>

        </div>
        <!-- Product Section Start-->
        <div v-else-if="filesData && filesData.length > 0" class="row">
            <div class="col-12">
                <po-lines :items="filesData"
                          :current_page_type="item_info.current_page_type"
                          :object_type="'files'"
                          :line_type="'files'"
                          :get-poline-classes-dict="getPoLineClassFor"
                          :keys_list="file_keys_list">
                    <template v-slot:files_actions="{ item, index }">
                        <div class="btn-group bl-auto">
                            <button type="button" class="btn btn-none dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <b-dropdown-item v-if="hasPermission('delete_' + object_type)"
                                                 variant="danger"
                                                 @click="performMSFileAction(item, ('delete' + object_type), index)">
                                    Delete
                                </b-dropdown-item>
                            </div>
                        </div>
                    </template>
                    <template v-slot:files_download="{ item, index }">

                        <div class="button-items file-button-items">
                            <b-button variant="light" size="sm" :href="item.file_url" target="_blank"><i class="fa fa-eye"></i></b-button>
                            <b-button variant="light" size="sm" :href="item.file_url_download" target="_blank"><i class="fa fa-download"></i></b-button>
                            <b-button v-if="hasPermission('delete_' + object_type)"
                                      variant="danger"
                                      size="sm"
                                      @click="performMSFileAction(item, ('delete' + object_type), index)"
                                      target="_blank"><i class="fa fa-trash"></i></b-button>
                        </div>
                    </template>
                </po-lines>

                <div class="row mb-3" v-if="object_type === 'asset_files'">
                    <div class="col-12">
                        <span class="asset-files-details add-new-asset-file asset-file-details-btn">
                            <a v-if="hasPermission('add_asset_files') && item_info"
                               href="javascript:void(0);"
                               @click="performAction(item_info, 'add_ms_files', 0)">
                                <i class="fas fa-file"></i> Add Files
                            </a>
                        </span>
                    </div>
                </div>
                <!-- Product Section End-->
                <div class="pagination">
                    <b-pagination
                        v-if="perpage.perPage !== 0"
                        v-model="perpage.currentPage"
                        :total-rows="request_meta.total"
                        @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                        class="pagination d-flex align-items-center justify-content-center"
                        :per-page="perpage.perPage"
                    ></b-pagination>
                </div>

            </div>
        </div>
    </div>
</template>
<script>

import {FileKeysList} from "@/views/assetflow/form_data/FileKeysList";
import PoLines from "./widgets/detailsViews/PoLines";

import Resource from '@/api/resource';
const fileResource = new Resource('api/assetflow/files');
const assetFileResource = new Resource('api/assetflow/asset_files');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'ObjectFilesTab',
    components: {LotsOfItemsAlert, PoLines},
    props: {
        object_type: {},
        item_info: {},
        template_category_id: {},
        required_files: {},
        page_unique_name: {},
        performAction: {}
    },
    data(){
        return {
            file_keys_list: FileKeysList,
            total: 0,
            filesData: [],
            request_meta: {},
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            listLoading: false,
            selected_item: null,
            permissions_list:{},
            permission_name: 'files',
        }
    },
    mounted() {
        // shall get files

        let preferredItemsPerPage = window.USER_INFO.user_meta['preferred_'+this.page_unique_name+'s_per_page'];
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta[this.page_unique_name+'_preferred_sorting']) {
            this.sorting.sortType = window.USER_INFO.user_meta[this.page_unique_name+'_preferred_sorting'];
        }

        if (['managed_storage_files', 'asset_files'].includes(this.required_files)){
            delete this.file_keys_list.category_info;
            delete this.file_keys_list.template_name;
            this.permission_name = 'asset_files';
            this.file_keys_list.name.class = "col-lg-3 fil_name";
        }


        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);

        if (this.permissions_list[('view_' + this.permission_name)]){
            this.file_keys_list.files_download = {
                key: 'files_download',
                label: "Download",
                class: "col-lg-2 fil_files_download text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'files_download'
            };
        }

        this.getList();
    },
    methods: {
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta(this.page_unique_name+'_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_'+this.page_unique_name+'s_per_page', perPage, message);
            this.refreshContent();
        },
        handleQueryItems(required_query) {
            if (this.current_sp && this.item_info.current_page_type === 'sp') {
                required_query.sp_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_info.current_page_type === 'customer') {
                required_query.customer_id = this.current_customer.id;
            }
            return required_query;
        },

        async getList() {
            let required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            required_query = this.handleQueryItems(required_query);


            let requiredResource;
            if (['managed_storage_files', 'asset_files'].includes(this.required_files)){
                if (this.required_files === 'managed_storage_files') {
                    required_query.item_id = this.item_info.item_info.id;
                }else{
                    required_query.item_id = this.item_info.id;
                }
                required_query.item_type = 'App\\Asset';
                requiredResource = assetFileResource;
            }else{
                if (this.required_files === 'files'){
                    required_query.item_id = this.item_info.id;
                    if (this.page_unique_name === 'order_files'){
                        required_query.item_type = 'order';
                    }
                }else if (this.required_files === 'all_files'){
                    required_query.category_id = this.template_category_id;
                }
                requiredResource = fileResource;
            }

            const {data} = await requiredResource.list(required_query, null, true);

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.filesData = data.data.items;
                this.request_meta = data.meta;
            }

            this.listLoading = false;
        },
        performMSFileAction(file, action, fileKey){
            if (action === ('delete'+this.permission_name)){
                if (file.id !== ''){
                    this.deleteItemByID(file.id, file.name, this.object_type, file)
                        .then((response) => {
                            if (response.data.data.status){
                                if (['managed_storage_files', 'asset_files'].includes(this.required_files)){
                                    this.filesData.splice(fileKey, 1);
                                }else if(this.item_info && this.item_info.hasOwnProperty('files_info')){
                                    this.item_info.files_info.splice(fileKey, 1);
                                }
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
    }
}
</script>
