<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item && selected_item.hasOwnProperty('id') ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <b-form-input
                    v-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <b-form-textarea
                    v-else-if="required_field.type === 'textarea'"
                    v-model="addForm[required_field.key]"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    class="form-control">
                </b-form-textarea>

                <multiselect
                    v-else-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    :loading="required_field.hasOwnProperty('listLoading') ? required_field.listLoading : false"
                    class="mySingleSelect"
                    :allow-empty="false"
                    deselect-label=""
                    :options="required_field.options">
                    <span slot="noResult">No data found.</span>
                </multiselect>

            </b-form-group>
        </div>

        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item && selected_item.hasOwnProperty('id') ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="$bvModal.hide('create-' + item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import AFButton from "@/components/AFButton";
const folderResource = new Resource('api/assetflow/folders');
import multiSelect from '@/mixins/MultiSelect';

export default {
    name: "AddNewFolder",
    components: {AFButton},
    props: ['modal_title', 'item_type', 'folders_options', 'selected_customer'],
    mixins:[multiSelect],
    data(){
        return {
            addForm:{
                parent_folder_id: null,
                folder_name: '',
                folder_description: '',
            },
            required_items:{
                parent_folder_id: {
                    key: 'parent_folder_id',
                    label: 'Parent Folder',
                    type: 'single-select',
                    options: []
                },
                folder_name: {
                    key: 'folder_name',
                    label: 'Folder Name',
                    type: 'text'
                },
                folder_description: {
                    key: 'folder_description',
                    label: 'Folder Description',
                    type: 'textarea'
                },
            },
            selected_item: null,
            customer_id: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{

        async getFieldsList(){
            this.is_saving_enabled = true;
            this.lists_values = {};
            this.isLoadingItem = true;
            this.required_items.parent_folder_id.listLoading = true;

            let required_select_lists =  {
                parent_folder_id: 'folders_list',
            }
            let params = {
                source_type: 'folder_modal',
                object_type: 'folders',
                action: 'new_folder',
                required_lists: required_select_lists,
            };
            if (this.selected_customer && this.selected_customer.id){
                this.customer_id = this.selected_customer.id;
            }

            if (this.customer_id !== null){
                params.customer_id = this.customer_id;
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: params
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                if (this.selected_item){
                    let parent_folder_id_options = this.getFolderObject(this.lists_values.parent_folder_id, 1, 'folders', 'id', 'name');

                    if (this.selected_item.hasOwnProperty('id') && this.selected_item.id){
                        let selected_item_id = this.selected_item.id;
                        parent_folder_id_options = parent_folder_id_options.map(function(folder) {
                            folder.$isDisabled = folder.id === selected_item_id;
                            return folder;
                        })
                    }

                    this.required_items.parent_folder_id.options = parent_folder_id_options;
                    if (this.selected_item.hasOwnProperty('parent_folder_id')) {
                        this.addForm.parent_folder_id = this.getObjectOf(this.selected_item.parent_folder_id, this.required_items.parent_folder_id.options);
                    }
                }else{
                    this.required_items.parent_folder_id.options = this.lists_values.parent_folder_id;
                    this.addForm.parent_folder_id = this.lists_values.parent_folder_id[0];
                }

                this.required_items.parent_folder_id.listLoading = false;
                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                if (this.selected_item.hasOwnProperty('id')){
                    this.addForm.folder_name = this.selected_item.name;
                    this.addForm.folder_description = this.selected_item.description;
                }

                if (this.selected_item.hasOwnProperty('customer_id')){
                    this.customer_id = this.selected_item.customer_id;
                }else if (this.selected_customer && this.selected_customer.id){
                    this.customer_id = this.selected_customer.id;
                }

            }
        },
        setSelectedItem(item){
            this.addForm.parent_folder_id = null;
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },


        async addItem(event){
            this.isAddingItem = true;

            var params = {
                parent_folder_id: this.addForm.parent_folder_id ? this.addForm.parent_folder_id.id : 0,
                name: this.addForm.folder_name,
                description: this.addForm.folder_description
            };
            if (this.selected_item && this.selected_item.hasOwnProperty('id')){
                params.item_id = this.selected_item.id;
            }
            if (this.selected_customer && this.selected_customer.id){
                params.customer_id = this.selected_customer.id;
            }else if (this.selected_item && this.selected_item.hasOwnProperty('customer_id')){
                params.customer_id = this.selected_item.customer_id;
            }

            const { data } = await folderResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item && this.selected_item.hasOwnProperty('id') ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-' + this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


