<template>
    <div>
        <div class="card-row" v-if="item_info.parent_address && item_info.parent_address.name">
            <div class="card-left">Address</div>
            <div class="card-right">
                <a :href="'/' + item_info.current_page_type + '/addresses/'+item_info.parent_address.id+'/view'"
                   class="text-dark">
                    {{ item_info.parent_address.name | titleize}}
                </a>
            </div>
        </div>
        <div class="card-row" v-if="item_info.address_location_latitude">
            <div class="card-left">Latitude</div>
            <div class="card-right">
                {{ item_info.address_location_latitude}}
            </div>
        </div>
        <div class="card-row" v-if="item_info.address_location_longitude">
            <div class="card-left">Longitude</div>
            <div class="card-right">
                {{ item_info.address_location_longitude}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssetLocationBlock',
    props: {
        item_info: {}
    }
}
</script>
