<template>
    <b-dropdown-group id="dropdown-group-2">
        <b-dropdown-item v-if="hasPermission(required_permission_name)" :href="'/af_files/' + item.id + '/'+object_single_name+'/queue/10'"
                         target="_blank">
            Export (CSV)
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission(required_permission_name)" :href="'/af_files/' + item.id + '/'+object_single_name+'/queue/11'"
                         target="_blank">
            Export (XLSX)
        </b-dropdown-item>
        <b-dropdown-item v-if="(object_single_name !== 'sp_storage') && hasPermission(required_permission_name)" :href="'/af_files/' + item.id + '/'+object_single_name+'/queue/12'"
                         target="_blank">
            Export with Images (XLSX)
        </b-dropdown-item>
    </b-dropdown-group>
</template>
<script>
export default {
    name: 'ExportAdditionalActions',
    props: {
        item: {},
        required_permission_name: '',
        object_single_name: '',
    }
}
</script>
