import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import {Currencies} from "@/views/assetflow/form_data/Currencies";

export default {
    data(){
        return {
            current_user_info: null,
            current_customer: null,
            currencies_list: Currencies
        }
    },
    created: function () {
        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
        }

    },
    methods: {
        getCustomerRateFor(currency){
            if (this.current_user_info.hasOwnProperty('customer_meta') && this.current_user_info.customer_meta.hasOwnProperty('currencies_meta')){
                let currencies_meta = this.current_user_info.customer_meta.currencies_meta;
                if (currencies_meta.hasOwnProperty(currency) && currencies_meta[currency] !== '' && currencies_meta[currency] !== null){
                    return parseFloat(currencies_meta[currency]);
                }
            }
            let selected_currency_index = this.currencies_list.map(function (e) {
                return e.value;
            }).indexOf(currency);

            if (selected_currency_index > -1){
                return this.currencies_list[selected_currency_index].rate;
            }else{
                return this.currencies_list[0].rate;
            }
        },
        getTotalTotals(required_items){
            let user_selected_currency = this.getCurrencyByValue(this.current_user_info.user_meta.currency);
            let myToUserCurrencyValue = this.toUserCurrencyValue;

            let actual_sum = sumBy(required_items, function (item) {
                if (item){
                    let curr = item.used_currency || item.currency
                    let value = myToUserCurrencyValue(curr, item.total_value, '');
                    return Number(value);
                }
                return 0;
            });
            return '~' + user_selected_currency.icon + actual_sum.toFixed(2);

        },
        getSumByValue: function (items, value) {
            return sumBy(items, function (item) {
                return item ? parseFloat(item[value]) : 0;
            }).toFixed(2);
        },
        getTotalsGroupedByCurrency(required_items, html_only=false, text_tag='b'){
            const currencies_list = groupBy(required_items, function (po_item) {
                return po_item ? po_item.used_currency : 'gbp';
            });
            let totals_desc = '';

            let index = 0;
            if (currencies_list && Object.keys(currencies_list).length !== 0){
                for (const key in currencies_list) {
                    let currency = this.getCurrencyByValue(key);
                    let item_by_currency = currencies_list[key];
                    let total_value_sum = this.getSumByValue(item_by_currency, 'total_value');

                    if (html_only){
                        totals_desc += '<'+text_tag+' class="total_currency_value currency_'+currency.value+'" v-b-tooltip:hover.bottom title="'+this.toUserCurrencyValue(currency.value, total_value_sum)+'">';
                    }
                    totals_desc += currency.value.toUpperCase() + ': ' + currency.icon + total_value_sum;
                    if (html_only){
                        totals_desc += '</'+text_tag+'>';
                    }

                    if (index !== Object.keys(currencies_list).length - 1){
                        totals_desc += ', ';
                    }

                    index += 1;
                }
            }else{
                totals_desc = '0';
            }

            return totals_desc;
        },
        toUserCurrencyValueTitle(index, item, key){
            var from_currency = item.currency ? item.currency : item.used_currency;
            if (!from_currency){
                from_currency = 'gbp';
            }

            let value = item[key];

            if (['forecast_pl', 'actual_pl'].includes(key)){
                let edited_key = key === 'forecast_pl' ? 'forecast_value' : 'sale_price';
                value = item[edited_key] - item.total_value;
            }
            if (key === 'amount' && item.hasOwnProperty('asset_info') && item.asset_info){
                value = item.asset_info.sale_price;
            }

            return this.toUserCurrencyValue(from_currency, value);
        },
        toUserCurrencyValueTitleUsingIndex(index, item, key){
            return this.toUserCurrencyValue(index, item[key]);
        },
        addCurrencyIconPrefix(from_currency, value){
            let from_currency_obj = this.getCurrencyByValue(from_currency);
            let prefix = '';
            value = Number(value);
            if (value < 0){
                value = -1*value;
                prefix += '-';
            }

            return prefix + from_currency_obj.icon + value.toFixed(2);
        },
        toUserCurrencyValue(from_currency, value, prefix='~'){
            let current_customer = this.current_customer;
            if (current_customer && current_customer.meta && this.current_user_info.user_meta){
                if (this.current_user_info.user_meta.currency !== from_currency ){

                    from_currency = from_currency === '' ? 'gbp' : from_currency;
                    if (from_currency !== 'gbp'){
                        // should change it back to gbp
                        value = value / this.getCustomerRateFor(from_currency);
                    }

                    let user_selected_currency = this.getCurrencyByValue(this.current_user_info.user_meta.currency);

                    if (value < 0){
                        value = -1*value;
                        prefix += '-';
                    }
                    let actual_value = value * this.getCustomerRateFor(this.current_user_info.user_meta.currency);
                    if (prefix === ''){
                        return actual_value;
                    }
                    return prefix + user_selected_currency.icon + actual_value.toFixed(2);
                }else{
                    if (prefix === ''){
                        return value;
                    }
                    // let user_selected_currency = this.getCurrencyByValue('gbp');
                    // return prefix + user_selected_currency.icon + parseInt(value).toFixed(2);
                }
            }
            return '';
        },
        toUserTotalWithCurrency(totals_info){
            return this.toUserCurrencyValue('gbp', totals_info.gbp_total_value);
        },
        getCurrencyByValueTextUsingIndex(index, item, key){
            return this.getCurrencyByValue(index).icon + item[key];
        },
        getCurrencyByValueText(index, item, key){
            var from_currency = item.currency ? item.currency : item.used_currency;
            let value = item[key];
            if (!from_currency){
                from_currency = 'gbp';
            }

            if (key === 'assets_sum_value'){
                value = Number(value).toFixed(2);
            }else if (['forecast_pl', 'actual_pl'].includes(key)){
                let edited_key = key === 'forecast_pl' ? 'forecast_value' : 'sale_price';
                value = item[edited_key] - item.total_value;
            }else if (key === 'amount' && item.hasOwnProperty('asset_info') && item.asset_info){
                value = item.asset_info.sale_price;
            }


            return this.getCurrencyByValue(from_currency).icon + (value ? value : 0);
        },
        getCurrencyByValue: function (value, icon_with_text=false) {
            let selected_currency_index = this.currencies_list.map(function (e) {
                return e.value;
            }).indexOf(value);
            let requried_currency = this.currencies_list[selected_currency_index];
            if (icon_with_text){
                return requried_currency.icon + ' - ' + requried_currency.text;
            }
            return requried_currency;
        },
        getTotal(totals_info){
            let selected_currency = this.getCurrencyByValue(totals_info.currency);
            return selected_currency.icon + "" + totals_info.customers_total_value;
        },
        getDetailedTotals: function (required_items) {
            const currencies_list = groupBy(required_items, function (po_item) {
                return po_item.currency.value;
            });

            let new_list = {};
            for (const key in currencies_list) {
                let po_items = currencies_list[key];
                new_list[key] = {
                    total_value_sum: this.getSumByValue(po_items, 'total_value'),
                    quantity_sum: this.getSumByValue(po_items, 'quantity'),
                }
            }

            return new_list;
        },
        getTotalsUsingPreferredCurrency(required_items) {
            let selected_currency = 'gbp';

            let getCustomerRateFor = this.getCustomerRateFor;
            const gbp_total_value = sumBy(required_items, function (po_item) {
                return parseFloat(po_item.total_value) / getCustomerRateFor(po_item.currency.value);
            });

            let customers_total_value = gbp_total_value;

            if (this.current_customer) {
                selected_currency = this.current_customer.meta.currency;
                let currencies = this.currencies_list;
                customers_total_value = sumBy(required_items, function (po_item) {
                    let value_in_gbp = parseFloat(po_item.total_value) / getCustomerRateFor(po_item.currency.value);
                    return value_in_gbp * getCustomerRateFor(selected_currency);
                });
            }

            return {
                currency: selected_currency,
                gbp_total_value: gbp_total_value.toFixed(2),
                customers_total_value: customers_total_value.toFixed(2),
                quantity_sum: this.getSumByValue(required_items, 'quantity')
            };
        },
    }
}
