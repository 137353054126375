<script>

    export default {
        name: 'SearchBox',
        components: { },
        props: {
            search_api: {
                type: String,
                default: ''
            },
            placeholder_text: {
                type: String,
                default: 'Search...'
            }
        },
        data() {
            return {
                input_value: '',
            };
        },
        methods: {
            setUrlSParameter: function () {
                if (window.history.pushState) {
                    let params = new URLSearchParams(window.location.search);
                    params.set('s', encodeURIComponent(this.input_value));
                    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
                    window.history.replaceState({url: newUrl}, null, newUrl);
                }
            },
            inputChanged(event){
                this.setUrlSParameter();
                this.$emit('on-value-changed', this.input_value, event);
            },
            setInputValue(input){
                this.input_value = input;
                if (input === ''){
                    this.setUrlSParameter();
                }
                this.$refs.search_input.focus();
            }
        }
    };
</script>

<template>
    <div class="search-box mr-2 mb-2 d-inline-block">
        <div class="position-relative">
            <input type="search"
                   ref="search_input"
                   @input="inputChanged($event)"
                   v-model="input_value"
                   class="form-control" :placeholder="placeholder_text" />
            <i class="bx bx-search-alt search-icon"></i>
        </div>
    </div>
</template>

