<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <CurrentUserRoleList/>
    </Layout>
</template>

<script>
    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import CurrentUserRoleList from "./CurrentUserRoleList";

    export default {
        props:['item_id', 'item_type', 'action'],
        name: "MyRoles",
        components: {
            CurrentUserRoleList,
            Layout,
            PageHeader
        },
        data() {
            return {
                title: "My Roles",
                items: [
                    {
                        text: "Profile",
                        href: "/profile"
                    },
                    {
                        text: "My Roles",
                        active: true
                    }
                ],
                user_info: {
                    id: null,
                },
            };
        },
        created() {
            this.user_info = window.USER_INFO ? window.USER_INFO : this.user_info;
        },
        methods: {

        }
    }
</script>


