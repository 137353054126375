<template>
    <asset-list
        :item_id="item_id"
        :item_type="item_type"
        :shared_info_string="shared_info_string"
        :is_group="true">
    </asset-list>
</template>

<script>
import AssetList from "./List";
export default {
    props: ['item_id', 'item_type', 'shared_info_string'],
    name: "GroupAssets",
    components: {AssetList}
}
</script>

