<template>
    <div>
        <AddNewScene
            ref="add-new-scenes"
            item_type='scenes'
            modal_title="Create New Scene"
            @on-new-item-adding="addNewItemToOptions"
        >
        </AddNewScene>

        <AddNewSceneLocation
            ref="add-new-scene_locations"
            item_type='scene_locations'
            modal_title="Create New Location"
            @on-new-item-adding="addNewItemToOptions"
        >
        </AddNewSceneLocation>

        <AddNewShootDay
            ref="add-new-shoot_days"
            item_type='shoot_days'
            modal_title="Create New Shoot Day"
            @on-new-item-adding="addNewItemToOptions"
        ></AddNewShootDay>


        <h2 class="mb-2">{{ this.selected_item ? 'Edit' : 'Add' }} Part</h2>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />


                <div class="st-radio-row"
                     v-if="required_field.type === 'radio-select'">
                    <div v-for="(option, index) in required_field.options"
                         :key="index"
                         class="col-3">
                        <div class="cm-radio-btn" :class="'mt-1 '+required_field.key+'-' + option.id">
                            <label>
                                <input
                                    v-model="addForm[required_field.key]"
                                    :key="index"
                                    :id="'_'+index"
                                    :name="required_field.key"
                                    :value="option"
                                    @input="selectedRadioButton(required_field.key, option, $event)"
                                    type="radio" />
                                <div class="st-radio-box">
                                    <span></span>
                                    <p>{{ option.name | titleize }}</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <multiselect
                    v-else-if="['single-select', 'multi-select'].includes(required_field.type)"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    :custom-label="required_field.hasOwnProperty('custom_label') ? required_field.custom_label : CustomMSLabel"
                    :multiple="required_field.type === 'multi-select'"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    @input="selectedRadioButton(required_field.key, $event)"
                    :options="required_field.options"
                    :allow-empty="required_field.type === 'multi-select'"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="['text', 'number'].includes(required_field.type)"
                    v-model="addForm[required_field.key]"
                    :type="required_field.type"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

                <span v-if="required_field.hasOwnProperty('note') && required_field.note !== ''">{{ required_field.note }}</span>
            </b-form-group>

        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item && selected_item.name ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>

            <b-button v-if="is_full_page" variant="outline-danger" :href="'/' + item_type + '/'+object_type" >Cancel</b-button>
            <b-button v-else variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>

    </div>
</template>

<script>
import Resource from '@/api/resource';
const partResource = new Resource('api/assetflow/parts');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import AddNewScene from "./AddNewScene";
import AddNewSceneLocation from "./AddNewSceneLocation";
import AddNewShootDay from "./AddNewShootDay";

export default {
    mixins: [multiSelect],
    name: "AddNewPartForm",
    props: ['modal_title', 'item_type', 'is_full_page', 'object_type'],
    components: {AddNewShootDay, AddNewSceneLocation, AddNewScene, DatePicker},
    data(){
        return {
            addForm:{
                scene_id: null,
                part_name: '',
                part_order: null,
                day_number: '',
                scene_time: null,
                scene_int_ext: null,
                scene_location_id: null,
                description: '',
                shoot_day_id: null,
                character_ids: null,
            },
            required_fields:{
                scene_id: {
                    key: 'scene_id',
                    label: 'Scene',
                    type: 'single-select',
                    options:[]
                },
                part_name: {
                    key: 'part_name',
                    label: 'Name',
                    type: 'text'
                },
                part_order: {
                    key: 'part_order',
                    label: 'Part Sort Order',
                    type: 'single-select',
                    options:[
                        {id: 1, name: 'A'},
                        {id: 2, name: 'B'},
                        {id: 3, name: 'C'},
                        {id: 4, name: 'D'},
                        {id: 5, name: 'E'},
                        {id: 6, name: 'F'},
                        {id: 7, name: 'G'},
                        {id: 8, name: 'H'},
                        {id: 9, name: 'I'},
                        {id: 10, name: 'J'},
                        {id: 11, name: 'K'},
                        {id: 12, name: 'L'},
                        {id: 13, name: 'M'},
                        {id: 14, name: 'N'},
                        {id: 15, name: 'O'},
                        {id: 16, name: 'P'},
                        {id: 17, name: 'Q'},
                        {id: 18, name: 'R'},
                        {id: 19, name: 'S'},
                        {id: 20, name: 'T'},
                        {id: 21, name: 'U'},
                        {id: 22, name: 'V'},
                        {id: 23, name: 'W'},
                        {id: 24, name: 'X'},
                        {id: 25, name: 'Y'},
                        {id: 26, name: 'Z'}
                    ]
                },
                day_number: {
                    key: 'day_number',
                    label: 'Story Day',
                    type: 'number'
                },
                scene_time: {
                    key: 'scene_time',
                    label: 'Time',
                    type: 'single-select',
                    options:[
                        {id: 'D', name: 'D'},
                        {id: 'N', name: 'N'},
                        {id: 'F', name: 'F'}
                    ]
                },
                scene_int_ext: {
                    key: 'scene_int_ext',
                    label: 'Int \\ Ext',
                    type: 'single-select',
                    options:[
                        {id: 0, name: 'Int'},
                        {id: 1, name: 'Ext'}
                    ]
                },
                scene_location_id: {
                    key: 'scene_location_id',
                    label: 'Location',
                    type: 'single-select',
                    options:[]
                },
                description: {
                    key: 'description',
                    label: 'Description',
                    type: 'textarea'
                },
                shoot_day_id: {
                    key: 'shoot_day_id',
                    label: 'Shoot Day',
                    type: 'single-select',
                    options:[]
                },
                character_ids: {
                    key: 'character_ids',
                    label: 'Character',
                    type: 'multi-select',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null,
            lists_values: {}
        }
    },
    created() {
        this.getFieldsList();
        this.required_fields.shoot_day_id.custom_label = this.ShootDayLabel;
    },
    methods:{
        ShootDayLabel({ id, shoot_date, name }){
            if (id === -99){
                return name;
            }
            let scene_name = '';
            if (shoot_date){
                scene_name = '{' + Vue.options.filters.moment(shoot_date, 'MMMM Do YYYY') + '} ';
            }
            return scene_name + name;
        },
        addNewItemToOptions(new_item, item_type){
            if (new_item) {
                let item_type_keys = {
                    'scenes': 'scene_id',
                    'scene_locations': 'scene_location_id',
                    'shoot_days': 'shoot_day_id',
                }

                // should append this to the list
                let item_type_key = item_type;
                if (Object.keys(item_type_keys).includes(item_type)){
                    item_type_key = item_type_keys[item_type];
                }

                let new_option = {id: new_item.id, name: new_item.name};

                this.required_fields[item_type_key].options.push(new_option);
                this.addForm[item_type_key] = new_option;
            }
        },
        PartDetailsItem({ id, uid, scene_info, name }){
            if (id === -99){
                return name;
            }
            let scene_name = '';
            if (scene_info){
                scene_name = scene_info.name + ' => ';
            }
            return scene_name + name;
        },
        selectedRadioButton(field, option){
            if (option.id === -99){
                if (field === 'scene_id'){
                    this.$refs['add-new-scenes'].setSelectedItem(null);
                    this.addForm.scene_id = null;
                    this.$bvModal.show('create-scenes');
                }else if (field === 'scene_location_id'){
                    this.$refs['add-new-scene_locations'].setSelectedItem(null);
                    this.addForm.scene_location_id = null;
                    this.$bvModal.show('create-scene_locations');
                }else if (field === 'shoot_day_id'){
                    this.$refs['add-new-shoot_days'].setSelectedItem(null);
                    this.addForm.shoot_day_id = null;
                    this.$bvModal.show('create-shoot_days');
                }
            }
        },
        async getFieldsList(selected_item=null){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                scene_id: 'scenes_list',
                scene_location_id: 'scene_locations_list',
                shoot_day_id: 'shoot_days_list',
                character_ids: 'character_short_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                let with_add_new = ['scene_id', 'scene_location_id', 'shoot_day_id'];
                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets', with_add_new);
                }

                if (selected_item){
                    this.setSelectedItem(selected_item);
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setDefaultValues() {
            // nothing to set?
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },

        setValueFromOptions: function ( field_name, item_id, default_value = null, is_multi=false) {
            let found_item;
            if (item_id !== undefined){
                if (is_multi){
                    found_item = this.required_fields[field_name].options.filter((item) => {
                        return item_id.includes(item.id);
                    });
                }else{
                    found_item = _.find(this.required_fields[field_name].options, function (o) {
                        return o.id === item_id;
                    });
                }

                if (found_item){
                    this.addForm[field_name] = found_item ? found_item : default_value;
                }
            }
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('scene_id', this.selected_item.scene_id);
                this.setValueFromOptions('part_order', this.selected_item.part_order);//  ?? multi?
                this.setValueFromOptions('scene_time', this.selected_item.scene_time);//  ?? multi?
                this.setValueFromOptions('scene_int_ext', this.selected_item.scene_int_ext);//  ?? multi?
                this.setValueFromOptions('scene_location_id', this.selected_item.scene_location_id);//  ?? multi?
                this.setValueFromOptions('shoot_day_id', this.selected_item.shoot_day_id);//  ?? multi?
                this.setValueFromOptions('character_ids', this.selected_item.character_ids, null, true);//  ?? multi?
                this.addForm.part_name = this.selected_item.name;
                this.addForm.day_number = this.selected_item.day_number;
                this.addForm.description = this.selected_item.description;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
            this.setDefaultValues();
        },

        async addItem(event){
            this.isAddingItem = true;

            let character_ids = this.addForm.character_ids;
            if (character_ids && character_ids.length > 0){
                character_ids = this.addForm.character_ids.map(function(e) { return e.id; }).join(",");
            }
            var params = {
                scene_id: this.addForm.scene_id ? this.addForm.scene_id.id : null,
                part_order: this.addForm.part_order ? this.addForm.part_order.id : null,
                scene_time: this.addForm.scene_time ? this.addForm.scene_time.id : null,
                scene_int_ext: this.addForm.scene_int_ext ? this.addForm.scene_int_ext.id : null,
                scene_location_id: this.addForm.scene_location_id ? this.addForm.scene_location_id.id : null,
                shoot_day_id: this.addForm.shoot_day_id ? this.addForm.shoot_day_id.id : null,
                character_ids: character_ids,
                part_name: this.addForm.part_name,
                day_number: this.addForm.day_number,
                description: this.addForm.description,
            };

            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await partResource.store(params);

            this.isAddingItem = false;
            this.resetSelectedItem();

            let new_item = data.data.items;
            let message = new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg;
            if (new_item.hasOwnProperty('id')) {
                let item_name = ', PRT-' + new_item.uid + ' {' + new_item.name + '}';
                message = 'Added Successfully' + item_name;
            }
            window.flash(message);
            window.location.href = '/' + this.item_type + '/' + this.object_type;

        }
    }
}
</script>
