<template>
    <Layout parent_class="asset-acquisition-form-wrap">
        <AddAssetAcquisitionForm
            :item_id="item_id"
            :item_type="item_type"
            object_type="asset_acquisition"
            object_type_link="acquisitions"
            object_type_shortcut="ACQ"
            :action="action"
            :with_sidebar="false"
        />

    </Layout>
</template>

<script>

import Layout from "@/layouts/main";
import AddAssetAcquisitionForm from "./AddAssetAcquisitionForm";

export default {
    name: "NewAssetAcquisition",
    props: ['item_id', 'item_type', 'action'],
    components: {AddAssetAcquisitionForm, Layout},
    data() {
        return {}
    },
    created() {

    },
    methods:{

    }
}
</script>

