const FirstPath = window.location.pathname.split('/')[1];

let formatFileSize = function (bytes,decimalPoint) {
    if(!bytes) return '0 Bytes';
    let k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const FileImportKeysList = {
    uid: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 fil_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                'status-btn-acquired',
                'status-btn-active',
                'status-btn-disposed'
            ];

            return '<a class="text-dark" href="/' + FirstPath + '/file_imports/' + item.id + '/view"><span class="button_size ' + (status_list[item.status]) + '">' + item[key] + '</span></a>';
        }
    },
    file_type: {
        key: 'file_type',
        label: "File Type",
        class: "col-lg-1 fil_file_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return (item[key] + "").toUpperCase();
        }
    },
    file_path: {
        key: 'file_path',
        label: "Original File",
        class: "col-lg-4 fil_file_path",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('original_file_url') && item.original_file_url){
                return '<a class="text-dark" target="_blank" href="' + item.original_file_url +'">' + item.name + '</a>' + ' (' + formatFileSize(item.size, 2) +')';
            }
            return '-';
        }
    },
    edited_file_path: {
        key: 'edited_',
        label: "Edited File",
        class: "col-lg-2 fil_edited_",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('edited_file_url') && item.edited_file_url){
                let edited_file_size = item.hasOwnProperty('edited_file_size') ? item.edited_file_size : 0 ;
                return '<a class="text-dark" target="_blank" href="' + item.edited_file_url +'">' + item.name + '</a>' + ' (' + formatFileSize(edited_file_size, 2) +')';
            }
            return '-';
        }
    },
    object_type: {
        key: 'object_type',
        label: "Object Type",
        class: "col-lg-1 fil_object_type",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.titleize(item[key] + 's');
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-2 fil_created_at",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'MMMM Do YYYY hh:mm:ss a') : '';
        }
    },
    user_info: {
        key: 'user_info',
        label: "Created By",
        class: "col-lg-1 fil_user_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('user_info') && item.user_info){
                let user_info = item.user_info;
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
            }
            return '';
        }
    },
};
