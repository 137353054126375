<template>
    <div class="customers-list" :class="block_class">
        <div v-if="required_items.includes('customers')" class="main-root-top sp-top-filters customers-top-filter">
            <h3 class="filter_option header-customer" v-if="selected_customer">{{ selected_customer.name | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="customers_search" type="text" placeholder="Search..">
                <li v-for="(customer_item, index) in computed_customers_list"
                    :class="'root-top-menu' + (selected_customer && selected_customer.id === customer_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(customer_item, 'customer')">
                        {{ customer_item ? customer_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <span v-if="selected_customer.id && required_items.includes('customers') && required_items.includes('orders')" class="arrow-right-icon"></span>
        <div v-if="selected_customer.id && required_items.includes('orders')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_order.name || selected_order.label) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <li v-for="(order_item, index) in orders_list" :class="'root-top-menu' + (selected_order && selected_order.id === order_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(order_item, 'order')">
                        {{ order_item ? order_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="required_items.includes('sp_warehouses')" class="main-root-top connected-sp-top-filters warehouses-top-filter">
            <h3 class="filter_option header-warehouse">{{ selected_sp_warehouse.name | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="sp_warehouses_search" type="text" placeholder="Search..">
                <li v-for="(sp_warehouse_item, index) in computed_sp_warehouses_list" :class="'root-top-menu' + (selected_sp_warehouse && selected_sp_warehouse.id === sp_warehouse_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(sp_warehouse_item, 'sp_warehouse')">
                        {{ sp_warehouse_item ? sp_warehouse_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <span v-if="selected_sp_warehouse.id && required_items.includes('sp_warehouses') && required_items.includes('sp_spaces')" class="arrow-right-icon"></span>
        <div v-if="selected_sp_warehouse.id && required_items.includes('sp_spaces')" class="main-root-top connected-sp-top-filters spaces-top-filter">
            <h3 class="filter_option header-space">{{ selected_sp_space.name | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="sp_spaces_search" type="text" placeholder="Search..">
                <li v-for="(sp_space_item, index) in computed_sp_spaces_list" :class="'root-top-menu' + (selected_sp_space && selected_sp_space.id === sp_space_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(sp_space_item, 'sp_space')">
                        {{ sp_space_item ? sp_space_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>


        <div v-if="required_items.includes('status_storage')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_status_storage.name || selected_status_storage.label) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="status_storages_search" type="text" placeholder="Search..">
                <li v-for="(status_storage_item, index) in computed_status_storages_list" :class="'root-top-menu' + (selected_status_storage && selected_status_storage.id === status_storage_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(status_storage_item, 'status_storage')">
                        {{ status_storage_item ? status_storage_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="required_items.includes('sp_movement_type')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_sp_movement_type.name) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="sp_movement_types_search" type="text" placeholder="Search..">
                <li v-for="(sp_movement_type_item, index) in computed_sp_movement_types_list" :class="'root-top-menu' + (selected_sp_movement_type && selected_sp_movement_type.id === sp_movement_type_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(sp_movement_type_item, 'sp_movement_type')">
                        {{ sp_movement_type_item ? sp_movement_type_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="required_items.includes('customer_status')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_customer_status.name) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="customer_statuss_search" type="text" placeholder="Search..">
                <li v-for="(customer_status_item, index) in computed_customer_statuss_list" :class="'root-top-menu' + (selected_customer_status && selected_customer_status.id === customer_status_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(customer_status_item, 'customer_status')">
                        {{ customer_status_item ? customer_status_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="required_items.includes('user_status')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_user_status.name) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="user_statuss_search" type="text" placeholder="Search..">
                <li v-for="(user_status_item, index) in computed_user_statuss_list" :class="'root-top-menu' + (selected_user_status && selected_user_status.id === user_status_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(user_status_item, 'user_status')">
                        {{ user_status_item ? user_status_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="required_items.includes('created_at')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_created_at.name) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="created_ats_search" type="text" placeholder="Search..">
                <li v-for="(created_at_item, index) in computed_created_ats_list" :class="'root-top-menu' + (selected_created_at && selected_created_at.id === created_at_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(created_at_item, 'created_at')">
                        {{ created_at_item ? created_at_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="required_items.includes('file_type')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_file_type.name) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="file_types_search" type="text" placeholder="Search..">
                <li v-for="(file_type_item, index) in computed_file_types_list" :class="'root-top-menu' + (selected_file_type && selected_file_type.id === file_type_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(file_type_item, 'file_type')">
                        {{ file_type_item ? file_type_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="required_items.includes('category_id')" class="main-root-top sp-top-filters statuses-top-filter">
            <h3 class="filter_option header-status">{{ (selected_category_id.name) | titleize }}</h3>
            <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="category_ids_search" type="text" placeholder="Search..">
                <li v-for="(category_id_item, index) in computed_category_ids_list" :class="'root-top-menu' + (selected_category_id && selected_category_id.id === category_id_item.id ? ' active' : '')">
                    <a @click="setSelectedTopFilter(category_id_item, 'category_id')">
                        {{ category_id_item ? category_id_item.name : '-' }}
                    </a>
                </li>
            </ul>
        </div>


        <div class="main-root-top sp-top-filters current-title-top-filter" v-if="current_title || current_title_html">
            <h3 class="header-selected-item" v-if="current_title_html" v-html="current_title_html"></h3>
            <h3 class="header-selected-item" v-else>{{ (current_title !== '' ? current_title : 'List') }}</h3>
        </div>
    </div>
</template>
<script>
import {CreatedDatesList} from "@/views/assetflow/form_data/CreatedDatesList";
import {FileTypesList} from "@/views/assetflow/form_data/FileTypesList";
import {FileTemplateCategoriesList} from "@/views/assetflow/form_data/FileTemplateCategoriesList";

export default {
    name: 'TopFiltersBlock',
    props: [
        'block_class',
        'object_type',
        'action',
        'required_items',
        'current_title_html',
        'current_title'
    ],
    data(){
        return {
            customers_search: null,
            sp_warehouses_search: null,
            sp_spaces_search: null,
            sp_movement_types_search: null,
            customer_statuss_search: null,
            user_statuss_search: null,
            created_ats_search: null,
            file_types_search: null,
            category_ids_search: null,
            status_storages_search: null,

            selected_customer: {
                id: null,
                name: 'All Customers'
            },
            customers_list: [],
            selected_sp_warehouse: {
                id: null,
                name: 'All Warehouses'
            },
            sp_warehouses_list: [],
            selected_sp_space: {
                id: null,
                name: 'All Spaces'
            },
            sp_spaces_list: [],
            selected_sp_movement_type: {
                id: null,
                name: 'All'
            },
            sp_movement_types_list: [
                { id: 0, name: 'Asset' },
                { id: 1, name: 'Storage' },
            ],
            selected_customer_status: {
                id: null,
                name: 'All'
            },
            customer_statuss_list: [
                { id: null, name: 'All Customers' },
                { id: 1, name: 'Active Customers' },
                { id: 0, name: 'Inactive Customers' },
            ],
            selected_user_status: {
                id: null,
                name: 'All'
            },
            user_statuss_list: [
                { id: null, name: 'All Users' },
                { id: 3, name: 'Created Users' },
                { id: 2, name: 'Invited Users' },
                { id: 1, name: 'Active Users' },
                { id: 0, name: 'Disabled Users' },
            ],
            selected_created_at: {
                id: null,
                name: 'All Days'
            },
            created_ats_list: CreatedDatesList,
            selected_file_type: {
                id: null,
                name: 'All Types'
            },
            file_types_list: FileTypesList,
            selected_category_id: {
                id: null,
                name: 'All Categories'
            },
            category_ids_list: FileTemplateCategoriesList,
            selected_status_storage: {
                id: null,
                name: 'All Statuses'
            },
            status_storages_list: [],
            selected_order: {
                id: null,
                name: 'All Orders'
            },
            orders_list: [],
            sp_only_lists: ['customers', 'sp_warehouses', 'sp_spaces']
        }
    },

    computed: {
        computed_customers_list(){
            return this.getCustomersListAfterSearch('customers_list', 'customers_search');
        },
        computed_sp_warehouses_list(){
            return this.getCustomersListAfterSearch('sp_warehouses_list', 'sp_warehouses_search');
        },
        computed_sp_spaces_list(){
            return this.getCustomersListAfterSearch('sp_spaces_list', 'sp_spaces_search');
        },
        computed_sp_movement_types_list(){
            return this.getCustomersListAfterSearch('sp_movement_types_list', 'sp_movement_types_search');
        },
        computed_customer_statuss_list(){
            return this.getCustomersListAfterSearch('customer_statuss_list', 'customer_statuss_search');
        },
        computed_user_statuss_list(){
            return this.getCustomersListAfterSearch('user_statuss_list', 'user_statuss_search');
        },
        computed_status_storages_list(){
            return this.getCustomersListAfterSearch('status_storages_list', 'status_storages_search');
        },
        computed_created_ats_list(){
            return this.getCustomersListAfterSearch('created_ats_list', 'created_ats_search');
        },
        computed_file_types_list(){
            return this.getCustomersListAfterSearch('file_types_list', 'file_types_search');
        },
        computed_category_ids_list(){
            return this.getCustomersListAfterSearch('category_ids_list', 'category_ids_search');
        },
    },
    created() {

        if (!this.is_sp_or_cross_or_admin){
            let edited_required_items = _.difference(this.required_items, this.sp_only_lists);
            this.$emit('update:required_items', edited_required_items)
        }

        this.getCustomersAndWarehousesList();
    },
    watch:{
        selected_sp_warehouse: {
            deep: true,
            handler: function (newValue, oldVal) {
                this.selected_sp_space = {
                    id: null,
                    name: 'All Spaces'
                };
                if (newValue && oldVal !== newValue && newValue.hasOwnProperty('id') && newValue.id !== null){
                    this.getTopFilterList('sp_space');
                }
            }
        },
    },
    methods:{
        getCustomersListAfterSearch(list_name='', search_keyword_name='') {
            var filtered_list=[];
            if (this[search_keyword_name] && this[search_keyword_name] !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['id', 'name'];
                    if (element.id === null){ return true; }
                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";
                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this[list_name].filter(startsWith(this[search_keyword_name]));
            } else {
                filtered_list = this[list_name];
            }
            return filtered_list
        },
        setSelectedTopFilter(required_item, item_type){
            let message = "Saved " + Vue.options.filters.titleize(item_type) + " {"+required_item.name+"} Successfully";
            window.flash(message);
            this.set_usermeta('top_filter_selected_'+item_type, required_item.id, "", false);
            if (['customer', 'status_storage'].includes(item_type)){
                this.set_usermeta((this.object_type + '_filter_type_id'), required_item.id, "", item_type === 'customer' && this.is_cross_entity);
            }

            this['selected_'+item_type] = required_item;

            this.$emit('onSelectingTopFilter', required_item, item_type);
        },
        getCustomersAndWarehousesList(){
            this.getTopFilterList('customer', !this.is_cross_entity); // with_all_title ?
            this.getTopFilterList('sp_warehouse');

            this.getTopFilterList('status_storage');
            this.getTopFilterList('order');

            if (this.required_items.includes('sp_movement_type')){
                let top_filter_selected_sp_movement_type = this.getUserMetaValueIfExist('top_filter_selected_sp_movement_type');
                let found_item = this.sp_movement_types_list.find((x) => x.id === (top_filter_selected_sp_movement_type));
                if (found_item){
                    this.selected_sp_movement_type = found_item;
                    this.$emit('onSelectingTopFilter', found_item, 'sp_movement_type');
                }
            }
            if (this.required_items.includes('customer_status')){
                let top_filter_selected_customer_status = this.getUserMetaValueIfExist('top_filter_selected_customer_status');
                let found_item = this.customer_statuss_list.find((x) => x.id === (top_filter_selected_customer_status));
                if (found_item){
                    this.selected_customer_status = found_item;
                    this.$emit('onSelectingTopFilter', found_item, 'customer_status');
                }
            }
            if (this.required_items.includes('user_status')){
                let top_filter_selected_user_status = this.getUserMetaValueIfExist('top_filter_selected_user_status');
                let found_item = this.user_statuss_list.find((x) => x.id === (top_filter_selected_user_status));
                if (found_item){
                    this.selected_user_status = found_item;
                    this.$emit('onSelectingTopFilter', found_item, 'user_status');
                }
            }

            if (this.required_items.includes('created_at')){
                let top_filter_selected_created_at = this.getUserMetaValueIfExist('top_filter_selected_created_at');
                let found_item = this.created_ats_list.find((x) => x.id === (top_filter_selected_created_at));
                if (found_item){
                    this.selected_created_at = found_item;
                    this.$emit('onSelectingTopFilter', found_item, 'created_at');
                }
            }
            if (this.required_items.includes('file_type')){
                let top_filter_selected_file_type = this.getUserMetaValueIfExist('top_filter_selected_file_type');
                let found_item = this.file_types_list.find((x) => x.id === (top_filter_selected_file_type));
                if (found_item){
                    this.selected_file_type = found_item;
                    this.$emit('onSelectingTopFilter', found_item, 'file_type');
                }
            }
            if (this.required_items.includes('category_id')){
                let top_filter_selected_category_id = this.getUserMetaValueIfExist('top_filter_selected_category_id');
                let found_item = this.category_ids_list.find((x) => x.id === (top_filter_selected_category_id));
                if (found_item){
                    this.selected_category_id = found_item;
                    this.$emit('onSelectingTopFilter', found_item, 'category_id');
                }
            }
        },
        async getTopFilterList(required_list='', with_all_title=true){

            if (!this.required_items.includes((required_list === 'status_storage' ? required_list : (required_list+'s')))){ return; }

            if (this.sp_only_lists.includes(required_list)){
                if (!this.is_sp_or_cross_or_admin){
                    return;
                }
            }

            return await new Promise( (resolve, reject) => {
                let listQuery = {
                    required_object: this.object_type
                }

                if (required_list === 'sp_space'){
                    listQuery.current_warehouse_id = this.selected_sp_warehouse.id;
                }
                if (this.is_cross_entity){
                    listQuery.only_current_selected = 1;
                }


                let url = '/api/assetflow/'+required_list+'s';
                if (required_list === 'status_storage'){

                    url = 'api/assetflow/'+this.object_type+'/itemStatus';
                    if (['orders', 'order_lines'].includes(this.object_type)){
                        listQuery = {
                            required_object: this.object_type,
                            object_type: 'item_status',
                        }
                    }else{
                        url = 'api/assetflow/sp_storages/itemStatus';
                        listQuery = {
                            required_object: 'sp_storages',
                            object_type: required_list,
                        }
                    }
                }else if (required_list === 'order'){
                    switch (this.action) {
                        case 'move_to_storage':
                            listQuery.order_type = 0;
                            break;
                        case 'storage_orders':
                            listQuery.order_type = 1;
                            break;
                    }
                }

                if (required_list === 'customer'){
                    listQuery.sort_by = 'name';
                    listQuery.sort_dir = 'asc';
                }

                axios({
                    method:'get',
                    url: url,
                    baseURL: '/',
                    params: listQuery
                }).then(response => {
                    let items_list = response.data.data.items;


                    let top_filter_selected_item = this.getUserMetaValueIfExist('top_filter_selected_'+required_list);
                    if (with_all_title){
                        let all_title = 'All ' + Vue.options.filters.titleize(required_list.replace('sp_', '')) + 's';
                        if (required_list === 'status_storage'){
                            all_title = 'All Statuses'
                            this[required_list+'s_list'] = items_list.map(function (e) {
                                return { id: e.id, name: e.label };
                            });
                        }else{
                            this[required_list+'s_list'] = items_list.map(function (e) {
                                return { id: e.id, name: e.name };
                            });
                        }

                        let all_item = {
                            id: null,
                            name: all_title
                        };
                        this[required_list+'s_list'].splice(0, 0, all_item);
                    }else{
                        if(required_list === 'customer') {
                            top_filter_selected_item = this.current_customer.id;
                            this[required_list + 's_list'] = [this.current_customer];
                        }
                        // other lists?? SP Maybe?

                    }

                    if (top_filter_selected_item !== null){
                        let found_item = this[required_list+'s_list'].find((x) => x.id === (top_filter_selected_item));
                        if (found_item){
                            this['selected_'+required_list] = found_item;
                            this.$emit('onSelectingTopFilter', found_item, required_list);
                        }
                    }

                    resolve(items_list);
                }).catch(error => {
                    reject(new Error('Response Error!'));
                }).finally(() => {
                    this.listLoading = false;
                });
            })
        },

    }
}
</script>
