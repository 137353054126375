<template>
    <div class="ascending-data-display" v-if="required_item_info">
        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Category
                </div>
                <div class="desc-data--right" v-if="required_item_info.hasOwnProperty('parent_category')">
                    {{ required_item_info.parent_category ? required_item_info.parent_category.name : '-' }}
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Height
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.height ? required_item_info.height : '-' }} <span class="used_unit">{{ required_item_info.preferred_unit ? required_item_info.preferred_unit : 'm'}}</span>
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Colours
                </div>
                <div class="desc-data--right">
                    <div
                        v-if="required_item_info.colours_info && required_item_info.colours_info.length > 0"
                        class="st-color-block asset-colours-block">
                        <ul>
                            <li
                                v-for="(colour, key) in required_item_info.colours_info"
                                :key="key"
                            >
                                <div class="st-color-box">
                                    <label>
                                        <div class="cm-color-round"
                                             :style="'background-color:' + colour.hex"
                                             v-b-tooltip.hover.bottom
                                             :title="colour.name | titleize">
                                            <img :src="getAssetBaseUrl + '/images/new_images/color_check-ic.png'" alt="Color Check"/>
                                        </div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-else>-</div>
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Depth
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.depth ? required_item_info.depth : '-' }} <span class="used_unit">{{ required_item_info.preferred_unit ? required_item_info.preferred_unit : 'm'}}</span>
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Composition
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.composition_info && required_item_info.composition_info.length ? required_item_info.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ') : '-' }}
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Width
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.width ? required_item_info.width : '-' }} <span class="used_unit">{{ required_item_info.preferred_unit ? required_item_info.preferred_unit : 'm'}}</span>
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Sustainability
                </div>
                <div class="desc-data--right">
                    {{ completedTasksTotal }}
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Area
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.area ? required_item_info.area : '-' }} <span class="used_unit">m<sup>2</sup></span>
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Waste Stream
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.hasOwnProperty('waste_stream_type') && [0,1].includes(required_item_info.waste_stream_type) ? (required_item_info.waste_stream_type === 0 ? 'Product' : 'Material') : '-' }}
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Volume
                </div>
                <div class="desc-data--right">
                    {{ required_item_info.volume ? required_item_info.volume : '-' }} <span class="used_unit">m<sup>3</sup></span>
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Product Waste Stream
                </div>
                <div class="desc-data--right" >
                    {{ required_item_info.hasOwnProperty('waste_stream_info') && required_item_info.waste_stream_info ? required_item_info.waste_stream_info.name : '-' }}
                </div>
            </div>
        </div>

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    Condition
                </div>
                <div class="desc-data--right">
                    {{ getCondition() }}
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'AfidPropertiesBlock',
    props: {
        completedTasksTotal: {},
        item_info: {}
    },
    created() {
        if (this.item_info.hasOwnProperty('cAFID_info')){
            this.required_item_info = this.item_info.cAFID_info;
        }else{
            this.required_item_info = this.item_info;
        }
    },
    methods:{
        getCondition(){
            if (this.required_item_info && this.required_item_info.condition){
                let conditions_list = {
                    '1': 'New',
                    '2': 'Excellent',
                    '3': 'Good',
                    '4': 'Attention Required'
                };
                return conditions_list[this.required_item_info.condition];
            }
        }
    }
}
</script>
