import {getSpStorageInfoBlock} from "./getSpStorageInfoBlock";
import {getLocationDetails} from "./SpLocationSharedFunctions";
import {getWarehouseSpaceInfoBlock} from "./getWarehouseSpaceInfoBlock";

export const SPStorageShortestKeysList = {
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 storage-name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return getSpStorageInfoBlock(item, false);
        }
    },

    initials: {
        key: 'initials',
        label: "",
        class: "col-lg-1 storage-war_initials",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return getWarehouseSpaceInfoBlock(item, 'current_');
        }
    },
    location: {
        key: 'location',
        label: "Location",
        class: "col-lg-3 war_location",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let parked_at = '';

            switch (item.location_status) {
                case 0:
                    return '<p class="object-title-detail"><span class="item-header homeless-location"><i class="fa fa-circle status-red"></i> Homeless</span></p><p class="object-title-detail"></p>';
                case 1:
                    if (item.hasOwnProperty('location_info') && item.location_info){
                        let location_info = item.location_info;

                        let html = '';
                        if (location_info.hasOwnProperty('space_assigned_at') && location_info.space_assigned_at){
                            let parked_at_date = Vue.options.filters.moment(location_info.space_assigned_at, 'DD/MM/YYYY');
                            parked_at = '<i class="fa fa-circle status-orange"></i> Parked on ' + parked_at_date;
                        }

                        let other_info = {};
                        other_info.warehouse_info = item.current_warehouse_info;
                        other_info.space_info = item.current_space_info;

                        html += getLocationDetails(location_info, true, true, false, other_info);
                        html += '<p class="object-title-detail">' + parked_at+ '</p>';
                        return html;
                    }
                    return '';
                case 2:
                    return '<p class="object-title-detail"><i class="fa fa-circle status-grey"></i>Archived</p>';
            }

        }
    },
};
