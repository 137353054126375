<template>
    <div class="testmonial">
        <div class="testmonial-inner owl-carousel owl-theme">
            <div class="slide-items">
                <div class="testmonial-top">
                    <div class="testmonial-image-name">
                        <div class="testmonial-image"><img :src="camera_url"></div>
                        <div class="testmonial-name">
                            <h3>Asset Flow </h3>
                            <h4>for Film &amp; TV Productions</h4>
                            <div class="testmonial-stars desktop"><img :src="star_url" alt="testmonial stars"></div>
                        </div>
                    </div>
                    <div class="testmonial-stars"><img :src="star_url" alt="testmonial stars"></div>
                </div>
                <div class="testmonial-bottom">
                    <p><img :src="quote_url" alt="quot icon">Please get in touch for any support, feature requests or feedback</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LoginCarousel',
    props:[
        'camera_url',
        'star_url',
        'quote_url',
    ]
}
</script>
