<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                v-if="key !== 'address'"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                :class="required_field.class || 'col-sm-6'"
            >
                <b-form-input
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <span>Address Info</span>
            </div>

            <b-form-group
                label="Search Addresses"
                label-for="selected_addresses"
                class="col-sm-12"
            >
                <b-form-input
                    type="search"
                    id="selected_addresses"
                    name="selected_addresses"
                    placeholder="Search Addresses.."
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                v-for="required_field in required_items.address"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-6"
            >
                <b-form-input
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="'supplier_' + required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-supplier')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const supplierResource = new Resource('api/assetflow/suppliers');
import AddressMeta from "@/mixins/addressActions.mixin";

export default {
    name: "AddNewSupplier",
    props: ['modal_title', 'item_type', 'suppliers_options'],
    mixins:[AddressMeta],
    data(){
        return {
            addForm:{
                name: '',
                initials: '',
                account_no_ref: '',
                contact: '',
                phone: '',
                website: '',
                vat_no_tax_id: '',

                company: '',
                line_1: '',
                line_2: '',
                city: '',
                state: '',
                zip_code: '',
                country: '',
                latitude: '',
                longitude: '',
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Supplier Name',
                    type: 'text'
                },
                initials: {
                    key: 'initials',
                    label: 'Initials',
                    type: 'text'
                },
                account_no_ref: {
                    key: 'account_no_ref',
                    label: 'Account No \\ Reference',
                    type: 'text'
                },
                contact: {
                    key: 'contact',
                    label: 'Contact',
                    type: 'text'
                },
                phone: {
                    key: 'phone',
                    label: 'Phone',
                    type: 'text'
                },
                website: {
                    key: 'website',
                    label: 'Website',
                    type: 'text'
                },
                vat_no_tax_id: {
                    key: 'vat_no_tax_id',
                    label: 'VAT No \\ Tax ID',
                    type: 'text'
                },
                address: {
                    company: {
                        key: 'company',
                        label: 'Company',
                        type: 'text'
                    },
                    line_1: {
                        key: 'line_1',
                        label: 'Line 1',
                        type: 'text'
                    },
                    line_2: {
                        key: 'line_2',
                        label: 'Line 2',
                        type: 'text'
                    },
                    city: {
                        key: 'city',
                        label: 'City',
                        type: 'text'
                    },
                    state: {
                        key: 'state',
                        label: 'State \\ County',
                        type: 'text'
                    },
                    zip_code: {
                        key: 'zip_code',
                        label: 'Zip \\ Post Code',
                        type: 'text'
                    },
                    country: {
                        key: 'country',
                        label: 'Country',
                        type: 'text'
                    },
                    latitude: {
                        key: 'latitude',
                        label: 'Latitude',
                        type: 'text'
                    },
                    longitude: {
                        key: 'longitude',
                        label: 'Longitude',
                        type: 'text'
                    }
                }
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        this.setupAddressFieldsWithLoqate('supplier');
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.initials = this.selected_item.initials;
                this.addForm.account_no_ref = this.selected_item.account_no_ref;
                this.addForm.contact = this.selected_item.contact;
                this.addForm.phone = this.selected_item.phone;
                this.addForm.website = this.selected_item.website;
                this.addForm.vat_no_tax_id = this.selected_item.vat_no_tax_id;

                this.addForm.company = this.selected_item.company;
                this.addForm.line_1 = this.selected_item.line_1;
                this.addForm.line_2 = this.selected_item.line_2;
                this.addForm.city = this.selected_item.city;
                this.addForm.state = this.selected_item.state;
                this.addForm.zip_code = this.selected_item.zip_code;
                this.addForm.country = this.selected_item.country;
                this.addForm.latitude = this.selected_item.latitude;
                this.addForm.longitude = this.selected_item.longitude;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },


        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                initials: this.addForm.initials,
                account_no_ref: this.addForm.account_no_ref,
                contact: this.addForm.contact,
                phone: this.addForm.phone,
                website: this.addForm.website,
                vat_no_tax_id: this.addForm.vat_no_tax_id,
                address_info:
                    {
                        company: this.addForm.company,
                        line_1: this.addForm.line_1,
                        line_2: this.addForm.line_2,
                        city: this.addForm.city,
                        state: this.addForm.state,
                        zip_code: this.addForm.zip_code,
                        country: this.addForm.country,
                        latitude: this.addForm.latitude,
                        longitude: this.addForm.longitude,
                    }
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await supplierResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-supplier');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>


