const FirstPath = window.location.pathname.split('/')[1];
export const SPCustomersKeysList = {
    initials: {
        key: 'initials',
        label: "",
        class: "col-lg-1 initials",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let style = '';
            if (item.hasOwnProperty('meta') && item.meta && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                let bg_colour = item.meta.accent_colour;
                let text_colour = item.meta.primary_colour;
                style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
            }
            return '<a href="/' + FirstPath + '/customers/'+item.id+'/view" class="text-dark">' + '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item[key]+'</span></div>' + '</a>'; //
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg prod_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a href="/' + FirstPath + '/customers/'+item.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item[key]) + '</a>';
        }
    },
    logo: {
        key: 'logo',
        label: "Logo",
        class: "col-lg-2 prod_logo",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<img src="' + item.logo + '/cover/300/100" alt="' + item.name + '" width="120px">';
        }
    },
    sps_count: {
        key: 'sps_count',
        label: "Service Providers",
        class: "col-lg-2 prod_sps_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    containers_count: {
        key: 'containers_count',
        label: "Containers",
        class: "col-lg-2 prod_containers_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets",
        class: "col-lg-1 prod_assets_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    orders_count: {
        key: 'orders_count',
        label: "Orders",
        class: "col-lg-1 prod_orders_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    }
};
