export const SustainabilityActions = [
    {
        id: '0',
        name: 'Not Planned'
    },
    {
        id: '1',
        name: 'Sell'
    },
    {
        id: '2',
        name: 'Store'
    },
    {
        id: '3',
        name: 'Transfer Internally'
    },
    {
        id: '4',
        name: 'Return to Supplier'
    },
    {
        id: '5',
        name: 'Destroy'
    },
    {
        id: '6',
        name: 'Consume \ Use'
    },
    {
        id: '7',
        name: 'Donate'
    },
    {
        id: '8',
        name: 'Recycle'
    },
    {
        id: '9',
        name: 'Gift'
    }
];
