import {getOrderOrOrderLineWithStatus} from "./getOrderOrOrderLineWithStatus";

const FirstPath = window.location.pathname.split('/')[1];
// 0:Open, 1:Confirmed, 2:In Progress, 3:Completed, 4:Cancelled, 5:Awaiting Transport

let status_list = [
    {
        name: 'Open',
        class: 'order-open status-grey',
        bg_class: 'status-background-grey',
    },
    {
        name: 'Confirmed',
        class: 'order-confirmed status-brown',
        bg_class: 'status-background-brown',
    },
    {
        name: 'In Progress',
        class: 'order-in_progress status-orange',
        bg_class: 'status-background-orange',
    },
    {
        name: 'Completed',
        class: 'order-completed status-green',
        bg_class: 'status-background-green',
    },
    {
        name: 'Cancelled',
        class: 'order-cancelled status-red',
        bg_class: 'status-background-red',
    },
    {
        name: 'Awaiting Transport',
        class: 'order-awaiting_transport status-black',
        bg_class: 'status-background-black',
    },
];

export const OrderKeysList = {
    uid: {
        key: 'uid',
        label: "Order ID",
        class: "col-lg-2 ord_uid",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return getOrderOrOrderLineWithStatus(item, 'orders', FirstPath);

        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-2 ord_customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_name = '';
            let customer_reference = '';
            let customer_info = item.customer_info;
            if (customer_info){
                customer_name = '<i class="fa fa-star"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + customer_info.name + '</a></span> ';
                customer_reference = 'Ref: ' + (item.reference ? item.reference : '-');
            }else{
                customer_name = '<i class="fas fa-times status-red"></i> <span class="item-header">Not Assigned</span>';
            }

            let html = '<p class="object-title-list">';
            html += customer_name;
            html += '</p><p class="object-title-detail">'+customer_reference+'</p>';
            return html;
        }
    },
    sp_info: {
        key: 'sp_info',
        label: "Service Provider",
        class: "col-lg-2 ord_sp_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {

            let sp_name = '';
            let sp_reference = '';
            let sp_info = item.sp_info;
            if (sp_info){
                sp_name = '<i class="fa fa-warehouse"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/service_providers/' + sp_info.id + '/view">' + sp_info.name + '</a></span> ';
                sp_reference = 'Ref: ' + (item.reference ? item.reference : '-');
            }else{
                sp_name = '<i class="fas fa-times status-red"></i> <span class="item-header">Not Assigned</span>';
            }

            let html = '<p class="object-title-list">';
            html += sp_name;
            html += '</p><p class="object-title-detail">'+sp_reference+'</p>';
            return html;
        }
    },
    service_info: {
        key: 'service_info',
        label: "Service",
        class: "col-lg-2 ord_service_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let content;
            if (item.hasOwnProperty('service_info') && item.service_info && item.service_info.hasOwnProperty('service_type_info') && item.service_info.service_type_info){
                content = '<p class="object-title-list">' +
                    '<i class="' + item.service_info.service_type_info.icon + '"></i> ' +
                    '<span class="item-header">' +
                    item.service_info.name +
                    '</span>' +
                    '</p>';
            }else{
                content = '<p class="object-title-list"><i class="fas fa-question-circle status-grey"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/orders/' + item.id + '/view">Waiting</a></span></p>';
            }
            content += '<label class="cm_order_label ' + (status_list[item.status].bg_class) + '">' + status_list[item.status].name + '</label>';
            return content;
        }
    },
    order_items: {
        key: 'order_items',
        label: "Items",
        class: "col-lg-1 ord_order_items",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {

            return '<div class="order-items-count">' +
                        '<div class="order-lines-count">' +
                            '<p class="object-title-list text-center">' + (item.hasOwnProperty('order_lines_count') && item.order_lines_count ? item.order_lines_count : 0) + '</p>' +
                            '<p class="object-title-detail text-center">Lines</p>' +
                        '</div>' +
                        '<div class="order-lines-qty">' +
                            '<p class="object-title-list text-center">' + (item.hasOwnProperty('order_lines_quantity') && item.order_lines_quantity ? item.order_lines_quantity : 0) + '</p>' +
                            '<p class="object-title-detail text-center">Items</p>' +
                        '</div>' +
                    '</div>';
        }
    },
    order_progress: {
        key: 'order_progress',
        label: "Progress",
        class: "col-lg-2 ord_order_progress",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let completed_order_lines_count = item.completed_order_lines_count;
            let order_lines_count = item.order_lines_count;

            if (order_lines_count){
                let width = (completed_order_lines_count / order_lines_count) * 100;
                return '<div>' +
                    '<div class="storage-usage-info">' +
                        '<span class="object-title-list order-progress-bar-top">' + completed_order_lines_count + ' of ' + order_lines_count + ' Tasks Completed</span>' +
                    '</div>' +
                    '<div class="progress progress-lg af-accent">' +
                        '<div class="progress-bar af-primary" role="progressbar" aria-label="Usage" style="width: '+width+'%" aria-valuenow="'+completed_order_lines_count+'" aria-valuemin="0" aria-valuemax="'+order_lines_count+'">' +
                        '</div>' +
                    '</div>' +
                    '</div>';
            }
            return '<div class="storage-usage-info"><span class="object-title-list order-progress-bar-top">Empty</span></div>';
        }
    },
    order_activity: {
        key: 'order_activity',
        label: "Activity",
        class: "col-lg-2 ord_order_activity",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {

            let required_date;
            let required_icon;
            let prefix = '';
            let label;


            switch (item.status) {
                case 0:
                    required_date = item.created_at;
                    label = 'Created';
                    required_icon = 'fas fa-plus-circle';
                    break;
                case 1:
                    required_date = item.service_target_date;
                    label = 'Target';
                    required_icon = 'far fa-clock';
                    prefix = 'Due '
                    break;
                case 2:
                    required_date = item.service_target_date;
                    label = 'Target';
                    required_icon = 'far fa-clock';
                    break;
                case 3:
                    required_date = item.completed_date;
                    label = 'Completed';
                    required_icon = 'far fa-check';
                    break;
                case 4:
                    required_date = item.cancelled_date;
                    label = 'Cancelled';
                    required_icon = 'far fa-clock';
                    break;
                case 5:
                    required_date = item.service_target_date;
                    label = 'Target';
                    required_icon = 'far fa-clock';
                    break;
            }


            if (required_date){
                let user_info = item.action_by_user;
                let action_by_text = label + ': ' + Vue.options.filters.moment(required_date, 'DD/MM/YYYY');
                if (user_info){
                    let user_link = '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
                    action_by_text += ' by ' + user_link;
                }

                return '<p class="object-title-list">' +
                    '<i class="'+required_icon+'"></i> ' +
                    '<span class="item-header">' +
                    prefix + Vue.options.filters.fromNow(required_date) +
                    '</span>' +
                    '</p>' +
                    '<p class="object-title-detail">' + action_by_text + '</p>';
            }
            return '-';
        }
    },
};
