<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mb-2">
                            <div class="col-sm-4">
                                <SearchBox placeholder_text="Search Permissions.."></SearchBox>
                            </div>
                            <div class="col-sm-8">
                                <div class="text-sm-right">
                                    <a href="./permissions/new" class="btn btn-success btn-rounded mb-2 mr-2">
                                        <i class="mdi mdi-plus mr-1"></i> Add New Permission
                                    </a>
                                </div>
                            </div>
                            <!-- end col-->
                        </div>

                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap table-hover">
                                <thead class="thead-light">
                                <tr>
                                    <th scope="col" style="width: 70px;">#</th>
                                    <th scope="col">Key</th>
                                    <th scope="col">Related Item Name</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="list in permissionGridData" :key="list.id">
                                    <td>
                                        <div v-if="list.id" class="avatar-xs">
                                            <span class="avatar-title rounded-circle">{{list.id}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 class="font-size-14 mb-1">
                                            <a href="#" class="text-dark">{{list.key}}</a>
                                        </h5>
                                    </td>
                                    <td>{{list.item_name}}</td>

                                    <td>
                                        <a :href="(item_type !== '' ? '/' + item_type : '') + '/permissions/'+list.id + '/edit'" v-b-tooltip.hover title="Edit" class="btn btn-primary btn-sm">
                                            Edit
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="pagination pagination-rounded justify-content-center mt-4">
                                    <li class="page-item disabled">
                                        <a href="#" class="page-link">
                                            <i class="mdi mdi-chevron-left"></i>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">1</a>
                                    </li>
                                    <li class="page-item active">
                                        <a href="#" class="page-link">2</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">3</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">4</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">5</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">
                                            <i class="mdi mdi-chevron-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

    import Resource from '@/api/resource';
    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import SearchBox from "@/components/search-box";

    const permissionResource = new Resource('api/assetflow/permissions');

    export default {
        props: ['item_id', 'item_type'],
        name: 'PermissionsList',
        components: { Layout, PageHeader, SearchBox },
        data() {
            return {
                total: 0,
                listLoading: true,
                permissionGridData: null,
                title: "Permissions List",
                items: [
                    {
                        text: "Permissions",
                        href: "/"
                    },
                    {
                        text: "Permissions List",
                        active: true
                    }
                ],
                listQuery: {
                    page: 1,
                    limit: 20
                },
            };
        },
        created() {
            this.getList();
        },
        methods: {
            async getList(selected_item=null) {
                this.listLoading = true;
                this.handleQueryItems();
                this.listQuery.role_id = 1;
                const { data } = await permissionResource.list(this.listQuery);
                this.permissionGridData = data.data.items;
                this.total = data.meta.total;
                this.listLoading = false;
            },
            handleQueryItems() {
                if (this.item_id !== '' && this.item_type === 'sp') {
                    this.listQuery.warehouse_id = this.item_id;
                }
                if (this.item_id !== '' && this.item_type === 'customer') {
                    this.listQuery.customer_id = this.item_id;
                }
            }
        },

    }
</script>



