const FirstPath = window.location.pathname.split('/')[1];
export const SharedMetaKeys = {
    name:{
        label: 'Name',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    status:{
        label: 'Status',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    images_count:{
        label: 'No. of images',
        cast_value: function (item, key) {
            return item[key];
        }
    },
};
