<template>
    <div class="step_main_accordion_border" :class="'section-' + (title === 'Quantity & Costs' ? 'cost' : title.replace(' ', '_').toLowerCase())">
        <div @click="handleShowingBlock" class="step-small-label">
            <p>{{ title | titleize }} <sup v-if="is_afid_field">AFID</sup></p>
            <div v-if="!$parent.$parent.useTooltips" class="section-close-btn">
                <i class="bx font-size-24"
                   :class="toggle ? 'bx-x' : 'bx-plus'"></i>
            </div>
        </div>

        <div class="st_accordion">
            <transition name="fade" mode="out-in">
                <slot v-if="toggle"/>
                <div v-else-if="selected_fields">
                    <div v-for="selected_field in selected_fields">
                        <p v-if="addForm.hasOwnProperty('single_select_v_models') && addForm.single_select_v_models[selected_field]">
                            {{ (selected_field === 'supplier_id' ? 'Supplier' : selected_field) | titleize }}
                            <b>
                                {{ (selected_field === 'po_id' ? addForm.single_select_v_models[selected_field].reference : addForm.single_select_v_models[selected_field].name) }}
                            </b>
                        </p>

                        <p v-else-if="addForm.hasOwnProperty('item_details') && addForm.item_details.hasOwnProperty(selected_field)">
                            {{ required_fields[selected_field].name | titleize }}
                            <b v-if="required_fields[selected_field].type === 'checkbox'">
                                {{ addForm.item_details[selected_field] === 1 ? 'Yes' : 'No' }}
                            </b>
                            <b v-else-if="selected_field === 'currency'">
                                {{ addForm.item_details[selected_field].icon  + '-' + addForm.item_details[selected_field].text }}
                            </b>
                            <b v-else-if="required_fields[selected_field].type === 'multi-select'">
                                <span class="mr-1 value-for-multi-select" v-for="(single_value, key) in addForm.item_details[selected_field]">{{ single_value.name + ((key === addForm.item_details[selected_field].length - 1) ? '' : ',') }}</span>
                            </b>
                            <b v-else-if="selected_field === 'po_id'">
                                {{ addForm.item_details[selected_field].reference }}
                            </b>
                            <b v-else-if="addForm.item_details[selected_field] && (addForm.item_details[selected_field].hasOwnProperty('name') || addForm.item_details[selected_field].hasOwnProperty('description'))">
                                {{ addForm.item_details[selected_field].name || addForm.item_details[selected_field].description }}
                            </b>
                            <b v-else>{{ addForm.item_details[selected_field] }}</b>
                        </p>
                    </div>
                </div>
                <small v-else>{{ selected_value }}</small>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props:['title', 'selected_value', 'selected_fields', 'addForm', 'is_afid_field', 'required_fields', 'received_toggle'],
    name: "AssetInfoSection",
    data(){
        return {
            toggle: true
        }
    },
    created() {
        if (this.received_toggle === true){
            this.toggle = false;
        }
    },
    methods: {
        handleShowingBlock(){
            if (!this.$parent.$parent.useTooltips){
                this.toggle = !this.toggle;
            }
        }
    }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
    transition: opacity .2s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

</style>
