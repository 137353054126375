<template>
    <div class="dropdown-menu dropdown-menu-right" v-if="performAction === null">
        <b-dropdown-group id="dropdown-group-1" header="View Asset" v-if="hasPermission('browse_assets')">
            <b-dropdown-item :href="'/' + item_type + '/assets/' + item.id + '/view?t=asset_overview'" target="_blank">View</b-dropdown-item>
        </b-dropdown-group>
    </div>
    <div class="dropdown-menu dropdown-menu-right" v-else-if="!checkIfAssetIsLocked(item)">
        <b-dropdown-group id="dropdown-group-2" header="Edit Asset" v-if="hasPermission('edit_assets')">
            <b-dropdown-item @click="performAction(item, 'updateDetails', $event)">Update Details
            </b-dropdown-item>

            <b-dropdown-item @click="performAction(item, 'generateQRCode', $event)">View QR Code
            </b-dropdown-item>

            <b-dropdown-item v-if="user_has_many_departments"
                             @click="performAction(item, 'changeDepartment', $event)">Change Department
            </b-dropdown-item>

            <b-dropdown-item v-if="hasPermission('edit_assets') && item.asset_group_id === null"
                             @click="performAction(item, 'changeGroup', $event)">Add To Group
            </b-dropdown-item>

            <b-dropdown-item v-if="hasPermission('browse_suppliers')"
                             @click="performAction(item, 'changeSupplier', $event)">{{ item.hasOwnProperty('supplier_id') && item.supplier_id ? 'Change' : 'Add' }} Supplier
            </b-dropdown-item>

            <b-dropdown-item @click="performAction(item, 'updateUsage', $event)">Usage
            </b-dropdown-item>
            <b-dropdown-item v-if="!is_closet_asset"
                             @click="performAction(item, 'updateCosts', $event)">Costs
            </b-dropdown-item>
            <b-dropdown-item @click="performAction(item, 'updateImages', $event)">Images
            </b-dropdown-item>
            <b-dropdown-item @click="performAction(item, 'add_ms_files', $event)">
                Files
            </b-dropdown-item>
            <b-dropdown-item @click="performAction(item, 'add_ms_link', $event)">
                URLs
            </b-dropdown-item>
            <b-dropdown-item @click="performAction(item, 'editAsset', $event)">Physical
            </b-dropdown-item>
        </b-dropdown-group>


        <b-dropdown-group id="dropdown-group-3" header="Actions" v-if="hasPermission('edit_assets')">
            <b-dropdown-item v-if="hasPermission('edit_assets') && item.quantity > 1 && !is_closet_asset"
                             @click="performAction(item, 'splitAsset', $event)">Split
            </b-dropdown-item>

            <b-dropdown-item v-if="hasPermission('add_assets')"
                             @click="performAction(item, 'duplicateAsset', $event)">Duplicate
            </b-dropdown-item>

            <b-dropdown-item v-if="hasPermission('delete_assets') && !is_closet_asset"
                             variant="danger"
                             @click="performAction(item, 'deleteAsset', $event)">Delete
            </b-dropdown-item>
        </b-dropdown-group>
    </div>

    <div class="dropdown-menu dropdown-menu-right" v-else>

        <b-dropdown-item v-if="hasPermission('edit_assets') && item.asset_group_id !== null"
                         @click="performAction(item, 'resetGroup', $event)">Remove From Group
        </b-dropdown-item>

        <b-dropdown-group id="dropdown-group-4" header="Actions" v-if="item.quantity > 1 && !is_closet_asset">
            <b-dropdown-item
                v-if="hasPermission('edit_assets')"
                v-b-tooltip.left
                :title="'This Asset can\'t be split, Asset is Locked'"
                disabled
            >Split
            </b-dropdown-item>

            <b-dropdown-item
                v-if="hasPermission('delete_assets')"
                v-b-tooltip.left
                :title="'This Asset can\'t be deleted, Asset is Locked'"
                disabled
            >Delete
            </b-dropdown-item>
        </b-dropdown-group>
    </div>
</template>
<script>
export default {
    name: 'AssetsActionsList',
    props: {
        item: {},
        item_type: {},
        performAction: {},
        is_closet_asset: {}
    }
}
</script>
