<template>
    <div>
        <asset-passport-tab :item_info="item_info" />
    </div>
</template>
<script>
import AssetPassportTab from "./widgets/detailsViews/AssetTabs/AssetPassportTab";
export default {
    name: 'CustomAssetPassportDetails',
    components: {AssetPassportTab},
    props: ['item_info']
}
</script>
