export const LocalesList = [
    {
        value: 'uk',
        text: "UK"
    },
    {
        value: 'us',
        text: "US"
    }
];
