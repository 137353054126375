<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <div class="st--common--box" v-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            rows="20"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      :disabled="isAddingItem"
                      v-on:click="addItem($event)">Save</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const set_pc_descriptionResource = new Resource('api/assetflow/changes/setPCDescription');

export default {
    mixins: [],
    name: "SetChangeSceneDescription",
    props: ['modal_title', 'item_type', 'received_item_id'],
    components: {},
    data(){
        return {
            addForm:{
                description: '',
            },
            required_items:{
                description: {
                    key: 'description',
                    label: 'Scene Notes',
                    type: 'textarea'
                },
            },
            isAddingItem: false,
            selected_item: null,
            item_id: null,
            item_index: null,
            change_index: null,
            list_disabled: null
        }
    },
    created() {
        if (this.received_item_id !== null){
            this.item_id = this.received_item_id;
        }
    },
    watch:{
        received_item_id(value) {
            this.item_id = this.received_item_id;
        },
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.description = this.selected_item.description;
                if (this.selected_item.hasOwnProperty('item_id') && this.selected_item.item_id){
                    this.item_id = this.selected_item.item_id;
                }
                if (this.selected_item.hasOwnProperty('item_index')){
                    this.item_index = this.selected_item.item_index;
                }
                if (this.selected_item.hasOwnProperty('change_index')){
                    this.change_index = this.selected_item.change_index;
                }
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                description: this.addForm.description,
                item_id: this.item_id
            };

            const { data } = await set_pc_descriptionResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Updated Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            if (this.item_index !== null && this.change_index !== null){
                this.$emit('on-new-item-adding-in-list', this.item_index, this.change_index, params.description);
            }else{
                this.$emit('on-new-item-adding', new_item, this.item_type);
            }
        }
    }
}
</script>


