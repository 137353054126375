<template>
    <div class="row">
        <div class="col-md-6">
            <div class="cm_usage folders_list">
                <span v-iconize="'folders'">Folder</span>
                <b-breadcrumb v-if="item_info && item_info.hasOwnProperty('folders_info') && item_info.folders_info">
                    <b-breadcrumb-item
                        v-for="(folder_info, index) in item_info.folders_info"
                        class="breadcrumb-folder-item"
                        :key="index"
                        :class="index === item_info.folders_info.length-1 ? 'active-item' : ''"
                        :href="'/' + item_type + '/folders/?s=' + folder_info.name">
                        {{ folder_info.name }}
                    </b-breadcrumb-item>
                </b-breadcrumb>
                <p v-else><span>-</span></p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="cm_usage categories_list">
                <span v-iconize="'categories'">Category</span>
                <b-breadcrumb
                    v-if="item_info && item_info.hasOwnProperty('categories_info') && item_info.categories_info">
                    <b-breadcrumb-item
                        v-for="(category_info, index) in item_info.categories_info"
                        :key="index"
                        class="breadcrumb-category-item"
                        :class="index === item_info.categories_info.length-1 ? 'active-item' : ''"
                        :href="'/' + item_type + '/managed_storages/?queue=category&id=' + category_info.id">
                        {{ category_info.name }}
                    </b-breadcrumb-item>
                </b-breadcrumb>
                <p v-else><span>-</span></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagedStorageParents',
    props: {
        item_info: {},
        item_type: {}
    }
}
</script>
