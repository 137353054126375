export default {
    created: function () {
    },
    methods: {
        async getCustomersSpsList(item_info) {
            return new Promise( (resolve, reject) => {
                axios({
                    method: 'get',
                    url: '/api/assetflow/'+item_info.item_type+'/owners_list?item_id=' + item_info.id,
                    baseURL: '/',
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            });
        }
    }
}
