const FirstPath = window.location.pathname.split('/')[1];
export const DispositionKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 a_uid",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/dispositions/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-1 e_created_at",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    reference: {
        key: 'reference',
        label: "Ref",
        class: "col-lg-1 d_reference",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (reference) {
            return reference;
        }
    },
    name: {
        key: 'name',
        label: "Action",
        class: "col-lg d_action",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a href="/' + FirstPath + '/dispositions/'+item.id+'/view">' + Vue.options.filters.titleize(item.name) + '</a>';
        }
    },
    recipient: {
        key: 'recipient',
        label: "Recipient",
        class: "col-lg-1 d_recipient",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('recipient_type') && item.recipient_type !== ''){
                return '<a href="/' + FirstPath + '/' + item.recipient_type + '/'+item.recipient_id+'/view">' + Vue.options.filters.titleize(item.recipient_name) + '</a>';
            }
            return '-';
        }
    },
    type: {
        key: 'type',
        label: "Type",
        class: "col-lg-1 d_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('assets_info') && item.assets_info){
                return item.assets_info.length === 1 ? 'Unique' : 'Shared';
            }
            return '-';
        },
    },
    assets: {
        key: 'assets',
        label: "Assets",
        class: "col-lg-1 d_assets",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('assets_info') && item.assets_info){
                return item.assets_info.length;
            }
            return '0';
        },
    },
    quantity: {
        key: 'quantity',
        label: "Qty",
        class: "col-lg-1 d_quantity",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('assets_info') && item.assets_info){
                return item.assets_info.reduce((a, b) => +a + +b.quantity, 0);
            }
            return '0';
        },
    },
    total_value: {
        key: 'total_value',
        label: "Total",
        class: "col-lg-1 d_total_value",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: function () {},
        value_func: function () {},
    },

};
