<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <multiselect
                    v-if="['single-select', 'multi-select'].includes(required_field.type)"
                    :multiple="required_field.type === 'multi-select'"
                    v-model="addForm[required_field.key]"
                    :placeholder="'Select ' + required_field.label"
                    class="mySingleSelect myCustomSelect"
                    track-by="id"
                    label="name"
                    :options="required_field.options"
                    :allow-empty="true"
                    deselect-label=""
                >
                </multiselect>
                <b-form-input v-else
                              v-model="addForm[required_field.key]"
                              type="text"
                              :id="required_field.key"
                              :placeholder="required_field.label"
                              autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-dashboard_template')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const moduleResource = new Resource('api/assetflow/modules');

import multiSelect from '@/mixins/MultiSelect';
import {DashboardTemplates} from "@/views/assetflow/form_data/DashboardTemplates";

export default {
    name: "SetModuleDashboardTemplate",
    props: ['modal_title', 'item_type'],
    mixins:[multiSelect],
    data(){
        return {
            addForm:{
                dashboard_template_id: null,
            },
            required_fields:{
                dashboard_template_id: {
                    key: 'dashboard_template_id',
                    label: 'Dashboard Template',
                    type: 'single-select',
                    options:DashboardTemplates
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {

    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
            this.addForm.dashboard_template_id = null;
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        fillSelectedItem() {
            if (this.selected_item) {
                if (this.selected_item.hasOwnProperty('dashboard_template_id')){
                    this.$set(this.addForm, 'dashboard_template_id', this.getObjectOf(this.selected_item.dashboard_template_id, this.required_fields.dashboard_template_id.options));
                }
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                dashboard_template_id: this.addForm.dashboard_template_id ? this.addForm.dashboard_template_id.id : 0,
                action: 'set_dashboard_template'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await moduleResource.store(params);

            let new_item = data.data.items;
            let saved = data.data.status;
            this.presentToast((saved ? "Success!" : "Error!"),
                saved ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : (data.data.items),
                (saved ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-dashboard_template');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


