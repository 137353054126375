<template>
    <div class="row">
        <p>Locations that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

        <div class="col-lg-12">
            <div class="row mb-2">
                <div class="col-sm-4">
                    <SearchBox placeholder_text="Search Locations.."></SearchBox>
                </div>
                <div class="col-sm-8">
                    <div class="text-sm-right">

                    </div>
                </div>
                <!-- end col-->
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-centered table-nowrap table-hover">
                <thead class="thead-light">
                <tr>
                    <th scope="col" style="width: 70px;">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(list, locationKey) in address_locationsData" :key="list.id">
                    <td>
                        <div v-if="list.id" class="avatar-xs">
                            <span class="avatar-title rounded-circle af-accent">{{list.id}}</span>
                        </div>
                    </td>
                    <td>
                        <h5 class="font-size-14 mb-1">
                            <span class="text-dark">{{list.name}}</span>
                        </h5>
                    </td>
                    <td>
                        <button v-if="permissions_list.delete_locations" :href="'/' + item_info.current_page_type + '/assets?filter=location&id=' + list.id" v-b-tooltip.hover title="Assets List"  class="btn af-btn-accent af-accent btn-sm">
                            Show Assets
                        </button>

                        <span v-if="permissions_list.delete_locations && list.assets_count === 0" >
                            <button @click="deleteLocation(list, locationKey, $event)" class="btn btn-outline-danger btn-sm">Delete</button>
                        </span>

                        <span v-else-if="permissions_list.delete_locations" v-b-tooltip.hover :title="'Only empty folders can be deleted. {Assets: ' + list.assets_count + '}'">
                            <button class="btn btn-outline-danger btn-sm disabled" >Delete</button>
                            <button @click="unassignAssets(list, locationKey, $event)" class="btn btn-outline-info btn-sm">Un-Assign Assets</button>
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import SearchBox from "@/components/search-box";
import Globals from "@/mixins/global.mixin";
import AssetActions from "@/mixins/assetActions.mixin";

export default {
    name: "ListLocations",
    mixins: [Globals, AssetActions],
    components: {SearchBox},
    props: {
        item_info: Object,
        locations: Array
    },
    data() {
        return {
            base_url: "",
            item_id: null,
            item_name: "",
            item_type: "",
            permissions_list: {}
        }
    },
    created() {
        this.item_id = this.item_info.id;
        this.item_name = this.item_info.name;
        this.item_type = this.item_info.item_type;
        this.base_url = this.item_info.base_url;

        let currentUserPermissions =  this.item_info.currentUserPermissions;
        this.permissions_list = {
            delete_locations: this.hasPermission('delete_addresses', currentUserPermissions),
            browse_assets: this.hasPermission('browse_assets', currentUserPermissions)
        };
        this.address_locationsData = this.locations;
    },
    methods: {
        refreshList(item_id){
            if (this.delete_after_untag){
                let index = this.address_locationsData.map(function(e) { return e.id; }).indexOf(item_id);
                this.address_locationsData.splice(index, 1);
            }
            location.reload();
        },
        unassignAssets(item, key, event){
            this.header_message = 'Un-Assign Assets of ';
            this.confirm_message = 'Are you sure you want to Un-Assign Assets of ';
            this.item_name = 'Location';
            this.untagAssets(item,'address_locations');
        },
        deleteLocation(item, key, event){
            if (item.id !== ''){
                this.deleteItemByID(item.id, item.name, 'address_locations', event)
                .then((response) => {
                    if (response.data.data.status){
                        this.address_locationsData.splice(key, 1);
                    }else{
                        console.log("response.data");
                        console.log(response.data);
                    }

                });
            }
        }
    }
}
</script>


