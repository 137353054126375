
export const CategoryKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-2 t_uid",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-4 t_name",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (value){
            return Vue.options.filters.titleize(value);
        }
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets",
        class: "col-lg-2 t_assets_count",
        show_on_mobile: false,
        case: ''
    },
    assets_total_quantity: {
        key: 'assets_total_quantity',
        label: "Qty",
        class: "col-lg-1 t_assets_total_quantity",
        show_on_mobile: false,
        case: ''
    },
    assets_available_quantity: {
        key: 'assets_available_quantity',
        label: "Qty",
        class: "col-lg-1 t_assets_available_quantity",
        show_on_mobile: false,
        case: ''
    },
    assets_reserved_quantity: {
        key: 'assets_reserved_quantity',
        label: "Qty",
        class: "col-lg-1 t_assets_reserved_quantity",
        show_on_mobile: false,
        case: ''
    },
};
