<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div v-if="this.item_type === 'customer' && !this.user_info.has_current_customer">
            <b-alert show variant="danger">
                There's nothing to show, Please "
                <a href="/customer/switch">
                    Select Customer
                </a>
                " to proceed
            </b-alert>
        </div>

        <div v-else-if="this.item_type === 'sp' && !this.user_info.has_current_sp">
            <b-alert show variant="danger">
                There's nothing to show, Please "
                <a href="/sp/switch">
                    Select Service Provider
                </a>
                " to proceed
            </b-alert>
        </div>

        <div v-else class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Basic Information</h4>
                        <p class="card-title-desc">Fill all information below</p>

                        <form>
                            <div class="row">
                                <div class="col-sm-6">

                                    <div
                                        v-for="(required_field, index) in required_fields"
                                        :key="index"
                                        class="form-group">
                                        <label v-if="required_field.has_permission && required_field.type !== 'toggle'" :for="'_'+required_field.name">{{ required_field.name | titleize }}</label>

                                        <textarea
                                            v-model="addForm.item_details[required_field.name]"
                                            v-if="required_field.type === 'longtext'"
                                            :id="'_'+required_field.name"
                                            :name="'_'+required_field.name"
                                            class="form-control"
                                            rows="3"
                                        ></textarea>

                                        <b-form-checkbox
                                            v-else-if="required_field.type === 'toggle'"
                                            v-model="addForm.item_details[required_field.name]"
                                            :id="'_'+required_field.name"
                                            :name="'_'+required_field.name"
                                            class="custom-checkbox mb-3"
                                        >{{ required_field.msg | titleize }}
                                        </b-form-checkbox>

                                        <input
                                            v-else-if="required_field.type === 'text' || required_field.type === 'number'"
                                            v-model="addForm.item_details[required_field.name]"
                                            :id="'_'+required_field.name"
                                            :name="'_'+required_field.name"
                                            :type="required_field.type"
                                            class="form-control" />


                                        <multiselect
                                            v-else-if="required_field.type === 'single-select'"
                                            v-model="addForm.single_select_v_models[required_field.name]"
                                            track-by="id"
                                            label="name"
                                            @select="onSelect(required_field.name, false, $event)"
                                            class="mySingleSelect"
                                            :options="required_field.options"></multiselect>

                                        <multiselect
                                            v-else-if="required_field.type === 'multi-select'"
                                            v-model="addForm.multi_select_v_models[required_field.name]"
                                            track-by="id"
                                            label="name"
                                            @select="onSelect(required_field.name, true, $event)"
                                            class="myMultiSelect"
                                            :options="required_field.options"
                                            :multiple="true"
                                        ></multiselect>
                                        <span v-else>
                                        </span>

                                    </div>


                                    <div v-if="this.shall_select_customers" class="form-group">
                                        <label class="control-label">Customer</label>
                                        <multiselect v-model="customer_value" :custom-label="customerWithWarehouse" label="name" track-by="id" placeholder="Type to search" open-direction="bottom" :options="customers" :multiple="false" :searchable="true" :loading="isLoadingCustomers" :internal-search="true">
                                            <span slot="noResult">No data found.</span>
                                        </multiselect>
                                    </div>

                                </div>
                                <div class="col-sm-6">

                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="dropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-if="addForm.asset_images.length == 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <h3 class="dropzone-custom-title">Drop files here or click to upload</h3>
                                            <div class="subtitle">...or click to select a file from your computer</div>
                                        </div>
                                        <div v-else ></div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone.$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New Image</b-button>
                                    <b-button
                                        @click="resetDropZone"
                                        variant="outline-danger"
                                        class="btn btn-sm mt-1 btn-block"
                                    >Remove All</b-button>

                                </div>
                            </div>

                            <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                            <a href="./" class="btn btn-secondary">Cancel</a>
                        </form>
                    </div>
                </div>

                <div v-if="this.can_see_sp_section" class="card">
                    <div class="card-body">
                        <h4 class="card-title">Service Provider Section <small>(Only SP Admins can see this)</small></h4>

                        <p class="card-title-desc">Additional actions can be set here by SP Admins.</p>

                        <form>
                            <div class="row">
                                <div class="col-sm-6">

                                    <div class="form-group">
                                        <b-form-checkbox
                                            v-model="addForm.assign_to_sp"
                                            unchecked-value="0"
                                            class="custom-checkbox mb-3"
                                        >Assign Asset to current Service Provider</b-form-checkbox>
                                    </div>

                                </div>

                            </div>

                            <button type="submit" class="btn btn-primary mr-1">Save</button>
                            <a href="./" class="btn btn-secondary">Cancel</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>



<script>


    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from "vue2-dropzone";

    import Resource from '@/api/resource';
    const assetResource = new Resource('api/assetflow/assets');


    export default {
        props:['item_id', 'item_type', 'action'],
        name: 'AdminNewAsset',
        components: {
            Layout,
            PageHeader,
            Multiselect,
            vueDropzone: vue2Dropzone
        },
        data() {
            return {
                asset_usageList:[
                    {
                        id: 0,
                        name: 'Off Camera'
                    },
                    {
                        id: 1,
                        name: 'On Camera'
                    }
                ],
                conditionsList:[
                    { id: 4, name: 4 },
                    { id: 3, name: 3 },
                    { id: 2, name: 2 },
                    { id: 1, name: 1 },
                ],
                dropzoneOptions: {
                    url: "required_url",
                    acceptedFiles: "image/*",
                    thumbnailWidth: 160,
                    thumbnailHeight: 160,
                    maxFilesize: 10, // 10MB
                    manuallyAddFile: true,
                    autoProcessQueue: false,
                    maxFiles: 10,
                    previewTemplate: this.template()
                },
                lists_values: null,
                form_lists: {
                    colours: null,
                    status: null,
                    composition: null,
                    product_id: null,
                    folder_id: null,
                    category_id: null,
                    location_id: null,
                    contact_id: null,
                    tag_ids: null,
                    condition: null,
                    asset_usage: null
                },
                required_fields: {
                    name: {
                        name: 'name',
                        type: 'text',
                        has_permission: true,
                        default: ''
                    },
                    description: {
                        name: 'description',
                        type: 'longtext',
                        has_permission: true,
                        default: ''
                    },
                    is_unique: {
                        name: 'is_unique',
                        type: 'toggle',
                        has_permission: true,
                        default: '',
                        msg: 'Is Unique?'
                    },
                    quantity: {
                        name: 'quantity',
                        type: 'number',
                        has_permission: true,
                        default: ''
                    },
                    status: {
                        name: 'status',
                        type: 'single-select',
                        has_permission: true,
                        default: 'planned',
                        options: []
                    },
                    internal_reference: {
                        name: 'internal_reference',
                        type: 'text',
                        has_permission: true,
                        default: ''
                    },
                    condition: {
                        name: 'condition',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    width: {
                        name: 'width',
                        type: 'number',
                        has_permission: true,
                        default: ''
                    },
                    height: {
                        name: 'height',
                        type: 'number',
                        has_permission: true,
                        default: ''
                    },
                    depth: {
                        name: 'depth',
                        type: 'number',
                        has_permission: true,
                        default: ''
                    },
                    volume: {
                        name: 'volume',
                        type: 'number',
                        has_permission: true,
                        default: ''
                    },
                    weight: {
                        name: 'weight',
                        type: 'number',
                        has_permission: true,
                        default: ''
                    },
                    colours: {
                        name: 'colours',
                        type: 'multi-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    composition: {
                        name: 'composition',
                        type: 'multi-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },

                    product_id: {
                        name: 'product_id',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    folder_id: {
                        name: 'folder_id',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    category_id: {
                        name: 'category_id',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    location_id: {
                        name: 'location_id',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    contact_id: {
                        name: 'contact_id',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    asset_usage: {
                        name: 'asset_usage',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    tag_ids: {
                        name: 'tag_ids',
                        type: 'multi-select',
                        has_permission: true,
                        default: '',
                        options: []
                    },
                    bss_status: {
                        name: 'bss_status',
                        type: 'toggle',
                        has_permission: false,
                        default: '',
                        msg: 'Add To BSS Status?'
                    },
                    is_hero: {
                        name: 'is_hero',
                        type: 'toggle',
                        has_permission: true,
                        default: '',
                        msg: 'Is Hero?'
                    },
                    is_asset: {
                        name: 'is_asset',
                        type: 'toggle',
                        has_permission: true,
                        default: '',
                        msg: 'Is Asset?'
                    }
                },
                title: "Add New Asset",
                items: [
                    {
                        text: "Assets",
                        href: "/"
                    },
                    {
                        text: "Add Asset",
                        active: true
                    }
                ],
                customer_value: null,
                customers: [],
                isLoadingCustomers: false,
                asset_images_previewElements: {},
                addForm:{
                    assign_to_sp: false,
                    item_details: {},
                    asset_images: [],
                    single_select_v_models: {
                        product_id: null,
                        folder_id: null,
                        category_id: null,
                        status: null,
                        location_id: null,
                        contact_id: null,
                        user_id: null,
                        warehouse_id: null,
                        condition: null,
                        asset_usage: null,
                    },
                    multi_select_v_models: {
                        colours: null,
                        composition: null,
                        tag_ids: null,
                    }
                },
                can_add_to_bss: false,
                can_see_sp_section: false,
                isLoadingItem: false,
                shall_select_customers: false,
                user_info: {
                    has_current_customer: false,
                    has_current_sp: false
                }
            };
        },
        mounted() {


        },
        created() {

            this.form_lists = this.$root.form_lists;

            this.lists_values = {
                colours: this.form_lists.colours_list,
                status: this.form_lists.status_list,
                composition: this.form_lists.compositions_list,
                product_id: this.form_lists.products_list,
                folder_id: this.form_lists.folders_list,
                category_id: this.form_lists.categories_list,
                location_id: this.form_lists.locations_list,
                contact_id: this.form_lists.contacts_list,
                tag_ids: this.form_lists.tags_list,
                asset_usage: this.asset_usageList,
                condition: this.conditionsList
            }

            for (let key in this.lists_values){
                this.required_fields[key].options = this.lists_values[key] ? this.lists_values[key] : [];
            }

            this.user_info = window.USER_INFO ?? this.user_info;
            this.shall_select_customers = this.item_type !== 'customer';
            if(this.action === 'admin_edit' && this.item_id !== ''){
                this.title = this.title.replace('Add New', 'Edit');
                this.items[1].text = this.items[1].text.replace('Add', 'Edit');
            }

            let default_addresses = this.lists_values.location_id.filter(function(e) { return e.parent_address.is_default === 1; });
            let primary_locations;

            if (default_addresses.length !== 0){
                primary_locations = default_addresses.filter(function(e) { return e.is_primary === 1; })
                if (primary_locations.length === 0){
                    primary_locations = default_addresses;
                }
            }else{
                primary_locations = this.lists_values.location_id.filter(function(e) { return e.is_primary === 1; })
            }

            var selected_location_index = 0;
            if (primary_locations.length !== 0){
                let first_primary_location = primary_locations[0];
                selected_location_index = this.lists_values.location_id.map(function(e) { return e.id; }).indexOf(first_primary_location.id);
            }

            this.addForm.single_select_v_models.location_id = this.lists_values.location_id[selected_location_index];

            this.getPagePermissions();
            if (this.shall_select_customers){
                this.asyncFindCustomers();
            }else{
                if(this.action === 'admin_edit' && this.item_id !== ''){
                    this.fetchInfoOf();
                }
            }
        },
        methods: {
            onSelect(value_name, is_multi=false, value){

                // console.log("value_name");
                // console.log(value_name);
                //
                // if (is_multi){
                //     this.addForm.multi_select_v_models[value_name] = value;
                //     console.log("multi_select_v_models");
                //     console.log(multi_select_v_models);
                // }else{
                //     this.addForm.single_select_v_models[value_name] = value;
                //     console.log("this.addForm.single_select_v_models");
                //     console.log(this.addForm.single_select_v_models);
                //
                // }
            },
            is_featuredClicked(item){
                console.log(item);
            },
            template: function () {
                return `<div class="dz-preview dz-file-preview col-3">
                            <div class="dz-image">
                                <div data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                                <div class="dz-size">
                                    <label><input type="radio" data-dz-is_featured name="is_featured" /> Is Featured?</label>
                                </div>
                                <div class="dz-size">
                                    <label><input type="checkbox" data-dz-add_to_ap name="add_to_ap[]" /> Add to Asset Passport?</label>
                                </div>
                            </div>
                            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        </div>`;
            },
            thumbnail: function(file, dataUrl) {
                var j, len, ref, thumbnailElement, is_featuredRef, is_featuredElement, add_to_apRef, add_to_apElement;

                file.previewElement.classList.remove("dz-file-preview");

                if (file.manuallyAdded && file.id){
                    this.asset_images_previewElements[file.id] = file.previewElement;
                }

                ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");

                for (j = 0, len = ref.length; j < len; j++) {
                    thumbnailElement = ref[j];
                    thumbnailElement.alt = file.name;
                    thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
                }

                is_featuredRef = file.previewElement.querySelectorAll("[data-dz-is_featured]");
                if (is_featuredRef){
                    is_featuredElement = is_featuredRef[0];
                    is_featuredElement.value = file.upload ? file.upload.uuid : file.id;
                    is_featuredElement.checked = file.is_featured === 1;
                }
                add_to_apRef = file.previewElement.querySelectorAll("[data-dz-add_to_ap]");
                if (add_to_apRef){
                    add_to_apElement = add_to_apRef[0];
                    add_to_apElement.value = file.upload ? file.upload.uuid : file.id;
                    add_to_apElement.checked = file.add_to_ap === 1;
                }

                return setTimeout(((function(_this) {
                    return function() {
                        return file.previewElement.classList.add("dz-image-preview");
                    };
                })(this)), 1);
            },
            fileAdded(file) {
                let file_id = file.id;
                let index = this.addForm.asset_images.map(function(e) { return e.id; }).indexOf(file_id);
                let foundFile = this.addForm.asset_images[index];
                if (foundFile && file.manuallyAdded){
                    this.addForm.asset_images[index] = file;
                }else{
                    this.addForm.asset_images.push(file);
                    index = this.addForm.asset_images.length - 1;
                }
                this.saveFileToS3(file, index, 'asset_images');

            },
            maxFilesExceeded(file){
                // todo: should show error message?
            },
            resetDropZone(){
                this.$refs.myVueDropzone.removeAllFiles();
                this.addForm.asset_images = []
            },
            getPagePermissions(){
                this.required_fields['bss_status'].has_permission = true;
                this.can_add_to_bss = true;
                this.can_see_sp_section = true;
            },
            fetchInfoOf() {
                if (this.item_id){
                    this.isLoadingItem = true;
                    axios({
                        method:'get',
                        url:'/api/assetflow/assets/' + this.item_id,
                        baseURL: '/',
                    }).then(response => {
                        var asset_info = response.data;
                        this.addForm.item_details = {
                            name: asset_info.name,
                            description: asset_info.description,
                            created_by: asset_info.created_by,
                            created_method: asset_info.created_method,
                            internal_reference: asset_info.internal_reference,
                            // product_id: asset_info.product_id,
                            // folder_id: asset_info.folder_id,
                            // category_id: asset_info.category_id,
                            is_unique: asset_info.is_unique,
                            quantity: asset_info.quantity,
                            // status: asset_info.status,
                            // modified_planned_date: asset_info.modified_planned_date,
                            // modified_acquired_date: asset_info.modified_acquired_date,
                            // modified_deployed_date: asset_info.modified_deployed_date,
                            // modified_last_serviced_date: asset_info.modified_last_serviced_date,
                            // modified_in_storage_date: asset_info.modified_in_storage_date,
                            // modified_listed_date: asset_info.modified_listed_date,
                            // modified_disposition_date: asset_info.modified_disposition_date,
                            // location_id: asset_info.location_id,
                            // contact_id: asset_info.contact_id,
                            import_file_id: asset_info.import_file_id,
                            bss_status: asset_info.bss_status,
                            // user_id: asset_info.user_id,
                            // warehouse_id: asset_info.warehouse_id,
                            // condition: asset_info.condition,
                            width: asset_info.width,
                            height: asset_info.height,
                            depth: asset_info.depth,
                            volume: asset_info.volume,
                            weight: asset_info.weight,
                            // colours: asset_info.colours,
                            // composition: asset_info.composition,
                            // tag_ids: asset_info.tag_ids,
                        };


                        for (const value_name in this.addForm.single_select_v_models) {
                            this.addForm.single_select_v_models[value_name] = this.getValueOf(value_name, asset_info[value_name]);
                        }

                        for (const value_name in this.addForm.multi_select_v_models) {
                            this.addForm.multi_select_v_models[value_name] = this.getValueOf(value_name, asset_info[value_name], true);
                        }

                        if (asset_info.asset_images){
                            this.addForm.asset_images = [... asset_info.asset_images];

                            for (const assetImagesKey in this.addForm.asset_images) {
                                let asset_image = this.addForm.asset_images[assetImagesKey];
                                var file = { id: asset_image.id, size: asset_image.image_name_size, name: asset_image.name, type: "image/png", is_featured: asset_image.is_featured, add_to_ap: asset_image.add_to_ap };
                                var url = asset_image.image_name;

                                if (asset_image.image_name_size){
                                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                                }
                            }
                        }

                        if (this.shall_select_customers){
                            var index = this.customers.map(function(e) { return e.id; }).indexOf(asset_info.customer_id);
                            this.customer_value = this.customers[index];
                        }
                        this.isLoadingItem = false;

                    }).catch(error => {
                        console.log(error);
                        this.isLoadingItem = false;
                    });
                }
            },
            getObjectOf(value_id, required_list){
                let index = required_list.map(function(e) { return e.id+""; }).indexOf(value_id+"");
                return required_list[index] ? required_list[index] : null;
            },
            getValueOf(value_name, value_id, is_multi=false){
                var required_list = this.lists_values[value_name];

                if (required_list){
                    if (is_multi){
                        var foundItems = [];
                        for (const v_id in value_id) {
                            let foundItem = this.getObjectOf(value_id[v_id], required_list);
                            if (foundItem){
                                foundItems.push(foundItem);
                            }
                        }
                        return foundItems;
                    }else{
                        let foundItem = this.getObjectOf(value_id, required_list);

                        if (foundItem){
                            return foundItem;
                        }
                    }
                }
                return null;
            },
            asyncFindCustomers () {
                console.log(this.item_type);

                this.isLoadingCustomers = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/customers?item_type=' + this.item_type,
                    baseURL: '/',
                }).then(response => {
                    this.customers = response.data.data.items;
                    this.isLoadingCustomers = false;
                    this.fetchInfoOf();

                }).catch(error => {
                    console.log(error);
                    this.isLoadingCustomers = false;
                });
            },
            customerWithWarehouse (customer) {
                return `${customer.name}`
            },
            getIDs(required_array){
                return required_array.map(item => {
                    return item.id;
                });
            },
            checkAndGetFiles(){
                var j, len, file;
                var allfiles = []
                for (j = 0, len = this.addForm.asset_images.length; j < len; j++) {
                    file = this.addForm.asset_images[j];

                    var previewElement;
                    if (this.asset_images_previewElements[file.id]){
                        previewElement = this.asset_images_previewElements[file.id];
                    }else{
                        previewElement = file.previewElement;
                    }

                    if (previewElement){
                        var is_featuredRef = previewElement.querySelectorAll("[data-dz-is_featured]");
                        if (is_featuredRef){
                            var is_featuredElement = is_featuredRef[0];
                            file.is_featured = is_featuredElement.checked
                        }

                        var add_to_apRef = previewElement.querySelectorAll("[data-dz-add_to_ap]");
                        if (add_to_apRef){
                            var add_to_apElement = add_to_apRef[0];
                            file.add_to_ap = add_to_apElement.checked
                        }
                    }

                    allfiles.push(file);
                }
                return allfiles;
            },
            addNew(event) {
                var allfiles = this.checkAndGetFiles(this.addForm.asset_images);

                if (event && !this.isSendingRequest && allfiles) {
                    this.isSendingRequest = true;
                    var data = {
                        asset_id: this.item_id,
                        item_details: this.addForm.item_details,
                        item_type: this.item_type,
                        asset_images: allfiles,
                        customer_id: this.customer_value ? this.customer_value.id : ""
                    };


                    for (const value_name in this.addForm.single_select_v_models) {
                        if (this.addForm.single_select_v_models[value_name]){
                            data.item_details[value_name] = this.addForm.single_select_v_models[value_name].id;
                        }
                    }

                    for (const value_name in this.addForm.multi_select_v_models) {
                        if (this.addForm.multi_select_v_models[value_name]){
                            data.item_details[value_name] = this.getIDs(this.addForm.multi_select_v_models[value_name]);
                        }
                    }

                    axios({
                        method:((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                        url:'/api/assetflow/assets' + (this.item_id !== '' ? ('/'+this.item_id) : ''),
                        baseURL: '/',
                        data: data,
                    }).then(response => {
                        this.isSendingRequest = false;
                        // console.log(response);
                        if (response.data.data.status === true){
                            window.location.href = './view';
                        }else{
                            console.log('Something went wrong');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });

                }
            }
        },

    }
</script>

<style>

#imagesgallery {
    font-family: sans-serif;
    cursor: pointer;
    transition: background-color .2s linear;
    min-height: 300px !important;
    border: 2px dashed #ced4da !important;
    background: #fff !important;
    border-radius: 6px !important;
}

#imagesgallery .dropzone-custom-content {
    font-size: 24px;
    color: #ced4da;
    margin: 3em 0 !important;
    text-align: center;
}

#imagesgallery .dz-preview {
    display: inline-block
}
#imagesgallery .dz-preview .dz-image {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-top: 20px;
}
#imagesgallery .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}
#imagesgallery .dz-preview .dz-image > img {
    width: 100%;
}

#imagesgallery .dz-preview .dz-details {
    color: inherit;
    transition: opacity .2s linear;
    text-align: center;
}
#imagesgallery .dz-success-mark, .dz-error-mark {
    display: none;
}
</style>
