<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="is_saving_enabled = true"
             hide-footer>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        Location Type Setup
                    </div>
                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_items"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_location_typeResource = new Resource('api/assetflow/sp_location_types');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import ChangeCommonFields from "../extras/ChangeCommonFields";
import AFButton from "@/components/AFButton";

export default {
    mixins: [multiSelect],
    name: "AddNewSpLocation",
    props: ['modal_title', 'item_type', 'sp_location_types_options'],
    components: {AFButton, ChangeCommonFields, DatePicker},
    data(){
        return {
            addForm:{
                location_type: null,
                label_1d: '',
                label_2d: '',
                label_3d: '',
                label_4d: '',
            },
            required_items:{
                location_type: {
                    key: 'location_type',
                    label: 'Location Type',
                    type: 'single-select',
                    options:[
                        {
                            id: 0,
                            name: '1D'
                        },
                        {
                            id: 1,
                            name: '2D'
                        },
                        {
                            id: 2,
                            name: '3D'
                        },
                        {
                            id: 3,
                            name: '4D'
                        }
                    ]
                },
                label_1d: {
                    key: 'label_1d',
                    label: '1D Label',
                    type: 'hidden_text'
                },
                label_2d: {
                    key: 'label_2d',
                    label: '2D Label',
                    type: 'hidden_text'
                },
                label_3d: {
                    key: 'label_3d',
                    label: '3D Label',
                    type: 'hidden_text'
                },
                label_4d: {
                    key: 'label_4d',
                    label: '4D Label',
                    type: 'hidden_text'
                },
            },
            selected_item: null
        }
    },
    created() {
    },

    computed:{
        location_type() {
            return this.addForm.location_type;
        },
    },
    watch:{
        location_type(value) {
            this.handleFieldsAppearance();
        },
    },
    methods:{
        handleFieldsAppearance(){
            if (!this.addForm.location_type){ return; }

            let location_type_style = this.addForm.location_type.id
            this.required_items.label_1d.type = 'text';

            if ([1,2,3].includes(location_type_style)){
                this.required_items.label_2d.type = 'text';
            }else{
                this.required_items.label_2d.type = 'hidden_text';
            }

            if ([2,3].includes(location_type_style)){
                this.required_items.label_3d.type = 'text';
            }else{
                this.required_items.label_3d.type = 'hidden_text';
            }

            if (location_type_style === 3){
                this.required_items.label_4d.type = 'text';
            }else{
                this.required_items.label_4d.type = 'hidden_text';
            }
        },
        resetSelectedItem(){
            this.required_items.label_1d.type = 'hidden_text';
            this.required_items.label_2d.type = 'hidden_text';
            this.required_items.label_3d.type = 'hidden_text';
            this.required_items.label_4d.type = 'hidden_text';
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.location_type = this.getObjectOf(this.selected_item.location_type, this.required_items.location_type.options);
                this.addForm.label_1d = this.selected_item.label_1d;
                this.addForm.label_2d = this.selected_item.label_2d;
                this.addForm.label_3d = this.selected_item.label_3d;
                this.addForm.label_4d = this.selected_item.label_4d;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            var params = {
                location_type: this.addForm.location_type ? this.addForm.location_type.id : 0,
                label_1d: this.addForm.label_1d,
                label_2d: this.addForm.label_2d,
                label_3d: this.addForm.label_3d,
                label_4d: this.addForm.label_4d
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_location_typeResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>


