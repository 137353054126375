<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <multiselect
                    v-if="['single-select', 'multi-select'].includes(required_field.type)"
                    :multiple="required_field.type === 'multi-select'"
                    v-model="addForm[required_field.key]"
                    :placeholder="'Select ' + required_field.label"
                    class="mySingleSelect myCustomSelect"
                    :options="required_field.options"
                    :allow-empty="true"
                    deselect-label=""
                >
                </multiselect>
                <b-form-input v-else
                              v-model="addForm[required_field.key]"
                              type="text"
                              :id="required_field.key"
                              :placeholder="required_field.label"
                              autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-searchable_items')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const moduleResource = new Resource('api/assetflow/modules');

import multiSelect from '@/mixins/MultiSelect';

export default {
    name: "AddNewSearchableItems",
    props: ['modal_title', 'item_type'],
    mixins:[multiSelect],
    data(){
        return {
            addForm:{
                searchable_items: null,
            },
            required_fields:{
                searchable_items: {
                    key: 'searchable_items',
                    label: 'Searchable Objects',
                    type: 'multi-select',
                    options:[
                        'assets', 'folders', 'acquisitions', 'ledgers', 'users', 'roles'
                    ]
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {

    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
            this.addForm.searchable_items = null;
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        fillSelectedItem() {
            if (this.selected_item) {
                let entity_type = this.selected_item.entity_type;
                if (entity_type === 'customer'){
                    this.required_fields.searchable_items.options = ['assets', 'folders', 'managed_storages', 'tags', 'acquisitions', 'ledgers', 'users', 'roles', 'orders'];
                }else if (entity_type === 'sp'){
                    this.required_fields.searchable_items.options = ['assets', 'folders', 'managed_storages', 'tags', 'customers', 'sp_storages', 'sp_locations', 'users', 'roles', 'orders'];
                }else{
                    this.required_fields.searchable_items.options = ['assets', 'folders', 'managed_storages', 'tags', 'acquisitions', 'ledgers', 'customers', 'sp_storages', 'sp_locations', 'users', 'roles', 'orders'];
                }

                if (this.selected_item.hasOwnProperty('searchable_items')){
                    this.$set(this.addForm, 'searchable_items', this.selected_item.searchable_items);
                }
            }
        },


        async addItem(event){
            this.isAddingItem = true;

            var params = {
                searchable_items: this.addForm.searchable_items,
                action: 'set_searchable_items'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await moduleResource.store(params);

            let new_item = data.data.items;
            let saved = data.data.status;
            this.presentToast((saved ? "Success!" : "Error!"),
                saved ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : (data.data.items),
                (saved ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-searchable_items');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


