<template>
    <div class="step-form-content-block row">
        Continuity Info.
    </div>
</template>

<script>
export default {
    name: "ContinuityInfo",
    props: ['addForm']
}
</script>


