<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <template #modal-title>
            <div v-html="current_modal_title"></div>
        </template>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFields from "../extras/ChangeCommonFields";

export default {
    name: "AssignStorageCustomer",
    props: ['modal_id', 'modal_title', 'item_type', 'customers_list'],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                customer_id: null,
                start_date: ''
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Assign Customer',
                    required_items:{
                        customer_id: {
                            key: 'customer_id',
                            label: 'Customer',
                            type: 'single-select',
                            options:[]
                        },
                        start_date: {
                            key: 'start_date',
                            label: 'Start Date',
                            type: 'datetime'
                        }
                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
    },
    computed:{
        current_modal_title(){
            return this.selected_item && this.selected_item.hasOwnProperty('name') ? ( (this.selected_item.hasOwnProperty('storage_wrapper_info') ? ('<i class="' + this.selected_item.storage_wrapper_info.icon +'"></i> ') : '')+this.selected_item.name + '') : this.modal_title;
        }
    },
    methods:{
        setCustomersList(customers_list=null){
            if (this.required_sections.storage_setup.required_items.customer_id.options.length !== 0){
                return;
            }
            if (customers_list){
                customers_list = customers_list.filter(customer => customer.id !== null);
                this.required_sections.storage_setup.required_items.customer_id.options = customers_list;
            }else{
                // shall get customers list >
                this.getCustomersList();
            }
        },
        async getCustomersList() {
            if (this.required_sections.storage_setup.required_items.customer_id.options.length !== 0){
                return;
            }
            this.required_sections.storage_setup.required_items.customer_id.listLoading = true;
            let listQuery = {
                item_type: this.item_type,
                item_id: this.item_id
            }
            const customerResource = new Resource('api/assetflow/customers');
            const { data } = await customerResource.list(listQuery);

            this.required_sections.storage_setup.required_items.customer_id.options = data.data.items;

            this.required_sections.storage_setup.required_items.customer_id.listLoading = false;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.customer_id = this.getObjectOf(this.selected_item.customer_id, this.required_sections.storage_setup.required_items.customer_id.options);
                let start_date = this.selected_item.start_date ? this.selected_item.start_date : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');
                this.$set(this.addForm, 'start_date', start_date);
            }
        },
        setSelectedItem(item){
            this.$set(this.addForm, 'start_date', Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm'));
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                start_date: this.addForm.start_date,
                action: 'assign_customer'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-assign-to-customer', this.modal_id, new_item);
        }
    }
}
</script>


