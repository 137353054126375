<template>
    <div>

        <div v-if="po.po_items">
            <div class="row mt-3">
                <div class="col-lg-12">
                    <po-lines
                        object_type="purchaseOrders"
                        :current_page_type="po.current_page_type"
                        header="Purchase Order Items"
                        :items="po.po_items"
                        :keys_list="po_items_info">
                    </po-lines>
                </div>
            </div>

            <div class="row" v-if="po.detailed_totals.length !== 0">
                <div class="col-12">
                    <po-lines
                        object_type="purchaseOrders"
                        :current_page_type="po.current_page_type"
                        header="Detailed Totals"
                        :items="po.detailed_totals"
                        :keys_list="detailed_totals_keys_list">
                    </po-lines>

                    <div class="total_block text-right">
                        <div class="total_quantity_block">
                            <span class="total_quantity_text">Total Quantity</span>
                            <span class="total_quantity_value">{{ po.totals_info.quantity_sum }}</span>
                        </div>
                        <div class="total_details">
                            <span class="total_text">Total in</span>
                            <span class="total_currency">{{ '{'+po.totals_info.currency.toUpperCase()+'}' }}</span>
                            <span class="total_total" v-b-tooltip:hover :title="toUserTotalWithCurrency(po.totals_info)">{{ getCurrencyByValue(po.totals_info.currency).icon + po.totals_info.customers_total_value }}</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import PoLines from "./PoLines";
export default {
    name: "PurchaseOrderDetails",
    components: {PoLines},
    mixins: [TotalsAndCurrencies],
    props: {
        po: {
            type: Object
        },
    },
    data() {
        return {
            total_footer:{},
            has_po_actions_permissions: false,
            po_items_info: {
                line: {
                    key: 'line',
                    label: "Line",
                    class: "col-lg-1 po-line-no",
                    show_on_mobile: true,
                    case: 'use_index',
                    with_index_func: function (index, item, key){
                        return index + 1;
                    }
                },
                reference: {
                    key: 'reference',
                    show_on_mobile: true,
                    label: 'Ref No.',
                    class: 'col-lg-2 poi_reference',
                    case: ''
                },
                description: {
                    key: 'description',
                    show_on_mobile: true,
                    label: 'Description',
                    class: 'col-lg poi_description',
                    case: ''
                },
                currency: {
                    key: 'currency',
                    show_on_mobile: false,
                    label: 'Currency',
                    class: 'col-lg-2 poi_currency text-center',
                    case: 'custom',
                    used_func: function (value){
                        return value.toUpperCase();
                    }
                },
                quantity: {
                    key: 'quantity',
                    show_on_mobile: false,
                    label: 'Quantity',
                    class: 'col-lg-2 poi_quantity text-center',
                    case: ''
                },
                value: {
                    key: 'value',
                    show_on_mobile: false,
                    label: 'Per Unit',
                    class: 'col-lg-2 poi_value text-center',
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                total_value: {
                    key: 'total_value',
                    show_on_mobile: false,
                    label: 'Total Value',
                    class: 'col-lg-2 poi_total_value text-right',
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                }
            },
            detailed_totals_keys_list: {
                currency_sum: {
                    key: 'currency_sum',
                    show_on_mobile: true,
                    label: 'Currency',
                    class: 'dt_currency',
                    case: 'use_index',
                    with_index_func: function (index, item, key){
                        return index.toUpperCase();
                    }
                },
                quantity_sum: {
                    key: 'quantity_sum',
                    show_on_mobile: true,
                    label: 'Quantity',
                    class: 'dt_quantity',
                    case: ''
                },
                total_value_sum: {
                    key: 'total_value_sum',
                    show_on_mobile: true,
                    label: 'Total',
                    class: 'dt_total_value',
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitleUsingIndex,
                    value_func: this.getCurrencyByValueTextUsingIndex,
                }
            },
            permissions_list:[]
        };
    },
    created() {

        let currentUserPermissions = this.po.currentUserPermissions;
        this.permissions_list = {
            edit_purchaseOrders: this.hasPermission('edit_purchaseOrders', currentUserPermissions),
            delete_purchaseOrders: this.hasPermission('delete_purchaseOrders', currentUserPermissions)
        };

        // let totals_info = this.po.totals_info;
        // if (totals_info){
            // this.po.detailed_totals['total'] = {
            //     currency: totals_info.currency,
            //     quantity_sum: totals_info.quantity_sum,
            //     total_value_sum: totals_info.customers_total_value
            // }

        // }

        //
        //
        // this.total_footer = {
        //     currency_sum
        //     quantity_sum
        //     total_value_sum
        // }

    },
    methods: {

    }
}
</script>


