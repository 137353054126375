<template>
    <div class="AcquisitionAllBox">
        <div class="common_col_bg_box">
            <div class="row">

                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>Acquisition ID</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="item_info" :href="'/' + item_type + '/acquisitions/' + item_info.id + '/view'">{{ uid_info }}</a>
                        </p>
                    </div>
                </div>

                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>Acquisition Date</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="acquired_date && acquired_date !== ''">{{ acquired_date | moment('MMMM Do YYYY') }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>Buyer</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="buyer_info" :href="'/' + item_type + '/users/' + buyer_info.id + '/view'">{{ buyer_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>Acquisition Type</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="acquisition_type_info">{{ acquisition_type_info.name }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>P-Card</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="buyer_ledger_info" :href="'/' + item_type + '/ledgers/' + buyer_ledger_info.id + '/view'">{{ buyer_ledger_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>P-Card Desc</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="buyer_ledger_info" :href="'/' + item_type + '/ledgers/' + buyer_ledger_info.id + '/view'">{{ buyer_ledger_info.description }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div :class="item_columns">
                    <div class="cm_usage" :item_class="item_class">
                        <span>Payment Account</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="payment_ledger_info" :href="'/' + item_type + '/ledgers/' + payment_ledger_info.id + '/view'">{{ payment_ledger_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'AcquisitionAllBox',
    props: {
        my_asset: {},
        item_type: {},
        item_class: {},
        item_columns: { default: 'col-md-4'},
        performAction: {}
    },
    computed:{
        item_info(){
            return this.getValueOrDefault(this.my_asset, 'acquisition_info', null);
        },
        uid_info(){
            return this.getValueOrDefault(this.item_info, 'uid', null);
        },
        buyer_info(){
            return this.getValueOrDefault(this.item_info, 'buyer_info', null);
        },
        acquired_date(){
            return this.getValueOrDefault(this.item_info, 'acquired_date', null);
        },
        character_info(){
            return this.getValueOrDefault(this.item_info, 'character_info', null);
        },
        buyer_ledger_info(){
            return this.getValueOrDefault(this.item_info, 'buyer_ledger_info', null);
        },
        payment_ledger_info(){
            return this.getValueOrDefault(this.item_info, 'payment_ledger_info', null);
        },
        supplier_name(){
            return this.getValueOrDefault(this.item_info, 'supplier_name', null);
        },
        acquisition_type_info(){
            return this.getValueOrDefault(this.item_info, 'acquisition_type_info', null);
        },
        purchase_orders_info(){
            return this.getValueOrDefault(this.item_info, 'purchase_orders_info', null);
        },
        no_of_assets(){
            return this.getValueOrDefault(this.item_info, 'no_of_assets', null);
        },
        no_of_payments(){
            return this.getValueOrDefault(this.item_info, 'no_of_payments', null);
        },
        required_payment(){
            return this.getValueOrDefault(this.item_info, 'required_payment', null);
        },
        item_info_currency(){
            let currency = this.item_info && this.item_info.currency !== '' ? this.item_info.currency : 'gbp';
            return this.getCurrencyByValue(currency);
        },
    },
    methods:{
    }
}
</script>
