<script>
    import MetisMenu from "metismenujs/dist/metismenujs";

    // import { menuItems } from "./menu";

    import Resource from '@/api/resource';
    const menuResource = new Resource('api/ajax/get_menu');

    export default {
        data() {
            return {
                menuItems: null,
            };
        },
        created() {
            this.getMenu();
        },
        methods: {
            refreshMenu(){
                this.menuItems = null;
                this.getMenu();
            },
            /**
             * Returns true or false if given menu item has child or not
             * @param item menuItem
             */
            hasItems(item) {
                return item.subItems !== undefined ? item.subItems.length > 0 : false;
            },
            async listItems(){
                var links = await document.getElementsByClassName("side-nav-link-ref");
                var matchingMenuItem = null;
                const paths = [];
                const paths_with_search = [];
                // console.log("here?");
                // console.log(links);
                // console.log(links[0]);
                // console.log(links[0]["pathname"]);
                // console.log(window.location.pathname);

                for (var i = 0; i < links.length; i++) {
                    let my_link = links[i];
                    paths.push(my_link["pathname"]);
                    paths_with_search.push(my_link["pathname"] + my_link["search"]);
                }
                // console.log("paths_with_search");
                // console.log(paths_with_search);

                let first_path = window.location.pathname + window.location.search;
                // console.log("first_path");
                // console.log(first_path);

                var itemIndex = paths_with_search.indexOf(first_path);
                // console.log("itemIndex");
                // console.log(itemIndex);

                if (itemIndex === -1) {
                    itemIndex = paths.indexOf(window.location.pathname);
                }

                if (itemIndex === -1) {
                    const strIndex = window.location.pathname.lastIndexOf("/");
                    const item = window.location.pathname.substr(0, strIndex).toString();
                    matchingMenuItem = links[paths.indexOf(item)];
                } else {
                    matchingMenuItem = links[itemIndex];
                }

                // console.log(matchingMenuItem)

                if (matchingMenuItem) {
                    matchingMenuItem.classList.add("active");
                    var parent = matchingMenuItem.parentElement;

                    /**
                     * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
                     * We should come up with non hard coded approach
                     */
                    if (parent) {
                        parent.classList.add("mm-active");
                        const parent2 = parent.parentElement.closest("ul");
                        if (parent2 && parent2.id !== "side-menu") {
                            parent2.classList.add("mm-show");

                            const parent3 = parent2.parentElement;
                            if (parent3) {
                                parent3.classList.add("mm-active");
                                var childAnchor = parent3.querySelector(".has-arrow");
                                var childDropdown = parent3.querySelector(".has-dropdown");
                                if (childAnchor) childAnchor.classList.add("mm-active");
                                if (childDropdown) childDropdown.classList.add("mm-active");

                                const parent4 = parent3.parentElement;
                                if (parent4 && parent4.id !== "side-menu") {
                                    parent4.classList.add("mm-show");
                                    const parent5 = parent4.parentElement;
                                    if (parent5 && parent5.id !== "side-menu") {
                                        parent5.classList.add("mm-active");
                                        const childanchor = parent5.querySelector(".is-parent");
                                        if (childanchor && parent5.id !== "side-menu") {
                                            childanchor.classList.add("mm-active");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                var menuRef = new MetisMenu("#side-menu", {
                    toggle: false,
                    triggerElement: 'a',
                    parentTrigger: 'li',
                    subMenu: 'ul'
                });
            },
            async getMenu() {
                const { data } = await menuResource.list();
                this.menuItems = data.data.items;
                await this.listItems();
            }
        },
    };
</script>

<template>
    <!-- ========== Left Sidebar Start ========== -->

    <!--- Sidemenu -->
    <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul v-if="menuItems" id="side-menu" class="metismenu list-unstyled">
            <template v-for="item in menuItems">
                <li class="menu-link-title" v-if="item.isTitle && item.link !== ''" :key="item.id">
                    <a :href="item.link">{{ $t(item.label) }}</a>
                </li>
                <li class="menu-title" v-else-if="item.isTitle" :key="item.id">{{ $t(item.label) }}</li>
                <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
                    <a
                        v-if="hasItems(item)"
                        href="javascript:void(0);"
                        class="is-parent"
                        :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
                    >
                        <i :class="`nav-item-icon ${item.icon}`" v-if="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <span
                            :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                            v-if="item.badge"
                        >{{ $t(item.badge.text)}}</span>
                    </a>

                    <a :href="item.link" v-if="!hasItems(item)" :class="(item.is_url ? 'side-nav-link-custom-url' : 'side-nav-link-ref') + (item.is_condensed ? ' side-nav-condensed' : '')">
                        <i :class="`nav-item-icon ${item.icon}`" v-if="item.icon"></i>
                        <span>{{ $t(item.label) }}</span>
                        <span
                            :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                            v-if="item.badge"
                        >{{$t(item.badge.text)}}</span>
                    </a>

                    <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                        <li v-for="(subitem, index) of item.subItems" :key="index">
                            <a
                                :href="subitem.link"
                                v-if="!hasItems(subitem)"
                                :class="(subitem.is_url ? 'side-nav-link-custom-url' : 'side-nav-link-ref') + (subitem.is_condensed ? ' side-nav-condensed' : '')"
                            >{{ $t(subitem.label) }}</a>
                            <a
                                v-if="hasItems(subitem)"
                                class="side-nav-link-a-ref has-arrow"
                                href="javascript:void(0);"
                            >{{ $t(subitem.label) }}</a>
                            <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                                    <a :href="subSubitem.link" :class="(subSubitem.is_url ? 'side-nav-link-custom-url' : 'side-nav-link-ref') + (subSubitem.is_condensed ? ' side-nav-condensed' : '')">{{ $t(subSubitem.label) }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
        <ul v-else id="loading-side-menu" class="metismenu list-unstyled">
            <li class="menu-title text-center">
                <b-spinner class="text-af-accent m-2"></b-spinner>
                <h4 class="text-white">Loading...</h4>
            </li>
        </ul>
    </div>
    <!-- Sidebar -->
</template>
