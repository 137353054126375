<template>
    <Layout>
<!--         -->
        <add-new-order
            ref="add-new-order"
            object_type='order'
            :item_type='item_type'
            :received_order_type="received_order_type"
            modal_title="Create New Order"
            @on-cancel-clicked="$bvModal.hide('create-order')"
            @on-new-item-adding="getList(selected_item)"
        >
        </add-new-order>



        <SetOrderService
            ref="set_order_service"
            @after-saving="$bvModal.hide('set_order_service');getList(selected_item)"
            @on-cancel-clicked="$bvModal.hide('set_order_service')"
        />

        <SetOrderSubscribers
            ref="set_order_subscribers"
            :item_type="item_type"
            :received_order_type="received_order_type"
            modal_title="Manage Order Subscribers"
            @on-cancel-clicked="$bvModal.hide('set_order_subscribers')"
            @after-saving-is-done="$bvModal.hide('set_order_subscribers');getList(selected_item)"
        />


        <div class="orders-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                :received_filter_classes="filter_classes"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <TopFiltersBlockAdvanced
                            ref="TopFiltersBlock"
                            object_type="orders"
                            :action="action"
                            :current_title="(specific_object_name_single ? specific_object_name_single : 'Order') + ' List'"
                            block_class="customers-warehouses-block  all-orders-filters"
                            :required_items.sync="required_items"
                            :resetFilter="resetFilter"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Orders.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+object_type)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('order')">Order
                                </b-dropdown-item>

                                <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="exportObjects(10)">
                                    Export (CSV)
                                </b-dropdown-item>
                                <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="exportObjects(11)">
                                    Export (XLSX)
                                </b-dropdown-item>
                                <b-dropdown-item v-if="hasPermission('browse_files')" href="javascript:void(0);" @click="exportObjects(12)">
                                    Export with Images (XLSX)
                                </b-dropdown-item>

                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="current-page-filters">
                        <span v-if="is_sp_or_cross_or_admin && selected_customer && selected_customer.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Customer: <b>{{ selected_customer.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_customer')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_status_storage && selected_status_storage.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Status: <b>{{ selected_status_storage.name || selected_status_storage.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_status_storage')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_order_service && selected_order_service.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Service: <b>{{ selected_order_service.name || selected_order_service.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_order_service')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_created_at && selected_created_at.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Created: <b>{{ selected_created_at.name || selected_created_at.label }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_created_at')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="![1, 4].includes(current_groupBy) && selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.label) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>

                        <span v-if="selected_dashboard_item && selected_dashboard_item.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ selected_dashboard_item.label }}: <b>{{ (selected_dashboard_item.name) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('dashboard_item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>


                    <div class="tab_menu_item">
                        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li v-for="tab in listTabs" class="nav-item">
                                <a class="nav-link"
                                   @click="tabClicked(tab.id)"
                                   :class="isActiveTab(tab.id)"
                                   data-toggle="tab" :href="'#'+tab.id" role="tab">
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                                    <span class="d-none d-sm-inline-block">
                                {{ tab.name | titleize}}
                            </span>
                                </a>
                            </li>
                        </ul>
                    </div>


                    <div class="tab-content tab_content_design">
                        <div class="sidebad-toggle af-accent"></div>
                        <div class="tab-pane" id="list_dashboard" role="tabpanel" :class="isActiveTab('list_dashboard')">
                            <ListItemsDashboardTab :dashboard_info="dashboard_info" @on-dashboard-clicked="dashboardItemClicked" item_name="Managed Storage"/>
                        </div>

                        <div class="tab-pane" id="list_items" role="tabpanel" :class="isActiveTab('list_items')">

                            <div class="product-filter-option-wrapper clearfix d-flex">

                                <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                        request_meta.from + '-' + request_meta.to
                                    }}</span> of <span>{{ request_meta.total }}</span></div>

                                <div class="filter-wrap d-flex align-items-center ml-auto">
                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Sort: <b>{{ sorting.sortType.text }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in sorting.sortOptions"
                                            :key="option.value"
                                            @click="saveSorting(option, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Show: <b>{{
                                                perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                            }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in perpage.pageOptions"
                                            :key="option.value"
                                            @click="savePerPage(option.value, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                        <div
                                            v-for="(layout, key) in supportedLayouts"
                                            :key="key"
                                            @click="changeObjectLayout(layout);"
                                            :id="'grid-list-'+layout.name.toLowerCase()"
                                            :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <!-- Product Section Start-->
                            <LotsOfItemsAlert :perpage="perpage"/>
                            <div v-if="listLoading" class="products text-center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                                <h4>Fetching {{ specific_object_name }}...</h4>
                            </div>
                            <div v-else-if="!ordersData || total === 0 || sortedItemsList.length === 0"
                                 class="products text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>No {{ specific_object_name }} found here at the moment.</h4>
                            </div>
                            <div v-else-if="ordersData" class="row">

                                <div class="col-lg-12">
                                    <BulkActionsBar
                                        :is_actions_bar_opened="is_actions_bar_opened"
                                        :is_selecting_items="is_selecting_items"
                                        :shouldToggleSelecting="shouldToggleSelecting"
                                        :selected_objects="selected_objects"
                                        :continueClicked="continueClicked"
                                        :performSelectionAction="performSelectionAction"
                                        :should_hide_unselected="should_hide_unselected"
                                        :enableBulkActions="enableBulkActions"
                                        :all_items_count="total"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                    />
                                </div>

                                <div v-if="selectedLayout === 0 && sortedItemsList" class="col-lg-12">
                                    <po-lines
                                        :items="sortedItemsList"
                                        :current_page_type="item_type"
                                        :object_type="object_type"
                                        :keys_list="order_list_keys_list">
                                        <template v-slot:bulks_actions_block="{ item, index }">
                                            <div class="col-lg-12 items-selection-block p-2" :class="object_type + '-selection-block'">
                                                <SelectableItemActions
                                                    :add-to-selected-list="addToSelectedList"
                                                    :item="item"
                                                    :bulk_actions_customer_id="bulk_actions_customer_id"
                                                    :is_actions_bar_opened="is_actions_bar_opened"
                                                    :preform-bulk-action="preformBulkAction"
                                                    :selected_ids="selected_ids"/>
                                            </div>

                                        </template>

                                        <template v-slot:orders_actions="{ item, index }">

                                            <OrderActionsList
                                                :index="index"
                                                :item="item"
                                                :item_type="item_type"
                                                :object_type="object_type"
                                                :perform-action="performAction"
                                                :permissions_list="permissions_list"
                                            />

                                        </template>
                                    </po-lines>
                                </div>
                                <!-- Product Section End-->
                                <div class="pagination">
                                    <b-pagination
                                        v-if="perpage.perPage !== 0"
                                        v-model="perpage.currentPage"
                                        :total-rows="request_meta.total"
                                        @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                                        class="pagination d-flex align-items-center justify-content-center"
                                        :per-page="perpage.perPage"
                                    ></b-pagination>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" id="list_add" role="tabpanel" :class="isActiveTab('list_add')">
                            <SpItemAddTab v-if="hasPermission('add_'+object_type)" @on-item-add="addNewItem" menu_title="Add Order" object_name="Order"/>
                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>

                        <div class="tab-pane" id="export_item" role="tabpanel" :class="isActiveTab('export_item')">
                            <div class="row">
                                <div class="col-12">
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="export_required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="item_type"
                                        :is-selected="isSelected"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="selected_customer"
                                        :all_items_count="total"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-if="can_bulk_update" class="tab-pane" id="list_bulk_actions" role="tabpanel" :class="isActiveTab('list_bulk_actions')">
                            <div class="row">
                                <div class="col-12">
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="item_type"
                                        :is-selected="isSelected"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="selected_customer"
                                        :all_items_count="total"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewOrder from "../extras/AddNewOrder";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {OrderKeysList} from "@/views/assetflow/form_data/OrderKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import TopFiltersBlockAdvanced from "../sp_storages/TopFiltersBlockAdvanced";
import OrderActionsList from "./OrderActionsList";
import ListItemsDashboardTab from "../sp_items_shared/ListItemsDashboardTab";
import SpItemExportTab from "../sp_items_shared/SpItemExportTab";
import SpItemAddTab from "../sp_items_shared/SpItemAddTab";
import SpItemBulkUpdatesTab from "../sp_items_shared/SpItemBulkUpdatesTab";
import SetOrderService from "../../items/ordersContent/SetOrderService";
import OrdersAndLinesShared from "@/mixins/OrdersAndLinesShared.mixin";
import SetOrderSubscribers from "./SetOrderSubscribers";
import BulkActionsBar from "../managed_storages/BulkActionsBar";
import BulkActionsBlock from "../managed_storages/BulkActionsBlock";

import SharedBulkUpdate from "@/mixins/SharedBulkUpdate.mixin";
import SharedBulkUpdateFetchLists from "@/mixins/SharedBulkUpdateFetchLists.mixin";
import SelectableItemActions from "../managed_storages/SelectableItemActions";

const orderResource = new Resource('api/assetflow/orders');
export default {
    name: 'OrdersList',
    props: ['item_id', 'item_type'],
    mixins: [SharedBulkUpdate, SharedBulkUpdateFetchLists, ObjectsSidebarShared, TopFiltersMixin, OrdersAndLinesShared],
    components: {
        SelectableItemActions,
        BulkActionsBlock,
        BulkActionsBar,
        SetOrderSubscribers,
        SetOrderService,
        SpItemBulkUpdatesTab,
        SpItemAddTab,
        SpItemExportTab,
        ListItemsDashboardTab,
        OrderActionsList,
        TopFiltersBlockAdvanced,
        LotsOfItemsAlert, ObjectsSidebar, PoLines, AddNewOrder, SearchBox, Layout},
    data() {
        return {
            required_actions: [],
            export_required_actions: [],
            order_list_keys_list: OrderKeysList,
            total: 0,
            ordersData: [],
            listLoading: false,
            specific_object_name: 'Orders',
            request_meta: {},
            breadcrumb_items: [],
            title: "Orders",
            items: [
                {
                    text: "Orders",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_order_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            object_type: 'orders',
            object_type_list_active_tab: 'orders_list_active_tab',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
                {
                    id: 2,
                    label: 'Order Types',
                    backend_key: 'order_type'
                },
                {
                    id: 3,
                    label: 'Due Date',
                    backend_key: 'service_target_date',
                    no_parse_to_int: true
                },
                {
                    id: 4,
                    label: 'Service Types',
                    backend_key: 'order_service_type_id'
                },
            ],
            items_list: [
                [],
                // status
                [],
                // order type
                [],
                // Due Date
                [],
                // Service Types
                [],
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'item_status'
                },
                {
                    index: 2, // index in items_list
                    object_name: 'order_type'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'due_date'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'order_service'
                },
            ],
            filter_classes: {
                '0': 'orders-all',
                '1': 'orders-statuses',
                '2': 'orders-order_types',
                '3': 'orders-due_dates',
                '4': 'orders-service_types'
            },

            active_tab: 'list_dashboard',
            totals_only:0,
            dashboard_info: null,
            listTabs:{
                list_dashboard:{
                    'id': "list_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                list_items:{
                    'id': "list_items",
                    'name': 'Orders',
                    'icon': "user",
                    'isActive': false
                },
                list_add:{
                    'id': "list_add",
                    'name': 'Add',
                    'icon': "user",
                    'isActive': false
                }
            },
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        this.tab_list_name = 'listTabs';

        if (this.hasPermission('can_export_orders')){
            this.appendExportItemsTab(this.tab_list_name);
            this.export_required_actions.push('export_orders');
        }

        if (this.user_info && this.user_info.hasOwnProperty('can_bulk_update')){
            // shall check for other bulk update actions' permissions
            if (this.user_info.can_bulk_update === 1 && this.hasPermission('can_export_orders')){
                this.appendBulkActionsTab(this.tab_list_name);
                this.can_bulk_update = true;
            }
        }


        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
        };
        this.required_items = ['order_service', 'customers', 'status_storage', 'created_at'];
        this.permissions_list['view_' + this.object_type] = this.hasPermission('read_' + this.object_type);
        this.permissions_list['add_' + this.object_type] = this.hasPermission('add_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);
        this.permissions_list['browse_users'] = this.hasPermission('browse_users');
        this.permissions_list['manage_orders'] = this.hasPermission('manage_orders');

        this.has_order_actions_permissions = this.permissions_list['view_' + this.object_type] || this.permissions_list['edit_' + this.object_type] || this.permissions_list['browse_users'] || this.permissions_list['manage_orders'] || this.permissions_list['delete_' + this.object_type];

        if (this.has_order_actions_permissions){
            this.order_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'orders_actions'
            }
        }
        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.order_list_keys_list.customer_info.label = 'Production';
            }
            delete this.order_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.order_list_keys_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_orders_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.order_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.order_preferred_sorting;
        }



        let sp_items_list_active_tab = localStorage.getItem(this.object_type_list_active_tab);
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'list_dashboard';
                    break;
                case 'items':
                    this.active_tab = 'list_items';
                    break;
                case 'add':
                    this.active_tab = 'list_add';
                    break;
                case 'export':
                    this.active_tab = 'export_item';
                    break;
                case 'bulk_actions':
                    this.active_tab = this.can_bulk_update ? 'list_bulk_actions' : 'list_items';
                    break;
            }
        }else if (sp_items_list_active_tab && sp_items_list_active_tab !== ''){
            if (sp_items_list_active_tab === 'list_bulk_actions' && !this.can_bulk_update){
                this.active_tab = 'list_items';
            }else{
                this.active_tab = sp_items_list_active_tab;
            }
        }

        this.listTabs.list_items.name = this.specific_object_name;
        if (!this.permissions_list[('add_' + this.object_type)]){
            delete this.listTabs.list_add;
            delete this.listTabs.export_item;
            this.active_tab = ['list_add', 'export_item'].includes(this.active_tab) ? 'list_dashboard' : this.active_tab;
            localStorage.setItem(this.object_type_list_active_tab, this.active_tab);
        }
        this.getActiveTabInfo(this.active_tab);
    },
    computed: {
        totalAssetsValue() {
            return this.ordersData ? this.ordersData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    watch:{
        bulk_actions_customer_id: {
            deep: true,
            handler: function (newValue) {
                if (this.bulk_actions_customer_id){
                    this.getSPCustomerStorage(this.bulk_actions_customer_id);
                }
            }
        },
    },
    methods: {

        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        tabClicked(tab_id){
            let older_active_tab = this.active_tab;
            this.active_tab = tab_id;
            localStorage.setItem(this.object_type_list_active_tab, tab_id);
            this.getActiveTabInfo(tab_id, older_active_tab);
        },
        getActiveTabInfo(tab_id, older_active_tab=''){
            switch (tab_id) {
                case 'list_dashboard':
                case 'list_items':
                    // refresh list_dashboard and list_items?
                    // refresh list_dashboard and list_items?
                    if (older_active_tab !== 'list_bulk_actions'){
                        // don't reload content if older tab was bulk actions
                        if (!this.is_content_loaded_using_queue){
                            this.getList(this.selected_item);
                        }
                    }
                    break;
                case 'list_add':
                    // shall get info of list_add?
                    break;
                case 'export_item':
                    // shall get info of export_item?
                    break;
                case 'list_bulk_actions':
                    if (older_active_tab === ''){
                        if (!this.is_content_loaded_using_queue){
                            this.getList(this.selected_item);
                        }
                    }
                    break;
            }
        },
        performAction(order, action, orderKey, e){
            if (action === 'edit'){
                if (this.permissions_list[('edit_' + this.object_type)] && order.status === 0){
                    this.$bvModal.show('create-order');
                    this.$refs['add-new-order'].setSelectedItem(order);
                }
            }else if (action === 'unconfirm'){
                let order_id = order.id;
                let nextStatus = 0;
                let strongThis = this;
                let other_info = {
                    success_message: 'Unconfirmed #' + order.uid + ' Successfully'
                };
                this.set_item_property_new(this.object_type, order_id, 'status', nextStatus, other_info, function () {
                    strongThis.ordersData[orderKey].status = nextStatus;
                });

            }else if (action === 'set_order_service'){
                this.$refs['set_order_service'].setSelectedItem(order, this.item_type);
                this.$bvModal.show('set_order_service');
            }else if (action === 'set_order_subscribers'){
                this.$refs['set_order_subscribers'].setSelectedItem(order, this.item_type);
                this.$bvModal.show('set_order_subscribers');
            }else if (action === 'remove_order_items'){
                let modal_title = '';
                let modal_content = '';
                let modal_condition = false;
                if (action === 'remove_order_items'){
                    modal_title = `Remove All ${order.order_lines_count} Items`;
                    modal_content = this.htmlToVNode(`<p>Remove All ${order.order_lines_count} Items from <strong>${order.uid}</strong>?</p>`);
                    modal_condition = order.hasOwnProperty('order_lines_count') && order.order_lines_count;
                }

                if (modal_condition){
                    this.presentConfirmBox(modal_title, modal_content, 'danger', 'md')
                        .then(value => {
                            if (value) {
                                console.log(`shall ${action}?`);
                                // send bulk action request?
                            }
                        });
                }

            }else if (action === ('delete'+this.object_type)){
                if (order.id !== '' && order.order_lines_count === 0){
                    this.deleteItemByID(order.id, order.name, this.object_type, order)
                        .then((response) => {
                            if (response.data.data.status){
                                this.ordersData.splice(orderKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, order) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            if (this.is_actions_bar_opened && this.should_hide_unselected){
                let strongThis = this;
                return this.ordersData.filter(function(asset) {
                    return strongThis.selected_ids.includes(asset.id);
                });
            }else{
                return this.ordersData;
            }
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('order_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_orders_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            this.resetSelectingItems();
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            let active_tab = localStorage.getItem(this.object_type_list_active_tab);
            if (!active_tab) {
                active_tab = 'list_dashboard';
            }

            this.totals_only = active_tab === 'list_dashboard' ? 1 : 0;

            if (!this.is_export){
                this.listLoading = true;
            }

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1, 2, 3].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }


            this.setTopFilterValue(selected_item);

            required_query = this.SBHandleQueryItems(required_query, selected_item);

            required_query.s_keyword = this.search_keyword;
            required_query.totals_only = this.totals_only;
            required_query = this.appendTopFiltersValues(required_query);
            const {data} = await orderResource.list(required_query);



            if (Object.keys(data.data).includes('status')){
                if (this.totals_only) {
                    // shall refresh totals
                    this.dashboard_info = data.data.items;
                    this.listLoading = false;
                    return;
                }

                if (this.is_export){
                    let new_item = data.data.status;
                    this.presentToast((new_item ? "Success!" : "Error!"),
                        data.data.msg,
                        (new_item ? 'success' : 'danger'),
                        3000,
                        true,
                        true);
                    this.listLoading = false;
                    this.setActionsStatus(true, new_item);
                    this.is_export = 0;
                    return;
                }
            }

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.ordersData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }

            this.is_export = 0;

        },
        addNewItem(order_type) {
            this.$refs['add-new-order'].setSelectedItem(null);
            this.$bvModal.show('create-order');
        }
    },
}
</script>
