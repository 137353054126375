<template>
    <div class="row">
        <b-form-group
        v-for="(required_field, key) in required_fields"
        v-if="required_field.type !== 'hidden_text'"
        :key="required_field.key"
        :label="required_field.label"
        :label-for="required_field.key"
        :class="required_field.required_class || 'col-sm-12'"
        >

            <template #label>
                <div v-if="required_field.has_message && required_field.message_html !== ''" v-html="required_field.message_html">
                </div>
                <div v-if="required_field.hasOwnProperty('html_label') && required_field.html_label" v-html="required_field.html_label"></div>
                <div v-else>{{ required_field.label }}</div>
            </template>

            <date-picker
                v-if="required_field.type === 'date'"
                v-model="addForm[required_field.key]"
                lang="en" :first-day-of-week="1"
                format="DD-MM-YYYY"
                value-type="DD-MM-YYYY"
                :placeholder="required_field.placeholder || required_field.label"/>

            <date-picker
                v-else-if="required_field.type === 'datetime'"
                v-model="addForm[required_field.key]"
                lang="en" :first-day-of-week="1"
                type="datetime"
                format="DD-MM-YYYY HH:mm"
                value-type="DD-MM-YYYY HH:mm"
                :placeholder="required_field.placeholder || required_field.label"/>

            <div v-else-if="required_field.type === 'readonly_text'" class="common-st-title-label readonly_text_value">
                <p class="object-title-list"
                   v-if="addForm[required_field.key] && ['service_id', 'address_id', 'customer_id', 'sp_id'].includes(required_field.key)">
                    <i v-if="required_field.key === 'service_id'" :class="addForm[required_field.key].service_type_info.icon || 'fa fa-star'"></i>
                    <i v-else-if="required_field.key === 'address_id'" class="fa fa-envelope"></i>
                    <i v-else class="fa fa-star"></i>

                    <span class="item-header">
                        <a class="text-dark"
                           v-if="['customer_id', 'sp_id'].includes(required_field.key)"
                           :href="'/' + item_type + '/' + (required_field.key === 'sp_id' ? 'service_providers' : required_field.key.replace('_id', 's')) + '/' + addForm[required_field.key].id + '/view'">
                            {{ addForm[required_field.key].name }}
                        </a>
                        <a class="text-dark"
                           v-else-if="required_field.key === 'address_id'"
                           :href="'/' + item_type + '/addresses/' + addForm[required_field.key].id + '/view'">
                            {{ addForm[required_field.key].name }}
                        </a>
                        <span v-else>{{ addForm[required_field.key].name }}</span>
                    </span>
                </p>

                <h5 v-else-if="addForm[required_field.key] && addForm[required_field.key].hasOwnProperty('is_sp_managed') && addForm[required_field.key].is_sp_managed">{{ addForm[required_field.key].sp_name }}</h5>
                <h5 v-else-if="addForm[required_field.key] && addForm[required_field.key].hasOwnProperty('name') && addForm[required_field.key].name">{{ addForm[required_field.key].name }}</h5>
                <h5 v-else-if="required_field.hasOwnProperty('listLoading') && required_field.listLoading">Loading...</h5>
                <h5 v-else>-</h5>
            </div>

            <div v-else-if="required_field.type === 'custom-multi-select'" class="row">
                <div :class="'text-center col-xl-' + (required_field.key === 'colours' ? '1' : '3')"
                     v-for="(colour, key) in required_field.options"
                     :key="key">

                    <div v-if="required_field.key === 'colours'"
                         class="color-box mt-1 p-1 rounded"
                         :style="'background-color:' + colour.hex"
                         @click="toggleCheckboxImage(colour, required_field.key)"
                    >
                    </div>
                    <img v-else
                         :alt="colour.name"
                         height="50"
                         :src="colour.image"
                         data-holder-rendered="true"
                         class="rounded"
                         @click="toggleCheckboxImage(colour, required_field.key)"
                    >

                    <b-form-checkbox
                        v-model="addForm[required_field.key]"
                        :name="required_field.key"
                        :id="required_field.key + '_' + colour.id"
                        :value="colour"
                        class="custom-checkbox-af-accent mt-3 mb-3 text-center"
                    >
                        <span class="my-2">{{ colour.name  | titleize }}</span>
                    </b-form-checkbox>
                </div>
            </div>

            <div class="st-radio-row"
                 v-else-if="required_field.type === 'radio-select'">
                <div v-for="(option, index) in required_field.options"
                     :key="index"
                     :class="required_field.required_options_class || 'col-3'">
                    <div class="cm-radio-btn" :class="'mt-1 '+required_field.key+'-' + option.id">
                        <label>
                            <input
                                v-model="addForm[required_field.key]"
                                :key="index"
                                :id="'_'+index"
                                :name="required_field.key"
                                :value="option"
                                @input="selectedRadioButton(required_field.key, option, $event)"
                                type="radio"/>
                            <div class="st-radio-box">
                                <span></span>
                                <p v-if="required_field.hasOwnProperty('is_lower_case') && required_field.is_lower_case">{{ option.name }}</p>
                                <p v-else>{{ option.name | titleize }}</p>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <multiselect
                v-else-if="['single-select', 'multi-select', 'toggle-single-select'].includes(required_field.type)"
                v-model="addForm[required_field.key]"
                track-by="id"
                label="name"
                :custom-label="required_field.hasOwnProperty('custom_label') ? required_field.custom_label : CustomMSLabel"
                :loading="required_field.hasOwnProperty('listLoading') ? required_field.listLoading : false"
                :multiple="required_field.type === 'multi-select'"
                :placeholder="required_field.placeholder || required_field.label"
                @input="changedSelectValue(required_field.key, $event)"
                :disabled="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                class="mySingleSelect"
                :options="required_field.options"
                :allow-empty="required_field.type === 'multi-select' || required_field.hasOwnProperty('allow_empty') && required_field.allow_empty"
                :deselect-label="required_field.hasOwnProperty('allow_empty') && required_field.allow_empty ? 'Remove Selected' : ''"
            >

                <template v-if="required_field.hasOwnProperty('custom_html_slot') && required_field.hasOwnProperty('custom_label') && required_field.custom_html_slot" v-slot:singleLabel="{ option }">
                    <div v-html="required_field.custom_label(option)"></div>
                </template>
                <template v-if="required_field.hasOwnProperty('custom_html_slot') && required_field.hasOwnProperty('custom_label') && required_field.custom_html_slot" v-slot:option="{ option }">
                    <div v-html="required_field.custom_label(option)"></div>
                </template>

            </multiselect>

            <div v-if="required_field.type === 'change_images'" class="st-drop-file-block">
                <div class="st-drop-file-box">
                    <vue-dropzone id="imagesgallery"
                                  ref="myVueDropzone"
                                  :use-custom-slot="true"
                                  :include-styling="false"
                                  :options="characterDropzoneOptions"
                                  @vdropzone-thumbnail="plain_thumbnail"
                                  @vdropzone-file-added="fileAdded"
                                  @vdropzone-removed-file="fileRemoved"
                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                    >
                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <div class="st-upload-title">
                                <h3>Drop files here or click to upload</h3>
                                <p>... or click to select a file from your computer</p>
                            </div>
                        </div>
                    </vue-dropzone>
                    <b-button
                        @click="$refs.myVueDropzone[0].$el.click();"
                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                    >Add New Image
                    </b-button>
                </div>
            </div>

            <div v-else-if="required_field.key === 'asset_images'" class="st-drop-file-block">

                <div v-if="required_field.hasOwnProperty('has_top_html_note') && required_field.has_top_html_note !== ''" v-html="required_field.has_top_html_note"></div>

                <div class="st-drop-file-box">
                    <vue-dropzone id="imagesgallery"
                                  ref="myVueDropzone"
                                  :use-custom-slot="true"
                                  :include-styling="false"
                                  :options="assetDropzoneOptions"
                                  @vdropzone-thumbnail="thumbnail"
                                  @vdropzone-file-added="fileAdded"
                                  @vdropzone-removed-file="fileRemoved"
                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                    >
                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <div class="st-upload-title">
                                <h3>Drop files here or click to upload</h3>
                                <p>... or click to select a file from your computer</p>
                            </div>
                        </div>
                    </vue-dropzone>
                    <b-button
                        @click="$refs.myVueDropzone[0].$el.click();"
                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                    >Add New Image</b-button>
                </div>
            </div>

            <div class="dimensions-btn" v-else-if="required_field.type === 'value_types' && addForm.hasOwnProperty('quantity')">
                <a
                    v-for="option in (parseInt(addForm.quantity) !== 1 ? value_type_options : [value_type_options[0]])"
                    :key="option.id"
                    :value="option.id"
                    @click="addForm.value_type = option.id"
                    :class="[{'active': addForm.value_type === option.id}]"
                    class="dimensions-auto">
                    <span></span>{{ getValueTypeTitle(option) | titleize }}
                </a>
            </div>


            <div v-else-if="required_field.type === 'custom_slot'" :class="required_field.slot_class">
                <slot :name="required_field.slot_name" v-bind:index="key" v-bind:required_field="required_field"></slot>
            </div>

            <div v-else-if="['text', 'number'].includes(required_field.type)"
                :class="required_field.hasOwnProperty('with_preferred_unit') && required_field.with_preferred_unit ? 'common--input-filed unit-input' : ''">
                <b-form-input
                    v-model="addForm[required_field.key]"
                    :type="required_field.type"
                    :disabled="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                    @input="changedSelectValue(required_field.key)"
                    :id="required_field.key"
                    :placeholder="required_field.placeholder || required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <span class="u-mm" v-if="required_field.hasOwnProperty('with_preferred_unit') && required_field.with_preferred_unit">
                    {{ !['area', 'total_area', 'volume', 'total_volume'].includes(required_field.key) && addForm.preferred_unit ? addForm.preferred_unit.name : 'm' }}
                    <sup v-if="['volume', 'total_volume'].includes(required_field.key)">3</sup>
                    <sup v-if="['area', 'total_area'].includes(required_field.key)">2</sup>
                </span>
            </div>

            <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                <div class="common--input-filed">
                    <textarea v-model="addForm[required_field.key]" :id="required_field.key" :placeholder="required_field.placeholder || ''"></textarea>
                </div>
            </div>
            <div class="st--common--box" v-else-if="required_field.type === 'icon'">
                <div class="common--input-filed">
                    <i :class="addForm[required_field.key]" class="font-size-20"></i>
                </div>
            </div>

            <div class="st--common--box" v-else-if="['custom_fields', 'asset_files', 'asset_links'].includes(required_field.type)">
                <CustomFieldsRepeater :add-form="addForm" :required_field_name="required_field.type" :static_list="required_field.type === 'custom_fields'" :force_refresh="['asset_files', 'asset_links'].includes(required_field.type)"/>
            </div>

            <div class="st--common--box" v-else-if="['single-select-label', 'custom-label'].includes(required_field.type)">
                <div class="common--input-filed">
                    <span>{{ addForm[required_field.key] ? addForm[required_field.key].name : ''}}</span>
                </div>
            </div>

            <div v-if="['single-select-label'].includes(required_field.type)"><a href="#" class="inline-action change-select-field" @click="toggleType(required_field)">{{ required_field.hasOwnProperty('action_text') ? required_field.action_text : 'Change' }}</a></div>

            <small v-if="required_field.hasOwnProperty('has_note') && required_field.has_note !== ''" class="form-text text-muted default-value-block text-left"> {{ required_field.has_note }}</small>
            <small v-if="required_field.hasOwnProperty('has_html_note') && required_field.has_html_note !== ''" class="form-text text-muted default-value-block text-left" v-html="required_field.has_html_note"></small>


            <small v-if="addForm.has_errors && required_field.is_required && !addForm[required_field.key]">
                This Field is required
            </small>

            <div class="waste_stream_fields mt-2" v-if="required_field.key === 'waste_stream_type' && addForm.waste_stream_type">
                <div class="common-st-title-label">
                    <label>{{ addForm.waste_stream_type.id === 0 ? 'Product Waste Stream' : 'Composition'}}</label>
                </div>
                <div>
                    <div class="st-radio-row" v-if="addForm.waste_stream_type.id === 0">
                        <div v-for="(option, index) in required_fields.waste_stream_id.options"
                             :key="index"
                             class="col-4">
                            <div class="cm-radio-btn" :class="'mt-1 waste_stream_id-' + option.id">
                                <label>
                                    <input
                                        v-model="addForm.waste_stream_id"
                                        :key="index"
                                        :id="'_'+index"
                                        name="waste_stream_id"
                                        :value="option"
                                        @input="selectedRadioButton('waste_stream_id', option, $event)"
                                        type="radio"/>
                                    <div class="st-radio-box">
                                        <span></span>
                                        <p>{{ option.name | titleize }}</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div v-else class="row">
                        <div class="text-center col-xl-3"
                             v-for="(colour, key) in required_fields.composition.options"
                             :key="key">
                            <img
                                 :alt="colour.name"
                                 height="140"
                                 :src="getAssetBaseUrl + colour.image"
                                 data-holder-rendered="true"
                                 class="rounded"
                                 @click="toggleCheckboxImage(colour, 'composition')"
                            >

                            <b-form-checkbox
                                v-model="addForm.composition"
                                name="composition"
                                :id="'composition_' + colour.id"
                                :value="colour"
                                class="custom-checkbox-af-accent mt-3 mb-3 text-center"
                            >
                                <span class="my-2">{{ colour.name  | titleize }}</span>
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>

            </div>

    </b-form-group>
    </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";
import CustomFieldsRepeater from "../../assets/assetForm/CustomFieldsRepeater";

export default {
    name: 'ChangeCommonFieldsAdvanced',
    components: {CustomFieldsRepeater, DatePicker},
    mixins: [multiSelect, dropzoneMixin],
    props: {
        item_type: String,
        addForm: {},
        required_fields: {}
    },
    data(){
        return {
            characterDropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.character_template()
            },
            value_type_options:[
                {
                    id: 0,
                    name: 'Dimensions for 1 item'
                },
                {
                    id: 1,
                    name: 'Dimensions for (x) items'
                }
            ],
        }
    },
    created() {

        if (this.required_fields.hasOwnProperty('part_ids')){
            this.required_fields.part_ids.custom_label = this.PartDetailsItem;
        }

        if (this.required_fields.hasOwnProperty('storage_wrapper_id')){
            this.required_fields.storage_wrapper_id.custom_html_slot = true;
            this.required_fields.storage_wrapper_id.custom_label = function (storage_wrapper_info){
                return '<i class="'+storage_wrapper_info.icon+'"></i> ' + storage_wrapper_info.name;
            };
        }
        if (this.required_fields.hasOwnProperty('category_id')){
            this.required_fields.category_id.custom_html_slot = true;
            this.required_fields.category_id.custom_label = this.CustomNameOrDesc;
        }

        if (this.required_fields.hasOwnProperty('character_id')){
            this.required_fields.character_id.custom_label = this.CustomCharacterName;
        }

        if (this.required_fields.hasOwnProperty('change_id')){
            this.required_fields.change_id.custom_label = this.CustomChangeName;
        }

        if (this.required_fields.hasOwnProperty('shoot_day_id')){
            this.required_fields.shoot_day_id.custom_label = this.CustomShootDayName;
        }

    },
    methods:{
        getValueTypeTitle(option) {
            if (this.addForm.hasOwnProperty('quantity') && option.id === 1) {
                return option.name.replace('(x)', this.addForm.quantity);
            }
            return option.name
        },
        toggleCheckboxImage(colour, field_key){
            let form_value = this.addForm[field_key];
            if (form_value){
                let index = form_value.map(function(e) { return e.id; }).indexOf(colour.id);
                if (index > -1){
                    // found, remove it!
                    form_value.splice(index, 1);
                }else{
                    // not found, add it
                    form_value.push(colour);
                }
            }else{
                form_value = [];
                form_value.push(colour);
            }
            this.$set(this.addForm, field_key, form_value);
        },
        toggleType(required_field){
            if (required_field.type === 'toggle-single-select'){
                required_field.type = 'single-select-label';
            }else{
                required_field.type = 'toggle-single-select';
            }
        },
        character_template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                        <div class="dz-image">
                            <div data-dz-thumbnail-bg></div>
                        </div>
                        <div class="dz-details">
                            <div class="dz-image_title">
                                <div class="common--input-filed">
                                    <input type="text" data-dz-image_title name="image_title" placeholder="Image Title" />
                                </div>
                            </div>
                            <div class="dz-image_description">
                                <div class="common--input-filed">
                                    <textarea data-dz-image_description name="image_description" placeholder="Image Description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message"><span data-dz-errormessage></span></div>
                        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                        <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        <a class="dz-remove btn btn btn-sm mt-1 btn-block btn-outline-danger" data-dz-remove="">Remove file</a>
                    </div>`;
        },
        selectedRadioButton(field, option, event){
            this.$set(this.addForm, field, option);
            if (this.required_fields[field] && this.required_fields[field].hasOwnProperty('onChangingValue')){
                this.required_fields[field].onChangingValue();
            }
        },
        changedSelectValue(field, event) {
            // let option = this.addForm[field];
            if (this.required_fields[field].hasOwnProperty('onChangingValue')){
                if (['weight', 'total_weight'].includes(field)){
                    this.required_fields[field].onChangingValue(field);
                }else{
                    this.required_fields[field].onChangingValue();
                }
            }
        },
        setManuallyAddFile(file, url){
            if (this.$refs.myVueDropzone) {
                this.$refs.myVueDropzone[0].manuallyAddFile(file, url);
            }
        }
    }
}
</script>
