<template>
    <b-modal :id="modal_id"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             hide-footer>

        <template #modal-title>
            <div v-html="modal_title"></div>
        </template>

        <div v-if="selected_asset">

            <b-form-group :label="'Quantity To Add ('+addForm.available_quantity+' Available):'">

                <p v-if="addForm.available_quantity > 0">
                    <multiselect
                        v-model="addForm.quantity"
                        :options="getNumbers(1, addForm.available_quantity)"
                        placeholder="Qty"
                        :show-labels="false"
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>
                </p>
                <p v-else>-</p>
            </b-form-group>

            <div class="row mb-3">
                <div class="col-12">
                    <div class="cm_common_table_box">
                        <div class="cm_common_table_heading af-primary">
                            <h4><i class="bx bxs-cart"></i> '{{ asset_is_in_storage ? "Storage" : "Move To Storage" }}' Orders</h4>
                        </div>

                        <div v-if="listLoading" class="products text-center">
                            <b-spinner class="text-af-accent m-2"></b-spinner>
                            <h4>Fetching '{{ asset_is_in_storage ? "Storage" : "Move To Storage" }}' Orders...</h4>
                        </div>
                        <div v-else-if="options_list && options_list.length > 0" class="cm_ordering_table_view cm_order_overview table-responsive">
                            <table>
                                <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Created By</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>


                                <tr v-for="(item, key) in options_list" :key="key" v-if="item.id !== -99">
                                    <td>
                                        <p><i class="bx bxs-cart"></i> <a :href="'/' + item_type + '/orders/' + item.id + '/view'" class="text-dark">{{ item.uid }}</a></p>
                                    </td>
                                    <td>
                                        <p><i class="bx bxs-cart"></i> {{ getObjectOf(item.order_type, orderTypesOptionList) ? getObjectOf(item.order_type, orderTypesOptionList)[(item_type === 'customer' ? 'name' : 'sp_name')] : '-' }}</p>
                                    </td>
                                    <td>
                                        <p><i class="bx bxs-cart"></i> <a :href="'/' + item_type + '/orders/' + item.id + '/view'" class="text-dark">{{ item.name }}</a></p>
                                    </td>
                                    <td>
                                        <p v-if="item.hasOwnProperty('user_info') && item.user_info"><i class="bx bxs-user"></i> <a :href="'/' + item_type + '/users/' + item.user_info.id + '/view'" class="text-dark">{{ item.user_info.name }}</a></p>
                                        <p v-else><i class="bx bxs-user"></i> -</p>
                                    </td>
                                    <td>
                                        <a v-if="destination_order && destination_order.id === item.id" href="javascript:void(0);" class="selected-order-to-add">Selected</a>

                                        <a v-else class="to-add-order" href="javascript:void(0);" @click="destination_order = item">Select this Order</a>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="5" @click="destination_order = item">
                                        <a class="to-add-order" href="javascript:void(0);">Add New Order</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-else class="cm_pending_review">
                            <p class="cm_no_item_pending_label">No '{{ asset_is_in_storage ? "Storage" : "Move To Storage" }}' Orders Available</p>
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="is_sp_managed_order" class="sp-form-section">
                <b-form-group label="This Order will be fulfilled by:" v-if="selected_asset.hasOwnProperty('warehouse_name')">
                    <p class="object-title-list">
                        <i class="fa fa-warehouse"></i> <span class="item-header"><a class="text-dark" :href="'/' + item_type + '/service_providers/' + selected_asset.warehouse_id + '/view'">{{ selected_asset.warehouse_name | titleize }}</a></span>
                    </p>
                </b-form-group>

                <b-form-group label="Service Provider Name" v-else-if="is_new_order" label-for="select-destination-sp">
                    <multiselect
                        id="select-destination-sp"
                        v-model="addForm.sp_id"
                        track-by="id"
                        label="name"
                        class="myMultiSelect"
                        :loading="listLoading_sps_list"
                        :options="sps_list"
                        placeholder="Select SP"
                        :allow-empty="false"
                        deselect-label=""
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>
                </b-form-group>
            </div>
            <div v-else-if="item_type === 'sp'" class="sp-form-section">
                <b-form-group label="" v-if="selected_asset.hasOwnProperty('customer_name')">
                    <p class="object-title-list">
                        <i class="fa fa-star"></i> <span class="item-header"><a class="text-dark" :href="'/' + item_type + '/customers/' + selected_asset.customer_id + '/view'">{{ selected_asset.customer_name | titleize }}</a></span>
                    </p>
                </b-form-group>
            </div>


            <hr v-if="is_new_order">
            <h4 v-if="is_new_order" class="mb-3" style="font-weight: 600;">Add New Order</h4>

            <b-form-group
                v-for="(required_field, key) in order_fields"
                :key="key"
                v-if="is_new_order && required_field.type !== 'hidden_text'"
                :label="required_field.label" :label-for="'_' + required_field.key">
                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    :id="'_' + required_field.key"
                    track-by="id"
                    :label="required_field.key === 'order_type' && item_type === 'sp' ? 'sp_name' : 'name'"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :loading="false"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="'_' + required_field.key"
                    :placeholder="required_field.placeholder || required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="common--input-filed" v-else-if="required_field.type === 'textarea'">
                    <textarea
                        v-model="addForm[required_field.key]"
                        :id="'_' + required_field.key"></textarea>
                </div>

            </b-form-group>


            <div v-if="with_caution" class="form-group">
                <b-form-checkbox
                    v-if="caution_title !== ''"
                    v-model="accepted_caution"
                    class="custom-checkbox mb-3"
                >{{ caution_title }}</b-form-checkbox>
                <span v-if="caution_error" class="caution-error text-danger">Please tick the checkbox to continue</span>
            </div>

            <div class="text-right">
                <b-button type="submit"
                          variant="success"
                          :disabled="isAddingItem || !(addForm.quantity && destination_order)"
                          v-on:click.once="performChange">Add</b-button>
                <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
            </div>
        </div>


    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const orderResource = new Resource('api/assetflow/orders');
const serviceProviderResource = new Resource('api/assetflow/service_providers');

const addToOrderResource = new Resource('api/assetflow/assets/addAssetToOrder');

import multiSelect from '@/mixins/MultiSelect';

export default {
    name: "AddAssetToOrder",
    components: {},
    props:['item_type', 'object_type', 'received_modal_id'],
    mixins: [multiSelect],
    data(){
        return {
            modal_id: 'add-asset-to-order',
            modal_title: 'Add To Order',
            list_title: 'Select Order',
            selected_asset: null,
            received_selected_asset: null,
            listLoading: false,
            options_list: [],
            destination_order: null,
            with_caution: true,
            caution_title: '',
            accepted_caution: false,
            caution_error: false,
            sps_list:[],
            listLoading_sps_list: false,
            orderTypesOptionList : [
                {
                    id: 0,
                    name: 'Move To Storage',
                    sp_name: 'Move To Storage',
                    is_sp_managed: true
                },
                {
                    id: 1,
                    name: 'Storage Order',
                    sp_name: 'Storage Order',
                    is_sp_managed: true
                }
            ],
            addForm:{
                order_type:null,
                sp_id:null,
                reference: '',
                name: '',
                quantity:'',
                available_quantity:'',
                notes: ''
            },
            order_fields: {
                reference: {
                    key: 'reference',
                    label: 'Customer Reference',
                    type: 'text',
                    has_message: true,
                    message_text: 'Easily identify this Order, and apply default Notes and Customer References to Order items.',
                    message_class: 'alert-info order-reference-msg',
                    is_required:false
                },
                name: {
                    key: 'name',
                    label: 'Easily identify this Order with a Friendly Name:',
                    placeholder: 'Friendly Name',
                    type: 'text',
                    is_required:false
                },
                notes: {
                    key: 'notes',
                    label: 'Order Notes',
                    type: 'textarea',
                    is_required:false
                },
            },
            isAddingItem: false
        }
    },
    mounted() {
        this.options_list = [];
    },
    created() {
        if (this.received_modal_id){
            this.modal_id = this.received_modal_id;
        }
    },
    computed:{
        asset_is_in_storage() {
            return this.selected_asset.storage_status === 1;
        },
        is_new_order() {
            return this.destination_order && this.destination_order.id === -99;
        },
        is_sp_managed_order() {
            return this.item_type === 'customer' && this.addForm.order_type && this.addForm.order_type.is_sp_managed;
        },
        orderTypesOtherThanStorageOrders(){
            let other_order_types = [...this.orderTypesOptionList];
            other_order_types.splice(1, 1);
            return other_order_types;
        }
    },
    methods: {
        getNumbers:function(start,stop,step = 1){
            return new Array(stop / step).fill(start).map((n,i)=>(i+1)*step);
        },
        async getItemsList(item_type, sp_id=null){

            if (this.item_type === 'customer' && this.current_customer){

                let queryList = {
                    customer_id: this.current_customer.id
                };
                if (sp_id){
                    queryList.sp_id = sp_id;
                }
                let requiredResource = null;
                let optionsList = '';
                switch (item_type){
                    case 'service_providers':
                        requiredResource = serviceProviderResource;
                        optionsList = 'sps_list';
                        break;
                }
                this['listLoading_' + optionsList] = true;
                if (requiredResource) {
                    const {data} = await requiredResource.list(queryList);
                    if (Object.keys(data).includes('data')) {

                        let items_list = data.data.items;
                        if (item_type === 'service_providers' && items_list.length === 0 && this.current_sp){
                            items_list = [{id: this.current_sp.id, name: this.current_sp.name}];
                            sp_id = this.current_sp.id;
                        }

                        this[optionsList] = items_list;

                        if (item_type === 'service_providers' && sp_id && items_list.length === 1){
                            this.addForm.sp_id = items_list[0];
                        }


                        this['listLoading_' + optionsList] = false;
                    } else {
                        console.log('Response Error');
                    }
                }
            }
        },
        async getOptionsList(){
            let order_type = this.asset_is_in_storage ? 1 : 0;

            this.listLoading = true;

            let queryList = {};
            if (this.item_type === 'customer' && this.current_customer){
                queryList.customer_id = this.current_customer.id;
            }else if (this.is_group && this.item_type === 'group' && this.selected_customer.id){
                queryList.customer_id = this.selected_customer.id;
            }

            queryList.order_type = order_type;

            queryList.status_id = 0;
            if (this.asset_is_in_storage){
                queryList.sp_id = this.selected_asset.warehouse_id;
            }
            queryList.order_by = 'created_at';
            queryList.order_dir = 'desc';

            if (['managed_storages', 'order_lines'].includes(this.object_type)){
                delete queryList.customer_id;
                queryList.current_customer_id = this.selected_asset.customer_id;
            }
            const { data } = await orderResource.list(queryList);


            if (Object.keys(data).includes('data')){
                let options_list = data.data.items;
                let added_order_id = localStorage.getItem('added_order_id');

                if (added_order_id !== "" && options_list.length > 0) {
                    this.destination_order = _.find(options_list, function(o) { return o.id === parseInt(added_order_id); });
                }

                this.options_list = options_list;
                this.options_list.push({id: -99, name: ('Add New ' + (this.asset_is_in_storage ? 'Storage Order' : 'Move To Storage Order'))});
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },
        set_item(item){
            if (item){
                this.options_list = [];
                this.destination_order = null;
                this.isAddingItem = false;

                this.received_selected_asset = item;
                this.selected_asset = item;

                if (this.asset_is_in_storage){
                    this.addForm.order_type = this.orderTypesOptionList[1];
                }else{
                    this.addForm.order_type = this.orderTypesOptionList[0];
                }
                if (item.hasOwnProperty('quantity')){
                    this.addForm.quantity = item.quantity;
                }

                if (item.hasOwnProperty('available_quantity')){
                    this.addForm.available_quantity = item.available_quantity;
                }

                this.addForm.sp_id = item.warehouse_id;

                let order_name = (this.asset_is_in_storage ? "Storage " : "");
                this.modal_title = 'Add <strong>' + this.selected_asset.name + '</strong> to a <strong>New</strong> or <strong>Existing</strong> ' + order_name + 'Order';
                this.list_title = 'Select ' + order_name + 'Order';
                this.getOptionsList();
                if (!this.selected_asset.hasOwnProperty('warehouse_name')){
                    this.getItemsList('service_providers');
                }else {
                    this.order_fields.notes.label = 'Order Notes for ' + this.selected_asset.warehouse_name;

                }
            }
        },

        getIDOfObject(object, required_key='id'){
            return object ? object[required_key] : null;
        },
        async performChange(){
            this.isAddingItem = true;

            let managed_storage_id = this.selected_asset.managed_storage_id ? this.selected_asset.managed_storage_id : null;

            let sp_id;
            if (this.addForm.sp_id){
                sp_id = this.getIDOfObject(this.addForm.sp_id);
            }else{
                sp_id = this.selected_asset.warehouse_id;
            }

            let params = {
                managed_storage_id: managed_storage_id,
                quantity: this.addForm.quantity,
                asset_id: this.getIDOfObject(this.selected_asset),
                order_type: this.asset_is_in_storage ? 1 : 0,
                order_id: this.getIDOfObject(this.destination_order),
                sp_id: sp_id,
                reference: this.addForm.reference,
                name: this.addForm.name,

                notes: this.addForm.notes
            }


            const { data } = await addToOrderResource.store(params);

            let status = data.data.status;

            let selected_managed_storage = data.data.managed_storage;
            if (selected_managed_storage){
                let order_info = data.data.order_info;
                if (order_info){
                    localStorage.setItem('added_order_id', order_info.id);
                }

                this.presentToast((status ? "Success!" : "Error!"),
                    status ? ('Asset Added To Order Successfully') : data.data.msg,
                    (status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
                this.isAddingItem = false;

                this.$bvModal.hide(this.modal_id);
                this.$emit('on-modal-save', selected_managed_storage, order_info, this.modal_id);
            }else{
                this.presentToast("Error!",
                    data.data.msg,
                    'danger',
                    3000,
                    true,
                    true);
                this.isAddingItem = false;
                this.$bvModal.hide(this.modal_id);
            }
        },
        showCautionError(value){
            this.caution_error = value;
        },
    }

}
</script>


