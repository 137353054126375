<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item && selected_item.id !== -99 ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="['single-select', 'multi-select'].includes(required_field.type)"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    :custom-label="required_field.hasOwnProperty('custom_label') ? required_field.custom_label : CustomMSLabel"
                    :multiple="required_field.type === 'multi-select'"
                    :placeholder="required_field.label"
                    @input="changedRadioValue(required_field.key, $event)"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="required_field.type === 'multi-select'"
                    deselect-label=""
                >
                </multiselect>


                <b-form-input
                    v-else-if="['text', 'number'].includes(required_field.type)"
                    v-model="addForm[required_field.key]"
                    :type="required_field.type"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

            </b-form-group>

        </div>

        <div class="text-right">

            <AFButton
                v-if="addForm.character_id"
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-change' + '-' + buttonkey"
                :is_enabled="addForm.character_id && is_saving_enabled"
                :required_action="addItem"
                v-b-tooltip.hover.bottom
                :title="!addForm.character_id ? '' : ''"
                :required_text="selected_item && selected_item.id !== -99 ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />

            <button v-else class="btn btn-success" disabled>
                <span id="no-character-selected">{{ selected_item && selected_item.id !== -99 ? ('Update {' + selected_item.name + '}') : 'Create' }}</span>
            </button>

            <b-tooltip target="no-character-selected" placement="top" variant="info">Please select a character.</b-tooltip>

            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const changeResource = new Resource('api/assetflow/changes');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";
import ChangeFormsMixin from "@/mixins/ChangeFormsMixin";

export default {
    mixins: [multiSelect, dropzoneMixin, ChangeFormsMixin],
    name: "AddNewChange",
    props: ['modal_title', 'item_type', 'with_item_details'],
    components: {AFButton, DatePicker},
    data(){
        return {
            part_index: null,
            character_index: null,
            isLoadingItem: false,
            isSavingItem: false,
            characterDropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.character_template()
            },
            addForm:{
                character_id: null,
                name: '',
                change_order: null,
                original_target_change: null,
                part_ids: '',
            },
            required_fields:{
                character_id: {
                    key: 'character_id',
                    label: 'Character',
                    type: 'single-select',
                    options:[]
                },

                name: {
                    key: 'name',
                    label: 'Change Name',
                    type: 'text'
                },
                change_order: {
                    key: 'change_order',
                    label: 'Change Order',
                    type: 'single-select',
                    options:[
                        {id: -1, name: 'Add Before'},
                        {id: 0, name: 'Add New'},
                        {id: 1, name: 'Add After'}
                    ]
                },
                original_target_change: {
                    key: 'original_target_change',
                    label: 'Change',
                    type: 'single-select',
                    options:[]
                },

                part_ids: {
                    key: 'part_ids',
                    label: 'Scene',
                    type: 'multi-select',
                    custom_label: 'name',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null,
            lists_values: {}
        }
    },
    created() {
        this.required_fields.part_ids.custom_label = this.PartDetailsItem;
        this.required_fields.character_id.custom_label = this.CustomCharacterName;
    },
    methods:{

        character_template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                            <div class="dz-image">
                                <div data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                            </div>
                            <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        </div>`;
        },

        selectedRadioButton(field, option, event){

        },

        filterChangesOfCharacter: function (option) {
            let character_id = option.id;
            let last_change_name = '';
            if (option.hasOwnProperty('last_change_name') && option.last_change_name) {
                last_change_name = option.last_change_name;
            }
            this.$set(this.addForm, 'name', last_change_name);

            this.required_fields.original_target_change.options = this.lists_values.original_target_change.filter((item) => {
                return character_id === item.character_id;
            });
        },
        changedRadioValue(field, event) {
            let option = this.addForm[field];
            if (field === 'character_id' && option){
                this.filterChangesOfCharacter(option);
            }
        },

        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                part_ids: 'parts_list',
                character_id: 'character_short_list',
                original_target_change: 'changes_short_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

                this.fillSelectedItem();
                if (this.addForm.character_id){
                    this.filterChangesOfCharacter(this.addForm.character_id);
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetFormValues() {

            this.addForm = {
                character_id: null,
                name: '',
                change_order: this.required_fields.change_order.options[1],
                original_target_change: null,
                part_ids: '',
            };

        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },

        fillSelectedItem() {
            if (this.selected_item) {

                if (this.selected_item.hasOwnProperty('part_index')){
                    this.part_index = this.selected_item.part_index;
                }
                if (this.selected_item.hasOwnProperty('character_index')){
                    this.character_index = this.selected_item.character_index;
                }

                this.setValueFromOptions('character_id', this.selected_item.character_id);
                this.setValueFromOptions('part_ids', this.selected_item.part_ids, null, true);//  ?? multi?

                this.addForm.name = this.selected_item.name;
                if (this.selected_item.change_order){
                    this.setValueFromOptions('change_order', this.selected_item.change_order);
                }else{
                    this.$set(this.addForm, 'change_order', this.required_fields.change_order.options[1]);
                }

                this.setValueFromOptions('original_target_change', this.selected_item.original_target_change);

            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.resetFormValues();
            }
        },

        async addItem(event){
            this.isSavingItem = true;
            let part_ids = this.addForm.part_ids;
            if (part_ids && part_ids.length > 0){
                part_ids = this.addForm.part_ids.map(function(e) { return e.id; }).join(",");
            }


            var params = {
                character_id: this.addForm.character_id ? this.addForm.character_id.id : null,
                part_ids: part_ids,


                change_order: this.addForm.change_order ? this.addForm.change_order.id : null,
                original_target_change: this.addForm.original_target_change ? this.addForm.original_target_change.id : null,
                name: this.addForm.name,
                with_item_details: this.with_item_details
            };
            if (this.selected_item && this.selected_item.id !== -99){
                params.item_id = this.selected_item.id;
            }

            const { data } = await changeResource.store(params);


            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                await this.getFieldsList();
                status_message = 'Success!';
                message = (this.selected_item && this.selected_item.id !== -99 ? 'Edited' : 'Added') + ' Successfully';
                status_class = 'success';
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

            this.isSavingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();


            if (this.part_index !== null){
                this.$emit('on-new-item-adding', new_item, this.item_type, this.part_index, this.character_index);
            }else{
                this.$emit('on-new-item-adding', new_item, this.item_type);
            }

        }
    }
}
</script>
