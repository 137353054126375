<template>
    <div class="AcquisitionTransactionsTab">
        <po-lines v-if="item_info.hasOwnProperty('transactions_info') && item_info.transactions_info && item_info.transactions_info.length > 0"
                  :items="item_info.transactions_info"
                  :current_page_type="item_info.current_page_type"
                  :object_type="'transactions'"
                  :line_type="'transactions'"
                  :get-poline-classes-dict="getPoLineClassFor"
                  :keys_list="transactions_list_key_list">
            <template v-slot:transactions_actions="{ item, index }">
                <div class="btn-group bl-auto">
                    <button type="button" class="btn btn-none dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <b-dropdown-item v-if="hasPermission('browse_' + item.item_type)"
                                         :href="'/' + item_info.current_page_type + '/' + item.item_type +'/' + item.id + '/view'">
                            View
                        </b-dropdown-item>
                        <b-dropdown-item v-if="hasPermission('edit_' + item.item_type)"
                                         :href="'/' + item_info.current_page_type + '/' + item.item_type +'/' + item.id + '/edit'">
                            Edit
                        </b-dropdown-item>
                        <b-dropdown-item v-if="hasPermission('delete_' + item.item_type)"
                                         variant="danger"
                                         @click="performAction(item, 'delete', index, $event)">
                            Delete
                        </b-dropdown-item>
                    </div>
                </div>
            </template>
        </po-lines>
        <div v-else class="text-center">
            <h6>No transactions found here at the moment.</h6>
        </div>
    </div>
</template>
<script>

import PoLines from "./widgets/detailsViews/PoLines";
import {TransactionKeysList} from "@/views/assetflow/form_data/TransactionKeysList";

export default {
    name: 'LedgerTransactionsTab',
    components: {PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            transactions_list_key_list:TransactionKeysList,
        }
    },
    created() {
        this.has_actions_permissions = this.hasPermission('edit_transactions') || this.hasPermission('delete_transactions');

    },
    methods: {
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },

    }

}
</script>
