<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <!-- Multi step form -->
                <section class="multi_step_form">
                    <form id="msform">
                        <!-- Tittle -->
                        <div class="tittle">
                            <h2>Import Items</h2>
                        </div>
                        <!-- fi_progressbar -->
                        <ul id="fi_progressbar">
                            <li @click="goToStep(1)" class="active">Basic Info</li>
                            <li @click="goToStep(2)">{{addForm.object_type ? addForm.object_type.name : 'Object'}} Settings</li>
                            <li @click="goToStep(3)">Upload Files</li>
                            <li @click="goToStep(4)">Mapping</li>
                            <li @click="goToStep(5)">Preview & Edit</li>
                            <li @click="goToStep(6)">Review & Import</li>
                        </ul>
                        <!-- fieldsets -->
                        <fieldset>
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Basic Info</h4>
                                    <div v-for="(required_field, key) in required_fields.first_step"
                                         :key="key"
                                         class="row d-flex flex-wrap mb-3"
                                         role="group">

                                        <label class="col-sm-3 col-form-label">{{required_field.label}}</label>
                                        <div class="col">
                                            <multiselect v-if="required_field.type === 'single-select'"
                                                         v-model="addForm[key]"
                                                         :track-by="required_field.track_by || 'id'"
                                                         :label="required_field.option_label || 'name'"
                                                         :placeholder="required_field.placeholder || ('Select ' + required_field.label)"
                                                         :options="required_field.options"
                                                         :allow-empty="required_field.allow_empty || false"
                                                         @input="required_field.change_action ? required_field.change_action(key) : null"
                                            >
                                            </multiselect>
                                            <div v-else-if="required_field.type === 'datepicker'">
                                                <date-picker
                                                    v-model="addForm[key]"
                                                    lang="en" :first-day-of-week="1"
                                                    type="datetime"
                                                    format="DD-MM-YYYY"
                                                    value-type="DD-MM-YYYY"
                                                    :placeholder="required_field.placeholder || ('Select ' + required_field.label)" />
                                            </div>
                                            <div v-else>
                                                Field Type isn't set yet!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-success next">Continue</button>
                        </fieldset>
                        <fieldset>
                            <div class="card">
                                <div class="card-body" v-if="addForm.object_type && required_fields.second_step.hasOwnProperty(addForm.object_type.id)">
                                    <h4 class="card-title mb-4">{{addForm.object_type.name}} Settings</h4>
                                    <div v-for="(required_field, key) in required_fields.second_step[addForm.object_type.id]"
                                         :key="key"
                                         class="row d-flex flex-wrap mb-3"
                                         role="group">

                                        <label class="col-sm-3 col-form-label">{{required_field.label}}</label>
                                        <div class="col">
                                            <multiselect v-if="required_field.type === 'single-select'"
                                                         v-model="addForm[key]"
                                                         :multiple="required_field.hasOwnProperty('is_multi') && required_field.is_multi"
                                                         :track-by="required_field.track_by || 'id'"
                                                         :label="required_field.option_label || 'name'"
                                                         :placeholder="required_field.placeholder || ('Select ' + required_field.label)"
                                                         :options="required_field.options"
                                                         :allow-empty="required_field.allow_empty || false"
                                                         @input="required_field.change_action ? required_field.change_action(key) : null"
                                            >
                                            </multiselect>
                                            <div v-else-if="required_field.type === 'datepicker'">
                                                <date-picker
                                                    v-model="addForm[key]"
                                                    lang="en" :first-day-of-week="1"
                                                    type="datetime"
                                                    format="DD-MM-YYYY"
                                                    value-type="DD-MM-YYYY"
                                                    :placeholder="required_field.placeholder || ('Select ' + required_field.label)" />
                                            </div>
                                            <div v-else>
                                                Field Type isn't set yet!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-else>
                                    <h4 class="card-title mb-4">Object Settings</h4>

                                    <div class="row d-flex flex-wrap mb-3">
                                        There are no shared settings for this Object type.
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-secondary previous">Back</button>
                            <button type="button" class="btn btn-success next">Continue</button>
                        </fieldset>
                        <fieldset>
                            <h3>Upload Files</h3>
                            <vue-dropzone
                                id="fi_upload"
                                ref="myVueDropzone"
                                class="mb-3"
                                :use-custom-slot="true"
                                :options="dropzoneOptions"
                                @vdropzone-file-added="startFileUpload"
                                @vdropzone-max-files-exceeded="maxFilesExceeded"
                            >
                                <div class="dropzone-custom-content">
                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                    <h4>Drop a file to import or click to upload.</h4>
                                </div>
                            </vue-dropzone>
                            <button type="button" class="btn btn-secondary previous">Back</button>
                            <button v-show="is_uploaded"
                                    type="button"
                                    class="btn btn-success next"
                                    @click="previewSelectedFile">Continue</button>

                        </fieldset>
                        <fieldset>
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Mapping</h4>
                                    <div class="form-group row mb-3" v-for="(mapping_table_header, key) in mapping_table_headers">
                                        <label class="col-3 mapping-field-label">{{ mapping_table_header.label }}</label>
                                        <div class="col">
                                            <multiselect
                                                class="my-multiselect"
                                                v-model="mapping_list[key]"
                                                :placeholder="'Select ' + mapping_table_header.label"
                                                @input="handleUnmappedFields"
                                                :options="fileTableHeaders">
                                            </multiselect>
                                            <small class="form-text text-muted mapping-field-note text-left">{{ mapping_table_header.note }}</small>
                                        </div>
                                    </div>

                                    <div class="text-left">
                                        <h6>Unmapped fields</h6>

                                        <div v-if="unmappedFields.length > 0">
                                            <span>The following fields from your spreadsheet have not been mapped. Data in these fields will not be imported. If you want to import this data, then please map these fields</span>
                                        </div>

                                        <span v-for="(unmapped_field, index) in unmappedFields" :key="index" class="file-import-status-btn status-btn-in_storage mr-1">{{ unmapped_field }}</span>
                                    </div>
                                </div>
                            </div>

                            <button type="button" class="btn btn-secondary previous">Back</button>

                            <button type="button"
                                    class="btn btn-success next"
                                    @click="handleMapping">{{ unmappedFields.length === 0 ? 'Ignore Unmapped Fields & Preview' : 'Preview & Edit' }}</button>

                        </fieldset>
                        <fieldset>
                            <h3>Preview & Edit</h3>
                            <div v-show="rows.length > 0">

                                <AssetContentBlock
                                    v-if="['asset'].includes(addForm.object_type.id)"
                                    :add-form="addForm"
                                    :item_type="item_type"
                                    :rows="rows"
                                    :asset-table-headers="assetTableHeaders"
                                    :selected_object_type="addForm.object_type.id"
                                    ref="FileContentTable"
                                />
                            </div>


                            <button type="button" class="btn btn-secondary previous">Back</button>
                            <button @click="edited_file ? saveEditedFile('exported') : null" type="button" class="btn btn-success next">{{ edited_file ? 'Save & ' : '' }}Continue</button>
                        </fieldset>
                        <fieldset>
                            <h3>Review & Import</h3>
                            <div class="row text-left">
                                <div class="col-12 mt-3" v-if="original_file">
                                    <ul class="summary-log-items">
                                        <li><b>Original Uploaded File:</b> {{ original_file.name }}</li>
                                        <li v-if="edited_file_info"><b>Updated File:</b> <a :href="edited_file_info.hasOwnProperty('download_url') ? edited_file_info.download_url : '#'" target="_blank">{{ original_file.name }}</a> (click to download)</li>
                                        <li><b>{{addForm.entity_type.name}} Name:</b> {{ addForm.entity_id ? addForm.entity_id.name : '-' }}</li>
                                        <li><b>Object Type:</b> {{ addForm.object_type.name | titleize}}s</li>
                                        <li><b>Records to be created:</b> {{rows.length}} {{ addForm.object_type.name | titleize}}s</li>
                                    </ul>
                                </div>
                                <div class="col-12 mt-3">
                                    <p>We'll send you an email notification once done. After clicking 'Start Import' you will be redirected to the Import Status page which you can refresh to monitor progress.</p>
                                    <p>Ready to Import? Click Start Import below.</p>
                                </div>
                            </div>

                            <button type="button" class="btn btn-secondary previous">Back</button>
                            <button type="button" class="btn btn-success" @click="startImport($event)">Start Import</button>
                        </fieldset>
                    </form>
                </section>
                <!-- End Multi step form -->
            </div>
        </div>
    </Layout>
</template>
<script>

import 'jquery.easing';
import Layout from "../../../layouts/main";

import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";

import Resource from '@/api/resource';
import {read, utils, write, writeFileXLSX} from "xlsx";

const fileImportResource = new Resource('api/assetflow/file_imports');

const saveFileImportResource = new Resource('api/assetflow/file_imports/start_import');


import vue2Dropzone from "vue2-dropzone";
import AssetContentBlock from "./AssetContentBlock";
export default {
    name: 'FileImport',
    components: {
        AssetContentBlock,
        Layout,
        Multiselect,
        DatePicker,
        vueDropzone: vue2Dropzone
    },
    props:['item_type'],
    data: function () {
        return {
            dropzoneOptions: {
                url: "required_url",
                acceptedFiles: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 1
            },
            rows: [],
            is_edited_uploaded: null,
            edited_file_link: null,
            edited_file: false,
            assetTableHeaders: [],
            fileTableHeaders: [],
            unmappedFields: [],
            forceMap: false,
            mapping_list: {
                name: null,
                description: null,
                created_by: null,
                external_reference: null,
                supplier_name: null,
                category_name: null,
                folder_name: null,
                module_id: null,
                fdp: null,
                fdp_status: null,
                ownership_type: null,
                acquisition_name: null,
                quantity: null,
                unit_price: 'unit_price',
                total_price: 'total_price',
                net_total: 'net_total',
                unit_net: 'unit_net',
                tax_rate: 'tax_rate',
                tax: 'tax',
                unit_tax: 'unit_tax',
                status: null,
                acquired_date: null,
                asset_usage: null,
                is_hero: null,
                is_asset: null,
                tags: null,
            },
            mapping_table_headers: {
                name: {
                    label: 'Asset Name',
                    note: 'This is the asset name'
                },
                description: {
                    label: 'Asset Description',
                    note: 'This is the asset description'
                },
                created_by: {
                    label: 'Created By',
                    note: 'This is the User who created the asset'
                },
                external_reference: {
                    label: 'External Reference',
                    note: 'This is the external reference'
                },
                supplier_name: {
                    label: 'Supplier',
                    note: 'This is the supplier name'
                },
                category_name: {
                    label: 'Category',
                    note: 'This is the category name'
                },
                folder_name: {
                    label: 'Folder',
                    note: 'This is the folder name'
                },
                module_id: {
                    label: 'Module',
                    note: 'This is the module name'
                },
                acquisition_name: {
                    label: 'Acquisition',
                    note: 'This is the acquisition name'
                },
                fdp: {
                    label: 'Final Disposition Planning',
                    note: 'This is the final disposition planning'
                },
                fdp_status: {
                    label: 'Confirm FDP',
                    note: 'Confirm FDP'
                },
                ownership_type: {
                    label: 'Ownership Type',
                    note: 'This is the ownership type'
                },
                quantity: {
                    label: 'Quantity',
                    note: 'This is the quantity'
                },
                unit_price: {
                    label: 'Unit Price',
                    note: 'This is the unit price'
                },
                total_price: {
                    label: 'Total Price',
                    note: 'This is the total price'
                },
                status: {
                    label: 'Status',
                    note: 'This is the status'
                },
                acquired_date: {
                    label: 'Acquired Date',
                    note: 'This is the acquired date'
                },
                asset_usage: {
                    label: 'Asset Usage',
                    note: 'This is the asset usage'
                },
                is_hero: {
                    label: 'Is Hero?',
                    note: ''
                },
                is_asset: {
                    label: 'Is Asset?',
                    note: ''
                },
                tags: {
                    label: 'Tags',
                    note: ''
                }
            },
            is_uploaded: null,
            is_uploading: null,
            uploadProgress: null,
            editedUploadProgress: null,
            original_file_link: null,
            original_file: null,
            file_import_info: null,
            edited_file_info: null,
            addForm: {},
            required_fields: {
                first_step: {
                    entity_type: {
                        type: 'single-select',
                        label: 'Entity Type',
                        change_action: null,
                        options: [
                            {
                                id: 'customer',
                                name: 'Customer'
                            },
                            {
                                id: 'sp',
                                name: 'SP'
                            },
                        ]
                    },
                    entity_id: {
                        type: 'single-select',
                        label: 'Entity',
                        options: []
                    },
                    import_type: {
                        type: 'single-select',
                        label: 'Import Type',
                        options: [
                            {
                                id: 'insert',
                                name: 'Insert'
                            },
                            {
                                id: 'upsert',
                                name: 'Upsert',
                                $isDisabled: true
                            },
                            {
                                id: 'update',
                                name: 'Update',
                                $isDisabled: true
                            },
                        ]
                    },
                    object_type: {
                        type: 'single-select',
                        label: 'Object',
                        options: [
                            {
                                id: 'asset',
                                name: 'Asset',
                                $isDisabled: true
                            },
                            {
                                id: 'acquisition',
                                name: 'Acquisition',
                                $isDisabled: true
                            },
                            {
                                id: 'supplier',
                                name: 'Supplier',
                                $isDisabled: true
                            },
                        ]
                    },
                },
                second_step: {
                    asset: {
                        module_id: {
                            type: 'single-select',
                            label: 'Module',
                            options: []
                        },
                        category_id: {
                            type: 'single-select',
                            label: 'Category',
                            options: []
                        },
                        folder_id: {
                            type: 'single-select',
                            label: 'Folder Name',
                            options: []
                        },
                        created_by: {
                            type: 'single-select',
                            label: 'Created By',
                            options: [
                                {
                                    id: 1,
                                    name: 'Abdelrahman S.'
                                },
                                {
                                    id: 16,
                                    name: 'Tristian Roberts A'
                                },
                                {
                                    id: 44,
                                    name: 'Tristian Roberts'
                                },
                            ]
                        },
                        acquired_date: {
                            type: 'datepicker',
                            label: 'Acquired Date'
                        },
                        supplier_id: {
                            type: 'single-select',
                            label: 'Supplier',
                            options: []
                        },
                        fdp: {
                            type: 'single-select',
                            label: 'Final Disposition Planning',
                            options: []
                        },
                        fdp_status: {
                            type: 'single-select',
                            label: 'Confirm FDP',
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'}
                            ]
                        },
                        ownership_type: {
                            label: 'Ownership Type',
                            type: 'single-select',
                            options: []
                        },
                        status: {
                            type: 'single-select',
                            label: 'Status',
                            options: [
                                {id: 'planned', name: 'Planned'},
                                {id: 'acquired', name: 'Acquired'},
                                {id: 'active', name: 'Active'},
                                {id: 'disposed', name: 'Disposed'},
                            ]
                        },
                        asset_usage: {
                            type: 'single-select',
                            label: 'Asset Usage',
                            options: [
                                {id: 1, name: 'On Camera'},
                                {id: 0, name: 'Off Camera'},
                                {id: 2, name: 'Unknown'}
                            ]
                        },
                        is_hero: {
                            type: 'single-select',
                            label: 'Hero Item',
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'},
                                {id: 2, name: 'Unknown'}
                            ]
                        },
                        is_asset: {
                            type: 'single-select',
                            label: 'Is Asset',
                            options: [
                                {id: 1, name: 'Yes'},
                                {id: 0, name: 'No'},
                                {id: 2, name: 'Unknown'}
                            ]
                        },
                        tags: {
                            type: 'single-select',
                            label: 'Tags',
                            is_multi: true,
                            options: []
                        },
                    },
                    acquisition: {
                        module_id: {
                            type: 'single-select',
                            label: 'Module',
                            options: [
                                {
                                    id: 3,
                                    name: 'Costume'
                                },
                                {
                                    id: 2,
                                    name: 'Scripts'
                                },
                                {
                                    id: 4,
                                    name: 'Asset Management'
                                },
                            ]
                        },
                        acquired_at: {
                            type: 'datepicker',
                            label: 'Acquisition Date'
                        },
                        created_date: {
                            type: 'datepicker',
                            label: 'Created Date'
                        },
                        supplier_id: {
                            type: 'single-select',
                            label: 'Supplier',
                            options: []
                        },
                    },
                },
            }

        }
    },
    watch:{
        rows: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (oldVal !== newValue && !this.edited_file){
                    this.edited_file = true;
                }

            }
        },
    },
    created() {

        if (this.item_type !== 'customer'){
            this.required_fields.second_step.asset.status.options.push({id: 'in_storage', name: 'In Storage'});
        }
        this.prepareFields();
        this.getFieldsList();
    },
    mounted(){
        /*Function Calls*/
        this.verificationForm();
    },
    methods:{
        handleUnmappedFields(){
            // get what's in this.fileTableHeaders and not in this.mapping_table_headers keys
            let mapping_table_headers_keys = Object.values(this.mapping_list);
            this.unmappedFields = this.fileTableHeaders.filter(x => !mapping_table_headers_keys.includes(x));
        },
        async getFieldsList(){


            let modules_list = window.MODULES_LIST ? window.MODULES_LIST : [];
            if (['asset', 'acquisition'].includes(this.addForm.object_type.id)){
                this.required_fields.second_step[this.addForm.object_type.id].module_id.options = [...modules_list];
            }

            this.lists_values = {};
            this.isLoadingItem = true;
            let required_select_lists =  {
                category_id: 'categories_short_list',
                folder_id: 'folders_list',
                created_by: 'users_list',
                supplier_id: 'suppliers_list',
                fdp: 'final_disposition_planning',
                tags: 'tags_list',
                ownership_type: 'ownership_type_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                if (form_lists){
                    for (const list_key in required_select_lists) {
                        let select_name = required_select_lists[list_key];

                        if (['category_id', 'folder_id'].includes(list_key)){
                            let item_type = list_key.replace('_id', '') + 's';
                            this.lists_values[list_key] = this.getFolderObject(form_lists[select_name], 1, item_type, 'id', 'name');
                        }else{
                            this.lists_values[list_key] = form_lists[select_name];
                        }
                    }


                    for (let key in this.lists_values) {
                        this.getOptionsForKey(key, this.addForm.object_type.id, [], this.required_fields.second_step);
                    }
                }

            }).catch(error => {
                console.log(error);
            });
        },
        maxFilesExceeded(file){
            this.$refs.myVueDropzone.removeAllFiles();
            this.$refs.myVueDropzone.addFile(file);
        },
        goToStep(step){
            // shall go to step?
        },
        async saveImportFile(response, file) {
            let params = {
                name: file.name,
                file_size: file.size || 0,
                file_type: response.extension || 'xlsx',
                s3_uuid: response.uuid
            };

            const {data} = await fileImportResource.store(params);
            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = 'Saved Successfully';
                status_class = 'success';
                this.file_import_info = new_item;
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

        },
        setObjectTypeOptions(){
            let _this = this;
            this.required_fields.first_step.object_type.options = this.required_fields.first_step.object_type.options.map(function(object_type) {
                let has_permission = _this.hasPermission('add_' + object_type.id + 's');
                object_type.$isDisabled = !has_permission;
                if (!has_permission){
                    object_type.name = object_type.name + ' (not currently enabled)';
                }

                return object_type;
            });

            let queryVars = this.getQueryVars();
            let query_object_type = 'asset';
            if (queryVars.hasOwnProperty('object_type') && queryVars.object_type !== '') {
                query_object_type = queryVars.object_type;
            }

            let selected_object_type = this.required_fields.first_step.object_type.options.filter(function(object_type) {
                return object_type.id === query_object_type;
            })
            if (selected_object_type.length > 0){
                this.$set(this.addForm, 'object_type', selected_object_type[0]);
            }

        },
        prepareFields(){

            this.addForm.import_type = this.required_fields.first_step.import_type.options[0];

            this.setObjectTypeOptions();


            switch (this.selected_entity_id) {
                case 0: // customer
                case 1: // group
                    // use current customer
                    let customer_option = {... this.required_fields.first_step.entity_type.options[0]};
                    this.required_fields.first_step.entity_type.options = [
                        customer_option
                    ];

                    if (this.selected_entity_id === 0){
                        this.required_fields.first_step.entity_id.options = [
                            this.current_customer
                        ];
                        this.addForm.entity_type = customer_option;
                        this.addForm.entity_id = this.current_customer;
                    }else {

                        // shall get group customers .then(this.addForm.entity_id = this.current_customer;)
                    }

                    break;
                case 2: // sp
                    // use current sp
                    let sp_option = {... this.required_fields.first_step.entity_type.options[1]};
                    this.required_fields.first_step.entity_type.options = [ sp_option ];
                    this.required_fields.first_step.entity_id.options = [
                        this.current_sp
                    ];

                    this.addForm.entity_type = sp_option;
                    this.addForm.entity_id = this.current_sp;
                    break;
            }

            this.required_fields.first_step.entity_type.change_action = function (key) {
                // reset entity id
                this.$set(this.addForm, 'entity_id', null);

                let entity_type = this.addForm[key];
                let entity_ids_list = [];
                switch (entity_type.id) {
                    case 'customer':
                        // TODO: // should fetch customers
                        entity_ids_list = [
                            {
                                id: '1',
                                name: 'Production 1'
                            },
                            {
                                id: '27',
                                name: 'FF10 Demo'
                            },
                            {
                                id: '33',
                                name: 'FF10 Production'
                            },
                        ];
                        break;
                    case 'sp':
                        // TODO: // should fetch sps
                        entity_ids_list = [
                            {
                                id: '7',
                                name: 'CAMA'
                            },
                            {
                                id: '9',
                                name: 'NEW SP'
                            },
                        ];
                        break;
                }

                this.required_fields.first_step.entity_id.options = entity_ids_list;
            }.bind(this);
        },
        //* Form js
        verificationForm(){
            //jQuery time
            let current_fs, next_fs, previous_fs; //fieldsets
            let left, opacity, scale; //fieldset properties which we will animate
            let animating; //flag to prevent quick multi-click glitches

            $(".next").click(function () {
                if (animating) return false;
                animating = true;

                current_fs = $(this).parent();
                next_fs = $(this).parent().next();

                //activate next step on fi_progressbar using the index of next_fs
                $("#fi_progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

                //show the next fieldset
                next_fs.show();


                current_fs.hide();
                animating = false;

            });

            $(".previous").click(function () {
                if (animating) return false;
                animating = true;

                current_fs = $(this).parent();
                previous_fs = $(this).parent().prev();

                //de-activate current step on fi_progressbar
                $("#fi_progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

                //show the previous fieldset
                previous_fs.show();

                current_fs.hide();
                animating = false;

            });

            $(".submit").click(function () {
                return false;
            })
        },
        startFileUpload(file){
            this.is_uploaded = false;

            if (!file) { return; }
            this.original_file = file;

            this.uploadFileToS3(this.original_file, progress => {
                let uploadProgress = Math.round(progress * 100);

                if (file.previewElement) {
                    let progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                    progressElement.style.width = uploadProgress + "%";
                    progressElement.setAttribute("aria-valuenow", uploadProgress);
                    progressElement.textContent = uploadProgress + '%';
                }

            }, true).then( (response) => {
                // console.log("response");
                // console.log(response);
                // key: "tmp/7b599e6a-807e-4c0d-8e0d-92005b350c39"
                // key needs to be saved and moved to a specific folder
                this.is_uploaded = true;
                this.original_file_link = response.key || '#';

                this.saveImportFile(response, this.original_file);

            }).catch(error => {
                this.is_uploaded = false;
            });

        },

        async startImport(event){
            // console.log("shall fire this file import >");
            //
            // console.log("this.file_import_info");
            // console.log(this.file_import_info);


            // module_id
            // category_id
            // folder_id
            // created_by
            // acquired_date
            // supplier_id
            // fdp
            // fdp_status
            // ownership_type
            // status
            // asset_usage
            // is_hero
            // is_asset
            // tags

            let shared_settings = {};

            let allSecondStepField = this.required_fields.second_step[this.addForm.object_type.id];

            for (const secondStepFieldKey in allSecondStepField) {
                let secondStepField = allSecondStepField[secondStepFieldKey];
                if (this.addForm.hasOwnProperty(secondStepFieldKey)) {
                    if (secondStepField.type === 'single-select') {
                        if (secondStepField.is_multi) {
                            // handle tags
                            shared_settings[secondStepFieldKey] = this.addForm[secondStepFieldKey].map(function (e) {
                                return e.name;
                            }).join('#!,!#');
                        } else {
                            // handle all other select fields

                            shared_settings[secondStepFieldKey] = this.addForm[secondStepFieldKey].id;
                        }
                    } else {
                        // handle other fields ( i.e. date )
                        shared_settings[secondStepFieldKey] = this.addForm[secondStepFieldKey];
                    }
                }
            }

            let file_import_id = this.file_import_info.id;
            let params = {
                item_id: file_import_id,
                shared_settings: shared_settings
            };

            const {data} = await saveFileImportResource.store(params);
            let message = data.data.msg;
            this.handleResponse(data, message, true);
            // shall visit file import page
            window.location.href = '/' + this.item_type + '/file_imports/' + file_import_id + '/view';
        },

        getSheetHeaders(sheet) {
            const headerRegex = new RegExp('^([A-Za-z]+)1=\'(.*)$');
            const cells = utils.sheet_to_formulae(sheet);
            return cells.filter(item => headerRegex.test(item)).map(item => item.split("='")[1]);
        },
        setupMappingList() {
            for (const mappingKey in this.mapping_table_headers) {
                if (this.assetTableHeaders.includes(mappingKey)){
                    this.$set(this.mapping_list, mappingKey, mappingKey);
                }
            }

            this.handleUnmappedFields();
        },
        renameKeys(keysMap, obj) {
            return Object.keys(obj).reduce(
                (acc, key) => ({
                    ...acc,
                    ...{ [keysMap[key] || key]: obj[key] }
                }),
                {}
            );
        },
        getRowQuantity: function (row) {
            let item_quantity = row.hasOwnProperty('quantity') && row.quantity ? row.quantity : 1;
            return item_quantity;
        },
        handleMapping() {
            let keys_list_to_use =  _.invert(this.mapping_list);


            this.assetTableHeaders = [... Object.values(keys_list_to_use)];

            let cost_properties = function (row) {
                return row;
            }

            if(this.rows.length > 0 && this.rows[0]){
                let first_row = this.rows[0];
                let getRowQuantity = this.getRowQuantity;
                let tax_rate = 0.2;

                if (first_row.hasOwnProperty('total_price')){
                    // shall calculate according to total_price
                    cost_properties = function (row) {
                        let quantity = getRowQuantity(row);
                        let total_price = parseFloat(row.total_price);
                        let unit_price = total_price / quantity;

                        let net_total = (total_price / (1 + tax_rate));
                        let tax = net_total * tax_rate;
                        let unit_net = 0;
                        if (net_total > 0 && quantity > 0){
                            unit_net = (net_total / quantity);
                        }

                        let unit_tax = 0;
                        if (tax > 0 && quantity > 0){
                            unit_tax = (tax / quantity);
                        }

                        row.unit_price = unit_price.toFixed(2);
                        row.net_total = net_total.toFixed(2);
                        row.unit_net = unit_net.toFixed(2);
                        row.tax_rate = tax_rate.toFixed(2);
                        row.tax = tax.toFixed(2);
                        row.unit_tax = unit_tax.toFixed(2);
                        return row;
                    }
                }else if (first_row.hasOwnProperty('unit_price')){
                    // shall calculate according to unit_price
                    cost_properties = function (row) {
                        let quantity = getRowQuantity(row);

                        let unit_price = parseFloat(row.unit_price);
                        let total_price = unit_price * quantity;
                        let net_total = (total_price / (1 + tax_rate));
                        let tax = net_total * tax_rate;

                        let unit_net = 0;
                        if (net_total > 0 && quantity > 0){
                            unit_net = (net_total / quantity);
                        }
                        let unit_tax = 0;
                        if (tax > 0 && quantity > 0){
                            unit_tax = (tax / quantity);
                        }

                        row.net_total = net_total.toFixed(2);
                        row.total_price = total_price.toFixed(2);
                        row.unit_net = unit_net.toFixed(2);
                        row.tax_rate = tax_rate.toFixed(2);
                        row.tax = tax.toFixed(2);
                        row.unit_tax = unit_tax.toFixed(2);
                        return row;
                    }
                }else{
                    // shall deal with defaults
                    cost_properties = function (row) {
                        row.unit_price = 0;
                        row.net_total = 0;
                        row.total_price = 0;
                        row.unit_net = 0;
                        row.tax_rate = tax_rate;
                        row.tax = 0;
                        row.unit_tax = 0;
                        return row;
                    }
                }

                if (first_row.hasOwnProperty('status')){
                    this.rows = this.rows.sort((a, b) => {
                        if (a.status > b.status) {
                            return 1;
                        }
                        if (a.status < b.status) {
                            return -1;
                        }
                        return 0;
                    });
                }
            }

            let edited_rows = [];
            for (const rowsKey in this.rows) {
                let edited_row = this.renameKeys(keys_list_to_use, this.rows[rowsKey]);
                edited_row = cost_properties(edited_row);
                edited_rows.push(edited_row);
            }

            this.rows = [... edited_rows];


            this.$nextTick(() => {
                if (this.$refs['FileContentTable']){
                    this.$refs['FileContentTable'].setDefaultValues();
                }
            })
        },
        async previewSelectedFile() {
            /* Download from https://sheetjs.com/pres.numbers */
            // const f = await fetch("https://sheetjs.com/pres.numbers");
            let f = this.original_file;
            if (!f){ return; }

            const ab = await f.arrayBuffer();

            /* parse workbook */
            const wb = read(ab);

            let worksheet = wb.Sheets[wb.SheetNames[0]]
            this.assetTableHeaders = this.getSheetHeaders(worksheet);
            this.fileTableHeaders = this.assetTableHeaders;

            this.setupMappingList();
            /* update data */
            this.rows = utils.sheet_to_json(worksheet, {
                defval:"", // if removed, headers will be omitted or reordered
                //header: ["ID","Name","Email", "Password","VerifiedAt","CreatedAt","UpdatedAt"],
                skipHeader:true,
            });


        },

        cleanName(required_object, select_field) {
            let value = required_object;
            let key = select_field;

            if (required_object.hasOwnProperty('id')){
                if (required_object.id < 0 && required_object.hasOwnProperty('is_new') && required_object.is_new){
                    value = required_object.name.replace(' (new)', '');
                }else{
                    value = required_object.id;
                    key = key.replace('_name', '_id');
                }
            }
            return {
                value: value,
                key: key
            }
        },
        organiseRows: function () {
            let edited_rows = [];
            for (const editedRowsKey in this.rows) {
                let row = Object.assign({}, this.rows[editedRowsKey]);
                let all_select_fields = [
                    'status',
                    'asset_usage',
                    'is_hero',
                    'is_asset',
                    'tags',
                    'module_name',
                    'category_name',
                    'folder_name',
                    'acquisition_name',
                    'fdp',
                    'fdp_status',
                    'ownership_type',
                    'created_by',
                    'supplier_name',
                ];

                for (const allSelectFieldsKey in all_select_fields) {
                    let select_field = all_select_fields[allSelectFieldsKey];
                    if (row.hasOwnProperty(select_field) && row[select_field] && row[select_field].hasOwnProperty('name')) {
                        let cleaned_key_value = this.cleanName(row[select_field], select_field);
                        delete row[select_field];
                        row[cleaned_key_value.key] = cleaned_key_value.value;
                    }
                }

                edited_rows.push(row);
            }
            return edited_rows;
        },
        exportFile(sheet_name='exported') {
            let edited_rows = this.organiseRows();
            const ws = utils.json_to_sheet(edited_rows);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, sheet_name);
            writeFileXLSX(wb, "SheetJSVueAoO.xlsx");

        },
        saveEditedFile(sheet_name='exported') {

            let edited_rows = this.organiseRows();
            const ws = utils.json_to_sheet(edited_rows);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, sheet_name);

            /* Make FormData */
            const data = write(wb, {bookType: 'xlsx', type: 'array'});
            const file = new File([data], sheet_name + '.xlsx', {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            this.uploadFileToS3(file, progress => {
                this.editedUploadProgress = Math.round(progress * 100);
            }, true).then( (response) => {
                // console.log("response");
                // console.log(response);
                // key: "tmp/7b599e6a-807e-4c0d-8e0d-92005b350c39"
                // key needs to be saved and moved to a specific folder
                this.is_edited_uploaded = true;
                this.edited_file_link = response.key || '#';

                this.saveEditedImportFile(response, this.file_import_info)
            }).catch(error => {
                this.is_edited_uploaded = false;
            });

        },
        async saveEditedImportFile(response) {
            if (!this.file_import_info){ return }

            let params = {
                item_id: this.file_import_info.id,
                s3_uuid: response.uuid
            };

            const {data} = await fileImportResource.store(params);
            this.edited_file_info = data.data.items;

            this.handleResponse(data, data.data.msg);

        },

    }
};
</script>

<style>
#fi_upload .dz-progress {
    margin-top: 10px !important;
}
</style>
