<template>
    <div class="step-form-content-block row">
        <div class="col-xl-12">
            <asset-info-section
                title="Confirmation"
                :selected_fields="section_fields"
                :add-form="addForm"
                :required_fields="required_fields">
                <div class="step-common-info-block">
                <div class="st--common--box" v-for="section_field in section_fields">
                    <div class="common-st-title-label">
                        <h5>{{ required_fields[section_field].name | titleize}}</h5>
                    </div>

                    <div class="st-status-radio-box" v-if="required_fields[section_field].type === 'radio-select'">
                        <div class="st-radio-row" v-if="required_fields[section_field].options.length > 0">
                            <div v-for="(option, index) in required_fields[section_field].options"
                                 :key="index"
                                 class="col-3">
                                <div class="cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                    <label>
                                        <input
                                            v-model="addForm.item_details[section_field]"
                                            :key="index"
                                            :id="'_'+index"
                                            :name="section_field"
                                            :value="option"
                                            @click="changedRadioValue(section_field, option, $event)"
                                            type="radio" />
                                        <div class="st-radio-box">
                                            <span></span>
                                            <p>{{ (option.name || option.description) | titleize }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'summary'">
                        <ul>
                            <li v-if="addForm.item_details.hasOwnProperty('acq_mode')">Acquisition Mode set to '{{ addForm.item_details.acq_mode.id === 1 ? 'Master' : 'Slave' }}'</li>
                            <li v-if="addForm.item_details.hasOwnProperty('acquisition_type')">
                                <p v-if="addForm.added_acquisition_types.length > 0 && addForm.added_acquisition_types.includes(addForm.item_details.acquisition_type.id)">New acquisition type'{{ addForm.item_details.acquisition_type.name }}' will be created.</p>
                                <p v-else>Acquisition type '{{ addForm.item_details.acquisition_type.name }}' will be used.</p>
                            </li>
                            <li>10 Assets (with Qty of 1) called 'Asset Name' will be created</li>
                            <li>1 Payment totalling £1000 will be created</li>
                            <li v-if="addForm.item_details.hasOwnProperty('confirmation_status') && addForm.item_details.confirmation_status.id === 0">Acquisition will be 'Closed'</li>
                            <li>Acquisition will be 'Completed' if balanced</li>
                        </ul>
                    </div>
                </div>

            </div>
            </asset-info-section>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import AssetInfoSection from "../../../assets/assetForm/AssetInfoSection";
export default {
    name: "ConfirmationStep",
    components: {AssetInfoSection, DatePicker},
    mixins:[],
    props:['item_type', 'addForm', 'required_fields', 'object_type'],
    data(){
        return {
            section_fields:[
                'confirmation_status',
                'acquisition_summary',
            ]
        }
    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods:{
        changedRadioValue(field, option, event) {

        },
    }
}
</script>

