<template>
    <div class="DispositionTab">
        <!-- Assets Status  -->
        <div class="assets-section box-border p-2 p-lg-3">
            <div class="row" v-if="latest_disposition_info">
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left col-divider">
                    <div class="assets-common ">
                        <div class="asseta-common-title mb-1">
                            <strong>Assets Status</strong>
                        </div>
                        <div class="assets-detail">
                            <a href="#" :class="'status-btn disposition-tab-status status-btn-' + item_info.status" v-html="getAssetStatusFrom(item_info)"></a>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-3 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Recipient</strong>
                        </div>
                        <div class="assets-detail">
                            <a v-if="latest_disposition_info.hasOwnProperty('recipient_type') && latest_disposition_info.recipient_type !== ''" :href="'/' + item_info.current_page_type + '/' + latest_disposition_info.recipient_type + '/' + latest_disposition_info.recipient_id + '/view'">{{ latest_disposition_info.recipient_name }}</a>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Last Updated By</strong>
                        </div>
                        <div class="assets-detail">
                            <span v-if="latest_disposition_info.hasOwnProperty('user_info')">
                                    <a :href="'/' + item_info.current_page_type + '/users/'+latest_disposition_info.user_info.id+'/view'">{{latest_disposition_info.user_info.name}}</a>
                                </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-2 text-left text-md-center col-divider">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Source</strong>
                        </div>
                        <div class="assets-detail">
                            <span v-if="latest_disposition_info.hasOwnProperty('source_info') && latest_disposition_info.source_info">
                                    <a :href="'/' + item_info.current_page_type + '/'+latest_disposition_info.source_info.item_type+'/'+latest_disposition_info.source_info.id+'/view'">{{latest_disposition_info.source_info.uid}}</a>
                                </span>
                            <span v-else>-</span>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xl-3 text-left text-md-center text-xl-right col-divider last-child">
                    <div class="assets-common">
                        <div class="asseta-common-title mb-1">
                            <strong>Date</strong>
                        </div>
                        <span v-if="latest_disposition_info.hasOwnProperty('created_at')">{{ latest_disposition_info.created_at | moment('MMMM Do YYYY') }} - {{ latest_disposition_info.created_at | fromNow}}</span>
                        <span v-else>-</span>

                    </div>
                </div>
            </div>
        </div>
        <!--  Assets Status Close -->

        <div class="row mt-2">
            <NewCustomGenericBlock
                block_title="Dispositions List"
                box_colour_v="-"
                columns_count_withclasses="12">
                <template v-slot:all_content>
                    <po-lines
                        :items="item_info.dispositions_info"
                        :current_page_type="item_info.current_page_type"
                        :object_type="'dispositions'"
                        :keys_list="disposition_list_keys_list"
                        :get-line-class-using="getLineDispositionClass">
                    </po-lines>
                </template>
            </NewCustomGenericBlock>
        </div>
    </div>
</template>
<script>
import AssetInfoSection from "@/views/assetflow/assets/assetForm/AssetInfoSection";
import PoLines from "./PoLines";
import {DispositionKeysList} from "@/views/assetflow/form_data/DispositionKeysList";
import NewCustomGenericBlock from "./AssetTabs/NewCustomGenericBlock";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";

export default {
    name: 'DispositionTab',
    mixins: [AssetTabsShared],
    components: {NewCustomGenericBlock, PoLines, AssetInfoSection},
    props: ['item_info', 'getAssetInfoUsingKey'],
    data(){
        return {
            disposition_list_keys_list: DispositionKeysList,
        }
    },
    created() {
        this.disposition_list_keys_list.total_value.title_func = this.toUserCurrencyValueTitle;
        this.disposition_list_keys_list.total_value.value_func = this.getCurrencyByValueText;
        this.has_disposition_actions_permissions = this.hasPermission('delete_dispositions');
        if (this.has_disposition_actions_permissions){
            this.disposition_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                actions_list: ['delete']
            }
        }
    },
    computed:{
        latest_disposition_info (){
            return this.item_info.hasOwnProperty('dispositions_info') && this.item_info.dispositions_info.length > 0 ? this.item_info.dispositions_info[0] : null;
        }
    },
    methods:{
        getLineDispositionClass(item, line_type){
            let is_current = false;
            if (this.item_info && this.item_info.hasOwnProperty('disposition_id')){
                let disposition_id = this.item_info.disposition_id;
                if (disposition_id){
                    is_current = item.id === disposition_id ? ' is-latest-disposition' : '';
                }
            }

            return 'po-line' + is_current;
        },
    }
}
</script>
