const FirstPath = window.location.pathname.split('/')[1];

export function getSpStorageInfoBlock(item_info, with_actions=true, details_text= null) {
    if (!item_info) {
        if (details_text){
            return '<p class="object-title-detail"><span class="item-header">' + details_text + '</span></p><div>-</div>';
        }
        return '';
    }
    let storage_wrapper_info = item_info.hasOwnProperty('storage_wrapper_info') ? item_info.storage_wrapper_info : null;
    let icon = 'fa fa-th-large';
    if (storage_wrapper_info) {
        icon = storage_wrapper_info.icon;
    }

    let name = '<i class="' + icon + '"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/sp_storages/' + item_info.id + '/view">' + item_info.name + '</a></span>';

    let html = '<p class="object-title-list">' + name + '</p>';

    let storage_status;
    if (details_text){
        html = '<p class="object-title-detail"><span class="item-header">' + details_text + '</span></p>' + html;
    }else{
        switch (item_info.status) {
            case 0:
                storage_status = '<i class="fa fa-circle status-green"></i> Free';
                if (with_actions) {
                    storage_status += ' <a href="#" class="inline-action assign-sp_storages-customer" data-space_id="' + item_info.id + '">Assign Now</a>';
                }
                break;
            case 1:
                let assigned_date = '';
                if (item_info.hasOwnProperty('latest_assignment_info') && item_info.latest_assignment_info) {
                    let latest_assignment_info = item_info.latest_assignment_info;
                    assigned_date = ' on ' + Vue.options.filters.moment(latest_assignment_info.start_date, 'DD/MM/YYYY');
                    if (with_actions){
                        if (item_info.hasOwnProperty('assets_count') && item_info.assets_count === 0){
                            assigned_date += ' <a href="#" class="inline-action remove-sp_storages-customer" data-space_id="' + item_info.id + '">Unassign</a>';
                        }
                    }
                }
                storage_status = '<i class="fa fa-circle status-orange"></i> Assigned' + assigned_date;
                break;
            case 2:
                storage_status = '<i class="fa fa-circle status-grey"></i> Reserved';
                break;
            case 3:
                storage_status = '<i class="fa fa-circle status-red"></i> Archived';
                break;
        }
        html += '<p class="object-title-detail">' + storage_status + '</p>';
    }

    return html;
}
