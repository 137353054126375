const FirstPath = window.location.pathname.split('/')[1];
export const LedgerMetaKeys = {
    name:{
        label: 'Name',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    details:{
        label: 'Details',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    acquired_date:{
        label: 'Date',
        cast_value: function (item, key) {
            if (item.acquired_date){
                return Vue.options.filters.moment(item.acquired_date, 'MMMM Do YYYY');
            }
            return '';
        }
    },
    quantity:{
        label: 'Qty',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    currency:{
        label: 'Currency',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    supplier_ref:{
        label: 'Ref',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    user_id:{
        label: 'Created By',
        cast_value: function (item, key) {
            if (item.user_id){
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + item[key] + '/view">' + Vue.options.filters.titleize(item.user_name) + '</a>';
            }
            return '';
        }
    },
};
