<template>
    <div class="tbody_item col-xs-6"
         :class="key_info.class + (key_info.class.includes('col-sm-') ? '' : ' col-sm-2')">
        <span v-if="key_info.case === 'custom'">{{ key_info.used_func(item[used_key])}}</span>
        <span v-else-if="key_info.case === 'asset_status'" v-html="getAssetStatusFrom(item)"></span>
        <div v-else-if="key_info.case === 'custom_html'"
             class="custom-html-content"
             :class="key_info.hasOwnProperty('required_class') && key_info.required_class ? key_info.required_class : ''"
             v-html="key_info.value_func(item, used_key)"
        ></div>
        <span v-else-if="key_info.case === 'custom_badges_list'">
            <div v-for="list_item in item[used_key]" class="mt-1">
                <a :href="'./'+ key_info.url_item_type + '/' + list_item[key_info.id_value]+'/view'"
                   class="badge badge-soft-primary font-size-11">
                    {{ list_item[key_info.name_value] | titleize }}
                </a>
            </div>
        </span>
        <span v-else-if="key_info.case === 'custom_slot'">
            <slot :name="key_info.slot_name" v-bind:index="index"
                  v-bind:item="item"></slot>
        </span>
        <span v-else-if="key_info.case === 'custom_tooltip'" v-b-tooltip:hover.bottom
              :title="key_info.title_func(index, item, used_key)">{{ key_info.value_func(index, item, used_key) }}</span>
        <span v-else-if="key_info.case === 'custom_html_tooltip'" v-b-tooltip:hover.bottom
              :title="key_info.title_func(index, item, used_key)" v-html="key_info.value_func(index, item, used_key)"></span>
        <span v-else-if="key_info.case === 'use_index'">{{key_info.with_index_func(index, item, used_key)}}</span>
        <div v-else-if="key_info.key === 'actions'" class="mt-info-label">
            <div class="dropdown">
                <button type="button" class="btn btn-none dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <b-dropdown-item
                        v-if="!['acquisitions'].includes(object_type) && permissions_list['view_' + permission_name]"
                        :href="'/' + current_page_type + '/'+object_type+'/' + item.id + '/view'">
                        View
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="!['acquisitions', 'dispositions', 'purchaseOrders', 'payments'].includes(object_type) && permissions_list['edit_' + permission_name]"
                        :href="'/' + current_page_type + '/'+object_type+'/' + item.id + '/edit'">
                        Edit
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="!['dispositions'].includes(object_type) &&permissions_list['delete_' + permission_name]"
                        variant="danger"
                        @click="performAction(item, 'delete'+object_type, $event)">
                        Delete
                    </b-dropdown-item>
                </div>
            </div>
        </div>
        <span v-else>{{item[used_key]}}</span>
    </div>
</template>
<script>
export default {
    name: 'PresentPoLineKeyInfo',
    props: {
        current_page_type: {},
        index: {},
        item: {},
        used_key: {},
        key_info: {},
        object_type: {},
        performAction: {},
        permission_name: {},
        permissions_list: {}
    }
}
</script>
