<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem;$emit('on-modal-cancel');"
             @show="fillSelectedItem"
             hide-footer>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <b-form-group
                                v-for="(required_field, key) in required_section.required_items"
                                :key="key"
                                :label="required_field.label"
                                :label-for="required_field.key"
                                class="col-sm-12"
                            >
                                <multiselect
                                    v-if="required_field.type === 'single-select'"
                                    v-model="addForm[required_field.key]"
                                    track-by="id"
                                    label="name"
                                    :placeholder="'Select ' + required_field.label"
                                    class="mySingleSelect"
                                    :options="required_field.options"
                                    :custom-label="required_field.custom_label || CustomMSLabel"
                                    :allow-empty="false"
                                    deselect-label=""
                                >
                                </multiselect>

                                <b-form-input
                                    v-else-if="required_field.type === 'text'"
                                    v-model="addForm[required_field.key]"
                                    type="text"
                                    :id="required_field.key"
                                    :placeholder="required_field.label"
                                    autocomplete="none"
                                ></b-form-input>

                                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                                    <div class="common--input-filed">
                                        <textarea v-model="addForm[required_field.key]" :id="required_field.key" :placeholder="required_field.label"></textarea>
                                    </div>
                                </div>

                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_storage_wrapperResource = new Resource('api/assetflow/sp_storage_wrappers');
import multiSelect from '@/mixins/MultiSelect';
import AFButton from "@/components/AFButton";

export default {
    mixins: [multiSelect],
    name: "AddNewSpStorageWrapper",
    props: ['modal_title', 'item_type', 'sp_storage_wrappers_options'],
    components: {AFButton},
    data(){
        return {
            addForm:{
                name: '',
                singular_name: '',
                type: null,
                naming: null,
                icon: '',
                default_storage_utilisation: null,
                single_item: null
            },
            required_sections:{
                storage_wrapper_setup: {
                    section_name: 'Storage Wrapper Setup',
                    required_items:{
                        name: {
                            key: 'name',
                            label: 'Name (Plural)',
                            type: 'text'
                        },
                        singular_name: {
                            key: 'singular_name',
                            label: 'Name (Singular)',
                            type: 'text'
                        },
                        type: {
                            key: 'type',
                            label: 'Type',
                            type: 'single-select',
                            options:[
                                {
                                    id: 0,
                                    name: 'Physical - Reusable'
                                },
                                {
                                    id: 1,
                                    name: 'Virtual - Single Use'
                                },
                            ]
                        },
                        naming: {
                            key: 'naming',
                            label: 'Naming',
                            type: 'single-select',
                            options:[
                                {
                                    id: 0,
                                    name: 'User Defined'
                                },
                                {
                                    id: 1,
                                    name: 'Auto ID'
                                },
                            ]
                        },
                        icon: {
                            key: 'icon',
                            label: 'Icon',
                            type: 'text'
                        },
                        default_storage_utilisation: {
                            key: 'default_storage_utilisation',
                            label: 'Default Storage Utilisation',
                            type: 'single-select',
                            options:[
                                {
                                    id: 100,
                                    name: 'Full'
                                },
                                {
                                    id: 75,
                                    name: '75'
                                },
                                {
                                    id: 50,
                                    name: '50'
                                },
                                {
                                    id: 25,
                                    name: '25'
                                },
                                {
                                    id: 0,
                                    name: 'Empty'
                                },
                            ]
                        },
                        single_item: {
                            key: 'single_item',
                            label: 'Single Item',
                            type: 'single-select',
                            options:[
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                }
                            ]
                        },
                    },
                }
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
    },
    methods:{

        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            this.is_saving_enabled = true;
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.singular_name = this.selected_item.singular_name;
                this.addForm.icon = this.selected_item.icon;
                this.addForm.type = this.getObjectOf(this.selected_item.type, this.required_sections.storage_wrapper_setup.required_items.type.options);
                this.addForm.naming = this.getObjectOf(this.selected_item.naming, this.required_sections.storage_wrapper_setup.required_items.naming.options);
                this.addForm.default_storage_utilisation = this.getObjectOf(this.selected_item.default_storage_utilisation, this.required_sections.storage_wrapper_setup.required_items.default_storage_utilisation.options);
                this.addForm.single_item = this.getObjectOf(this.selected_item.single_item, this.required_sections.storage_wrapper_setup.required_items.single_item.options);
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.addForm.naming = this.required_sections.storage_wrapper_setup.required_items.naming.options[1];
                this.addForm.default_storage_utilisation = this.required_sections.storage_wrapper_setup.required_items.default_storage_utilisation.options[4];
                this.addForm.single_item = this.required_sections.storage_wrapper_setup.required_items.single_item.options[1];
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            let params = {
                name: this.addForm.name,
                singular_name: this.addForm.singular_name,
                icon: this.addForm.icon,
                type: this.addForm.type ? this.addForm.type.id : null,
                naming: this.addForm.naming ? this.addForm.naming.id : 1,
                default_storage_utilisation: this.addForm.default_storage_utilisation ? this.addForm.default_storage_utilisation.id : 1,
                single_item: this.addForm.single_item ? this.addForm.single_item.id : 1,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_storage_wrapperResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>


