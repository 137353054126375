<template>
    <Layout>
        <PageHeader :title="title" :items="items" />


        <b-alert v-if="this.warning !== ''"
                 :show="dismissCountDown"
                 dismissible
                 variant="danger"
                 @dismissed="dismissCountDown=0"
                 @dismiss-count-down="countDownChanged"
                 class="mt-3">{{this.warning.replace(/['"]+/g, '')}}</b-alert>



        <div class="row">
            <div class="col-12">

                <!-- Multi step form -->
                <section class="multi_step_form">
                    <form id="msform" class="text-left">
                        <!-- Tittle -->
                        <div class="tittle">
                            <h2>New Signup Wizard</h2>
                        </div>
                        <!-- login_wizard_progressbar -->
                        <ul id="login_wizard_progressbar" class="text-center">
                            <li class="active">My Profile</li>
                            <li>My Roles</li>
                            <li>Welcome</li>
                        </ul>

                        <fieldset class="login_wizard_fieldset">
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-center">
                                        <h3 v-if="user_info" class="mt-0">Welcome {{user_info.name}}</h3>
                                        <p class="w-75 mb-2 mx-auto">
                                            For a better exprerience, kindly set your preferences here.
                                        </p>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-3">
                                                <div v-for="(item, index) in name_items">
                                                    <b-form-group class="mb-3 has-error" :description="item.description" :label="item.label" :label-for="item.name">
                                                        <b-form-input
                                                            :id="item.name"
                                                            :name="item.name"
                                                            v-model="addForm[item.name]"
                                                            type="text"
                                                            :placeholder="'Enter ' + item.label"
                                                            :class="{ 'is-invalid': item.is_invalid }"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            <span >This value is required.</span>
                                                        </div>
                                                    </b-form-group>
                                                </div>


                                                <div v-for="(item, index) in this.preferences_items">
                                                    <b-form-group class="mb-3 has-error" :description="item.description" :label="item.label" :label-for="item.name">
                                                        <b-form-select :id="item.name"
                                                                       :ref="item.name"
                                                                       v-on:change="checkSelectedItem($event, item.name)"
                                                                       v-model="addForm.meta[item.name]"
                                                                       :options="item.list_name"
                                                                       :class="{ 'is-invalid': item.is_invalid }"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            <span >This value is required.</span>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mt-3">
                                                <div class="form-group">
                                                    <label for="avatar">Avatar</label>
                                                    <div class="col-sm-6">
                                                        <vue-dropzone id="avatar" ref="myVueDropzone" :use-custom-slot="true"
                                                                      :options="dropzoneOptions"
                                                                      @vdropzone-file-added="fileAdded"
                                                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                                                        >
                                                            <div class="dropzone-custom-content">
                                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </vue-dropzone>
                                                        <b-button
                                                            @click="$refs.myVueDropzone.$el.click();"
                                                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                                        >Select New Avatar</b-button>
                                                        <b-button
                                                            @click="addForm.avatar = null;$refs.myVueDropzone.removeAllFiles();"
                                                            variant="outline-danger"
                                                            class="btn btn-sm mt-1 btn-block"
                                                        >Remove Selected</b-button>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px; width: 100%; margin-top: 8px;">
                                                            <p><strong>Avatar Dimensions</strong></p>
                                                            <p>Avatar's will be cropped to become square and should be a minimum size of 100px x 100px</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>

                            <div class="row text-center">
                                <div class="col-12">
                                    <button type="button" class="btn af-btn-accent af-accent submitForm"><i class="fas fa-check mr-1"></i>Save Changes</button>
                                    <button type="button" class="btn btn-success next should-validate">Next <i class="fas fa-arrow-right ml-1"></i></button>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="login_wizard_fieldset">
                            <CurrentUserRoleList title="My Roles"/>

                            <div class="row text-center">
                                <div class="col-12">
                                    <button type="button" class="btn btn-secondary previous"><i class="fas fa-arrow-left mr-1"></i> Back</button>
                                    <a href="/af_dashboard" class="btn af-btn-accent af-accent"><i class="fa fa-home mr-1"></i> Visit Dashboard</a>
                                    <button type="button" class="btn btn-success next should-validate">Next <i class="fas fa-arrow-right ml-1"></i></button>
                                </div>
                            </div>

                        </fieldset>
                        <fieldset class="login_wizard_fieldset">
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-center">
                                        <h2 class="mt-0">
                                            <i class="mdi mdi-check-all"></i>
                                        </h2>
                                        <h3 class="mt-0">Thank you!</h3>

                                        <p class="w-75 mb-2 mx-auto">
                                            Currencies, Dates and Times will use your chosen preferences.
                                        </p>

                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <div class="row text-center">
                                <div class="col-12">
                                    <button type="button" class="btn btn-secondary previous"><i class="fas fa-arrow-left mr-1"></i> Back</button>
                                    <a href="/af_dashboard" class="btn af-btn-accent af-accent"><i class="fa fa-home mr-1"></i> Visit Dashboard</a>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </section>

            </div>
        </div>
    </Layout>
</template>



<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

import {Currencies} from "../assetflow/form_data/Currencies";
import vue2Dropzone from "vue2-dropzone";
import {DropZoneOptions} from "../assetflow/form_data/DropZoneOptions";
import CurrentUserRoleList from "./CurrentUserRoleList";

export default {
        props: [],
        name: "FirstLoginWizard",
        components: {
            CurrentUserRoleList,
            Layout,
            PageHeader,
            vueDropzone: vue2Dropzone
        },
        data() {
            return {
                warning: "",
                alreadySaved: false,
                dismissSecs: process.env.MIX_DISMISS_ALERTS_AFTER,
                dismissCountDown: process.env.MIX_DISMISS_ALERTS_AFTER,
                title: "User Preferences",
                items: [
                    {
                        text: "User Profile",
                        href: "/profile"
                    },
                    {
                        text: "User Preferences",
                        active: true
                    }
                ],
                user_info: {},
                name_items: {
                    first_name: {
                        label: "First Name",
                        name: "first_name",
                        description: "Set your First Name.",
                        is_invalid: false
                    },
                    last_name: {
                        label: "Last Name",
                        name: "last_name",
                        description: "Set your Last Name.",
                        is_invalid: false
                    },
                },
                preferences_items: {
                    // timezone: {
                    //     label: "Timezone",
                    //     name: "timezone",
                    //     list_name: Timezones,
                    //     description: "Set your preferred Timezone.",
                    //     is_invalid: false
                    // },
                    // currency: {
                    //     label: "Currency",
                    //     name: "currency",
                    //     list_name: Currencies,
                    //     description: "Set your preferred Currency.",
                    //     is_invalid: false
                    // },
                    // date_format: {
                    //     label: "Date Format",
                    //     name: "date_format",
                    //     list_name: DateFormats,
                    //     description: "Set your preferred Date Format.",
                    //     is_invalid: false
                    // }
                },
                addForm:{
                    first_name: '',
                    last_name: '',
                    avatar: null,
                    meta: {
                        // date_format: 'dd\\mm\\yyyy', //'', //
                        // timezone: 'gmt', // '', //
                        // currency: '', // 'gbp'
                    }
                },
                isSendingRequest: false,
                dropzoneOptions: DropZoneOptions,

            };
        },
        created() {

            this.warning = window.AF_WARNING;
            this.user_info = window.USER_INFO ? window.USER_INFO : this.user_info;



            this.addForm.first_name = this.user_info.first_name;
            this.addForm.last_name = this.user_info.last_name;
            let user_meta = this.user_info.hasOwnProperty('user_meta') ? this.user_info.user_meta : {};
            if (user_meta && Object.keys(user_meta).length > 0){
                for (const userMetaKey in this.addForm.meta) {
                    if (user_meta.hasOwnProperty(userMetaKey)){
                        let options_list = this.preferences_items[userMetaKey].list_name;
                        let found_object = this.getObjectOf(user_meta[userMetaKey], options_list, 'value');
                        if(found_object){
                            this.addForm.meta[userMetaKey] = found_object.value;
                        }
                    }
                }
            }
            this.presentOlderAvatar();
        },
        mounted(){
            /*Function Calls*/
            this.verificationForm();
        },
        methods: {
            //* Form js
            verificationForm(){
                //jQuery time
                let current_fs, next_fs, previous_fs, animating;
                let strongThis = this;

                $(".next").click(function () {
                    if (animating) return false;
                    animating = true;

                    if ($(this).hasClass('should-validate')){
                        let isValid = strongThis.validateObject();
                        if (isValid){
                            if (!strongThis.alreadySaved){
                                strongThis.onComplete();
                            }
                        }else{
                            return;
                        }
                    }

                    current_fs = $(this).parents('.login_wizard_fieldset');
                    next_fs = $(this).parents('.login_wizard_fieldset').next();

                    //activate next step on login_wizard_progressbar using the index of next_fs
                    $("#login_wizard_progressbar li").eq($(".login_wizard_fieldset").index(next_fs)).addClass("active");

                    //show the next fieldset
                    next_fs.show();


                    current_fs.hide();
                    animating = false;

                });

                $(".previous").click(function () {
                    if (animating) return false;
                    animating = true;

                    current_fs = $(this).parents('.login_wizard_fieldset');
                    previous_fs = $(this).parents('.login_wizard_fieldset').prev();

                    //de-activate current step on login_wizard_progressbar
                    $("#login_wizard_progressbar li").eq($(".login_wizard_fieldset").index(current_fs)).removeClass("active");

                    //show the previous fieldset
                    previous_fs.show();

                    current_fs.hide();
                    animating = false;

                });

                $(".submitForm").click(function () {
                    let isValid = strongThis.validateObject();
                    if (isValid){
                        strongThis.onComplete();
                    }
                })
            },
            presentOlderAvatar(){
                let item_info = this.user_info;
                if (item_info.avatar){
                    var file = { size: "", name: item_info.first_name + ' ' + item_info.last_name, type: "image/png" };
                    var url = item_info.avatar;

                    this.$nextTick(() => {
                        if (url){
                            this.$refs.myVueDropzone.manuallyAddFile(file, url);
                        }
                    })
                }
            },
            checkSelectedItem(obj, item_name){
                this.preferences_items[item_name].is_invalid = this.preferences_items[item_name].name === '';
            },
            validateObject() {
                // validate all items aren't empty
                var isValid = true;
                var obj = this.addForm.meta;
                for (var key in obj) {
                    this.preferences_items[key].is_invalid = (obj[key] === "");
                    if (isValid === true){
                        isValid = obj[key] !== "";
                    }
                }

                let is_first_name_invalid = this.addForm.first_name === '';
                this.name_items.first_name.is_invalid = is_first_name_invalid;
                isValid = isValid && !is_first_name_invalid;

                let is_last_name_invalid = this.addForm.last_name === '';
                this.name_items.last_name.is_invalid = is_last_name_invalid;
                isValid = isValid && !is_last_name_invalid;

                return isValid;
            },
            onComplete(){
                // should save and redirect to /

                if (!this.isSendingRequest){
                    this.isSendingRequest = true;
                    axios({
                        method:'post',
                        url:'/api/assetflow/users/save_meta',
                        baseURL: '/',
                        data: {
                            meta: this.addForm.meta,
                            avatar: this.addForm.avatar,
                            first_name: this.addForm.first_name,
                            last_name: this.addForm.last_name,
                        },
                    }).then(response => {
                        this.isSendingRequest = false;
                        this.alreadySaved = true;
                        this.presentToast("Success!",
                            'Saved Changes Successfully',
                            'success',
                            3000,
                            true,
                            true);
                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            fileAdded(file) {
                if (file.hasOwnProperty('manuallyAdded') && file.manuallyAdded){
                    this.addForm.avatar = 'older_avatar';
                    // this.addForm.avatar = file;
                }else{
                    this.addForm.avatar = file;
                    this.saveFileToS3(file, null, 'avatar', 'user');
                }
            },
            maxFilesExceeded(file){
                this.$refs.myVueDropzone.removeAllFiles();
                this.$refs.myVueDropzone.addFile(file);
            },
        },
    }
</script>

<style>
#avatar .dz-preview .dz-progress {
    opacity: 0 !important;
}

.vue-dropzone > .dz-preview .dz-image {
    height: 160px !important;
    width: 160px !important;
}

.vue-dropzone .dz-preview .dz-details .dz-size {
    display: none
}
</style>
