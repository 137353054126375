<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <template #modal-title>
            <div v-html="current_modal_title"></div>
        </template>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFields from "../extras/ChangeCommonFields";

export default {
    name: "ChangeStorageUtilisation",
    props: ['modal_id', 'modal_title', 'item_type', 'sp_storages_options'],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                storage_utilisation: null,
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Update Storage Usage',
                    required_items:{
                        storage_utilisation: {
                            key: 'storage_utilisation',
                            label: 'Storage Usage',
                            type: 'single-select',
                            options:[
                                {
                                    id: 100,
                                    name: 'Full'
                                },
                                {
                                    id: 75,
                                    name: '75'
                                },
                                {
                                    id: 50,
                                    name: '50'
                                },
                                {
                                    id: 25,
                                    name: '25'
                                },
                                {
                                    id: 0,
                                    name: 'Empty'
                                }
                            ]
                        }
                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    computed:{
        current_modal_title(){
            return this.selected_item && this.selected_item.hasOwnProperty('name') ? ( (this.selected_item.hasOwnProperty('storage_wrapper_info') ? ('<i class="' + this.selected_item.storage_wrapper_info.icon +'"></i> ') : '')+this.selected_item.name + '') : this.modal_title;
        }
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.storage_utilisation = this.getObjectOf(this.selected_item.storage_utilisation, this.required_sections.storage_setup.required_items.storage_utilisation.options);
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                storage_utilisation: this.addForm.storage_utilisation ? this.addForm.storage_utilisation.id : null,
                action: 'change_storage_utilisation'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-utilisation-change', this.modal_id, new_item, ['storage_utilisation']);
        }
    }
}
</script>


