<script>
/**
 * Forgot Password component
 */
export default {
    data() {
        return {
            isResetError: false
        };
    },
    mounted() {
        this.isResetError = !!this.error;
    },
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        email: {
            type: String,
            required: false,
            default: () => null
        },
        error: {
            type: String,
            required: false,
            default: () => null
        }
    }
};
</script>

<template>
    <div class="p-2">
        <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{error}}</b-alert>
        <form :action="submitUrl" method="POST">
            <slot />
            <div class="form-group">
                <label for="useremail">Email</label>
                <input type="email" name="email" :value="email" class="form-control" id="useremail" placeholder="Enter email" />
            </div>
            <b-form-group id="password-group" label="Password" label-for="password">
                <b-form-input
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Confirm Password" label-for="password-confirm">
                <b-form-input
                    id="password-confirm"
                    name="password_confirmation"
                    type="password"
                    placeholder="Confirm password"
                ></b-form-input>
            </b-form-group>
            <div class="form-group row mb-0">
                <div class="col-12 text-right">
                    <button class="btn btn-primary w-md" type="submit">Reset</button>
                </div>
            </div>
        </form>
    </div>
</template>
