<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    placeholder="Select Status"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const receiptResource = new Resource('api/assetflow/receipts');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewReceipt",
    props: ['modal_title', 'item_type', 'receipts_options'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                receipt: '',
                description: '',
                category: '',
                type: '',
            },
            required_items:{
                receipt: {
                    key: 'receipt',
                    label: 'Receipt',
                    type: 'text'
                },
                category: {
                    key: 'category',
                    label: 'Category',
                    type: 'single-select',
                    options:[
                        {
                            id: 'bank',
                            name: 'Bank'
                        },
                        {
                            id: 'sales',
                            name: 'Sales'
                        },
                        {
                            id: '',
                            name: 'Other'
                        },
                    ]
                },
                type: {
                    key: 'type',
                    label: 'Type',
                    type: 'single-select',
                    options:[
                        {
                            id: '',
                            name: 'None'
                        },
                        {
                            id: 'debit',
                            name: 'Debit'
                        },
                        {
                            id: 'credit',
                            name: 'Credit'
                        },
                    ]
                },
                description: {
                    key: 'description',
                    label: 'Description',
                    type: 'textarea'
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.receipt = this.selected_item.receipt;
                this.addForm.description = this.selected_item.description;

               this.addForm.category = this.getObjectOf(this.selected_item.category, this.required_items.category.options);
               this.addForm.type = this.getObjectOf(this.selected_item.type, this.required_items.type.options);
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                receipt: this.addForm.receipt,
                description: this.addForm.description,
                category: this.addForm.category ? this.addForm.category.id : '',
                type: this.addForm.type ? this.addForm.type.id : '',
                short_form: 1
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await receiptResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


