<template>
    <div class="row mt-3">
        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left col-divider">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Date</strong>
                </div>
                <div class="assets-detail" v-if="item_details.hasOwnProperty('acquisition_date') && item_details.acquisition_date">{{ item_details.acquisition_date }}</div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Supplier</strong>
                </div>
                <div class="assets-detail" v-if="item_details.hasOwnProperty('supplier_id') && item_details.supplier_id">
                    <a :href="'/' + item_type + '/suppliers/' + item_details.supplier_id.id + '/view'">{{ item_details.supplier_id.name }}</a>
                </div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>

        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Ledger</strong>
                </div>
                <div class="assets-detail" v-if="item_details.hasOwnProperty('ledger_id') && item_details.ledger_id">
                    <a :href="'/' + item_type + '/ledgers/' + item_details.ledger_id.id + '/view'">{{ item_details.ledger_id.description }}</a>
                </div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>

        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Net</strong>
                </div>
                <div class="assets-detail">{{ line_items.reduce((a, b) => +a + +b.net_total, 0) }}</div>
            </div>
        </div>

        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>VAT</strong>
                </div>
                <div class="assets-detail">{{ line_items.reduce((a, b) => +a + +b.tax, 0) }}</div>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center text-xl-right col-divider last-child">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Total</strong>
                </div>
                <div class="assets-detail">{{ line_items.reduce((a, b) => +a + +b.total_price, 0) }}</div>
            </div>
        </div>


    </div>
</template>
<script>
export default {
    name: 'LineItemTotalsBlocks',
    props: ['line_items', 'item_details', 'item_type']
}
</script>
