<template>
    <Layout>

        <div class="row" v-if="item_type === 'sp'">
            <div class="col-lg-12">
                <div class="text-sm-right">
                    <template>
                        <span>
                            <b-button v-b-modal.users-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign User</b-button>
                        </span>
                        <b-modal id="users-modal"
                                 ref="assign-user-modal"
                                 title="Assign User"
                                 title-class="font-18"
                                 hide-footer>
                            <AddNotifiedUser
                                ref="assign-to-notified-user"
                                :item_info="item_info"
                                :item_type="item_type"
                                :item_id="item_id"
                                @refresh-users-list="fetchItemInfo();$refs['assign-user-modal'].hide();"
                            />
                        </b-modal>
                    </template>
                </div>
            </div>
        </div>

        <div class="row" v-if="isLoadingItem">
            <div class="col-12">
                <div class="text-center">
                    <b-spinner class="text-af-accent mr-1"></b-spinner>
                    <small>Loading...</small>
                </div>
            </div>
        </div>
        <EntitySubscribersList v-else-if="item_info" :item_info="item_info" @refresh-users-list="fetchItemInfo();"/>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import EntitySubscribersList from "../../items/widgets/EntitySubscribersList";
import AddNotifiedUser from "./AddNotifiedUser";

export default {
    name: "NotifiedUserList",
    props: ['item_id', 'other_item_id', 'item_type'],
    components: {
        AddNotifiedUser,
        EntitySubscribersList,
        Layout
    },
    data(){
        return {
            isLoadingItem: true,
            item_info: null,
        }
    },
    created() {
        this.fetchItemInfo();
    },
    methods:{
        fetchItemInfo(){
            if (this.item_id && ['assetflow', 'sp']){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/service_providers/getSubscribers',
                    baseURL: '/',
                }).then(response => {
                    if (response.data.data.status){
                        this.item_info = response.data.data.items;
                    }
                    this.isLoadingItem = false;
                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        },
    }
}
</script>
