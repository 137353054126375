<template>

    <b-modal id="add-order-asset-modal"
             size="lg"
             :title="'Add Asset To {' + (item_info ? item_info.name : '') + '}'"
             title-class="font-18"
             hide-footer>

        <OrderLineFormFields
            ref="order-line-fields"
            :received_order_type="item_info.order_type"
            :item_type="item_info.current_page_type"
            :received_parent_order="item_info"
            :with_asset_details="with_asset_details"
            :has_cancel_button="true"
            @after-saving-is-done="afterSaving"
            @on-cancel-clicked="$bvModal.hide('add-order-asset-modal')"
        />

    </b-modal>

</template>

<script>
import AssetActions from "@/mixins/assetActions.mixin";
import OrderLineFormFields from "./OrderLineFormFields";

export default {
    components: {OrderLineFormFields},
    mixins: [AssetActions],
    name: "AddOrderAssetModal",
    props:['item_type', 'item_info', 'with_refresh', 'with_asset_details'],
    data(){
        return {}
    },
    methods:{
        afterSaving(new_item){
            this.$bvModal.hide('add-order-asset-modal');
            this.$emit('on-new-item-adding', new_item);
        }
    }

}
</script>

