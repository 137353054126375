<template>
    <div v-if="dashboard_info">

        <div class="row">
            <div class="col-sm-12 col-lg-4 col-xl-4">
                <div class="cm_common_table_box">
                    <div class="cm_common_table_heading af-primary">
                        <h4><i class="fa fa-circle"></i> {{ item_name }} Statuses</h4>
                    </div>
                    <div class="cm_ordering_table_view cm_order_overview table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Total Count</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody v-if="dashboard_info.hasOwnProperty('totals_by_status') && dashboard_info.totals_by_status">
                                <tr>
                                    <td>
                                        <p><i class="fa fa-circle customer-status-icon"></i> <a href="#" @click="viewDashboardItem('All','status', null, $event)">All</a></p>
                                    </td>
                                    <td>
                                        <p>{{ dashboard_info.items_count }}</p>
                                    </td>
                                    <td>
                                        <p><a href="#" @click="viewDashboardItem('All','status', null, $event)">View</a></p>
                                    </td>
                                </tr>
                                <tr
                                    v-for="status_info in dashboard_info.totals_by_status" v-if="status_info.status !== ''">
                                    <td>
                                        <p><i class="fa fa-circle" :class="status_info.status_icon || 'status-green'"></i> <a href="#" @click="viewDashboardItem(status_info.status, status_info.status_key, status_info.status_value, $event)">{{ status_info.status | titleize }}</a></p>
                                    </td>
                                    <td>
                                        <p>{{ status_info.count }}</p>
                                    </td>
                                    <td>
                                        <p><a href="#" @click="viewDashboardItem(status_info.status, status_info.status_key, status_info.status_value, $event)">View</a></p>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td>
                                        <p><i class="fa fa-circle customer-status-icon"></i> <a href="#" @click="viewDashboardItem('All','status', null, $event)">All</a></p>
                                    </td>
                                    <td>
                                        <p>{{ dashboard_info.items_count }}</p>
                                    </td>
                                    <td>
                                        <p><a href="#" @click="viewDashboardItem('All','status', null, $event)">View</a></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else class="products text-center">
        <b-spinner class="text-af-accent m-2"></b-spinner>
        <h4>Fetching Dashboard...</h4>
    </div>
</template>
<script>
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";

export default {
    name: 'ListItemsDashboardTab',
    mixins: [TotalsAndCurrencies],
    props: ['dashboard_info', 'item_name'],
    computed:{
        all_html_content(){
            if (this.dashboard_info && this.dashboard_info.hasOwnProperty('items_count')){
                return '<td><p><i class="fa fa-circle customer-status-icon"></i> <a href="#">All</a></p></td>' +
                    '<td><p>' + this.dashboard_info.items_count +'</p></td>' +
                    '<td><p><a href="#">View</a></p></td>';
            }
            return '';
        }
    },
    methods:{
        viewDashboardItem(status_text, status_key, status_value, event){
            event.preventDefault()
            this.$emit('on-dashboard-clicked', status_text, status_key, status_value)
        }
    }
}
</script>
