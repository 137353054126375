<template>
    <Layout>
        <main>
            <move-assets-modal
                ref="change-addresses-modal"
                item_type="addresses"
                modal_title="Move All Address Assets"
                list_title="Destination Location"
                button_title="Move Assets"
                :selected_item="selected_folder"
                :options_list="all_folders"
                caution_title="I understand this bulk action will update all the selected items and cannot be undone"
                @on-modal-save="moveAddressContent"
            >
            </move-assets-modal>

            <move-assets-modal
                ref="change-address_locations-modal"
                item_type="address_locations"
                modal_title="Change Location"
                list_title="Destination Location"
                button_title="Move Assets"
                :selected_item="selected_folder"
                :options_list="all_folders"
                @on-modal-save="moveAddressContent"
            >
            </move-assets-modal>


            <add-new-modal
                ref="add-new-address"
                item_type='address'
                modal_title="Create New Address"
                @on-new-item-adding="addressLocationChange"
            ></add-new-modal>

            <add-new-modal
                ref="add-new-location"
                item_type='location'
                modal_title="Create New Location"
                @on-new-item-adding="addressLocationChange"
            ></add-new-modal>


            <b-modal id="create-tag-modal"
                     ref="create-new-tag"
                     :title="(required_action === 'edit-tag' ? 'Update' : 'Create') + ' Tag'"
                     title-class="font-18"
                     hide-footer>

                <b-form-group label="Tag Name" label-for="tag_name">
                    <b-form-input
                        v-model="folder_name"
                        type="text"
                        id="tag_name"
                        placeholder="Tag Name..."
                        class="form-control"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Tag Description" label-for="tag_description">
                    <b-form-textarea
                        v-model="folder_description"
                        id="tag_description"
                        placeholder="Tag Description..."
                        class="form-control">
                    </b-form-textarea>
                </b-form-group>

                <div class="text-right">
                    <b-button type="submit"
                              variant="success"
                              v-on:click="addTag($event)">
                        {{ required_action === 'edit-tag' ? 'Update' : 'Create' }}</b-button>
                    <b-button variant="outline-danger" @click="$bvModal.hide('create-tag-modal')">Cancel</b-button>
                </div>
            </b-modal>

            <b-modal id="change-tag-modal"
                     ref="change-new-tag"
                     title="Change Tag"
                     title-class="font-18"
                     hide-footer>

                <b-form-group label="Destination Tag" label-for="destination_tags_list">
                    <b-form-select
                        id="destination_tags_list"
                        name="destination_tags_list"
                        v-model="selected_destination_folder"
                        :options="tags_list"
                    >
                    </b-form-select>
                </b-form-group>

                <div class="text-right">
                    <b-button type="submit"
                              variant="success"
                              v-on:click="performChangeTag">
                        Move Assets</b-button>
                    <b-button variant="outline-danger" @click="$bvModal.hide('change-tag-modal')">Cancel</b-button>
                </div>
            </b-modal>

            <b-modal id="create-folder-modal"
                     ref="create-new-folder"
                     :title="(required_action === 'edit-folder' ? 'Update' : 'Create') + ' Folder'"
                     title-class="font-18"
                     hide-footer>

                <b-form-group label="Parent Folder" label-for="folders_list">
                    <b-form-select
                        id="folders_list"
                        name="folders_list"
                        v-model="selected_folder"
                        :options="folders_list"
                    >
                    </b-form-select>
                </b-form-group>

                <b-form-group label="Folder Name" label-for="folder_name">
                    <b-form-input
                        v-model="folder_name"
                        type="text"
                        id="folder_name"
                        placeholder="Folder Name..."
                        class="form-control"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Folder Description" label-for="folder_description">
                    <b-form-textarea
                        v-model="folder_description"
                        id="folder_description"
                        placeholder="Folder Description..."
                        class="form-control">
                    </b-form-textarea>
                </b-form-group>

                <div class="text-right">
                    <b-button type="submit"
                              variant="success"
                              v-on:click="addFolder($event)">
                        {{ required_action === 'edit-folder' ? 'Update' : 'Create' }}</b-button>
                    <b-button variant="outline-danger" @click="$bvModal.hide('create-folder-modal')">Cancel</b-button>
                </div>
            </b-modal>

            <b-modal id="change-folder-modal"
                     ref="change-new-folder"
                     title="Change Folder"
                     title-class="font-18"
                     hide-footer>

                <b-form-group label="Destination Folder" label-for="destination_folders_list">
                    <b-form-select
                        id="destination_folders_list"
                        name="destination_folders_list"
                        v-model="selected_destination_folder"
                        :options="folders_list"
                    >
                    </b-form-select>
                </b-form-group>

                <div class="text-right">
                    <b-button type="submit"
                              variant="success"
                              v-on:click="performChangeFolder">
                        Move Assets</b-button>
                    <b-button variant="outline-danger" @click="$bvModal.hide('change-folder-modal')">Cancel</b-button>
                </div>
            </b-modal>


            <div class="" id="wrapper">

                <folders-sidebar v-if="!is_listing_sidebar"
                    :sidebar_list="treeList"
                    :filter_type="this.current_groupBy"
                    :item_type="item_type"
                    @on-group-changing="getList"
                    @on-filter-changing="getList"
                    @on-folder-creation="createFolder"
                    @on-tag-creation="createTag"
                    @on-tag-editing="editTag"
                    @on-add-location="addLocation"
                    @on-untag-assets="untagAssignAssets"
                    @on-folder-moving="moveFolder"
                    @on-folder-editing="editFolder"
                    @on-folder-deletion="deleteItem"
                    @on-content-moving="moveContent"
                >
                </folders-sidebar>
                <div v-else class="sidebar text-center" id="sidebar-wrapper">
                    <div class="folder-wrapper">
                        <div class="loading-spinner text-center">
                            <div class="center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                            </div>
                            Loading...
                            <br />
                            <small>Please wait.</small>
                        </div>
                    </div>
                </div>


                <page-content
                    ref="my-page-content"
                    :shared_info_string="shared_info_string"
                    :items_list="itemsList"
                    :item_type="item_type"
                    :request_meta="request_meta"
                    :breadcrumb_items="breadcrumb_items"
                    :groupBy="current_groupBy"
                    :selected_item="selected_item"
                    :tags_list="tags_list"
                    @on-folder-creation="createFolder"
                    @on-tag-creation="createTag"
                    @on-updating-tags="getTagsList"
                    @on-change-folder="changeFolder"
                    @on-item-deleted="getList"></page-content>

            </div>
        </main>
    </Layout>
</template>

<script>
import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

import UserMeta from "@/mixins/usermeta.mixin";
import Global from "@/mixins/global.mixin";
import AssetActions from "@/mixins/assetActions.mixin";


import FileManager from "./FileManager";
import { StatusesList } from "./Extras/StatusesList";
import FoldersSidebar from "./subItems/FoldersSidebar";
import PageContent from "./subItems/PageContent";
import AddNewModal from "../customers/extras/AddNewModal";
import MoveAssetsModal from "../customers/extras/MoveAssetsModal";

const assetResource = new Resource('api/assetflow/assets');
const folderResource = new Resource('api/assetflow/folders');
const categoryResource = new Resource('api/assetflow/categories');
const addressResource = new Resource('api/assetflow/address_locations');
const tagsResource = new Resource('api/assetflow/tags');
const usersResource = new Resource('api/assetflow/users');
const supplierResource = new Resource('api/assetflow/suppliers');


String.prototype.repeat = String.prototype.repeat || function(n){
    n = n || 1;
    return Array(n+1).join(this);
}

/**
 * Advanced table component
 */
export default {
    name: "AssetList",
    mixins: [UserMeta, Global, AssetActions],
    props: ['item_id', 'item_type', 'shared_info_string'],
    components: {MoveAssetsModal, AddNewModal, PageContent, FoldersSidebar, FileManager, Layout, PageHeader },
    data() {
        return {
            selectedLayoutIndex: null,
            title: "Assets",
            items: [
                {
                    text: "Assets",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            total:0,
            request_meta:null,
            treeList: null,
            itemsList:null,
            current_groupBy: 1,
            current_groupBy_title: 'Folders',
            is_listing_sidebar: false,
            selected_folder: null,
            required_method: null,
            folder_name: null,
            folder_description: null,
            all_folders: [],
            flat_result: [],
            quantities_list: [],
            folders_list: [],
            locations_list: [],
            tags_list: [],
            folders_list_copy: [],
            selected_destination_folder: null,
            selected_folder_destination_folder: null,
            selected_asset: null,
            required_action: null,
            selected_item: null,
            breadcrumb_items: [
                {
                    text: window.AF_NAME,
                    href: "/"
                }
            ],
            customer_item: {
                id:0,
                value: 0,
                text: window.AF_NAME,
                name: window.AF_NAME,
            },
            groupBy_titles:{
                '1': 'Folders',
                '2': 'Status',
                '3': 'Categories',
                '4': 'Tags',
                '5': 'Users',
                '6': 'Addresses',
                '7': 'Suppliers'
            }
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            this.$refs['my-page-content'].setSearchKeyword(queryVars["s"]);
        }
    },
    created() {
        document.body.classList.add('is_assets');

        this.getTagsList(true);
        this.getFoldersList('addresses', true);
        this.setGroupByOption();

        this.selectedLayoutIndex = 2;

        var preferredLayout = window.USER_INFO.user_meta.preferred_assets_layout;
        if (preferredLayout && /^\d+$/.test(preferredLayout)){
            this.selectedLayoutIndex = parseInt(preferredLayout);
        }

    },
    methods: {
        addressLocationChange(new_item){
            // if (new_item){
            //     // should select this? >>
            //     console.log("new_item");
            //     console.log(new_item);
            //
            // }
            this.handleListingAddresses(false);
        },
        refreshList(item_id, required_type='tags'){
            if (required_type === 'tags'){
                this.getTagsList();
            }else if (['addresses', 'address_locations'].includes(required_type)){
                this.handleListingAddresses(false);
            }
            this.delete_after_untag = false;
        },
        addFolder(){
            if (this.required_action === 'edit-folder'){
                let message = "Edited Folder {"+this.folder_name+"} Successfully";
                this.add_new_item('folders', this.selected_folder, this.folder_name, this.folder_description, message, true, this.selected_asset.id);
                this.$bvModal.hide('change-folder-modal');
            }else{
                let message = "Add a New Folder {"+this.folder_name+"} Successfully";
                this.add_new_item('folders', this.selected_folder, this.folder_name, this.folder_description, message, true);
                this.$bvModal.hide('change-folder-modal');
            }

        },
        async deleteItem(item_id='', item_type='folders', event){

            if (item_id !== ''){
                var index, required_item_name;
                if (item_type === 'folders'){
                    index = this.folders_list.map(function(e) { return e.value; }).indexOf(item_id);
                    required_item_name = this.folders_list[index].text;
                }else if (item_type === 'tags'){
                    index = this.treeList.map(function(e) { return e.id; }).indexOf(item_id);
                    required_item_name = this.treeList[index].name;
                }else if (item_type === 'addresses'){
                    index = 1;
                    required_item_name = item_id.name;
                    item_type = (item_id.address_id ? 'address_locations' : 'addresses')

                    // dont move this from here vv
                    item_id = item_id.id;
                }

                if (index > -1){
                    this.deleteItemByID(item_id, required_item_name, item_type, event)
                        .then((response) => {
                            if (item_type === 'folders'){
                                this.getFoldersList();
                            }else if (['address_locations', 'addresses'].includes(item_type)){
                                this.handleListingAddresses(false);
                            }else{
                                this.getTagsList();
                            }
                        });
                }else{
                    this.presentToast("Error!",
                        'Something went wrong!',
                        'danger',
                        3000,
                        true,
                        true);
                }

            }
        },
        untagAssignAssets(item='', required_type='tags', item_name='tag', event){
            if (required_type === 'tags'){
                this.header_message = 'Untag Assets of ';
                this.confirm_message = 'Are you sure you want to Un-tag Assets of ';
                this.item_name = 'Tag';
                this.untagAssets(item,'tags');
            }else{
                this.header_message = 'Un-Assign Assets of ';
                this.confirm_message = 'Are you sure you want to Un-Assign Assets of ';
                this.item_name = 'Location';
                this.untagAssets(item,'address_locations');
            }
        },
        addLocation(address){
            this.$refs['add-new-location'].setSelectedItem(null);
            this.$refs['add-new-location'].setAddressID(address.id);
            this.$bvModal.show('create-location');
        },
        editTag(tag, item_type='tags'){

            if (item_type === 'addresses'){
                let type = 'address';
                if (tag.address_id){
                    type = 'location';
                    this.$refs['add-new-location'].setAddressID(tag.address_id);
                    this.$refs['add-new-location'].setSelectedItem(tag);
                }else{
                    this.$refs['add-new-address'].setSelectedItem(tag);
                    this.setupAddressLookup();
                }

                this.$bvModal.show('create-'+type);

                return;
            }

            if (tag){
                this.required_action = 'edit-tag';
                this.selected_asset = tag;
                this.folder_name = tag.name;
                this.folder_description = tag.description;
                this.$bvModal.show('create-tag-modal');
            }
        },
        editFolder(folder=null){
            if (folder){
                this.required_action = 'edit-folder';

                if (folder.parent_folder_id !== ''){
                    let index = this.folders_list.map(function(e) { return e.value; }).indexOf(folder.parent_folder_id);
                    if (this.folders_list[index]){
                        this.selected_folder = this.folders_list[index].value;
                    }
                }else{
                    this.selected_folder = this.folders_list[0].value;
                }
                this.selected_asset = folder;
                this.folder_name = folder.name;
                this.folder_description = folder.description;

                this.$bvModal.show('create-folder-modal');
            }
        },
        moveAddressContent(type, item, destination_item, accepted_caution = false){
            if (accepted_caution){
                let message = "Moved Content Of "+type+" {"+item.name+"} Successfully";
                this.move_content_of(type, item.id, destination_item, message)

                this.$bvModal.hide('change-'+type+'-modal')
                this.handleListingAddresses(false);
                this.required_action = null;
            }else{
                this.$refs['change-'+type+'-modal'].showCautionError(true);
            }

        },
        performChangeTag(){
            if (this.required_action === 'moveContent'){
                let message = "Moved Content Of Tag {"+this.selected_asset.name+"} Successfully";
                this.move_content_of('tags', this.selected_asset.id, this.selected_destination_folder, message, true)
            }
            this.$bvModal.hide('change-tag-modal');

            this.required_action = null;
        },
        performChangeFolder(){
            let name = Vue.options.filters.titleizeToSingle(this.change_folder_item_type);

            if (this.required_action === 'move'){
                let message = "Changed Folder of "+name+" {"+this.selected_asset.name+"} Successfully";
                let key = this.change_folder_item_type === 'folders' ? 'parent_folder_id' : 'folder_id';
                this.set_item_value(this.change_folder_item_type, this.selected_asset.id, key, this.selected_destination_folder, message, true);
            }else if (this.required_action === 'moveContent'){
                let message = "Moved Content Of Folder {"+this.selected_asset.name+"} Successfully";
                this.move_content_of(this.change_folder_item_type, this.selected_asset.id, this.selected_destination_folder, message, true)
            }

            this.$bvModal.hide('change-folder-modal');

            this.required_action = null;
        },
        moveContent(folder=null, item_type='folders'){

            this.required_action = 'moveContent';
            this.change_folder_item_type = item_type;
            this.selected_asset = folder;
            this.selected_destination_folder = folder.id ? folder.id : null;
            this.selected_folder = folder.id;
            if (item_type === 'folders'){
                this.$bvModal.show('change-folder-modal');
            }else if(item_type === 'tags'){
                this.$bvModal.show('change-tag-modal');
            }else{
                // TODO:// SHOULD show caution
                this.$refs['change-'+item_type+'-modal'].set_item(folder);
                this.$refs['change-'+item_type+'-modal'].setGroupedOptions();
                this.$bvModal.show('change-'+item_type+'-modal');
            }
        },
        changeFolder(asset=null){
            this.required_action = 'move';
            this.change_folder_item_type = 'assets';
            this.selected_asset = asset;
            this.selected_destination_folder = asset.parent_folder_id ? asset.parent_folder_id : null;
            this.selected_folder = asset.parent_folder_id;
            this.$bvModal.show('change-folder-modal');
        },
        moveFolder(folder=null){
            this.required_action = 'move';
            this.change_folder_item_type = 'folders';
            this.selected_asset = folder;
            this.selected_destination_folder = folder.parent_folder_id ? folder.parent_folder_id : null;
            this.selected_folder = folder.id;
            this.$bvModal.show('change-folder-modal');
        },
        addTag(){
            if (this.required_action === 'edit-tag'){
                let message = "Edited Tag {"+this.folder_name+"} Successfully";
                this.add_new_item('tags', 0, this.folder_name, this.folder_description, message, true, this.selected_asset.id);
            }else{
                let message = "Add a New Tag {"+this.folder_name+"} Successfully";
                this.add_new_item('tags', 0, this.folder_name, this.folder_description, message, true);
            }
            this.$bvModal.hide('create-tag-modal');
        },
        createTag(){
            this.required_action = 'create-tag';
            this.$bvModal.show('create-tag-modal');
        },
        createFolder(parent_id=''){
            this.required_action = 'create-subfolder';
            if (parent_id !== ''){
                let index = this.folders_list.map(function(e) { return e.value; }).indexOf(parent_id);
                if (this.folders_list[index]){
                    this.selected_folder = this.folders_list[index].value;
                }
            }else{
                this.selected_folder = this.folders_list[0].value;
            }

            this.$bvModal.show('create-folder-modal');
        },
        handleListingFolders() {
            this.current_groupBy = 1;
            this.current_groupBy_title = this.groupBy_titles["1"];
            this.is_listing_sidebar = true;
            this.getFoldersList();
        },
        handleListingStatuses() {
            this.current_groupBy = 2;
            this.current_groupBy_title = this.groupBy_titles['2'];
            this.is_listing_sidebar = true;
            this.getStatusList();
        },
        handleListingCategories() {
            this.current_groupBy = 3;
            this.current_groupBy_title = this.groupBy_titles['3'];
            this.is_listing_sidebar = true;
            this.getFoldersList('categories');
        },
        handleListingUsers(){
            this.current_groupBy = 5;
            this.current_groupBy_title = this.groupBy_titles['5'];
            this.is_listing_sidebar = true;
            this.getUsersList();
        },
        handleListingAddresses(is_listing_sidebar=true){
            this.current_groupBy = 6;
            this.current_groupBy_title = this.groupBy_titles['6'];
            this.is_listing_sidebar = is_listing_sidebar;
            this.getFoldersList('addresses');
        },
        handleListingTags() {
            this.current_groupBy = 4;
            this.current_groupBy_title = this.groupBy_titles['4'];
            this.is_listing_sidebar = true;
            this.getTagsList();
        },
        handleListingSuppliers(){
            this.current_groupBy = 7;
            this.current_groupBy_title = this.groupBy_titles['7'];
            this.is_listing_sidebar = true;
            this.getFoldersList('suppliers');
        },
        handleSelectedFilter() {
            switch (parseInt(this.current_groupBy)) {
                case 1:
                    this.handleListingFolders();
                    break;
                case 2:
                    this.handleListingStatuses();
                    break;
                case 3:
                    this.handleListingCategories();
                    break;
                case 4:
                    this.handleListingTags();
                    break;
                case 5:
                    this.handleListingUsers();
                    break;
                case 6:
                    this.handleListingAddresses();
                    break;
                case 7:
                    this.handleListingSuppliers();
                    break;
                default:
                    this.handleListingFolders();
                    break;
            }
        },
        setGroupByOption(){
            let queryVars = this.getQueryVars();


            if ("filter" in queryVars && queryVars["filter"] !== '') {
                let filtering_option = queryVars["filter"];
                switch (filtering_option){
                    case "status":
                        this.handleListingStatuses();
                        break;
                    default:
                        this.handleListingFolders();
                        break;
                }
            }else{
                let saved_filter_type = localStorage.getItem('filter_type');
                if (saved_filter_type){
                    this.current_groupBy = saved_filter_type;
                    this.current_groupBy_title = this.groupBy_titles[saved_filter_type + ''];
                }
                this.handleSelectedFilter();
            }
        },
        async getUsersList(){
            this.listLoading = true;

            let queryList = {};
            if (this.item_type === 'customer'){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }

            const { data } = await usersResource.list(queryList);

            if (Object.keys(data).includes('data')){
                this.treeList = data.data.items;

                this.selected_item = this.customer_item;
                this.getList();

                this.is_listing_sidebar = false;
            }else{
                console.log('Response Error');
            }
        },
        async getTagsList(just_fetch=false){
            if (!just_fetch){
                this.listLoading = true;
            }

            let queryList = {};
            if (this.item_type === 'customer'){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }

            const { data } = await tagsResource.list(queryList);

            if (Object.keys(data).includes('data')){
                if (just_fetch){
                    let my_tags_list = data.data.items;
                    this.$refs['my-page-content'].set_tags_list(my_tags_list);
                }else{
                    this.treeList = data.data.items;
                    this.selected_item = this.customer_item;
                    this.getList();

                    this.tags_list = this.treeList.map(function(e) { return {value: e.id, text: e.name}; })
                    this.is_listing_sidebar = false;
                }

            }else{
                console.log('Response Error');
            }
        },
        getStatusList(){
            this.treeList = StatusesList;
            this.is_listing_sidebar = false;
            this.getList();
        },
        getFolderObject(folders, level = 1, item_type='folders'){
            var allFoldersObjects = [];
            if (folders.length){
                for (let i = 0; i < folders.length; i++) {
                    let folder = folders[i];

                    let folderObject = {
                        value: folder.id,
                        text: (('-'.repeat(level)) + ' ' + folder.name)
                    }

                    allFoldersObjects.push(folderObject);
                    let hierarchy = null;

                    if (item_type === 'addresses'){
                        if (folder.subfolders){
                            hierarchy = folder.subfolders;
                        }
                    }else{
                        if (folder.hierarchy){
                            hierarchy = folder.hierarchy;
                        }
                    }

                    if (hierarchy){
                        allFoldersObjects = allFoldersObjects.concat(this.getFolderObject(hierarchy, level + 1, item_type));
                    }
                }
            }
            return allFoldersObjects;
        },
        fixItemQuantities (items) {
            var allItemObjects = [];
            if (items.length){
                for (let i = 0; i < items.length; i++) {
                    let item = items[i];
                    item.assets_sum_quantity = this.quantities_list[item.id];
                    if (item.subfolders) {
                        item.subfolders = this.fixItemQuantities(item.subfolders);
                    }
                    allItemObjects.push(item);
                }
            }
            return allItemObjects;
        },

        async getFoldersList(item_type='folders', just_fetch=false){
            this.listLoading = true;

            let queryList = {};
            if (this.item_type === 'customer' && ['folders', 'addresses'].includes(item_type)){
                queryList.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
            var requiredResource;
            var customer_folder = [
                this.customer_item
            ];
            if (item_type === 'folders'){
                requiredResource = folderResource;
            }else if (item_type === 'categories'){
                requiredResource = categoryResource;
            }else if (item_type === 'addresses'){
                requiredResource = addressResource;
            }else if (item_type === 'suppliers'){
                requiredResource = supplierResource;
            }else{
                return;
            }
            const { data } = await requiredResource.list(queryList);

            if (Object.keys(data).includes('data')){
                if (just_fetch){
                    let addresses_list = data.data.items.map(function(e) { return {value: e.id, text: e.name}; });
                    this.$refs['add-new-location'].set_addresses_list(addresses_list);
                }else{
                    this.treeList = data.data.items;
                    this.all_folders = data.all_folders.items;

                    this.folders_list = customer_folder.concat(this.getFolderObject(data.all_folders.items, 1, item_type));


                    this.selected_item = this.folders_list[0];
                    this.getList(this.selected_item);

                    this.folders_list_copy = this.folders_list;

                    this.flat_result = data.flat_result.items;
                    if (this.current_groupBy === 1){
                        for (const key in this.flat_result) {
                            let folder = this.flat_result[key];
                            this.quantities_list[folder.id] = folder.assets_sum_quantity;
                        }
                        this.treeList = this.fixItemQuantities(this.treeList);
                    }

                    this.is_listing_sidebar = false;
                }

            }else{
                console.log('Response Error');
            }

        },
        async getList(selected_item="", changing_type='group') {

            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            this.listLoading = true;

            var required_query = {
                page: 1,
                limit: 100,
            };

            this.handleQueryItems(required_query);
            if (selected_item !== ''){
                // this can help reducing no. of requests
                // if (this.selected_item === selected_item){ return; }

                if (changing_type === 'filter'){
                    this.current_groupBy = selected_item.value;
                    this.current_groupBy_title = selected_item.name;
                    localStorage.setItem('filter_type', this.current_groupBy);
                    this.handleSelectedFilter();
                }else{
                    this.selected_item = selected_item;
                    switch (this.current_groupBy){
                        case 1:
                            // console.log(selected_item.id);
                            // console.log(selected_item.name);
                            required_query.folder_id = selected_item.id;
                            break;
                        case 2:
                            // console.log(selected_item.name);
                            if (selected_item.id !== 0){
                                required_query.status_name = selected_item.name;
                            }
                            break;
                        case 3:
                            // console.log(selected_item.id);
                            required_query.category_id = selected_item.id;
                            break;
                        case 4:
                            // console.log(selected_item.id);
                            required_query.tag_id = selected_item.id;
                            break;
                        case 5:
                            // console.log(selected_item.id);
                            required_query.user_id = selected_item.id;
                            break;
                        case 6:
                            // console.log(selected_item.id);
                            var location_ids;
                            if (selected_item.address_id){
                                location_ids = selected_item.id;
                            }else{
                                location_ids = selected_item.location_ids
                            }
                            required_query.location_ids = location_ids;
                            break;
                        case 7:
                            // console.log(selected_item.id);

                            var po_ids;
                            if (!selected_item.address_id){
                                po_ids = selected_item.id;
                            }else{
                                po_ids = selected_item.purchaseOrder_ids;
                            }

                            required_query.po_ids = po_ids;
                            break;
                        default:
                            break;
                    }
                }
            }else{
                console.log("selected_item is Empty");
            }

            const { data } = await assetResource.list(required_query);
            if (Object.keys(data).includes('data')){
                this.itemsList = data.data.items;
                this.total = data.meta.total;
                this.request_meta = data.meta;
            }else{
                console.log('Response Error');
            }
            this.listLoading = false;
        },
        handleQueryItems(query) {
            if (this.item_type === 'bss'){
                query.bss_status = true;
            }else{
                if (this.item_type === 'sp'){
                    query.warehouse_id = this.item_id !== '' ? this.item_id : "0";
                }
                if (this.item_type === 'customer') {
                    query.customer_id = this.item_id !== '' ? this.item_id : "0";
                }
            }
        },

        setSelectedLayout(layoutIndex){
            this.selectedLayoutIndex = layoutIndex;
            let message = "Saved New Layout {"+this.layoutOptions[layoutIndex].title+"} Successfully";
            this.set_usermeta('preferred_assets_layout', layoutIndex, message);
        },
        get_parents_info(item, item_type='folders'){

            let parent_item_id = item_type === 'folders' ? item.parent_folder_id : item.parent_category_id,
                current_item_parent_ids = [];

            let index = this.flat_result.map(function(e) { return e.id; }).indexOf(parent_item_id);

            if (index === -1){
                return [];
            }

            let parent_item = this.flat_result[index];

            current_item_parent_ids.push({
                text: parent_item.name,
                href: '/'+item_type+'/' + parent_item.id + '/view'
            });
            current_item_parent_ids = this.get_parents_info(parent_item).concat(current_item_parent_ids);

            return current_item_parent_ids;
        },
        get_subfolder_ids(folder){

            let c_subfolders = folder.hierarchy,
                current_folder_subfolder_ids = [];

            if(c_subfolders.length > 0){
                for (let i = 0; i < c_subfolders.length; i++) {
                    current_folder_subfolder_ids.push(c_subfolders[i].id);
                    current_folder_subfolder_ids = current_folder_subfolder_ids.concat(this.get_subfolder_ids(c_subfolders[i]));
                }
            }
            return current_folder_subfolder_ids;
        }
    },
    watch: {
        selected_item: {
            deep: true,
            handler: function(newValue, oldValue){
                if ([1,3].includes(this.current_groupBy)){
                    var new_breadcrumb_items = this.breadcrumb_items;
                    let item_type = this.current_groupBy === 1 ? 'folders' : 'categories';

                    let index = this.flat_result.map(function(e) { return e.id; }).indexOf(this.selected_item.id);
                    if (index !== -1){
                        var current_folder_info = this.flat_result[index];

                        if (current_folder_info){
                            let current_folder_text =
                                {
                                    text: current_folder_info.name,
                                    href: '/'+item_type+'/' + current_folder_info.id + '/view'
                                };

                            new_breadcrumb_items = this.breadcrumb_items.concat(this.get_parents_info(current_folder_info, item_type));
                            new_breadcrumb_items.push(current_folder_text);
                        }
                        this.$refs['my-page-content'].set_breadcrumb_items(new_breadcrumb_items);
                    }else{
                        this.$refs['my-page-content'].set_breadcrumb_items(this.breadcrumb_items);
                    }
                }else if ([2,4,5, 6].includes(this.current_groupBy)){ // TODO: // handle groups/children

                    let required_id = (this.current_groupBy === 6 && this.selected_item.address_id) ? this.selected_item.address_id :  this.selected_item.id;

                    let index = this.treeList.map(function(e) { return e.id; }).indexOf(required_id);

                    var status_info = this.treeList[index];

                    var selected_status;
                    var new_breadcrumb_items = this.breadcrumb_items;
                    if (status_info){
                        let status_name = Vue.options.filters.titleize(status_info.name);
                        selected_status = [
                            {
                                text: status_name,
                                active: true
                            }
                        ];
                        new_breadcrumb_items = new_breadcrumb_items.concat(selected_status);
                    }

                    this.$refs['my-page-content'].set_breadcrumb_items(new_breadcrumb_items);
                }else{
                    this.$refs['my-page-content'].set_breadcrumb_items(this.breadcrumb_items);
                }

            }
        },
        selected_folder: {
            deep: true,
            handler: function(newValue, oldValue){

                if (['addresses', 'address_locations'].includes(this.change_folder_item_type)){

                }else{

                    let index = this.flat_result.map(function(e) { return e.id; }).indexOf(this.selected_folder);
                    if (index !== -1){
                        var current_folder_info = this.flat_result[index];

                        if (this.required_action !== 'create-subfolder'){
                            var subfolder_ids = this.get_subfolder_ids(current_folder_info);
                            subfolder_ids = [current_folder_info.id].concat(subfolder_ids);

                            this.folders_list = this.folders_list_copy.filter(function(e) {
                                return !subfolder_ids.includes(e.value);
                            });
                        }
                    }
                }

            }
        }
    }
};
</script>


<style>

.is_assets .page-content {
    padding: 70px 0px 60px 0px !important;
}
</style>
