<template>
    <b-modal :id="modal_id"
             :title="item_info && item_info.hasOwnProperty('name') ? ('Return {' + item_info.name + '}') : 'Return'"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             @show="fillAssetImages"
             hide-footer>

        <div class="common-step-panel use-section">
            <div class="row">

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :received_toggle="section_group.minimized">

                        <div class="step-common-info-right-block" v-if="key === 'image_upload_section'">
                            <div class="st-drop-file-block">
                                <div class="st-drop-file-box">
                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="dropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-removed-file="fileRemoved"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <div class="st-upload-title">
                                                <h3>Drop files here or click to upload</h3>
                                                <p>... or click to select a file from your computer</p>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone[0].$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New Image</b-button>
                                </div>
                            </div>
                        </div>

                        <StepCommonInfoBlock
                            v-else
                            :add-form="addForm"
                            :block_key="key"
                            :item_info="item_info"
                            :changed-radio-value="changedRadioValue"
                            :mark-as-checked="markAsChecked"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :my_lists_values="lists_values"
                            :without_asset_passport_name="true"
                        />
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-asset-acquisition' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="item_info && item_info.hasOwnProperty('name') ? ('Return {' + item_info.name + '}') : 'Return'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>

    </b-modal>

</template>
<script>

import AFButton from "@/components/AFButton";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetInfoSection from "./AssetInfoSection";
import StepCommonInfoBlock from "../../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";
export default {

    components: {StepCommonInfoBlock, AssetInfoSection, AFButton},
    mixins: [dropzoneMixin],
    name: 'ReturnAssetModal',
    props: ['received_item_info', 'modal_id', 'item_type', 'is_list'],
    data(){
        return {
            with_used_file: true,
            item_info: null,
            addForm: {
                asset_meta: {},
                item_details: {},
                asset_images: [],
                filled_asset_images: [],
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-6',
                    fields_list: {
                        name_section: {
                            name: 'Details',
                            minimized: false,
                            fields: [
                                'return_date',
                                'return_reference',
                                'ledger_id',
                                'recipient_info',
                                'tracking_info',
                                'disposition_notes'
                            ]
                        },
                        cost_section: {
                            name: 'Quantity & Costs',
                            minimized: false,
                            fields: [
                                'quantity',
                                'total_price',
                                'unit_price',
                                'net_total',
                                'unit_net',
                                'tax_rate',
                                'tax',
                                'unit_tax',
                            ]
                        },
                    }
                },
                right_column: {
                    title: '',
                    column_class: 'col-xl-6',
                    fields_list: {
                        image_upload_section: {
                            name: 'Images',
                            minimized: false,
                            fields: {}
                        },
                    }
                },
            },
            required_fields: {
                return_date: {
                    name: 'Return Date',
                    type: 'date',
                    has_permission: true,
                    default_value: new Date()
                },
                return_reference: {
                    name: 'Reference',
                    type: 'text',
                    has_permission: true
                },
                recipient_info:{
                    name: 'recipient_info',
                    type: 'recipient_info',
                    has_permission: true,
                    options: [],
                    default: ''
                },
                tracking_info:{
                    name: 'Tracking Info',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                disposition_notes:{
                    name: 'Notes',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                ledger_id: {
                    name: 'Ledger',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                quantity: {
                    is_readonly: true,
                    name: 'Quantity',
                    type: 'number',
                    default_value: 1,
                    has_permission: true
                },
                total_price: {
                    name: '1 Unit',
                    main_title: 'Totals With Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                unit_price: {
                    name: 'Unit Price',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                net_total: {
                    name: '1 Unit',
                    main_title: 'Totals without Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_net: {
                    name: 'Unit Net',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                tax_rate: {
                    name: 'Tax Rate',
                    main_title: 'Tax',
                    type: 'number',
                    default_value: 0.2,
                    has_permission: true
                },
                tax: {
                    name: '1 Unit',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_tax: {
                    name: 'Unit Tax',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
            }
        }
    },
    created() {
        this.item_info = this.received_item_info;

        this.getFieldsList();
        this.setDefaultValues();
    },
    methods:{

        changedRadioValue(field, event) {

        },
        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },
        set_item(item){
            this.item_info = item;
            this.setDefaultValues();
        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;
            let required_select_lists =  {
                recipient_info: 'recipients_list',
                ledger_id: 'ledgers_short_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                if (form_lists){
                    for (const list_key in required_select_lists) {
                        let select_name = required_select_lists[list_key];
                        this.lists_values[list_key] = form_lists[select_name];
                    }
                    for (let key in this.lists_values) {
                        this.getOptionsForKey(key, 'assets');
                    }
                }

            }).catch(error => {
                console.log(error);
            });
        },
        setDefaultValues() {
            let addForm = this.addForm;
            addForm.asset_images = [];
            addForm.filled_asset_images = [];
            _.forOwn(this.required_fields, function (field, key) {
                if (field.hasOwnProperty('default_value')) {
                    if (key === 'return_date'){
                        const today = new Date();
                        const yyyy = today.getFullYear();
                        let mm = today.getMonth() + 1; // Months start at 0!
                        let dd = today.getDate();

                        if (dd < 10) dd = '0' + dd;
                        if (mm < 10) mm = '0' + mm;

                        addForm.item_details[key] = dd + '-' + mm + '-' + yyyy;
                        addForm.item_details = Object.assign({}, addForm.item_details);
                    }else {
                        if (!addForm.item_details.hasOwnProperty(key)){
                            addForm.item_details[key] = field.default_value;
                        }
                    }
                }
            });

            if (this.item_info){
                if (this.item_info.hasOwnProperty('supplier_id') && this.item_info.supplier_id){
                    addForm.item_details.recipient_info = {
                        id: this.item_info.supplier_id,
                        name: this.item_info.supplier_info ? this.item_info.supplier_info.name : '-',
                        item_type: 'suppliers',
                        id_item_type: this.item_info.supplier_id + '-suppliers'
                    }
                }

                if (this.item_info.hasOwnProperty('quantity')){

                    addForm.item_details.ledger_id = this.getObjectOf(this.item_info.ledger_id, this.required_fields.ledger_id.options);

                    addForm.item_details.quantity = this.item_info.quantity;
                    addForm.item_details.unit_price = this.item_info.value;
                    addForm.item_details.total_price = this.item_info.total_value;

                    addForm.item_details.unit_net = this.item_info.net_unit;
                    addForm.item_details.net_total = this.item_info.net_total;

                    addForm.item_details.tax_rate = this.item_info.tax_rate;
                    addForm.item_details.unit_tax = this.item_info.unit_tax;

                    addForm.item_details.tax = this.item_info.tax;
                }
            }

            this.addForm = Object.assign({}, addForm);
        },
        fillAssetImages() {
            if (this.addForm.asset_images.length > 0) {
                this.addForm.filled_asset_images = [];
                this.$nextTick(() => {
                    for (const assetImagesKey in this.addForm.asset_images) {
                        let asset_image = this.addForm.asset_images[assetImagesKey];

                        var file = {
                            id: asset_image.id,
                            s3_uuid: asset_image.s3_uuid,
                            size: asset_image.image_name_size,
                            name: asset_image.name,
                            description: asset_image.description,
                            type: "image/png",
                            upload_progress: 100,
                            is_featured: asset_image.is_featured,
                            add_to_ap: asset_image.add_to_ap
                        };
                        var url = asset_image.image_name;
                        if (this.$refs.myVueDropzone) {
                            this.$refs.myVueDropzone[0].manuallyAddFile(file, url);
                        }
                    }
                });
            }
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        getValueFrom(property_key){
            return this.addForm.item_details.hasOwnProperty(property_key) ? this.addForm.item_details[property_key] : null;
        },
        async addItem(event){
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (!all_files){
                return;
            }

            if (all_files.length > 0){
                let asset_images_copy = [... all_files];
                all_files = asset_images_copy.map(function (image) {
                    let new_image = {
                        item_type: image.item_type,
                        uuid: image.uuid,
                        is_featured: image.is_featured,
                        add_to_ap: image.add_to_ap,
                        image_title: image.image_title,
                        image_description: image.image_description,
                        width: image.width,
                        height: image.height,
                        key: image.key,
                        name: image.name,
                    }
                    if (image.hasOwnProperty('id')){
                        new_image.id = image.id;
                    }
                    return new_image;
                });
            }

            this.isLoadingItem = true;

            let disposition_info = {
                disposition_date: this.getValueFrom('return_date'),
                reference_info: this.getValueFrom('return_reference'),
                movement_info: this.getValueFrom('tracking_info'),
                disposition_details: this.getValueFrom('disposition_notes'),
                ledger_id: this.addForm.item_details.hasOwnProperty('ledger_id') && this.addForm.item_details.ledger_id ? this.addForm.item_details.ledger_id.id : null,
                disposition_type: 4,
                currency: this.item_info.hasOwnProperty('used_currency') ? this.item_info.used_currency : 'gbp',
                disposition_images: all_files,

                total_price: this.getValueFrom('total_price'), // / quantity unit_price
                net_total: this.getValueFrom('net_total'), // / quantity unit_net
                tax_rate: this.getValueFrom('tax_rate'),
                tax: this.getValueFrom('tax'), // / quantity unit_tax
            }
            let recipient_info = this.getValueFrom('recipient_info');
            if (recipient_info){
                disposition_info.recipient_type = recipient_info.item_type;
                disposition_info.recipient_id = recipient_info.id;
            }

            let asset_id = this.item_info.id;
            let params = {
                asset_id: asset_id,
                item_details: {
                    disposition_info: disposition_info
                }
            };

            axios({
                method: 'put',
                url: '/api/assetflow/assets/' + asset_id,
                baseURL: '/',
                data: params,
            }).then(response => {
                this.isLoadingItem = false;
                if (response.data.data.status === true) {
                    let added_item = response.data.data.items;
                    let message;

                    if (added_item && added_item.hasOwnProperty('id')){
                        let asset_name = ', ASS-' + added_item.uid + ' {' + added_item.name + '}';
                        message = 'Done' + asset_name;
                        if (asset_id !== '') {
                            message = 'Updated Images Successfully' + asset_name;
                        }


                        let status = response.data.data.status;
                        this.presentToast("Success!",
                            message,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);

                        let required_keys = [
                            'asset_images',
                            'disposition_info',
                            'status'
                        ]
                        this.$emit('on-cost-updates', this.modal_id, added_item, required_keys);
                    }else{
                        console.log(added_item);
                    }

                } else {
                    console.log('Something went wrong');
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

    }
}
</script>
