<template>
    <div :class="used_classes">

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <AddNewChange
            ref="add-new-change"
            item_type='changes'
            modal_title="Create New Change"
            :with_item_details="false"
            @on-new-item-adding="reloadPage"
        >
        </AddNewChange>


        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_list_view_title">
                    <div class="d-flex-p">
                        <div>
                            <span class="button_size acq-status" :class="getChangeStatus(true)">{{getChangeStatus()}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MyBCarouselImages parent_class="cm_product_grid_img mt-4" :my_asset="pc_item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

    </div>
</template>
<script>

import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import AddChangeAssetsModal from "../customers/changes/Modals/AddChangeAssetsModal";
import ChangeActionsList from "./ChangeActionsList";
import AddNewChange from "../customers/extras/AddNewChange";
import MyBCarouselImages from "../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'PartChangeRightBox',
    mixins: [AddChangeAssetsModal, TotalsAndCurrencies, ImageGallery],
    components: {
        MyBCarouselImages,
        AddNewChange,
        ChangeActionsList,
        AddChangeAssetsModal,
        MyBCarouselSlide,
        VueEasyLightbox
    },
    props: {
        pc_item_info: {},
        unique_id: '',
        used_classes:''
    },
    data(){
        return {
            add_assets_modal_id: 'add_assets',
            add_assets_modal_title: 'Add Assets',
        }
    },
    computed:{
    },
    methods:{
        getChangeStatus(just_classes=false){
            let change_info = this.pc_item_info.hasOwnProperty('change_info') && this.pc_item_info.change_info;
            if (change_info){
                if (just_classes){
                    return 'status-btn-' + (change_info.status === 1 ? 'active' : 'planned') +' ' + (change_info.status === 1 ? 'established' : 'planned') + '-change-status';
                }
                return (change_info.status === 1 ? 'Established' : 'Planned');
            }
            return '';
        },
        onFormSubmit(modal_action, pc_item_info, addForm, object_type, entity_type, received_modal_id){
            console.log("modal_action");
            console.log(modal_action);
        },
        performAction(item, action_name, index, event=null) {
            switch (action_name) {
                case 'delete':
                    this.deleteItemByID(item.id, item.name, 'changes', event)
                        .then((response) => {
                            window.location = encodeURI("/" + this.pc_item_info.current_page_type + "/changes");
                        });
                    break;
                case 'edit':
                    let change_info_for_modal = Object.assign({}, item);
                    change_info_for_modal.character_id = item.character_id;
                    change_info_for_modal.part_ids = item.parts_info.map(function(e) { return e.id; });
                    change_info_for_modal.change_status = item.status;
                    change_info_for_modal.number = item.name;

                    this.$refs['add-new-change'].setSelectedItem(change_info_for_modal);
                    this.$bvModal.show('create-changes');
                    break;
            }
        },
    },
    created() {
    },

}
</script>

