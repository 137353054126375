
<script>
    import i18n from "../i18n";
    import simplebar from "simplebar-vue";
    import InputWithDropdown from "./SearchDropdown/InputWithDropdown";
    import UserMeta from "@/mixins/usermeta.mixin";
    import FlashableMessages from "@/mixins/FlashableMessages.mixin";

    import {Currencies} from "@/views/assetflow/form_data/Currencies";
    import {Timezones} from "@/views/assetflow/form_data/Timezones";

    /**
     * Nav-bar Component
     */
    export default {
        mixins: [UserMeta, FlashableMessages],
        data() {
            return {
                item_type: '',
                languages: [
                    {
                        flag: "/images/flags/us.jpg",
                        language: "en",
                        title: "English",
                    },
                    {
                        flag: "/images/flags/french.jpg",
                        language: "fr",
                        title: "French",
                    },
                    {
                        flag: "/images/flags/spain.jpg",
                        language: "es",
                        title: "Spanish",
                    },
                    {
                        flag: "/images/flags/chaina.png",
                        language: "zh",
                        title: "Chinese",
                    },
                    {
                        flag: "/images/flags/arabic.png",
                        language: "ar",
                        title: "Arabic",
                    },
                ],
                lan: i18n.locale,
                text: null,
                flag: null,
                value: null,
                site_name: "",
                af_initials: '',
                main_logo: "/images/logo-dark.png",
                dark_logo: '/images/logo-dark.png',
                light_logo: '/images/logo-light.png',
                dark_logo_svg: '/images/logo.svg',
                light_logo_svg: '/images/logo-light.svg',
                item_name: '',
                user_info: {
                    id: null,
                    name: "AssetFlow",
                    can_switch_customer: false,
                    can_switch_sp: false,
                    can_switch_group: false
                },
                user_meta: {},

                modules_list:[],
                current_currency: {
                    value: 'gbp',
                    text: "GBP",
                    icon: '£'
                },
                currencies: Currencies,
                current_timezone: {
                    value: '+00:00',
                    text: "GMT"
                },
                timezones: Timezones,

            };
        },
        components: { InputWithDropdown, simplebar },
        created() {

            let url_segments = window.location.pathname.split('/');
            if (url_segments.length > 1){
                this.item_type = url_segments[1];
            }


            this.user_info = window.USER_INFO ?? this.user_info;

            if ( this.item_type === '' || this.item_type === 'af_dashboard'){
                let current_entity = this.user_info && this.user_info.hasOwnProperty('current_entity') ? this.user_info.current_entity : null;
                if (current_entity && current_entity.hasOwnProperty('entity_item_type') && current_entity.entity_item_type){
                    this.item_type = current_entity.entity_item_type;
                }
            }

            this.user_meta = this.user_info.user_meta ?? this.user_meta;

            this.site_name = (window.AF_NAME ? window.AF_NAME : (process.env.MIX_SITE_NAME ? process.env.MIX_SITE_NAME : 'Asset Flow'));
            this.af_initials = (window.AF_INITIALS ? window.AF_INITIALS : "");

            this.main_logo = (window.AF_LOGO ? window.AF_LOGO : this.main_logo) + '/inside/180/60';
            this.dark_logo = this.main_logo;
            this.light_logo = this.main_logo;
            this.dark_logo_svg = this.main_logo;
            this.light_logo_svg = this.main_logo;
        },
        mounted() {
            let saved_currency = this.user_meta.currency ?? localStorage.getItem("current_currency");
            let found_currency = this.currencies.find((x) => x.value === saved_currency);
            if (found_currency){
                this.current_currency = found_currency;
            }

            this.modules_list = window.MODULES_LIST ? window.MODULES_LIST : [];
            let saved_module = this.current_module ?? null;
            if (saved_module){
                let found_module = this.modules_list.find((x) => x.id === (saved_module.id));
                if (found_module){
                    this.current_module = found_module;
                }
            }


            let saved_timezone = this.user_meta.timezone ?? localStorage.getItem("current_timezone");
            let found_timezone = this.timezones.find((x) => x.value === saved_timezone);
            if (found_timezone){
                this.current_timezone = found_timezone;
            }

            this.value = this.languages.find((x) => x.language === i18n.locale);
            this.text = this.value.title;
            this.flag = this.value.flag;
        },
        methods: {
            toggleMenu() {
                let use_icons = !this.$parent.isMenuCondensed ? true : 0;
                this.$parent.$refs.rightBar.setUseIcons(use_icons);
            },
            initFullScreen() {
                document.body.classList.toggle("fullscreen-enable");
                if (
                    !document.fullscreenElement &&
                    /* alternative standard method */ !document.mozFullScreenElement &&
                    !document.webkitFullscreenElement
                ) {
                    // current working methods
                    if (document.documentElement.requestFullscreen) {
                        document.documentElement.requestFullscreen();
                    } else if (document.documentElement.mozRequestFullScreen) {
                        document.documentElement.mozRequestFullScreen();
                    } else if (document.documentElement.webkitRequestFullscreen) {
                        document.documentElement.webkitRequestFullscreen(
                            Element.ALLOW_KEYBOARD_INPUT
                        );
                    }
                } else {
                    if (document.cancelFullScreen) {
                        document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
                }
            },
            setLanguage(locale, country, flag) {
                this.lan = locale;
                this.text = country;
                this.flag = flag;
                i18n.locale = locale;
                localStorage.setItem("locale", locale);
            },

            setCurrency(currency) {
                this.current_currency = currency;
                let message = "Saved New Currency {"+currency.text+"} Successfully";
                this.set_usermeta('currency', currency.value, message);

                localStorage.setItem("current_currency", currency.value);
            },
            setModule(module) {
                this.current_module = module;
                let message = "Saved New Module {"+module.name+"} Successfully";
                window.flash(message);
                // // shall redirect?
                // console.log("'shall redirect?'");
                // console.log('shall redirect?');
                // console.log("module");
                // console.log(module);


                this.set_usermeta('current_module', module.id, "", '/');
            },
            setTimezone(timezone) {
                this.current_timezone = timezone;
                let message = "Saved New Timezone {"+timezone.text+"} Successfully";
                this.set_usermeta('timezone', timezone.value, message);
                localStorage.setItem("current_timezone", timezone.value);
            },
            setLogoAndName(name, new_logo){
                this.light_logo = new_logo
                this.item_name = name
            }
        },
        computed:{
            required_item(){
                if (this.item_type === 'customer'){
                    return this.current_customer;
                }else if (this.item_type === 'sp'){
                    return this.current_sp;
                }
                return null;
            }
        }
    };
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="/" class="logo logo-dark">
                        <span
                            class="logo-sm"
                            style="position:absolute; left: 11px; top: 10px" >
                            <div v-if="af_initials !== ''" class="avatar-sm mx-auto mb-4">
                                <span class="avatar-title rounded-circle af-accent text-af-primary font-size-16">{{af_initials}}</span>
                            </div>

                            <div v-else class="avatar-sm mx-auto mb-4">
                                <span class="avatar-title rounded-circle af-accent text-white font-size-16">AF</span>
                            </div>

                        </span>
                        <span v-if="!this.dark_logo.includes('noimage.png')" class="logo-lg">
                            <img :src="this.dark_logo" :alt="this.site_name" />
                        </span>
                        <span v-else class="mx-auto mt-4 logo-lg font-size-20">
                            {{site_name}}
                        </span>
                    </a>

                    <a href="/" class="logo logo-light">
                        <span
                            class="logo-sm"
                            style="position:absolute; left: 11px; top: 10px" >

                            <div v-if="af_initials !== ''" class="avatar-sm mx-auto mb-4">
                                <span class="avatar-title rounded-circle af-accent text-af-primary font-size-16">{{af_initials}}</span>
                            </div>
                            <div v-else class="avatar-sm mx-auto mb-4">
                                <span class="avatar-title rounded-circle af-accent text-white font-size-16">AF</span>
                            </div>
                        </span>
                        <span v-if="!this.dark_logo.includes('noimage.png')" class="logo-lg">
                            <img :src="this.dark_logo" :alt="this.site_name" />
                        </span>
                        <span v-else class="mx-auto mt-4 logo-lg font-size-20">
                            {{site_name}}
                        </span>
                    </a>
                </div>

                <h4 v-if="this.item_name">{{this.item_name}}</h4>

                <button
                    id="vertical-menu-btn"
                    type="button"
                    class="btn btn-sm px-3 font-size-16 header-item"
                    @click="toggleMenu"
                >
                    <i class="fa fa-fw fa-bars"></i>
                </button>

                <!-- App Search-->
                <div class="app-search d-none d-lg-block">
                    <div class="position-relative">
                        <input-with-dropdown></input-with-dropdown>
                    </div>
                </div>


            </div>

            <div class="d-flex" v-if="required_item && required_item.hasOwnProperty('demo_only') && required_item.demo_only">


                <div role="alert" class="customer-alert alert demo-entity-alert alert-danger" style="margin-bottom: 0px; background: rgb(211, 41, 44); color: #fff;">
                    <span class="fa fas fa-exclamation-triangle"></span>
                    <a id="show-btn" href="javascript:void(0);" @click="$bvModal.show('bv-modal-warning')" style="color: #fff!important;">Demo Only. Click for Details.</a>
                </div>

                <b-modal id="bv-modal-warning" size="lg" centered hide-footer>
                    <template #modal-title>
                        <span class="fa fas fa-exclamation-triangle"></span> Demo Only
                    </template>

                    <div class="text-center mb-4">
                        <div class="avatar-md mx-auto mb-4">
                            <div class="avatar-title bg-light rounded-circle h1" style="color: rgb(211, 41, 44) !important;">
                                <span class="fa fas fa-exclamation-triangle"></span>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-xl-10">
                                <h4 class="mb-4" style="color: rgb(211, 41, 44) !important;">Demo Only!</h4>

                                <p class="text-muted font-size-14 mb-4"> Please note: this {{ required_item.entity_type | titleizeToSingle }} ({{ required_item.name }}) is for Demo Purposes Only.</p>

                                <p class="text-muted font-size-14 mb-4"><strong>Any changes you make will be lost.</strong></p>
                                <p class="text-muted font-size-14 mb-4">Please contact your Account Manager if you would like to learn more.</p>

                                <b-button class="demo-ok-button mt-3" style="background: rgb(211, 41, 44) !important; color: #fff;" block @click="$bvModal.hide('bv-modal-warning')">Ok</b-button>
                            </div>
                        </div>
                    </div>

                </b-modal>

            </div>

            <div v-if="current_module_info && current_module_info.hasOwnProperty('custom_header')" class="d-flex hide-mobile header-module-header" v-html="current_module_info.custom_header"></div>

            <div class="d-flex hide-mobile header-orders-block" v-else-if="required_item && required_item.hasOwnProperty('open_orders_count')">
                <div v-if="item_type === 'customer'"
                     class="customer-alert alert "
                     :class="required_item.open_orders_count === 0 ? 'alert-primary' : 'alert-warning'" role="alert" style="margin-bottom:0px;">
                    <span class="fa fa-shopping-cart"></span>
                    <a class="text-dark" v-if="required_item.open_orders_count === 0" :href="'/' + item_type + '/orders/storage_orders'">No orders awaiting confirmation</a>

                    <a class="text-dark" v-else-if="required_item.open_orders_count === 1 && required_item.hasOwnProperty('latest_order')" :href="'/' + item_type + '/orders/' + required_item.latest_order.id + '/view'" ><strong>{{ required_item.latest_order.uid }}</strong> is awaiting confirmation. <strong>View This Order</strong></a>

                    <a class="text-dark" v-else-if="required_item.open_orders_count > 1" :href="'/' + item_type + '/orders/storage_orders'"><strong>{{ required_item.open_orders_count }} Orders</strong> are awaiting confirmation. <strong>View Open Orders</strong></a>
                </div>

                <div v-else-if="item_type === 'sp' && required_item.hasOwnProperty('latest_order') && required_item.latest_order && required_item.latest_order.hasOwnProperty('order_info') && required_item.latest_order.order_info"
                     class="sp-alert alert alert-info" role="alert" style="margin-bottom:0px;">
                    <span class="fa fa-shopping-cart"></span>
                    <a class="text-dark" :href="'/' + item_type + '/orders/' + required_item.latest_order.order_info.id + '/view'">Latest: {{ '(' + required_item.latest_order.order_info.uid + ') ' + getItemPostTitle(required_item.latest_order.order_info, required_item.latest_order.post_info, 'Order') }}</a>
                </div>
            </div>
            <div class="d-flex">

                <b-dropdown
                    class="d-inline-block d-lg-none ml-2 header-search"
                    variant="black"
                    menu-class="dropdown-menu-lg p-0"
                    toggle-class="header-item noti-icon"
                    right
                >
                    <template v-slot:button-content>
                        <i class="mdi mdi-magnify"></i>
                    </template>

                    <input-with-dropdown :mobile_only="true"></input-with-dropdown>
                </b-dropdown>

                <b-dropdown variant="white" right toggle-class="header-item" class="hide-mobile header-modules">
                    <template v-slot:button-content>
                        <span v-if="current_module.hasOwnProperty('name') && current_module.name">
                            <i v-if="current_module.icon" :class="current_module.icon"></i> {{`${current_module.name}`}}
                        </span>
                        <span v-else>Choose Module</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <b-dropdown-item
                        class="notify-item"
                        v-for="(module, m_key) in modules_list"
                        :key="m_key"
                        :value="current_module"
                        @click="setModule(module)"
                        :class=" {'active' : current_module === module.id}"
                    >
                        <span class="align-middle">
                            <i v-if="module.icon" :class="module.icon"></i> {{`${module.name}`}}
                        </span>
                    </b-dropdown-item>
                </b-dropdown>


                <b-dropdown variant="white" right toggle-class="header-item" class="hide-mobile header-currencies">
                    <template v-slot:button-content>
                        {{`${current_currency.icon} ${current_currency.text}`}}
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <b-dropdown-item
                        class="notify-item"
                        v-for="(currency, c_key) in currencies"
                        :key="c_key"
                        :value="current_currency"
                        @click="setCurrency(currency)"
                        :class=" {'active' : current_currency === currency.value}"
                    >
                        <span class="align-middle">{{`${currency.icon} ${currency.text}`}}</span>
                    </b-dropdown-item>
                </b-dropdown>


                <b-dropdown variant="white" right toggle-class="header-item" class="hide-mobile header-timezones">
                    <template v-slot:button-content>
                        <i class="bx bx-time"></i> {{`${current_timezone.text}`}}
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <b-dropdown-item
                        class="notify-item"
                        v-for="(timezone, c_key) in timezones"
                        :key="c_key"
                        :value="current_timezone"
                        @click="setTimezone(timezone)"
                        :class=" {'active' : current_timezone === timezone.value}"
                    >
                        <span class="align-middle">{{`${timezone.text}`}}</span>
                    </b-dropdown-item>
                </b-dropdown>


                <b-dropdown right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <img
                            class="rounded-circle header-profile-user"
                            :src="user_info.avatar ? user_info.avatar : '/images/users/avatar-1.jpg'"
                            alt="Header Avatar"
                        />
                        <span class="d-none d-xl-inline-block ml-1">{{ user_info.name || "AssetFlow" }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <b-dropdown-item href="/af_dashboard">
                        <i class="fa fa-home font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.dashboard') }}
                    </b-dropdown-item>

                    <b-dropdown-item href="/profile">
                        <i class="fa fa-user font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.profile') }}
                    </b-dropdown-item>

                    <b-dropdown-item href="/my_roles">
                        <i class="fa fa-user-check font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.my_roles') }}
                    </b-dropdown-item>

                    <b-dropdown-item v-if="user_info.can_bulk_update === 1" :href="'/' + (item_type === 'sp' ? 'sp' : 'customer') + '/bulk_updates/user'">
                        <i class="fas fa-history font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.bulk_updates') }}
                    </b-dropdown-item>

                    <b-dropdown-divider></b-dropdown-divider>

                    <b-dropdown-item v-if="user_info.can_switch_customer" href="/customer/switch">
                        <i class="fas fa-exchange-alt font-size-16 align-middle mr-1"></i>
                        {{ !is_tv_film_asset ? $t('navbar.dropdown.assetflow.switch_customer') : $t('navbar.dropdown.assetflow.switch_production')}}
                    </b-dropdown-item>

                    <b-dropdown-item v-if="user_info.can_switch_sp" href="/sp/switch">
                        <i class="fas fa-exchange-alt font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.switch_sp') }}
                    </b-dropdown-item>

                    <b-dropdown-item v-if="user_info.can_switch_group" href="/group/switch">
                        <i class="fas fa-exchange-alt font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.switch_group') }}
                    </b-dropdown-item>

                    <b-dropdown-item v-if="user_info.add_file_imports" :href="'/' + (item_type === 'sp' ? 'sp' : 'customer') + '/files/file-import-export'">
                        <i class="fas fa-file-import font-size-16 align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.assetflow.import_objects') }}
                    </b-dropdown-item>


                    <b-dropdown-divider></b-dropdown-divider>
                    <a href="/logout" class="dropdown-item text-danger">
                        <i class="fas fa-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        {{ $t('navbar.dropdown.assetflow.logout') }}
                    </a>
                </b-dropdown>


            </div>
        </div>
    </header>
</template>
