<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_labelling",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    data(){
        return {
            addForm:{
                labelling_labelling: 1,
            },
            sidebar_navigation: {
                labelling: {
                    label: 'Labelling',
                    settings: {
                        labelling_labelling: {
                            id: 'labelling_labelling',
                            label: 'Labelling',
                            functionality: 'core'
                        }
                    }
                },
            }
        }
    }
}
</script>

