<template>
    <div class="tab-content" :id="tab_id">
        <b-skeleton-wrapper class="af-skeleton-block" :loading="loadingStates[tab_index]">
            <template #loading>
                <div class="common_col_bg_box" v-for="item in [0,1,2]">
                    <b-skeleton-table
                        class="mb-0"
                        :hideHeader="true"
                        :showFooter="true"
                        :key="'table_'+item"
                        :rows="1"
                        :columns="3"
                        :table-props="{ bordered: false, striped: false, borderless: true, small: true }"
                    ></b-skeleton-table>
                </div>
            </template>

            <div v-if="tabData[tab_index]">
                <slot name="tab-content"></slot>
            </div>
        </b-skeleton-wrapper>
    </div>
</template>
<script>

export default {
    name: 'TabContentUsingIndex',
    components: {},
    props: {
        tab_id: {},
        item_info: {},
        loadingStates: {},
        tabData: {},
        tab_index: {}
    }
}
</script>
