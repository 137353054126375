<template>
    <Layout>
        <add-new-tag
            ref="add-new-tag"
            item_type='tag'
            modal_title="Create New Tag"
            @on-new-item-adding="getList(selected_item)"
        >
        </add-new-tag>

        <QrCodeModal
            :ref="qr_modal_id"
            :modal_id="qr_modal_id"
            :current_page_type="item_type"
            :object_name="object_type"
        />

        <div class="tags-wrapper" :id="item_type === 'customer' ? 'wrapper' : ''">
            <objects-sidebar
                v-if="item_type === 'customer'"
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="permission_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            object_type="tags"
                            :current_title_html="getTitleFor(selected_item, 'tags')"
                            block_class="customers-warehouses-block both-customers-warehouses"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />

                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Tags.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+object_type)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('tag')">Tag
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="current-page-filters">
                        <span v-if="is_sp_or_cross_or_admin && selected_customer && selected_customer.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Customer: <b>{{ selected_customer.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_customer')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ selected_item.name | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>

                    <div v-if="item_type === 'customer'" class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- Product Section Start-->
                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="shall_select_customer_message" class="products text-center">
                        <i class="display-4 text-muted bx bx-info-circle mb-2"></i>
                        <h4>Please Select a Customer First...</h4>
                    </div>
                    <div v-else-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching {{ specific_object_name }}...</h4>
                    </div>
                    <div v-else-if="!tagsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No {{ specific_object_name }} found here at the moment.</h4>

                    </div>

                    <div v-else-if="tagsData && selectedLayout === 0" class="tags">
                        <div class="cm_list_view_wrapper">
                            <div class="cm_list_view af-primary cm_list_view-header">
                                <div class="cm_list_row row">
                                    <div class="col-12">{{ specific_object_name }}</div>
                                </div>
                            </div>

                            <ParentAwareObject
                                v-for="(object, objectKey) in tagsData"
                                :key="objectKey"
                                :object="object"
                                :selected_object_id="selected_object_id"
                                :item_type="item_type"
                                :object_type="object_type"
                                :object_index="objectKey"
                                :performAction="performAction"/>
                        </div>

                    </div>

                    <div v-else-if="tagsData" class="row">
                        <div v-if="selectedLayout === 1 && tagsData" class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="object_type"
                                :keys_list="tag_list_keys_list">
                                <template v-slot:tags_actions="{ item, index }">

                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item v-if="permissions_list['view_' + object_type]"
                                                             :href="'/' + item_type + '/tags/' + item.id + '/view'">
                                                View
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="permissions_list['edit_' + object_type]"
                                                             @click="performAction(item, 'edit', index, $event)">
                                                Edit
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="permissions_list['delete_' + object_type]"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete' + object_type, index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>

                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import AddNewTag from "../extras/AddNewTag";
import SearchBox from "@/components/search-box";

const tagResource = new Resource('api/assetflow/tags');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {TagKeysList} from "@/views/assetflow/form_data/TagKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import TopFiltersBlock from "../sp_storages/TopFiltersBlock";
import ParentAwareObject from "../folders/ParentAwareObject";

import QrCodeModal from "../../items/QrCodeModal";

export default {
    name: 'TagsList',
    props: ['item_id', 'item_type'],
    mixins: [ObjectsSidebarShared, TopFiltersMixin],
    components: {
        QrCodeModal,
        ParentAwareObject,
        TopFiltersBlock,
        LotsOfItemsAlert, ObjectsSidebar, PoLines, AddNewTag, SearchBox, Layout, PageHeader},
    data() {
        return {
            qr_modal_id: 'qrcode_generator',
            tag_list_keys_list: TagKeysList,
            total: 0,
            tagsData: [],
            listLoading: false,
            specific_object_name: 'Tags',
            request_meta: {},
            breadcrumb_items: [],
            title: "Tags",
            items: [
                {
                    text: "Tags",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_tag_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            object_type: 'tags',
            permission_type: 'tags',
            groupBy_options: [
                {
                    id: 0,
                    label: 'Tags',
                    backend_key: 'parent_tag_id'
                },
            ],
            items_list: [
                // Tags
                [],
            ],
            loadableOptions: [
                {
                    index: 0, // index in items_list
                    object_name: 'tags'
                },
            ],
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {
        if (this.item_type !== 'customer'){
            delete this.groupBy_options[0];
            delete this.items_list[0];
            delete this.loadableOptions[0];
        }
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
        };

        this.required_items = ['customers'];
        this.permissions_list['view_' + this.object_type] = false; // this.hasPermission('browse_' + this.object_type);
        this.permissions_list['add_' + this.object_type] = this.hasPermission('add_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);

        this.has_tag_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['delete_' + this.object_type];

        if (this.has_tag_actions_permissions){
            this.tag_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'tags_actions'
            }
        }


        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.tag_list_keys_list.customer_info.label = 'Production';
            }
            delete this.tag_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.tag_list_keys_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_tags_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.tag_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.tag_preferred_sorting;
        }

        this.getList(this.selected_item);
    },
    computed: {
        totalAssetsValue() {
            return this.tagsData ? this.tagsData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        performAction(tag, action, tagKey, e){
            if (action === 'edit'){
                this.$bvModal.show('create-tag');
                this.$refs['add-new-tag'].setSelectedItem(tag);
            }else if (action === 'generateQRCode'){
                this.$refs[this.qr_modal_id].setQrCodeURL(tag);
                this.$bvModal.show(this.qr_modal_id);
            }else if (action === ('delete'+this.object_type)){
                if (tag.id !== ''){
                    this.deleteItemByID(tag.id, tag.name, this.object_type, tag)
                        .then((response) => {
                            if (response.data.data.status){
                                this.tagsData.splice(tagKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, tag) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.tagsData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('tag_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_tags_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (this.item_type !== 'customer'){
                // shall restrict!
                this.shall_select_customer_message = false;
                if (!this.selected_customer || !this.selected_customer.id){
                    this.shall_select_customer_message = true;
                    return;
                }
            }
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }


            this.setTopFilterValue(selected_item);
            required_query = this.SBHandleQueryItems(required_query, selected_item);
            required_query.with_flat_results = 0;
            required_query.use_current_module = 0;
            required_query.s_keyword = this.search_keyword;
            required_query = this.appendTopFiltersValues(required_query);
            const {data} = await tagResource.list(required_query);
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.tagsData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },
        addNewItem(tag_type) {
            this.$refs['add-new-tag'].setSelectedItem(null);
            this.$bvModal.show('create-tag');
        }
    },
}
</script>
