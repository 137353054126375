<template>
    <div class="dropdown-menu dropdown-menu-right">

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] &&
                                                        item.status === 0"
            @click="showModal(item, 'park-location-item')">
            Park Storage
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] &&
                                                        item.status === 1"
            @click="showModal(item, 'vacate-location-item')">
            Vacate
        </b-dropdown-item>


        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status === 0"
            @click="showModal(item, 'make-unavailable')">
            Make Unavailable
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status === 2"
            @click="showModal(item, 'make-free')">
            Make Free
        </b-dropdown-item>

        <b-dropdown-item v-if="permissions_list['delete_' + permission_name]"
                         variant="danger"
                         @click="performAction(item, ('delete' + permission_name), index)">
            Delete
        </b-dropdown-item>
    </div>
</template>
<script>
export default {
    name: 'SpLocationActions',
    props: [
        'index',
        'item',
        'permission_name',
        'permissions_list',
        'performAction',
        'showModal'
    ]
}
</script>
