<template>
    <div class="step-form-content-block row">
        <div class="col-xl-6">
            <div class="step-common-info-block">
                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Asset Name</h5>
                    </div>
                    <div class="common--input-filed">
                        <input
                            v-model="addForm.item_details.name"
                            placeholder="" type="text">
                    </div>
                </div>

                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Asset Type</h5>
                    </div>
                    <div class="st-status-radio-box">
                        <div class="st-radio-row" v-if="!asset_is_locked">
                            <div v-for="(option, index) in required_fields.af_asset_type.options"
                                 :key="index"
                                 class="st-radio-col-2 mb-2">
                                <div class="cm-radio-btn status-1" :class="'af_asset_type-' + option.id">
                                    <label>
                                        <input
                                            v-model="addForm.single_select_v_models.af_asset_type"
                                            :key="index"
                                            :id="'_'+index"
                                            name="asset_af_asset_type"
                                            :value="option"
                                            type="radio" />
                                        <div class="st-radio-box">
                                            <span></span>
                                            <p>{{ option.name | titleize }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <LockedAssetWarning icon="bx bx-lock" message="This Asset is Locked, 'Changing Asset Type' is not available."/>
                        </div>

                    </div>
                </div>

                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Ownership Type</h5>
                    </div>
                    <div class="st-status-radio-box">
                        <div class="st-radio-row">
                            <div v-for="(option, index) in required_fields.ownership_type.options"
                                 :key="index"
                                 class="st-radio-col-2">
                                <div class="cm-radio-btn status-1" :class="'ownership' + option.id">
                                    <label>
                                        <input
                                            v-model="addForm.item_details.ownership_type"
                                            :key="index"
                                            :id="'_'+index"
                                            name="asset_ownership"
                                            :value="option.id"
                                            :disabled="addForm.single_select_v_models.acquisition_type !== null"
                                            type="radio" />
                                        <div class="st-radio-box">
                                            <span></span>
                                            <p>{{ option.name | titleize }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Product Quantity</h5>
                    </div>
                    <div v-if="!asset_is_locked"
                        class="common--input-filed st-unique-input">
                        <input
                            v-model="addForm.item_details.quantity"
                            :readonly="addForm.item_details.quantity_disabled"
                            class="st-input-uq"
                            type="number">
                        <div class="st-unique-input-box">
                            <input
                                v-model="addForm.item_details.is_unique"
                                id="styled-checkbox-2" class="styled-checkbox"
                                   type="checkbox" value="1">
                            <label for="styled-checkbox-2">Is Unique?</label>
                        </div>
                    </div>
                    <div v-else>
                        <LockedAssetWarning icon="bx bx-lock" message="This Asset is Locked, 'Changing Product Quantity' is not available."/>
                    </div>
                </div>



                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Internal Reference</h5>
                    </div>
                    <div class="common--input-filed">
                        <input
                            v-model="addForm.item_details.internal_reference"
                            placeholder="Ref No." type="text">
                    </div>
                </div>
                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Asset Description</h5>
                    </div>
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm.item_details.description"
                            placeholder="Asset Description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="step-common-info-right-block">
                <div class="st-drop-file-block">
                    <div class="st-drop-file-box">
                        <vue-dropzone id="imagesgallery"
                                      ref="myVueDropzone"
                                      :use-custom-slot="true"
                                      :include-styling="false"
                                      :options="dropzoneOptions"
                                      @vdropzone-thumbnail="thumbnail"
                                      @vdropzone-file-added="fileAdded"
                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                        >
                            <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <div class="st-upload-title">
                                    <h3>Drop files here or click to upload</h3>
                                    <p>... or click to select a file from your computer</p>
                                </div>
                            </div>
                        </vue-dropzone>
                        <b-button
                            @click="$refs.myVueDropzone.$el.click();"
                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                        >Add New Image</b-button>
                        <b-button
                            @click="resetDropZone"
                            variant="outline-danger"
                            class="btn btn-sm mt-1 btn-block"
                        >Remove All</b-button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import LockedAssetWarning from "./LockedAssetWarning";

export default {
    name: "BasicInfo",
    components: {LockedAssetWarning},
    mixins:[dropzoneMixin],
    props:['addForm', 'required_fields', 'asset_images', 'asset_is_locked', 'asset_is_ended'],
    data(){
        return {
            first_time: true
        }
    },
    mounted() {
        this.fillAssetImages();
    },
    computed: {
        asset_images_list() {
            return this.addForm.filled_asset_images;
        },
    },
    watch: {
        asset_images_list:{
            deep: true,
            handler: function (newValue) {
                if (this.first_time){
                    this.fillAssetImages();
                    this.first_time = false;
                }
            }
        },
    },
    methods:{
        fillAssetImages() {
            if (this.addForm.hasOwnProperty('asset_images') && this.addForm.filled_asset_images.length > 0) {
                for (const assetImagesKey in this.addForm.filled_asset_images) {
                    let asset_image = this.addForm.filled_asset_images[assetImagesKey];
                    var file = {
                        id: asset_image.id,
                        size: asset_image.image_name_size,
                        name: asset_image.name,
                        type: "image/png",
                        is_featured: asset_image.is_featured,
                        add_to_ap: asset_image.add_to_ap
                    };
                    var url = asset_image.image_name;

                    if (asset_image.image_name_size) {
                        this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    }
                }
            }
        }
    }
}
</script>

<style>

#imagesgallery {
    font-family: sans-serif;
    cursor: pointer;
    transition: background-color .2s linear;
    min-height: 300px !important;
    border: 2px dashed #ced4da !important;
    background: #fff !important;
    border-radius: 6px !important;
}

#imagesgallery .dropzone-custom-content {
    font-size: 24px;
    color: #ced4da;
    margin: 3em 0 !important;
    text-align: center;
}

#imagesgallery .dz-preview {
    display: inline-block
}

#imagesgallery .dz-preview .dz-image {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-top: 20px;
}

#imagesgallery .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}

#imagesgallery .dz-preview .dz-image > img {
    width: 100%;
}

#imagesgallery .dz-preview .dz-details {
    color: inherit;
    transition: opacity .2s linear;
    text-align: center;
}

#imagesgallery .dz-success-mark, .dz-error-mark {
    display: none;
}

</style>
