const FirstPath = window.location.pathname.split('/')[1];
import {getOrderOrOrderLineWithStatus} from "./getOrderOrOrderLineWithStatus";

export const OrderLineKeysList = {
    uid: {
        key: 'uid',
        label: "Task",
        class: "col-lg-2 ordl_uid",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return getOrderOrOrderLineWithStatus(item, 'order_lines', FirstPath);
        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-3 ordl_customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_name = '';
            let customer_reference = '';
            let customer_info = item.customer_info;
            if (customer_info){
                customer_name = '<i class="fa fa-star"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + customer_info.name + '</a></span> ';
                customer_reference = 'Ref: ' + item.item_reference;
            }else{
                customer_name = '<i class="fas fa-times status-red"></i> <span class="item-header">Not Assigned</span>';
            }

            let html = '<p class="object-title-list">';
            html += customer_name;
            html += '</p><p class="object-title-detail">'+customer_reference+'</p>';
            return html;
        }
    },
    sp_info: {
        key: 'sp_info',
        label: "Service Provider",
        class: "col-lg-3 ordl_sp_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {

            let sp_name = '';
            let sp_reference = '';
            let sp_info = item.sp_info;
            if (sp_info){
                sp_name = '<i class="fa fa-warehouse"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/service_providers/' + sp_info.id + '/view">' + sp_info.name + '</a></span> ';
                sp_reference = 'Ref: ' + item.item_reference;
            }else{
                sp_name = '<i class="fas fa-times status-red"></i> <span class="item-header">Not Assigned</span>';
            }

            let html = '<p class="object-title-list">';
            html += sp_name;
            html += '</p><p class="object-title-detail">'+sp_reference+'</p>';
            return html;
        }
    },
    order_info: {
        key: 'order_id',
        label: "Order",
        class: "col-lg-2 ordl_order_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let order = item.order_info;
            if (order){
                let content = '<p class="object-title-list">' +
                        '<i class="fa fa-shopping-cart"></i> ' +
                        '<span class="item-header">' +
                            '<a title="' + item.order_info.name + '" class="text-dark" href="/' + FirstPath + '/orders/' + item.order_id + '/view">' + item.order_info.uid + '</a>' +
                        '</span>' +
                    '</p>';
                    if (order.hasOwnProperty('order_lines_count') && order.order_lines_count){
                        content += '<p class="object-title-detail">' +
                                order.completed_order_lines_count + ' of ' + order.order_lines_count +' Lines Completed' +
                            '</p>';
                    }
                return content;
            }
            return '-';
        }
    },
    service_type_info: {
        key: 'service_type_info',
        label: "Service",
        class: "col-lg-2 ordl_service_type_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('service_type_info') && item.service_type_info){
                let service_type_info = item.service_type_info;
                let content = '<p class="object-title-list">' +
                    '<i class="' + service_type_info.icon + '"></i> ' +
                    '<span class="item-header">' +
                    service_type_info.name +
                    '</span>' +
                    '</p>';
                let customer_info = item.customer_info;
                if (customer_info){
                    content += '<p class="object-title-detail"><a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + customer_info.name + '</a></p>';
                }else{
                    content += '<p class="object-title-detail">Not Assigned</p>';
                }
                return content;
            }
            return '<p class="object-title-list"><i class="fas fa-question-circle status-grey orderline-status-planned-circle"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/order_lines/' + item.id + '/view">Waiting</a></span></p>' +
                '<p class="object-title-detail">Waiting For Service Confirmation.</p>';
        }
    },

    intake_date: {
        key: 'intake_date',
        label: "Due Date",
        class: "col-lg-2 ordl_intake_date",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let intake_date;
            if (item.status === 0){
                intake_date = item.hasOwnProperty('created_at') && item.created_at ? item.created_at : null;
            }else if (item.status === 1){
                intake_date = item.hasOwnProperty('service_target_date') && item.service_target_date ? item.service_target_date : null;
            }else if ([2, 3].includes(item.status)){
                intake_date = item.hasOwnProperty('completed_date') && item.completed_date ? item.completed_date : null;
            }else if (item.status === 4){
                intake_date = item.hasOwnProperty('removed_date') && item.removed_date ? item.removed_date : null;
            }

            if (intake_date){
                return '<p class="object-title-list">' +
                    '<i class="far fa-clock"></i> ' +
                        '<span class="item-header">' +
                            Vue.options.filters.fromNow(intake_date) +
                        '</span>' +
                    '</p>' +
                    '<p class="object-title-detail">Target: ' +
                        Vue.options.filters.moment(intake_date, 'DD/MM/YYYY HH:mm') +
                    '</p>';
            }
            return '<i class="far fa-clock"></i> -';
        }
    },
    actions:{
        key: 'actions',
        label: '',
        class: "col-lg-1 po_actions",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_name: 'order_lines_actions'
    }
};
