<template>
    <div :class="used_classes">

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <MyBCarouselImages parent_class="cm_product_grid_img mt-4" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_list_view_title">
                    <div class="d-flex-p"><div><span class="button_size status-btn-disposed">{{item_info.name}}</span></div></div>
                </div>

                <div class="cm_status_ul disposition_details_qty">
                    <ul>
                        <li>
                            <p>Assets <span class="total_box_bg">{{ item_info.hasOwnProperty('assets_info') && item_info.assets_info ? item_info.assets_info.length : '-' }}</span></p>
                        </li>
                        <li>
                            <p>Qty <span class="total_box_bg">{{ item_info.quantity }}</span></p>
                        </li>
                        <li>
                            <p>Total
                                <span v-if="item_info.total_value && item_info.currency" class="total_box_bg" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.total_value)">{{getCurrencyByValue(item_info.currency).icon + item_info.total_value}}</span>
                                <span v-else class="total_box_bg">-</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common_col_bg_box">
                <div class="cm_view_title">
                    <h4>Details</h4>
                </div>
                <div class="row">

                    <div class="col-md-4">
                        <div class="cm_usage border_r">
                            <span>Disposition Status</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info.hasOwnProperty('status')">{{item_info.status === 1 ? 'Completed' : 'Pending'}}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="cm_usage border_r">
                            <span>Category</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info.hasOwnProperty('category_info') && item_info.category_info">{{item_info.category_info.name}}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="cm_usage border_r">
                            <span>Source</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info && item_info.hasOwnProperty('source')">{{sources_list[item_info.source]}}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="cm_usage border_r">
                            <span>Source ID</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info && item_info.hasOwnProperty('source_info') && item_info.source_info">
                                    <a :href="'/' + item_info.current_page_type + '/'+item_info.source_info.item_type+'/'+item_info.source_info.id+'/view'">{{item_info.source_info.name}}</a>
                                </span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="cm_usage border_r">
                            <span>Created By</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info && item_info.hasOwnProperty('user_info')">
                                    <a :href="'/' + item_info.current_page_type + '/users/'+item_info.user_info.id+'/view'">{{item_info.user_info.name}}</a>
                                </span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="cm_usage border_r">
                            <span>Date</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info.hasOwnProperty('created_at')">{{ item_info.created_at | moment("MMMM Do YYYY") }}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>

import AssetActions from "@/mixins/assetActions.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import MyBCarouselImages from "../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'DispositionRightBox',
    mixins: [AssetActions, TotalsAndCurrencies, TagsManagerMixin, ImageGallery],
    components: {
        MyBCarouselImages,
        MyBCarouselSlide,
        VueEasyLightbox
    },
    props: {
        item_info: {},
        used_classes:''
    },
    data(){
        return {
            disposition_type_info: null,
            sources_list:[
                'Direct',
                'Actions',
                'Orders',
                'Storage Orders',
            ]
        }
    },
    computed:{
        intake_profile_info(){
            return this.item_info.hasOwnProperty('intake_profile_info') && this.item_info.intake_profile_info;
        }
    },
    methods:{
        getColourHTML(item){
            let colours_info = item.colours_info;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        },
        getConditionText(condition){
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },
        getManagedObjectStatus(item){
            let status_list = [
                {
                    name: 'Dispatched',
                    class: 'status-background-grey',
                },
                {
                    name: 'Intake In Progress',
                    class: 'status-background-orange',
                },
                {
                    name: 'In Storage',
                    class: 'status-background-green',
                },
            ];
            if (status_list[item.status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span></div></div>';
            }
            return '';
        },
    },
    created() {
        if (this.item_info){
            this.disposition_type_info = this.item_info.hasOwnProperty('disposition_type_info') && this.item_info.disposition_type_info ? this.item_info.disposition_type_info : null;
        }
    }
}
</script>

