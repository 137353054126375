export const StatusesList = [
    // {
    //     id: 1,
    //     name: 'planned',
    //     value: 'planned'
    // },
    {
        id: 2,
        name: 'Acquired (Not Used)',
        value: 'acquired'
    },
    {
        id: 3,
        name: 'Active (In Use)',
        value: 'active'
    },
    // {
    //     id: 6,
    //     name: 'on_loan',
    //     value: 'on_loan'
    // },
    {
        id: 4,
        name: 'in_storage',
        value: 'in_storage'
    },
    {
        id: 5,
        name: 'all_dispositions',
        value: 'all_dispositions',
        subfolders: [
            {
                id: 'loading',
                name: 'loading..',
                icon: ''
            },
        ]
    },
];
