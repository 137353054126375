

export function getOrderOrOrderLineWithStatus(item, item_type, FirstPath) {
    if (!item_type || !item){
        return '-';
    }
    let status_list = [];
    if (item_type === 'order_lines'){
        status_list = [
            // 0:Planned | 1:In Progress | 2: Pending Completion| 3:Completed | 4:Removed
            {
                name: 'Planned',
                class: 'orderline-planned status-grey',
            },
            {
                name: 'In Progress',
                class: 'orderline-in_progress status-orange',
            },
            {
                name: 'Pending Completion',
                class: 'orderline-pending_completion status-brightgreen',
            },
            {
                name: 'Completed',
                class: 'orderline-completed status-green',
            },
            {
                name: 'Removed',
                class: 'orderline-removed status-red',
            }
        ];
    }else if (item_type === 'orders'){
        status_list = [
            {
                name: 'Open',
                class: 'order-open status-grey'
            },
            {
                name: 'Confirmed',
                class: 'order-confirmed status-brown'
            },
            {
                name: 'In Progress',
                class: 'order-in_progress status-orange'
            },
            {
                name: 'Completed',
                class: 'order-completed status-green'
            },
            {
                name: 'Cancelled',
                class: 'order-cancelled status-red'
            },
            {
                name: 'Awaiting Transport',
                class: 'order-awaiting_transport status-black'
            },
        ];
    }
    let status_class = status_list[item.status].class;


    let updated_by_text = '';
    if (item_type === 'orders'){
        updated_by_text = item.name;
    }else if(item.user_info){
        let user_info = item.user_info;
        let user_link = '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
        updated_by_text = Vue.options.filters.moment(item.created_at, 'DD/MM/YYYY') + ' by ' + user_link;
    }

    return '<p class="object-title-list">' +
        '<i class="fa fa-circle ' + status_class + '"></i> ' +
        '<span class="item-header">' +
        '<a class="text-dark" href="/' + FirstPath + '/'+item_type+'/' + item.id + '/view">' + item.uid + '</a>' +
        '</span>' +
        '</p>' +
        '<p class="object-title-detail">' +
        updated_by_text +
        '</p>';
}
