<template>
    <div :class="used_classes">

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>


        <div class="cm_product_content acq-overview-tags">
            <div class="cm_product_status_grid">
                <div class="cm_list_view_title">
                    <div class="d-flex-p">
                        <div>
                            <span class="button_size acq-status" :class="getAcquisitionStatus(item_info.status, true)">{{getAcquisitionStatus(item_info.status)}}</span>

                            <span class="button_size status-btn-in_storage acquisition-mode"><i class="bx" :class="{
                                'bx-export' : item_info.mode === 1,
                                'bx-import' : item_info.mode === 0,
                            }"></i> {{ item_info.mode === 0 ? 'Slave' : 'Master' }}</span>

                            <span class="button_size status-btn-active acq-supplier" v-if="item_info.hasOwnProperty('supplier_name') && item_info.supplier_name !== ''">
                                <a class="text-white" :href="'/' + item_info.current_page_type + '/suppliers/' + item_info.supplier_id + '/view'">{{ item_info.supplier_name }}</a>
                            </span>
                            <span class="button_size status-btn-active acq-acquisition_type" v-if="acquisition_type_info">
                                {{ acquisition_type_info.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="cm_product_more_btn">

            <div v-if="hasPermission('edit_acquisitions') || hasPermission('delete_acquisitions')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>


                <AddPaymentModal
                    :ref="add_payment_modal_id + unique_id"
                    :received_modal_id="add_payment_modal_id + unique_id"
                    object_type="acquisitions"
                    :item_type="item_info.current_page_type"
                    :modal_title="add_payment_modal_title"
                    @onFormSubmit="onFormSubmit"
                />

                <AddAssetsModal
                    :ref="add_assets_modal_id + unique_id"
                    :received_modal_id="add_assets_modal_id + unique_id"
                    object_type="acquisitions"
                    :item_type="item_info.current_page_type"
                    :modal_title="add_assets_modal_title"
                    @onFormSubmit="onFormSubmit"
                />

                <DefineSupplierModal
                    :ref="define_supplier_modal_id + unique_id"
                    :received_modal_id="define_supplier_modal_id + unique_id"
                    object_type="acquisitions"
                    :item_type="item_info.current_page_type"
                    :modal_title="define_supplier_modal_title"
                    @onFormSubmit="onFormSubmit"
                />

                <CloseAcquisitionModal
                    :ref="close_acquisition_modal_id + unique_id"
                    :received_modal_id="close_acquisition_modal_id + unique_id"
                    object_type="acquisitions"
                    :item_type="item_info.current_page_type"
                    :modal_title="close_acquisition_modal_title"
                    @onFormSubmit="onFormSubmit"
                />

                <AcquisitionActionsList :item="item_info" :index="0" :item_type="item_info.current_page_type" object_type="acquisitions" :perform-action="acquisitionPerformAction"/>
            </div>
        </div>

        <div class="cm_product_content acq-overview-costs">
            <div class="cm_product_status_grid">
                <div class="cm_status_ul acquisition_details_qty">
                    <ul>
                        <li>
                            <p>Net <span v-if="item_info.net_value !== null"
                                 class="total_box_bg"
                                 v-b-tooltip:hover.bottom
                                 :title="toUserCurrencyValue(item_info_currency.value, item_info.net_value)">
                                    {{item_info_currency.icon + parseFloat(item_info.net_value)}}</span>
                                <span class="total_box_bg" v-else>{{item_info_currency.icon + '0'}}</span>
                            </p>
                        </li>
                        <li>
                            <p>Tax <span v-if="item_info.tax_value !== null"
                                 class="total_box_bg"
                                 v-b-tooltip:hover.bottom
                                 :title="toUserCurrencyValue(item_info_currency.value, item_info.tax_value)">
                                    {{item_info_currency.icon + parseFloat(item_info.tax_value)}}</span>
                                <span class="total_box_bg" v-else>{{item_info_currency.icon + '0'}}</span>
                            </p>
                        </li>
                        <li>
                            <p>Total <span v-if="item_info.all_items_value !== null"
                                   class="total_box_bg"
                                   v-b-tooltip:hover.bottom
                                   :title="toUserCurrencyValue(item_info_currency.value, item_info.all_items_value)">
                                    {{item_info_currency.icon + parseFloat(item_info.all_items_value)}}</span>
                                <span class="total_box_bg" v-else>{{item_info_currency.icon + '0'}}</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <MyBCarouselImages parent_class="cm_product_grid_img" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

        <div class="cm_product_content acq-overview-ownership">
            <div class="common_col_bg_box">
                <div class="cm_view_title">
                    <h4>Ownership</h4>
                </div>
                <div class="row">

                    <div class="col-md-6">
                        <div class="border_r">
                            <span>Acquisition Type</span>
                            <div class="d-flex-p">
                                <div>
                                    <span class="button_size status-btn-active acq-acquisition_type" v-if="acquisition_type_info">
                                        {{ acquisition_type_info.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="cm_usage border_r">
                            <span>Acquisition Date</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info.hasOwnProperty('acquired_date') && item_info.acquired_date !== ''">{{item_info.acquired_date | moment('MMMM Do YYYY')}}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="cm_usage border_r">
                            <span>Returns Deadline</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info.hasOwnProperty('return_expiry_date') && item_info.return_expiry_date && item_info.return_expiry_date !== ''">{{item_info.return_expiry_date | moment('MMMM Do YYYY')}}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="cm_usage border_r">
                            <span>Days Left to Return</span>
                            <p>
                                <i class="bx bx-detail"></i>
                                <span v-if="item_info.hasOwnProperty('return_expiry_date') && item_info.return_expiry_date && item_info.return_expiry_date !== ''">{{item_info.return_expiry_date | fromNow}}</span>
                                <span v-else>-</span>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>

import AssetActions from "@/mixins/assetActions.mixin";
import AcquisitionShared from "@/mixins/AcquisitionShared.mixin";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";

import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import AcquisitionActionsList from "../customers/acquisitions/AcquisitionActionsList";
import AddPaymentModal from "../customers/acquisitions/Modals/AddPaymentModal";
import AddAssetsModal from "../customers/acquisitions/Modals/AddAssetsModal";
import DefineSupplierModal from "../customers/acquisitions/Modals/DefineSupplierModal";
import CloseAcquisitionModal from "../customers/acquisitions/Modals/CloseAcquisitionModal";
import MyBCarouselImages from "../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'AcquisitionRightBox',
    mixins: [AcquisitionShared, dropzoneMixin, AssetActions, TotalsAndCurrencies, TagsManagerMixin, ImageGallery],
    components: {
        MyBCarouselImages,
        CloseAcquisitionModal,
        DefineSupplierModal,
        AddAssetsModal,
        AddPaymentModal,
        AcquisitionActionsList,
        MyBCarouselSlide,
        VueEasyLightbox
    },
    props: {
        item_info: {},
        used_classes:''
    },
    data(){
        return {
            acquisition_type_info: null,
            sources_list:[
                'Direct',
                'Actions',
                'Orders',
                'Storage Orders',
            ]
        }
    },
    computed:{
        intake_profile_info(){
            return this.item_info.hasOwnProperty('intake_profile_info') && this.item_info.intake_profile_info;
        },
        item_info_currency(){
            let currency = this.item_info.currency && this.item_info.currency !== '' ? this.item_info.currency : 'gbp';
            return this.getCurrencyByValue(currency);
        },
    },
    methods:{
        getColourHTML(item){
            let colours_info = item.colours_info;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        },
        getConditionText(condition){
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },
        getManagedObjectStatus(item){
            let status_list = [
                {
                    name: 'Dispatched',
                    class: 'status-background-grey',
                },
                {
                    name: 'Intake In Progress',
                    class: 'status-background-orange',
                },
                {
                    name: 'In Storage',
                    class: 'status-background-green',
                },
            ];
            if (status_list[item.status]){
                return '<div class="d-flex-p"><div><span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span></div></div>';
            }
            return '';
        },
    },
    created() {
        if (this.item_info){
            this.acquisition_type_info = this.item_info.hasOwnProperty('acquisition_type_info') && this.item_info.acquisition_type_info ? this.item_info.acquisition_type_info : null;
        }
    },

}
</script>

