import Resource from '@/api/resource';
const checkEmailResource = new Resource('api/assetflow/users/check_email');

export default {
    data(){
        return{
            isSendingRequest: false,
            fetched_user_info: null,
            errors: [],
            user_status: null,
        }
    },
    created: function () {
    },
    methods: {
        get_selected_entity(){
            switch (this.selected_entity_id) {
                case 0: // customer
                    this.current_customer.item_type = 'customer';
                    return this.current_customer;
                case 1: // group
                    this.current_group.item_type = 'group';
                    return this.current_group;
                case 2: // sp
                    this.current_sp.item_type = 'sp';
                    return this.current_sp;
                case 3: // assetflow
                    if (this.addForm.required_entity !== null){
                        return this.addForm.required_entity;
                    }
            }
            return null;
        },
        async checkEmail(event = null, ignore_status=false) {
            if (event){
                event.preventDefault();
            }
            if (!this.isSendingRequest) {
                this.errors = [];

                if (!this.addForm.email) {
                    this.errors.push('Email required.');
                } else if (!this.validEmail(this.addForm.email)) {
                    this.errors.push('Valid email required.');
                }

                if (!this.errors.length) {
                    this.isSendingRequest = true;
                    let params = {
                        email: this.addForm.email
                    }
                    let required_entity = this.get_selected_entity();
                    if (required_entity){
                        params.entity_id = required_entity.value || required_entity.id;
                        params.entity_type = required_entity.hasOwnProperty('item_type') ? required_entity.item_type : 'customer';
                    }
                    const { data } = await checkEmailResource.store(params);
                    if (data.data.hasOwnProperty('items')){
                        if (!ignore_status){
                            this.user_status = data.data.items.user_status;
                        }
                        this.fetched_user_info = data.data.items.user_info;

                        let first_name = '', last_name = '';
                        this.addForm.required_roles = [];

                        if (this.fetched_user_info){
                            this.fetched_user_info.user_profile_link = '/' + this.item_type + '/users/' + this.fetched_user_info.id + '/view';
                            first_name = this.fetched_user_info.first_name;
                            last_name = this.fetched_user_info.last_name;

                            if (this.fetched_user_info.hasOwnProperty('user_roles')){
                                this.addForm.required_roles = [... _.map(this.fetched_user_info.user_roles, 'id')];
                            }
                        }

                        if (this.addForm.hasOwnProperty('first_name')){
                            this.addForm.first_name = first_name;
                            this.addForm.last_name = first_name;
                        }
                    }else{
                        this.user_status = null;
                    }
                    this.isSendingRequest = false;
                }

            }else{
                if (this.isSendingRequest){
                    // shall show please wait?
                }
            }
        },
        validEmail(email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
}
