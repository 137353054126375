<template>
    <div class="row CharacterMeasurementsTab">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title="block_contents.measurements_info.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :use_table="true" :block_contents="block_contents.measurements_info"/>
                    </template>
                </NewCustomGenericBlock>

            </div>
        </div>
    </div>
</template>
<script>
import CustomGenericBlockContent from "./widgets/detailsViews/AssetTabs/CustomGenericBlockContent"
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock"

export default {
    name: 'CharacterMeasurementsTab',
    props:['item_info'],
    components: {CustomGenericBlockContent, NewCustomGenericBlock},
    data(){
        return {
            block_contents: {
                measurements_info: {
                    label: 'Measurements',
                    has_action: false,
                    action_required: function () {
                    },
                    items_class: 'col-md-6',
                    info_list: []
                }
            }
        }
    },
    created() {

        let measurements = this.item_info.character_measurements;
        if (measurements){
            this.block_contents.measurements_info.info_list = [
                {
                    label: 'Height',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('height'); },
                    info_value : function () { return measurements.height; }
                },
                {
                    label: 'Chest \\ Bust',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('chest_bust'); },
                    info_value : function () { return measurements.chest_bust; }
                },
                {
                    label: 'Natural Waist',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('natural_waist'); },
                    info_value : function () { return measurements.natural_waist; }
                },
                {
                    label: 'Low Waist',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('low_waist'); },
                    info_value : function () { return measurements.low_waist; }
                },
                {
                    label: 'Hips',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('hips'); },
                    info_value : function () { return measurements.hips; }
                },
                {
                    label: 'Inside leg',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('inside_leg'); },
                    info_value : function () { return measurements.inside_leg; }
                },
                {
                    label: 'Neck',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('neck'); },
                    info_value : function () { return measurements.neck; }
                },
                {
                    label: 'Head Circumference',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('head_circumference'); },
                    info_value : function () { return measurements.head_circumference; }
                },
                {
                    label: 'Head Ear to ear',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('head_ear_to_ear'); },
                    info_value : function () { return measurements.head_ear_to_ear; }
                },
                {
                    label: 'Outside leg',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('outside_leg'); },
                    info_value : function () { return measurements.outside_leg; }
                },
                {
                    label: 'Across back',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('across_back'); },
                    info_value : function () { return measurements.across_back; }
                },
                {
                    label: 'Shoulder to wrist',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('shoulder_to_wrist'); },
                    info_value : function () { return measurements.shoulder_to_wrist; }
                },
                {
                    label: 'Shoe size',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () { return measurements.hasOwnProperty('shoe_size'); },
                    info_value : function () { return measurements.shoe_size; }
                },


            ]
        }

    }
}
</script>
