<template>
    <b-modal :id="received_modal_id"
             size="lg"
             :ref="received_modal_id"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="cancelClicked"
             @show="getFieldsList"
             hide-footer>

        <add-new-folder
            ref="add-new-folder"
            item_type="folder"
            modal_title="Create New Folder"
            @on-new-item-adding="appendFolder"
        >
        </add-new-folder>

        <ChangeCommonFields :add-form="addForm" :required_fields="required_items"/>

        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'edit-ms-asset' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const editAssetResource = new Resource('api/assetflow/managed_storages/editAsset');
import multiSelect from '@/mixins/MultiSelect';
import ChangeCommonFields from "./ChangeCommonFields";
import AFButton from "@/components/AFButton";
import AddNewFolder from "./AddNewFolder";

export default {
    mixins: [multiSelect],
    name: "EditManagedStorageAsset",
    props: ['modal_title', 'received_modal_id'],
    components: {AddNewFolder, AFButton, ChangeCommonFields},

    computed:{
        folder_id() {
            return this.addForm.folder_id;
        },
    },
    watch:{
        folder_id(value) {
            if (value){
                if (value.id === -99){
                    this.$set(this.addForm, 'folder_id', null);
                    let folder = {};
                    if (this.selected_item){
                        folder.customer_id = this.selected_item.customer_id;
                    }
                    this.$refs['add-new-folder'].setSelectedItem(folder);
                    this.$bvModal.show('create-folder');
                }
            }
        },
    },
    data(){
        return {
            index: null,
            addForm:{
                name: '',
                description: '',
                category_id:null,
                folder_id:null,
                shall_update_parent: {id: 1, name: 'Yes'},
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Name',
                    type: 'text'
                },
                description: {
                    key: 'description',
                    label: 'Description',
                    type: 'textarea'
                },
                category_id: {
                    key: 'category_id',
                    label: 'Category',
                    type: 'single-select',
                    options: []
                },
                folder_id: {
                    key: 'folder_id',
                    label: 'Folder',
                    type: 'single-select',
                    options: []
                },
                shall_update_parent: {
                    key: 'shall_update_parent',
                    label: 'Update Asset Passport',
                    type: 'radio-select',
                    options: [
                        {
                            id: 1,
                            name: 'Yes'
                        },
                        {
                            id: 0,
                            name: 'No'
                        },
                    ]
                },
            },
            selected_item: null
        }
    },
    methods:{

        appendFolder(folder_info = null){
            if (folder_info){
                this.$set(this.addForm, 'folder_id', folder_info);
                this.required_items.folder_id.options.push(folder_info);
            }
        },
        set_index(index){
            this.index = index;
        },
        async getFieldsList(){
            this.is_saving_enabled = true;
            this.lists_values = {};
            this.isLoadingItem = true;
            this.required_items.folder_id.listLoading = true;
            this.required_items.category_id.listLoading = true;

            let required_select_lists =  {
                category_id: 'categories_list',
                folder_id: 'folders_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'managed_storage_modal',
                    object_type: 'managed_storages',
                    action: 'new_managed_storage',
                    required_lists: required_select_lists,
                    with_asset_template: false,
                    customer_id: this.selected_item ? this.selected_item.customer_id : null
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                let category_id_options = this.getFolderObject(this.lists_values.category_id, 1, 'categorys', 'id', 'name');
                this.required_items.category_id.options = category_id_options;


                this.required_items.category_id.listLoading = false;

                let add_new_item = {id: -99, name: 'Add New'};
                let folder_id_options = this.getFolderObject(this.lists_values.folder_id, 1, 'folders', 'id', 'name');
                folder_id_options.splice(0, 0, add_new_item);

                this.required_items.folder_id.options = folder_id_options;

                this.required_items.folder_id.listLoading = false;

                if (this.selected_item){
                    this.addForm.category_id = this.getObjectOf(this.selected_item.category_id, category_id_options);
                    this.addForm.folder_id = this.getObjectOf(this.selected_item.folder_id, folder_id_options);
                    this.fillSelectedItem();
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            this.addForm.name = this.selected_item.name;
            this.addForm.description = this.selected_item.description;
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                name: this.addForm.name,
                description: this.addForm.description,
                category_id: this.addForm.category_id ? this.addForm.category_id.id : 1,
                folder_id: this.addForm.folder_id ? this.addForm.folder_id.id : 0,
                shall_update_parent: this.addForm.shall_update_parent ? this.addForm.shall_update_parent.id : 0,
            };
            let managed_storage_id = null;
            if (this.selected_item){
                params.item_id = this.selected_item.id;

                managed_storage_id = this.selected_item.managed_storage_id;
                params.managed_storage_id = managed_storage_id;
            }

            const { data } = await editAssetResource.store(params);

            let new_item = data.data.items;
            if (!new_item){
                this.buttonkey++;
            }
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            let required_keys = ['name', 'description', 'category_id', 'folder_id', 'categories_info', 'folders_info'];
            this.$emit('on-new-item-adding', this.received_modal_id, new_item, required_keys, managed_storage_id, this.index);
        }
    }
}
</script>


