<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_assignmentResource = new Resource('api/assetflow/sp_assignments');
import multiSelect from '@/mixins/MultiSelect';
import ChangeCommonFields from "../extras/ChangeCommonFields";
import AFButton from "@/components/AFButton";

export default {
    mixins: [multiSelect],
    name: "AddNewSpAssignment",
    props: ['modal_title', 'item_type', 'sp_assignments_options'],
    components: {AFButton, ChangeCommonFields},
    data(){
        return {
            addForm:{
                customer_id: null,
                storage_id: null,
                storage_wrapper_icon: '',
                start_date: null
            },
            required_sections:{
                assignment_setup: {
                    section_name: 'Assignment Setup',
                    required_items:{
                        customer_id: {
                            key: 'customer_id',
                            label: 'Customer',
                            type: 'single-select',
                            options:[]
                        },
                        storage_id: {
                            key: 'storage_id',
                            label: 'Storage',
                            type: 'single-select',
                            options:[]
                        },
                        storage_wrapper_icon: {
                            key: 'storage_wrapper_icon',
                            label: 'Icon',
                            type: 'hidden_text',
                            is_readonly: true
                        },
                        start_date: {
                            key: 'start_date',
                            label: 'Start Date',
                            type: 'datetime'
                        }
                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
    },
    computed:{
        storage_id() {
            return this.addForm.storage_id;
        },
    },
    watch:{
        storage_id(value) {
            if (value && value.hasOwnProperty('storage_wrapper_info') && value.storage_wrapper_info){
                this.addForm.storage_wrapper_icon = value.storage_wrapper_info.icon;
                this.required_sections.assignment_setup.required_items.storage_wrapper_icon.type = 'icon';
            }else{
                this.required_sections.assignment_setup.required_items.storage_wrapper_icon.type = 'hidden_text';
            }
        },
    },
    methods:{

        async getFieldsList(){
            this.is_saving_enabled = true;
            this.addForm.start_date = Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');

            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                customer_id: 'sp_customers_list',
                storage_id: 'sp_storages_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_assignment_modal',
                    object_type: 'sp_assignments',
                    action: 'new_sp_assignment',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                this.required_sections.assignment_setup.required_items.customer_id.options = this.lists_values.customer_id;
                this.required_sections.assignment_setup.required_items.storage_id.options = this.lists_values.storage_id;

                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.customer_id = this.getObjectOf(this.selected_item.customer_id, this.required_sections.assignment_setup.required_items.customer_id.options);
                this.addForm.storage_id = this.getObjectOf(this.selected_item.storage_id, this.required_sections.assignment_setup.required_items.storage_id.options);
                this.addForm.start_date = this.selected_item.start_date ? this.selected_item.start_date : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.addForm.start_date = Vue.options.filters.moment(new Date(), 'DD-MM-YYYY');
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            let params = {
                customer_id: this.addForm.customer_id ? this.addForm.customer_id.id : null,
                storage_id: this.addForm.storage_id ? this.addForm.storage_id.id : null,
                start_date: this.addForm.start_date
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_assignmentResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>


