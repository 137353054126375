<template>
    <div class="img_and_text_block"
        :class="post_images_count === 0 ? 'l_r_text_img row mx-0' : 'grid_img'">
        <div v-if="item_post.description !== ''"
             class="dummy_text_block"
             :class="post_images_count === 0 ? ' col-sm-9 col-12 pl-0' : ''">
            <slot name="post_description" v-bind:item="item_post"></slot>
        </div>
        <div v-if="post_images_count" class="camera_img row">
            <div v-for="post_image in item_post.post_images"
                 class="col-6 col-sm-auto">
                <img @click="onPostImageClicked ? onPostImageClicked(post_image) : null" :src="post_image.image_name + (image_sizes.hasOwnProperty('post_image') ? image_sizes.post_image : '')" class="img-fluid w-100 clickable-image" :alt="post_image.name">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['item_post', 'onPostImageClicked'],
    name: "MyPostContent",
    computed:{
        post_images_count(){
            return this.item_post.post_images && this.item_post.post_images.length !== 0 ? this.item_post.post_images.length : 0;
        }
    }
}
</script>
