<template>
    <div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        Show:
                        <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            v-on:change="savePerPage($event)"
                        ></b-form-select> entries
                    </label>
                </div>
            </div>
            <!-- Search -->
            <div v-if="this.checkedItems.length > 0" class="col-sm-12 col-md-4">
                <div class="selected-items">
                    <label class="d-inline-flex align-items-center">
                        {{this.checkedItems.length}} Items Selected:
                    </label>
                        <b-button v-b-modal.change-status-modal variant="success" class="btn-sm ml-2">
                            <i class="bx bx-info-circle mr-2"></i> Change Status
                        </b-button>

                        <b-modal id="change-status-modal"
                                 ref="change-asset-status-modal"
                                 title="Change Status"
                                 title-class="font-18"
                                 hide-footer>

                            <b-form-group label="Choose Status" label-for="statuses_list">
                                <b-form-select
                                    id="statuses_list"
                                    name="statuses"
                                    v-model="selected_status"
                                    :options="statuses"
                                >
                                </b-form-select>
                            </b-form-group>

                            <div class="text-right">
                                <b-button type="submit"
                                          variant="primary"
                                          v-on:click="changeStatus($event)">
                                    Update {{checkedItems.length}} Item{{checkedItems.length > 1 ? 's' : ''}}</b-button>
                                <b-button variant="outline-danger" @click="$bvModal.hide('change-status-modal')">Cancel</b-button>
                            </div>
                        </b-modal>

                        <b-button v-b-modal.delete-items-modal variant="danger" class="btn-sm ml-2">
                            <i class="bx bx-x mr-2"></i> Delete
                        </b-button>

                        <b-modal id="delete-items-modal"
                                 ref="delete-selected-items-modal"
                                 title="Delete"
                                 title-class="font-18"
                                 hide-footer>
                            <b-form-group label="Confirm Deletion?">
                                <b-alert show variant="danger">You can not undo this action!</b-alert>
                            </b-form-group>

                            <div class="text-right">
                                <b-button type="submit"
                                          variant="danger"
                                          v-on:click="deleteSelectedItems($event)">
                                    Delete {{checkedItems.length}} Item{{checkedItems.length > 1 ? 's' : ''}}</b-button>
                                <b-button variant="outline-danger" @click="$bvModal.hide('delete-items-modal')">Cancel</b-button>
                            </div>
                        </b-modal>

                </div>
            </div>
            <div :class="`col-sm-12 col-md-${(this.checkedItems.length > 0 ? '4' : '8')}`">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-select
                            style="width: unset !important"
                            v-model="defaultFilter"
                            class="ml-2"
                            size="sm"
                            :options="filterFields">

                            <template v-slot:first>
                                <b-form-select-option value="all">All</b-form-select-option>
                            </template>

                        </b-form-select>
                        <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ml-2"
                        ></b-form-input>
                    </label>
                </div>
            </div>
            <!-- End search -->
        </div>

        <!-- Table -->
        <div class="table-responsive mb-0">
            <b-table
                :items="tableData"
                :fields="titleizedFields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-function="filterFunction"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="itemClicked"
            >

                <template v-slot:cell(actions)="item">
                    <b-form-checkbox type="checkbox" name="checked" :key="item.index" :value="item.item.id" @click.stop v-model="checkedItems"></b-form-checkbox>
                </template>

                <template v-slot:cell(customer_id)="item">
                    <a v-if="item.item.customer_id" :href="(item_type !== '') ? './customers/'+item.item.customer_id+'/view' : '#'" v-b-tooltip.hover :title="item.item.customer_name" class="text-secondary">
                        {{item.item.customer_name}}
                    </a>
                </template>

                <template v-slot:cell(sp_id)="item">
                    <a v-if="item.item.warehouse_id" :href="(item_type !== '') ? './service_providers/'+item.item.warehouse_id+'/view' : '#'" v-b-tooltip.hover :title="item.item.warehouse_name" class="text-secondary">
                        {{item.item.warehouse_name}}
                    </a>
                </template>

                <template v-slot:cell(status)="item">
                    <span
                        class="badge badge-pill badge-soft-success font-size-12"
                        :class=" { 'badge-soft-danger': `${item.item.status}` === '0' }"
                    >{{item.item.status ? 'Active' : 'Disabled'}}</span>
                </template>

                <template v-slot:head(actions)="item">
                    <b-form-checkbox type="checkbox" @click.native.stop="toggleSelected" v-model="allSelected"></b-form-checkbox>
                </template>

                <template v-slot:head(item_actions)="item">
                    Actions
                </template>
                <template v-slot:cell(item_actions)="item">

                    <a :href="(item_type !== '' ? '/' + item_type : '') + '/assets/'+item.item.id + '/view'" v-b-tooltip.hover :title="'View ' +item.item.name" class="btn btn-sm af-btn-accent af-accent">
                        View
                    </a>
                    <b-button class="btn-sm" href="javascript:void(0);" v-b-tooltip.hover :title="'Delete ' + item.item.name + '?'" v-on:click="deleteItem(item.item.id, item.item.name, 'assets', $event)" variant="outline-danger">Delete</b-button>
                </template>


            </b-table>

        </div>
        <div class="row">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-if="perPage !== 0" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import UserMeta from "@/mixins/usermeta.mixin";
    import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";

    export default {
        name: "TableLayout",
        mixins: [UserMeta],
        props: ['tableData', 'fields', 'item_type'],
        data() {
            return {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: ItemsPerPage,
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: false,
                filterFields: [],
                defaultFilter: 'name',
                allSelected: false,
                checkedItems: [],
                selected_status: "1",
                statuses: [
                    {
                        value: 1,
                        text: "Active"
                    },
                    {
                        value: 0,
                        text: "Disabled"
                    }
                ],
            };
        },
        created() {

            var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_items_per_page;
            if (preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)){
                preferredItemsPerPage = parseInt(preferredItemsPerPage);
                this.perPage = preferredItemsPerPage;
                this.pageOptions = this.setPerPageOptions(this.pageOptions, preferredItemsPerPage);
            }

            this.filterFields = this.titleizedFields.filter(function(field) {
                return field.key != 'actions' && field.key != 'item_actions';
            }).map(function(field) {
                return {value: field.key , text: field.label};
            })
        },
        computed: {
            rows() {
                return this.tableData ? this.tableData.length : 0;
            },
            titleizedFields () {
                return this.fields.map(function(field) {
                    var titleized_key = Vue.options.filters.titleize(field.key);
                    return { key: field.key, label: titleized_key, sortable: field.sortable };
                })
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.tableData ? this.tableData.length : 0;
        },
        methods: {
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            filterFunction(row, filter) {
                if (this.defaultFilter === 'all'){
                    var foundOne = false;
                    var BreakException = {};
                    try {

                        for (var row_field in row){
                            if (!['customer_logo', 'created_time', 'created_date', 'updated_time', 'updated_date'].includes(row_field)){
                                var field = row[row_field] + "";
                                if (field.toLowerCase().indexOf(filter.toLowerCase()) !== -1){
                                    console.log(field);
                                    foundOne = true;
                                    throw BreakException;
                                }
                            }

                        }

                        // this.filterFields.forEach(function(row_field) {
                        //     var field = row[row_field.value] + "";
                        //     if (field.toLowerCase().indexOf(filter.toLowerCase()) !== -1){
                        //         foundOne = true;
                        //         throw BreakException;
                        //     }
                        // });
                    } catch (e) {
                        if (e !== BreakException) throw e;
                    }



                    return foundOne;
                }
                var required_field = row[this.defaultFilter] + "";
                return required_field.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            },
            handleExport(){
                alert("Shall Export Items");
            },
            toggleSelected() {
                this.checkedItems = this.allSelected ? [] : this.tableData.map(x => x.id);
            },
            itemClicked(item, index){
                if (this.checkedItems.includes(item.id)){
                    const index = this.checkedItems.indexOf(item.id);
                    if (index > -1) {
                        this.checkedItems.splice(index, 1);
                    }
                }else{
                    this.checkedItems.push(item.id);
                }
            },
            savePerPage(obj){
                console.log(this.perPage);
                let message = "Saved Items Per Page {"+this.perPage+"} Successfully";
                this.set_usermeta('preferred_items_per_page', this.perPage, message);

            },
            deleteItem(item_id, item_name, item_type, event) {
                this.deleteItemByID(item_id, item_name, item_type, event)
                    .then((response) => {
                        this.$emit('on-item-deleted');
                    });
            },
            changeStatus(event){
                if (this.selected_status !== ""){
                    this.performGenericBulkAction('change_status', this.selected_status, event)
                        .then((response) => {
                            this.$refs['change-asset-status-modal'].hide();
                            this.checkedItems = [];
                            this.selected_status = '1';
                            this.$emit('on-bulk-action');
                        });
                }
            },
            deleteSelectedItems(event){
                if (this.checkedItems.length !== 0){
                    this.performGenericBulkAction('delete', "", event)
                        .then((response) => {
                            this.$refs['delete-selected-items-modal'].hide();
                            this.checkedItems = [];

                            this.$emit('on-bulk-action');
                        });
                }
            },
            performGenericBulkAction(action, value, event){
                return new Promise( (resolve, reject) => {
                    if (event){
                        this.performAssetBulkAction(action, 'assets', this.checkedItems, value, event)
                            .then((response) => {
                                resolve(response);
                            });
                    }else{
                        reject(new Error('Event isn\'t defined!'));
                    }
                })
            }
        }
    }
</script>


