<template>
    <div class="step-form-content-block row">

        <add-new-disposition ref="add-new-fdp"
                             modal_id="add-new-fdp"
                             modal_title="Create Disposition"
                             item_type="final_disposition_planning"
                             @on-new-item-adding="addedDispositionType"
                             @on-hiding-modal="resetField('final_disposition_planning')" />

        <div class="col-xl-6 st-col-6">
            <asset-info-section title="Final Disposition Planning" :selected_fields="sustainability_fields" :addForm="addForm">
                <div v-if="asset_is_ended">
                    <LockedAssetWarning icon="bx bx-time" message="This Asset is Locked, 'Setting Final Disposition Planning' is not available."/>
                </div>

                <div v-else>
                    <div v-for="(sustainability_field, key) in sustainability_fields"
                         :key="key"
                         v-if="required_fields[sustainability_field].has_permission"
                         class="st--common--box">


                        <div v-if="selected_final_disposition_planning && sustainability_field === 'fdp_status'" class="common-st-title-label">
                            <h5>{{ required_fields[sustainability_field].label }}</h5>
                        </div>

                        <div v-if="sustainability_field === 'fdp_status'" class="st-status-radio-box">
                            <div v-if="selected_final_disposition_planning" class="st-radio-row">
                                <div v-for="(option, index) in required_fields[sustainability_field].options"
                                     :key="index"
                                     class="col-3">

                                    <div class="cm-radio-btn" :class="'mt-1 '+sustainability_field+'-' + option.id">
                                        <label>
                                            <input
                                                v-model="addForm.item_details.fdp_status"
                                                :key="'_'+index"
                                                :id="'_'+index"
                                                :name="sustainability_field"
                                                :value="option"
                                                type="radio" />
                                            <div class="st-radio-box">
                                                <span></span>
                                                <p>{{ option.name | titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="st-status-radio-box">

                            <div class="st-radio-row">
                                <div v-for="(option, index) in required_fields[sustainability_field].options"
                                     :key="index"
                                     class="col-3">

                                    <div class="cm-radio-btn" :class="'mt-1 '+sustainability_field+'-' + option.id">
                                        <label>
                                            <input
                                                v-model="addForm.single_select_v_models[sustainability_field]"
                                                :key="index"
                                                :id="'_'+index"
                                                :name="sustainability_field"
                                                :value="option"
                                                @input="selectedRadioButton(sustainability_field, option, $event)"
                                                type="radio" />
                                            <div class="st-radio-box">
                                                <span></span>
                                                <p>{{ option.name | titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </asset-info-section>
        </div>

        <div class="col-xl-6 st-col-6 mt-2">
            <asset-info-section title="Valuation" :selected_fields="valuation_fields" :addForm="addForm">
                <div v-if="asset_is_ended">
                    <LockedAssetWarning icon="bx bx-time" message="This Asset is Locked, 'Setting Valuation' is not available."/>
                </div>

                <div v-else>
                    <div class="st--common--box">
                        <div class="dimensions-btn">
                            <a
                                v-for="option in (parseInt(addForm.item_details.quantity) !== 1 ? value_type_options : [value_type_options[0]])"
                                :key="option.id"
                                :value="option.id"
                                @click="value_type = option.id"
                                :class="[{'active': value_type === option.id}]"
                                class="dimensions-auto">
                                <span></span>{{ getValueTypeTitle(option) | titleize }}
                            </a>
                        </div>
                    </div>

                    <div
                        v-for="valuation_item in valuation_fields"
                        class="st--common--box">
                        <div class="common-st-title-label">
                            <h5>{{ required_fields[valuation_item].label }}</h5>
                        </div>


                        <div v-if="valuation_item === 'valuation_method'" class="dimensions-btn valuation-method">
                            <a
                                v-for="(option, index) in required_fields.valuation_method.options"
                                :key="option.id"
                                :value="option.id"
                                @click="updatedValuationMethod(option)"
                                :class="[{'active': addForm.item_details.valuation_method === option.id}]"
                                class="dimensions-auto">
                                <span></span>{{ option.name | titleize }}
                            </a>
                        </div>
                        <div v-else-if="valuation_item === 'forecast_value'" class="mb-2">

                            <div v-if="addForm.item_details.valuation_method === 1" class="common--input-filed unit-input mb-1">
                                <input
                                    v-model="addForm.valuation_percentage"
                                    aria-describedby="_valuation_percentage_desc"
                                    id="_valuation_percentage"
                                    name="_valuation_percentage"
                                    type="text"
                                    class="form-control valuation_percentage-input"
                                />
                                <span class="u-mm">%</span>
                            </div>

                            <div class="common--input-filed unit-input mb-1">
                                <input
                                    v-model="addForm.forecast_value_per_unit"
                                    aria-describedby="_forecast_value_auto_desc"
                                    id="_forecast_value_per_unit_auto"
                                    :readonly="handleValueReadonly('forecast_value_per_unit')"
                                    name="_forecast_value_auto"
                                    @input="handleValueChanging"
                                    :type="handleValueReadonly('forecast_value_per_unit') ? 'text' : 'number'"
                                    placeholder="Per Unit"
                                >
                                <span class="u-mm">{{ addForm.single_select_v_models.used_currency ? addForm.single_select_v_models.used_currency.icon : '' }}</span>
                            </div>


                            <div class="common--input-filed unit-input mb-1">
                                <input
                                    v-model="addForm.item_details.forecast_value"
                                    aria-describedby="_forecast_value_desc"
                                    id="_forecast_value_auto"
                                    :readonly="handleValueReadonly('forecast_value')"
                                    name="_forecast_value_auto"
                                    @input="handleValueChanging"
                                    :type="handleValueReadonly('forecast_value') ? 'text' : 'number'"
                                    placeholder="Total"
                                >
                                <span class="u-mm">{{ addForm.single_select_v_models.used_currency ? addForm.single_select_v_models.used_currency.icon : '' }}</span>
                            </div>

                        </div>


                    </div>
                </div>
            </asset-info-section>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import multiSelect from '@/mixins/MultiSelect';
import AddNewDisposition from "../../customers/extras/AddNewDispositionType";
import DispositionsShared from '@/mixins/DispositionsShared.mixin';
import LockedAssetWarning from "./LockedAssetWarning";
export default {
    mixins: [multiSelect, DispositionsShared],
    name: "FdpInfo",
    components: {LockedAssetWarning, AddNewDisposition, AssetInfoSection},
    props: ['addForm', 'required_fields', 'listQuery', 'asset_is_ended'],
    data(){
        return {
            value_type: 0,
            value_type_options:[
                {
                    id: 0,
                    name: 'Value for 1 item'
                },
                {
                    id: 1,
                    name: 'Value for (x) items'
                }
            ],
            sustainability_fields:[
                'final_disposition_planning',
                'fdp_status'
            ],
            valuation_fields:[
                'valuation_method', 'forecast_value'
            ]
        }
    },
    computed:{
        selected_final_disposition_planning(){
            return this.addForm.single_select_v_models.final_disposition_planning && this.addForm.single_select_v_models.final_disposition_planning.hasOwnProperty('id') && !['0', -99].includes(this.addForm.single_select_v_models.final_disposition_planning.id);
        },
        asset_quantity() {
            return this.addForm.item_details.quantity;
        },
    },
    watch:{
        asset_quantity(value) {
            this.handleValueChanging();
        },
    },
    methods:{
        markAsChecked(option, event){
            this.addForm.item_details.fdp_status = option.id;
        },
        updatedValuationMethod(option){
            this.addForm.item_details.valuation_method !== option.id ? (this.addForm.item_details.valuation_method = option.id) : (this.addForm.item_details.valuation_method = null);
            this.$parent.$parent.$parent.$parent.handleValuationPercentage();

        },
        getValueTypeTitle(option) {
            if (option.id === 1) {
                return option.name.replace('(x)', this.addForm.item_details.quantity);
            }
            return option.name
        },
        handleValueReadonly(field) {
            if (this.addForm.item_details.valuation_method === 1){
                return true;
            }
            return ((field === 'forecast_value' && this.value_type === 0) || (field === 'forecast_value_per_unit' && this.value_type === 1));
        },
        handleValueChanging() {
            let item_details = this.addForm.item_details,
                quantity = item_details.quantity,
                value = this.addForm.forecast_value_per_unit,
                total_value = item_details.forecast_value;

            if ((!value && !total_value) || (value + '' + total_value) === '') {
                return;
            }

            if (this.value_type === 0) {
                // for one item!
                this.addForm.item_details.forecast_value = (value * quantity).toFixed(2);
            } else {
                // for all
                this.addForm.forecast_value_per_unit = (total_value / quantity).toFixed(2);
            }
        },
    }
}
</script>


