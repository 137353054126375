<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="is_saving_enabled = true"
             hide-footer>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        Location Dimension
                    </div>
                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_fields"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const shoot_dayResource = new Resource('api/assetflow/shoot_days');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import ChangeCommonFields from "../extras/ChangeCommonFields";
import AFButton from "@/components/AFButton";

export default {
    mixins: [multiSelect],
    name: "AddNewSpLocationDimension",
    props: ['modal_title', 'item_type'],
    components: {AFButton, ChangeCommonFields, DatePicker},
    data(){
        return {
            addForm:{
                name: '',
                id: ''
            },
            required_fields:{
                name: {
                    key: 'name',
                    label: 'Name',
                    type: 'text'
                },
                id: {
                    key: 'id',
                    label: 'Value',
                    type: 'text'
                },
            },
            selected_item: null,
            required_dimension: null,
            current_dimension_list: [],
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.id = this.selected_item.id;
                this.addForm.name = this.selected_item.name;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        setDimensionInfo(required_dimension, current_dimension_list){
            this.required_dimension = required_dimension;
            this.current_dimension_list = current_dimension_list;
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            let found_item = false;

            let new_item = {
                id: this.addForm.id,
                name: this.addForm.name
            };

            if (this.current_dimension_list.length > 0){
                found_item = _.find(this.current_dimension_list, function(o) { return o.id === new_item.id || o.name === new_item.name; });
            }
            if (found_item){
                this.presentToast("Error!",
                    'Dimension name\\value already in use',
                    'danger',
                    1500,
                    true,
                    true);
                return;
            }


            // TODO:// shall check if existing?

            this.presentToast("Success!",
                (this.selected_item ? 'Edited' : 'Added') + ' Successfully',
                'success',
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.required_dimension);
        }
    }
}
</script>
