<template>
    <div class="step-form-content-block row">
        <add-new-purchase-order
            ref="add-new-purchaseOrder"
            item_type='purchaseOrder'
            :only_po="true"
            modal_title="Create New Purchase Order"
            @on-new-item-adding="addedNewPO"
        >
        </add-new-purchase-order>


        <add-new-acquisition-type
            custom_id="aa_form_"
            ref="add-new-acquisition-type"
            item_type='acquisition_type'
            modal_title="Create New Acquisition Type"
            @on-new-item-adding="addNewItemToOptions"
        >
        </add-new-acquisition-type>

        <add-new-supplier
            ref="add-new-supplier"
            item_type='supplier'
            modal_title="Create New Supplier"
            @on-new-item-adding="addNewItemToOptions"
        >
        </add-new-supplier>

        <AddNewLedger
            ref="add-new-buyer-ledger"
            item_type='buyer-ledger'
            modal_title="Create New Buyer (P-Card)"
            @on-new-item-adding="addNewItemToOptions"
        >
        </AddNewLedger>
        <AddNewLedger
            ref="add-new-payment-ledger"
            item_type='payment-ledger'
            modal_title="Create New Payment Account"
            @on-new-item-adding="addNewItemToOptions"
        >
        </AddNewLedger>
        <AddNewLedger
            ref="add-new-ledger"
            item_type='acq-ledger'
            modal_title="Create New Ledger Account"
            @on-new-item-adding="addNewItemToOptions"
        >
        </AddNewLedger>


        <AcquisitionAssetModal
            ref="add-new-asset"
            item_type='asset'
            modal_title="Add New Asset"
            :acquisition_info="addForm.item_details"
            @on-new-item-adding="appendToList"
        />

        <AcquisitionLineModal
            ref="add-new-line"
            item_type='acquisition_line'
            modal_title="Add New Line"
            :acquisition_info="addForm.item_details"
            @on-new-item-adding="appendToList"/>


        <div v-for="(column_sections, col_key) in columns_class"
             :key="col_key"
             :class="column_sections.column_class"
        >

            <asset-info-section
                v-for="(section_group, key) in column_sections.fields_list"
                :key="key"
                :title="section_group.name ? section_group.name : ''"
                :add-form="addForm"
                :selected_fields="section_group.fields"
                :required_fields="required_fields"
                :received_toggle="section_group.minimized"
            >

                <div class="step-common-info-right-block" v-if="key === 'images_section'">
                    <div class="st-drop-file-block">
                        <div class="st-drop-file-box">
                            <vue-dropzone id="imagesgallery"
                                          ref="myVueDropzone"
                                          :use-custom-slot="true"
                                          :include-styling="false"
                                          :options="dropzoneOptions"
                                          @vdropzone-thumbnail="thumbnail"
                                          @vdropzone-file-added="fileAdded"
                                          @vdropzone-removed-file="fileRemoved"
                                          @vdropzone-max-files-exceeded="maxFilesExceeded"
                            >
                                <div class="dz-message" data-dz-message>
                                    <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                        <div class="st-upload-title">
                                            <h3>Drop files here or click to upload</h3>
                                            <p>... or click to select a file from your computer</p>
                                        </div>
                                    </div>
                                </div>
                            </vue-dropzone>
                            <b-button
                                @click="$refs.myVueDropzone.$el.click();"
                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                            >Add New Image</b-button>
                            <b-button
                                @click="resetDropZone"
                                variant="outline-danger"
                                class="btn btn-sm mt-1 btn-block"
                            >Remove All</b-button>
                        </div>
                    </div>
                </div>

                <StepCommonInfoBlock
                    v-else
                    :add-form="addForm"
                    :changed-radio-value="changedRadioValue"
                    :mark-as-checked="markAsChecked"
                    :required_fields="required_fields"
                    :section_group="section_group"
                    :my_lists_values="my_lists_values"/>

            </asset-info-section>
        </div>


        <div class="col-xl-12 mt-2">
            <asset-info-section title="Line Items">
                <div class="step-common-info-block">
                    <po-lines
                        v-if="addForm.line_items.length > 0"
                        :items="addForm.line_items"
                        object_type="parts"
                        :keys_list="line_items_key_list">

                        <template v-slot:lines_actions="{ index, item }">


                            <div class="btn-group bl-auto">
                                <button type="button" class="btn btn-none dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                    <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <b-dropdown-item @click="editItemInList(item, index)">
                                        Edit {{item.line_type}}
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="duplicateItemInList(item, index)">
                                        Duplicate {{item.line_type}}
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="removeItemFromList(index)" variant="danger">
                                        Remove {{item.line_type}}
                                    </b-dropdown-item>
                                </div>
                            </div>

                        </template>
                    </po-lines>
                    <span v-else>No Items Yet. <a @click="showAssetModal" href="#">Add An Asset?</a></span>

                    <div class="text-center">
                        <a class="btn af-btn-accent af-accent btn-sm add-asset-modal-btn" @click="showAssetModal">
                            Add Asset
                        </a>
                        <a class="btn af-btn-accent af-accent btn-sm add-line-modal-btn" @click="showLineModal">
                            Add Line
                        </a>
                    </div>

                </div>
            </asset-info-section>

            <asset-info-section title="Summary">
                <div class="step-common-info-block">
                    <LineItemTotalsBlocks
                        :line_items="addForm.line_items"
                        :item_details="addForm.item_details"x
                        :item_type="item_type"
                    />
                </div>
            </asset-info-section>
        </div>

    </div>
</template>

<script>
import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";
import AssetInfoSection from "../../../assets/assetForm/AssetInfoSection";
import AddNewPurchaseOrder from "../../extras/AddNewPurchaseOrder";
import Resource from '@/api/resource';
import AddNewAcquisitionType from "../../extras/AddNewAcquisitionType";
import StepCommonInfoBlock from "./StepCommonInfoBlock";
import PoLines from "../../../items/widgets/detailsViews/PoLines";
import LineItemTotalsBlocks from "./LineItemTotalsBlocks";
import AcquisitionAssetModal from "./AcquisitionModals/AcquisitionAssetModal";
import AcquisitionLineModal from "./AcquisitionModals/AcquisitionLineModal";
import AddNewSupplier from "../../extras/AddNewSupplier";

const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');
import {AcquisitionLineItemKeysList} from "@/views/assetflow/form_data/AcquisitionLineItemKeysList";
import AddNewLedger from "../../extras/AddNewLedger";

const referenceResource = new Resource('api/assetflow/assets/getNewRef');
import AMDepartmentShared from "@/mixins/AMDepartmentShared.mixin";

export default {
    name: "CostumeAssetAcquisitionStep",
    components: {
        AddNewLedger,
        AddNewSupplier,
        AcquisitionAssetModal,
        AcquisitionLineModal,
        LineItemTotalsBlocks,
        PoLines, StepCommonInfoBlock, AddNewAcquisitionType, AddNewPurchaseOrder, AssetInfoSection},
    mixins:[dropzoneMixin, AMDepartmentShared],
    props:['addForm', 'required_fields', 'item_type', 'my_lists_values'],
    data(){
        return {
            first_time: true,
            listQuery: {
                page: 1,
                limit: 100
            },
            line_items_key_list: AcquisitionLineItemKeysList,
            line_items: [],
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-6',
                    fields_list: {
                        department_section: {
                            name: 'Department',
                            minimized: false,
                            fields: [
                                'department_id',
                            ]
                        },
                        buyer_section: {
                            name: 'Buyer',
                            minimized: false,
                            fields: [
                                'buyer_id',
                                'buyer_ledger_id',
                                'notes',
                                'payment_date',
                                'payment_ledger_id'
                            ]
                        },
                        supplier_section: {
                            name: 'Supplier',
                            minimized: false,
                            fields: [
                                'supplier_id',
                                'purchase_order_id',
                                'item_return_policy',
                            ]
                        },
                        // costume_section: {
                        //     name: 'Costume',
                        //     minimized: false,
                        //     fields: [
                        //         'character_id',
                        //         'ledger_id',
                        //     ]
                        // },
                    }
                },
                right_column: {
                    title: '',
                    column_class: 'col-xl-6',
                    fields_list: {
                        images_section: {
                            name: 'Receipt Upload',
                            minimized: false,
                        },
                        advanced_section: {
                            name: 'Advanced',
                            minimized: true,
                            fields: [
                                'currency',
                                'acquisition_date',
                                'acquisition_method',
                                'acquisition_type',
                                'supplier_reference',
                                'payment_reference',
                                'payment_type',
                                'payment_status',
                                'confirmation_status',
                            ]
                        },
                    }
                },
            }
        }
    },
    mounted() {
    },
    computed: {
        selected_supplier() {
            return this.addForm.item_details.supplier_id;
        },
        selected_payment_date() {
            return this.addForm.item_details.payment_date;
        },
    },

    watch: {
        selected_supplier: {
            deep: true,
            handler: function (newValue) {
                if (newValue && newValue.hasOwnProperty('reference') && newValue.reference && newValue.reference !== ''){
                    this.addForm.item_details.supplier_reference = newValue.reference;
                }

            }
        },
        selected_payment_date: {
            deep: true,
            handler: function (newValue) {
                if (newValue){
                    this.$set(this.addForm.item_details, 'acquisition_date', newValue);
                }
            }
        },
    },
    created() {

        this.line_items_key_list.net_total.title_func = this.toUserCurrencyValueTitle;
        this.line_items_key_list.net_total.value_func = this.getCurrencyByValueText;

        this.line_items_key_list.tax.title_func = this.toUserCurrencyValueTitle;
        this.line_items_key_list.tax.value_func = this.getCurrencyByValueText;

        this.line_items_key_list.total_price.title_func = this.toUserCurrencyValueTitle;
        this.line_items_key_list.total_price.value_func = this.getCurrencyByValueText;

        let addForm = this.addForm;
        _.forOwn(this.required_fields, function(field, key) {
            if (field.hasOwnProperty('default_value')){

                if (key === 'payment_date'){

                    const today = new Date();
                    const yyyy = today.getFullYear();
                    let mm = today.getMonth() + 1; // Months start at 0!
                    let dd = today.getDate();

                    if (dd < 10) dd = '0' + dd;
                    if (mm < 10) mm = '0' + mm;

                    let todays_date = dd + '-' + mm + '-' + yyyy;
                    addForm.item_details.acquisition_date = todays_date;
                    addForm.item_details[key] = todays_date;

                }else{
                    addForm.item_details[key] = field.default_value;
                }
            }
        } );

        this.addForm.item_details = Object.assign({}, addForm.item_details);
    },
    methods:{
        appendToList(received_item, index=null){
            let new_item = _.cloneDeep(received_item);

            if (index !== null){
                this.addForm.line_items.splice(index, 1, new_item);
            }else{
                this.addForm.line_items.push(new_item);
            }
        },
        editItemInList(received_item, index){
            let item = Object.assign({}, received_item);

            let float_values = [
                'quantity',
                'total_price',
                'unit_price',
                'net_total',
                'unit_net',
                'tax_rate',
                'tax',
                'unit_tax'
            ];
            for (const floatValueKey in float_values) {
                let key = float_values[floatValueKey];
                if (item[key] !== undefined){
                    item[key] = parseFloat(item[key]);
                }
            }


            if (item.line_type === 'Asset'){
                // shall open asset modal

                let single_select_keys = [
                    'category_id',
                    'character_id',
                    'ledger_id',
                    'asset_template_id',
                    'create_assets_automatically',
                    'create_assets_asset_quantities',
                    'condition',
                    'waste_stream_type',
                    'status',
                    'asset_usage',
                    'is_hero',
                    'is_asset',
                    'folder_id',
                    'department_id',
                    'contact_id',
                    'address_id',
                    'location_id',
                    'final_disposition_planning',
                    'fdp_status',
                    'waste_stream_id',
                    'colours',
                    'composition',
                    'tag_ids'
                ];
                for (const singleSelectKeysKey in single_select_keys) {
                    let key = single_select_keys[singleSelectKeysKey];
                    let info_key = single_select_keys[singleSelectKeysKey] + '_info';
                    if (item[info_key] !== undefined){
                        item[key] = item[info_key];
                    }
                }

                this.$refs['add-new-asset'].setSelectedItem(item, index);
                this.$bvModal.show('create-asset');
            }else{
                // shall open line modal
                this.$refs['add-new-line'].setSelectedItem(item, index);
                this.$bvModal.show('create-acquisition_line');
            }
        },
        removeItemFromList(index){
            this.addForm.line_items.splice(index, 1);
        },
        async duplicateItemInList(item, index){
            let required_item = Object.assign({}, item);

            if (required_item.line_type === 'Asset'){
                let params = {
                    item_type: 'assets'
                }
                const { data } = await referenceResource.store(params);

                let new_reference = data.data.items;
                required_item.reference = new_reference;
                let name_suffix = '{' + new_reference + '}';
                if (required_item.name && required_item.name !== ''){
                    if (required_item.name.indexOf("{") !== -1){
                        required_item.name = required_item.name.replace(/{.*}/, name_suffix);
                    }else{
                        required_item.name = required_item.name + ' ' + name_suffix
                    }
                }
            }

            this.appendToList(required_item);
        },
        showAssetModal(){

            this.$refs['add-new-asset'].setSelectedItem(null, null);
            this.$bvModal.show('create-asset');
        },
        showLineModal(){
            this.$refs['add-new-line'].setSelectedItem(null, null);
            this.$bvModal.show('create-acquisition_line');
        },
        addNewItemToOptions(new_item, item_type){
            if (new_item) {

                let item_type_keys = {
                    'ledger': 'ledger_id',
                    'payment-ledger': 'payment_ledger_id',
                    'buyer-ledger': 'buyer_ledger_id',
                }

                // should append this to the list
                let item_type_key = item_type;
                if (item_type === 'supplier'){
                    item_type_key = 'supplier_id';
                    this.required_fields.purchase_order_id.options = [{
                        id: -99,
                        name: 'Add New'
                    }];
                }else if (Object.keys(item_type_keys).includes(item_type)){
                    item_type_key = item_type_keys[item_type];
                }

                let new_option = {id: new_item.id, name: new_item.name};
                // if (item_type === 'acquisition_type'){
                //     this.addForm.added_acquisition_types.push(new_option.id);
                // }
                this.required_fields[item_type_key].options.push(new_option);

                this.addForm.item_details[item_type_key] = new_option;
            }
        },
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getPOsListFor(supplier_id, selected_po_id) {
            this.isLoadingPOs = true;
            this.handleQueryItems();
            this.listQuery.supplier_id = supplier_id;
            this.listQuery.only_ids = true;
            const {data} = await purchaseOrderResource.list(this.listQuery);
            let add_new_option = [{
                id: -99,
                name: 'Add New'
            }];

            let pos_list = data.data.items;
            this.required_fields.purchase_order_id.options = add_new_option.concat(pos_list);

            if (selected_po_id){
                let po_options = this.required_fields.purchase_order_id.options;
                let selected_po_index = po_options.map(function(e) { return e.id; }).indexOf(selected_po_id);
                if (selected_po_index > -1){
                    this.$set(this.addForm.item_details, 'purchase_order_id', po_options[selected_po_index]);
                }
            }else{
                this.$set(this.addForm.item_details, 'purchase_order_id', null);
            }
            this.isLoadingPOs = false;
        },
        addedNewPO(new_item){
            if (new_item.supplier_id){
                this.required_fields.purchase_order_id.options.push(new_item);
                this.addForm.item_details.purchase_order_id = new_item;
            }
        },

        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },
        changedRadioValue(field, event) {
            let option = this.addForm.item_details[field];

            if (option && option.hasOwnProperty('id') && option.id === -99) {
                // shall add new
                let custom_id = '';
                switch (field) {
                    case 'acquisition_type':
                        this.$refs['add-new-acquisition-type'].setSelectedItem(null);
                        custom_id = 'aa_form_acquisition_type';
                        break;
                    case 'supplier_id':
                        this.$refs['add-new-supplier'].setSelectedItem(null);
                        custom_id = 'supplier';

                        break;
                    case 'ledger_id':
                        this.$refs['add-new-ledger'].setSelectedItem(null);
                        custom_id = 'ledger';
                        break;
                    case 'buyer_ledger_id':
                        this.$refs['add-new-buyer-ledger'].setSelectedItem(null);
                        this.$refs['add-new-buyer-ledger'].setLedgerType('buyer');
                        custom_id = 'buyer-ledger';
                        break;
                    case 'payment_ledger_id':
                        this.$refs['add-new-payment-ledger'].setSelectedItem(null);
                        this.$refs['add-new-payment-ledger'].setLedgerType('bank');
                        custom_id = 'payment-ledger';
                        break;
                    case 'purchase_order_id':
                        let supplier_id = this.addForm.item_details.supplier_id ?? null;

                        if (supplier_id){
                            let selectedValues = {
                                supplier_id: supplier_id
                            };

                            this.$refs['add-new-purchaseOrder'].setSelectedItem(selectedValues);
                            this.$refs['add-new-purchaseOrder'].setSuppliersList([supplier_id]);
                        }

                        custom_id = 'purchaseOrder';
                        break;
                        default:

                            console.log("field");
                            console.log(field);

                            break;
                }
                this.addForm.item_details[field] = null;
                this.$bvModal.show('create-' + custom_id);
                if (field === 'supplier_id'){
                    this.setupAddressLookup('supplier');
                }
            }else if (field === 'supplier_id'){
                this.$set(this.addForm.item_details, 'supplier_id', this.addForm.item_details.supplier_id);
                this.getPOsListFor(option.id);
            }
        },

        changedSelectField(section_field, event) {
        },

    }
}
</script>

<style>

#imagesgallery {
    font-family: sans-serif;
    cursor: pointer;
    transition: background-color .2s linear;
    min-height: 300px !important;
    border: 2px dashed #ced4da !important;
    background: #fff !important;
    border-radius: 6px !important;
}

#imagesgallery .dropzone-custom-content {
    font-size: 24px;
    color: #ced4da;
    margin: 3em 0 !important;
    text-align: center;
}

#imagesgallery .dz-preview {
    display: inline-block
}

#imagesgallery .dz-preview .dz-image {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-top: 20px;
}

#imagesgallery .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}

#imagesgallery .dz-preview .dz-image > img {
    width: 100%;
}

#imagesgallery .dz-preview .dz-details {
    color: inherit;
    transition: opacity .2s linear;
    text-align: center;
}

#imagesgallery .dz-success-mark, .dz-error-mark {
    display: none;
}

</style>
