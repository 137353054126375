<template>
    <div>
        <div class="row">
            <div class="col-12">
                <b-button variant="outline-success" @click="handleExport" >
                    <i class="bx bx-export mr-2"></i> Export CSV
                </b-button>
            </div>
        </div>

        <!-- Table -->
        <div class="table-responsive mb-0">
            <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            ></b-table>
        </div>

    </div>
</template>

<script>
    export default {
        name: "CsvLayout",
        props: ['tableData', 'fields'],
        data() {
            return {
                currentPage: 1,
                perPage: 0,
                sortBy: "id",
                sortDesc: false
            };
        },
        methods: {
            handleExport(){
                alert("Shall Export Items");
            }
        }
    }
</script>


