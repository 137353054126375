const FirstPath = window.location.pathname.split('/')[1];
export const CharacterMetaKeys = {
    change_link:{
        label: 'Change',
        cast_value: function (item, key) {
            return item[key].replace(/ITEM_TYPE/g, FirstPath);
        }
    },
    character_link:{
        label: 'Character',
        cast_value: function (item, key) {
            return item[key].replace(/ITEM_TYPE/g, FirstPath);
        }
    },
    parts_list:{
        label: 'Scenes',
        cast_value: function (item, key) {
            return item[key].replace(/ITEM_TYPE/g, FirstPath);
        }
    },
    description:{
        label: 'Description',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    status:{
        label: 'Status',
        cast_value: function (item, key) {
            return item[key] === 1 ? 'Established' : 'Planned';
        }
    },
};

