<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <b-form-group label="Choose User" label-for="users_list">

                <multiselect
                    id="users_list"
                    v-model="$v.form.item.$model"
                    :options="received_items_list"
                    placeholder="Choose User"
                    :show-labels="false"
                    :loading="listLoading"
                    :state="validateState('item')"
                    track-by="id"
                    label="name"
                    aria-describedby="live-feedback"
                >
                    <span slot="noResult">No data found.</span>
                </multiselect>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('users-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';

export default {
    name: "AddNotifiedUser",
    mixins: [validationMixin, multiSelect],
    props: {
        item_info: Object,
        is_customer: {
            type: Boolean,
            default: false
        },
        item_type: {
            type: String,
            default: ''
        },
        item_id: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            selected_item: "",
            form: {
                item: ""
            },
            received_items_list: [],
            listLoading: false,
            listQuery:{
                page: 1,
                limit: 5000
            },
        }
    },
    validations: {
        form: {
            item: {
                required
            }
        }
    },
    created() {
        this.getUsersList();
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                item: ""
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        submit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            console.log("Form submitted? " + this.form.item + " >> " + this.item_info);
            let assignData = {
                user_id: this.form.item.id,
                sp_id: this.current_sp.id,
            };
            if (this.is_customer){
                assignData.entity_type = 'customer';
                assignData.customer_id = this.item_info.id;
            }
            axios({
                method: 'post',
                url: '/api/assetflow/service_providers/addUserSubscriber',
                baseURL: '/',
                data: assignData,
            }).then(response => {
                const { status, msg } = response.data.data;
                const toastType = status ? 'success' : 'danger';
                const toastMessage = status ? 'Success!' : 'Failed!';

                this.presentToast(
                    toastMessage,
                    msg,
                    toastType,
                    3000,
                    true,
                    true
                );

                if (status) {
                    this.$emit('refresh-users-list');
                }
            }).catch(error => {
                console.log(error);
            });


        },
        async getUsersList(owner_id='', item_type='') {
            this.listLoading = true;
            this.listQuery.item_type = this.item_type;
            this.listQuery.item_id = this.item_id;

            let owner_text = '';
            if (owner_id !== '') {
                owner_text = '-' + owner_id;
                this.listQuery.owner_id = owner_id;
                this.listQuery.owner_item_type = item_type;
            }
            this.listQuery.sort_by = 'name';
            this.listQuery.sort_dir = 'asc';
            this.listQuery.is_short_list = 1;
            this.listQuery.is_for_subscribers_list = 1;
            this.listQuery.customer_id = this.item_info.id;

            const userResource = new Resource('api/assetflow/users');

            const { data } = await userResource.list(this.listQuery);

            let entity_subscriber_ids = this.item_info.entity_subscribers.map(function (e) {
                return e.user.id;
            }); // shall get user id
            this.received_items_list = data.data.items.filter((user) => {
                return !entity_subscriber_ids.includes(user.id);
            }).map(function (user) {
                if (user.hasOwnProperty('entity_name') && user.entity_name){
                    user.name = user.entity_name + ' => ' + user.name;
                }
                return user;
            });

            this.listLoading = false;
        },

    }
}
</script>
