<template>
    <div>
        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <div class="products" v-if="item_info_asset_images && item_info_asset_images.length > 0">

            <EditImageNotes
                ref="edit-image-notes"
                item_type="ap-image-notes"
                modal_title="Edit Image Notes"
                :with_item_details="false"
                @on-new-item-adding="addNewImageNotes"
            />


            <div class="product-row grid af-images">
                <div class="product-item"
                     v-for="(image, index) in item_info_asset_images"
                     :key="index"
                >
                    <div class="product-block">
                        <!-- image-->
                        <div class="product-image">
                            <b-carousel class="product-image-slider"
                                        style="text-shadow: 0px 0px 2px #000"
                                        :interval="0"
                            >
                                <a @click="() => showCaptiomImg(index, item_info_asset_images)">
                                    <my-b-carousel-slide :with_no_controls="true" :image_index="index" :images_count="item_info_asset_images.length" :src="item_info_asset_images[index]"/>
                                </a>
                            </b-carousel>
                        </div>

                        <!-- details -->
                        <ImageObjectExtraInfo
                            :image="image"
                            :index="index"
                            :perform-action="imagePerformAction"
                            object_type="assets"
                            @on-save-item-image="refreshItemImage"
                            :image_size="(image_sizes.hasOwnProperty('media_tab') ? image_sizes.media_tab : '')"
                            :item_type="item_info.current_page_type"/>

                    </div>
                </div>
            </div>
        </div>

        <div v-else class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Media found here at the moment.</h4>
        </div>
    </div>

</template>

<script>


import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import MyBCarouselSlide from "./AssetPassportBlocks/MyBCarouselSlide";
import ImageObjectExtraInfo from "./ImageObjectExtraInfo";
import EditImageNotes from "../../../../customers/extras/EditImageNotes";

export default {
    mixins:[ImageGallery],
    name: "AssetPassportMedia",
    components: {EditImageNotes, ImageObjectExtraInfo, MyBCarouselSlide, VueEasyLightbox},
    props:['item_info'],
    data(){
        return {
        }
    },
    created() {
        this.gallery_object_type = 'asset_passports';

    },
    mounted(){

    },
    methods:{

    },
    computed:{
        cAFID_info(){
            if (this.item_info.hasOwnProperty('cAFID_info')){
                return this.item_info.cAFID_info;
            }
            return this.item_info;
        },
        item_info_asset_images(){
            if (!this.cAFID_info || !this.cAFID_info.filtered_asset_images){
                return [];
            }
            return this.cAFID_info.filtered_asset_images.filter(function (e) {
                return e.add_to_ap === 1;
            });
        }
    }
}
</script>


