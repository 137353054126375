<script>

export default {
    data() {
        return {
            email: "",
            password: "",
            tryingToLogIn: false,
            isAuthError: false,
        };
    },
    props: {
        submitUrl: {
            type: String,
            required: true,
        },
        authError: {
            type: String,
            required: false,
            default: () => null,
        },
    },
    methods: {},
    mounted() {
        document.body.classList.add("auth-body-bg");
    },
};
</script>

<template>
    <div class="mt-4">
        <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{authError}}</b-alert>
        <b-form class="p-2" :action="submitUrl" method="POST">
            <slot />
            <b-form-group id="input-group-1" label="Email" label-for="input-1">
                <b-form-input
                    id="input-1"
                    name="email"
                    v-model="email"
                    type="text"
                    placeholder="Enter email"
                ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Password" label-for="input-2">
                <b-form-input
                    id="input-2"
                    v-model="password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                ></b-form-input>
            </b-form-group>
            <div class="custom-control custom-checkbox">
                <input id="customControlInline" type="checkbox" class="custom-control-input" />
                <label class="custom-control-label" for="customControlInline">Remember me</label>
            </div>
            <div class="mt-3">
                <b-button type="submit" variant="primary" class="btn-block">Log In</b-button>
            </div>
            <div class="mt-4 text-center">
                <h5 class="font-size-14 mb-3">Sign in with</h5>

                <ul class="list-inline">
                    <li class="list-inline-item">
                        <a
                            href="javascript: void(0);"
                            class="social-list-item bg-primary text-white border-primary"
                        >
                            <i class="mdi mdi-facebook"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a
                            href="javascript: void(0);"
                            class="social-list-item bg-info text-white border-info"
                        >
                            <i class="mdi mdi-twitter"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a
                            href="javascript: void(0);"
                            class="social-list-item bg-danger text-white border-danger"
                        >
                            <i class="mdi mdi-google"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="mt-4 text-center">
                <a href="/password/reset" class="text-muted">
                    <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                </a>
            </div>
        </b-form>
    </div>
</template>


