<template>
    <div :class="('col-xl-' + columns_count_withclasses)">
        <div class="cm_common_asset_box">
            <div class="cm_asset_head" :class="is_shown ? 'this_active' : ''" @click.self="is_shown = !is_shown">
                <h3 v-if="block_title_html" v-html="block_title_html"></h3>
                <h3 v-else><i v-if="block_title_icon !== ''" :class="block_title_icon"></i> {{ block_title }}</h3>
                <div class="cm_asset_action_fire" v-if="action_required_title && action_required_title !== ''">
                    <a href="javascript:void(0)" @click="runAction">{{action_required_title}}</a>
                </div>
            </div>

            <div class="cm_asset_details_upload_box" v-show="is_shown">
                <div :class="box_colour_v ? (box_colour_v !== '-' ? box_colour_v : '') : 'common_sub_col_bg color_v_accent'">
                    <slot name="all_content">
                        <div class="textarea_and_upload template-overview-block">
                            <div class="card">
                                <div class="card-body">
                                    <slot name="block_content">
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewCustomGenericBlock',
    props: ['columns_count_withclasses', 'block_title', 'block_title_icon', 'block_title_html', 'box_colour_v', 'action_required', 'action_required_title'],
    data(){
        return {
            is_shown: true
        }
    },
    methods:{
        runAction(){
            if (this.action_required){
                this.action_required();
            }
        }
    }
}
</script>

