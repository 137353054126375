const FirstPath = window.location.pathname.split('/')[1];
export const DepartmentKeysList = {
    id: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 e_id",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 e_name",
        show_on_mobile: true,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {
            return item.created_at ? 'Created at: ' + Vue.options.filters.moment(item.created_at, 'MMMM Do YYYY') : '';
        },
        value_func: function (index, item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/departments/' + item.id + '/view">' + Vue.options.filters.titleize(item[key]) + '</a>';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 e_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                {
                    name: 'Inactive',
                    class: 'status-btn-disposed',
                },
                {
                    name: 'Active',
                    class: 'status-btn-active',
                }
            ];
            return '<span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span>';
        }
    },
    dep_users: {
        key: 'dep_users',
        label: "Department Users",
        class: "col-lg-2 s_dep_users",
        show_on_mobile: false,
        case: 'custom_badges_list',
        url_item_type: 'users',
        id_value: 'id',
        name_value: 'name'
    },
    dep_heads: {
        key: 'dep_heads',
        label: "Department Heads",
        class: "col-lg-2 s_dep_heads",
        show_on_mobile: false,
        case: 'custom_badges_list',
        url_item_type: 'users',
        id_value: 'id',
        name_value: 'name'
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets Total",
        class: "col-lg-1 s_assets_count",
        show_on_mobile: false,
        case: ''
    },
    assets_sum_quantity: {
        key: 'assets_sum_quantity',
        label: "Assets Qty",
        class: "col-lg-1 s_assets_sum_quantity",
        show_on_mobile: false,
        case: ''
    },
    assets_sum_value: {
        key: 'assets_sum_value',
        label: "Cost",
        class: "col-lg-1 s_assets_sum_value",
        show_on_mobile: false,
        case: 'custom_tooltip'
    },
};
