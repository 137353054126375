<template>
    <div class="step-form-content-block row">
        <add-new-purchase-order
            ref="add-new-purchaseOrder"
            item_type='purchaseOrder'
            :only_po="true"
            modal_title="Create New Purchase Order"
            @on-new-item-adding="addedNewPO"
        >
        </add-new-purchase-order>
        <div class="col-xl-12 mt-4">
            <asset-info-section
                v-for="(section_group, key) in section_fields"
                :key="key"
                :title="section_group.name"
                :add-form="addForm"
                :selected_fields="section_group.fields"
                :required_fields="required_fields"
                :received_toggle="section_group.minimized"
            >
                <div class="step-common-info-block">
                    <div class="st--common--box" v-for="section_field in section_group.fields">
                        <div class="common-st-title-label" v-if="required_fields[section_field].type !== 'checkbox'">
                            <h5>{{ required_fields[section_field].name | titleize}}</h5>
                        </div>
                        <div class="st-status-radio-box" v-if="required_fields[section_field].type === 'radio-select'">
                            <div class="st-radio-row" v-if="required_fields[section_field].options.length > 0">
                                <div v-for="(option, index) in required_fields[section_field].options"
                                     :key="index"
                                     class="col-3">
                                    <div class="cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                        <label>
                                            <input
                                                v-model="addForm.item_details[section_field]"
                                                :key="index"
                                                :id="'_'+index"
                                                :name="section_field"
                                                :value="option"
                                                @click="changedRadioValue(section_field, option, $event)"
                                                type="radio"/>
                                            <div class="st-radio-box">
                                                <span></span>
                                                <p v-if="section_field === 'purchase_order_id' && option.uid" >{{option.uid}}</p>
                                                <p v-else>{{ (option.name || option.description || option.uid) | titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="st-radio-row"
                                 v-else-if="required_fields[section_field].hasOwnProperty('name_plural') && required_fields[section_field].hasOwnProperty('link_name')">
                                <div class="col-12">
                                    There are no {{ required_fields[section_field].name_plural | titleize}} yet, <a
                                    :href="'/' + item_type + '/' + required_fields[section_field].link_name">Add New?</a>
                                </div>
                            </div>
                        </div>

                        <div v-if="['single-select', 'multi-select'].includes(required_fields[section_field].type)">
                            <multiselect
                                v-model="addForm.item_details[section_field]"
                                track-by="id"
                                :custom-label="section_field === 'purchase_order_id' ? POWithUIDs : CustomNameOrDesc"
                                :placeholder="required_fields[section_field].name | titleize"
                                class="mySingleSelect"
                                :options="required_fields[section_field].options"
                                @input="changedSelectField(section_field, $event)"
                                :allow-empty="required_fields[section_field].type === 'single-select'"
                                deselect-label=""
                                :multiple="required_fields[section_field].type === 'multi-select'"
                            >
                            </multiselect>
                        </div>

                        <div class="common--input-filed"
                             v-else-if="['text', 'number'].includes(required_fields[section_field].type)">
                            <input v-model="addForm.item_details[section_field]" placeholder=""
                                   :type="required_fields[section_field].type">
                        </div>
                        <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'textarea'">
                            <textarea v-model="addForm.item_details[section_field]"
                                      :placeholder="required_fields[section_field].name | titleize"></textarea>
                        </div>
                        <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'date'">
                            <date-picker
                                v-model="addForm.item_details[section_field]"
                                lang="en" :first-day-of-week="1"
                                format="DD-MM-YYYY"
                                value-type="DD-MM-YYYY"
                                :placeholder="required_fields[section_field].name | titleize"/>
                        </div>
                        <div v-if="required_fields[section_field].type === 'item_images'" class="st-drop-file-block">
                            <div class="st-drop-file-box">
                                <vue-dropzone id="imagesgallery"
                                              ref="myVueDropzone"
                                              :use-custom-slot="true"
                                              :include-styling="false"
                                              :options="dropzoneOptions"
                                              @vdropzone-thumbnail="plain_thumbnail"
                                              @vdropzone-file-added="fileAdded"
                                              @vdropzone-removed-file="fileRemoved"
                                              @vdropzone-max-files-exceeded="maxFilesExceeded"
                                >
                                    <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                        <div class="st-upload-title">
                                            <h3>Drop files here or click to upload</h3>
                                            <p>... or click to select a file from your computer</p>
                                        </div>
                                    </div>
                                </vue-dropzone>
                                <b-button
                                    @click="$refs.myVueDropzone[0].$el.click();"
                                    class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                >Add New Image</b-button>
                            </div>
                        </div>

                        <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'checkbox'">

                            <div class="common--input-filed">
                                <div class="dimensions-btn">
                                    <a @click="markAsChecked(section_field, $event)"
                                       :class="addForm.item_details[section_field] === 1 ? 'active' : ''"
                                       class="dimensions-auto">
                                        <span></span>{{ required_fields[section_field].name | titleize }}
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </asset-info-section>
        </div>
    </div>
</template>
<script>
import AssetInfoSection from "../../../assets/assetForm/AssetInfoSection"
import DatePicker from "vue2-datepicker"
import multiSelect from '@/mixins/MultiSelect';

import Resource from '@/api/resource';
const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');
import AddNewPurchaseOrder from "../../extras/AddNewPurchaseOrder";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";

export default {
    name: 'SharedFormFieldsMultipleSections',
    components: {AddNewPurchaseOrder, AssetInfoSection, DatePicker},
    mixins: [multiSelect, dropzoneMixin],
    props: {
        addForm: {},
        section_title: '',
        item_type: '',
        required_fields: {},
        section_fields: {},
        object_type: '',
        item_info: {},
        required_select_lists: {}
    },
    data(){
        return {
            listQuery: {
                page: 1,
                limit: 100
            },
        }
    },
    mounted() {
        if (this.required_select_lists && Object.keys(this.required_select_lists).length > 0){
            this.getSelectFieldsList();
        }
    },
    methods:{

        addedNewPO(new_item){
            if (new_item.supplier_id){
                this.required_fields.purchase_order_id.options.push(new_item);
                this.addForm.item_details.purchase_order_id = new_item;
            }
        },
        changedRadioValue(field, option, event) {
            if (option.id === -99 && ['payment_purchase_order_id'].includes(field)){
                let supplier_id = this.addForm.item_details.supplier_id ?? this.item_info.supplier_info;

                if (supplier_id){
                    let selectedValues = {
                        supplier_id: supplier_id
                    };

                    this.$refs['add-new-purchaseOrder'].setSelectedItem(selectedValues);
                    this.$refs['add-new-purchaseOrder'].setSuppliersList([supplier_id]);
                }
                this.$bvModal.show('create-purchaseOrder');
                this.addForm.item_details[field] = null;
            }
        },
        changedSelectField(field, event) {
            let option = this.addForm.item_details[field];
            if (option && option.id === -99 && field === 'purchase_order_id'){
                let supplier_id = this.addForm.item_details.supplier_id ?? this.item_info.supplier_info;

                if (supplier_id){
                    let selectedValues = {
                        supplier_id: supplier_id
                    };

                    this.$refs['add-new-purchaseOrder'].setSelectedItem(selectedValues);
                    this.$refs['add-new-purchaseOrder'].setSuppliersList([supplier_id]);
                }
                this.$bvModal.show('create-purchaseOrder');
                this.addForm.item_details[field] = null;

            }else if (field === 'supplier_id' && option){
                this.getPOsListFor(option.id);
            }

        },
        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },

        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getPOsListFor(supplier_id, selected_po_id) {
            this.isLoadingPOs = true;
            this.handleQueryItems();
            this.listQuery.supplier_id = supplier_id;
            this.listQuery.only_ids = true;
            const {data} = await purchaseOrderResource.list(this.listQuery);
            let add_new_option = [{
                id: -99,
                name: 'Add New'
            }];

            let pos_list = data.data.items;
            this.required_fields.purchase_order_id.options = add_new_option.concat(pos_list);

            if (selected_po_id){
                let po_options = this.required_fields.purchase_order_id.options;
                let selected_po_index = po_options.map(function(e) { return e.id; }).indexOf(selected_po_id);
                this.addForm.item_details.purchase_order_id = po_options[selected_po_index];
            }else{
                this.addForm.item_details.purchase_order_id = null;
            }
            this.isLoadingPOs = false;
        },

        async getSelectFieldsList(){
            this.lists_values = [];
            this.isLoadingLists = true;
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    required_lists: this.required_select_lists,
                    object_id: this.item_info.id
                }
            }).then(response => {

                this.isLoadingLists = false;
                let form_lists = response.data.data;

                for (const list_key in this.required_select_lists) {
                    let select_name = this.required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'acquisition_modal');
                }

                if (this.addForm.item_details.supplier_id && this.addForm.item_details.purchase_order_id){
                    this.getPOsListFor(this.addForm.item_details.supplier_id.id, this.addForm.item_details.purchase_order_id.id);
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingLists = false;
            });
        },
    }
}
</script>
