<template>
    <div class="row mt-3">
        <div class="col-12 text-left text-md-center col-divider col-md-3 col-lg-3 col-xl-4">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Assets</strong>
                </div>
                <div class="assets-detail" v-if="items_list">{{ items_list.length }}</div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider col-md-3 col-lg-3 col-xl-4">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Asset Qty</strong>
                </div>
                <div class="assets-detail">{{ items_list.reduce((a, b) => +a + +b.quantity, 0) }}</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center text-xl-right col-divider last-child col-md-3 col-lg-3 col-xl-4">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Total</strong>
                </div>

                <div class="assets-detail" v-if="items_list && items_list.length > 0" >
                    <span class="total-all-currencies">{{ totalPrice }}</span>
                </div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'TotalsBlocksForAcquisitionDispositions',
    props: ['items_list', 'total_property_name'],
    computed:{
        totalPrice(){
            return this.getTotalTotals(this.items_list);
        },
    },
    methods:{
    }
}
</script>
