<template>
    <div>

        <task-list
            v-show="false"
            ref="myTaskList"
            :completed_tasks_list="completed_tasks_list"
            :uncompleted_tasks_list="uncompleted_tasks_list"
            :item_info="item_info"
        ></task-list>

        <!-- Intelligence Template -->
        <div class="intelligence-template">
            <div class="row">
                <span class="what-we-know">Here's What We Know...</span>
            </div>
            <div class="row overview-block">
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <custom-generic-block v-if="true" block_title="AFID Properties" columns_count_withclasses="12">
                            <template v-slot:block_content>
                                <afid-properties-block :completed-tasks-total="completedTasksTotal" :item_info="item_info"/>
                            </template>
                        </custom-generic-block>
                        <custom-generic-block v-if="true" block_title="Asset Flow" columns_count_withclasses="12">
                            <template v-slot:block_content>
                                <asset-assetflow-block :item_info="item_info"/>
                            </template>
                        </custom-generic-block>
                        <custom-generic-block v-if="true" :block_title="'Related Items ' + (item_info.pAFID ? '- '+item_info.pAFID + ' ' : '') + '(Splits)'" columns_count_withclasses="12">
                            <template v-slot:block_content>
                                <asset-related-splits :item_info="item_info"/>
                            </template>
                        </custom-generic-block>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row">
                        <custom-generic-block block_title="Details" columns_count_withclasses="12">
                            <template v-slot:block_content>
                                <asset-right-box :item_info="item_info" unique_id="asset-ip-check"/>
                            </template>
                        </custom-generic-block>
                        <custom-generic-block block_title="Current Location" columns_count_withclasses="12">
                            <template v-slot:block_content>
                                <asset-location-block :item_info="item_info"/>
                            </template>
                        </custom-generic-block>
                    </div>

                </div>
            </div>
        </div>

        <!-- Intelligance Template Close-->
    </div>

</template>

<script>

import AssetRightBox from "./AssetRightBox";
import CustomGenericBlock from "./CustomGenericBlock";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import TaskList from "./TaskList";
import AssetLocationBlock from "./AssetBlocks/AssetLocationBlock";
import AfidPropertiesBlock from "./AfidPropertiesBlock";
import AssetAssetflowBlock from "./AssetAssetflowBlock";
import AssetRelatedSplits from "./AssetRelatedSplits";

export default {
    mixins:[AssetTabsShared],
    name: "IpCheckTab",
    components: {
        AssetRelatedSplits,
        AssetAssetflowBlock,
        AfidPropertiesBlock, AssetLocationBlock, CustomGenericBlock, TaskList, AssetRightBox},
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            completedTasksTotal:0,
            completed_tasks_list: [],
            uncompleted_tasks_list: [],
            sustainability_type: '',
        }
    },
    created() {
        switch (this.item_info.status){
            case 'planned':
                this.sustainability_type = 'planning';
                break;
            case 'acquired':
            case 'active':
            case 'in_storage':
                this.sustainability_type = 'readiness';
                let location_task, po_task, volume_task, cost_task, final_disposition_planning_task;
                let staticScore = 100/5;

                if (this.item_info.location_id){
                    let {completed_at, completed_by_user} = this.getUserAndDate([3, 2]);
                    location_task = {
                        title: 'Location Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(location_task);
                }else{
                    location_task = {
                        title: 'Location Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(location_task);
                }

                if (this.item_info.po_item_info){
                    let {completed_at, completed_by_user} = this.getUserAndDate([4, 2]);

                    po_task = {
                        title: 'Purchase Order Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(po_task);
                }else{
                    po_task = {
                        title: 'Purchase Order Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(po_task);
                }

                if (this.item_info.volume){
                    let {completed_at, completed_by_user} = this.getUserAndDate([5, 2]);
                    volume_task = {
                        title: 'Volume Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(volume_task);
                }else{
                    volume_task = {
                        title: 'Volume Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(volume_task);
                }

                if (this.item_info.total_value){
                    let {completed_at, completed_by_user} = this.getUserAndDate([6, 2]);
                    cost_task = {
                        title: 'Cost Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(cost_task);
                }else{
                    cost_task = {
                        title: 'Cost Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(cost_task);
                }


                if (this.item_info.hasOwnProperty('final_disposition_planning') && this.item_info.final_disposition_planning){
                    let {completed_at, completed_by_user} = this.getUserAndDate([7, 2]);
                    final_disposition_planning_task = {
                        title: 'Final Disposition Planning Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(final_disposition_planning_task);
                }else{
                    final_disposition_planning_task = {
                        title: 'Final Disposition Planning Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(final_disposition_planning_task);
                }

                break;
            case 'disposed':
                this.sustainability_type = 'reporting';
                break;
        }
    },
    mounted(){
        this.completedTasksTotal = this.$refs['myTaskList'].getTotalScore();
    },
    methods:{
        getAcquisitionType(){
            let acquisition_type = this.getObjectOf(this.item_info.asset_meta.acquisition_type, this.acquisition_types);
            if (acquisition_type){
                return acquisition_type.name;
            }
            return '-';
        },
        getAcquisitionUsing(){
            return this.item_info.asset_meta.acquired_using === 1 ? 'PO' : '-';
        },
        getUserAndDate(action_types_filters = []) {
            var completed_at, completed_by_user;
            if (this.item_info.asset_posts) {
                var location_posts;
                for (const key in action_types_filters) {
                    let action_type = action_types_filters[key];
                    if (!location_posts || location_posts.length === 0) {
                        location_posts = this.item_info.asset_posts.filter(function (e) {
                            return e.action_type === action_type;
                        });
                    }else{
                        continue;
                    }
                }

                if (location_posts.length > 0) {
                    completed_at = location_posts[0].created_at;
                    completed_by_user = location_posts[0].user;
                }
            }
            return {completed_at, completed_by_user};
        },
    }
}
</script>

