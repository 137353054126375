<template>
    <b-modal :id="'create-'+item_type"
             no-enforce-focus
             modal-class="modal-fullscreen-xl"
             :ref="'create-'+item_type"
             :title="selected_item ? getTitleFor(selected_item, 'parts') : modal_title"
             title-class="font-18"
             @show="addImageToEditor"
             @hide="resetSelectedItem"
             hide-footer>
        <div class="tui-image-editor-wrapper" style="height: 90%;">
            <ImageEditor
                ref="tuiImageEditor"
                :include-ui="true"
                :options="tui_options"
                @addText="onAddText"
                @objectMoved="onObjectMoved"
            ></ImageEditor>
            <div class="text-right mt-2">
                <b-button type="submit" variant="success" :disabled="isSavingItem" v-on:click.once="addItem($event)">
                    <span v-show="!isSavingItem"> Update </span>
                    <div v-show="isSavingItem" class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Saving... {{ uploadProgress ? uploadProgress : '' }}</span>
                    </div>
                </b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>

import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';

import { ImageEditor } from '@toast-ui/vue-image-editor';

import Resource from '@/api/resource';
const imageResource = new Resource('api/assetflow/images/editImage');

export default {

    name: "ImageEditorModal",
    props: ['modal_title', 'item_type'],
    mixins: [],
    components: {ImageEditor},
    data(){
        return {
            tui_options: {
                // for tui-image-editor component's "options" prop
                includeUI: {
                    theme: {
                        'common.bi.image': 'https://amiable-winter-bfcgdhmuv2zd.vapor-farm-f1.com/af_image_path/images/logo-light.png/inside/180/60'
                    },
                    initMenu: 'text',
                    menuBarPosition: 'bottom',
                    uiSize: {
                        width: '100%',
                        height: '100%'
                    },
                    // menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter', 'mask'],
                },

            },
            addForm: {},
            uploadProgress: null,
            isSavingItem: false,
            selected_item: null,
            list_disabled: null,
            item_index: null,
            done_action: null
        }
    },
    created() {
        this.uploadProgress = null;
    },
    methods:{
        setDoneAction(done_action){
            if (done_action){
                this.done_action = done_action;
            }
        },
        onAddText(pos){
            console.log("onAddText");
            console.log(pos);

        },
        onObjectMoved(props){
            console.log("onObjectMoved");
            console.log(props);
        },
        addImageToEditor(){
            let item = this.selected_item;
            if (item && item.image_url !== ''){
                let image_name = item.image_url; //  + (item.hasOwnProperty('image_size') && item.image_size ? item.image_size: '');
                this.$nextTick(() => {
                    if (this.$refs['tuiImageEditor']){
                        let actions = this.$refs['tuiImageEditor'].invoke('getActions');
                        if(image_name && actions) {
                            actions.main.initLoadImage(image_name, item.name);
                            this.$refs['tuiImageEditor'].invoke('ui.activeMenuEvent');
                        }
                    }
                });

            }
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.isSavingItem = false;
        },
        setSelectedItem(item){
            if (item){
                if (item.hasOwnProperty('index')){
                    this.item_index = item.index;
                }
                this.selected_item = item;
            }else{
                this.selected_item = null;
                this.resetFormValues();
            }
        },
        dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type:mime});
        },
        async saveImageUUID(response) {
            let params = {
                image_id: this.selected_item.id,
                s3_uuid: response.uuid,
            };

            const {data} = await imageResource.store(params);


            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = (this.selected_item && this.selected_item.id !== -99 ? 'Edited' : 'Added') + ' Successfully';
                status_class = 'success';
            }
            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

            this.isSavingItem = false;

            this.$bvModal.hide('create-'+this.item_type);

            if (this.done_action !== null){
                this.done_action(new_item);
            }else{
                location.reload();
            }

        },
        async addItem(event){
            this.isSavingItem = true;

            if (this.$refs['tuiImageEditor']){
                let imageToDataURL = this.$refs['tuiImageEditor'].invoke('toDataURL');

                // GET TUI IMAGE AS A BLOB
                let blob = this.dataURLtoBlob(imageToDataURL);

                let file = new File([blob], this.selected_item.image_name,{type:"image/png", lastModified:new Date().getTime()});

                // Stream the file to S3
                Vapor.store(file, {
                    progress: progress => {
                        this.uploadProgress = Math.round(progress * 100);
                    }
                }).then(response => {
                    if (response){
                        // shall edit image # this.selected_item.id with response.uuid
                        this.saveImageUUID(response).finally(() => {
                            this.isSavingItem = false;
                        });
                    }else{
                        this.isSavingItem = false;
                    }
                });
            }
        }
    }
}
</script>
