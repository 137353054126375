const FirstPath = window.location.pathname.split('/')[1];
import {getWarehouseSpaceInfoBlock} from "./getWarehouseSpaceInfoBlock";

export function getLocationDetails(location_info, with_labels=true, just_initials= true, no_html=false, other_info= null) {
    let html = '';

    let location_type_info;
    if (location_info.hasOwnProperty('location_type_info') && location_info.location_type_info){
        location_type_info = location_info.location_type_info;
    }else{
        location_type_info = location_info;
    }

    if (!other_info){
        other_info = location_info;
    }

    html += '<div class="object-title-list">';
        html += '<div class="warehouse-space-location">';
            // html += '<div class="location-icon"><i class="fas fa-location-arrow"></i></div>';

            html += getWarehouseSpaceInfoBlock(other_info, '', no_html) + (no_html ? ' ' : '');

            html += '<div class="location-middle">';
                html += '<div class="wsl-border">';
                    html += '<a class="text-dark" href="/' + FirstPath + '/sp_locations/' + location_info.id + '/view">';
                        html += '<div class="l-dimensions-container">';
                            if (location_type_info && location_type_info.hasOwnProperty('location_type')) {
                                if (location_type_info.location_type !== null) {
                                    let loop_count = location_type_info.location_type + 1;
                                    for (let i = 0; i < loop_count; i++) {
                                        html += '<div class="l-dimension">';
                                            if (with_labels || just_initials){
                                                let label = location_type_info['label_' + (i + 1) + 'd'];
                                                if (!no_html && with_labels){
                                                    html += '<span class="item-label">' + label + '</span>';
                                                }
                                            }
                                            html += '<span class="item-header">' + location_info['label_' + (i + 1) + 'd_text'] + '</span>' + (no_html && (i !== loop_count-1) ? '-' : '');
                                        html += '</div>';
                                    }
                                }
                            } else {
                                html += '<span class="item-header">' + location_info.location_number + ' </span>';
                            }
                        html += '</div>';
                    html += '</a>';
                html += '</div>';
            html += '</div>';
        html += '</div>';
    html += '</div>';

    return no_html ? html.replace(/<\/?[^>]+>/ig, "") : html;
}
