<template>
    <div>
        <h2 class="mb-2">{{ this.selected_item ? 'Edit' : 'Add' }} Purchase Order</h2>

        <div class="row">
            <b-form-group
                label="Supplier"
                label-for="supplier_id"
                class="col-sm-12"
            >
                <multiselect
                    id="supplier_id"
                    v-model="addForm.supplier_id"
                    track-by="id"
                    label="name"
                    class="mySingleSelect"
                    :options="required_items.supplier_id.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                    <span slot="noResult">No data found.</span>
                </multiselect>
            </b-form-group>

            <b-form-group
                v-for="(item, key) in ['reference', 'department_id']"
                :key="key"
                :label="required_items[item].label"
                :label-for="required_items[item].key"
                class="col-sm-6">
                <input
                    v-model="addForm[item]"
                    :type="required_items[item].type"
                    :id="required_items[item].key"
                    class="form-control"/>
            </b-form-group>
        </div>


        <div v-for="(po_item, index) in addForm.po_items" :key="index" class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">Purchase Order Item #{{index + 1}}</div>
                    <div class="card-body">
                        <div class="row">
                            <div
                                v-for="(field, key) in required_items.po_items"
                                class="form-group"
                                :class="field.class">
                                <label :for="key + '_' + index">{{ field.label }}</label>
                                <multiselect
                                    v-if="key === 'currency'"
                                    v-model="addForm.po_items[index][key]"
                                    track-by="value"
                                    label="text"
                                    :custom-label="currencyWithIcon"
                                    class="mySingleSelect"
                                    :options="field.options"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    placeholder=""
                                >
                                    <span slot="noResult">No data found.</span>
                                </multiselect>


                                <div v-else-if="key === 'value_type'" class="input-group">
                                    <b-form-radio-group v-model="addForm.po_items[index][key]">
                                        <b-form-radio
                                            v-for="option in (addForm.po_items[index].quantity !== '1' ? value_type_options : [value_type_options[0]])"
                                            :key="option.id"
                                            :value="option.id"
                                            v-if="getValueTypeTitle(index,option) !== ''"
                                            @input="value_typeChanged(index, $event)"
                                            class="custom-radio mb-3"
                                        >{{ getValueTypeTitle(index,option) | titleize }}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </div>
                                <div v-else class="input-group">
                                    <input
                                        v-model="po_item[key]"
                                        :readonly="['value', 'total_value'].includes(key) ? handleValueReadonly(index, key) : false"
                                        :type="handleValueReadonly(index, key) ? 'text' : field.type"
                                        v-mask="['quantity', 'value', 'total_value'].includes(key) ? '######' : ''"
                                        @input="handleValueChanging(index)"
                                        @change="['quantity', 'value', 'total_value'].includes(key) ? check_price(index, key) : null"
                                        :id="key + '_' + index"
                                        class="form-control"/>

                                    <div v-if="['value', 'total_value'].includes(key)" class="input-group-append">
                                        <label :for="key + '_' + index" class="input-group-text">{{ key !== 'tax' ? (addForm.po_items[index].currency.icon) : '%' }}</label>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-2 align-self-center">
                                <input
                                    type="button"
                                    class="btn btn-danger btn-block"
                                    value="Delete"
                                    @click="deleteRow(index)"
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
        <div class="row">

            <div class="form-group col-lg-2">
                <input
                    type="button"
                    class="btn btn-success mt-3 mt-lg-0 right"
                    value="Add Item"
                    @click="AddformData"
                />
            </div>
        </div>


        <div class="row" v-if="detailed_totals.length !== 0">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">Detailed Totals</div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-sm table-striped mb-0">
                                <thead>
                                <tr>
                                    <th>Currency</th>
                                    <th>Quantity</th>
                                    <th>Total Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(currency_items, key) in detailed_totals">
                                    <td>{{ key.toUpperCase() }}</td>
                                    <td>{{ currency_items.quantity_sum }}</td>
                                    <td>
                                        <span v-b-tooltip:hover :title="toUserCurrencyValue(key, currency_items.total_value_sum)">
                                            {{ getCurrencyByValue(key).icon + currency_items.total_value_sum }}
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-if="totals_of_preferredCurrency.hasOwnProperty('currency')">
                                    <th>Total in {{ '{'+totals_of_preferredCurrency.currency.toUpperCase()+'}' }}</th>
                                    <th>{{ totals_of_preferredCurrency.quantity_sum }}</th>
                                    <th>
                                        <span v-b-tooltip:hover :title="toUserTotalWithCurrency(totals_of_preferredCurrency)">
                                            {{ getCurrencyByValue(totals_of_preferredCurrency.currency).icon + totals_of_preferredCurrency.customers_total_value }}
                                        </span>
                                    </th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

        </div>



        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item && selected_item.name ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button href="./" variant="outline-danger">Cancel</b-button>
        </div>
    </div>
</template>

<script>

import multiSelect from '@/mixins/MultiSelect';
import Resource from '@/api/resource';
import AddressMeta from "@/mixins/addressActions.mixin";
import {Currencies} from "@/views/assetflow/form_data/Currencies";

const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";

export default {
    name: "AddNewPoForm",
    mixins:[AddressMeta, TotalsAndCurrencies, multiSelect],
    data(){
        return {
            number_mask: null,
            value_type_options:[
                {
                    id: 0,
                    name: '1 item'
                },
                {
                    id: 1,
                    name: '(x) items'
                }
            ],
            addForm:{
                supplier_id: '',
                reference: '',
                department_id: '',
                po_items: [],
            },
            required_items:{
                supplier_id: {
                    key: 'supplier_id',
                    label: 'Supplier',
                    type: 'single-select',
                    options:[]
                },
                reference: {
                    key: 'reference',
                    label: 'Reference',
                    type: 'text'
                },
                department_id: {
                    key: 'department_id',
                    label: 'Department ID',
                    type: 'number'
                },
                po_items: {
                    reference: {
                        key: 'reference',
                        label: 'Reference',
                        type: 'text',
                        class: 'col-lg-4'
                    },
                    description: {
                        key: 'description',
                        label: 'Description',
                        type: 'text',
                        class: 'col-lg-8'
                    },
                    currency: {
                        key: 'currency',
                        label: 'Currency',
                        options: Currencies,
                        class: 'col-lg-2'
                    },
                    quantity: {
                        key: 'quantity',
                        label: 'Quantity',
                        type: 'text',
                        class: 'col-lg-2'
                    },
                    value_type: {
                        key: 'value_type',
                        type: 'radio',
                        class: 'col-lg-2'
                    },
                    value: {
                        key: 'value',
                        label: 'Price per Unit',
                        type: 'number',
                        class: 'col-lg-2'
                    },
                    total_value: {
                        key: 'total_value',
                        label: 'Total Value',
                        type: 'number',
                        class: 'col-lg-2'
                    }
                }
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        // this.number_mask = [/[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
        this.addForm.po_items = this.selected_item ? this.selected_item.po_items : [this.getFirstItem()];

        this.setupAddressFieldsWithLoqate('purchaseOrder');
    },
    computed:{
        totals_of_preferredCurrency(){
            return this.getTotalsUsingPreferredCurrency(this.addForm.po_items);
        },
        detailed_totals() {
            return this.getDetailedTotals(this.addForm.po_items);
        },
    },
    methods:{

        check_price(index, key){
            var price = this.addForm.po_items[index][key];
            price = price
                .replace(/[^\d.]/g, '') // Clear characters other than "number" and "."
                .replace(/\.{2,}/g,  '.') // Keep only the first one. Clear extra
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.')
                .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // Only two decimals can be entered
            if (price.indexOf('.') < 0 && price !== '') {
                // The above has been filtered. The control here is that if there is no decimal point, the first digit cannot be an amount similar to 01, 02.
                price = parseFloat(price);
            }
            this.addForm.po_items[index][key] = price;
        },
        value_typeChanged(index, event){
            this.handleValueChanging(index);
        },
        handleValueReadonly(index, acquisition_field){
            let value_type = this.addForm.po_items[index].value_type;
            return ((acquisition_field === 'total_value' && value_type === 0) || (acquisition_field === 'value' && value_type === 1));
        },
        handleValueChanging(index) {
            let item_details = this.addForm.po_items[index],
                quantity = item_details.quantity,
                value = item_details.value,
                total_value = item_details.total_value;

            if ((!value && !total_value) || (value + '' + total_value) === ''){
                return;
            }

            if (this.addForm.po_items[index].value_type === 0) {
                // for one item!
                this.addForm.po_items[index].total_value = (value * quantity).toFixed(2);
            } else {
                // for all
                this.addForm.po_items[index].value = (total_value / quantity).toFixed(2);
            }
        },
        getValueTypeTitle(index, option){
            if (option.id === 1){
                if (parseInt(this.addForm.po_items[index].quantity) > 0){
                    return option.name.replace('(x)', this.addForm.po_items[index].quantity);
                }else{
                    return '';
                }
            }
            return option.name
        },
        getFirstValueType(){
            return this.value_type_options[0].id;
        },
        getFirstItem(){
            var first_item = {};
            for (const key in this.required_items.po_items) {
                let key_item = this.required_items.po_items[key];
                if (key === 'value_type'){
                    first_item[key] = this.getFirstValueType();
                }else if (key === 'currency'){
                    first_item[key] = key_item.options.find(x => x.value === 'gbp');
                }else if (key === 'quantity'){
                    first_item[key] = '1';
                }else{
                    first_item[key] = key_item.type === 'number' ? 0 : '';
                }
            }
            return first_item;
        },
        setSuppliersList(supplier_list){
            this.required_items.supplier_id.options = supplier_list;
        },
        AddformData() {
            this.addForm.po_items.push(this.getFirstItem());
        },
        deleteRow(index) {
            this.addForm.po_items.splice(index, 1);
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {

                let supplier_index = this.required_items.supplier_id.options.map(function(e) { return e.id }).indexOf(this.selected_item.supplier_id);
                this.addForm.supplier_id = this.required_items.supplier_id.options[supplier_index];
                this.addForm.reference = this.selected_item.reference;
                this.addForm.department_id = this.selected_item.department_id;

                var items = [];
                if (this.selected_item.po_items){
                    this.selected_item.po_items.map(item => {
                        item.currency = this.getCurrencyByValue(item.currency ?? 'gbp');
                        return item;
                    });
                    items = this.selected_item.po_items;
                }else{
                    items = [this.getFirstItem()];
                }

                this.addForm.po_items = items;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                supplier_id: this.addForm.supplier_id,
                reference: this.addForm.reference,
                department_id: this.addForm.department_id,
                po_items: this.addForm.po_items,
                detailed_totals: this.detailed_totals,
                totals_info: this.totals_of_preferredCurrency
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await purchaseOrderResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item && this.selected_item.name ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-purchaseOrder');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


