<template>
    <div class="bulk-action-options">
        <h4>Your Default Settings</h4>
        <p>These settings are stored in your User Profile and used as defaults for all your bulk actions.</p>


        <b-form-group id="input-group-2" label="Confirmation">
            <b-form-checkbox
                v-for="(item, index) in confirmation_options"
                :key="index"
                v-model="selected_confirmation_option"
                :value="item.value"
                name="confirmation_options"
                class="custom-checkbox-af-accent m-3 d-inline"
                @change="handleCheckboxChange('confirmation_option', item, $event)"
            >{{ item.label }}
            </b-form-checkbox>
        </b-form-group>
        <b-form-group id="input-group-3" label="Where shall we redirect you?">
            <b-form-checkbox
                v-for="(item, index) in redirection_options"
                :key="index"
                v-model="selected_redirection_option"
                :value="item.value"
                name="confirmation_options"
                class="custom-checkbox-af-accent m-3 d-inline"
                @change="handleCheckboxChange('redirection_option', item, $event)"
            >{{ item.label }}
            </b-form-checkbox>
        </b-form-group>
    </div>
</template>
<script>
export default {
    name: 'BulkActionOptions',
    props: {
        object_type: {},
        setBulkActionOptions: {}
    },
    data(){
        return {
            is_add_to_order_tab: true,
            confirmation_options: [
                { label: 'Email Me When Done', value: '1' },
                { label: 'Do Not Email Me When Done', value: '2' }
            ],
            selected_confirmation_option: null,
            redirection_options: [
                { label: 'Leave me here', value: '1' },
                { label: 'Bulk Action Report', value: '2' },
            ],
            selected_redirection_option: null,
        }
    },
    created() {
        this.redirection_options.push({
            label: ('Return To ' + Vue.options.filters.titleize(this.object_type)), value: '5'
        })
        this.selected_confirmation_option = this.redirection_options[0].value;
        if (this.user_info.user_meta.hasOwnProperty('preferred_confirmation_option')) {
            let preferred_confirmation_option = this.user_info.user_meta.preferred_confirmation_option;
            this.selected_confirmation_option = preferred_confirmation_option;
            this.setFormOption('selected_confirmation_option', preferred_confirmation_option);
        }

        this.selected_redirection_option = this.redirection_options[0].value;
        if (this.user_info.user_meta.hasOwnProperty('preferred_redirection_option')) {
            let preferred_redirection_option = this.user_info.user_meta.preferred_redirection_option;
            this.selected_redirection_option = preferred_redirection_option;
            this.setFormOption('selected_redirection_option', preferred_redirection_option);
        }
    },
    methods:{
        addOrderToRedirectionOptions(newValue, selected_tab = 0) {
            const options = [
                { label: 'Leave me here', value: '1' },
                { label: 'Bulk Action Report', value: '2' },
                { label: ('Return To ' + Vue.options.filters.titleize(this.object_type)), value: '5' }
            ];

            if (newValue && [0, 3].includes(selected_tab)) {
                const [newOptionName, newOptionValue] = selected_tab === 0 ? ['order', '3'] : ['storage', '4'];

                if (!options.some(option => option.value === newOptionValue)) {
                    const newOptionLabel = `Go to ${newOptionName} ${newValue.uid}: ${newValue.name}`;
                    options.push({ label: newOptionLabel, value: newOptionValue });
                }
            }
            this.is_add_to_order_tab = selected_tab === 0;
            this.redirection_options = options;
        },
        setFormOption(formOption, event) {
            this.setBulkActionOptions(formOption, event);
        },
        handleCheckboxChange(field, option, newValue) {
            let option_value = 1;
            if (newValue){
                option_value = option.value;
            }
            this.set_usermeta(('preferred_' + field), option_value, "", false);
            this.setFormOption(('selected_' + field), newValue);
        },
    },
}
</script>
