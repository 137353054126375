<template>
    <Layout>
        <div class="product-page-header dashboard-filters-list">
            <div class="customers-list">
                <div class="main-root-top">
                    <h3 class="filter_option">{{ selected_sp.name | titleize }}</h3>
                    <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                        <li v-for="(sp_item, index) in sps_list" :class="'root-top-menu' + (selected_sp && selected_sp.id === sp_item.id ? ' active' : '')">
                            <a @click="setSelectedSP(sp_item)">
                                {{ sp_item ? sp_item.name : '-' }}
                            </a>
                        </li>
                    </ul>
                </div>

                <span class="arrow-right-icon"></span>
                <div class="main-root-top">
                    <h3 class="filter_option">{{ selected_status.name | titleize }}</h3>
                    <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                        <li v-for="(status, key) in sp_statusesList"
                            :key="key"
                            class="root-top-menu">
                            <a @click="setSelectedStatus(status)">{{ status.name | titleize }}</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div class="cm_dashboard_page_block">
                <div class="cm_goodmoring_tristian_block">
                    <div class="cm_common_asset_box">
                        <div class="common_sub_col_bg">
                            <div class="row">
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Currently Viewing</span>
                                        <p v-if="selected_sp && selected_sp.id !== null">
                                            <i class="bx bxs-cart"></i> Assets {{ assetsStatus | titleize }}at {{ selected_sp.name | titleize }}
                                        </p>
                                        <p v-else>
                                            <i class="bx bxs-cart"></i> All Service Providers
                                        </p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Total Assets & Groups</span>
                                        <p v-if="totals_list.hasOwnProperty('assets_count')"><i class="bx bx-minus"></i> {{ totals_list.assets_count }} | {{ totals_list.asset_groups_count }}</p>
                                        <p v-else><i class="bx bx-minus"></i> -</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Orders Awaiting Confirmation</span>
                                        <p v-if="totals_list.hasOwnProperty('order_awaiting_confirmation_count')"><i class="bx bxs-dashboard"></i> {{ totals_list.order_awaiting_confirmation_count }} Orders</p>
                                        <p v-else><i class="bx bxs-dashboard"></i> -</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3">
                                    <div class="cm_list_inner_common">
                                        <span>Storage Footprint</span>
                                        <p v-if="totals_list.hasOwnProperty('storage_footprint')"><i class="bx bxs-dashboard"></i> {{ totals_list.storage_footprint }} sq ft</p>
                                        <p v-else><i class="bx bxs-dashboard"></i> -</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Search & Find</h3>
                        </div>

                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <!-- // Search -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Asset Keyword Search</h4>
                                        </div>
                                        <div class="cm_stock_intake">
                                            <div class="common--input-filed">
                                                <input placeholder="Enter Text" v-model="search_keyword" type="text" >
                                            </div>

                                            <div class="cm_search_clear_btn">
                                                <button @click="redirectToAssets()" type="button">search</button>
                                                <button @click="search_keyword = ''" type="button">clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- // Categories -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> View by Category</h4>
                                        </div>

                                        <div class="cm_ordering_table_view cm_order_overview table-responsive" v-if="categories_list && categories_list.length > 0">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Assets</th>
                                                        <th>Qty</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                <tr v-for="(category, key) in categories_list"
                                                    :key="key"
                                                    v-if="category.hasOwnProperty('parent_category') && category.parent_category">
                                                    <td>
                                                        <p>{{ category.parent_category.name }}</p>
                                                    </td>
                                                    <td><p>{{ category.assets_count }}</p></td>
                                                    <td><p>{{ category.total_quantity }}</p></td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div v-else class="cm_pending_review">
                                            <p class="cm_no_item_pending_label">No Items Available</p>
                                        </div>

                                        <div class="cm_view_open_order_btn">
                                            <a :href="'/' + item_type + '/assets?queue=category'"><i class="bx bx-plus-circle"></i> View Categories</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- // Tags -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Popular Tags</h4>
                                        </div>

                                        <div class="cm_ordering_table_view cm_order_overview table-responsive" v-if="tags_list && tags_list.length > 0">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Total Count</th>
                                                    <th>Total Qty</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                <tr v-for="(tag, key) in tags_list"
                                                    :key="key">
                                                    <td>
                                                        <p>{{ tag.name }}</p>
                                                    </td>
                                                    <td><p>{{ tag.assets_count }}</p></td>
                                                    <td><p>{{ tag.assets_sum_quantity }}</p></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div v-else class="cm_pending_review">
                                            <p class="cm_no_item_pending_label">No Items Available</p>
                                        </div>

                                        <div class="cm_view_open_order_btn">
                                            <a :href="'/' + item_type + '/tags'"><i class="bx bx-plus-circle"></i> View Tags</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- // Storage Orders -->
                                <div class="col-md-6 col-xl-3">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Storage Orders</h4>
                                        </div>
                                        <div class="cm_ordering_table_view cm_order_overview table-responsive" v-if="storage_orders_list && storage_orders_list.length > 0">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Life cycle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                <tr v-for="(item, key) in storage_orders_list" :key="key">
                                                    <td>
                                                        <p><i class="bx bxs-cart"></i> <a :href="'/' + item_type + '/orders/' + item.id + '/view'" class="text-dark">{{ item.name }}</a></p>
                                                    </td>
                                                    <td v-if="item.hasOwnProperty('order_lines_info') && item.order_lines_info && item.order_lines_info.length.length !== 0">{{ getCompletedOrderLinesCount(item) }} of {{ item.order_lines_info.length }} Lines Completed</td>
                                                    <td v-else>-</td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                        <div v-else class="cm_pending_review">
                                            <p class="cm_no_item_pending_label">No Storage Orders Available</p>
                                        </div>

                                        <div class="cm_view_open_order_btn">
                                            <a :href="'/' + item_type + '/orders'"><i class="bx bx-plus-circle"></i> View All</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block" v-if="assets_groups_info && Object.keys(assets_groups_info).length > 0">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Assets & Groups</h3>
                        </div>
                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <div class="col-md-6 col-xl-3"
                                      v-for="(list_info, key) in assets_groups_info"
                                     :key="key"
                                >
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> {{ list_info.label }}</h4>
                                        </div>
                                        <div v-if="key !== 'latest' && list_info.items.length > 0" class="cm_ordering_table_view cm_order_overview table-responsive">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Asset \ Group</th>
                                                    <th>Quantities</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in list_info.items" :key="item.id">
                                                    <td>
                                                        <p :class="(item.item_type === 0 ? 'item_is_asset' : 'item_is_asset_group')"><i :class="'bx ' + (item.item_type === 0 ? 'bx-box' : 'bxs-box')"></i> <a class="text-dark" :href="'/' + item_type + '/' + (item.item_type === 0 ? 'assets' : 'asset_groups') + '/' + item.item_info.id + '/view' ">{{ item.item_info.name }} </a></p>
                                                    </td>
                                                    <td>
                                                        <div class="cm_qty_display">

                                                            <p class="cm_qty_box_num" v-if="key === 'recently_dispatched'">
                                                                <span v-if="key === 'recently_dispatched'" class="cm_qo_line" v-b-tooltip title="Dispatched Qty">{{ item.dispatched_quantity }}</span>
                                                            </p>
                                                            <p class="cm_qty_box_num" v-else>
                                                                <span class="cm_qty_label" v-b-tooltip title="Total Qty">{{ item.total_quantity }}</span>
                                                                <span class="cm_qg_line" v-b-tooltip title="Available Qty">{{ item.available_quantity }}</span>
                                                                <span class="cm_qo_line" v-b-tooltip title="Reserved Qty">{{ item.reserved_quantity }}</span>
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else-if="list_info.items && list_info.items.length > 0" class="cm_common_confirm_content">
                                            <div class="cm_confirm_order_img">

                                                <b-carousel v-if="list_info.items[0].hasOwnProperty('images_list') && list_info.items[0].images_list && list_info.items[0].images_list.length > 0"
                                                            class="product-image-slider"
                                                            style="text-shadow: 0px 0px 2px #000"
                                                            :interval="0"
                                                            controls>
                                                    <a v-for="(src, index) in list_info.items[0].images_list"
                                                       :key="index"
                                                       @click="() => showCaptiomImg(index, list_info.items[0].images_list)">
                                                        <my-b-carousel-slide :image_index="index" :images_count="list_info.items[0].images_list.length" :src="src"/>
                                                    </a>
                                                </b-carousel>
                                                <a v-else>
                                                    <div class="placeholder-ai text-af-accent">
                                                        <i class="bx bx-cube"></i>
                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                        <div v-else class="cm_pending_review">
                                            <p class="cm_no_item_pending_label">No {{ list_info.label }} Items Available</p>
                                        </div>
                                        <div v-if="key === 'latest' && list_info.items.length > 0" class="cm_common_confirm_detail">
                                            <h3 :class="(list_info.items[0].item_type === 0 ? 'item_is_asset' : 'item_is_asset_group')"><i :class="'bx ' + (list_info.items[0].item_type === 0 ? 'bx-box' : 'bxs-box')"></i> <a class="text-dark" :href="'/' + item_type + '/' + (list_info.items[0].item_type === 0 ? 'assets' : 'asset_groups') + '/' + list_info.items[0].item_info.id + '/view'">{{ list_info.items[0].item_info.name }} </a></h3>
                                            <div class="cm_qty_display">

                                                <p class="cm_qty_box_num" v-if="key === 'recently_dispatched'">
                                                    <span v-if="key === 'recently_dispatched'" class="cm_qo_line" v-b-tooltip title="Dispatched Qty">{{ list_info.items[0].dispatched_quantity }}</span>
                                                </p>
                                                <p class="cm_qty_box_num" v-else>
                                                    <span class="cm_qty_label" v-b-tooltip title="Total Qty">{{ list_info.items[0].total_quantity }}</span>
                                                    <span class="cm_qg_line" v-b-tooltip title="Available Qty">{{ list_info.items[0].available_quantity }}</span>
                                                    <span class="cm_qo_line" v-b-tooltip title="Reserved Qty">{{ list_info.items[0].reserved_quantity }}</span>
                                                </p>
                                            </div>

                                            <div class="cm_list_last_update mt-1">
                                                <p v-if="list_info.items[0].hasOwnProperty('latest_post_update')" v-html="getPostText(list_info.items[0])"></p>
                                            </div>
                                        </div>

                                        <div class="cm_view_open_order_btn">
                                            <a :href="'/' + item_type + '/managed_storages'"><i class="bx bx-plus-circle"></i> View All</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Order Flow</h3>
                        </div>
                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <!-- Open -->
                                <div class="col-md-6 col-xl-3"
                                     v-for="(orders_list, key) in all_orders_list"
                                     :key="key"
                                >
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i :class="'font-size-18 bx bxs-circle status-icon status-icon-' + orders_list.label"></i> {{ orders_list.label | titleize}}</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive" v-if="orders_list.items && orders_list.items.length > 0">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>SP</th>
                                                        <th>Name</th>
                                                        <th>Order Type</th>
                                                        <th>Total Assets</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, key) in orders_list.items" :key="key">
                                                        <td><a class="text-dark" :href="'/' + item_type + '/service_providers/' + item.sp_info.id + '/view'">{{item.sp_info.name | titleize}}</a></td>
                                                        <td><a class="text-dark" :href="'/' + item_type + '/orders/' + item.id + '/view'">{{item.name }} {{ item.uid}}</a></td>
                                                        <td>{{ getStoDashboardOrderType(item) }}</td>
                                                        <td>{{ item.order_lines_count }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else class="cm_pending_review">
                                            <p class="cm_no_item_pending_label">No {{ orders_list.label | titleize }} Orders Available</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cm_common_ordering_block">
                        <div class="cm_common_ordering_title">
                            <h3><i class="bx bxs-cart"></i> Managed Storage Providers</h3>
                        </div>

                        <div class="cm_ordering_inner_row">
                            <div class="row">
                                <div class="col-md-12 col-xl-12">
                                    <div class="cm_common_table_box">
                                        <div class="cm_common_table_heading af-primary">
                                            <h4><i class="bx bxs-cart"></i> Managed Storage Providers</h4>
                                        </div>
                                        <div class="cm_ordering_table_view table-responsive">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>Service Provider</th>
                                                    <th>Contracts</th>
                                                    <th>Assets</th>
                                                    <th>Groups</th>
                                                    <th>Contact</th>
                                                    <th>Phone</th>
                                                    <th>Website</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(item, key) in current_sps_list"
                                                    :key="key"
                                                    v-if="item.id !== null">
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.contracts }}</td>
                                                    <td>{{ item.assets_count }}</td>
                                                    <td>{{ item.groups_count }}</td>
                                                    <td>{{ item.contact }}</td>
                                                    <td>{{ item.phone }}</td>
                                                    <td>{{ item.website }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

    </Layout>
</template>


<script>

import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import Resource from '@/api/resource';
import MyBCarouselSlide from "../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
const stoResource = new Resource('api/assetflow/managed_storages');
const tagResource = new Resource('api/assetflow/tags');
const orderResource = new Resource('api/assetflow/orders');

export default {
    name: "StoDashboard",
    mixins: [TimelineCommons],
    components: {MyBCarouselSlide, Layout, PageHeader },
    computed:{
        current_sps_list(){
            if (this.selected_sp && this.selected_sp.id !== null){
                return [this.selected_sp];
            }
            return this.all_sps;
        },
        assetsStatus(){
            if (this.selected_status && this.selected_status.id !== null ){
                return this.selected_status.name + ' ';
            }
            return '';
        },
    },
    data() {
        return {
            title: "STO Dashboard",
            items: [
                {
                    text: "Customers",
                    href: "./customers"
                },
                {
                    text: "STO Dashboard",
                    active: true
                }
            ],
            listLoadingSPs: false,
            selected_sp: {
                id: null,
                name: 'All'
            },
            all_sps: [],
            sps_list: [],
            selected_status: {
                id: null,
                name: 'Any'
            },
            sp_statusesList: [
                {
                    id: null,
                    name: 'Any',
                    value: 'any'
                },
                {
                    id: 2,
                    name: 'In Storage',
                    value: 'in_storage'
                },
                {
                    id: 1,
                    name: 'Intake In Progress',
                    value: 'intake_in_progress'
                },
                {
                    id: 0,
                    name: 'Dispatched',
                    value: 'dispatched'
                },
            ],
            categories_list: [],
            tags_list: [],
            storage_orders_list: [],
            all_orders_list: [],
            search_keyword: '',
            totals_list: {},
            listQuery: {},
            assets_groups_info: {}
        };
    },
    props:['item_title', 'item_type', 'item_id', 'item_info_string'],
    created() {
        this.getSPSList();
        this.getAllLists();
    },

    methods:{
        getAllLists() {
            this.getListOf('totals');
            this.getListOf('categories');
            this.getListOf('tags');
            this.getListOf('all_orders');
            this.getListOf('storage_orders');
            this.getListOf('assets_groups');
        },
        getStoDashboardOrderType(order, short=false){
            switch (order.order_type){
                case 0:
                    if (order.current_page_type === 'sp'){
                        return 'Intake';
                    }
                    return short ? 'MTS' : 'Move To Storage';
                case 1:
                    return short ? 'STO' : 'Storage Order';
                case 99:
                    return 'Local Order (Demo)';
                default:
                    return 'Order';
            }
        },
        getCompletedOrderLinesCount(order){
            let completed_order_lines = [];
            if (order.order_lines_info){
                completed_order_lines =  order.order_lines_info.filter((order_line) => {
                    return order_line.status === 2;
                });
            }
            return completed_order_lines.length;
        },
        getPostText(asset_info){
            let user = asset_info.latest_post_update.user;
            let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
            return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(this.asset, asset_info.latest_post_update) + " on " + Vue.options.filters.moment(asset_info.latest_post_update.created_at , 'DD/MM/YYYY');
        },
        redirectToAssets(){
            window.location = encodeURI('/' + this.item_type + '/assets?s=' + this.search_keyword);
        },
        handleQueryItems(query) {
            if (this.item_type === 'bss'){
                query.bss_status = true;
            }else{
                if (this.item_type === 'sp'){
                    query.warehouse_id = this.item_id !== '' ? this.item_id : "0";
                }
                if (this.item_type === 'customer') {
                    query.customer_id = this.item_id !== '' ? this.item_id : "0";

                    if (this.selected_sp && this.selected_sp.id !== null){
                        query.warehouse_id = this.selected_sp.id;
                    }

                }

                if (this.is_group){
                    if (this.selected_customer && this.selected_customer.id){
                        query.customer_id = this.selected_customer.id;
                    }
                    if (this.selected_status && this.selected_status.id && this.current_groupBy !== 2){
                        query.status_name = this.selected_status.name;
                    }
                    query.item_type = this.item_type;
                    query.filter_option = this.current_groupBy;
                }
            }
            return query;
        },
        async getListOf(item_type) {
            let requiredResource = null;
            this.listQuery = this.handleQueryItems({});
            switch (item_type){
                case 'totals':
                    this.listQuery.group_by = 'totals';
                    requiredResource = stoResource;
                    break;
                case 'categories':
                    this.listQuery.group_by = 'category_id';
                    requiredResource = stoResource;
                    break;
                case 'tags':
                    this.listQuery.limit = 4;
                    requiredResource = tagResource;
                    break;
                case 'storage_orders':
                    this.listQuery.order_type = 1;
                    this.listQuery.limit = 4;
                    this.listQuery.order_by = 'created_at';
                    this.listQuery.order_dir = 'desc';
                    requiredResource = orderResource;
                    break;
                case 'all_orders':
                    this.listQuery.group_by = 'all_orders';
                    requiredResource = orderResource;
                    break;
                case 'assets_groups':
                    this.listQuery.group_by = 'assets_groups';
                    requiredResource = stoResource;
                    break;
            }

            if (requiredResource){
                const {data} = await requiredResource.list(this.listQuery);
                let required_items = data.data.items;

                if (item_type === 'assets_groups'){
                    this.assets_groups_info = required_items;
                }else if (item_type === 'all_orders'){
                    this.all_orders_list = required_items;
                }else{
                    switch (item_type){
                        case 'totals':
                            this.totals_list = required_items;
                            break;
                        case 'categories':
                            this.categories_list = required_items;
                            break;
                        case 'tags':
                            this.tags_list = required_items;
                            break;
                        case 'storage_orders':
                            this.storage_orders_list = required_items;
                            break;
                    }
                }
            }

        },
        getSPSList: function () {
            this.listLoadingSPs = true;
            let listQuery = {
                item_type: this.item_type,
                customer_id: this.item_id
            }

            axios({
                method:'get',
                url:'/api/assetflow/service_providers',
                baseURL: '/',
                params: listQuery
            }).then(response => {
                let items_list = response.data.data.items;
                if (items_list){

                    this.all_sps = items_list;
                    this.sps_list = items_list;
                    let all_item = {
                        id: null,
                        name: 'All'
                    };
                    this.sps_list.splice(0, 0, all_item);

                    let preferred_selected_sp = window.USER_INFO.user_meta.preferred_selected_sp;
                    if (preferred_selected_sp && /^\d+$/.test(preferred_selected_sp)){
                        let selected_sp_index = this.sps_list.map(function(e) { return e.id; }).indexOf(preferred_selected_sp);
                        if (this.sps_list[selected_sp_index]){
                            this.setSelectedSP(this.sps_list[selected_sp_index], false);
                        }
                    }
                }

            }).finally(() => {
                this.listLoadingSPs = false;
            });
        },

        setSelectedSP(sp_item, with_message = true){
            this.selected_sp = sp_item;

            if (with_message){
                let message = "Saved Selected SP {"+this.selected_sp.name+"} Successfully";
                this.set_usermeta('preferred_selected_sp', this.selected_sp.id, message);
            }
            this.getAllLists();
            // this.getList(this.selected_item);
        },
        setSelectedStatus(status_item){
            this.selected_status = status_item;
            // this.getList(this.selected_item);
        },
    }
};
</script>


<style>
.dashboard-filters-list{
    margin-bottom: 40px !important;
}
</style>
