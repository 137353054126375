<template>
    <div class="row">
        <div class="col-12">
            <form>
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }}</h4>
                        <div class="row">
                            <div class="col-sm-12">

                                <div class="form-group">
                                    <label for="sector_ids">Industry\Sector</label>
                                    <multiselect
                                        v-model="addForm.sector_ids"
                                        track-by="id"
                                        label="name"
                                        class="myMultiSelect"
                                        :options="availableSectors"
                                        :multiple="true"
                                        placeholder="Select Industry\Sector"
                                    >
                                        <span slot="noResult">No data found.</span>
                                    </multiselect>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-body">
                        <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                        <a href="./" class="btn btn-secondary">Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {AvailableSectors} from "../../../form_data/AvailableSectors";
import Multiselect from "vue-multiselect";

export default {
    name: 'industry',
    components: {
        Multiselect
    },
    props:['selected_setting'],
    data(){
        return{
            availableSectors: AvailableSectors,
            isSendingRequest: false,
            addForm:{
                sector_ids: [],
            },
        }
    },
    computed: {
        object_name() {
            return this.current_customer ? 'customer' : 'group';
        },
    },
    created() {
        if(this.current_customer && this.current_customer.id !== ''){
            this.fetchInfoOf();
        }
    },
    methods: {
        setSectors(){
            this.setSelectedSectors(this.addForm.group_id ? this.addForm.group_id.sector_ids : []);
        },
        setSelectedSectors(sector_ids) {
            let sector_ids_objects = [];
            if (sector_ids){
                sector_ids_objects = this.availableSectors.filter(function (e) {
                    return sector_ids.includes(e.id);
                });
            }

            this.addForm.sector_ids = sector_ids_objects;
        },
        addNew(event) {
            if (event && !this.isSendingRequest) {
                this.isSendingRequest = true;

                let sector_ids_array = this.addForm.sector_ids.map(function(e) { return e.id; });

                let keys_list = [
                    'sector_ids'
                ];
                axios({
                    method:(this.current_customer.id !== '' ? 'put' : 'post'),
                    url:'/api/assetflow/' + this.object_name + 's' + (this.current_customer.id !== '' ? ('/'+this.current_customer.id) : ''),
                    baseURL: '/',
                    data: {
                        keys_list: keys_list,
                        customer_id: this.current_customer.id,
                        sector_ids: sector_ids_array,
                    },
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true){
                        if (this.object_name === 'group'){
                            let message = "Updated Settings Successfully";
                            this.handleResponse(response, message, false, true);
                        }else{
                            let added_item = response.data.data.items;
                            if (added_item){
                                let message = "Updated Settings Successfully";
                                this.handleResponse(response, message, false, true);
                            }
                        }

                    }else{
                        console.log('Something went wrong');
                    }

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });

            }
        },
        fetchInfoOf() {
            var customer_info = this.current_customer;
            this.setSelectedSectors(customer_info.sector_ids);
        }
    }
}
</script>

