import Resource from '@/api/resource';


export default {
    data(){
        return {
        }
    },
    created: function () {
    },
    methods: {
        checkIfServiceAddressWasSet() {
            let service_info = this.addForm.service_id;
            if (service_info.hasOwnProperty('service_type_info') && service_info.service_type_info) {
                let service_type_info = service_info.service_type_info;
                if (
                    service_type_info &&
                    service_type_info.hasOwnProperty('order_attributes') &&
                    service_type_info.order_attributes &&
                    service_type_info.order_attributes.includes(1) &&
                    !this.addForm.address_id
                ) {
                    return false;
                }
            }
            return true;
        },
        onAddingNewAddress(new_address) {
            this.$bvModal.hide('create-address');
            if (new_address){
                this.addForm.address_id = new_address;
                this.required_sections.service_setup.fields.address_id.options.push(new_address);
            }
        },
        handleSelectingAddress(){
            let value = this.addForm.address_id;
            if (value && value.id === -99){
                this.addForm.address_id = null;
                this.$refs['add-new-address'].setSelectedItem(null);
                if (this.addForm.customer_id){
                    this.$refs['add-new-address'].setSelectedCustomer(this.addForm.customer_id.id);
                }
                this.$bvModal.show('create-address');
                this.setupAddressLookup();
            }
        },
        handleQueryItems(listQuery) {
            if (this.current_sp && this.item_type === 'sp') {
                listQuery.warehouse_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                listQuery.customer_id = this.current_customer.id;
            }
            return listQuery;
        },
        setMultiValueFromOptions(section_name, field_name, value_id) {
            let required_list = this.required_sections[section_name].fields[field_name].options;
            let foundItems = [];
            for (const v_id in value_id) {
                let foundItem = this.getObjectOf(value_id[v_id], required_list);
                if (foundItem) {
                    foundItems.push(foundItem);
                }
            }
            this.addForm[field_name] = foundItems;
        },
        async shallGetListFor(item_type, section_name = '') {

            if (!(this.required_sections.hasOwnProperty(section_name) && this.required_sections[section_name])){
                // console.log("this.required_sections");
                // console.log(this.required_sections);
                // console.log("section_name");
                // console.log(section_name);
                return;
            }
            let requiredResource = null;
            let optionsField = '';

            let listQuery = this.handleQueryItems({});

            listQuery.sort_by = 'name';
            listQuery.sort_dir = 'asc';

            switch (item_type) {
                case 'customer':
                    requiredResource = new Resource('api/assetflow/service_providers');
                    listQuery.just_related = true;
                    optionsField = 'sp_id';
                    break;
                case 'sp':
                    requiredResource = new Resource('api/assetflow/customers');
                    listQuery.just_related = true;
                    optionsField = 'customer_id';
                    break;
                case 'service':
                    requiredResource = new Resource('api/assetflow/order_services');

                    if (this.item_info){
                        listQuery.order_type = this.item_info.hasOwnProperty('order_type') ? this.item_info.order_type : 0;
                        if (this.item_info.hasOwnProperty('customer_id')){
                            listQuery.current_customer_id = this.item_info.customer_id;
                        }
                    }else{
                        listQuery.order_type = this.addForm.order_type ? this.addForm.order_type.id : null;
                        listQuery.current_customer_id = this.addForm.customer_id ? this.addForm.customer_id.id : null;
                    }

                    optionsField = 'service_id';
                    break;
                case 'address':
                    requiredResource = new Resource('api/assetflow/addresses');
                    if (this.item_info){
                        if (this.item_info.hasOwnProperty('customer_id')){
                            listQuery.current_customer_id = this.item_info.customer_id;
                        }
                    }else{
                        listQuery.current_customer_id = this.addForm.customer_id ? this.addForm.customer_id.id : null;
                    }

                    optionsField = 'address_id';
                    break;
                case 'subscriber':
                    requiredResource = new Resource('api/assetflow/users');
                    if (this.item_info){
                        if (this.item_info.hasOwnProperty('customer_id')){
                            listQuery.customer_id = this.item_info.customer_id;
                        }
                    }else{
                        listQuery.customer_id = this.addForm.customer_id ? this.addForm.customer_id.id : null;
                    }


                    optionsField = 'subscriber_id';
                    break;
            }
            this.required_sections[section_name].fields[optionsField].options = [];
            this.$set(this.required_sections[section_name].fields[optionsField], 'listLoading', true);

            if (requiredResource) {
                const {data} = await requiredResource.list(listQuery);
                let required_items = data.data.items;
                if (required_items.length > 0) {

                    if (optionsField !== 'service_id') {
                        required_items = required_items.map(function (e) {
                            return {id: e.id, name: e.name};
                        });
                    }

                    if (optionsField === 'address_id') {
                        let add_new_option = [
                            {
                                id: -99,
                                name: 'Add New'
                            }
                        ];
                        required_items = add_new_option.concat(required_items);
                    }
                    this.required_sections[section_name].fields[optionsField].options = required_items;
                    if (required_items.length === 1) {
                        this.addForm[optionsField] = this.required_sections[section_name].fields[optionsField].options[0];
                    }
                }else{
                    if (item_type === 'customer' && this.current_sp && this.item_type === 'customer'){
                        this.required_sections[section_name].fields[optionsField].options = [this.current_sp];
                        this.addForm.sp_id = this.current_sp;
                    }else if (optionsField === 'address_id') {
                        this.required_sections[section_name].fields[optionsField].options = [
                            {
                                id: -99,
                                name: 'Add New'
                            }
                        ];
                    }
                }

                let used_item = this.item_info ? this.item_info : ( this.selected_item ? this.selected_item : null);


                if (!this.addForm[optionsField] && used_item) {
                    if (optionsField === 'subscriber_id') {
                        let subscriber_ids;
                        if (used_item.hasOwnProperty('subscriber_ids') && used_item.subscriber_ids){
                            subscriber_ids = used_item.subscriber_ids;
                        }
                        this.setMultiValueFromOptions(section_name, optionsField, subscriber_ids);
                    }else{
                        this.setValueFromOptions(section_name, optionsField, used_item[optionsField], []);
                    }
                }else if (optionsField === 'subscriber_id') {
                    let subscriber_id = this.addForm[optionsField];
                    if (!(subscriber_id instanceof Array)){
                        this.addForm[optionsField] = [subscriber_id];
                    }
                }
            }

            this.$set(this.required_sections[section_name].fields[optionsField], 'listLoading', false);
        }
    }
}
