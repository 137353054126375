<template>
    <div class="row" v-if="item_info">
        <div class="col-lg-8 col-md-12 col-12">

            <div class="row">
                <div class="col-12">
                    <po-lines
                        table_class="m-0"
                        :items="[item_info]"
                        :current_page_type="item_info.current_page_type"
                        :object_type="permission_name"
                        :keys_list="sp_location_keys_list">
                    </po-lines>
                </div>

            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title_html="getHtmlTitleFor('sp_locations')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <div class="cm_product_more_btn" v-if="has_sp_location_actions_permissions">
                            <div class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                    <i class="mdi mdi-dots-horizontal"></i>
                                </button>

                                <SpLocationActions
                                    :index="0"
                                    :item="item_info"
                                    :permission_name="permission_name"
                                    :permissions_list="permissions_list"
                                    :perform-action="performAction"
                                    :show-modal="showModal"
                                />
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="cm_usage">
                                <span>Status</span>
                                <p v-html="getLocationStatus(item_info)"></p>
                            </div>
                        </div>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>
    </div>
</template>
<script>

import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import PoLines from "./widgets/detailsViews/PoLines";
import {SPLocationKeysList} from "@/views/assetflow/form_data/SPLocationKeysList";
import SpLocationActions from "../customers/sp_locations/SpLocationActions";

export default {
    name: 'SpLocationOverviewTab',
    components: {SpLocationActions, PoLines, NewCustomGenericBlock},
    mixins:[],
    data(){
        return {
            permission_name: 'sp_locations',
            sp_location_keys_list: SPLocationKeysList,
            has_sp_location_actions_permissions: false,
            permissions_list: []
        }
    },
    props: {
        item_info: {},
        showModal: {},
        performAction: {},
        getLocationStatus:{}
    },
    created() {
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);
        this.has_sp_location_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];

    }
}
</script>
