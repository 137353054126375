<script>
// Adjust the path as per your project structure
export default {
    name: 'ErrorBoundary',
    data: () => ({
        error: false,
        msg: ''
    }),
    errorCaptured (err, vm, info) {
        this.error = true
        this.msg = `Right loaded content will be shown here...`
    },
    render (h) {
        return this.error
            ? h('p', this.msg)
            : this.$slots.default[0]
    }
}
</script>
