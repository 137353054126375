<template>
    <div class="cm_list_view">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'acquisition_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p">
                                    <div>
                                        <h3>
                                            <a v-b-tooltip :title="'UID: ' + item_info.uid" :href="'/' + item_type + '/acquisitions/' + item_info.id + '/view'">
                                                <i class="bx" :class="item_info.ownership_type === 1 ? 'bx-cube-alt' : 'bx-time'"></i> {{ item_info.name | titleize }}
                                            </a>
                                        </h3>
                                    </div>
                                </div>

                                <div class="common_col_desc" v-if="item_info.notes && item_info.notes !== ''">
                                    <p>{{ item_info.notes }}</p>
                                </div>
                            </div>

                            <div class="cm_status_ul acquisition_details_qty">
                                <div class="d-flex-p">
                                    <div>
                                        <span class="button_size status-btn-in_storage acquisition-mode"><i class="bx" :class="{
                                            'bx-export' : item_info.mode === 1,
                                            'bx-import' : item_info.mode === 0,
                                        }"></i> {{ item_info.mode === 0 ? 'Slave' : 'Master' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p"><div>
                                    <span class="button_size" :class="getAcquisitionStatus(item_info.status, true)">{{getAcquisitionStatus(item_info.status)}}</span>
                                </div></div>
                            </div>

                            <div class="cm_status_ul acquisition_details_qty">
                                <ul>
                                    <li>
                                        <p>Net <span v-if="item_info.net_value !== null"
                                                     class="total_box_bg"
                                                     v-b-tooltip:hover.bottom
                                                     :title="toUserCurrencyValue(item_info_currency.value, item_info.net_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.net_value)}}</span>
                                            <span class="total_box_bg" v-else>{{item_info_currency.icon + '0'}}</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>Tax <span v-if="item_info.tax_value !== null"
                                                     class="total_box_bg"
                                                     v-b-tooltip:hover.bottom
                                                     :title="toUserCurrencyValue(item_info_currency.value, item_info.tax_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.tax_value)}}</span>
                                            <span class="total_box_bg" v-else>{{item_info_currency.icon + '0'}}</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>Total <span v-if="item_info.all_items_value !== null"
                                                  class="total_box_bg"
                                                  v-b-tooltip:hover.bottom
                                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.all_items_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.all_items_value)}}</span>
                                            <span class="total_box_bg" v-else>{{item_info_currency.icon + '0'}}</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Buyer</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('buyer_info') && item_info.buyer_info" :href="'/' + item_type + '/users/' + item_info.buyer_info.id + '/view'">{{ item_info.buyer_info.name }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Acquisition Date</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.hasOwnProperty('acquired_date') && item_info.acquired_date !== ''">{{ item_info.acquired_date | moment('MMMM Do YYYY') }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Payment Account</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('payment_ledger_info') && item_info.payment_ledger_info" :href="'/' + item_type + '/ledgers/' + item_info.payment_ledger_info.id + '/view'">{{ item_info.payment_ledger_info.description }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>P-Card</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('buyer_ledger_info') && item_info.buyer_ledger_info" :href="'/' + item_type + '/ledgers/' + item_info.buyer_ledger_info.id + '/view'">{{ item_info.buyer_ledger_info.name }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>P-Card Desc</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('buyer_ledger_info') && item_info.buyer_ledger_info" :href="'/' + item_type + '/ledgers/' + item_info.buyer_ledger_info.id + '/view'">{{ item_info.buyer_ledger_info.description }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Reconciled</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.reconciliation_date">{{item_info.reconciliation_date | moment('MMMM Do YYYY')}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>User</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('reconciliation_user_info') && item_info.reconciliation_user_info" :href="'/' + item_type + '/users/' + item_info.reconciliation_user_info.id + '/view'">{{ item_info.reconciliation_user_info.name }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>External</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.reference !== ''">{{item_info.reference}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Supplier</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('supplier_name') && item_info.supplier_name !== ''" :href="'/' + item_type + '/suppliers/' + item_info.supplier_id + '/view'">{{ item_info.supplier_name }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Purchase Order</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('purchase_orders_info') && item_info.purchase_orders_info && item_info.purchase_orders_info.length > 0"
                                               :href="'/' + item_type + '/purchaseOrders/' + latest_po.id + '/view'">{{ POWithUIDs(latest_po) }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Acquisition Method</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.acquisition_method && acquisition_methods[item_info.acquisition_method]">{{ acquisition_methods[item_info.acquisition_method].name }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>{{ item_info.no_of_assets ? item_info.no_of_assets + ' ' : '' }}Assets</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.no_of_assets > 0"
                                                  v-b-tooltip:hover.bottom
                                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.assets_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.assets_value)}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>{{ item_info.no_of_payments ? item_info.no_of_payments + ' ' : '' }}Payments</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.no_of_payments > 0"
                                                  v-b-tooltip:hover.bottom
                                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.payments_value)">
                                                {{item_info_currency.icon + parseFloat(item_info.payments_value)}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="item_info.mode === 1">
                                    <div class="cm_usage border_r">
                                        <span>Required Payment</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-b-tooltip:hover.bottom
                                                  v-if="item_info.required_payment"
                                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.required_payment)">
                                                {{item_info_currency.icon + parseFloat(item_info.required_payment)}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4" v-else>
                                    <div class="cm_usage border_r">
                                        <span>Unallocated Funds</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-b-tooltip:hover.bottom
                                                  v-if="item_info.unallocated_funds"
                                                  :title="toUserCurrencyValue(item_info_currency.value, item_info.unallocated_funds)">
                                                {{item_info_currency.icon + parseFloat(item_info.unallocated_funds)}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>{{ item_info.no_of_assets === 1 ? 'Asset Name' : 'Assets' }}</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.no_of_assets === 1 && item_info.hasOwnProperty('asset_info')">
                                                <a :href="'/' + item_type + '/assets/'+item_info.asset_info.id+'/view'">{{ item_info.asset_info.name }}</a>
                                            </span>
                                            <span v-else><a :href="'/' + item_type + '/acquisitions/' + item_info.id + '/view?t=assets'">View Assets</a></span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Returns Deadline</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.item_return_policy">{{ item_info.return_expiry_date | moment('MMMM Do YYYY')}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Returned To Date</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span>{{item_info_currency.icon + '0'}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cm_list_last_update mt-1" v-if="item_info.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText()"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">

            <div v-if="hasPermission('edit_acquisitions') || hasPermission('delete_acquisitions')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <AcquisitionActionsList :item="item_info" :index="item_key" :item_type="item_type" object_type="acquisitions" :perform-action="performAction"/>
            </div>

        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/acquisitions/' + item_info.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import AcquisitionActionsList from "./AcquisitionActionsList";
import MyBCarouselSlide from "../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'AcquisitionListView',
    components: {MyBCarouselImages, MyBCarouselSlide, AcquisitionActionsList},
    props: [
        'item_type',
        'item_key',
        'item_info',
        'received_acquisition_methods',
        'showCaptiomImg',
        'performAction',
    ],
    computed:{
        item_info_currency(){
            let currency = this.item_info.currency !== '' ? this.item_info.currency : 'gbp';
            return this.getCurrencyByValue(currency);
        },
        latest_po(){
            return this.item_info.purchase_orders_info[this.item_info.purchase_orders_info.length-1];
        },
    },
    data(){
        return {
            acquisition_methods: [],
            acquisition_type_info: null,
            sources_list:[
                'Asset',
                'Actions',
                'Orders',
                'Storage Orders',
            ]
        }
    },
    methods:{
        getPostText(){
            if (this.item_info.hasOwnProperty('latest_post_update')){
                let user = this.item_info.latest_post_update.user;
                let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(this.asset, this.item_info.latest_post_update) + " on " + Vue.options.filters.moment(this.item_info.latest_post_update.created_at , 'DD/MM/YYYY');
            }
            return '';
        },
        getClassFor(required_property, when_zero, when_one){
            let classes_dict = {};
            classes_dict[when_zero] = this.item_info[required_property] === 0;
            classes_dict[when_one] = this.item_info[required_property] === 1;
            return classes_dict;
        }
    },
    created() {
        this.acquisition_methods = this.received_acquisition_methods;

        if (this.item_info){
            this.acquisition_type_info = this.item_info.hasOwnProperty('acquisition_type_info') && this.item_info.acquisition_type_info ? this.item_info.acquisition_type_info : null;
        }
    }
}
</script>
