const FirstPath = window.location.pathname.split('/')[1];
export const SPLocationTypeKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 sp_loct_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<span class="button_size status-btn-active">' + item[key] + '</span>';
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg sp_loct_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/sp_location_types/' + item.id + '/view">' + item.name + '</a>';
        }
    },
    location_type: {
        key: 'location_type',
        label: "Type",
        class: "col-lg-1 sp_loct_location_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let location_type_list = [
                '1D',
                '2D',
                '3D',
                '4D'
            ];
            return location_type_list[item.location_type];
        }
    },
    spaces_count: {
        key: 'spaces_count',
        label: "Spaces",
        class: "col-lg-1 war_spaces_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let spaces_count = '-';
            if (item.hasOwnProperty('spaces_count')){
                spaces_count = item.spaces_count;
            }
            return spaces_count;
        }
    },
    user_info: {
        key: 'user_info',
        label: "Created By",
        class: "col-lg-2 sp_loct_user_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('user_info') && item.user_info){
                let user_info = item.user_info;
                return '<a class="text-dark" href="/' + FirstPath + '/users/' + user_info.id + '/view">' + Vue.options.filters.titleize(user_info.name) + '</a>';
            }
            return '';
        }
    },
    created_at: {
        key: 'created_at',
        label: "Date",
        class: "col-lg-2 sp_loct_created_at",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'DD/MM/YYYY') : '';
        }
    },
};

