<template>
    <div class="AssetUsageRightBox">
        <div class="row">
            <div class="col-xl-12">

                <div class="step-common-info-block">
                    <div class="st--common--box" v-for="(required_field, section_field) in required_fields" :key="section_field">
                        <div class="common-st-title-label">
                            <label>{{ required_field.name }}</label>
                        </div>

                        <div class="st-radio-row" v-if="required_field.type === 'radio-select'">
                            <div v-for="(option, index) in required_field.options"
                                 :key="index"
                                 :class="getClassFor(required_field)">
                                <div class="cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                    <label>
                                        <input
                                            v-model="addForm[section_field]"
                                            :key="index"
                                            :id="section_field + '_' + index"
                                            :name="section_field"
                                            :value="option"
                                            @click="changedRadioValue(section_field, option, $event)"
                                            type="radio"/>
                                        <div class="st-radio-box" :class="(addForm[section_field] && option.id === addForm[section_field].id) ? 'force-checked' : ''">
                                            <span></span>
                                            <p>{{ option.name| titleize }}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>
<script>

import AssetInfoSection from "../../assetForm/AssetInfoSection";
import StepCommonInfoBlock from "../../../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";
export default {
    components: {StepCommonInfoBlock, AssetInfoSection},
    mixins: [],
    name: 'AssetUsageRightBox',
    props: {
        my_asset: {},
        item_type: {},
        changedRadioValueOf: {},
        getClassFor: {},
    },
    data(){
        return {
            addForm: {
                status: null,
                asset_usage: null,
                is_hero: null,
                is_asset: null,
            },
            usage_fields: {
                name: '',
                minimized: false,
                fields: [
                    'status',
                    'asset_usage',
                    'is_hero',
                    'is_asset',
                ]
            },
            required_fields:{
                status: {
                    name: '',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: {id: 'acquired', name: 'Acquired (Not Used)'},
                    options: [
                        { id: 'acquired', name: 'Acquired (Not Used)' },
                        { id: 'active', name: 'Active (In Use)' },
                    ]
                },
                asset_usage: {
                    name: 'Asset Usage',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 2, name: 'Unknown' },
                    options:[
                        { id: 1, name: 'On Camera'},
                        { id: 0, name: 'Off Camera'},
                        { id: 2, name: 'Unknown' }
                    ]
                },
                is_hero: {
                    name: 'Is Hero?',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 2, name: 'Unknown' },
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
                is_asset: {
                    name: 'Is Asset?',
                    type: 'radio-select',
                    has_permission: true,
                    default_value: { id: 2, name: 'Unknown' },
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' },
                        { id: 2, name: 'Unknown' }
                    ]
                },
            },
        }
    },
    computed: {

    },
    watch: {
        my_asset:{
            deep: true,
            handler: function (newValue) {
                this.setDefaultValues();
            }
        },
    },
    created() {
        this.setDefaultValues();
    },
    methods: {
        setDefaultValues() {
            let status_property_value = this.getObjectOf(this.my_asset.status, this.required_fields.status.options);
            this.$set(this.addForm, 'status', status_property_value);

            let asset_usage_property_value = this.getObjectOf(this.my_asset.asset_usage, this.required_fields.asset_usage.options);
            this.$set(this.addForm, 'asset_usage', asset_usage_property_value);

            let is_hero_property_value = this.getObjectOf(this.my_asset.is_hero, this.required_fields.is_hero.options);
            this.$set(this.addForm, 'is_hero', is_hero_property_value);

            let is_asset_property_value = this.getObjectOf(this.my_asset.is_asset, this.required_fields.is_asset.options);
            this.$set(this.addForm, 'is_asset', is_asset_property_value);
        },
        changedRadioValue(field_key, option, event) {
            if (event){
                this.changedRadioValueOf(field_key, option.id);
            }
        },

    }
}
</script>
