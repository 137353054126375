<template>
    <div>
        <h4> {{ menu_title !== '' ? menu_title : 'Export'}}</h4>
        <div class="row customer-list">

            <div class="col-12">
                <po-lines
                    v-if="required_actions && Object.keys(required_actions).length > 0"
                    :items="required_actions"
                    :keys_list="export_required_actions_keys_list"
                    :get-line-class-using="getLineFolderClass">
                    <template v-slot:name_content="{ item, index }">
                        <div v-html="item.action_label_html"></div>
                    </template>
                    <template v-slot:new_line="{ item, index }">
                        <div class="tbody_item col-xs-6 col-lg-12 f_actions col-sm-2" v-if="item.is_enabled !== 1">
                            <div v-if="item.is_enabled === 3 && item.hasOwnProperty('exported')" class="mt-1">
                                <div v-if="item.exported" class="export-success-message">Your file is being processed. You will receive an email when ready. You can track progress in the File Manager <a
                                    :href="'/' + item_type + '/files'" target="_blank" v-if="hasPermission('browse_files')">here</a></div>
                                <div v-else class="export-fail-message">Your file was not generated. Please try again or contact your Account Manager for help</div>
                            </div>
                            <div v-else-if="item.is_enabled === 2" class="mt-1">
                                <div class="export-wait-message">Please wait a moment</div>
                            </div>
                        </div>
                    </template>

                    <template v-slot:generate_actions="{ item, index }">
                        <button
                            v-if="item.is_enabled === 1"
                            class="btn btn-sm btn-link af-btn-accent af-accent" @click="performAction(index)">
                            Generate
                        </button>
                        <div v-else-if="item.is_enabled === 3 && item.hasOwnProperty('exported')" class="mt-1">
                            <button
                                class="btn btn-sm btn-link text-white" :class="item.exported ? 'status-background-green' : 'status-background-red'" @click="performAction(index)">
                                {{ item.exported ? 'Success' : 'Failed' }}
                            </button>
                        </div>
                        <div v-else-if="[0, 2].includes(item.is_enabled)" class="mt-1">
                            <button
                                class="btn btn-sm btn-link af-btn-accent af-accent" disabled>
                                {{ item.is_enabled === 2 ? 'Waiting' : 'Generate' }}
                            </button>
                        </div>
                        <div v-else class="mt-1">
                            {{ item.is_enabled }}
                        </div>
                    </template>
                </po-lines>
                <span v-else>No Export actions found here at the moment.</span>
            </div>

        </div>
    </div>
</template>
<script>
import PoLines from "../../items/widgets/detailsViews/PoLines";
export default {
    name: 'SpItemExportTab',
    components: {PoLines},
    props: {
        menu_title: String,
        required_actions: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data(){
        return {
            item_type: '',
            export_required_actions_keys_list:{
                id: {
                    key: 'id',
                    label: "ID",
                    class: "col-lg-1 f_id",
                    show_on_mobile: true,
                    case: ''
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg-4 f_name",
                    show_on_mobile: true,
                    case: 'custom_slot',
                    slot_name: 'name_content'
                },
                description: {
                    key: 'description',
                    label: "Description",
                    class: "col-lg-4 f_description",
                    show_on_mobile: false,
                    case: ''
                },
                file_type: {
                    key: 'file_type',
                    label: "Report Type",
                    class: "col-lg-1 f_file_type",
                    show_on_mobile: false,
                    case: ''
                },
                actions: {
                    key: 'actions',
                    label: "Action",
                    class: "col-lg-2 f_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'generate_actions'
                }
            },
        }
    },
    created() {
        let url_segments = window.location.pathname.split('/');
        if (url_segments.length > 1){
            this.item_type = url_segments[1];
        }
    },
    methods: {
        getLineFolderClass(item, line_type){
            return 'po-line parts-row export-actions';
        },
        performAction(action_name){
            this.$emit('on-export-pdf', action_name);
        }
    },
}
</script>
