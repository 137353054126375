<script>
import NavBar from "../components/nav-bar";
import SideBar from "../components/side-bar";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";
import ChangeFilteringModule from "./ChangeFilteringModule";

import CrossEntityCustomerSelector from "../components/CrossEntityCustomerSelector";

export default {
  components: {ChangeFilteringModule, NavBar, SideBar, RightBar, Footer, CrossEntityCustomerSelector },
    props:['parent_class', 'main_content_id'],
  data() {
    return {
        isMenuCondensed: false,
        selected_filtering_module: null,
        selected_filtering_module_id: null,
        current_module_info: null,
        top_filter_selected_entity: null,
        is_cross_entity: false,
    };
  },
    created() {
      let user_info = window.USER_INFO ?? null;
      if (user_info && user_info.hasOwnProperty('current_entity')){
          let current_entity = user_info.current_entity;
          if (current_entity){
              this.is_cross_entity = current_entity.is_cross_entity;

              let parent_entity_type = current_entity.parent_entity_type;
              let original_entity_type = current_entity.original_entity_type;
              if (parent_entity_type && parent_entity_type !== original_entity_type){
                  this.top_filter_selected_entity = this.getUserMetaValueIfExist('top_filter_selected_' + parent_entity_type);
              }
          }
      }

    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
  },
    methods: {
      changedFilteringModule(selected_filtering_module){
          this.selected_filtering_module = selected_filtering_module;
          let message = "Saved Filtering Module {"+selected_filtering_module.name+"} Successfully";
          window.flash(message);
          this.set_usermeta('selected_filtering_module_id', selected_filtering_module.id, "", true);
      },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 1367) {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {

    }
  }
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar ref="MyNavBar" />
    <SideBar :is-condensed="isMenuCondensed" ref="mySideBar" />
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="main-content" :id="main_content_id">
        <ChangeFilteringModule @changed-filtering_module="changedFilteringModule"/>

        <div class="page-content">
            <div class="text-center page-content-height" v-if="false && (current_module_info && !current_module_info.filter_type_id)">
                <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px;width: 100%;margin-top: 8px;">
                    <p v-if="selected_filtering_module">
                      <i v-if="selected_filtering_module.icon" :class="selected_filtering_module.icon"></i> {{ selected_filtering_module.name }} is Currently Selected | <a href="#" @click="$bvModal.show('change-filtering_module')">Change</a>
                    </p>
                    <p v-else>
                      <a href="#" @click="$bvModal.show('change-filtering_module')">Select Filtering Module</a>
                    </p>
                </div>
            </div>



            <!-- Start Content-->
            <div class="container-fluid af-main-page no-gutters" :class="parent_class">
                <div class="text-center cross-entity-customer-selector" v-if="is_cross_entity">
                    <CrossEntityCustomerSelector/>
                </div>

                <div v-if="is_cross_entity && !top_filter_selected_entity" class="no-entity-content"></div>

                <slot v-else />
            </div>
        </div>
      <Footer />
    </div>
    <RightBar ref="rightBar" />
  </div>
</template>
