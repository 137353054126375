<template>
    <div class="row mt-3">
        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left col-divider" v-if="with_line_items">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Lines</strong>
                </div>
                <div class="assets-detail" v-if="item_info.hasOwnProperty('line_items_count')">{{ item_info.line_items_count }}</div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 col-xl-2 text-left text-md-center col-divider" v-if="with_line_items">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Total Units</strong>
                </div>
                <div class="assets-detail">{{ item_info.line_items.reduce((a, b) => +a + +b.quantity, 0) }}</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider" :class="with_line_items ? ' col-md-4 col-lg-4 col-xl-2' : 'col-md-3 col-lg-3 col-xl-3'">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Assets</strong>
                </div>
                <div class="assets-detail" v-if="item_info.hasOwnProperty('assets_count')">{{ item_info.assets_count }}</div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider" :class="with_line_items ? 'col-md-4 col-lg-4 col-xl-2' : 'col-md-3 col-lg-3 col-xl-3'">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Asset Qty</strong>
                </div>
                <div class="assets-detail">{{ item_info.line_items.reduce((a, b) => +a + +(b.line_type === 'Asset' ? b.quantity : 0), 0) }}</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider col-md-2 col-lg-2 col-xl-1" v-if="with_line_items">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Net</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.net_value)">{{ getCurrencyByValue(item_info.currency).icon + item_info.net_value }}</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider col-md-2 col-lg-2 col-xl-1" v-if="with_line_items">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Tax</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.tax_value)">{{ getCurrencyByValue(item_info.currency).icon + item_info.tax_value }}</div>
            </div>
        </div>


        <div class="col-12 text-left text-md-center text-xl-right col-divider col-md-3 col-lg-3 col-xl-3" v-if="!with_line_items">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Net Total</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, netTotalPrice)">{{ getCurrencyByValue(item_info.currency).icon + netTotalPrice }}</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center text-xl-right col-divider last-child" :class="with_line_items ? 'col-md-4 col-lg-4 col-xl-2' : 'col-md-3 col-lg-3 col-xl-3'">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Gross Total</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, totalPrice)">{{ getCurrencyByValue(item_info.currency).icon + totalPrice }}</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'LineItemTotalsBlocksForAcquisition',
    props: ['item_info', 'with_line_items'],
    computed:{
        totalPrice(){
            if (this.with_line_items === false){
                return this.item_info.assets_value;
            }

            return this.item_info.all_items_value;
        },
        netTotalPrice(){
            if (this.with_line_items === false){
                return this.item_info.net_value;
            }

            return '';
        }
    }
}
</script>
