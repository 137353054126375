<template>
    <div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            v-on:change="savePerPage($event)">
                        </b-form-select>&nbsp;entries
                    </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div v-for="grid in lists" :key="grid.id" class="col-xl-4 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="media">
                            <div class="avatar-md mr-4">
                                <span v-b-tooltip.hover.top title="Customer Logo" class="avatar-title rounded-circle bg-light text-danger font-size-16">
                                    <img v-if="grid.customer_logo" :src="grid.customer_logo" alt width="60" />
                                    <img v-else src="images/logo-light.png" alt height="30" />
                                </span>
                            </div>

                            <div class="media-body overflow-hidden">
                                <h5 class="text-truncate font-size-15">
                                    <a href="#" class="text-dark">{{grid.name}}</a>
                                </h5>
                                <p class="text-muted mb-4">{{grid.description}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 py-3 border-top">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item mr-3">
                                <span
                                    class="badge"
                                    :class="{ 'badge-primary': `${grid.status}` === '1',
                                          'badge-danger': `${grid.status}` === '0' }"
                                >{{grid.status =='1' ? 'Active': 'Disabled'}}</span>
                            </li>
                            <li v-b-tooltip.hover.top class="list-inline-item mr-3" title="Created Date">
                                <i class="bx bx-calendar mr-1"></i>
                                {{grid.created_date}}
                            </li>
                            <li v-b-tooltip.hover.top class="list-inline-item mr-3" title="Created Time">
                                <i class="bx bx-time mr-1"></i>
                                {{grid.created_time}}
                            </li>
                            <li v-b-tooltip.hover.top class="list-inline-item mr-3" title="Stock Quantity">
                                <i class="bx bx-comment-dots mr-1"></i>
                                {{grid.stock_quantity}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                            v-if="perPage !== 0"
                            :total-rows="totalRows"
                            v-model="currentPage"
                            :per-page="perPage"
                        />
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import UserMeta from "@/mixins/usermeta.mixin";
    import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";

    export default {
        name: "GridLayout",
        mixins: [UserMeta],
        props: ['tableData', 'fields'],
        data() {
            return {
                currentPage: 1,
                perPage: 10,
                pageOptions: ItemsPerPage
            }
        },
        created() {
            var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_items_per_page;
            if (preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)){
                preferredItemsPerPage = parseInt(preferredItemsPerPage);
                this.perPage = preferredItemsPerPage;
                this.pageOptions = this.setPerPageOptions(this.pageOptions, preferredItemsPerPage);
            }
        },
        computed: {
            lists () {
                const items = this.tableData ? this.tableData : [];
                // Return just page of items needed
                if (this.perPage !== 0){
                    return items.slice(
                        (this.currentPage - 1) * this.perPage,
                        this.currentPage * this.perPage
                    );
                }
                return items;
            },
            totalRows () {
                return this.tableData ? this.tableData.length : 0;
            }
        },
        methods: {
            savePerPage(obj){
                console.log(this.perPage);
                let message = "Saved Items Per Page {"+this.perPage+"} Successfully";
                this.set_usermeta('preferred_items_per_page', this.perPage, message);
            }
        }
    }
</script>


