const FirstPath = window.location.pathname.split('/')[1];
export const RoleKeysList = {
    id: {
        key: 'id',
        label: "#",
        class: "col-lg-1 rol_id",
        show_on_mobile: false,
        case: ''
    },
    entity_type: {
        key: 'entity_type',
        label: "Entity Type",
        class: "col-lg-1 rol_entity_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return Vue.options.filters.iconize(Vue.options.filters.titleize(item.role_type), item.role_type + 's');
        }
    },
    entity: {
        key: 'entity',
        label: "Entity",
        class: "col-lg-3 rol_entity",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let html = '';
            if (item.hasOwnProperty('owner_info') && item.owner_info){
                let item_info = item.owner_info;
                let avatar_html = '<img src="'+(item_info.hasOwnProperty('logo') ? item_info.logo : '')+'" alt="'+item_info.name+'" class="rounded-circle item-post-image list-usr-avatar" width="25" height="25">';
                let entity_url = 'javascript:void(0);';
                if (item_info.hasOwnProperty('item_url') && item_info.item_url){
                    entity_url = '/' + FirstPath + '/' + item_info.item_url + '/'+item_info.id +'/view';
                }
                return '<div>' + avatar_html + ' <a href="' + entity_url + '" class="text-dark">'+item_info.name+'</a></div>';
            }
            return html;
        }
    },
    name: {
        key: 'name',
        label: "Role Name",
        class: "col-lg-3 rol_name",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_link = '<a class="text-dark" href="/' + FirstPath + '/roles/' + item.id + '/view">' + Vue.options.filters.titleize(item.name) + '</a>';
            return Vue.options.filters.iconize(item_link, 'roles');
        }
    },
    module: {
        key: 'module',
        label: "Module",
        class: "col-lg-3 rol_module",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let module_info = item.hasOwnProperty('module_info') ? item.module_info : null;
            if (module_info){
                let item_link = '<a class="text-dark" href="/' + FirstPath + '/modules/' + module_info.id + '/view">' + Vue.options.filters.titleize(module_info.name) + '</a>';
                return Vue.options.filters.iconize(item_link, 'modules');
            }
            return '-';
        }
    },

    is_default: {
        key: 'is_default',
        label: "Default",
        class: "col-lg-1 rol_is_default",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<span class="text-dark">' + item.is_default + '</span>';
        }
    },
    sharing_type: {
        key: 'sharing_type',
        label: "Role type",
        class: "col-lg-2 rol_sharing_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_link = '<span class="text-dark">' + (item.sharing_type ? item.sharing_type.toUpperCase() : '-') + '</span>';
            return Vue.options.filters.iconize(item_link, 'sharing_type');
        }
    }
};
