<template>

    <div class="dropdown-menu dropdown-menu-right">
        <b-dropdown-item v-if="hasPermission('browse_' + object_type)"
                         :href="'/' + item_type + '/'+object_type+'/' + item.id + '/view'">
            View
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'edit', index, $event)">
            Edit
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'edit_images', index, $event)">
            Edit Images
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'add_ms_files', index, $event)">
            Add Files
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'add_ms_link', index, $event)">
            Add URL
        </b-dropdown-item>

        <b-dropdown-item v-if="hasPermission('can_update_status')"
                         @click="performAction(item, 'update_ms_status', index, $event)">
            Update Status
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('can_update_stocks')"
                         @click="performAction(item, 'update_ms_stocks', index, $event)">
            Update Stocks
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('can_change_asset_storage') && item_type === 'sp'"
                         @click="performAction(item, 'change_asset_storage', index, $event)">
            Change Asset Storage
        </b-dropdown-item>
        <b-dropdown-item v-if="item.status === 2 && hasPermission('can_add_storage_order')"
                         @click="performAction(item, 'addToOrder', index, $event)">
            Add to Order
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('delete_' + object_type)"
                         variant="danger"
                         @click="performAction(item, 'delete' + object_type, index, $event)">
            Delete
        </b-dropdown-item>

    </div>
</template>
<script>
    import ExportAdditionalActions from "../orders/ExportAdditionalActions";
    export default {
        name: 'ManagedObjectActionsList',
        components: {ExportAdditionalActions},
        props: {
            item: {},
            item_type: {},
            object_type: {},
            index: {},
            performAction: {}
        }
    }
</script>
