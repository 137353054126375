<template>
    <div class="row mt-3">
        <div class="col-12 col-md-3 col-lg-3 col-xl-3 text-left col-divider">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Receipts</strong>
                </div>
                <div class="assets-detail" v-if="item_info.hasOwnProperty('receipts_info')">{{ item_info.receipts_info.length }}</div>
                <div class="assets-detail" v-else>-</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider col-md-3 col-lg-3 col-xl-3">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Net</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, net_value)">{{ getCurrencyByValue(item_info.currency).icon + net_value }}</div>
            </div>
        </div>

        <div class="col-12 text-left text-md-center col-divider col-md-3 col-lg-3 col-xl-3">
            <div class="assets-common ">
                <div class="asseta-common-title mb-1">
                    <strong>Tax</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, tax_value)">{{ getCurrencyByValue(item_info.currency).icon + tax_value }}</div>
            </div>
        </div>


        <div class="col-12 text-left text-md-center text-xl-right col-divider last-child col-md-3 col-lg-3 col-xl-3">
            <div class="assets-common">
                <div class="asseta-common-title mb-1">
                    <strong>Total</strong>
                </div>
                <div class="assets-detail" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, all_items_value)">{{ getCurrencyByValue(item_info.currency).icon + all_items_value }}</div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'AcquisitionTotalsBlocksForLedger',
    props: ['item_info', 'getLedgerTotalFor'],
    computed:{
        net_value(){
            return this.getLedgerTotalFor('net_value');
        },
        tax_value(){
            return this.getLedgerTotalFor('tax_value');
        },
        all_items_value(){
            return this.getLedgerTotalFor('all_items_value');
        },
    },

}
</script>
