function get_user_info_using(item, key) {
    const firstPath = window.location.pathname.split('/')[1];
    let user_info;
    if (item.hasOwnProperty(key + '_info')) {
        user_info = item[key + '_info'];
    } else {
        return '-';
    }
    return '<a href="/' + firstPath + '/users/' + item[key] + '/view" class="text-dark"><img src="' + user_info.avatar_url + '" alt="' + user_info.name + '" class="rounded-circle item-post-image" width="20" height="20"> ' + Vue.options.filters.titleize(user_info.name) + '</a>';
}

export const TaskKeysList = {
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-2 e_status",
        show_on_mobile: false,
        case: 'custom_slot',
        slot_name: 'task_status'
    },
    id: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 e_id",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Action",
        class: "col-lg-2 e_name",
        show_on_mobile: true,
        case: 'custom_slot',
        slot_name: 'action_name'
    },
    created_at: {
        key: 'created_at',
        label: "Created Date",
        class: "col-lg-2 e_created_at",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'MMMM Do YYYY') : '';
        }
    },
    user_id: {
        key: 'user_id',
        label: "Created By",
        class: "col-lg-2 s_user_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return get_user_info_using(item, key);
        }
    },
    completed_at: {
        key: 'completed_at',
        label: "Completed Date",
        class: "col-lg-2 e_completed_at",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return date ? Vue.options.filters.moment(date, 'MMMM Do YYYY') : '';
        }
    },
    completed_by: {
        key: 'completed_by',
        label: "Completed By",
        class: "col-lg-2 s_completed_by",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return get_user_info_using(item, key);
        }
    },
};
