<template>
    <b-tab v-if="required_actions.includes(required_key)" :id="required_key" :title-link-class="'unavailable-bu ' + required_key">
        <template v-slot:title>
            <span class="d-inline-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-inline-block unavailable-bu-title">{{ required_key.replace('_unavailable', '') | titleize }}</span>
        </template>
        <b-card-body v-html="required_reason" :class="'unavailable-bu-content ' + required_key+'_content'"/>
    </b-tab>
</template>
<script>
export default {
    name: 'BulkActionUnavailableTab',
    props: {
        required_actions: {},
        required_key: '',
        required_reason: '',
    }
}
</script>
