<template>
    <div>
        <QuickAddNewAsset
            ref="add-quick-asset"
            :object_type="'quick-asset'"
            :item_type="item_type"
            @on-cancel-clicked="assetModalCanceled"
            @on-new-item-adding="onAddingNewAsset"
        />

        <div class="common-step-panel use-section">
            <div class="row">
                <div class="col-sm-12">
                    <setting-info-section
                        v-for="(required_section, key) in required_sections"
                        :key="key"
                        :title="required_section.label | titleize">
                        <div>
                            <div class="st--common--box"
                                 v-for="(required_field, key) in required_section.fields"
                                 :key="key"
                            >
                                <div class="common-st-title-label">
                                    <h5>{{ required_field.label }}</h5>
                                </div>

                                <date-picker
                                    v-if="required_field.type === 'date'"
                                    v-model="addForm[required_field.key]"
                                    lang="en" :first-day-of-week="1"
                                    format="DD-MM-YYYY"
                                    value-type="DD-MM-YYYY"
                                    :placeholder="required_field.label"/>

                                <div v-else-if="required_field.type === 'readonly_text'">
                                    <h6>{{ received_order_type }}</h6>
                                </div>

                                <multiselect
                                    v-else-if="required_field.type === 'single-select'"
                                    v-model="addForm[required_field.key]"
                                    track-by="id"
                                    :loading="required_field.hasOwnProperty('isLoading') ? required_field.isLoading : false"
                                    :disabled="required_field.hasOwnProperty('isLoading') ? required_field.isLoading : false"
                                    :label="required_field.key === 'order_type' && item_type === 'sp' ? 'sp_name' : 'name'"
                                    :placeholder="required_field.label"
                                    class="mySingleSelect"
                                    :options="required_field.options"
                                    @input="required_field.hasOwnProperty('onChangingValue') ? required_field.onChangingValue($event) : null"
                                    :allow-empty="false"
                                    deselect-label=""
                                >
                                </multiselect>

                                <div class="st-radio-row" v-else-if="required_field.type === 'single-checkbox'">
                                    <div class="checkbox-hint col-12 m-1" v-if="required_field.message">{{ required_field.message }}</div>
                                    <div v-for="(option, index) in required_field.options"
                                         :key="index"
                                         class="st-radio-col">
                                        <div class="cm-radio-btn" :class="required_field.key+'-' + option.id">
                                            <label>
                                                <input
                                                    v-model="addForm[required_field.key]"
                                                    :key="index"
                                                    :id="'_'+required_field.key"
                                                    :name="'_'+required_field.key"
                                                    :value="option.id"
                                                    @input="required_field.hasOwnProperty('onChangingValue') ? required_field.onChangingValue(option) : null"
                                                    type="radio" />
                                                <div class="st-radio-box">
                                                    <span></span>
                                                    <p>{{ option.name | titleize }}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <b-form-input
                                    v-else-if="required_field.type === 'text'"
                                    v-model="addForm[required_field.key]"
                                    type="text"
                                    :id="required_field.key"
                                    :placeholder="required_field.label"
                                    autocomplete="none"
                                ></b-form-input>

                                <div class="common--input-filed" v-else-if="required_field.type === 'textarea'">
                                    <textarea v-model="addForm[required_field.key]" :id="required_field.key"></textarea>
                                </div>

                                <small v-if="has_errors && required_field.is_required && !!addForm[required_field.key]">
                                    This Field is required
                                </small>

                            </div>
                        </div>
                    </setting-info-section>
                </div>
            </div>
        </div>
        <div class="text-right mt-2" v-if="has_action_buttons">
            <b-button type="submit"
                      variant="success"
                      v-on:click.once="addItem">
                {{ selected_item ? ('Update Order Line {' + selected_item.uid + '}') : 'Create'}}
            </b-button>
            <b-button v-if="has_cancel_button" variant="outline-danger" @click="$emit('on-cancel-clicked')">Cancel</b-button>
        </div>
    </div>
</template>
<script>
import SettingInfoSection from "../settings/settingsForm/SettingInfoSection";
import DatePicker from "vue2-datepicker";
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import QuickAddNewAsset from "./QuickAddNewAsset";

const order_lineResource = new Resource('api/assetflow/order_lines');
const assetResource = new Resource('api/assetflow/assets');
const assetGroupResource = new Resource('api/assetflow/asset_groups');
const managed_storageResource = new Resource('api/assetflow/managed_storages');

const ItemTypes = [
    {
        id: 0,
        name: 'Asset',
    },
    {
        id: 1,
        name: 'Asset Group',
    }
];

export default {
    name: 'OrderLineFormFields',
    components: {QuickAddNewAsset, SettingInfoSection, DatePicker},
    mixins: [multiSelect],
    props: [
        'is_full',
        'item_type',
        'received_order_type',
        'received_parent_order',
        'received_selected_item',
        'with_asset_details',
        'has_cancel_button'
    ],
    data(){
        return {
            has_action_buttons: true,
            parent_order: null,
            has_errors: null,
            addForm:{
                quantity:null,
                // adding_type:null,
                item_type:null,
                item_id:null,
                reference: '',
                notes: ''
            },
            required_sections: {
                asset_details:{
                    'label' : 'Asset Details',
                    fields: {
                        item_type: {
                            key: 'item_type',
                            label: 'Type',
                            type: 'single-select',
                            is_required:false,
                            isLoading: false,
                            options: ItemTypes
                        },
                        item_id: {
                            key: 'item_id',
                            label: 'Asset \\ Asset Group',
                            type: 'single-select',
                            is_required:false,
                            isLoading: false,
                            onChangingValue: function (value){},
                            options: []
                        },
                        reference: {
                            key: 'reference',
                            label: 'Ref',
                            type: 'text',
                            is_required:false
                        },
                        notes: {
                            key: 'notes',
                            label: 'Notes',
                            type: 'textarea',
                            is_required:false
                        },
                    }
                },
            },

            isAddingItem: null,
            selected_item: null,
            list_disabled: null,
            listQuery: {}
        }
    },
    mounted() {
        this.setParentOrder(this.received_parent_order);
        this.setOrderLine(this.received_selected_item);

        if (!this.with_asset_details && this.required_sections.hasOwnProperty('asset_details')) {
            delete this.required_sections.asset_details;
        } else {
            this.required_sections.asset_details.fields.item_id.onChangingValue = this.handleSelectingExistingItem;
        }

    },

    computed:{
        selected_item_type(){
            return this.addForm.item_type;
        }
    },
    watch: {
        selected_item_type:{
            deep: true,
            handler: function (newValue) {
                if (newValue){
                    switch (newValue.id) {
                        case 0:
                            // console.log("shall existing asset");
                            this.getListOf('assets');
                            break;
                        case 1:
                            // console.log("shall existing asset group");
                            this.getListOf('asset_groups');
                            break;
                        case 2:
                            // console.log("shall existing Managed Storage");
                            this.getListOf('managed_storages');
                            break;
                    }
                }
            }
        }
    },
    methods:{
        setParentOrder(parent_order) {
            if (parent_order) {
                this.parent_order = parent_order;
                this.addForm.reference = parent_order.reference;
                this.addForm.notes = parent_order.notes;

                if (parent_order.hasOwnProperty('order_type')){
                    switch (parent_order.order_type) {
                        case 1:
                            // storage order >> managed storage object
                            this.required_sections.asset_details.fields.item_type.options = [
                                {
                                    id: 2,
                                    name: 'Managed Storage',
                                }
                            ]
                            break;
                        default:
                            // other order types >> asset object
                            break;

                    }
                }
            }
        },
        setOrderLine(selected_item) {
            if (selected_item) {
                this.selected_item = selected_item;

                let found_item = _.find(this.required_sections.asset_details.fields.item_type.options, function(o) { return o.id === selected_item.item_type; });
                if (found_item){
                    this.addForm.item_type = found_item;
                }else{
                    this.addForm.item_type = null;
                }

            }
        },
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getListOf(item_type) {

            this.required_sections.asset_details.fields.item_id.options = [];
            this.required_sections.asset_details.fields.item_id.isLoading = true;
            let requiredResource = null;

            this.handleQueryItems();
            switch (item_type){
                case 'assets':
                    requiredResource = assetResource;
                    this.required_sections.asset_details.fields.item_id.label = 'Select Asset';
                    break;
                case 'asset_groups':
                    requiredResource = assetGroupResource;
                    this.required_sections.asset_details.fields.item_id.label = 'Select Asset Group';
                    break;
                case 'managed_storages':
                    requiredResource = managed_storageResource;
                    this.listQuery.order_line_type = this.parent_order.order_type;
                    this.listQuery.current_customer_id = this.parent_order.customer_id;
                    this.required_sections.asset_details.fields.item_id.label = 'Select Managed Storage';
                    break;
            }

            if (requiredResource){
                this.listQuery.is_short_list = true;
                const {data} = await requiredResource.list(this.listQuery);
                this.required_sections.asset_details.fields.item_id.isLoading = false;
                let required_items = data.data.items;
                if (required_items.length > 0){
                    let item_id_options = required_items.map(function(e) {
                        let edited_item = e;
                        edited_item.name = e.name + (e.uid !== '' ? ' ('+e.uid+')' : '');
                        return edited_item;
                    });
                    if (item_type === 'assets'){
                        let add_new_item = {id: -99, name: 'Add New'};
                        item_id_options.splice(0, 0, add_new_item);
                    }
                    this.required_sections.asset_details.fields.item_id.options = item_id_options;
                    if (this.selected_item){
                        this.addForm.item_id = this.getObjectOf(this.selected_item.item_id, item_id_options);
                    }
                }
            }else{
                this.required_sections.asset_details.fields.item_id.isLoading = false;
            }

        },
        handleSelectingExistingItem (value) {
            if (value.id === -99){
                this.addForm.item_id = null;
                if (this.parent_order){
                    this.$refs['add-quick-asset'].setSelectedCustomer(this.parent_order.customer_id, this.parent_order.sp_id);
                }
                this.$bvModal.show('create-quick-asset');
                console.log("shall create new item");
            }else{
                this.fillInfoFromAsset(value, this.addForm.item_type.id);
            }
        },
        handleAddingType (value) {
            switch (value.id){
                case 0:
                    console.log("shall existing asset or asset group");
                    break;
                case 1:
                    // this.addForm.adding_type = null;
                    this.$bvModal.show('create-quick-asset');
                    break;
            }
        },
        async addItem(event){
            this.isAddingItem = true;

            var params = {
                order_type: this.received_order_type,
                order_id: this.parent_order.id,
                customer_id: this.parent_order.customer_id,
                reference: this.addForm.reference,
                item_type: this.addForm.item_type ? this.addForm.item_type.id : 0,
                aag_item_id: this.addForm.item_id ? this.addForm.item_id.id : null,
                notes: this.addForm.notes,
                quantity: this.addForm.quantity
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await order_lineResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.selected_item = new_item;
            // this.has_action_buttons = false;
            this.$emit('after-saving-is-done', new_item);
        },
        fillInfoFromAsset: function (new_asset, item_type) { // item_type 0:Asset, 1:Asset Group
            let found_item = _.find(this.required_sections.asset_details.fields.item_type.options, function(o) { return o.id === item_type; });
            if (found_item){
                this.addForm.item_type = found_item;
            }else{
                this.addForm.item_type = null;
            }


            this.addForm.item_id = new_asset;
            if (item_type === 0){
                this.addForm.quantity = new_asset.quantity;
                // this.addForm.notes = new_asset.description;
            }else{
                // may fill information about asset group >> number of assets maybe??
            }
        },
        assetModalCanceled(){
            // this.addForm.adding_type = null;
        },
        onAddingNewAsset(new_item){
            this.$bvModal.hide('create-quick-asset');
            if (new_item){
                this.getListOf('assets');
                this.fillInfoFromAsset(new_item, 0);
            }
        }
    }
}
</script>
