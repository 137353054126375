import {Currencies} from "../views/assetflow/form_data/Currencies";

export default {
    data(){
        return {
            entity_info_list: {},
            image_width: process.env.MIX_DEFAULT_IMGS_WIDTH,
            items_lists:{
                currency: Currencies,
            },
        }
    },
    created: function () {
    },
    methods: {
        getEntityLogoAndName: function (item_info) {
            if (!item_info){ return ''; }
            let image_width = this.image_width;
            return '<div class="entity-overview-logo"><img src="' + item_info.logo + '/inside/300/100" alt="' + item_info.name + '" width="' + image_width + '" /></div>';
        },
        getObjectForKey(key){
            let value = this.item_info.meta[key];
            if (key === 'currency'){
                let required_items = this.items_lists[key];

                if (required_items){
                    let options = required_items.filter(option => {
                        return option.value === value
                    });

                    if (options.length > 0){
                        value = options[0];
                    }
                }
            }else{
                value = (value+"").toUpperCase();
            }
            return value;
        }
    }
}
