<template>
    <div class="step-form-content-block row">
        <div class="col-xl-6 st-col-6">
            <asset-info-section title="Events & Actions" :selected_fields="sustainability_fields" :addForm="addForm">
                <div v-if="asset_is_ended">
                    <LockedAssetWarning icon="bx bx-time" message="This Asset is Locked, 'Setting Events & Actions' is not available."/>
                </div>

                <event-fields-repeater v-else :add-form="addForm" :my_lists_values="my_lists_values" />
            </asset-info-section>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import EventFieldsRepeater from "./EventFieldsRepeater";
import LockedAssetWarning from "./LockedAssetWarning";

export default {
    name: "SustainabilityInfo",
    components: {LockedAssetWarning, EventFieldsRepeater, AssetInfoSection},
    props: ['addForm', 'required_fields', 'listQuery', 'my_lists_values', 'asset_is_ended'],
    data(){
        return {
            sustainability_fields:[
                'events'
            ]
        }
    },
}
</script>


