<template>
    <div class="ns_ptc_box" v-if="coloured_block">
        <p>{{ coloured_block.type }}: {{ coloured_block.index }} of {{ items_count }}</p>
        <a :href="sceneClicked(coloured_block)" :class="coloured_block.block_class">
            {{ coloured_block.name }}
            <span>{{ coloured_block.date }}</span>
        </a>
    </div>
</template>
<script>
export default {
    name: 'SceneColouredBlock',
    props:['coloured_block','sceneClicked', 'items_count']
}
</script>
