<template>
    <div class="AssetLayoutLeftBox">
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Brand</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="asset_meta && asset_meta.hasOwnProperty('brand')">{{asset_meta.brand}}</span>
                            <span v-else-if="asset_meta && asset_meta.hasOwnProperty('make') && asset_meta.make">{{asset_meta.make.name}}</span>
                            <span v-else-if="asset_meta && asset_meta.hasOwnProperty('make_brand') && asset_meta.make_brand">{{asset_meta.make_brand}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Size</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="asset_meta && asset_meta.hasOwnProperty('size')">{{asset_meta.size}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage">
                        <span>Colours</span>
                        <div v-html="getColourHTML()"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">

                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Character</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="character_info" :href="'/' + item_type + '/characters/'+character_info.id+'/view'">({{ character_info.character_number || '' }}) {{ character_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Asset Reference</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="external_reference">
                                {{ external_reference }}
                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="common_col_bg_box">
            <div class="row">

                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Qty</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span>{{ my_asset.quantity }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Cost For 1</span>

                        <p class="totals-net-gross">

                            <span class="total-net cost-per-one" v-if="(my_asset.net_unit) && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, (my_asset.net_unit))">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.net_unit).toFixed(2)}}</span>
                            <span class="total-net cost-per-one" v-else-if="(my_asset.net_total / my_asset.quantity) && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, (my_asset.net_total / my_asset.quantity))">{{getCurrencyByValue(my_asset.used_currency).icon + ((my_asset.net_total / my_asset.quantity).toFixed(2))}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-net cost-per-one">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>
                            <span class="total-gross" v-if="(my_asset.value) && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, my_asset.value)">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.value).toFixed(2)}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-gross">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>
                        </p>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Cost for {{ my_asset.quantity }}</span>
                        <p class="totals-net-gross">
                            <span class="total-net" v-if="my_asset.net_total && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, my_asset.net_total)">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.net_total).toFixed(2)}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-net">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>
                            <span class="total-gross" v-if="my_asset.total_value && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, my_asset.total_value)">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.total_value).toFixed(2)}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-gross">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>

                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'AssetLayoutRightBox',
    props: {
        my_asset: {},
        item_type: {},
        performAction: {}
    },
    computed:{
        asset_meta(){
            return this.getValueOrDefault(this.my_asset, 'asset_meta', null);
        },
        character_info(){
            return this.getValueOrDefault(this.my_asset, 'character_info', null);
        },
        external_reference(){
            return this.getValueOrDefault(this.my_asset, 'external_reference', null);
        },
        colours_info(){
            return this.getValueOrDefault(this.my_asset, 'colours_info', null);
        }
    },
    methods:{
        getColourHTML(){
            let colours_info = this.colours_info;
            if (colours_info){
                let html = '';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                return html;
            }
            return '-';
        },
    }
}
</script>
