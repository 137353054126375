<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>

                    <div class="row">
                        <div class="col-sm-12">

                            <custom-generic-block block_title="Current Totals" columns_count_withclasses="12" v-if="current_customer.totals">
                                <template v-slot:block_content>
                                    <div class="card-row" v-for="(setting_item, key) in setting_items">
                                        <div class="col-md-6 col-xl-6 mb-6">
                                            {{ setting_item.name | titleize}}
                                        </div>
                                        <div class="col-md-6 col-xl-6 mb-6">
                                            {{ current_customer.totals[setting_item.name] }}
                                        </div>
                                    </div>
                                </template>
                            </custom-generic-block>


                            <custom-generic-block block_title="Limits" columns_count_withclasses="12">
                                <template v-slot:block_content>
                                    <div class="card-row" v-for="(setting_item, key) in setting_items">
                                        <div class="col-md-6 col-xl-6 mb-6">
                                            {{ setting_item.name | titleize}}
                                        </div>
                                        <div class="col-md-6 col-xl-6 mb-6">
                                            -
                                        </div>
                                    </div>
                                </template>
                            </custom-generic-block>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomGenericBlock from "../../../items/widgets/detailsViews/AssetTabs/CustomGenericBlock";
export default {
    name: "group_usage_limits",
    components: {CustomGenericBlock},
    props:['selected_setting'],
    data(){
        return {
            setting_items: {
                assets: {
                    id: 0,
                    name: 'assets'
                },
                assets_qty: {
                    id: 1,
                    name: 'assets_qty'
                },
                addresses: {
                    id: 2,
                    name: 'addresses'
                },
                locations: {
                    id: 3,
                    name: 'locations'
                },
                departments: {
                    id: 4,
                    name: 'departments'
                },
                assetflow_qty: {
                    id: 5,
                    name: 'assetflow_qty'
                }
            }
        }
    }
}
</script>


