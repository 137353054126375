<template>
    <div class="AssetLayoutLeftBox">
        <div class="common_col_bg_box">
            <div class="row">

                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Qty</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span>{{ my_asset.quantity }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Cost For 1</span>

                        <p class="totals-net-gross">

                            <span class="total-net cost-per-one" v-if="(my_asset.net_unit) && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, (my_asset.net_unit))">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.net_unit).toFixed(2)}}</span>
                            <span class="total-net cost-per-one" v-else-if="(my_asset.net_total / my_asset.quantity) && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, (my_asset.net_total / my_asset.quantity))">{{getCurrencyByValue(my_asset.used_currency).icon + ((my_asset.net_total / my_asset.quantity).toFixed(2))}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-net cost-per-one">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>
                            <span class="total-gross" v-if="(my_asset.value) && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, my_asset.value)">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.value).toFixed(2)}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-gross">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>
                        </p>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Cost for {{ my_asset.quantity }}</span>
                        <p class="totals-net-gross">
                            <span class="total-net" v-if="my_asset.net_total && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, my_asset.net_total)">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.net_total).toFixed(2)}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-net">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>
                            <span class="total-gross" v-if="my_asset.total_value && my_asset.used_currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, my_asset.total_value)">{{getCurrencyByValue(my_asset.used_currency).icon + parseFloat(my_asset.total_value).toFixed(2)}}</span>
                            <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(my_asset.used_currency, 0)" class="total-gross">{{ getCurrencyByValue(my_asset.used_currency).icon }}0.00</span>

                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Acquisition</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="item_info" :href="'/' + item_type + '/acquisitions/' + item_info.id + '/view'">{{ uid_info }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Ledger</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="ledger_info" :href="'/' + item_type + '/ledgers'">{{ ledger_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage">
                        <span>P-Card</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="buyer_ledger_info" :href="'/' + item_type + '/ledgers'">{{ buyer_ledger_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>Supplier</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="supplier_info" :href="'/' + item_type + '/suppliers/' + supplier_info.id + '/view'">{{ supplier_info.name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage border_r">
                        <span>PO</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="purchase_orders_info && purchase_orders_info.length > 0">
                                <a class="text-dark" :href="'/' + item_type + '/purchaseOrders/'+purchase_orders_info[purchase_orders_info.length-1].id+'/view'">{{ POWithUIDs(purchase_orders_info[purchase_orders_info.length-1]) }}</a>
                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="cm_usage">
                        <span>Returns Deadline</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_info && item_info.hasOwnProperty('return_expiry_date')">{{ item_info.return_expiry_date | moment('MMMM Do YYYY')}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'AssetLayoutRightBoxOld',
    props: {
        my_asset: {},
        item_type: {},
        performAction: {}
    },
    computed:{
        item_info(){
            return this.getValueOrDefault(this.my_asset, 'acquisition_info', null);
        },
        uid_info(){
            return this.getValueOrDefault(this.item_info, 'uid', null);
        },
        return_expiry_date(){
            return this.getValueOrDefault(this.my_asset, 'return_expiry_date', null);
        },
        buyer_ledger_info(){
            return this.getValueOrDefault(this.item_info, 'buyer_ledger_info', null);
        },
        ledger_info(){
            return this.getValueOrDefault(this.my_asset, 'ledger_info', null);
        },
        supplier_info(){
            return this.getValueOrDefault(this.my_asset, 'supplier_info', null);
        },
        purchase_orders_info(){
            return this.getValueOrDefault(this.item_info, 'purchase_orders_info', null);
        },
    },
    methods:{
    }
}
</script>
