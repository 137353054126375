<template>
    <b-modal :id="'create-'+item_type"
             :title="modal_title"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             @show="fillAssetImages"
             hide-footer>

        <div class="common-step-panel use-section">
            <div class="row">

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :received_toggle="section_group.minimized">

                        <div class="step-common-info-right-block" v-if="key === 'image_upload_section'">
                            <div class="st-drop-file-block">
                                <div class="st-drop-file-box">
                                    <vue-dropzone id="imagesgallery"
                                                  ref="myVueDropzone"
                                                  :use-custom-slot="true"
                                                  :include-styling="false"
                                                  :options="dropzoneOptions"
                                                  @vdropzone-thumbnail="thumbnail"
                                                  @vdropzone-file-added="fileAdded"
                                                  @vdropzone-removed-file="fileRemoved"
                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                    >
                                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <div class="st-upload-title">
                                                <h3>Drop files here or click to upload</h3>
                                                <p>... or click to select a file from your computer</p>
                                            </div>
                                        </div>
                                    </vue-dropzone>
                                    <b-button
                                        @click="$refs.myVueDropzone[0].$el.click();"
                                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                    >Add New Image</b-button>
                                </div>
                            </div>
                        </div>
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-asset-acquisition' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="item_info && item_info.hasOwnProperty('name') ? ('Update {' + item_info.name + '}') : 'Save'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>

    </b-modal>

</template>
<script>

import AFButton from "@/components/AFButton";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetInfoSection from "../../assets/assetForm/AssetInfoSection";


export default {

    components: {AssetInfoSection, AFButton},
    mixins: [dropzoneMixin],
    name: 'SetChangeSceneImages',
    props: ['modal_title', 'modal_id', 'item_type', 'received_item_id'],
    data(){
        return {
            with_used_file: true,
            item_info: null,
            addForm: {
                asset_meta: {},
                item_details: {},
                asset_images: [],
                filled_asset_images: [],
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {
                        image_upload_section: {
                            name: 'Asset Upload',
                            minimized: false,
                            fields: {}
                        },
                    }
                },
            },
            item_index: null,
            change_index: null,
        }
    },
    created() {
        this.setDefaultValues();
    },
    methods:{
        setSelectedItem(item){
            if (item){
                this.item_info = item;

                if (this.item_info.hasOwnProperty('item_index')){
                    this.item_index = this.item_info.item_index;
                }
                if (this.item_info.hasOwnProperty('change_index')){
                    this.change_index = this.item_info.change_index;
                }
                this.setDefaultValues();
            }else{
                this.item_info = null;
            }
        },
        setDefaultValues() {

            let addForm = this.addForm;
            addForm.asset_images = [];
            addForm.filled_asset_images = [];

            if (this.item_info && this.item_info.hasOwnProperty('asset_images')){
                addForm.asset_images = this.item_info.asset_images;
            }

            this.addForm = Object.assign({}, addForm);
        },
        fillAssetImages() {
            if (this.addForm.asset_images.length > 0) {
                this.addForm.filled_asset_images = [];
                this.$nextTick(() => {
                    for (const assetImagesKey in this.addForm.asset_images) {
                        let asset_image = this.addForm.asset_images[assetImagesKey];

                        var file = {
                            id: asset_image.id,
                            s3_uuid: asset_image.s3_uuid,
                            size: asset_image.image_name_size,
                            name: asset_image.name,
                            description: asset_image.description,
                            type: "image/png",
                            upload_progress: 100,
                            is_featured: asset_image.is_featured,
                            add_to_ap: asset_image.add_to_ap
                        };

                        var url = asset_image.image_name + (this.image_sizes.hasOwnProperty('asset_list') ? this.image_sizes.asset_list : '');
                        if (this.$refs.myVueDropzone) {
                            this.$refs.myVueDropzone[0].manuallyAddFile(file, url);
                        }
                    }
                });
            }
        },
        cancelClicked(){
            this.setDefaultValues();
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        async addItem(event){
            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (!all_files){
                return;
            }

            if (all_files.length > 0){
                let asset_images_copy = [... all_files];
                all_files = asset_images_copy.map(function (image) {
                    let new_image = {
                        item_type: image.item_type,
                        uuid: image.uuid,
                        s3_uuid: image.s3_uuid,
                        is_featured: image.is_featured,
                        add_to_ap: image.add_to_ap,
                        image_title: image.image_title,
                        image_description: image.image_description,
                        width: image.width,
                        height: image.height,
                        key: image.key,
                        name: image.name,
                    }
                    if (image.hasOwnProperty('id')){
                        new_image.id = image.id;
                    }
                    return new_image;
                });
            }

            this.isLoadingItem = true;

            let item_id = this.item_info.item_id;
            let params = {
                item_id: item_id,
                asset_images: all_files
            };

            axios({
                method: 'post',
                url: '/api/assetflow/changes/setSceneNoteImages',
                baseURL: '/',
                data: params,
            }).then(response => {
                this.isLoadingItem = false;
                if (response.data.data.status === true) {
                    let added_item = response.data.data.items;
                    let message;

                    if (added_item && added_item.hasOwnProperty('id')){
                        let asset_name = '';
                        message = 'Done' + asset_name;
                        if (item_id !== '') {
                            message = 'Updated Images Successfully' + asset_name;
                        }

                        let status = response.data.data.status;
                        this.presentToast("Success!",
                            message,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);

                        this.$bvModal.hide(this.modal_id);
                        this.$emit('on-new-item-adding-in-list', this.item_index, this.change_index, added_item.scene_note_images);

                    }else{
                        console.log(added_item);
                    }

                } else {
                    console.log('Something went wrong');
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

    }
}
</script>
