export const CustomersSortOptions = [
    {
        value: 1,
        text: 'A~Z',
        name: 'name',
        reversed: false
    },
    {
        value: 2,
        text: 'Z~A',
        name: 'name',
        reversed: true
    },
    {
        value: 3,
        text: 'Users',
        name: 'users_count',
        reversed: true
    },
    {
        value: 4,
        text: 'Assets',
        name: 'assets_count',
        reversed: true
    },
    {
        value: 5,
        text: 'Qty',
        name: 'quantity',
        reversed: true
    },
    {
        value: 6,
        text: 'Cost',
        name: 'total_value',
        reversed: true
    },

    {
        value: 7,
        text: 'Created Date: New to Old',
        name: 'created_at',
        reversed: true
    },
    {
        value: 8,
        text: 'Created Date: Old to New',
        name: 'created_at',
        reversed: false
    },
    {
        value: 9,
        text: 'Modified Date: New to Old',
        name: 'updated_at',
        reversed: true
    },
    {
        value: 10,
        text: 'Modified Date: Old to New',
        name: 'updated_at',
        reversed: false
    },
];
