<template>
    <b-modal :id="'create-'+item_type"
             size="xl"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>

        <AddNewSpLocationDimension
            ref="add-new-location_dimension"
            item_type='location_dimension'
            modal_title="Add New Dimension"
            @on-new-item-adding="addNewItemToOptions"
        >
        </AddNewSpLocationDimension>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        Location Setup
                    </div>
                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_items"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import ChangeCommonFields from "../extras/ChangeCommonFields";
import AddNewSpLocationDimension from "./AddNewSpLocationDimension";
import AFButton from "@/components/AFButton";

const sp_locationResource = new Resource('api/assetflow/sp_locations');

export default {
    mixins: [multiSelect],
    name: "AddNewSpLocation",
    props: ['modal_title', 'item_type', 'sp_locations_options'],
    components: {AFButton, AddNewSpLocationDimension, ChangeCommonFields},
    data(){
        return {
            addForm:{
                warehouse_id: null,
                space_id: null,
                location_type: null,
                status: null,

                label_1d_option: null,
                label_2d_option: null,
                label_3d_option: null,
                label_4d_option: null,

                location_name: '',
                location_number: '',

            },
            required_items:{

                warehouse_id: {
                    key: 'warehouse_id',
                    label: 'Warehouse',
                    type: 'single-select',
                    options:[]
                },
                space_id: {
                    key: 'space_id',
                    label: 'Space',
                    type: 'single-select',
                    options:[]
                },
                location_type: {
                    key: 'location_type',
                    label: 'Location Type',
                    type: 'single-select',
                    options:[], // option is coming from the selected space, << this is defined once
                    is_readonly: true
                },
                status: {
                    key: 'status',
                    label: 'Status',
                    type: 'single-select',
                    options:[
                        {
                            id: 0,
                            name: 'Free'
                        },
                        {
                            id: 1,
                            name: 'Unavailable'
                        }
                    ]
                },

                label_1d_option: {
                    key: 'label_1d_option',
                    label: '1D Text',
                    type: 'hidden_text',
                    required_class: 'col-sm-3',
                    options:[]
                },
                label_2d_option: {
                    key: 'label_2d_option',
                    label: '2D Text',
                    type: 'hidden_text',
                    required_class: 'col-sm-3',
                    options:[]
                },
                label_3d_option: {
                    key: 'label_3d_option',
                    label: '3D Text',
                    type: 'hidden_text',
                    required_class: 'col-sm-3',
                    options:[]
                },
                label_4d_option: {
                    key: 'label_4d_option',
                    label: '4D Text',
                    type: 'hidden_text',
                    required_class: 'col-sm-3',
                    options:[]
                },
                location_name: {
                    key: 'location_name',
                    label: 'Location Name',
                    type: 'text',
                    is_readonly: true
                },
                location_number: {
                    key: 'location_number',
                    label: 'Location Number',
                    type: 'text',
                    is_readonly: true
                },
            },
            selected_item: null
        }
    },
    created() {
    },
    computed:{

        warehouse_id() {
            return this.addForm.warehouse_id;
        },
        space_id() {
            return this.addForm.space_id;
        },
        label_1d_option() {
            return this.addForm.label_1d_option;
        },
        label_2d_option() {
            return this.addForm.label_2d_option;
        },
        label_3d_option() {
            return this.addForm.label_3d_option;
        },
        label_4d_option() {
            return this.addForm.label_4d_option;
        },


    },
    watch:{
        warehouse_id(value) {
            // shall get spaces of this warehouse id
            this.getSPSpaces(value);
        },
        space_id(value) {
            // shall get spaces of this warehouse id
            if (value && value.hasOwnProperty('location_type_info') && value.location_type_info){
                let location_type_info = value.location_type_info;
                this.required_items.location_type.options = [ location_type_info ];
                this.addForm.location_type = location_type_info;
                let location_type_style = location_type_info.location_type

                if (location_type_style !== null){
                    let required_class = 12 / (location_type_style + 1);
                    this.required_items.label_1d_option.required_class = 'col-sm-' + required_class;
                    this.required_items.label_2d_option.required_class = 'col-sm-' + required_class;
                    this.required_items.label_3d_option.required_class = 'col-sm-' + required_class;
                    this.required_items.label_4d_option.required_class = 'col-sm-' + required_class;
                }

                if (location_type_info.label_1d){
                    this.required_items.label_1d_option.label = location_type_info.label_1d;
                }

                if (location_type_info.label_2d){
                    this.required_items.label_2d_option.label = location_type_info.label_2d;
                }

                if (location_type_info.label_3d){
                    this.required_items.label_3d_option.label = location_type_info.label_3d;
                }

                if (location_type_info.label_4d){
                    this.required_items.label_4d_option.label = location_type_info.label_4d;
                }

                this.required_items.label_1d_option.type = 'single-select';
                if (value.hasOwnProperty('labels_options')){
                    this.required_items.label_1d_option.options = value.labels_options.label_1d_options;
                }


                if ([1,2,3].includes(location_type_style)){
                    this.required_items.label_2d_option.type = 'single-select';
                    if (value.hasOwnProperty('labels_options')){
                        this.required_items.label_2d_option.options = value.labels_options.label_2d_options;
                    }

                    this.addForm.label_2d_option = '';
                }else{
                    this.required_items.label_2d_option.type = 'hidden_text';
                }

                if ([2,3].includes(location_type_style)){
                    this.required_items.label_3d_option.type = 'single-select';
                    if (value.hasOwnProperty('labels_options')){
                        this.required_items.label_3d_option.options = value.labels_options.label_3d_options;
                    }

                    this.addForm.label_3d_option = '';
                }else{
                    this.required_items.label_3d_option.type = 'hidden_text';
                }

                if (location_type_style === 3){
                    this.required_items.label_4d_option.type = 'single-select';
                    if (value.hasOwnProperty('labels_options')){
                        this.required_items.label_4d_option.options = value.labels_options.label_4d_options;
                    }

                    this.addForm.label_4d_option = '';
                }else{
                    this.required_items.label_4d_option.type = 'hidden_text';
                }

            }else{
                this.restForm();
            }
        },
        label_1d_option(value) {
            this.handleChangingLabelOption(value, 'label_1d_option');
        },
        label_2d_option(value) {
            this.handleChangingLabelOption(value, 'label_2d_option');
        },

        label_3d_option(value) {
            this.handleChangingLabelOption(value, 'label_3d_option');
        },
        label_4d_option(value) {
            this.handleChangingLabelOption(value, 'label_4d_option');
        },
    },
    methods:{

        addNewItemToOptions(new_item, item_type_key){
            if (new_item) {
                // should append this to the list
                let new_option = {id: new_item.id, name: new_item.name};
                this.required_items[item_type_key].options.push(new_option);
                this.addForm[item_type_key] = new_option;
            }
        },
        handleChangingLabelOption (value, required_option='') {
            if (value && value.id === -99) {
                let current_dimension_list = this.required_items[required_option].options;
                this.$refs['add-new-location_dimension'].setDimensionInfo(required_option, current_dimension_list);
                this.$refs['add-new-location_dimension'].setSelectedItem(null);
                this.addForm[required_option] = null;
                this.$bvModal.show('create-location_dimension');
            } else {
                this.handleNameChanging();
                this.handleNumberChanging();
            }
        },
        restForm(all=false) {
            this.required_items.location_type.options = [];
            this.addForm.location_type = null;
            this.required_items.label_1d_option.type = 'hidden_text';
            this.required_items.label_2d_option.type = 'hidden_text';
            this.required_items.label_3d_option.type = 'hidden_text';
            this.required_items.label_4d_option.type = 'hidden_text';

            this.required_items.label_1d_option.label = '1D Text';
            this.required_items.label_2d_option.label = '2D Text';
            this.required_items.label_3d_option.label = '3D Text';
            this.required_items.label_4d_option.label = '4D Text';

            this.addForm.label_1d_option = null;
            this.addForm.label_2d_option = null;
            this.addForm.label_3d_option = null;
            this.addForm.label_4d_option = null;
        },
        handleNameChanging(){
            let name = '';
            if (this.addForm.label_1d_option){
                name = this.addForm.label_1d_option.name;
            }

            if (this.addForm.location_type && this.addForm.location_type.hasOwnProperty('location_type')){
                if ([1, 2, 3].includes(this.addForm.location_type.location_type) && this.addForm.label_2d_option){
                    name += '-' + this.addForm.label_2d_option.name;
                }
                if ([2, 3].includes(this.addForm.location_type.location_type) && this.addForm.label_3d_option){
                    name += '-' + this.addForm.label_3d_option.name;
                }
                if (this.addForm.location_type.location_type === 3 && this.addForm.label_4d_option){
                    name += '-' + this.addForm.label_4d_option.name;
                }
            }
            this.addForm.location_name = name;
        },
        addLeadingZeros(value){
            if (!value || !value.hasOwnProperty('id')) { value = '0' }
            return _.padStart(value.id, 4, '0');
        },
        handleNumberChanging(){
            this.addForm.location_number = '1' +
                this.addLeadingZeros(this.addForm.label_4d_option) +
                this.addLeadingZeros(this.addForm.label_3d_option) +
                this.addLeadingZeros(this.addForm.label_2d_option) +
                this.addLeadingZeros(this.addForm.label_1d_option);
        },
        async getSPSpaces(warehouse_id){
            if (!warehouse_id || !warehouse_id.hasOwnProperty('id')) { return; }
            const getSPSpacesResource = new Resource('api/assetflow/sp_warehouses/getSPSpaces');

            let queryList = {
                warehouse_id: warehouse_id.id
            };
            const { data } = await getSPSpacesResource.list(queryList);

            this.required_items.space_id.options = data.data.items;
            this.addForm.space_id = null;
        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;
            this.is_saving_enabled = true;

            let required_select_lists =  {
                warehouse_id: 'sp_warehouses_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_space_modal',
                    object_type: 'sp_spaces',
                    action: 'new_sp_space',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                this.required_items.warehouse_id.options = this.lists_values.warehouse_id;

                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.restForm(true);
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.label_1d_option = this.selected_item.label_1d_option;
                this.addForm.label_2d_option = this.selected_item.label_2d_option;
                this.addForm.label_3d_option = this.selected_item.label_3d_option;
                this.addForm.label_4d_option = this.selected_item.label_4d_option;
                this.addForm.location_name = this.selected_item.location_name;
                this.addForm.location_number = this.selected_item.location_number;

                this.required_items.space_id.options = [];
                this.addForm.space_id = null;

                this.addForm.warehouse_id = this.getObjectOf(this.selected_item.warehouse_id, this.required_items.warehouse_id.options);
                this.addForm.status = this.getObjectOf(this.selected_item.status, this.required_items.status.options);
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.addForm.status = this.required_items.status.options[0];
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            let params = {
                label_1d_option: this.addForm.label_1d_option,
                label_2d_option: this.addForm.label_2d_option,
                label_3d_option: this.addForm.label_3d_option,
                label_4d_option: this.addForm.label_4d_option,
                location_name: this.addForm.location_name,
                location_number: this.addForm.location_number,
                warehouse_id: this.addForm.warehouse_id ? this.addForm.warehouse_id.id : null,
                space_id: this.addForm.space_id ? this.addForm.space_id.id : null,
                location_type: this.addForm.location_type ? this.addForm.location_type.id : null,
                status: this.addForm.status ? this.addForm.status.id : null,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_locationResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>

