<template>
    <div class="cm_ordering_table_view cm_order_overview table-responsive">
        <table>
            <thead>
            <tr>
                <th v-for="(table_header, hkey) in block_info.table_headers" :key="hkey">{{ table_header }}</th>
            </tr>
            </thead>

            <tbody v-if="block_info.required_items.length > 0">
                <tr v-for="(required_item, bkey) in block_info.required_items" :key="bkey">
                    <td>
                        <p v-if="block_info.object_type === 'sp_storages'"><i :class="required_item.storage_wrapper_info.icon"></i> <a :href="'/' + current_page_type + '/sp_storages?queue=storage_wrapper&id='+required_item.storage_wrapper_info.id">{{ required_item.storage_wrapper_info.name }}</a></p>
                        <p v-else-if="['orders', 'managed_storages'].includes(block_info.object_type)"><i class="fa fa-circle" :class="required_item.status_info.icon"></i> <a :href="'/' + current_page_type + '/orders?queue=status&id='+required_item.status_info.id">{{ required_item.status_info.name }}</a></p>

                        <p v-else-if="block_info.object_type === 'users'">
                            <img :src="(required_item.hasOwnProperty('avatar_url') ? required_item.avatar_url : required_item.avatar)" :alt="required_item.name" class="rounded-circle item-post-image list-usr-avatar" width="25" height="25"> <a :href="'/' + current_page_type + '/'+block_info.object_type+(required_item.hasOwnProperty('id') ? ('/' + required_item.id + '/view') : '')">{{ required_item.name }}</a>
                        </p>

                        <p v-else-if="block_info.object_type_icon === 'iconize' && block_info.hasOwnProperty('object_type')" v-iconize="block_info.object_type"><a :href="'/' + current_page_type + '/'+block_info.object_type+(required_item.hasOwnProperty('id') ? ('/' + required_item.id + '/view') : '')">{{ required_item.name }}</a></p>
                        <p v-else><i :class="block_info.object_type_icon"></i> <a :href="'/' + current_page_type + '/'+block_info.object_type+(required_item.hasOwnProperty('id') ? ('/' + required_item.id + '/view') : '')">{{ required_item.name }}</a></p>

                    </td>

                    <td>
                        <p v-if="block_info.object_type === 'users'">{{ required_item.email }}</p>
                        <p v-else-if="block_info.object_type === 'modules'">{{ required_item.roles_count }}</p>
                        <p v-else-if="block_info.object_type === 'roles'"><i class="fa fa-vector-square"></i> <a :href="'/' + current_page_type + '/modules/' + required_item.module_info.id + '/view'">{{ required_item.module_info.name }}</a></p>
                        <p v-else-if="['service_providers', 'customers'].includes(block_info.object_type)">
                            <span v-if="required_item.status === 1" class="badge badge-pill badge-soft-success font-size-12">Active</span>
                            <span v-else class="badge badge-pill badge-soft-danger font-size-12">Inactive</span>
                        </p>
                        <p v-else-if="required_item.hasOwnProperty('count')">{{ required_item.count || '-' }}</p>
                    </td>
                    <td v-if="['users', 'modules', 'roles'].includes(block_info.object_type)">
                        <p v-if="block_info.object_type === 'users'"><a :href="'/' + current_page_type + '/'+block_info.object_type+'/' + required_item.id + '/view'">View</a></p>
                        <p v-else-if="['modules', 'roles'].includes(block_info.object_type)">{{ required_item.users_count }}</p>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr class="table-empty-content">
                    <td>
                        <p v-if="block_info.object_type_icon === 'iconize' && block_info.hasOwnProperty('object_type')" v-iconize="block_info.object_type"><a :href="'/' + current_page_type + '/'+block_info.object_type">{{ block_info.name }}</a></p>
                        <p v-else><i :class="block_info.object_type_icon"></i> <a :href="'/' + current_page_type + '/'+block_info.object_type">{{ block_info.name }}</a></p>
                    </td>
                    <td><p>-</p></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'DashboardBlockTable',
    props:['block_info', 'current_page_type']
}
</script>
