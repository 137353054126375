<template>
    <div>
        <div class="common-step-panel use-section">
            <div class="row">
                <div class="col-sm-12">
                    <setting-info-section
                        v-for="(required_section, key) in required_sections"
                        :key="key"
                        v-if="enabled_sections.includes(key)"
                        :title="required_section.label | titleize">
                        <div>
                            <div class="st--common--box"
                                 v-for="(required_field, key) in required_section.fields"
                                 :key="key"
                            >
                                <div class="common-st-title-label">
                                    <h5>{{ required_field.label }}</h5>
                                </div>

                                <div v-if="required_field.key === 'asset_images'" class="st-drop-file-block">
                                    <div class="st-drop-file-box">
                                        <vue-dropzone id="imagesgallery"
                                                      ref="myVueDropzone"
                                                      :use-custom-slot="true"
                                                      :include-styling="false"
                                                      :options="dropzoneOptions"
                                                      @vdropzone-thumbnail="thumbnail"
                                                      @vdropzone-file-added="fileAdded"
                                                      @vdropzone-removed-file="fileRemoved"
                                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                                        >
                                            <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <div class="st-upload-title">
                                                    <h3>Drop files here or click to upload</h3>
                                                    <p>... or click to select a file from your computer</p>
                                                </div>
                                            </div>
                                        </vue-dropzone>
                                        <b-button
                                            @click="$refs.myVueDropzone[0].$el.click();"
                                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                        >Add New Image</b-button>
                                    </div>
                                </div>

                                <div class="dimensions-btn"
                                     v-if="required_field.type === 'value_types' && selected_item">
                                    <a
                                        v-for="option in (parseInt(selected_item.total_quantity) !== 1 ? value_type_options : [value_type_options[0]])"
                                        :key="option.id"
                                        :value="option.id"
                                        @click="value_type = option.id"
                                        :class="[{'active': value_type === option.id}]"
                                        class="dimensions-auto">
                                        <span></span>{{ getValueTypeTitle(option) | titleize }}
                                    </a>
                                </div>

                                <date-picker
                                    v-if="required_field.type === 'date'"
                                    v-model="addForm[required_field.key]"
                                    lang="en" :first-day-of-week="1"
                                    format="DD-MM-YYYY"
                                    value-type="DD-MM-YYYY"
                                    :placeholder="required_field.label"/>

                                <div v-else-if="required_field.type === 'readonly_text'">
                                    <h6 v-if="required_field.hasOwnProperty('message')">{{ required_field.message }}</h6>
                                    <h6 v-else>{{ received_order_type }}</h6>
                                </div>

                                <multiselect
                                    v-else-if="required_field.type === 'single-select'"
                                    v-model="addForm[required_field.key]"
                                    track-by="id"
                                    :loading="required_field.hasOwnProperty('isLoading') ? required_field.isLoading : false"
                                    :disabled="required_field.hasOwnProperty('isLoading') ? required_field.isLoading : false"
                                    :label="required_field.key === 'order_type' && item_type === 'sp' ? 'sp_name' : 'name'"
                                    :placeholder="required_field.label"
                                    class="mySingleSelect"
                                    :options="required_field.options"
                                    @input="required_field.hasOwnProperty('onChangingValue') ? required_field.onChangingValue($event) : null"
                                    :allow-empty="false"
                                    deselect-label=""
                                >
                                </multiselect>

                                <multiselect
                                    v-else-if="required_field.type === 'multi-select'"
                                    v-model="addForm[required_field.key]"
                                    track-by="id"
                                    label="name"
                                    :multiple="true"
                                    :placeholder="required_field.label"
                                    class="mySingleSelect"
                                    :options="required_field.options"
                                    deselect-label=""
                                >
                                    <span slot="noResult">No data found.</span>
                                </multiselect>


                                <div v-else-if="required_field.type === 'custom-multi-select'" class="row">
                                    <div :class="'text-center col-xl-' + (required_field.key === 'colours' ? '1' : '3')"
                                         v-for="(colour, key) in required_field.options"
                                         :key="key">

                                        <div v-if="required_field.key === 'colours'"
                                             class="color-box mt-1 p-1 rounded"
                                             :style="'background-color:' + colour.hex"
                                             @click="toggleCheckboxImage(colour, required_field.key)"
                                        >
                                        </div>
                                        <img v-else
                                             :alt="colour.name"
                                             height="50"
                                             :src="colour.image"
                                             data-holder-rendered="true"
                                             class="rounded"
                                             @click="toggleCheckboxImage(colour, required_field.key)"
                                        >

                                        <b-form-checkbox
                                            v-model="addForm[required_field.key]"
                                            :name="required_field.key"
                                            :id="required_field.key + '_' + colour.id"
                                            :value="colour"
                                            class="custom-checkbox-af-accent mt-3 mb-3 text-center"
                                        >
                                            <span class="my-2">{{ colour.name  | titleize }}</span>
                                        </b-form-checkbox>
                                    </div>
                                </div>


                                <div class="st-radio-row" v-else-if="required_field.type === 'single-checkbox'">
                                    <div class="checkbox-hint col-12 m-1" v-if="required_field.message">{{ required_field.message }}</div>
                                    <div v-for="(option, index) in required_field.options"
                                         :key="index"
                                         class="st-radio-col">
                                        <div class="cm-radio-btn" :class="required_field.key+'-' + option.id">
                                            <label>
                                                <input
                                                    v-model="addForm[required_field.key]"
                                                    :key="index"
                                                    :id="'_'+required_field.key"
                                                    :name="'_'+required_field.key"
                                                    :value="option.id"
                                                    @input="required_field.hasOwnProperty('onChangingValue') ? required_field.onChangingValue(option) : null"
                                                    type="radio" />
                                                <div class="st-radio-box">
                                                    <span></span>
                                                    <p>{{ option.name | titleize }}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-else-if="['text', 'number'].includes(required_field.type)"
                                    :class="required_field.hasOwnProperty('with_preferred_unit') && required_field.with_preferred_unit ? 'common--input-filed unit-input' : ''"
                                >
                                    <b-form-input
                                        v-model="addForm[required_field.key]"
                                        :type="required_field.type"
                                        :id="required_field.key"
                                        :placeholder="required_field.hasOwnProperty('custom_placeholder') ? required_field.custom_placeholder : required_field.label"
                                        :readonly="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                                        @input="required_field.hasOwnProperty('onChangingValue') ? required_field.onChangingValue() : null"
                                        autocomplete="none"
                                    ></b-form-input>


                                    <span class="u-mm" v-if="required_field.hasOwnProperty('with_preferred_unit') && required_field.with_preferred_unit">
                                        {{ !['area', 'total_area', 'volume', 'total_volume'].includes(required_field.key) && addForm.preferred_unit ? addForm.preferred_unit.name : 'm' }}
                                        <sup v-if="['volume', 'total_volume'].includes(required_field.key)">3</sup>
                                        <sup v-if="['area', 'total_area'].includes(required_field.key)">2</sup>
                                    </span>
                                </div>


                                <div class="common--input-filed" v-else-if="required_field.type === 'textarea'">
                                    <textarea v-model="addForm[required_field.key]" :id="required_field.key"></textarea>
                                </div>

                                <small v-if="has_errors && required_field.is_required && !!addForm[required_field.key]">
                                    This Field is required
                                </small>

                            </div>
                        </div>
                    </setting-info-section>
                </div>

            </div>
        </div>
        <div class="text-right mt-2">


            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-change' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :first_argument="true"
                required_text="Process"
                item_classes="btn btn-success"
            />


            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-change' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :first_argument="true"
                :required_text="getModalTitle()"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="$emit('on-cancel-clicked')">Cancel</b-button>
        </div>
    </div>
</template>
<script>
import SettingInfoSection from "../settings/settingsForm/SettingInfoSection";
import DatePicker from "vue2-datepicker";
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import QuickAddNewAsset from "./QuickAddNewAsset";

const order_lineResource = new Resource('api/assetflow/order_lines/processOrderLine');
const intake_profileResource = new Resource('api/assetflow/intake_profiles');
const warehousing_movementResource = new Resource('api/assetflow/warehousing_movements');
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AFButton from "@/components/AFButton";

export default {
    name: 'OrderLineProcessingFieldsOld',
    components: {AFButton, QuickAddNewAsset, SettingInfoSection, DatePicker},
    mixins: [multiSelect, dropzoneMixin],
    props: [
        'is_full',
        'item_type',
        'received_order_type',
        'received_parent_order',
        'received_selected_item',
        'enabled_sections'
    ],
    data(){
        return {
            has_errors: null,
            addForm:{
                filled_asset_images:[],
                asset_images:[],
                category:null,
                preferred_unit:null,
                width:null,
                height:null,
                depth:null,
                area:null,
                volume:null,
                weight:null,
                colours:[],
                condition:null,
                waste_stream:[],
                shall_update_parent: 1,
                notes:null,
                movement_type:null,
                movement_source_type:null,
                movement_source_id:null,
                movement_target_type:null,
                movement_target_id:null
            },
            required_sections: {
                intake_profile_details:{
                    'label' : 'Intake Profile',
                    fields: {
                        intake_images: {
                            key: 'asset_images',
                            label: 'Images',
                            type: 'intake_images',
                        },
                        category: {
                            key: 'category',
                            label: 'Category',
                            type: 'single-select',
                            is_required:false,
                            isLoading: false,
                            options: []
                        },
                        value_types: {
                            key: 'value_types',
                            label: '',
                            type: 'value_types',
                            options:[]
                        },
                        preferred_unit: {
                            key: 'preferred_unit',
                            label: 'Preferred Unit',
                            type: 'single-select',
                            options:[],
                            onChangingValue: function (value){},
                        },
                        width: {
                            key: 'width',
                            label: 'Width',
                            type: 'number',
                            with_preferred_unit: true,
                            onChangingValue: function (value){},
                        },
                        height: {
                            key: 'height',
                            label: 'Height',
                            type: 'number',
                            with_preferred_unit: true,
                            onChangingValue: function (value){},
                        },
                        depth: {
                            key: 'depth',
                            label: 'Depth',
                            type: 'number',
                            with_preferred_unit: true,
                            onChangingValue: function (value){},
                        },
                        area: {
                            key: 'area',
                            label: 'Area For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_area: {
                            key: 'total_area',
                            label: 'Total Area',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        volume: {
                            key: 'volume',
                            label: 'Volume For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_volume: {
                            key: 'total_volume',
                            label: 'Total Volume',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        weight: {
                            key: 'weight',
                            label: 'Weight',
                            type: 'number'
                        },
                        colours: {
                            key: 'colours',
                            label: 'Colours',
                            type: 'custom-multi-select',
                            options: [
                            ]
                        },
                        condition: {
                            key: 'condition',
                            label: 'Condition',
                            type: 'single-select',
                            options:[
                                { id: 1, name: 'New' },
                                { id: 2, name: 'Excellent' },
                                { id: 3, name: 'Good' },
                                { id: 4, name: 'Attention Required' },
                            ]
                        },
                        waste_stream: {
                            key: 'waste_stream',
                            label: 'Waste Stream',
                            type: 'custom-multi-select',
                            options: [
                                {
                                    id: 'metal',
                                    name: 'Metal',
                                    image: '/images/error-img.png'
                                },
                                {
                                    id: 'wood',
                                    name: 'Wood',
                                    image: '/images/megamenu-img.png'
                                },
                                {
                                    id: 'plastic',
                                    name: 'Plastic',
                                    image: '/images/verification-img.png'
                                },
                                {
                                    id: 'fabric',
                                    name: 'Fabric',
                                    image: '/images/profile-img.png'
                                },

                                {
                                    id: 'foam',
                                    name: 'Foam',
                                    image: ''
                                },
                                {
                                    id: 'fibreglass',
                                    name: 'Fibreglass',
                                    image: ''
                                },
                                {
                                    id: 'ceramic',
                                    name: 'Ceramic',
                                    image: ''
                                },
                                {
                                    id: 'plaster',
                                    name: 'Plaster',
                                    image: ''
                                }
                            ]
                        },
                        shall_update_parent: {
                            key: 'shall_update_parent',
                            label: 'Update Asset Passport',
                            type: 'single-checkbox',
                            options: [
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                },
                            ]
                        },
                        notes: {
                            key: 'notes',
                            label: 'Notes',
                            type: 'textarea',
                            is_required:false
                        },
                    }
                },
                warehousing_details:{
                    'label' : 'Warehousing Details',
                    fields: {
                        // 0:Park, 1:Vacate, 2:Vacate & Park, 3:Intake, 4:Relocation, 5:Dispatched
                        movement_type: {
                            key: 'movement_type',
                            label: 'Movement Type',
                            type: 'single-select',
                            options: [
                                {
                                    id: 0,
                                    name: 'Park'
                                },
                                {
                                    id: 1,
                                    name: 'Vacate'
                                },
                                {
                                    id: 2,
                                    name: 'Vacate & Park'
                                },
                                {
                                    id: 3,
                                    name: 'Intake'
                                },
                                {
                                    id: 4,
                                    name: 'Relocation'
                                },
                                {
                                    id: 5,
                                    name: 'Dispatched'
                                }
                            ]
                        },
                        // 0:Homeless, 1:Location, 2:Delivery, 3:Collection, 4:Storage
                        movement_source_type: {
                            key: 'movement_source_type',
                            label: 'Source Type',
                            type: 'single-select',
                            options:[
                                {
                                    id: 0,
                                    name: 'Homeless'
                                },
                                {
                                    id: 1,
                                    name: 'Location'
                                },
                                {
                                    id: 2,
                                    name: 'Delivery'
                                },
                                {
                                    id: 3,
                                    name: 'Collection'
                                },
                                {
                                    id: 4,
                                    name: 'Storage'
                                },
                                {
                                    id: 5,
                                    name: 'Order'
                                }
                            ]
                        },
                        // movement_source_id will depend on movement_source_type value
                        movement_source_id: {
                            key: 'movement_source_id',
                            label: 'Source',
                            type: 'single-select',
                            options:[
                                { id: null, name: 'Homeless' },
                                { id: 4, name: 'Source 4' },
                                { id: 3, name: 'Source 3' },
                                { id: 2, name: 'Source 2' },
                                { id: 1, name: 'Source 1' },
                            ]
                        },
                        // 0:Homeless, 1:Location, 2:Delivery, 3:Collection, 4:Storage, 5:Recycle, 6:Archived, 7:User Defined
                        movement_target_type: {
                            key: 'movement_target_type',
                            label: 'Target Type',
                            type: 'single-select',
                            options:[
                                {
                                    id: 0,
                                    name: 'Homeless'
                                },
                                {
                                    id: 1,
                                    name: 'Location'
                                },
                                {
                                    id: 2,
                                    name: 'Delivery'
                                },
                                {
                                    id: 3,
                                    name: 'Collection'
                                },
                                {
                                    id: 4,
                                    name: 'Storage'
                                },
                                {
                                    id: 5,
                                    name: 'Order'
                                },
                                {
                                    id: 6,
                                    name: 'Recycle'
                                },
                                {
                                    id: 7,
                                    name: 'Archived'
                                },
                                {
                                    id: 8,
                                    name: 'User Defined'
                                }
                            ]
                        },
                        // movement_target_id will depend on movement_target_type value
                        movement_target_id: {
                            key: 'movement_target_id',
                            label: 'Target',
                            type: 'single-select',
                            options:[
                                { id: null, name: 'Homeless' },
                                { id: 4, name: 'Target 4' },
                                { id: 3, name: 'Target 3' },
                                { id: 2, name: 'Target 2' },
                                { id: 1, name: 'Target 1' },
                            ]
                        },
                    }
                },
            },
            isAddingItem: null,
            selected_item: null,
            list_disabled: null,
            listQuery: {},
            preferred_units:[
                {id: 1000, name: 'mm'},
                {id: 100, name: 'cm'},
                {id: 1, name: 'm'},
            ],
            value_type: 0,
            value_type_options:[
                {
                    id: 0,
                    name: 'Dimensions for 1 item'
                },
                {
                    id: 1,
                    name: 'Dimensions for (x) items'
                }
            ],
        }
    },
    mounted() {
        if (this.received_selected_item){
            this.setSelectedItem(this.received_selected_item);
        }
    },
    computed:{

        is_processing_form(){
            return this.has_intake_profile_details && this.has_intake_profile_details && this.enabled_sections.length > 1;
        },
        has_intake_profile_details(){
            return this.enabled_sections.includes('intake_profile_details');
        },
        has_warehousing_details(){
            return this.enabled_sections.includes('warehousing_details');
        }
    },
    watch: {
        value_type:{
            deep: true,
            handler: function (newValue) {
                this.handleChangingVolume();
            }
        },
    },
    methods:{
        fillAssetImages() {
            if (this.addForm.hasOwnProperty('asset_images') && this.addForm.asset_images.length > 0) {
                for (const assetImagesKey in this.addForm.asset_images) {
                    let asset_image = this.addForm.asset_images[assetImagesKey];
                    var file = {
                        id: asset_image.id,
                        size: asset_image.image_name_size,
                        name: asset_image.name,
                        type: "image/png",
                        is_featured: asset_image.is_featured,
                        add_to_ap: asset_image.add_to_ap
                    };
                    var url = asset_image.image_name;

                    if (asset_image.image_name_size) {
                        this.$refs.myVueDropzone[0].manuallyAddFile(file, url);
                    }
                }
            }
        },
        setSelectedItem(selected_item){
            this.selected_item = selected_item;

            if (this.has_intake_profile_details){
                this.addForm.preferred_unit = window.USER_INFO.user_meta.preferred_dimension_unit ?? this.preferred_units[0];
                this.required_sections.intake_profile_details.fields.preferred_unit.options = this.preferred_units;
                this.required_sections.intake_profile_details.fields.preferred_unit.onChangingValue = this.handleChangingVolume;
                this.required_sections.intake_profile_details.fields.width.onChangingValue = this.handleChangingVolume;
                this.required_sections.intake_profile_details.fields.height.onChangingValue = this.handleChangingVolume;
                this.required_sections.intake_profile_details.fields.depth.onChangingValue = this.handleChangingVolume;
                this.getListOf('category');
                this.getListOf('colours');
                this.getListOf('waste_stream');
            }

            if (this.selected_item){
                if (this.is_processing_form){
                    this.fillIntakeProfileInfo();
                    this.fillWarehousingMovementInfo();
                }else if (this.has_intake_profile_details){
                    this.fillIntakeProfileInfo();
                }else if (this.has_warehousing_details){
                    this.fillWarehousingMovementInfo();
                }
            }
        },
        getValueTypeTitle(option) {
            if (this.selected_item && option.id === 1) {
                return option.name.replace('(x)', this.selected_item.total_quantity);
            }
            return option.name
        },
        getModalTitle(){
            if (this.is_processing_form){
                return 'Save';
            }else if (this.has_intake_profile_details){
                return this.selected_item && this.selected_item.hasOwnProperty('intake_profile_info') ? ('Update Intake Profile {' + this.selected_item.intake_profile_info.uid + '}') : 'Add Intake Profile';
            }else if (this.has_warehousing_details){
                return this.selected_item && this.selected_item.hasOwnProperty('warehousing_movement_info') ? ('Update Warehousing {' + this.selected_item.warehousing_movement_info.uid + '}') : 'Add Warehousing';
            }else{
                return 'Save';
            }
        },
        setSelectedFormOptionFor: function (key_name, section_name, object_info) {
            let options_list = this.required_sections[section_name].fields[key_name].options;
            let id_name = 'id'
            if (key_name === 'preferred_unit'){
                id_name = 'name';
            }
            this.addForm[key_name] = this.getObjectOf(this.selected_item[object_info][key_name], options_list, id_name);
        },
        fillWarehousingMovementInfo: function () {
            if (this.selected_item.hasOwnProperty('warehousing_movement_info') && this.selected_item.warehousing_movement_info) {
                this.setSelectedFormOptionFor('movement_type', 'warehousing_details', 'warehousing_movement_info');
                this.setSelectedFormOptionFor('movement_source_type', 'warehousing_details', 'warehousing_movement_info');
                this.setSelectedFormOptionFor('movement_source_id', 'warehousing_details', 'warehousing_movement_info');
                this.setSelectedFormOptionFor('movement_target_type', 'warehousing_details', 'warehousing_movement_info');
                this.setSelectedFormOptionFor('movement_target_id', 'warehousing_details', 'warehousing_movement_info');
            }
        },
        fillIntakeProfileInfo: function () {

            if (this.selected_item.hasOwnProperty('intake_profile_info') && this.selected_item.intake_profile_info) {
                this.setSelectedFormOptionFor('preferred_unit', 'intake_profile_details', 'intake_profile_info');
                this.addForm.width = this.selected_item.intake_profile_info.width;
                this.addForm.height = this.selected_item.intake_profile_info.height;
                this.addForm.depth = this.selected_item.intake_profile_info.depth;
                this.addForm.total_area = this.selected_item.intake_profile_info.area;
                this.addForm.total_volume = this.selected_item.intake_profile_info.volume;
                this.addForm.notes = this.selected_item.intake_profile_info.notes;
                this.setSelectedFormOptionFor('condition', 'intake_profile_details', 'intake_profile_info');


                if (this.selected_item.intake_profile_info.hasOwnProperty('asset_images')){
                    this.addForm.asset_images = [... this.selected_item.intake_profile_info.asset_images];
                    this.fillAssetImages();
                }

                this.value_type = this.value_type_options[1].id;
                this.handleChangingVolume();
            }else if (this.selected_item.hasOwnProperty('item_parent_afid') && this.selected_item.item_parent_afid) {
                this.addForm.preferred_unit = this.getObjectOf(this.selected_item.item_parent_afid.preferred_unit, this.preferred_units, 'name');
                this.addForm.width = this.selected_item.item_parent_afid.width;
                this.addForm.height = this.selected_item.item_parent_afid.height;
                this.addForm.depth = this.selected_item.item_parent_afid.depth;
                this.addForm.total_area = this.selected_item.item_parent_afid.area;
                this.addForm.total_volume = this.selected_item.item_parent_afid.volume;
                this.setSelectedFormOptionFor('condition', 'intake_profile_details', 'item_parent_afid');

                this.value_type = this.value_type_options[1].id;
                this.handleChangingVolume();
            }


        },
        getMultiValueFrom: function (value_id, required_list) {
            var foundItems = [];
            if (value_id){
                for (const v_id in value_id) {
                    let foundItem = this.getObjectOf(value_id[v_id], required_list);
                    if (foundItem) {
                        foundItems.push(foundItem);
                    }
                }
            }
            return foundItems;
        },
        reformatNumbers(number){
            let new_value = number ? parseFloat((number+"").replace(',', '')) : '';
            return new_value !== '' && this.addForm.hasOwnProperty('preferred_unit') && this.addForm.preferred_unit ? new_value / this.addForm.preferred_unit.id : '';
        },
        handleChangingVolume(){
            let item_details = this.addForm,
                width = this.reformatNumbers(item_details.width),
                height = this.reformatNumbers(item_details.height),
                depth = this.reformatNumbers(item_details.depth);

            if (width === '' && height === '' && depth === ''){
                return;
            }

            this.$set(this.addForm, (this.value_type === 0 ? 'volume' : 'total_volume'), (width * height * depth).toFixed(2));

            let dimensions = [width, height, depth];
            dimensions = dimensions.sort(function(a, b) {
                return a - b;
            }).slice(-2);
            let area = dimensions.reduce( (a,b) => a * b );
            this.$set(this.addForm, (this.value_type === 0 ? 'area' : 'total_area'), area.toFixed(2));

            let quantity = this.selected_item.total_quantity;
            if (this.value_type === 0) {
                // for one item!
                let total_area = (this.addForm.area * quantity).toFixed(2);
                let total_volume = (this.addForm.volume * quantity).toFixed(2);
                this.$set(this.addForm, 'total_area', total_area);
                this.$set(this.addForm, 'total_volume', total_volume);
            } else {
                // for all
                let area = (this.addForm.total_area / quantity).toFixed(2);
                let volume = (this.addForm.total_volume / quantity).toFixed(2);
                this.$set(this.addForm, 'area', area);
                this.$set(this.addForm, 'volume', volume);
            }

        },
        toggleCheckboxImage(colour, field_key){
            let form_value = this.addForm[field_key];
            if (form_value){
                let index = form_value.map(function(e) { return e.id; }).indexOf(colour.id);
                if (index > -1){
                    // found, remove it!
                    form_value.splice(index, 1);
                }else{
                    // not found, add it
                    form_value.push(colour);
                }
            }else{
                form_value = [];
                form_value.push(colour);
            }
            this.$set(this.addForm, field_key, form_value);
        },
        async getListOf(item_type) {
            if (item_type){
                let parent_section = '';
                switch (item_type){
                    case 'colours':
                        parent_section = 'intake_profile_details';
                        break;
                    case 'category':
                        parent_section = 'intake_profile_details';
                        this.required_sections[parent_section].fields[item_type].isLoading = true;
                        break;
                    case 'waste_stream':
                        parent_section = 'intake_profile_details';
                        break;
                }

                axios({
                    method:'post',
                    url:'/api/assetflow/customers/get_list',
                    baseURL: '/',
                    data: {
                        list_name: item_type
                    },
                }).then(response => {
                    let required_items = response.data.items;

                    if (required_items.length > 0){
                        this.required_sections[parent_section].fields[item_type].options = required_items;

                        if (this.has_intake_profile_details){

                            if (['colours', 'waste_stream', 'category'].includes(item_type)){
                                let required_value = this.selected_item.hasOwnProperty('item_parent_afid') && this.selected_item.item_parent_afid ? this.selected_item.item_parent_afid[(item_type === 'category' ? 'category_id' : item_type)] : null;
                                if (this.selected_item){
                                    if (this.selected_item.hasOwnProperty('intake_profile_info')){
                                        if (item_type === 'category'){
                                            if (this.selected_item.intake_profile_info.hasOwnProperty('category_info') && this.selected_item.intake_profile_info.category_info){
                                                required_value = this.selected_item.intake_profile_info.category_info.id;
                                            }
                                        }else{
                                            required_value = this.selected_item.intake_profile_info[item_type];
                                        }

                                    }
                                }
                                if (item_type === 'category'){
                                    this.addForm[item_type] = this.getObjectOf(required_value, required_items);
                                }else{
                                    this.addForm[item_type] = this.getMultiValueFrom(required_value, required_items);
                                }

                            }
                        }
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    if (item_type === 'category'){
                        this.required_sections[parent_section].fields[item_type].isLoading = false;
                    }
                });
            }
        },
        async addItem(shall_complete_order_line, event){
            this.isAddingItem = true;

            let all_files = this.checkAndGetFiles(this.addForm.filled_asset_images);

            if (!all_files){
                return;
            }
            let params = {};
            let requiredResource = null;
            let intake_profile_params = {}
            let warehousing_params = {}
            if (this.has_intake_profile_details){
                intake_profile_params = {
                    order_line_id: this.selected_item.id,
                    asset_images: all_files,
                    category_id: this.addForm.category ? this.addForm.category.id : 0,
                    preferred_unit: this.addForm.preferred_unit ? this.addForm.preferred_unit.name : 'm',
                    width: this.addForm.width,
                    height: this.addForm.height,
                    depth: this.addForm.depth,
                    area: this.addForm.total_area,
                    volume: this.addForm.total_volume,
                    weight: this.addForm.weight,
                    colours: this.addForm.colours ? this.getIDs(this.addForm.colours) : null,
                    condition: this.addForm.condition ? this.addForm.condition.id : null,
                    waste_stream: this.addForm.waste_stream ? this.getIDs(this.addForm.waste_stream) : null,
                    shall_update_parent: this.addForm.shall_update_parent,
                    notes: this.addForm.notes,
                };
            }
            if (this.has_warehousing_details){
                warehousing_params = {
                    order_line_id: this.selected_item.id,
                    movement_type: this.addForm.movement_type ? this.addForm.movement_type.id : null,
                    movement_source_type: this.addForm.movement_source_type ? this.addForm.movement_source_type.id : null,
                    movement_source_id: this.addForm.movement_source_id ? this.addForm.movement_source_id.id : null,
                    movement_target_type: this.addForm.movement_target_type ? this.addForm.movement_target_type.id : null,
                    movement_target_id: this.addForm.movement_target_id ? this.addForm.movement_target_id.id : null
                };
            }


            if (this.selected_item) {
                let intake_profile_info = null;
                if (this.selected_item.hasOwnProperty('intake_profile_info') && this.selected_item.intake_profile_info) {
                    intake_profile_info = this.selected_item.intake_profile_info;
                }

                let warehousing_movement_info = null;
                if (this.selected_item.hasOwnProperty('warehousing_movement_info') && this.selected_item.warehousing_movement_info) {
                    warehousing_movement_info = this.selected_item.warehousing_movement_info;
                }
                if (this.is_processing_form) {
                    if (intake_profile_info){
                        intake_profile_params.ip_item_id = intake_profile_info.id;
                    }
                    if (warehousing_movement_info){
                        warehousing_params.wm_item_id = warehousing_movement_info.id;
                    }
                } else if (this.has_intake_profile_details && intake_profile_info) {
                    intake_profile_params.ip_item_id = intake_profile_info.id;
                } else if (this.has_warehousing_details && warehousing_movement_info) {
                    warehousing_params.wm_item_id = warehousing_movement_info.id;
                }
            }

            if (this.is_processing_form){
                params = Object.assign( {}, intake_profile_params, warehousing_params);
                requiredResource = order_lineResource;
            }else if (this.has_intake_profile_details){
                requiredResource = intake_profileResource;
                params = intake_profile_params;
            }else if (this.has_warehousing_details){
                requiredResource = warehousing_movementResource;
                params = warehousing_params;
            }


            params.shall_complete_order_line = shall_complete_order_line;
            const { data } = await requiredResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;

            this.$emit('after-saving-is-done', new_item, shall_complete_order_line);
        },
    }
}
</script>
