<template>
    <div class="btn-group bl-auto">
        <button type="button" class="btn btn-none dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <b-dropdown-item v-if="permissions_list['view_' + object_type]"
                             :href="'/' + item_type + '/'+object_type+'/' + item.id + '/view'">
                View
            </b-dropdown-item>
            <b-dropdown-item v-if="permissions_list['edit_' + object_type] && item.status === 0"
                             @click="performAction(item, 'set_order_service', index, $event)">
                {{ item.service_id ? 'Change Service' : 'Set Order Service' }}
            </b-dropdown-item>
            <b-dropdown-item v-if="permissions_list['browse_users'] && permissions_list['manage_orders']"
                             @click="performAction(item, 'set_order_subscribers', index, $event)">Set Subscribers</b-dropdown-item>

            <b-dropdown-item v-if="permissions_list['manage_orders'] && item.status === 0 && item.hasOwnProperty('order_lines_count') && item.order_lines_count"
                             @click="performAction(item, 'remove_order_items', index, $event)">Remove All {{ item.order_lines_count }} Items</b-dropdown-item>

            <b-dropdown-item v-if="permissions_list['edit_' + object_type] && item.status === 0"
                             :href="'/' + item_type + '/'+object_type+'/' + item.id + '/confirm'">
                Confirm Order
            </b-dropdown-item>
            <b-dropdown-item v-if="permissions_list['edit_' + object_type] && item.status === 1 && item.completed_order_lines_count === 0"
                             @click="performAction(item, 'unconfirm', index, $event)">
                Unconfirm Order
            </b-dropdown-item>
            <b-dropdown-item v-if="permissions_list['edit_' + object_type] && item.status === 0"
                             @click="performAction(item, 'edit', index, $event)">
                Edit
            </b-dropdown-item>
            <b-dropdown-item v-if="permissions_list['delete_' + object_type] && item.status === 0 && item.order_lines_count === 0"
                             variant="danger"
                             @click="performAction(item, 'delete' + object_type, index, $event)">
                Delete
            </b-dropdown-item>

            <ExportAdditionalActions :item="item" object_single_name="order" required_permission_name="browse_files"/>
        </div>
    </div>
</template>
<script>
import ExportAdditionalActions from "./ExportAdditionalActions";

export default {
    name: 'OrderActionsList',
    components: {ExportAdditionalActions},
    props: {
        index: {},
        item: {},
        item_type: {},
        object_type: {},
        performAction: {},
        permissions_list: {}
    }
}
</script>
