const FirstPath = window.location.pathname.split('/')[1];
export const CustomerKeysList = {
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-3 prod_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {

            let style = '';
            if (item.hasOwnProperty('meta') && item.meta && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                let bg_colour = item.meta.accent_colour;
                let text_colour = item.meta.primary_colour;
                style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
            }
            let initials = '<a href="/' + FirstPath + '/customers/'+item.id+'/view" class="text-dark">' + '<div class="avatar-sm"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item.initials+'</span></div>' + '</a>';

            let is_demo = '';
            if (item.demo_only === 1){
                is_demo = '<span class="badge badge-danger ml-1">Demo</span>';
            }

            return initials + '<a href="/' + FirstPath + '/customers/'+item.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item[key]) + '</a> ' + is_demo;
        }
    },
    logo: {
        key: 'logo',
        label: "Logo",
        class: "col-lg-1 prod_logo",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<img class="m-3" src="' + item.logo + '/inside/100/100" alt="' + item.name + '" />';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 prod_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<span class="active_button button_size data_status'+(item.status === 1 ? 'active_status' : 'inactive_status')+'">'+(item.status === 1 ? 'Active' : 'Inactive')+'</span>';
        }
    },
    storage_count: {
        key: 'storage_count',
        label: "Storage",
        class: "col-lg-1 prod_storage_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] ? item[key] : 0;
        }
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets",
        class: "col-lg-2 prod_assets_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let assets_count = item.assets_count;
            if (assets_count){
                return assets_count + ' Asset' + (assets_count !== 1 ? 's' : '');
            }
            return '0 Assets';
        }
    },
    stock_count: {
        key: 'stock_count',
        label: "Stock",
        class: "col-lg-3 prod_stock_count",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<div class="cm_status_ul"><ul><li><p>Total <span class="total_box_bg">' + (item.assets_total_quantity || 0) + '</span></p></li> <li><p>Available <span class="available_box_bg">' + (item.assets_available_quantity || 0) + '</span></p></li> <li><p>Reserved <span class="reserved_box_bg">' + (item.assets_reserved_quantity || 0) + '</span></p></li></ul></div>';
        }
    },
};
