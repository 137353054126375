<template>
    <component v-if="selected_setting"
               :is="selected_setting.hasOwnProperty('soon') && !selected_setting.hasOwnProperty('use_template') ? 'coming_soon' : selected_setting.id"
               :selected_setting="selected_setting"/>
</template>
<script>
import coming_soon from "./SettingsComponents/ComingSoon";
import customer_status from "./SettingsComponents/CustomerStatus";
import currency_timezone from "./SettingsComponents/CurrencyTimezone";
import logo_appearance from "./SettingsComponents/LogoAppearance";
import industry from "./SettingsComponents/Industry";
import pg_inheritance from "./SettingsComponents/Inheritance";


// billing
import group_usage_limits from "./SettingsComponents/BillingUsage";

// features
import features_status from "./SettingsComponents/FeaturesStatus";

// users_access
import users_access_users from "./SettingsComponents/UsersList";
import users_access_user_roles from "./SettingsComponents/RolesList";
import users_access_service_providers from "./SettingsComponents/SpsList";

// assets
import assets_core from "./SettingsComponents/AssetsCore";
import assets_defaults from "./SettingsComponents/AssetsDefaults";
import assets_structure from "./SettingsComponents/AssetsStructure";
import assets_tracking from "./SettingsComponents/AssetsTracking";
import assets_workflow from "./SettingsComponents/AssetsWorkflow";
import assets_intelligence from "./SettingsComponents/AssetsIntelligence";
import assets_lifecycle from "./SettingsComponents/AssetsLifecycle";
import assets_labelling from "./SettingsComponents/AssetsLabelling";
import assets_incoming_assets from "./SettingsComponents/AssetsIncomingAssets";


// assetflow
import assetflow_passports from "./SettingsComponents/AssetflowPassports";
import assetflow_splits from "./SettingsComponents/AssetflowSplits";
import assetflow_bank from "./SettingsComponents/AssetflowBank";
import assetflow_transfers from "./SettingsComponents/AssetflowTransfers";
import assetflow_loans from "./SettingsComponents/AssetflowLoans";
import assetflow_marketplace from "./SettingsComponents/AssetflowMarketplace";



// accounting
import accounting_general from "./SettingsComponents/AccountingGeneral";
import accounting_suppliers from "./SettingsComponents/AccountingSuppliers";
import accounting_purchase_orders from "./SettingsComponents/AccountingPurchaseOrders";
import accounting_ledgers from "./SettingsComponents/AccountingLedgers";


// Events
import events from "./SettingsComponents/Events";

// Addresses & Locations
import delivery_address from "./SettingsComponents/DeliveryAddress";
import invoicing_address from "./SettingsComponents/InvoicingAddress";


// RAW System
import raw_features from "./SettingsComponents/RawFeatures";
import raw_settings from "./SettingsComponents/RawSettings";

export default {
    name: 'SettingContent',
    components: {
        coming_soon,

        customer_status,
        currency_timezone,
        logo_appearance,
        industry,
        pg_inheritance,

        group_usage_limits,

        features_status,

        users_access_users,
        users_access_user_roles,
        users_access_service_providers,

        assets_core,
        assets_defaults,
        assets_structure,
        assets_tracking,
        assets_workflow,
        assets_intelligence,
        assets_lifecycle,
        assets_labelling,
        assets_incoming_assets,

        assetflow_passports,
        assetflow_splits,
        assetflow_bank,
        assetflow_transfers,
        assetflow_loans,
        assetflow_marketplace,

        accounting_general,
        accounting_suppliers,
        accounting_purchase_orders,
        accounting_ledgers,

        events,

        delivery_address,
        invoicing_address,

        raw_features,
        raw_settings,
    },
    props:['selected_setting']
}
</script>
