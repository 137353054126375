<template>
    <Layout>
        <div class="roles-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title"><span v-if="current_entity" class="current_entity_name" :class="current_entity.entity_type + '-entity'">{{ current_entity.name }}</span> Role List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Roles.."></search-box>
                            <b-dropdown v-if="hasPermission('add_roles')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_roles')" href="./roles/new">Role
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->

                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Roles...</h4>
                    </div>
                    <div v-else-if="!rolesData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Roles found here at the moment.</h4>

                    </div>
                    <div v-else-if="rolesData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'roles'"
                                :keys_list="role_list_keys_list">

                                <template v-slot:role_actions="{ item, index }">
                                    <a v-if="permissions_list['edit_' + permission_name]" :href="(item_type !== '' ? '/' + item_type : '') + '/roles/'+item.id + '/edit'" class="btn btn-primary btn-sm">Edit</a>
                                    <b-button v-if="permissions_list['delete_' + permission_name]" class="btn-sm" href="javascript:void(0);" @click="performAction(item, 'delete', index, $event)" variant="outline-danger">Delete</b-button>
                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                            @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";

import SearchBox from "@/components/search-box";
import AssetActions from "@/mixins/assetActions.mixin";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {GroupsSortOptions} from "@/views/assetflow/form_data/GroupsSortOptions";
import {RoleKeysList} from "@/views/assetflow/form_data/RoleKeysList";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";


const roleResource = new Resource('api/assetflow/roles');


export default {
    name: 'RolesList',
    mixins: [AssetActions],
    props: ['item_id', 'item_type'],
    components: {LotsOfItemsAlert, PoLines, SearchBox, Layout},
    data() {
        return {
            listLoading: false,
            role_list_keys_list: RoleKeysList,
            total: 0,
            rolesData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Roles",
            items: [
                {
                    text: "Roles",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'roles'
            },
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: GroupsSortOptions,
            },
            has_role_actions_permissions: false,
            permissions_list:{},
            permission_name: 'roles',
        };
    },
    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
    },
    created() {


        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);
        this.has_role_actions_permissions = this.permissions_list['delete_' + this.permission_name] || this.permissions_list['edit_' + this.permission_name];

        delete this.role_list_keys_list.entity_type;
        delete this.role_list_keys_list.entity;

        if (!this.isSuperAdmin){
            delete this.role_list_keys_list.is_default;
            this.role_list_keys_list.name.class = 'rol_name col-lg-' + (this.has_role_actions_permissions ? '5' : '6');
            this.role_list_keys_list.module.class = 'col-lg-4 rol_module';
        }else{
            this.role_list_keys_list.name.class = 'rol_name col-lg-' + (this.has_role_actions_permissions ? '4' : '5');
            this.role_list_keys_list.module.class = 'col-lg-4 rol_module';
        }


        if (this.has_role_actions_permissions){
            this.role_list_keys_list.sharing_type.class = 'col-lg-1 rol_sharing_type';
            this.role_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 rol_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_class: '',
                slot_name: 'role_actions'
            }
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_roles_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.role_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.role_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        performAction(role, action, roleKey, event){
            if (action === 'delete'){
                if (role.id !== ''){
                    this.deleteItemByID(role.id, role.name, 'roles', 'event')
                        .then((response) => {
                            if (response.data.data.status){
                                this.rolesData.splice(roleKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            return this.rolesData;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('role_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_roles_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery);
            this.listQuery.s_keyword = this.search_keyword;

            const {data} = await roleResource.list(this.listQuery);
            this.total = data.meta.total;
            this.rolesData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-role'].setSelectedItem(null);
            this.$refs['add-new-role'].getFieldsList();
            this.$bvModal.show('create-role');
        }
    },
}
</script>


