export const BulkUpdateOptions = [
    {
        value: '0',
        text: "Disabled"
    },
    {
        value: '2',
        text: "Available"
    },
    {
        value: '1',
        text: "Enabled"
    }
];
