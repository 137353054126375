export const AcquisitionMethodsList = [
    {
        id: 0,
        name: 'In Shop'
    },
    {
        id: 1,
        name: 'Internet'
    },
    {
        id: 2,
        name: 'Market'
    },
];
