<script>
/**
 * Forgot Password component
 */
export default {
    data() {
        return {
            email: "",
            tryingToReset: false,
            isResetError: false
        };
    },
    mounted() {
        this.isResetError = !!this.error;
        this.tryingToReset = !!this.status;
    },
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: () => null
        },
        status: {
            type: String,
            required: false,
            default: () => null
        }
    }
};
</script>

<template>
<div class="p-2">
    <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{error}}</b-alert>
    <b-alert v-model="tryingToReset" class="mb-4" variant="success" dismissible>{{status}}</b-alert>
    <form :action="submitUrl" method="POST">
        <slot />
        <div class="form-group">
            <label for="useremail">Email</label>
            <input type="email" name="email" v-model="email" class="form-control" id="useremail" placeholder="Enter email" />
        </div>
        <div class="form-group row mb-0">
            <div class="col-12 text-right">
                <button class="btn btn-primary w-md" type="submit">Reset</button>
            </div>
        </div>
    </form>
</div>
</template>
