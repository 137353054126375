<template>
    <div class="af-images" :class="parent_class">
        <b-carousel
            v-if="my_asset.hasOwnProperty(asset_images_name) && my_asset[asset_images_name] && my_asset[asset_images_name].length > 0"
            class="product-image-slider carousel-with-controls"
            style="text-shadow: 0px 0px 2px #000"
            :interval="0"
            ref="myGridCarousel"
            v-model="my_asset.current_image">

            <a v-for="(src, index) in my_asset[asset_images_name]"
               :key="index"
               @click="() => showCaptiomImg(index, my_asset[asset_images_name])">
                <my-b-carousel-slide :image_index="index"
                                     :src="src"
                                     :done_action="done_action"
                                     :image_size="(image_sizes.hasOwnProperty(required_image_size) ? image_sizes[required_image_size] : '')"/>
            </a>

            <MyCarouselNavigation :current_image="my_asset.current_image" :images_count="my_asset[asset_images_name].length"/>
        </b-carousel>
        <a v-else>
            <div class="placeholder-ai text-af-accent">
                <i class="bx bx-cube"></i>
            </div>
        </a>
    </div>
</template>
<script>
import MyBCarouselSlide from "../../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide"
import MyCarouselNavigation from "./MyCarouselNavigation"

export default {
    name: 'MyBCarouselImages',
    components: {MyBCarouselSlide, MyCarouselNavigation},
    props: {
        parent_class: {},
        my_asset: {},
        showCaptiomImg: {},
        asset_images_name: {
            type: String,
            required: false,
            default: () => "asset_images"
        },
        required_image_size:{}
    },
    methods:{
        done_action(index, image_info){
            this.$set(this.my_asset[this.asset_images_name], index, image_info);
        }
    }
}
</script>
