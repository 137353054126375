<template>
    <div class="dropdown-menu dropdown-menu-right">
        <b-dropdown-item v-if="hasPermission('browse_' + object_type)"
                         :href="'/' + item_type + '/' + object_type + '/' + item.id + '/view'">
            View
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'add_assets', index, $event)">
            Add Assets
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'add_payment', index, $event)">
            Add Payments
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         @click="performAction(item, 'define_supplier', index, $event)">
            Update Acquisition
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                         variant="danger"
                         @click="performAction(item, 'close_acquisition', index, $event)">
            Close
        </b-dropdown-item>
        <b-dropdown-item v-if="hasPermission('delete_' + object_type)"
                         variant="danger"
                         @click="performAction(item, 'delete_' + object_type, index, $event)">
            Delete
        </b-dropdown-item>
    </div>
</template>
<script>
export default {
    name: 'AcquisitionActionsList',
    props: {
        is_table: false,
        item: {},
        item_type: {},
        object_type: {},
        index: {},
        performAction:{}
    }
}
</script>
