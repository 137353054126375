export const UserLayoutOptions = {
    layout_width: [
        {
            value: 'fluid',
            text: "Fluid"
        },
        {
            value: 'boxed',
            text: "Boxed"
        }
    ],
    sidebar_mode: [
        {
            value: 'dark',
            text: "Dark"
        },
        {
            value: 'light',
            text: "Light"
        }
    ],
    use_icons:[],
    layout_mode:[
        {
            value: 'default',
            text: "Light",
            image: window.ASSET_BASE_URL + '/images/layouts/layout-1.jpg'
        },
        {
            value: 'dark',
            text: "Dark",
            image: window.ASSET_BASE_URL + '/images/layouts/layout-2.jpg'
        },
        // {
        //     value: 'rtl',
        //     text: "RTL"
        // }
    ]

};
