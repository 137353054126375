<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="resetModalValues"
             hide-footer>

        <template #modal-title>
            <div v-html="current_modal_title"></div>
        </template>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFields from "../extras/ChangeCommonFields";
import {getLocationDetailsTextOnly} from "@/views/assetflow/form_data/SpLocationTextOnly";

export default {
    name: "ParkStorageItem",
    props: ['modal_id', 'modal_title', 'item_type'],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                current_warehouse_id: null,
                current_space_id: null,
                location_id: null,
                show_occupied: null,
                space_assigned_at: null,
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Park Storage',
                    required_items:{
                        show_occupied: {
                            key: 'show_occupied',
                            label: 'Show Occupied',
                            type: 'radio-select',
                            options:[
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                }
                            ]
                        },
                        current_warehouse_id: {
                            key: 'current_warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select-label',
                            action_text: 'Change Warehouse',
                            options:[]
                        },
                        current_space_id: {
                            key: 'current_space_id',
                            label: 'Space',
                            type: 'single-select-label',
                            action_text: 'Change Space',
                            options:[]
                        },
                        location_id: {
                            key: 'location_id',
                            label: 'Location',
                            type: 'single-select',
                            options:[]
                        },
                        space_assigned_at: {
                            key: 'space_assigned_at',
                            label: 'Park Date',
                            type: 'datetime'
                        }
                    },
                },
            },
            selected_item: null,
            lists_values: {
            }
        }
    },
    created() {

        this.required_sections.storage_setup.required_items.location_id.custom_html_slot = true;

        this.required_sections.storage_setup.required_items.location_id.custom_label = function (location_info){
            if ( location_info.id === null ){
                return location_info.name;
            }
            return getLocationDetailsTextOnly(location_info);
        };
    },
    computed:{
        show_occupied(){
            return this.addForm.show_occupied;
        },
        current_warehouse_id(){
            return this.addForm.current_warehouse_id;
        },
        current_space_id(){
            return this.addForm.current_space_id;
        },
        current_modal_title(){
            return this.selected_item && this.selected_item.hasOwnProperty('name') ? ( (this.selected_item.hasOwnProperty('storage_wrapper_info') ? ('<i class="' + this.selected_item.storage_wrapper_info.icon +'"></i> ') : '')+this.selected_item.name + '') : this.modal_title;
        }
    },
    watch:{
        show_occupied: {
            deep: true,
            handler: function (newValue, oldVal) {
                // shall filter locations list
                this.getLocationsList(this.show_occupied.id);
            }
        },
        current_warehouse_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue){
                    this.filterSpacesOptions();
                }else{
                    this.resetLocationsList();
                }
            }
        },
        current_space_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue){
                    this.getLocationsList(this.show_occupied.id);
                }else{
                    this.resetLocationsList();
                }
            }
        },
    },
    methods:{
        resetLocationsList() {
            let msg = '';
            if (!this.addForm.current_warehouse_id){
                msg = 'Warehouse';
            }

            if (!this.addForm.current_space_id){
                msg = msg === '' ? 'Space' : 'Warehouse & Space';
            }

            this.required_sections.storage_setup.required_items.location_id.options = [
                {
                    id: null,
                    name: 'Please select a ' + msg
                }
            ];
        },
        setLocationOptions() {
            if (this.lists_values.hasOwnProperty('location_id')) {
                if (this.addForm.show_occupied && this.addForm.show_occupied.id === 1) {
                    this.required_sections.storage_setup.required_items.location_id.options = this.lists_values.location_id;

                    if (this.selected_item && this.selected_item.hasOwnProperty('current_location_id') && this.selected_item.current_location_id){
                        this.addForm.location_id = this.getObjectOf(this.selected_item.current_location_id, this.required_sections.storage_setup.required_items.location_id.options);
                    }else{
                        this.addForm.location_id = null;
                    }
                } else {
                    this.required_sections.storage_setup.required_items.location_id.options = this.lists_values.location_id.filter(location => location.status === 0);
                    this.addForm.location_id = null;
                }


            }
        },
        filterSpacesOptions() {
            if (this.lists_values.hasOwnProperty('current_space_id')) {
                let warehouse_info = this.addForm.current_warehouse_id;
                if (warehouse_info && warehouse_info.id) {
                    this.required_sections.storage_setup.required_items.current_space_id.options = this.lists_values.current_space_id.filter(sp_space => sp_space.sp_warehouse_id === warehouse_info.id);
                    let current_space_id = this.getObjectOf(this.selected_item.current_space_id, this.required_sections.storage_setup.required_items.current_space_id.options);

                    this.addForm.current_space_id = current_space_id;
                    if (!current_space_id){
                        this.required_sections.storage_setup.required_items.current_space_id.type = 'toggle-single-select';
                    }

                } else {
                    this.required_sections.storage_setup.required_items.current_space_id.options = [];
                    this.addForm.current_space_id = null;
                }
            }
        },
        async getLocationsList(show_occupied=0) {
            this.required_sections.storage_setup.required_items.location_id.listLoading = true;
            let listQuery = {
                item_type: this.item_type,
                item_id: this.item_id,
                is_short_list: 1,
                current_warehouse_id: this.addForm.current_warehouse_id ? this.addForm.current_warehouse_id.id : null,
                current_space_id: this.addForm.current_space_id ? this.addForm.current_space_id.id : null,
            }
            if (show_occupied === 0){
                listQuery.status_id = show_occupied;
            }
            const locationResource = new Resource('api/assetflow/sp_locations');
            const { data } = await locationResource.list(listQuery);

            this.lists_values.location_id = data.data.items;
            this.setLocationOptions();
            this.required_sections.storage_setup.required_items.location_id.listLoading = false;

        },
        async resetModalValues() {
            this.required_sections.storage_setup.required_items.current_warehouse_id.type = 'single-select-label';
            this.required_sections.storage_setup.required_items.current_space_id.type = 'single-select-label';
            await this.getFieldsList();
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                if (this.selected_item.hasOwnProperty('current_warehouse_info')){
                    this.addForm.current_warehouse_id = this.selected_item.current_warehouse_info;
                }
                if (this.selected_item.hasOwnProperty('current_space_info')){
                    this.addForm.current_space_id = this.selected_item.current_space_info;
                }

                if (this.selected_item.current_location_id){
                    this.addForm.show_occupied = this.required_sections.storage_setup.required_items.show_occupied.options[0];
                }

                let space_assigned_at = this.selected_item.space_assigned_at ? this.selected_item.space_assigned_at : Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm');
                this.$set(this.addForm, 'space_assigned_at', space_assigned_at);

            }
        },
        setSelectedItem(item){
            this.addForm.show_occupied = this.required_sections.storage_setup.required_items.show_occupied.options[1];
            this.$set(this.addForm, 'space_assigned_at', Vue.options.filters.moment(new Date(), 'DD-MM-YYYY HH:mm'));
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        async getFieldsList(){
            this.required_sections.storage_setup.required_items.current_warehouse_id.listLoading = true;
            this.required_sections.storage_setup.required_items.current_space_id.listLoading = true;

            let required_select_lists =  {
                current_warehouse_id: 'sp_warehouses_list',
                current_space_id: 'sp_spaces_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_space_modal',
                    object_type: 'sp_spaces',
                    action: 'new_sp_space',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                let current_warehouse_options = this.lists_values.current_warehouse_id;
                this.required_sections.storage_setup.required_items.current_warehouse_id.options = current_warehouse_options;
                this.addForm.current_warehouse_id = this.getObjectOf(this.selected_item.current_warehouse_id, current_warehouse_options);
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            }).finally(() => {
                this.required_sections.storage_setup.required_items.current_warehouse_id.listLoading = false;
                this.required_sections.storage_setup.required_items.current_space_id.listLoading = false;
            });
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                current_warehouse_id: this.addForm.current_warehouse_id ? this.addForm.current_warehouse_id.id : null,
                current_space_id: this.addForm.current_space_id ? this.addForm.current_space_id.id : null,
                location_id: this.addForm.location_id ? this.addForm.location_id.id : null,
                space_assigned_at: this.addForm.space_assigned_at,
                action: 'assign_location'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-park-storage', this.modal_id, new_item);
        }
    }
}
</script>


