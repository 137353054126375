<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item && selected_item.hasOwnProperty('name') ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-' + item_type + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sp_storageResource = new Resource('api/assetflow/sp_storages');
import ChangeCommonFields from "../extras/ChangeCommonFields";
import AFButton from "@/components/AFButton";

export default {
    name: "AddNewSpStorage",
    props: ['modal_title', 'item_type', 'sp_storages_options'],
    components: {AFButton, ChangeCommonFields},
    data(){
        return {
            addForm:{
                has_errors: false,
                storage_wrapper_id: null,
                storage_wrapper_icon: '',
                name: '',
                current_warehouse_id: null,
                current_space_id: null,
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Storage Setup',
                    required_items:{
                        storage_wrapper_id: {
                            key: 'storage_wrapper_id',
                            label: 'Storage Wrapper',
                            type: 'single-select',
                            options:[]
                        },
                        storage_wrapper_icon: {
                            key: 'storage_wrapper_icon',
                            label: 'Icon',
                            type: 'hidden_text',
                            is_readonly: true
                        },
                        name: {
                            key: 'name',
                            label: 'Name',
                            type: 'hidden_text'
                        },
                        current_warehouse_id: {
                            key: 'current_warehouse_id',
                            label: 'Warehouse',
                            type: 'single-select',
                            options:[],
                            is_required: true
                        },
                        current_space_id: {
                            key: 'current_space_id',
                            label: 'Space',
                            type: 'single-select',
                            options:[],
                            is_required: true
                        },

                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    created() {
    },
    computed:{

        current_warehouse_id() {
            return this.addForm.current_warehouse_id;
        },
        current_space_id() {
            return this.addForm.current_space_id;
        },
        storage_wrapper_id() {
            return this.addForm.storage_wrapper_id;
        },
    },
    watch:{
        current_warehouse_id(value) {
            // shall get spaces of this warehouse id
            this.getSPSpaces(value);
        },
        current_space_id(value) {
            // do nothing?
        },
        storage_wrapper_id(value) {
            // storage_wrapper_id
            if (value){
                this.addForm.storage_wrapper_icon = this.addForm.storage_wrapper_id.icon;
                if (this.addForm.storage_wrapper_id.naming === 0){
                    this.addForm.name = '';
                    this.required_sections.storage_setup.required_items.name.type = 'text';
                }else{
                    this.addForm.name = null;
                    this.required_sections.storage_setup.required_items.name.type = 'hidden_text';
                }

                this.required_sections.storage_setup.required_items.storage_wrapper_icon.type = 'icon';
            }else{
                this.required_sections.storage_setup.required_items.storage_wrapper_icon.type = 'hidden_text';
            }
        },
    },
    methods:{
        async getSPSpaces(current_warehouse_id){
            if (!current_warehouse_id || !current_warehouse_id.hasOwnProperty('id')) { return; }
            const getSPSpacesResource = new Resource('api/assetflow/sp_warehouses/getSPSpaces');

            let queryList = {
                warehouse_id: current_warehouse_id.id
            };
            const { data } = await getSPSpacesResource.list(queryList);

            this.required_sections.storage_setup.required_items.current_space_id.options = data.data.items;
            this.addForm.current_space_id = null;
            if (this.selected_item.current_space_id){
                this.addForm.current_space_id = this.getObjectOf(this.selected_item.current_space_id, this.required_sections.storage_setup.required_items.current_space_id.options);
            }
        },
        async getFieldsList(){
            this.addForm.has_errors = false;
            this.lists_values = {};
            this.isLoadingItem = true;
            this.is_saving_enabled = true;

            let required_select_lists =  {
                current_warehouse_id: 'sp_warehouses_list',
                storage_wrapper_id: 'sp_storage_wrappers_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'sp_storage_modal',
                    object_type: 'sp_storages',
                    action: 'new_sp_storage',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }
                this.required_sections.storage_setup.required_items.current_warehouse_id.options = this.lists_values.current_warehouse_id;
                this.required_sections.storage_setup.required_items.storage_wrapper_id.options = this.lists_values.storage_wrapper_id;

                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.setSelectedItem({});
            this.required_sections.storage_setup.required_items.name.type = 'hidden_text';
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.required_sections.storage_setup.required_items.current_space_id.options = [];
                this.addForm.current_space_id = null;
                this.addForm.current_warehouse_id = this.getObjectOf(this.selected_item.current_warehouse_id, this.required_sections.storage_setup.required_items.current_warehouse_id.options);

                this.addForm.storage_wrapper_id = this.getObjectOf(this.selected_item.storage_wrapper_id, this.required_sections.storage_setup.required_items.storage_wrapper_id.options);
                this.addForm.storage_wrapper_icon = this.selected_item.name;
                this.addForm.name = this.selected_item.name;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;
            this.is_saving_enabled = false;

            if (!this.current_space_id || !this.current_warehouse_id){
                this.addForm.has_errors = true;
                return;
            }
            this.addForm.has_errors = false;

            let params = {
                current_warehouse_id: this.addForm.current_warehouse_id ? this.addForm.current_warehouse_id.id : null,
                current_space_id: this.addForm.current_space_id ? this.addForm.current_space_id.id : null,
                storage_wrapper_id: this.addForm.storage_wrapper_id ? this.addForm.storage_wrapper_id.id : null,
                storage_wrapper_icon: this.addForm.storage_wrapper_icon,
                name: this.addForm.name
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sp_storageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding');
        }
    }
}
</script>


