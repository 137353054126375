<template>
    <div class="row">
        <div class="col-12">

            <SettingsFeaturesOverview/>

            <po-lines
                table_class="settings_features_list"
                :items="itemsData"
                :keys_list="keys_lists[item_type]"
            >
            </po-lines>
        </div>
    </div>
</template>
<script>
import PoLines from "../../../items/widgets/detailsViews/PoLines"
import SettingsShared from "@/mixins/SettingsShared.mixin";
import SettingsFeaturesOverview from "./SettingsFeaturesOverview";

export default {
    name: 'SettingsFeaturesList',
    components: {SettingsFeaturesOverview, PoLines},
    mixins: [SettingsShared],
    props:['item_type'],
    data(){
        return {
            itemsData: [],
            keys_lists: {
                features: {
                    id: {
                        key: 'id',
                        label: "Feature ID",
                        class: "col-lg-1 f_id",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item.setting_item.id;
                        }
                    },
                    name: {
                        key: 'name',
                        label: "Feature Name",
                        class: "col-lg f_name",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item.setting_item.name;
                        }
                    },
                    inheritance_type: {
                        key: 'inheritance_type',
                        label: "Feature Control",
                        class: "col-lg-1 f_inheritance_type",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            let inheritance_type = '';
                            switch (item.setting_item.inheritance_type){
                                case 0:
                                    inheritance_type = 'Local';
                                    break
                                case 1:
                                    inheritance_type = 'Optional';
                                    break
                                case 2:
                                    inheritance_type = 'Inherited';
                                    break
                            }
                            return inheritance_type;
                        }
                    },
                    default_value: {
                        key: 'default_value',
                        label: "Default",
                        class: "col-lg-1 f_default_value",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                        }
                    },
                    current_source: {
                        key: 'current_source',
                        label: "Current Source",
                        class: "col-lg-1 f_current_source",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                        }
                    },
                    friendly_value: {
                        key: 'friendly_value',
                        label: "Friendly Value",
                        class: "col-lg-1 f_friendly_value",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {

                        }
                    },
                    raw_value: {
                        key: 'raw_value',
                        label: "RAW Value",
                        class: "col-lg-1 f_raw_value",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item.value;
                        }
                    },
                },
                settings: {
                    id: {
                        key: 'id',
                        label: "Setting ID",
                        class: "col-lg-1 f_id",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item.setting_item.id;
                        }
                    },
                    name: {
                        key: 'name',
                        label: "Setting Name",
                        class: "col-lg f_name",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item.setting_item.name;
                        }
                    },
                    parent_feature_info: {
                        key: 'parent_feature_info',
                        label: "Parent Feature",
                        class: "col-lg f_parent_feature_info",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            let html = '';
                            if (item.hasOwnProperty('feature_info')){
                                let feature_info = item.feature_info.setting_item;
                                let status = item.feature_info.value === '1' ? 'bx-check-double' : 'bx-x';
                                html += '<i class="font-size-16 bx '+status+' settings-parent-feature-status"></i> ' + feature_info.name + ' (' + feature_info.id +')';
                            }
                            return html;
                        }
                    },
                    inheritance_type: {
                        key: 'inheritance_type',
                        label: "Setting Control",
                        class: "col-lg-1 f_inheritance_type",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            let inheritance_type = '';
                            switch (item.setting_item.inheritance_type){
                                case 0:
                                    inheritance_type = 'Local';
                                    break
                                case 1:
                                    inheritance_type = 'Optional';
                                    break
                                case 2:
                                    inheritance_type = 'Inherited';
                                    break
                            }
                            return inheritance_type;
                        }
                    },
                    default_value: {
                        key: 'default_value',
                        label: "Default",
                        class: "col-lg-1 f_default_value",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {}
                    },
                    current_source: {
                        key: 'current_source',
                        label: "Current Source",
                        class: "col-lg-1 f_current_source",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                        }
                    },
                    friendly_value: {
                        key: 'friendly_value',
                        label: "Friendly Value",
                        class: "col-lg-1 f_friendly_value",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {}
                    },
                    raw_value: {
                        key: 'raw_value',
                        label: "RAW Value",
                        class: "col-lg-1 f_raw_value",
                        show_on_mobile: true,
                        case: 'custom_html',
                        value_func: function (item, key) {
                            return item.value;
                        }
                    },
                },
            },
        }
    },
    created() {
        if (this.item_type === 'features'){
            this.itemsData = this.current_customer.hasOwnProperty('all_features') ? this.current_customer.all_features : [];
        }else if (this.item_type === 'settings'){
            this.itemsData = this.current_customer.hasOwnProperty('all_settings') ? this.current_customer.all_settings : [];
            // shall use features to check availability!
            // let features_dict = this.current_customer.hasOwnProperty('features_dict') ? this.current_customer.features_dict : [];
        }


        let get_asset_types = this.get_asset_types;
        let enabled_or_values = function (item, key) {
            let setting_item_key = item.setting_item.key;
            let required_value = item.value;
            if (key === 'default_value'){
                required_value = item.setting_item.default_value;
                if (['default_assigned_users', 'default_assigned_departments', 'default_assigned_locations', 'default_assigned_folders'].includes(setting_item_key)){
                    return 'None';
                }
            }else{
                if (['default_assigned_users', 'default_assigned_departments', 'default_assigned_locations', 'default_assigned_folders'].includes(setting_item_key)){
                    let related_object = item.hasOwnProperty('related_object') ? item.related_object : null;
                    if (related_object){
                        if (related_object.hasOwnProperty('item_name')){
                            return '<a href="./'+related_object.item_name + '/' + related_object.id +'/view">' + related_object.name + '</a>';
                        }else{
                            return '<a href="#">' + related_object.name + '</a>';
                        }

                    }
                    return 'None';
                }
            }

            if (setting_item_key === 'asset_types'){
                return get_asset_types(required_value);
            }

            if (['assign_users_by_default', 'assign_departments_by_default', 'assign_locations_by_default', 'assign_folders_by_default'].includes(setting_item_key)){
                return required_value === '1' ? 'True' : 'False';
            }

            return required_value === '1' ? 'Enabled' : 'Disabled';
        };

        this.keys_lists[this.item_type].default_value.value_func = enabled_or_values;
        this.keys_lists[this.item_type].friendly_value.value_func = enabled_or_values;

        let customer_inheritance = this.current_customer.hasOwnProperty('customer_inheritance') ? this.current_customer.customer_inheritance : 'Local';
        let current_source_func = function (item, key) {
            if (customer_inheritance === 1){
                return 'Parent';
            }else {
                if (item.value === item.setting_item.default_value){
                    return 'Default';
                }
                return 'Customer';
            }

        }
        this.keys_lists[this.item_type].current_source.value_func = current_source_func;

    }
}
</script>
