<template>
    <div class="ascending-data-display" v-if="item_info">

        <div class="ascendind-data--single col-sm-12 col-md-6 col-lg-12 col-xl-6"
             v-for="(list_item, key) in list_items"
             :key="key">
            <div class="ascending-data d-flex align-item-center justify-content-between py-3 px-2">
                <div class="asc-data--left">
                    {{ list_item.label }}
                </div>
                <div class="desc-data--right" v-if="list_item.key === 'colours'">
                    <div
                        v-if="item_info.colours_info && item_info.colours_info.length > 0"
                        class="st-color-block asset-colours-block">
                        <ul>
                            <li v-for="(colour, key) in item_info.colours_info" :key="key">
                                <div class="st-color-box">
                                    <label>
                                        <div class="cm-color-round"
                                             :style="'background-color:' + colour.hex"
                                             v-b-tooltip.hover.bottom
                                             :title="colour.name | titleize">
                                            <img :src="getAssetBaseUrl + '/images/new_images/color_check-ic.png'" alt="Color Check"/>
                                        </div>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-else>-</div>
                </div>
                <div class="desc-data--right" v-else>
                    {{ getValueFor(list_item.key) }}
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'IdentificationBlock',
    props: {
        item_info: {}
    },
    data(){
        return {
            passport_item: null,
            list_items:{
                asset_name: {
                    key: 'asset_name',
                    label: 'Asset Name'
                },
                passport_status: {
                    key: 'passport_status',
                    label: 'Passport Status'
                },
                passport_no: {
                    key: 'passport_no',
                    label: 'Passport No.'
                },
                last_known_condition: {
                    key: 'last_known_condition',
                    label: 'Last Known Condition'
                },
                passport_type: {
                    key: 'passport_type',
                    label: 'Passport Type'
                },
                colours: {
                    key: 'colours',
                    label: 'Colours'
                },
                serial: {
                    key: 'serial',
                    label: 'Serial'
                },
                category: {
                    key: 'category',
                    label: 'Category'
                },
                model: {
                    key: 'model',
                    label: 'Model'
                },
                category_template: {
                    key: 'category_template',
                    label: 'Category Template'
                },
            }
        }
    },
    created() {
        this.passport_item = this.item_info.hasOwnProperty('cAFID_info') ? this.item_info.cAFID_info : null;
    },
    methods:{
        getValueFor(key){
            if (!this.passport_item) { return '';}

            switch (key){
                case 'asset_name':
                    return this.passport_item.universal_asset_name;
                case 'passport_status':
                    return this.passport_item.passport_status === 0 ? 'Assigned' : '-';
                case 'passport_no':
                    return 'PASS-' +  this.item_info.cAFID;
                case 'last_known_condition':
                    let conditions_list = [
                        'New',
                        'Excellent',
                        'Good',
                        'Attention Required',
                    ];
                    return this.passport_item.condition > 0 ? conditions_list[this.passport_item.condition - 1] : '-';
                case 'passport_type':
                    return this.passport_item.quantity > 1 ? 'Shared' : 'Unique';
                case 'serial':
                    return this.passport_item.serial_number;
                case 'category':
                    return this.passport_item.parent_category ? this.passport_item.parent_category.name : '-';
                case 'model':
                    return this.passport_item.model_number;
                case 'category_template':
                    return this.passport_item.asset_template ? this.passport_item.asset_template.name : '-'
                default:
                    return this.item_info.hasOwnProperty(key) ? this.item_info[key] : '-';
            }
        }
    }
}
</script>
