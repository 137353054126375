<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <customer-settings-section :add-form.sync="addForm" :sidebar_navigation="sidebar_navigation"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CustomerSettingsSection from "./CustomerSettingsSection";

export default {
    name: "assets_tracking",
    props:['selected_setting'],
    components: {CustomerSettingsSection},
    data(){
        return {
            addForm:{
                default_assigned_departments: null,
                assign_departments_by_default: false,
                default_assigned_users: null,
                assign_users_by_default: false,
                default_assigned_locations: null,
                assign_locations_by_default: false,
                asset_types: [
                    '1',
                    '0',
                    '0',
                    '0'
                ],
                assets_asset_user: 1
            },
            sidebar_navigation: {
                asset_types: {
                    label: 'Asset Types',
                    settings: {
                        asset_types: {
                            id: 'asset_types',
                            label: 'Asset Types',
                            functionality: 'settings',
                            type: 'radio',
                            status: true,
                            options: [
                                {
                                    id: '0',
                                    name: 'Asset'
                                },
                                {
                                    id: '1',
                                    name: 'Consumable'
                                },
                                {
                                    id: '2',
                                    name: 'Digital'
                                },
                                {
                                    id: '3',
                                    name: 'Capital Asset'
                                },
                            ]
                        }
                    }
                },
                assets_assignments: {
                    label: 'Assignments',
                    settings: {
                        assets_asset_user: {
                            id: 'assets_asset_user',
                            label: 'Users',
                            functionality: 'settings',
                            type: 'checkbox',
                            with_assigning:true,
                            status: false,
                            options: [
                                {
                                    id: 0,
                                    name: 'User 1'
                                },
                                {
                                    id: 1,
                                    name: 'User 2'
                                },
                            ]
                        },
                        assets_asset_department: {
                            id: 'assets_asset_department',
                            label: 'Departments',
                            functionality: 'settings',
                            type: 'checkbox',
                            with_assigning:true,
                            status: true,
                            options: [
                                {
                                    id: 0,
                                    name: 'Department 1'
                                },
                                {
                                    id: 1,
                                    name: 'Department 1'
                                },
                            ]
                        },
                        assets_asset_location: {
                            id: 'assets_asset_location',
                            label: 'Locations',
                            functionality: 'settings',
                            type: 'checkbox',
                            with_assigning:true,
                            status: true,
                            options: [
                                {
                                    id: 0,
                                    name: 'Location 1'
                                },
                                {
                                    id: 1,
                                    name: 'Location 2'
                                },
                            ]
                        },
                    }
                },
            }
        }
    },
    created() {
        let required_meta_keys = [
            'asset_types',
            'assets_asset_department',
            'assets_asset_location',

            'default_assigned_departments',
            'assign_departments_by_default',
            'default_assigned_users',
            'assign_users_by_default',
            'default_assigned_locations',
            'assign_locations_by_default',
        ];
        for (const key in required_meta_keys) {
            let meta_key = required_meta_keys[key];
            if (this.settings_dict.hasOwnProperty(meta_key)){
                this.addForm[meta_key] = this.settings_dict[meta_key];
            }else if (meta_key !== 'asset_types'){
                this.addForm[meta_key] = 0;
            }
        }
        this.addForm = Object.assign({}, this.addForm);
    }
}
</script>

