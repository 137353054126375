<template>
    <div class="intelligence-template custom-change-details">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="change_overview_tab" role="tabpanel" :class="isActiveTab('change_overview_tab')">
                <ChangeOverviewTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="change_costume_tab" role="tabpanel" :class="isActiveTab('change_costume_tab')">
                <ChangeSceneCostumeTab :change_info="item_info" :item_type="item_info.current_page_type" :item_info="item_info" :no_description_block="true" @after-item-moving="moveItemTo"/>
            </div>
            <div class="tab-pane" id="change_closet_tab" role="tabpanel" :class="isActiveTab('change_closet_tab')">
                <ChangeSceneClosetTab :item_info="item_info" :item_type="item_info.current_page_type" @after-item-moving="moveItemTo"/>
            </div>
            <div class="tab-pane" id="change_tasks_tab" role="tabpanel" :class="isActiveTab('change_tasks_tab')">
                <ChangeTasksTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="change_media_tab" role="tabpanel" :class="isActiveTab('change_media_tab')">
                <MediaTab :item_info="item_info" object_type="changes"/>
            </div>
            <div class="tab-pane" id="change_files_tab" role="tabpanel" :class="isActiveTab('change_files_tab')">
                <ObjectFilesTab :item_info="item_info" object_type="files" page_unique_name="change_files" required_files="files" :template_category_id="1"/>
            </div>
            <div class="tab-pane" id="change_all_files_tab" role="tabpanel" :class="isActiveTab('change_all_files_tab')">
                <ObjectFilesTab :item_info="item_info" object_type="files" page_unique_name="change_all_files" required_files="all_files" :template_category_id="1"/>
            </div>
            <div class="tab-pane" id="change_activity_tab" role="tabpanel" :class="isActiveTab('change_activity_tab')">
                <ChangeActivityTab :item_info="item_info"/>
            </div>
        </div>


    </div>
</template>
<script>

import ChangeOverviewTab from "./ChangeOverviewTab";
import ChangeTasksTab from "./ChangeTasksTab";
import ChangeActivityTab from "./ChangeActivityTab";
import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";
import ChangeSceneCostumeTab from "./ChangeSceneRel/ChangeSceneCostumeTab";
import ChangeSceneClosetTab from "./ChangeSceneRel/ChangeSceneClosetTab";
import ObjectFilesTab from "./ObjectFilesTab";

export default {
    name: 'CustomChangeDetails',
    components: {
        ObjectFilesTab,
        ChangeSceneClosetTab,
        ChangeSceneCostumeTab,
        ChangeOverviewTab,
        ChangeTasksTab,
        MediaTab,
        ChangeActivityTab
    },
    props: ['item_info'],
    data(){
        return {
            active_tab: 'change_overview_tab',
            tabs: {
                'change_overview_tab': {
                    'id': "change_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "change_overview_tab",
                    'isActive': false
                },
                'change_activity_tab': {
                    'id': "change_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "change_activity_tab",
                    'isActive': false
                },
                'change_costume_tab': {
                    'id': "change_costume_tab",
                    'name': "Costume",
                    'icon': "user",
                    'type': "change_costume_tab",
                    'isActive': false
                },
                'change_closet_tab': {
                    'id': "change_closet_tab",
                    'name': "Closet",
                    'icon': "user",
                    'type': "change_closet_tab",
                    'isActive': false
                },
                'change_media_tab': {
                    'id': "change_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "change_media_tab",
                    'isActive': false
                },
                'change_files_tab': {
                    'id': "change_files_tab",
                    'name': "Files",
                    'icon': "user",
                    'type': "change_files_tab",
                    'isActive': false
                },
                'change_all_files_tab': {
                    'id': "change_all_files_tab",
                    'name': "All Files",
                    'icon': "user",
                    'type': "change_all_files_tab",
                    'isActive': false
                },
                // 'change_tasks_tab': {
                //     'id': "change_tasks_tab",
                //     'name': "Tasks",
                //     'icon': "user",
                //     'type': "change_tasks_tab",
                //     'isActive': false
                // },
            },
        }
    },
    created() {
        let changes_active_tab = localStorage.getItem('changes_active_tab');

        if (this.item_info.hasOwnProperty('costume_items') && this.item_info.costume_items && this.item_info.costume_items.length > 0){
            this.tabs.change_costume_tab.name = 'Costume (' + this.item_info.costume_items.length + ')'
        }

        if (this.item_info.hasOwnProperty('closet_items') && this.item_info.closet_items && this.item_info.closet_items.length > 0){
            this.tabs.change_closet_tab.name = 'Closet (' + this.item_info.closet_items.length + ')'
        }

        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'activity':
                    this.active_tab = 'change_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'change_media_tab';
                    break;
                case 'measurements':
                    this.active_tab = 'change_measurements_tab';
                    break;
                case 'scenes':
                    this.active_tab = 'change_scenes_tab';
                    break;
                case 'assets':
                    this.active_tab = 'change_assets_tab';
                    break;
                case 'changes':
                    this.active_tab = 'change_changes_tab';
                    break;
                case 'files':
                    this.active_tab = 'change_files_tab';
                    break;
                case 'all_files':
                    this.active_tab = 'change_all_files_tab';
                    break;
            }
        }else if (changes_active_tab && changes_active_tab !== ''){
            this.active_tab = changes_active_tab;
        }

    },
    methods:{
        moveItemTo(new_item, move_to_item_type, index){
            if (this.item_info.hasOwnProperty(move_to_item_type) && this.item_info[move_to_item_type]){
                this.item_info[move_to_item_type].push(new_item);

                this.item_info[move_to_item_type === 'closet_items' ? 'costume_items' : 'closet_items'].splice(index, 1);

                this.tabs.change_closet_tab.name = 'Closet (' + this.item_info.closet_items.length + ')';
                this.tabs.change_costume_tab.name = 'Costume (' + this.item_info.costume_items.length + ')';
            }
        },
        tabClicked(tab_id){
            localStorage.setItem('changes_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
