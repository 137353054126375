
import AddPaymentModal from "@/views/assetflow/customers/acquisitions/Modals/AddPaymentModal";
import AddAssetsModal from "@/views/assetflow/customers/acquisitions/Modals/AddAssetsModal";
import DefineSupplierModal from "@/views/assetflow/customers/acquisitions/Modals/DefineSupplierModal";
import CloseAcquisitionModal from "@/views/assetflow/customers/acquisitions/Modals/CloseAcquisitionModal";
export default {
    components: {
        AddPaymentModal,
        AddAssetsModal,
        DefineSupplierModal,
        CloseAcquisitionModal
    },
    props:{
        unique_id: {
            default: '',
            type: String
        }
    },
    data(){
        return {
            add_payment_modal_id: 'add_payment',
            add_payment_modal_title: 'Add Payment',
            add_assets_modal_id: 'add_assets',
            add_assets_modal_title: 'Add Assets',
            define_supplier_modal_id: 'define_supplier',
            define_supplier_modal_title: 'Update Acquisition',
            close_acquisition_modal_id: 'close_acquisition',
            close_acquisition_modal_title: 'Close Acquisition',
            select_keys: {},
            modal_actions:{
                'add_payment': 'Added Payment',
                'add_assets': 'Added Assets',
                'define_supplier': 'Defined Supplier Details',
                'close_acquisition': 'Closed Acquisition',
            }
        }
    },
    created() {
        this.select_keys[this.add_payment_modal_id] = [
            'payment_type',
            'payment_purchase_order_id',
            'payment_nominal_code_id'
        ];
        this.select_keys[this.add_assets_modal_id] = [
            'asset_state',
            'create_assets_asset_quantities',
            'create_assets_asset_distribution',
            'create_assets_usage',
            'create_assets_asset_usage',
            'create_assets_is_hero',
            'create_assets_is_asset',
            'create_assets_folder_id',
            'create_assets_category_id',
            'create_assets_tag_ids',
            'create_assets_department_id',
            'create_assets_contact_id',
            'create_assets_address_id',
            'create_assets_location_id',
            'create_assets_final_disposition_planning',
            'create_assets_confirm_fdp'
        ];
        this.select_keys[this.define_supplier_modal_id] = [
            'buyer_id',
            'buyer_ledger_id',
            'payment_ledger_id',
            'supplier_id',
            'purchase_order_id',
            'item_return_policy',
            'character_id',
            'ledger_id'
        ];
        this.select_keys[this.close_acquisition_modal_id] = [
            'confirmation_status'
        ];
    },
    methods: {

        onFormSubmit(modal_action, item_info, addForm, object_type, entity_type, received_modal_id){
            let item_id = item_info.id;

            let item_details_values = {};
            if (addForm.hasOwnProperty('item_details') && Object.keys(addForm.item_details).length > 0){
                item_details_values = Object.assign({}, addForm.item_details);
                let single_select_keys = this.select_keys[modal_action];
                for (const selectKeysKey in single_select_keys) {
                    let select_key = single_select_keys[selectKeysKey];
                    if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){
                        item_details_values[select_key] = item_details_values[select_key].id;
                    }
                }
                if (modal_action === this.add_assets_modal_id){
                    let multi_select_keys = ['create_assets_tag_ids'];
                    for (const selectKeysKey in multi_select_keys) {
                        let select_key = multi_select_keys[selectKeysKey];
                        if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){
                            item_details_values[select_key] = this.getIDs(item_details_values[select_key], true);
                        }
                    }
                }
            }

            let params = {
                item_id: item_id,
                item_details: item_details_values,
                object_type: object_type,
                modal_action: modal_action
            };

            if (modal_action === this.define_supplier_modal_id && addForm.hasOwnProperty('filled_asset_images') && addForm.filled_asset_images){

                let all_files = this.checkAndGetFiles(addForm.filled_asset_images);

                if (!all_files){
                    return;
                }
                params.asset_images = all_files;
            }


            axios({
                method: (item_id !== '' ? 'put' : 'post'),
                url: '/api/assetflow/' + object_type + (item_id !== '' ? ('/' + item_id) : ''),
                baseURL: '/',
                data: params,
            }).then(response => {
                this.isSendingRequest = false;
                this.$bvModal.hide(received_modal_id);

                if (response.data.data.status === true) {
                    let added_item = response.data.data.items;

                    let message = this.modal_actions[modal_action] + ' Successfully';
                    if (object_type === 'acquisitions'){
                        message += ', ' + added_item.uid + ' {' + added_item.name + '}';
                    }
                    window.flash(message, 'success');
                    location.reload();
                } else {
                    this.presentToast("Failed!",
                        'Something went wrong.',
                        'danger',
                        3000,
                        true,
                        true);

                }
            }).catch(error => {
                console.log(error);
                this.isSendingRequest = false;
                this.$bvModal.hide(received_modal_id);

                this.presentToast("Failed!",
                    'Something went wrong.',
                    'danger',
                    3000,
                    true,
                    true);

            });

        },
        acquisitionPerformAction(item, action_name, index, event) {

            // this.$root.$emit("bv::show::modal", "your-modal-id");
            if (action_name === 'delete_acquisitions'){
                this.deleteItemByID(item.id, item.name, this.object_type, event)
                    .then((response) => {
                        let with_reload = true;
                        if (response.data.data.status){
                            if (this.hasOwnProperty('acquisitionsData')){
                                this.acquisitionsData.splice(index, 1);
                                with_reload = false;
                            }
                        }
                        if (with_reload){
                            window.location = encodeURI("/" + this.item_type + "/" + this.object_type);
                        }
                    });
            }else{
                let action_ref = this[action_name + '_modal_id'] + this.unique_id;
                this.$refs[action_ref].set_item(item);
                console.log("action_ref");
                console.log(action_ref);

                this.$bvModal.show(action_ref);
            }
        }
    }
}
