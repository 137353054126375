<template>
    <div class="btn-group bl-auto" :class="parent_custom_classes">
        <button type="button" class="btn btn-none dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <b-dropdown-item v-if="permissions_list['edit_' + object_type] && ![3,4].includes(item.status)"
                             @click="performAction('edit')">
                Edit
            </b-dropdown-item>

            <slot v-if="custom_slot_names" v-for="custom_slot_name in custom_slot_names" :name="custom_slot_name"></slot>


        </div>
    </div>
</template>
<script>
import ExportAdditionalActions from "../orders/ExportAdditionalActions";
export default {
    name: 'OrderLineActions',
    components: {ExportAdditionalActions},
    props: {
        index: {},
        item: {},
        item_type: {},
        object_type: {},
        permissions_list: {},
        deletion_condition: null,
        custom_slot_names: null,
        parent_custom_classes: null,
    },
    methods:{
        performAction(action){
            this.$emit('on-perform-action', this.item, action, this.index);
        }
    }
}
</script>
