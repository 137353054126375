<template>
    <div>
        <h4> {{ menu_title !== '' ? menu_title : 'Bulk Updates'}}</h4>
        <div class="row customer-list">
            <div class="col-lg-4 col-xl-4 text-center" @click="revealExtraContent('add_tags')">
                <div class="card selected_card" :class="{active : required_action === 'add_tags'}">
                    <div class="card-body">
                        <h5 class="card-title">Add Tags</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-4 text-center" @click="revealExtraContent('remove_tags')">
                <div class="card selected_card" :class="{active : required_action === 'remove_tags'}">
                    <div class="card-body">
                        <h5 class="card-title">Remove Tags</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row customer-list" v-show="required_action">
            <div class="col-lg-12">
                <div class="card" v-show="['add_tags', 'remove_tags'].includes(required_action)">
                    <div class="card-body">
                        <TagsManager
                            class="mt-2"
                            ref="tags-manager1"
                            :product="my_product"
                            :all_tags_list="all_tags_list"
                            @on-add-tag="myAddTag"
                            @on-remove-tag="myRemoveTag"
                        ></TagsManager>

                        <b-button
                            v-if="my_product.tag_ids.length > 0"
                            class="af-btn-accent af-accent"
                            @click="performAction(required_action)">{{ required_action | titleize }}</b-button>
                        <b-button v-else
                              variant="outline-secondary"
                              disabled="disabled">{{ required_action | titleize }}</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Resource from '@/api/resource';
const tagsResource = new Resource('api/assetflow/tags');

import TagsManager from "./TagsManager";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";

export default {
    name: 'BulkUpdatesTab',
    components: {TagsManager},
    props: ['menu_title', 'applyBulkAction'],
    mixins: [TagsManagerMixin],
    data(){
        return {
            required_action: null,
            my_product: {
                tag_ids: []
            },
        }
    },
    methods: {
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async myAddTag(inputAttrs) {
            if (inputAttrs.value){
                const tags_info_index = this.all_tags_list.map(function (e) {
                    return e.name;
                }).indexOf(inputAttrs.value);

                if (tags_info_index !== -1){
                    this.presentToast("Error!",
                        'Tag name already in use',
                        'danger',
                        3000,
                        true,
                        true);
                    return;
                }
            }
            if (inputAttrs.value === ''){
                this.presentToast("Error!",
                    'Please add a tag name to proceed.',
                    'danger',
                    3000,
                    true,
                    true);
                return;
            }

            this.isAddingTag = true;
            let params = {
                name: inputAttrs.value,
                description: inputAttrs.value
            };
            const { data } = await tagsResource.store(params);

            let new_tag = data.data.items;

            if (new_tag){
                if (!this.my_product.tag_ids){
                    this.my_product.tag_ids = [];
                }
                this.my_product.tag_ids.push(new_tag.id+"");
                this.all_tags_list.push(new_tag);
            }else{
                this.presentToast((data.data.status ? "Success!" : "Error!"),
                    data.data.msg,
                    (data.data.status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
            }
            this.isAddingTag = false;
        },
        myRemoveTag(tag_id) {
            const tagIndex = this.all_tags_list.map(function (e) {
                return e.id;
            }).indexOf(tag_id);


            let tag = this.all_tags_list[tagIndex];

            if (!tag.id){
                tag = JSON.parse(tag);
            }

            let older_tags = this.my_product.tag_ids;
            if (older_tags && older_tags.includes(tag_id+"")){
                // shall remove it
                tag.is_selected = false;
                const index = older_tags.indexOf(tag.id+"");

                if (index !== -1){
                    older_tags.splice(index, 1);
                }
            }else{
                //shall add it
                tag.is_selected = true;
                if (!older_tags){
                    older_tags = [];
                }
                older_tags.push(tag_id+"");
            }

            this.my_product.tag_ids = older_tags;
            this.all_tags_list[tagIndex] = tag;
        },
        revealExtraContent(action_name){
            if (this.required_action === action_name){
                this.required_action = null;
                return;
            }
            switch (action_name) {
                case 'add_tags':
                case 'remove_tags':
                    this.my_product.tag_ids = [];
                    this.$refs['tags-manager1'].showTagsManager(true);
                    this.required_action = action_name;
                    break;
            }
        },
        performAction(action_name){
            switch (action_name) {
                case 'add_tags':
                case 'remove_tags':
                    this.$emit('on-bulk-action', action_name, this.my_product);
                    break;
            }
        },
    },
}
</script>
