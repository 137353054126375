const FirstPath = window.location.pathname.split('/')[1];
export const AssetPassportKeysList = {
    passport_number: {
        key: 'passport_number',
        label: "Passport Number",
        class: "col-lg-1 ap_passport_number",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/asset_passports/' + item.id + '/view">' + item.uid + '</a>';
        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer \\ Production",
        class: "col-lg-1 ap_customer_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_info = item.customer_info;
            if (customer_info){
                return '<a style="' + (item.hasOwnProperty('customer_accent_colour') ? 'color: ' + item.customer_accent_colour + ' !important;' : '') +  '" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a>';
            }
            return '-';
        }
    },
    name: {
        key: 'name',
        label: "Asset Passport Name",
        class: "col-lg-2 ap_asset_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.universal_asset_name !== ''){
                return '<a class="text-dark" href="/' + FirstPath + '/asset_passports/' + item.id + '/view">' + item.universal_asset_name + '</a>';
            }
            return '-';
        }
    },
    passport_type: {
        key: 'passport_type',
        label: "Passport Type",
        class: "col-lg-1 ap_passport_type",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.quantity > 1 ? 'Shared' : 'Unique';
        }
    },
    category: {
        key: 'category',
        label: "Category",
        class: "col-lg-1 ap_category",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let parent_category = item.parent_category;
            if (parent_category){
                return Vue.options.filters.titleize(parent_category.name);
            }
            return '-';
        }
    },
    volume: {
        key: 'volume',
        label: "Volume",
        class: "col-lg-1 ap_volume",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let volume = item.volume;
            if (volume){
                return volume + 'm<sup>3</sup>';
            }
            return '-';
        }
    },
    colour: {
        key: 'colour',
        label: "Colour",
        class: "col-lg-1 ap_colour",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let colours_info = item.colours_info;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';

        }
    },
    condition: {
        key: 'condition',
        label: "Condition",
        class: "col-lg-1 ap_condition",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return item.condition > 0 ? conditions_list[item.condition - 1] : '-';
        }
    }
};
