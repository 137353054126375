<template>
    <div class="col-sm-12">
        <div v-if="addForm.hasOwnProperty('module_navigations')">
            <div
                v-for="(field, index) in addForm.module_navigations"
                :key="index"
                class="row"
                v-if="field"
            >
                <div class="mb-3 col-lg-8" v-if="field.hasOwnProperty('item_type') && field.item_type === 'title'">
                    <div class="row">
                        <div class="col-lg-6">
                            <label for="item_name">Title</label>
                            <input id="item_name" v-model="field.item_name" type="text" class="form-control"/>
                        </div>
                        <div class="col-lg-6">
                            <label for="item_permission">Permission</label>
                            <input id="item_permission" v-model="field.item_permission" type="text" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-lg-8" v-else-if="field.hasOwnProperty('item_type') && ['object', 'link'].includes(field.item_type)">
                    <div class="row">
                        <div class="col-lg-3">
                            <label for="item_name">Name</label>
                            <input id="item_name" v-model="field.item_name" type="text" class="form-control"/>
                        </div>
                        <div class="col-lg-3">
                            <label for="item_link">Link</label>
                            <input id="item_link" v-model="field.item_link" type="text" class="form-control"/>
                        </div>
                        <div class="col-lg-2">
                            <label for="item_permission">Permission</label>
                            <input id="item_permission" v-model="field.item_permission" type="text" class="form-control"/>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label for="item_icon">Icon</label>
                                    <input id="item_icon" v-model="field.item_icon" type="text" class="form-control"/>
                                </div>
                                <div class="col-lg-6">
                                    <label for="is_condensed">Condensed?</label> <input id="is_condensed" v-model="field.is_condensed" type="text" class="form-control"/><small>value is 1 or 0 only</small>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="mb-3 col-lg-8" v-else>
                    <label for="navigation_item">Navigation Item</label>

                    <multiselect
                        v-if="required_fields"
                        v-model="field.navigation_item"
                        track-by="id"
                        placeholder="Navigation Item"
                        class="mySingleSelect"
                        :loading="required_fields.navigation_item.isLoadingItem"
                        :options="required_fields.navigation_item.options"
                        @input="changedSelectField('navigation_item', $event)"
                        :allow-empty="false"
                        deselect-label=""
                    >
                        <template v-slot:singleLabel="{ option }">
                            <div><i :class="option.icon"></i> {{ option.name }} <sup v-if="option.is_title">Title</sup></div>
                        </template>
                        <template v-slot:option="{ option }">
                            <div>{{ (addForm.entity_type === 'assetflow' ? (option.item_type.toUpperCase() + ' | ') : '')}}<i :class="option.icon"></i> {{ option.name }}{{' => ' + option.link }} <sup v-if="option.is_title">Title</sup></div>
                        </template>
                    </multiselect>
                </div>

                <div class="mb-3 col-lg-2">
                    <label for="navigation_order">Order</label>
                    <input
                        id="navigation_order"
                        v-model="field.navigation_order"
                        type="text"
                        class="form-control"
                    />
                </div>

                <div class="col-lg-2 align-self-center">
                    <div class="d-grid">
                        <input
                            type="button"
                            class="btn btn-danger btn-block"
                            value="Delete"
                            @click="deleteRow(index)"
                        />
                    </div>
                </div>

            </div>
        </div>
        <div class="row mb-2">
            <div class="col-lg-10">
                <input
                    type="button"
                    class="btn btn-success btn-block"
                    value="Add"
                    @click="AddformData"
                />
            </div>
            <div class="col-lg-2">
                <input
                    type="button"
                    class="btn btn-danger btn-block"
                    value="Reset All"
                    @click="addForm.module_navigations = [{navigation_item: '', navigation_order: 0}]"
                />
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-lg-4">
                <input
                    type="button"
                    class="btn btn-primary btn-block"
                    value="Add Title"
                    @click="addNewItem('title')"
                />
            </div>
            <div class="col-lg-4">
                <input
                    type="button"
                    class="btn btn-primary btn-block"
                    value="Add Object"
                    @click="addNewItem('object')"
                />
            </div>
            <div class="col-lg-4">
                <input
                    type="button"
                    class="btn btn-primary btn-block"
                    value="Add External Link"
                    @click="addNewItem('link')"
                />
            </div>
        </div>

    </div>
</template>
<script>

import multiSelect from '@/mixins/MultiSelect';

export default {
    name: 'CustomMNRepeater',
    mixins:[ multiSelect ],
    props: ['addForm', 'required_fields'],
    data(){
        return {

        }
    },
    created() {
        let singleItem = {navigation_item: "", navigation_order: 0};
        if (!this.addForm.hasOwnProperty('module_navigations')){
            this.addForm.module_navigations = [singleItem];
        }else{
            if (this.addForm.module_navigations.length === 0){
                this.addForm.module_navigations = [singleItem];
            }
        }
    },
    methods:{

        changedSelectField(field, event){
            console.log('changedSelectField', field);
        },
        AddformData() {
            this.addForm.module_navigations.push({navigation_item: "", navigation_order: ((this.addForm.module_navigations ? this.addForm.module_navigations.length : 0))});
        },
        addNewItem(item_type) {
            let new_item;
            let new_order = ((this.addForm.module_navigations ? this.addForm.module_navigations.length : 0))
            switch (item_type){
                case 'object':
                case 'title':
                case 'link':
                    new_item = {item_type: item_type, navigation_order: new_order};
                    break;
            }
            this.addForm.module_navigations.push(new_item);
        },
        deleteRow(index) {
            this.addForm.module_navigations.splice(index, 1);
        }
    }
}
</script>
