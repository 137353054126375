<template>
    <div>
        <div class="card-row card-row-header">
            <div class="col-md-6 col-xl-3 mb-3">
                No of Assignments
            </div>
            <div class="col-md-6 col-xl-3 mb-3">
                First Acquired
            </div>
            <div class="col-md-6 col-xl-3 mb-3">
                Age
            </div>
            <div class="col-md-6 col-xl-3 mb-3">
                Asset Flow
            </div>
        </div>
        <div class="card-row">
            <div class="col-md-6 col-xl-3 mb-3">
                {{ item_info.assignments_count}}
            </div>
            <div class="col-md-6 col-xl-3 mb-3">
                <div v-if="item_info.hasOwnProperty('first_acquired_asset') && item_info.first_acquired_asset.hasOwnProperty('acquired_date') && item_info.first_acquired_asset.acquired_date" class="acquired_date">
                    {{ item_info.first_acquired_asset.acquired_date | moment('MMMM Do YYYY') }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="col-md-6 col-xl-3 mb-3">
                <div v-if="item_info.hasOwnProperty('first_acquired_asset') && item_info.first_acquired_asset.hasOwnProperty('asset_age') && item_info.first_acquired_asset.asset_age" class="asset_age">
                    {{ (item_info.first_acquired_asset.asset_age ? item_info.first_acquired_asset.asset_age : '-') | titleize }}
                </div>
                <div v-else>-</div>
            </div>
            <div class="col-md-6 col-xl-3 mb-3">
                <div v-if="item_info.hasOwnProperty('first_acquired_asset') && item_info.first_acquired_asset.hasOwnProperty('asset_age') && item_info.first_acquired_asset.asset_age" class="asset_age">
                    <a :href="'/'+ item_info.current_page_type + '/assets/' + item_info.first_acquired_asset.id + '/view'"
                       v-b-tooltip
                       :title="item_info.first_acquired_asset.name"
                       class="text-dark">View</a>
                </div>
                <div v-else>View</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssetAssetflowBlock',
    props: {
        item_info: {}
    }
}
</script>
