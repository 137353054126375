<template>
    <Layout>
        <AddNewOrderForm
            ref="add-new-order"
            :item_id="item_id"
            :item_type="item_type"
            :received_order_type="item_id === 'move_to_storage' ? 0 : 1"
        />
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import AddNewOrderForm from "./AddNewOrderForm";

export default {
    name: "NewOrder",
    props: ['item_id', 'other_item_id', 'item_type'],
    components: {AddNewOrderForm, Layout},
    data(){
        return {
            listQuery:{},
            current_customer: null,
            current_user_info: null,
            current_sp: null
        }
    },
    created() {
        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
            this.current_sp = this.current_user_info.current_sp;
        }

        this.fetchItemInfo();
    },
    methods:{

        fetchItemInfo(){
            if (this.item_id && parseInt(this.item_id)){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/orders/' + this.item_id,
                    baseURL: '/',
                }).then(response => {

                    var item_info = response.data;

                    this.$refs['add-new-order'].setSelectedItem(item_info);

                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        }
    }
}
</script>
