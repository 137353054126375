<template>
    <div class="text-sm-right">
        <span id="enabled-wrapper">
            <b-button v-b-modal.assign-entity-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign To {{ entity_type | titleize }}</b-button>
        </span>
        <b-modal id="assign-entity-modal"
                 ref="assign-entity-modal"
                 :title="('Assign ' + entity_type) | titleize"
                 title-class="font-18"
                 @hide="$bvModal.hide('assign-entity-modal')"
                 @show="getEntitiesList"
                 hide-footer>

            <AssignToSp
                v-if="entity_type === 'sp'"
                :items="items"
                modal_id="assign-entity-modal"
                :item_id="item_id"
                :item_type="item_type"
                :listLoading="listLoading"
                @assigned-sp="assignToEntity" />

            <AssignToCustomer v-else-if="entity_type === 'customer'"
                :items="items"
                modal_id="assign-entity-modal"
                :item_id="item_id"
                :item_type="item_type"
                :listLoading="listLoading"
                @assigned-customer="assignToEntity" />
        </b-modal>
    </div>
</template>
<script>
import AssignToSp from "./forms/AssignToSp";
import AssignToCustomer from "./forms/AssignToCustomer";

import Resource from '@/api/resource';

export default {
    name: 'AssignModuleToEntity',
    components: {AssignToCustomer, AssignToSp},
    props: {
        item_info: {},
        items_count: 0,
        entity_type: '',
        item_id: '',
        item_type: '',
        assigned_entity_ids: [],
    },
    data(){
        return {
            items: null,
            listLoading: false,
        }
    },
    methods:{
        assignToEntity(form){

            let entity_id = form.item;
            let entity_info =  _.find(this.items, ['id', entity_id]);
            this.$emit('assigned-entity', form, entity_info);
        },
        async getEntitiesList() {
            this.listLoading = true;
            const ownersListResource = new Resource('api/assetflow/users/available_entities');
            let listQuery = {
                required_list: this.entity_type
            };
            const { data } = await ownersListResource.list(listQuery);

            let assigned_entity_ids = this.assigned_entity_ids;
            this.items = data.data.items.map(function(entity) {
                if (assigned_entity_ids.includes(entity.id)){
                    entity.name += ' (Assigned)';
                    entity.disabled = true;
                }
                return entity;
            });

            this.listLoading = false;
        },
    }
}
</script>
