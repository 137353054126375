const FirstPath = window.location.pathname.split('/')[1];
export const IntakeProfilesShortKeysList = {
    uid: {
        key: 'uid',
        label: "Intake Profile ID",
        class: "col-lg-1 int_uid",
        show_on_mobile: true,
        case: ''
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-2 int_customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_info = item.customer_info;
            if (customer_info){
                return '<a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a>';
            }
            return '';
        }
    },
    category_info: {
        key: 'category_info',
        label: "Category",
        class: "col-lg-2 int_category_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let category_info = item.category_info;
            return category_info ? category_info.name : '';
        }
    },

    dimensions: {
        key: 'dimensions',
        label: "Dimensions",
        class: "col-lg-2 int_dimensions",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item.width + '*' + item.height + '*' + item.depth;
        }
    },
    volume: {
        key: 'volume',
        label: "Volume",
        class: "col-lg-1 int_volume",
        show_on_mobile: false,
        case: ''
    },
    colour: {
        key: 'colour',
        label: "Colour",
        class: "col-lg-2 int_colour",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let colours_info = item.colours_info;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';

        }
    },
    condition: {
        key: 'condition',
        label: "Condition",
        class: "col-lg-1 int_condition",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return item.condition > 0 ? conditions_list[item.condition - 1] : '-';
        }
    },
    waste_stream: {
        key: 'waste_stream',
        label: "Waste Stream",
        class: "col-lg-1 int_waste_stream",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] ? item[key].map(function(name) {
                name = name + '';
                return name ? (name.charAt(0).toUpperCase() + name.substring(1)) : '';
            }).join(', ') : '-';
        }
    }
};
