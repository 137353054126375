<template>
    <div class="ChangeSceneOverviewTab">
        <div class="row">
            <div class="col-lg-8 col-md-12 col-12">
                <div class="row">
                    <SetChangeSceneDescription
                        ref="set-change-scene-description"
                        item_type='description-overview'
                        modal_title="Update Scene Notes"
                        :received_item_id="item_info.id"
                        @on-new-item-adding="AfterSettingDescription"
                    >
                    </SetChangeSceneDescription>
                    <NewCustomGenericBlock
                        block_title="Scene Order"
                        columns_count_withclasses="12"
                        action_required_title="Scenes for this Change"
                        :action_required="goToTabScenesTab"
                        box_colour_v="-"
                    >
                        <template v-slot:all_content>
                            <div class="ns_ptc_box_content">
                                <SceneColouredBlock
                                    v-for="(coloured_block, key) in scene_coloured_blocks"
                                    :key="key"
                                    :coloured_block="coloured_block"
                                    :items_count="parts_count"
                                    :scene-clicked="sceneClicked"
                                />

                            </div>
                        </template>
                    </NewCustomGenericBlock>
                    <NewCustomGenericBlock
                block_title="Scene Notes"
                columns_count_withclasses="12"
                action_required_title="Update Scene Notes"
                :action_required="addDescriptionModal"
                box_colour_v="-"
            >
                <template v-slot:all_content>
                    <div class="scene-notes-description" v-if="part_change_description !== ''">{{ part_change_description }}</div>
                </template>
            </NewCustomGenericBlock>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                    <NewCustomGenericBlock
                        :block_title_html="getHtmlTitleFor('part_changes')"
                        box_colour_v="-"
                        columns_count_withclasses="12">
                        <template v-slot:all_content>
                            <PartChangeRightBox :pc_item_info="item_info" unique_id="overview"/>
                        </template>
                    </NewCustomGenericBlock>

                    <div class="popup-gallery">
                        <vue-easy-lightbox
                            :visible="visibleCaption"
                            :imgs="captionimgs"
                            :index="index"
                            @hide="handleCaptionHide">
                        </vue-easy-lightbox>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import SetChangeSceneDescription from "@/views/assetflow/items/ChangeSceneRel/SetChangeSceneDescription";
import SceneColouredBlock from "../../customers/ShootDayLive/SceneColouredBlock";
import PartChangeRightBox from "../PartChangeRightBox";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
    name: 'ChangeSceneOverviewTab',
    mixins:[ImageGallery],
    components: {PartChangeRightBox, SceneColouredBlock, NewCustomGenericBlock, SetChangeSceneDescription, VueEasyLightbox},
    props: {
        item_info: {},
        goToTab: {},
    },
    data(){
        return {
            part_change_description: '',
            partSortType:{
                value: 0,
                text: 'Part Order',
                first_criteria: 'target_scene',
                first_reversed: false,
                name: 'part_order',
                second_criteria: 'scene_order',
                second_reversed: false,
                third_criteria: 'part_order',
                third_reversed: false
            },
        }
    },
    created() {
        if (this.item_info){
            this.part_change_description = this.item_info.description;
        }
    },
    computed:{
        change_info(){
            return this.item_info.hasOwnProperty('change_info') && this.item_info.change_info ? this.item_info.change_info : null;
        },
        parts_info(){
            return this.change_info.hasOwnProperty('parts_info') && this.change_info.parts_info ? this.change_info.parts_info : null;
        },
        parts_count(){
            return this.parts_info ? this.parts_info.length : 0;
        },
        item_info_description(){
            return this.item_info.description;
        },
        scene_coloured_blocks(){
            return this.getSceneColouredBlocks();
        }
    },
    watch:{
        item_info_description:{
            deep: true,
            handler: function (newValue) {
                this.part_change_description = this.item_info_description;
            }
        },
    },
    methods: {
        goToTabScenesTab(){
            this.goToTab('change_scene_scenes_tab');
        },
        sceneClicked(coloured_block){
            return "/" + this.item_info.current_page_type + '/changes/'+this.item_info.change_info.id + '/scene/' + coloured_block.item_id;
        },
        getColouredBlockFor: function (current_part_index, block_name) {
            let parts_info = this.parts_info;

            let current_part_info = parts_info[current_part_index];

            let current_part_name = current_part_info.scene_info.name || '';

            if (current_part_info.name !== null && current_part_info.name !== ''){
                current_part_name += ' ' + current_part_info.name;
            }

            let current_part_date = '-';
            let block_class = 'ns_bor_red';
            if (current_part_info.hasOwnProperty('shoot_day_date') && current_part_info.shoot_day_date) {
                current_part_date = Vue.options.filters.moment(current_part_info.shoot_day_date, 'DD/MM/YYYY');

                let shootDate = this.$moment(current_part_info.shoot_day_date);
                if (this.$moment() > shootDate) {
                    block_class = 'ns_bor_green';
                }
            }

            if (block_name === 'Current'){
                block_class = 'ns_bor_yellow';
            }

            return {
                type: block_name,
                index: current_part_index + 1,
                item_id: current_part_info.id,
                block_class: block_class,
                name: current_part_name,
                date: current_part_date,
                part_info: current_part_info
            };
        },
        getSceneColouredBlocks(){
            let part_id = this.item_info.part_id;
            let previous_part_block = null;
            let current_part_block = null;
            let next_part_block = null;

            if (part_id){

                let parts_info_ids = this.parts_info.map(function(e) { return e.id; });
                let current_part_index = parts_info_ids.indexOf(parseInt(part_id));

                if (current_part_index > -1){
                    current_part_block = this.getColouredBlockFor(current_part_index, 'Current');

                    if (current_part_index > 0){
                        previous_part_block = this.getColouredBlockFor(current_part_index-1, 'Prev');
                    }

                    if (current_part_index !== (this.parts_count - 1)){
                        next_part_block = this.getColouredBlockFor(current_part_index+1, 'Next');
                    }
                }
            }

            let coloured_blocks = [];

            if (previous_part_block){
                coloured_blocks.push(previous_part_block)
            }

            if (current_part_block){
                coloured_blocks.push(current_part_block)
            }

            if (next_part_block){
                coloured_blocks.push(next_part_block)
            }


            return coloured_blocks;
        },
        addDescriptionModal(){
            this.$refs['set-change-scene-description'].setSelectedItem({description: this.part_change_description});
            this.$bvModal.show('create-description-overview');
        },
        AfterSettingDescription(new_item, item_type){
            this.part_change_description = new_item.description;
            this.item_info.description = new_item.description;
            this.$emit('description-changed', new_item, item_type);
        },

    }

}
</script>
