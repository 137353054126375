// Create a file named paginationPlugin.js
export default {
    install(Vue) {
        Vue.mixin({
            mounted() {
                // Check if the component is a b-pagination component
                if (this.$options.name === 'BPagination') {
                    // Set the limit property to 20
                    this.limit = 21;
                }
            }
        });
    }
};
