<template>
    <div class="row CharacterScenesTab">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="text-center mt-5">
                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                <h4>Scenes will appear here.</h4>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CharacterScenesTab',
    props: {
        item_info: {}
    }
}
</script>

