export const Timezones = [
    {
        value: '-11:00',
        text: "GMT-11"
    },
    {
        value: '-10:00',
        text: "GMT-10"
    },
    {
        value: '-09:00',
        text: "GMT-9"
    },
    {
        value: '-08:00',
        text: "GMT-8"
    },
    {
        value: '-07:00',
        text: "GMT-7"
    },
    {
        value: '-06:00',
        text: "GMT-6"
    },
    {
        value: '-05:00',
        text: "GMT-5"
    },
    {
        value: '-04:00',
        text: "GMT-4"
    },
    {
        value: '-03:00',
        text: "GMT-3"
    },
    {
        value: '-02:00',
        text: "GMT-2"
    },
    {
        value: '-01:00',
        text: "GMT-1"
    },
    {
        value: '+00:00',
        text: "GMT"
    },
    {
        value: '+01:00',
        text: "GMT+1"
    },
    {
        value: '+02:00',
        text: "GMT+2"
    },
    {
        value: '+03:00',
        text: "GMT+3"
    },
    {
        value: '+04:00',
        text: "GMT+4"
    },
    {
        value: '+05:00',
        text: "GMT+5"
    },
    {
        value: '+06:00',
        text: "GMT+6"
    },
    {
        value: '+07:00',
        text: "GMT+7"
    },
    {
        value: '+08:00',
        text: "GMT+8"
    },
    {
        value: '+09:00',
        text: "GMT+9"
    },
    {
        value: '+10:00',
        text: "GMT+10"
    },
    {
        value: '+11:00',
        text: "GMT+11"
    }
];
