<template>
    <b-modal :id="modal_id"
             title="Update Costs"
             title-class="font-18"
             size="xl"
             @hide="cancelClicked"
             @show="setDefaultValues"
             hide-footer>

        <div class="common-step-panel use-section">
            <div class="row">

                <div v-for="(column_sections, col_key) in columns_class"
                     :key="col_key"
                     :class="column_sections.column_class"
                >
                    <AssetInfoSection
                        v-for="(section_group, key) in column_sections.fields_list"
                        :key="key"
                        :title="section_group.name ? section_group.name : ''"
                        :add-form="addForm"
                        :selected_fields="section_group.fields"
                        :required_fields="required_fields"
                        :received_toggle="section_group.minimized">

                        <StepCommonInfoBlock
                            :add-form="addForm"
                            :block_key="key"
                            :item_info="item_info"
                            :changed-radio-value="changedRadioValue"
                            :mark-as-checked="markAsChecked"
                            :required_fields="required_fields"
                            :section_group="section_group"
                            :my_lists_values="lists_values"
                            :without_asset_passport_name="true"
                        />
                    </AssetInfoSection>

                </div>

            </div>
        </div>

        <div class="text-right">
            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'save-asset-acquisition' + '-' + buttonkey"
                :is_enabled="is_saving_enabled"
                :required_action="addItem"
                :required_text="item_info && item_info.hasOwnProperty('name') ? ('Update {' + item_info.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />

            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>

    </b-modal>

</template>
<script>

import AFButton from "@/components/AFButton";
import AssetInfoSection from "./AssetInfoSection";
import StepCommonInfoBlock from "../../customers/acquisitions/CostumeAcquisitionForm/StepCommonInfoBlock";
export default {
    components: {StepCommonInfoBlock, AssetInfoSection, AFButton},
    mixins: [],
    name: 'UpdateCostsModal',
    props: ['received_item_info', 'modal_id', 'item_type', 'is_list'],
    data(){
        return {
            item_info: null,
            addForm: {
                asset_meta: {},
                item_details: {},
            },
            columns_class:{
                left_column: {
                    title: '',
                    column_class: 'col-xl-12',
                    fields_list: {
                        costume_section: {
                            name: 'Costume',
                            minimized: false,
                            fields: [
                                'ledger_id'
                            ]
                        },
                        cost_section: {
                            name: 'Quantity & Costs',
                            minimized: false,
                            fields: [
                                'quantity',
                                'total_price',
                                'unit_price',
                                'net_total',
                                'unit_net',
                                'tax_rate',
                                'tax',
                                'unit_tax',
                            ]
                        },
                    }
                },
            },
            required_fields: {

                ledger_id: {
                    name: 'Ledger',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                quantity: {
                    name: 'Quantity',
                    type: 'number',
                    default_value: 1,
                    has_permission: true
                },
                total_price: {
                    name: '1 Unit',
                    main_title: 'Totals With Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                unit_price: {
                    name: 'Unit Price',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    has_currency: true
                },
                net_total: {
                    name: '1 Unit',
                    main_title: 'Totals without Tax',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_net: {
                    name: 'Unit Net',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                tax_rate: {
                    name: 'Tax Rate',
                    main_title: 'Tax',
                    type: 'number',
                    default_value: 0.2,
                    has_permission: true
                },
                tax: {
                    name: '1 Unit',
                    type: 'number',
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
                unit_tax: {
                    name: 'Unit Tax',
                    type: 'number',
                    is_hidden:true,
                    default_value: 0,
                    has_permission: true,
                    is_readonly: true,
                    has_currency: true
                },
            }
        }
    },
    created() {
        this.item_info = this.received_item_info;

        this.getFieldsList();
    },
    methods:{

        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;
            let required_select_lists =  {
                ledger_id: 'ledgers_short_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                let form_lists = response.data.data;
                if (form_lists){
                    for (const list_key in required_select_lists) {
                        let select_name = required_select_lists[list_key];
                        this.lists_values[list_key] = form_lists[select_name];
                    }
                    for (let key in this.lists_values) {
                        this.getOptionsForKey(key, 'assets');
                    }
                }

                this.setDefaultValues();

            }).catch(error => {
                console.log(error);
            });
        },
        set_item(item){
            this.item_info = item;

            if (this.item_info.hasOwnProperty('department_info') && this.item_info.department_info){
                this.columns_class.left_column.fields_list.costume_section.name = this.item_info.department_info.name;
            }
            this.setDefaultValues();
        },
        setDefaultValues() {
            let addForm = this.addForm;
            _.forOwn(this.required_fields, function (field, key) {
                if (field.hasOwnProperty('default_value')) {
                    addForm.item_details[key] = field.default_value;
                }
            });

            if (this.item_info){
                addForm.item_details.ledger_id = this.getObjectOf(this.item_info.ledger_id, this.required_fields.ledger_id.options);
                let quantity = this.item_info.quantity;
                addForm.item_details.quantity = quantity;
                addForm.item_details.total_price = this.item_info.total_value;
                addForm.item_details.net_total = this.item_info.net_total;
                addForm.item_details.tax_rate = this.item_info.tax_rate;
                addForm.item_details.tax = this.item_info.tax;

                addForm.item_details.unit_net = (this.item_info.net_total / quantity).toFixed(2);
                addForm.item_details.unit_price = (this.item_info.total_value / quantity).toFixed(2);
                addForm.item_details.unit_tax = (this.item_info.tax / quantity).toFixed(2);
            }

            this.addForm.item_details = Object.assign({}, addForm.item_details);

        },
        changedRadioValue(field, event) {

        },
        markAsChecked(section_field, event) {
            let new_value = 1;
            if (this.addForm.item_details.hasOwnProperty(section_field)){
                new_value = this.addForm.item_details[section_field] === 1 ? 0 : 1;
            }
            this.$set(this.addForm.item_details, section_field, new_value);
        },
        cancelClicked(){
            this.$bvModal.hide(this.modal_id);
            this.$emit('on-modal-cancel');
        },
        async addItem(event){
            this.isLoadingItem = true;

            let quantity = this.addForm.item_details.quantity;
            let item_details_values = {
                quantity: quantity,
                available_quantity: quantity,
                value: (this.addForm.item_details.total_price / quantity).toFixed(2),
                net_total: this.addForm.item_details.net_total,
                total_value: this.addForm.item_details.total_price,
                tax_rate: this.addForm.item_details.tax_rate,
                tax: this.addForm.item_details.tax,
                ledger_id: this.addForm.item_details.hasOwnProperty('ledger_id') && this.addForm.item_details.ledger_id ? this.addForm.item_details.ledger_id.id : null
            };

            let asset_id = this.item_info.id;
            let params = {
                asset_id: asset_id,
                item_details: item_details_values
            };

            axios({
                method: 'put',
                url: '/api/assetflow/assets/' + asset_id,
                baseURL: '/',
                data: params,
            }).then(response => {
                this.isLoadingItem = false;
                if (response.data.data.status === true) {
                    let added_item = response.data.data.items;
                    let message;

                    if (added_item && added_item.hasOwnProperty('id')){
                        let asset_name = ', ASS-' + added_item.uid + ' {' + added_item.name + '}';
                        message = 'Done' + asset_name;
                        if (asset_id !== '') {
                            message = 'Updated Costs Successfully' + asset_name;
                        }

                        let status = response.data.data.status;
                        this.presentToast("Success!",
                            message,
                            (status ? 'success' : 'danger'),
                            3000,
                            true,
                            true);

                        let required_keys = [
                            'ledger_info',
                            'quantity',
                            'value',
                            'net_unit',
                            'total_value',
                            'net_total',
                            'tax_rate',
                            'tax'
                        ]
                        this.$emit('on-cost-updates', this.modal_id, added_item, required_keys);


                    }else{
                        console.log(added_item);
                    }

                } else {
                    console.log('Something went wrong');
                }
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },

    }
}
</script>
