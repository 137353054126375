const FirstPath = window.location.pathname.split('/')[1];
export const PostMetaKeys = {
    name:{
        label: 'Name',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    description:{
        label: 'description',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    status:{
        label: 'Status',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    quantity:{
        label: 'Qty',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    internal_reference:{
        label: 'Ref',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    images_count:{
        label: 'No. of images',
        cast_value: function (item, key) {
            return item[key];
        }
    },
    asset_usage:{
        label: 'Asset Usage',
        cast_value: function (item, key) {
            return item[key] === 1 ? 'On Camera' : 'Off Camera';
        }
    },
    is_hero:{
        label: 'Is Hero',
        cast_value: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },
    is_asset:{
        label: 'Is Asset',
        cast_value: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },
    contact_id:{
        label: 'User',
        cast_value: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/users/' + item[key] + '/view">' + Vue.options.filters.titleize(item.contact_name) + '</a>';
        }
    },
    folder_id:{
        label: 'Folder',
        cast_value: function (item, key) {
            if (item.hasOwnProperty('folder_names_info') && item.folder_names_info && item.folder_names_info.length){
                return '<a class="text-dark" href="/' + FirstPath + '/folders?s=' + item.folder_name + '">' + item.folder_names_info.map(e => { return e.text; }).join(" > ") + '</a>';
            }
            return '-';
        }
    },
    category_id:{
        label: 'Category',
        cast_value: function (item, key) {
            if (item.hasOwnProperty('category_names_info') && item.category_names_info && item.category_names_info.length){ // contains only names, ids can be enabled
                return '<a class="text-dark" href="/' + FirstPath + '/categories?s=' + item.category_name + '">' + item.category_names_info.map(e => { return (e.hasOwnProperty('text') ? e.text : e); }).join(" > ") + '</a>';
            }
            return '-'
        }
    },
    department_id:{
        label: 'Department',
        cast_value: function (item, key) {
            let department_name = Vue.options.filters.titleize(item.department_name);
            return Vue.options.filters.iconize(department_name, 'departments');
        }
    },
    supplier_id:{
        label: 'Supplier',
        cast_value: function (item, key) {
            if (item.hasOwnProperty('supplier_name') && item.supplier_name){
                let supplier_name = '<a class="post-update-link-value" href="/' + FirstPath + '/suppliers/' + item.supplier_id + '/view" target="_blank">' + item.supplier_name + '</a>';
                return Vue.options.filters.iconize(supplier_name, 'suppliers');
            }
            return '-';
        }
    },
    location_id:{
        label: 'Location',
        cast_value: function (item, key) {
            return Vue.options.filters.titleize(item.location_name);
        }
    },
    final_disposition_planning:{
        label: 'FDP',
        cast_value: function (item, key) {
            return item.final_disposition_planning_text;
        }

    },
    custom_fields:{
        label: 'Custom Fields',
        cast_value: function (item, key) {
            let html = '<ul>';
            for (const itemElementKey in item[key]) {
                let custom_field = item[key][itemElementKey];
                html += '<li>' + custom_field.field_name + ' <b>' + custom_field.field_value + '</b></li>';
            }
            html += '<ul>';
            return html;
        }
    },
};
