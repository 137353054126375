export const AutoDimensionsList = [
    {
        id: 1,
        name: "Metre 1",
        image: '/images/megamenu-img.png',
        unit: 'm',
        dimensions:{
            height: '1',
            width: '1',
            depth: '1'
        },
    },
    {
        id: 2,
        name: "Metre 2",
        image: '/images/megamenu-img.png',
        unit: 'm',
        dimensions:{
            height: '10',
            width: '10',
            depth: '10'
        },
    },
    {
        id: 3,
        name: "Centi 1",
        image: '/images/megamenu-img.png',
        unit: 'cm',
        dimensions:{
            height: '100',
            width: '100',
            depth: '100'
        },
    },
    {
        id: 4,
        name: "Centi 2",
        image: '/images/megamenu-img.png',
        unit: 'cm',
        dimensions:{
            height: '100',
            width: '100',
            depth: '100'
        },
    },
    {
        id: 5,
        name: "Milli 1",
        image: '/images/megamenu-img.png',
        unit: 'mm',
        dimensions:{
            height: '1000',
            width: '1000',
            depth: '1000'
        },
    },
    {
        id: 6,
        name: "Milli 2",
        image: '/images/megamenu-img.png',
        unit: 'mm',
        dimensions:{
            height: '10000',
            width: '10000',
            depth: '10000'
        },
    },
];
