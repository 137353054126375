<template>
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                {{header_title}}
            </div>
            <div class="card-body">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DetailsSection",
        components: {},
        props: {
            header_title: {
                type: String,
                default: '',
            }
        },
    }
</script>


