<template>
    <!-- Overview Row -->
    <div class="row mt-4 overview-block">
        <div class="col-md-12 col-lg-12 col-xl-8 mb-3 cards-seperate overview-block-card">
            <div class="card">
                <div class="card-header af-primary">
                    Overview
                </div>
                <div class="card-body">
                    <div class="overview-table">
                        <div class="card-row" v-if="po.supplier_address.company !== ''">{{ po.supplier_address.company }}</div>
                        <div class="card-row" v-if="po.supplier_address.line_1 !== ''">{{ po.supplier_address.line_1 }}</div>
                        <div class="card-row" v-if="po.supplier_address.line_2 !== ''">{{ po.supplier_address.line_2 }}</div>
                        <div class="card-row" v-if="po.supplier_address.city !== ''">{{ po.supplier_address.city }}</div>
                        <div class="card-row" v-if="po.supplier_address.state !== ''">{{ po.supplier_address.state }}</div>
                        <div class="card-row" v-if="po.supplier_address.zip_code !== ''">{{ po.supplier_address.zip_code }}</div>
                        <div class="card-row" v-if="po.supplier_address.country !== ''">{{ po.supplier_address.country }}</div>
                    </div>

                    <div class="detail-table">
                        <div class="card-row">
                            <div class="card-left">P.O. Number</div>
                            <div class="card-right">PO-{{ po.uid }}</div>
                        </div>
                        <div class="card-row">
                            <div class="card-left">SID</div>
                            <div class="card-right">{{ po.id }}</div>
                        </div>
                        <div class="card-row">
                            <div class="card-left">External Reference</div>
                            <div class="card-right">{{ po.reference }}</div>
                        </div>
                        <div class="card-row">
                            <div class="card-left">Department</div>
                            <div class="card-right">
                                <a v-if="po.hasOwnProperty('department_info') && po.department_info" class="text-dark" :href="'/' + po.current_page_type + '/departments/' + po.department_info.id + '/view'">{{ po.department_info.name }}</a>
                                <span v-else>-</span>
                            </div>
                        </div>
                        <div class="card-row">
                            <div class="card-left">Created By</div>
                            <div class="card-right">
                                <a v-if="po.hasOwnProperty('user_info') && po.user_info" class="text-dark" :href="'/' + po.current_page_type + '/users/' + po.user_info.id + '/view'">{{ po.user_info.name }}</a>
                                <span v-else>-</span>
                            </div>
                        </div>
                        <div class="card-row">
                            <div class="card-left">Date</div>
                            <div class="card-right">{{ po.created_at | moment("dddd, MMMM Do YYYY") }}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-6 col-xl-4 mb-3 cards-seperate lifecycle-block">
            <div class="card">
                <div class="card-header af-primary">
                    Life Cycle
                </div>
                <div class="card-body">
                    <div class="card-row ">
                        <ul id="progressbar">
                            <li class=""><span>Step 1</span></li>
                            <li class=""><span>Step 2</span></li>
                            <li class=""><span>Step 3</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Overview Row  Close -->
</template>
<script>
export default {
    name: 'PurchaseOrderHeader',
    props: ['po'],
    data(){
        return {

        }
    }
}
</script>
