<template>
    <div class="row" v-if="changingAllDepartmentsStatus">
        <div class="col-lg-12">
            <div class="alert alert-info">
                Please Wait...
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div v-if="item_info.hasOwnProperty('has_all_departments') && item_info.has_all_departments" class="col-lg-12" >
            <div class="alert alert-success">
                <i class="bx bx-check-double me-2"></i> All Departments Enabled. There is no need to assign any departments to this user.
                <a href="javascript:void(0);" @click="changeAllDepartments(0)">Disable All Departments</a>
            </div>
        </div>
        <div v-else class="col-lg-12">
            <div class="alert alert-info">
                <i class="bx bx-x me-2"></i> All Departments Not Enabled. Assign this user to the required departments.
                <a href="javascript:void(0);" @click="changeAllDepartments(1)">Enable All Departments</a>
            </div>

            <p v-if="this.item_type !== 'users'" class="mb-2">Departments that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+this.item_type+'/'+this.item_id+'/view'">{{this.item_name}}</a></p>

            <p v-else class="mb-2">Departments that&nbsp;<a :href="base_url +'/'+ item_info.group + '/users/'+this.item_id+'/view'">{{this.item_name}}</a>&nbsp;belongs to.</p>

            <div class="text-sm-right">
                <span :id="this.items.length === 0 && this.selected_owner_id === null ? 'disabled-wrapper' : 'enabled-wrapper'">
                    <b-button :disabled="this.items.length === 0 && this.selected_owner_id === null" v-b-modal.departments-modal variant="success" class="btn btn-success btn-rounded mb-2 mr-2"><i class="mdi mdi-plus mr-1"></i> Assign Department</b-button>
                </span>

                <b-tooltip target="disabled-disabled-wrapper" placement="left" variant="info">No departments to assign.</b-tooltip>

                <b-modal id="departments-modal"
                         ref="assign-department-modal"
                         title="Assign Department"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-department
                        ref="assign-to-department"
                        :items="item_info.item_type === 'users' && item_info.current_page_type === 'assteflow' ? null : this.items"
                        :item_info="item_info"
                        @assigned-department="assignToDepartment"
                        @changed-owner="changeOwner"
                    ></assign-to-department>

                </b-modal>
            </div>
            <po-lines
                :items="departmentsData"
                :current_page_type="item_info.current_page_type"
                :object_type="'departments'"
                :keys_list="department_list_keys_list">
                <template v-slot:department_actions="{ item, index }">
                    <a v-if="hasPermission('browse_departments')" :href="base_url +'/'+ item_info.current_page_type + '/departments/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                    <a v-if="hasPermission('edit_departments') && !item.roles_count" v-on:click="unAssignFromDepartment($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                </template>
            </po-lines>
        </div>

    </div>
</template>

<script>
import SearchBox from "@/components/search-box";
import Resource from '@/api/resource';

import AssignToDepartment from "../forms/AssignToDepartment.vue";
import PoLines from "./detailsViews/PoLines";
import {DepartmentShortKeysList} from "@/views/assetflow/form_data/DepartmentShortKeysList";

export default {
    name: "ListDepartments",
    components: {PoLines, AssignToDepartment, SearchBox},
    props: {
        item_info: Object,
    },
    data() {
        return {
            changingAllDepartmentsStatus: false,
            department_list_keys_list: DepartmentShortKeysList,
            base_url: "",
            departmentsData: [],
            item_id: null,
            item_name: "",
            item_type: "",
            items: [],
            listLoading: false,
            listQuery:{
                page: 1,
                limit: 20
            },
            assignData: {
                owner_id: "",
                department_id: ""
            },
            selected_owner_id: null,
            has_department_actions_permissions: null
        }
    },
    created() {

        this.has_department_actions_permissions = this.hasPermission('browse_departments');

        if (this.has_department_actions_permissions){
            this.department_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-3 c_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'department_actions'
            }
        }

        this.item_id = this.item_info.id;
        this.item_name = this.item_info.name;
        this.item_type = this.item_info.item_type;
        this.base_url = this.item_info.base_url;
        this.getList();
        this.getDepartmentsList();
    },
    methods: {
        async changeAllDepartments(nextStatus) {
            // assets
            this.changingAllDepartmentsStatus = true;
            try {
                let user_id = this.item_info.id;
                const response = await axios.post('/api/assetflow/user_customers/toggle_departments', {
                    has_all_departments: nextStatus,
                    user_id: user_id
                });
                if (response.data.data.status){
                    this.item_info.has_all_departments = response.data.data.has_all_departments;
                    console.log('Successfully toggled has_all_departments');
                } else {
                    console.error('Failed to toggle has_all_departments');
                }
                this.changingAllDepartmentsStatus = false;
            } catch (error) {
                console.error('Error occurred while toggling has_all_departments:', error);
                this.changingAllDepartmentsStatus = false;
            }
        },
        getList() {
            if (!this.listLoading) {
                this.listLoading = true;
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/departments' ,
                    baseURL: '/',
                    data: {
                        page: 1,
                        limit: 20,
                        current_page_type: this.item_info.current_page_type
                    },
                }).then(response => {
                    this.listLoading = false;
                    this.departmentsData = response.data.data.items;
                    this.total = response.data.data.total;
                    this.listLoading = false;

                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });
            }
        },
        changeOwner(owner_id='', item_type=''){

            this.getDepartmentsList(owner_id, item_type);
            this.selected_owner_id = owner_id;
        },
        assignToDepartment(department_id, roles_list=[]){
            this.$refs['assign-department-modal'].hide();
            this.assignData = {
                owner_id: this.item_id,
                item_id: department_id,
                item_type: 'departments',
                selected_owner_id: this.selected_owner_id,
                roles_list: roles_list
            };

            this.assign_unassign_department();

            console.log("should assign user to department " + department_id + this.item_id);
        },
        assign_unassign_department() {
            axios({
                method: 'post',
                url: '/api/assetflow/'+this.item_type+'/assign_department',
                baseURL: '/',
                data: this.assignData,
            }).then(response => {
                let status = response.data.status;
                let msg = response.data.msg;
                if (status) {
                    this.presentToast("Success!",
                        msg,
                        'success',
                        3000,
                        true,
                        true);
                    this.getList();
                    this.getDepartmentsList();
                    if (this.assignData.roles_list){
                        this.$set(this.item_info, 'refresh_roles_list', true);
                    }
                }else{
                    this.presentToast("Failed!",
                        msg,
                        'danger',
                        3000,
                        true,
                        true);
                }

            }).catch(error => {
                console.log(error);
            });
        },
        unAssignFromDepartment(event, department_id){
            this.assignData = {
                owner_id: this.item_id,
                item_id: department_id,
                item_type: 'departments',
                unassign: true,
                selected_owner_id: this.selected_owner_id
            };
            this.assign_unassign_department();
            console.log("should unassign user from department >> " + department_id);

        },
        async getDepartmentsList(owner_id='', item_type='') {
            this.listLoading = true;
            this.listQuery.item_type = this.item_type;

            if (this.item_type === 'users'){
                this.listQuery.user_id = this.item_id;
            }else{
                this.listQuery.item_id = this.item_id;
            }

            this.listQuery.current_page_type = this.item_info.current_page_type;
            this.listQuery.is_short_list = true;

            if (owner_id !== '') {
                this.listQuery.owner_id = owner_id;
                this.listQuery.owner_item_type = item_type;
            }
            const departmentResource = new Resource('api/assetflow/departments');

            const { data } = await departmentResource.list(this.listQuery);


            this.items =  data.data.items.filter((department) => {
                return !this.departmentsData.map(x => x.id).includes(department.id);
            });

            if (this.$refs['assign-to-department']){
                this.$refs['assign-to-department'].refreshDepartmentsList(this.items);
            }

            this.listLoading = false;
        },
    }
}
</script>


