<template>
    <div class="step-form-content-block row">
        <div class="col-xl-6">
            <asset-info-section
                title="Overview"
                :selected_fields="section_fields"
                :add-form="addForm"
                :required_fields="required_fields">
                <div class="step-common-info-block">
                    <div class="st--common--box" v-for="section_field in section_fields">
                        <div class="common-st-title-label">
                            <h5>{{ required_fields[section_field].name | titleize}}</h5>
                        </div>
                        <div class="st-status-radio-box" v-if="required_fields[section_field].type === 'radio-select'">
                            <div class="st-radio-row" v-if="required_fields[section_field].options.length > 0">
                                <div v-for="(option, index) in required_fields[section_field].options"
                                     :key="index"
                                     class="col-3">
                                    <div class="cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                        <label>
                                            <input
                                                v-model="addForm.item_details[section_field]"
                                                :key="index"
                                                :id="'_'+index"
                                                :name="section_field"
                                                :value="option"
                                                @click="changedRadioValue(section_field, option, $event)"
                                                type="radio" />
                                            <div class="st-radio-box">
                                                <span></span>
                                                <p>{{ (option.name || option.description) | titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="st-radio-row" v-else-if="required_fields[section_field].hasOwnProperty('name_plural') && required_fields[section_field].hasOwnProperty('link_name')">
                                <div class="col-12">
                                    There are no {{ required_fields[section_field].name_plural | titleize}} yet, <a :href="'/' + item_type + '/' + required_fields[section_field].link_name">Add New?</a>
                                </div>

                            </div>
                        </div>

                        <div class="common--input-filed" v-else-if="['text', 'number'].includes(required_fields[section_field].type)">
                            <input v-model="addForm.item_details[section_field]" placeholder="" :type="required_fields[section_field].type">
                        </div>
                        <div class="common--input-filed" v-else-if="required_fields[section_field].type === 'textarea'">
                            <textarea v-model="addForm.item_details[section_field]" :placeholder="required_fields[section_field].name | titleize"></textarea>
                        </div>
                    </div>

                </div>
            </asset-info-section>
        </div>
        <div class="col-xl-6">
            <div class="step-common-info-right-block">
                <div class="st-drop-file-block">
                    <div class="st-drop-file-box">
                        <vue-dropzone id="imagesgallery"
                                      ref="myVueDropzone"
                                      :use-custom-slot="true"
                                      :include-styling="false"
                                      :options="dropzoneOptions"
                                      @vdropzone-thumbnail="thumbnail"
                                      @vdropzone-file-added="fileAdded"
                                      @vdropzone-removed-file="fileRemoved"
                                      @vdropzone-max-files-exceeded="maxFilesExceeded"
                        >
                            <div class="dz-message" data-dz-message>
                                <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                    <div class="st-upload-title">
                                        <h3>Drop files here or click to upload</h3>
                                        <p>... or click to select a file from your computer</p>
                                    </div>
                                </div>
                            </div>
                        </vue-dropzone>
                        <b-button
                            @click="$refs.myVueDropzone.$el.click();"
                            class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                        >Add New Image</b-button>
                        <b-button
                            @click="resetDropZone"
                            variant="outline-danger"
                            class="btn btn-sm mt-1 btn-block"
                        >Remove All</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetInfoSection from "../../../assets/assetForm/AssetInfoSection";

export default {
    name: "OverviewStep",
    components: {AssetInfoSection},
    mixins:[dropzoneMixin],
    props:['addForm', 'required_fields', 'item_type'],
    data(){
        return {
            first_time: true,
            section_fields: ['acq_mode', 'external_ledger_reference', 'notes']
        }
    },
    mounted() {
        this.fillAssetImages();
    },
    computed: {
        asset_images_list() {
            return this.addForm.asset_images;
        },
    },
    watch: {
        asset_images_list:{
            deep: true,
            handler: function (newValue) {
                if (this.first_time){
                    this.fillAssetImages();
                    this.first_time = false;
                }
            }
        },
    },
    methods:{
        changedRadioValue(field, option, event) {

        },
        fillAssetImages() {
            if (this.addForm.asset_images.length > 0) {
                for (const assetImagesKey in this.addForm.asset_images) {
                    let asset_image = this.addForm.asset_images[assetImagesKey];
                    var file = {
                        id: asset_image.id,
                        size: asset_image.image_name_size,
                        name: asset_image.name,
                        type: "image/png",
                        is_featured: asset_image.is_featured,
                        add_to_ap: asset_image.add_to_ap
                    };
                    var url = asset_image.image_name;

                    if (asset_image.image_name_size) {
                        this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    }
                }
            }
        }
    }
}
</script>

<style>

#imagesgallery {
    font-family: sans-serif;
    cursor: pointer;
    transition: background-color .2s linear;
    min-height: 300px !important;
    border: 2px dashed #ced4da !important;
    background: #fff !important;
    border-radius: 6px !important;
}

#imagesgallery .dropzone-custom-content {
    font-size: 24px;
    color: #ced4da;
    margin: 3em 0 !important;
    text-align: center;
}

#imagesgallery .dz-preview {
    display: inline-block
}

#imagesgallery .dz-preview .dz-image {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-top: 20px;
}

#imagesgallery .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}

#imagesgallery .dz-preview .dz-image > img {
    width: 100%;
}

#imagesgallery .dz-preview .dz-details {
    color: inherit;
    transition: opacity .2s linear;
    text-align: center;
}

#imagesgallery .dz-success-mark, .dz-error-mark {
    display: none;
}

</style>
