<template>
    <div class="row cm_line_row mb-2" v-if="is_sp_or_cross_or_admin">
        <div class="col-12" v-if="!without_actions">
            <div>
                <b>Edit:</b>
                <br>
                <a href="javascript:void(0)" @click="performMSAction(order_line, 'edit_ms_asset', index)" class="btn btn-primary btn-sm af-accent">Asset</a> |
                <a href="javascript:void(0)" @click="performMSAction(order_line, 'edit_ms_images', index)" class="btn btn-primary btn-sm af-accent">Images</a> |
                <template v-if="hasPermission('add_asset_files')"><a href="javascript:void(0)" @click="performMSAction(order_line, 'add_ms_files', index)" class="btn btn-primary btn-sm af-accent">Add Files</a> |</template>
                <template v-if="hasPermission('can_update_status')"><a href="javascript:void(0)" @click="performMSAction(order_line, 'update_ms_status', index)" class="btn btn-primary btn-sm af-accent">Update Status</a> |</template>
                <template v-if="hasPermission('can_update_stocks')"><a href="javascript:void(0)" @click="performMSAction(order_line, 'update_ms_stocks', index)" class="btn btn-primary btn-sm af-accent">Update Stocks</a> |</template>
                <a href="javascript:void(0)" @click="performAction(order_line, 'add_intake_profile', index)" class="btn btn-primary btn-sm af-accent">Intake</a> |
                <a href="javascript:void(0)" @click="performMSAction(order_line, 'edit_ms_storage', index)" class="btn btn-primary btn-sm af-accent">Storage</a>
            </div>
        </div>
        <div class="col-12">
            <div>
                <b>Navigation:</b>
                <br>
                <span class="af-btn-span"><a :href="'/' + item_type + '/order_lines/move_to_storage/new' + getNextActionParams()" class="btn btn-primary btn-sm af-accent">Add Another Intake</a> |</span>
                <span class="af-btn-span" v-if="order_line.hasOwnProperty('managed_storage_info') && order_line.managed_storage_info"><a :href="'/' + item_type + '/managed_storages/'+order_line.item_id+'/view'" class="btn btn-primary btn-sm af-accent">View {{ order_line.managed_storage_info.uid }}</a> |</span>
                <span class="af-btn-span" v-if="order_line.hasOwnProperty('order_info') && order_line.order_info"><a :href="'/' + item_type + '/orders/'+order_line.order_id+'/view'" class="btn btn-primary btn-sm af-accent">View {{ order_line.order_info.uid }}</a></span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'OrderLineActionsBlock',
    props:['index', 'order_line', 'item_type', 'performAction', 'performMSAction', 'without_actions'],
    methods:{
        getNextActionParams(){
            let params = '';
            if (this.order_line.customer_id){
                params += '?customer_id=' + this.order_line.customer_id;
            }
            if (this.order_line.order_id){
                params += (params === '' ? '?' : '&') + 'order_id=' + this.order_line.order_id;
            }
            if (this.order_line.hasOwnProperty('sp_wsl_info') && this.order_line.sp_wsl_info){
                let sp_wsl_info = this.order_line.sp_wsl_info;
                if (sp_wsl_info.hasOwnProperty('storage_info') && sp_wsl_info.storage_info){
                    let storage_info = sp_wsl_info.storage_info;
                    if (storage_info){
                        params += (params === '' ? '?' : '&') + 'storage_id=' + storage_info.id;
                    }
                }
            }
            return params;
        },
    }
}
</script>
