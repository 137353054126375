<template>
    <Layout>
        <AddNewCharacter
            ref="add-new-character"
            item_type='character'
            modal_title="Create New Character"
            @on-new-item-adding="getList"
        >
        </AddNewCharacter>

        <SetMeasurements
            ref="set-measurements"
            item_type='measurements'
            modal_title="Set Measurements"
            @on-new-item-adding="getList"/>

        <div class="characters-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Character List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Characters.."></search-box>
                            <b-dropdown v-if="hasPermission('add_characters')" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item v-if="hasPermission('add_characters')"
                                                 @click="addNewItem('character')">Character
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                        <div class="grid-list-button d-flex align-items-center" style="float: right;">
                            <div
                                v-for="(layout, key) in supportedLayouts"
                                :key="key"
                                @click="changeObjectLayout(layout);"
                                :id="'grid-list-'+layout.name.toLowerCase()"
                                :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                            </div>
                        </div>

                    </div>

                    <div class="popup-gallery">
                        <vue-easy-lightbox
                            :visible="visibleCaption"
                            :imgs="captionimgs"
                            :index="index"
                            @hide="handleCaptionHide">
                        </vue-easy-lightbox>
                    </div>

                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Characters...</h4>
                    </div>
                    <div v-else-if="!charactersData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Characters found here at the moment.</h4>

                    </div>

                    <!-- Product Section Start-->
                    <div v-else-if="charactersData && selectedLayout === 0" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="object_type"
                                :keys_list="character_list_keys_list">
                                <template v-slot:characters_actions="{ item, index }">
                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">

                                            <b-dropdown-item v-if="hasPermission('edit_characters')"
                                                             @click="performAction(item, 'edit', index, $event)">
                                                Edit
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="hasPermission('edit_characters')"
                                                             @click="performAction(item, 'set_measurements', index, $event)">
                                                Set Measurements
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('delete_characters')"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete', index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>
                                </template>
                            </po-lines>
                        </div>
                    </div>
                    <div v-else-if="charactersData && selectedLayout === 1" class="cm_list_view_wrapper">
                        <CharacterListView
                            v-for="(character, characterKey) in sortedItemsList"
                            :key="characterKey"
                            :item_type="item_type"
                            :item_key="characterKey"
                            :item_info="character"
                            :show-captiom-img="showCaptiomImg"
                            :perform-action="performAction"
                        />
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";

import SearchBox from "@/components/search-box";
import AssetActions from "@/mixins/assetActions.mixin";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import AddNewCharacter from "../extras/AddNewCharacter";
import CharacterListView from "./CharacterListView";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import SetMeasurements from "./SetMeasurements";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

const characterResource = new Resource('api/assetflow/characters');


export default {
    name: 'CharactersList',
    mixins: [AssetActions, ImageGallery],
    props: ['item_id', 'item_type'],
    components: {
        LotsOfItemsAlert,
        SetMeasurements, CharacterListView, VueEasyLightbox, AddNewCharacter, PoLines, SearchBox, Layout},
    data() {
        return {
            character_list_keys_list:{

                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg-2 c_name",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<a href="./characters/' + item.id + '/view" class="text-dark">' + (`(${item.character_number}) ${item.name}`) + '</a>';
                    }
                },
                actor_name: {
                    key: 'actor_name',
                    label: "Actor Name",
                    class: "col-lg-4 c_actor_name",
                    show_on_mobile: true,
                    case: 'custom',
                    used_func: function (value){
                        return Vue.options.filters.titleize(value);
                    }
                },
                ledger_id: {
                    key: 'ledger_id',
                    label: "Ledger",
                    class: "col-lg-4 c_ledger_id",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return (item.hasOwnProperty('ledger_info') && item.ledger_info ? 'UID: ' + item.ledger_info.uid : '');
                    },
                    value_func: function (index, item, key) {
                        return item.hasOwnProperty('ledger_info') && item.ledger_info ? ('<a class="text-dark" href="./ledgers/' + item.ledger_info.id + '/view">' + item.ledger_info.nominal_code + ': ' + item.ledger_info.description + '</a>') : '-';
                    }
                },
            },
            listLoading: false,
            total: 0,
            charactersData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Characters",
            items: [
                {
                    text: "Characters",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'characters'
            },
            has_character_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            object_type: 'characters',
            selectedLayout: 0,
            supportedLayouts:{
                small_list: {
                    name: 'Smaller List',
                    value: 0,
                    class: 'smaller-list',
                    isActive: false
                },
                list: {
                    name: 'List',
                    value: 1,
                    class: 'list',
                    isActive: false
                },
            },


        };
    },
    created() {
        this.has_character_actions_permissions = this.hasPermission('edit_characters') || this.hasPermission('delete_characters');

        if (this.has_character_actions_permissions){
            this.character_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-2 c_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_class: '',
                slot_name: 'characters_actions'
            }
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_characters_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.character_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.character_preferred_sorting;
        }

        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_'+this.object_type + '_layout')) {
            this.selectedLayout = window.USER_INFO.user_meta['preferred_'+this.object_type + '_layout'];
        }

        this.getList();
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            this.set_usermeta('preferred_'+this.object_type + '_layout', layout.value, message);
            this.selectedLayout = layout.value
        },

        performAction(character, action, characterKey, event){
            if (action === 'edit'){
                this.$refs['add-new-character'].setSelectedItem(character);
                this.$bvModal.show('create-character');
            }else if (action === 'set_measurements'){
                this.$refs['set-measurements'].setSelectedItem(character);
                this.$bvModal.show('set-measurements');
            }else if (action === 'delete'){
                if (character.id !== ''){
                    this.deleteItemByID(character.id, character.name, 'characters', 'event')
                        .then((response) => {
                            if (response.data.data.status){
                                this.charactersData.splice(characterKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'name', 'actor_name'];

                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";

                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.charactersData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.charactersData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('character_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_characters_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery = this.addPaginationParams(this.listQuery)
            this.listQuery.is_short_list = true;

            const {data} = await characterResource.list(this.listQuery);
            this.total = data.meta.total;
            this.charactersData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem() {
            this.$refs['add-new-character'].setSelectedItem(null);
            this.$bvModal.show('create-character');
        }
    },
}
</script>


