<template>
    <div class="step_main_accordion_border" :class="'section-' + (title.replace(' ', '_').toLowerCase())">
        <div @click="handleShowingBlock" class="step-small-label">
            <p>{{ title | titleize }}</p>
            <div class="section-close-btn">
                <i class="bx font-size-24"
                   :class="toggle ? 'bx-x' : 'bx-plus'"></i>
            </div>
        </div>

        <div class="st_accordion">
            <transition name="fade" mode="out-in">
                <slot v-if="toggle"/>
                <div v-else-if="selected_fields">
                    <p v-for="selected_field in selected_fields" v-if="addForm[selected_field]">
                        {{ selected_field | titleize }}
                        <b>
                        {{ (addForm[selected_field].name) }}
                    </b></p>
                </div>
                <small v-else>{{ selected_value }}</small>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props:['title', 'selected_value', 'selected_fields', 'addForm'],
    name: "SettingInfoSection",
    data(){
        return {
            toggle: true
        }
    },
    methods: {
        handleShowingBlock(){
            this.toggle = !this.toggle;
        }
    }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
    transition: opacity .2s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

</style>
