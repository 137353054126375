const FirstPath = window.location.pathname.split('/')[1];
export const AcquisitionReceiptsKeysList = {

    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-2 a_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/acquisitions/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    acquired_date: {
        key: 'acquired_date',
        label: "Date",
        class: "col-lg-2 a_acquired_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    supplier_info: {
        key: 'supplier_info',
        label: "Supplier",
        class: "col-lg-2 a_supplier_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('supplier_name') && item.supplier_name !== ''){
                return '<a class="text-dark" href="/' + FirstPath + '/suppliers/'+item.supplier_id+'/view">' + item.supplier_name + '</a>';
            }
            return '-';
        }
    },
    buyer_id: {
        key: 'buyer_id',
        label: "Buyer",
        class: "col-lg-2 a_buyer_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('buyer_info') && item.buyer_info){
                return '<a class="text-dark" href="/' + FirstPath + '/users/'+item.buyer_info.id+'/view">' + item.buyer_info.name + '</a>';
            }
            return '-';
        }
    },

    net_value: {
        key: 'net_value',
        label: "Net",
        class: "col-lg-1 a_net_value",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: function () {},
        value_func: function () {}
    },

    tax_value: {
        key: 'tax_value',
        label: "Tax",
        class: "col-lg-1 a_tax_value",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: function () {},
        value_func: function () {}
    },
    all_items_value: {
        key: 'all_items_value',
        label: "Gross",
        class: "col-lg-1 a_all_items_value",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: function () {},
        value_func: function () {}
    },

};
