<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <add-new-purchaseOrder
            ref="add-new-purchaseOrder"
            item_type='purchaseOrder'
            :only_po="true"
            modal_title="Create New Purchase Order"
            @on-new-item-adding="addedNewPO"
        >
        </add-new-purchaseOrder>

        <add-new-modal
            ref="add-new-address"
            item_type='address'
            modal_title="Create New Address"
            @on-new-item-adding="addressLocationChange"
        ></add-new-modal>

        <add-new-modal
            ref="add-new-location"
            item_type='location'
            modal_title="Create New Location"
            @on-new-item-adding="addressLocationChange"
        ></add-new-modal>

        <div v-if="this.item_type === 'customer' && !this.user_info.has_current_customer">
            <b-alert show variant="danger">
                There's nothing to show, Please "
                <a href="/customer/switch">
                    Select Customer
                </a>
                " to proceed
            </b-alert>
        </div>

        <div v-else-if="this.item_type === 'sp' && !this.user_info.has_current_sp">
            <b-alert show variant="danger">
                There's nothing to show, Please "
                <a href="/sp/switch">
                    Select Service Provider
                </a>
                " to proceed
            </b-alert>
        </div>


        <div v-else class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <vue-form-wizard
                            ref="add-asset-wizard"
                            shape="tab"
                            :color="addressPercentage === 100 ? 'green' : wizard_colour"
                            :error-color="wizard_colour"
                            finish-button-text="Save"
                            @on-complete="onComplete">

                            <template slot="footer" slot-scope="props">
                                <div class="wizard-footer-left">
                                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button>
                                </div>
                                <div class="wizard-footer-right">
                                    <wizard-button v-if="!props.isLastStep"@click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>
                                </div>
                            </template>

                            <tab-content icon="bx bx-cube text-af-primary"
                                         title="Asset Info"
                                         :before-change="tabChanged">
                                <div class="card">
                                    <div class="card-header">Basic Information</div>
                                    <div class="card-body">
                                        <h4 class="card-title">Basic Information</h4>
                                        <p class="card-title-desc">Fill all information below</p>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div
                                                    v-for="(required_field, index) in required_fields"
                                                    v-if="basic_info_fields.includes(index)"
                                                    :key="index"
                                                    class="form-group">
                                                    <label v-if="required_field.has_permission && required_field.type !== 'toggle'" :for="'_'+required_field.name">{{ required_field.name | titleize }}</label>

                                                    <textarea
                                                        v-model="addForm.item_details[required_field.name]"
                                                        v-if="required_field.type === 'longtext'"
                                                        :id="index"
                                                        :name="index"
                                                        class="form-control"
                                                        rows="3"
                                                    ></textarea>


                                                    <div v-else-if="required_field.type === 'radio'" class="status-input">
                                                        <b-form-radio-group>
                                                            <b-form-radio
                                                                v-for="(option, key) in required_field.options"
                                                                v-model="addForm.single_select_v_models[required_field.name]"
                                                                :key="key"
                                                                :id="key+''"
                                                                :name="required_field.name"
                                                                :value="option"
                                                                class="custom-radio mb-3"
                                                                @input="errors_list[required_field.name] = ($event === '');"
                                                            >{{ option.name | titleize }}
                                                            </b-form-radio>

                                                            <span v-show="errors_list[required_field.name]" class="help-block text-danger">{{ required_field.name | titleize }} is required.</span>
                                                        </b-form-radio-group>

                                                    </div>

                                                    <b-form-checkbox
                                                        v-else-if="required_field.type === 'toggle'"
                                                        v-model="addForm.item_details[required_field.name]"
                                                        :id="index"
                                                        :name="index"
                                                        class="custom-checkbox-af-accent mb-3"
                                                    >{{ required_field.msg | titleize }}
                                                    </b-form-checkbox>

                                                    <input
                                                        v-else-if="required_field.name === 'quantity'"
                                                        v-model="addForm.item_details.quantity"
                                                        :readonly="quantity_disabled"
                                                        v-mask="'#######'"
                                                        :id="index"
                                                        :name="index"
                                                        type="text"
                                                        class="form-control" />

                                                    <input
                                                        v-else-if="required_field.type === 'text' || required_field.type === 'number'"
                                                        v-model="addForm.item_details[required_field.name]"
                                                        :id="index"
                                                        :name="index"
                                                        :type="required_field.type"
                                                        class="form-control" />

                                                    <multiselect
                                                        v-else-if="required_field.type === 'single-select'"
                                                        v-model="addForm.single_select_v_models[required_field.name]"
                                                        track-by="id"
                                                        label="name"
                                                        class="mySingleSelect"
                                                        :allow-empty="false"
                                                        deselect-label=""
                                                        :options="required_field.options">
                                                        <span slot="noResult">No data found.</span>
                                                    </multiselect>


                                                </div>

                                            </div>
                                            <div class="col-sm-6">

                                                <vue-dropzone id="imagesgallery"
                                                              ref="myVueDropzone"
                                                              :use-custom-slot="true"
                                                              :include-styling="false"
                                                              :options="dropzoneOptions"
                                                              @vdropzone-thumbnail="thumbnail"
                                                              @vdropzone-file-added="fileAdded"
                                                              @vdropzone-max-files-exceeded="maxFilesExceeded"
                                                >
                                                    <div v-if="addForm.asset_images.length == 0" class="dropzone-custom-content">
                                                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                        <h3 class="dropzone-custom-title">Drop files here or click to upload</h3>
                                                        <div class="subtitle">...or click to select a file from your computer</div>
                                                    </div>
                                                    <div v-else ></div>
                                                </vue-dropzone>
                                                <b-button
                                                    @click="$refs.myVueDropzone.$el.click();"
                                                    class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                                >Add New Image</b-button>
                                                <b-button
                                                    @click="resetDropZone"
                                                    variant="outline-danger"
                                                    class="btn btn-sm mt-1 btn-block"
                                                >Remove All</b-button>

                                            </div>
                                            <!-- end col -->
                                        </div>
                                        <!-- end row -->
                                    </div>
                                </div>

                                <div v-if="sectors_list" class="row">
                                    <div v-if="is_tv_film_asset" class="col-12">
                                        <div class="card">
                                            <div class="card-header">TV & Film</div>
                                            <div class="card-body">
                                                <h4 class="card-title">TV & Film Information</h4>
                                                <p class="card-title-desc">Fill all TV & Film information below</p>

                                                <div
                                                    v-for="(field_key, index) in tv_film_fields"
                                                    :key="index"
                                                    class="form-group">

                                                    <label v-if="field_key === 'asset_usage' && required_fields[field_key].has_permission"
                                                           :for="'_'+required_fields[field_key].name">
                                                        {{ required_fields[field_key].name | titleize }}
                                                    </label>


                                                    <b-form-radio-group
                                                        v-if="field_key === 'asset_usage'"
                                                        v-model="addForm.asset_meta.asset_usage">
                                                        <b-form-radio
                                                            v-for="option in asset_usageList"
                                                            :key="option.id"
                                                            :value="option.id"
                                                            class="custom-radio mb-3"
                                                        >{{ option.name | titleize }}
                                                        </b-form-radio>
                                                    </b-form-radio-group>

                                                    <b-form-checkbox
                                                        v-else
                                                        v-model="addForm.asset_meta[field_key]"
                                                        :id="field_key"
                                                        :name="field_key"
                                                        class="custom-checkbox-af-accent mb-3"
                                                    >{{ required_fields[field_key].msg | titleize }}
                                                    </b-form-checkbox>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="is_demo_industry_asset" class="col-12">
                                        <div class="card">
                                            <div class="card-header">Demo Industry</div>
                                            <div class="card-body">
                                                <h4 class="card-title">Demo Industry Information</h4>
                                                <p class="card-title-desc">All Demo Industry information can be listed below</p>

                                                <div
                                                    v-for="(field_key, index) in demo_industry_fields"
                                                    :key="index"
                                                    class="form-group">

                                                    <label v-if="required_fields[field_key].has_permission"
                                                           :for="'_'+required_fields[field_key].name">
                                                        {{ required_fields[field_key].name | titleize }}
                                                    </label>

                                                    <input
                                                        v-if="['text', 'number'].includes(required_fields[field_key].type)"
                                                        v-model="addForm.asset_meta[field_key]"
                                                        :id="field_key"
                                                        :name="field_key"
                                                        :type="required_fields[field_key].type"
                                                        class="form-control" />

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </tab-content>

                            <tab-content icon="bx bx-hive text-af-primary"
                                         title="Properties"
                                         :before-change="tabChanged">
                                <div class="card">
                                    <div class="card-header">Physical Properties</div>
                                    <div class="card-body">
                                        <h4 class="card-title">Physical Properties</h4>
                                        <p class="card-title-desc">Fill all physical properties below</p>

                                        <div
                                            v-for="(field_key, index) in properties_fields"
                                            :key="index"
                                            class="form-group">
                                            <label v-if="required_fields[field_key] && required_fields[field_key].has_permission" :for="'_'+required_fields[field_key].name">{{ required_fields[field_key].name | titleize }}</label>

                                            <div v-if="field_key === 'dimensions-wizard'">
                                                <p class="dimensions-title"><label>Dimensions</label></p>
                                                <p class="dimensions-desc">
                                                    <strong>Think Sustainability</strong>. Knowing dimensions helps later with logistics, storage and sustainability planning.
                                                </p>

                                                <div class="btn-group btn-group-example" role="group">
                                                    <b-button
                                                        v-for="(dimensions_type, key) in dimensions_types"
                                                        :key="key"
                                                        @click="changeDimensionsType(dimensions_type.name)"
                                                        :variant="dimensions_type.variant"
                                                        :class="'w-sm' + (selected_dimensions_type === dimensions_type.name ? ' active' : '')">
                                                        {{ dimensions_type.name | titleize}}
                                                    </b-button>
                                                </div>


                                                <div v-if="selected_dimensions_type === 'manual'" class="row m-3">
                                                    <div class="col-2">
                                                        Prefarred Unit
                                                    </div>
                                                    <div class="col-2">
                                                        <multiselect
                                                            v-model="preferred_dimension_unit"
                                                            :options="dimension_units"
                                                            track-by="id"
                                                            label="name"
                                                            :allow-empty="false"
                                                            :show-labels="false"
                                                            placeholder="Select a unit"
                                                        ></multiselect>
                                                    </div>
                                                </div>

                                                <div v-if="selected_dimensions_type === 'manual'">
                                                    <div class="row"
                                                         v-for="(dimension_field, key) in dimension_fields"
                                                         :key="key">
                                                        <div v-if="required_fields[dimension_field].has_permission" class="col-6">

                                                            <div class="input-group m-1">
                                                                <div class="input-group-prepend">
                                                                    <label :for="'_'+dimension_field" class="input-group-text" style="width:80px">{{ dimension_field | titleize }}</label>
                                                                </div>
                                                                <input
                                                                    v-model="addForm.item_details[dimension_field]"
                                                                    :aria-describedby="dimension_field"
                                                                    :id="'_'+dimension_field"
                                                                    :name="'_'+dimension_field"
                                                                    :readonly="dimension_field === 'volume'"
                                                                    @input="changeVolume"
                                                                    :type="dimension_field === 'volume' ? 'text' : 'number'"
                                                                    :placeholder="dimension_field === 'volume' ? 'Please enter height, width & depth' : ''"
                                                                    :class="'form-control ' + dimension_field + '-input'" />
                                                                <div class="input-group-append">
                                                                    <span :id="dimension_field" class="input-group-text">
                                                                        {{ dimension_field !== 'volume' ? preferred_dimension_unit.name : 'm' }}
                                                                        <sup v-if="dimension_field === 'volume'">3</sup>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-else-if="selected_dimensions_type === 'auto'"
                                                     class="row">
                                                    <div class="col-12 mt-3">
                                                        <p><strong>Challenge</strong>. Click the image that is closest in physical size to your item</p>
                                                    </div>


                                                    <div
                                                        v-for="(auto_dimension_item, key) in auto_dimensions_list"
                                                        :key="'d_'+auto_dimension_item.id"
                                                        class="text-center col-xl-2">
                                                        <img :alt="auto_dimension_item.name"
                                                             height="150"
                                                             :src="auto_dimension_item.image"
                                                             data-holder-rendered="true"
                                                             class="rounded"
                                                             @click="selected_auto_dimensions = auto_dimension_item"
                                                        >

                                                        <b-form-radio
                                                            v-model="selected_auto_dimensions"
                                                            :id="key + '_' + auto_dimension_item.id"
                                                            :value="auto_dimension_item"
                                                            name="auto_dimensions"
                                                            class="custom-radio mt-3 mb-3 text-center"
                                                        >
                                                            <span class="my-2">{{ auto_dimension_item.name  | titleize }}</span>
                                                        </b-form-radio>
                                                    </div>

                                                    <div class="col-12 mb-2">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <label for="_volume_auto" class="input-group-text" style="width:80px">Volume</label>
                                                            </div>
                                                            <input
                                                                v-model="addForm.item_details.volume"
                                                                aria-describedby="_volume_auto_desc"
                                                                id="_volume_auto"
                                                                name="_volume_auto"
                                                                readonly
                                                                type="text"
                                                                placeholder="Click an Image"
                                                                class="form-control col-4" />
                                                            <div class="input-group-append">
                                                                <span id="_volume_auto_desc" class="input-group-text">
                                                                    m<sup>3</sup>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div v-else-if="['colours', 'composition'].includes(field_key)" class="row">
                                                <div :class="'text-center col-xl-' + (field_key === 'colours' ? '1' : '3')"
                                                     v-for="(colour, key) in required_fields[field_key].options"
                                                     :key="key">

                                                    <div v-if="field_key === 'colours'"
                                                         class="color-box mt-2 p-2 rounded"
                                                         :style="'background-color:' + colour.hex"
                                                         @click="toggleColour(colour, field_key)"
                                                    >
                                                    </div>
                                                    <img v-else
                                                         :alt="colour.name"
                                                         height="150"
                                                         :src="colour.image"
                                                         data-holder-rendered="true"
                                                         class="rounded"
                                                         @click="toggleColour(colour, field_key)"
                                                    >

                                                    <b-form-checkbox
                                                        v-model="addForm.multi_select_v_models[field_key]"
                                                        :id="field_key + '_' + colour.id"
                                                        :value="colour"
                                                        class="custom-checkbox-af-accent mt-3 mb-3 text-center"
                                                    >
                                                        <span class="my-2">{{ colour.name  | titleize }}</span>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>

                                            <multiselect
                                                v-else-if="required_fields[field_key].type === 'single-select'"
                                                v-model="addForm.single_select_v_models[field_key]"
                                                track-by="id"
                                                label="name"
                                                class="mySingleSelect"
                                                :options="required_fields[field_key].options"
                                                :allow-empty="false"
                                                deselect-label=""
                                            >
                                                <span slot="noResult">No data found.</span>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->


                                <div v-if="asset_type_fields.length !== 0" class="card">
                                    <div class="card-header">Asset Type</div>
                                    <div class="card-body">
                                        <h4 class="card-title">Asset Type Properties</h4>
                                        <p class="card-title-desc">Fill all asset type properties below</p>

                                        <div
                                            v-for="(field_key, index) in asset_type_fields"
                                            :key="index"
                                            class="form-group">
                                            <multiselect
                                                v-if="field_key === 'asset_type' && required_fields[field_key].options.length !== 0"
                                                v-model="addForm.asset_meta.asset_type"
                                                track-by="id"
                                                label="name"
                                                class="mySingleSelect"
                                                :options="required_fields[field_key].options"
                                                :allow-empty="false"
                                                deselect-label=""
                                            >
                                                <span slot="noResult">No data found.</span>
                                            </multiselect>
                                        </div>

                                        <div v-if="selected_asset_type_keys && selected_asset_type_keys.length !== 0"
                                             v-for="(assetType_meta, assetType_metaKey) in selected_asset_type_keys"
                                             class="form-group">

                                            <label :for="'_'+assetType_metaKey">{{ assetType_meta.name | titleize }}</label>

                                            <input
                                                v-if="['text', 'number'].includes(assetType_meta.type)"
                                                v-model="addForm.asset_meta[assetType_metaKey]"
                                                :id="assetType_metaKey"
                                                :name="assetType_metaKey"
                                                :type="assetType_meta.type"
                                                class="form-control" />

                                            <multiselect
                                                v-else-if="assetType_meta.options && assetType_meta.options.length !== 0"
                                                v-model="addForm.asset_meta[assetType_metaKey]"
                                                track-by="id"
                                                label="name"
                                                class="mySingleSelect"
                                                :options="assetType_meta.options"
                                                :allow-empty="false"
                                                deselect-label=""
                                            >
                                                <span slot="noResult">No data found.</span>
                                            </multiselect>

                                        </div>

                                    </div>
                                </div>


                            </tab-content>

                            <tab-content
                                v-if="addForm.single_select_v_models.status && addForm.single_select_v_models.status.id !== 'planned'"
                                icon="bx bx-map-pin text-af-primary"
                                :title="'Location ' + addressPercentage + '%'"
                                class="address-tab-content">
                                <div v-if="addressPercentage === 100" class="row completed-address-info">
                                    <div class="col-12 text-center">
                                        <h3><i class="bx bx-check-double"></i> well done!</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">

                                        <div
                                            v-for="(field_key, index) in address_info_fields"
                                            :key="index"
                                            class="form-group">
                                            <label v-if="required_fields[field_key].has_permission" :for="'_'+required_fields[field_key].name">{{ required_fields[field_key].name | titleize }}</label>

                                            <multiselect
                                                v-model="addForm.single_select_v_models[field_key]"
                                                track-by="id"
                                                label="name"
                                                class="mySingleSelect"
                                                :options="required_fields[field_key].options"
                                                :disabled="field_key === 'location_id' && !addForm.single_select_v_models.address_id"
                                                @input="changedAddressInfo(field_key, $event)"
                                                :allow-empty="false"
                                                deselect-label=""
                                            >
                                                <span slot="noResult">No data found.</span>
                                            </multiselect>

                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </tab-content>

                            <tab-content
                                v-if="addForm.single_select_v_models.status && ['acquired', 'active'].includes(addForm.single_select_v_models.status.id)"
                                icon="fas fa-handshake text-af-primary font-size-18"
                                title="Acquisition"
                                class="acquisition-tab-content">

                                <div class="row">
                                    <div class="col-12">

                                        <div class="row"
                                             v-for="(acquisition_field, key) in acquisition_fields"
                                             :key="key">
                                            <div v-if="required_fields[acquisition_field].has_permission" class="col-6">

                                                <div v-if="acquisition_field === 'used_currency'" class="input-group m-1">
                                                    <label for="_currency">Currency</label>
                                                    <multiselect
                                                        v-model="addForm.single_select_v_models.used_currency"
                                                        id="_currency"
                                                        track-by="value"
                                                        label="text"
                                                        :custom-label="currencyWithIcon"
                                                        class="mySingleSelect"
                                                        :options="currencies_list"
                                                        :allow-empty="false"
                                                        :show-labels="false"
                                                        placeholder="Select Currency"
                                                    >
                                                        <span slot="noResult">No data found.</span>
                                                    </multiselect>

                                                </div>
                                                <div v-else-if="acquisition_field === 'value_type'" class="input-group m-1">
                                                    <b-form-radio-group v-model="value_type">
                                                        <b-form-radio
                                                            v-for="option in (addForm.item_details.quantity !== '1' ? value_type_options : [value_type_options[0]])"
                                                            :key="option.id"
                                                            :value="option.id"
                                                            class="custom-radio mb-3"
                                                        >{{ getValueTypeTitle(option) | titleize }}
                                                        </b-form-radio>
                                                    </b-form-radio-group>

                                                </div>

                                                <div v-else-if="required_fields[acquisition_field].type === 'single-select'"
                                                     class="input-group m-1">
                                                    <label :for="'_'+acquisition_field">{{ (acquisition_field === 'supplier_id' ? 'Supplier' : acquisition_field)  | titleize }}</label>
                                                    <multiselect
                                                        v-if="acquisition_field === 'po_id'"
                                                        v-model="addForm.single_select_v_models.po_id"
                                                        track-by="id"
                                                        label="name"
                                                        placeholder="Select Purchase Orders"
                                                        class="mySingleSelect"
                                                        :custom-label="POWithUIDs"
                                                        :loading="isLoadingPOs"
                                                        :options="required_fields.po_id.options"
                                                        @input="changedAcquisitionField('po_id', $event)"
                                                        :allow-empty="false"
                                                        deselect-label=""
                                                    >
                                                        <span slot="noResult">No data found.</span>
                                                    </multiselect>
                                                    <multiselect
                                                        v-else
                                                        v-model="addForm.single_select_v_models[acquisition_field]"
                                                        track-by="id"
                                                        label="name"
                                                        :placeholder="'Select ' + (acquisition_field) | titleize"
                                                        class="mySingleSelect"
                                                        :options="required_fields[acquisition_field].options"
                                                        @input="changedAcquisitionField(acquisition_field, $event)"
                                                        :allow-empty="false"
                                                        deselect-label=""
                                                    >
                                                        <span slot="noResult">No data found.</span>
                                                    </multiselect>

                                                </div>
                                                <div v-else class="input-group m-1">
                                                    <div class="input-group-prepend">
                                                        <label :for="'_'+acquisition_field" class="input-group-text" style="width:100px">{{ acquisition_field | titleize }}</label>
                                                    </div>
                                                    <input
                                                        v-model="addForm.item_details[acquisition_field]"
                                                        :aria-describedby="acquisition_field"
                                                        :id="'_'+acquisition_field"
                                                        :name="'_'+acquisition_field"
                                                        :readonly="handleValueReadonly(acquisition_field)"
                                                        @input="handleValueChanging"
                                                        :type="handleValueReadonly(acquisition_field) ? 'text' : 'number'"
                                                        :class="'form-control ' + acquisition_field + '-input'" />
                                                    <div class="input-group-append">
                                                        <span :id="acquisition_field" class="input-group-text">
                                                            {{ addForm.single_select_v_models.used_currency ? addForm.single_select_v_models.used_currency.icon : '' }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </tab-content>

                        </vue-form-wizard>

                        <a v-on:click="addNew(false, $event)" class="btn af-btn-accent af-accent">Save & View</a>
                        <a v-on:click="addNew(true, $event)" class="btn btn-outline-success">Save & Add Another</a>
                        <a href="./" class="btn btn-secondary">Cancel</a>
                    </div>
                </div>

            </div>
        </div>
    </Layout>
</template>



<script>

import UserMeta from "@/mixins/usermeta.mixin";

import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect';
import vue2Dropzone from "vue2-dropzone";
import {FormWizard, TabContent, WizardButton,  WizardStep} from "vue-form-wizard";

import Resource from '@/api/resource';
import AddNewModal from "../customers/extras/AddNewModal";

import {AutoDimensionsList} from "../form_data/AutoDimensionsList";
import {Currencies} from "../form_data/Currencies";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import AddNewPurchaseOrder from "../customers/extras/AddNewPurchaseOrder";
const assetResource = new Resource('api/assetflow/assets');

const purchaseOrderResource = new Resource('api/assetflow/purchaseOrders');


export default {
    props:['item_id', 'item_type', 'action'],
    mixins: [UserMeta, TotalsAndCurrencies],
    name: 'NewAsset',
    components: {
        AddNewPurchaseOrder,
        WizardButton,
        AddNewModal,
        VueFormWizard: FormWizard,
        TabContent,
        Layout,
        PageHeader,
        Multiselect,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            listQuery: {
                page: 1,
                limit: 100
            },
            sectors_list:[],
            is_tv_film_asset: false,
            is_demo_industry_asset: false,
            errors_list: {
                status: false,
            },
            asset_usageList:[
                {
                    id: 0,
                    name: 'Off Camera'
                },
                {
                    id: 1,
                    name: 'On Camera'
                }
            ],
            conditionsList:[
                { id: 4, name: 4 },
                { id: 3, name: 3 },
                { id: 2, name: 2 },
                { id: 1, name: 1 },
            ],
            dropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.template()
            },
            lists_values: null,
            form_lists: {
                colours: null,
                status: null,
                composition: null,
                product_id: null,
                folder_id: null,
                category_id: null,
                address_id: null,
                location_id: null,
                contact_id: null,
                tag_ids: null,
                condition: null,
                supplier_id: null
            },
            basic_info_fields:[
                'name', 'description', 'is_unique', 'quantity', 'internal_reference', 'status'
            ],
            address_info_fields:[
                'address_id', 'location_id'
            ],
            properties_fields:[
                'category_id', 'dimensions-wizard', 'colours', 'composition'
            ],
            dimension_fields:[
                'height', 'width', 'depth', 'volume'
            ],
            acquisition_fields:[
                'acquisition_type', 'acquired_using', 'supplier_id', 'po_id',
                'used_currency', 'value_type', 'value', 'total_value'
            ],
            tv_film_fields:[
                'asset_usage', 'is_hero', 'is_asset'
            ],
            demo_industry_fields:[
                'demo_field'
            ],
            asset_type_fields:[
                'asset_type'
            ],
            value_type: null,
            value_type_options:[
                {
                    id: 0,
                    name: 'Value for 1 item'
                },
                {
                    id: 1,
                    name: 'Value for (x) items'
                }
            ],
            required_fields: {
                name: {
                    name: 'name',
                    type: 'text',
                    has_permission: true,
                    default_value: ''
                },
                description: {
                    name: 'description',
                    type: 'longtext',
                    has_permission: true,
                    default_value: ''
                },
                is_unique: {
                    name: 'is_unique',
                    type: 'toggle',
                    has_permission: true,
                    default_value: false,
                    msg: 'Is Unique?'
                },
                quantity: {
                    name: 'quantity',
                    type: 'number',
                    has_permission: true,
                    default_value: '1'
                },
                internal_reference: {
                    name: 'internal_reference',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                status: {
                    name: 'status',
                    type: 'radio',
                    has_permission: true,
                    default_value: 'planned',
                    options: []
                },
                address_id: {
                    name: 'address',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                location_id: {
                    name: 'location',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                asset_type: {
                    name: 'asset_type',
                    type: '',
                    has_permission: true,
                    default: '',
                    options: []
                },
                category_id: {
                    name: 'category_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                colours: {
                    name: 'colours',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                composition: {
                    name: 'composition',
                    type: 'multi-select',
                    has_permission: true,
                    default: '',
                    options: []
                },

                width: {
                    name: 'width',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                height: {
                    name: 'height',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                depth: {
                    name: 'depth',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                volume: {
                    name: 'volume',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                acquisition_type: {
                    name: 'acquisition_type',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: [
                        { id: 0, name: 'Buy' }
                    ]
                },
                acquired_using: {
                    name: 'acquired_using',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: [
                        { id: 0, name: 'Purchase Order' }
                    ]
                },
                supplier_id: {
                    name: 'supplier_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                po_id: {
                    name: 'po_id',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                value: {
                    name: 'value',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                total_value: {
                    name: 'total_value',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                value_type: {
                    name: 'value_type',
                    type: 'radio',
                    has_permission: true,
                    default_value: 0
                },
                used_currency:{
                    name: 'Currency',
                    type: 'single-select',
                    has_permission: true
                },
                asset_usage: {
                    name: 'asset_usage',
                    type: 'toggle',
                    has_permission: true,
                    default: '',
                    options: []
                },
                is_hero: {
                    name: 'is_hero',
                    type: 'toggle',
                    has_permission: true,
                    default: '',
                    msg: 'Is Hero?'
                },
                is_asset: {
                    name: 'is_asset',
                    type: 'toggle',
                    has_permission: true,
                    default: '',
                    msg: 'Is Asset?'
                },
                demo_field: {
                    name: 'demo_field',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
            },
            title: "Add New Asset",
            items: [
                {
                    text: "Assets",
                    href: "/"
                },
                {
                    text: "Add Asset",
                    active: true
                }
            ],
            customer_value: null,
            customers: [],
            isLoadingCustomers: false,
            asset_images_previewElements: {},
            quantity_disabled: false,
            addForm:{
                newww: null,
                assign_to_sp: false,
                item_details: {},
                asset_meta: {},
                asset_images: [],
                single_select_v_models: {
                    status: null,
                    address_id: null,
                    location_id: null,
                    category_id: null,
                    asset_type: null,
                    used_currency: null,
                    supplier_id: null,
                    po_id: null,
                    acquisition_type: null,
                    acquired_using: null,
                },
                multi_select_v_models: {
                    colours: [],
                    composition: [],
                }
            },
            isLoadingItem: false,
            user_info: {
                has_current_customer: false,
                has_current_sp: false
            },
            wizard_colour: '#556ee6',
            selected_dimensions_type: 'auto',
            dimensions_types:[
                {
                    name: 'auto',
                    variant: 'outline-success'
                },
                {
                    name: 'manual',
                    variant: 'outline-primary'
                }
            ],
            preferred_dimension_unit: null,
            dimension_units:[
                {id: 1000, name: 'mm'},
                {id: 100, name: 'cm'},
                {id: 1, name: 'm'},
            ],
            selected_auto_dimensions: null,
            auto_dimensions_list: AutoDimensionsList,
            currencies_list: Currencies,
            isLoadingPOs: false,
            meta_keys_for_acquisition:['acquisition_type', 'acquired_using', 'supplier_id', 'po_id']
        };
    },
    mounted(){
        this.activateAllWizardTabs();
    },
    created() {

        this.quantity_disabled = this.action === 'edit' && this.item_id !== '';

        this.selected_dimensions_type = window.USER_INFO.user_meta.dimensions_type ?? 'auto';
        this.preferred_dimension_unit = window.USER_INFO.user_meta.preferred_dimension_unit ?? this.dimension_units[0];

        this.value_type = this.value_type_options[0].id;

        this.wizard_colour = window.COLOUR_PLATE.accent_colour ? window.COLOUR_PLATE.accent_colour : '';

        this.form_lists = this.$root.form_lists;
        this.lists_values = {
            status: this.form_lists.status_list,
            address_id: this.form_lists.addresses_list,
            location_id: this.form_lists.locations_list,
            category_id: this.form_lists.categories_list,
            colours: this.form_lists.colours_list,
            composition: this.form_lists.compositions_list,
            asset_type: this.form_lists.assetTypes_list,
            supplier_id:  this.form_lists.suppliers_list
        }


        for (let key in this.lists_values){
            this.getOptionsForKey(key);
        }

        if (this.lists_values.asset_type.length === 0){
            this.asset_type_fields = [];
        }


        this.user_info = window.USER_INFO ?? this.user_info;


        let current_customer = this.user_info.current_customer;
        this.sectors_list = [];
        if (current_customer){
            this.sectors_list = current_customer.sector_ids;
        }
        this.is_tv_film_asset = this.sectors_list.includes('1');
        this.is_demo_industry_asset = this.sectors_list.includes('2');

        if(this.action === 'edit' && this.item_id !== ''){
            this.title = this.title.replace('Add New', 'Edit');
            this.items[1].text = this.items[1].text.replace('Add', 'Edit');
            this.fetchInfoOf();
        }else{
            this.setDefaults();
        }
    },
    computed: {
        selected_asset_type_keys(){
            return this.addForm.asset_meta.asset_type ? this.lists_values.asset_type[this.addForm.asset_meta.asset_type.id].meta_values : [];
        },
        current_status() {
            return this.addForm.single_select_v_models.status;
        },
        is_unique_asset() {
            return this.addForm.item_details.is_unique;
        },
        asset_quantity() {
            return this.addForm.item_details.quantity;
        },
        addressPercentage() {
            let total_values = this.address_info_fields.length;
            var completed_values = 0;
            for (const key in this.address_info_fields) {
                let field = this.address_info_fields[key];
                if (this.addForm.single_select_v_models[field]){
                    completed_values += 1;
                }
            }

            return completed_values !== 0 ? (completed_values / total_values) * 100 : 0;
        }
    },
    methods: {
        activateAllWizardTabs(){
            this.$refs['add-asset-wizard'].activateAll();
        },
        handleValueReadonly(acquisition_field){
            return ((acquisition_field === 'total_value' && this.value_type === 0) || (acquisition_field === 'value' && this.value_type === 1));
        },
        handleValueChanging() {
            let item_details = this.addForm.item_details,
                quantity = item_details.quantity,
                value = item_details.value,
                total_value = item_details.total_value,
                currency = item_details.used_currency;

            if ((!value && !total_value) || (value + '' + total_value) === ''){
                return;
            }

            if (this.value_type === 0) {
                // for one item!
                this.addForm.item_details.total_value = value * quantity;
            } else {
                // for all
                this.addForm.item_details.value = total_value / quantity
            }
        },
        getValueTypeTitle(option){
            if (option.id === 1){
                return option.name.replace('(x)', this.addForm.item_details.quantity);
            }
            return option.name
        },
        setItemDimensions(selected_dimensions) {
            let current_item_details = this.addForm.item_details;
            current_item_details.width = selected_dimensions.width;
            current_item_details.height = selected_dimensions.height;
            current_item_details.depth = selected_dimensions.depth;
            this.addForm.item_details = current_item_details;
            this.changeVolume();
        },

        reformatNumbers(number){
            let new_value = number ? parseFloat((number+"").replace(',', '')) : '';
            return new_value !== '' ? new_value / this.preferred_dimension_unit.id : '';
        },
        changeVolume(){
            let item_details = this.addForm.item_details,
                width = this.reformatNumbers(item_details.width),
                height = this.reformatNumbers(item_details.height),
                depth = this.reformatNumbers(item_details.depth);

            if (width === '' && height === '' && depth === ''){
                return;
            }


            this.addForm.item_details.volume = (width * height * depth).toFixed(2);
        },
        changeDimensionsType(dimensions_type_name){
            this.selected_dimensions_type = dimensions_type_name;
            this.set_usermeta('dimensions_type', dimensions_type_name, 'Saved Successfully');
        },
        toggleColour(colour, field_key){
            let index = this.addForm.multi_select_v_models[field_key].map(function(e) { return e.id; }).indexOf(colour.id);
            if (index > -1){
                // found, remove it!
                this.addForm.multi_select_v_models[field_key].splice(index, 1);
            }else{
                // not found, add it
                this.addForm.multi_select_v_models[field_key].push(colour);
            }
        },
        getOptionsForKey(key) {
            let options_list = this.lists_values[key];
            if (key === 'asset_type'){
                options_list = this.getDictValues(options_list, key);
            }

            if (options_list) {
                let add_new_option = [];
                if (['address_id', 'location_id'].includes(key)) {
                    add_new_option.push(
                        {
                            id: -99,
                            name: 'Add New'
                        }
                    )
                }

                this.required_fields[key].options = add_new_option.concat(options_list);
            } else {
                this.required_fields[key].options = [];
            }
        },
        reset_default_items(item_id_name, new_option, default_value, default_text='') {
            // shall reset other primaries
            let new_options = this.required_fields[item_id_name].options.map(item => {
                if (item[default_value]){
                    item[default_value] = false;
                    item.name = item.name.replace(default_text, '');
                }
                return item;
            });
            this.required_fields[item_id_name].options = new_options;
        },
        addressLocationChange(new_item, item_type){
            if (new_item){
                // should append this to the list
                let item_type_key = item_type+'_id';
                let new_option = {id: new_item.id, name: new_item.name};

                if (item_type === 'address'){
                    new_option.is_default = new_item.is_default;
                    if (new_option.is_default){
                        let default_text = ' <= Default';
                        new_option.name = new_option.name + default_text;

                        this.reset_default_items(item_type_key, new_option, 'is_default', default_text);
                    }

                    if (new_item.locations){
                        let new_location = new_item.locations[0];
                        let new_location_option = {id: new_location.id, name: new_location.name, is_primary: new_location.is_primary, parent_address: {id:new_item.id}};

                        if (new_location_option.is_primary){
                            let default_text = ' <= Primary';
                            new_location_option.name = new_location_option.name + default_text;
                        }
                        this.required_fields.location_id.options = [
                            { id: -99, name: 'Add New' },
                            new_location_option
                        ];
                        this.addForm.single_select_v_models.location_id = new_location_option;
                    }

                }else if (item_type === 'location'){
                    new_option.is_primary = new_item.is_primary;
                    if (new_option.is_primary){
                        let default_text = ' <= Primary';
                        new_option.name = new_option.name + default_text;
                        this.reset_default_items(item_type_key, new_option, 'is_primary', default_text);
                    }

                }

                this.required_fields[item_type_key].options.push(new_option);
                this.addForm.single_select_v_models[item_type_key] = new_option;

            }
        },
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        addedNewPO(new_item){
            if (new_item.supplier_id){
                this.required_fields.po_id.options.push(new_item);
                this.addForm.single_select_v_models.po_id = new_item;
            }
        },
        async getPOsListFor(supplier_id, selected_po_id) {
            this.isLoadingPOs = true;
            this.handleQueryItems();
            this.listQuery.supplier_id = supplier_id;
            this.listQuery.only_ids = true;
            const {data} = await purchaseOrderResource.list(this.listQuery);
            let add_new_option = [{
                id: -99,
                name: 'Add New'
            }];

            let pos_list = data.data.items;
            this.required_fields.po_id.options = add_new_option.concat(pos_list);

            if (selected_po_id){
                let po_options = this.required_fields.po_id.options;
                let selected_po_index = po_options.map(function(e) { return e.id; }).indexOf(selected_po_id);
                this.addForm.single_select_v_models.po_id = po_options[selected_po_index];
            }else{
                this.addForm.single_select_v_models.po_id = null;
            }
            this.isLoadingPOs = false;
        },
        changedAcquisitionField(field, event){
            if (event.id === -99 && field === 'po_id'){

                let selectedValues = {
                    supplier_id: this.addForm.single_select_v_models.supplier_id
                };
                this.$refs['add-new-purchaseOrder'].setSelectedItem(selectedValues);
                this.$refs['add-new-purchaseOrder'].setSuppliersList([selectedValues.supplier_id]);
                this.$bvModal.show('create-purchaseOrder');
                this.addForm.single_select_v_models.po_id = null;

            }else if (field === 'supplier_id'){
                this.getPOsListFor(event.id);
            }
        },
        changedAddressInfo(field, event){
            if (event.id === -99){
                // shall add new
                switch (field){
                    case 'address_id':
                        // console.log("shall add new address");
                        this.$refs['add-new-address'].setSelectedItem(null);
                        this.setupAddressLookup();
                        break;
                    case 'location_id':

                        let selected_address = this.addForm.single_select_v_models.address_id;
                        this.$refs['add-new-location'].setSelectedItem(null);
                        this.$refs['add-new-location'].set_addresses_list([{value: selected_address.id, text: selected_address.name}], true);
                        this.$refs['add-new-location'].setAddressID(selected_address.id);

                        break;
                }

                this.addForm.single_select_v_models[field] = null;
                this.$bvModal.show('create-'+field.replace('_id', ''));
            }else if (field === 'address_id'){
                // should filter items in locations list.
                this.$refs['add-new-location'].set_addresses_list([{value: event.id, text: event.name}], true);
                this.$refs['add-new-location'].setAddressID(event.id);
                this.setSelectedLocationFor(event);

            }

        },
        tabChanged(){
            if (!this.addForm.single_select_v_models.status){
                this.errors_list.status = true;
                return false;
            }
            return true;
        },
        onComplete(){
            console.log("'form completed'");
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                            <div class="dz-image">
                                <div data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-size"><span data-dz-size></span></div>
                                <div class="dz-filename"><span data-dz-name></span></div>
                                <div class="dz-size">
                                    <label><input type="radio" data-dz-is_featured name="is_featured" /> Is Featured?</label>
                                </div>
                                <div class="dz-size">
                                    <label><input type="checkbox" data-dz-add_to_ap name="add_to_ap[]" /> Add to Asset Passport?</label>
                                </div>
                            </div>
                            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        </div>`;
        },
        thumbnail: function(file, dataUrl) {
            var j, len, ref, thumbnailElement, is_featuredRef, is_featuredElement, add_to_apRef, add_to_apElement;

            file.previewElement.classList.remove("dz-file-preview");

            if (file.manuallyAdded && file.id){
                this.asset_images_previewElements[file.id] = file.previewElement;
            }

            ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");

            for (j = 0, len = ref.length; j < len; j++) {
                thumbnailElement = ref[j];
                thumbnailElement.alt = file.name;
                thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }

            is_featuredRef = file.previewElement.querySelectorAll("[data-dz-is_featured]");
            if (is_featuredRef){
                is_featuredElement = is_featuredRef[0];
                is_featuredElement.value = file.upload ? file.upload.uuid : file.id;
                is_featuredElement.checked = file.is_featured === 1;
            }
            add_to_apRef = file.previewElement.querySelectorAll("[data-dz-add_to_ap]");
            if (add_to_apRef){
                add_to_apElement = add_to_apRef[0];
                add_to_apElement.value = file.upload ? file.upload.uuid : file.id;
                add_to_apElement.checked = file.add_to_ap === 1;
            }

            return setTimeout(((function(_this) {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        },
        fileAdded(file) {
            let file_id = file.id;
            let index = this.addForm.asset_images.map(function(e) { return e.id; }).indexOf(file_id);
            let foundFile = this.addForm.asset_images[index];
            if (foundFile && file.manuallyAdded){
                this.addForm.asset_images[index] = file;
            }else{
                this.addForm.asset_images.push(file);
                index = this.addForm.asset_images.length - 1;
            }
            this.saveFileToS3(file, index, 'asset_images');

        },
        maxFilesExceeded(file){
            // todo: should show error message?
        },
        resetDropZone(){
            this.$refs.myVueDropzone.removeAllFiles();
            this.addForm.asset_images = []
        },
        handle_sector_details(asset_meta) {
            this.addForm.asset_meta = Array.isArray(asset_meta) ? {} : asset_meta;
        },
        reset_po_id: function () {
            if (this.addForm.single_select_v_models.po_id && this.addForm.single_select_v_models.po_id.id === -99) {
                this.addForm.single_select_v_models.po_id = null;
            }
        },
        fetchInfoOf() {
            if (this.item_id){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/assets/' + this.item_id,
                    baseURL: '/',
                }).then(response => {
                    var asset_info = response.data;
                    this.addForm.item_details = {
                        name: asset_info.name,
                        description: asset_info.description,
                        internal_reference: asset_info.internal_reference,
                        is_unique: asset_info.is_unique === 1,
                        quantity: asset_info.quantity,
                        value: asset_info.value,
                        total_value: asset_info.total_value
                    };

                    this.handle_sector_details(asset_info.asset_meta);

                    let selected_po_id = asset_info.asset_meta.po_id;
                    for (const value_name in this.addForm.single_select_v_models) {
                        if (value_name === 'used_currency'){
                            this.addForm.single_select_v_models.used_currency = this.getCurrencyByValue(asset_info.used_currency ?? 'gbp');
                        }else if (this.meta_keys_for_acquisition.includes(value_name)){
                            if (value_name !== 'po_id'){
                                let value = this.getValueOf(value_name, asset_info.asset_meta[value_name]);
                                this.addForm.single_select_v_models[value_name] = value ?? this.required_fields[value_name].options[0];
                            }
                        }else{
                            this.addForm.single_select_v_models[value_name] = this.getValueOf(value_name, asset_info[value_name]);
                        }
                    }

                    this.getPOsListFor(this.addForm.single_select_v_models.supplier_id.id, selected_po_id);
                    this.reset_po_id();

                    for (const value_name in this.addForm.multi_select_v_models) {
                        this.addForm.multi_select_v_models[value_name] = this.getValueOf(value_name, asset_info[value_name], true);
                    }

                    if (asset_info.asset_images){
                        this.addForm.asset_images = asset_info.asset_images;

                        for (const assetImagesKey in this.addForm.asset_images) {
                            let asset_image = this.addForm.asset_images[assetImagesKey];
                            var file = { id: asset_image.id, size: asset_image.image_name_size, name: asset_image.name, type: "image/png", is_featured: asset_image.is_featured, add_to_ap: asset_image.add_to_ap };
                            var url = asset_image.image_name;

                            if (asset_image.image_name_size){
                                this.$refs.myVueDropzone.manuallyAddFile(file, url);
                            }
                        }
                    }

                    if (this.addForm.single_select_v_models.status !== 'planned'){
                        let current_status = this.addForm.single_select_v_models.status.id;
                        // should splice statuses list
                        let statusesList = this.lists_values.status;
                        let current_status_index = statusesList.map(function(e) { return e.id; }).indexOf(current_status);
                        statusesList.splice(0, (current_status_index));
                        this.required_fields.status.options = statusesList;
                    }
                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        },

        getObjectOf(value_id, required_list){
            let index = required_list.map(function(e) { return e.id+""; }).indexOf(value_id+"");
            return required_list[index] ? required_list[index] : null;
        },
        getValueOf(value_name, value_id, is_multi=false){
            var required_list = this.lists_values[value_name];

            if (value_name === 'asset_type'){
                required_list = this.getDictValues(required_list, value_name);
            }else if (this.meta_keys_for_acquisition.includes(value_name)){
                required_list = this.required_fields[value_name].options;
            }

            if (required_list){
                if (is_multi){
                    var foundItems = [];
                    for (const v_id in value_id) {
                        let foundItem = this.getObjectOf(value_id[v_id], required_list);
                        if (foundItem){
                            foundItems.push(foundItem);
                        }
                    }
                    return foundItems;
                }else{
                    let foundItem = this.getObjectOf(value_id, required_list);

                    if (foundItem){
                        return foundItem;
                    }
                }
            }
            return null;
        },
        getIDs(required_array){
            return required_array.map(item => {
                return item.id;
            });
        },
        checkAndGetFiles(){
            var j, len, file;
            var allfiles = []
            for (j = 0, len = this.addForm.asset_images.length; j < len; j++) {
                file = this.addForm.asset_images[j];

                var previewElement;
                if (this.asset_images_previewElements[file.id]){
                    previewElement = this.asset_images_previewElements[file.id];
                }else{
                    previewElement = file.previewElement;
                }

                if (previewElement){
                    var is_featuredRef = previewElement.querySelectorAll("[data-dz-is_featured]");
                    if (is_featuredRef){
                        var is_featuredElement = is_featuredRef[0];
                        file.is_featured = is_featuredElement.checked
                    }

                    var add_to_apRef = previewElement.querySelectorAll("[data-dz-add_to_ap]");
                    if (add_to_apRef){
                        var add_to_apElement = add_to_apRef[0];
                        file.add_to_ap = add_to_apElement.checked
                    }
                }

                allfiles.push(file);
            }
            return allfiles;
        },
        addNew(add_another, event) {
            var allfiles = this.checkAndGetFiles(this.addForm.asset_images);

            if (event && !this.isSendingRequest && allfiles) {
                this.isSendingRequest = true;

                var data = {
                    asset_id: this.item_id,
                    item_details: this.addForm.item_details,
                    asset_meta: this.addForm.asset_meta,
                    item_type: this.item_type,
                    asset_images: allfiles,
                    customer_id: this.customer_value ? this.customer_value.id : ""
                };
                for (const value_name in this.addForm.single_select_v_models) {
                    if (this.addForm.single_select_v_models[value_name]){
                        if (value_name === 'used_currency'){
                            data.item_details.used_currency = this.addForm.single_select_v_models.used_currency.value;
                        }else{
                            if (this.meta_keys_for_acquisition.includes(value_name)){
                                data.asset_meta[value_name] = this.addForm.single_select_v_models[value_name].id;
                            }else{
                                data.item_details[value_name] = this.addForm.single_select_v_models[value_name].id;
                            }
                        }
                    }
                }
                for (const value_name in this.addForm.multi_select_v_models) {
                    if (this.addForm.multi_select_v_models[value_name]){
                        data.item_details[value_name] = this.getIDs(this.addForm.multi_select_v_models[value_name]);
                    }
                }

                axios({
                    method:((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                    url:'/api/assetflow/assets' + (this.item_id !== '' ? ('/'+this.item_id) : ''),
                    baseURL: '/',
                    data: data,
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true){
                        if (add_another){
                            if(this.action === 'edit' && this.item_id !== ''){
                                window.location.href = '/' + this.item_type + '/assets/new';
                            }else{
                                location.reload();
                            }
                        }else{
                            let added_asset = response.data.data.items;
                            if (added_asset){
                                let asset_id = added_asset.id;
                                window.location.href = '/' + this.item_type + '/assets/' + asset_id + '/view';
                            }else{
                                window.location.href = '/' + this.item_type + '/assets';
                            }
                        }
                    }else{
                        console.log('Something went wrong');
                    }
                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });

            }
        },
        setSelectedLocationFor: function (address) {
            this.getOptionsForKey('location_id');
            let location_options = this.required_fields.location_id.options.filter(function (e) {
                return e.id === -99 || (e.parent_address ? e.parent_address.id === address.id : false);
            });
            this.required_fields.location_id.options = location_options;
            let selected_location = this.getFirstOrDefault('location_id', 'is_primary');
            this.addForm.single_select_v_models.location_id = selected_location;
        },
        getFirstOrDefault (item_type, is_default) {
            let selected_address = this.required_fields[item_type].options.find(obj => {
                return obj[is_default] === 1;
            })
            // if (!selected_address) {
            //     selected_address = this.required_fields[item_type].options[0];
            // }

            return selected_address;
        },
        getDefaultAndPrimary: function () {
            let selected_address = this.getFirstOrDefault('address_id', 'is_default');
            this.addForm.single_select_v_models.address_id = selected_address;
            if (selected_address){
                this.setSelectedLocationFor(selected_address);
            }

        },
        setDefaults() {

            this.addForm.item_details = {
                name: this.required_fields.name.default_value,
                description: this.required_fields.description.default_value,
                is_unique: this.required_fields.is_unique.default_value,
                quantity: this.required_fields.quantity.default_value
            }

            this.addForm.asset_meta = {
                asset_usage: 0
            }
            this.addForm.single_select_v_models.category_id = this.lists_values.category_id[0];
            this.addForm.single_select_v_models.used_currency = this.getCurrencyByValue(window.USER_INFO.user_meta.currency ?? 'gbp');


            for (const key in this.meta_keys_for_acquisition) {
                let meta_key = this.meta_keys_for_acquisition[key];
                this.addForm.single_select_v_models[meta_key] = this.required_fields[meta_key].options[0];
                if (meta_key === 'supplier_id'){
                    this.getPOsListFor(this.addForm.single_select_v_models.supplier_id.id);
                }
            }
            this.reset_po_id();


            this.getDefaultAndPrimary();
        }
    },
    watch: {
        current_status(value) {
            setTimeout(() => {
                this.activateAllWizardTabs();
            }, 1);
        },
        asset_quantity(value) {
            this.handleValueChanging();
        },
        value_type:{
            deep: true,
            handler: function(newValue){
                this.handleValueChanging();
            }
        },
        selected_auto_dimensions: {
            deep: true,
            handler: function(newValue){
                let selected_auto_unit_index = this.dimension_units.map(function(e) { return e.name; }).indexOf(this.selected_auto_dimensions.unit);

                this.preferred_dimension_unit = this.dimension_units[selected_auto_unit_index > -1 ? selected_auto_unit_index : 0]
                this.setItemDimensions(this.selected_auto_dimensions.dimensions);
            }
        },
        preferred_dimension_unit: {
            deep: true,
            handler: function(newValue){
                this.changeVolume();
            }
        },
        is_unique_asset(value) {
            if (!!value){
                this.addForm.item_details.quantity = 1;
                this.quantity_disabled = true;
            }else{
                this.quantity_disabled = false;
            }
        }
    },


}
</script>

<style>

#imagesgallery {
    font-family: sans-serif;
    cursor: pointer;
    transition: background-color .2s linear;
    min-height: 300px !important;
    border: 2px dashed #ced4da !important;
    background: #fff !important;
    border-radius: 6px !important;
}

#imagesgallery .dropzone-custom-content {
    font-size: 24px;
    color: #ced4da;
    margin: 3em 0 !important;
    text-align: center;
}

#imagesgallery .dz-preview {
    display: inline-block
}
#imagesgallery .dz-preview .dz-image {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-top: 20px;
}
#imagesgallery .dz-preview .dz-image > div {
    width: inherit;
    height: inherit;
    border-radius: 20%;
    background-size: contain;
}
#imagesgallery .dz-preview .dz-image > img {
    width: 100%;
}

#imagesgallery .dz-preview .dz-details {
    color: inherit;
    transition: opacity .2s linear;
    text-align: center;
}
#imagesgallery .dz-success-mark, .dz-error-mark {
    display: none;
}
</style>
