<template>
    <div class="carousel-navigation">
        <div>
            <a href="javascript: void(0);" @click="$parent.$parent.$refs.myGridCarousel.prev()" role="button" class="carousel-control-prev">
                <span class="carousel-control-prev-icon"></span>
                <span class="sr-only">Previous Slide</span>
            </a>
        </div>
        <div v-if="images_count && images_count !== null"
             class="af-image-pagination-block">
            <span class="af-current-image-number">{{ current_image ? current_image + 1 : 1 }}</span>
            of <span class="af-images-count">{{ images_count }}</span>
        </div>
        <div>
            <a href="javascript: void(0);" @click="$parent.$parent.$refs.myGridCarousel.next()" role="button" class="carousel-control-next">
                <span class="carousel-control-next-icon"></span><span
                class="sr-only">Next Slide</span>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyCarouselNavigation',
    props: {
        current_image: {},
        images_count: {}
    }
}
</script>
