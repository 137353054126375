<template>
    <Layout>
        <add-new-intake
            ref="add-new-intake"
            item_type='intake'
            modal_title="Create New Intake"
            @on-new-item-adding="getList" />

        <div class="intakes-wrapper">
            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Intake List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Intakes.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+object_type)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('intake')">Intake
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">



                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>

                    </div>

                    <!-- Product Section Start-->
                    <LotsOfItemsAlert :perpage="perpage"/>

                    <div v-if="intakesData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="object_type"
                                :keys_list="intake_list_keys_list">
                                <template v-slot:intakes_actions="{ item, index }">

                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item v-if="permissions_list['view_' + object_type]"
                                                             :href="'/' + item_type + '/intakes/' + item.id + '/view'">
                                                View
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="permissions_list['edit_' + object_type]"
                                                             @click="performAction(item, 'edit', index, $event)">
                                                Edit
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="permissions_list['delete_' + object_type] && item.managed_storage_count === 0"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete' + object_type, index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                            <b-dropdown-item v-else-if="permissions_list['delete_' + object_type] && item.managed_storage_count !== 0" variant="danger" v-b-tooltip.hover :title="'Only empty '+object_type+' can be deleted. {A: ' + item.managed_storage_count + '}'" disabled>
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>

                                </template>
                            </po-lines>
                        </div>
                    </div>

                    <div v-if="!intakesData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Intakes found here at the moment.</h4>

                    </div>
                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import GmapCustomMarker from "../extras/gmap-custom-marker";
import AddNewIntake from "../extras/AddNewIntake";
import SearchBox from "@/components/search-box";

const intakeResource = new Resource('api/assetflow/intakes');
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {IntakeKeysList} from "@/views/assetflow/form_data/IntakeKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'IntakesList',
    props: ['item_id', 'item_type'],
    components: {LotsOfItemsAlert, AddNewIntake, PoLines, SearchBox, GmapCustomMarker, Layout, PageHeader},
    data() {
        return {
            intake_list_keys_list: IntakeKeysList,
            total: 0,
            intakesData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Intakes",
            items: [
                {
                    text: "Intakes",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_intake_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            object_type: 'orders'
        };
    },
    created() {

        this.permissions_list['view_' + this.object_type] = false; // this.hasPermission('browse_' + this.object_type);
        this.permissions_list['add_' + this.object_type] = this.hasPermission('add_' + this.object_type);
        this.permissions_list['edit_' + this.object_type] = this.hasPermission('edit_' + this.object_type);
        this.permissions_list['delete_' + this.object_type] = this.hasPermission('delete_' + this.object_type);

        this.has_intake_actions_permissions = this.permissions_list['edit_' + this.object_type] || this.permissions_list['delete_' + this.object_type];

        if (this.has_intake_actions_permissions){
            this.intake_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'intakes_actions'
            }
        }
        if (this.item_type === 'sp'){
            if (this.is_tv_film_asset){
                this.intake_list_keys_list.customer_info.label = 'Production';
            }
            delete this.intake_list_keys_list.sp_info;
        }else if (this.item_type === 'customer') {
            delete this.intake_list_keys_list.customer_info;
        }


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_intakes_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.intake_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.intake_preferred_sorting;
        }

        this.getList();
    },
    computed: {
        totalAssetsValue() {
            return this.intakesData ? this.intakesData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        checkIntakeStatus(intake){
            return parseInt(intake.assets_sum_quantity) !== 0;
        },

        performAction(intake, action, intakeKey, e){
            if (action === 'edit'){
                this.$refs['add-new-intake'].setSelectedItem(intake);
                this.$bvModal.show('create-intake');
            }else if (action === ('delete'+this.object_type)){
                if (intake.id !== '' && intake.managed_storage_count === 0){
                    this.deleteItemByID(intake.id, intake.name, this.object_type, intake)
                        .then((response) => {
                            if (response.data.data.status){
                                this.intakesData.splice(intakeKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, intake) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['name'];

                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";

                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this.intakesData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.intakesData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('intake_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_intakes_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item=null) {
            this.listLoading = true;
            this.handleQueryItems();
            this.listQuery.order_type = '0';
            this.listQuery = this.addPaginationParams(this.listQuery)
            const {data} = await intakeResource.list(this.listQuery);
            this.total = data.meta.total;
            this.intakesData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
            }
            if (this.item_type === 'customer') {
                this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
            }
        },

        addNewItem(order_type) {
            this.$refs['add-new-intake'].setSelectedItem(null);
            this.$bvModal.show('create-intake');
        }
    },
}
</script>


