<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>

                    <div class="common-step-panel use-section">
                        <div class="row">
                            <div class="col-sm-12">
                                <setting-info-section
                                    v-for="(features_section, key) in sidebar_navigation"
                                    :key="key"
                                    :title="features_section.label | titleize">
                                    <div>
                                        <div class="st--common--box" v-for="(feature, key) in features_section.settings">
                                            <div class="common-st-title-label">
                                                <h5>{{ feature.label }}<sup v-if="feature.functionality === 'core'">Core</sup></h5>
                                            </div>
                                            <div class="st-status-radio-box">
                                                <div class="st-radio-row">
                                                    <div v-for="(option, index) in enabling_options"
                                                         :key="index"
                                                         class="st-radio-col-2">
                                                        <div class="cm-radio-btn" :class="'status-' + option.id">
                                                            <label>
                                                                <input
                                                                    v-model="addForm.available_features[feature.id]"
                                                                    :id="'_'+feature.id"
                                                                    :name="'_'+feature.id"
                                                                    :value="option.id"
                                                                    :disabled="feature.functionality === 'core'"
                                                                    type="radio" />
                                                                <div class="st-radio-box">
                                                                    <span></span>
                                                                    <p>{{ option.name | titleize }}</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </setting-info-section>
                            </div>
                        </div>
                    </div>


                    <div class="common-step-button-block">
                        <div class="save-cancel-btn">
                            <a @click="shallSaveFeatures($event)" class="btn btn-primary">Save</a>
                            <a href="./assets" class="btn btn-secondary">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SettingInfoSection from "../settingsForm/SettingInfoSection";
import SettingsShared from "@/mixins/SettingsShared.mixin";
export default {

    name: "features_status",
    components: {SettingInfoSection},
    mixins: [SettingsShared],
    props:['selected_setting'],
    data(){
        return {
            addForm:{
                available_features: {
                    '1': 1,
                    '2': 1,
                    '3': 1,
                    '4': 1,
                    '5': 1
                },
            },

            sidebar_navigation: {
                core: {
                    label: 'Core',
                    settings: {
                        assets: {
                            id: '1',
                            key: 'customer_assets',
                            label: 'Assets',
                            functionality: 'core'
                        },
                        tags: {
                            id: '2',
                            key: 'customer_tags',
                            label: 'Tags',
                            functionality: 'core'
                        },
                        categories: {
                            id: '3',
                            key: 'customer_categories',
                            label: 'Categories',
                            functionality: 'core'
                        },
                        addresses: {
                            id: '4',
                            key: 'customer_addresses',
                            label: 'Addresses',
                            functionality: 'core'
                        },
                        assetflow_passports: {
                            id: '5',
                            key: 'customer_assetflow_passports',
                            label: 'Asset Flow Passports',
                            functionality: 'core'
                        }
                    }
                },
                productivity: {
                    label: 'Productivity',
                    settings: {
                        asset_type: {
                            id: '6',
                            key: 'customer_productivity_asset_type',
                            label: 'Asset Types',
                            functionality: 'settings'
                        },
                        asset_groups: {
                            id: '7',
                            key: 'customer_productivity_asset_groups',
                            label: 'Asset Groups',
                            functionality: 'settings'
                        },
                        folders: {
                            id: '8',
                            key: 'customer_productivity_folders',
                            label: 'Folders',
                            functionality: 'settings'
                        },
                        users: {
                            id: '9',
                            key: 'customer_productivity_users',
                            label: 'Users',
                            functionality: 'settings'
                        },
                        departments: {
                            id: '10',
                            key: 'customer_productivity_departments',
                            label: 'Departments',
                            functionality: 'settings'
                        },
                        locations: {
                            id: '11',
                            key: 'customer_productivity_locations',
                            label: 'Locations',
                            functionality: 'settings'
                        },
                        workflow: {
                            id: '12',
                            key: 'customer_productivity_workflow',
                            label: 'Workflow',
                            functionality: 'settings'
                        },
                        intelligence: {
                            id: '13',
                            key: 'customer_productivity_intelligence',
                            label: 'Intelligence',
                            functionality: 'settings'
                        },
                        lifecycle: {
                            id: '14',
                            key: 'customer_productivity_lifecycle',
                            label: 'Lifecycle',
                            functionality: 'settings'
                        },
                        accounting: {
                            id: '15',
                            key: 'customer_productivity_accounting',
                            label: 'Accounting',
                            functionality: 'settings'
                        },
                        labelling: {
                            id: '16',
                            key: 'customer_productivity_labelling',
                            label: 'Labelling',
                            functionality: 'settings'
                        }
                    }
                },
                asset_flow: {
                    label: 'Asset Flow',
                    settings: {
                        asset_passports: {
                            id: '17',
                            key: 'customer_assetflow_asset_passports',
                            label: 'Asset Passports',
                            functionality: 'settings'
                        },
                        splits: {
                            id: '18',
                            key: 'customer_assetflow_splits',
                            label: 'Splits',
                            functionality: 'settings'
                        },
                        bank: {
                            id: '19',
                            key: 'customer_assetflow_bank',
                            label: 'Bank',
                            functionality: 'settings'
                        },
                        transfers: {
                            id: '20',
                            key: 'customer_assetflow_transfers',
                            label: 'Transfers',
                            functionality: 'settings'
                        },
                        loans: {
                            id: '21',
                            key: 'customer_assetflow_loans',
                            label: 'Loans',
                            functionality: 'settings'
                        },
                        marketplace: {
                            id: '22',
                            key: 'customer_assetflow_marketplace',
                            label: 'Marketplace',
                            functionality: 'settings'
                        },
                    }
                }
            }
        }
    },
    created() {
        this.addForm.available_features = Object.assign(this.features_dict, this.addForm.available_features);
        this.addForm.available_features = Object.assign(this.available_features, this.addForm.available_features);
    },
    computed: {
    },
    watch: {

    },
    methods:{
        shallSaveFeatures(event){
            let enabled_features = this.addForm.available_features;
            this.shallSaveMeta('available_features', enabled_features, true);
        }
    }
}
</script>


