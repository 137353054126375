<template>

    <div class="col-md-6 mb-3" :class="('col-xl-' + columns_count_withclasses)">
        <div class="cm_common_asset_box">
            <div class="cm_asset_head" :class="is_shown ? 'this_active' : ''" @click.self="is_shown = !is_shown">
                <h3>{{ block_title }}</h3>
            </div>
            <div class="cm_asset_details_upload_box" v-if="is_shown">
                <div class="common_sub_col_bg color_v_accent">
                    <slot name="all_content">
                        <div class="textarea_and_upload template-overview-block">
                            <div class="card">
                                <div class="card-body">
                                    <slot name="block_content">
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CustomGenericBlock',
    props: ['columns_count_withclasses', 'block_title', 'upload_ditels_box_class'],
    data(){
        return {
            is_shown: true
        }
    },
    methods:{
    }
}
</script>

