<template>
    <new-customer
        :item_id="item_id"
        :item_type="item_type"
        :action="action"
        :is_group="true"
    ></new-customer>
</template>

<script>
import NewCustomer from "../customers/New";
export default {
    props:['item_id', 'item_type', 'action'],
    name: "NewGroup",
    components: {NewCustomer}
}
</script>


