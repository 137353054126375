<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <form enctype="multipart/form-data">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Basic Information</h4>
                            <p class="card-title-desc">Fill all information below</p>


                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label for="initials">Initials</label>
                                        <input v-model="addForm.initials" id="initials" name="initials" type="text" class="form-control" />
                                    </div>
                                    <div class="form-group">
                                        <label for="first_name">First Name</label>
                                        <input v-model="addForm.first_name" id="first_name" name="first_name" type="text" class="form-control" />
                                    </div>
                                    <div class="form-group">
                                        <label for="last_name">Last Name</label>
                                        <input v-model="addForm.last_name" id="last_name" name="last_name" type="text" class="form-control" />
                                    </div>
                                    <div class="form-group">
                                        <label for="user_email">Email</label>
                                        <input v-model="addForm.email" id="user_email" name="user_email" type="text" class="form-control" />
                                    </div>
                                    <div class="form-group" v-if="isSuperAdmin">
                                        <label for="cama_id">Cama ID</label>
                                        <input v-model="addForm.cama_id" id="cama_id" name="cama_id" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="user_password">Password</label>
                                        <small v-if="(this.action === 'edit' || this.action == 'profile')"> Leave empty to ignore updating password</small>
                                        <input v-model="addForm.password" id="user_password" name="user_password" type="password" class="form-control" />
                                    </div>
                                </div>

                                <div class="col-sm-6">

                                    <div class="form-group">
                                        <label for="avatar">Avatar</label>
                                        <div class="col-sm-6">
                                            <vue-dropzone id="avatar" ref="myVueDropzone" :use-custom-slot="true"
                                                          :options="dropzoneOptions"
                                                          @vdropzone-file-added="fileAdded"
                                                          @vdropzone-max-files-exceeded="maxFilesExceeded"
                                            >
                                                <div class="dropzone-custom-content">
                                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                            </vue-dropzone>
                                            <b-button
                                                v-if="action === 'edit' || action === 'profile'"
                                                @click="$refs.myVueDropzone.$el.click();"
                                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                            >Select New Avatar</b-button>
                                            <b-button
                                                @click="addForm.avatar = null;$refs.myVueDropzone.removeAllFiles();"
                                                variant="outline-danger"
                                                class="btn btn-sm mt-1 btn-block"
                                            >Remove Selected</b-button>
                                        </div>
                                        <div class="col-sm-6">
                                            <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px; width: 100%; margin-top: 8px;">
                                                <p><strong>Avatar Dimensions</strong></p>
                                                <p>Avatar's will be cropped to become square and should be a minimum size of 100px x 100px</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">User Preferences</h4>


                            <div class="form-group" v-if="isSuperAdmin">
                                <label for="currency">Bulk Actions</label>
                                <b-form-select
                                    id="currency"
                                    v-model="addForm.can_bulk_update"
                                    :options="bulkUpdateOptions"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>


                            <div class="form-group">
                                <label for="locales_list">Locale</label>
                                <b-form-select
                                    id="locales_list"
                                    v-model="addForm.meta.locale"
                                    :options="this.locales"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>

                            <div class="form-group">
                                <label for="dateformat">Date Format</label>
                                <b-form-select
                                    id="dateformat"
                                    v-model="addForm.meta.date_format"
                                    :options="this.dateFormats"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>


                            <div class="form-group">
                                <label for="dateformat">Time Format</label>
                                <b-form-select
                                    id="timeformat"
                                    v-model="addForm.meta.time_format"
                                    :options="this.timeFormats"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>


                            <div class="form-group">
                                <label for="timezone">Timezone</label>
                                <b-form-select
                                    id="timezone"
                                    v-model="addForm.meta.timezone"
                                    :options="this.timezones"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>

                            <div class="form-group">
                                <label for="currency">Currency</label>
                                <b-form-select
                                    id="currency"
                                    v-model="addForm.meta.currency"
                                    :options="this.currencies"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>


                            <div class="form-group">
                                <b-form-checkbox
                                    v-model="addForm.meta.filled_login_wizard"
                                    class="custom-checkbox mb-3"
                                >Hide First Login Wizard</b-form-checkbox>
                            </div>


                            <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                            <a href="./" class="btn btn-secondary">Cancel</a>

                        </div>
                    </div>

                </form>

            </div>
        </div>
    </Layout>
</template>



<script>

    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import Resource from '@/api/resource';
    import vue2Dropzone from "vue2-dropzone";
    import { LocalesList } from "../form_data/LocalesList";
    import { DateFormats } from "../form_data/DateFormats";
    import { TimeFormats } from "../form_data/TimeFormats";
    import { Currencies } from "../form_data/Currencies";
    import { Timezones } from "../form_data/Timezones";
    import { DropZoneOptions } from "../form_data/DropZoneOptions";
    import { BulkUpdateOptions } from "../form_data/BulkUpdateOptions";

    import { ItemLayoutOptions } from "../form_data/ItemLayoutOptions";
    import { UserLayoutOptions } from "../form_data/UserLayoutOptions";

    const userResource = new Resource('api/admin/users');


    export default {
        props: ['item_id', 'item_type', 'action'],
        name: 'NewUser',
        components: {
            vueDropzone: vue2Dropzone,
            Layout,
            PageHeader
        },
        data() {
            return {
                title: "Add New User",
                items: [
                    {
                        text: "Users",
                        href: "/"
                    },
                    {
                        text: "Add User",
                        active: true
                    }
                ],
                isSendingRequest: false,
                addForm:{
                    initials: "",
                    first_name: "",
                    cama_id: "",
                    last_name: "",
                    email: "",
                    password: "",
                    avatar: null,
                    can_bulk_update: null,
                    meta: {
                        locale: 'uk',
                        date_format: 'd/m/Y',
                        time_format: 'h:i A',
                        timezone: '+00:00',
                        currency: 'gbp',
                        filled_login_wizard: 0,
                        preferred_items_per_page: 10,
                        preferred_layout: 2,
                        layout_options:{
                            layout_mode: "default",
                            sidebar_mode: "dark",
                            layout_width: "fluid",
                            use_icons: 0,
                        }
                    }
                },
                bulkUpdateOptions: BulkUpdateOptions,
                dropzoneOptions: DropZoneOptions,
                locales: LocalesList,
                dateFormats: DateFormats,
                timeFormats: TimeFormats,
                currencies: Currencies,
                timezones: Timezones,
                items_layout_options: ItemLayoutOptions,
                user_layout_options: UserLayoutOptions
            };
        },
        created() {
            if(['edit', 'profile'].includes(this.action) && this.item_id !== ''){
                this.fetchInfoOf();
                this.title = this.title.replace('Add New', 'Edit');
                this.items[1].text = this.items[1].text.replace('Add', 'Edit');
            }
        },
        methods: {
            fileAdded(file) {
                if (file.hasOwnProperty('manuallyAdded') && file.manuallyAdded){
                    this.addForm.avatar = 'older_avatar';
                }else{
                    this.addForm.avatar = file;
                    this.saveFileToS3(file, null, 'avatar', 'user');
                }
            },
            maxFilesExceeded(file){
                this.$refs.myVueDropzone.removeAllFiles();
                this.$refs.myVueDropzone.addFile(file);
            },
            fetchInfoOf() {
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/users/' + this.item_id + "?action=" + this.action,
                    baseURL: '/'
                }).then(response => {
                    var item_info = response.data;

                    this.addForm.initials = item_info.initials;
                    this.addForm.first_name = item_info.first_name;
                    if (this.isSuperAdmin){
                        this.addForm.cama_id = item_info.cama_id;
                        this.addForm.can_bulk_update = item_info.can_bulk_update;
                    }
                    this.addForm.last_name = item_info.last_name;
                    this.addForm.email = item_info.email;

                    if (item_info.avatar){
                        this.addForm.avatar = item_info.avatar;

                        var file = { size: item_info.image_size, name: item_info.first_name + ' ' + item_info.last_name, type: "image/png" };
                        var url = this.addForm.avatar;

                        if (url){
                            this.$refs.myVueDropzone.manuallyAddFile(file, url);
                        }
                    }
                    if (item_info.meta){
                        let required_meta = {
                            locale: item_info.meta.locale,
                            date_format: item_info.meta.date_format,
                            time_format: item_info.meta.time_format,
                            timezone: item_info.meta.timezone,
                            currency: item_info.meta.currency,
                            filled_login_wizard: item_info.meta.filled_login_wizard,
                            preferred_items_per_page: item_info.meta.preferred_items_per_page ?? 10,
                            preferred_assets_layout: item_info.meta.preferred_layout ?? 2,
                            layout_options: {
                                layout_mode: item_info.meta.layout_options.layout_mode ?? "default",
                                sidebar_mode: item_info.meta.layout_options.sidebar ?? "dark",
                                layout_width: item_info.meta.layout_options.layout_width ?? "fluid",
                                use_icons: item_info.meta.layout_options.use_icons ?? 0,
                            }
                        };
                        this.addForm.meta = required_meta;
                    }

                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });

            },
            addNew(event) {
                if (event && !this.isSendingRequest) {
                    this.isSendingRequest = true;
                    var edited_meta = this.addForm.meta;
                    edited_meta.filled_login_wizard = edited_meta.filled_login_wizard == 1;
                    let params = {
                        user_id: this.item_id,
                        initials: this.addForm.initials,
                        first_name: this.addForm.first_name,
                        last_name: this.addForm.last_name,
                        email: this.addForm.email,
                        password: this.addForm.password,
                        avatar: this.addForm.avatar,
                        item_type: this.item_type,
                        meta: edited_meta,
                        action: this.action
                    };
                    if (this.isSuperAdmin){
                        params.cama_id = this.addForm.cama_id;
                        params.can_bulk_update = this.addForm.can_bulk_update;
                    }

                    axios({
                        method:((['edit', 'profile'].includes(this.action) && this.item_id !== '') ? 'put' : 'post'),
                        url:'/api/assetflow/users' + (this.item_id !== '' ? ('/'+this.item_id) : ''),
                        baseURL: '/',
                        data: params,
                    }).then(response => {
                        this.isSendingRequest = false;
                        if (response.data.data.status === true){
                            if(this.action !== 'profile'){
                                let added_user = response.data.data.items;
                                let message;
                                let user_name = ', {' + added_user.first_name + ' ' + added_user.last_name + '}';
                                message='Added Successfully' + user_name;
                                if (this.action === 'edit' && this.item_id !== '') {
                                    message = 'Edited Successfully' + user_name;
                                }

                                window.flash(message, 'success', event);
                                let user_id = added_user.id;
                                window.location.href = '/' + this.item_type + '/users/' + user_id + '/view';
                            }else{
                                this.presentToast("Success!",
                                    "Updated Profile Successfully",
                                    'success',
                                    3000,
                                    true,
                                    true);
                            }
                        }else{
                            console.log('Something went wrong');
                        }

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });

                }
            }
        }


    }
</script>

<style>
#avatar .dz-preview .dz-progress {
    opacity: 0 !important;
}

.vue-dropzone > .dz-preview .dz-image {
    height: 160px !important;
    width: 160px !important;
}

.vue-dropzone .dz-preview .dz-details .dz-size {
    display: none
}
</style>
