export const TaskKeysListShort = {
    name: {
        key: 'name',
        label: "Action",
        class: "col-lg e_name",
        show_on_mobile: true,
        case: 'custom_slot',
        slot_name: 'action_name'
    },
    description: {
        key: 'description',
        label: "Description",
        class: "col-lg-2 description",
        show_on_mobile: false
    }
};
