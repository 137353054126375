<template>
    <b-modal :id="received_modal_id"
             size="lg"
             :ref="received_modal_id"
             :title="modal_title + (selected_item ? (' of {'+selected_item.name + '}') : '')"
             title-class="font-18"
             @hide="cancelClicked"
             hide-footer>

        <ChangeCommonFields :add-form="addForm" :required_fields="required_items"/>

        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'edit-ms-asset' + '-' + buttonkey"
                :is_enabled="is_saving_enabled && !missing_stocks"
                :required_action="addItem"
                :required_text="selected_item ? ('Update {' + selected_item.name + '}') : 'Create'"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const editMSResource = new Resource('api/assetflow/managed_storages/editMS');
import multiSelect from '@/mixins/MultiSelect';
import AFButton from "@/components/AFButton";
import ChangeCommonFields from "../extras/ChangeCommonFields";

const firstPath = window.location.pathname.split('/')[1];

export default {
    mixins: [multiSelect],
    name: "UpdateMSStocks",
    props: ['modal_title', 'received_modal_id'],
    components: {ChangeCommonFields, AFButton},

    data(){
        return {
            index: null,
            addForm:{
                crnt_qty:null,
                crnt_asset_qty:null,
                crnt_passport_qty:null,
                crnt_mts_ol_qty:null,

                available_quantity:null,
                reserved_quantity:null,
                dispatched_quantity:null
            },
            required_items:{
                crnt_qty: {
                    key: 'crnt_qty',
                    label: 'Current Stock',
                    type: 'text',
                    is_readonly: true
                },
                crnt_asset_qty: {
                    key: 'crnt_asset_qty',
                    label: 'Asset Qty',
                    type: 'text',
                    is_readonly: true
                },
                crnt_passport_qty: {
                    key: 'crnt_passport_qty',
                    label: 'Passport Qty',
                    type: 'text',
                    is_readonly: true
                },
                crnt_mts_ol_qty: {
                    key: 'crnt_mts_ol_qty',
                    label: 'MTS Qty',
                    type: 'text',
                    is_readonly: true
                },

                available_quantity: {
                    key: 'available_quantity',
                    label: 'Available Stock',
                    type: 'number'
                },
                reserved_quantity: {
                    key: 'reserved_quantity',
                    label: 'Reserved Stock',
                    type: 'number'
                },
                dispatched_quantity: {
                    key: 'dispatched_quantity',
                    label: 'Dispatched Stock',
                    type: 'hidden_text'
                },
            },
            selected_item: null,
            note_asset: '',
            note_passport: '',
            note_mts_ol: '',
        }
    },
    computed:{
        ms_status() {
            return this.selected_item ? this.selected_item.ms_status : null;
        },
        dispatched_quantity() {
            return this.addForm.dispatched_quantity;
        },
        available_quantity() {
            return this.addForm.available_quantity;
        },
        reserved_quantity() {
            return this.addForm.reserved_quantity;
        },
        missing_stocks() {
            if (this.ms_status !== null){
                if (this.ms_status === 0){
                    return parseInt(this.addForm.dispatched_quantity) <= 0;
                }else{
                    return (parseInt(this.addForm.available_quantity) + parseInt(this.addForm.reserved_quantity)) <= 0;
                }
            }
            return true;
        },
    },
    watch:{
        dispatched_quantity(value) {
            this.changedStock();
        },
        available_quantity(value) {
            this.changedStock();
        },
        reserved_quantity(value) {
            this.changedStock();
        },
        ms_status(value) {
            if (value === 0){
                this.required_items.dispatched_quantity.type = 'number';
                this.required_items.available_quantity.type = 'hidden_text';
                this.required_items.reserved_quantity.type = 'hidden_text';
            }else{
                this.required_items.dispatched_quantity.type = 'hidden_text';
                this.required_items.available_quantity.type = 'number';
                this.required_items.reserved_quantity.type = 'number';
            }
        },
    },
    methods:{
        changedStock(){
            this.required_items.crnt_qty.has_html_note = '';
            this.required_items.crnt_asset_qty.has_html_note = '';
            this.required_items.crnt_passport_qty.has_html_note = '';
            this.required_items.crnt_mts_ol_qty.has_html_note = '';


            if (this.selected_item.hasOwnProperty('uid')){
                let asset_link = '#';
                let asset_link_target = '';
                if (this.hasPermission('browse_managed_storages')){
                    asset_link = '/' + firstPath + '/managed_storages/' + this.selected_item.managed_storage_id + '/view?t=overview';
                    asset_link_target = ' target="_blank"';
                }
                this.required_items.crnt_qty.has_html_note = '<a href="' + asset_link + '"' + asset_link_target + '>MS-' + this.selected_item.managed_storage_uid + '</a>';
            }


            if (this.selected_item.hasOwnProperty('uid')){
                let asset_link = '#';
                let asset_link_target = '';
                if (this.hasPermission('browse_assets')){
                    asset_link = '/' + firstPath + '/assets/' + this.selected_item.id + '/view?t=overview';
                    asset_link_target = ' target="_blank"';
                }
                this.required_items.crnt_asset_qty.has_html_note = '<a href="' + asset_link + '"' + asset_link_target + '>ASS-' + this.selected_item.uid + '</a>';
            }

            if (this.selected_item.hasOwnProperty('passport_uid')){
                let passport_link = '#';
                let passport_link_target = '';
                if (this.hasPermission('browse_asset_passports')){
                    passport_link = '/' + firstPath + '/asset_passports/' + this.selected_item.cAFID + '/view';
                    passport_link_target = ' target="_blank"';
                }
                this.required_items.crnt_passport_qty.has_html_note = '<a href="' + passport_link + '"' + passport_link_target + '>PASS-' + this.selected_item.passport_uid + '</a>';
            }

            if (this.selected_item.hasOwnProperty('order_line_uid')){
                let order_line_link = '#';
                let order_line_link_target = '';
                if (this.hasPermission('browse_order_lines')){
                    order_line_link = '/' + firstPath + '/order_lines/' + this.selected_item.order_line_id + '/view';
                    order_line_link_target = ' target="_blank"';
                }
                this.required_items.crnt_mts_ol_qty.has_html_note = '<a href="' + order_line_link + '"' + order_line_link_target + '>' + this.selected_item.order_line_uid + '</a>';
            }

            let new_total_quantity, old_total_quantity;
            if (this.ms_status === 0){
                // dispatched
                if (!this.addForm.dispatched_quantity){
                    this.addForm.dispatched_quantity = 0;
                }
                new_total_quantity = this.addForm.dispatched_quantity;
                old_total_quantity = this.selected_item.dispatched_quantity;
                this.required_items.dispatched_quantity.has_html_note = 'Current dispatched stock is <span class="crnt_dispatched_qty"><b>' + this.selected_item.dispatched_quantity + '</b></span>' +
                    '<div class="mt-2">Changing this value will update all records to <span class="total-qty"><b>' + new_total_quantity + '</b></span></div>';
            }else{
                // available & reserved
                if (!this.addForm.available_quantity){
                    this.addForm.available_quantity = 0;
                }
                if (!this.addForm.reserved_quantity){
                    this.addForm.reserved_quantity = 0;
                }
                new_total_quantity = parseInt(this.addForm.available_quantity) + parseInt(this.addForm.reserved_quantity);
                old_total_quantity = parseInt(this.selected_item.available_quantity) + parseInt(this.selected_item.reserved_quantity);
                this.required_items.reserved_quantity.has_html_note = 'Current reserved stock is <span class="crnt_reserved_qty"><b>' + this.selected_item.reserved_quantity + '</b></span>' +
                    '<div class="mt-2">Changing these values will update all records to <span class="total-qty"><b>' + new_total_quantity + '</b></span></div>';
            }

            if (new_total_quantity !== old_total_quantity){
                // changed
                this.required_items.crnt_qty.has_html_note += ' total will be updated to <b>' + new_total_quantity + '</b>';
                this.required_items.crnt_asset_qty.has_html_note += ' total will be updated to <b>' + new_total_quantity + '</b>';
                this.required_items.crnt_passport_qty.has_html_note += ' total will be updated to <b>' + new_total_quantity + '</b>';
                this.required_items.crnt_mts_ol_qty.has_html_note += ' total will be updated to <b>' + new_total_quantity + '</b>';
            }
        },
        set_index(index){
            this.index = index;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            this.addForm.crnt_qty = this.selected_item.crnt_qty;
            this.addForm.crnt_asset_qty = this.selected_item.quantity;
            this.addForm.crnt_passport_qty = this.selected_item.crnt_passport_qty;
            this.addForm.crnt_mts_ol_qty = this.selected_item.crnt_mts_ol_qty;


            this.addForm.available_quantity = this.selected_item.available_quantity;
            this.required_items.available_quantity.has_html_note = 'Current available stock is <span class="crnt_available_qty"><b>' + this.selected_item.available_quantity + '</b></span>';

            this.addForm.reserved_quantity = this.selected_item.reserved_quantity;
            this.required_items.reserved_quantity.has_html_note = 'Current reserved stock is <span class="crnt_reserved_qty"><b>' + this.selected_item.reserved_quantity + '</b></span>' +
                '<div class="mt-2">Changing these values will update all records to <span class="total-qty"><b>' + (this.selected_item.available_quantity + this.selected_item.reserved_quantity) + '</b></span></div>';

            this.addForm.dispatched_quantity = this.selected_item.dispatched_quantity;
            this.required_items.dispatched_quantity.has_html_note = 'Current dispatched stock is <span class="crnt_dispatched_qty"><b>' + this.selected_item.dispatched_quantity + '</b></span>' +
                '<div class="mt-2">Changing this value will update all records to <span class="total-qty"><b>' + this.selected_item.dispatched_quantity + '</b></span></div>';
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            if (this.ms_status !== null){
                let params = {
                    status: this.ms_status,
                };
                if (this.ms_status === 0){
                    params.dispatched_quantity = this.addForm.dispatched_quantity;
                    params.available_quantity = 0;
                    params.reserved_quantity = 0;
                }else{
                    params.dispatched_quantity = 0;
                    params.available_quantity = this.addForm.available_quantity;
                    params.reserved_quantity = this.addForm.reserved_quantity;
                }

                let managed_storage_id = null;
                if (this.selected_item){
                    params.item_id = this.selected_item.id;

                    managed_storage_id = this.selected_item.managed_storage_id;
                    params.managed_storage_id = managed_storage_id;
                }
                params.action = 'stock_update';

                const { data } = await editMSResource.store(params);

                let new_item = data.data.items;
                if (!new_item){
                    this.buttonkey++;
                }
                this.presentToast((new_item ? "Success!" : "Error!"),
                    new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                    (new_item ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
                this.isAddingItem = false;
                this.resetSelectedItem();
                this.$emit('on-new-item-adding', this.received_modal_id, new_item);
            }

        }
    }
}
</script>


