<template>
    <div>
        <div class="col-xl-12">
            <div v-if="['group_details', 'sps_details'].includes(tab_name)" class="row">
                <div class="col-xl-12">
                    <EntityDetailsDetails :item_info="item_info" :tab_name="tab_name"/>
                </div>
                <div class="col-xl-12">
                    <img :src="item_info.logo" :alt="item_info.name" :width="image_width">
                </div>
            </div>

            <div v-else-if="tab_name === 'user_details'" class="row">
                <div class="col-md-2">
                    <img :src="item_info.avatar" :alt="item_info.name" />
                </div>
                <div class="col-md-10">
                    <div class="card-body">
                        <h4 class="card-title mb-4">
                            <div class="row">
                                <div class="col-10">
                                    Personal Information
                                </div>
                                <div class="col-2 text-sm-right">
                                    <a v-if="hasPermission('add_users')" :href="'/'+item_info.current_page_type+'/users/new?email='+item_info.email" class="btn btn-rounded af-btn-accent af-accent">
                                        <i class="fa fa-users"></i> Wizard
                                    </a>
                                </div>
                            </div>
                        </h4>
                        <div class="table-responsive">
                            <table class="table table-nowrap mb-0">
                                <tbody>
                                <tr>
                                    <th scope="row">Status</th>
                                    <td v-html="getUserStatus(item_info)"></td>
                                </tr>
                                <tr>
                                    <th scope="row">User ID</th>
                                    <td>{{item_info.id}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Full Name</th>
                                    <td>{{item_info.name}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">First Name</th>
                                    <td>{{item_info.first_name}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Last Name</th>
                                    <td>{{item_info.last_name}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Email</th>
                                    <td><a :href="'mailto:' + item_info.email">{{item_info.email}}</a></td>
                                </tr>
                                <tr>
                                    <th scope="row">Created At</th>
                                    <td>{{ item_info.created_at | moment("DD/MM/YYYY") }} - {{item_info.created_at | fromNow}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Last Activation Sent</th>
                                    <td v-if="item_info.hasOwnProperty('last_activation_sent') && item_info.last_activation_sent">{{ item_info.last_activation_sent | moment("DD/MM/YYYY") }} - {{item_info.last_activation_sent | fromNow}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Last Login</th>
                                    <td>{{ item_info.last_login_at | moment("DD/MM/YYYY") }} - {{item_info.last_login_at | fromNow}}</td>
                                </tr>

                                <tr>
                                    <th scope="row">Bulk Actions</th>
                                    <td>{{ item_info.can_bulk_update === 1 ? 'Enabled' : (item_info.can_bulk_update === 2 ? 'Available' : 'Disabled') }}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Bulk Actions Enabled Date</th>
                                    <td v-if="item_info.meta && item_info.meta.hasOwnProperty('bulk_actions_enabled_date')">
                                        {{ item_info.meta.bulk_actions_enabled_date }}
                                    </td>
                                    <td v-else>-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Bulk Actions Setting</th>
                                    <td v-if="item_info.meta && item_info.meta.hasOwnProperty('is_actions_bar_opened') && item_info.meta.is_actions_bar_opened">
                                        On
                                    </td>
                                    <td v-else>Off</td>
                                </tr>
                                <tr>
                                    <th scope="row">Bulk Actions Notification Default</th>

                                    <td v-if="item_info.meta && item_info.meta.hasOwnProperty('preferred_confirmation_option') && item_info.meta.preferred_confirmation_option === '1'">
                                        Email Me When Done
                                    </td>
                                    <td v-else>Do Not Email Me When Done</td>
                                </tr>
                                <tr>
                                    <th scope="row">Bulk Actions Redirection</th>
                                    <td v-if="item_info.meta && item_info.meta.hasOwnProperty('preferred_redirection_option')">
                                        <span v-if="item_info.meta.preferred_redirection_option === '1'">Leave me here</span>
                                        <span v-else-if="item_info.meta.preferred_redirection_option === '2'">Bulk Action Report</span>
                                        <span v-else>Go To Object</span>
                                    </td>
                                    <td v-else>Leave me here</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="card">
                    <div class="text-primary p-3">
                        <h6 v-if="tab_name === 'department_details'">{{item_info.status ? 'Active' : 'InActive'}}</h6>
                        <p v-if="tab_name === 'related_items' || tab_name === 'listing_preview'">{{tab_name | titleize}} Placeholder</p>
                        <p v-if="tab_name === 'permission_details'">{{item_info.display_name}}</p>
                    </div>

                    <div v-if="tab_name === 'department_details'" class="row text-right">
                        <add-new-department
                            ref="add-new-department"
                            item_type='department'
                            modal_title="Create New Department"
                            @on-new-item-adding="departmentEdited"
                        >
                        </add-new-department>

                        <div class="col-12">
                            <div class="mt-4">
                                <a @click="editDepartment($event)" class="btn btn-primary btn-sm">
                                    Edit "{{item_info.name}}"
                                    <i class="mdi mdi-arrow-right ml-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="itemsContains(tab_name) && isSuperAdmin" class="row text-right">
                        <div class="col-12">
                            <div class="mt-4">
                                <a :href="'/'+item_info.group+'/'+item_info.item_type+'/' + item_info.id + '/edit'" class="btn btn-primary btn-sm">
                                    Edit "{{item_info.name}}"
                                    <i class="mdi mdi-arrow-right ml-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>


                </div>
        </div>

        <div v-if="tab_name === 'customer_sp_relation'" class="row">
            <details-section header_title="Overview">
                <div class="row">
                    <div class="col-xl-4">
                        <div class="text-center p-4 border-right">
                            <div class="avatar-sm mx-auto mb-4">
                                <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">{{item_info.initials}}</span>
                            </div>
                            <h5 class="text-truncate">{{item_info.name}}</h5>
                        </div>
                    </div>

                    <div class="col-xl-8">
                        <div class="p-4 text-center text-xl-left">
                            <div class="row">

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Service Providers</p>
                                    <h5>{{item_info.assigned_containers_count}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Assets in Storage</p>
                                    <h5>{{item_info.assigned_assets_count}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Orders</p>
                                    <h5>{{item_info.assigned_containers_count}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Users Assigned</p>
                                    <h6>
                                        <a class="text-secondary" href="#">All <span class="badge badge-primary">{{item_info.active_users_count + item_info.inactive_users_count}}</span></a>
                                        |
                                        <a class="text-secondary" href="#">Active <span class="badge badge-success">{{item_info.active_users_count}}</span></a>
                                        |
                                        <a class="text-secondary" href="#">Inactive <span class="badge badge-danger">{{item_info.inactive_users_count}}</span></a>
                                    </h6>
                                </div>


                                <div v-if="item_info.relation_meta" class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Account No.</p>
                                    <h5>{{item_info.relation_meta.account_no}}</h5>
                                </div>

                                <div class="col-md-6">
                                    <p class="text-muted mb-2 text-truncate">Other Info...</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </details-section>

        </div>

    </div>
</template>

<script>

import DetailsSection from "../../customers/reusables/DetailsSection";
import AddNewDepartment from "../../customers/extras/AddNewDepartment";
import EntityDetailsDetails from "./EntityDetailsDetails";
import EntityDashboardBlock from "./ModuleDashboards/EntityDashboardBlock";

export default {
        name: "ItemDetails",
        components: {EntityDashboardBlock, EntityDetailsDetails, AddNewDepartment, DetailsSection},
        data() {
            return {
                image_width: process.env.MIX_DEFAULT_IMGS_WIDTH
            }
        },
        props: {
            tab_name: String,
            item_info: {
                type: Object,
                default: () => {
                    return {}
                },
            },
        },

    methods: {
        getUserStatus(user_info){
            let status = user_info.user_state;
            if (user_info.status === 0){
                status = user_info.status;
            }

            let user_states_list = [
                'Disabled',
                'Active',
                'Invited',
                'Created'
            ];
            let user_icons_list = [
                'red',
                'green',
                'brown',
                'grey'
            ];
            return '<span class="badge text-white p-2 status-background-' + user_icons_list[status] + '">' + user_states_list[status] + '</span>';
        },
        departmentEdited(edited_item, item_type, event){
            this.item_info.name = edited_item.name;
            this.item_info.status = edited_item.status;
        },
        editDepartment(event){
            this.$refs['add-new-department'].setSelectedItem(this.item_info);
            this.$bvModal.show('create-department');
        },
        itemsContains(n) {
            return !(['customer_details', 'group_details', 'sps_details', 'customer_sp_relation', 'related_items', 'listing_preview'].indexOf(n) > -1);
        }
    }
}
</script>

