import Resource from '@/api/resource';
const tagsResource = new Resource('api/assetflow/tags');

export default {
    data(){
        return {
            tag_box_unique_id: '',
            all_tags_list: [],
            isLoadingTags: [],
            listQuery:{},
            current_customer: null,
            current_user_info: null,
            current_sp: null,
            required_item_info: null
        }
    },
    created: function () {

        this.required_item_info = this.item_info;
        if (this.required_item_info && this.required_item_info.item_type === 'managed_storages' && this.required_item_info.hasOwnProperty('item_info') && this.required_item_info.item_info){
            this.required_item_info = this.required_item_info.item_info;
        }

        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
            this.current_sp = this.current_user_info.current_sp;
        }
        this.getTagsList();
    },
    methods: {
        handleQueryItems() {
            if (this.current_sp && this.item_info && this.item_info.current_page_type === 'sp') {
                this.listQuery.is_short_list = true
                this.listQuery.with_customer_info = true
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_info && this.item_info.current_page_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getTagsList(){
            this.isLoadingTags = true;
            this.handleQueryItems();

            const { data } = await tagsResource.list(this.listQuery, null, true);

            this.isLoadingTags = false;
            if (Object.keys(data).includes('data')){
                if (this.current_sp && this.item_info && this.item_info.current_page_type === 'sp' && this.required_item_info && this.required_item_info.hasOwnProperty('customer_id')) {
                    let required_customer_id = this.required_item_info.customer_id;
                    this.all_tags_list = data.data.items.filter((tag) => {
                        return tag.customer_id === required_customer_id;
                    });
                }else{
                    this.all_tags_list = data.data.items;
                }
                if (this.$refs['tags-manager2' + this.tag_box_unique_id]){
                    this.$refs['tags-manager2' + this.tag_box_unique_id].set_all_tags_list(this.all_tags_list);
                }

            }else{
                console.log('Response Error');
            }
        },
        saveTags(asset){
            if (this.groupBy === 4){
                this.$emit('on-updating-tags');
            }

            let message = "Updated Tags List of {"+asset.name+"} Successfully";
            this.set_item_value('assets', asset.id, 'tag_ids', this.required_item_info.tag_ids, message, false);

        },
        async myAddTag(inputAttrs, asset, assetKey, resolve) {

            if (inputAttrs.value){
                const tags_info_index = this.all_tags_list.map(function (e) {
                    return e.name;
                }).indexOf(inputAttrs.value);

                if (tags_info_index !== -1){
                    this.presentToast("Error!",
                        'Tag name already in use',
                        'danger',
                        3000,
                        true,
                        true);
                    return;
                }
            }

            this.isAddingTag = true;
            let params = {
                name: inputAttrs.value,
                description: inputAttrs.value,
                item_class: 'App\\Asset',
                customer_id: asset.hasOwnProperty('customer_info') && asset.customer_info ? asset.customer_info.id : (asset.hasOwnProperty('customer_id') ? asset.customer_id : '')
            };
            const { data } = await tagsResource.store(params);

            let new_tag = data.data.items;

            if (new_tag){
                if (!this.required_item_info.tag_ids){
                    this.required_item_info.tag_ids = [];
                    this.required_item_info.tags_info = [];
                }
                this.required_item_info.tag_ids.push(new_tag.id+"");

                this.required_item_info.tags_info.push(new_tag);

                this.all_tags_list.push(new_tag);
                resolve(this.all_tags_list);
            }else{
                this.presentToast((data.data.status ? "Success!" : "Error!"),
                    data.data.msg,
                    (status ? 'success' : 'danger'),
                    3000,
                    true,
                    true);
            }
            this.isAddingTag = false;

            this.saveTags(asset);
        },
        myRemoveTag(tag_id, asset, assetKey=0) {

            const tagIndex = this.all_tags_list.map(function (e) {
                return e.id;
            }).indexOf(tag_id);

            let tag = this.all_tags_list[tagIndex];

            if (!tag.id){
                tag = JSON.parse(tag);
            }

            let older_tags = this.required_item_info.tag_ids;
            let older_tags_info = this.required_item_info.tags_info;

            if (older_tags && older_tags.includes(tag_id+"")){
                // shall remove it
                tag.is_selected = false;
                const index = older_tags.indexOf(tag.id+"");

                if (index !== -1){
                    older_tags.splice(index, 1);
                }


                const tags_info_index = older_tags_info.map(function (e) {
                    return e.id;
                }).indexOf(tag.id);

                if (tags_info_index !== -1){
                    older_tags_info.splice(tags_info_index, 1);
                }

            }else{
                //shall add it
                tag.is_selected = true;
                if (!older_tags){
                    older_tags = [];
                    older_tags_info = [];
                }

                older_tags.push(tag_id+"");
                older_tags_info.push(tag);

            }

            this.required_item_info.tag_ids = older_tags

            this.all_tags_list[tagIndex] = tag;
            this.all_tags_list.push({id:'', name:''});
            this.all_tags_list.splice(-1, 1);


            this.saveTags(asset);

        },
    }
}
