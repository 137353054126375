<template>
    <div>
        <div class="row" v-for="(required_section, k) in required_sections" :key="k">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="row">
                        <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items">
                                </ChangeCommonFieldsAdvanced>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('second_required_items')" :class="required_section.second_required_items_class">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced ref="secondBlock" :add-form="addForm" :required_fields="required_section.second_required_items"/>
                            </div>
                        </div>
                        <div v-if="required_section.hasOwnProperty('third_required_items')" :class="required_section.third_required_items">
                            <div class="card-body">
                                <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.second_required_items"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>

import ChangeCommonFieldsAdvanced from "./ChangeCommonFieldsAdvanced";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import IntakeProfileShared from "@/mixins/IntakeProfileShared.mixin";

import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";

import AFButton from "@/components/AFButton";


export default {
    name: 'IntakeAssetIntakeFields',
    components: {AFButton, PoLines, ChangeCommonFieldsAdvanced},
    mixins: [IntakeProfileShared, dropzoneMixin],
    props: [
        'current_page_type',
        'use_saved_intake_profile',
        'recieved_asset_info',
    ],
    data(){
        return {
            asset_info: null,
            addForm:{
                quantity: 1,
                value_type: 0,
                intake_images: null,
                value_types: null,
                preferred_unit: null,
                width: null,
                height: null,
                depth: null,
                area: null,
                total_area: null,
                volume: null,
                total_volume: null,
                weight: null,
                total_weight: null,
                colours:[],
                composition:[],
                waste_stream:[],
                waste_stream_type:null,
                waste_stream_id:null,
                shall_update_parent: {id: 1, name: 'Yes'},
                notes: null,
            },
            required_sections:{
                intake_profile: {
                    section_name: 'Intake Profile',
                    required_items:{
                        value_types: {
                            key: 'value_types',
                            label: '',
                            type: 'value_types',
                            options:[]
                        },
                        preferred_unit: {
                            key: 'preferred_unit',
                            label: 'Preferred Unit',
                            type: 'radio-select',
                            is_lower_case: true,
                            required_options_class: 'col-4',
                            options:[]
                        },
                        height: {
                            key: 'height',
                            label: 'Height',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        width: {
                            key: 'width',
                            label: 'Width',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        depth: {
                            key: 'depth',
                            label: 'Depth',
                            type: 'number',
                            with_preferred_unit: true
                        },
                        weight: {
                            key: 'weight',
                            html_label: 'Weight <sup>kg</sup> for 1 Item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        total_weight: {
                            key: 'total_weight',
                            html_label: 'Weight <sup>kg</sup> for 1 item',
                            required_class: 'col-sm-12 col-lg-6',
                            type: 'number'
                        },
                        area: {
                            key: 'area',
                            label: 'Area For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_area: {
                            key: 'total_area',
                            label: 'Total Area',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        volume: {
                            key: 'volume',
                            label: 'Volume For 1 Item',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                        total_volume: {
                            key: 'total_volume',
                            label: 'Total Volume',
                            type: 'text',
                            is_readonly:true,
                            with_preferred_unit: true,
                            required_class: 'col-sm-12 col-lg-3',
                            custom_placeholder: 'Please enter height, width & depth'
                        },
                    },
                    required_items_class: 'col-sm-12',
                    second_required_items_class: 'col-sm-12',
                    second_required_items:{
                        colours: {
                            key: 'colours',
                            label: 'Colours',
                            type: 'custom-multi-select',
                            options: [
                            ]
                        },

                        waste_stream_type: {
                            key: 'waste_stream_type',
                            label: 'Waste Stream Type',
                            type: 'radio-select',
                            options:[
                                { id: 0, name: 'Product' },
                                { id: 1, name: 'Material' },
                            ]
                        },
                        waste_stream_id: {
                            key: 'waste_stream_id',
                            label: 'Product Waste Stream',
                            type: 'hidden_text',
                            options: []
                        },
                        composition: {
                            key: 'composition',
                            label: 'composition',
                            type: 'hidden_text',
                            options: []
                        },
                        shall_update_parent: {
                            key: 'shall_update_parent',
                            label: 'Update Asset Passport',
                            type: 'radio-select',
                            options: [
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                },
                            ]
                        },
                    },
                },
            },
            isAddingIntakeProfile: false,
            addedIntakeProfile: null,
            failedAddingIntakeProfile: false,
            value_type_options:[
                {
                    id: 0,
                    name: 'Dimensions for 1 item'
                },
                {
                    id: 1,
                    name: 'Dimensions for (x) items'
                }
            ],
        }
    },
    created() {
        if (this.use_saved_intake_profile === 0 && this.recieved_asset_info){
            this.setSelectedItem(this.recieved_asset_info);
        }
    },
    computed:{
    },
    watch:{
        addForm: {
            deep: true,
            handler: function (newValue) {
                this.$emit('form-values-updated', this.addForm);
            }
        },
        use_saved_intake_profile(value) {
            if (this.use_saved_intake_profile === 0 && this.recieved_asset_info){
                this.setSelectedItem(this.recieved_asset_info);
            }
        },
    },
    methods:{
        async getFieldsList(){
            this.prepareOtherFields();

            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                colours: 'colours_list',
                waste_stream_id: 'product_waste_streams_list',
                composition: 'compositions_list',
            }

            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'intake_modal',
                    object_type: 'intakes',
                    action: 'new_intake',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }
                this.required_sections.intake_profile.second_required_items.colours.options = this.lists_values.colours;
                this.required_sections.intake_profile.second_required_items.waste_stream_id.options = this.lists_values.waste_stream_id;
                this.required_sections.intake_profile.second_required_items.composition.options = this.lists_values.composition;

                if (this.asset_info){
                    let asset_info = this.asset_info;
                    this.setFormValue('preferred_unit', this.getObjectOf(asset_info.preferred_unit, this.required_sections.intake_profile.required_items.preferred_unit.options, 'name'));
                    this.handleChangingVolume();

                    this.setFormValue('waste_stream_id', this.getObjectOf(asset_info.waste_stream_id, this.required_sections.intake_profile.second_required_items.waste_stream_id.options));

                    this.addForm.colours = this.getValueOf('colours', asset_info.colours, true);
                    this.addForm.composition = this.getValueOf('composition', asset_info.composition, true, 'name');


                }


            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setSelectedItem(asset_info){

            this.addForm.width = asset_info.width;
            this.addForm.height = asset_info.height;
            this.addForm.depth = asset_info.depth;
            this.addForm.area = asset_info.area;
            this.addForm.total_area = asset_info.total_area;
            this.addForm.volume = asset_info.volume;
            this.addForm.total_volume = asset_info.total_volume;
            this.addForm.weight = asset_info.weight;
            this.addForm.total_weight = asset_info.total_weight;
            this.addForm.value_type = asset_info.value_type;


            this.setFormValue('waste_stream_type', this.getObjectOf(asset_info.waste_stream_type, this.required_sections.intake_profile.second_required_items.waste_stream_type.options));

            // keep it last to update all totals
            this.addForm.quantity = asset_info.quantity;

            this.asset_info = asset_info;

            this.getFieldsList();



        },
        /*
        * let params = {
            order_line_id: this.order_line.id,
            preferred_unit: this.addForm.preferred_unit ? this.addForm.preferred_unit.name : 'm',
            value_type: this.addForm.value_type, // 0 for one item >> do nothing, 1 for all items > divide the biggest by quantity
            width: this.addForm.width,
            height: this.addForm.height,
            depth: this.addForm.depth,
            area: this.addForm.area,
            total_area: this.addForm.total_area,
            volume: this.addForm.volume,
            total_volume: this.addForm.total_volume,
            weight: this.addForm.weight,
            total_weight: this.addForm.total_weight,
            colours: this.addForm.colours ? this.getIDs(this.addForm.colours) : null,

            waste_stream_type: this.addForm.waste_stream_type ? this.addForm.waste_stream_type.id : null,
            waste_stream_id: this.addForm.waste_stream_id ? this.addForm.waste_stream_id.id : null,
            composition: this.addForm.composition ? this.getIDs(this.addForm.composition, false, 'name') : null,

            shall_update_parent: this.addForm.shall_update_parent ? this.addForm.shall_update_parent.id : null,
        };

        if (this.asset_info && this.asset_info.hasOwnProperty('id')){
            params.ip_item_id = this.asset_info.id;
        }
        * */

    }
}
</script>
