<template>
    <Layout>
        <AddNewChange
            ref="add-new-change"
            item_type='changes'
            modal_title="Create New Change"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </AddNewChange>


        <EditChangeNotes
            ref="edit-change-notes"
            item_type='changes-notes'
            modal_title="Edit Change Notes"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </EditChangeNotes>

        <EditChangeScenes
            ref="edit-change-scenes"
            item_type='changes-scenes'
            modal_title="Edit Change Scenes"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </EditChangeScenes>

        <EditChangeSetup
            ref="edit-change-setup"
            item_type='changes-setup'
            modal_title="Edit Change Setup"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </EditChangeSetup>

        <EditGeneratePdf
            ref="edit-generate-pdf"
            item_type="generate-pdf"
            modal_title="Generate PDF"
            object_type="change"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </EditGeneratePdf>


        <div class="changes-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Changes List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Changes.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('change')">Change
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-if="supportedLayouts && Object.keys(supportedLayouts).length > 0"
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Product Section Start-->
                    <LotsOfItemsAlert :perpage="perpage"/>

                    <div v-if="!has_item_selected" class="products text-center">
                        <h4>Please select a character</h4>
                    </div>

                    <div v-else-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Changes...</h4>
                    </div>
                    <div v-else-if="!changesData || total === 0 || sortedItemsList.length === 0" class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Changes found for this character.</h4>
                    </div>
                    <div v-else-if="[0, 1].includes(selectedLayout) && changesData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="permission_name"
                                :keys_list="change_keys_list">

                                <template v-slot:new_line="{ item, index }" v-if="selectedLayout === 0">
                                    <div class="tbody_item col-xs-12 col-sm-12 col-lg-12 scene_notes">
                                        <span v-if="item.hasOwnProperty('parts_info') && item.parts_info">
                                            <a v-for="part_info in item.parts_info" :href="'/' + item_type + '/parts/' + part_info.id + '/view'" class="text-dark scene-part-name">{{ (part_info.hasOwnProperty('scene_info') && part_info.scene_info ? part_info.scene_info.name + ' ' : '') + part_info.name }}</a>
                                        </span>
                                    </div>
                                </template>

                                <template v-slot:changes_actions="{ item, index }">
                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>

                                        <ChangeActionsList :item="item" :index="index" :item_type="item_type" :object_type="permission_name" :perform-action="performAction"/>

                                    </div>
                                </template>

                            </po-lines>
                        </div>
                    </div>
                    <div v-else-if="changesData && [2, 3].includes(selectedLayout)" class="cm_list_view_wrapper">


                        <div class="popup-gallery">
                            <vue-easy-lightbox
                                :visible="visibleCaption"
                                :imgs="captionimgs"
                                :index="index"
                                @hide="handleCaptionHide">
                            </vue-easy-lightbox>
                        </div>


                        <div class="cm_list_view_wrapper">
                            <SetChangeSceneDescription
                                ref="set-change-scene-description"
                                item_type='description-changes'
                                modal_title="Update Scene Notes"
                                @on-new-item-adding-in-list="AfterSettingDescription"
                            >
                            </SetChangeSceneDescription>

                            <SetChangeSceneImages
                                ref="set-change-scene-images"
                                item_type='scene-note-images'
                                modal_id="create-scene-note-images"
                                modal_title="Update Scene Note Images"
                                @on-new-item-adding-in-list="AfterSettingImages"
                            >
                            </SetChangeSceneImages>
                            <ChangeListView
                                v-for="(change, changeKey) in sortedItemsList"
                                :key="changeKey"
                                :item_type="item_type"
                                :item_key="changeKey"
                                :item_info="change"
                                :with_parts_section="selectedLayout === 2"
                                :show-captiom-img="showCaptiomImg"
                                :perform-action="performAction"
                                :add-description-modal="addDescriptionModal"
                                :update-images-modal="updateImagesModal"
                            />
                        </div>
                    </div>

                    <!-- ELSE other layouts -->


                    <!-- Product Section End-->
                    <div class="pagination" v-if="sortedItemsList.length > 0">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewOrderLine from "../extras/AddNewOrderLine";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";

import VueEasyLightbox from 'vue-easy-lightbox';
import ImageGallery from "@/mixins/ImageGallery.mixin";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";

const changeResource = new Resource('api/assetflow/changes');
import {ChangeKeysList} from "@/views/assetflow/form_data/ChangeKeysList";
import AddNewChange from "../extras/AddNewChange";
import EditChangeNotes from "../extras/EditChangeNotes";
import EditChangeScenes from "../extras/EditChangeScenes";
import EditChangeSetup from "../extras/EditChangeSetup";

import ChangeListView from "./ChangeListView";
import ChangeActionsList from "../../items/ChangeActionsList";
import SetChangeSceneDescription from "../../items/ChangeSceneRel/SetChangeSceneDescription";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import SetChangeSceneImages from "../../items/ChangeSceneRel/SetChangeSceneImages";
import EditGeneratePdf from "../extras/EditGeneratePdf";

export default {
    name: 'ChangeList',
    props: ['item_id', 'item_type'],
    components: {
        EditGeneratePdf,
        SetChangeSceneImages,
        LotsOfItemsAlert,
        VueEasyLightbox,
        SetChangeSceneDescription,
        ChangeActionsList,
        AddNewChange,
        EditChangeNotes,
        EditChangeScenes,
        EditChangeSetup,
        ObjectsSidebar, PoLines, AddNewOrderLine, SearchBox, Layout, ChangeListView},
    mixins: [TimelineCommons, ObjectsSidebarShared, ImageGallery],
    data() {
        return {
            total: 0,
            has_item_selected: false,
            changesData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Changes",
            items: [
                {
                    text: "Changes",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_change_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 0,
                    text: 'Change Order',
                    first_criteria: 'target_change',
                    first_reversed: false,
                    name: 'change_order',
                    second_criteria: 'change_order',
                    second_reversed: false,
                    third_criteria: 'name',
                    third_reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'changes',

            object_type: 'changes',
            groupBy_options: [
                {
                    id: 0,
                    label: 'Characters',
                    backend_key: 'character_id'
                },
            ],
            items_list: [
                // Characters
                [],
            ],
            loadableOptions: [
                {
                    index: 0, // index in items_list
                    object_name: 'characters'
                },
            ],
            filter_classes: {
                '0': 'change-characters'
            },
            change_keys_list: ChangeKeysList,
            listLoading: false
        };
    },
    created() {
        this.current_groupBy = 0;
        this.sorting.sortOptions.push(this.sorting.sortType);
        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
            list_parts: {
                name: 'List - No Scenes',
                value: 1,
                class: 'smaller-list',
                isActive: false
            },
            grid: {
                name: 'Grid',
                value: 2,
                class: 'grid',
                isActive: true
            },
            grid_small: {
                name: 'Grid - No Scene Notes',
                value: 3,
                class: 'smaller-grid',
                isActive: false
            },
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        this.has_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];
        if (!this.has_actions_permissions){
            delete this.change_keys_list.actions;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_changes_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.change_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.change_preferred_sorting;
        }

    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        addDescriptionModal(item, item_index, change_index, event){
            this.$refs['set-change-scene-description'].setSelectedItem({
                description: item.part_change_description,
                item_id: item.part_change_id,
                item_index: item_index,
                change_index: change_index
            });
            this.$bvModal.show('create-description-changes');
        },
        AfterSettingDescription(item_index, change_index, description){

            let sortedItemsList = this.sortedItemsList;
            if (sortedItemsList.length > change_index){
                let sorted_parts_info = sortedItemsList[change_index].parts_info;
                if (sorted_parts_info.length > item_index){
                    sorted_parts_info[item_index].part_change_description = description;
                }
            }
        },
        updateImagesModal(item, item_index, change_index, event){
            this.$refs['set-change-scene-images'].setSelectedItem({
                asset_images: item.scene_note_images,
                item_id: item.part_change_id,
                item_index: item_index,
                change_index: change_index
            });
            this.$bvModal.show('create-scene-note-images');
        },
        AfterSettingImages(item_index, change_index, scene_note_images){
            console.log("item_index");
            console.log(item_index);
            console.log("change_index");
            console.log(change_index);
            console.log("scene_note_images");
            console.log(scene_note_images);


            let sortedItemsList = this.sortedItemsList;
            if (sortedItemsList.length > change_index){
                let sorted_parts_info = sortedItemsList[change_index].parts_info;
                if (sorted_parts_info.length > item_index){
                    sorted_parts_info[item_index].scene_note_images = scene_note_images;
                }
            }
        },
        performAction(change, action, changeKey){

            if (action === 'delete'){
                if (change.id !== ''){
                    this.deleteItemByID(change.id, change.name, this.object_type, change)
                        .then((response) => {
                            if (response.data.data.status){
                                this.changesData.splice(changeKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }else{
                let change_info_for_modal = Object.assign({}, change);

                switch (action) {
                    case 'change_notes':
                        change_info_for_modal.change_status = change.status;
                        this.$refs['edit-change-notes'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-notes');
                        break;
                    case 'change_scenes':
                        change_info_for_modal.part_ids = change.parts_info.map(function(e) { return e.id; });
                        this.$refs['edit-change-scenes'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-scenes');
                        break;
                    case 'change_setup':
                        change_info_for_modal.character_id = change.character_id;
                        change_info_for_modal.number = change.name;
                        this.$refs['edit-change-setup'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-changes-setup');
                        break;
                    case 'generate_pdf':
                        this.$refs['edit-generate-pdf'].setSelectedItem(change_info_for_modal);
                        this.$bvModal.show('create-generate-pdf');
                        break;
                }
            }

        },
        searchFor(keyword, change) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'name'];
                    return this.handleSearch(searchable_values, element, wordToCompare);
                }
                filtered_list = this.changesData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.changesData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('change_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_changes_per_page', perPage, message);
            this.refreshContent();
        },
        stopGettingList: function () {
            this.has_item_selected = false;
            this.total = 0;
            this.changesData = [];
            this.request_meta = {};
            this.listLoading = false;
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;
            // >>> shall be edited <<<
            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            if (required_query.hasOwnProperty('character_id')){
                if (required_query.character_id !== 0){
                    this.has_item_selected = true;
                // }else{
                //     this.stopGettingList();
                //     return;
                }
            }else if (this.current_groupBy === 0){
                this.stopGettingList();
                return;
            }else if (this.current_groupBy !== 0){
                this.has_item_selected = true;
            }


            const {data} = await changeResource.list(required_query);
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.changesData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }

        },

        addNewItem(change_type) {
            this.$refs['add-new-change'].setSelectedItem(null);
            this.$bvModal.show('create-changes');
        }
    },
}
</script>


