<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }} Settings</h4>
                    <div>
                        Tabs, Valuations, Page Totals
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "accounting_general",
    props:['selected_setting'],
    components: {},
    data(){
        return {
        }
    }
}
</script>

