import vue2Dropzone from "vue2-dropzone";

export default {
    components:{ vueDropzone: vue2Dropzone },
    data(){
        return {
            dropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.template()
            },
            assetDropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.asset_template()
            },
            asset_images_previewElements: {},
        }
    },
    created: function () {
    },
    methods: {
        organizeAssetImages: function (asset_images) {
            let asset_images_copy = [...asset_images];
            asset_images_copy = asset_images_copy.map(function (image) {
                let new_image = {
                    item_type: image.item_type,
                    uuid: image.uuid,
                    is_featured: image.is_featured,
                    add_to_ap: image.add_to_ap,
                    image_title: image.image_title,
                    image_description: image.image_description,
                    width: image.width,
                    height: image.height,
                    key: image.key,
                    name: image.name,
                }
                if (image.hasOwnProperty('id')) {
                    new_image.id = image.id;
                }

                return new_image;
            });
            return asset_images_copy;
        },
        checkAndGetFiles(){
            var j, len, file;
            var allfiles = []
            if (this.addForm.hasOwnProperty('filled_asset_images') && this.addForm.filled_asset_images){

                let all_uploads_finished = this.addForm.filled_asset_images.every(x => x.upload_progress === 100 && x.status !== 'canceled');
                if (!all_uploads_finished){
                    this.buttonkey++;
                    this.presentToast("Error",
                        'Uploading images, Please wait.',
                        'info',
                        3000,
                        true,
                        true);
                    return false
                }


                for (j = 0, len = this.addForm.filled_asset_images.length; j < len; j++) {
                    file = this.addForm.filled_asset_images[j];

                    var previewElement;
                    if (this.asset_images_previewElements[file.id]){
                        previewElement = this.asset_images_previewElements[file.id];
                    }else{
                        previewElement = file.previewElement;
                    }

                    if (previewElement){
                        var is_featuredRef = previewElement.querySelectorAll("[data-dz-is_featured]");
                        if (is_featuredRef){
                            var is_featuredElement = is_featuredRef[0];
                            if (is_featuredElement){
                                file.is_featured = is_featuredElement.checked
                            }

                        }

                        var add_to_apRef = previewElement.querySelectorAll("[data-dz-add_to_ap]");
                        if (add_to_apRef){
                            var add_to_apElement = add_to_apRef[0];
                            if (add_to_apElement){
                                file.add_to_ap = add_to_apElement.checked
                            }
                        }

                        let image_titleRef = previewElement.querySelectorAll("[data-dz-image_title]");
                        if (image_titleRef){
                            let image_titleElement = image_titleRef[0];
                            if (image_titleElement){
                                file.image_title = image_titleElement.value
                            }
                        }


                        let image_descriptionRef = previewElement.querySelectorAll("[data-dz-image_description]");
                        if (image_descriptionRef){
                            let image_descriptionElement = image_descriptionRef[0];
                            if (image_descriptionElement){
                                file.image_description = image_descriptionElement.value
                            }
                        }
                    }

                    file.is_receipt = 1;
                    allfiles.push(file);
                }
            }

            return allfiles;
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                            <div class="dz-image">
                                <div data-dz-thumbnail-bg></div>
                            </div>
                            <div class="dz-details">
                                <div class="dz-image_title">
                                    <div class="common--input-filed">
                                        <input type="text" data-dz-image_title name="image_title" placeholder="Image Title" />
                                    </div>
                                </div>
                                <div class="dz-image_description">
                                    <div class="common--input-filed">
                                        <textarea data-dz-image_description name="image_description" placeholder="Image Description"></textarea>
                                    </div>
                                </div>

                            </div>
                            <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                            <div class="dz-error-message"><span data-dz-errormessage></span></div>
                            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                            <a class="dz-remove btn btn btn-sm mt-1 btn-block btn-outline-danger" data-dz-remove="">Remove file</a>
                        </div>`;
        },

        asset_template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">

                    <div class="dz-image_title">
                        <div class="common--input-filed">
                            <input type="text" data-dz-image_title name="image_title" placeholder="Image Title" />
                        </div>
                    </div>
                    <div class="dz-image_description">
                        <div class="common--input-filed">
                            <textarea data-dz-image_description name="image_description" placeholder="Image Description"></textarea>
                        </div>
                    </div>

                    <div class="dz-size">
                        <label><input type="checkbox" data-dz-is_featured name="is_featured[]" /> Is Featured?</label>
                    </div>
                    <div class="dz-size">
                        <label><input type="checkbox" data-dz-add_to_ap name="add_to_ap[]" /> Add to Asset Passport?</label>
                    </div>
                </div>
                <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                <a class="dz-remove btn btn btn-sm mt-1 btn-block btn-outline-danger" data-dz-remove="">Remove file</a>
            </div>`;
        },
        thumbnail: function(file, dataUrl) {
            var j, len, ref, thumbnailElement, is_featuredRef, add_to_apRef, is_featuredElement, add_to_apElement;

            file.previewElement.classList.remove("dz-file-preview");

            if (file.manuallyAdded && file.id){
                this.asset_images_previewElements[file.id] = file.previewElement;
            }

            ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");

            for (j = 0, len = ref.length; j < len; j++) {
                thumbnailElement = ref[j];
                thumbnailElement.alt = file.name;
                thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }

            is_featuredRef = file.previewElement.querySelectorAll("[data-dz-is_featured]");
            if (is_featuredRef){
                is_featuredElement = is_featuredRef[0];
                is_featuredElement.value = file.upload ? file.upload.uuid : file.id;
                is_featuredElement.checked = file.is_featured === 1;
            }
            add_to_apRef = file.previewElement.querySelectorAll("[data-dz-add_to_ap]");
            if (add_to_apRef){
                add_to_apElement = add_to_apRef[0];
                add_to_apElement.value = file.upload ? file.upload.uuid : file.id;
                add_to_apElement.checked = file.add_to_ap === 1;
            }


            let image_title = file.previewElement.querySelectorAll("[data-dz-image_title]");
            if (image_title && file.name){
                let image_titleElement = image_title[0];
                image_titleElement.value = file.name;
            }

            let image_description = file.previewElement.querySelectorAll("[data-dz-image_description]");
            if (image_description && file.description){
                let image_descriptionElement = image_description[0];
                image_descriptionElement.value = file.description;
            }


            return setTimeout(((function(_this) {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        },

        fileRemoved(file, error, xhr) {
            let file_id = file.id;
            if (!file_id){
                return;
            }

            if (this.cancelTokens && this.cancelTokens[file_id]){
                this.cancelTokens[file_id].cancel('Removed file?');
            }

            let index = this.addForm.filled_asset_images.map(function(e) { return e.id; }).indexOf(file_id);
            if (index > -1 && this.addForm.filled_asset_images.length > index){
                this.addForm.asset_images.splice(index, 1);
                this.addForm.filled_asset_images.splice(index, 1);
            }

            // shall check if enabled again?

            if (this.addForm.filled_asset_images.length === 0 || this.addForm.filled_asset_images.every(x => x.upload_progress === 100)){
                this.is_saving_enabled = true;
            }


        },
        fileAdded(file) {
            let file_id = file.hasOwnProperty('upload') ? file.upload.uuid : file.id;
            file.id = file_id;

            let has_s3_uuid = file.hasOwnProperty('s3_uuid');

            if (has_s3_uuid){
                if (file.previewElement) {
                    let progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                    progressElement.style.width = 100 + "%";
                    progressElement.setAttribute("aria-valuenow", 100);
                    progressElement.textContent = 'Done';
                }
            }

            if (!this.addForm.filled_asset_images){
                this.addForm.filled_asset_images = [];
            }
            let foundFile = null;
            let index = null;
            if (file_id){
                index = this.addForm.filled_asset_images.map(function(e) { return e.id; }).indexOf(file_id);
                foundFile = this.addForm.filled_asset_images[index];
            }

            if (foundFile && file.manuallyAdded){
                this.addForm.filled_asset_images[index] = file;
            }else{
                this.addForm.filled_asset_images.push(file);
                index = this.addForm.filled_asset_images.length - 1;
            }


            if (!has_s3_uuid){
                this.saveFileToS3(file, index);
            }


        },
        maxFilesExceeded(file){
            // todo: should show error message?
        },
        resetDropZone(){
            this.$refs.myVueDropzone.removeAllFiles();
            this.addForm.filled_asset_images = []
        },
    }
}
