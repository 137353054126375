<template>
    <div class="btn-group bl-auto">
        <button type="button" class="btn btn-none dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <b-dropdown-item v-if="permissions_list['view_' + object_type]"
                             :href="'/' + item_type + '/managed_storages/' + item.id + '/view'">
                View
            </b-dropdown-item>
            <b-dropdown-item v-if="permissions_list['edit_' + object_type]"
                             @click="performAction('edit')">
                Edit
            </b-dropdown-item>

            <slot v-if="custom_slot_names" v-for="custom_slot_name in custom_slot_names" :name="custom_slot_name"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagedStorageActions',
    props: {
        index: {},
        item: {},
        item_type: {},
        object_type: {},
        permissions_list: {},
        deletion_condition: null,
        custom_slot_names: null
    },
    methods:{
        performAction(action){
            this.$emit('on-perform-action', this.item, action, this.index);
        }
    }
}
</script>
