<template>
    <div class="product-details-left" v-if="edited_image">

        <div class="image-header">
            <h3 class="product-title text-truncate">{{ edited_image.name }}</h3>
            <p>{{ edited_image.description ? edited_image.description : '-' }}</p>
            <ul>
                <li v-if="edited_image.user">User<b><a
                    :href="'/' + item_type + '/users/' + edited_image.user.id + '/view'">{{
                    edited_image.user.name }}</a></b></li>
                <li v-if="object_type && object_type === 'assets'">Asset Passport<b class="is-ap-image">{{ edited_image.add_to_ap ? 'Yes' : 'No' }}</b></li>
                <li>Featured<b class="featured-image">{{ edited_image.is_featured ? 'Yes' : 'No' }}</b></li>
            </ul>
        </div>

        <div class="image-footer">
            <div v-if="edited_image.hasOwnProperty('name') && edited_image.name !== ''" class="af-image-description-block">
                <button type="button" @click.stop.prevent="$root.presentModal('edit_image_info', edited_image, '', fireDoneAction)" class="btn btn-sm btn-success af-image-edit-info"><i class="fas fa-pen font-size-11"></i></button>
                <button type="button" @click.stop.prevent="$root.presentModal('edit_image', edited_image, image_size, fireDoneAction)" class="btn btn-sm btn-success af-image-edit-image"><i class="fas fa-crop font-size-11"></i></button>
                <button type="button" @click.stop.prevent="$root.presentModal('show_full_image', edited_image, '', fireDoneAction)" class="btn btn-sm btn-success af-image-preview-info"><i class="fas fa-image font-size-11"></i></button>
                <button type="button" @click.stop.prevent="$root.presentModal('show_media', edited_image, '', fireDoneAction)" class="btn btn-sm btn-success af-image-preview-info"><i class="fas fa-images font-size-11"></i></button>
                <button type="button" v-if="hasPermission('delete_images')" @click.stop.prevent="$root.presentModal('delete_image', edited_image, '', fireDoneAction)" class="btn btn-sm btn-success af-image-delete-image"><i class="fas fa-trash-alt font-size-11"></i></button>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'ImageObjectExtraInfo',
    props: {
        image: {},
        index: {},
        performAction: {},
        item_type: {},
        image_size: {},
        object_type: {}
    },
    data(){
        return {
            edited_image: null
        }
    },
    mounted() {
        this.edited_image = this.image;
    },
    methods:{
        fireDoneAction(edited_image){
            this.edited_image.image_name = (edited_image.image_name + (this.image_size ? this.image_size: '')) + '?t=' + Date.now();
            this.$set(this, 'edited_image', edited_image);
            this.$emit('on-save-item-image', this.index, edited_image);
        }
    }
}
</script>
