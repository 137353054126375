<template>
    <div class="btn-group bl-auto">
        <button type="button" class="btn btn-none dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">

            <b-dropdown-item v-if="hasPermission('add_' + object_type)"
                             @click="performAction(item, 'add', index, $event)">
                Sub Folder
            </b-dropdown-item>
            <b-dropdown-item v-if="hasPermission('edit_' + object_type)"
                             @click="performAction(item, 'edit', index, $event)">
                Edit
            </b-dropdown-item>
            <b-dropdown-item v-if="hasPermission('delete_' + object_type)"
                             variant="danger"
                             @click="performAction(item, 'delete' + object_type, index, $event)">
                Delete
            </b-dropdown-item>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FolderActionsList',
    props: {
        item: {},
        item_type: {},
        object_type: {},
        index: {},
        performAction: {}
    }
}
</script>
