export const EventKeysList = {
    id: {
        key: 'id',
        label: "ID",
        class: "col-lg-1 e_id",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 e_name",
        show_on_mobile: true,
        case: 'custom_html_tooltip',
        title_func: function (index, item, key) {
            return 'Created at: ' + Vue.options.filters.moment(item.created_at, 'MMMM Do YYYY');
        },
        value_func: function (index, item, key) {
            return Vue.options.filters.titleize(item[key]);
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-2 e_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                {
                    name: 'Inactive',
                    class: 'status-btn-disposed',
                },
                {
                    name: 'Active',
                    class: 'status-btn-active',
                }
            ];
            return '<span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span>';
        }
    },
    start_date: {
        key: 'start_date',
        label: "Start Date",
        class: "col-lg-2 e_start_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    finish_date: {
        key: 'finish_date',
        label: "Finish Date",
        class: "col-lg-2 e_finish_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    assets_count: {
        key: 'assets_count',
        label: "Assets",
        class: "col-lg-2 s_assets_count",
        show_on_mobile: false,
        case: ''
    },
    assets_sum_quantity: {
        key: 'assets_sum_quantity',
        label: "Asset Qty",
        class: "col-lg-2 s_assets_sum_quantity",
        show_on_mobile: false,
        case: ''
    },
    event_tasks: {
        key: 'event_tasks',
        label: "Tasks",
        class: "col-lg-2 s_event_tasks",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let html = '<ul class="tasks-list tasks-list-' + item.id + '">';
            for (const itemElementKey in item[key]) {
                let custom_field = item[key][itemElementKey];
                html += '<li>' + custom_field.name + '</li>';
            }
            html += '<ul>';
            return html;
        }
    },
    user_id: {
        key: 'user_id',
        label: "Created By",
        class: "col-lg-2 s_user_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            const firstPath = window.location.pathname.split('/')[1];
            return '<a href="/' + firstPath + '/users/'+item.user_id+'/view" class="text-dark">' + Vue.options.filters.titleize((item.hasOwnProperty('user_info') ? item.user_info.name : '-')) + '</a>';
        }
    },
};
