<template>
    <div class="row ol-action" style="margin-bottom: 10px;">
        <div v-if="order_line.order_type === 0" class="col-lg-12 mts-actions">
            <div class="row">
                <div class="col-lg-2">
                    <p class="task-action">
                        <i class="fa fa-arrow-right"></i> <a class="text-dark">Intake</a>
                    </p>
                </div>

                <div class="col-lg-8">
                    <p class="task-action">
                        <span class="item-header">
                            <a class="text-dark" :href="'/' + current_page_type + '/order_lines/' + order_line.id + '/view'">{{ order_line.uid }} was completed.</a>
                        </span>
                    </p>

                    <p class="task-action" v-if="managed_storage_info">
                        <span class="item-header">
                            <a class="text-dark" :href="'/' + current_page_type + '/managed_storages/' + managed_storage_info.id + '/view'">{{ managed_storage_info.uid }}</a> was created.
                        </span>
                    </p>
                </div>


                <div class="col-lg-2">
                    <slot name="order_lines_task_actions" v-bind:order_line="order_line" v-bind:index="index"></slot>
                </div>

            </div>
        </div>

        <div v-else-if="!is_cross_entity && current_page_type === 'customer'" class="col-lg-12 sto-actions">
            <div class="row">
                <div class="col-lg-2">
                    <p class="task-action">
                        <i class="fa fa-arrow-right"></i> <a class="text-dark">Task</a>
                    </p>
                </div>

                <div class="col-lg-10">
                    <p class="task-action">
                        <span class="item-header" v-if="order_line.status === 0">Waiting for order confirmation.</span>
                        <span class="item-header" v-else-if="[1,2, 3].includes(order_line.status)">
                            <a v-if="order_line.sp_info" class="text-dark" :href="'/' + current_page_type + '/service_providers/' + order_line.sp_info.id + '/view'">{{order_line.sp_info.name | titleize}}</a> {{[1,2].includes(order_line.status) ? 'will complete' : 'has completed'}} this task.</span>
                    </p>
                </div>

            </div>

        </div>
        <div v-else class="col-lg-12 sto-actions">
            <div class="row">
                <div class="col-lg-3">
                    <p class="task-action">
                        <i class="fa fa-arrow-right"></i> <a class="text-dark">Movement Required</a>
                    </p>
                </div>

                <div class="col-lg-3">
                    <p class="object-title-detail">
                        <span class="item-header">LOCATION</span>
                    </p>
                    <div v-html="getLocationHTML()"></div>
                </div>

                <template v-for="(movement_info, mindex) in ['storage_info', 'order_info']">

                    <div class="col-lg-2"
                         v-if="movement_info === 'storage_info' && sp_wsl_info && sp_wsl_info.hasOwnProperty('storage_info')"
                         v-html="getSpStorageInfoHTML(sp_wsl_info.storage_info, 'STORAGE')">
                    </div>
                    <div class="col-lg-2"
                         v-else-if="movement_info === 'order_info' && order_line.hasOwnProperty('order_info') && order_line.order_info">
                        <p class="object-title-detail">
                            <span class="item-header">
                                <a class="text-dark" :href="'/' + current_page_type + '/orders/' + order_line.order_info.id + '/view'">{{ order_line.order_type === 0 ? 'SOURCE' : 'TARGET' }}</a>
                            </span>
                        </p>
                        <p class="object-title-list">
                            <i class="fa fa-shopping-cart"></i>
                            <span class="item-header">
                                <a
                                    :title="order_line.order_info.name" v-b-tooltip.bottom
                                    class="text-dark"
                                   :href="'/' + current_page_type + '/orders/' + order_line.order_info.id + '/view'" >{{ order_line.order_info.uid }}</a>
                            </span>
                        </p>
                    </div>
                    <div class="col-lg-2" v-else>
                        <p class="object-title-detail">
                            <span class="item-header" v-if="movement_info === 'order_info'">TARGET</span>
                            <span class="item-header" v-else-if="movement_info === 'storage_info'">STORAGE</span>
                        </p>
                        <p class="task-action">-</p>
                    </div>

                </template>

                <div class="col-lg-2">
                    <slot name="order_lines_task_actions" v-bind:order_line="order_line" v-bind:index="index"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {getLocationDetails} from "@/views/assetflow/form_data/SpLocationSharedFunctions";
import {getSpStorageInfoBlock} from "@/views/assetflow/form_data/getSpStorageInfoBlock";

export default {
    name: 'OrderLineRelatedAction',
    props:[
        'order_line',
        'index',
        'current_page_type'
    ],
    methods:{
        getLocationHTML() {
            if (this.sp_wsl_info && this.sp_wsl_info.hasOwnProperty('location_info') && this.sp_wsl_info.location_info){
                return getLocationDetails(this.sp_wsl_info.location_info, true, true);
            }
            return '-';
        },
        getSpStorageInfoHTML(storage_info, details_text=null) {
            return getSpStorageInfoBlock(storage_info, false, details_text);
        },
    },
    computed:{
        managed_storage_info(){
            return this.order_line.hasOwnProperty('managed_storage_info') && this.order_line.managed_storage_info ? this.order_line.managed_storage_info : null;
        },
        sp_wsl_info(){
            return this.order_line.hasOwnProperty('sp_wsl_info') && this.order_line.sp_wsl_info ? this.order_line.sp_wsl_info : null;
        },
    }
}
</script>
