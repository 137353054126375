<script>
    import Layout from "../../layouts/main";
    import PageHeader from "../../components/page-header";
    export default {
        name: "PlaceholderPage",
        components: {
            Layout,
            PageHeader
        },
        data() {
            return {
                title: "Asset Flow",
                items: [{
                    text: "Dashboards",
                    href: "javascript:void(0)"
                },
                    {
                        text: "Asset Flow",
                        active: true
                    }
                ],
                warning: "",
                dismissSecs: 5,
                dismissCountDown: 15,
                currentUrl: ""

            };
        },
        props: ['is_404'],
        created() {
            this.warning = window.AF_WARNING;

            this.currentUrl = window.location.pathname.replace(/\//g, "_") + ' Page';
        },
        methods: {
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
        }
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <b-alert v-if="this.warning !== ''"
                 :show="dismissCountDown"
                 dismissible
                 variant="danger"
                 @dismissed="dismissCountDown=0"
                 @dismiss-count-down="countDownChanged"
                 class="mt-3">{{this.warning}}</b-alert>


        <div v-if="this.is_404" class="account-pages my-5 pt-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mb-5">
                            <h1 class="display-2 font-weight-medium">
                                4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4
                            </h1>
                            <h4 class="text-uppercase">Sorry, page not found</h4>
                            <div class="mt-5 text-center">
                                <a class="btn btn-primary" href="/">Back to Dashboard</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8 col-xl-6">
                        <div>
                            <img :src="getAssetBaseUrl + '/images/error-img.png'" alt class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row" v-else>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <a v-if="currentUrl" :href="currentUrl">{{currentUrl | titleize}}</a>
                        <h5 v-else>Asset Flow Home Page</h5>
                        <p>Statistics, Charts and Important info will be listed here.</p>
                        <img :src="getAssetBaseUrl + '/images/logo-dark.png'" alt="" width="300px">
                    </div>
                </div>
            </div>
        </div>

        <!-- end row -->
    </Layout>
</template>
