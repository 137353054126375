<template>
    <div class="common-step-panel use-section">
        <div class="row">
            <div class="col-sm-12">
                <setting-info-section
                    v-for="(features_section, key) in sidebar_navigation"
                    :key="key"
                    :title="features_section.label | titleize">
                    <div>
                        <div class="st--common--box" v-for="(feature, key) in features_section.settings">
                            <div class="common-st-title-label" v-if="feature.id !== 'custom_fields'">
                                <h5>{{ feature.label }}<sup v-if="feature.functionality === 'core'">Core</sup></h5>
                            </div>
                            <div class="st-status-radio-box">

                                <CustomFieldsRepeater
                                    v-if="feature.id === 'custom_fields'"
                                    :add-form="addForm"
                                    :required_field_name="feature.id"
                                    :force_refresh="true"/>

<!--                                // asset types-->
                                <div class="st-radio-row" v-else-if="feature.type === 'radio'">
                                    <div
                                        v-for="(option, index) in feature.options"
                                        :key="index"
                                        class="st-radio-col-2">
                                        <div class="cm-radio-btn mb-2" :class="(feature.id === 'asset_types' ? (feature.id + ' ' + feature.id) : 'status') + '-' + option.id">
                                            <label>
                                                <input
                                                    v-model="addForm[feature.id][option.id]"
                                                    :key="index"
                                                    :id="'_' + feature.id + '_'+option.id"
                                                    :name="'_' + feature.id + '_'+option.id"
                                                    :disabled="!feature.status"
                                                    :value="'1'"
                                                    @click="refreshAddForm(option.id, feature.id)"
                                                    type="radio" />
                                                <div class="st-radio-box">
                                                    <span></span>
                                                    <p>{{ option.name | titleize }}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

<!--                                //  checkbox with extra options for assigning defaults -->
                                <div v-else-if="feature.type === 'checkbox'">

                                    <div class="st-radio-row">
                                        <div v-for="(option, index) in enabling_options"
                                             :key="index"
                                             class="st-radio-col-2">
                                            <div class="cm-radio-btn" :class="'status-' + option.id" v-if="checkIfEnabled(option, feature)">
                                                <label>
                                                    <input
                                                        v-model="addForm[feature.id]"
                                                        :id="'_'+feature.id"
                                                        :name="'_'+feature.id"
                                                        :value="option.id"
                                                        :disabled="!feature.status"
                                                        type="radio"/>
                                                    <div class="st-radio-box" :class="'radio_' + (index === 0 ? 'enabled' : 'disabled')">
                                                        <span></span>
                                                        <p>{{ option.name | titleize }}</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="st-radio-row">
                                        <assign-item-by-default
                                            class="mt-3 st-radio-col-2"
                                            v-if="feature.with_assigning"
                                            :add-form.sync="addForm"
                                            :form_key="key"
                                            :setting="feature" />
                                    </div>
                                </div>

<!--                                // other settings (enable, disable) only -->
                                <div v-else class="st-radio-row">
                                    <div v-for="(option, index) in enabling_options"
                                         :key="index"
                                         class="st-radio-col-2">
                                        <div class="cm-radio-btn" :class="'status-' + option.id" v-if="checkIfEnabled(option, feature)">
                                            <label>
                                                <input
                                                    v-model="addForm[feature.id]"
                                                    :id="'_'+feature.id"
                                                    :name="'_'+feature.id"
                                                    :value="option.id"
                                                    :disabled="feature.functionality === 'core' && !feature.hasOwnProperty('with_assigning')"
                                                    type="radio"/>
                                                <div class="st-radio-box" :class="'radio_' + (index === 0 ? 'enabled' : 'disabled')">
                                                    <span></span>
                                                    <p>{{ option.name | titleize }}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </setting-info-section>



                <div class="common-step-button-block">
                    <div class="save-cancel-btn">
                        <a @click="shallSaveSettings($event)" class="btn btn-primary">Save</a>
                        <a href="./assets" class="btn btn-secondary">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SettingInfoSection from "../settingsForm/SettingInfoSection"
import SettingsShared from "@/mixins/SettingsShared.mixin";
import AssignItemByDefault from "../settingsForm/AssignItemByDefault";
import CustomFieldsRepeater from "../../../assets/assetForm/CustomFieldsRepeater";

export default {
    name: 'CustomerSettingsSection',
    components: {CustomFieldsRepeater, AssignItemByDefault, SettingInfoSection},
    mixins: [SettingsShared],
    props: {
        addForm: {},
        sidebar_navigation: {}
    },
    methods:{
        checkIfEnabled(option, feature){
            if (option.id === 0){
                if (feature.functionality !== 'core'){
                    if (!feature.status && option.id === 0){
                        return false;
                    }
                }else if (feature.functionality === 'core' && option.id === 0){
                    return false
                }
            }
            return true;
        },
        refreshAddForm(index, key){
            this.addForm[key][index] = (this.addForm[key][index] === '1' ? '0' : '1');
            this.$emit('update:addForm', Object.assign({}, this.addForm));
        },
        shallSaveSettings(){
            // let enabled_features = this.getEnabledFeatures(this.addForm.available_features);
            this.saveSettings(this.addForm, true);
        }
    }
}
</script>
