<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

                <span v-if="required_field.hasOwnProperty('note') && required_field.note !== ''">{{ required_field.note }}</span>
            </b-form-group>

        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const sceneResource = new Resource('api/assetflow/scenes');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewScene",
    props: ['modal_title', 'item_type'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                script_id: null,
                scene_name: null,
                before_after: '',
                scene_id: '',
            },
            required_fields:{
                script_id: {
                    key: 'script_id',
                    label: 'Script',
                    type: 'single-select',
                    options:[]
                },
                scene_name: {
                    key: 'scene_name',
                    label: 'Scene Name',
                    type: 'text'
                },
                scene_order: {
                    key: 'scene_order',
                    label: 'Scene Order',
                    type: 'single-select',
                    options:[
                        {id: -1, name: 'Add Before'},
                        {id: 0, name: 'Add New'},
                        {id: 1, name: 'Add After'}
                    ]
                },
                scene_id: {
                    key: 'scene_id',
                    label: 'Scene',
                    type: 'single-select',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null,
            lists_values: {}
        }
    },
    created() {
        this.getFieldsList();
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                script_id: 'scripts_list',
                scene_id: 'scenes_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setDefaultValues() {
            let script_options = this.required_fields.script_id.options;

            if (script_options && script_options.length > 0){
                this.addForm.script_id = script_options[0];
            }

            this.addForm.scene_order = this.required_fields.scene_order.options[1];

        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },

        setValueFromOptions: function ( field_name, item_id, default_value = null) {
            let found_item = _.find(this.required_fields[field_name].options, function (o) {
                return o.id === item_id;
            });

            this.addForm[field_name] = found_item ? found_item : default_value;
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('script_id', this.selected_item.script_id);
                this.setValueFromOptions('scene_order', this.selected_item.scene_order);
                this.setValueFromOptions('scene_id', this.selected_item.scene_id);
                this.addForm.scene_name = this.selected_item.scene_name;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
            this.setDefaultValues();
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                script_id: this.addForm.script_id ? this.addForm.script_id.id : null,
                scene_order: this.addForm.scene_order ? this.addForm.scene_order.id : null,
                scene_id: this.addForm.scene_id ? this.addForm.scene_id.id : null,
                scene_name: this.addForm.scene_name,
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await sceneResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>
