<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <form enctype="multipart/form-data">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Basic Information</h4>
                            <p class="card-title-desc">Fill all information below</p>

                            <div class="row">
                                <div class="col-sm-12" v-if="errors.length">
                                    <b>Please correct the following error(s):</b>
                                    <ul>
                                        <li v-for="error in errors">{{ error }}</li>
                                    </ul>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="user_email">Enter Email</label>
                                        <input v-if="user_status !== null" disabled :value="addForm.email" id="user_email" name="user_email" type="email" class="form-control" />
                                        <input v-else v-model="addForm.email" id="user_email" name="user_email" type="email" class="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div v-if="user_status === 0" class="create-and-assign-assetflow">
                                        <div class="alert alert-warning">
                                            <i class="bx font-size-16 bx-x non-existing-user_email"></i> This user doesn't have an Asset Flow account.
                                        </div>
                                        <div v-if="selected_entity_id === 3" class="form-group">
                                            <label for="required_entity">Select Entity</label>

                                            <multiselect
                                                id="owners_list"
                                                name="customer_sp"
                                                class="mySingleSelect"
                                                :class="entity_not_selected ? 'is-invalid' : ''"
                                                placeholder="Select Entity"
                                                v-model="addForm.required_entity"
                                                track-by="id"
                                                label="name"
                                                :custom-label="CustomEntityWithType"
                                                @input="changedOwner($event)"
                                                :options="available_entities">
                                            </multiselect>

                                            <span class="invalid-feedback entity-not-selected"><small>Please select an entity to continue.</small></span>
                                        </div>

                                        <button type="submit" @click="createAndAssign($event)" class="btn btn-success create-and-assign mb-1">Create User Account <span v-if="!(selected_entity_id === 3 && addForm.required_entity === null)"> and assign to {{ selected_entity_name }}</span></button>
                                    </div>

                                    <div v-else-if="user_status === 1" class="shall-assign-only mb-1">
                                        <div class="alert alert-info">
                                            <i class="bx font-size-16 bx-check existing-user_email"></i> User <b>'{{ fetched_user_info.name }}'</b> already has an Asset Flow account.
                                        </div>

                                        <div v-if="selected_entity_id === 3" class="form-group">
                                            <label for="required_entity">Select Entity</label>

                                            <multiselect
                                                id="owners_list"
                                                name="customer_sp"
                                                class="mySingleSelect"
                                                :class="entity_not_selected ? 'is-invalid' : ''"
                                                placeholder="Select Entity"
                                                v-model="addForm.required_entity"
                                                track-by="id"
                                                label="name"
                                                :custom-label="CustomEntityWithType"
                                                @input="changedOwner($event)"
                                                :options="available_entities">
                                            </multiselect>
                                            <span class="invalid-feedback entity-not-selected"><small>Please select an entity to continue.</small></span>

                                        </div>

                                        <p v-if="selected_entity_id === 3 && addForm.required_entity === null">Select entity to continue.</p>
                                        <div v-else>
                                            <div class="alert alert-warning" v-if="selected_entity_id !== 3">
                                                <i class="bx font-size-16 bx-x not-assigned-user"></i> User <b>'{{ fetched_user_info.name }}'</b> is not added to {{ selected_entity_name }}.
                                            </div>
                                            <button type="submit" @click="assignOnly($event)" class="btn btn-success create-and-assign">Add {{ addForm.email }} to {{ selected_entity_name }}</button>
                                        </div>
                                    </div>

                                    <div v-else-if="[2, 3].includes(user_status)" class="shall-assign-role-only">

                                        <div class="alert alert-info">
                                            <i class="bx font-size-16 bx-check existing-user_email"></i> User <b>'{{ fetched_user_info.name }}'</b> already has an Asset Flow account.
                                        </div>

                                        <div class="alert alert-info" v-if="user_status === 2">
                                            <i class="bx font-size-16 bx-check existing-user_email"></i> User <b>'{{ fetched_user_info.name }}'</b> is added to {{ selected_entity_name }}. <a v-if="selected_entity_id === 3" :href="'/' + item_type + '/users/new?email=' + fetched_user_info.email">Change</a>
                                        </div>
                                        <div class="alert alert-success" v-else-if="user_status === 3">
                                            <i class="bx font-size-16 bx-check existing-user_email"></i> Added <b>'{{ fetched_user_info.name }}'</b> to {{ selected_entity_name }} successfully.
                                        </div>

                                        <div class="modules-and-roles-list" v-if="get_selected_entity() !== null">

                                            <h4>{{ fetched_user_info ? (fetched_user_info.name + '\'s') : '' }} Roles</h4>

                                            <p v-if="modulesListLoading">Loading Roles...</p>
                                            <div v-else-if="modules_list && modules_list.length > 0">
                                                <div v-for="(module, module_index) in modules_list" :key="module_index" class="row mt-2">
                                                    <div class="col-12">

                                                        <div class="card">
                                                            <div class="card-header af-custom-colour af-primary text-white">
                                                                <div>{{ module.name }}</div>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="row" v-if="module.hasOwnProperty('module_roles') && module.module_roles && module.module_roles.length > 0">
                                                                    <div
                                                                        v-for="(option, index) in module.module_roles"
                                                                        :key="module.id + '_' + option.id"
                                                                        class="dimensions-btn col-3 mb-1"
                                                                        style="width:100%">
                                                                        <a class="dimensions-auto"
                                                                           :class="addForm.required_roles.includes(option.id) ? 'selected-role active' : 'not-selected-role'"
                                                                           @click="roleCheckBoxClicked(option, $event)"
                                                                           :value="option.id"
                                                                           style="width:100%">
                                                                            <span></span>{{ option.name | titleize }}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div v-else>There are no roles available for '{{ module.name }}'.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p v-else>There are no available Modules\Roles. Please contact your Account Manager.</p>

                                        </div>

                                        <div class="alert alert-info" v-if="select_roles_message !== ''">
                                            <i class="mdi mdi-alert-circle-outline me-2"></i> {{ select_roles_message }}
                                        </div>

                                        <button v-if="select_roles_message !== ''" @click="assignRole($event)" class="btn btn-success shall-assign-role mb-1">Save Changes</button>
                                        <button v-else disabled="disabled" class="btn btn-success mb-1">Save Changes</button>

                                    </div>

                                    <div v-else-if="user_status === 4" class="assigned-to-role">

                                        <div class="alert" v-if="fetched_user_info">
                                            <h4 class="alert-heading">Done!</h4>
                                            <div v-if="fetched_user_info.hasOwnProperty('user_roles') && fetched_user_info.user_roles.length > 0">
                                                <p>Assigned <b>'{{ fetched_user_info.name }}'</b> to these roles</p>
                                                <ul class="assigned-user-roles pl-3 mb-0">
                                                    <li v-for="(user_role, index) in fetched_user_info.user_roles">
                                                        <a :href="'/' + item_type + '/roles/' + user_role.id + '/view'"></a>{{ user_role.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <hr />
                                            <div v-if="fetched_user_info.hasOwnProperty('removed_user_roles') && fetched_user_info.removed_user_roles.length > 0">
                                                <p>Unassigned <b>'{{ fetched_user_info.name }}'</b> from these roles</p>
                                                <ul class="unassigned-user-roles pl-3 mb-0">
                                                    <li v-for="(user_role, index) in fetched_user_info.removed_user_roles">
                                                        <a :href="'/' + item_type + '/roles/' + user_role.id + '/view'"></a>{{ user_role.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    <div v-else-if="user_status === 5" class="row shall-create-user">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="username">First Name</label>
                                                <input v-model="addForm.first_name" id="username" name="username" type="text" class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label for="username">Last Name</label>
                                                <input v-model="addForm.last_name" id="username" name="username" type="text" class="form-control" />
                                            </div>

                                            <div class="form-group" v-if="selected_entity_id === 3">
                                                <label for="user_password">Password</label>
                                                <input v-model="addForm.password" id="user_password" name="user_password" type="password" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label for="avatar">Avatar</label>
                                                <div class="col-sm-6">
                                                    <vue-dropzone id="avatar" ref="myVueDropzone" :use-custom-slot="true"
                                                                  :options="dropzoneOptions"
                                                                  @vdropzone-file-added="fileAdded"
                                                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                                                    >
                                                        <div class="dropzone-custom-content">
                                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                            <h4>Drop files here or click to upload.</h4>
                                                        </div>
                                                    </vue-dropzone>
                                                    <b-button
                                                        @click="$refs.myVueDropzone.removeAllFiles();"
                                                        variant="outline-danger"
                                                        class="btn btn-sm mt-1 btn-block"
                                                    >Remove Selected</b-button>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px; width: 100%; margin-top: 8px;">
                                                        <p><strong>Avatar Dimensions</strong></p>
                                                        <p>Avatar's will be cropped to become square and should be a minimum size of 100px x 100px</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 mb-1">
                                            <a v-on:click="addNew($event)" class="btn btn-primary">{{ create_user_action }}</a>
                                        </div>

                                    </div>

                                    <button v-else @click="checkEmail($event)" ref="check-email-button" type="submit" class="btn btn-primary shall-check-email">Continue</button>

                                    <a v-if="fetched_user_info && fetched_user_info.hasOwnProperty('email')" :href="'/' + item_type + '/users/new?email=' + fetched_user_info.email" class="btn btn-info shall-start-over">Wizard: {{ fetched_user_info.name }}</a>
                                    <a @click="startOver($event)" class="btn btn-info shall-start-over">Wizard: New User</a>
                                    <a :href="'/' + item_type + '/users'" class="btn btn-secondary shall-go-back">Return to User List</a>
                                </div>

                            </div>

                        </div>
                    </div>

                </form>
            </div>
        </div>
    </Layout>
</template>



<script>

    import Layout from "@/layouts/main";
    import PageHeader from "@/components/page-header";
    import multiSelect from '@/mixins/MultiSelect';
    import Resource from '@/api/resource';
    const ownersListResource = new Resource('api/assetflow/users/available_entities');
    const moduleResource = new Resource('api/assetflow/modules');
    const checkUserStatusResource = new Resource('api/assetflow/users/check_user_status');
    import AdministrationShared from "@/mixins/AdministrationShared.mixin";
    import vue2Dropzone from "vue2-dropzone";
    import {DropZoneOptions} from "../form_data/DropZoneOptions";


    // outside of the component:
    function initialState (){
        return {
            dropzoneOptions: DropZoneOptions,
            title: "Add New User",
            items: [
                {
                    text: "Users",
                    href: "/"
                },
                {
                    text: "Add User",
                    active: true
                }
            ],
            next_action:"",
            select_roles_message:'',
            modulesListLoading:false,
            addForm:{
                name: null,
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                avatar: null,
                shall_assign_to_entity: false,
                required_entity: null,
                shall_assign_role: false,
                required_roles: [],
            },
            available_roles: [],
            modules_list: [],
            available_entities: [],
            create_user_action: null,
            entity_not_selected: null,

        };
    }


    export default {
        props: ['item_id', 'item_type', 'action'],
        name: 'AddUser',
        mixins: [AdministrationShared, multiSelect],
        components: {
            vueDropzone: vue2Dropzone,
            Layout,
            PageHeader
        },
        data() {
            return initialState();
        },
        watch:{
            changed_roles_list: {
                deep: true,
                handler: function(newValue){
                    if (newValue){
                        let user_role_ids = [];
                        if (this.fetched_user_info && this.fetched_user_info.hasOwnProperty('user_roles')){
                            user_role_ids = _.map(this.fetched_user_info.user_roles, 'id');
                        }
                        this.select_roles_message = !_.isEqual( newValue, user_role_ids) ? 'Please Save Changes to Confirm' : '';
                    }else{
                        this.select_roles_message = '';
                    }
                }
            },
        },
        computed:{
            selected_entity_name(){
                return this.selected_entity_property('text');
            },
            changed_roles_list(){
                return this.addForm.required_roles;
            },
            selected_role_name(){
                if (this.fetched_user_info && this.fetched_user_info.hasOwnProperty('user_roles')){
                    return _.map(this.fetched_user_info.user_roles, 'name').join(',');
                }
                return 'Role';
            }
        },
        mounted() {
            let queryVars = this.getQueryVars();
            if ("email" in queryVars && queryVars["email"] !== '') {
                let user_email = decodeURI(queryVars["email"]);
                this.addForm.email = user_email;
                this.$refs['check-email-button'].click();
            }
        },
        created() {
            this.getEntitiesList();
            this.changedOwner();
        },
        methods: {
            roleCheckBoxClicked(option, event){
                const index = this.addForm.required_roles.indexOf(option.id);
                if (index !== -1){
                    this.addForm.required_roles.splice(index, 1);
                }else{
                    this.addForm.required_roles.push(option.id);
                }
            },
            fileAdded(file) {
                this.addForm.avatar = file;
                this.saveFileToS3(file, null, 'avatar', 'user');
            },
            maxFilesExceeded(file){
                this.$refs.myVueDropzone.removeAllFiles();
                this.$refs.myVueDropzone.addFile(file);
            },
            addNew(event) {
                if (event && !this.isSendingRequest) {
                    this.isSendingRequest = true;

                    let required_entity = this.get_selected_entity();

                    axios({
                        method:'post',
                        url:'/api/assetflow/users',
                        baseURL: '/',
                        data: {
                            first_name: this.addForm.first_name,
                            last_name: this.addForm.last_name,
                            email: this.addForm.email,
                            password: this.addForm.password ? this.addForm.password : 'asset@flow',
                            avatar: this.addForm.avatar,
                            item_type: this.item_type,
                            entity_id: required_entity.value || required_entity.id,
                            entity_type: required_entity.hasOwnProperty('item_type') ? required_entity.item_type : 'customer'
                        },
                    }).then(response => {
                        this.isSendingRequest = false;
                        if (response.data.data.status === true){
                            let added_user = response.data.data.items;
                            let message = 'Added Successfully, {' + added_user.name + '}';

                            this.user_status = 3;


                            this.presentToast("Success!",
                                message,
                                'success',
                                3000,
                                true,
                                true);

                            this.fetched_user_info = added_user;
                            if (this.fetched_user_info){
                                this.fetched_user_info.user_profile_link = '/' + this.item_type + '/users/' + this.fetched_user_info.id + '/view';
                            }

                        }else{
                            console.log('Something went wrong');
                        }

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });

                }
            },
            async getModulesList(item_id, item_type) {
                this.modulesListLoading = true;
                if (item_type === 'service_providers'){
                    item_type = 'sp';
                }else{
                    item_type = item_type.slice(0, -1);
                }
                let listQuery = {
                    owner_item_type: item_type,
                    owner_id: item_id,
                    current_page_type: this.item_type,
                    with_roles: true
                }

                const { data } = await moduleResource.list(listQuery);

                this.modules_list =  data.data.items;
                this.modulesListLoading = false;
            },

            handleListingRolesAndModules: function (owner_id, owner_type) {
                this.getModulesList(owner_id, owner_type + 's');
                let user_entity_info_name = 'user_' + owner_type + '_info';
                if (this.fetched_user_info && this.fetched_user_info.hasOwnProperty(user_entity_info_name) && this.fetched_user_info[user_entity_info_name]) {
                    let user_entity_info = this.fetched_user_info[user_entity_info_name];
                    if (user_entity_info.includes(owner_id)) {
                        this.user_status = 2;
                    }
                }
            },
            changedOwner(event) {
                let owner_id = (this.current_customer ? this.current_customer.id : null), owner_type = this.item_type;
                if (event) {
                    this.$set(this.addForm, 'required_entity', event);
                    owner_id = this.addForm.required_entity.value;
                    owner_type = this.addForm.required_entity.item_type;
                }
                if (owner_id){
                    this.handleListingRolesAndModules(owner_id, owner_type);
                    if (this.selected_entity_id === 3 && this.addForm.email) {
                        this.checkEmail(null, true);
                    }

                }else{
                    console.log("no owner_id?");
                }
            },

            async getEntitiesList() {
                this.listLoading = true;

                let listQuery = {};
                const { data } = await ownersListResource.list(listQuery);

                this.available_entities = data.data.items;
                this.listLoading = false;
            },
            async getRolesList(owner_id='', item_type='', assigned_module='') {
                this.listLoading = true;
                let listQuery = {};

                listQuery.item_type = this.item_type;
                let selected_entity = this.get_selected_entity();
                listQuery.item_id = selected_entity.value || selected_entity.id;
                listQuery.assigned_module = assigned_module;
                if (this.fetched_user_info){
                    listQuery.entity_id = owner_id;
                    listQuery.entity_type = item_type;
                    listQuery.for_user = this.fetched_user_info.id;
                }

                let owner_text = '';
                if (owner_id !== '') {
                    owner_text = '-' + owner_id;
                    listQuery.owner_id = owner_id;
                    if (item_type === 'service_providers'){
                        item_type = 'sp';
                    }else{
                        item_type = item_type.slice(0, -1);
                    }
                    listQuery.owner_item_type = item_type;
                }

                const roleResource = new Resource('api/assetflow/roles');

                const { data } = await roleResource.list(listQuery);

                this.available_roles =  data.data.items;
                this.listLoading = false;
            },
            selected_entity_property(property='text'){

                if (property === 'item_type'){
                    switch (this.selected_entity_id) {
                        case 0: // customer
                            return 'customer';
                        case 1: // group
                            return 'group';
                        case 2: // sp
                            return 'sp';
                        case 3: // assetflow
                            if (this.addForm.required_entity !== null){
                                return this.addForm.required_entity[property];
                            }
                            return 'customer';
                        default:
                            return 'customer';
                    }
                }

                let entity_name = 'AssetFlow';

                let name_property = property === 'text' ? 'name' : property;
                name_property = name_property === 'value' ? 'id' : name_property;

                switch (this.selected_entity_id) {
                    case 0: // customer
                        // use current customer
                        entity_name = this.current_customer[name_property];
                        break;
                    case 1: // group
                        // use current customer
                        entity_name = this.current_group[name_property];
                        break;
                    case 2: // sp
                        // use current sp
                        entity_name = this.current_sp[name_property];
                        break;
                    case 3: // assetflow
                        // use current sp
                        if (this.addForm.required_entity !== null){
                            entity_name = this.addForm.required_entity[property];
                        }
                        break;
                }

                return entity_name;
            },
            createAndAssign(event) {
                event.preventDefault();
                let required_entity = this.get_selected_entity();
                if(!required_entity){
                    this.entity_not_selected = true;
                    this.presentToast("Error!",
                        'Please select an entity to continue',
                        'danger',
                        1500,
                        true,
                        true);
                    return;
                }
                this.entity_not_selected = false;

                // should check if noc entity
                this.user_status = 5; // Shall Create user

                this.create_user_action = 'Create user ' + this.addForm.email + ' and assign to ' + this.selected_entity_name;
            },
            async assignOnly(event) {
                event.preventDefault();

                if (this.fetched_user_info){
                    this.isSendingRequest = true;
                    let required_entity = this.get_selected_entity();

                    let params = {
                        user_id: this.fetched_user_info.id,
                        user_status: this.user_status,
                        entity_id: required_entity.value || required_entity.id,
                        entity_type: required_entity.hasOwnProperty('item_type') ? required_entity.item_type : 'customer' // customer, sp, group

                    }
                    const { data } = await checkUserStatusResource.store(params);

                    if (data.data.hasOwnProperty('items')){
                        this.user_status = data.data.items.user_status;
                        this.fetched_user_info = data.data.items.user_info ? data.data.items.user_info : this.fetched_user_info;
                        if (this.fetched_user_info){
                            this.fetched_user_info.user_profile_link = '/' + this.item_type + '/users/' + this.fetched_user_info.id + '/view';

                            if (this.fetched_user_info.hasOwnProperty('user_roles')){
                                this.addForm.required_roles = [... _.map(this.fetched_user_info.user_roles, 'id')];
                            }
                        }

                    }else{
                        this.user_status = null;
                    }

                    this.isSendingRequest = false;

                    // this.next_action = 'Shall assign ' + this.addForm.email + ' to entity ' + this.selected_entity_name;
                }
            },
            async assignRole(event) {
                event.preventDefault();
                let required_entity = null;
                if (this.selected_entity_id === 3){
                    required_entity = this.addForm.required_entity;
                }else if (this.selected_entity_id === 2){
                    required_entity = this.current_sp;
                }else if (this.selected_entity_id === 1){
                    required_entity = this.current_group;
                }else {
                    required_entity = this.current_customer;
                }

                if (required_entity !== null && this.addForm.required_roles && this.fetched_user_info){
                    this.isSendingRequest = true;

                    let required_roles = this.addForm.required_roles;

                    let entity_id = this.selected_entity_property('value');
                    let entity_type = this.selected_entity_property('item_type');

                    let params = {
                        user_id: this.fetched_user_info.id,
                        user_status: this.user_status,
                        entity_id: entity_id,
                        entity_type: entity_type,
                        required_roles: required_roles
                    }

                    const { data } = await checkUserStatusResource.store(params);

                    if (data.data.hasOwnProperty('items')){
                        this.user_status = data.data.items.user_status;
                        this.fetched_user_info = data.data.items.user_info ? data.data.items.user_info : this.fetched_user_info;
                        if (this.fetched_user_info){
                            this.fetched_user_info.user_profile_link = '/' + this.item_type + '/users/' + this.fetched_user_info.id + '/view';
                        }
                    }else{
                        this.user_status = null;
                    }

                    this.isSendingRequest = false;

                    // this.next_action = 'Shall assign ' + this.addForm.email + ' to role ' + required_roles.name;
                }else{
                    console.log("'something is missing");

                    this.presentToast("Error!",
                        'Something went wrong add-role',
                        'danger',
                        1500,
                        true,
                        true);
                }
            },

            startOver(event) {
                Object.assign(this.$data, initialState());
                this.user_status = null;
                this.getEntitiesList();
                this.changedOwner();
            },

        }

    }
</script>

<style>
#avatar .dz-preview .dz-progress {
    opacity: 0 !important;
}

.vue-dropzone > .dz-preview .dz-image {
    height: 160px !important;
    width: 160px !important;
}

.vue-dropzone .dz-preview .dz-details .dz-size {
    display: none
}
</style>
