export default {
    data(){
        return {
            index: 0,
            visibleCaption: false,
            captionimgs: [],
            gallery_object_type: null
        }
    },
    created() {
    },
    methods: {
        refreshItemImage(index, image){
            this.$set(this.item_info.asset_images, index, image);
        },
        imagePerformAction(image, action, index) {
            if (image.id !== ''){
                switch (action) {
                    case 'delete':
                        this.deleteItemByID(image.id, image.name, 'images', image)
                            .then((response) => {
                                if (response.data.data.status){
                                    this.item_info.asset_images.splice(index, 1);
                                }else{
                                    console.log("response.data");
                                    console.log(response.data);
                                }
                            });
                        break;
                    case 'edit_notes':
                        image.index = index;
                        this.$refs['edit-image-notes'].setSelectedItem(image);
                        let modal_id = 'create-image-notes';
                        if (this.gallery_object_type && this.gallery_object_type === 'asset_passports'){
                            modal_id = 'create-ap-image-notes';
                        }
                        this.$bvModal.show(modal_id);
                        break;
                }

            }

        },
        showCaptiomImg(index, asset_images, image_size) {
            if (!image_size){
                image_size = (this.image_sizes.hasOwnProperty('full_screen_image') ? this.image_sizes.full_screen_image : '');
            }
            this.index = index;
            this.captionimgs = asset_images.map(function (e) {
                return {src: e.image_name + image_size, title: e.name};
            });
            this.visibleCaption = true;
        },
        postImageClicked(image) {
            let parts_info_ids = this.item_info.asset_images.map(function(e) { return e.s3_uuid; });
            let index = parts_info_ids.indexOf(image.s3_uuid);
            if (index > -1){
                this.showCaptiomImg(index, this.item_info.asset_images)
            }
        },
        handleCaptionHide() {
            this.visibleCaption = false;
        },
        addNewImageNotes(new_item, index){
            if (this.gallery_object_type && this.gallery_object_type === 'asset_passports'){
                if (this.cAFID_info.filtered_asset_images.length > index){
                    this.cAFID_info.filtered_asset_images[index].name = new_item.name;
                    this.cAFID_info.filtered_asset_images[index].description = new_item.description;
                    this.cAFID_info.filtered_asset_images[index].is_featured = new_item.is_featured;
                }
            }else{
                if (this.item_info.asset_images.length > index){
                    this.item_info.asset_images[index].name = new_item.name;
                    this.item_info.asset_images[index].description = new_item.description;
                    this.item_info.asset_images[index].is_featured = new_item.is_featured;
                }
            }


        }
    }
}
