<template>
    <div class="row" v-if="managed_storage">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title="managed_storage.name"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>

                        <div class="common_col_bg_box">
                            <ManagedStorageIntakePPInfo
                                :asset="managed_storage"
                                :get-colour-h-t-m-l="getColourHTML(managed_storage)"
                                :get-condition-text="getConditionText(managed_storage)"/>
                        </div>

                        <ManagedStorageIntakeInfo
                            :asset="managed_storage"
                            :item_type="managed_storage.current_page_type"/>

                        <div class="common_col_desc ms-item-description" v-if="managed_storage && managed_storage.hasOwnProperty('description') && managed_storage.description">
                            <p>{{ managed_storage.description }}</p>
                        </div>

                    </template>
                </NewCustomGenericBlock>
            </div>

            <div class="row" v-if="item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('custom_fields') && item_info.asset_meta.custom_fields">
                <NewCustomGenericBlock
                    block_title="Custom Fields"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <div class="row">
                            <div
                                v-for="(custom_field, key) in item_info.asset_meta.custom_fields"
                                class="col-6 col-md-3" :class="customFieldsClass()">
                                <div class="cm_usage">
                                    <span>{{ custom_field }}</span>
                                    <p>
                                        <i class=""></i> <span class="cm_list_inner_common_value">{{ item_info.asset_meta.hasOwnProperty(custom_field) && item_info.asset_meta[custom_field] ? item_info.asset_meta[custom_field] : '-' }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </NewCustomGenericBlock>
            </div>

            <div class="row" v-if="item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('asset_links') && item_info.asset_meta.asset_links">
                <NewCustomGenericBlock
                    block_title="Links"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <div class="row">
                            <div
                                v-for="(custom_field, key) in item_info.asset_meta.asset_links"
                                class="col-12 col-md-12" :class="customFieldsClass('asset_links')">
                                <div class="cm_usage">
                                    <span>URL #{{ key + 1}}</span>
                                    <p v-if="custom_field.field_value">
                                        <i class="fa fa-link"></i> <span class="cm_list_inner_common_value"><a class="text-dark" :href="custom_field.field_value" target="_blank">{{ custom_field.field_name || ('URL #' + (key + 1)) }}</a></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </NewCustomGenericBlock>
            </div>

        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <ManagedStorageRightBox
                :item_info="managed_storage"
                box_unique_id="ms_overview_tab"
                :object_type="object_type"
                :refreshPage="refreshPage"
            />
        </div>
    </div>
</template>
<script>
import ManagedStorageRightBox from "./ManagedStorageRightBox"
import ManagedStorageIntakePPInfo from "../../customers/managed_storages/ManagedStorageIntakePPInfo";
import ManagedStorageShared from "@/mixins/ManagedStorageShared.mixin";
import NewCustomGenericBlock from "../widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import ManagedStorageIntakeInfo from "../../customers/managed_storages/ManagedStorageIntakeInfo";
import Multiselect from "vue-multiselect";
import AddAssetToOrder from "../../customers/extras/AddAssetToOrder";

export default {
    name: 'ManagedStorageOverviewTab',
    components: {
        AddAssetToOrder,
        ManagedStorageIntakeInfo, NewCustomGenericBlock, ManagedStorageIntakePPInfo, ManagedStorageRightBox, Multiselect},
    mixins:[ManagedStorageShared],
    props: {
        managed_storage: {}
    },
    data(){
        return {
            orders_list: [],
            selected_order_to_add_to: null,
            selected_quantity_to_add: null,
            isAddingItem: false
        }
    },
    computed:{
        item_info(){
            return this.managed_storage.hasOwnProperty('item_info') && this.managed_storage.item_info;
        },
    },
    methods:{
        orderDetails({ name, sp_info }){
            return name + (sp_info ? ' - ' + sp_info.name : '');
        },
        getNumbers:function(start,stop,step = 1){
            return new Array(stop / step).fill(start).map((n,i)=>(i+1)*step);
        },
    }
}
</script>
