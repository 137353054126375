<template>
    <div class="ChangeSceneCostumeTab">

        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="'icons-action-tasks' + unique_id"
            sub_modal_id="costume"
            :custom_keys_only="true"
            modal_title="Complete task"
            @on-completing-task="itemTaskCompleted"
            @on-modal-cancel="selected_asset_id = null"
        />

        <div class="row" v-if="change_info && change_info.hasOwnProperty('description') && !no_description_block">

            <SetChangeSceneDescription
                ref="set-change-scene-description"
                item_type='description'
                modal_title="Update Scene Notes"
                :received_item_id="item_info.id"
                @on-new-item-adding="AfterSettingDescription"
            >
            </SetChangeSceneDescription>

            <NewCustomGenericBlock
                block_title="Scene Notes"
                columns_count_withclasses="12"
                action_required_title="Update Scene Notes"
                :action_required="addDescriptionModal"
                box_colour_v="-"
            >
                <template v-slot:all_content>
                    <div class="scene-notes-description" v-if="part_change_description !== ''">{{ part_change_description }}</div>
                </template>
            </NewCustomGenericBlock>
        </div>

        <div class="row">


            <AcquisitionAssetModal
                :ref="add_new_asset_id"
                :item_type="'asset' + unique_id"
                modal_title="Add New Asset"
                save_text="Create & View"
                :should_add="true"
                :respect_modules="true"
                :predefined_values="{closet_character_id: item_info.character_id, change_id: item_info.id}"
                @on-new-item-adding="reloadCloset"
            />

            <SharedModalsBlock
                ref="shared-modals-block"
                :item_type="item_type"
                :update_asset_costs_id="update_asset_costs_id"
                :update_asset_details_id="update_asset_details_id"
                :update_asset_images_id="update_asset_images_id"
                :refreshAsset="refreshAsset"
                @on-modal-cancel="selected_asset_id = null"
            />

            <div class="col-12 mb-1">
                <div class="product-page-header">
                    <div class="filter-searchbar">
                        <b-dropdown v-if="hasPermission('add_assets')" dropleft class="add-filter" variant="danger">
                            <template slot="button-content">
                                <span class="add-filter-button"></span>
                            </template>
                            <b-dropdown-item href="javascript:void(0);" @click="addAsset">
                                New Asset
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0);" @click="addExistingAsset">
                                Existing Asset
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>


            <div class="col-12 wardrobe-layouts">
                <div class="grid-list-button d-flex align-items-center" style="float: right;">
                    <div
                        v-if="supportedLayouts && Object.keys(supportedLayouts).length > 0"
                        v-for="(layout, key) in supportedLayouts"
                        :key="key"
                        @click="changeObjectLayout(layout);"
                        :id="'grid-list-'+layout.name.toLowerCase()"
                        :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                    </div>
                </div>
            </div>

            <NewCustomGenericBlock
                block_title="Costume"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>


                    <div v-if="selectedLayout === 1 && change_info.hasOwnProperty('costume_items') && change_info.costume_items && change_info.costume_items.length > 0">

                        <div class="popup-gallery">
                            <vue-easy-lightbox
                                :visible="visibleCaption"
                                :imgs="captionimgs"
                                :index="index"
                                @hide="handleCaptionHide">
                            </vue-easy-lightbox>
                        </div>

                        <AssetClosetItem
                            v-for="(item, key) in change_info.costume_items"
                            :key="key"
                            class="cm_list_view asset-list-item" :class="getAssetClasses(item, selected_asset_id)"
                            :item="item"
                            :item_type="item_type"
                            :index="key"
                            :perform-action="performAction"
                            :show-captiom-img="showCaptiomImg"
                            >

                            <template v-slot:action_button="{ asset, index }">
                                <div class="cm_usage">
                                    <p v-if="hasPermission('edit_assets')">
                                        <b-button class="btn btn-sm btn-danger asset-closet-remove" @click="removeFromChange(asset, index)">Remove
                                        </b-button>
                                    </p>
                                </div>
                            </template>
                        </AssetClosetItem>
                    </div>
                    <po-lines v-else-if="change_info.hasOwnProperty('costume_items') && change_info.costume_items && change_info.costume_items.length > 0"
                              :items="change_info.costume_items"
                              :current_page_type="change_info.current_page_type"
                              :object_type="'assets'"
                              :line_type="'assets'"
                              :keys_list="costume_items_key_list">

                        <template v-slot:action_button="{ item, index }">
                            <b-button
                                v-if="hasPermission('edit_assets')"
                                class="btn btn-sm btn-danger mt-2 btn-block asset-closet-remove"
                                @click="removeFromChange(item, index)"
                            >Remove</b-button>
                        </template>
                        <template v-slot:costume_actions="{ item, index }">
                            <AssetActionsDropdown
                                :is_closet_asset="true"
                                :item="item"
                                :item_type="item_type"
                                :perform-action="performAction"/>
                        </template>

                    </po-lines>
                    <div v-else class="text-center">
                        <h6>No Costume found here at the moment.</h6>
                    </div>
                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import {CostumeAssetsKeysList} from "@/views/assetflow/form_data/CostumeAssetsKeysList";
import Resource from '@/api/resource';
import SetChangeSceneDescription from "@/views/assetflow/items/ChangeSceneRel/SetChangeSceneDescription";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import ClosetSharedActions from "@/mixins/ClosetSharedActions.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import AssetClosetItem from "./AssetClosetItem";
import AssetActionsDropdown from "../../assets/subItems/AssetActionsDropdown";
import SharedModalsBlock from "./SharedModalsBlock";
import GenericTasksForm from "../widgets/detailsViews/AssetTabs/GenericTasksForm";
import AcquisitionAssetModal
    from "../../customers/acquisitions/CostumeAcquisitionForm/AcquisitionModals/AcquisitionAssetModal";

const removeFromChangeResource = new Resource('api/assetflow/changes/removeFromChange');
export default {
    name: 'ChangeSceneCostumeTab',
    mixins:[ImageGallery, ClosetSharedActions],
    components: {
        AcquisitionAssetModal,
        GenericTasksForm,
        SharedModalsBlock,
        AssetActionsDropdown,
        AssetClosetItem,
        NewCustomGenericBlock, PoLines, SetChangeSceneDescription, VueEasyLightbox},
    props: {
        change_info: {},
        item_info: {},
        no_description_block: {},
        item_type: {},
    },
    data(){
        return {
            add_new_asset_id: 'add-costume-asset-list',
            unique_id: 'costume_tab',
            part_change_description: '',
            costume_items_key_list: CostumeAssetsKeysList,
            object_type: 'costume_list',
            list_name: 'costume_items',
            item_info_name: 'change_info'
        }
    },
    created() {
        let change_info = this.change_info;
        this.costume_items_key_list.character_id.value_func = function (item, key) {
            return change_info.hasOwnProperty('character_info') && change_info.character_info ? change_info.character_info.name : '';
        }

        if (this.item_info) {
            this.part_change_description = this.item_info.description;
        }
    },
    computed:{
        item_info_description(){
            return this.item_info.description;
        }
    },
    watch:{
        item_info_description:{
            deep: true,
            handler: function (newValue) {
                this.part_change_description = this.item_info_description;
            }
        },
    },
    methods: {
        addDescriptionModal(){
            this.$refs['set-change-scene-description'].setSelectedItem({description: this.part_change_description});
            this.$bvModal.show('create-description');
        },
        AfterSettingDescription(new_item, item_type){
            this.part_change_description = new_item.description;
            this.item_info.description = new_item.description;
            this.$emit('description-changed', new_item, item_type);
        },
        async removeFromChange(item, index) {

            let params = {
                change_id: this.change_info.id,
                asset_id: item.id,
                item_type: 'asset',
            }


            const { data } = await removeFromChangeResource.store(params);
            let new_item = data.data.status;

            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Removed From Change Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);

            if (new_item){
                this.$emit('after-item-moving', item, 'closet_items', index)
            }

        },

    }

}
</script>
