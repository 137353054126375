<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">
                        <span class="d-inline-block d-sm-none" v-iconize-icon="getTabName(tab).toLowerCase()"></span>
                        <span class="d-none d-sm-inline-block" v-iconize="getTabName(tab).toLowerCase()">{{ getTabName(tab) | titleize}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">

            <div class="tab-pane" id="module_entities_tab" role="tabpanel" :class="isActiveTab('module_entities_tab')">
                <div class="row">

                    <div class="col-lg-12">
                        <AssignModuleToEntity
                            v-if="canAssignModules && item_info.hasOwnProperty('entities_info') && item_info.entities_info"
                            :item_info="item_info"
                            :entity_type="item_info.entity_type"
                            :items_count="item_info.entities_info.length"
                            :assigned_entity_ids="assigned_entity_ids"
                            :item_id="item_info.id"
                            :item_type="item_info.item_type"
                            @assigned-entity="assignToEntity"
                        />

                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="item_info.edited_entity_type">{{ item_info.edited_entity_type | titleize }}</h6>
                            </div>
                            <div class="card-body">
                                <po-lines v-if="item_info.hasOwnProperty('entities_info') && item_info.entities_info && item_info.entities_info.length > 0"
                                          :items="item_info.entities_info"
                                          :current_page_type="item_info.current_page_type"
                                          :object_type="item_info.entity_type + 's'"
                                          :line_type="item_info.entity_type + 's'"
                                          :get-poline-classes-dict="getPoLineClassFor"
                                          :keys_list="entities_list_key_list">

                                    <template v-slot:entity_actions="{ item, index }">
                                        <a v-if="hasPermission('browse_'+item_info.edited_entity_type)" :href="'/'+ item_info.current_page_type + '/'+item_info.edited_entity_type+'/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                                        <a v-if="hasPermission('edit_'+item_info.edited_entity_type)" v-on:click="unAssignFromModule($event, item)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                                    </template>

                                </po-lines>
                                <div v-else class="text-center">
                                    <h6>No {{ (item_info.entity_type + 's') }} found here at the moment.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane" id="module_nav_items_tab" role="tabpanel" :class="isActiveTab('module_nav_items_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'navigation_items'">Navigation Items</h6>
                            </div>
                            <div class="card-body">

                                <po-lines v-if="item_info.hasOwnProperty('navigation_info') && item_info.navigation_info && item_info.navigation_info.length > 0"
                                          :items="item_info.navigation_info"
                                          :current_page_type="item_info.current_page_type"
                                          :object_type="'navigation'"
                                          :line_type="'navigation'"
                                          :get-poline-classes-dict="getPoLineClassFor"
                                          :keys_list="navigation_list_key_list">
                                </po-lines>
                                <div v-else class="text-center">
                                    <h6>No navigation items found here at the moment.</h6>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="module_roles_tab" role="tabpanel" :class="isActiveTab('module_roles_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'roles'">Roles</h6>
                            </div>
                            <div class="card-body">
                                <po-lines v-if="item_info.hasOwnProperty('roles_info') && item_info.roles_info && item_info.roles_info.length > 0"
                                          :items="item_info.roles_info"
                                          :current_page_type="item_info.current_page_type"
                                          :object_type="'roles'"
                                          :line_type="'roles'"
                                          :get-poline-classes-dict="getPoLineClassFor"
                                          :keys_list="role_list_key_list">
                                </po-lines>
                                <div v-else class="text-center">
                                    <h6>No roles found here at the moment.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="module_assets_tab" role="tabpanel" :class="isActiveTab('module_assets_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'assets'">Assets</h6>
                            </div>

                            <ModuleObjectsList :item_info="item_info" object_type="asset"/>

                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="module_files_tab" role="tabpanel" :class="isActiveTab('module_files_tab')">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-title">
                                <h6 v-iconize="'files'">Files</h6>
                            </div>
                            <ModuleObjectsList :item_info="item_info" object_type="file"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


import PoLines from "./widgets/detailsViews/PoLines";
import ModuleObjectsList from "./ModuleObjectsList";
import AssignModuleToEntity from "./AssignModuleToEntity";

export default {
    name: 'CustomModuleDetails',
    components: {AssignModuleToEntity, ModuleObjectsList, PoLines },
    props: ['item_info'],
    data(){
        return {
            assigned_entity_ids : [],
            entities_list_key_list:{
                initials: {
                    key: 'initials',
                    label: "",
                    class: "col-lg-2 initials",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                id: {
                    key: 'id',
                    label: "Id",
                    class: "col-lg-1 r_id",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg r_name",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
            },
            role_list_key_list:{
                id: {
                    key: 'id',
                    label: "Id",
                    class: "col-lg-1 r_id",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg r_name",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                role_type: {
                    key: 'role_type',
                    label: "Entity Type",
                    class: "col-lg-1 r_role_type",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return Vue.options.filters.titleize(item[key]);
                    }
                },
                warehouse_customers_count: {
                    key: 'warehouse_customers_count',
                    label: "Total Entities",
                    class: "col-lg-1 r_warehouse_customers_count",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<span class="badge badge-soft-primary font-size-11 m-1">' + item[key] +'</span>';
                    }
                },
                users_count: {
                    key: 'users_count',
                    label: "Total Users",
                    class: "col-lg-1 r_users_count",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<span class="badge badge-soft-primary font-size-11 m-1">' + item[key] +'</span>';
                    }
                }
            },
            navigation_list_key_list:{
                order: {
                    key: 'order',
                    label: "Order",
                    class: "col-lg-1 n_order",
                    show_on_mobile: false,
                    case: 'custom',
                    used_func: function (value) {
                        return value;
                    }
                },
                icon: {
                    key: 'icon',
                    label: "Icon",
                    class: "col-lg-1 n_icon",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        if (item.icon){
                            return '<i class="' + item.icon +'"></i>';
                        }
                        return '-';
                    }
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg n_name",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let name = item.name + (item.is_title ? ' <sup>Title</sup>' : '');
                        return Vue.options.filters.iconize(name, item.name.toLowerCase());
                    }
                },
                link: {
                    key: 'link',
                    label: "Link",
                    class: "col-lg-1 n_link",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        if (!item.is_title){
                            return '<span>' + ( !item.is_url ? (item.item_type === 'settings' ? 'customer' : item.item_type).toLowerCase() : '') +  item.link + '</span>';
                        }
                        return '-';
                    }
                },
            },

            active_tab: 'module_entities_tab',
            tabs: {
                'module_entities_tab': {
                    'id': "module_entities_tab",
                    'name': 'Entities',
                    'icon': "user",
                    'type': "module_entities_tab",
                    'isActive': true
                },
                'module_nav_items_tab': {
                    'id': "module_nav_items_tab",
                    'name': "Navigation Items",
                    'icon': "user",
                    'type': "module_nav_items_tab",
                    'isActive': true
                },
                'module_roles_tab': {
                    'id': "module_roles_tab",
                    'name': "Roles",
                    'icon': "user",
                    'type': "module_roles_tab",
                    'isActive': false
                },
                'module_assets_tab': {
                    'id': "module_assets_tab",
                    'name': "Assets",
                    'icon': "user",
                    'type': "module_assets_tab",
                    'isActive': false
                },
                'module_files_tab': {
                    'id': "module_files_tab",
                    'name': "Files",
                    'icon': "user",
                    'type': "module_files_tab",
                    'isActive': false
                }
            },
            assignData: {},
        }
    },
    computed:{
        canAssignModules(){
            if (this.item_info && this.item_info.hasOwnProperty('currentUserRoles')){
                let currentUserRoles =  this.item_info.currentUserRoles;
                return this.isSuperAdmin && currentUserRoles.map(function(e) { return e.role_type; }).includes('assetflow');
            }
            return this.isSuperAdmin;
        }
    },
    created() {

        let item_info = this.item_info;



        if (item_info.hasOwnProperty('entities_info') && item_info.entities_info){
            this.assigned_entity_ids = item_info.entities_info.map(function(entity) { return entity.id });
        }

        let entity_type = item_info.entity_type.replace('sp', 'service_provider') + 's';
        this.item_info.edited_entity_type = entity_type;


        if (this.hasPermission('browse_'+entity_type) || this.hasPermission('edit_'+entity_type)){
            this.entities_list_key_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 r_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_class: '',
                slot_name: 'entity_actions'
            }
        }

        this.entities_list_key_list.id.value_func = function (item, key) {
            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/'+entity_type + 's/' + item.id + '/view">' + item[key] + '</a>' : '';
        };

        this.entities_list_key_list.initials.value_func = function (item, key) {
            let style = '';

            if (item.hasOwnProperty('meta') && item.meta && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                let bg_colour = item.meta.accent_colour;
                let text_colour = item.meta.primary_colour;
                style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
            }
            return '<a href="/' + item_info.current_page_type +'/'+ entity_type + '/'+item.id+'/view" class="text-dark">' + '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item[key]+'</span></div>' + '</a>'; //
        }
        ;
        this.entities_list_key_list.name.value_func = function (item, key) {
            let iconize = Vue.options.filters.iconize;
            let titleize = Vue.options.filters.titleize;
            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/'+ entity_type + '/' + item.id + '/view">' + iconize(titleize(item[key]), entity_type) + '</a>' : '';
        };


        this.role_list_key_list.id.value_func = function (item, key) {
            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/roles/' + item.id + '/view">' + item[key] + '</a>' : '';
        };
        this.role_list_key_list.name.value_func = function (item, key) {
            let iconize = Vue.options.filters.iconize;
            let titleize = Vue.options.filters.titleize;
            return item_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/roles/' + item.id + '/view">' + iconize(titleize(item[key]), 'roles') + '</a>' : '';
        };


        let modules_active_tab = localStorage.getItem('modules_active_tab');
        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'overview':
                    this.active_tab = 'module_nav_items_tab';
                    break;
                case 'roles':
                    this.active_tab = 'module_roles_tab';
                    break;
                case 'assets':
                    this.active_tab = 'module_assets_tab';
                    break;
                case 'files':
                    this.active_tab = 'module_files_tab';
                    break;
            }
        }else if (modules_active_tab && modules_active_tab !== ''){
            this.active_tab = modules_active_tab;
        }

    },
    methods:{
        getTabName(tab){
            return (tab.name === 'Entities' ? this.item_info.edited_entity_type : tab.name);
        },
        tabClicked(tab_id){
            localStorage.setItem('modules_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        assignToEntity(form, entity_info){
            this.$bvModal.hide('assign-entity-modal');

            let entity_id = form.item;
            if (entity_id){
                console.log('Shall assign module? '+this.item_info.id+' to '+this.item_info.entity_type+' #' + entity_id);

                // if assigned?
                let module_id = this.item_info.id;

                this.assignData = {
                    owner_id: entity_id,
                    item_id: module_id,
                    entity_info: entity_info,
                    item_type: 'modules'
                };

                this.assign_unassign_module();
            }
        },
        unAssignFromModule(event, entity_info){
            if (!entity_info) { return; }
            let entity_id = entity_info.id
            let module_id = this.item_info.id;
            this.assignData = {
                owner_id: entity_id,
                item_id: module_id,
                entity_info: entity_info,
                item_type: 'modules',
                unassign: true
            };
            this.assign_unassign_module();
        },

        assign_unassign_module() {
            axios({
                method: 'post',
                url: '/api/assetflow/'+this.item_info.edited_entity_type+'/assign_module',
                baseURL: '/',
                data: this.assignData,
            }).then(response => {
                let status = response.data.status;
                let msg = response.data.msg;
                if (status) {
                    let entity_info = this.assignData.entity_info;
                    let entity_id = entity_info.id;
                    if (this.assignData.hasOwnProperty('unassign') && this.assignData.unassign){
                        let index_of_assigned_entity_id = this.assigned_entity_ids.indexOf(entity_id);
                        if (index_of_assigned_entity_id > -1 && this.assigned_entity_ids.length > index_of_assigned_entity_id){
                            this.assigned_entity_ids.splice(index_of_assigned_entity_id, 1);
                        }

                        let index = this.item_info.entities_info.map(function(e) { return e.id; }).indexOf(entity_id);
                        if (index > -1 && this.item_info.entities_info.length > index){
                            this.item_info.entities_info.splice(index, 1);
                        }
                    }else{
                        this.assigned_entity_ids.push(entity_id);
                        this.item_info.entities_info.push(entity_info);
                    }

                    this.presentToast("Success!",
                        msg,
                        'success',
                        3000,
                        true,
                        true);
                }else{
                    this.presentToast("Failed!",
                        msg,
                        'danger',
                        3000,
                        true,
                        true);
                }

            }).catch(error => {
                console.log(error);
            });
        },
    }
}
</script>
