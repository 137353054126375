<template>
    <div>

        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="'actions-generic-tasks'"
            :sub_modal_id="'actions'"
            modal_title="Complete task"
            :received_asset_info="item_info"
            @on-post-adding="postAdded"
            @on-completing-task="taskCompleted"
        />

        <UpdateCostsModal
            ref="asset-cost-fields"
            modal_id="asset-cost-fields"
            :received_item_info="item_info"
            :item_type="item_info.current_page_type"
            @on-cost-updates="onCostsUpdate" />

        <RegisterDispositionModal
            ref="register_disposition_actions"
            received_modal_id="register_disposition_actions"
            :required_fields="required_fields"
            :item_type="item_info.current_page_type"
            :other_info="other_info"
            :getAssetInfoUsingKey="getAssetInfoUsingKey"
            @on-registering-disposition="onRegisteringDisposition"
        />

        <po-lines
            :items="item_info.event_tasks"
            :current_page_type="item_info.current_page_type"
            object_type="tasks"
            :keys_list="task_list_keys_list_short">

            <template v-slot:action_name="{ item }">

                <span class="complete-action">
                    <a
                        v-if="(asset_is_locked && [4, 7].includes(item.id))"
                        href="#"
                        class="text-dark"
                        :title="'This Asset is Locked, \'' + item.name + '\' is not available.'"
                        v-b-tooltip
                        style="text-decoration: line-through !important;"
                        >
                        {{ item.name | titleize }}
                    </a>
                    <a
                        v-else-if="(asset_is_ended && [1, 5, 6, 7, 9, 10, 12].includes(item.id))"
                        href="#"
                        class="text-dark"
                        :title="'This Asset is Ended, \'' + item.name + '\' is not available.'"
                        v-b-tooltip
                        style="text-decoration: line-through !important;"
                        >
                        {{ item.name | titleize }}
                    </a>
                    <a
                        v-else-if="(asset_has_acquisition && item.id === 8)"
                        href="#"
                        class="text-dark"
                        :title="'This Asset has Acquisition, \'' + item.name + '\' is not available.'"
                        v-b-tooltip
                        style="text-decoration: line-through !important;"
                        >
                        {{ item.name | titleize }}
                    </a>
                    <a
                        v-else-if="item.id === 14"
                        href="#"
                        class="text-dark"
                        style="text-decoration: line-through !important;"
                        >
                        {{ item.name | titleize }}
                    </a>
<!--                    <a v-else-if="item.id === 14" @click="registerDisposition(item) ">{{ item.name | titleize }}</a>-->
                    <a v-else-if="item.id === 15" @click="updateCosts('asset-cost-fields')">{{ item.name | titleize }}</a>
                    <a v-else @click="completeTask(item, 'actions-generic-tasks') ">{{ item.name | titleize }}</a>
                </span>
            </template>
        </po-lines>
    </div>
</template>
<script>
import AssetTasks from "@/mixins/AssetTasks.mixin";
import PoLines from "./PoLines";
import GenericTasksForm from "./AssetTabs/GenericTasksForm";
import RegisterDispositionModal from "../../../assets/assetForm/RegisterDispositionModal";
import UpdateCostsModal from "../../../assets/assetForm/UpdateCostsModal";

export default {
    name: 'ActionsTab',
    components: {UpdateCostsModal, RegisterDispositionModal, GenericTasksForm, PoLines},
    props: ['item_info'],
    mixins: [AssetTasks],
    data(){
        return {
            required_fields: {
                dispose_asset:{
                    name: 'dispose_asset',
                    type: 'checkbox',
                    has_permission: true,
                    default: ''
                },
                disposition_type:{
                    name: 'disposition_type',
                    type: 'radio-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_category:{
                    name: 'disposition_category',
                    type: 'single-select',
                    has_permission: true,
                    default: '',
                    options: []
                },
                disposition_name:{
                    name: 'disposition_name',
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
                disposition_date:{
                    name: 'disposition_date',
                    type: 'date',
                    has_permission: true,
                    default: ''
                },
                disposition_details:{
                    name: 'disposition_details',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                sale_price:{
                    name: 'sale_price',
                    type: 'number',
                    has_permission: true,
                    default: ''
                },
                movement_info:{
                    name: 'movement',
                    type: 'textarea',
                    has_permission: true,
                    default: ''
                },
                recipient_info:{
                    name: 'recipient_info',
                    type: 'recipient_info',
                    has_permission: true,
                    default: ''
                },
                reference_info:{
                    name: 'reference', // dispositionb
                    type: 'text',
                    has_permission: true,
                    default: ''
                },
            },
            other_info: {}
        }
    },
    created() {
    },
    computed:{
        asset_is_locked(){
            return this.checkIfAssetIsLocked(this.item_info);
        },
        asset_is_ended(){
            return this.item_info ? this.item_info.hasOwnProperty('ownership_status') && this.item_info.ownership_status === 0 : false;
        },
        asset_has_acquisition(){
            return this.item_info ? this.item_info.hasOwnProperty('acquisition_id') && this.item_info.acquisition_id !== null : false;
        },
    },
    methods:{
        updateCosts(modal_id){
            this.$bvModal.show(modal_id);
        },
        onCostsUpdate(item){
            console.log("shall save Costs");
        },
        getAssetInfoUsingKey(item){

        },
        onRegisteringDisposition(item){
            console.log("shall save disposition");
        },
        registerDisposition(item){
            console.log("'shall register disposition'");
            console.log('shall register disposition');
            this.other_info = {
                used_currency: item.used_currency
            }

            this.$bvModal.show('register_disposition_actions');

            console.log("item");
            console.log(item);

        },
    },
}
</script>
