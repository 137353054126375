<template>
    <div class="row">
        <p v-if="item_type !== 'users'">{{ item_info.is_group_tv_film ? 'Productions' : 'Customers' }} that belong to&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+item_type+'/'+item_id+'/view'">{{item_name}}</a></p>

        <p v-else>{{ item_info.is_group_tv_film ? 'Productions' : 'Customers' }} that&nbsp;<a :href="base_url +'/'+ item_info.group + '/'+item_type+'/'+item_id+'/view'">{{item_name}}</a>&nbsp;belongs to.</p>



        <div class="col-lg-12" v-if="hasPermission('browse_customers')">
            <div class="text-sm-right">
                <a v-if="item_type === 'users' && hasPermission('add_users')" :href="'/'+item_info.current_page_type+'/users/new?email='+item_info.email" class="btn btn-rounded af-btn-accent af-accent">
                    <i class="fa fa-users"></i> Wizard
                </a>

                <span :id="this.items.length === 0 ? 'disabled-customer-wrapper' : 'enabled-customer-wrapper'">
                    <b-button :disabled="this.items.length === 0" v-b-modal.customers-modal variant="success" class="btn btn-success btn-rounded"><i class="mdi mdi-plus mr-1"></i> Assign To {{item_info.is_group_tv_film ? 'Production' : 'Customer'}}</b-button>
                </span>

                <b-tooltip target="disabled-customer-wrapper" placement="left" variant="info">No {{ item_info.is_group_tv_film ? 'productions' : 'customers' }} to assign.</b-tooltip>

                <b-modal id="customers-modal"
                         ref="assign-customer-modal"
                         title="Assign Customer"
                         title-class="font-18"
                         hide-footer>
                    <assign-to-customer
                        modal_id="customers-modal"
                        :items="this.items" :item_id="item_info.id" :item_type="item_info.item_type" @assigned-customer="assignToCustomer"></assign-to-customer>
                </b-modal>
            </div>
        </div>

        <div class="col-lg-12">
            <po-lines
                :items="customersData"
                :current_page_type="item_type"
                :object_type="'customers'"
                :keys_list="customer_list_keys_list">

                <template v-slot:customer_actions="{ item, index }">
                    <a v-if="hasPermission('browse_customers')" :href="base_url +'/'+ item_info.group + '/customers/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                    <a v-else :href="base_url +'/customer/overview'" class="btn btn-primary btn-sm">View</a>
                    <a v-if="hasPermission('edit_customers')" v-on:click="unAssignFromCustomer($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
                </template>
            </po-lines>
        </div>
    </div>
</template>

<script>
    import SearchBox from "@/components/search-box";
    import AssignToCustomer from "../forms/AssignToCustomer";
    import Resource from '@/api/resource';
    import Globals from "@/mixins/global.mixin";
    import {CustomerShortKeysList} from "@/views/assetflow/form_data/CustomerShortKeysList";

    import PoLines from "./detailsViews/PoLines";
    export default {
        name: "ListCustomers",
        mixins: [Globals],
        components: {PoLines, AssignToCustomer, SearchBox},
        props: {
            item_info: Object,
        },
        data() {
            return {
                customer_list_keys_list: CustomerShortKeysList,
                base_url: "",
                customersData: [],
                item_id: null,
                item_name: "",
                item_type: "",
                items: [],
                listLoading: false,
                listQuery:{
                    page: 1
                },
                assignData: {
                    user_id: "",
                    customer_id: ""
                }
            }
        },
        created() {

            this.customer_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 c_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'customer_actions'
            }

            this.item_id = this.item_info.id;
            this.item_name = this.item_info.name;
            this.item_type = this.item_info.item_type;
            this.base_url = this.item_info.base_url;
            this.getList();
            this.getCustomersList();
        },
        methods: {
            getList() {
                if (!this.listLoading) {
                    this.listLoading = true;
                    axios({
                        method: 'post',
                        url: '/api/assetflow/'+this.item_type+'/' + this.item_id + '/customers' ,
                        baseURL: '/',
                        data: {
                            page: 1
                        },
                    }).then(response => {
                        this.listLoading = false;
                        this.customersData = response.data.data.items;
                        this.total = response.data.data.total;
                        this.listLoading = false;

                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });
                }
            },
            assignToCustomer(form){
                this.$refs['assign-customer-modal'].hide();
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: form.item ? form.item.id : null,
                    item_type: 'customers',
                    account_no: form.account_no
                };

                this.assign_unassign_customer();

                console.log("should assign user to customer " + form.item + this.item_id);
            },
            assign_unassign_customer() {
                axios({
                    method: 'post',
                    url: '/api/assetflow/'+this.item_type+'/assign_customer',
                    baseURL: '/',
                    data: this.assignData,
                }).then(response => {
                    let status = response.data.status;
                    let msg = response.data.msg;
                    if (status) {

                        this.presentToast("Success!",
                            msg,
                            'success',
                            3000,
                            true,
                            true);
                        this.getList();
                        this.getCustomersList();
                    }else{
                        this.presentToast("Failed!",
                            msg,
                            'danger',
                            3000,
                            true,
                            true);
                    }

                }).catch(error => {
                    console.log(error);
                });
            },
            unAssignFromCustomer(event, customer_id){
                this.assignData = {
                    owner_id: this.item_id,
                    item_id: customer_id,
                    item_type: 'customers',
                    unassign: true
                };
                this.assign_unassign_customer();
                console.log("should unassign user from customer >> " + customer_id);

            },
            async getCustomersList() {
                this.listLoading = true;
                this.listQuery.item_type = this.item_type;
                this.listQuery.item_id = this.item_id;

                const customerResource = new Resource('api/assetflow/customers');

                const { data } = await customerResource.list(this.listQuery);

                this.items =  data.data.items.filter((customer) => {
                    return !this.customersData.map(x => x.id).includes(customer.id);
                });


                this.listLoading = false;
            },
        }
    }
</script>


