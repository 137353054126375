<template>
    <div class="AcquisitionLineItemsTab">

        <div class="row">
            <NewCustomGenericBlock
                block_title="Summary"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>
                    <LineItemTotalsBlocksForAcquisition :item_info="item_info" :with_line_items="true"/>
                </template>
            </NewCustomGenericBlock>
        </div>

        <div class="row">
            <NewCustomGenericBlock
                block_title="Line Items"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>

                    <po-lines v-if="item_info.hasOwnProperty('line_items') && item_info.line_items && item_info.line_items.length > 0"
                              :items="item_info.line_items"
                              :current_page_type="item_info.current_page_type"
                              :object_type="'assets'"
                              :line_type="'assets'"
                              :keys_list="line_items_key_list">

                    </po-lines>
                    <div v-else class="text-center">
                        <h6>No Line Items found here at the moment.</h6>
                    </div>
                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines";
import {AcquisitionLineItemKeysList} from "@/views/assetflow/form_data/AcquisitionLineItemKeysList";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import LineItemTotalsBlocksForAcquisition from "./LineItemTotalsBlocksForAcquisition";

export default {
    name: 'AcquisitionLineItemsTab',
    components: {LineItemTotalsBlocksForAcquisition, NewCustomGenericBlock, PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            line_items_key_list:AcquisitionLineItemKeysList,
        }
    },
    created() {
        let item_info = this.item_info;
        let current_customer = this.current_customer;
        delete this.line_items_key_list.actions;


        this.line_items_key_list.net_total.title_func = this.toUserCurrencyValueTitle;
        this.line_items_key_list.net_total.value_func = this.getCurrencyByValueText;

        this.line_items_key_list.tax.title_func = this.toUserCurrencyValueTitle;
        this.line_items_key_list.tax.value_func = this.getCurrencyByValueText;

        this.line_items_key_list.total_price.title_func = this.toUserCurrencyValueTitle;
        this.line_items_key_list.total_price.value_func = this.getCurrencyByValueText;


    },
    methods: {
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },

    }

}
</script>
