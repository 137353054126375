<template>
    <Layout>
        <AddAssetMenuContent :item_type="item_type" class="add-asset-menu-content"/>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import AddAssetMenuContent from "./AddAssetMenuContent";
export default {
    name: "AddAssetMenu",
    components: {
        AddAssetMenuContent,
        Layout
    },
    data(){
        return { item_type: '' }
    },
    props: [],
    created() {
        this.item_type = this.current_entity && this.current_entity.hasOwnProperty('entity_item_type') && this.current_entity.entity_item_type ? this.current_entity.entity_item_type : 'customer';
    }
}
</script>

