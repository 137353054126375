<template>
    <div class="step-form-content-block row">
        <div class="col-xl-6 st-col-6">

            <asset-info-section
                v-for="(structure_field, key) in structure_fields"
                :key="key"
                v-if="structure_field.key === 'tag_ids'"
                :title="structure_field.label" :selected_value="getValueFor(structure_field.key)">
                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Select {{structure_field.label}}</h5>
                    </div>

                    <multiselect
                        v-model="addForm.multi_select_v_models[structure_field.key]"
                        track-by="id"
                        label="name"
                        :multiple="true"
                        :placeholder="'Select ' + structure_field.label"
                        class="mySingleSelect"
                        :options="required_fields[structure_field.key].options"
                        deselect-label=""
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>

                </div>
            </asset-info-section>

            <asset-info-section
                v-else
                :title="structure_field.label"
                :is_afid_field="structure_field.key === 'category_id'"
                :selected_value="getValueFor(structure_field.key)">

                <div class="st--common--box">
                    <div class="common-st-title-label">
                        <h5>Select {{structure_field.label}}</h5>
                    </div>

                    <div v-if="structure_field.key === 'category_id' && asset_is_locked">
                        <LockedAssetWarning icon="bx bx-lock" message="This Asset is Locked, 'Changing Category' is not available."/>
                    </div>

                    <multiselect
                        v-else
                        v-model="addForm.single_select_v_models[structure_field.key]"
                        track-by="id"
                        :custom-label="CustomMSLabel"
                        class="category-options"
                        :options="getOptionsFor(structure_field.key)"
                        :allow-empty="false"
                        :placeholder="'Select ' + structure_field.label"
                    >
                        <span slot="noResult">No data found.</span>
                    </multiselect>


                </div>
            </asset-info-section>
        </div>
    </div>
</template>

<script>
import AssetInfoSection from "./AssetInfoSection";
import multiSelect from '@/mixins/MultiSelect';
import LockedAssetWarning from "./LockedAssetWarning";
export default {
    mixins: [multiSelect],
    name: "StructureInfo",
    components: {LockedAssetWarning, AssetInfoSection},
    props: ['addForm', 'required_fields', 'asset_is_locked'],
    data(){
        return {
            structure_fields:{
                folder_id: {
                    key: 'folder_id',
                    label: 'Folder'
                },
                category_id: {
                    key: 'category_id',
                    label: 'Category'
                },
                tag_ids: {
                    key: 'tag_ids',
                    label: 'Tags'
                }
            }
        }
    },
    methods:{
        getOptionsFor(field_key){
            if (['folder_id', 'category_id'].includes(field_key)){
                let folders_list = this.required_fields[field_key].options;
                let item_type = field_key.replace('_id', '') + 's';
                return this.getFolderObject(folders_list, 1, item_type, 'id', 'name');
            }
            return this.required_fields[field_key].options;
        },
        getValueFor(field_id){
            if (field_id === 'tag_ids') {
                let tag_names = this.addForm.multi_select_v_models.tag_ids.map(function (e) {
                    return e.name;
                }).join(', ');
                return tag_names;
            }
            return this.addForm.single_select_v_models[field_id] ? this.addForm.single_select_v_models[field_id].name : '';
        },
        getValueFrom(value_id, items_list, required_value, used_key='id'){
            let foundItem = find(items_list, function(o) { return o[used_key] === value_id; });
            if (foundItem){
                return foundItem[required_value];
            }
            return value_id;
        }
    }
}
</script>


