<template>
    <div class="AcquisitionAssetsTab">


        <div class="row">
            <NewCustomGenericBlock
                block_title="Summary"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>
                    <LineItemTotalsBlocksForAcquisition :item_info="item_info" :with_line_items="false"/>
                </template>
            </NewCustomGenericBlock>
        </div>

        <div class="row">
            <NewCustomGenericBlock
                block_title="Assets"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>

                    <po-lines v-if="item_info.hasOwnProperty('assets_info') && item_info.assets_info && item_info.assets_info.length > 0"
                              :items="item_info.assets_info"
                              :current_page_type="item_info.current_page_type"
                              :object_type="'assets'"
                              :line_type="'assets'"
                              :get-poline-classes-dict="getPoLineClassFor"
                              :keys_list="asset_list_key_list">
                        <template v-slot:asset_list_actions="{ item, index }">
                            <div v-if="has_actions_permissions">
                                <asset-actions-dropdown
                                    :item="item"
                                    :item_type="item_info.current_page_type"
                                    :perform-action="null"/>
                            </div>
                        </template>
                    </po-lines>
                    <div v-else class="text-center">
                        <h6>No assets found here at the moment.</h6>
                    </div>
                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines";
import AssetActionsDropdown from "../assets/subItems/AssetActionsDropdown";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import LineItemTotalsBlocksForAcquisition from "./LineItemTotalsBlocksForAcquisition";
export default {
    name: 'AcquisitionAssetsTab',
    components: {LineItemTotalsBlocksForAcquisition, NewCustomGenericBlock, AssetActionsDropdown, PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            has_actions_permissions:false,
            asset_list_key_list:{
                uid: {
                    key: 'uid',
                    label: "ID",
                    class: "col-lg-1 a_uid",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                category_id: {
                    key: 'category_id',
                    label: "Category",
                    class: "col-lg-1 a_category_id",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                name: {
                    key: 'name',
                    label: "Name",
                    class: "col-lg-2 a_name",
                    show_on_mobile: true,
                    case: 'custom_html_tooltip',
                    title_func: function (index, item, key) {
                        return (item.uid !== '' ? 'UID: ' + item.uid : '');
                    },
                    value_func: function (index, item, key) { }
                },
                ledger: {
                    key: 'ledger',
                    label: "",
                    class: "",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '';
                    }
                },
                character: {
                    key: 'character',
                    label: "",
                    class: "",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '';
                    }
                },
                asset_usage: {
                    key: 'asset_usage',
                    is_html_label: true,
                    label: "<span class='au-cam'>Cam</span><span class='au-is-hero'>Hero</span><span class='au-is-asset'>Asset</span>",
                    class: "col-lg-1 a_asset_usage",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item){
                        var html = '';
                        let required_items = ['asset_usage', 'is_hero', 'is_asset'];
                        for (const key in required_items) {
                            let assetKey = required_items[key];

                            let required_classes = '';
                            switch (item[assetKey]){
                                case 0:
                                    required_classes = 'text-danger bx-x';
                                    break;
                                case 1:
                                    required_classes = 'text-success bx-check';
                                    break;
                                case 2:
                                    required_classes = 'text-secondary bx-question-mark';
                                    break;
                            }

                            html += "<b><i class='bx font-size-22 " + required_classes + "'>" + "</i></b>"

                        }
                        return html;
                    }
                },

                quantity: {
                    key: 'quantity',
                    label: "Qty",
                    class: "col-lg-1 a_quantity",
                    show_on_mobile: false,
                    case: ''
                },
                net_total: {
                    key: 'net_total',
                    label: "Net Total",
                    class: "col-lg-1 a_net_total",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                total_value: {
                    key: 'total_value',
                    label: "Gross Total",
                    class: "col-lg-1 a_total_value",
                    show_on_mobile: false,
                    case: 'custom_tooltip',
                    title_func: this.toUserCurrencyValueTitle,
                    value_func: this.getCurrencyByValueText
                },
                internal_reference: {
                    key: 'internal_reference',
                    label: "Ref No.",
                    class: "col-lg-1 a_reference",
                    show_on_mobile: false,
                    case: ''
                },
                actions: {
                    key: 'actions',
                    label: "Actions",
                    class: "col-lg-1 a_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'asset_list_actions'
                }
            },
        }
    },
    created() {
        this.has_actions_permissions = this.hasPermission('edit_assets') || this.hasPermission('delete_assets');

        let item_info = this.item_info;
        let current_customer = this.current_customer;

        let item_type = item_info.current_page_type;
        this.asset_list_key_list.character = {
            key: 'character',
            label: "Character",
            class: "col-lg-1 a_character",
            show_on_mobile: false,
            case: 'custom_html',
            value_func: function (item, key) {
                let character_info = item.character_info;
                if (character_info){
                    return '<a class="text-dark" href="/' + item_type + '/characters/' + character_info.id + '/view">' + `(${character_info.character_number}) ${character_info.name}` + '</a>';
                }
                return '';
            }
        }
        this.asset_list_key_list.ledger = {
            key: 'ledger',
            label: "Ledger Account",
            class: "col-lg-1 a_ledger",
            show_on_mobile: false,
            case: 'custom_html',
            value_func: function (item, key) {
                let ledger_info = item.ledger_info;
                if (ledger_info){
                    return '<a class="text-dark" href="/' + item_type + '/ledgers">' + `${ledger_info.name}` + '</a>';
                }
                return '';
            }
        }


        this.asset_list_key_list.uid.value_func = function (item, key) {
            return item_info ? '<a class="text-dark" href="/' + item_type +'/assets/' + item.id + '/view">ASS-' + item[key] + '</a>' : '';
        };
        this.asset_list_key_list.name.value_func = function (index, item, key) {
            return item_info ? '<a class="text-dark" href="/' + item_type +'/assets/' + item.id + '/view">' + Vue.options.filters.titleize(item.name) + '</a>' : '';
        };

        this.asset_list_key_list.category_id.value_func = function (item, key) {

            if (item.hasOwnProperty('category_info') && item.category_info){
                return '<a class="text-dark" href="/' + item_type +'/assets?queue=category&id='+item.category_id+'">' + item.category_info.name + '</a>';
            }
            return '-';
        };

        if (!this.is_tv_film_asset){
            delete this.asset_list_key_list.asset_usage;
        }

    },
    methods: {
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },

    }

}
</script>
