<template>
    <Layout>
        <div class="row" v-if="isLoadingItem">
            Loading...
        </div>
        <SetServiceAndConfirm
            v-else-if="item_info"
            :item_type="item_type"
            :received_item_info="item_info"
            :customer_overview="customer_overview"
            @after-saving="goToDetailPage"
            @on-cancel-clicked="goToDetailPage"
            :shall_confirm="true"/>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import SetServiceAndConfirm from "./SetServiceAndConfirm";

export default {
    name: "ConfirmOrder",
    props: ['item_id', 'other_item_id', 'item_type'],
    components: {
        SetServiceAndConfirm,
        Layout
    },
    data(){
        return {
            isLoadingItem: true,
            item_info: null,
            customer_overview:{
                label: 'Overview',
                action_required_title: '',
                action_name: '',
                has_action: false,
                items_class: 'col-md-6',
                info_list: []
            },
        }
    },
    created() {
        this.fetchItemInfo();
    },
    methods:{
        goToDetailPage(modal_id=null){
            if (modal_id){
                this.$bvModal.hide('set_order_service');
            }
            window.location.href = '/' + this.item_type + '/orders/' + this.item_id + '/view';
        },
        getCustomerOverView(item_info, used_functions, item_type){
            return [
                {
                    label: 'Order ID',
                    icon: 'fa fa-star',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.uid;
                    },
                    info_value : function () {
                        return item_info.uid;
                    }
                },
                {
                    label: 'Customer',
                    icon: 'fa fa-star',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('customer_info') && item_info.customer_info;
                    },
                    info_value : function () {
                        let customer_link = '/' + item_type + '/customers/'+item_info.customer_info.id+'/view';
                        return '<i class="fa fa-star"></i> <a href="' + customer_link + '" class="text-dark">' + item_info.customer_info.name + '</a>';
                    }
                },
                {
                    label: 'Order Name',
                    icon: 'fa fa-star',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.name;
                    },
                    info_value : function () {
                        return item_info.name;
                    }
                },
                {
                    label: 'Customer Reference',
                    icon: 'fa fa-star',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.reference;
                    },
                    info_value : function () {
                        return item_info.reference;
                    }
                },
                {
                    label: 'Status',
                    icon: 'fa fa-star',
                    is_html_value: true,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        let status_class = '' + used_functions.getOrderStatus(item_info, true);
                        return '<i class="fa fa-star"></i> <span class="order-status ' + status_class + '">' + used_functions.getOrderStatus(item_info) + '</span>';
                    }
                },

                {
                    label: 'Target Date',
                    icon: 'fa fa-star',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('service_target_date') && item_info.service_target_date && item_info.service_target_date !== '';
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(item_info.service_target_date, 'DD/MM/YYYY\ hh.mm a');
                    }
                },

            ];
        },
        getOrderStatus(order, just_class=false){
            switch (order.status){
                case 0:
                    return just_class ? 'status-open' : 'Open';
                case 1:
                    return just_class ? 'status-confirmed' : 'Confirmed';
                case 2:
                    return just_class ? 'status-in_progress' : 'In Progress';
                case 3:
                    return just_class ? 'status-completed' : 'Completed';
                case 4:
                    return just_class ? 'status-cancelled' : 'Cancelled';
                case 5:
                    return just_class ? 'status-awaiting-transport' : 'Awaiting Transport';
                default:
                    return just_class ? 'status-open' : 'Open';
            }
        },
        fetchItemInfo(){
            if (this.item_id){
                let used_functions = {
                    getOrderStatus: this.getOrderStatus,
                }
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/orders/' + this.item_id,
                    baseURL: '/',
                }).then(response => {
                    this.item_info = response.data;
                    this.customer_overview.info_list = this.getCustomerOverView(this.item_info, used_functions, this.item_type);
                    this.isLoadingItem = false;
                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        },
    }
}
</script>
