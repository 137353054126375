export default {
    data() {
        return {
            layout: {
                type: "vertical",
                sidebar: "dark",
                width: "fluid",
                topbar: "light",
                loader: true
            },
            form_lists: {},
            shared_info: {}
        };
    },
    created() {
        const layout = JSON.parse(localStorage.getItem("layout")) || {};
        if (layout) {
            this.layout = {
                ...this.layout,
                ...layout,
                sidebar:
                    layout?.type === "horizontal"
                        ? "light"
                        : layout?.sidebar || "dark"
            };
        }
    },
    methods: {
        async getImagePreSignedURL(image_info) {
            return new Promise( (resolve, reject) => {
                // shall get new link then open it!
                axios({
                    method:'post',
                    url:'/api/assetflow/images/getImage',
                    baseURL: '/',
                    data: {
                        image_id: image_info.id
                    }
                }).then(response => {
                    if (response.data.hasOwnProperty('data')){
                        if (response.data.data.hasOwnProperty('image_url') && response.data.data.image_url !== ''){
                            resolve(response.data.data.image_url);
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            });
        },
        presentModal(modal_name, image_info, image_size, done_action){
            switch (modal_name) {
                case 'edit_image_info':
                    if (this.$root.$children[0].$children[0]){
                        let shared_image_editor = null;
                        if (this.$root.$children[0].$children[0].$refs['shared-edit-image-notes']){
                            shared_image_editor = this.$root.$children[0].$children[0].$refs['shared-edit-image-notes'];
                        }else if (this.$root.$children[0].$children[0].$children[0].$refs['shared-edit-image-notes']){
                            shared_image_editor = this.$root.$children[0].$children[0].$children[0].$refs['shared-edit-image-notes'];
                        }

                        if (shared_image_editor){
                            shared_image_editor.setSelectedItem(image_info);
                            shared_image_editor.setDoneAction(done_action);
                            // image_info.image_name = '';
                            // image_info.name = 'Editing...';
                            // image_info.description = 'Editing...';
                            this.$bvModal.show('create-shared-image-notes');
                        }
                    }
                    break;
                case 'edit_image':
                    if (this.$root.$children[0].$children[0]){
                        image_info.image_size = image_size;

                        let shared_image_editor = null;
                        if (this.$root.$children[0].$children[0].$refs['shared-image-editor']){
                            shared_image_editor = this.$root.$children[0].$children[0].$refs['shared-image-editor'];
                        }else if (this.$root.$children[0].$children[0].$children[0].$refs['shared-image-editor']){
                            shared_image_editor = this.$root.$children[0].$children[0].$children[0].$refs['shared-image-editor'];
                        }
                        this.getImagePreSignedURL(image_info)
                            .then( (image_url) => {
                                if (image_url){
                                    image_info.image_url = image_url;
                                    if (shared_image_editor){
                                        shared_image_editor.setSelectedItem(image_info);
                                        shared_image_editor.setDoneAction(done_action);
                                        // image_info.image_name = '';
                                        // image_info.name = 'Editing...';
                                        // image_info.description = 'Editing...';
                                        this.$bvModal.show('create-shared-image-editor');
                                    }
                                }
                            });
                    }
                    break;
                case 'show_full_image':
                    this.getImagePreSignedURL(image_info)
                        .then( (image_url) => {
                            if (image_url){
                                window.open(image_url, '_blank');
                            }
                        });

                    break;
                case 'delete_image':
                    this.deleteItemByID(image_info.id, image_info.name, 'images', image_info)
                        .then((response) => {
                            if (response.data.data.status){
                                image_info.image_name = '';
                            }
                        });
                    break;
                case 'show_media':
                    if (image_info.hasOwnProperty('image_item_type') && image_info.image_item_type !== ''){
                        let object_path = this.getObjectPathFrom(image_info.image_item_type);
                        if (object_path !== ''){
                            window.location = encodeURI("/" + this.shared_info.current_page_type + "/" + object_path + "/" + image_info.item_id + "/view?t=media");
                        }
                    }
                    break;
                default:
                    console.log(this.$root.$children[0].$children[0]);

                    console.log("image_info");
                    console.log(image_info);
                    break;
            }

        },
        changeMode(mode) {
            let cssUrl = document.getElementById("layout-css").href;
            cssUrl = cssUrl.split("/");
            cssUrl.pop();
            document.cookie = "layout=" + (mode || "default");
            switch (mode) {
                case "dark":
                    cssUrl.push("app-dark.css");
                    break;
                case "rtl":
                    cssUrl.push("app-rtl.css");
                    break;
                default:
                    cssUrl.push("app.css");
                    break;
            }
            document.body.setAttribute("data-layout", mode);
            document.getElementById("layout-css").href = cssUrl.join("/");
        },
        changeLayout(change) {
            this.layout = {
                ...this.layout,
                ...change
            };
            localStorage.setItem("layout", JSON.stringify(this.layout));
        },
        changeSidebar(sidebar) {
            switch (sidebar) {
                case "dark":
                    document.body.setAttribute("data-sidebar", "dark");
                    document.body.removeAttribute("data-topbar");
                    document.body.removeAttribute("data-sidebar-size");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "light":
                    document.body.setAttribute("data-topbar", "dark");
                    document.body.removeAttribute("data-sidebar");
                    document.body.removeAttribute("data-sidebar-size");
                    document.body.classList.remove("vertical-collpsed");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "compact":
                    document.body.setAttribute("data-sidebar-size", "small");
                    document.body.setAttribute("data-sidebar", "dark");
                    document.body.classList.remove("vertical-collpsed");
                    document.body.removeAttribute("data-topbar", "dark");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "icon":
                    document.body.setAttribute("data-keep-enlarged", "true");
                    document.body.classList.add("vertical-collpsed");
                    document.body.setAttribute("data-sidebar", "dark");
                    document.body.removeAttribute("data-topbar", "dark");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "colored":
                    document.body.setAttribute("data-sidebar", "colored");
                    document.body.removeAttribute("data-keep-enlarged");
                    document.body.classList.remove("vertical-collpsed");
                    document.body.removeAttribute("data-sidebar-size");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "scrollable":
                    document.body.setAttribute(
                        "data-layout-scrollable",
                        "true"
                    );
                    document.body.removeAttribute("data-layout-mode");
                    break;
                default:
                    document.body.setAttribute("data-sidebar", "dark");
                    break;
            }
        },
        changeTopbar(topbar) {
            switch (topbar) {
                case "dark":
                    document.body.setAttribute("data-topbar", "dark");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "light":
                    document.body.setAttribute("data-topbar", "light");
                    document.body.removeAttribute("data-layout-size", "boxed");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "colored":
                    document.body.setAttribute("data-topbar", "colored");
                    document.body.removeAttribute("data-layout-size", "boxed");
                    document.body.removeAttribute("data-layout-scrollable");
                    break;
                case "scrollable":
                    document.body.setAttribute(
                        "data-layout-scrollable",
                        "true"
                    );
                    document.body.removeAttribute("data-layout-mode");
                    break;
                default:
                    document.body.setAttribute("data-topbar", "dark");
                    break;
            }
        },
        changeWidth(width) {
            switch (width) {
                case "boxed":
                    document.body.setAttribute("data-layout-size", "boxed");
                    break;
                case "fluid":
                    document.body.setAttribute("data-layout-mode", "fluid");
                    document.body.removeAttribute("data-layout-size");
                    break;
                default:
                    document.body.setAttribute("data-layout-mode", "fluid");
                    break;
            }
        },
        changeLoader(loader) {},
        setFormLists(form_lists) {
            let data = JSON.parse(form_lists);
            this.form_lists = data;
        },
        setSharedInfo(sharedInfo) {
            this.shared_info = JSON.parse(sharedInfo);
        }
    },
    watch: {
        layout: {
            immediate: true,
            handler(newLayout, oldLayout) {
                if (newLayout) {
                    if (
                        newLayout.sidebar !== oldLayout?.sidebar ||
                        newLayout.type !== oldLayout?.type
                    ) {
                        this.changeSidebar(newLayout.sidebar);
                    }
                    if (
                        newLayout.topbar !== oldLayout?.topbar ||
                        newLayout.type !== oldLayout?.type
                    ) {
                        this.changeTopbar(newLayout.topbar);
                    }
                    if (newLayout.loader !== oldLayout?.loader) {
                        this.changeLoader(newLayout.loader);
                    }
                    if (newLayout.width !== oldLayout?.width) {
                        this.changeWidth(newLayout.width);
                    }
                }
            }
        }
    }
};
