<template>
    <Layout>

        <EditPartShootDate
            ref="edit-part-shoot-date"
            item_type='part-shoot-date'
            modal_title="Edit Shoot Date"
            :with_item_details="false"
            @on-new-item-adding="getList(selected_item)"
        >
        </EditPartShootDate>
        <div class="parts-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <h2 class="title">Scene List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Parts.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>

                                <b-dropdown-item href="./parts/new">
                                    Part
                                </b-dropdown-item>

                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="sidebad-toggle af-accent"></div>
                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-if="supportedLayouts && supportedLayouts.length > 0"
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                    </div>


                    <LotsOfItemsAlert :perpage="perpage"/>
                    <div v-if="listLoading" class="products text-center">
                        <b-spinner class="text-af-accent m-2"></b-spinner>
                        <h4>Fetching Parts...</h4>
                    </div>
                    <div v-else-if="!partsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Parts found here at the moment.</h4>

                    </div>

                    <!-- Product Section Start-->
                    <div v-else-if="partsData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="permission_name"
                                :keys_list="part_keys_list">

                                <template v-slot:parts_actions="{ item, index }">
                                    <div class="btn-group bl-auto">
                                        <button type="button" class="btn btn-none dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <b-dropdown-item v-if="hasPermission('edit_' + permission_name)" :href="'./'+permission_name+'/'+item.id + '/edit'">Edit</b-dropdown-item>
                                            <b-dropdown-item v-if="hasPermission('edit_' + permission_name)" href="#" @click="performAction(item, 'shoot_date', index, $event)">Edit Shoot Date</b-dropdown-item>

                                            <b-dropdown-item v-if="hasPermission('delete_' + permission_name)"
                                                             variant="danger"
                                                             @click="performAction(item, 'delete'+permission_name, index, $event)">
                                                Delete
                                            </b-dropdown-item>
                                        </div>
                                    </div>
                                </template>

                            </po-lines>
                        </div>
                    </div>

                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewOrderLine from "../extras/AddNewOrderLine";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";

const partResource = new Resource('api/assetflow/parts');
import {PartKeysList} from "@/views/assetflow/form_data/PartKeysList";

import {ReturnDatesList} from "@/views/assetflow/form_data/ReturnDatesList";
import EditPartShootDate from "../extras/EditPartShootDate";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'PartList',
    props: ['item_id', 'item_type'],
    components: {LotsOfItemsAlert, EditPartShootDate, ObjectsSidebar, PoLines, AddNewOrderLine, SearchBox, Layout},
    mixins: [TimelineCommons, ObjectsSidebarShared],
    data() {
        return {
            listLoading: false,
            total: 0,
            partsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Scenes",
            items: [
                {
                    text: "Scenes",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_part_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 0,
                    text: 'Part Order',
                    first_criteria: 'target_scene',
                    first_reversed: false,
                    name: 'part_order',
                    second_criteria: 'scene_order',
                    second_reversed: false,
                    third_criteria: 'part_order',
                    third_reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'parts',

            object_type: 'parts',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Scenes',
                    backend_key: 'scene_id'
                },
                {
                    id: 2,
                    label: 'Locations',
                    backend_key: 'scene_location_id'
                },
                {
                    id: 3,
                    label: 'Characters',
                    backend_key: 'character_id'
                },
                {
                    id: 4,
                    label: 'Shoot Day',
                    backend_key: 'shoot_day_id'
                },
                {
                    id: 5,
                    label: 'Shoot Schedule',
                    backend_key: 'shoot_schedule_id'
                },
            ],
            items_list: [
                [],
                // Scenes
                [],
                // Locations
                [],
                // Characters
                [],
                // Shoot Date
                [],
                ReturnDatesList
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'scenes'
                },
                {
                    index: 2, // index in items_list
                    object_name: 'scene_locations'
                },
                {
                    index: 3, // index in items_list
                    object_name: 'characters'
                },
                {
                    index: 4, // index in items_list
                    object_name: 'shoot_days'
                },
            ],
            part_keys_list: PartKeysList
        };
    },
    created() {
        this.sorting.sortOptions.push(this.sorting.sortType);
        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
            // grid: {
            //     name: 'Grid',
            //         value: 1,
            //         class: 'grid',
            //         isActive: true
            // }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);



        this.has_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];
        if (!this.has_actions_permissions){
            delete this.part_keys_list.actions;
        }


        // if (this.item_type === 'sp'){
        //     if (this.is_tv_film_asset){
        //         this.part_keys_list.customer_info.label = 'Production';
        //     }
        //     delete this.part_keys_list.sp_info;
        // }else if (this.item_type === 'customer') {
        //     delete this.part_keys_list.customer_info;
        // }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_parts_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.part_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.part_preferred_sorting;
        }



        this.getList(this.selected_item);
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        performAction(part, action, partKey){
            if (action === ('delete'+this.permission_name)){
                if (part.id !== ''){
                    this.deleteItemByID(part.id, (part.name || part.uid), this.object_type, part)
                        .then((response) => {
                            if (response.data.data.status){
                                this.partsData.splice(partKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }else if (action === 'shoot_date'){
                // shall open modal to edit shoot date
                this.$refs['edit-part-shoot-date'].setSelectedItem(part);
                this.$bvModal.show('create-part-shoot-date');
            }
        },
        searchFor(keyword, part) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'name'];
                    return this.handleSearch(searchable_values, element, wordToCompare);
                }
                filtered_list = this.partsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.partsData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('part_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_parts_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await partResource.list(required_query);
            this.total = data.meta.total;
            this.partsData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },

    },
}
</script>


