<template>
    <div class="cm_list_view asset-list-item" :class="getAssetClasses(my_asset, selected_asset_id)">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="my_asset" :show-captiom-img="showCaptiomImg" :required_image_size="'asset_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-7">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <h3><a :href="'/' + item_type + '/assets/' + my_asset.id + '/view'"><i class="bx" :class="my_asset.ownership_type === 1 ? 'bx-cube-alt' : 'bx-time'"></i> {{ my_asset.name | titleize }}</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-5">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title"><h3><a href="#" :class="'status-btn status-background-orange'">Intake In Progress</a></h3></div>
                            <div class="cm_status_ul">
                                <ul>
                                    <li>
                                        <p>Intake Total <span class="total_box_bg">{{ my_asset.quantity }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-7">

                        <div class="common_col_desc asset-description" v-if="my_asset.description && my_asset.description !== ''">
                            <p>{{ my_asset.description }}</p>
                        </div>


                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Colours</span>
                                        <div v-html="getColourHTML()"></div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Weight</span>
                                        <p>
                                            <span
                                                v-if="my_asset.hasOwnProperty('weight') && my_asset.weight">
                                                {{ my_asset.weight }}kg
                                            </span>
                                            <span v-else>0kg</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Height</span>
                                        <p>
                                            <span
                                                v-if="my_asset.hasOwnProperty('height') && my_asset.height">
                                                {{ my_asset.height }}{{ my_asset.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Width</span>
                                        <p>
                                            <span
                                                v-if="my_asset.hasOwnProperty('width') && my_asset.width">
                                                {{ my_asset.width }}{{ my_asset.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Depth</span>
                                        <p>
                                            <span
                                                v-if="my_asset.hasOwnProperty('depth') && my_asset.depth">
                                                {{ my_asset.depth }}{{ my_asset.preferred_unit }}
                                            </span>
                                            <span v-else>0m</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="cm_usage border_r">
                                        <span>Area/Volume</span>
                                        <p>
                                            <span
                                                v-if="my_asset.hasOwnProperty('total_area') && my_asset.total_area">
                                                {{ (my_asset.area * my_asset.quantity).toFixed(2) }}m<sup>2</sup>
                                            </span>
                                            <span v-else>0m<sup>2</sup></span>
                                            <span
                                                v-if="my_asset.hasOwnProperty('total_volume') && my_asset.total_volume">
                                                {{ (my_asset.volume * my_asset.quantity).toFixed(2) }}m<sup>3</sup>
                                            </span>
                                            <span v-else>0m<sup>3</sup></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Waste Stream</span>
                                        <p><b>{{ my_asset.waste_stream_type === 0 ? 'Product' : 'Material' }}</b></p>
                                        <p>
                                            <span v-if="my_asset.waste_stream_type === 0 && my_asset.hasOwnProperty('waste_stream_info') && my_asset.waste_stream_info">{{ my_asset.waste_stream_info.name }}</span>
                                            <span v-else-if="my_asset.waste_stream_type === 1 && my_asset.hasOwnProperty('composition_info') && my_asset.composition_info">
                                                {{my_asset.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ') }}
                                            </span>
                                            <span v-else></span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage border_r">
                                        <span>Current Condition</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="my_asset.hasOwnProperty('condition')">
                                                {{ getConditionText(my_asset.condition) }}
                                            </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6 col-xl-5 cm_order">
                        <div class="cm_list_last_update mt-1">
                            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-info rts-image-note"> <i class="mdi mdi-alert-circle-outline me-2"></i> Order and Storage details will be available once this 'Add to Storage' has been completed.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/assets/' + my_asset.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import MyBCarouselSlide from "../../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import TagsManager from "../TagsManager";
import AssetsActionsList from "../AssetsActionsList";
import AssetIconList from "./AssetIconList";
import StorageOrderRightBox from "./StorageOrderRightBox";
import StorageOrderLeftBox from "./StorageOrderLeftBox";
import DispositionLeftBox from "./DispositionLeftBox";
import DispositionRightBox from "./DispositionRightBox";
import AssetLayoutLeftBox from "./AssetLayoutRightBox";
import AssetLayoutRightBox from "./AssetLayoutRightBoxOld";
import AcquisitionLeftBox from "./AcquisitionLeftBox";
import AcquisitionRightBox from "./AcquisitionRightBox";
import MyCarouselNavigation from "./MyCarouselNavigation";
import MyBCarouselImages from "./MyBCarouselImages";

export default {
    name: 'AssetPreviewListView',
    components: {
        MyBCarouselImages,
        MyCarouselNavigation,
        AcquisitionLeftBox,
        AcquisitionRightBox,
        AssetLayoutRightBox,
        AssetLayoutLeftBox,
        DispositionRightBox,
        DispositionLeftBox,
        StorageOrderLeftBox,
        StorageOrderRightBox, AssetIconList, AssetsActionsList, TagsManager, MyBCarouselSlide},
    data(){
        return {
            custom_class: ''
        }
    },
    props:[
        'item_type',
        'my_asset',
        'selected_asset_id',
        'showCaptiomImg',
        'all_tags_list',
        'acquisition_methods',
    ],
    computed:{
        asset_is_locked(){
            return this.checkIfAssetIsLocked(this.asset);
        }
    },
    methods:{
        getColourHTML(){
            let colours_info = this.my_asset.hasOwnProperty('colours_info') ? this.my_asset.colours_info : null;
            if (colours_info){
                let html = '';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                return html;
            }
            return '-';
        },
        getConditionText(condition){
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },
    }
}
</script>
