<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item[ledger_category === 'buyer' ? 'nominal_code' : 'uid'] + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="setLedgerType(ledger_category)"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_fields"
                :key="required_field.key"
                v-if="!(required_field.hasOwnProperty('is_hidden') && required_field.is_hidden)"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    :track-by="required_field.key === 'currency' ? 'value' : 'id'"
                    :custom-label="required_field.key === 'currency' ? currencyWithIcon : CustomNameOrDesc"
                    :placeholder="'Select ' + required_field.label"
                    class="mySingleSelect"
                    :disabled="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="['text', 'number'].includes(required_field.type)"
                    v-model="addForm[required_field.key]"
                    :type="required_field.type"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>

                <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                    <div class="common--input-filed">
                        <textarea
                            v-model="addForm[required_field.key]"
                            :id="required_field.key"></textarea>
                    </div>
                </div>

            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.uid + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const ledgerResource = new Resource('api/assetflow/ledgers');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

import {Currencies} from "@/views/assetflow/form_data/Currencies";

export default {
    mixins: [multiSelect],
    name: "AddNewLedger",
    props: ['modal_title', 'item_type', 'ledger_category'],
    components: {DatePicker},
    data(){
        return {
            addForm:{
                category: '',
                // type: '',
                description: '',
                ledger_group_id: '',
                nominal_code: '',
                currency: '',
                // budget: ''
            },
            required_fields:{
                category: {
                    key: 'category',
                    label: 'Category',
                    type: 'single-select',
                    options:[
                        {
                            id: 'bank',
                            name: 'Bank'
                        },
                        {
                            id: 'buyer',
                            name: 'Buyer (P-Card)'
                        },
                        {
                            id: 'sales',
                            name: 'Sales'
                        },
                        {
                            id: 'general',
                            name: 'General'
                        },
                    ]
                },
                // type: {
                //     key: 'type',
                //     label: 'Type',
                //     type: 'single-select',
                //     options:[
                //         {
                //             id: '',
                //             name: 'None'
                //         },
                //         {
                //             id: 'debit',
                //             name: 'Debit'
                //         },
                //         {
                //             id: 'credit',
                //             name: 'Credit'
                //         },
                //     ]
                // },
                ledger_group_id: {
                    key: 'ledger_group_id',
                    label: 'Ledger Group',
                    type: 'single-select',
                    options:[]
                },
                nominal_code: {
                    key: 'nominal_code',
                    label: 'Account', // 'Nominal Code',
                    type: 'text'
                },
                // budget: {
                //     key: 'budget',
                //     label: 'Budget',
                //     type: 'number'
                // },
                description: {
                    key: 'description',
                    label: 'Description',
                    type: 'text'
                },
                currency: {
                    key: 'currency',
                    label: 'Currency',
                    type: 'single-select',
                    options: Currencies
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        if (this.ledger_category === 'buyer'){
            this.setLedgerType('buyer');
        }

    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                ledger_group_id: 'ledger_groups_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {
                this.isLoadingItem = false;
                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setLedgerType(ledger_category){
            if (!ledger_category){
                return;
            }
            this.addForm.category = this.getObjectOf(ledger_category, this.required_fields.category.options);
            this.addForm.currency = Object.assign({}, this.required_fields.currency.options[0]);
            if (['buyer', 'bank'].includes(ledger_category)){
                this.required_fields.category.is_hidden = true;
                this.required_fields.ledger_group_id.is_hidden = true;
            }else if (ledger_category !== '' && ledger_category !== 'ledger'){
                this.required_fields.category.is_readonly = true;
            }

            if (ledger_category !== 'buyer'){
                this.getFieldsList();
            }
        },
        setLedgerGroups(ledger_group_options){
            this.required_fields.ledger_group_id.options = ledger_group_options;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.description = this.selected_item.description;
                this.addForm.nominal_code = this.selected_item.nominal_code;
                // this.addForm.budget = this.selected_item.budget;

               this.addForm.category = this.getObjectOf(this.selected_item.category, this.required_fields.category.options);
               // this.addForm.type = this.getObjectOf(this.selected_item.type, this.required_fields.type.options);
               this.addForm.ledger_group_id = this.getObjectOf(this.selected_item.ledger_group_id, this.required_fields.ledger_group_id.options);
               this.addForm.currency = this.getObjectOf(this.selected_item.currency, this.required_fields.currency.options, 'value');
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                description: this.addForm.description,
                nominal_code: this.addForm.nominal_code,
                budget: this.addForm.budget,
                category: this.addForm.category ? this.addForm.category.id : '',
                type: this.addForm.type ? this.addForm.type.id : '',
                ledger_group_id: this.addForm.ledger_group_id ? this.addForm.ledger_group_id.id : '',
                currency: this.addForm.currency ? this.addForm.currency.value : ''
            };

            if (['buyer', 'bank'].includes(this.ledger_category)){
                params.category = this.ledger_category;
            }

            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await ledgerResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>


