<template>
    <Layout>
        <add-new-po-form ref="add-new-purchaseOrder">
        </add-new-po-form>
    </Layout>
</template>

<script>
import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import AddNewPoForm from "../extras/AddNewPOForm";

const supplierResource = new Resource('api/assetflow/suppliers');
export default {
    name: "NewPO",
    props: ['item_id', 'item_type'],
    components: {AddNewPoForm, Layout},
    data(){
        return {
            listQuery:{},
            current_customer: null,
            current_user_info: null,
            current_sp: null
        }
    },
    created() {
        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
            this.current_sp = this.current_user_info.current_sp;
        }

        this.getSuppliersList();
    },
    methods:{
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getSuppliersList(){
            this.handleQueryItems();
            const {data} = await supplierResource.list(this.listQuery);
            this.suppliersList = data.data.items.map(function(e) { return {id: e.id, name: e.name}; });
            this.$refs['add-new-purchaseOrder'].setSelectedItem(null);
            this.$refs['add-new-purchaseOrder'].setSuppliersList(this.suppliersList);

            this.fetchItemInfo();
        },
        fetchItemInfo(){
            if (this.item_id){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/purchaseOrders/' + this.item_id,
                    baseURL: '/',
                }).then(response => {

                    var item_info = response.data;

                    this.$refs['add-new-purchaseOrder'].setSelectedItem(item_info);

                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        }
    }
}
</script>
