<template>
    <div class="row">
        <table v-if="use_table" class="table table-bordered">
            <tbody>
            <tr
                v-for="(info, index) in block_contents.info_list"
                :class="Array.isArray(block_contents.items_class) && block_contents.items_class.length > index ? block_contents.items_class[index] : block_contents.items_class"
            >
                <td><p>{{ info.label }}</p></td>
                <td>
                    <div class="price_table">
                        <p v-if="info.info_condition() && !info.is_html_value" :class="info.hasOwnProperty('block_class') ? info.block_class : ''" >
                            <i :class="info.icon"></i> <span class="cm_list_inner_common_value" v-b-tooltip:hover.bottom :title="info.hasOwnProperty('tooltip_title') ? info.tooltip_title() : ''">{{ info.info_value() }}</span>
                        </p>
                        <p v-else-if="info.info_condition() && info.is_html_value" :class="info.hasOwnProperty('block_class') ? info.block_class : ''" v-html="info.info_value()"></p>
                        <p v-else>-</p>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div v-else
            v-for="(info, index) in block_contents.info_list"
            class="col-6" :class="Array.isArray(block_contents.items_class) && block_contents.items_class.length > index ? block_contents.items_class[index] : block_contents.items_class">
            <div class="cm_list_inner_common">
                <span class="cm_list_inner_common_label">{{ info.label }}</span>
                <p v-if="info.info_condition() && !info.is_html_value" :class="info.hasOwnProperty('block_class') ? info.block_class : ''" >
                    <i :class="info.icon"></i> <span class="cm_list_inner_common_value" v-b-tooltip:hover.bottom :title="info.hasOwnProperty('tooltip_title') ? info.tooltip_title() : ''">{{ info.info_value() }}</span>
                </p>
                <p v-else-if="info.info_condition() && info.is_html_value" :class="info.hasOwnProperty('block_class') ? info.block_class : ''"  v-html="info.info_value()"></p>
                <p v-else>-</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CustomGenericBlockContent',
    props: {
        block_contents: {},
        use_table:false
    }
}
</script>
