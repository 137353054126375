<template>
    <div class="row">
        <add-new-disposition ref="add-new-disposition-type"
                             modal_id="add-new-disposition-type"
                             modal_title="Create Disposition"
                             item_type="disposition_type"
                             :get-custom-options-for-key="getCustomOptionsForKey"
                             :set-field="setField"
                             :getAssetInfoUsingKey="getAssetInfoUsingKey"
                             @on-new-item-adding="addedDispositionType"
                             @on-hiding-modal="setField('disposition_type')" />


        <div
            v-if="disposal_sections"
            v-for="(section, section_key) in disposal_sections"
            :key="section_key"
            :class="section.used_classes">

            <div
                class="step_main_accordion_border" :class="'section-' + (section_key)">
                <div class="step-small-label" v-if="section.with_title">
                    <p>{{ section.label | titleize }}</p>
                </div>

                <div class="st_accordion" v-if="section.with_title">
                    <div v-if="section_key === 'ownership_status'">
                        <div class="common--input-filed change_liability nit-input mb-2">
                            <span class="liability-change" v-if="item_details.disposition_type.ends_ownership">Ownership for this item will be changed to 'Ended'</span>
                            <span class="liability-no-change" v-else>Ownership for this item won't be changed</span>
                        </div>
                    </div>

                    <div v-else-if="section_key === 'disposition_images'" class="st-drop-file-block">

                        <div class="st-drop-file-box">
                            <vue-dropzone id="imagesgallery"
                                          ref="myVueDropzone"
                                          :use-custom-slot="true"
                                          :include-styling="false"
                                          :options="dropzoneOptions"
                                          @vdropzone-thumbnail="thumbnail"
                                          @vdropzone-removed-file="fileRemoved"
                                          @vdropzone-file-added="fileAdded"
                                          @vdropzone-max-files-exceeded="maxFilesExceeded"
                            >
                                <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                    <div class="st-upload-title">
                                        <h3>Drop files here or click to upload</h3>
                                        <p>... or click to select a file from your computer</p>
                                    </div>
                                </div>
                            </vue-dropzone>
                            <b-button
                                @click="$refs.myVueDropzone[0].$el.click();"
                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                            >Add New Image</b-button>
                        </div>
                    </div>

                    <div v-else-if="section_key !== 'ownership_status'">
                        <div
                            v-for="(disposal_field, key) in section.fields"
                            :key="key"
                            v-if="required_fields[disposal_field].has_permission"
                            class="st--common--box">

                            <div class="common-st-title-label">
                                <h5>{{ (disposal_field !== 'reference_info' ? disposal_field : 'Reference') | titleize }}</h5>
                            </div>

                            <div v-if="assignment_keys.includes(disposal_field)">
                                <small><b>Current {{ disposal_field.replace('_id', '') | titleize }}</b>: {{ getAssetInfoUsingKey(disposal_field, 'single_select_v_models', true, 'name') || 'Not Set' }}</small>
                                <br />
                                <small v-if="item_details[disposal_field]"><b>New {{ disposal_field.replace('_id', '') | titleize }}</b>: {{ item_details[disposal_field].name }}</small>
                            </div>

                            <div v-if="['text', 'number'].includes(required_fields[disposal_field].type)" class="common--input-filed mb-1" :class="disposal_field === 'sale_price' ? 'unit-input' : ''">
                                <input
                                    v-model="item_details[disposal_field]"
                                    aria-describedby="_sale_price_auto_desc"
                                    :id="disposal_field"
                                    :name="disposal_field"
                                    :class="'form-control disposal-field-' + disposal_field"
                                    :type="required_fields[disposal_field].type"
                                />
                                <span v-if="disposal_field === 'sale_price'" class="u-mm">{{other_info.used_currency.icon}}</span>
                            </div>
                            <div v-if="required_fields[disposal_field].type === 'recipient_info'" class="mb-1">

                                <multiselect
                                    v-model="item_details[disposal_field]"
                                    track-by="id_item_type"
                                    :custom-label="recipientDetails"
                                    class="recipient-options"
                                    :options="contacts_list"
                                    :allow-empty="false"
                                    placeholder="Select Recipient"
                                >
                                    <span slot="noResult">No data found.</span>
                                </multiselect>

                            </div>

                            <div v-else-if="['radio-select', 'single-select'].includes(required_fields[disposal_field].type)" class="common--input-filed unit-input mb-1">
                                <div class="st-status-radio-box">

                                    <div class="st-radio-row">
                                        <div v-for="(option, index) in getCustomOptionsForKey(disposal_field)"
                                             :key="index"
                                             class="col-3">

                                            <div class="cm-radio-btn" :class="'mt-1 '+disposal_field+'-' + option.id">
                                                <label>
                                                    <input
                                                        v-model="item_details[disposal_field]"
                                                        :key="index"
                                                        :id="'_'+index"
                                                        :name="disposal_field"
                                                        :value="option"
                                                        :disabled="disposal_field === 'location_id' && !item_details.address_id"
                                                        @input="selectedRadioButton(disposal_field, option, $event)"
                                                        type="radio" />
                                                    <div class="st-radio-box">
                                                        <span></span>
                                                        <p>{{ option.name | titleize }}</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div v-else-if="required_fields[disposal_field].type === 'textarea'" class="common--input-filed">
                                <textarea
                                    v-model="item_details[disposal_field]"
                                    :id="disposal_field"
                                    :name="disposal_field"
                                    :class="'disposal-field-' + disposal_field"
                                    spellcheck="false"></textarea>
                            </div>
                            <div v-else-if="required_fields[disposal_field].type === 'date'" class="common--input-filed">
                                <date-picker
                                    v-model="item_details[disposal_field]"
                                    lang="en" :first-day-of-week="1"
                                    format="DD-MM-YYYY"
                                    value-type="DD-MM-YYYY"
                                    :placeholder="disposal_field | titleize" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
        <div class="col-12">

            <div class="text-right">
                <AFButton
                    tag_name="button"
                    tag_type="submit"
                    :key_prefix="'save-disposition-fields' + '-' + buttonkey"
                    :is_enabled="is_saving_enabled && !isRegisteringDisposition"
                    :required_action="saveClicked"
                    required_text="Save"
                    item_classes="btn btn-success"
                />

                <b-button variant="outline-danger" @click="cancelClicked()">Cancel</b-button>
            </div>
        </div>

    </div>
</template>
<script>
import multiSelect from '@/mixins/MultiSelect';
import DatePicker from "vue2-datepicker";
import Resource from '@/api/resource';
const contactResource = new Resource('api/assetflow/contacts');

import AddNewDisposition from "../../customers/extras/AddNewDispositionType";
import AssetInfoSection from "./AssetInfoSection";
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AssetLocation from "@/mixins/AssetLocation";
import AFButton from "@/components/AFButton";

export default {
    name: 'RegisterDispositionFields',
    components: {AFButton, AssetInfoSection, AddNewDisposition, DatePicker},
    mixins: [multiSelect, dropzoneMixin, AssetLocation],
    props: {
        received_modal_id: {},
        required_fields: {},
        other_info: {},
        item_type: {},
        getAssetInfoUsingKey: {},
        shall_load_lists: false,
        isRegisteringDisposition: false,
    },
    data(){
        return {
            disposal_sections: {
                disposition_type: {
                    key: 'disposition_type',
                    with_title: true,
                    label: 'Disposition Type',
                    used_classes: 'col-12',
                    fields: ['disposition_type']
                },
                ownership_status: {
                    key: 'ownership_status',
                    with_title: false,
                    label: 'Ownership Status',
                    used_classes: 'col-12',
                    fields: []
                },
                assignments: {
                    key: 'assignments',
                    with_title: false,
                    label: 'Assignments',
                    used_classes: 'col-12',
                    fields: []
                },
                details: {
                    key: 'details',
                    with_title: true,
                    label: 'Details',
                    used_classes: 'col-6',
                    fields: [ 'disposition_details' ]
                },
                disposition_images: {
                    key: 'disposition_images',
                    with_title: true,
                    used_classes: 'col-6',
                    label: 'Images',
                },
            },
            item_details: {},
            contacts_list: [],
            listQuery: {},
            addForm:{
                filled_asset_images: []
            },
            assignment_keys: ['contact_id', 'department_id', 'address_id', 'location_id']
        }
    },
    computed:{
        disposition_type(){
            return this.item_details.disposition_type;
        }
    },
    watch:{
        disposition_type:{
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue && (!oldVal || oldVal.id !== newValue.id)){
                    this.handleDispositionsChanging();
                }
            }
        },
    },
    created() {
        if (this.shall_load_lists === true){
            this.getFieldsList();
        }
        this.disposal_sections.assignments.fields = this.assignment_keys;
        this.handleDispositionsChanging();
        this.getContactsList();

    },
    methods:{
        getCustomOptionsForKey(disposal_field){
            if (this.assignment_keys.includes(disposal_field)){
                // shall ??
                let options_list = [...this.required_fields[disposal_field].options];
                if (disposal_field !== 'contact_id'){
                    options_list.splice(0, 1);
                }

                let first_options = [
                    {
                        id: -98,
                        name: 'No Change'
                    },
                    {
                        id: -97,
                        name: 'Reset'
                    }
                ];

                return first_options.concat(options_list);
            }else{
                return this.required_fields[disposal_field].options;
            }
        },
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getContactsList(){

            this.handleQueryItems();
            const {data} = await contactResource.list(this.listQuery);
            this.contacts_list = data.data.items;

        },
        getValueFrom(property_key){
            return this.item_details.hasOwnProperty(property_key) ? this.item_details[property_key] : null;
        },
        saveClicked(){
            if (this.item_details.hasOwnProperty('disposition_type') && this.item_details.disposition_type){

                let all_disposition_images = this.checkAndGetFiles(this.addForm.filled_asset_images);
                let contact_id = this.getValueFrom('contact_id'),
                    department_id = this.getValueFrom('department_id'),
                    address_id = this.getValueFrom('address_id'),
                    location_id = this.getValueFrom('location_id');


                let disposition_info = {
                    disposition_date: this.getValueFrom('disposition_date'),
                    disposition_details: this.getValueFrom('disposition_details'),
                    disposition_type: this.item_details.disposition_type.id,
                    movement_info: this.getValueFrom('movement_info'),
                    reference_info: this.getValueFrom('reference_info'),
                    sale_price: this.getValueFrom('sale_price'),
                    currency: this.other_info.used_currency.value || 'gbp',
                    disposition_images: all_disposition_images,
                    contact_id: contact_id ? contact_id.id : null,
                    department_id: department_id ? department_id.id : null,
                    address_id: address_id ? address_id.id : null,
                    location_id: location_id ? location_id.id : null,
                }
                let recipient_info = this.getValueFrom('recipient_info');
                if (recipient_info){
                    disposition_info.recipient_type = recipient_info.item_type;
                    disposition_info.recipient_id = recipient_info.id;
                }
                this.$emit('on-registering-disposition', disposition_info);
            }else{
                this.presentToast("Error!",
                    'Please Select a Proper Disposition Type',
                    'danger',
                    3000,
                    true,
                    true);
                this.$emit('on-registering-disposition');
            }
        },
        handleDispositionsChanging(){
            let disposal_fields = [];
            let with_ownership_status = false;
            let with_assignments = false;
            if (this.item_details.hasOwnProperty('disposition_type') && this.item_details.disposition_type && this.item_details.disposition_type.hasOwnProperty('ends_ownership')){
                with_ownership_status = true;
                with_assignments = !this.item_details.disposition_type.ends_ownership;
                let disposition_type = this.item_details.disposition_type;
                let flags_info = {
                    has_date: 'disposition_date',
                    has_reference: 'reference_info',
                    has_amount: 'sale_price',
                    has_recipient: 'recipient_info',
                    has_movement: 'movement_info',
                };

                for (const flagsInfoKey in flags_info) {
                    let field_key = flags_info[flagsInfoKey];
                    if (disposition_type[flagsInfoKey]) {
                        disposal_fields.push(field_key);
                    }
                }

                for (const assignmentKeysKey in this.assignment_keys) {
                    let assignment_key = this.assignment_keys[assignmentKeysKey];

                    let assignment_key_options = this.getCustomOptionsForKey(assignment_key);
                    if (this.item_details.disposition_type.hasOwnProperty(assignment_key) && this.item_details.disposition_type[assignment_key]){
                        let assignment_id = this.item_details.disposition_type[assignment_key];
                        let index = assignment_key_options.map(function(e) { return e.id; }).indexOf(assignment_id);
                        if (index > -1){
                            this.item_details[assignment_key] = assignment_key_options[index];
                        }

                    }else if (assignment_key_options.length > 0){
                        this.item_details[assignment_key] = assignment_key_options[0];
                    }
                }
            }
            disposal_fields.push('disposition_details');

            this.disposal_sections.ownership_status.with_title = with_ownership_status;
            this.disposal_sections.assignments.with_title = with_assignments;
            this.disposal_sections.details.fields = disposal_fields;

        },

        selectedRadioButton(field, option, event){
            if (option.id === -99){
                if (field === 'disposition_type'){
                    this.$refs['add-new-disposition-type'].setSelectedItem(null);
                    this.item_details.disposition_type = null;
                    this.$bvModal.show('add-new-disposition-type');
                }
            }
            if (field === 'address_id'){
                this.setSelectedLocationFor(option, true, 'item_details');
            }

            this.setField(field, option);
        },
        addedDispositionType(new_item, field) {
            if (new_item) {
                let options_count = this.required_fields.disposition_type.options.length;
                this.required_fields.disposition_type.options.splice(options_count - 1,0, new_item);

                // TODO Shall add new disposition to FDPs List ??
                // let fdp_count = this.required_fields.final_disposition_planning.options.length;
                // this.required_fields.final_disposition_planning.options.splice(fdp_count - 1,0, new_item);
                this.setField(field, new_item);
            }
        },
        setField(field, value=null){
            this.item_details[field] = value;
            this.item_details = Object.assign({}, this.item_details);
        },
        getFieldsList(){
            let required_select_lists = {
                disposition_type: 'dispositions_list',
                disposition_category: 'disposition_categories_list',
                address_id: 'addresses_list',
                location_id: 'locations_list',
                department_id: 'departments_list',
                contact_id: 'contacts_list',
            }

            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'disposition_modal',
                    object_type: 'assets',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                let form_lists = response.data.data;
                if (form_lists){
                    for (const list_key in required_select_lists) {
                        let select_name = required_select_lists[list_key];
                        this.required_fields[list_key].options = form_lists[select_name];
                    }
                }

            }).catch(error => {
                console.log(error);
            });
        }
    }
}
</script>
