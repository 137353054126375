const FirstPath = window.location.pathname.split('/')[1];
export const SpShortKeysList = {
    initials: {
        key: 'initials',
        label: "",
        class: "col-lg-2 initials",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let style = '';
            if (item.hasOwnProperty('meta') && item.meta && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                let bg_colour = item.meta.accent_colour;
                let text_colour = item.meta.primary_colour;
                style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
            }
            return '<a href="/' + FirstPath + '/service_providers/'+item.id+'/view" class="text-dark">' + '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item[key]+'</span></div>' + '</a>'; //
        }
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg-2 prod_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a href="/' + FirstPath + '/service_providers/'+item.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item[key]) + '</a>';
        }
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-2 prod_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<span class="active_button button_size data_status'+(item.status === 1 ? 'active_status' : 'inactive_status')+'">'+(item.status === 1 ? 'Active' : 'Inactive')+'</span>';
        }
    }
};
