<template>
    <div class="dropdown-menu dropdown-menu-right">

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.location_status === 0"
            @click="showModal(item, 'assign-sp_storages-location')">
            Park Storage
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && [0, 1].includes(item.location_status)"
            @click="showModal(item, 'change-sp_storages-utilisation')">
            Usage
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.location_status === 1"
            @click="showModal(item, 'assign-sp_storages-location')">
            Relocate
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.location_status === 1"
            @click="showModal(item, 'remove-sp_storages-location')">
            Make Homeless
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status !== 3"
            @click="goToRelocateWizard(item.id, $event)">
            Location Wizard
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name]"
            @click="showModal(item, 'relocate-sp_storages-items')">
            Relocate All Items
        </b-dropdown-item>


        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.current_customer_id === null"
            @click="showModal(item, 'assign-sp_storages-customer')">
            Assign
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.current_customer_id !== null && item.hasOwnProperty('assets_count') && item.assets_count === 0"
            @click="showModal(item, 'remove-sp_storages-customer')">
            Unassign
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status === 0"
            @click="showModal(item, 'reserve-sp_storages')">
            Reserve
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status === 2"
            @click="showModal(item, 'reserve-sp_storages')">
            Unreserve
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status === 0"
            @click="showModal(item, 'archive-sp_storages')">
            Archive
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['edit_' + permission_name] && item.status === 3"
            @click="showModal(item, 'archive-sp_storages')">
            Unarchive
        </b-dropdown-item>

        <b-dropdown-item
            v-if="permissions_list['delete_' + permission_name]"
            variant="danger"
            @click="performAction(item, ('delete' + permission_name), index)">
            Delete
        </b-dropdown-item>
        <ExportAdditionalActions :item="item" object_single_name="sp_storage" required_permission_name="browse_files"/>
    </div>
</template>
<script>
import ExportAdditionalActions from "../orders/ExportAdditionalActions";
export default {
    name: 'SpStorageActions',
    components: {ExportAdditionalActions},
    props: {
        goToRelocateWizard: {},
        index: {},
        item: {},
        performAction: {},
        permission_name: {},
        permissions_list: {},
        showModal: {}
    }
}
</script>
