<template>

    <b-modal :id="modal_id ? modal_id : 'split-asset-modal'"
             ref="split-asset-modal"
             :title="'Split Asset {' + (selected_asset ? selected_asset.name : '') + '}'"
             title-class="font-18"
             @hide="$emit('on-modal-cancel')"
             hide-footer>

        <div v-if="selected_asset">
            <p class="m-1">Current Quantity: <b class="badge-soft-primary p-1">{{ selected_asset.quantity }}</b></p>
            <p class="m-1">Remaining: <b :class="'p-1 badge-' + (remaining_quantity === 0 ? 'danger' : (parseInt(remaining_quantity) < 0 ? 'danger' : 'success'))">{{ remaining_quantity }}</b></p>

            <div class="m-1">
                <div>
                    <div class="mb-3 col-lg-4">
                        <label for="asset_quantity">Quantity</label>
                        <input
                            id="asset_quantity"
                            v-model="asset_quantity"
                            type="text"
                            name="untyped-input"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>

        </div>

        <div class="text-right">
            <span class="invalid-quantity-block" v-if="!can_save">Invalid Quantities</span>
            <b-button type="submit"
                      :disabled="!can_save"
                      :variant="can_save ? 'success' : 'danger'"
                      v-on:click.once="can_save ? splitAsset(with_refresh ? with_refresh : false) : null ">
                Split Asset { {{selected_asset ? selected_asset.name : ''}} }</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(modal_id ? modal_id : 'split-asset-modal')">Cancel</b-button>
        </div>
    </b-modal>

</template>

<script>
import AssetActions from "@/mixins/assetActions.mixin";
export default {
    mixins: [AssetActions],
    name: "SplitAssetsModal",
    props:['item_type', 'selected_asset', 'with_refresh', 'modal_id'],
    data(){
        return {
            asset_quantity: null,
        }
    },
    mounted() {

    },
    computed:{
        can_save(){
            return this.remaining_quantity > 0 && this.asset_quantity > 0;
        },
        remaining_quantity(){
            if (this.selected_asset && this.asset_quantity > 0){
                return this.selected_asset.quantity - this.asset_quantity;
            }
            return 0;
        },
    },
    methods: {
        splitAsset(with_refresh = false){
            if (!this.selected_asset){
                return;
            }
            axios({
                method:'post',
                url:'/api/assetflow/assets/split_asset',
                baseURL: '/',
                data: {
                    asset_id: this.selected_asset.id,
                    new_asset_quantity: this.asset_quantity
                },
            }).then(response => {
                let success_message = "Split Asset {"+this.selected_asset.name+"} Successfully";
                this.handleResponse(response, success_message);
                if (with_refresh){
                    location.reload();
                }else{
                    this.$emit('after-split-asset');
                }

            }).catch(error => {
                console.log(error);
            });

            this.asset_quantity = null;
            this.$bvModal.hide(this.modal_id ? this.modal_id : 'split-asset-modal');

        },

    }

}
</script>


