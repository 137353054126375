import {TaskKeysList} from "@/views/assetflow/form_data/TaskKeysList";
import {TaskKeysListShort} from "@/views/assetflow/form_data/TaskKeysListShort";
export default {
    data(){
        return {
            selected_action_item: null,
            task_list_keys_list: TaskKeysList,
            task_list_keys_list_short: TaskKeysListShort,
            task_status_list: [
                {
                    name: 'Pending',
                    class: 'status-btn-disposed',
                },
                {
                    name: 'Completed',
                    class: 'status-btn-active',
                }
            ],
        }
    },
    created: function () {

    },
    methods: {
        completeTask(task, modal_id, keys_list, asset_info=null){
            if (asset_info){
                this.$refs['generic-tasks-form'].setSelectedAssetInfo(asset_info);
            }
            this.$refs['generic-tasks-form'].setSelectedTask(task, keys_list);
            this.$bvModal.show(modal_id);
        },
        taskCompleted(task){
            console.log("task");
            console.log(task);
            if (this.selected_action_item){
                this.selected_action_item = null;
            }
            location.reload();

        },
        itemTaskCompleted(task, item, required_keys=null){
            if (this.selected_action_item){
                this.selected_action_item = null;
            }
            if (required_keys === 'status, asset_usage, is_hero, is_asset'){
                // shall update that?
                this.refreshAssetItem(item, 'status', item.status);
                this.refreshAssetItem(item, 'asset_usage', item.asset_usage);
                this.refreshAssetItem(item, 'is_hero', item.is_hero);
                this.refreshAssetItem(item, 'is_asset', item.is_asset);
                return;
            }

            let index = _.findIndex(this.items_list, {id: item.id});
            if (index > -1 && required_keys && required_keys !== '') {
                required_keys = required_keys.split(",");
                for (const requiredKeysKey in required_keys) {
                    let required_key = (required_keys[requiredKeysKey]+'').trim();
                    this.items_list[index][required_key] = item[required_key];
                    if (required_key === 'character_id' && item.hasOwnProperty('character_info')){
                        this.$set(this.items_list[index], 'character_info', item.character_info);
                    }else if (required_key === 'ledger_id' && item.hasOwnProperty('ledger_info')){
                        this.$set(this.items_list[index], 'ledger_info', item.ledger_info);
                    }
                }
            }

        },
        postAdded(post){
            if (this.item_info.asset_posts.length > 0){
                this.item_info.asset_posts.splice(0, 0, post);
            }else{
                this.item_info.asset_posts = [post];
            }

        },

    }
}
