<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? getTitleFor(selected_item, 'changes') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>


        <ChangeCommonFields
            :add-form="addForm"
            :required_fields="required_fields"
            :filter-changes-of-character="filterChangesOfCharacter"
        />

        <div class="text-right">
            <b-button type="submit" variant="success" :disabled="isSavingItem" v-on:click.once="addItem($event)">
                <span v-show="!isSavingItem"> Update  </span>
                <div v-show="isSavingItem" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Saving...</span>
                </div>
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFields from "./ChangeCommonFields";

const changeResource = new Resource('api/assetflow/changes/editChange');
import ChangeFormsMixin from "@/mixins/ChangeFormsMixin";


export default {
    name: "EditChangeSetup",
    props: ['modal_title', 'item_type', 'with_item_details'],
    mixins: [ChangeFormsMixin],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                character_id: null,
                name: '',
                change_order: null,
                original_target_change: null,
            },
            required_fields:{
                character_id: {
                    key: 'character_id',
                    label: 'Character',
                    type: 'single-select',
                    options:[]
                },
                name: {
                    key: 'name',
                    label: 'Change Name',
                    type: 'text'
                },
                change_order: {
                    key: 'change_order',
                    label: 'Change Order',
                    type: 'single-select',
                    options:[
                        {id: -1, name: 'Add Before'},
                        {id: 0, name: 'Add New'},
                        {id: 1, name: 'Add After'}
                    ]
                },
                original_target_change: {
                    key: 'original_target_change',
                    label: 'Change',
                    type: 'single-select',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                character_id: 'character_short_list',
                original_target_change: 'changes_short_list'
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

                this.fillSelectedItem();
                if (this.addForm.character_id){
                    this.filterChangesOfCharacter(this.addForm.character_id);
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        filterChangesOfCharacter: function (option) {
            let character_id = option.id;
            let last_change_name = '';
            if (option.hasOwnProperty('last_change_name') && option.last_change_name) {
                last_change_name = option.last_change_name;
            }

            if (this.addForm.name === ''){
                this.$set(this.addForm, 'name', last_change_name);
            }

            this.required_fields.original_target_change.options = this.lists_values.original_target_change.filter((item) => {
                return character_id === item.character_id;
            });
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('character_id', this.selected_item.character_id);
                this.addForm.name = this.selected_item.name;
                this.setValueFromOptions('change_order', this.selected_item.original_change_order);
                this.setValueFromOptions('original_target_change', this.selected_item.original_target_change);
            }
        },
        resetFormValues(){
            this.addForm = {
                character_id: null,
                name: '',
                change_order: this.required_fields.change_order.options[1],
                original_target_change: null,
            };
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;

                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.resetFormValues();
            }
        },

        async addItem(event){
            this.isSavingItem = true;
            var params = {
                character_id: this.addForm.character_id ? this.addForm.character_id.id : null,
                change_order: this.addForm.change_order ? this.addForm.change_order.id : null,
                original_target_change: this.addForm.original_target_change ? this.addForm.original_target_change.id : null,
                name: this.addForm.name,
                with_item_details: this.with_item_details,
                edit_type : 'change_setup'
            };
            if (this.selected_item && this.selected_item.id !== -99){
                params.item_id = this.selected_item.id;
            }

            const { data } = await changeResource.store(params);


            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = (this.selected_item && this.selected_item.id !== -99 ? 'Edited' : 'Added') + ' Successfully';
                status_class = 'success';
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

            this.isSavingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();


            this.$emit('on-new-item-adding', new_item, this.item_type);
        }
    }
}
</script>
