export default {
    data(){
        return {
            unique_id: null,
            selected_asset_id: null,
            update_asset_costs_id: 'update-costs-list',
            update_asset_details_id: 'update-details-list',
            update_asset_images_id: 'update-images-list',
            selectedLayout: 1,
            supportedLayouts:{
                list: {
                    name: 'List',
                    value: 0,
                    class: 'list',
                    isActive: false
                },
                grid: {
                    name: 'Grid',
                    value: 1,
                    class: 'grid',
                    isActive: true
                }
            },
        }
    },
    created: function () {

        this.update_asset_costs_id = this.update_asset_costs_id + this.object_type;
        this.update_asset_details_id = this.update_asset_details_id + this.object_type;
        this.update_asset_images_id = this.update_asset_images_id + this.object_type;


        if (window.USER_INFO.user_meta.hasOwnProperty('preferred_'+this.object_type + '_layout')) {
            this.selectedLayout = window.USER_INFO.user_meta['preferred_'+this.object_type + '_layout'];
        }
    },
    methods: {
        reloadCloset(asset_info){
            let message = asset_info ? ('Saved Asset Successfully') : '';
            window.flash(message);
            location.reload();
        },
        addAsset(event) {
            this.$refs[this.add_new_asset_id].setSelectedItem(null, null);
            this.$bvModal.show('create-asset' + this.unique_id);
            console.log("should add?");
        },
        addExistingAsset(event) {
            console.log("'addExistingAsset'");
            console.log('addExistingAsset');
        },
        refreshAsset(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }

            let index = _.findIndex(this[this.item_info_name][this.list_name], {id: edited_asset.id});

            if (index > -1) {
                if (required_keys){
                    for (const requiredKeysKey in required_keys) {
                        let required_key = required_keys[requiredKeysKey];
                        if (['asset_images', 'colours_info', 'asset_meta'].includes(required_key)){
                            this.$set(this[this.item_info_name][this.list_name][index], required_key, edited_asset[required_key]);
                        }else{
                            this[this.item_info_name][this.list_name][index][required_key] = edited_asset[required_key];
                        }

                    }
                }else{
                    this[this.item_info_name][this.list_name][index] = edited_asset;
                }
            }
        },
        changeObjectLayout(layout) {
            let message = ''; //"Saved Layout {" + layout.name + "} Successfully";
            let object_type = this.object_type;
            this.set_usermeta('preferred_' + object_type + '_layout', layout.value, message);
            this.selectedLayout = layout.value
        },

        completeTask(task, modal_id, keys_list, asset_info=null){
            if (this.$refs['generic-tasks-form']){
                if (asset_info){
                    this.$refs['generic-tasks-form'].setSelectedAssetInfo(asset_info);
                }
                this.$refs['generic-tasks-form'].setSelectedTask(task, keys_list);
                this.$bvModal.show(modal_id);
            }
        },
        itemTaskCompleted(task, item, required_keys=null){
            if (this.selected_asset_id){
                this.selected_asset_id = null;
            }
            let index = _.findIndex(this[this.item_info_name][this.list_name], {id: item.id});
            this[this.item_info_name][this.list_name][index] = item;
        },
        performAction(item, action_name, event) {
            this.selected_asset_id = item.id;

            if (action_name === 'show_usage_action'){
                this.completeTask(null, ('icons-action-tasks' + this.unique_id), 'asset_usage, is_hero, is_asset', item);
            }else if (this.$refs['shared-modals-block']){
                switch (action_name){
                    case 'updateCosts':
                        this.$refs['shared-modals-block'].$refs[this.update_asset_costs_id].set_item(item);
                        this.$bvModal.show(this.update_asset_costs_id);
                        break;
                    case 'updateDetails':
                        this.$refs['shared-modals-block'].$refs[this.update_asset_details_id].set_item(item);
                        this.$bvModal.show(this.update_asset_details_id);
                        break;
                    case 'updateImages':
                        this.$refs['shared-modals-block'].$refs[this.update_asset_images_id].set_item(item);
                        this.$bvModal.show(this.update_asset_images_id);
                        break;
                }
            }

        },

    }
}
