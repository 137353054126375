<template>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title="block_contents.properties.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :block_contents="block_contents.properties"/>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    v-if="disposition_type_info && disposition_type_info.ends_ownership"
                    :block_title="block_contents.ownership.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <p>Ownership for these items was Ended</p>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    v-if="disposition_type_info && disposition_type_info.has_recipient"
                    :block_title="block_contents.recipient.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <a v-if="item_info.hasOwnProperty('recipient_type') && item_info.recipient_type !== ''" :href="'/' + item_info.current_page_type + '/' + item_info.recipient_type + '/' + item_info.recipient_id + '/view'">{{ item_info.recipient_name }}</a>
                        <span v-else>-</span>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    v-if="disposition_type_info && disposition_type_info.has_movement"
                    :block_title="block_contents.movement.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        {{ item_info.movement_info }}
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    :block_title="block_contents.assignment.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :block_contents="block_contents.assignment"/>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    v-if="disposition_type_info.ends_ownership"
                    :block_title="block_contents.asset_passport.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <p>Updates Asset Passport Status</p>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <div class="row" v-if="item_info">
                <NewCustomGenericBlock
                    block_title=""
                    :block_title_html="getHtmlTitleFor('dispositions')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <DispositionRightBox :item_info="item_info"/>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

    </div>
</template>
<script>
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import PoLines from "./widgets/detailsViews/PoLines";
import MyPostContent from "./widgets/detailsViews/PostContentTypes/MyPostContent";
import DispositionRightBox from "./DispositionRightBox";
import CustomGenericBlockContent from "./widgets/detailsViews/AssetTabs/CustomGenericBlockContent";

export default {
    name: 'DispositionDetailsTab',
    components: {
        CustomGenericBlockContent,
        DispositionRightBox,
        MyPostContent,
        PoLines,
        NewCustomGenericBlock
    },
    mixins:[TimelineCommons],
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            disposition_type_info: null,
            block_contents: {
                properties:{
                    label: 'Properties',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-3',
                    info_list: []
                },
                ownership:{
                    label: 'Ownership',
                    has_action: false,
                    action_required: function () {},
                    info_list: []
                },
                recipient:{
                    label: 'Recipient',
                    has_action: false,
                    action_required: function () {},
                    info_list: []
                },
                movement:{
                    label: 'Movement',
                    has_action: false,
                    items_class: 'col-md-4',
                    action_required: function () {},
                    info_list: []
                },
                assignment:{
                    label: 'New Assignments',
                    has_action: false,
                    items_class: 'col-md-3',
                    action_required: function () {},
                    info_list: []
                },
                asset_passport:{
                    label: 'Asset Passports',
                    has_action: false,
                    items_class: 'col-md-4',
                    action_required: function () {},
                    info_list: []
                },
            }
        }
    },
    computed:{
        item_info_asset_posts(){
            return this.item_info && this.item_info.hasOwnProperty('asset_posts') ? this.item_info.asset_posts : null;
        },
    },
    created() {

        let item_info = this.item_info;
        let disposition_type_info = item_info.hasOwnProperty('disposition_type_info') && item_info.disposition_type_info ? item_info.disposition_type_info : null;
        this.disposition_type_info = disposition_type_info;
        if (disposition_type_info){
            this.block_contents.properties.info_list = [
                {
                    label: 'Ownership Changed',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return disposition_type_info.ends_ownership ? 'Yes' : 'No';
                    }
                },
                {
                    label: 'Movement Required',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return disposition_type_info.has_movement ? 'Yes' : 'No';
                    }
                },
                {
                    label: 'Assignments Updated',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return !disposition_type_info.ends_ownership ? 'Yes' : 'No';
                    }
                },
                {
                    label: 'Passport Updated',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return '-';
                    }
                },
            ];

            this.block_contents.assignment.info_list = [
                    {
                        label: 'User',
                        icon: '',
                        is_html_value: true,
                        info_condition: function () {
                            return true;
                        },
                        info_value : function () {
                            if (disposition_type_info.ends_ownership){
                                return 'Cleared';
                            }else if (item_info.hasOwnProperty('contact_name')){
                                    return '<a href="/' + item_info.current_page_type + '/users/' + item_info.contact_id + '/view">' + item_info.contact_name + '</a>';
                            }
                            return '-';
                        }
                    },
                    {
                        label: 'Department',
                        icon: '',
                        is_html_value: true,
                        info_condition: function () {
                            return true;
                        },
                        info_value : function () {
                            if (disposition_type_info.ends_ownership){
                                return 'Cleared';
                            }else if (item_info.hasOwnProperty('department_name')){
                                return '<a href="/' + item_info.current_page_type + '/departments/' + item_info.department_id + '/view">' + item_info.department_name + '</a>';
                            }
                            return '-';
                        }
                    },
                    {
                        label: 'Address',
                        icon: '',
                        is_html_value: true,
                        info_condition: function () {
                            return true;
                        },
                        info_value : function () {
                            if (disposition_type_info.ends_ownership){
                                return 'Cleared';
                            }else if (item_info.hasOwnProperty('address_name')){
                                return '<a href="/' + item_info.current_page_type + '/addresses/' + item_info.address_id + '/view">' + item_info.address_name + '</a>';
                            }
                            return '-';
                        }
                    },
                    {
                        label: 'Location',
                        icon: '',
                        is_html_value: true,
                        info_condition: function () {
                            return true;
                        },
                        info_value : function () {
                            if (disposition_type_info.ends_ownership){
                                return 'Cleared';
                            }else if (item_info.hasOwnProperty('location_name') && item_info.hasOwnProperty('address_id')){
                                return '<a href="/' + item_info.current_page_type + '/addresses/' + item_info.address_id + '/view">' + item_info.location_name + '</a>';
                            }
                            return '-';
                        }
                    }
                ];
        }

    },
    methods:{
    }
}
</script>
