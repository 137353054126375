function getWarehouseSpaceInfoBlockTextOnly(item, prefix='') {
    let warehouse_name = '';
    let space_name = '';
    if (item.hasOwnProperty(prefix+'warehouse_info') && item[prefix+'warehouse_info']) {
        let warehouse_info = item[prefix+'warehouse_info'];
        warehouse_name = warehouse_info.initials;
    }
    if (item.hasOwnProperty(prefix+'space_info') && item[prefix+'space_info']) {
        let space_info = item[prefix+'space_info'];
        if (warehouse_name !== ''){
            warehouse_name += ':';
        }
        space_name = space_info.initials;
    }
    return warehouse_name + space_name;
}


export function getLocationDetailsTextOnly(location_info, other_info= null) {
    let text = '';

    let location_type_info;
    if (location_info.hasOwnProperty('location_type_info') && location_info.location_type_info){
        location_type_info = location_info.location_type_info;
    }else{
        location_type_info = location_info;
    }

    if (location_info.hasOwnProperty('full_location_name') && location_info.full_location_name !== ''){
        return location_info.full_location_name;
    }

    if (!other_info){
        other_info = location_info;
    }
    text += getWarehouseSpaceInfoBlockTextOnly(other_info, '') + ' ';
    if (location_type_info && location_type_info.hasOwnProperty('location_type')) {
        if (location_type_info.location_type !== null) {
            let loop_count = location_type_info.location_type + 1;
            for (let i = 0; i < loop_count; i++) {
                text += location_info['label_' + (i + 1) + 'd_text'] + (i !== loop_count-1 ? '-' : '');
            }
        }
    } else {
        text += location_info.location_number;
    }

    return text;
}
