<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_event ? 'Add Task to ' + selected_event.name + ' {' + asset_info.name + '}' : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>
                <span v-if="has_error">This field is required.</span>

            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-task')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const taskResource = new Resource('api/assetflow/tasks');
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewTask",
    props: ['modal_title', 'item_type', 'tasks_options', 'shall_assign_to', 'shall_assign_to_id'],
    data(){
        return {
            addForm:{
                task: ''
            },
            required_items:{
                task: {
                    key: 'task',
                    label: 'Task',
                    type: 'single-select',
                    options: []
                },
            },
            selected_item: null,
            asset_info: null,
            selected_event: null,
            has_error: false,
        }
    },
    created() {
        this.has_error = false;

    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.task = this.selected_item.task;
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        setSelectedEvent(event, asset_info=''){
            this.selected_event = event;
            this.asset_info = asset_info;
            let event_tasks = this.tasks_options.filter((task) => {
                return !event.event_tasks.map(x => x.id).includes(task.id);
            });
            if (this.tasks_options){
                this.required_items.task.options = event_tasks;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            if (!this.addForm.task){
                this.has_error = true;
                return;
            }

            var params = {
                task_id: this.addForm.task.id,
                event_id: this.selected_event.id,
                asset_id: this.asset_info.id,
            };

            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await taskResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-task');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.selected_event.id);
        }
    }
}
</script>


