export const AssetTypes = [
    {
        id: -99,
        name: "Asset Templates",
        icon: 'bx bx-circle',
        fetch_meta_key: 'asset_template',
        subfolders: [
            {
                id: 'loading',
                name: 'loading..',
                icon: ''
            },
        ]
    },
    {
        id: -99,
        name: "Custom Types",
        icon: 'fa fa-star',
        fetch_meta_key: 'custom_types',
        subfolders: [
            {
                id: 'loading',
                name: 'loading..',
                icon: ''
                // meta_key: 'custom_fields'
            },
        ]
    }
];
