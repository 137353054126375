<template>
    <div>
        <div class="mt-1">
            <h4>{{ is_asset_passport ? tabs.basic_info_tab.name : 'Asset Passport' }}</h4>
        </div>
        <!-- Intelligence Template -->
        <div class="intelligence-template">
            <div class="tab_menu_item">
                <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                    <li v-for="tab in tabs" class="nav-item">
                        <a class="nav-link"
                           @click="tabClicked(tab.id)"
                           :class="isActiveTab(tab.id)"
                           data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content tab_content_design">
                <div class="tab-pane" id="basic_info_tab" role="tabpanel" :class="isActiveTab('basic_info_tab')">
                    <div class="row overview-block" v-if="passport_item">
                        <div class="col-12 col-lg-8">
                            <div class="row">
                                <NewCustomGenericBlock
                                    :block_title="block_contents.identification.label"
                                    columns_count_withclasses="12">
                                    <template v-slot:all_content>
                                        <CustomGenericBlockContent :block_contents="block_contents.identification"/>
                                    </template>
                                </NewCustomGenericBlock>

                                <NewCustomGenericBlock
                                    block_title="Notes"
                                    box_colour_v="common_col_desc"
                                    columns_count_withclasses="12">
                                    <template v-slot:all_content>
                                        <p v-if="passport_item && passport_item.notes && passport_item.notes !== ''">
                                            {{ passport_item.notes }}
                                        </p>
                                        <p v-else class="no-passport-notes">
                                            No notes entered.
                                        </p>
                                    </template>
                                </NewCustomGenericBlock>


                                <NewCustomGenericBlock
                                    v-if="passport_item.hasOwnProperty('asset_template') && passport_item.asset_template && passport_item.asset_template.hasOwnProperty('asset_template_fields') && passport_item.asset_template.asset_template_fields.length > 0"
                                    :block_title="passport_item.asset_template.name"
                                    columns_count_withclasses="12">
                                    <template v-slot:all_content>
                                        <div class="row">
                                            <div
                                                v-for="(asset_template_field, key) in passport_item.asset_template.asset_template_fields"
                                                class="col-6" :class="customFieldsClass()">
                                                <div class="cm_list_inner_common">
                                                    <span class="cm_list_inner_common_label">{{ asset_template_field.name | titleize }}</span>
                                                    <p>
                                                        <span class="cm_list_inner_common_value">{{ getMetaValue(asset_template_field.name)}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </NewCustomGenericBlock>



                                <NewCustomGenericBlock
                                    v-if="passport_item.hasOwnProperty('ws_defined_status') && passport_item.ws_defined_status !== 0"
                                    :block_title="block_contents.waste_stream.label"
                                    columns_count_withclasses="12">
                                    <template v-slot:all_content>
                                        <CustomGenericBlockContent :block_contents="block_contents.waste_stream"/>
                                    </template>
                                </NewCustomGenericBlock>

                                <NewCustomGenericBlock
                                    v-if="passport_item.hasOwnProperty('ph_defined_status') && passport_item.ph_defined_status !== 0"
                                    :block_title="block_contents.physical_properties.label"
                                    columns_count_withclasses="12">
                                    <template v-slot:all_content>
                                        <CustomGenericBlockContent :block_contents="block_contents.physical_properties"/>
                                    </template>
                                </NewCustomGenericBlock>

                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="row">

                                <NewCustomGenericBlock
                                    :block_title_html="getHtmlTitleFor('asset_passport')"
                                    box_colour_v="-"
                                    columns_count_withclasses="12">
                                    <template v-slot:all_content>
                                        <asset-passport-right-box :item_info="item_info"/>
                                    </template>
                                </NewCustomGenericBlock>

                            </div>

                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12">
                            No Passport For this Asset
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="instances_tab" role="tabpanel" :class="isActiveTab('instances_tab')">
                    <div class="row overview-block">
                        <div class="col-12">
                            <div class="row">
                                <NewCustomGenericBlock
                                    :block_title="'Instances ' + (item_info.cAFID ? '- '+item_info.cAFID + ' ' : '')"
                                    box_colour_v="-"
                                    columns_count_withclasses="12"
                                >
                                    <template v-slot:all_content>
                                        <asset-related-splits
                                            :item_info="item_info"
                                            item_type="instances"
                                            :selected_asset_id="selected_asset_id"
                                        />
                                    </template>
                                </NewCustomGenericBlock>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="related_items_tab" role="tabpanel" :class="isActiveTab('related_items_tab')">
                    <div class="row overview-block">
                        <div class="col-12">
                            <div class="row">

                                <NewCustomGenericBlock
                                    :block_title="'Related Items ' + (item_info.pAFID ? '- '+item_info.pAFID + ' ' : '') + '(Splits)'"
                                    box_colour_v="-"
                                    columns_count_withclasses="12"
                                >
                                    <template v-slot:all_content>
                                        <asset-related-splits
                                            :item_info="item_info"
                                            item_type="related_items"
                                            :selected_asset_id="selected_asset_id"
                                        />
                                    </template>
                                </NewCustomGenericBlock>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" id="media_tab" role="tabpanel" :class="isActiveTab('media_tab')">
                    <AssetPassportMedia :item_info="item_info"/>
                </div>
                <div class="tab-pane" id="activity_tab" role="tabpanel" :class="isActiveTab('activity_tab')">
                    <AssetPassportActiviyTab :item_info="item_info"/>

                </div>
            </div>
        </div>
        <!-- Intelligance Template Close-->
    </div>
</template>

<script>
import TaskList from "./TaskList";
import CustomGenericBlock from "./CustomGenericBlock";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import AssetRelatedSplits from "./AssetRelatedSplits";
import IdentificationBlock from "./AssetBlocks/IdentificationBlock";
import AssetPassportRightBox from "./AssetPassportBlocks/AssetPassportRightBox";
import AssetPassportActiviyTab from "./AssetPassportBlocks/AssetPassportActiviyTab";
import AssetPassportMedia from "./AssetPassportMedia";
import NewCustomGenericBlock from "./NewCustomGenericBlock";
import CustomGenericBlockContent from "./CustomGenericBlockContent";

const firstPath = window.location.pathname.split('/')[1];

export default {
    mixins:[AssetTabsShared],
    name: "AssetPassportTab",
    components: {
        CustomGenericBlockContent,
        NewCustomGenericBlock,
        AssetPassportMedia,
        AssetPassportActiviyTab,
        AssetPassportRightBox,
        IdentificationBlock,
        AssetRelatedSplits,
        CustomGenericBlock,
        TaskList
    },
    props:['item_info', 'selected_asset_id'],
    computed:{
        passport_item(){
            if (this.item_info.hasOwnProperty('cAFID_info')){
                this.is_asset_passport = false;
                return this.item_info.cAFID_info;
            }
            this.is_asset_passport = true;
            return this.item_info;
        }
    },
    data(){
        return {
            is_asset_passport: false,
            active_tab: 'basic_info_tab',
            tabs: {
                'basic_info_tab': {
                    'id': "basic_info_tab",
                    'name': "Asset Passport",
                    'icon': "user",
                    'type': "basic_info_tab",
                    'isActive': true
                },
                'instances_tab': {
                    'id': "instances_tab",
                    'name': "Instances",
                    'icon': "user",
                    'type': "instances_tab",
                    'isActive': false
                },
                'related_items_tab': {
                    'id': "related_items_tab",
                    'name': "Related Items",
                    'icon': "user",
                    'type': "related_items_tab",
                    'isActive': false
                },
                'media_tab': {
                    'id': "media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "media_tab",
                    'isActive': false
                },
                'activity_tab': {
                    'id': "activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "activity_tab",
                    'isActive': false
                },
            },
            physical_properties:[
                {
                    key: 'width'
                },
                {
                    key: 'volume',
                    sup_value: '3'
                },
                {
                    key: 'height'
                },
                {
                    key: 'total_volume',
                    sup_value: '3'
                },
                {
                    key: 'depth'
                },
                {
                    key: 'area',
                    sup_value: '2'
                },
                {
                    key: 'weight',
                    no_unit: true
                },
                {
                    key: 'total_area',
                    sup_value: '2'
                },
            ],
            block_contents: {
                identification:{
                    label: 'Identification',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-3',
                    info_list: []
                },
                waste_stream:{
                    label: 'Waste Stream',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                physical_properties:{
                    label: 'Physical Properties',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
            }
        }
    },
    created() {
        this.item_info.current_page_type = firstPath;

        let asset_passport_active_tab = localStorage.getItem('asset_passport_active_tab');
        if (asset_passport_active_tab && asset_passport_active_tab !== ''){
            this.active_tab = asset_passport_active_tab;
        }


        let item_info = this.item_info;
        let used_functions = {
            getPropertyStatusText: this.getPropertyStatusText,
            is_asset_passport: this.is_asset_passport,
        }

        // AFID Properties
        let my_passport_item = this.passport_item;
        if (my_passport_item){

            let uid = this.is_asset_passport && this.item_info.uid ? (this.item_info.uid+'').replace('PASS-', '') : this.item_info.cAFID_info.uid;
            if (uid !== ''){
                this.tabs.basic_info_tab.name = 'PASS-'+uid;
            }
            this.block_contents.identification.info_list = [
                {
                    label: 'Asset Name',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return my_passport_item.universal_asset_name;
                    }
                },
                {
                    label: 'Passport Status',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return my_passport_item.passport_status === 0 ? 'Assigned' : '-';
                    }
                },
                {
                    label: 'Passport No.',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return (!(my_passport_item.uid+"").startsWith("PASS-") ? 'PASS-' : '') + my_passport_item.uid;
                    }
                },
                {
                    label: 'Last Known Condition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return my_passport_item.condition > 0;
                    },
                    info_value : function () {
                        let conditions_list = [
                            'New',
                            'Excellent',
                            'Good',
                            'Attention Required',
                        ];
                        return conditions_list[my_passport_item.condition - 1];
                    }
                },
                {
                    label: 'Passport Type',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return my_passport_item.quantity > 1 ? 'Shared' : 'Unique';
                    }
                },
                {
                    label: 'Colours',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.colours_info && my_passport_item.colours_info.length > 0;
                    },
                    info_value : function () {
                        let colours_info = my_passport_item.colours_info;
                        if (colours_info){
                            let html = '<div class="cm_list_inner_common_value">';
                            if (colours_info.length > 0){
                                for (const itemElementKey in colours_info) {
                                    let colour = colours_info[itemElementKey];
                                    html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                                }
                            }else{
                                html += '-';
                            }
                            html += '</div>';
                            return html;
                        }
                        return '-';
                    }
                },
                {
                    label: 'Serial',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return my_passport_item.serial_number;
                    }
                },
                {
                    label: 'Category',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('parent_category') && my_passport_item.parent_category;
                    },
                    info_value : function () {
                        return my_passport_item.parent_category.name;
                    }
                },
                {
                    label: 'Model',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return my_passport_item.model_number;
                    }
                },
                {
                    label: 'Category Template',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('asset_template') && my_passport_item.asset_template;
                    },
                    info_value : function () {
                        return my_passport_item.asset_template.name;
                    }
                },
            ];

            let ws_status_values = used_functions.getPropertyStatusText('ws', true);
            this.block_contents.waste_stream.info_list = [
                {
                    label: 'Status',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return ws_status_values.status_text !== '';
                    },
                    info_value : function () {
                        return '<i class="font-size-18 bx bxs-circle status-icon status-icon-'+ws_status_values.status_class+'"></i> ' +  ws_status_values.status_text;
                    }
                },
                {
                    label: 'Updated On',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return ws_status_values.defined_date !== '';
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(ws_status_values.defined_date, "dddd, MMMM Do YYYY");
                    }
                },
                {
                    label: 'Updated By',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return ws_status_values.defined_by !== '';
                    },
                    info_value : function () {
                        if (ws_status_values.defined_by_id === 0){
                            return ws_status_values.defined_by;
                        }else{
                            let user_link = '/' + item_info.current_page_type + '/users/'+ws_status_values.defined_by_id+'/view';
                            return '<i class="bx bx-user-circle"></i> <a href="' + user_link + '" class="text-dark">' + Vue.options.filters.titleize(ws_status_values.defined_by) + '</a>';
                        }
                    }
                },
                {
                    label: 'Proposed Waste Stream',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('waste_stream_type') && [0,1].includes(my_passport_item.waste_stream_type);
                    },
                    info_value : function () {
                        return my_passport_item.waste_stream_type === 0 ? 'Product' : 'Material';
                    }
                },
                {
                    label: 'Waste Stream by Product',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        if (my_passport_item.waste_stream_type === 0) {
                            return my_passport_item.hasOwnProperty('waste_stream_info') && my_passport_item.waste_stream_info;
                        }
                        return my_passport_item.composition_info && my_passport_item.composition_info.length;
                    },
                    info_value : function () {
                        if (my_passport_item.waste_stream_type === 0){
                            return my_passport_item.waste_stream_info.name;
                        }
                        return my_passport_item.composition_info.map(item => item.name.charAt(0).toUpperCase() + item.name.slice(1)).join(', ');
                    }
                },
            ];
            if (my_passport_item.waste_stream_type !== 0){
                this.block_contents.waste_stream.info_list[4].label = 'Waste Stream by Composition';
            }

            let ph_status_values = used_functions.getPropertyStatusText('ph', true);
            let preferred_unit = ' <span class="used_unit">' + (my_passport_item.preferred_unit ? my_passport_item.preferred_unit : 'm') + '</span>';
            this.block_contents.physical_properties.info_list = [
                {
                    label: 'Status',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return ph_status_values.status_text !== '';
                    },
                    info_value : function () {
                        return '<i class="font-size-18 bx bxs-circle status-icon status-icon-'+ph_status_values.status_class+'"></i> ' +  ph_status_values.status_text;
                    }
                },
                {
                    label: 'Updated On',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return ph_status_values.defined_date !== '';
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(ph_status_values.defined_date, "dddd, MMMM Do YYYY");
                    }
                },
                {
                    label: 'Updated By',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return ph_status_values.defined_by !== '';
                    },
                    info_value : function () {
                        if (ph_status_values.defined_by_id === 0){
                            return ph_status_values.defined_by;
                        }else{
                            let user_link = '/' + item_info.current_page_type + '/users/'+ph_status_values.defined_by_id+'/view';
                            return '<i class="bx bx-user-circle"></i> <a href="' + user_link + '" class="text-dark">' + Vue.options.filters.titleize(ph_status_values.defined_by) + '</a>';
                        }
                    }
                },
                {
                    label: 'Height',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('height') && item_info.height;
                    },
                    info_value : function () {
                        return my_passport_item.height + preferred_unit;
                    }
                },
                {
                    label: 'Depth',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('depth') && item_info.depth;
                    },
                    info_value : function () {
                        return my_passport_item.depth + preferred_unit;
                    }
                },
                {
                    label: 'Width',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('width') && item_info.width;
                    },
                    info_value : function () {
                        return my_passport_item.width + preferred_unit;
                    }
                },
                {
                    label: 'Weight',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('weight') && item_info.weight;
                    },
                    info_value : function () {
                        return my_passport_item.weight + ' <span class="used_unit"><sup>kg</sup> for 1 Item</span>';
                    }
                },
                {
                    label: 'Total Weight',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('total_weight') && item_info.total_weight;
                    },
                    info_value : function () {
                        return my_passport_item.total_weight + ' <span class="used_unit"><sup>kg</sup> for ' + my_passport_item.quantity + ' Item' + (my_passport_item.quantity > 1 ? 's' : '') +'</span>';
                    }
                },
                {
                    label: 'Volume',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('volume') && item_info.volume;
                    },
                    info_value : function () {
                        return my_passport_item.volume + ' <span class="used_unit">m<sup>3</sup></span>';
                    }
                },
                {
                    label: 'Total Volume',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('total_volume') && item_info.total_volume;
                    },
                    info_value : function () {
                        return my_passport_item.total_volume + ' <span class="used_unit">m<sup>3</sup></span>';
                    }
                },
                {
                    label: 'Area',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('area') && item_info.area;
                    },
                    info_value : function () {
                        return my_passport_item.area + ' <span class="used_unit">m<sup>2</sup></span>';
                    }
                },
                {
                    label: 'Total Area',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return my_passport_item.hasOwnProperty('total_area') && item_info.total_area;
                    },
                    info_value : function () {
                        return my_passport_item.total_area + ' <span class="used_unit">m<sup>2</sup></span>';
                    }
                },
            ];
        }

    },
    mounted(){

    },
    methods:{
        getPropertyStatusText(property_name){
            let dict_values = {};
            let passport_status_key = property_name + '_defined_status';
            let passport_by_key = property_name + '_defined_by';
            let passport_date_key = property_name + '_defined_date';

            if (!this.passport_item){
                return '';
            }
            if (this.passport_item.hasOwnProperty(passport_status_key)){
                switch (this.passport_item[passport_status_key]) {
                    case '0':
                        dict_values.status_text = 'None';
                        dict_values.status_class = 'planned';
                        break;
                    case '1':
                        dict_values.status_text = 'Estimated';
                        dict_values.status_class = 'acquired';
                        break;
                    case '2':
                        dict_values.status_text = 'Confirmed';
                        dict_values.status_class = 'active';
                        break;
                }
            }

            if (this.passport_item[passport_by_key] !== null){
                if (this.passport_item[passport_by_key] === 0){
                    dict_values.defined_by = 'AF Intelligence';
                    dict_values.defined_by_id = 0;
                }else{
                    let user_key = passport_by_key + '_user';
                    if (this.passport_item.hasOwnProperty(user_key) && this.passport_item[user_key]){
                        dict_values.defined_by = this.passport_item[user_key].name;
                        dict_values.defined_by_id = this.passport_item[user_key].id;
                    }
                }
            }

            if (this.passport_item.hasOwnProperty(passport_date_key)){
                dict_values.defined_date = this.passport_item[passport_date_key];
            }

            return dict_values;
        },
        getMetaValue(meta_key){
            if (this.item_info.hasOwnProperty('asset_meta') && this.item_info.asset_meta){
                let asset_meta = this.item_info.asset_meta;
                if (asset_meta.hasOwnProperty(meta_key)){
                    return asset_meta[meta_key];
                }
            }
            return '-';
        },
        tabClicked(tab_id){
            localStorage.setItem('asset_passport_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },

        getAcquisitionType(){
            let acquisition_type = this.getObjectOf(this.item_info.asset_meta.acquisition_type, this.acquisition_types);
            if (acquisition_type){
                return acquisition_type.name;
            }
            return '-';
        },
        getAcquisitionUsing(){
            return this.item_info.asset_meta.acquired_using === 1 ? 'PO' : '-';
        },
        getUserAndDate(action_types_filters = []) {
            var completed_at, completed_by_user;
            if (this.item_info.asset_posts) {
                var location_posts;
                for (const key in action_types_filters) {
                    let action_type = action_types_filters[key];
                    if (!location_posts || location_posts.length === 0) {
                        location_posts = this.item_info.asset_posts.filter(function (e) {
                            return e.action_type === action_type;
                        });
                    }else{
                        continue;
                    }
                }

                if (location_posts.length > 0) {
                    completed_at = location_posts[0].created_at;
                    completed_by_user = location_posts[0].user;
                }
            }
            return {completed_at, completed_by_user};
        },
    }
}
</script>

