<template>
    <p v-if="key_info.case === 'custom'">{{ key_info.used_func(item[key_info.key])}}</p>
    <p v-else-if="key_info.case === 'asset_status'" v-html="getAssetStatusFrom(item)"></p>
    <p v-else-if="key_info.case === 'custom_html'" v-html="key_info.value_func(item, key_info.key)"></p>
    <p v-else-if="key_info.case === 'custom_tooltip'" v-b-tooltip:hover.bottom :title="key_info.title_func(index, item, key_info.key)">{{ key_info.value_func(index, item, key_info.key) }}</p>

    <p v-else-if="key_info.case === 'custom_badges_list'">
        <span v-for="list_item in item[key_info.key]" class="mt-1">
            <a :href="'./'+ key_info.url_item_type + '/' + list_item[key_info.id_value] +'/view'" class="badge badge-soft-primary font-size-11">
                {{ list_item[key_info.name_value] | titleize }}
            </a>
        </span>
    </p>

    <p v-else-if="key_info.case === 'custom_html_tooltip'" v-b-tooltip:hover.bottom :title="key_info.title_func(index, item, key_info.key)" v-html="key_info.value_func(index, item, key_info.key)"></p>
    <p v-else-if="key_info.case === 'use_index'">{{key_info.with_index_func(index, item, key_info.key)}}</p>
    <p v-else>{{item[key_info.key]}}</p>
</template>
<script>
export default {
    name: 'ValueByKeyUsingCase',
    props: {
        current_page_type: String,
        index: {},
        item: {},
        key_info: {}
    }
}
</script>
