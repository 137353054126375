<template>
    <div class="role_module_details">

        <po-lines :items="module_details" :keys_list="module_details_keys_list" />
    </div>
</template>
<script>
import PoLines from "./detailsViews/PoLines";
export default {
    name: 'RoleModuleDetails',
    components: {PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            module_details: [],
            module_details_keys_list: {
                module_name: {
                    key: 'module_name',
                    label: "Module Name",
                    class: "col-lg-6 md_module_name",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {

                        return item[key];
                    }
                },
                entity_type: {
                    key: 'entity_type',
                    label: "Entity Type",
                    class: "col-lg-6 md_entity_type",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return item[key];
                    }
                },
            },
        }
    },
    created() {
        let item_info = this.item_info;

        if (item_info){

            this.module_details = [
                {
                    module_name: item_info.hasOwnProperty('module_info') && item_info.module_info ? item_info.module_info.name : '-',
                    entity_type: item_info.role_type
                },
            ];


            this.module_details_keys_list.module_name.label = 'Modules that \'' + item_info.name + '\' Belongs to';
            this.module_details_keys_list.module_name.value_func = function (item, key) {
                let module_link = item_info.hasOwnProperty('module_info') && item_info.module_info ? '<a class="text-dark" href="/' + item_info.current_page_type +'/modules/' + item_info.module_info.id + '/view">' + item_info.module_info.name + '</a>' : '-';
                return Vue.options.filters.iconize(module_link, 'modules')
            };

            this.module_details_keys_list.entity_type.value_func = function (item, key) {
                let role_type_text = item_info.hasOwnProperty('role_type') && item_info.role_type ? Vue.options.filters.titleize(item_info.role_type) : '-';
                return Vue.options.filters.iconize(role_type_text, item_info.role_type+'s');
            };
        }


    }
}
</script>
