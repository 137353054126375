<template>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="row">
                <NewCustomGenericBlock
                    :block_title="block_contents.status.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :use_table="true" :block_contents="block_contents.status"/>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    :block_title="block_contents.details.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :block_contents="block_contents.details"/>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    :block_title="block_contents.supplier.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :block_contents="block_contents.supplier"/>
                    </template>
                </NewCustomGenericBlock>

                <NewCustomGenericBlock
                    :block_title="block_contents.reconciliation.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <CustomGenericBlockContent :block_contents="block_contents.reconciliation"/>
                    </template>
                </NewCustomGenericBlock>

            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <div class="row" v-if="item_info">
                <NewCustomGenericBlock
                    block_title=""
                    :block_title_html="getHtmlTitleFor('acquisitions')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <AcquisitionRightBox :item_info="item_info" unique_id="details"/>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

    </div>
</template>
<script>
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import PoLines from "./widgets/detailsViews/PoLines";
import MyPostContent from "./widgets/detailsViews/PostContentTypes/MyPostContent";
import AcquisitionRightBox from "./AcquisitionRightBox";
import CustomGenericBlockContent from "./widgets/detailsViews/AssetTabs/CustomGenericBlockContent";
import {AcquisitionMethodsList} from "@/views/assetflow/form_data/AcquisitionMethodsList";

export default {
    name: 'AcquisitionDetailsTab',
    components: {
        CustomGenericBlockContent,
        AcquisitionRightBox,
        MyPostContent,
        PoLines,
        NewCustomGenericBlock
    },
    mixins:[TimelineCommons],
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            acquisition_type_info: null,
            block_contents: {
                status:{
                    label: 'Status',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-3',
                    info_list: []
                },
                details:{
                    label: 'Details',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
                reconciliation:{
                    label: 'Reconciliation',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
                supplier:{
                    label: 'Supplier',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
            }
        }
    },
    computed:{
        item_info_asset_posts(){
            return this.item_info && this.item_info.hasOwnProperty('asset_posts') ? this.item_info.asset_posts : null;
        },
    },
    created() {


        let item_info = this.item_info;
        let used_functions = {
            getCurrencyByValue: this.getCurrencyByValue,
            toUserCurrencyValue: this.toUserCurrencyValue,
            getAcquisitionStatus: this.getAcquisitionStatus,
            POWithUIDs: this.POWithUIDs,
            acquisition_methods: AcquisitionMethodsList,
        }

        let acquisition_type_info = item_info.hasOwnProperty('acquisition_type_info') && item_info.acquisition_type_info ? item_info.acquisition_type_info : null;
        this.acquisition_type_info = acquisition_type_info;
        if (item_info){


            let number_of_assets = item_info.no_of_assets ? (item_info.no_of_assets + ' ') : '';
            let number_of_payments = item_info.no_of_payments ? (item_info.no_of_payments + ' ') : '';
            let item_info_currency = this.getCurrencyByValue(item_info.currency !== '' ? item_info.currency : 'gbp');
            let item_info_currency_icon = '£';
            if (item_info_currency){
                item_info_currency_icon = item_info_currency.icon;
            }

            let assets_value = parseFloat(item_info.assets_value),
                line_items_value = parseFloat(item_info.line_items_value),
                payments_value = parseFloat(item_info.payments_value);

            item_info.balance = parseFloat(assets_value + line_items_value - payments_value);

            this.block_contents.status.info_list = [
                {
                    label: 'Status',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        let acquisitionStatus = used_functions.getAcquisitionStatus(item_info.status, true);
                        let statusesList = '';
                        if (acquisitionStatus){
                            statusesList = Object.keys(acquisitionStatus).join(" ");
                        }

                        return '<div class="d-flex-p"><div><span class="button_size acq-status ' + statusesList + '">'  + used_functions.getAcquisitionStatus(item_info.status) + '</span></div></div>' ;
                    }
                },
                {
                    label: number_of_assets + 'Assets:',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('assets_value') && item_info.assets_value !== '';
                    },
                    tooltip_title : function () {
                        return used_functions.toUserCurrencyValue(item_info.currency, item_info.assets_value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        return currency_icon + ' ' + item_info.assets_value;
                    }
                },
                {
                    label: 'Line Items',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('line_items_value') && item_info.line_items_value !== '';
                    },
                    tooltip_title : function () {
                        return used_functions.toUserCurrencyValue(item_info.currency, item_info.line_items_value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        return currency_icon + ' ' + item_info.line_items_value;
                    }
                },
                {
                    label: number_of_payments + ' Payments:',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('payments_value') && item_info.payments_value !== '';
                    },
                    tooltip_title : function () {
                        return used_functions.toUserCurrencyValue(item_info.currency, item_info.payments_value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        return currency_icon + ' ' + item_info.payments_value;
                    }
                },
                {
                    label: '0 Returns',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return item_info_currency_icon + ' 0';
                    }
                },
                {
                    label: '0 Receipts',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return item_info_currency_icon + ' 0';
                    }
                },
                {
                    label: 'Balance',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('balance') && item_info.balance !== '';
                    },
                    tooltip_title : function () {
                        return used_functions.toUserCurrencyValue(item_info.currency, item_info.balance);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.currency).icon;
                        return currency_icon + ' ' + item_info.balance;
                    }
                },
            ];


            this.block_contents.details.info_list = [

                {
                    label: 'Buyer',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('buyer_info') && item_info.buyer_info;
                    },
                    info_value : function () {
                        return '<a href="/' + item_info.current_page_type + '/users/' + item_info.buyer_info.id + '/view">' + item_info.buyer_info.name + '</a>';
                    }
                },
                {
                    label: 'Acquisition Date',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('acquired_date') && item_info.acquired_date !== '';
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(item_info.acquired_date, 'MMMM Do YYYY');
                    }
                },
                {
                    label: 'Payment Account',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('payment_ledger_info') && item_info.payment_ledger_info;
                    },
                    info_value : function () {
                        return '<a href="/' + item_info.current_page_type + '/ledgers/' + item_info.payment_ledger_info.id + '/view">' + item_info.payment_ledger_info.description + '</a>';
                    }
                },
                {
                    label: 'P-Card',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('buyer_ledger_info') && item_info.buyer_ledger_info;
                    },
                    info_value : function () {
                        return '<a href="/' + item_info.current_page_type + '/ledgers/' + item_info.buyer_ledger_info.id + '/view">' + item_info.buyer_ledger_info.name + '</a>';
                    }
                },
                {
                    label: 'P-Card Desc',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('buyer_ledger_info') && item_info.buyer_ledger_info;
                    },
                    info_value : function () {
                        return '<a href="/' + item_info.current_page_type + '/ledgers/' + item_info.buyer_ledger_info.id + '/view">' + item_info.buyer_ledger_info.description + '</a>';
                    }
                },
            ];

            this.block_contents.reconciliation.info_list = [
                {
                    label: 'Reconciled',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('reconciled') && item_info.reconciled !== '';
                    },
                    info_value : function () {
                        return item_info.reconciled === 1 ? 'Yes' : 'No';
                    }
                },
                {
                    label: 'Date',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('reconciliation_date') && item_info.reconciliation_date;
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(item_info.reconciliation_date, 'MMMM Do YYYY');
                    }
                },
                {
                    label: 'Reconciled By',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('reconciliation_user_info') && item_info.reconciliation_user_info && item_info.reconciliation_user_info.length > 0;
                    },
                    info_value : function () {
                        return '<a href="/' + item_info.current_page_type + '/users/' + item_info.reconciliation_user_info.id + '/view">' + item_info.reconciliation_user_info.name + '</a>';
                    }
                },
                {
                    label: 'Reference',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return true;
                    },
                    info_value : function () {
                        return item_info.uid;
                    }
                },
                {
                    label: 'External Reference',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.reference !== '';
                    },
                    info_value : function () {
                        return item_info.reference;
                    }
                },
            ];

            this.block_contents.supplier.info_list = [
                {
                    label: 'Supplier',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('supplier_name') && item_info.supplier_name !== '';
                    },
                    info_value : function () {
                        return '<a href="/' + item_info.current_page_type + '/suppliers/' + item_info.supplier_id + '/view">' + item_info.supplier_name + '</a>';
                    }
                },
                {
                    label: 'Purchase Order',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('purchase_orders_info') && item_info.purchase_orders_info && item_info.purchase_orders_info.length > 0;
                    },
                    info_value : function () {
                        let latest_po = item_info.purchase_orders_info[item_info.purchase_orders_info.length-1];
                        return '<a href="/' + item_info.current_page_type + '/purchaseOrders/' + latest_po.id + '/view">' + used_functions.POWithUIDs(latest_po) + '</a>';
                    }
                },
                {
                    label: 'Acquisition Method',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.acquisition_method !== '';
                    },
                    info_value : function () {
                        let acquisition_methods = used_functions.acquisition_methods;
                        return acquisition_methods[item_info.acquisition_method] ? acquisition_methods[item_info.acquisition_method].name : '-';
                    }
                },
            ];


            if (item_info.ownership_type === 0){
                let end_date_info = {
                    label: 'End Date',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return item_info.hasOwnProperty('end_date') && item_info.end_date !== '';
                    },
                    info_value : function () {
                        return Vue.options.filters.moment(item_info.end_date, 'MMMM Do YYYY');
                    }
                };
                this.block_contents.details.info_list.push(end_date_info);
            }

        }

    },
    methods:{
    }
}
</script>
