<template>
    <div class="cm_list_view">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'change_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p">
                                    <div>
                                        <h3>
                                            <a v-if="item_info.hasOwnProperty('character_info') && item_info.character_info" v-b-tooltip :title="'UID: ' + item_info.uid" :href="'/' + item_type + '/changes/' + item_info.id + '/view'">
                                                {{ '('+item_info.character_info.character_number+') ' +item_info.character_info.name + ': Change ' +item_info.name }}
                                            </a>
                                            <a v-else v-b-tooltip :title="'UID: ' + item_info.uid" :href="'/' + item_type + '/changes/' + item_info.id + '/view'">
                                                {{ item_info.name | titleize }}
                                            </a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p"><div>
                                    <span class="button_size" :class="getChangeStatus(true)">{{getChangeStatus()}}</span>
                                </div></div>
                            </div>

                            <div class="cm_status_ul change_details_qty">
                                <ul>
                                    <li>
                                        <p>Assets <span class="total_box_bg">-</span></p>
                                    </li>
                                    <li>
                                        <p>Tasks <span class="total_box_bg">-</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row cm_list_view_row">

                    <div class="col-md-12 col-xl-12 change-list-description" v-if="item_info.description !== ''">{{item_info.description}}</div>
                    <div class="col-md-12 col-xl-12">
                        <po-lines
                            v-if="item_info.hasOwnProperty('parts_info') && item_info.parts_info && item_info.parts_info.length > 0"
                            :items="sorted_parts_info"
                            :current_page_type="item_info.current_page_type"
                            :object_type="'parts'"
                            :line_type="'parts'"
                            :keys_list="part_keys_list"
                        >

                            <template v-slot:new_line="{ item, index }" v-if="with_parts_section">
                                <div class="tbody_item col-xs-12 col-sm-4 col-lg-3 scene_notes scene_notes_gallery">
                                    <MyBCarouselImages v-if="item.hasOwnProperty('scene_note_images') && item.scene_note_images"
                                                       parent_class="cm_list_view_image"
                                                       :my_asset="item"
                                                       asset_images_name="scene_note_images"
                                                       :show-captiom-img="showCaptiomImg"
                                                       :required_image_size="'scene_notes_gallery'"/>
                                </div>
                                <div class="tbody_item col-xs-12 col-sm-8 col-lg-9 scene_notes">
                                    <ul class="scene-notes-header">
                                        <li class="scene-notes-heading">Scene Notes</li>
                                        <li class="scene-notes-modal-link"><a href="javascript:void(0);" @click="updateImagesModal(item, index, item_key, $event)">{{ item.part_change_asset_images ? item.part_change_asset_images : 'No' }} Image{{item.part_change_asset_images > 1 ? 's' : ''}}</a></li>
                                        <li class="scene-notes-modal-link"><a href="javascript:void(0);" @click="addDescriptionModal(item, index, item_key, $event)">Update Scene Notes</a></li>

                                        <li class="scene-notes-sdl-link"><a :href="'/' + item_type + '/shoot-day-live?scene='+item.id+'&character='+item_info.character_id+'&change='+item_info.id" target="_blank">View in SDL</a></li>
                                        <li class="scene-notes-details-link"><a :href="'/' + item_type + '/changes/'+item_info.id + '/scene/' + item.id" target="_blank">View Details</a></li>
                                    </ul>
                                    <ul class="scene-notes-description">
                                        <li>{{ item.part_change_description ? item.part_change_description : 'No Scene Notes Yet' }}</li>
                                    </ul>
                                </div>
                            </template>

                            <template v-slot:parts_actions="{ item, index }">
                                <div class="btn-group bl-auto">
                                    <button type="button" class="btn btn-none dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                        <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <b-dropdown-item :href="'/' + item_type + '/changes/'+item_info.id + '/scene/' + item.id">View Details</b-dropdown-item>
                                    </div>
                                </div>
                            </template>


                        </po-lines>
                    </div>

                    <div class="col-md-12 col-xl-12">
                        <div class="cm_list_last_update mt-1" v-if="item_info.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText()"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">

            <div v-if="hasPermission('edit_changes') || hasPermission('delete_changes')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>


                <ChangeActionsList :item="item_info" :index="item_key" :item_type="item_type" object_type="changes" :perform-action="performAction"/>

            </div>

        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/changes/' + item_info.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import MyBCarouselSlide from "../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import PoLines from "../../items/widgets/detailsViews/PoLines";

import {PartChangeKeysList} from "@/views/assetflow/form_data/PartChangeKeysList";
import ChangeActionsList from "../../items/ChangeActionsList";
import SetChangeSceneDescription from "../../items/ChangeSceneRel/SetChangeSceneDescription";
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'ChangeListView',
    components: {MyBCarouselImages, SetChangeSceneDescription, ChangeActionsList, PoLines, MyBCarouselSlide},
    props: [
        'item_type',
        'item_key',
        'item_info',
        'with_parts_section',
        'showCaptiomImg',
        'performAction',
        'addDescriptionModal',
        'updateImagesModal',
    ],
    data(){
        return {
            sorted_parts_info:[],
            part_keys_list: PartChangeKeysList,
            partSortType:{
                value: 0,
                text: 'Part Order',
                first_criteria: 'target_scene',
                first_reversed: false,
                name: 'part_order',
                second_criteria: 'scene_order',
                second_reversed: false,
                third_criteria: 'part_order',
                third_reversed: false
            },
        }
    },
    methods:{
        getChangeStatus(just_classes=false){
            if (just_classes){
                return 'status-btn-' + (this.item_info.status === 1 ? 'active' : 'planned') +' ' + (this.item_info.status === 1 ? 'established' : 'planned') + '-change-status';
            }
            return (this.item_info.status === 1 ? 'Established' : 'Planned');
        },
        getPostText(){
            if (this.item_info.hasOwnProperty('latest_post_update')){
                let user = this.item_info.latest_post_update.user;
                let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(this.asset, this.item_info.latest_post_update) + " on " + Vue.options.filters.moment(this.item_info.latest_post_update.created_at , 'DD/MM/YYYY');
            }
            return '';
        },
    },
    created() {
        this.sorted_parts_info = this.item_info.parts_info;
    }
}
</script>
