<template>
    <po-lines
        v-if="managed_storages"
        :items="managed_storages"
        :current_page_type="item_info.current_page_type"
        object_type="orders"
        :keys_list="keys_list">
        <template v-slot:managed_storages_actions="{ item, index }">
            <ManagedStorageActions :index="index"
                                   :item="item"
                                   :item_type="item_info.current_page_type"
                                   :object_type="orders_object_type"
                                   @on-perform-action="performAction"
                                   :deletion_condition="false"
                                   :permissions_list="permissions_list"
                                   :custom_slot_names="['custom_action']">
                <template v-slot:custom_action>
                    <b-dropdown-item
                        v-if="permissions_list['edit_' + orders_object_type] && item_info.current_page_type === 'sp'"
                        @click="performAction(item, 'custom_managed_storage_action', index)">
                        Custom STO Action
                    </b-dropdown-item>
                </template>
            </ManagedStorageActions>
        </template>


        <template v-slot:order_lines_actions="{ item, index }">
            <ManagedStorageActions :index="index"
                                   :item="item"
                                   :item_type="item_info.current_page_type"
                                   :object_type="orders_object_type"
                                   @on-perform-action="performAction"
                                   :deletion_condition="false"
                                   :permissions_list="permissions_list"
                                   :custom_slot_names="['custom_action']">
                <template v-slot:custom_action>
                    <b-dropdown-item
                        v-if="permissions_list['edit_' + orders_object_type] && item_info.current_page_type === 'sp'"
                        @click="performAction(item, 'custom_order_line_action', index)">
                        Custom Order Line Action
                    </b-dropdown-item>
                </template>
            </ManagedStorageActions>
        </template>
    </po-lines>

    <div v-else class="products text-center">
        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
        <h4>No {{ object_type | titleize }} found here at the moment.</h4>
    </div>

</template>
<script>


import ManagedStorageActions from "../../customers/managed_storages/ManagedStorageActions";
import PoLines from "../widgets/detailsViews/PoLines";

export default {
    name: 'ListManagedStoragesFor',
    components: {PoLines, ManagedStorageActions},
    props: {
        item_info: {},
        managed_storages: {},
        keys_list: {},
        object_type: {},
    },
    data(){
        return {
            permissions_list:{},
            orders_object_type: 'orders'
        }
    },
    created() {

        this.permissions_list['view_' + this.orders_object_type] = this.hasPermission('browse_' + this.orders_object_type);
        this.permissions_list['edit_' + this.orders_object_type] = this.hasPermission('edit_' + this.orders_object_type);
        this.permissions_list['delete_' + this.orders_object_type] = this.hasPermission('delete_' + this.orders_object_type);

        this.has_order_actions_permissions = this.permissions_list['edit_' + this.orders_object_type] || this.permissions_list['delete_' + this.orders_object_type];

        if (this.has_order_actions_permissions) {
            switch (this.object_type) {
                case 'managed_storages':
                    this.keys_list.actions = {
                        key: 'actions',
                        label: "Actions",
                        class: "col-lg-1 po_actions",
                        show_on_mobile: false,
                        case: 'custom_slot',
                        slot_name: 'managed_storages_actions'
                    }
                    break;
                case 'order_lines':
                    this.keys_list.actions = {
                        key: 'actions',
                        label: "Actions",
                        class: "col-lg-1 po_actions",
                        show_on_mobile: false,
                        case: 'custom_slot',
                        slot_name: 'order_lines_actions'
                    }
                    break;

            }
        }
    },
    methods:{
        performAction(managed_storage, action, managed_storageKey){
            if (action === 'edit') {
                console.log("shall handle 'edit action'");
            }else if (action === ('custom_managed_storage_action')){
                console.log("shall handle 'custom_managed_storage_action action'");
            }else if (action === ('custom_order_line_action')){
                console.log("shall handle 'custom_order_line_action action'");
            }else if (action === ('delete'+this.object_type)){
                if (managed_storage.id !== ''){
                    this.deleteItemByID(managed_storage.id, managed_storage.name, this.object_type, managed_storage)
                        .then((response) => {
                            if (response.data.data.status){
                                this.managed_storagesData.splice(managed_storageKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
    }
}
</script>
