import {getLocationDetails} from "./SpLocationSharedFunctions";
const FirstPath = window.location.pathname.split('/')[1];
export const SPLocationKeysList = {
    location: {
        key: 'location',
        label: "Location",
        class: "col-lg-4 war_location",
        show_on_mobile: false,
        case: 'custom_html',
        required_class: 'object-title-block',
        value_func: function (item, key) {
            let html = '';
            let status_list = [
                'Free',
                'Occupied',
                'Unavailable'
            ];
            let location_classes_list = [
                'green',
                'orange',
                'grey'
            ];

            let location_status = status_list[item.status];
            let location_class = location_classes_list[item.status];

            let park_action = '';
            let location_action_info = ' data-location_id="'+item.id+'"';
            if (item.status === 0){
                park_action = '<a href="#" class="inline-action park-location-item"' + location_action_info + '>Park Storage</a>';
            }else if (item.status === 1){
                park_action = '<a href="#" class="inline-action vacate-location-item"' + location_action_info + '>Vacate</a>';
            }

            html += getLocationDetails(item);
            html += '<p class="object-title-detail"><i class="fa fa-circle status-'+location_class+' location-'+location_status.toLowerCase()+'"></i>'+location_status +
                park_action + '</p>';
            return html;
        }
    },
    storage: {
        key: 'storage',
        label: "Storage",
        class: "col-lg-3 war_storage",
        show_on_mobile: false,
        case: 'custom_html',
        required_class: 'object-title-block',
        value_func: function (item, key) {
            let html = '<p class="object-title-list">';
                if (item.hasOwnProperty('storage_info') && item.storage_info){
                    let storage_info = item.storage_info;

                    let storage_wrapper_info = storage_info.storage_wrapper_info;
                    let icon = 'fa fa-th-large';
                    if (storage_wrapper_info){
                        icon = storage_wrapper_info.icon;
                    }
                    html += '<i class="'+icon+'"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/sp_storages/' + storage_info.id + '/view">' + storage_info.name + '</a></span> ';
                    let assets_count = '0';
                    if (storage_info.hasOwnProperty('in_storage_assets_counts') && storage_info.in_storage_assets_counts.length > 0){
                        assets_count = storage_info.in_storage_assets_counts[0].total;
                    }
                    html += '<span class="item-text" style="padding-left: 5px;">' + assets_count + ' Assets</span>';

                    html += '</p>';
                    let storage_status_text = 'Free'; // Homeless?
                    let storage_status_class = 'status-green storage-location-free';
                    if (storage_info.hasOwnProperty('location_status')){
                        if (storage_info.location_status === 1){
                            if (item.hasOwnProperty('space_assigned_at') && item.space_assigned_at){
                                storage_status_text = 'Assigned on ' + Vue.options.filters.moment(item.space_assigned_at, 'DD/MM/YYYY h:mm:ss a');
                            }else{
                                storage_status_text = 'Assigned';
                            }
                            storage_status_class = 'status-orange storage-location-assigned';
                        }else if (storage_info.location_status === 2){
                            storage_status_text = 'Archived';
                            storage_status_class = 'status-grey storage-location-archived';
                        }

                    }
                    html += '<p class="object-title-detail"><i class="fa fa-circle '+storage_status_class+'"></i>' + storage_status_text + '</p>';

                }else{
                    html += '<i class="fa fa-times status-red"></i> <span class="item-header">Location is Empty</span></p>';
                }
            return html;
        }
    },

    customer: {
        key: 'customer',
        label: "Customer",
        class: "col-lg-4 war_customer",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {

            let customer_name = '';
            let storage_assignments = '';
            if (item.hasOwnProperty('storage_info') && item.storage_info){
                let storage_info = item.storage_info;
                if (storage_info){
                    let customer_info = storage_info.customer_info;
                    if (customer_info){
                        customer_name = '<i class="fa fa-star"></i> <span class="item-header"><a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a></span> ';
                        if (customer_info.hasOwnProperty('assignments_info_count') && customer_info.assignments_info_count){
                            storage_assignments = '<i class="fa fa-circle status-green"></i> ' + customer_info.assignments_info_count + ' Storage Assignments';
                        }else{
                            storage_assignments = '<i class="fa fa-circle status-grey"></i> No Storage Assignments';
                        }
                    }else{
                        customer_name = '<i class="fas fa-times status-red"></i> <span class="item-header">Storage Not Assigned</span>';
                        storage_assignments = '<a href="#" class="inline-action assign-storage-to-customer" data-space_id="' + storage_info.id + '">Assign Storage</a>';
                    }
                }
            }

            let html = '<p class="object-title-list">';
            html += customer_name;
            html += '</p><p class="object-title-detail">'+storage_assignments+'</p>';
            return html;
        }
    }
};

