<template>
    <div class="row">
        <b-form-group
        v-for="(required_field, key) in required_fields"
        v-if="required_field.type !== 'hidden_text'"
        :key="required_field.key"
        :label="required_field.label"
        :label-for="required_field.key"
        :class="required_field.required_class || 'col-sm-12'"
        >

            <template #label>
                <div v-if="required_field.hasOwnProperty('html_label') && required_field.html_label" v-html="required_field.html_label"></div>
                <div v-else>{{ required_field.label }}</div>
            </template>

            <date-picker
                v-if="required_field.type === 'date'"
                v-model="addForm[required_field.key]"
                lang="en" :first-day-of-week="1"
                format="DD-MM-YYYY"
                value-type="DD-MM-YYYY"
                :placeholder="required_field.label"/>

            <date-picker
                v-else-if="required_field.type === 'datetime'"
                v-model="addForm[required_field.key]"
                lang="en" :first-day-of-week="1"
                type="datetime"
                format="DD-MM-YYYY HH:mm"
                value-type="DD-MM-YYYY HH:mm"
                :placeholder="required_field.label"/>

            <div class="st-radio-row"
                 v-else-if="required_field.type === 'radio-select'">
                <div v-for="(option, index) in required_field.options"
                     :key="index"
                     class="col-3">
                    <div class="cm-radio-btn" :class="'mt-1 '+required_field.key+'-' + option.id">
                        <label>
                            <input
                                v-model="addForm[required_field.key]"
                                :key="index"
                                :id="'_'+index"
                                :name="required_field.key"
                                :value="option"
                                @input="selectedRadioButton(required_field.key, option, $event)"
                                type="radio"/>
                            <div class="st-radio-box">
                                <span></span>
                                <p>{{ option.name | titleize }}</p>
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <multiselect
                :ref="'list_reference-' + [required_field.key]"
                v-else-if="['single-select', 'multi-select', 'toggle-single-select'].includes(required_field.type)"
                v-model="addForm[required_field.key]"
                track-by="id"
                label="name"
                :custom-label="required_field.hasOwnProperty('custom_label') ? required_field.custom_label : CustomMSLabel"
                :loading="required_field.hasOwnProperty('listLoading') ? required_field.listLoading : false"
                :multiple="required_field.type === 'multi-select'"
                :placeholder="required_field.label"
                @input="changedSelectValue(required_field.key, $event)"
                :disabled="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                class="mySingleSelect"
                :options="required_field.options"
                :allow-empty="required_field.type === 'multi-select'"
                deselect-label=""
                :options-limit="100"
                @search-change="updateListLength(required_field.key)"
            >

                <template v-if="required_field.hasOwnProperty('custom_html_slot') && required_field.hasOwnProperty('custom_label') && required_field.custom_html_slot" v-slot:singleLabel="{ option }">
                    <div v-html="required_field.custom_label(option)"></div>
                </template>
                <template v-if="required_field.hasOwnProperty('custom_html_slot') && required_field.hasOwnProperty('custom_label') && required_field.custom_html_slot" v-slot:option="{ option }">
                    <div v-html="required_field.custom_label(option)"></div>
                </template>

                <template #beforeList>
                    <div class="multiselect__option multiselect__option--group d-flex fixed-item" v-if="!required_field.hasOwnProperty('no_limit')">
                        <p v-if="filterLength[required_field.key] !== undefined">Showing {{ filterLength[required_field.key] }} of {{ required_field.options.length }} Items</p>
                        <p v-else-if="required_field.options.length > 100">Showing 100 of {{ required_field.options.length }} Items</p>
                        <p v-else>Showing {{ required_field.options.length }} Items</p>
                    </div>
                </template>

            </multiselect>

            <div v-if="required_field.type === 'change_images'" class="st-drop-file-block">
                <div class="st-drop-file-box">
                    <vue-dropzone id="imagesgallery"
                                  ref="myVueDropzone"
                                  :use-custom-slot="true"
                                  :include-styling="false"
                                  :options="characterDropzoneOptions"
                                  @vdropzone-thumbnail="plain_thumbnail"
                                  @vdropzone-file-added="fileAdded"
                                  @vdropzone-removed-file="fileRemoved"
                                  @vdropzone-max-files-exceeded="maxFilesExceeded"
                    >
                        <div v-show="addForm.filled_asset_images.length === 0" class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <div class="st-upload-title">
                                <h3>Drop files here or click to upload</h3>
                                <p>... or click to select a file from your computer</p>
                            </div>
                        </div>
                    </vue-dropzone>
                    <b-button
                        @click="$refs.myVueDropzone[0].$el.click();"
                        class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                    >Add New Image
                    </b-button>
                </div>
            </div>

            <b-form-input
                v-else-if="['text', 'number'].includes(required_field.type)"
                v-model="addForm[required_field.key]"
                :type="required_field.type"
                :disabled="required_field.hasOwnProperty('is_readonly') ? required_field.is_readonly : false"
                :id="required_field.key"
                :placeholder="required_field.label"
                autocomplete="none"
            ></b-form-input>

            <div class="st--common--box" v-else-if="required_field.type === 'textarea'">
                <div class="common--input-filed">
                    <textarea v-model="addForm[required_field.key]" :id="required_field.key"></textarea>
                </div>
            </div>
            <div class="st--common--box" v-else-if="required_field.type === 'icon'">
                <div class="common--input-filed">
                    <i :class="addForm[required_field.key]" class="font-size-20"></i>
                </div>
            </div>
            <div class="st--common--box" v-else-if="required_field.type === 'single-select-label'">
                <div class="common--input-filed">
                    <span>{{ addForm[required_field.key] ? addForm[required_field.key].name : ''}}</span>
                </div>
            </div>

            <div v-if="['single-select-label'].includes(required_field.type)"><a href="#" class="inline-action change-select-field" @click="toggleType(required_field)">{{ required_field.hasOwnProperty('action_text') ? required_field.action_text : 'Change' }}</a></div>

            <small v-if="required_field.hasOwnProperty('has_note') && required_field.has_note !== ''" class="form-text text-muted default-value-block text-left"> {{ required_field.has_note }}</small>
            <div v-if="required_field.hasOwnProperty('has_html_note') && required_field.has_html_note !== ''" class="form-text text-muted default-value-block custom-field-html-note text-left" v-html="required_field.has_html_note"></div>


            <small v-if="addForm.has_errors && required_field.is_required && !addForm[required_field.key]">
                This Field is required
            </small>

    </b-form-group>
    </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";

export default {
    name: 'ChangeCommonFields',
    components: {DatePicker},
    mixins: [multiSelect, dropzoneMixin],
    props: {
        addForm: {},
        required_fields: {},
        filterChangesOfCharacter: {}
    },
    data(){
        return {
            filterLength: [],
            searchKeywords: [],
            characterDropzoneOptions: {
                url: "required_url",
                acceptedFiles: "image/*",
                thumbnailWidth: 160,
                thumbnailHeight: 160,
                maxFilesize: 10, // 10MB
                manuallyAddFile: true,
                autoProcessQueue: false,
                maxFiles: 100,
                previewTemplate: this.character_template()
            },
        }
    },
    created() {

        if (this.required_fields.hasOwnProperty('part_ids')){
            this.required_fields.part_ids.custom_label = this.PartDetailsItem;
        }

        if (this.required_fields.hasOwnProperty('storage_wrapper_id')){
            this.required_fields.storage_wrapper_id.custom_html_slot = true;
            this.required_fields.storage_wrapper_id.custom_label = function (storage_wrapper_info){
                return '<i class="'+storage_wrapper_info.icon+'"></i> ' + storage_wrapper_info.name;
            };
        }
        if (this.required_fields.hasOwnProperty('category_id')){
            this.required_fields.category_id.custom_html_slot = true;
            this.required_fields.category_id.custom_label = this.CustomNameOrDesc;
        }

        if (this.required_fields.hasOwnProperty('character_id')){
            this.required_fields.character_id.custom_label = this.CustomCharacterName;
        }

        if (this.required_fields.hasOwnProperty('change_id')){
            this.required_fields.change_id.custom_label = this.CustomChangeName;
        }

        if (this.required_fields.hasOwnProperty('shoot_day_id')){
            this.required_fields.shoot_day_id.custom_label = this.CustomShootDayName;
        }

    },
    methods:{
        updateListLength(required_field){
            if (this.$refs['list_reference-' + required_field]){
                this.filterLength[required_field] = this.$refs['list_reference-' + required_field][0].filteredOptions.length;
                this.searchKeywords[required_field] = this.$refs['list_reference-' + required_field][0].search;
            }

        },
        toggleType(required_field){
            if (required_field.type === 'toggle-single-select'){
                required_field.type = 'single-select-label';
            }else{
                required_field.type = 'toggle-single-select';
            }
        },
        character_template: function () {
            return `<div class="dz-preview dz-file-preview col-3">
                        <div class="dz-image">
                            <div data-dz-thumbnail-bg></div>
                        </div>
                        <div class="dz-details">
                            <div class="dz-image_title">
                                <div class="common--input-filed">
                                    <input type="text" data-dz-image_title name="image_title" placeholder="Image Title" />
                                </div>
                            </div>
                            <div class="dz-image_description">
                                <div class="common--input-filed">
                                    <textarea data-dz-image_description name="image_description" placeholder="Image Description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="dz-progress"><span role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" class="dz-upload progress-bar progress-bar-striped progress-bar-animated" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message"><span data-dz-errormessage></span></div>
                        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                        <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                        <a class="dz-remove btn btn btn-sm mt-1 btn-block btn-outline-danger" data-dz-remove="">Remove file</a>
                    </div>`;
        },
        selectedRadioButton(field, option, event){

        },
        changedSelectValue(field, event) {
            let option = this.addForm[field];
            if (field === 'character_id' && option && this.filterChangesOfCharacter){
                this.filterChangesOfCharacter(option);
            }
        },
    }
}
</script>
