<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="this.item_type === 'selected_customer'">
            <b-alert show variant="danger">Should Show assets of customer with id #{{this.item_id}}</b-alert>
        </div>

        <div v-if="this.item_id === '' && ['customer', 'sp'].includes(this.item_type)">
            <div>
                <b-alert show variant="danger">There's nothing to show, Please "
                    <a href="/sp/switch"
                       v-if="this.item_type === 'sp'">
                        Select Service Provider
                    </a>
                    <a href="/customer/switch"
                       v-else-if="this.item_type === 'customer'">
                        Select Customer
                    </a>
                    " to proceed</b-alert>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row mb-2">
                            <div class="col-sm-2">
                                <h4 class="card-title">Current Layout: {{this.layoutOptions[this.selectedLayoutIndex].title | titleize }}</h4>
                            </div>

                            <div class="col-sm-6">

                                <div class="btn-group btn-group-sm btn-group-toggle mt-2 mt-xl-0" data-toggle="buttons">
                                    <label
                                        v-for="(option, index) in this.layoutOptions"
                                        class="btn btn-outline-secondary"
                                        :class=" { 'af-accent': index === selectedLayoutIndex,
                                                   'button-secondary-hover': index !== selectedLayoutIndex
                                                }"
                                    >
                                        <input
                                            v-model="selectedLayoutIndex"
                                            id="selectedLayout"
                                            :value="index"
                                            v-on:click="setSelectedLayout(index)"
                                            type="radio"
                                            name="selectedLayout"
                                            :key="index" />
                                        <i :class="'bx ' + option.icon + ' font-size-16 align-middle mr-2'"></i> {{option.title}}
                                    </label>
                                </div>

                            </div>
                            <div class="col-sm-4">
                                <div class="text-sm-right">
                                    <div class="btn-group">
                                        <b-dropdown dropright variant="success">

                                            <template slot="button-content">
                                                <i class="mdi mdi-chevron-left"></i>
                                                Actions
                                            </template>

                                            <b-dropdown-item href="./assets/new">
                                                <i class="mdi mdi-plus mr-1"></i> Add New Asset
                                            </b-dropdown-item>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-item href="./assets/csv_import">
                                                <i class="bx bx-spreadsheet"></i> CSV Import
                                            </b-dropdown-item>

                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                            <!-- end col-->
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-12">
                <div v-if="this.selectedLayoutIndex === 1">
                    <div class="grid-layout">
                        <h4 class="card-title">Grid Layout</h4>
                        <grid-layout :table-data="this.tableData" :fields="fields"></grid-layout>
                    </div>
                </div>
                <div v-else class="card">
                    <div class="card-body">
                        <div v-if="this.selectedLayoutIndex === 0" class="table-layout">
                            <h4 class="card-title">Table Layout</h4>
                            <table-layout
                                :table-data="this.tableData"
                                :fields="fields"
                                :item_type="item_type"
                                @on-item-deleted="getList"
                                @on-bulk-action="getList"
                            ></table-layout>
                        </div>


                        <div v-else-if="this.selectedLayoutIndex === 2" class="csv-layout">
                            <h4 class="card-title">CSV Layout</h4>
                            <csv-layout :table-data="this.tableData" :fields="fields"></csv-layout>
                        </div>

                        <div v-else-if="this.selectedLayoutIndex === 3" class="pdf-layout">
                            <h4 class="card-title">PDF Layout</h4>
                            <pdf-layout :table-data="this.tableData" :fields="fields"></pdf-layout>
                        </div>

                        <div v-else-if="this.selectedLayoutIndex === 4" class="print-layout">
                            <h4 class="card-title">Print Layout</h4>
                            <print-layout :table-data="this.tableData" :fields="fields"></print-layout>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

import TableLayout from "./layouts/TableLayout";
import GridLayout from "./layouts/GridLayout";
import CsvLayout from "./layouts/CSVLayout";
import PdfLayout from "./layouts/PDFLayout";
import PrintLayout from "./layouts/PrintLayout";

import UserMeta from "@/mixins/usermeta.mixin";

const assetResource = new Resource('api/assetflow/assets');
/**
 * Advanced table component
 */
export default {
    name: "AFLogList",
    mixins: [UserMeta],
    props: ['item_id', 'item_type'],
    components: {PrintLayout, PdfLayout, CsvLayout, GridLayout, TableLayout, Layout, PageHeader },
    data() {
        return {
            selectedLayoutIndex: null,
            tableData: null,
            title: "Advanced Table",
            listQuery: {
                page: 1,
                limit: 20,
            },
            items: [
                {
                    text: "Tables",
                    href: "/"
                },
                {
                    text: "Advanced",
                    active: true
                }
            ],
            fields: [
                { key: "actions", sortable: false },
                { key: "id", sortable: true },
                { key: "name", sortable: true },
                { key: "status", sortable: true },
                { key: "customer_id", sortable: true },
                { key: "parent_asset_id", sortable: true },
                { key: "bss_status", sortable: true },
                { key: "sp_id", sortable: true },
                { key: "source", sortable: true },
                { key: "source_id", sortable: true },
                { key: "user_id", sortable: true },
                { key: "stock_quantity", sortable: true },
                { key: "width", sortable: true },
                { key: "height", sortable: true },
                { key: "depth", sortable: true },
                { key: "colour", sortable: true },
                { key: "item_actions", sortable: false },
            ],
            layoutOptions: [
                {
                    title: "Table",
                    icon: "bx-layout"
                },
                {
                    title: "Grid",
                    icon: "bx-grid-alt"
                },
                {
                    title: "CSV",
                    icon: "bx-spreadsheet"
                },
                {
                    title: "PDF",
                    icon: "bxs-file-pdf"
                },
                {

                    title: "Print",
                    icon: "bx-printer"
                },
            ]
        };
    },
    created() {
        this.selectedLayoutIndex = 2;

        var preferredLayout = window.USER_INFO.user_meta.preferred_assets_layout;
        if (preferredLayout && /^\d+$/.test(preferredLayout)){
            this.selectedLayoutIndex = parseInt(preferredLayout);
        }

        this.getList();
    },
    methods: {
        async getList(selected_item=null) {
            this.listLoading = true;

            this.handleQueryItems();

            const { data } = await assetResource.list(this.listQuery);
            if (Object.keys(data).includes('data')){
                this.tableData = data.data.items;
                this.total = data.meta.total;
            }else{
                console.log('Response Error');
            }
            this.listLoading = false;
        },
        handleQueryItems() {
            if (this.item_type === 'bss'){
                this.listQuery.bss_status = true;
            }else{
                if (this.item_type === 'sp'){
                    this.listQuery.warehouse_id = this.item_id !== '' ? this.item_id : "0";
                }
                if (this.item_type === 'customer') {
                    this.listQuery.customer_id = this.item_id !== '' ? this.item_id : "0";
                }
            }
        },

        setSelectedLayout(layoutIndex){
            this.selectedLayoutIndex = layoutIndex;
            let message = "Saved New Layout {"+this.layoutOptions[layoutIndex].title+"} Successfully";
            this.set_usermeta('preferred_assets_layout', layoutIndex, message);
        }
    }
};
</script>


