import {getLocationDetails} from "./SpLocationSharedFunctions";
import {getSpStorageInfoBlock} from "./getSpStorageInfoBlock";

const FirstPath = window.location.pathname.split('/')[1];

export const ManagedStorageKeysList = {
    uid: {
        key: 'uid',
        label: "Managed Storage ID",
        class: "col-lg-1 mst_uid",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {

            let status_class = 'action-missing';
            if (item.status === 1){
                status_class = 'action-populated';
            }

            let html = '<a class="text-dark" href="/' + FirstPath + '/managed_storages/' + item.id + '/view"><div class="managed_storages-status '+status_class+'"><span></span><span class="font-size-12">'+item[key]+'</span></div></a>';
            return html;
        }
    },
    name:{
        key: 'name',
        label: "Name",
        class: "col-lg mst_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let item_info = item.item_info;
            if (item_info){
                let object_item_type = item.hasOwnProperty('object_item_type') ? item.object_item_type : item.item_type;
                if (object_item_type === 0){
                    // asset
                    return '<a class="text-dark" href="/' + FirstPath + '/assets/' + item_info.id + '/view">' + Vue.options.filters.titleize(item_info.name) + ' (ASS-' + item_info.uid + ')</a>';
                }else if (object_item_type === 1){
                    // asset group
                    return '<a class="text-dark" href="/' + FirstPath + '/asset_groups/' + item_info.id + '/view">' + Vue.options.filters.titleize(item_info.name) + ' (GRP-' + item_info.uid + ')</a>';
                }
            }
            return '';
        }
    },
    customer_info: {
        key: 'customer_info',
        label: "Customer",
        class: "col-lg-1 mst_customer_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let customer_info = item.customer_info;
            if (customer_info){
                return '<a class="text-dark" href="/' + FirstPath + '/customers/' + customer_info.id + '/view">' + Vue.options.filters.titleize(customer_info.name) + '</a>';
            }
            return '';
        }
    },
    sp_info: {
        key: 'sp_info',
        label: "Service Provider",
        class: "col-lg-1 mst_sp_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let sp_info = item.sp_info;
            if (sp_info){
                return '<a class="text-dark" href="/' + FirstPath + '/service_providers/' + sp_info.id + '/view">' + Vue.options.filters.titleize(sp_info.name) + '</a>';
            }
            return '';
        }
    },
    parent_order_line: {
        key: 'parent_order_line',
        label: "MTS ID",
        class: "col-lg-1 mst_parent_order",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let order_line_info = item.order_line_info;
            if (order_line_info){
                return '<a class="text-dark" href="/' + FirstPath + '/order_lines/' + order_line_info.id + '/view">MTS-' + order_line_info.uid + '</a>';
            }
            return '';
        }
    },
    total_quantity: {
        key: 'total_quantity',
        label: "Qty",
        class: "col-lg-1 mst_total_quantity",
        show_on_mobile: false,
        case: ''
    },
    status: {
        key: 'status',
        label: "Status",
        class: "col-lg-1 mst_status",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            let status_list = [
                {
                    name: 'Dispatched',
                    class: 'status-background-grey',
                },
                {
                    name: 'Intake In Progress',
                    class: 'status-background-orange',
                },
                {
                    name: 'In Storage',
                    class: 'status-background-green',
                },
            ];
            return '<span class="button_size ' + (status_list[item.status].class) + '">' + status_list[item.status].name + '</span>';
        }
    },
    intake_profile: {
        key: 'intake_profile',
        label: "Intake Profile",
        class: "col-lg-1 mst_intake_profile",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let intake_profile_info = item.intake_profile_info;
            if (intake_profile_info){
                return '<a class="text-dark" href="/' + FirstPath + '/intake_profiles/' + intake_profile_info.id + '/view">' + intake_profile_info.uid + '</a>';
            }
            return '';
        }
    },

    movement: {
        key: 'movement',
        label: "Movement",
        class: "col-lg-1 mst_movement",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let warehousing_movement_info = item.latest_warehousing_movement_info;
            if (warehousing_movement_info){
                return '<a class="text-dark" href="/' + FirstPath + '/sp_movements?s=' + warehousing_movement_info.uid + '">' + warehousing_movement_info.uid + '</a>';
            }
            return '';
        }
    },
    latest_location: {
        key: 'latest_location',
        label: "Latest Location",
        class: "col-lg-1 mst_latest_location",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            let latest_warehousing_movement_info = item.latest_warehousing_movement_info;
            if (latest_warehousing_movement_info){

                let item_info = latest_warehousing_movement_info.target_info;
                switch (latest_warehousing_movement_info.movement_target_type) {
                    case 0:
                        return 'Homeless';
                    case 1:
                        return getLocationDetails(item_info);
                    case 2:
                        return 'Delivery';
                    case 3:
                        return 'Collection';
                    case 4:
                        return getSpStorageInfoBlock(item_info, false);
                    case 5:
                        return 'Order';
                    case 6:
                        return 'Recycle';
                    case 7:
                        return 'Archived';
                    case 8:
                        return 'User Defined';
                    default:
                        return '-';
                }
            }
            return '';
        }
    }
};
