<template>

    <div class="row" v-if="item_info">
        <NewCustomGenericBlock
            block_title=""
            box_colour_v="-"
            columns_count_withclasses="12">
            <template v-slot:all_content>
                <div :class="used_classes">
                    <add-asset-to-order
                        ref="add-ms-to-order"
                        :item_type="item_info.current_page_type"
                        :received_modal_id="'add-ms-to-order' + box_unique_id"
                        :object_type="object_type"
                        @on-modal-save="refreshPage('add-ms-to-order' + box_unique_id)"
                    />

                    <EditManagedStorageAsset
                        ref="edit-managed_storage-asset"
                        :received_modal_id="'managed_storage-asset' + box_unique_id"
                        modal_title="Edit Asset"
                        @on-new-item-adding="refreshPage('managed_storage-asset' + box_unique_id)"
                    >
                    </EditManagedStorageAsset>


                    <UpdateMSStatus
                        ref="update_ms_status"
                        :received_modal_id="'update_ms_status' + box_unique_id"
                        modal_title="Update Status"
                        @on-new-item-adding="refreshPage('update_ms_status' + box_unique_id)"
                    >
                    </UpdateMSStatus>
                    <AddMSFile
                        ref="add_ms_files"
                        :received_modal_id="'add_ms_files' + box_unique_id"
                        modal_title="Add Files"
                        @on-new-item-adding="refreshPage('add_ms_files' + box_unique_id)"
                    >
                    </AddMSFile>

                    <UpdateMSStocks
                        ref="update_ms_stocks"
                        :received_modal_id="'update_ms_stocks' + box_unique_id"
                        modal_title="Update Stocks"
                        @on-new-item-adding="refreshPage('update_ms_stocks' + box_unique_id)"
                    >
                    </UpdateMSStocks>


                    <UpdateImagesModal
                        ref="edit-managed_storage-images"
                        :modal_id="'managed_storage-images' + box_unique_id"
                        :received_item_info="null"
                        :is_list="true"
                        :item_type="object_type"
                        @on-cost-updates="refreshPage('change_asset_storage' + box_unique_id)" />

                    <ChangeAssetStorage
                        ref="change_asset_storage"
                        :modal_id="'change_asset_storage' + box_unique_id"
                        modal_title="Change Asset Storage"
                        :item_type="item_info.current_page_type"
                        :with_movement_info="true"
                        @on-storage-update="refreshStorageAndLocation"
                    />


                    <div class="popup-gallery">
                        <vue-easy-lightbox
                            :visible="visibleCaption"
                            :imgs="captionimgs"
                            :index="index"
                            @hide="handleCaptionHide">
                        </vue-easy-lightbox>
                    </div>

                    <div v-if="has_order_actions_permissions"
                         class="cm_product_more_btn">
                        <div class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                            <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-horizontal"></i>
                            </button>
                            <ManagedObjectActionsList :item="item_info" index="0" :item_type="item_info.current_page_type" :object_type="object_type" :perform-action="performAction"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="cm_list_title_content">
                                <div class="cm_list_view_title">
                                    <h3 v-html="getHtmlTitle(item_info)"></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="cm_usage icons_list">
                                <ManagedStorageIconList
                                    v-if="item_info.hasOwnProperty('item_info') && item_info.item_info"
                                    class="mr-5 text-right"
                                    :item_info="item_info"
                                    :item_type="item_info.current_page_type"
                                    :m-s-perform-action="performAction"/>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <div class="cm_product_content">
                                <div class="cm_product_status_grid">
                                    <div class="cm_list_view_title" v-html="getManagedObjectStatus(item_info)"></div>
                                    <div class="cm_status_ul">
                                        <ul v-if="[1, 2].includes(item_info.status)">
                                            <li>
                                                <p>Total <span class="total_box_bg">{{ item_info.total_quantity }}</span></p>
                                            </li>
                                            <li>
                                                <p>Available <span class="available_box_bg">{{ item_info.available_quantity }}</span></p>
                                            </li>
                                            <li>
                                                <p>Reserved <span class="reserved_box_bg">{{ item_info.reserved_quantity }}</span></p>
                                            </li>
                                        </ul>
                                        <ul v-else>
                                            <li>
                                                <p>Dispatched <span class="total_box_bg">{{ item_info.dispatched_quantity }}</span></p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <ManagedStorageParents v-if="item_info.hasOwnProperty('item_info') && item_info.item_info" :item_info="item_info.item_info" :item_type="item_info.current_page_type"/>

                    <ManagedStorageFiles
                        v-if="item_info.hasOwnProperty('item_info') &&
                        item_info.item_info &&
                        item_info.item_info.hasOwnProperty('files_info') &&
                        item_info.item_info.files_info"
                        :item_info="item_info.item_info"
                        object_type="managed_storages"
                        :ms_id="item_info.id"
                        :item_type="item_info.current_page_type"/>

                    <div class="row">
                        <div class="col-12">
                            <ManagedStorageEntityBlock
                                :asset="item_info"
                                :get-location-block="getLocationBlock(item_info)"
                                :get-s-p-location-block="getSPLocationBlock(item_info)"
                                :get-storage-block="getStorageBlock(item_info)"
                                :item_type="item_info.current_page_type"/>
                        </div>
                    </div>
                    <div class="row" v-if="item_info.status === 2 && hasPermission('can_add_storage_order')">

                        <add-asset-to-order
                            ref="add-ms-to-order"
                            :item_type="item_info.current_page_type"
                            :received_modal_id="'add-ms-to-order' + box_unique_id"
                            :object_type="object_type"
                            @on-modal-save="refreshPage('add-ms-to-order' + box_unique_id)"
                        />

                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="cm_list_inner_common">
                                        <span>In Storage Ordering:</span>
                                        <p v-if="item_info.available_quantity === 0" class="common_col_desc no-available-stock">
                                            No Available Stock
                                        </p>
                                        <p v-else class="common_col_desc available-stock">
                                            <i class="bx bx-detail"></i>
                                            <span><button @click="performAction(item_info, 'addToOrder', 0, $event)" class="btn btn-link">Add To Order</button></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <MyBCarouselImages parent_class="cm_product_grid_img mt-4" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

                    <div class="row" v-if="asset_info">
                        <div class="col-12">
                            <div class=" cm_asset_view_tag">
                                <div class="tag-wrap list-show">
                                    <h3 class="cm_asset_tag_title">Tags</h3>
                                    <ul v-if="asset_info.tags_info">
                                        <li v-for="tag in asset_info.tags_info" class="d-inline-block">
                                            <span><a :href="'/' + item_info.current_page_type + '/tags/' + tag.id + '/view'">{{ tag.name }}</a></span>
                                        </li>
                                    </ul>
                                </div>

                                <transition name="fade" mode="out-in">
                                    <div v-if="isLoadingTags" key="1">
                                        <span class="loading_tags"><i class="fas fa-tag"></i> Loading Tags...</span>
                                    </div>
                                    <tags-manager
                                        v-else
                                        key="2"
                                        class="d-inline-block"
                                        :ref="'tags-manager2' + tag_box_unique_id"
                                        :product="asset_info"
                                        :all_tags_list="all_tags_list"
                                        :asset_key="0"
                                        @on-add-tag="myAddTag"
                                        @on-remove-tag="myRemoveTag"
                                    ></tags-manager>
                                </transition>

                            </div>
                        </div>
                    </div>

                </div>
            </template>
        </NewCustomGenericBlock>
    </div>

</template>
<script>

import AssetActions from "@/mixins/assetActions.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import TagsManagerMixin from "@/mixins/TagsManagerMixin.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "../widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";

import ManagedStorageShared from "@/mixins/ManagedStorageShared.mixin";
import ManagedStorageParents from "../../customers/managed_storages/ManagedStorageParents";
import ManagedObjectActionsList from "../../customers/managed_storages/ManagedObjectActionsList";
import AddAssetToOrder from "../../customers/extras/AddAssetToOrder";
import EditManagedStorageAsset from "../../customers/extras/EditManagedStorageAsset";
import ManagedStorageEntityBlock from "../../customers/managed_storages/ManagedStorageEntityBlock";
import NewCustomGenericBlock from "../widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import TagsManager from "../../assets/subItems/TagsManager";
import ManagedStorageIconList from "../../customers/managed_storages/ManagedStorageIconList";
import UpdateImagesModal from "../../assets/assetForm/UpdateImagesModal";
import ChangeAssetStorage from "../../customers/managed_storages/ChangeAssetStorage";
import UpdateMSStatus from "../../customers/managed_storages/UpdateMSStatus";
import UpdateMSStocks from "../../customers/managed_storages/UpdateMSStocks";
import ManagedStorageFiles from "../../customers/managed_storages/ManagedStorageFiles";
import AddMSFile from "./AddMSFile";

export default {
    name: 'ManagedStorageRightBox',
    mixins: [ManagedStorageShared, AssetActions, TotalsAndCurrencies, TagsManagerMixin, ImageGallery],
    components: {
        AddMSFile,
        ManagedStorageFiles,
        UpdateMSStocks,
        UpdateMSStatus,
        ChangeAssetStorage,
        UpdateImagesModal,
        ManagedStorageIconList,
        TagsManager,
        NewCustomGenericBlock,
        ManagedStorageEntityBlock,
        EditManagedStorageAsset,
        AddAssetToOrder,
        ManagedObjectActionsList,
        ManagedStorageParents,
        MyBCarouselImages,
        MyBCarouselSlide,
        VueEasyLightbox
    },
    props: {
        item_info: {},
        box_unique_id: {},
        used_classes:''
    },
    computed:{
        asset_info(){
            return this.item_info.hasOwnProperty('item_info') && this.item_info.item_info;
        },
    },
    created() {
        this.tag_box_unique_id = this.box_unique_id;
    }
}
</script>

<style>
.loading_tags{
    cursor: pointer;
    border: 1px solid #d3292c;
    font-weight: 400;
    font-size: 11px;
    color: #000;
    padding: 5px 5px;
}
</style>

