<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">

            <div class="col-12" v-if="errors && errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </div>

            <div class="col-12">
                <form>

                    <div class="row">
                        <div class="col-sm-6">

                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">{{ object_name | titleize }} Setup</h4>

                                    <div class="form-group">
                                        <label for="customer_name">{{ object_name | titleize }} Name</label>
                                        <input v-model="addForm.name" id="customer_name" name="customer_name" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="phone">Initials</label>
                                        <input v-model="addForm.initials" id="initials" name="initials" type="text" class="form-control" />
                                        <small class="form-text text-muted">Leave empty to auto generate initials.</small>
                                    </div>

                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input v-model="addForm.phone" id="phone" name="phone" type="tel" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="website">Website</label>
                                        <input v-model="addForm.website" id="website" name="website" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="company_registration_no">Company Registration No</label>
                                        <input v-model="addForm.company_registration_no" id="company_registration_no" name="company_registration_no" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group">
                                        <label for="vat_registration_no">VAT Registration No</label>
                                        <input v-model="addForm.vat_registration_no" id="vat_registration_no" name="vat_registration_no" type="text" class="form-control" />
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Administration Setup</h4>

                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <div class="input-group mb-3">
                                            <input v-model="addForm.email" id="email" name="email" type="email" class="form-control" aria-describedby="check-addon" />
                                            <div class="input-group-append">
                                                <span @click="checkEmail($event)" class="btn af-btn-accent af-accent ml-0 btn-block" id="check-addon">Check Email</span>
                                            </div>
                                        </div>
                                        <small v-if="fetched_user_info && fetched_user_info.email === addForm.email"><i class="bx font-size-16 bx-check existing-user_email"></i> This user <b><a :href="fetched_user_info.user_profile_link">'{{ fetched_user_info.name }}'</a></b> already has an AF account.</small>
                                    </div>

                                    <div class="form-group" v-if="!user_status">
                                        <label for="first_name">First Name</label>
                                        <input v-model="addForm.first_name" id="first_name" name="first_name" type="text" class="form-control" />
                                    </div>

                                    <div class="form-group" v-if="!user_status">
                                        <label for="last_name">Last Name</label>
                                        <input v-model="addForm.last_name" id="last_name" name="last_name" type="text" class="form-control" />
                                    </div>


                                    <div class="form-group">
                                        <label>Demo Mode</label>
                                        <b-form-radio-group v-model="addForm.demo_only">
                                            <b-form-radio
                                                v-for="(option, key) in statuses"
                                                :key="option.value"
                                                :value="option.value"
                                                class="custom-radio mb-3"
                                            >{{ option.text | titleize }}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </div>

                                </div>
                            </div>

                            <div class="card" v-if="!is_group">
                                <div class="card-body">
                                    <h4 class="card-title">Group Setup</h4>

                                    <div class="form-group">
                                        <b-form-radio-group v-model="addForm.use_existing_group">
                                            <b-form-radio
                                                v-for="(option, key) in groupOptions"
                                                :key="option.value"
                                                :value="option.value"
                                                class="custom-radio mb-3"
                                            >{{ option.text | titleize }}
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </div>
                                    <div v-if="addForm.use_existing_group === 1" class="form-group">
                                        <label>Select Group</label>
                                        <multiselect
                                            v-model="addForm.group_id"
                                            track-by="id"
                                            label="name"
                                            class="myMultiSelect"
                                            :options="availableGroups"
                                            :loading="listLoading"
                                            @input="setSectors"
                                            placeholder="Select Group"
                                        >
                                            <span slot="noResult">No data found.</span>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Customisation</h4>
                                    <div class="form-group">
                                        <label for="sector_ids">Industry\Sector</label>
                                        <multiselect
                                            v-model="addForm.sector_ids"
                                            track-by="id"
                                            label="name"
                                            class="myMultiSelect"
                                            :options="availableSectors"
                                            :multiple="true"
                                            placeholder="Select Industry\Sector"
                                        >
                                            <span slot="noResult">No data found.</span>
                                        </multiselect>
                                    </div>

                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Appearance, Functionality & Defaults</h4>
                                    <div v-for="(item, key) in addForm.meta">
                                        <div class="form-group">
                                            <label :for="'item_' + key">{{key | titleize}}</label>

                                            <b-form-select v-if="key === 'currency'"
                                                           id="currency"
                                                           v-model="addForm.meta[key]"
                                                           :options="currencies"
                                            >
                                                <template v-slot:first>
                                                    <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                                                </template>
                                            </b-form-select>

                                            <input v-else v-model="addForm.meta[key]" :id="'item_' + key" :name="'item_' + key" :type="((key.includes('colour') || key === 'logo_background') ? 'color' : 'text')" class="form-control" />

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-sm-6">


                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title"><label for="logo">Logo</label></h4>

                                    <div class="form-group">
                                        <div class="col-sm-6">
                                            <vue-dropzone id="logo" ref="myVueDropzone" :use-custom-slot="true"
                                                          :options="dropzoneOptions"
                                                          @vdropzone-file-added="fileAdded"
                                                          @vdropzone-max-files-exceeded="maxFilesExceeded"
                                            >
                                                <div class="dropzone-custom-content">
                                                    <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                            </vue-dropzone>
                                            <b-button
                                                v-if="action === 'edit'"
                                                @click="$refs.myVueDropzone.$el.click();"
                                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                                            >Select New Logo</b-button>
                                            <b-button
                                                @click="$refs.myVueDropzone.removeAllFiles();"
                                                variant="outline-danger"
                                                class="btn btn-sm mt-1 btn-block"
                                            >Remove Selected</b-button>
                                        </div>
                                        <div class="col-sm-6">
                                            <div role="alert" class="module-alert alert alert-info" style="margin-bottom: 0px; width: 100%; margin-top: 8px;">
                                                <p><strong>Logo Dimensions</strong></p>
                                                <br>
                                                <p>Logos should be uploaded in the ratio 3:1.</p>
                                                <br>
                                                <p>For example, 3000 pixels wide by 1000 pixels high. Logos will be scaled automatically. Logos outside these dimensions maybe truncated.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <a v-on:click="addNew($event)" class="btn btn-primary">Save</a>
                    <a href="./" class="btn btn-secondary">Cancel</a>

                </form>
            </div>
        </div>
    </Layout>
</template>


<script>

import vue2Dropzone from "vue2-dropzone";
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect';

import {DropZoneOptions} from "../form_data/DropZoneOptions";

import {Currencies} from "../form_data/Currencies";
import {AvailableSectors} from "../form_data/AvailableSectors";

import AdministrationShared from "@/mixins/AdministrationShared.mixin";


export default {
        props:['item_id', 'item_type', 'action', 'is_group'],
        name: 'NewCustomer',
        mixins: [AdministrationShared],
        components: {
            vueDropzone: vue2Dropzone,
            Layout,
            PageHeader,
            Multiselect
        },
        data() {
            return {
                title: "Add New Customer",
                items: [
                    {
                        text: "Customers",
                        href: "/"
                    },
                    {
                        text: "Add Customer",
                        active: true
                    }
                ],
                statuses:[
                    {
                        value: 0,
                        text: "Disabled",
                    },
                    {
                        value: 1,
                        text: "Enabled",
                    }
                ],
                groupOptions:[
                    {
                        value: 0,
                        text: "Create New Group",
                    },
                    {
                        value: 1,
                        text: "Select existing Group",
                    }
                ],
                fetched_customer_info:null,
                addForm:{
                    initials: "",
                    name: "",
                    logo: "",
                    status: 1,
                    email: "",
                    first_name: "",
                    last_name: "",
                    phone: "",
                    website: "",
                    company_registration_no: "",
                    vat_registration_no: "",
                    sector_ids: [],
                    currency: '',
                    group_id:null,
                    use_existing_group: 0,
                    demo_only: 1,
                    meta:{ currency: "gbp" }
                },
                dropzoneOptions: DropZoneOptions,
                isLoadingItem: false,
                shall_select_customers: false,
                currencies: Currencies,
                availableSectors: AvailableSectors,
                availableGroups: [],
                listLoading: false
            };
        },
        computed: {
            object_name() {
                return this.is_group ? 'group' : 'customer';
            },
        },
        created() {
            this.getGroupsList()
                .finally(() => {
                    if(this.action === 'edit' && this.item_id !== ''){
                        this.fetchInfoOf();
                        this.title = this.title.replace('Add New', 'Edit').replace('Customer', this.object_name);
                        this.items[1].text = this.items[1].text.replace('Add', 'Edit').replace('Customer', this.object_name);
                    }
                });


        },
        methods: {
            setSectors(){
                this.setSelectedSectors(this.addForm.group_id ? this.addForm.group_id.sector_ids : []);
            },
            async getGroupsList() {
                return await new Promise( (resolve, reject) => {
                    this.listLoading = true;
                    axios({
                        method:'get',
                        url:'/api/assetflow/groups',
                        baseURL: '/',
                    }).then(response => {

                        this.availableGroups = response.data.data.items;
                        resolve(response.data);
                    }).catch(error => {
                        reject(new Error('Response Error!'));
                    }).finally(() => {
                        this.listLoading = false;
                    });
                });

            },

            fileAdded(file) {
                this.addForm.logo = file;
                this.saveFileToS3(file, null, 'logo', 'customer');
            },
            maxFilesExceeded(file){
                this.$refs.myVueDropzone.removeAllFiles();
                this.$refs.myVueDropzone.addFile(file);
            },
            setSelectedSectors(sector_ids) {
                if (sector_ids){
                    this.addForm.sector_ids = this.availableSectors.filter(function (e) {
                        return sector_ids.includes(e.id);
                    });
                }
            },
            fetchInfoOf() {
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/' + this.object_name + 's/' + this.item_id,
                    baseURL: '/',
                }).then(response => {
                    let fetched_customer_info = response.data;
                    this.fetched_customer_info = fetched_customer_info;
                    this.addForm.initials = fetched_customer_info.initials;
                    this.addForm.name = fetched_customer_info.name;
                    this.addForm.logo = fetched_customer_info.logo;
                    this.addForm.demo_only = fetched_customer_info.demo_only;

                    if (fetched_customer_info.group_id && this.availableGroups.length !== 0){

                        let foundItem = _.find(this.availableGroups, function(o) { return o.id === fetched_customer_info.group_id; });
                        if (foundItem){
                            this.addForm.group_id = foundItem;
                        }

                        this.addForm.use_existing_group = 1;
                    }else{
                        this.addForm.use_existing_group = 0;
                    }


                    if (fetched_customer_info.logo){
                        this.addForm.logo = fetched_customer_info.logo;

                        var file = { size: fetched_customer_info.image_size, name: fetched_customer_info.name, type: "image/png" };
                        var url = this.addForm.logo;
                        if (fetched_customer_info.image_size){
                            this.$refs.myVueDropzone.manuallyAddFile(file, url);
                        }
                    }
                    this.addForm.email = fetched_customer_info.admin_email;
                    this.addForm.phone = fetched_customer_info.phone;
                    this.addForm.website = fetched_customer_info.website;
                    this.addForm.company_registration_no = fetched_customer_info.company_registration_no;
                    this.addForm.vat_registration_no = fetched_customer_info.vat_registration_no;

                    this.setSelectedSectors(fetched_customer_info.sector_ids);

                    this.addForm.meta = fetched_customer_info.meta;

                    $('#check-addon').click();
                    this.isLoadingItem = false;

                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            },

            addNew(event) {
                if (event && !this.isSendingRequest) {
                    this.isSendingRequest = true;
                    let sector_ids_array = this.addForm.sector_ids.map(function(e) { return e.id; });

                    if (!this.addForm.use_existing_group){
                        this.addForm.group_id = null;
                    }

                    axios({
                        method:((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                        url:'/api/assetflow/' + this.object_name + 's' + ((this.action === 'edit' && this.item_id !== '') ? ('/'+this.item_id) : ''),
                        baseURL: '/',
                        data: {
                            customer_id: this.item_id,
                            initials: this.addForm.initials,
                            customer_name: this.addForm.name,
                            add_to_bss: this.addForm.add_to_bss,
                            item_type: this.item_type,
                            status: 1,
                            contact: this.addForm.first_name + ' ' + this.addForm.last_name,
                            admin_email: this.addForm.email,
                            first_name: this.addForm.first_name,
                            last_name: this.addForm.last_name,
                            demo_only: this.addForm.demo_only,
                            phone: this.addForm.phone,
                            website: this.addForm.website,
                            company_registration_no: this.addForm.company_registration_no,
                            vat_registration_no: this.addForm.vat_registration_no,
                            sector_ids: sector_ids_array,
                            currency: this.addForm.meta.currency,
                            meta: this.addForm.meta,
                            logo: this.addForm.logo,
                            group_id: this.addForm.group_id ? this.addForm.group_id.id : ''
                        },
                    }).then(response => {
                        this.isSendingRequest = false;
                        let status = response.data.data.status;
                        let msg = response.data.data.msg;
                        if (status) {
                            if (this.object_name === 'group'){
                                window.location.href = '/' + this.item_type + '/groups';
                            }else{
                                let added_item = response.data.data.items;
                                let item_id = added_item.id;
                                window.location.href = '/' + this.item_type + '/' + this.object_name +'s/' + item_id + '/view';
                            }
                        }else{
                            this.presentToast("Failed!",
                                msg,
                                'danger',
                                3000,
                                true,
                                true);
                        }
                    }).catch(error => {
                        console.log(error);
                        this.isSendingRequest = false;
                    });

                }
            }
        },

    }
</script>

<style scoped>
#logo .dz-preview .dz-progress {
    opacity: 0 !important;
}

.vue-dropzone > .dz-preview .dz-image {
    height: 160px !important;
    width: 160px !important;
}

.vue-dropzone .dz-preview .dz-details .dz-size {
    display: none
}
</style>
