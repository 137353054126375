<template>
    <div class="common-step-panel" :class="useTooltips ? 'show-tooltips' : 'use-section'">
        <component :is="current_step.id"
                   :add-form="addForm"
                   :required_fields="required_fields"
                   :my_lists_values="my_lists_values"
                   :listQuery="listQuery"
                   :class="current_step.class"
                   :item_id="item_id"
                   :asset_is_locked="asset_is_locked"
                   :asset_is_ended="asset_is_ended"
                   :asset_is_disposed="asset_is_disposed"
                   :getAssetInfoUsingKey="getAssetInfoUsingKey"
        />
    </div>
</template>
<script>

import asset_details_step from "./assetForm/BasicInfo";
import tv_film_step from "./assetForm/TVFilmInfo";
import physical_properties_step from "./assetForm/PhysicalProperties";

import location_step from "./assetForm/LocationInfo";
import structure_step from "./assetForm/StructureInfo";
import acquisition_step from "./assetForm/AcquisitionInfo";
import continuity_step from "./assetForm/ContinuityInfo";
import sustainability_step from "./assetForm/SustainabilityInfo";
import fdp_step from "./assetForm/FdpInfo";
import disposal_step from "./assetForm/DisposalInfo";


export default {
    name: 'step-panel',
    components: {
        asset_details_step,
        tv_film_step,
        physical_properties_step,
        structure_step,
        location_step,
        acquisition_step,
        continuity_step,
        sustainability_step,
        fdp_step,
        disposal_step
    },
    props: ['addForm', 'current_step', 'useTooltips', 'required_fields', 'my_lists_values', 'listQuery', 'item_id', 'asset_is_locked', 'asset_is_ended', 'asset_is_disposed', 'getAssetInfoUsingKey']
}
</script>
<style>

</style>
