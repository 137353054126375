<template>

    <div class="col-lg-12">
        <po-lines
            :items="itemsData"
            :current_page_type="item_info.group"
            :object_type="'sps'"
            :keys_list="sp_list_keys_list">

            <template v-slot:sp_actions="{ item, index }">
                <a :href="item_info.base_url +'/'+ item_info.group + '/service_providers/' + item.id + '/view'" class="btn btn-primary btn-sm">View</a>
                <a :href="item_info.base_url + '/customer/' + item_info.id + '/sp/' + item.id" v-if="item_info.item_type === 'customers'" class="btn btn-outline-info btn-sm">View Relation</a>
                <a v-on:click="unAssignFromSp($event, item.id)" class="btn btn-outline-danger btn-sm">Un-Assign</a>
            </template>
        </po-lines>
    </div>
</template>

<script>
    import PoLines from "../../items/widgets/detailsViews/PoLines";
    import {SpShortKeysList} from "@/views/assetflow/form_data/SpShortKeysList";

    export default {
        name: "SpsList",
        components: {PoLines},
        data(){
            return {
                sp_list_keys_list: SpShortKeysList,
                itemsData: [],
                total: 0,
                itemsListLoading: false,
                has_sp_actions_permissions: null
            }
        },
        created() {
            this.has_sp_actions_permissions = this.hasPermission('edit_service_providers') || this.hasPermission('delete_service_providers');

            if (this.has_sp_actions_permissions){
                this.sp_list_keys_list.actions = {
                    key: 'actions',
                    label: "",
                    class: "col-lg-1 c_actions",
                    show_on_mobile: false,
                    case: 'custom_slot',
                    slot_name: 'sp_actions'
                }
            }

            this.getItemList();
        },
        props: [
            'item_info',
            'api_url',
            'unAssignFromSp',
            'with_sp'
        ],
        methods: {
            getItemList() {
                return new Promise( (resolve, reject) => {
                    if (!this.itemsListLoading) {
                        this.itemsListLoading = true;
                        let request_data  = {
                            page: 1,
                            limit: 20
                        };

                        if (this.with_sp){
                            request_data.warehouse_id = this.item_info.sp_id;
                        }
                        axios({
                            method: 'post',
                            url: this.api_url ,
                            baseURL: '/',
                            data: request_data,
                        }).then(response => {
                            this.itemsData = response.data.data.items;
                            this.total = response.data.data.total;
                            this.itemsListLoading = false;
                            resolve(this.itemsData);
                        }).catch(error => {
                            console.log(error);
                            reject(error)
                            this.isSendingRequest = false;
                        });
                    }

                })
            }
        }
    }
</script>


