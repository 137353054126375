<template>
    <Layout>
        <div>
            <!-- Content Goes here-->

            <!-- Link Css-->
            <link rel="stylesheet" href="https://www.koolchart.com/demo/KoolChart/Assets/Css/KoolChart.css"/>
            <link rel="stylesheet" href="https://rawgit.com/enyo/dropzone/master/dist/dropzone.css" />

            <!-- Start here -->
            <div class="" id="main-wrapper">
                <!-- Sidebar start-->
                <div class="side_bar_block" id="side_bar_main_section">
                    <div class="step-progress-bar-block">
                        <!-- progressbar -->
                        <ul id="progressbar">
                            <li class="active"><span>Asset Details</span></li>
                            <li class="blank_round"><span>Physical Properties</span></li>
                            <li class="alert-li"><span>Location</span></li>
                            <li><span>Acquisition</span></li>
                            <li><span>Continuity</span></li>
                            <li><span>Sustainability</span></li>
                            <li><span>Disposal</span></li>
                        </ul>
                    </div>
                    <div class="sustainability-progress-block">
                        <div id="chartHolder" style="height:180px; width:100%;"></div>
                    </div>
                </div>
                <!-- Sidebar end-->
                <!-- Page-Content start-->
                <div id="disboard-content-wrapper">
                    <div class="side_bar_icon_btn"></div>
                    <div class="common-inner-section">
                        <div class="common-title-block">
                            <h2>Add New Assets</h2>
                        </div>
                        <div class="main-step-wrapper">
                            <!-- Multi step form -->
                            <section class="multi_step_form">
                                <div id="msform">

                                    <!-- fieldsets -->
                                    <fieldset class="common-step-block step-1">
                                        <div class="common-title-block">
                                            <h4>Asset Details</h4>
                                            <p>Fill all information below</p>
                                        </div>
                                        <div class="common-step-panel">
                                            <div class="step-form-content-block row">
                                                <div class="col-xl-6">
                                                    <div class="step-common-info-block">
                                                        <div class="st--common--box">
                                                            <div class="common-st-title-label">
                                                                <h5>Product Name</h5>
                                                            </div>
                                                            <div class="common--input-filed">
                                                                <input type="text" placeholder="">
                                                            </div>
                                                            <span class="st-note">Want to name this Asset after it's ID? </span>
                                                        </div>
                                                        <div class="st--common--box status--box">
                                                            <div class="common-st-title-label">
                                                                <h5>Status</h5>
                                                            </div>
                                                            <div class="st-status-radio-box">
                                                                <div class="st-radio-row">
                                                                    <div class="st-radio-col">
                                                                        <div class="cm-radio-btn">
                                                                            <label>
                                                                                <input type="radio" name="status">
                                                                                <div class="st-radio-box">
                                                                                    <span></span>
                                                                                    <p>Planned</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="st-radio-col">
                                                                        <div class="cm-radio-btn ">
                                                                            <label>
                                                                                <input type="radio" name="status">
                                                                                <div class="st-radio-box">
                                                                                    <span></span>
                                                                                    <p>Acquired</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="st-radio-col-2">
                                                                        <div class="cm-radio-btn">
                                                                            <label>
                                                                                <input type="radio" name="status" checked>
                                                                                <div class="st-radio-box">
                                                                                    <span></span>
                                                                                    <p>Active</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="st--common--box">
                                                            <div class="common-st-title-label">
                                                                <h5>Product Quantity</h5>
                                                            </div>
                                                            <div class="common--input-filed st-unique-input">
                                                                <input type="number" placeholder="12" class="st-input-uq">
                                                                <div class="st-unique-input-box">
                                                                    <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" >
                                                                    <label for="styled-checkbox-2">Is Unique?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="st--common--box">
                                                            <div class="common-st-title-label">
                                                                <h5>Internal Reference</h5>
                                                            </div>
                                                            <div class="common--input-filed">
                                                                <input type="text" placeholder="abc other" class="error">
                                                            </div>
                                                            <span class="error-note open"><img src="https://portal.assetflow.co.uk/public/html-templates/images/eroor-ic.svg" alt="Error Icon"/> Enter valid input here..</span>
                                                        </div>
                                                        <div class="st--common--box">
                                                            <div class="common-st-title-label">
                                                                <h5>Product Description</h5>
                                                            </div>
                                                            <div class="common--input-filed">
                                                                <textarea  placeholder="Add your product description"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div class="step-common-info-right-block">
                                                        <div class="st-drop-file-block">
                                                            <div class="st-drop-file-box">
                                                                <div id="dropzone">
                                                                    <form action="/upload" class="dropzone needsclick" id="demo-upload">
                                                                        <div class="dz-message needsclick">
                                                                            <div class="cloud-icon">
                                                                                <img src="https://portal.assetflow.co.uk/public/html-templates/images/upload-cloud-ic.svg" alt="Upload Icon"/>
                                                                            </div>
                                                                            <div class="st-upload-title">
                                                                                <h3>Drop files here or click to upload</h3>
                                                                                <p>... or click to select a file from your computer</p>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div class="st-file-upload-btn">
                                                                <a href="#0" class="st-file-btn">Add New Image</a>
                                                                <a href="#0" class="st-file-btn st-file-btn-ub">Remove All</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="common-step-button-block">

                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="next action-button st-common-btn">
                                                    Next
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.20831 10H15.2916" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9.75 4.45834L15.2917 10L9.75 15.5417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="common-step-block step-2">
                                        <div class="use-section-df">
                                            <div class="common-title-block">
                                                <h4>Physical Properties</h4>
                                                <p>Fill all information below</p>
                                            </div>
                                            <div class="use-section-btn">
                                                <a href="#0" class="st-common-btn use-st-btn">Use Sections</a>
                                                <a href="#0" class="st-common-btn show-tool-btn active">Show Tooltips</a>
                                            </div>
                                        </div>

                                        <div class="common-step-panel show-tooltips">
                                            <div class="step-form-content-block row">
                                                <div class="col-xl-6 st-col-6">
                                                    <div class="step_main_accordion_border">
                                                        <div class="step-small-label">
                                                            <p>Category</p>
                                                            <div class="ac_pluse pluse_rotact">
                                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div class="st_accordion">
                                                            <div class="st--common--box">
                                                                <div class="common-st-title-label">
                                                                    <h5>Enter your category ID</h5>
                                                                </div>
                                                                <div class="common--input-filed">
                                                                    <input type="text" placeholder="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="step_main_accordion_border">
                                                        <div class="step-small-label">
                                                            <p>Dimensions</p>
                                                            <div class="ac_pluse pluse_rotact">
                                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div class="st_accordion">
                                                            <div class="st--common--box">
                                                                <div class="dimensions-block">
                                                                    <div class="common-st-title-label">
                                                                        <h5>Prefarred Unit</h5>
                                                                    </div>
                                                                    <div class="dimensions-btn">
                                                                        <a href="javascript:void(0)" data-btn="dimensions-auto" class="dimensions-auto"><span></span>Auto</a>
                                                                        <a href="javascript:void(0)" data-btn="dimensions-manual" class="dimensions-manual active"><span></span>Manual</a>
                                                                    </div>
                                                                    <div class="dimensions-content-block">
                                                                        <div id="dimensions-auto" class="dimensions-auto-content-block">
                                                                            <div class="challenge-note">
                                                                                <p><span>Challenge:</span>  Click the image that is closest in physical size to your item</p>
                                                                            </div>
                                                                            <div class="dimensions-auto-item-list">
                                                                                <div class="st-item-radio-item">
                                                                                    <ul>
                                                                                        <li>
                                                                                            <div class="st-auto-radio">
                                                                                                <div class="cm-radio-btn">
                                                                                                    <label>
                                                                                                        <input type="radio" name="auto-item">
                                                                                                        <div class="img-item"><img src="https://portal.assetflow.co.uk/public/html-templates/images/auto-item-img.png" alt="Item Size"></div>
                                                                                                        <span class="cm-ro-box">Metre 1</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div class="st-auto-radio">
                                                                                                <div class="cm-radio-btn">
                                                                                                    <label>
                                                                                                        <input type="radio" name="auto-item">
                                                                                                        <div class="img-item"><img src="https://portal.assetflow.co.uk/public/html-templates/images/auto-item-img.png" alt="Item Size"></div>
                                                                                                        <span class="cm-ro-box">Centi 1</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li><li>
                                                                                        <div class="st-auto-radio">
                                                                                            <div class="cm-radio-btn">
                                                                                                <label>
                                                                                                    <input type="radio" name="auto-item">
                                                                                                    <div class="img-item"><img src="https://portal.assetflow.co.uk/public/html-templates/images/auto-item-img.png" alt="Item Size"></div>
                                                                                                    <span class="cm-ro-box">Milli 1</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id="dimensions-manual" class="dimensions-manual-content-block active">
                                                                            <div class="st--common--box">
                                                                                <div class="common-st-title-label">
                                                                                    <h5>Height</h5>
                                                                                </div>
                                                                                <div class="common--input-filed unit-input">
                                                                                    <input type="text" placeholder="">
                                                                                    <span class="u-mm">mm</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="st--common--box">
                                                                                <div class="common-st-title-label">
                                                                                    <h5>Weight</h5>
                                                                                </div>
                                                                                <div class="common--input-filed unit-input unit-input-error">
                                                                                    <input type="text" placeholder="" class="error">
                                                                                    <span class="u-mm">mm</span>
                                                                                    <span class="error-note open"><img src="https://portal.assetflow.co.uk/public/html-templates/images/eroor-ic.svg" alt="Error Icon"> Please enter value in empty fields</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="st--common--box">
                                                                                <div class="common-st-title-label">
                                                                                    <h5>Depth</h5>
                                                                                </div>
                                                                                <div class="common--input-filed unit-input">
                                                                                    <input type="text" placeholder="">
                                                                                    <span class="u-mm">mm</span>
                                                                                </div>
                                                                            </div><div class="st--common--box">
                                                                            <div class="common-st-title-label">
                                                                                <h5>Volume</h5>
                                                                            </div>
                                                                            <div class="common--input-filed unit-input volume-active">
                                                                                <input type="text" placeholder="">
                                                                                <span class="u-mm">m<sup>3</sup></span>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="step_main_accordion_border">
                                                        <div class="step-small-label">
                                                            <p>Select Color</p>
                                                            <div class="ac_pluse pluse_rotact">
                                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div class="st_accordion">
                                                            <div class="st--common--box">
                                                                <div class="st-color-block">
                                                                    <ul>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #000" data-toggle="tooltip" data-placement="bottom" title="Black">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #C0C0C0" data-toggle="tooltip" data-placement="bottom" title="Silver">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #808080" data-toggle="tooltip" data-placement="bottom" title="Grey">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #800000" data-toggle="tooltip" data-placement="bottom" title="Maroon">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="" checked>
                                                                                    <div class="cm-color-round" style="background-color: #FF0000" data-toggle="tooltip" data-placement="bottom" title="Red">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #800080" data-toggle="tooltip" data-placement="bottom" title="Purple">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #FF00FF" data-toggle="tooltip" data-placement="bottom" title="Magenta">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #008000" data-toggle="tooltip" data-placement="bottom" title="Green">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #00FF00" data-toggle="tooltip" data-placement="bottom" title="Electric Green">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #808000" data-toggle="tooltip" data-placement="bottom" title="Olive">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #FFFF00" data-toggle="tooltip" data-placement="bottom" title="Yellow">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #000080" data-toggle="tooltip" data-placement="bottom" title="Navy Blue">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #0000FF" data-toggle="tooltip" data-placement="bottom" title="Blue">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #008080" data-toggle="tooltip" data-placement="bottom" title="Teal">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div class="st-color-box">
                                                                                <label>
                                                                                    <input type=checkbox name="">
                                                                                    <div class="cm-color-round" style="background-color: #00FFFF" data-toggle="tooltip" data-placement="bottom" title="Cyan">
                                                                                        <img src="https://portal.assetflow.co.uk/public/html-templates/images/color_check-ic.png" alt="Color Check" />
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 st-col-6">
                                                    <div class="validation-box-block">
                                                        <div class="validation-box">
                                                            <div class="validation-title">
                                                                <img src="https://portal.assetflow.co.uk/public/html-templates/images/enter-weight-ic.png" alt="Weight" />
                                                                <p>Enter Weight</p>
                                                            </div>
                                                            <div class="validation-desc">
                                                                <p>include all the necessary details needed to complte your request.</p>
                                                            </div>
                                                            <div class="all-validation-desc">
                                                                <h3>For Example:</h3>
                                                                <p>If you are looking for a logo, you can specify your company name, business type, preferred color, Weight etc.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="composition-block col-xl-12">
                                                    <div class="step_main_accordion_border">
                                                        <div class="step-small-label">
                                                            <p>Composition</p>
                                                            <div class="ac_pluse pluse_rotact">
                                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div class="st_accordion">
                                                            <div class="st--common--box">
                                                                <div class="composition--item-block">
                                                                    <div class="row">
                                                                        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                                                            <div class="composition-item-box">
                                                                                <div class="common-checkbox-img">
                                                                                    <label>
                                                                                        <input type="checkbox">
                                                                                        <div class="common-item-img">
                                                                                            <img src="https://portal.assetflow.co.uk/public/html-templates/images/common-item-img1.png" />
                                                                                        </div>
                                                                                        <span class="common-check">Metal</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                                                            <div class="composition-item-box">
                                                                                <div class="common-checkbox-img">
                                                                                    <label>
                                                                                        <input type="checkbox">
                                                                                        <div class="common-item-img">
                                                                                            <img src="https://portal.assetflow.co.uk/public/html-templates/images/common-item-img2.png" />
                                                                                        </div>
                                                                                        <span class="common-check">Wood</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                                                            <div class="composition-item-box">
                                                                                <div class="common-checkbox-img">
                                                                                    <label>
                                                                                        <input type="checkbox">
                                                                                        <div class="common-item-img">
                                                                                            <img src="https://portal.assetflow.co.uk/public/html-templates/images/common-item-img3.png" />
                                                                                        </div>
                                                                                        <span class="common-check">Plastic</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                                                                            <div class="composition-item-box">
                                                                                <div class="common-checkbox-img">
                                                                                    <label>
                                                                                        <input type="checkbox">
                                                                                        <div class="common-item-img">
                                                                                            <img src="https://portal.assetflow.co.uk/public/html-templates/images/common-item-img4.png" />
                                                                                        </div>
                                                                                        <span class="common-check">Fabric</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="common-step-button-block">
                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="action-button previous previous_button st-common-btn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7917 10H4.70832" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M10.25 4.45831L4.70833 9.99998L10.25 15.5416" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Previous
                                                </button>
                                                <button type="button" class="next action-button st-common-btn">
                                                    Next
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.20831 10H15.2916" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9.75 4.45834L15.2917 10L9.75 15.5417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>

                                    </fieldset>

                                    <fieldset class="common-step-block step-3">
                                        <div class="use-section-df">
                                            <div class="common-title-block">
                                                <h4>Location</h4>
                                                <p>Fill all information below</p>
                                            </div>
                                            <div class="use-section-btn">
                                                <a href="#0" class="st-common-btn use-st-btn">Use Sections</a>
                                                <a href="#0" class="st-common-btn show-tool-btn active">Show Tooltips</a>
                                            </div>
                                        </div>
                                        <div class="common-step-panel show-tooltips">
                                            <div class="well-done-note">
                                                <p>Well Done! You Are Just One Step Away.</p>
                                                <a href="javascript:void(0)" class="note-close"><img src="https://portal.assetflow.co.uk/public/html-templates/images/note-close.png" alt="Close"/> </a>
                                            </div>
                                            <div class="step-form-content-block row">
                                                <div class="col-xl-6 st-col-6">
                                                    <div class="step_main_accordion_border">
                                                        <div class="step-small-label">
                                                            <p>Address</p>
                                                            <div class="ac_pluse pluse_rotact">
                                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div class="st_accordion">
                                                            <div class="st--common--box">
                                                                <div class="common-st-title-label">
                                                                    <h5>Flat, House No., Building, Apartment No.</h5>
                                                                </div>
                                                                <div class="common--input-filed">
                                                                    <input type="text" placeholder="D-26,">
                                                                </div>
                                                            </div>
                                                            <div class="st--common--box">
                                                                <div class="common-st-title-label">
                                                                    <h5>Area, Colony, Street, Sector, Village Name</h5>
                                                                </div>
                                                                <div class="common--input-filed">
                                                                    <input type="text" placeholder="St. Stephan street,">
                                                                </div>
                                                            </div>
                                                            <div class="st--common--box">
                                                                <div class="common-st-title-label">
                                                                    <h5>Landmark</h5>
                                                                </div>
                                                                <div class="common--input-filed">
                                                                    <input type="text" placeholder="William Circle,">
                                                                </div>
                                                            </div>
                                                            <div class="st--common--box">
                                                                <div class="common-st-title-label">
                                                                    <h5>City, Town</h5>
                                                                </div>
                                                                <div class="common--input-filed">
                                                                    <input type="text" placeholder="Islington.">
                                                                </div>
                                                            </div>
                                                            <div class="st--common--box">
                                                                <div class="common-st-title-label">
                                                                    <h5>Select Country</h5>
                                                                </div>
                                                                <div class="common--input-filed">
                                                                    <select name="" class="cm-select wide">
                                                                        <option>United Kingdom</option>
                                                                        <option>India</option>
                                                                        <option>America</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="st--common--box">
                                                                <div class="save-address-future">
                                                                    <div class="common-checkbox-img">
                                                                        <label>
                                                                            <input type="checkbox">
                                                                            <span class="common-check">Save this address for future?</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="step_main_accordion_border location--box-block">
                                                        <div class="step-small-label">
                                                            <p>Location</p>
                                                            <div class="ac_pluse pluse_rotact">
                                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <div class="st_accordion">
                                                            <div class="location--box">
                                                                <div class="st--common--box">
                                                                    <div class="common-st-title-label">
                                                                        <h5>Enter your location</h5>
                                                                    </div>
                                                                    <div class="common--input-filed">
                                                                        <input type="text" placeholder="1 Bedroom">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="col-xl-6 st-col-6">
                                                    <div class="validation-box-block">
                                                        <div class="validation-box">
                                                            <div class="validation-title">
                                                                <img src="https://portal.assetflow.co.uk/public/html-templates/images/enter-weight-ic.png" alt="Weight">
                                                                <p>Landmark</p>
                                                            </div>
                                                            <div class="validation-desc">
                                                                <p>include all the necessary details needed to complte your request.</p>
                                                            </div>
                                                            <div class="all-validation-desc">
                                                                <h3>For Example:</h3>
                                                                <p>If you are looking for a logo, you can specify your company name, business type, preferred color, Weight etc.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="common-step-button-block">
                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="action-button previous previous_button st-common-btn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7917 10H4.70832" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M10.25 4.45831L4.70833 9.99998L10.25 15.5416" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Previous
                                                </button>
                                                <button type="button" class="next action-button st-common-btn">
                                                    Next
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.20831 10H15.2916" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9.75 4.45834L15.2917 10L9.75 15.5417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="common-step-block step-4">
                                        <div class="use-section-df">
                                            <div class="common-title-block">
                                                <h4>Acquisition</h4>
                                                <p>Fill all information below</p>
                                            </div>
                                            <div class="use-section-btn">
                                                <a href="#0" class="st-common-btn use-st-btn">Use Sections</a>
                                                <a href="#0" class="st-common-btn show-tool-btn active">Show Tooltips</a>
                                            </div>
                                        </div>
                                        <div class="common-step-panel show-tooltips">
                                            <div class="acquisition-section">
                                                <div class="row">
                                                    <div class="col-xl-6 st-col-6">
                                                        <div class="step_main_accordion_border">
                                                            <div class="step-small-label">
                                                                <p>Value For 1 Item</p>
                                                                <div class="ac_pluse pluse_rotact">
                                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.9706 5V19" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M5.97058 12H19.9706" stroke="#D7171F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                </div>
                                                            </div>

                                                            <div class="st_accordion">
                                                                <div class="st--common--box">
                                                                    <div class="common-st-title-label">
                                                                        <h5>Value</h5>
                                                                    </div>
                                                                    <div class="common--input-filed unit-input">
                                                                        <input type="text" placeholder="">
                                                                        <span class="u-mm">£</span>
                                                                    </div>
                                                                </div>

                                                                <div class="st--common--box">
                                                                    <div class="common-st-title-label">
                                                                        <h5>Total Value</h5>
                                                                    </div>
                                                                    <div class="common--input-filed unit-input">
                                                                        <input type="text" placeholder="">
                                                                        <span class="u-mm">£</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 st-col-6">
                                                        <div class="validation-box-block">
                                                            <div class="validation-box">
                                                                <div class="validation-title">
                                                                    <img src="https://portal.assetflow.co.uk/public/html-templates/images/enter-weight-ic.png" alt="Weight" />
                                                                    <p>Enter Weight</p>
                                                                </div>
                                                                <div class="validation-desc">
                                                                    <p>include all the necessary details needed to complte your request.</p>
                                                                </div>
                                                                <div class="all-validation-desc">
                                                                    <h3>For Example:</h3>
                                                                    <p>If you are looking for a logo, you can specify your company name, business type, preferred color, Weight etc.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="common-step-button-block">
                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="action-button previous previous_button st-common-btn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7917 10H4.70832" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M10.25 4.45831L4.70833 9.99998L10.25 15.5416" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Previous
                                                </button>
                                                <button type="button" class="next action-button st-common-btn">
                                                    Next
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.20831 10H15.2916" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9.75 4.45834L15.2917 10L9.75 15.5417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="common-step-block step-5">
                                        <div class="use-section-df">
                                            <div class="common-title-block">
                                                <h4>Continuity</h4>
                                                <p>Fill all information below</p>
                                            </div>
                                        </div>
                                        <div class="common-step-panel">
                                            <div class="step-form-content-block">

                                            </div>
                                        </div>

                                        <div class="common-step-button-block">
                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="action-button previous previous_button st-common-btn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7917 10H4.70832" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M10.25 4.45831L4.70833 9.99998L10.25 15.5416" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Previous
                                                </button>
                                                <button type="button" class="next action-button st-common-btn">
                                                    Next
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.20831 10H15.2916" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9.75 4.45834L15.2917 10L9.75 15.5417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="common-step-block step-6">
                                        <div class="use-section-df">
                                            <div class="common-title-block">
                                                <h4>Sustainability</h4>
                                                <p>Fill all information below</p>
                                            </div>
                                        </div>
                                        <div class="common-step-panel">
                                            <div class="step-form-content-block">

                                            </div>
                                        </div>

                                        <div class="common-step-button-block">
                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="action-button previous previous_button st-common-btn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7917 10H4.70832" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M10.25 4.45831L4.70833 9.99998L10.25 15.5416" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Previous
                                                </button>
                                                <button type="button" class="next action-button st-common-btn">
                                                    Next
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.20831 10H15.2916" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M9.75 4.45834L15.2917 10L9.75 15.5417" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </button>

                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="common-step-block step-7">
                                        <div class="use-section-df">
                                            <div class="common-title-block">
                                                <h4>Disposal</h4>
                                                <p>Fill all information below</p>
                                            </div>
                                        </div>
                                        <div class="common-step-panel">
                                            <div class="step-form-content-block">

                                            </div>
                                        </div>

                                        <div class="common-step-button-block">
                                            <div class="save-cancel-btn">
                                                <div class="btn-group dropup">
                                                    <a href="#0" class="st-common-btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Save & View
                                                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7L13 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="#">Action</a>
                                                        <a class="dropdown-item" href="#">Save</a>
                                                        <a class="dropdown-item" href="#">View</a>
                                                    </div>
                                                </div>
                                                <a href="#0" class="st-cancel-btn">Cancel</a>
                                            </div>
                                            <div class="prev-next--btn">
                                                <button type="button" class="action-button previous previous_button st-common-btn">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.7917 10H4.70832" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M10.25 4.45831L4.70833 9.99998L10.25 15.5416" stroke="#D7171F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    Previous
                                                </button>
                                                <!--<a href="#" class="action-button">Finish</a>-->
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </section>
                            <!-- End Multi step form -->
                        </div>
                    </div>
                </div>
                <!-- Page-Content end-->
            </div>
            <!-- End here -->

            <!--all JS Moved to /js/ folder-->

        </div>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
export default {
    name: "AssetFormTemplate",
    components: {Layout}
}
</script>


