<template>
    <div class="mr-2 mb-2 d-inline-block">
        <!-- Button to open the modal -->
        <button type="button" class="btn af-btn-accent af-accent" @click="showModal"><i class="fas fa-qrcode"></i></button>
        <QrCodeModal
            :ref="modal_id"
            :modal_id="modal_id"
            :current_page_type="current_page_type"
            :object_name="object_name"
        />

    </div>
</template>
<script>

import QrCodeModal from './QrCodeModal';

export default {
    name: 'ItemQrGenerator',
    components: {QrCodeModal},
    props: {
        item_info: {},
        object_name: {},
        current_page_type: {}
    },
    mixins: [],
    data() {
        return {
            modal_id: 'qrcode_generator',
            qrCodeImage: '',
            qrCodeURL: '',
        };
    },
    methods:{
        showModal(){
            // Opens the modal

            this.$refs[this.modal_id].setQrCodeURL(this.item_info);
            this.$bvModal.show(this.modal_id);
        },
    }
}
</script>
