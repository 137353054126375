<template>
    <div>

        <add-new-modal
            ref="add-new-address"
            item_type='address'
            modal_title="Create New Address"
            @on-new-item-adding="onAddingNewAddress"
        ></add-new-modal>

        <div class="row">
            <div class="col-sm-12">
                <div class="card" v-for="(required_section, k) in required_sections" :key="k">
                    <div role="alert" class="alert alert-info order-service-msg" v-if="!selected_customer_id && ['service_setup', 'subscriber_setup'].includes(k)">
                        <p><i class="mdi mdi-alert-circle-outline me-2"></i><em>To set {{ (k === 'service_setup' ? 'order service' : 'subscribers') }}, please select a customer first.</em></p>
                    </div>
                    <template v-else>
                        <div class="card-header af-soft-accent text-af-primary">
                            {{ required_section.label | titleize }}
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div :class="required_section.hasOwnProperty('required_items_class') ? required_section.required_items_class : 'col-sm-12'">
                                    <ChangeCommonFieldsAdvanced
                                        :item_type="item_type"
                                        :add-form="addForm"
                                        :required_fields="required_section.fields" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="text-right mt-2">
            <b-button type="submit"
                      :disabled="!addForm.customer_id"
                      variant="success"
                      v-on:click.once="addItem">
                {{ selected_item && selected_item.hasOwnProperty('name') ? ('Update {' + selected_item.name + '}') : 'Create'}}
            </b-button>
            <b-button variant="outline-danger" @click="$emit('on-cancel-clicked')">Cancel</b-button>
        </div>
    </div>
</template>
<script>

import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';
import SharedServiceFunctions from '@/mixins/SharedServiceFunctions.mixin';
import SettingInfoSection from "../settings/settingsForm/SettingInfoSection"
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";
import AddNewModal from "../extras/AddNewModal";

const orderResource = new Resource('api/assetflow/orders');


export default {
    name: 'OrderFormFields',
    components: {AddNewModal, ChangeCommonFieldsAdvanced, SettingInfoSection},
    mixins: [multiSelect, SharedServiceFunctions],
    props: ['is_full', 'item_type', 'received_order_type', 'received_selected_item'],
    data(){
        return {
            selected_item: null,
            addForm:{
                has_errors: null,
                order_type:null,
                sp_id:null,
                customer_id:null,
                reference: '',
                name: '',
                notes: '',
                service_id:null,
                address_id:null,
                subscriber_id:[],
                intake_date: null,
            },
            required_sections: {
                order_setup:{
                    'label' : 'Setup',
                    fields: {
                        order_type:{
                            key: 'order_type',
                            label: 'Order Type',
                            type: 'single-select',
                            custom_label: null,
                            is_required:true,
                            options: [
                                {
                                    id: 0,
                                    name: 'Move To Storage',
                                    sp_name: 'Move To Storage',
                                    is_sp_managed: true
                                },
                                {
                                    id: 1,
                                    name: 'Storage Order',
                                    sp_name: 'Storage Order',
                                    is_sp_managed: true
                                }
                            ]
                        },
                        sp_id:{
                            key: 'sp_id',
                            label: 'Service Provider',
                            placeholder: 'Select Service Provider',
                            type: 'single-select',
                            is_required:true,
                            options: []
                        },
                        customer_id:{
                            key: 'customer_id',
                            label: 'Customer',
                            placeholder: 'Select Customer',
                            type: 'single-select',
                            is_required:true,
                            options: []
                        },
                        name: {
                            key: 'name',
                            label: 'Order Name',
                            placeholder: 'Friendly Name for this Order',
                            type: 'text',
                            is_required:false
                        },
                        intake_date: {
                            key: 'intake_date',
                            label: 'Intake Date',
                            type: 'hidden_text' // date
                        },
                        reference: {
                            key: 'reference',
                            label: 'Customer Reference',
                            placeholder: 'Optional Reference or Purchase Order',
                            type: 'text',
                            is_required:false
                        },
                        notes: {
                            key: 'notes',
                            label: 'Notes',
                            type: 'textarea',
                            placeholder: 'Enter any Order Notes, Special Requests or Useful Information',
                            is_required:false
                        },
                    }
                },
                service_setup:{
                    'label' : 'Service',
                    fields: {
                        service_id:{
                            key: 'service_id',
                            label: 'Service',
                            placeholder: 'Select Required Service',
                            type: 'single-select',
                            is_required:false,
                            allow_empty:true,
                            has_message: true,
                            message_html: '<div role="alert" class="alert alert-info order-service-msg"><p><i class="mdi mdi-alert-circle-outline me-2"></i><em>Leave these blank to set the required Service at the Order Confirmation stage.</em></p></div>',
                            options: []
                        },
                        address_id:{
                            key: 'address_id',
                            label: 'Delivery Address',
                            placeholder: 'Select Delivery Address',
                            type: 'hidden_text',
                            allow_empty:true,
                            onChangingValue: function (value){},
                            is_required:true,
                            options: []
                        },
                    }
                },
                subscriber_setup:{
                    'label' : 'Subscribers',
                    fields: {
                        subscriber_id:{
                            key: 'subscriber_id',
                            label: 'Subscribers',
                            placeholder: 'Select Users',
                            type: 'multi-select',
                            is_required:false,
                            allow_empty:true,
                            has_message: true,
                            message_html: '<div role="alert" class="alert alert-info order-service-msg"><p><i class="mdi mdi-alert-circle-outline me-2"></i><em>Select Users who can see this Order and will receive Order Notifications.</em></p></div>',
                            options: [],
                            has_html_note: ''
                        },
                    }
                },
            },
            isAddingItem: false
        }
    },
    created() {
        let item_type = this.item_type;

        if (this.received_selected_item && this.received_selected_item.hasOwnProperty('customer_info') && this.received_selected_item.customer_info){
            console.log("this.received_selected_item.customer_info");
            console.log(this.received_selected_item.customer_info);
            this.addForm.customer_id = this.received_selected_item.customer_info;
        }

        this.required_sections.order_setup.fields.order_type.custom_label = function (order_type_info){
            return item_type === 'sp' ? order_type_info.sp_name : order_type_info.name;
        };

        this.setFields();
        if (this.received_selected_item && this.received_selected_item.hasOwnProperty('name')){
            this.setSelectedItem(this.received_selected_item);
        }else{
            if (this.received_selected_item && this.received_selected_item.hasOwnProperty('customer_id')){
                this.setSelectedItem(this.received_selected_item);
            }
            this.shallGetListFor(this.item_type, 'order_setup');
        }

        this.required_sections.service_setup.fields.address_id.onChangingValue = this.handleSelectingAddress;


        if (!(this.hasPermission('browse_users') && this.hasPermission('manage_orders'))){
            delete this.required_sections.subscriber_setup;
        }
    },
    computed:{
        selected_order_type() {
            return this.addForm.order_type;
        },
        selected_customer_id() {
            return this.addForm.customer_id;
        },
        selected_service_id() {
            return this.addForm.service_id;
        },
    },
    watch: {
        selected_order_type: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue && newValue !== oldVal) {
                    if (newValue.id === 0){
                        this.required_sections.order_setup.fields.intake_date.type = 'date';
                        this.addForm.intake_date = this.$moment().format('DD-MM-YYYY');
                    }else{
                        this.required_sections.order_setup.fields.intake_date.type = 'hidden_text';
                        this.addForm.intake_date = null;
                    }
                }
            }
        },
        selected_customer_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal) {
                    this.addForm.service_id = null;
                    this.addForm.address_id = null;
                    this.addForm.subscriber_id = [];
                    this.shallGetListFor('service', 'service_setup');
                    this.shallGetListFor('address', 'service_setup');
                    if (this.hasPermission('browse_users') && this.hasPermission('manage_orders')) {
                        this.shallGetListFor('subscriber', 'subscriber_setup');
                    }
                }
            }
        },
        selected_service_id: {
            deep: true,
            handler: function (newValue, oldVal) {
                if (newValue !== oldVal){
                    if (newValue && newValue.hasOwnProperty('service_type_info') && newValue.service_type_info){
                        let service_type_info = newValue.service_type_info;
                        if (
                            service_type_info &&
                            service_type_info.hasOwnProperty('order_attributes') &&
                            service_type_info.order_attributes &&
                            service_type_info.order_attributes.includes(1) // delivery address field === 1?
                        ){
                            if (this.selected_item && ![0, 1].includes(this.selected_item.status)){
                                this.required_sections.service_setup.fields.address_id.type = 'readonly_text';
                            }else{
                                this.required_sections.service_setup.fields.address_id.type = 'single-select';
                            }
                            return;
                        }
                    }
                }

                this.required_sections.service_setup.fields.address_id.type = 'hidden_text';
            }
        },
    },
    methods:{


        setFields(){

            // shall get #customers/sps list
            if (this.item_type === 'sp'){
                // shall get customers list
                if (this.is_tv_film_asset){
                    this.required_sections.order_setup.fields.customer_id.label = 'Production';
                    this.required_sections.order_setup.fields.customer_id.placeholder = 'Select Production';
                }
                delete this.required_sections.order_setup.fields.sp_id;


                // shall filter order types
                this.required_sections.order_setup.fields.order_type.options = this.required_sections.order_setup.fields.order_type.options.filter(function (file) {
                    return file.is_sp_managed;
                });

            }else if (this.item_type === 'customer'){
                // shall get sps list
                this.addForm.customer_id = this.current_customer;
                this.required_sections.order_setup.fields.customer_id.type = 'hidden_text';
            }

            let order_type_name = 'Order';
            if (this.received_order_type !== null){
                let received_order_type = this.received_order_type;
                let found_item = _.find(this.required_sections.order_setup.fields.order_type.options, function(o) { return o.id === received_order_type; });

                if (found_item){
                    this.addForm.order_type = found_item;
                    this.required_sections.order_setup.fields.order_type.type = 'hidden_text';
                    this.required_sections.order_setup.label = found_item.name + (found_item.id === 0 ? ' Order' : '') + ' Setup';
                    order_type_name = this.addForm.order_type.name || 'Order';
                }else{
                    this.addForm.order_type = null;
                }
            }
            this.required_sections.subscriber_setup.fields.subscriber_id.has_html_note = '<p>Note: <strong>' + order_type_name + ' Managers</strong> see all Orders</p>'

        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        setValueFromOptions: function (section_name, field_name, item_id, default_value = null) {
            let found_item = _.find(this.required_sections[section_name].fields[field_name].options, function (o) {
                return o.id === item_id;
            });

            this.addForm[field_name] = found_item ? found_item : default_value;
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('order_setup', 'order_type', this.selected_item.order_type);
                this.required_sections.order_setup.fields.customer_id.type = 'hidden_text';
                if (this.item_type === 'sp'){
                    this.addForm.customer_id = this.selected_item.customer_info;
                    this.required_sections.order_setup.fields.customer_id.type = 'readonly_text';
                }else if (this.item_type === 'customer'){
                    this.addForm.sp_id = this.selected_item.sp_info;
                    this.required_sections.order_setup.fields.sp_id.type = 'readonly_text';
                }
                this.addForm.reference = this.selected_item.reference;
                this.required_sections.order_setup.fields.reference.has_message = false;
                this.addForm.name = this.selected_item.name;

                this.addForm.notes = this.selected_item.notes;

                if (![0, 1].includes(this.selected_item.status)){
                    // shall hide service and address
                    this.required_sections.service_setup.fields.service_id.type = 'readonly_text';
                    this.required_sections.service_setup.fields.service_id.has_message = false;
                }else{
                    this.required_sections.service_setup.fields.service_id.has_message = true;
                }

            }else{
                if (!this.received_order_type){
                    this.required_sections.order_setup.fields.order_type.type = 'single-select';
                }
                if (this.item_type === 'sp') {
                    this.required_sections.order_setup.fields.customer_id.type = 'single-select';
                } else if (this.item_type === 'customer') {
                    this.required_sections.order_setup.fields.sp_id.type = 'single-select';
                }
            }

        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            let params = {
                order_type: this.addForm.order_type ? this.addForm.order_type.id : null,
                reference: this.addForm.reference,
                name: this.addForm.name,
                intake_date: this.addForm.intake_date,
                notes: this.addForm.notes,
                service_id: this.addForm.service_id ? this.addForm.service_id.id : null,
                address_id: this.addForm.address_id ? this.addForm.address_id.id : null,
                subscriber_id: this.addForm.subscriber_id ? this.getIDs(this.addForm.subscriber_id) : null
            };

            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            if (this.item_type === 'customer'){
                params.sp_id = this.addForm.sp_id ? this.addForm.sp_id.id : null;
            }
            if (this.item_type === 'sp'){
                params.customer_id = this.addForm.customer_id ? this.addForm.customer_id.id : null;
            }

            const { data } = await orderResource.store(params);

            let new_item = data.data.items;
            let status = data.data.status;
            this.presentToast((status ? "Success!" : "Error!"),
                status ? ((this.selected_item && this.selected_item.hasOwnProperty('name') ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (status ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$emit('after-saving-is-done', new_item, status);
        },
    }
}
</script>
