<template>
    <v-chart class="chart" :option="radarChart" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {RadarChart} from "echarts/charts";

import { TooltipComponent } from "echarts/components";
import VChart from "vue-echarts";

use([
    CanvasRenderer,
    RadarChart,
    TooltipComponent
]);

export default {
    props:[
        'custom_options'
    ],
    name: "AfRadarChart",
    components: {
        VChart
    },
    mounted() {

    },
    methods:{
        convertHex (color, opacity) {
            color = color.replace('#', '')
            const r = parseInt(color.substring(0, 2), 16)
            const g = parseInt(color.substring(2, 4), 16)
            const b = parseInt(color.substring(4, 6), 16)
            return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`
        }
    },
    data() {
        return {
            sections_colours: [],
            radarChart: {
                radar: [
                    {
                        indicator: this.custom_options.titles,
                        center: ['55%', '50%'],
                        radius: 70,
                        startAngle: 90,
                        splitNumber: 4,
                        // shape: 'circle',
                        axisName: {
                            formatter: '{value}',
                            color: this.custom_options.titles_colour,
                            backgroundColor: this.custom_options.titles_bgcolour,
                            borderRadius: 2,
                            padding: [4, 4]
                        },
                        splitArea: {
                            areaStyle: {
                                color: this.custom_options.sections_colours,
                                shadowColor: 'rgba(0, 0, 0, 0.3)',
                                shadowBlur: 0
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.5)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.5)'
                            }
                        }
                    }
                ],
                series: [
                    {
                        itemStyle: {
                            color:[this.custom_options.lines_colour]
                        },
                        type: 'radar',
                        emphasis: {
                            lineStyle: {
                                width: 8
                            }
                        },
                        data: [
                            {
                                value: this.custom_options.data_values,
                                name: 'Data Name',
                                areaStyle: {
                                    color: this.custom_options.area_colour
                                },
                                label: {
                                    show: true,
                                    formatter: function(params) {
                                        return params.value + '%';
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    }
}
</script>


