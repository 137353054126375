<template>
    <div :class="used_classes">

        <div class="popup-gallery">
            <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="captionimgs"
                :index="index"
                @hide="handleCaptionHide">
            </vue-easy-lightbox>
        </div>

        <MyBCarouselImages parent_class="cm_product_grid_img mt-4" :my_asset="cAFID_info" asset_images_name="filtered_asset_images" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>

        <div class="cm_product_content">
            <div class="cm_product_status_grid">
                <div class="cm_pro_status_tag" v-if="cAFID_info.passport_status === 0">
                    <a href="#" :class="'status-btn status-btn-active'">Assigned</a>
                </div>
                <div class="cm_status_ul asset_details_qty">
                    <ul>
                        <li>
                            <p>Total <span class="total_box_bg">{{ cAFID_info.quantity }}</span></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="common_col_bg_box">
                <div class="cm_view_title">
                    <h4>Details</h4>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <div class="cm_usage">
                            <span>Passport Type</span>
                            <p><i class="bx bx-map"></i> {{ cAFID_info.quantity > 1 ? 'Shared' : 'Unique' }}</p>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="cm_usage">
                            <span>Category</span>
                            <p v-if="cAFID_info.hasOwnProperty('parent_category') && cAFID_info.parent_category"><i class="bx bx-map"></i> {{ cAFID_info.parent_category.name }}</p>
                            <p v-else>-</p>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="cm_usage">
                            <span>Category Template</span>
                            <p v-if="cAFID_info.hasOwnProperty('asset_template') && cAFID_info.asset_template"><i class="bx bx-map"></i> {{ cAFID_info.asset_template.name }}</p>
                            <p v-else>-</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>

import AssetActions from "@/mixins/assetActions.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TotalsAndCurrencies from "@/mixins/TotalsAndCurrencies.mixin";
import VueEasyLightbox from "vue-easy-lightbox";
import MyBCarouselSlide from "./MyBCarouselSlide";
import MyBCarouselImages from "../../../../../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'AssetPassportRightBox',
    mixins: [AssetActions, TotalsAndCurrencies, ImageGallery],
    components: {
        MyBCarouselImages,
        MyBCarouselSlide,
        VueEasyLightbox
    },
    props: {
        item_info: {},
        used_classes:''
    },
    computed:{
        cAFID_info(){
            if (this.item_info.hasOwnProperty('cAFID_info')){
                return this.item_info.cAFID_info;
            }
            return this.item_info;
        },
        item_info_asset_images(){
            if (!this.cAFID_info || !this.cAFID_info.filtered_asset_images){
                return [];
            }
            return this.cAFID_info.filtered_asset_images.filter(function (e) {
                return e.add_to_ap === 1;
            });
        },
    },
}
</script>
