<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <ul class="summary-log-items">
                        <li>Status: <b><span v-html="getItemStatus(item_info)"></span></b>
                        </li>
                        <li>Bulk Action ID: <b>{{ item_info.uid }}</b></li>


                        <li>Type of Action: <b>{{ item_info.type_of_action | titleize }}</b></li>
                        <li v-if="item_info.type_of_action === 'object'">Object: <b>{{ item_info.action_object.type
                            }}</b></li>
                        <li v-else-if="item_info.hasOwnProperty('action_object') && item_info.action_object">List: <b>
                            <a v-if="item_info.action_object.hasOwnProperty('url') && item_info.action_object.url" :href="item_info.action_object.url" target="_blank">{{item_info.action_object.name }}</a>
                            <span v-else><b>{{item_info.action_object.name }}</b></span>
                        </b></li>
                        <li v-else>List</li>

                        <li v-if="item_info.type_of_action === 'object'">Item: <b><a :href="item_info.action_object.url" target="_blank">{{item_info.action_object.name }}</a></b></li>
                        <li v-else class="list_details_label">List Details:</li>
                        <li v-if="item_info.type_of_action === 'list' && item_info.query_text !== ''" class="list_details_li">
                            <ol class="list_details_ol">
                                <li v-for="(query_text) in item_info.query_text.split(',')" v-html="query_text" class="list_details_ol_li"></li>
                            </ol>
                        </li>

                        <li>User: <b>{{ item_info.user_info.name }}</b></li>
                        <li>Action Name: <b>{{ item_info.action_name | titleize }}</b></li>
                        <li>Requested Date: <b>{{ item_info.created_at | moment('dddd, MMMM Do YYYY h:mm:ss A') }} -
                            {{item_info.created_at | fromNow}}</b></li>
                        <li>Entity: <b>{{ item_info.entity_info ? item_info.entity_info.name : '-' }}</b></li>
                        <li>Module: <b>{{ item_info.module_info ? item_info.module_info.name : '-' }}</b></li>
                        <li>No of Items: <b>{{ item_info.total_items }}</b></li>
                        <li>Success: <b>{{ item_info.successful_items }}</b></li>
                        <li>Failed: <b>{{ item_info.failed_items }}</b></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BulkActionOverview',
    props: {
        item_info: {}
    },
    methods:{
        getItemStatus(item){
            let status_class = '';
            let status_text = Vue.options.filters.titleize(item.status);
            switch (item.status){
                case 'pending':
                    status_class = 'background-orange';
                    break;
                case 'completed':
                    status_class = 'background-green';
                    break;
                case 'partial':
                    status_class = 'background-green';
                    break;
                case 'cancelled':
                    status_class = 'background-grey';
                    break;
            }

            return '<span class="status-btn status-'+status_class+'">'+status_text+'</span>';
        }
    }
}
</script>
