<template>
    <b-modal :id="received_modal_id"
             size="lg"
             :ref="received_modal_id"
             :title="modal_title + (selected_item ? (' to {'+selected_item.name + '}') : '')"
             title-class="font-18"
             @hide="cancelClicked"
             hide-footer>

        <div class="card">
            <div class="card-header af-soft-accent text-af-primary">
                Files
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-body">
                        <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">

            <AFButton
                tag_name="button"
                tag_type="submit"
                :key_prefix="'edit-ms-files' + '-' + buttonkey"
                :is_enabled="!isAddingItem"
                :required_action="addItem"
                required_text="Save"
                :required_action_text="required_action_text"
                item_classes="btn btn-success"
            />
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import AFButton from "@/components/AFButton";
import ChangeCommonFieldsAdvanced from "../../customers/extras/ChangeCommonFieldsAdvanced";
import CustomFieldsRepeater from "../../assets/assetForm/CustomFieldsRepeater";
const assetFileResource = new Resource('api/assetflow/asset_files');

export default {
    mixins: [],
    name: "AddMSFile",
    props: ['modal_title', 'received_modal_id'],
    components: {CustomFieldsRepeater, ChangeCommonFieldsAdvanced, AFButton},
    data(){
        return {
            required_action_text: 'Saving...',
            isAddingItem: false,
            index: null,
            addForm:{
                asset_meta:{
                    asset_links: [],
                },
            },
            required_items:{
                asset_files: {
                    key: 'asset_files',
                    label: '',
                    type: 'asset_files'
                },
            },

            selected_item: null
        }
    },
    methods:{
        set_index(index){
            this.index = index;
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {

        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        async saveFileUUID(response, asset_id, asset_file) {
            return new Promise( async (resolve, reject) => {
                let file_info = asset_file.field_value;
                let params = {
                    item_id: asset_id,
                    item_type: 'App\\Asset',
                    s3_uuid: response.uuid,
                    file_type: response.extension,
                    name: asset_file.field_name,
                    file_name: file_info.name,
                    size: file_info.size
                };

                const {data} = await assetFileResource.store(params);

                let new_item = data.data.items;
                let status = data.data.status;

                let status_message = 'Error!',
                    status_class = 'danger',
                    message = data.data.msg;

                if (status && new_item) {
                    status_message = 'Success!';
                    message = 'Uploaded File Successfully';
                    status_class = 'success';
                }
                this.presentToast(status_message,
                    message,
                    status_class,
                    3000,
                    true,
                    true);

                if (new_item){
                    resolve(new_item);
                }else{
                    reject();
                }
            })


        },
        async addItem(event){
            this.isAddingItem = true;
            let asset_files = [];
            if (this.addForm.asset_meta.hasOwnProperty('asset_files') && this.addForm.asset_meta.asset_files){
                asset_files = [... this.addForm.asset_meta.asset_files];
                delete this.addForm.asset_meta.asset_files;
            }

            let asset_files_length = asset_files.length;

            let files_are_empty = asset_files_length === 0 || (asset_files_length === 1 && asset_files[0].field_name === '' && asset_files[0].field_value === '');

            if (!files_are_empty){
                // adding asset files

                let asset_id = this.selected_item.id;
                if (!asset_id){
                    this.buttonkey++;
                    this.presentToast("Error!",
                    'Something went wrong, please refresh and try again.',
                    'danger',
                    3000,
                    true,
                    true);
                    return;
                }
                let uploaded_files = [];

                // A simple promise that resolves after a given time
                const uploadAssetFile = (asset_file, asset_id) => {
                    return new Promise((resolve, reject) => {
                        let file_info = asset_file.field_value;
                        if (file_info){
                            // Stream the file to S3
                            Vapor.store(file_info).then(response => {
                                if (response){
                                    let file_response = {
                                        response: response,
                                        asset_id: asset_id,
                                        asset_file: asset_file
                                    }
                                    resolve(file_response);
                                }else{
                                    console.log("rejected?");
                                    reject();
                                }
                            });
                        }
                    })
                }

                const promises = []

                asset_files.map((asset_file) => {
                    promises.push(uploadAssetFile(asset_file, asset_id))
                })

                
                this.required_action_text = `Uploading ${asset_files_length} file${(asset_files_length === 1 ? '.' : 's.')}`;
                Promise.all(promises)
                    .then(responses => {
                        for (const responsesKey in responses) {
                            let file_response = responses[responsesKey];
                            if (file_response){
                                uploaded_files.push(responsesKey)
                                let filePromise = this.saveFileUUID(file_response.response, file_response.asset_id, file_response.asset_file);
                                this.required_action_text = `Uploaded ${uploaded_files.length} out of ${asset_files_length} file${(asset_files_length === 1 ? '.' : 's.')}`;

                                if (parseInt(responsesKey) === responses.length - 1){
                                    filePromise.then(response => {
                                        if (uploaded_files.length !== asset_files_length){
                                            this.buttonkey++;
                                            this.isAddingItem = false;
                                            this.presentToast("Warning!",
                                                'Not all files were uploaded',
                                                'warning',
                                                3000,
                                                true,
                                                true);
                                        }else{
                                            this.required_action_text = `Uploaded ${asset_files_length} file${(asset_files_length === 1 ? '.' : 's.')}`;
                                        }
                                        this.resetSelectedItem();
                                        this.$emit('on-new-item-adding', this.received_modal_id, null);
                                    }).finally(() => {
                                        this.buttonkey++;
                                        this.isAddingItem = false;
                                    })
                                }
                            }
                        }
                    })
            }

        }
    }
}
</script>


