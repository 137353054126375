<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    placeholder="Select Status"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const asset_groupResource = new Resource('api/assetflow/asset_groups');
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewAssetGroup",
    props: ['modal_title', 'item_type'],
    data(){
        return {
            addForm:{
                name: '',
                status:null,
                start_date: '',
                finish_date: ''
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Event Name',
                    type: 'text'
                },
                status:{
                    key: 'status',
                    label: 'Status',
                    type: 'single-select',
                    options: [
                        {
                            id: 0,
                            name: 'Inactive'
                        },
                        {
                            id: 1,
                            name: 'Active'
                        },
                    ]
                }
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
        this.required_items.name.label = Vue.options.filters.titleize(this.item_type) + ' Name';
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                let status_id = this.selected_item.status;
                let found_item = _.find(this.required_items.status.options, function(o) { return o.id === status_id; });
                if (found_item){
                    this.addForm.status = found_item;
                }else{
                    this.addForm.status = null;
                }
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                status: this.addForm.status ? this.addForm.status.id : 1
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }
            const { data } = await asset_groupResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-' + this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


