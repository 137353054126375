<template>
    <!-- Modal with QR code -->
    <b-modal
        :id="modal_id"
        :title="item_info ? 'QR Code of \'' + item_info.name + '\'' : ''"
        title-class="font-18"
        size="lg"
        @shown="showQRCode"
        @hide="$emit('on-modal-cancel')"
        ok-only
    >
        <img v-if="qrCodeImage" :src="qrCodeImage" :alt="qrCodeURL" width="100%" height="100%" />

        <template #modal-footer="{ ok, cancel, hide }">
            <b-button v-if="qrCodeImage" variant="success" @click="downloadQRCode">
                Download QR Code
            </b-button>
            <b-button variant="secondary" @click="cancel()">
                Close
            </b-button>
        </template>
    </b-modal>
</template>
<script>

import QRCode from 'qrcode';
import {MY_BASE_URL} from "@/utils/shared_constants";

export default {
    name: 'QrCodeModal',
    components: {},
    props: {
        modal_id: {},
        current_page_type: {},
        object_name: {}
    },
    mixins: [],
    data() {
        return {
            qrCodeImage: '',
            qrCodeURL: '',
            item_info: null
        };
    },
    methods:{
        getEntityID(){
            let entity_id = '';
            let item_info = this.item_info;
            if(item_info){
                switch (this.current_page_type) {
                    case 'customer':
                        entity_id = item_info.customer_id || item_info.customer_info.id || '';
                        break;
                    case 'sp':
                        entity_id = item_info.warehouse_id || item_info.warehouse_info.id || '';
                        break;
                    case 'group':
                        entity_id = item_info.group_id || item_info.group_info.id || '';
                        break;
                    default:
                        entity_id = '';
                        break;
                }
            }
            return entity_id;
        },
        setQrCodeURL(item_info){
            let qrCodeURL = MY_BASE_URL;
            let uid = item_info.uid.toString().split('-').pop();
            this.item_info = item_info;
            let entity_id = this.getEntityID();
            this.qrCodeURL = qrCodeURL + 'link?uid=' + uid + '&entity_type=' + this.current_page_type + '&entity_id='+entity_id;
        },
        async showQRCode() {
            try {
                // Base URL
                const baseURL = this.qrCodeURL;

                let item_info = this.item_info;
                
                let entity_id = this.getEntityID();
                // Dictionary data to encode in the URL
                const data = {
                    entity_type: this.current_page_type || '',
                    entity_id: entity_id,
                    module_id: item_info.module_id || '',
                    department_id: item_info.department_id || '',
                    object_name: Vue.options.filters.titleizeToSingle(this.object_name || ''),
                    item_id: item_info.id || '',
                    item_name: item_info.name || '',
                    item_uid: item_info.uid || '',
                    reference_type: this.object_name === 'assets' ? 'internal_reference' : '',
                    reference_value: item_info.internal_reference || ''
                };

                // Remove keys with empty string values using lodash
                const cleanedData = _.omitBy(data, value => value === '');

                // Convert the dictionary to URL query parameters
                const queryParams = new URLSearchParams(cleanedData).toString();

                // Construct the full URL with query parameters
                const qrCodeURL = `${baseURL}&${queryParams}`;

                // Generate the QR code with the constructed URL and set it as an image source
                this.qrCodeImage = await QRCode.toDataURL(qrCodeURL, { width: 600 });
            } catch (error) {
                console.error('QR code generation failed', error);
            }
        },
        downloadQRCode() {
            // Create a temporary link to download the QR code image
            const link = document.createElement('a');
            link.href = this.qrCodeImage;
            link.download = this.item_info.uid + '.png'; // Set the default filename for download
            link.click();
        },
    }
}
</script>
