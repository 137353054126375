<template>
    <div class="EntityDetailsDetails">
        <po-lines :items="entity_details" :keys_list="entity_details_keys_list" />
    </div>
</template>
<script>
import PoLines from "./detailsViews/PoLines";
export default {
    name: 'EntityDetailsDetails',
    components: {PoLines},
    props: {
        item_info: {},
        tab_name: {},
    },
    data(){
        return {
            entity_details: [],
            entity_details_keys_list: {
                initials: {
                    key: 'initials',
                    label: "",
                    class: "col-lg-2 initials",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let style = '';
                        if (item.hasOwnProperty('meta') && item.meta && item.meta.hasOwnProperty('primary_colour') && item.meta.primary_colour){
                            let bg_colour = item.meta.accent_colour;
                            let text_colour = item.meta.primary_colour;
                            style = 'style="background-color: '+bg_colour+' !important; color:'+text_colour+' !important;"';
                        }
                        return '<a href="./customers/'+item.id+'/view" class="text-dark">' + '<div class="avatar-sm m-1"><span class="avatar-title rounded-circle af-accent text-af-primary font-size-16" '+style+'>'+item[key]+'</span></div> ' + item.name + '</a>';
                    }
                },
                entity_total_modules: {
                    key: 'entity_total_modules',
                    label: "Modules Count",
                    class: "col-lg-1 md_entity_total_modules",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                entity_total_users: {
                    key: 'entity_total_users',
                    label: "Total Users",
                    class: "col-lg-1 md_entity_total_users",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                entity_active_users: {
                    key: 'entity_active_users',
                    label: "Active Users",
                    class: "col-lg-1 md_entity_active_users",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                entity_disabled_users: {
                    key: 'entity_disabled_users',
                    label: "Disabled Users",
                    class: "col-lg-1 md_entity_disabled_users",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                entity_user_roles: {
                    key: 'entity_user_roles',
                    label: "User Roles",
                    class: "col-lg-1 md_entity_user_roles",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                entity_assets: {
                    key: 'entity_assets',
                    label: "Assets",
                    class: "col-lg-1 md_entity_assets",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                entities: {
                    key: 'entities',
                    label: "Service Providers",
                    class: "col-lg-1 md_entities",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                actions: {
                    key: 'actions',
                    label: "",
                    class: "col-lg-1 md_actions",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
            },
        }
    },
    created() {
        let item_info = this.item_info;
        let tab_name = this.tab_name;
        this.entity_details = [ item_info ];

        if (tab_name !== 'customer_details'){
            delete this.entity_details_keys_list.entity_active_users;
            delete this.entity_details_keys_list.entity_disabled_users;
            this.entity_details_keys_list.entities.label = item_info.is_group_tv_film ? 'Productions' : 'Customers';
        }else{

            this.entity_details_keys_list.entity_active_users.value_func = function (item, key) {
                return item.active_users_count;
            };
            this.entity_details_keys_list.entity_disabled_users.value_func = function (item, key) {
                return item.inactive_users_count;
            };

        }
        this.entity_details_keys_list.entity_total_modules.value_func = function (item, key) {
            return item.hasOwnProperty('entity_modules_count') ? item.entity_modules_count : 0;
        };

        this.entity_details_keys_list.entity_total_users.value_func = function (item, key) {
            if (tab_name === 'customer_details'){
                return item.active_users_count + item.inactive_users_count;
            }else{
                return item[ (tab_name === 'group_details' ? 'group_' : '') + 'users_count'];
            }
        };

        this.entity_details_keys_list.entity_user_roles.value_func = function (item, key) {
            if (tab_name === 'customer_details'){
                return item.customer_roles_count;
            }else{
                return item[ (tab_name === 'group_details' ? 'group_' : '') + 'roles_count'];
            }
        };

        this.entity_details_keys_list.entity_assets.value_func = function (item, key) {
            return item.assets_count;
        };
        this.entity_details_keys_list.actions.value_func = function (item, key) {
            return '<a class="btn btn-primary btn-sm" href="/'+item_info.group+'/'+item_info.item_type+'/' + item_info.id + '/edit' +'">Edit "' + item_info.name + '"<i class="mdi mdi-arrow-right ml-1"></i></a>';
        };

        this.entity_details_keys_list.entities.value_func = function (item, key) {
            if (tab_name === 'customer_details'){
                return item.customer_warehouses_count;
            }else{
                return item.customers_count;
            }
        };

    }
}
</script>
