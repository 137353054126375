<template>
    <b-modal :id="'create-'+ (custom_id ? custom_id : '') + item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <date-picker
                    v-if="required_field.type === 'date'"
                    v-model="addForm[required_field.key]"
                    lang="en" :first-day-of-week="1"
                    format="DD-MM-YYYY"
                    value-type="DD-MM-YYYY"
                    :placeholder="required_field.label" />

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="name"
                    :disabled="required_field.key === 'ownership_type'"
                    :custom-label="CustomNameOrDesc"
                    :placeholder="'Select ' + required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options !== undefined ? required_field.options : []"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>

                <b-form-input
                    v-else-if="required_field.type === 'text'"
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide(('create-' + (custom_id ? custom_id : '') + item_type))">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const acquisition_typeResource = new Resource('api/assetflow/acquisition_types');
import DatePicker from "vue2-datepicker";
import multiSelect from '@/mixins/MultiSelect';

export default {
    mixins: [multiSelect],
    name: "AddNewAcquisitionType",
    props: ['modal_title', 'item_type', 'my_lists_values', 'without_status', 'custom_id'],
    components: {DatePicker},
    data(){
        return {
            lists_values: {},
            addForm:{
                name: '',
                acquisition_category_id:null,
                ownership_type:null,
                asset_state:null,
                ledger_id:null
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Acquisition Type Name',
                    type: 'text'
                },
                acquisition_category_id: {
                    key: 'acquisition_category_id',
                    label: 'Category',
                    type: 'single-select',
                    options: []
                },
                ownership_type: {
                    key: 'ownership_type',
                    label: 'Ownership Type',
                    type: 'single-select',
                    options:[]
                },
                asset_state: {
                    key: 'asset_state',
                    label: 'Asset State',
                    type: 'single-select',
                    options:[
                        { id: 1, name: 'New' },
                        { id: 0, name: 'Used' }
                    ]
                },
                ledger_id: {
                    key: 'ledger_id',
                    label: 'Ledger',
                    type: 'single-select',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null,
            select_items: [
                'acquisition_category_id',
                'ownership_type',
                'asset_state',
                'ledger_id'
            ]
        }
    },
    created() {
        this.lists_values = this.my_lists_values;
        this.setSelectOptions(true);
    },
    methods:{
        setSelectOptions(with_fetching = false){

            if (this.lists_values !== undefined && Object.keys(this.lists_values).length > 0){

                for (const selectItemsKey in this.select_items) {
                    let select_item = this.select_items[selectItemsKey];
                    if (this.required_items[select_item].options.length === 0){
                        this.required_items[select_item].options = this.lists_values[select_item];
                    }
                }
            }else if(with_fetching) {
                this.getFieldsList();
            }

        },
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                ledger_id: 'ledgers_list',
                acquisition_category_id: 'acquisition_categories_list',
                ownership_type: 'ownership_type_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }
                this.setSelectOptions();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                for (const selectItemsKey in this.select_items) {
                    let select_item = this.select_items[selectItemsKey];
                    let item_ID = this.selected_item[select_item];
                    let found_item = _.find(this.required_items[select_item].options, function(o) { return o.id === item_ID; });
                    this.addForm[select_item] = found_item ? found_item : null;
                }
            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                acquisition_category_id: this.addForm.acquisition_category_id ? this.addForm.acquisition_category_id.id : null,
                ownership_type: this.addForm.ownership_type ? this.addForm.ownership_type.id : null,
                asset_state: this.addForm.asset_state ? this.addForm.asset_state.id : null,
                ledger_id: this.addForm.ledger_id ? this.addForm.ledger_id.id : null
            };

            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await acquisition_typeResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-' + (this.custom_id ? this.custom_id : '') + this.item_type);
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item, this.item_type);
        },
        handleCategoryChanging(){
            let values_toFill = ['asset_state', 'ownership_type'];
            for (const valuesToFillKey in values_toFill) {
                let value_toFill = values_toFill[valuesToFillKey];
                if (this.addForm.acquisition_category_id.hasOwnProperty(value_toFill) && this.addForm.acquisition_category_id[value_toFill] !== null){
                    let value_toFill_id  = this.addForm.acquisition_category_id[value_toFill];
                    let found_item = _.find(this.required_items[value_toFill].options, function(o) { return o.id === value_toFill_id; });
                    this.addForm[value_toFill] = found_item ? found_item : null;
                }
            }
        }
    },
    watch:{
        selected_category: {
            deep: true,
            handler: function(newValue, oldVal){
                if (newValue && (!oldVal || oldVal.id !== newValue.id)){
                    this.handleCategoryChanging();
                }
            }
        },
    },
    computed:{
        selected_category(){
            return this.addForm.acquisition_category_id;
        },
    }
}
</script>


