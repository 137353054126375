<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             @show="getFieldsList"
             hide-footer>


        <ChangeCommonFields :add-form="addForm" :required_fields="required_fields"/>

        <div class="text-right">
            <b-button type="submit" variant="success" :disabled="isSavingItem" v-on:click.once="addItem($event)">
                <span v-show="!isSavingItem"> Update  </span>
                <div v-show="isSavingItem" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Saving...</span>
                </div>
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFields from "./ChangeCommonFields";

const characterResource = new Resource('api/assetflow/characters/editCharacter');
import ChangeFormsMixin from "@/mixins/ChangeFormsMixin";


export default {
    name: "AddSceneCharacter",
    props: ['modal_title', 'item_type'],
    mixins: [ChangeFormsMixin],
    components: {ChangeCommonFields},
    data(){
        return {
            part_index: null,
            addForm:{
                character_id: null,
            },
            required_fields:{
                character_id: {
                    key: 'character_id',
                    label: 'Character',
                    type: 'single-select',
                    custom_label: 'name',
                    options:[]
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                character_id: 'character_short_list',
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'assets');
                }

                this.fillSelectedItem();

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.setValueFromOptions('character_id', this.selected_item.character_id, null);

                if (this.selected_item.hasOwnProperty('part_index')){
                    this.part_index = this.selected_item.part_index;
                }
            }
        },
        resetFormValues(){
            this.addForm = {
                character_id: null,
            };
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.resetFormValues();
            }
        },

        async addItem(event){
            this.isSavingItem = true;

            var params = {
                item_id: this.addForm.character_id ? this.addForm.character_id.id : null,
                edit_type : 'assign_to_scene'
            };
            if (this.selected_item && this.selected_item.hasOwnProperty('part_id')){
                params.part_id = this.selected_item.part_id;
            }

            const { data } = await characterResource.store(params);

            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = (this.selected_item && this.selected_item.id !== -99 ? 'Edited' : 'Added') + ' Successfully';
                status_class = 'success';
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

            this.isSavingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();


            if (this.part_index !== null){
                this.$emit('on-new-item-adding', new_item, this.item_type, this.part_index, null);
            }else{
                this.$emit('on-new-item-adding', new_item, this.item_type);
            }

        }
    }
}
</script>
