<template>
    <OrderLineActions
        :parent_custom_classes="parent_custom_classes"
        :index="index"
        :item="item"
        :item_type="item_type"
        :object_type="permission_name"
        @on-perform-action="performAction"
        :deletion_condition="item.order_line_lines_count === 0"
        :permissions_list="permissions_list"
        :custom_slot_names="getCustomSlotNames(item, item.order_info)">

        <template v-slot:in_progress_actions>
            <b-dropdown-item v-if="permissions_list['edit_' + permission_name] && ![3,4].includes(item.status)"
                             @click="performAction(item, 'add_intake_profile', index)">
                {{ item ? 'Edit' : 'Add' }} Intake Profile
            </b-dropdown-item>
            <b-dropdown-item
                v-if="permissions_list['process_' + permission_name] && item.order_info.status === 1 && item.status !== 3"
                @click="performAction(item, 'process-order_line', index)">
                Complete Task
            </b-dropdown-item>
        </template>

        <template v-slot:in_progress_storage_order_actions>
            <b-dropdown-item
                v-if="permissions_list['process_' + permission_name] && item.order_info.status === 1 && item.status !== 3"
                @click="performAction(item, 'process-order_line', index)">
                Complete Task
            </b-dropdown-item>
        </template>

    </OrderLineActions>
</template>
<script>
import OrderLineActions from "./OrderLineActions"

export default {
    name: 'HandleOrderLineActionsAndSlots',
    components: {OrderLineActions},
    props: {
        index: {},
        item: {},
        item_type: {},
        performAction: {type: Function},
        getCustomSlotNames: {type: Function},
        permission_name: {},
        permissions_list: {},
        parent_custom_classes: null,
    }
}
</script>
