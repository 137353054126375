<template>
    <div class="DispositionRightBox" v-if="item_info">
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Recipient</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="recipient_type" :href="'/' + item_type + '/' + recipient_type + '/' + item_info.recipient_id + '/view'">{{ item_info.recipient_name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Pro Rata Per Unit</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_info.value && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.value)">{{getCurrencyByValue(item_info.currency).icon + item_info.value}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Pro Rata Total</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_info.total_value && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.total_value)">{{getCurrencyByValue(item_info.currency).icon + item_info.total_value}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Ref</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="reference">{{reference}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Date</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="created_at">{{ created_at | moment("MMMM Do YYYY") }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Source</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="source !== null">{{sources_list[source]}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Source ID</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="source_info">
                                    <a :href="'/' + item_type + '/'+source_info.item_type+'/'+source_info.id+'/view'">{{source_info.name}}</a>
                                </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Created By</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="asset_user_info">
                                    <a :href="'/' + item_type + '/users/'+asset_user_info.id+'/view'">{{asset_user_info.name}}</a>
                                </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div v-else class="DispositionRightBox">
        <div class="common_col_bg_box" v-if="character_info">
            <div class="row">
                <div class="col-md-12">
                    <a :href="'/' + item_type + '/characters/'+character_info.id+'/view'" class="badge text-af-primary af-soft-accent font-size-11 m-1">({{ character_info.character_number || '' }}) {{ character_info.name }}</a>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-12">
                    <div class="common-st-title-label">
                        <label>Final Disposition Planning</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Plan</span>
                        <span v-if="my_asset.hasOwnProperty('fdp_status') && my_asset.fdp_status !== 1"
                              class="add-fdp-action" style="float: right;">
                            <a href="javascript:void(0);" @click="performAction(my_asset, 'add_fdp_info', $event)">Final Disposition</a>
                        </span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a href="#" @click="performAction(my_asset, 'add_fdp_info', $event)"><span>{{ getSustainabilityActionFor(my_asset, true) }}</span></a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Status</span>
                        <p>
                            <span>
                                <i class="font-size-18 bx bxs-circle status-icon" :class="my_asset.fdp_status === 1 ? 'status-icon-active confirmed-fdp-action' : 'status-icon-planned waiting-fdp-action'"></i>
                                {{ my_asset.fdp_status === 1 ? 'Confirmed' : 'Waiting' }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Updated At</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="my_asset.hasOwnProperty('fdp_updated_at') && my_asset.fdp_updated_at">{{ my_asset.fdp_updated_at | moment('MMMM Do YYYY') }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Updated By</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="my_asset.hasOwnProperty('fdp_updated_by')">
                                <a v-if="my_asset.fdp_updated_by !== 0 && my_asset.hasOwnProperty('fdp_updated_by_user') && my_asset.fdp_updated_by_user"
                                   :href="'/' + item_type + '/users/'+my_asset.fdp_updated_by+'/view'"
                                   class="text-dark">{{ my_asset.fdp_updated_by_user.name | titleize}}</a>
                                <span v-else>AF Intelligence</span>
                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-12">
                    <div class="common-st-title-label">
                        <label>Register Disposition</label>
                    </div>
                    <button class="btn btn-primary btn-sm btn-block" @click="performAction(my_asset, 'returnAsset', $event)">Return</button>
                    <button class="btn btn-primary btn-sm btn-block" @click="performAction(my_asset, 'sellAsset', $event)">Sell</button>
                    <button class="btn btn-primary btn-sm btn-block" @click="performAction(my_asset, 'registerDisposition', $event)">More</button>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
export default {
    mixins: [AssetTabsShared],
    name: 'DispositionRightBox',
    props: {
        my_asset: {},
        item_type: {},
        performAction: {}
    },
    data(){
        return {
            sources_list:[
                'Asset',
                'Actions',
                'Orders',
                'Storage Orders',
            ]
        }
    },
    computed:{
        character_info(){
            return this.getValueOrDefault(this.my_asset, 'character_info', null);
        },
        item_info(){
            return this.getValueOrDefault(this.my_asset, 'disposition_info', null);
        },
        recipient_type(){
            return this.getValueOrDefault(this.item_info, 'recipient_type', null);
        },
        reference(){
            return this.getValueOrDefault(this.item_info, 'reference', null);
        },
        created_at(){
            return this.getValueOrDefault(this.item_info, 'disposition_date', null);
        },
        source(){
            return this.getValueOrDefault(this.item_info, 'source', null);
        },
        source_info(){
            return this.getValueOrDefault(this.item_info, 'source_info', null);
        },
        asset_user_info(){
            return this.getValueOrDefault(this.item_info, 'user_info', null);
        },
    },
    methods:{
        shallRegisterDisposition(event){
            window.flash('', 'info', 'added_disposition');
            window.location.href = '/' + this.item_type + '/assets/' + this.my_asset.id + '/edit';
        }
    }
}
</script>
