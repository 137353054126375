import Resource from '@/api/resource';
const stoResource = new Resource('api/assetflow/managed_storages');
const tagResource = new Resource('api/assetflow/tags');
const orderResource = new Resource('api/assetflow/orders');
const spStorageResource = new Resource('api/assetflow/sp_storages');

export default {
    props:{
    },
    data(){
        return {
            categories_list: [],
            tags_list: [],
            orders_list: [],
            storages_list: [],
            recent_storage_list: [],
            recent_storage_label: '',
            storage_id_listLoading: false,
        }
    },
    methods: {
        handleQueryItems(query, object_name='') {
            if (['order'].includes(object_name)){
                let object_info = this[object_name];
                query.sp_id = object_info.sp_id;
                query.current_customer_id = object_info.customer_id;
            }
            return query;
        },
        async getListOf(item_type, object_name) {
            let requiredResource = null;
            this.listQuery = this.handleQueryItems({}, object_name);
            switch (item_type) {
                case 'categories':
                    this.listQuery.group_by = 'category_id';
                    requiredResource = stoResource;
                    break;
                case 'tags':
                    this.listQuery.limit = 4;
                    requiredResource = tagResource;
                    break;
                case 'all_orders':
                    this.listQuery.status_id = 0;
                    this.listQuery.order_type = 1;
                    this.listQuery.order_by = 'created_at';
                    this.listQuery.order_dir = 'desc';
                    requiredResource = orderResource;
                    break;
            }

            if (requiredResource) {
                const {data} = await requiredResource.list(this.listQuery);
                let required_items = data.data.items;

                switch (item_type) {
                    case 'categories':
                        this.categories_list = required_items;
                        break;
                    case 'tags':
                        this.tags_list = required_items;
                        break;
                    case 'all_orders':
                        this.orders_list = required_items;
                        break;
                }
            }
        },

        async getSPCustomerStorage(customer_id, storage_id=null){

            const getSPCustomerStorageResource = new Resource('api/assetflow/customers/get_list');

            let queryList = {
                list_name: 'SPStorage',
                customer_id: customer_id,
                with_recent: true,
                with_all_info:true
            };
            const { data } = await getSPCustomerStorageResource.store(queryList);

            let storage_response = data.items;

            if (storage_response){
                let recent_storage_ids = storage_response.recent_storage_ids;
                let storage_list = storage_response.storage_list;
                if (storage_id){
                    storage_list = storage_list.filter(function (e) {
                        return e.id !== storage_id;
                    });
                }

                this.storages_list = storage_list;
                let recent_storage = storage_list.filter(function (e) {
                    return recent_storage_ids.includes(e.id);
                });

                this.recent_storage_list = recent_storage;
                if (recent_storage.length > 0){
                    this.recent_storage_label = 'Recent Storage';
                }else{
                    this.recent_storage_label = '';
                }

            }

            this.storage_id_listLoading = false;
        },
        appendOrderToOrdersList(newOrder){
            if (newOrder){
                this.orders_list.push(newOrder);
                this.setBulkActionOptions('selected_bulk_order', newOrder);
                if (this.$refs['bulk-actions-block']){
                    this.$refs['bulk-actions-block'].selected_modal_order = newOrder;
                }
            }
        },
    }
}
