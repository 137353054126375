<template>
    <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
            <div class="row">

                <EditChangeNotes
                    ref="edit-change-notes"
                    item_type='change-notes'
                    modal_title="Edit Change Notes"
                    :received_item_id="item_info.id"
                    @on-new-item-adding="reloadPage"
                >
                </EditChangeNotes>

                <NewCustomGenericBlock
                    :block_title="block_contents.description.label"
                    columns_count_withclasses="12"
                    action_required_title="Change Notes"
                    :action_required="addChangeNotes"
                    box_colour_v="change-list-description"
                >
                    <template v-slot:all_content>
                        <div v-if="item_info.hasOwnProperty('description') && item_info.description !== ''" class="">{{ item_info.description }}</div>
                    </template>
                </NewCustomGenericBlock>

                <SetChangeSceneDescription
                    ref="set-change-scene-description"
                    item_type='description-changes'
                    modal_title="Update Scene Notes"
                    @on-new-item-adding-in-list="AfterSettingDescription"
                >
                </SetChangeSceneDescription>

                <SetChangeSceneImages
                    ref="set-change-scene-images"
                    item_type='scene-note-images'
                    modal_id="create-scene-note-images"
                    modal_title="Update Scene Note Images"
                    @on-new-item-adding-in-list="AfterSettingImages"
                >
                </SetChangeSceneImages>

<!--                here? use this > Update Scene Notes SetChangeSceneDescription -->

                <div class="popup-gallery">
                    <vue-easy-lightbox
                        :visible="visibleCaption"
                        :imgs="captionimgs"
                        :index="index"
                        @hide="handleCaptionHide">
                    </vue-easy-lightbox>
                </div>

                <NewCustomGenericBlock
                    :block_title="block_contents.scenes_list.label"
                    columns_count_withclasses="12"
                    box_colour_v="-"
                >
                    <template v-slot:all_content>
                        <po-lines v-if="item_info.hasOwnProperty('parts_info') && item_info.parts_info && item_info.parts_info.length > 0"
                                  :items="sorted_parts_info"
                                  :current_page_type="item_info.current_page_type"
                                  :object_type="'parts'"
                                  :line_type="'parts'"
                                  :keys_list="part_keys_list">

                            <template v-slot:new_line="{ item, index }">
                                <div class="tbody_item col-xs-12 col-sm-4 col-lg-3 scene_notes scene_notes_gallery">
                                    <MyBCarouselImages v-if="item.hasOwnProperty('scene_note_images') && item.scene_note_images"
                                                       parent_class="cm_list_view_image"
                                                       :my_asset="item"
                                                       asset_images_name="scene_note_images"
                                                       :show-captiom-img="showCaptiomImg"
                                                       :required_image_size="'scene_notes_gallery'"/>
                                    </div>
                                    <div class="tbody_item col-xs-12 col-sm-8 col-lg-9 scene_notes">
                                    <ul class="scene-notes-header">
                                        <li class="scene-notes-heading">Scene Notes</li>
                                        <li class="scene-notes-images"><a href="javascript:void(0);" @click="updateImagesModal(item, index, 0, $event)">{{ item.part_change_asset_images ? item.part_change_asset_images : 'No' }} Image{{item.part_change_asset_images > 1 ? 's' : ''}}</a></li>
                                        <li class="scene-notes-modal-link"><a href="javascript:void(0);" @click="addDescriptionModal(item, index, 0, $event)">Update Scene Notes</a></li>

                                        <li class="scene-notes-sdl-link"><a :href="'/' + item_info.current_page_type + '/shoot-day-live?scene='+item.id+'&character='+item_info.character_id+'&change='+item_info.id" target="_blank">View in SDL</a></li>
                                        <li class="scene-notes-details-link"><a :href="'/' + item_info.current_page_type + '/changes/'+item_info.id + '/scene/' + item.id" target="_blank">View Details</a></li>
                                    </ul>
                                    <ul class="scene-notes-description">
                                        <li>{{ item.part_change_description ? item.part_change_description : 'No Scene Notes Yet' }}</li>
                                    </ul>
                                </div>
                            </template>

                            <template v-slot:appended_last_item>
                                <div class="table_inner_body d-flex-p parts-row table-footer">
                                    <div class="tbody_item col-xs-12 col-sm-12 col-lg-12 parts-row">

                                        <EditChangeScenes
                                            ref="edit-change-scenes"
                                            :item_type="'change-scenes' + unique_id"
                                            modal_title="Edit Change Scenes"
                                            :with_item_details="false"
                                            @on-new-item-adding="reloadPage"
                                        >
                                        </EditChangeScenes>

                                        <ul>
                                            <li v-html="getPageTitle()"></li><li><a href="#" @click="editChangeScenes()">Add Another Scene</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:parts_actions="{ item, index }">
                                <div class="btn-group bl-auto">
                                    <button type="button" class="btn btn-none dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                        <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <b-dropdown-item v-if="hasPermission('edit_' + permission_name)" :href="'./'+permission_name+'/'+item.id + '/edit'">Edit</b-dropdown-item>

                                        <b-dropdown-item v-if="hasPermission('delete_' + permission_name)"
                                                         variant="danger"
                                                         @click="performAction(item, 'delete', index, $event)">
                                            Delete
                                        </b-dropdown-item>
                                    </div>
                                </div>
                            </template>
                        </po-lines>
                        <div v-else class="text-center">
                            <h6>No Scenes found here at the moment.</h6>
                        </div>

                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

        <div class="col-lg-4 col-md-12 col-12">
            <div class="row" v-if="item_info">
                <NewCustomGenericBlock
                    block_title=""
                    :block_title_html="getTitleFor(item_info,'changes')"
                    box_colour_v="-"
                    columns_count_withclasses="12">
                    <template v-slot:all_content>
                        <ChangeRightBox :change_item_info="item_info" unique_id="overview"/>
                    </template>
                </NewCustomGenericBlock>
            </div>
        </div>

    </div>
</template>
<script>
import TimelineCommons from "@/mixins/TimelineCommons.mixin";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';

import NewCustomGenericBlock from "./widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import PoLines from "./widgets/detailsViews/PoLines";
import MyPostContent from "./widgets/detailsViews/PostContentTypes/MyPostContent";
import ChangeRightBox from "./ChangeRightBox";
import CustomGenericBlockContent from "./widgets/detailsViews/AssetTabs/CustomGenericBlockContent";

import {PartKeysList} from "@/views/assetflow/form_data/PartKeysList";
import SetChangeSceneDescription from "./ChangeSceneRel/SetChangeSceneDescription";
import EditChangeNotes from "../customers/extras/EditChangeNotes";
import EditChangeScenes from "../customers/extras/EditChangeScenes";
import MyBCarouselImages from "../assets/subItems/AssetLayouts/MyBCarouselImages";
import SetChangeSceneImages from "./ChangeSceneRel/SetChangeSceneImages";

export default {
    name: 'ChangeDetailsTab',
    components: {
        SetChangeSceneImages,
        VueEasyLightbox,
        MyBCarouselImages,
        EditChangeScenes,
        EditChangeNotes,
        SetChangeSceneDescription,
        CustomGenericBlockContent,
        ChangeRightBox,
        MyPostContent,
        PoLines,
        NewCustomGenericBlock
    },
    mixins:[TimelineCommons, ImageGallery],
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            unique_id: 'overview',
            sorted_parts_info:[],
            partSortType:{
                value: 0,
                text: 'Part Order',
                first_criteria: 'target_scene',
                first_reversed: false,
                name: 'part_order',
                second_criteria: 'scene_order',
                second_reversed: false,
                third_criteria: 'part_order',
                third_reversed: false
            },
            acquisition_type_info: null,
            block_contents: {
                description:{
                    label: 'Description',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-12',
                    info_list: []
                },
                scenes_list:{
                    label: 'Scenes',
                    has_action: false,
                    action_required: function () {},
                    items_class: 'col-md-12',
                    info_list: []
                },
            },
            part_keys_list: PartKeysList
        }
    },
    computed:{
    },
    created() {
        this.sorted_parts_info = this.item_info.parts_info;
        delete this.part_keys_list.actions;
        let item_info = this.item_info;
        this.part_keys_list.scene_id.value_func = function (item, key) {
            if (item.hasOwnProperty('scene_info') && item.scene_info){
                return '<a class="text-dark" href="/' + item_info.current_page_type + '/changes/' + item_info.id +'/scene/'+item.id+'">' + item.scene_info.name + ((item.name !== '' && item.name !== null) ? ' ' + item.name : '') + '</a>';
            }
            return '-';
        };
    },
    methods:{

        editChangeScenes(){
            let change = this.item_info;
            let change_info_for_modal = Object.assign({}, change);
            change_info_for_modal.part_ids = change.parts_info.map(function(e) { return e.id; });
            this.$refs['edit-change-scenes'].setSelectedItem(change_info_for_modal);
            this.$bvModal.show('create-change-scenes' + this.unique_id);
        },
        addChangeNotes(){
            let change = this.item_info;
            let change_info_for_modal = Object.assign({}, change);
            change_info_for_modal.change_status = change.status;

            this.$refs['edit-change-notes'].setSelectedItem(change_info_for_modal);
            this.$bvModal.show('create-change-notes');
        },
        getPageTitle(){
            return this.getTitleFor(this.item_info, 'changes');
        },
        updateImagesModal(item, item_index, change_index, event){
            this.$refs['set-change-scene-images'].setSelectedItem({
                asset_images: item.scene_note_images,
                item_id: item.part_change_id,
                item_index: item_index,
                change_index: change_index
            });
            this.$bvModal.show('create-scene-note-images');
        },
        AfterSettingImages(item_index, change_index, scene_note_images){
            if (this.item_info.parts_info.length > item_index){
                this.item_info.parts_info[item_index].scene_note_images = scene_note_images;
            }
        },
        addDescriptionModal(item, item_index, change_index, event){
            this.$refs['set-change-scene-description'].setSelectedItem({
                description: item.part_change_description,
                item_id: item.part_change_id,
                item_index: item_index,
                change_index: change_index
            });
            this.$bvModal.show('create-description-changes');
        },
        AfterSettingDescription(item_index, change_index, description){
            if (this.item_info.parts_info.length > item_index){
                this.item_info.parts_info[item_index].part_change_description = description;
            }
        },
    }
}
</script>
