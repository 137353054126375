<template>
    <Layout>
        <div class="asset_passports-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="'asset_passports'"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <div>
                        <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
                    </div>

                    <div class="product-page-header">
                        <div v-if="is_group">

                            <div class="customers-list">

                                <div class="main-root-top">
                                    <h3 class="filter_option">{{ selected_customer.name | titleize }}</h3>
                                    <ul class="root-folder filters_list" style="display: none; padding: 15px;">
                                        <li v-for="(customer_item, index) in customers_list" :class="'root-top-menu' + (selected_customer && selected_customer.id === customer_item.id ? ' active' : '')">
                                            <a @click="setSelectedCustomer(customer_item)">
                                                {{ customer_item ? customer_item.name : '-' }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <span class="arrow-right-icon"></span>
                                <div class="main-root-top">
                                    <h3>{{ (selected_item ? (selected_item.name) : '') | titleize }}</h3>
                                </div>

                            </div>

                        </div>
                        <h2 class="title">Asset Passports List</h2>
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Asset Passports.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem('asset_passport')">Asset Passport
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="page-value-info">
                            <ul class="d-flex align-items-center">
                                <li class="d-flex align-items-center">
                                    Total: <b>{{ request_meta ? request_meta.total : 0 }}</b>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="product-filter-option-wrapper clearfix d-flex">

                        <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                request_meta.from + '-' + request_meta.to
                            }}</span> of <span>{{ request_meta.total }}</span></div>

                        <div class="filter-wrap d-flex align-items-center ml-auto">

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Sort: <b>{{ sorting.sortType.text }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in sorting.sortOptions"
                                    :key="option.value"
                                    @click="saveSorting(option, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <b-dropdown class="option" variant="outline-secondary">
                                <template slot="button-content">
                                    Show: <b>{{
                                        perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                    }}</b>
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button
                                    v-for="option in perpage.pageOptions"
                                    :key="option.value"
                                    @click="savePerPage(option.value, $event)">
                                    {{ option.text | titleize }}
                                </b-dropdown-item-button>
                            </b-dropdown>

                            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                <div
                                    v-for="(layout, key) in supportedLayouts"
                                    :key="key"
                                    @click="changeObjectLayout(layout);"
                                    :id="'grid-list-'+layout.name.toLowerCase()"
                                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div v-if="selectedLayout === 1" class="row">
                        <div class="col-lg-12">

                            <div class="popup-gallery">
                                <vue-easy-lightbox
                                    :visible="visibleCaption"
                                    :imgs="captionimgs"
                                    :index="index"
                                    @hide="handleCaptionHide">
                                </vue-easy-lightbox>
                            </div>

                            <div class="cm_list_view_wrapper">
                                <div class="cm_list_view_wrapper">
                                    <AssetPassportListView
                                        v-for="(asset_passport, key) in sortedItemsList"
                                        :key="key"
                                        :asset_passport="asset_passport"
                                        :item_type="item_type"
                                        :show-captiom-img="showCaptiomImg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Product Section Start-->
                    <div v-else-if="selectedLayout === 0 && asset_passportsData" class="row">
                        <div class="col-lg-12">
                            <po-lines
                                :items="sortedItemsList"
                                :current_page_type="item_type"
                                :object_type="'asset_passports'"
                                :keys_list="asset_passport_list_keys_list">
                            </po-lines>
                        </div>
                    </div>

                    <div v-if="!asset_passportsData || total === 0 || sortedItemsList.length === 0"
                         class="products text-center">
                        <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                        <h4>No Asset Passports found here at the moment.</h4>

                    </div>
                    <!-- Product Section End-->
                    <div class="pagination">
                        <b-pagination
                            v-if="perpage.perPage !== 0"
                            v-model="perpage.currentPage"
                            :total-rows="request_meta.total"
                                @change="perpage.currentPage = $event; getList(selected_item ? selected_item : null)"
                            class="pagination d-flex align-items-center justify-content-center"
                            :per-page="perpage.perPage"
                        ></b-pagination>
                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import SearchBox from "@/components/search-box";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import {AssetPassportKeysList} from "@/views/assetflow/form_data/AssetPassportKeysList";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";

import AssetPassportListView from "./Layouts/AssetPassportListView";

const asset_passportResource = new Resource('api/assetflow/asset_passports');
import VueEasyLightbox from 'vue-easy-lightbox';
import ImageGallery from "@/mixins/ImageGallery.mixin";

export default {
    name: 'AssetPassportsList',
    props: ['item_id', 'item_type'],
    components: {AssetPassportListView, ObjectsSidebar, PoLines, SearchBox, Layout, VueEasyLightbox},
    mixins: [ObjectsSidebarShared, ImageGallery],
    data() {
        return {
            asset_passport_list_keys_list: AssetPassportKeysList,
            total: 0,
            asset_passportsData: [],
            request_meta: {},
            breadcrumb_items: [],
            title: "Asset Passports",
            items: [
                {
                    text: "Asset Passports",
                    href: "/"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_asset_passport_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'assets',
            object_type: 'asset_passports',
            groupBy_options: [
                {
                    id: 0,
                    label: 'Passport Type',
                    backend_key: 'quantity'
                },
                {
                    id: 1,
                    label: 'Category',
                    backend_key: 'category_id'
                },
            ],
            items_list: [
                // Passport Type
                [
                    {
                        id: 1,
                        label: 'Unique',
                        key: '1'
                    },
                    {
                        id: 2,
                        label: 'Shared',
                        key: '2'
                    }
                ],
                // Categories
                []
            ],
            loadableOptions: [
                {
                    index: 1, // index in group_items_list
                    object_name: 'categories'
                }
            ],
        };
    },
    created() {
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        this.has_asset_passport_actions_permissions = this.permissions_list['edit_' + this.permission_name] || this.permissions_list['delete_' + this.permission_name];

        if (this.has_asset_passport_actions_permissions){
            this.asset_passport_list_keys_list.actions = {
                key: 'actions',
                label: "Actions",
                class: "col-lg-1 po_actions",
                show_on_mobile: false,
            }
        }

        if (this.is_tv_film_asset){
            this.asset_passport_list_keys_list.customer_info.label = 'Production';
        }else{
            this.asset_passport_list_keys_list.customer_info.label = 'Customer';
        }

        if (this.item_type === 'customer') {
            delete this.asset_passport_list_keys_list.customer_info;
        }

        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_asset_passports_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.asset_passport_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.asset_passport_preferred_sorting;
        }

        this.getList(this.selected_item);
    },
    computed: {
        totalAssetsValue() {
            return this.asset_passportsData ? this.asset_passportsData.reduce((a, b) => +a + +b.quantity, 0) : 0;
        },
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {

        setSelectedCustomer(customer_item){
            this.selected_customer = customer_item;
            this.getList(this.selected_item);
        },
        checkAssetPassportStatus(asset_passport){
            return parseInt(asset_passport.assets_sum_quantity) !== 0;
        },
        performAction(asset_passport, action, asset_passportKey){
            if (action === 'edit'){
                this.$refs['add-new-asset_passport'].setSelectedItem(asset_passport);
                this.$bvModal.show('create-asset_passport');
            }else if (action === ('delete'+this.permission_name)){
                if (asset_passport.id !== '' && asset_passport.asset_passport_lines_count === 0){
                    this.deleteItemByID(asset_passport.id, asset_passport.name, this.object_type, asset_passport)
                        .then((response) => {
                            if (response.data.data.status){
                                this.asset_passportsData.splice(asset_passportKey, 1);
                            }else{
                                console.log("response.data");
                                console.log(response.data);
                            }
                        });
                }
            }
        },
        searchFor(keyword, asset_passport) {
            this.search_keyword = keyword;
            this.sortItemsList();
        },
        sortItemsList() {
            let filtered_list = [];
            if (this.search_keyword && this.search_keyword !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['uid', 'universal_asset_name', 'parent_category.name'];
                    return this.handleSearch(searchable_values, element, wordToCompare);
                }
                filtered_list = this.asset_passportsData.filter(startsWith(this.search_keyword));
                this.total = filtered_list.length;
            } else {
                filtered_list = this.asset_passportsData;
            }

             return filtered_list;
        },

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('asset_passport_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_asset_passports_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };

            this.listLoading = true;

            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if ([0, 1].includes(selected_item.id)){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            const {data} = await asset_passportResource.list(required_query);
            this.total = data.meta.total;
            this.asset_passportsData = data.data.items;
            this.request_meta = data.meta;
            this.listLoading = false;
        },

        addNewItem(asset_passport_type) {
            this.$refs['add-new-asset_passport'].setSelectedItem(null);
            this.$bvModal.show('create-asset_passport');
        }
    },
}
</script>


