<template>
    <div class="LabelLeftBox mt-1">
        <div class="row">
            <div class="col-md-12">
                <h3 class="label-preview">Label Preview</h3>

                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr v-if="labelAddForm.hasOwnProperty('with_entity_logo') && labelAddForm.with_entity_logo">
                            <th style="width: 40%;">Production</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('customer_info') && my_asset.customer_info" class="customer_info">{{ my_asset.customer_info.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr v-if="labelAddForm.hasOwnProperty('with_department') && labelAddForm.with_department">
                            <th style="width: 40%;">Department</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('department_info') && my_asset.department_info" class="department_info">{{ my_asset.department_info.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr v-if="labelAddForm.hasOwnProperty('with_entity_group_logo') && labelAddForm.with_entity_group_logo">
                            <th style="width: 40%;">Studio</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('group_info') && my_asset.group_info" class="group_info">{{ my_asset.group_info.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>

                        <tr v-if="labelAddForm.hasOwnProperty('with_wrap_box') && labelAddForm.with_wrap_box">
                            <th style="width: 40%;">Wrap Box</th>
                            <td>
                                <span v-if="my_asset.asset_group_info">{{ my_asset.asset_group_info.uid}}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr v-if="labelAddForm.hasOwnProperty('with_date') && labelAddForm.with_date">
                            <th style="width: 40%;">Label Date</th>
                            <td>{{ new Date() | moment('YYYY/MM/DD')}}</td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Label Created By</th>
                            <td>{{ user_info.name || '-' }}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row" v-if="labelAddForm.hasOwnProperty('asset_identification') && labelAddForm.asset_identification">
            <div class="col-md-12">
                <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Identification</b></p>

                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Asset ID</th>
                            <td>
                                <span>{{ my_asset.id }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Asset ID</th>
                            <td>
                                <span>{{ my_asset.uid }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Internal Reference</th>
                            <td>
                                <span>{{ my_asset.internal_reference || '-' }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">External Reference</th>
                            <td>
                                <span>{{ my_asset.external_reference || '-' }}</span>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row" v-if="labelAddForm.hasOwnProperty('with_usage') && labelAddForm.with_usage">
            <div class="col-md-12">
                <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Usage</b></p>
                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Category</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('categories_info') && my_asset.categories_info" class="categories_info">{{ my_asset.categories_info.map(category => category.name).join('>') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Folder</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('folders_info') && my_asset.folders_info" class="folders_info">{{ my_asset.folders_info.map(folder => folder.name).join('>') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Is Asset</th>
                            <td>
                                <span class="is_asset">{{ ['No', 'Yes', 'Unknown'][my_asset.is_asset] || '-' }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Is Hero</th>
                            <td>
                                <span class="is_hero">{{ ['No', 'Yes', 'Unknown'][my_asset.is_hero] || '-' }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Asset Usage</th>
                            <td>
                                <span class="asset_usage">{{ ['Off Camera', 'On Camera', 'Unknown'][my_asset.asset_usage] || '-' }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row" v-if="labelAddForm.hasOwnProperty('asset_details') && labelAddForm.asset_details">
            <div class="col-md-12">
                <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Details</b></p>

                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Created By</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('created_by') && my_asset.created_by" class="created_by">{{ my_asset.created_by.name }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Created Date</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('created_at') && my_asset.created_at" class="created_at">{{ my_asset.created_at | moment('YYYY/MM/DD') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Modified Date</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('updated_at') && my_asset.updated_at" class="updated_at">{{ my_asset.updated_at | moment('YYYY/MM/DD') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'LabelLeftBox',
    props: {
        item_type: {},
        my_asset: {},
        performAction: {},
        labelAddForm: {}
    },
}
</script>
