<template>
    <Layout>

        <AddNewSpLocation
            ref="add-new-sp_locations"
            item_type='sp_locations'
            modal_title="Create New SP Location"
            @on-new-item-adding="getList(selected_item)"
        >
        </AddNewSpLocation>

        <ParkStorageInLocation
            ref="park-location-item"
            modal_id="park-location-item"
            item_type='sp_locations'
            modal_title="Park Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-park-storage="refreshItem"
        >
        </ParkStorageInLocation>

        <RemoveStorageLocation
            ref="vacate-location-item"
            modal_id="vacate-location-item"
            item_type='sp_locations'
            modal_title="Remove Storage"
            @on-modal-cancel="selected_object_id = null"
            @on-remove-storage="refreshItem"
        >
        </RemoveStorageLocation>

        <div class="sp_locations-wrapper" id="wrapper">
            <objects-sidebar
                :sidebar_list="treeList"
                :is_listing_sidebar="is_listing_sidebar"
                :group-by_options="groupBy_options"
                :filter_type="current_groupBy"
                :item_type="item_type"
                :object_type="object_type"
                :is_group="is_group"
                :selected_item="selected_item"
                :selected_customer="selected_customer"
                @on-filter-changing="getList"
                @on-group-changing="getList"
            />

            <!-- Page-Content start-->
            <div id="page-content-wrapper">
                <div class="page-content-inner">
                    <PageHeaderCustom title="" :items="items" class="list-header-block" />
                    <div class="product-page-header">
                        <TopFiltersBlock
                            ref="TopFiltersBlock"
                            object_type="sp_locations"
                            current_title="Location List"
                            block_class="customers-warehouses-block warehouses-only"
                            :required_items.sync="required_items"
                            @onSelectingTopFilter="onSelectingTopFilter"
                        />
                        <div class="filter-searchbar dropdown">
                            <search-box :ref="'search-box'" @on-value-changed="searchFor"
                                        placeholder_text="Search Locations.."></search-box>
                            <b-dropdown v-if="hasPermission('add_'+permission_name)" dropleft
                                        class="add-filter" variant="danger">
                                <template slot="button-content">
                                    <span class="add-filter-button"></span>
                                </template>
                                <b-dropdown-item @click="addNewItem">Location
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>

                        <div class="page-value-info"></div>
                    </div>

                    <div class="current-page-filters">
                        <span v-if="selected_sp_warehouse && selected_sp_warehouse.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Warehouse: <b>{{ selected_sp_warehouse.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_warehouse')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_sp_space && selected_sp_space.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Space: <b>{{ selected_sp_space.name }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('selected_sp_space')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_item && selected_item.id !== null && current_groupBy_option" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ current_groupBy_option.label | titleizeToSingle }}: <b>{{ (selected_item.name || selected_item.label) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="selected_dashboard_item && selected_dashboard_item.id !== null" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">{{ selected_dashboard_item.label }}: <b>{{ (selected_dashboard_item.name) | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="resetFilter('dashboard_item')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                        <span v-if="search_keyword && search_keyword !== ''" class="badge d-inline-flex align-items-center badge-primary" style="background-color: #d3292c;">
                            <span class="flex-grow-1 text-truncate text-white">Keywords: <b>{{ search_keyword | titleize }}</b></span>
                            <button type="button" class="close ml-1" @click="setSearchKeyword('')"><i class="text-white bx font-size-22 bx-x"></i></button>
                        </span>
                    </div>

                    <div class="tab_menu_item">
                        <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li v-for="tab in listTabs" class="nav-item">
                                <a class="nav-link"
                                   @click="tabClicked(tab.id)"
                                   :class="isActiveTab(tab.id)"
                                   data-toggle="tab" :href="'#'+tab.id" role="tab">
                            <span class="d-inline-block d-sm-none">
                                <i :class="'fas fa-'+tab.icon"></i>
                            </span>
                                    <span class="d-none d-sm-inline-block">
                                {{ tab.name | titleize}}
                            </span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div class="tab-content tab_content_design">
                        <div class="sidebad-toggle af-accent"></div>
                        <div class="tab-pane" id="list_dashboard" role="tabpanel" :class="isActiveTab('list_dashboard')">
                            <ListItemsDashboardTab :dashboard_info="dashboard_info" @on-dashboard-clicked="dashboardItemClicked" item_name="Locations"/>
                        </div>

                        <div class="tab-pane" id="list_items" role="tabpanel" :class="isActiveTab('list_items')">

                            <div class="product-filter-option-wrapper clearfix d-flex">

                                <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                                        request_meta.from + '-' + request_meta.to
                                    }}</span> of <span>{{ request_meta.total }}</span></div>

                                <div class="filter-wrap d-flex align-items-center ml-auto">

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Sort: <b>{{ sorting.sortType.text }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in sorting.sortOptions"
                                            :key="option.value"
                                            @click="saveSorting(option, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <b-dropdown class="option" variant="outline-secondary">
                                        <template slot="button-content">
                                            Show: <b>{{
                                                perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                                            }}</b>
                                            <i class="mdi mdi-chevron-down"></i>
                                        </template>
                                        <b-dropdown-item-button
                                            v-for="option in perpage.pageOptions"
                                            :key="option.value"
                                            @click="savePerPage(option.value, $event)">
                                            {{ option.text | titleize }}
                                        </b-dropdown-item-button>
                                    </b-dropdown>

                                    <div class="grid-list-button d-flex align-items-center" style="float: right;">
                                        <div
                                            v-if="supportedLayouts && supportedLayouts.length > 0"
                                            v-for="(layout, key) in supportedLayouts"
                                            :key="key"
                                            @click="changeObjectLayout(layout);"
                                            :id="'grid-list-'+layout.name.toLowerCase()"
                                            :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- Product Section Start-->
                            <LotsOfItemsAlert :perpage="perpage"/>

                            <div v-if="listLoading" class="products text-center">
                                <b-spinner class="text-af-accent m-2"></b-spinner>
                                <h4>Fetching Locations...</h4>
                            </div>
                            <div v-else-if="!sp_locationsData || total === 0 || sortedItemsList.length === 0"
                                 class="products text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>No locations found here at the moment.</h4>

                            </div>
                            <div v-else-if="selectedLayout === 0 && sp_locationsData" class="row">

                                <div class="col-lg-12">
                                    <BulkActionsBar
                                        :is_actions_bar_opened="is_actions_bar_opened"
                                        :is_selecting_items="is_selecting_items"
                                        :shouldToggleSelecting="shouldToggleSelecting"
                                        :selected_objects="selected_objects"
                                        :continueClicked="continueClicked"
                                        :performSelectionAction="performSelectionAction"
                                        :should_hide_unselected="should_hide_unselected"
                                        :enableBulkActions="enableBulkActions"
                                        :all_items_count="total"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                    />
                                </div>

                                <div class="col-lg-12">
                                    <po-lines
                                        :items="sortedItemsList"
                                        :current_page_type="item_type"
                                        :object_type="permission_name"
                                        :line_type="object_type"
                                        :get-poline-classes-dict="getPoLineClassForObject"
                                        :keys_list="sp_location_keys_list">

                                        <template v-slot:bulks_actions_block="{ item, index }">
                                            <div class="col-lg-12 items-selection-block p-2" :class="object_type + '-selection-block'">
                                                <SelectableItemActions
                                                    :has_customer="false"
                                                    :add-to-selected-list="addToSelectedList"
                                                    :item="item"
                                                    :bulk_actions_customer_id="bulk_actions_customer_id"
                                                    :is_actions_bar_opened="is_actions_bar_opened"
                                                    :preform-bulk-action="preformBulkAction"
                                                    :selected_ids="selected_ids"/>
                                            </div>

                                        </template>

                                        <template v-slot:sp_locations_actions="{ item, index }">
                                            <div class="btn-group bl-auto">
                                                <button type="button" class="btn btn-none dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                                </button>
                                                <SpLocationActions
                                                    :index="index"
                                                    :item="item"
                                                    :permission_name="permission_name"
                                                    :permissions_list="permissions_list"
                                                    :perform-action="performAction"
                                                    :show-modal="showModal"
                                                />

                                            </div>
                                        </template>
                                    </po-lines>
                                </div>
                            </div>


                            <!-- Product Section End-->
                            <div class="pagination">
                                <b-pagination
                                    v-if="request_meta && perpage.perPage !== 0"
                                    v-model="perpage.currentPage"
                                    :total-rows="request_meta.total"
                                    @change="goToPage($event)"
                                    class="pagination d-flex align-items-center justify-content-center"
                                    :per-page="perpage.perPage"
                                ></b-pagination>
                            </div>

                        </div>


                        <div class="tab-pane" id="list_add" role="tabpanel" :class="isActiveTab('list_add')">
                            <SpItemAddTab v-if="hasPermission('add_'+permission_name)" @on-item-add="addNewItem" menu_title="Add Locations" object_name="Location"/>
                            <div v-else class="text-center">
                                <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
                                <h4>Nothing to see here.</h4>
                            </div>
                        </div>

                        <div class="tab-pane" id="export_item" role="tabpanel" :class="isActiveTab('export_item')">
                            <div class="row">
                                <div class="col-12">
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="export_required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="item_type"
                                        :is-selected="isSelected"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="selected_customer"
                                        :all_items_count="total"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                        :has_customer="false"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-if="can_bulk_update" class="tab-pane" id="list_bulk_actions" role="tabpanel" :class="isActiveTab('list_bulk_actions')">
                            <div class="row">
                                <div class="col-12">
                                    <BulkActionsBlock
                                        ref="bulk-actions-block"
                                        :required_actions="required_actions"
                                        :object_type="object_type"
                                        :is_selecting_items="is_selecting_items"
                                        :selected_objects="selected_objects"
                                        :add-to-selected-list="addToSelectedList"
                                        :perform-bulk-action="performBulkAction"
                                        :item_type="item_type"
                                        :is-selected="isSelected"
                                        :toggle-accordion="toggleAccordion"
                                        :selected_items_mode="selected_items_mode"
                                        :setSelectedItemsMode="setSelectedItemsMode"
                                        :startSelectingItems="startSelectingItems"
                                        :setBulkActionOptions="setBulkActionOptions"
                                        :selected_customer="selected_customer"
                                        :all_items_count="total"
                                        :bulk_actions_customer_id="bulk_actions_customer_id"
                                        :listLoading="listLoading"
                                        :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                                        :has_customer="false"
                                    />
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <!-- Page-Content end-->

        </div>
    </Layout>
</template>

<script>

import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {AddressesSortOptions} from "@/views/assetflow/form_data/AddressesSortOptions";
import PoLines from "../../items/widgets/detailsViews/PoLines";


import ObjectsSidebar from "../ObjectsSidebar/ObjectsSidebar";
import ObjectsSidebarShared from "@/mixins/ObjectsSidebarShared.mixin";
import TimelineCommons from "@/mixins/TimelineCommons.mixin";

import {SPLocationKeysList} from "@/views/assetflow/form_data/SPLocationKeysList";
import LotsOfItemsAlert from "../../assets/subItems/LotsOfItemsAlert";
import AddNewSpLocation from "./AddNewSpLocation";
import SearchBox from "@/components/search-box";
import PageHeaderCustom from "../../../../components/page-header-custom";
import ListItemsDashboardTab from "../sp_items_shared/ListItemsDashboardTab";
import SpItemExportTab from "../sp_items_shared/SpItemExportTab";
import SpItemAddTab from "../sp_items_shared/SpItemAddTab";
import TopFiltersBlock from "../sp_storages/TopFiltersBlock";
import TopFiltersMixin from "@/mixins/TopFiltersMixin.mixin";
import RemoveStorageLocation from "../sp_storages/RemoveStorageLocation";
import ParkStorageInLocation from "./ParkStorageInLocation";
import SpLocationActions from "./SpLocationActions";
import SpLocationShared from "@/mixins/SpLocationShared.mixin";


import BulkActionsBar from "../managed_storages/BulkActionsBar";
import BulkActionsBlock from "../managed_storages/BulkActionsBlock";

import SharedBulkUpdate from "@/mixins/SharedBulkUpdate.mixin";
import SharedBulkUpdateFetchLists from "@/mixins/SharedBulkUpdateFetchLists.mixin";
import SelectableItemActions from "../managed_storages/SelectableItemActions";


const sp_locationResource = new Resource('api/assetflow/sp_locations');


export default {
    name: 'LocationList',
    props: ['item_id', 'item_type'],
    components: {
        SelectableItemActions,
        BulkActionsBlock,
        BulkActionsBar,
        SpLocationActions,
        ParkStorageInLocation,
        RemoveStorageLocation,
        TopFiltersBlock,
        SpItemAddTab,
        SpItemExportTab,
        ListItemsDashboardTab,
        PageHeaderCustom, AddNewSpLocation, LotsOfItemsAlert, ObjectsSidebar, PoLines, SearchBox, Layout},
    mixins: [SharedBulkUpdate, SharedBulkUpdateFetchLists, TimelineCommons, TopFiltersMixin, ObjectsSidebarShared, SpLocationShared],
    data() {
        return {
            required_actions: [],
            export_required_actions: [],
            total: 0,
            sp_locationsData: [],
            request_meta: {},
            listLoading: false,
            breadcrumb_items: [],
            title: "Locations List",
            items: [
                {
                    text: "Asset Flow",
                    href: "/"
                },
                {
                    text: "Locations",
                    href: "#"
                },
                {
                    text: "List",
                    active: true
                }
            ],
            listQuery: {
                page: 1,
                limit: 100
            },
            has_sp_location_actions_permissions: false,
            selected_item: null,
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            search_keyword: '',
            sorting: {
                sortType: {
                    value: 1,
                    text: 'A~Z',
                    name: 'name',
                    reversed: false
                },
                sortOptions: AddressesSortOptions,
            },
            permissions_list:{},
            permission_name: 'sp_locations',
            object_type: 'sp_locations',
            object_type_list_active_tab: 'sp_locations_list_active_tab',
            groupBy_options: [
                {
                    id: 0,
                    label: 'All',
                    is_all:true,
                    sidebar_list_class: 'all'
                },
                {
                    id: 1,
                    label: 'Statuses',
                    backend_key: 'status_id'
                },
            ],
            items_list: [
                [],
                // status
                []
            ],
            loadableOptions: [
                {
                    index: 1, // index in items_list
                    object_name: 'item_status'
                },
            ],
            sp_location_keys_list: SPLocationKeysList,

            active_tab: 'list_dashboard',
            totals_only:0,
            dashboard_info: null,
            listTabs:{
                list_dashboard:{
                    'id': "list_dashboard",
                    'name': 'Dashboard',
                    'icon': "user",
                    'isActive': true
                },
                list_items:{
                    'id': "list_items",
                    'name': 'Locations',
                    'icon': "user",
                    'isActive': false
                },
                list_add:{
                    'id': "list_add",
                    'name': 'Add',
                    'icon': "user",
                    'isActive': false
                },
                export_item:{
                    'id': "export_item",
                    'name': 'Export',
                    'icon': "user",
                    'isActive': false
                }
            },
        };
    },

    mounted() {
        let queryVars = this.getQueryVars();
        if ("s" in queryVars && queryVars["s"] !== '') {
            let search_keyword = decodeURI(queryVars["s"]);
            this.search_keyword = search_keyword;
            this.$refs['search-box'].setInputValue(search_keyword);
            this.getList(this.selected_item);
        }
        /*Function Calls*/
        this.setupJQueryMethods();
    },
    created() {


        this.tab_list_name = 'listTabs';
        if (this.hasPermission('can_export_sp_locations')){
            this.appendExportItemsTab(this.tab_list_name);
            this.export_required_actions.push('export_sp_locations');
        }

        if (this.user_info && this.user_info.hasOwnProperty('can_bulk_update')){
            // shall check for other bulk update actions' permissions
            if (this.user_info.can_bulk_update === 1 && this.hasPermission('can_export_sp_locations')){
                this.appendBulkActionsTab(this.tab_list_name);
                this.can_bulk_update = true;
            }
        }

        this.required_items = ['sp_warehouses', 'sp_spaces'];

        this.sorting.sortOptions[0].name = 'uid';
        this.sorting.sortOptions[1].name = 'uid';
        // Force set Supported Layouts
        this.supportedLayouts = {
            list: {
                name: 'List',
                value: 0,
                class: 'list',
                isActive: false
            },
            // grid: {
            //     name: 'Grid',
            //         value: 1,
            //         class: 'grid',
            //         isActive: true
            // }
        };
        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);


        var preferredItemsPerPage = window.USER_INFO.user_meta.preferred_sp_locations_per_page;
        preferredItemsPerPage = this.setDefaultPerPage(preferredItemsPerPage);
        if ((preferredItemsPerPage && /^\d+$/.test(preferredItemsPerPage)) || preferredItemsPerPage === 0) {
            preferredItemsPerPage = parseInt(preferredItemsPerPage);
            this.perpage.perPage = preferredItemsPerPage;
            this.perpage.perPageStr = preferredItemsPerPage === 0 ? 'All' : preferredItemsPerPage;
            this.perpage.pageOptions = this.setPerPageOptions(this.perpage.pageOptions, preferredItemsPerPage);
        }

        if (window.USER_INFO.user_meta.sp_location_preferred_sorting) {
            this.sorting.sortType = window.USER_INFO.user_meta.sp_location_preferred_sorting;
        }


        let sp_items_list_active_tab = localStorage.getItem(this.object_type_list_active_tab);
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'dashboard':
                    this.active_tab = 'list_dashboard';
                    break;
                case 'items':
                    this.active_tab = 'list_items';
                    break;
                case 'add':
                    this.active_tab = 'list_add';
                    break;
                case 'export':
                    this.active_tab = 'export_item';
                    break;
                case 'bulk_actions':
                    this.active_tab = this.can_bulk_update ? 'list_bulk_actions' : 'list_items';
                    break;
            }
        }else if (sp_items_list_active_tab && sp_items_list_active_tab !== ''){
            if (sp_items_list_active_tab === 'list_bulk_actions' && !this.can_bulk_update){
                this.active_tab = 'list_items';
            }else{
                this.active_tab = sp_items_list_active_tab;
            }
        }
        if (this.permissions_list[('edit_' + this.permission_name)]){
            this.sp_location_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 war_sp_locations_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_locations_actions'
            };
        }

        if (!this.permissions_list[('add_' + this.permission_name)]){
            delete this.listTabs.list_add;
            delete this.listTabs.export_item;
            this.active_tab = ['list_add', 'export_item'].includes(this.active_tab) ? 'list_dashboard' : this.active_tab;
            localStorage.setItem(this.object_type_list_active_tab, this.active_tab);
        }

        this.getActiveTabInfo(this.active_tab);

    },
    watch:{
        bulk_actions_customer_id: {
            deep: true,
            handler: function (newValue) {
                if (this.bulk_actions_customer_id){
                    this.getSPCustomerStorage(this.bulk_actions_customer_id);
                }
            }
        },
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    methods: {
        refreshItem(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }

            if (['park-location-item', 'vacate-location-item'].includes(modal_id)){
                this.getObjectsList('item_status', 1, this.current_groupBy === 1);
                if (this.selected_item && this.selected_item.id !== null){
                    this.getList(this.selected_item);
                    return;
                }
            }

            let index = _.findIndex(this.sp_locationsData, {id: edited_asset.id});

            if (index > -1) {
                if (required_keys){
                    for (const requiredKeysKey in required_keys) {
                        let required_key = required_keys[requiredKeysKey];
                        this.sp_locationsData[index][required_key] = edited_asset[required_key];
                    }
                }else{
                    if(edited_asset.hasOwnProperty('storage_info') && edited_asset.storage_info){
                        // shall refresh all other storages that have same current location id
                        this.sp_locationsData = this.sp_locationsData.map(function (sp_storage) {
                            if (
                                sp_storage.hasOwnProperty('storage_info') &&
                                sp_storage.storage_info &&
                                edited_asset.hasOwnProperty('storage_info') &&
                                sp_storage.storage_info.id === edited_asset.storage_info.id
                            ){
                                sp_storage.current_location_id = null;
                                sp_storage.location_status = 0;
                            }
                            return sp_storage;
                        })
                    }

                    this.$set(this.sp_locationsData, index, edited_asset);
                }
            }else{
                console.log("index is -1?");
            }
        },

        exportLocations(){
            console.log("shall export??");
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
        tabClicked(tab_id){
            let older_active_tab = this.active_tab;
            this.active_tab = tab_id;
            localStorage.setItem(this.object_type_list_active_tab, tab_id);
            this.getActiveTabInfo(tab_id, older_active_tab);
        },
        getActiveTabInfo(tab_id, older_active_tab=''){
            switch (tab_id) {
                case 'list_dashboard':
                case 'list_items':
                    // refresh list_dashboard and list_items?
                    if (older_active_tab !== 'list_bulk_actions'){
                        // don't reload content if older tab was bulk actions
                        if (!this.is_content_loaded_using_queue){
                            this.getList(this.selected_item);
                        }
                    }
                    break;
                case 'list_add':
                    // shall get info of list_add?
                    break;
                case 'export_item':
                    // shall get info of export_item?
                    break;
                case 'list_bulk_actions':
                    if (older_active_tab === ''){
                        if (!this.is_content_loaded_using_queue){
                            this.getList(this.selected_item);
                        }
                    }
                    break;
            }
        },
        goToPage(page){
            this.perpage.currentPage = page;
            this.getList(this.selected_item ? this.selected_item : null)
        },
        addNewItem() {
            this.$refs['add-new-sp_locations'].setSelectedItem(null);
            this.$bvModal.show('create-sp_locations');
        },
        searchFor(keyword, event) {
            this.search_keyword = keyword;
            this.getList(this.selected_item);
        },
        sortItemsList() {
            if (this.is_actions_bar_opened && this.should_hide_unselected){
                let strongThis = this;
                return this.sp_locationsData.filter(function(asset) {
                    return strongThis.selected_ids.includes(asset.id);
                });
            }else{
                return this.sp_locationsData;
            }
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta('sp_location_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_sp_locations_per_page', perPage, message);
            this.refreshContent();
        },
        async getList(selected_item="", changing_type='group') {
            this.request_meta = null;
            this.resetSelectingItems();
            if (selected_item === ''){
                selected_item = this.customer_item;
            }
            this.resetCurrentPage(changing_type, selected_item);

            var required_query = {
                page: this.perpage.currentPage,
                limit: this.perpage.perPage,
                sort_by: this.sorting.sortType.name,
                sort_dir: this.sorting.sortType.reversed ? 'desc' : 'asc',
            };


            let active_tab = localStorage.getItem(this.object_type_list_active_tab);
            if (!active_tab) {
                active_tab = 'list_dashboard';
            }

            this.totals_only = active_tab === 'list_dashboard' ? 1 : 0;

            this.listLoading = true;


            // >>> shall be edited <<<

            if (changing_type === 'filter') {
                this.current_groupBy = parseInt(selected_item.id);
                this.current_groupBy_option = selected_item;

                localStorage.setItem(this.object_type + '_filter_type' + (this.is_group ? '_group' : ''), this.current_groupBy + '');

                if (this.items_list.length > selected_item.id){
                    this.treeList = this.items_list[selected_item.id];
                }
                selected_item = null;
                this.selected_item = null;
            }
            required_query = this.SBHandleQueryItems(required_query, selected_item);

            required_query = this.addPaginationParams(required_query);
            required_query.s_keyword = this.search_keyword;
            required_query.totals_only = this.totals_only;

            required_query = this.appendTopFiltersValues(required_query);
            const {data} = await sp_locationResource.list(required_query);


            if (Object.keys(data.data).includes('status')) {
                if (this.totals_only) {
                    // shall refresh totals
                    this.dashboard_info = data.data.items;
                    this.listLoading = false;
                    return;
                }
            }

            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.sp_locationsData = data.data.items;
                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                console.log('Response Error');
            }
        },

    },
}
</script>
