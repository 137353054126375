export const DateFormats = [
    {
        value: 'd/m/Y',
        text: "DD/MM/YYYY"
    },
    {
        value: 'm/d/Y',
        text: "MM/DD/YYYY"
    },
    {
        value: 'm-d-Y',
        text: "MM-DD-YYYY"
    },
    {
        value: 'd-m-Y',
        text: "DD-MM-YYYY"
    }
];
