<template>
    <div>
        <!-- Get Asset Details using AJAX, according to selected task! -->
        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="'overview-action-tasks'"
            :sub_modal_id="'actions'"
            modal_title="Complete task"
            :received_asset_info="item_info"
            @on-post-adding="postAdded"
            @on-completing-task="taskCompleted"
        />

        <!-- Intelligence Template -->
        <div class="intelligence-template">
            <div class="row overview-block">
                <div class="col-12 col-lg-8">
                    <div class="row">


                        <NewCustomGenericBlock
                            :block_title="block_contents.asset_description.label"
                            columns_count_withclasses="6">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_description"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.asset_properties.label"
                            columns_count_withclasses="6">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_properties"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.asset_details.label"
                            columns_count_withclasses="6">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_details"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            v-if="is_tv_film_asset"
                            :block_title="block_contents.asset_usage.label"
                            columns_count_withclasses="6"
                            :action_required_title="block_contents.asset_usage.action_required_title"
                            :action_required="block_contents.asset_usage.action_required"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_usage"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.accounts.label"
                            columns_count_withclasses="6"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.accounts"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            :block_title="block_contents.asset_returns.label"
                            columns_count_withclasses="6">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_returns"/>
                            </template>
                        </NewCustomGenericBlock>

                        <NewCustomGenericBlock
                            :block_title="block_contents.costs.label"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.costs"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            :block_title="block_contents.asset_supplier.label"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.asset_supplier"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            :block_title="block_contents.acquisition.label"
                            columns_count_withclasses="12"
                            :action_required_title="block_contents.acquisition.action_required_title"
                            :action_required="block_contents.acquisition.action_required"
                        >
                            <template v-slot:all_content>
                                <AcquisitionAllBox :my_asset="item_info" :item_type="item_info.current_page_type" item_columns="col-md-3"/>
                            </template>
                        </NewCustomGenericBlock>


                        <NewCustomGenericBlock
                            v-if="item_info.status === 'disposed'"
                            :block_title="block_contents.disposition.label"
                            columns_count_withclasses="12"
                        >
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.disposition"/>
                            </template>
                        </NewCustomGenericBlock>

                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row">

                        <NewCustomGenericBlock
                            :block_title="item_info.name"
                            :block_title_icon="item_info.ownership_type === 1 ? 'bx bx-cube-alt' : 'bx bx-time'"
                            box_colour_v="-"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <asset-right-box :item_info="item_info" unique_id="asset-all-overview"/>
                            </template>
                        </NewCustomGenericBlock>
                        <NewCustomGenericBlock
                            :block_title="block_contents.liability.label"
                            :box_colour_v="'common_sub_col_bg color_v_accent ' + (item_info.ownership_status === 1 ? 'ownership-status-current ownership-status-current-bg' : 'ownership-status-ended ownership-status-ended-bg')"
                            columns_count_withclasses="12">
                            <template v-slot:all_content>
                                <CustomGenericBlockContent :block_contents="block_contents.liability"/>
                            </template>
                        </NewCustomGenericBlock>



                    </div>

                </div>
            </div>
        </div>

        <!-- Intelligance Template Close-->
    </div>

</template>

<script>

import AssetRightBox from "./AssetRightBox";
import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import simplebar from "simplebar-vue";
import AssetLocationBlock from "./AssetBlocks/AssetLocationBlock";
import AfidPropertiesBlock from "./AfidPropertiesBlock";
import AssetAssetflowBlock from "./AssetAssetflowBlock";
import AssetRelatedSplits from "./AssetRelatedSplits";
import NewCustomGenericBlock from "./NewCustomGenericBlock";
import GenericTasksForm from "./GenericTasksForm";
import AssetTasks from "@/mixins/AssetTasks.mixin";
import {AcquisitionOptions} from "@/views/assetflow/form_data/AcquisitionOptions";
import CustomGenericBlockContent from "./CustomGenericBlockContent";
import CustomGenericBlock from "./CustomGenericBlock";
import AcquisitionAllBox from "../../../../assets/subItems/AssetLayouts/AcquisitionAllBox";
import {AcquisitionMethodsList} from "@/views/assetflow/form_data/AcquisitionMethodsList";

export default {
    mixins:[AssetTabsShared, AssetTasks],
    name: "AssetOverviewTab",
    components: {
        AcquisitionAllBox,
        CustomGenericBlock,
        CustomGenericBlockContent,
        GenericTasksForm,
        NewCustomGenericBlock,
        AssetRelatedSplits,
        AssetAssetflowBlock,
        AfidPropertiesBlock,
        AssetLocationBlock,
        AssetRightBox,
        simplebar
    },
    props:['item_info', 'post_meta_keys'],
    data(){
        return {
            completed_tasks_list: [],
            uncompleted_tasks_list: [],
            sustainability_type: '',
            block_contents: {
                asset_description:{
                    label: 'Description',
                    has_action: false,
                    items_class: 'col-md-12',
                    info_list: []
                },
                asset_properties:{
                    label: 'Properties',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                asset_details:{
                    label: 'Details',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                asset_usage:{
                    label: 'Film & TV',
                    action_required_title: 'Set Usage',
                    action_name: 'Film & TV',
                    has_action: true,
                    action_required: function () {},
                    items_class: 'col-md-4',
                    info_list: []
                },
                accounts:{
                    label: 'Accounts',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                asset_returns:{
                    label: 'Returns',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                },
                costs:{
                    label: 'Costs',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                asset_supplier:{
                    label: 'Supplier',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                acquisition:{
                    label: 'Acquisition',
                    action_required_title: 'Fill Acquisition',
                    action_name: 'Fill Acquisition',
                    has_action: true,
                    action_required: function () {},
                    items_class: 'col-md-3',
                    info_list: []
                },
                disposition:{
                    label: 'Disposition',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-4',
                    info_list: []
                },
                liability:{
                    label: 'Ownership',
                    action_required_title: '',
                    action_name: '',
                    has_action: false,
                    items_class: 'col-md-6',
                    info_list: []
                }
            }
        }
    },
    created() {
        let item_info = this.item_info;
        let used_functions = {
            POWithUIDs: this.POWithUIDs,
            getAcquisitionUsing: this.getAcquisitionUsing,
            acquisition_methods: AcquisitionMethodsList,
            getAcquisitionType: this.getAcquisitionType,
            getCurrencyByValue: this.getCurrencyByValue,
            toUserCurrencyValue: this.toUserCurrencyValue,
            getTaskFor: this.getTaskFor,
            completeTask: this.completeTask,
            post_meta_keys: this.post_meta_keys,
        }



        // asset_usage
        let asset_usage_block = this.block_contents.asset_usage;
        this.block_contents.asset_usage.label = (this.current_customer ? this.current_customer.name : 'Asset') + ' Usage';
        this.block_contents.asset_usage.action_required = function () {
            let event_task = used_functions.getTaskFor(asset_usage_block.action_name);
            if (event_task){
                used_functions.completeTask(event_task, 'overview-action-tasks');
            }
        };
        this.block_contents.asset_usage.info_list = [
            {
                label: 'Usage',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return used_functions.post_meta_keys;
                },
                info_value : function () {
                    return used_functions.post_meta_keys['asset_usage'].cast_value(item_info, 'asset_usage');
                }
            },
            {
                label: 'Is Hero',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return used_functions.post_meta_keys;
                },
                info_value : function () {
                    return used_functions.post_meta_keys['is_hero'].cast_value(item_info, 'is_hero');
                }
            },
            {
                label: 'Is Asset',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return used_functions.post_meta_keys;
                },
                info_value : function () {
                    return used_functions.post_meta_keys['is_asset'].cast_value(item_info, 'is_asset');
                }
            },
        ];






        this.block_contents.asset_description.info_list = [
            {
                label: 'Description',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.description;
                },
                info_value : function () {
                    return item_info.description;
                }
            },
        ];
        this.block_contents.asset_properties.info_list = [
            {
                label: 'Brand',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && (item_info.asset_meta.hasOwnProperty('brand') || item_info.asset_meta.hasOwnProperty('make_brand'));
                },
                info_value : function () {
                    return item_info.asset_meta.brand || item_info.asset_meta.make_brand;
                }
            },
            {
                label: 'Size',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('asset_meta') && item_info.asset_meta && item_info.asset_meta.hasOwnProperty('size');
                },
                info_value : function () {
                    return item_info.asset_meta.size;
                }
            },
            {
                label: 'Colours',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.colours_info && item_info.colours_info.length;
                },
                info_value : function () {
                    let colours_info = item_info.colours_info;
                    let html = '';
                    if (colours_info.length > 0) {
                        for (const itemElementKey in colours_info) {
                            let colour = colours_info[itemElementKey];
                            html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                        }
                    } else {
                        html += '-';
                    }
                    return html;
                }
            },
        ];
        this.block_contents.asset_details.info_list = [
            {
                label: 'Character',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('character_info') && item_info.character_info;
                },
                info_value : function () {
                    return '<a href="/' + item_info.current_page_type + '/characters/'+item_info.character_info.id + '/view" target="_blank">(' + (item_info.character_info.character_number || '') + ') '+ item_info.character_info.name + '</a>';
                }
            },
            {
                label: 'Internal Reference',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.internal_reference || '-';
                },
                info_value : function () {
                    return item_info.internal_reference || '-';
                }
            },
            {
                label: 'External Reference',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.external_reference || '-';
                },
                info_value : function () {
                    return item_info.external_reference || '-';
                }
            },
        ];
        this.block_contents.asset_returns.info_list = [
            {
                label: 'Returns Deadline',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info && item_info.hasOwnProperty('return_expiry_date') && item_info.return_expiry_date;
                },
                info_value : function () {
                    return Vue.options.filters.moment(item_info.return_expiry_date, 'MMMM Do YYYY');
                }
            },
            {
                label: 'Days Remaining',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info && item_info.hasOwnProperty('return_expiry_date') && item_info.return_expiry_date;
                },
                info_value : function () {
                    return Vue.options.filters.fromNow(item_info.return_expiry_date);
                }
            },
        ];

        let acquisition_info = item_info.hasOwnProperty('acquisition_info') && item_info.acquisition_info ? item_info.acquisition_info : {};


        this.block_contents.asset_supplier.info_list = [
                {
                    label: 'Supplier',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return item_info.hasOwnProperty('supplier_info') && item_info.supplier_info;
                    },
                    info_value : function () {
                        let supplier_link = '/' + item_info.current_page_type + '/suppliers/'+item_info.supplier_info.id+'/view';
                        return '<i class=""></i> <a href="' + supplier_link + '">' + item_info.supplier_info.name + '</a>';
                    }
                },
                {
                    label: 'Purchase Order',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return acquisition_info.hasOwnProperty('purchase_orders_info') && acquisition_info.purchase_orders_info && acquisition_info.purchase_orders_info.length > 0;
                    },
                    info_value : function () {
                        let latest_po = acquisition_info.purchase_orders_info[acquisition_info.purchase_orders_info.length-1];
                        return '<a href="/' + item_info.current_page_type + '/purchaseOrders/' + latest_po.id + '/view">' + used_functions.POWithUIDs(latest_po) + '</a>';
                    }
                },
                {
                    label: 'Acquisition Method',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return acquisition_info.acquisition_method !== '';
                    },
                    info_value : function () {
                        let acquisition_methods = used_functions.acquisition_methods;
                        return acquisition_methods[acquisition_info.acquisition_method] ? acquisition_methods[acquisition_info.acquisition_method].name : '-';
                    }
                },


                {
                    label: (acquisition_info.hasOwnProperty('no_of_assets') ? acquisition_info.no_of_assets + ' ' : '') + 'Assets',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return acquisition_info.hasOwnProperty('no_of_assets') && acquisition_info.no_of_assets && acquisition_info.no_of_assets > 0;
                    },
                    tooltip_title : function () {
                        return acquisition_info.assets_value === '' ? '' : used_functions.toUserCurrencyValue(acquisition_info.currency, acquisition_info.assets_value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(acquisition_info.currency).icon;
                        let total_value = acquisition_info.assets_value === '' ? '-' : acquisition_info.assets_value;
                        return currency_icon + ' ' + total_value;
                    }
                },
                {
                    label: (acquisition_info.hasOwnProperty('no_of_payments') ? acquisition_info.no_of_payments + ' ' : '') + 'Payments',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return acquisition_info.hasOwnProperty('no_of_payments') && acquisition_info.no_of_payments && acquisition_info.no_of_payments > 0;
                    },
                    tooltip_title : function () {
                        return acquisition_info.payments_value === '' ? '' : used_functions.toUserCurrencyValue(acquisition_info.currency, acquisition_info.payments_value);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(acquisition_info.currency).icon;
                        let total_value = acquisition_info.payments_value === '' ? '-' : acquisition_info.payments_value;
                        return currency_icon + ' ' + total_value;
                    }
                },
                {
                    label: 'Required Payment',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return acquisition_info.hasOwnProperty('required_payment') && acquisition_info.required_payment && acquisition_info.required_payment !== 0;
                    },
                    tooltip_title : function () {
                        return acquisition_info.required_payment === '' ? '' : used_functions.toUserCurrencyValue(acquisition_info.currency, acquisition_info.required_payment);
                    },
                    info_value : function () {
                        let currency_icon = used_functions.getCurrencyByValue(acquisition_info.currency).icon;
                        let total_value = acquisition_info.required_payment === '' ? '-' : acquisition_info.required_payment;
                        return currency_icon + ' ' + total_value;
                    }
                },

            ];

        // Accounts
        this.block_contents.accounts.info_list = [
            {
                label: 'Acquisition',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return acquisition_info.hasOwnProperty('id');
                },
                info_value : function () {
                    let acquisition_link = '/' + item_info.current_page_type + '/acquisitions/'+acquisition_info.id+'/view';
                    return '<i class=""></i> <a href="' + acquisition_link + '">' + acquisition_info.name + '</a>';
                }
            },
            {
                label: 'Ledger',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return item_info.hasOwnProperty('ledger_info') && item_info.ledger_info;
                },
                info_value : function () {
                    let ledger_link = '/' + item_info.current_page_type + '/ledgers/'+item_info.ledger_info.id+'/view';
                    return '<i class=""></i> <a href="' + ledger_link + '">' + item_info.ledger_info.name + '</a>';
                }
            },
            {
                label: 'P-Card',
                icon: '',
                is_html_value: true,
                info_condition: function () {
                    return acquisition_info && acquisition_info.hasOwnProperty('buyer_ledger_info') && acquisition_info.buyer_ledger_info;
                },
                info_value : function () {
                    let buyer_ledger_link = '/' + acquisition_info.current_page_type + '/ledgers/'+acquisition_info.buyer_ledger_info.id+'/view';
                    return '<i class=""></i> <a href="' + buyer_ledger_link + '">' + acquisition_info.buyer_ledger_info.name + '</a>';
                }
            },
        ];
        // Costs

        let empty_cost = used_functions.getCurrencyByValue(item_info.used_currency).icon + '0.00';
        this.block_contents.costs.info_list = [
            {
                label: 'QTY',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.quantity;
                }
            },
            {
                label: 'Cost For 1',
                icon: '',
                is_html_value: true,
                block_class: 'totals-net-gross',
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    let html = '';
                    if(item_info.net_unit && item_info.used_currency){
                        html += '<span class="total-net cost-per-one">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.net_unit).toFixed(2) + '</span>';
                    }else if((item_info.net_total / item_info.quantity) && item_info.used_currency){
                        html += '<span class="total-net cost-per-one">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + ((item_info.net_total / item_info.quantity).toFixed(2)) + '</span>';
                    }else{
                        html += '<span class="total-net cost-per-one">' + empty_cost + '</span>';
                    }
                    if((item_info.value) && item_info.used_currency){
                        html += '<span class="total-gross">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.value).toFixed(2) + '</span>';
                    }else{
                        html += '<span class="total-net cost-per-one">' + empty_cost + '</span>';
                    }
                    return html;
                }
            },
            {
                label: ('Cost For ' + item_info.quantity),
                icon: '',
                is_html_value: true,
                block_class: 'totals-net-gross',
                info_condition: function () {
                    return item_info.total_value;
                },
                info_value : function () {
                    let html = '';
                    if(item_info.net_total && item_info.used_currency){
                        html += '<span class="total-net cost-per-one">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.net_total).toFixed(2) + '</span>';
                    }else{
                        html += '<span class="total-net cost-per-one">' + empty_cost + '</span>';
                    }
                    if((item_info.total_value) && item_info.used_currency){
                        html += '<span class="total-gross">' + used_functions.getCurrencyByValue(item_info.used_currency).icon + parseFloat(item_info.total_value).toFixed(2) + '</span>';
                    }else{
                        html += '<span class="total-net cost-per-one">' + empty_cost + '</span>';
                    }
                    return html;
                }
            },
        ];


        // acquisition
        let acquisition_block = this.block_contents.acquisition;
        if (this.item_info.hasOwnProperty('acquisition_id') && this.item_info.acquisition_id !== null){
            this.block_contents.acquisition.has_action = false;
            this.block_contents.acquisition.action_required_title = '';
        }else{
            this.block_contents.acquisition.action_required = function () {
                let acquisition_task = used_functions.getTaskFor(acquisition_block.action_name);
                if (acquisition_task){
                    used_functions.completeTask(acquisition_task, 'overview-action-tasks');
                }
            };
        }



        if (item_info.hasOwnProperty('disposition_info') && item_info.disposition_info){
            this.fillDispositionInfo(item_info);
        }


        this.block_contents.liability.info_list = [
            {
                label: 'Acquisition Type',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return item_info.hasOwnProperty('acquisition_info') && item_info.acquisition_info && item_info.acquisition_info.hasOwnProperty('acquisition_type_info') && item_info.acquisition_info.acquisition_type_info ;
                },
                info_value : function () {
                    return item_info.acquisition_info.acquisition_type_info.name;
                }
            },
            {
                label: 'Ownership Status',
                icon: '',
                is_html_value: false,
                info_condition: function () {
                    return true;
                },
                info_value : function () {
                    return item_info.ownership_status === 1 ? 'Current' : 'Ended';
                }
            },
            {
                label: (item_info.ownership_status === 1 ? 'Acquired Date' : 'Ended Date'),
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    let age_key = item_info.ownership_status === 1 ? 'acquired_date' : 'ownership_end_date';
                    return item_info.hasOwnProperty(age_key) && item_info[age_key] && item_info[age_key] !== '';
                },
                info_value : function () {
                    let age_key = item_info.ownership_status === 1 ? 'acquired_date' : 'ownership_end_date';
                    return Vue.options.filters.moment(item_info[age_key], 'MMMM Do YYYY');
                }
            },
            {
                label: (item_info.ownership_status === 1 ? 'Age' : 'Lifespan'),
                icon: 'bx bx-money',
                is_html_value: false,
                info_condition: function () {
                    let age_key = item_info.ownership_status === 1 ? 'asset_age' : 'ownership_age';
                    return item_info.hasOwnProperty(age_key) && item_info[age_key] && item_info[age_key] !== '';
                },
                info_value : function () {
                    return item_info.ownership_status === 1 ? item_info.asset_age : item_info.ownership_age;
                }
            },
        ];

        switch (this.item_info.status){
            case 'planned':
                this.sustainability_type = 'planning';
                break;
            case 'acquired':
            case 'active':
            case 'in_storage':
                this.sustainability_type = 'readiness';
                let location_task, po_task, volume_task, cost_task, final_disposition_planning_task;
                let staticScore = 100/5;

                if (this.item_info.location_id){
                    let {completed_at, completed_by_user} = this.getUserAndDate([3, 2]);
                    location_task = {
                        title: 'Location Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(location_task);
                }else{
                    location_task = {
                        title: 'Location Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(location_task);
                }

                if (this.item_info.po_item_info){
                    let {completed_at, completed_by_user} = this.getUserAndDate([4, 2]);

                    po_task = {
                        title: 'Purchase Order Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(po_task);
                }else{
                    po_task = {
                        title: 'Purchase Order Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(po_task);
                }

                if (this.item_info.volume){
                    let {completed_at, completed_by_user} = this.getUserAndDate([5, 2]);
                    volume_task = {
                        title: 'Volume Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(volume_task);
                }else{
                    volume_task = {
                        title: 'Volume Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(volume_task);
                }

                if (this.item_info.total_value){
                    let {completed_at, completed_by_user} = this.getUserAndDate([6, 2]);
                    cost_task = {
                        title: 'Cost Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(cost_task);
                }else{
                    cost_task = {
                        title: 'Cost Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(cost_task);
                }


                if (this.item_info.hasOwnProperty('final_disposition_planning') && this.item_info.final_disposition_planning){
                    let {completed_at, completed_by_user} = this.getUserAndDate([7, 2]);
                    final_disposition_planning_task = {
                        title: 'Final Disposition Planning Updated',
                        is_checked: true,
                        status: 'Completed',
                        variant: 'success',
                        completed_at: completed_at,
                        completed_by_user: completed_by_user,
                        score: staticScore
                    }
                    this.completed_tasks_list.push(final_disposition_planning_task);
                }else{
                    final_disposition_planning_task = {
                        title: 'Final Disposition Planning Missing',
                        is_checked: false,
                        status: 'Waiting',
                        variant: 'danger',
                        score: '0'
                    }
                    this.uncompleted_tasks_list.push(final_disposition_planning_task);
                }

                break;
            case 'disposed':
                this.sustainability_type = 'reporting';
                break;
        }
    },
    computed:{
        disposition_info() {
            if (this.item_info.hasOwnProperty('disposition_info')){
                return this.item_info.disposition_info;
            }
            return null;
        },
    },
    watch:{
        disposition_info: {
            deep: true,
            handler: function (newValue) {
                if (newValue){
                    this.fillDispositionInfo(this.item_info);
                }
            }
        },

    },
    mounted(){
    },
    methods:{

        fillDispositionInfo: function (item_info) {
            let disposition_info = item_info.disposition_info;

            let used_functions = {
                getCurrencyByValue: this.getCurrencyByValue,
                toUserCurrencyValue: this.toUserCurrencyValue
            };

            this.block_contents.disposition.info_list = [
                {
                    label: 'Date',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('disposition_date') && disposition_info.disposition_date;
                    },
                    info_value: function () {
                        return Vue.options.filters.moment(disposition_info.disposition_date, 'MMMM Do YYYY') + ' - ' + Vue.options.filters.fromNow(disposition_info.disposition_date);
                    }
                },
                {
                    label: 'Disposition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('disposition_type_info') && disposition_info.disposition_type_info;
                    },
                    info_value: function () {
                        return disposition_info.disposition_type_info.name;
                    }
                },
                {
                    label: 'Recipient',
                    icon: '',
                    is_html_value: true,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('recipient_type') && disposition_info.recipient_type !== '';
                    },
                    info_value: function () {
                        let recipient_link = '/' + item_info.current_page_type + '/' + disposition_info.recipient_type + '/' + disposition_info.recipient_id + '/view';
                        return '<a href="' + recipient_link + '" class="text-dark">' + disposition_info.recipient_name + '</a>';
                    }
                },
                {
                    label: 'Currency',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.currency;
                    },
                    info_value: function () {
                        return used_functions.getCurrencyByValue(disposition_info.currency, true);
                    }
                },
                {
                    label: 'Pro Rata Per Unit',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('value') && disposition_info.value && disposition_info.value !== 0;
                    },
                    tooltip_title: function () {
                        return disposition_info.value === '' ? '' : used_functions.toUserCurrencyValue(item_info.used_currency, disposition_info.value);
                    },
                    info_value: function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.used_currency).icon;
                        let total_value = disposition_info.value === '' ? '-' : disposition_info.value;
                        return currency_icon + ' ' + total_value;
                    }
                },
                {
                    label: 'Method',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('disposition_type_info') && disposition_info.disposition_type_info;
                    },
                    info_value: function () {
                        return disposition_info.disposition_type_info.name;
                    }
                },
                {
                    label: 'Reference',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('reference') && disposition_info.reference;
                    },
                    info_value: function () {
                        return disposition_info.reference;
                    }
                },
                {
                    label: 'Qty at Disposition',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('quantity') && disposition_info.quantity;
                    },
                    info_value: function () {
                        return disposition_info.quantity;
                    }
                },
                {
                    label: 'Pro Rata Total',
                    icon: '',
                    is_html_value: false,
                    info_condition: function () {
                        return disposition_info.hasOwnProperty('total_value') && disposition_info.total_value && disposition_info.total_value !== 0;
                    },
                    tooltip_title: function () {
                        return disposition_info.total_value === '' ? '' : used_functions.toUserCurrencyValue(item_info.used_currency, disposition_info.total_value);
                    },
                    info_value: function () {
                        let currency_icon = used_functions.getCurrencyByValue(item_info.used_currency).icon;
                        let total_value = disposition_info.total_value === '' ? '-' : disposition_info.total_value;
                        return currency_icon + ' ' + total_value;
                    }
                },
            ];
        },
        getTaskFor(task_name){
            if (this.item_info.event_tasks){
                return this.getObjectOf(task_name, this.item_info.event_tasks, 'name');
            }
            return null;
        },
        getAcquisitionType(){
            let acquisition_type = this.getObjectOf(this.item_info.asset_meta.acquisition_type, this.acquisition_types);
            if (acquisition_type){
                return acquisition_type.name;
            }
            return '-';
        },
        getAcquisitionUsing(){
            let acquired_using = this.getObjectOf(this.item_info.asset_meta.acquired_using, AcquisitionOptions);
            if (acquired_using){
                return acquired_using.name;
            }
            return '-';
        },
        getUserAndDate(action_types_filters = []) {
            var completed_at, completed_by_user;
            if (this.item_info.asset_posts) {
                var location_posts;
                for (const key in action_types_filters) {
                    let action_type = action_types_filters[key];
                    if (!location_posts || location_posts.length === 0) {
                        location_posts = this.item_info.asset_posts.filter(function (e) {
                            return e.action_type === action_type;
                        });
                    }else{
                        continue;
                    }
                }

                if (location_posts.length > 0) {
                    completed_at = location_posts[0].created_at;
                    completed_by_user = location_posts[0].user;
                }
            }
            return {completed_at, completed_by_user};
        },
    }
}
</script>
