<template>
    <div class="row ol-object" v-if="order_line.status === 4 && managed_storage_info">
        <div class="col-lg-12">
            <p class="task-action">
                <span class="item-header">
                    <a class="text-dark" :href="'/' + current_page_type + '/managed_storages/' + managed_storage_info.id + '/view'">{{ managed_storage_info.uid }}</a> was removed.
                </span>
            </p>
        </div>
    </div>

    <div class="row ol-object" v-else-if="item_info">
        <div class="col-lg-2">
            <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'item_details'"/>
        </div>
        <div class="col-lg-10">
            <div class="row">
                <div class="col-lg-12">
                    <p class="object-title-list">
                        <span class="item-header">
                            <a v-if="order_line.item_type === 1" class="text-dark" :href="'/sp/managed_storages/'+managed_storage_info.id+'/view'">{{ item_info.name }}</a>
                            <a v-else class="text-dark" :href="'/sp/assets/'+item_info.id+'/view'">{{ item_info.name }}</a>
                        </span>
                    </p>
                    <p class="object-title-detail">{{ item_info.description }}</p>
                </div>
                <div class="col-lg-2">
                    <div class="cm_usage">
                        <span>Condition</span>
                        <p><span>{{ getConditionText(item_info) }}</span></p>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="cm_usage">
                        <span>Colour</span>
                        <div v-html="getColourHTML(item_info)"></div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="cm_usage">
                        <span>Area</span>
                        <p><span>{{ item_info.total_area }} m<sup>2</sup></span></p>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="cm_usage">
                        <span>Volume</span>
                        <p><span>{{ item_info.total_volume }} m<sup>2</sup></span></p>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="cm_usage">
                        <span>Quantity</span>
                        <p><span>{{ order_line.total_quantity }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div class="col-lg-12">
            <p class="task-action" style="display: none;">
                Asset Info Not Available
            </p>
        </div>
    </div>
</template>
<script>
import MyBCarouselImages from "@/views/assetflow/assets/subItems/AssetLayouts/MyBCarouselImages";
export default {
    name: 'OrderLineRelatedObject',
    components: {MyBCarouselImages},
    props:[
        'order_line',
        'index',
        'showCaptiomImg',
        'current_page_type'
    ],
    computed:{
        item_info(){
            return this.order_line.hasOwnProperty('item_info') && this.order_line.item_info ? this.order_line.item_info : null;
        },
        managed_storage_info(){
            return this.order_line.hasOwnProperty('managed_storage_info') && this.order_line.managed_storage_info ? this.order_line.managed_storage_info : null;
        },
    },
    methods:{
        getConditionText(item_info){
            let condition = item_info.condition;
            let conditions_list = [
                'New',
                'Excellent',
                'Good',
                'Attention Required',
            ];
            return condition > 0 ? conditions_list[condition - 1] : '-';
        },
        getColourHTML(item_info){
            let colours_info = item_info.colours_info;
            if (colours_info){
                let html = '<div>';
                if (colours_info.length > 0){
                    for (const itemElementKey in colours_info) {
                        let colour = colours_info[itemElementKey];
                        html += '<span class="colour-item"><span class="colour-name">' + colour.name + '</span><span class="colour-bar badge badge-soft-success font-size-12" style="background-color:' + colour.hex + '; color:white"><i></i></span></span> ';
                    }
                }else{
                    html += '-';
                }
                html += '<div>';
                return html;
            }
            return '-';
        },
    }
}
</script>
