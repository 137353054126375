<template>
    <Layout>
        <h3>{{ other_item_id ? 'Update' : 'Add'}}</h3>

        <OrderLineFormFields
            v-show="item_info"
            ref="full-order-line-fields"
            :is_full="true"
            :item_type="item_type"
            :received_parent_order.sync="item_info"
            :received_order_type="item_info ? item_info.order_type : null"
            :with_asset_details="true"
            :has_cancel_button="false"
            @after-saving-is-done="onSavingOrderLine"
        />

        <h3>Process</h3>
        <OrderLineProcessingFields
            v-show="order_line && item_info.order_type === 0"
            ref="full-process-order_line"
            :item_type="item_type"
            :received_parent_order="item_info"
            :enabled_sections="['intake_profile_details', 'warehousing_details']"
            @after-saving-is-done="afterProcessing"
            @on-cancel-clicked="cancelClicked"/>

        <div v-if="!(order_line && item_info.order_type === 0)">
            <h5>Process Order Lines of Storage Orders</h5>
        </div>

    </Layout>
</template>

<script>
import Resource from '@/api/resource';
import Layout from "@/layouts/main";
import OrderLineFormFields from "../extras/OrderLineFormFields";
import OrderLineProcessingFields from "../extras/OrderLineProcessingFields";
const orderResource = new Resource('api/assetflow/orders');

export default {
    name: "ProcessOrder",
    props: ['item_id', 'other_item_id', 'item_type'],
    components: {OrderLineProcessingFields, OrderLineFormFields, Layout },
    data(){
        return {
            listQuery:{},
            current_customer: null,
            current_user_info: null,
            current_sp: null,
            item_info: null,
            order_line_id: null,
            order_line: null
        }
    },
    created() {
        this.order_line_id = this.other_item_id;

        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
            this.current_sp = this.current_user_info.current_sp;
        }

        this.fetchItemInfo();
    },
    methods:{
        onSavingOrderLine(item){
            this.order_line_id = item.id;
            this.order_line = item;
        },
        afterProcessing(items, processed){
            window.location.href = '/' + this.item_type + '/orders/' + this.item_id + '/view';
        },
        cancelClicked(){
            window.location.href = '/' + this.item_type + '/orders/' + this.item_id + '/view';
        },
        fetchItemInfo(){
            if (this.item_id){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/orders/' + this.item_id,
                    baseURL: '/',
                }).then(response => {

                    let item_info = response.data;
                    if (item_info){
                        this.item_info = item_info;
                        this.$refs['full-order-line-fields'].setParentOrder(item_info);
                        this.fetchOrderLineItemInfo();
                    }
                    this.isLoadingItem = false;
                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }
        },
        fetchOrderLineItemInfo(){
            if (this.order_line_id){
                this.isLoadingOrderLine = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/order_lines/' + this.order_line_id,
                    baseURL: '/',
                }).then(response => {
                    this.order_line = response.data;
                    if (this.$refs['full-process-order_line']){
                        console.log("this.order_line");
                        console.log(this.order_line);

                        this.$refs['full-process-order_line'].setSelectedItem(this.order_line);
                    }
                    if (this.$refs['full-order-line-fields']){
                        this.$refs['full-order-line-fields'].setOrderLine(this.order_line);
                    }

                    this.isLoadingOrderLine = false;
                }).catch(error => {
                    console.log(error);
                    this.isLoadingOrderLine = false;
                });
            }
        }
    }
}
</script>
