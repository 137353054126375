<template>
    <!-- Start here -->
    <div id="acquisition-main-wrapper" class="">
        <!-- Page-Content start-->
        <div id="disboard-content-wrapper">
            <div class="common-inner-section">
                <div class="common-title-block">
                    <h2>{{ title }}</h2>
                </div>
                <div class="main-step-wrapper">
                    <!-- Multi step form -->
                    <fieldset class="common-step-block step-1">
                        <div class="use-section-df">
                            <div class="common-title-block">
                                <h4>{{ steps_list[selected_step_index].name }}</h4>
                                <p>Fill all information below</p>
                            </div>

                        </div>

                        <transition name="fade" mode="out-in">
                            <AcquisitionStepPanel
                                ref="step-panel-ref"
                                :current-step="steps_list[selected_step_index]"
                                :use-tooltips="false"
                                :add-form="addForm"
                                :object_type="object_type"
                                :required_fields="required_fields[object_type]"
                                :listQuery="listQuery"
                                :my_lists_values="lists_values"
                                :item_id="item_id"
                                :item_type="item_type"
                            />
                        </transition>

                        <div>
                            <div class="common-step-button-block" v-show="false">
                                <div class="prev-next--btn" style="justify-content: space-between; display: flex; ">
                                    <button type="button" class="action-button previous_button st-common-btn"><i class="bx bx-left-arrow-alt font-size-12"></i> <span class="prev-btn-text">Previous</span></button>
                                    <button type="button" class="action-button next_button st-common-btn" style=" "><span class="next-btn-text">Next</span> <i class="bx bx-right-arrow-alt font-size-12"></i></button>
                                </div>
                            </div>
                            <div class="common-step-button-block">
                                <a :href="item_id !== '' ? './view' : './'" class="st-cancel-btn">Cancel</a>

                                <AFButton
                                    tag_name="button"
                                    tag_type="button"
                                    :key_prefix="'save-AA' + '-' + buttonkey"
                                    :is_enabled="true"
                                    :required_action="addNew"
                                    :first_argument="false"
                                    required_text="Save & View"
                                    item_classes="action-button next_button st-common-btn"
                                    span_classes="save-btn-text"
                                />

                                <AFButton
                                    tag_name="button"
                                    tag_type="button"
                                    :key_prefix="'save-add-AA' + '-' + buttonkey"
                                    :is_enabled="true"
                                    :required_action="addNew"
                                    :first_argument="true"
                                    style=" margin-right: 20px; "
                                    required_text="Save & Add Another"
                                    item_classes="action-button next_button st-common-btn"
                                    span_classes="save-btn-text"
                                />

                            </div>
                        </div>


                    </fieldset>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import dropzoneMixin from "@/mixins/assetAcquisitionDropzoneMixin.mixin";
import AcquisitionStepPanel from "./AcquisitionStepPanel";
import {Currencies} from "@/views/assetflow/form_data/Currencies";
import AFButton from "@/components/AFButton";

export default {
    name: "AddAssetAcquisitionForm",
    props: ['item_id', 'item_type', 'source_type', 'action', 'with_sidebar', 'object_type', 'object_type_link', 'object_type_shortcut', 'received_lists_values'],
    mixins: [dropzoneMixin],
    components: {AFButton, AcquisitionStepPanel},

    computed:{
        all_filled_asset_images(){
            return this.addForm.filled_asset_images;
        }
    },
    watch:{
        all_filled_asset_images:{
            deep: true,
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue){
                    this.finished_all_uploads = this.addForm.filled_asset_images.every(x => x.upload_progress === 100);
                }
            }
        },
    },
    data() {
        return {
            title: 'Add New Asset Acquisition',
            lists_values: {},
            finished_all_uploads: false,
            listQuery: {
                page: 1,
                limit: 100
            },
            required_fields:{

                asset_acquisition: {

                    department_id: {
                        name: 'department',
                        type: 'single-select',
                        name_plural:'departments',
                        link_name:'',
                        has_permission: true,
                        options: []
                    },
                    buyer_id: {
                        name: 'Buyer',
                        type: 'single-select',
                        name_plural:'buyers',
                        link_name:'',
                        has_permission: true,
                        options:[]
                    },
                    buyer_ledger_id: {
                        name: 'Buyer Ledger (P-CARD)',
                        type: 'single-select',
                        name_plural:'Buyer Ledgers',
                        link_name:'',
                        has_permission: true,
                        options:[]
                    },
                    character_id: {
                        name: 'Character',
                        type: 'single-select',
                        name_plural:'characters',
                        link_name:'characters',
                        has_permission: true,
                        options:[]
                    },
                    ledger_id: {
                        name: 'Ledger Account',
                        type: 'single-select',
                        name_plural:'ledger accounts',
                        link_name:'ledgers',
                        has_permission: true,
                        options:[]
                    },
                    notes: {
                        name: 'Notes',
                        type: 'text',
                        has_permission: true
                    },

                    currency: {
                        name: 'Currency',
                        type: 'single-select',
                        has_permission: true,
                        default: '',
                        default_value: { value: 'gbp',  text: "GBP",  icon: '£',  rate: 1 },
                        options: Currencies
                    },
                    supplier_id: {
                        name: 'supplier',
                        name_plural:'suppliers',
                        link_name:'suppliers',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    acquisition_method: {
                        name: 'Acquisition Method',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                        default_value: { id: 1, name: 'Internet' },
                    },
                    acquisition_type: {
                        name: 'Acquisition Type',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                        default_value: { id: 1, name: 'Purchased' },
                    },
                    purchase_order_id: {
                        name: 'Purchase Order',
                        name_plural:'Purchase Orders',
                        link_name:'purchaseOrders',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    item_return_policy: {
                        name: 'Item Return Policy',
                        type: 'single-select',
                        has_permission: true,
                        options:[
                            { id: 0, name: '0' },
                            { id: 1, name: '1' },
                            { id: 2, name: '2' },
                            { id: 3, name: '3' },
                            { id: 4, name: '4' },
                            { id: 5, name: '5' },
                            { id: 6, name: '6' },
                            { id: 7, name: '7' },
                            { id: 14, name: '14' },
                            { id: 28, name: '28' },
                            { id: 30, name: '30' },
                            { id: 60, name: '60' },
                            { id: 90, name: '90' }
                        ]
                    },
                    supplier_reference: {
                        name: 'Supplier Reference',
                        type: 'text',
                        has_permission: true
                    },
                    acquisition_date: {
                        name: 'Acquisition Date',
                        type: 'date',
                        has_permission: true
                    },
                    create_assets_automatically: {
                        name: 'Automatically Create Assets?',
                        type: 'checkbox',
                        has_permission: true,
                        default_value: 1,
                    },
                    confirmation_status: {
                        name: 'Close Acquisition?',
                        type: 'radio-select',
                        has_permission: true,
                        default_value: { id: 1, name: 'Yes' },
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' }
                        ]
                    },
                    payment_status: {
                        name: 'Paid?',
                        type: 'radio-select',
                        has_permission: true,
                        default_value: { id: 1, name: 'Yes' },
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' }
                        ]
                    },
                    create_assets_asset_quantities: {
                        name: 'Asset Quantities',
                        type: 'single-select',
                        has_permission: true,
                        default_value: { id: 1, name: 'Multiple Assets with Qty of 1' },
                        options:[
                            { id: 0, name: '1 Asset With Multiple Qty' },
                            { id: 1, name: 'Multiple Assets with Qty of 1' },
                        ]
                    },

                    payment_date: {
                        name: 'Payment Date',
                        type: 'date',
                        has_permission: true,
                        default_value: new Date()
                    },
                    payment_type: {
                        name: 'Payment Type',
                        type: 'single-select',
                        has_permission: true,
                        default_value: { id: 2, name: 'Credit Card' },
                        options:[
                            { id: 0, name: 'Account' },
                            { id: 1, name: 'Cash' },
                            { id: 2, name: 'Credit Card' },
                            { id: 3, name: 'Expenses' }
                        ]
                    },
                    payment_ledger_id: {
                        name: 'Payment Account',
                        type: 'single-select',
                        name_plural:'Payment Accounts',
                        link_name:'ledgers',
                        has_permission: true,
                        options:[]
                    },
                    payment_reference: {
                        name: 'Payment Reference',
                        type: 'text',
                        has_permission: true
                    },

                }
            },
            addForm: {
                asset_meta: {},
                line_items: [],
                asset_images: [],
                filled_asset_images: [],
                item_details: {},
                added_acquisition_types: [],
                single_select_v_models: {},
                multi_select_v_models: {},
            },
            selected_step_index: 0,
            steps_list: [
                {
                    id: 'costume_asset_acquisition_step', // >>>
                    name: 'Costume Asset Acquisition',
                    class: 'form-steps costume-asset-acquisition-form',
                    isActive: true
                }

            ],
            isSendingRequest: false
        }
    },
    created() {
        this.getFieldsList();
    },
    methods:{
        async getFieldsList(){
            this.lists_values = {};
            this.isLoadingItem = true;

            let required_select_lists =  {
                buyer_id: 'buyers_list', //[],
                character_id: 'characters_list', //[],
                ledger_id: 'ledgers_list', //[],
                supplier_id: 'suppliers_list',
                acquisition_method: 'acquisition_methods_list',
                acquisition_type: 'acquisition_types_list',
                payment_ledger_id: 'payment_ledgers_list', //[],
                buyer_ledger_id: 'buyer_ledgers_list', //[],
                acquisition_category_id: 'categories_list', //[],
                ownership_type: 'ownership_type_list', //[],
            }
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                    action: 'new_acquisition',
                    required_lists: required_select_lists,
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;

                for (const list_key in required_select_lists) {
                    let select_name = required_select_lists[list_key];
                    this.lists_values[list_key] = form_lists[select_name];
                }


                let with_add_new = ['ledger_id', 'buyer_ledger_id', 'payment_ledger_id', 'supplier_id', 'purchase_order_id'];
                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'asset_acquisition', with_add_new);
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        addNew(add_another, event) {
            let allfiles = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (event && !this.isSendingRequest && allfiles) {
                this.isSendingRequest = true;

                let item_details_values = Object.assign({}, this.addForm.item_details);
                let single_select_keys = [
                    'create_assets_automatically',
                    'buyer_id',
                    'buyer_ledger_id',
                    'character_id',
                    'department_id',
                    'ledger_id',
                    'supplier_id',
                    'acquisition_method',
                    'acquisition_type',
                    'purchase_order_id',
                    'item_return_policy',
                    'confirmation_status',
                    'payment_status',
                    'create_assets_asset_quantities',
                    'payment_type',
                    'payment_ledger_id',
                ];

                for (const selectKeysKey in single_select_keys) {
                    let select_key = single_select_keys[selectKeysKey];
                    if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){
                        item_details_values[select_key] = item_details_values[select_key].id;
                    }
                }

                item_details_values.currency = item_details_values.currency ? item_details_values.currency.value : 'gpb';


                item_details_values.asset_meta = this.addForm.asset_meta;
                let line_items = Object.assign({}, this.addForm.line_items);
                let edited_line_items = [];

                let line_single_select_keys = [
                    'category_id',
                    'character_id',
                    'ledger_id',
                    'asset_template_id',
                    'create_assets_automatically',
                    'create_assets_asset_quantities',
                    'colours',
                    'composition',
                    'tag_ids',
                    'condition',
                    'waste_stream_type',
                    'status',
                    'asset_usage',
                    'is_hero',
                    'is_asset',
                    'folder_id',
                    'department_id',
                    'contact_id',
                    'address_id',
                    'location_id',
                    'final_disposition_planning',
                    'fdp_status',
                    'waste_stream_id'
                ];

                for (const lineItemsKey in line_items) {
                    let line_item = line_items[lineItemsKey];
                    if (line_item.hasOwnProperty('asset_images') && line_item.asset_images.length > 0){
                        line_item.asset_images = this.organizeAssetImages(line_item.asset_images);
                    }
                    for (const selectKeysKey in line_single_select_keys) {
                        let select_key = line_single_select_keys[selectKeysKey] + '_info';
                        if (line_item.hasOwnProperty(select_key) && line_item[select_key]){
                            delete line_item[select_key];
                        }
                    }

                    edited_line_items.push(line_item);
                }

                let params = {
                    item_id: this.item_id,
                    item_details: item_details_values,
                    line_items: edited_line_items,
                    object_type: this.object_type,
                    asset_images: allfiles
                };

                axios({
                    method: 'post',
                    url: '/api/assetflow/acquisitions/addAssetAcquisition',
                    baseURL: '/',
                    data: params,
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true) {
                        let added_item = response.data.data.items;
                        let message;


                        if (added_item && added_item.hasOwnProperty('id')){
                            let asset_name = ', ' + this.object_type_shortcut + '-' + added_item.uid + ' {' + added_item.name + '}';
                            message = 'Added Successfully' + asset_name;
                            if (this.action === 'edit' && this.item_id !== '') {
                                message = 'Edited Successfully' + asset_name;
                            }

                            if (this.source_type === 'modal'){
                                this.$emit('on-save-item', added_item);
                            }else{
                                // after success
                                if (add_another === 'edit_page') {
                                    if (added_item) {
                                        window.flash(message, 'success', event);
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link;
                                    } else {
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link;
                                    }
                                }else if (add_another) {
                                    if (this.action === 'edit' && this.item_id !== '') {
                                        window.flash(message);
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link;
                                    } else {
                                        window.flash(message);
                                        location.reload();
                                    }
                                } else {
                                    if (added_item && added_item.hasOwnProperty('id')) {
                                        window.flash(message);
                                        let asset_id = added_item.id;
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link + '/' + asset_id + '/view';
                                    } else {
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link;
                                    }
                                }
                            }

                        }else{
                            console.log(added_item);
                        }

                    } else {
                        console.log('Something went wrong');
                    }
                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                }).finally(() => {
                    this.buttonkey++;
                });
            }

        }
    }
}
</script>


