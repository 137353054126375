<template>
    <Layout>
        <AddNewPartForm object_type="scenes-costume" :item_type="item_type" ref="add-new-part" :is_full_page="true"></AddNewPartForm>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import AddNewPartForm from "../extras/AddNewPartForm";

export default {
    name: "NewPart",
    props: ['item_id', 'item_type', 'action'],
    components: {AddNewPartForm, Layout},
    data(){
        return {
            listQuery:{},
            current_customer: null,
            current_user_info: null,
            current_sp: null
        }
    },
    created() {
        this.current_user_info = window.USER_INFO;
        if (this.current_user_info){
            this.current_customer = this.current_user_info.current_customer;
            this.current_sp = this.current_user_info.current_sp;
        }

        if (this.action === 'edit' && this.item_id !== '') {
            this.fetchItemInfo();
        }
    },
    methods:{
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.customer_id = this.current_sp.id;
            }
            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        fetchItemInfo(){
            if (this.item_id){
                this.isLoadingItem = true;
                axios({
                    method:'get',
                    url:'/api/assetflow/parts/' + this.item_id,
                    baseURL: '/',
                }).then(response => {

                    var item_info = response.data;
                    this.$refs['add-new-part'].getFieldsList(item_info);
                    this.isLoadingItem = false;
                }).catch(error => {
                    console.log(error);
                    this.isLoadingItem = false;
                });
            }

        }
    }
}
</script>
