<template>
    <div class="product-page-header">
        <div v-if="!loading_cross_customers_list && cross_entity_customers_list"
             class="main-root-top sp-top-filters customers-top-filter">
            <h3 class="cross-entity-item header-customer" v-if="cross_entity_selected_customer">
                <a href="JavaScript:void(0)" class="text-dark">
                    <img v-if="cross_entity_selected_customer.logo"
                         class="rounded-circle header-profile-user"
                         :src="cross_entity_selected_customer.logo"
                         :alt="cross_entity_selected_customer.name"/>
                    <span class="ml-1">{{ cross_entity_selected_customer.name | titleize }}</span>
                </a>
            </h3>
            <ul class="root-folder filters_list custom-icons-list" style="display: none; padding: 15px;">
                <input class="form-control mb-2" v-model="customers_search" type="text" placeholder="Search..">
                <li v-for="(customer_item, index) in computed_cross_entity_customers_list"
                    :class="'root-top-menu' + (cross_entity_selected_customer && cross_entity_selected_customer.id === customer_item.id ? ' active' : '')">
                    <a @click="setSelectedCrossFilter(customer_item)" v-if="customer_item">
                        <img v-if="customer_item.logo !== ''"
                             class="rounded-circle header-profile-user"
                             :src="customer_item.logo"
                             :alt="customer_item.name"/><span class="ml-1">{{ customer_item.name | titleize }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div v-else-if="loading_cross_customers_list" class="text-center">
            <b-spinner class="text-af-accent mr-1"></b-spinner>
            <small>Loading...</small>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CrossEntityCustomerSelector',
    data(){
        return {
            customers_search: null,
            user_info: null,
            current_entity: null,
            current_page_type: null,
            cross_entity_selected_customer: {
                id:-99, name: 'Select Customer', logo: ''
            },
            cross_entity_customers_list: [],
            loading_cross_customers_list: false,
            top_filter_selected_entity: null,
            is_cross_entity: false,
        }
    },
    computed: {
        computed_cross_entity_customers_list(){
            return this.getCustomersListAfterSearch('cross_entity_customers_list', 'customers_search');
        },
    },
    created() {

        this.user_info = window.USER_INFO ?? null;

        if (this.user_info && this.user_info.hasOwnProperty('current_entity')){
            this.current_entity = this.user_info.current_entity;
            if (this.current_entity){
                this.is_cross_entity = this.current_entity.is_cross_entity;

                let entity_item_type = this.current_entity.entity_item_type;
                this.current_page_type = this.current_entity.current_page_type ? this.current_entity.current_page_type : 'customer';

                if (entity_item_type && entity_item_type !== this.current_page_type){
                    this.top_filter_selected_entity = this.getUserMetaValueIfExist('top_filter_selected_' + this.current_page_type);
                    this.loading_cross_customers_list = true;
                    let url = '/api/assetflow/'+this.current_page_type+'s';
                    axios({
                        method:'get',
                        url: url,
                        baseURL: '/',
                        params: {
                            get_all: true
                        }
                    }).then(response => {
                        let items_list = response.data.data.items;
                        this.cross_entity_customers_list = items_list.map(function (e) {
                            return { id: e.id, name: e.name, logo: e.logo };
                        });
                        this.loading_cross_customers_list = false;

                        if (this.top_filter_selected_entity) {
                            let found_item = this.cross_entity_customers_list.find((x) => x.id === (this.top_filter_selected_entity));
                            if (found_item) {
                                this.cross_entity_selected_customer = found_item;
                            }
                        }
                    }).catch(error => {
                        console.log("Response Error!", error);
                    });
                }
            }
        }
    },
    methods:{
        getCustomersListAfterSearch(list_name='', search_keyword_name='') {
            var filtered_list=[];
            if (this[search_keyword_name] && this[search_keyword_name] !== '') {
                const startsWith = wordToCompare => (element, index, array) => {
                    let searchable_values = ['id', 'name'];
                    if (element.id === null){ return true; }
                    for (let key in searchable_values) {
                        let value = element[searchable_values[key]] + "";
                        if (value.toLowerCase().includes(wordToCompare.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                }
                filtered_list = this[list_name].filter(startsWith(this[search_keyword_name]));
            } else {
                filtered_list = this[list_name];
            }
            return filtered_list
        },
        setSelectedCrossFilter(required_item){
            let item_type = this.current_page_type;
            let message = "Saved " + Vue.options.filters.titleize(item_type) + " {"+required_item.name+"} Successfully";
            window.flash(message);
            this.set_usermeta('top_filter_selected_'+item_type, required_item.id, "", true);
        },

    }
}
</script>
