<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >
                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    label="label"
                    :placeholder="'Select ' + required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    :allow-empty="false"
                    deselect-label=""
                >
                </multiselect>
                <b-form-input v-else
                    v-model="addForm[required_field.key]"
                    type="text"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-template')">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const templateResource = new Resource('api/assetflow/templates');

import multiSelect from '@/mixins/MultiSelect';
const TemplateStatusOptions = [
    {id:1, label: 'Enabled'},
    {id:0, label: 'Disabled'}
];
const TemplateTypeOptions = [
    {id:'customer', label: 'Customer'},
    {id:'sp', label: 'SP'},
    {id:'group', label: 'Group'},
    {id:'assetflow', label: 'Assetflow'},
    {id:'marketplace', label: 'Marketplace'},
];
export default {
    name: "AddNewTemplate",
    props: ['modal_title', 'item_type', 'templates_options'],
    mixins:[multiSelect],
    data(){
        return {
            addForm:{
                name: '',
                item_type: {id:'customer', label: 'Customer'},
                template_status: {id:1, label: 'Enabled'}
            },
            required_items:{
                name: {
                    key: 'name',
                    label: 'Template Name',
                    type: 'text'
                },
                template_status: {
                    key: 'template_status',
                    label: 'Template Status',
                    type: 'single-select',
                    options: TemplateStatusOptions
                },
                item_type: {
                    key: 'item_type',
                    label: 'Template Type',
                    type: 'single-select',
                    options: TemplateTypeOptions
                },
            },
            selected_item: null,
            list_disabled: null
        }
    },
    created() {
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                let entity_type = this.selected_item.entity_type;
                this.addForm.entity_type = _.find(TemplateTypeOptions, function (o) {
                    return o.id === entity_type;
                });

                let template_status = this.selected_item.status;
                this.addForm.template_status = _.find(TemplateStatusOptions, function (o) {
                    return o.id === template_status;
                });

            }
        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },


        async addItem(event){
            this.isAddingItem = true;

            var params = {
                name: this.addForm.name,
                entity_type: this.addForm.item_type ? this.addForm.item_type.id : 'customer',
                status: this.addForm.template_status ? this.addForm.template_status.id : 1
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await templateResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-template');
            this.resetSelectedItem();
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>


