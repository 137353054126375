<template>
    <SharedFormFieldsMultipleSections :add-form="addForm" :item_type="item_type" :required_fields="required_fields" :section_fields="section_fields" :object_type="object_type"/>
</template>

<script>
import SharedFormFieldsMultipleSections from "./SharedFormFieldsMultipleSections";

export default {
    name: "PaymentStep",
    components: {SharedFormFieldsMultipleSections},
    mixins:[],
    props:['item_type', 'addForm', 'required_fields', 'object_type'],
    data(){
        return {
            section_fields:{
                add_payment:{
                    name: 'Payment',
                    minimized: false,
                    fields: [
                        'payment_date',
                        'payment_type',
                        'payment_purchase_order_id',
                        'payment_nominal_code_id',
                        'payment_amount',
                        'payment_reference',
                        'payment_reconciliation'
                    ]
                },
            }
        }
    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods:{


    }
}
</script>

