<template>
    <b-modal :id="'create-'+item_type"
             size="lg"
             :ref="'create-'+item_type"
             :title="selected_item ? getTitleFor(selected_item, 'parts') : modal_title"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <ChangeCommonFields :add-form="addForm" :required_fields="required_fields"/>

        <div class="text-right">
            <b-button type="submit" variant="success" :disabled="isSavingItem" v-on:click.once="addItem($event)">
                <span v-show="!isSavingItem"> Update  </span>
                <div v-show="isSavingItem" class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Saving...</span>
                </div>
            </b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('create-'+item_type)">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFields from "./ChangeCommonFields";
const imageResource = new Resource('api/assetflow/images/editNotes');

export default {

    name: "EditImageNotes",
    props: ['modal_title', 'item_type'],
    mixins: [],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                name: null,
                description: null,
                is_featured: null,
            },
            required_fields:{
                name: {
                    key: 'name',
                    label: 'Image Title',
                    type: 'text'
                },
                description: {
                    key: 'description',
                    label: 'Image Notes',
                    type: 'textarea'
                },
                is_featured: {
                    key: 'is_featured',
                    label: 'Featured',
                    type: 'radio-select',
                    options:[
                        { id: 1, name: 'Yes' },
                        { id: 0, name: 'No' }
                    ]
                },
            },
            isSavingItem: false,
            selected_item: null,
            list_disabled: null,
            item_index: null,
            done_action: null
        }
    },
    created() {
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
            this.isSavingItem = false;
        },
        fillSelectedItem() {
            if (this.selected_item) {
                this.addForm.name = this.selected_item.name;
                this.addForm.description = this.selected_item.description;
                if (this.selected_item.hasOwnProperty('is_featured')){
                    this.addForm.is_featured = this.required_fields.is_featured.options[this.selected_item.is_featured === 1 ? 0 : 1];
                }
            }
        },
        resetFormValues(){
            this.addForm = {
                name: null,
                description: null,
                is_featured: null,
            };
        },
        setDoneAction(done_action){
            if (done_action){
                this.done_action = done_action;
            }
        },
        setSelectedItem(item){
            if (item){
                if (item.hasOwnProperty('index')){
                    this.item_index = item.index;
                }
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
                this.resetFormValues();
            }
        },

        async addItem(event){
            this.isSavingItem = true;

            var params = {
                name: this.addForm.name,
                description: this.addForm.description,
                is_featured: this.addForm.is_featured ? this.addForm.is_featured.id : 0,
                edit_type : 'edit_notes'
            };
            if (this.selected_item && this.selected_item.id !== -99){
                params.item_id = this.selected_item.id;
            }

            const { data } = await imageResource.store(params);


            let new_item = data.data.items;

            let status_message = 'Error!',
                status_class = 'danger',
                message = data.data.msg;

            if (new_item){
                status_message = 'Success!';
                message = (this.selected_item && this.selected_item.id !== -99 ? 'Edited' : 'Added') + ' Successfully';
                status_class = 'success';
            }

            this.presentToast(status_message,
                message,
                status_class,
                3000,
                true,
                true);

            this.isSavingItem = false;
            this.$bvModal.hide('create-'+this.item_type);
            this.resetSelectedItem();

            if (this.done_action !== null){
                this.done_action(new_item);
            }else{
                this.$emit('on-new-item-adding', new_item, this.item_index);
            }
        }
    }
}
</script>
