<template>
    <div class="ChangeSceneWardrobeTab">

        <generic-tasks-form
            ref="generic-tasks-form"
            :modal_id="'icons-action-tasks' + unique_id"
            sub_modal_id="wardraobe"
            :custom_keys_only="true"
            modal_title="Complete task"
            @on-completing-task="itemTaskCompleted"
            @on-modal-cancel="selected_asset_id = null"
        />

        <div class="row">

            <SharedModalsBlock
                ref="shared-modals-block"
                :item_type="item_type"
                :update_asset_costs_id="update_asset_costs_id"
                :update_asset_details_id="update_asset_details_id"
                :update_asset_images_id="update_asset_images_id"
                :refreshAsset="refreshAsset"
                @on-modal-cancel="selected_asset_id = null"
            />

            <div class="col-12 wardrobe-layouts">
                <div class="grid-list-button d-flex align-items-center" style="float: right;">
                    <div
                        v-if="supportedLayouts && Object.keys(supportedLayouts).length > 0"
                        v-for="(layout, key) in supportedLayouts"
                        :key="key"
                        @click="changeObjectLayout(layout);"
                        :id="'grid-list-'+layout.name.toLowerCase()"
                        :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                    </div>
                </div>
            </div>

            <NewCustomGenericBlock
                block_title="Closet"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>

                    <div v-if="selectedLayout === 1 && item_info.hasOwnProperty('wardrobe_items') && item_info.wardrobe_items && item_info.wardrobe_items.length > 0">


                        <div class="popup-gallery">
                            <vue-easy-lightbox
                                :visible="visibleCaption"
                                :imgs="captionimgs"
                                :index="index"
                                @hide="handleCaptionHide">
                            </vue-easy-lightbox>
                        </div>


                        <AssetClosetItem
                            v-for="(item, key) in item_info.wardrobe_items"
                            :key="key"
                            class="cm_list_view asset-list-item" :class="getAssetClasses(item, selected_asset_id)"
                            :item="item"
                            :item_type="item_type"
                            :index="key"
                            :perform-action="performAction"
                            :show-captiom-img="showCaptiomImg"
                        >

                            <template v-slot:action_button="{ asset, index }">
                                <div class="cm_usage">
                                    <p v-if="hasPermission('edit_assets')">
                                        <b-button class="btn btn-sm af-btn-accent af-accent asset-closet-add" @click="addToChange(asset, index)">Add to Change</b-button>
                                    </p>
                                </div>
                            </template>
                        </AssetClosetItem>

                    </div>
                    <po-lines v-else-if="item_info.hasOwnProperty('wardrobe_items') && item_info.wardrobe_items && item_info.wardrobe_items.length > 0"
                              :items="item_info.wardrobe_items"
                              :current_page_type="item_info.current_page_type"
                              :object_type="'assets'"
                              :line_type="'assets'"
                              :keys_list="wardrobe_items_key_list">

                        <template v-slot:action_button="{ item, index }">
                            <b-button
                                v-if="hasPermission('edit_assets')"
                                class="btn btn-sm af-btn-accent af-accent mt-2 btn-block asset-closet-add"
                                @click="addToChange(item, index)"
                            >Add to Change</b-button>
                        </template>

                        <template v-slot:wardrobe_actions="{ item, index }">
                            <AssetActionsDropdown
                                :is_closet_asset="true"
                                :item="item"
                                :item_type="item_type"
                                :perform-action="performAction"/>

                        </template>

                    </po-lines>
                    <div v-else class="text-center">
                        <h6>No Closet Items found here at the moment.</h6>
                    </div>
                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>

import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";
import {WardrobeKeysList} from "@/views/assetflow/form_data/WardrobeKeysList";
import Resource from '@/api/resource';
import ImageGallery from "@/mixins/ImageGallery.mixin";
import ClosetSharedActions from "@/mixins/ClosetSharedActions.mixin";
import VueEasyLightbox from 'vue-easy-lightbox';
import AssetClosetItem from "./AssetClosetItem";
import AssetActionsDropdown from "../../assets/subItems/AssetActionsDropdown";
import SharedModalsBlock from "./SharedModalsBlock";
import GenericTasksForm from "../widgets/detailsViews/AssetTabs/GenericTasksForm";

const addToChangeResource = new Resource('api/assetflow/changes/addToChange');
export default {
    name: 'ChangeSceneWardrobeTab',
    mixins:[ImageGallery, ClosetSharedActions],
    components: {
        GenericTasksForm,
        SharedModalsBlock,
        AssetActionsDropdown,
        AssetClosetItem, NewCustomGenericBlock, PoLines, VueEasyLightbox},
    props: {
        item_info: {},
        item_type: {}
    },
    data(){
        return {
            unique_id: 'wardrobe_tab',
            wardrobe_items_key_list: WardrobeKeysList,
            object_type: 'wardrobe_list',
            list_name: 'wardrobe_items',
            item_info_name: 'item_info',
        }
    },
    created() {
        let item_info = this.item_info;
        this.wardrobe_items_key_list.character_id.value_func = function (item, key) {
            return item_info.hasOwnProperty('character_info') && item_info.character_info ? item_info.character_info.name : '';
        }
    },
    methods: {
        async addToChange(item, index) {

            let params = {
                change_id: this.item_info.id,
                asset_id: item.id,
                item_type: 'asset',
            }

            const { data } = await addToChangeResource.store(params);
            let new_item = data.data.status;

            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Added To Change Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);

            if (new_item){
                this.$emit('after-item-moving', item, 'costume_items', index)
            }
        },
    }

}
</script>
