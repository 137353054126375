<template>
    <div class="intelligence-template">

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }} <span v-if="tab.badge !== ''" class="badge badge-soft-primary ">{{tab.badge}}</span></a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="sp_storage_overview_tab" role="tabpanel" :class="isActiveTab('sp_storage_overview_tab')">

                <ParkStorageItem
                    ref="assign-sp_storages-location"
                    modal_id="assign-sp_storages-location"
                    item_type='sp_storages'
                    modal_title="Park Storage"
                    @on-park-storage="refreshItem"
                >
                </ParkStorageItem>


                <ChangeStorageUtilisation
                    ref="change-sp_storages-utilisation"
                    modal_id="change-sp_storages-utilisation"
                    item_type='sp_storages'
                    modal_title="Change Storage Utilisation"
                    @on-utilisation-change="refreshItem"
                >
                </ChangeStorageUtilisation>

                <ParkStorageItem
                    ref="assign-sp_storages-location"
                    modal_id="assign-sp_storages-location"
                    item_type='sp_storages'
                    modal_title="Park Storage"
                    @on-park-storage="refreshItem"
                >
                </ParkStorageItem>

                <RemoveStorageLocation
                    ref="remove-sp_storages-location"
                    modal_id="remove-sp_storages-location"
                    item_type='sp_storages'
                    modal_title="Remove Storage"
                    @on-remove-storage="refreshItem"
                >
                </RemoveStorageLocation>

                <RelocateAllSpStorageItems
                    ref="relocate-sp_storages-items"
                    modal_id="relocate-sp_storages-items"
                    modal_title="Relocate All Items"
                    :item_type="item_info.current_page_type"
                    @on-storage-update="refreshItem"
                />

                <AssignStorageCustomer
                    ref="assign-sp_storages-customer"
                    modal_id="assign-sp_storages-customer"
                    item_type='sp_storages'
                    modal_title="Assign Customer"
                    @on-assign-to-customer="refreshItem"
                >
                </AssignStorageCustomer>

                <RemoveStorageCustomer
                    ref="remove-sp_storages-customer"
                    modal_id="remove-sp_storages-customer"
                    item_type='sp_storages'
                    modal_title="Remove Storage Customer"
                    @on-remove-storage="refreshItem"
                >
                </RemoveStorageCustomer>

                <ReserveStorageLocation
                    ref="reserve-sp_storages"
                    modal_id="reserve-sp_storages"
                    item_type='sp_storages'
                    modal_title="Reserve Storage"
                    @on-remove-storage="refreshItem"
                >
                </ReserveStorageLocation>

                <ArchiveStorageLocation
                    ref="archive-sp_storages"
                    modal_id="archive-sp_storages"
                    item_type='sp_storages'
                    modal_title="Archive Storage"
                    @on-remove-storage="refreshItem"
                >
                </ArchiveStorageLocation>

                <SpStorageOverviewTab
                    :item_info="item_info"
                    :go-to-relocate-wizard="goToRelocateWizard"
                    :perform-action="performAction"
                    :show-modal="showModal"
                    :get-location-status="getLocationStatus"
                    :get-location-block="getLocationBlock"
                    :get-sp-storage-status="getSpStorageStatus"
                />
            </div>
            <div class="tab-pane" id="assets_in_storage_tab" role="tabpanel" :class="isActiveTab('assets_in_storage_tab')">

                <BulkActionsBar
                    :is_actions_bar_opened="is_actions_bar_opened"
                    :is_selecting_items="is_selecting_items"
                    :shouldToggleSelecting="shouldToggleSelecting"
                    :selected_objects="selected_objects"
                    :continueClicked="continueClicked"
                    :performSelectionAction="performSelectionAction"
                    :should_hide_unselected="should_hide_unselected"
                    :enableBulkActions="enableBulkActions"
                    :all_items_count="all_items_count"
                    :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                />

                <ObjectManagedStorageList
                    ref="ObjectManagedStorageList"
                    :item_info="item_info"
                    parent_object_type="sp_storages"
                    @when-list-is-loaded="whenListIsLoaded"

                    :is_actions_bar_opened="is_actions_bar_opened"
                    :is_selecting_items="is_selecting_items"
                    :selected_ids="selected_ids"
                    :addToSelectedList="addToSelectedList"
                    :should_hide_unselected="should_hide_unselected"
                    :preform-bulk-action="preformBulkAction"
                    :bulk_actions_customer_id="bulk_actions_customer_id"
                />

            </div>
            <div class="tab-pane" id="sp_storage_tasks_tab" role="tabpanel" :class="isActiveTab('sp_storage_tasks_tab')">
                <ObjectTasksList :item_info="item_info" object_type="order_lines"/>
            </div>
            <div class="tab-pane" id="asset_movements_tab" role="tabpanel" :class="isActiveTab('asset_movements_tab')">
                <ObjectMovementList :item_info="item_info" object_type="managed_storages"/>
            </div>
            <div class="tab-pane" id="sp_storage_movements_tab" role="tabpanel" :class="isActiveTab('sp_storage_movements_tab')">
                <ObjectMovementList :item_info="item_info" object_type="sp_storages"/>
            </div>
            <div class="tab-pane" id="sp_storage_activity_tab" role="tabpanel" :class="isActiveTab('sp_storage_activity_tab')">
                <SpSharedActivityTab :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="sp_storage_media_tab" role="tabpanel" :class="isActiveTab('sp_storage_media_tab')">
                <MediaTab :item_info="item_info" object_type="sp_storages"/>
            </div>
            <div class="tab-pane" id="list_bulk_actions" role="tabpanel" :class="isActiveTab('list_bulk_actions')">

                <AddNewOrder
                    ref="add-new-order"
                    modal_title="Add New Storage Order"
                    :item_type="item_info.current_page_type"
                    object_type="orders"
                    :received_order_type="1"
                    :received_customer="bulk_actions_customer_id"
                    @on-new-item-adding="appendOrderToOrdersList"
                    @on-cancel-clicked="$bvModal.hide('create-orders')"
                />

                <BulkActionsBlock
                    ref="bulk-actions-block"
                    :required_actions="required_actions"
                    :object_type="object_type"
                    :is_selecting_items="is_selecting_items"
                    :selected_objects="selected_objects"
                    :add-to-selected-list="addToSelectedList"
                    :perform-bulk-action="performBulkAction"
                    :total="all_items_count"
                    :item_type="item_info.current_page_type"
                    :is-selected="isSelected"
                    :order-select-details="orderSelectDetails"
                    :toggle-accordion="toggleAccordion"
                    :selected_items_mode="selected_items_mode"
                    :setSelectedItemsMode="setSelectedItemsMode"
                    :startSelectingItems="startSelectingItems"
                    :setBulkActionOptions="setBulkActionOptions"
                    :selected_customer="(item_info.hasOwnProperty('customer_info') && item_info.customer_info ? item_info.customer_info : null)"
                    :all_items_count="all_items_count"
                    :bulk_actions_customer_id="bulk_actions_customer_id"
                    :listLoading="listLoading"
                    :orders_list="orders_list"
                    :storages_list="storages_list"
                    :storage_id_listLoading="storage_id_listLoading"
                    :sp_storage_keys_list="sp_storage_keys_list"
                    :recent_storage_list="recent_storage_list"
                    :recent_storage_label="recent_storage_label"
                    :setSelectedOrder="setSelectedOrder"
                    :setSelectedStorage="setSelectedStorage"

                    :required_bulk_actions_customer_id="required_bulk_actions_customer_id"
                    :addNewOrder="addNewOrder"
                />

            </div>
        </div>
    </div>
</template>
<script>

import MediaTab from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/MediaTab";
import SpSharedActivityTab from "./SpSharedActivityTab";
import ObjectMovementList from "./ObjectMovementList";
import ObjectManagedStorageList from "./ObjectManagedStorageList";
import SpStorageOverviewTab from "./SpStorageOverviewTab";

import SpStorageShared from "@/mixins/SpStorageShared.mixin";
import ParkStorageItem from "../customers/sp_storages/ParkStorageItem";
import ChangeStorageUtilisation from "../customers/sp_storages/ChangeStorageUtilisation";
import RemoveStorageLocation from "../customers/sp_storages/RemoveStorageLocation";
import RelocateAllSpStorageItems from "../customers/sp_storages/RelocateAllSpStorageItems";
import AssignStorageCustomer from "../customers/sp_storages/AssignStorageCustomer";
import RemoveStorageCustomer from "../customers/sp_storages/RemoveStorageCustomer";
import ReserveStorageLocation from "../customers/sp_storages/ReserveStorageLocation";
import ArchiveStorageLocation from "../customers/sp_storages/ArchiveStorageLocation";
import SharedBulkUpdate from "@/mixins/SharedBulkUpdate.mixin";
import SharedBulkUpdateFetchLists from "@/mixins/SharedBulkUpdateFetchLists.mixin";

import BulkActionsBlock from "../customers/managed_storages/BulkActionsBlock";

import {SPStorageShortestKeysList} from "@/views/assetflow/form_data/SPStorageShortestKeysList";
import BulkActionsBar from "../customers/managed_storages/BulkActionsBar";
import AddNewOrder from "../customers/extras/AddNewOrder";
import ObjectTasksList from "./ObjectTasksList";

export default {
    name: 'CustomSpStorageDetails',
    components: {
        ObjectTasksList,
        AddNewOrder,
        BulkActionsBar,
        BulkActionsBlock,
        ArchiveStorageLocation,
        ReserveStorageLocation,
        RemoveStorageCustomer,
        AssignStorageCustomer,
        RelocateAllSpStorageItems,
        RemoveStorageLocation,
        ChangeStorageUtilisation,
        ParkStorageItem,
        SpStorageOverviewTab, ObjectManagedStorageList, ObjectMovementList, SpSharedActivityTab, MediaTab },
    props: ['item_info'],
    mixins: [SpStorageShared, SharedBulkUpdate, SharedBulkUpdateFetchLists],
    data(){
        return {
            active_tab: 'sp_storage_overview_tab',
            tabs: {
                'sp_storage_overview_tab': {
                    'id': "sp_storage_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "sp_storage_overview_tab",
                    'isActive': false
                },
                'assets_in_storage_tab': {
                    'id': "assets_in_storage_tab",
                    'name': "Assets In Storage",
                    'icon': "user",
                    'type': "assets_in_storage_tab",
                    'isActive': false
                },
                'sp_storage_tasks_tab': {
                    'id': "sp_storage_tasks_tab",
                    'name': "Tasks",
                    'icon': "user",
                    'type': "sp_storage_tasks_tab",
                    'isActive': false
                },
                'asset_movements_tab': {
                    'id': "asset_movements_tab",
                    'name': "Asset Movements",
                    'icon': "user",
                    'type': "asset_movements_tab",
                    'isActive': false
                },
                'sp_storage_movements_tab': {
                    'id': "sp_storage_movements_tab",
                    'name': "Movements",
                    'icon': "user",
                    'type': "sp_storage_movements_tab",
                    'isActive': false
                },
                'sp_storage_activity_tab': {
                    'id': "sp_storage_activity_tab",
                    'name': "Activity",
                    'icon': "user",
                    'type': "sp_storage_activity_tab",
                    'isActive': false
                },
                'sp_storage_media_tab': {
                    'id': "sp_storage_media_tab",
                    'name': "Media",
                    'icon': "user",
                    'type': "sp_storage_media_tab",
                    'isActive': false
                }
            },
            required_actions: [],
            object_type: 'sp_storages',
            sp_storage_keys_list: SPStorageShortestKeysList,
            all_items_count: 0,
            listLoading: true,
            object_type_list_active_tab: 'sp_storages_active_tab',
        }
    },
    created() {
        if (this.hasPermission('can_add_storage_order')){
            this.required_actions.push('tab_add_to_order');
        }
        if (this.hasPermission('browse_sp_storages')){
            this.required_actions.push('tab_change_storage');
        }

        this.list_items_tab_name = 'assets_in_storage_tab';
        this.list_bulk_actions_tab_name = 'list_bulk_actions';
        this.resetSelectingItems();

        this.getListOf('all_orders', 'sp_storage');
        if (this.item_info.hasOwnProperty('current_customer_id')){
            this.getSPCustomerStorage(this.item_info.current_customer_id, this.item_info.id);
        }

        this.tab_list_name = 'tabs';
        if (this.user_info && this.user_info.hasOwnProperty('can_bulk_update')){
            if (this.user_info.can_bulk_update === 1){
                this.appendBulkActionsTab(this.tab_list_name);
                this.can_bulk_update = true;
            }
        }

        if (this.item_info.hasOwnProperty('storage_wrapper_info') && this.item_info.storage_wrapper_info){
            this.tabs.sp_storage_movements_tab.name = this.item_info.storage_wrapper_info.singular_name + ' Movements';
        }

        this.tabs.sp_storage_tasks_tab.badge = this.item_info.tasks_count ?? 0;

        let sp_storages_active_tab = localStorage.getItem(this.object_type_list_active_tab);
        if ((!sp_storages_active_tab || sp_storages_active_tab === '') && !this.can_bulk_update){
            sp_storages_active_tab = this.list_items_tab_name;
        }


        let queryVars = this.getQueryVars();
        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'movements':
                    this.active_tab = 'sp_storage_movements_tab';
                    break;
                case 'activity':
                    this.active_tab = 'sp_storage_activity_tab';
                    break;
                case 'media':
                    this.active_tab = 'sp_storage_media_tab';
                    break;
                case 'tasks':
                    this.active_tab = 'sp_storage_tasks_tab';
                    break;
            }
        }else if (sp_storages_active_tab && sp_storages_active_tab !== ''){
            this.active_tab = sp_storages_active_tab;
        }

    },
    methods:{
        tabClicked(tab_id){
            this.active_tab = tab_id;
            localStorage.setItem(this.object_type_list_active_tab, tab_id);
        },
        whenListIsLoaded(total, listLoading){
            this.all_items_count = total;
            this.listLoading = listLoading;
        },
        refreshItem(modal_id, edited_asset, required_keys=null){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_asset){ return; }
            if (required_keys){
                for (const requiredKeysKey in required_keys) {
                    let required_key = required_keys[requiredKeysKey];
                    this.item_info[required_key] = edited_asset[required_key];
                }
            }else{
                let message = edited_asset ? ('Saved Successfully') : '';
                window.flash(message);
                location.reload();
            }
        },

        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>
