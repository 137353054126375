<template>
    <Layout>
        <PageHeader :title="title" :items="items" />


        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h6>DB data</h6>
                        <table class="table table-responsive table-striped">
                            <thead>
                            <th>Date</th>
                            <th>Name</th>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) of isFileData" :key="index">
                                <td v-if="item.updated_at">
                                    {{ item.updated_at.slice(0, 11) }}
                                </td>
                                <td>
                                    {{ item.filename }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                Create, test & deploy a process for managing and importing
                                different types of CSV data
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input
                                        type="file"
                                        id="file"
                                        ref="file"
                                        class="form-control"
                                        @change="onFileChange"
                                    />
                                </div>
                                <button
                                    v-on:click="submitFile()"
                                    type="submit"
                                    class="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card" v-if="fileResponse">
                    <div class="card-body">
                        <h1>
                            This is a
                            {{
                                fileResponse.data &&
                                fileResponse.data[0].includes("Folder Name")
                                    ? "Folders"
                                    : "Assets"
                            }}
                            flow file.
                        </h1>
                        {{ fileResponse.data }}
                        <table class="table display">
                            <div
                                v-for="(data, indexmain) of fileResponse.data"
                                :key="indexmain"
                            >
                                <thead v-if="indexmain === 0">
                                <tr>
                                    <th
                                        scope="col"
                                        v-for="(subData, index) of data"
                                        :key="index"
                                    >
                                        {{ subData }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="indexmain !== 0">
                                <tr>
                                    <td v-for="(subData, index2) of data" :key="index2">
                                        {{ subData }}
                                    </td>
                                </tr>
                                </tbody>
                            </div>
                        </table>

                        <button
                            type="submit"
                            class="btn btn-primary"
                            v-on:click="appendFile()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </Layout>
</template>

<script>
import Layout from "@/layouts/main";
import PageHeader from "@/components/page-header";

export default {
    props:['item_id', 'item_type', 'action'],
    name: "CsvImport",
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Import From CSV",
            items: [
                {
                    text: "Assets",
                    href: "/"
                },
                {
                    text: "Import CSV",
                    active: true
                }
            ],

            fileData: null,
            fileResponse: null,
            isFileData: null,
        };
    },
    created() {
    },
    methods: {
        submitFile() {
            let formData = new FormData();
            formData.append("file", this.fileData);
            axios.post(process.env.MIX_AJAX_URL + "/api/assetflow/assets/importfile", formData, {
                headers: {
                    "Content-Type": "multipart/for-data",
                },
            })
                .then((response) => {
                    this.fileResponse = response.data;
                });
        },

        onFileChange() {
            this.fileData = this.$refs.file.files[0];
        },
        appendFile() {
            let payload = {
                filename: this.fileResponse.data,
                fileid: this.fileResponse.fileId,
            };
            axios.post("/api/assetflow/assets/insertFile", payload).then((response) => {
                return response;
            });
        },
    },
    mounted() {
        axios.get(process.env.MIX_AJAX_URL + "/api/assetflow/assets/fetchData").then((response) => {
            console.log(response);
            this.isFileData = response.data;
        });
    },
}
</script>


