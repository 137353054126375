<template>
    <div class="row">
        <div class="col-12">
            <h3 v-if="title !== ''"></h3>
            <po-lines v-if="currentUserAllRoles && currentUserAllRoles.length > 0"
                      :items="currentUserAllRoles"
                      :object_type="'role'"
                      :line_type="'role'"
                      :keys_list="role_list_key_list">
                <template v-slot:switch_entity_actions="{ item, index }">
                    <div>
                        <b-button
                            size="sm"
                            v-if="item.hasOwnProperty('owner_info') &&
                                  item.owner_info &&
                                  (item.owner_info.id === current_entity.id) &&
                                  (item.role_type === current_entity.original_entity_type) &&
                                  item.module_info &&
                                  (item.module_info.id === current_module.id)"
                            href="javascript:void(0);"
                            disabled
                            variant="primary">Current {{ item.role_type | titleize}}</b-button>

                        <b-button size="sm"
                                  v-else
                                  href="javascript:void(0);"
                                  v-on:click="switchTo(item, $event)"
                                  variant="primary">Switch {{ item.role_type | titleize}}</b-button>

                    </div>
                </template>
            </po-lines>
            <div v-else class="text-center">
                <h6>No roles found here at the moment.</h6>
            </div>
        </div>
    </div>
</template>
<script>
import PoLines from "../assetflow/items/widgets/detailsViews/PoLines"

export default {
    name: 'CurrentUserRoleList',
    components: {PoLines},
    props: ['title'],
    data(){
        return {
            isSendingRequest: false,
            currentUserAllRoles: [],
            role_list_key_list: {
                entity: {
                    key: 'entity',
                    label: "Entity",
                    class: "col-lg-4 rol_entity",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let html = '';
                        if (item.hasOwnProperty('owner_info') && item.owner_info){
                            let item_info = item.owner_info;
                            let avatar_html = '<img src="'+(item_info.hasOwnProperty('edited_logo') ? item_info.edited_logo : '')+'" alt="'+item_info.name+'" class="rounded-circle item-post-image list-usr-avatar" width="25" height="25">';
                            return '<div>' + avatar_html + ' '+item_info.name+'</div>';
                        }
                        return html;
                    }
                },
                module: {
                    key: 'module',
                    label: "Module",
                    class: "col-lg-2 rol_module",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let module_info = item.hasOwnProperty('module_info') ? item.module_info : null;
                        if (module_info){
                            let item_link = Vue.options.filters.titleize(module_info.name);
                            return Vue.options.filters.iconize(item_link, 'modules');
                        }
                        return '-';
                    }
                },
                name: {
                    key: 'name',
                    label: "Role",
                    class: "col-lg-2 rol_name",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        let item_link = Vue.options.filters.titleize(item.name);
                        return Vue.options.filters.iconize(item_link, 'roles');
                    }
                },
                description: {
                    key: 'description',
                    label: "Description",
                    class: "col-lg-3 rol_description",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return item.description;
                    }
                },
                switch_entity: {
                    key: 'switch_entity',
                    label: "",
                    class: "col-lg-1 rol_switch_entity",
                    show_on_mobile: true,
                    case: 'custom_slot',
                    slot_name: 'switch_entity_actions'
                },
            },
        }
    },
    created() {
        if (this.user_info && this.user_info.hasOwnProperty('currentUserAllRoles') && this.user_info.currentUserAllRoles) {
            this.currentUserAllRoles = this.user_info.currentUserAllRoles;
        }
    },
    methods:{

        switchTo(item, event) {
            let item_type = item.role_type;
            let item_id;
            if (item.hasOwnProperty('owner_info') && item.owner_info){
                item_id = item.owner_info.id
            }


            if (event && item_id && !this.isSendingRequest && ['customer', 'sp'].includes(item_type)) {
                // let element = event.target;

                let entity_type_id = 0;
                if (item_type === 'sp'){
                    entity_type_id = 2;
                }

                let strongThis = this;
                this.set_usermeta('selected_entity_id', entity_type_id, "", false, function () {
                    strongThis.isSendingRequest = true;
                    axios({
                        method:'post',
                        url:'/api/assetflow/switch-'+item_type,
                        baseURL: '/',
                        data: {
                            item_id: item_id,
                            item_type: item_type,
                            module_id: item.module_id
                        },
                    }).then(response => {
                        strongThis.isSendingRequest = false;
                        if (response.data.data.status === true){

                            window.location.href = '/' + item_type + '/overview';
                            // location.reload();
                        }else{
                            console.log('Something went wrong');
                        }

                    }).catch(error => {
                        console.log(error);
                        strongThis.isSendingRequest = false;
                    });
                });

            }
        }
    }
}
</script>
