<template>
    <div class="ChangeSceneScenesTab">

        <div class="row change-scene-items">
            <NewCustomGenericBlock
                block_title="Scenes"
                columns_count_withclasses="12"
                box_colour_v="-"
            >
                <template v-slot:all_content>

                    <po-lines v-if="item_info.change_info.hasOwnProperty('parts_info') && item_info.change_info.parts_info && item_info.change_info.parts_info.length > 0"
                              :items="item_info.change_info.parts_info"
                              :current_page_type="item_type"
                              :object_type="'parts'"
                              :line_type="'parts'"
                              :keys_list="parts_key_list">
                        <template v-slot:parts_actions="{ item, index }">
                            <div class="btn-group bl-auto">
                                <button type="button" class="btn btn-none dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                    <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <b-dropdown-item :href="'/' + item_type + '/changes/'+item_info.change_info.id + '/scene/' + item.id">View Details</b-dropdown-item>
                                    <b-dropdown-item :href="'/' + item_type + '/shoot-day-live'">View SDL</b-dropdown-item>
                                </div>
                            </div>
                        </template>

                    </po-lines>
                    <div v-else class="text-center">
                        <h6>No Scenes found here at the moment.</h6>
                    </div>
                </template>
            </NewCustomGenericBlock>
        </div>

    </div>
</template>
<script>
import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";
import NewCustomGenericBlock from "@/views/assetflow/items/widgets/detailsViews/AssetTabs/NewCustomGenericBlock";

import {PartChangeKeysList} from "@/views/assetflow/form_data/PartChangeKeysList";

export default {
    name: 'ChangeSceneScenesTab',
    components: {NewCustomGenericBlock, PoLines},
    props: {
        item_info: {},
        item_type: {},
    },
    data(){
        return {
            has_actions_permissions:false,
            parts_key_list: PartChangeKeysList
        }
    },
    created() {
    },
    methods: {
        performAction(item, action_name, event) {
        },

    }

}
</script>
