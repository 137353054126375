<template>
    <div class="entity-subscribers-list">
        <div v-if="item_info.hasOwnProperty('entity_subscribers') && item_info.entity_subscribers" class="row">
            <div class="col-lg-12">
                <po-lines
                    :items="item_info.entity_subscribers"
                    :current_page_type="item_info.current_page_type"
                    :object_type="'entity_subscribers'"
                    :keys_list="entity_subscriber_list_keys_list">
                    <template v-slot:entity_subscribers_actions="{ item, index }">
                        <a :href="'/'+ item_info.current_page_type + '/users/' + item.user.id + '/view'" class="btn btn-primary btn-sm">View</a>
                        <a @click="removeUserSubscriber(item, $event)" class="btn btn-outline-danger btn-sm">Remove</a>
                    </template>
                </po-lines>
            </div>
        </div>
    </div>
</template>
<script>
import PoLines from "./detailsViews/PoLines";
const firstPath = window.location.pathname.split('/')[1];

export default {
    name: 'EntitySubscribersList',
    components: {PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            entity_subscriber_list_keys_list:{
                id: {
                    key: 'id',
                    label: "ID",
                    class: "col-lg-2 s_user_id",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (subscriber_info, key) {
                        let item = subscriber_info.user;
                        return item ? item.id : '-';
                    }
                },
                user_name: {
                    key: 'user_name',
                    label: "Name",
                    class: "col-lg s_user_name",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (subscriber_info, key) {
                        let item = subscriber_info.user;
                        if (item){
                            return '<img src="'+(item.hasOwnProperty('avatar_url') ? item.avatar_url : item.avatar)+'" alt="'+item.name+'" class="rounded-circle item-post-image" width="25" height="25"> <a href="/' + firstPath + '/users/'+item.id+'/view">' + Vue.options.filters.titleize(item.name) + '</a>';
                        }
                        return '-';
                    }
                },
                sp_info: {
                    key: 'sp_info',
                    label: "SP",
                    class: "col-lg-2 s_sp_info",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (subscriber_info, key) { }
                },
                created_at: {
                    key: 'created_at',
                    label: "Date",
                    class: "col-lg-2 s_created_at",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (subscriber_info, key) {
                        return subscriber_info.created_at ? Vue.options.filters.moment(subscriber_info.created_at, 'DD/MM/YYYY HH:mm') : '';
                    }
                },
            },
        }
    },
    created() {


        if (this.item_info.hasOwnProperty('entity_subscribers') && this.item_info.entity_subscribers){
            let first_subscriber = this.item_info.entity_subscribers[0];
            if (first_subscriber && first_subscriber.hasOwnProperty('sp')){
                this.entity_subscriber_list_keys_list.sp_info = {
                    key: 'sp_info',
                    label: "SP",
                    class: "col-lg-2 s_sp_info",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (subscriber_info, key) {
                        let item = subscriber_info.sp;
                        if (item){
                            return '<a href="/' + firstPath + '/service_providers/'+item.id+'/view">' + Vue.options.filters.titleize(item.name) + '</a>';
                        }
                        return '-';
                    }
                };
            }else{
                delete this.entity_subscriber_list_keys_list.sp_info;
            }
        }
        if (this.hasPermission('browse_users')){
            this.entity_subscriber_list_keys_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-2 l_actions",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_class: '',
                slot_name: 'entity_subscribers_actions'
            }
        }
    },
    methods:{
        removeUserSubscriber(user_subscriber, event) {
            if (event){
                event.preventDefault();
            }

            if (!user_subscriber){
                return;
            }
            let assignData = {
                action: 'remove',
                user_id: user_subscriber.user_id,
                sp_id: user_subscriber.sp_id,
                customer_id: user_subscriber.customer_id
            }
            axios({
                method: 'post',
                url: '/api/assetflow/service_providers/addUserSubscriber',
                baseURL: '/',
                data: assignData,
            }).then(response => {
                    const { status, msg } = response.data.data;
                    const toastType = status ? 'success' : 'danger';
                    const toastMessage = status ? 'Success!' : 'Failed!';

                    this.presentToast(
                        toastMessage,
                        msg,
                        toastType,
                        3000,
                        true,
                        true
                    );

                    if (status) {
                        this.$emit('refresh-users-list');
                    }
                })
                .catch(error => {
                    // Handle error
                    console.error('Error removing user subscriber:', error);
                    // Display an error message or handle the error accordingly
                });
        }
    }
}
</script>
