<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">

            <b-form-group v-if="item_info.item_type === 'users' && item_info.current_page_type === 'assetflow'" label="Choose Customer/SP" label-for="owners_list">

                <b-form-select
                    id="owners_list"
                    name="customer_sp"
                    v-model="customer_sp"
                    @change="changedOwner">
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                    <option v-for="(owner, key) in owners_list" :key="key" :value="owner">
                        {{ owner.item_type | titleize }} => {{ owner.text }}
                    </option>
                </b-form-select>

            </b-form-group>

            <b-form-group label="Choose Module" label-for="modules_list">
                <b-form-select
                    id="modules_list"
                    name="module"
                    v-model="$v.form.item.$model"
                    :options="received_items_list"
                    :state="validateState('item')"
                    @change="onModuleSelect($event)"
                    aria-describedby="live-feedback"
                    value-field="id"
                    text-field="name"
                >
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group v-if="$v.form.item.$model" label="Add These Module Roles?">
                <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="form.roles_list"
                    :options="selected_roles_list"
                    value-field="id"
                    text-field="name"
                ></b-form-checkbox-group>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('modules-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import Commons from "@/mixins/common_methods.mixin";
    import { required } from "vuelidate/lib/validators";

    export default {
        name: "AssignToModule",
        mixins: [validationMixin, Commons],
        props: {
            items: Array,
            item_info: Object
        },
        data() {
            return {
                selected_roles_list: null,
                item_id: null,
                selected_item: "",
                form: {
                    item: "",
                    roles_list: []
                },
                received_items_list: null,
                customer_sp: '',
                owners_list: null
            }
        },
        validations: {
            form: {
                item: {
                    required
                },
                roles_list: []
            }
        },
        created() {
            this.item_id = this.item_info.id;
            if (this.item_info.item_type === 'users' && this.item_info.current_page_type === 'assetflow'){
                this.received_items_list = null;
                this.getOwnersList();
            }else{
                this.received_items_list = this.items;
            }
        },
        methods: {
            onModuleSelect(event){
                this.form.roles_list = [];
                if (event){
                    let found_index = this.received_items_list.map(function (e) {
                        return e.id;
                    }).indexOf(event);
                    if (found_index > -1){
                        this.selected_roles_list = this.received_items_list[found_index].module_roles;
                        return;
                    }
                }
                this.selected_roles_list = [];
            },
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    item: "",
                    roles_list: []
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                console.log("Form submitted! " + this.form.item + " >> " + this.item_id);
                this.$emit('assigned-module', this.form.item, (this.form.roles_list || []));
                this.resetForm();

            },
            async getOwnersList() {
                this.getCustomersSpsList(this.item_info)
                    .then(response => {
                        let status = response.data.data.status;
                        if (status) {
                            var items = response.data.data.items;
                            this.owners_list = items;
                            if (items.length > 0){
                                var first_object = items[0];
                                var owner_id = first_object.value,
                                    item_type = first_object.item_type;
                                this.$emit('changed-owner', owner_id, item_type);
                            }

                        }
                    }
                );
            },
            changedOwner(item) {

                this.$emit('changed-owner', this.customer_sp.value, this.customer_sp.item_type);
            },
            refreshModulesList(items){
                console.log("should refresh modules??");
                console.log(items);
                this.received_items_list = items;
            }

        }
    }
</script>
