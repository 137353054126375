const FirstPath = window.location.pathname.split('/')[1];
export const ModuleKeysList = {
    id: {
        key: 'id',
        label: "ID",
        class: "col-lg-2 m_uid",
        show_on_mobile: true,
        case: ''
    },
    name: {
        key: 'name',
        label: "Name",
        class: "col-lg m_name",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a href="/' + FirstPath + '/modules/'+item.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.name) + '</a>';
        }
    },
    roles_count: {
        key: 'roles_count',
        label: "Roles",
        class: "col-lg-2 m_roles_count",
        show_on_mobile: true,
        case: ''
    },
    users_count: {
        key: 'users_count',
        label: "Users",
        class: "col-lg-2 m_users_count",
        show_on_mobile: true,
        case: ''
    },
    module_template_info: {
        key: 'module_template_info',
        label: "Template",
        class: "col-lg-2 m_module_template_info",
        show_on_mobile: true,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.module_template_info){
                return '<a href="/' + FirstPath + '/modules/'+item.module_template_info.id+'/view" class="text-dark">' + Vue.options.filters.titleize(item.module_template_info.name) + '</a>';
            }
            return '-';
        }
    },
    entity_type: {
        key: 'entity_type',
        label: "Entity",
        class: "col-lg-2 m_name",
        show_on_mobile: true,
        case: 'custom',
        used_func: function (value){
            return Vue.options.filters.titleize(value);
        }
    },
};
