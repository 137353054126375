<template>
    <div class="card-body">


        <AddNewSpMovement
            ref="add-new-sp_movements"
            item_type='sp_movements'
            modal_title="Create New SP Movement"
            @on-new-item-adding="reloadPage"
            @on-modal-cancel="selected_object_id = null"
        >
        </AddNewSpMovement>

        <div class="product-filter-option-wrapper clearfix d-flex">

            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                    request_meta.from + '-' + request_meta.to
                }}</span> of <span>{{ request_meta.total }}</span></div>

            <div class="filter-wrap d-flex align-items-center ml-auto">

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Show: <b>{{
                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                        }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in perpage.pageOptions"
                        :key="option.value"
                        @click="savePerPage(option.value, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

            </div>

        </div>

        <LotsOfItemsAlert :perpage="perpage"/>

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching Movements...</h4>
        </div>
        <div v-else-if="!objectsData || total === 0 || objectsData.length === 0"
             class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Movements found here at the moment.</h4>
        </div>
        <po-lines
            v-else-if="objectsData"
            :items="objectsData"
            :current_page_type="item_info.current_page_type"
            :object_type="permission_name"
            :line_type="permission_name"
            :get-poline-classes-dict="getPoLineClassFor"
            :keys_list="object_list_key_list">
            <template v-slot:sp_movements_actions="{ item, index }">
                <div class="btn-group bl-auto" v-if="has_actions_permissions">
                    <button type="button" class="btn btn-none dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-dots-horizontal text-af-accent"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <b-dropdown-item v-if="permissions_list['edit_' + permission_name]"
                                         @click="performAction(item, ('edit' + permission_name), index, $event)">
                            Edit
                        </b-dropdown-item>
                        <b-dropdown-item v-if="permissions_list['delete_' + permission_name]"
                                         variant="danger"
                                         @click="performAction(item, ('delete' + permission_name), index)">
                            Delete
                        </b-dropdown-item>
                    </div>
                </div>
            </template>
        </po-lines>

        <!-- Product Section End-->
        <div class="pagination">
            <b-pagination
                v-if="perpage.perPage !== 0"
                v-model="perpage.currentPage"
                :total-rows="request_meta.total"
                @change="perpage.currentPage = $event; getList()"
                class="pagination d-flex align-items-center justify-content-center"
                :per-page="perpage.perPage"
            ></b-pagination>
        </div>
    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines"
import Resource from '@/api/resource';
const movementResource = new Resource('api/assetflow/sp_movements');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {GroupsSortOptions} from "@/views/assetflow/form_data/GroupsSortOptions";

import {SPMovementKeysList} from "@/views/assetflow/form_data/SPMovementKeysList";
import AddNewSpMovement from "../customers/sp_movements/AddNewSpMovement";
import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";

export default {
    name: 'ObjectMovementList',
    components: {LotsOfItemsAlert, AddNewSpMovement, PoLines},
    props: {
        object_type: '',
        item_info: {},
    },
    data(){
        return {
            selected_object_id: null,
            permission_name: 'sp_movements',
            has_actions_permissions:false,
            permissions_list:{},
            object_list_key_list:SPMovementKeysList,
            objectsData: [],
            listLoading: false,
            total: 0,
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'sp_movements'
            },
            request_meta: {},
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            sorting: {
                sortType: {
                    value: 3,
                    text: 'Created Date: New to Old',
                    name: 'created_at',
                    reversed: true
                },
                sortOptions: GroupsSortOptions,
            },
        }
    },
    created() {
        this.has_actions_permissions = this.hasPermission('edit_'+this.permission_name) || this.hasPermission('delete_'+this.permission_name);


        this.permissions_list['view_' + this.permission_name] = this.hasPermission('browse_' + this.permission_name);
        this.permissions_list['add_' + this.permission_name] = this.hasPermission('add_' + this.permission_name);
        this.permissions_list['edit_' + this.permission_name] = this.hasPermission('edit_' + this.permission_name);
        this.permissions_list['delete_' + this.permission_name] = this.hasPermission('delete_' + this.permission_name);

        if (this.permissions_list[('edit_' + this.permission_name)]){
            this.object_list_key_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 war_sp_movements_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'sp_movements_actions'
            };
        }

        this.getList();
    },
    methods: {

        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta(this.permission_name+'_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;

            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_'+this.permission_name+'s_per_page', perPage, message);
            this.refreshContent();
        },

        async getList() {
            this.listLoading = true;
            this.listQuery = this.addPaginationParams(this.listQuery);


            this.listQuery.item_id = this.item_info.id;
            this.listQuery.object_type = this.object_type;

            const {data} = await movementResource.list(this.listQuery, null, true);

            let object_id = this.item_info.id;
            this.objectsData = data.data.items.map(function(movement) {
                movement.is_source = movement.movement_source_id === object_id;
                return movement;
            });
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.request_meta = data.meta;
            }else{
                this.total = this.objectsData.length;
            }

            this.listLoading = false;
        },
        performAction(sp_movement, action, sp_movementKey){
            this.selected_object_id = sp_movement.id;
            if (action === ('edit'+this.permission_name)){
                this.$refs['add-new-'+ this.permission_name].setSelectedItem(sp_movement);
                this.$bvModal.show('create-'+ this.permission_name);
            }else if (action === ('delete'+this.permission_name)){
                if (sp_movement.id !== ''){
                    this.deleteItemByID(sp_movement.id, sp_movement.name, this.object_type, sp_movement)
                        .then((response) => {
                            if (response.data.data.status){
                                this.sp_movementsData.splice(sp_movementKey, 1);
                            }
                        });
                }
            }
        },
    }
}
</script>
