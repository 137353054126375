<template>
    <div class="cm_list_view">
        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="item_info" :show-captiom-img="showCaptiomImg" :required_image_size="'acquisition_list'"/>
            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p">
                                    <div>
                                        <a v-b-tooltip.bottom :title="'UID: ' + item_info.uid" :href="'/' + item_type + '/dispositions/' + item_info.id + '/view'">
                                            <span class="button_size status-btn-disposed disposition-title"><i class="bx bx-user-circle"></i> {{ item_info.name | titleize }}</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-xl-6">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title">
                                <div class="d-flex-p"><div><span :class="'button_size status-btn-' + (item_info.status === 1 ? 'active' : 'acquired') + ' disposition-' + (item_info.status === 1 ? 'completed' : 'pending')">{{item_info.status === 1 ? 'Completed' : 'Pending'}}</span></div></div>
                            </div>

                            <div class="cm_status_ul disposition_details_qty">
                                <ul>
                                    <li>
                                        <p>Assets <span class="total_box_bg">{{ item_info.hasOwnProperty('assets_info') && item_info.assets_info ? item_info.assets_info.length : '-' }}</span></p>
                                    </li>
                                    <li>
                                        <p>Qty <span class="total_box_bg">{{ item_info.quantity }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Recipient</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <a v-if="item_info.hasOwnProperty('recipient_type') && item_info.recipient_type !== ''" :href="'/' + item_type + '/' + item_info.recipient_type + '/' + item_info.recipient_id + '/view'">{{ item_info.recipient_name }}</a>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Date</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.hasOwnProperty('created_at')">{{ item_info.created_at | moment("MMMM Do YYYY") }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Ref</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info.hasOwnProperty('reference') && item_info.reference !== ''">{{item_info.reference}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Source</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info && item_info.hasOwnProperty('source')">{{sources_list[item_info.source]}}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Source ID</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info && item_info.hasOwnProperty('source_info') && item_info.source_info">
                                    <a :href="'/' + item_type + '/'+item_info.source_info.item_type+'/'+item_info.source_info.id+'/view'">{{item_info.source_info.name}}</a>
                                </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="cm_usage border_r">
                                        <span>Created By</span>
                                        <p>
                                            <i class="bx bx-detail"></i>
                                            <span v-if="item_info && item_info.hasOwnProperty('user_info')">
                                    <a :href="'/' + item_type + '/users/'+item_info.user_info.id+'/view'">{{item_info.user_info.name}}</a>
                                </span>
                                            <span v-else>-</span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="common_col_desc" v-if="item_info.details && item_info.details !== ''">
                            <p>{{ item_info.details }}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-6">
                        <div class="common_col_bg_box">
                            <DispositionCosts :item_info="item_info"/>
                        </div>
                        <div class="cm_list_last_update mt-1" v-if="item_info.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText()"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">

            <div v-if="hasPermission('edit_dispositions') || hasPermission('delete_dispositions')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <DispositionActionsList :item="item_info" :index="item_key" :item_type="item_type" object_type="dispositions" :perform-action="performAction"/>
            </div>

        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/managed_storages/' + item_info.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import DispositionActionsList from "./DispositionActionsList";
import MyBCarouselSlide from "../../items/widgets/detailsViews/AssetTabs/AssetPassportBlocks/MyBCarouselSlide";
import DispositionCosts from "./DispositionCosts";
import MyBCarouselImages from "../../assets/subItems/AssetLayouts/MyBCarouselImages";

export default {
    name: 'DispositionListView',
    components: {MyBCarouselImages, DispositionCosts, MyBCarouselSlide, DispositionActionsList},
    props: [
        'item_type',
        'item_key',
        'item_info',
        'showCaptiomImg',
        'performAction',
    ],
    data(){
        return {
            disposition_type_info: null,
            sources_list:[
                'Asset',
                'Actions',
                'Orders',
                'Storage Orders',
            ]
        }
    },
    methods:{
        getPostText(){
            if (this.item_info.hasOwnProperty('latest_post_update')){
                let user = this.item_info.latest_post_update.user;
                let image_tag = '<img src="' + user.avatar_url +'" alt="' + user.name + '" class="rounded-circle item-post-image" width="20" height="20">';
                return image_tag + " <a class=\"text-dark\" href=\"/" + this.item_type + "/users/" + user.id + "/view\">" + user.name + "</a> " + this.getItemPostTitle(this.asset, this.item_info.latest_post_update) + " on " + Vue.options.filters.moment(this.item_info.latest_post_update.created_at , 'DD/MM/YYYY');
            }
            return '';
        }
    },
    created() {
        if (this.item_info){
            this.disposition_type_info = this.item_info.hasOwnProperty('disposition_type_info') && this.item_info.disposition_type_info ? this.item_info.disposition_type_info : null;
        }
    }
}
</script>
