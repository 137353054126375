import { render, staticRenderFns } from "./ChangeRightBox.vue?vue&type=template&id=788c34b5&"
import script from "./ChangeRightBox.vue?vue&type=script&lang=js&"
export * from "./ChangeRightBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports