<template>
    <div class="cm_common_table_box" v-if="current_entity_info">
        <div class="cm_common_table_heading">
            <h4><i class="fa fa-star"></i> {{ (!is_assetflow ? 'Current ' : '') + selected_entity.label }}</h4>
        </div>
        <div class="cm_common_confirm_content" v-if="current_entity_info.logo_url && current_entity_info.logo_url !== ''">
            <div class="cm_confirm_order_img">
                <img :src="current_entity_info.logo_url + '/inside/300/100'" :alt="current_entity_info.name" :class="'entity_logo ' + selected_entity.item_type+'-dashboard-logo'">
            </div>
        </div>
        <div class="cm_common_list_detail">
            <span v-if="!is_assetflow">{{ selected_entity.label }} Name</span>
            <span v-else>{{ current_entity_info.name }}</span>
            <p v-if="!is_assetflow"><a :href="'/' + selected_entity.item_type + '/overview'">{{current_entity_info.name}}</a></p>
        </div>
    </div>
    <div class="cm_common_table_box" v-else>
        <div class="cm_common_table_heading">
            <h4><i class="fa fa-star"></i> {{ selected_entity.label }}</h4>
        </div>
        <div class="cm_common_confirm_detail">
            <a :href="'/' + selected_entity.item_type + '/switch'">Switch {{ selected_entity.label }}</a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EntityInfo',
    data(){
        return {
        }
    },
    props: {
        is_assetflow: {},
        selected_entity: {},
        current_entity_info: {}
    },
    mounted() {

    }
}
</script>
