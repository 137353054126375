<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{ selected_setting ? selected_setting.name : '' }}</h4>
                    Coming soon.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "coming_soon",
    props:['selected_setting'],
}
</script>


