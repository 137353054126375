<template>
    <div class="cm_ordering_table_view cm_order_overview table-responsive">
        <table>
            <thead>
                <tr v-if="block_info.required_items.length > 0">
                    <th v-for="(table_header, hkey) in block_info.table_headers" :key="hkey">{{ table_header }}</th>
                </tr>
                <tr v-else></tr>
            </thead>
            <tbody v-if="block_info.required_items.length > 0">
                <tr v-for="(required_item, bkey) in block_info.required_items" :key="bkey">
                    <td v-for="(table_key, tkey) in block_info.object_keys">
                        <p v-if="required_item.hasOwnProperty(table_key) && required_item[table_key]" v-html="required_item[table_key]"></p>
                        <p v-else>-</p>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr class="table-empty-content">
                    <td><p>No {{ block_info.name }} found</p></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    name: 'DashboardBlockTableCustom',
    props:['block_info', 'current_page_type']
}
</script>
