<template>
    <!-- Start here -->
    <div :id="with_sidebar ? 'main-wrapper' : 'acquisition-main-wrapper'" class="">
        <!-- Sidebar start-->

        <div id="side_bar_main_section" class="side_bar_block" v-if="with_sidebar">
            <div class="step-progress-bar-block">
                <!-- progressbar -->
                <ul id="progressbar">
                    <li v-for="(step, index) in steps_list"
                        @click="selected_step_index = index"
                        :class="getStepClass(step)">
                        <span>{{ step.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Sidebar end-->

        <!-- Page-Content start-->
        <div id="disboard-content-wrapper">
            <div v-if="with_sidebar" class="sidebad-toggle af-accent asset_form"></div>
            <div class="common-inner-section">
                <div class="common-title-block">
                    <h2>{{ title }} Acquisition</h2>
                </div>
                <div class="main-step-wrapper">
                    <!-- Multi step form -->

                    <fieldset class="common-step-block step-1">
                        <div class="use-section-df">
                            <div class="common-title-block">
                                <h4>{{ steps_list[selected_step_index].name }}</h4>
                                <p>Fill all information below</p>
                            </div>

                        </div>

                        <transition name="fade" mode="out-in">
                            <AcquisitionStepPanel
                                ref="step-panel-ref"
                                :current-step="steps_list[selected_step_index]"
                                :use-tooltips="false"
                                :add-form="addForm"
                                :object_type="object_type"
                                :required_fields="required_fields[object_type]"
                                :listQuery="listQuery"
                                :my_lists_values="lists_values"
                                :item_id="item_id"
                                :item_type="item_type"
                            />
                        </transition>


                        <div class="common-step-button-block">

                            <div class="save-cancel-btn" v-if="source_type !== 'modal'">
                                <div class="btn-group dropup">
                                    <a aria-expanded="false" aria-haspopup="true" class="st-common-btn dropdown-toggle"
                                       data-toggle="dropdown" href="#0">
                                        <span class="save-view-btn-text">Save & View</span>
                                        <i class="bx bx-chevron-down font-size-12"></i>
                                    </a>
                                    <div class="dropdown-menu">


                                        <AFButton tag_name="a"
                                                  :key_prefix="'save-view' + '-' + buttonkey"
                                                  :is_enabled="is_saving_enabled"
                                                  :required_action="addNew"
                                                  :first_argument="false"
                                                  item_classes="btn dropdown-item"
                                                  required_text="Save & View"/>

                                        <AFButton tag_name="a"
                                                  :key_prefix="'save-add' + '-' + buttonkey"
                                                  :is_enabled="is_saving_enabled"
                                                  :required_action="addNew"
                                                  :first_argument="true"
                                                  item_classes="btn dropdown-item"
                                                  required_text="Save & Add Another"/>

                                        <a :href="item_id !== '' ? './view' : './'" class="btn dropdown-item">Cancel</a>

                                    </div>
                                </div>
                                <a class="st-cancel-btn" :href="item_id !== '' ? './view' : './'">Cancel</a>
                            </div>
                            <div class="save-cancel-btn" v-else>

                            </div>

                            <div class="prev-next--btn">


                                <button v-if="selected_step_index !== 0"
                                        class="action-button previous_button st-common-btn"
                                        type="button"
                                        @click="moveSteps('prev')">
                                    <i class="bx bx-left-arrow-alt font-size-12"></i>
                                    <span class="prev-btn-text">Previous</span>
                                </button>
                                <button v-if="selected_step_index !== steps_list.length - 1"
                                        class="action-button next_button st-common-btn"
                                        type="button"
                                        @click="moveSteps('next')">
                                    <span class="next-btn-text">Next</span>
                                    <i class="bx bx-right-arrow-alt font-size-12"></i>
                                </button>

                                <AFButton
                                    tag_name="button"
                                    :key_prefix="'save-only' + '-' + buttonkey"
                                    :is_enabled="is_saving_enabled"
                                    :required_action="addNew"
                                    :first_argument="'edit_page'"
                                    required_text="Save"
                                    item_classes="action-button next_button st-common-btn"
                                    span_classes="save-btn-text"
                                />


                                <button
                                    v-if="source_type === 'modal'"
                                    class="action-button next_button st-cancel-btn"
                                    type="button"
                                    @click.once="$emit('on-cancel-item')">
                                    <span>Cancel</span>
                                </button>

                            </div>
                        </div>

                    </fieldset>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import dropzoneMixin from "@/mixins/assetDropzoneMixin.mixin";
import AcquisitionStepPanel from "./AcquisitionStepPanel";
import AFButton from "@/components/AFButton";
export default {
    name: "AddNewForm",
    props: ['item_id', 'item_type', 'source_type', 'action', 'with_sidebar', 'object_type', 'object_type_link', 'object_type_shortcut', 'received_lists_values'],
    mixins: [dropzoneMixin],
    components: {AFButton, AcquisitionStepPanel},
    data() {
        return {
            title: 'Add New',
            lists_values: {},
            listQuery: {
                page: 1,
                limit: 100
            },
            required_fields:{
                acquisition:{
                    /* OverviewStep */
                    acq_mode: {
                        name: 'acquisition mode',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'Master' },
                            { id: 0, name: 'Slave' }
                        ]
                    },
                    external_ledger_reference: {
                        name: 'External Ledger Reference',
                        type: 'text',
                        has_permission: true
                    },
                    notes: {
                        name: 'Notes',
                        type: 'text',
                        has_permission: true,
                        default_value: ''
                    },
                    /* TypeStep */




                    /* DatesStep */

                    /* DetailsStep */
                    acquisition_type: {
                        name: 'acquisition type',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },
                    ownership_type: {
                        name: 'ownership',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },
                    acquired_date: {
                        name: 'acquired date',
                        type: 'date',
                        has_permission: true
                    },
                    end_date: {
                        name: 'scheduled return date',
                        type: 'date',
                        has_permission: true
                    },
                    nominal_code_id: {
                        name: 'ledger account',
                        name_plural:'ledger accounts',
                        link_name:'nominal_codes',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },

                    supplier_id: {
                        name: 'supplier',
                        name_plural:'suppliers',
                        link_name:'suppliers',
                        type: 'radio-select',
                        has_permission: true,
                        options:[],
                    },
                    purchase_order_id: {
                        name: 'Purchase Order',
                        name_plural:'Purchase Orders',
                        link_name:'purchaseOrders',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },
                    supplier_reference: {
                        name: 'Supplier Reference',
                        type: 'text',
                        has_permission: true
                    },
                    currency: {
                        name: 'Currency',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },

                    /* AssetsStep */
                    asset_name: {
                        name: 'Name',
                        type: 'text',
                        has_permission: true
                    },
                    asset_initial_quantity: {
                        name: 'Initial Qty',
                        type: 'number',
                        has_permission: true
                    },
                    asset_notes: {
                        name: 'Notes',
                        type: 'textarea',
                        has_permission: true
                    },
                    asset_state: {
                        name: 'Asset State',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'New' },
                            { id: 0, name: 'Used' }
                        ]
                    },

                    /* PaymentStep */
                    payment_date: {
                        name: 'date',
                        type: 'date',
                        has_permission: true
                    },
                    payment_type: {
                        name: 'Payment Type',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 0, name: 'Account' },
                            { id: 1, name: 'Cash' },
                            { id: 2, name: 'Credit Card' },
                            { id: 3, name: 'Expenses' }
                        ]
                    },
                    payment_purchase_order_id: {
                        name: 'Purchase Order',
                        name_plural:'Purchase Orders',
                        link_name:'purchaseOrders',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },
                    payment_nominal_code_id: {
                        name: 'Payment Account',
                        name_plural:'ledger accounts',
                        link_name:'nominal_codes',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },
                    payment_amount: {
                        name: 'Amount',
                        type: 'number',
                        has_permission: true
                    },
                    payment_reference: {
                        name: 'Reference',
                        type: 'text',
                        has_permission: true
                    },
                    payment_reconciliation: {
                        name: 'Reconcile Payment with External Ledger?',
                        type: 'checkbox',
                        has_permission: true
                    },

                    /* Create Assets */
                    /* TODO: // Create Assets HERE */


                    create_assets_automatically: {
                        name: 'Automatically Create Assets?',
                        type: 'checkbox',
                        has_permission: true
                    },
                    create_assets_number_of_assets: {
                        name: 'Quantity',
                        type: 'number',
                        has_permission: true
                    },
                    create_assets_asset_quantities: {
                        name: 'Asset Quantities',
                        type: 'single-select',
                        has_permission: true,
                        options:[
                            { id: 0, name: '1 Asset With Multiple Qty' },
                            { id: 1, name: 'Multiple Assets with Qty of 1' },
                        ]
                    },
                    create_assets_asset_distribution: {
                        name: 'Distribute Costs to Assets',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 'null', name: 'None' },
                            { id: 'auto', name: 'Auto' },
                            { id: 'manual', name: 'Manual' },
                            { id: 'proportional', name: 'Proportional' },
                        ]
                    },
                    create_assets_update_settings: {
                        name: 'Update Asset Settings?',
                        type: 'checkbox',
                        has_permission: true
                    },
                    create_assets_usage: {
                        name: 'Usage',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 'acquired', name: 'Acquired (Not Used)' },
                            { id: 'active', name: 'Active (In Use)' }
                        ]
                    },
                    create_assets_asset_usage: {
                        name: 'Asset usage',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'On Camera' },
                            { id: 0, name: 'Off Camera' },
                            { id: 2, name: 'Unknown' }
                        ]
                    },
                    create_assets_is_hero: {
                        name: 'Hero Item',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' },
                            { id: 2, name: 'Unknown' }
                        ]
                    },
                    create_assets_is_asset: {
                        name: 'Is Asset',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' },
                            { id: 2, name: 'Unknown' }
                        ]
                    },
                    create_assets_folder_id: {
                        name: 'folder',
                        name_plural:'folders',
                        link_name:'folders',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_category_id: {
                        name: 'category',
                        name_plural:'categories',
                        link_name:'assets',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_tag_ids: {
                        name: 'tags',
                        name_plural:'tags',
                        link_name:'tags',
                        type: 'multi-select',
                        has_permission: true,
                        options:[],
                    },

                    create_assets_department_id: {
                        name: 'department',
                        name_plural:'departments',
                        link_name:'departments',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_contact_id: {
                        name: 'User',
                        name_plural:'Users',
                        link_name:'Users',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_address_id: {
                        name: 'Address',
                        name_plural:'Addresses',
                        link_name:'Addresses',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_location_id: {
                        name: 'Location',
                        name_plural:'Locations',
                        link_name:'Locations',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_final_disposition_planning: {
                        name: 'Final Disposition Planning',
                        type: 'single-select',
                        has_permission: true,
                        options:[],
                    },
                    create_assets_confirm_fdp: {
                        name: 'Confirm FDP Plan',
                        type: 'single-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' }
                        ]
                    },


                    /* Tracking */
                    tracking_reference: {
                        name: 'Reference',
                        type: 'text',
                        has_permission: true
                    },
                    tracking_reconciliation: {
                        name: 'Reconcile with External Ledger Now?',
                        type: 'checkbox',
                        has_permission: true
                    },
                    tracking_reconciliation_at: {
                        name: 'Reconciliation Date',
                        type: 'date',
                        has_permission: true
                    },
                    tracking_reconciliation_by: {
                        name: 'Reconciliation User',
                        name_plural:'users',
                        link_name:'users',
                        type: 'radio-select',
                        has_permission: true,
                        options:[]
                    },

                    /* Confirmation */
                    confirmation_status: {
                        name: 'Close Acquisition?',
                        type: 'radio-select',
                        has_permission: true,
                        options:[
                            { id: 1, name: 'Yes' },
                            { id: 0, name: 'No' }
                        ]
                    },
                    acquisition_summary: {
                        name: 'Summary',
                        type: 'summary',
                        has_permission: true
                    },

                }
            },
            addForm: {
                asset_images: [],
                filled_asset_images: [],
                item_details: {},
                added_acquisition_types: [],
                single_select_v_models: {},
                multi_select_v_models: {},
            },
            selected_step_index: 0,
            steps_list: [
                {
                    id: 'overview_step', // >>>
                    name: 'Overview',
                    class: 'form-steps overview-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'details_step',
                    name: 'Details',
                    class: 'form-steps details-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'supplier_step',
                    name: 'Supplier',
                    class: 'form-steps supplier-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'payment_step',
                    name: 'Payment',
                    class: 'form-steps payment-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'assets_step',
                    name: 'Assets',
                    class: 'form-steps assets-step',
                    isActive: false,
                    current_status: 'empty'
                },
                {
                    id: 'confirmation_step',
                    name: 'Confirmation',
                    class: 'form-steps confirmation-step',
                    isActive: false,
                    current_status: 'empty'
                }

            ],
            isSendingRequest: false
        }
    },
    created() {
        this.setSelectedStepIndex();

        this.lists_values = Object.assign({}, this.received_lists_values);
        if (this.source_type === 'modal'){
            this.getFieldsList();
        }else{
            for (let key in this.lists_values) {
                this.getOptionsForKey(key, 'acquisition');
            }
        }


        if((this.action === 'edit') && this.item_id !== ''){
            this.fetchInfoOf();
            this.title = 'Edit';
        }


    },
    watch: {
        selected_step_index: {
            deep: true,
            handler: function (newValue) {
                this.setSelectedStepIndex();
            }
        },
    },
    methods:{
        async getFieldsList(){
            this.lists_values = [];
            this.isLoadingItem = true;
            axios({
                method:'post',
                url:'/api/assetflow/acquisitions/getFormList',
                baseURL: '/',
                data: {
                    source_type: 'acquisition_modal',
                    object_type: 'acquisitions',
                }
            }).then(response => {

                this.isLoadingItem = false;

                let form_lists = response.data.data;
                this.lists_values = {
                    acquisition_type: form_lists.acquisition_types_list,
                    ownership_type: form_lists.ownership_type_list,

                    nominal_code_id: form_lists.nominal_codes_list,
                    payment_nominal_code_id: form_lists.nominal_codes_list,

                    supplier_id: form_lists.suppliers_list,

                    purchase_order_id: form_lists.purchase_orders_list,
                    payment_purchase_order_id: form_lists.purchase_orders_list,

                    tracking_reconciliation_by: form_lists.contacts_list,
                    create_assets_folder_id: form_lists.folders_list,
                    category_id: form_lists.categories_list,
                    acquisition_category_id: form_lists.acquisition_categories_list,
                    create_assets_category_id: form_lists.categories_list,
                    create_assets_tag_ids: form_lists.tags_list,
                    create_assets_department_id: form_lists.departments_list,
                    create_assets_contact_id: form_lists.contacts_list,
                    create_assets_address_id: form_lists.addresses_list,
                    create_assets_location_id: form_lists.locations_list,
                    create_assets_final_disposition_planning: form_lists.final_disposition_planning,
                    currency: form_lists.currencies_list,
                }

                for (let key in this.lists_values) {
                    this.getOptionsForKey(key, 'acquisition');
                }

            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        fetchInfoOf() {
            this.isLoadingItem = true;
            axios({
                method:'get',
                url:'/api/assetflow/acquisitions/' + this.item_id,
                baseURL: '/'
            }).then(response => {
                let item_info = response.data;
                let item_details = {
                    name: item_info.name,
                    asset_initial_quantity: item_info.initial_quantity,
                    acquired_date: item_info.acquired_date,
                    end_date: item_info.end_date,
                    supplier_reference: item_info.supplier_ref,
                    notes: item_info.notes,
                    tracking_reference: item_info.reference,
                    tracking_reconciliation: item_info.reconciled,
                    tracking_reconciliation_at: item_info.reconciliation_date
                };

                this.keys_for_acquisition = {
                    acq_mode: item_info.mode,
                    create_assets_asset_distribution: item_info.cost_distribution,
                    create_assets_asset_quantities: item_info.creating_assets_mode,
                    supplier_id: item_info.supplier_id,
                    ownership_type: item_info.ownership_type,
                    acquisition_type: item_info.acquisition_type_id,
                    asset_state: item_info.asset_state,
                    currency: item_info.currency,
                    nominal_code_id: item_info.nominal_code_id,
                    tracking_reconciliation_by: item_info.reconciliation_by,
                };
                for (const acquisitionKey in this.keys_for_acquisition) {
                    let value_for_acquisition = this.keys_for_acquisition[acquisitionKey];
                    item_details[acquisitionKey] = this.getValueOf(acquisitionKey, value_for_acquisition);
                }

                this.addForm.item_details = item_details;
                this.isLoadingItem = false;
            }).catch(error => {
                console.log(error);
                this.isLoadingItem = false;
            });
        },
        setSelectedStepIndex: function () {
            this.steps_list = this.steps_list.map(e => ({...e, isActive: false}));
            this.steps_list[this.selected_step_index].isActive = true;
        },
        moveSteps(type) {
            if (type === 'next') {
                this.selected_step_index += 1;
            } else if (type === 'prev') {
                this.selected_step_index -= 1;
            }
        },
        getStepClass(step) {
            var classList = [];
            if (step.isActive) {
                classList.push('blank_round active');
            }

            var result = false;
            let required_fields = [];
            let myForm = this.addForm;

            switch (step.id){
                case 'mode_step':
                    required_fields = ['acq_mode'];
                    break;
                case 'type_step':
                    required_fields = ['acquisition_type', 'ownership_type', 'nominal_code_id'];
                    break;
                case 'dates_step':
                    required_fields = ['acquired_date'];
                    break;
                case 'details_step':
                    required_fields = ['supplier_id', 'currency'];
                    break;
                case 'assets_step':
                    required_fields = ['asset_name'];
                    break;
                case 'payment_step':
                    required_fields = [ 'payment_date', 'payment_type', 'payment_nominal_code_id', 'payment_amount'];
                    break;
                case 'create_assets_step':
                    required_fields = ['create_assets_automatically'];
                    break;
                case 'tracking_reconciliation_step':
                    required_fields = ['tracking_reconciliation_at', 'tracking_reconciliation_by'];
                    break;
                case 'confirmation_step':
                    required_fields = ['confirmation_status'];
                    break;

            }
            if (required_fields.length > 0){
                result = required_fields.every((k) => myForm.item_details[k]);
                if (result){
                    classList.push('completed');
                }
            }

            return classList;
        },
        addNew(add_another, event) {
            let allfiles = this.checkAndGetFiles(this.addForm.filled_asset_images);
            if (event && !this.isSendingRequest && allfiles) {
                this.isSendingRequest = true;

                let item_details_values = Object.assign({}, this.addForm.item_details);
                let single_select_keys = [
                    'acq_mode',
                    'acquisition_type',
                    'ownership_type',
                    'nominal_code_id',
                    'supplier_id',
                    'purchase_order_id',
                    'currency',
                    'asset_state',
                    'payment_type',
                    'payment_purchase_order_id',
                    'payment_nominal_code_id',
                    'create_assets_asset_quantities',
                    'create_assets_asset_distribution',
                    'create_assets_usage',
                    'create_assets_asset_usage',
                    'create_assets_is_hero',
                    'create_assets_is_asset',
                    'create_assets_folder_id',
                    'create_assets_category_id',
                    'create_assets_tag_ids',
                    'create_assets_department_id',
                    'create_assets_contact_id',
                    'create_assets_address_id',
                    'create_assets_location_id',
                    'create_assets_final_disposition_planning',
                    'create_assets_confirm_fdp',
                    'tracking_reconciliation_by',
                    'confirmation_status'
                ];


                for (const selectKeysKey in single_select_keys) {
                    let select_key = single_select_keys[selectKeysKey];
                    if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){
                        item_details_values[select_key] = item_details_values[select_key].id;
                    }
                }

                let multi_select_keys = ['create_assets_tag_ids'];

                for (const selectKeysKey in multi_select_keys) {
                    let select_key = multi_select_keys[selectKeysKey];
                    if (item_details_values.hasOwnProperty(select_key) && item_details_values[select_key]){
                        item_details_values[select_key] = this.getIDs(item_details_values[select_key], true);
                    }
                }

                let params = {
                    item_id: this.item_id,
                    item_details: item_details_values,
                    object_type: this.object_type,
                    asset_images: allfiles
                };

                axios({
                    method: ((this.action === 'edit' && this.item_id !== '') ? 'put' : 'post'),
                    url: '/api/assetflow/' + this.object_type_link + (this.item_id !== '' ? ('/' + this.item_id) : ''),
                    baseURL: '/',
                    data: params,
                }).then(response => {
                    this.isSendingRequest = false;
                    if (response.data.data.status === true) {
                        let added_item = response.data.data.items;
                        let message;


                        if (added_item && added_item.hasOwnProperty('id')){
                            let asset_name = ', ' + this.object_type_shortcut + '-' + added_item.uid + ' {' + added_item.name + '}';
                            message = 'Added Successfully' + asset_name;
                            if (this.action === 'edit' && this.item_id !== '') {
                                message = 'Edited Successfully' + asset_name;
                            }

                            if (this.source_type === 'modal'){
                                this.$emit('on-save-item', added_item);
                            }else{

                                // after success
                                if (add_another === 'edit_page') {
                                    if (added_item) {
                                        window.flash(message, 'success', event);
                                        let asset_id = added_item.id;
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link + '/' + asset_id + '/edit';
                                    } else {
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link;
                                    }
                                }else if (add_another) {
                                    if (this.action === 'edit' && this.item_id !== '') {
                                        window.flash(message);
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link + '/new';
                                    } else {
                                        window.flash(message);
                                        location.reload();
                                    }
                                } else {
                                    if (added_item && added_item.hasOwnProperty('id')) {
                                        window.flash(message);
                                        let asset_id = added_item.id;
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link + '/' + asset_id + '/view';
                                    } else {
                                        window.location.href = '/' + this.item_type + '/' + this.object_type_link;
                                    }
                                }
                            }


                        }else{
                            console.log(added_item);
                        }

                    } else {
                        console.log('Something went wrong');
                    }
                }).catch(error => {
                    console.log(error);
                    this.isSendingRequest = false;
                });
            }

        }
    }
}
</script>


