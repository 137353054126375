<template>
    <div>
        <DropdownList :mobile_only="this.is_mobile" @on-item-selected="selectedItem($event)" />

        <!-- The selected country name will be visible below, when selected -->
        <!-- {{ dropdownSelection.name }} -->

    </div>
</template>

<script>
    import DropdownList from './SearchDropdown';

    export default {
        name: "InputWithDropdown",
        data () {
            return {
                dropdownSelection: {},
                is_mobile: false
            }
        },
        created() {
            this.is_mobile = this.mobile_only ?? false;
        },
        props:{
            mobile_only: {
                type: Boolean,
                default: false
            }
        },
        components: {
            DropdownList
        },
        methods: {
            selectedItem($event) {
                this.dropdownSelection = $event;
                window.location = this.dropdownSelection.link;
            },
        }
    }
</script>

