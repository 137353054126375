<template>
    <div>
        <h4>To continue, Please select a module.</h4>
        <a href="./">Go To Home Page</a>
    </div>
</template>

<script>
import PageHeader from "@/components/page-header";

export default {
    name: "SelectAModule.vue",
    components: {
        PageHeader,
    },
    data() {
        return {
            title: "User Preferences",
            items: [
                {
                    text: "Select A Module",
                    active: true
                }
            ],
        }
    }
}
</script>


