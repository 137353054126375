<template>
    <b-modal :id="modal_id"
             size="xl"
             :ref="'create-'+modal_id"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <template #modal-title>
            <div>Change Asset Storage</div>
        </template>

        <ChangeStorageUtilisation
            ref="change-sp_storages-utilisation"
            modal_id="change-sp_storages-utilisation"
            item_type='sp_storages'
            modal_title="Change Storage Utilisation"
            @on-utilisation-change="refreshItem"
        >
        </ChangeStorageUtilisation>

        <ParkStorageItem
            ref="assign-sp_storages-location"
            modal_id="assign-sp_storages-location"
            item_type='sp_storages'
            modal_title="Park Storage"
            @on-park-storage="refreshItem"
        >
        </ParkStorageItem>

        <RemoveStorageLocation
            ref="remove-sp_storages-location"
            modal_id="remove-sp_storages-location"
            item_type='sp_storages'
            modal_title="Remove Storage"
            @on-remove-storage="refreshItem"
        >
        </RemoveStorageLocation>

        <AssignAndGetStorage
            ref="assign-and-get-sp_storages"
            modal_id="assign-and-get-sp_storages"
            item_type='sp_storages'
            modal_title="Assign Storage"
            @on-assign-to-customer="refreshItem"
        >
        </AssignAndGetStorage>


        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFieldsAdvanced :add-form="addForm" :required_fields="required_section.required_items">
                            <template v-slot:recent_storage="{ index, required_field }">
                                <div class="recent-storage-item"
                                     v-if="required_field.options.length"
                                     v-for="(storage, idx) in required_field.options"
                                     :key="idx">
                                    <a href="#" @click="setSelectedStorage(storage, $event)" class="text-dark">
                                        <i v-if="storage && storage.hasOwnProperty('storage_wrapper_info') && storage.storage_wrapper_info" :class="storage.storage_wrapper_info.icon"></i> {{ storage.name }}
                                    </a>
                                </div>
                            </template>

                            <template v-slot:selected_storage="{ index, required_field }">
                                <po-lines
                                    v-if="selected_storage_info"
                                    custom_header="Selected Storage:"
                                    custom_header_classes="col-12"
                                    :items="[selected_storage_info]"
                                    :current_page_type="item_type"
                                    object_type="sp_storages"
                                    :keys_list="sp_storage_keys_list" />
                                <div v-else-if="isLoadingItem">
                                    Loading Storage Info...
                                </div>
                            </template>

                            <template v-slot:assign_storage="{ index, required_field }">
                                <div v-if="addForm.customer_id">
                                    <b-button @click="assignStorage($event)" class="af-btn-accent af-accent mt-2">Assign Storage to '{{ addForm.customer_id.name }}'</b-button>
                                </div>
                            </template>
                        </ChangeCommonFieldsAdvanced>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? ('Update {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import ChangeCommonFieldsAdvanced from "../extras/ChangeCommonFieldsAdvanced";
import PoLines from "../../items/widgets/detailsViews/PoLines";
import {SPStorageShortKeysList} from "@/views/assetflow/form_data/SPStorageShortKeysList";
import AssignAndGetStorage from "../sp_storages/AssignAndGetStorage";
import RemoveStorageLocation from "../sp_storages/RemoveStorageLocation";
import ParkStorageItem from "../sp_storages/ParkStorageItem";
import ChangeStorageUtilisation from "../sp_storages/ChangeStorageUtilisation";

const updateStorageResource = new Resource('api/assetflow/assets/updateStorage');
import SelectedSpStorageShared from "@/mixins/SelectedSpStorageShared.mixin";

export default {
    name: "ChangeAssetStorage",
    props: ['modal_id', 'modal_title', 'item_type', 'with_movement_info', 'is_order_line'],
    mixins: [SelectedSpStorageShared],
    components: {
        ChangeStorageUtilisation,
        ParkStorageItem, RemoveStorageLocation, AssignAndGetStorage, PoLines, ChangeCommonFieldsAdvanced},
    data(){
        return {
            sp_storage_keys_list: SPStorageShortKeysList,
            selected_storage_info:null,
            isLoadingItem:false,
            addForm:{
                storage_id: null,
            },
            required_sections:{
                movement: {
                    section_name: 'Storage',
                    required_items:{
                        storage_id: {
                            key: 'storage_id',
                            label: 'Storage',
                            type: 'single-select',
                            options:[]
                        },
                        recent_storage: {
                            key: 'recent_storage',
                            label: 'Loading Recent Storage...',
                            listLoading: true,
                            type: 'custom_slot',
                            slot_class: 'recent-storage-block',
                            slot_name: 'recent_storage',
                            options:[]
                        },
                        selected_storage: {
                            key: 'selected_storage',
                            label: '',
                            type: 'custom_slot',
                            slot_class: 'selected-storage-block',
                            slot_name: 'selected_storage'
                        },
                        assign_storage: {
                            key: 'assign_storage',
                            label: '',
                            type: 'custom_slot',
                            slot_class: 'select-storage-block',
                            slot_name: 'assign_storage'
                        },
                    },
                },
            },
            selected_item: null,
            index: null,
            lists_values: {
            }
        }
    },
    mounted(){
        /*Function Calls*/
        this.setupJQueryMethods();
    },
    created() {

    },
    methods:{
        refreshItem(modal_id, edited_storage){
            if (modal_id !== ''){
                this.$bvModal.hide(modal_id);
            }
            if (!edited_storage){ return; }

            if (['assign-and-get-sp_storages', 'assign-sp_storages-location','remove-sp_storages-location'].includes(modal_id)){
                if (modal_id === 'assign-and-get-sp_storages'){
                    this.required_sections.movement.required_items.storage_id.options.push(edited_storage);
                    this.required_sections.movement.required_items.recent_storage.options.splice(0,0, edited_storage);
                }
                this.setSelectedStorage(edited_storage);
            }else if (modal_id === 'change-sp_storages-utilisation'){
                if (this.selected_storage_info && this.selected_storage_info.id !== null){
                    this.selected_storage_info.storage_utilisation = edited_storage.storage_utilisation;
                }
            }
        },
        assignStorage(event){
            event.preventDefault();
            let selected_item = {
                customer_id:this.selected_item.customer_info.id
            }
            let modal_ref = 'assign-and-get-sp_storages';
            this.$refs[modal_ref].setCustomersList([this.selected_item.customer_info]);
            this.showModal(selected_item, modal_ref);
        },

        setupJQueryMethods(){
            let strongThis = this;
            let action_names = [
                'change-sp_storages-utilisation',
                'assign-sp_storages-location',
                'remove-sp_storages-location'
            ];
            for (const actionNamesKey in action_names) {
                let action_name = action_names[actionNamesKey];
                $(document).on('click', '.'+action_name, function(event) {
                    strongThis.getItemAndShowModal(action_name, event);
                });
            }
        },
        showModal(selected_item, modal_ref){
            if (this.$refs[modal_ref]){
                this.$refs[modal_ref].setSelectedItem(selected_item);
                this.$bvModal.show(modal_ref);
            }
        },
        setSelectedStorage(storage, event){
            if (event){
                event.preventDefault();
            }
            this.setFormValue('storage_id', storage);
        },
        setFormValue(property_name, property_value){
            this.$set(this.addForm, property_name, property_value);
        },
        fillSelectedItem() {
            if(this.selected_item.hasOwnProperty('customer_info') && this.selected_item.customer_info){
                this.getSPCustomerStorage(this.selected_item.customer_info.id);
                this.setFormValue('customer_id', this.selected_item.customer_info);
            }
        },
        async getSPCustomerStorage(customer_id){
            this.required_sections.movement.required_items.storage_id.listLoading = true;

            this.required_sections.movement.required_items.recent_storage.label = 'Loading Recent Storage...';
            this.required_sections.movement.required_items.recent_storage.options = [];

            const getSPCustomerStorageResource = new Resource('api/assetflow/customers/get_list');

            let queryList = {
                list_name: 'SPStorage',
                customer_id: customer_id,
                with_recent: true
            };
            const { data } = await getSPCustomerStorageResource.store(queryList);

            let storage_response = data.items;

            if (storage_response){
                let recent_storage_ids = storage_response.recent_storage_ids;
                let storage_list = storage_response.storage_list;
                this.required_sections.movement.required_items.storage_id.options = storage_list;
                let recent_storage = storage_list.filter(function (e) {
                    return recent_storage_ids.includes(e.id);
                }).map(function (storage) {
                    return {
                        id: storage.id,
                        name: storage.name,
                        storage_utilisation: storage.storage_utilisation,
                        status: storage.status,
                        location_status: storage.location_status,
                        assets_count: storage.assets_count,
                        location_info: storage.location_info,
                        current_warehouse_info: storage.current_warehouse_info,
                        current_space_info: storage.current_space_info,
                        storage_wrapper_info: storage.storage_wrapper_info
                    };
                });
                this.required_sections.movement.required_items.recent_storage.options = recent_storage;
                if (recent_storage.length > 0){
                    this.required_sections.movement.required_items.recent_storage.label = 'Recent Storage';
                }else{
                    this.required_sections.movement.required_items.recent_storage.label = '';
                }


                let current_storage_id;
                if (this.selected_item.hasOwnProperty('sp_wsl_info') && this.selected_item.sp_wsl_info && this.selected_item.sp_wsl_info.hasOwnProperty('storage_info') && this.selected_item.sp_wsl_info.storage_info){
                    this.setSelectedStorage(this.selected_item.sp_wsl_info.storage_info);
                }
            }

            this.required_sections.movement.required_items.storage_id.listLoading = false;

        },
        setSelectedItem(item){
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },
        resetSelectedItem(){
            this.setFormValue('storage_id', null);
            this.setSelectedItem({});
            this.cancelClicked();
        },

        set_index(index){
            this.index = index;
        },
        async addItem(event){
            this.isAddingItem = true;

            let params = {
                storage_id: this.selected_storage_info ? this.selected_storage_info.id : null,
                with_movement_info: this.with_movement_info
            };
            let managed_storage_id;
            if (this.selected_item){
                if (this.is_order_line){
                    if (this.selected_item.hasOwnProperty('managed_storage_info') && this.selected_item.managed_storage_info){
                        managed_storage_id = this.selected_item.managed_storage_info.id;
                    }else{
                        return;
                    }
                }else{
                    managed_storage_id = this.selected_item.id;
                }
                params.managed_storage_id = managed_storage_id;
            }else{
                console.log("no selected item!");
                return;
            }
            const { data } = await updateStorageResource.store(params);
            let new_item = data.data.status;
            let storage_info = data.data.storage_info;
            let location_info = data.data.location_info;
            let warehousing_movement_info;
            if (this.with_movement_info){
                warehousing_movement_info = data.data.warehousing_movement_info;
            }
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-storage-update', this.modal_id, storage_info, location_info, managed_storage_id, warehousing_movement_info, this.index);
        }
    }
}
</script>


