<template>
    <b-modal :id="'create-'+object_type"
             size="lg"
             :ref="'create-'+object_type"
             :title="selected_item ? ('Update {'+selected_item.name + '}') : modal_title"
             title-class="font-18"
             @hide="cancelClicked()"
             hide-footer>

        <div class="row">
            <b-form-group
                v-for="(required_field, key) in required_items"
                :key="required_field.key"
                :label="required_field.label"
                :label-for="required_field.key"
                class="col-sm-12"
            >

                <multiselect
                    v-if="required_field.type === 'single-select'"
                    v-model="addForm[required_field.key]"
                    track-by="id"
                    :loading="required_field.hasOwnProperty('isLoading') ? required_field.isLoading : false"
                    :disabled="required_field.hasOwnProperty('isLoading') ? required_field.isLoading : false"
                    label="name"
                    :placeholder="required_field.label"
                    class="mySingleSelect"
                    :options="required_field.options"
                    @input="required_field.hasOwnProperty('onChangingValue') ? required_field.onChangingValue($event) : null"
                    :allow-empty="false"
                    deselect-label=""></multiselect>

                <b-form-input
                    v-else
                    v-model="addForm[required_field.key]"
                    :type="required_field.type"
                    :id="required_field.key"
                    :placeholder="required_field.label"
                    autocomplete="none"
                ></b-form-input>
            </b-form-group>
        </div>

        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click.once="addItem($event)">Create</b-button>
            <b-button variant="outline-danger" @click="cancelClicked()">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
import multiSelect from '@/mixins/MultiSelect';

const assetResource = new Resource('api/assetflow/assets');
const categoriesResource = new Resource('api/assetflow/categories');

export default {
    name: "QuickAddNewAsset",
    props: ['modal_title', 'object_type', 'item_type'],
    mixins: [multiSelect],
    data(){
        return {
            addForm:{
                asset_name: '',
                quantity: '',
                category: '',
                reference: '',
                description: '',
                warehouse_id: null,
                customer_id: null
            },
            required_items:{
                asset_name: {
                    key: 'asset_name',
                    label: 'Asset Name',
                    type: 'text'
                },
                quantity: {
                    key: 'quantity',
                    label: 'Quantity',
                    type: 'number'
                },
                category: {
                    key: 'category',
                    label: 'Category',
                    type: 'single-select',
                    is_required:false,
                    isLoading: false,
                    options: []
                },
                reference: {
                    key: 'reference',
                    label: 'Reference',
                    type: 'text'
                },
                description: {
                    key: 'description',
                    label: 'Description',
                    type: 'text'
                },
            },
            selected_item: null,
            listQuery: {}
        }
    },
    created() {
        this.getListOf('categories');
    },
    methods:{
        handleQueryItems() {
            if (this.current_sp && this.item_type === 'sp') {
                this.listQuery.warehouse_id = this.current_sp.id;
            }

            if (this.current_customer && this.item_type === 'customer') {
                this.listQuery.customer_id = this.current_customer.id;
            }
        },
        async getListOf(item_type) {

            this.required_items.category.isLoading = true;
            let requiredResource = null;

            this.handleQueryItems();
            switch (item_type){
                case 'categories':
                    requiredResource = categoriesResource;
                    break;
            }

            if (requiredResource){
                this.listQuery.is_short_list = true;
                const {data} = await requiredResource.list(this.listQuery);
                let required_items = data.data.items;
                if (required_items.length > 0){
                    this.required_items.category.options = required_items.map(function(e) { return {id: e.id, name: e.name}; });
                }
            }
            this.required_items.category.isLoading = false;

        },
        cancelClicked(){
            this.$emit('on-cancel-clicked');
            this.$bvModal.hide('create-'+this.object_type);
        },
        setSelectedCustomer(customer_id, sp_id){
            if (customer_id) {
                this.$set(this.addForm, 'customer_id', customer_id);
            }
            if (sp_id){
                this.$set(this.addForm, 'warehouse_id', sp_id);
            }
        },
        async addItem(event){
            this.isAddingItem = true;

            var params = {
                customer_id: this.addForm.customer_id,
                warehouse_id: this.addForm.warehouse_id,
                item_details:{
                    name: this.addForm.asset_name,
                    quantity: this.addForm.quantity,
                    category_id: this.addForm.category ? this.addForm.category.id : 0,
                    internal_reference: this.addForm.reference,
                    description: this.addForm.description
                }
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await assetResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Added Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.$bvModal.hide('create-'+this.object_type);
            this.$emit('on-new-item-adding', new_item);
        }
    }
}
</script>
