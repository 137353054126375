<template>
    <div class="LedgerPendingReceiptsTab">
        <po-lines v-if="item_info.pending_receipts_info && item_info.pending_receipts_info.length > 0"
                  :items="item_info.pending_receipts_info"
                  :current_page_type="item_info.current_page_type"
                  :object_type="'pending_receipts'"
                  :line_type="'pending_receipts'"
                  :keys_list="pending_receipts_list_key_list">
            <template v-slot:pending_receipts_actions="{ item, index }">
                <b-button
                    class="btn btn-sm af-btn-accent af-accent mt-2 btn-block"
                    @click="addToLedger(item, index)"
                >Add to Ledger</b-button>
            </template>
        </po-lines>
        <div v-else class="text-center">
            <h6>No Pending Receipts found here at the moment.</h6>
        </div>
    </div>
</template>
<script>

import PoLines from "./widgets/detailsViews/PoLines";
import {AcquisitionReceiptsKeysList} from "@/views/assetflow/form_data/AcquisitionReceiptsKeysList";

import Resource from '@/api/resource';
const addToLedgerResource = new Resource('api/assetflow/ledgers/addToLedger');
export default {
    name: 'LedgerPendingReceiptsList',
    components: {PoLines},
    props: {
        item_info: {}
    },
    data(){
        return {
            pending_receipts_list_key_list: AcquisitionReceiptsKeysList,
        }
    },
    created() {
        this.pending_receipts_list_key_list = Object.assign({}, this.pending_receipts_list_key_list);
        this.pending_receipts_list_key_list.net_value.title_func = this.toUserCurrencyValueTitle;
        this.pending_receipts_list_key_list.net_value.value_func = this.getCurrencyByValueText;

        this.pending_receipts_list_key_list.tax_value.title_func = this.toUserCurrencyValueTitle;
        this.pending_receipts_list_key_list.tax_value.value_func = this.getCurrencyByValueText;

        this.pending_receipts_list_key_list.all_items_value.title_func = this.toUserCurrencyValueTitle;
        this.pending_receipts_list_key_list.all_items_value.value_func = this.getCurrencyByValueText;

        if (this.hasPermission('edit_acquisitions')){
            this.pending_receipts_list_key_list.actions = {
                key: 'actions',
                label: "",
                class: "col-lg-1 a_pending_receipts_actions text-center",
                show_on_mobile: false,
                case: 'custom_slot',
                slot_name: 'pending_receipts_actions'
            };
        }
    },
    methods: {
        performAction(item, action_name, event) {
            console.log("shall run action_name");
            console.log(action_name);
        },

        async addToLedger(item, index) {

            let params = {
                ledger_id: this.item_info.id,
                acquisition_id: item.id,
                item_type: 'acquisition',
            }

            const { data } = await addToLedgerResource.store(params);
            let new_item = data.data.saved;

            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? 'Added To Ledger Successfully' : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);

            if (new_item){
                this.$emit('after-item-moving', item, 'receipts_info', index);
            }

        },

    }

}
</script>
