<template>
    <div class="row DispositionCosts">
        <div class="col-md-4">
            <div class="cm_usage border_r">
                <span>Qty</span>
                <p>
                    <i class="bx bx-detail"></i>
                    <span>{{ item_info.quantity }}</span>
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="cm_usage border_r">
                <span>Cost For 1</span>

                <p class="totals-net-gross">

                    <span class="total-net cost-per-one" v-if="(item_info.net_unit) && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, (item_info.net_unit))">{{getCurrencyByValue(item_info.currency).icon + parseFloat(item_info.net_unit).toFixed(2)}}</span>
                    <span class="total-net cost-per-one" v-else-if="(item_info.net_total / item_info.quantity) && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, (item_info.net_total / item_info.quantity))">{{getCurrencyByValue(item_info.currency).icon + ((item_info.net_total / item_info.quantity).toFixed(2))}}</span>
                    <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, 0)" class="total-net cost-per-one">{{ getCurrencyByValue(item_info.currency).icon }}0.00</span>
                    <span class="total-gross" v-if="(item_info.value) && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.value)">{{getCurrencyByValue(item_info.currency).icon + parseFloat(item_info.value).toFixed(2)}}</span>
                    <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, 0)" class="total-gross">{{ getCurrencyByValue(item_info.currency).icon }}0.00</span>
                </p>

            </div>
        </div>
        <div class="col-md-4">
                <div class="cm_usage border_r">
                    <span>Cost for {{ item_info.quantity }}</span>
                    <p class="totals-net-gross">
                        <span class="total-net" v-if="item_info.net_total && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.net_total)">{{getCurrencyByValue(item_info.currency).icon + parseFloat(item_info.net_total).toFixed(2)}}</span>
                        <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, 0)" class="total-net">{{ getCurrencyByValue(item_info.currency).icon }}0.00</span>
                        <span class="total-gross" v-if="item_info.total_value && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.total_value)">{{getCurrencyByValue(item_info.currency).icon + parseFloat(item_info.total_value).toFixed(2)}}</span>
                        <span v-else v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, 0)" class="total-gross">{{ getCurrencyByValue(item_info.currency).icon }}0.00</span>

                    </p>
                </div>
            </div>
    </div>
</template>
<script>
export default {
    name: 'DispositionCosts',
    props:['item_info']
}
</script>
