<template>
    <div class="row">
        <div class="col-md-12">
            <div class="cm_usage asset_files_list">
                <span v-iconize="'files'">{{ item_info.files_info.length }} Attachment{{ item_info.files_info.length === 1 ? '' : 's'}}</span>
                <span class="asset-files-view-all" style="float: right">
                    <a :href="'/' + item_type + '/'+object_type+'/' + ms_id + '/view?t=files'">View All</a>
                </span>

                <b-breadcrumb class="asset-files-info">
                    <b-breadcrumb-item
                        v-for="(file_info, index) in item_info.files_info"
                        class="breadcrumb-file-item asset-file-info"
                        :key="index"
                        :href="file_info.file_url"
                        target="_blank"
                    >
                        {{ file_info.name }}
                    </b-breadcrumb-item>
                </b-breadcrumb>

                <span class="asset-files-view-all add-new-asset-file" style="float: right">
                    <a v-if="hasPermission('add_asset_files') && ms_item"
                       href="javascript:void(0);"
                       @click="performAction(ms_item, 'add_ms_files', ms_index, $event)">
                        <i class="fas fa-file"></i> Add Files
                    </a>
                </span>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManagedStorageFiles',
    props: {
        ms_id: {},
        item_info: {},
        item_type: {},
        object_type: {},
        performAction: {},
        ms_item: {},
        ms_index: {}
    }
}
</script>

<style>

.asset-file-info.breadcrumb-item + .asset-file-info.breadcrumb-item::before {
    content: "\002C" !important;
}

</style>
