const FirstPath = window.location.pathname.split('/')[1];
export const PaymentKeysList = {
    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 a_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/payments/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    source: {
        key: 'source',
        label: "Source",
        class: "col-lg-1 p_source",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    source_type: {
        key: 'source_type',
        label: "Source Type",
        class: "col-lg p_source_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    supplier_item_type: {
        key: 'supplier_item_type',
        label: "Supplier Type",
        class: "col-lg-1 p_supplier_item_type",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    payment_method: {
        key: 'payment_method',
        label: "Payment Method",
        class: "col-lg-2 p_payment_method",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    cost: {
        key: 'cost',
        label: "Cost",
        class: "col-lg-2 p_cost",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key];
        }
    },
    reconciled: {
        key: 'reconciled',
        label: "Reconciled",
        class: "col-lg-1 p_reconciled",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },
    reference: {
        key: 'reference',
        label: "Reference",
        class: "col-lg-2 p_reference",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] && item[key] !== '' ? item[key] : '-';
        }
    },
    actions: {
        key: 'actions',
        label: "Actions",
        class: "col-lg-1 a_actions",
        show_on_mobile: false,
        case: ''
    }
};
