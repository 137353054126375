<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">

            <b-form-group label="Choose Role" label-for="permissions_list">
                <b-form-select
                    id="permissions_list"
                    name="permission"
                    v-model="$v.form.item.$model"
                    :options="items"
                    :state="validateState('item')"
                    aria-describedby="live-feedback"
                    value-field="id"
                    text-field="display_name"
                >
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide('permissions-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";

    export default {
        name: "AssignToPermission",
        mixins: [validationMixin],
        props: {
            item_id: Number,
            items: Array
        },
        data() {
            return {
                selected_item: "",
                form: {
                    item: ""
                }
            }
        },
        validations: {
            form: {
                item: {
                    required
                }
            }
        },
        created() {
        },

        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    item: ""
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                console.log("Form submitted! " + this.form.item + " >> " + this.item_id);

                this.$emit('assigned-permission', this.form.item);
                this.resetForm();

            },

        }
    }
</script>
