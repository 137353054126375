const FirstPath = window.location.pathname.split('/')[1];
export const AcquisitionKeysList = {

    uid: {
        key: 'uid',
        label: "ID",
        class: "col-lg-1 a_uid",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return '<a class="text-dark" href="/' + FirstPath + '/acquisitions/'+item.id+'/view">' + item[key] + '</a>';
        }
    },
    acquired_date: {
        key: 'acquired_date',
        label: "Date",
        class: "col-lg-1 a_acquired_date",
        show_on_mobile: false,
        case: 'custom',
        used_func: function (date) {
            return Vue.options.filters.moment(date, 'MMMM Do YYYY');
        }
    },
    buyer_id: {
        key: 'buyer_id',
        label: "Buyer",
        class: "col-lg-1 a_buyer_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('buyer_info') && item.buyer_info){
                return '<a class="text-dark" href="/' + FirstPath + '/users/'+item.buyer_info.id+'/view">' + item.buyer_info.name + '</a>';
            }
            return '-';
        }
    },
    buyer_ledger_id: {
        key: 'buyer_ledger_id',
        label: "P-Card",
        class: "col-lg-2 a_buyer_ledger_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('buyer_ledger_info') && item.buyer_ledger_info){
                return '<a class="text-dark" href="/' + FirstPath + '/ledgers/'+item.buyer_ledger_info.id+'/view">' + item.buyer_ledger_info.description + '</a>';
            }
            return '-';
        }
    },
    character_id: {
        key: 'character_id',
        label: "Character",
        class: "col-lg-1 a_character_id",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('character_info') && item.character_info){
                return '<a class="text-dark" href="/' + FirstPath + '/characters/'+item.character_info.id+'/view">' + item.character_info.name + '</a>';
            }
            return '-';
        }
    },
    supplier_info: {
        key: 'supplier_info',
        label: "Supplier",
        class: "col-lg-2 a_supplier_info",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            if (item.hasOwnProperty('supplier_name') && item.supplier_name !== ''){
                return '<a class="text-dark" href="/' + FirstPath + '/suppliers/'+item.supplier_id+'/view">' + item.supplier_name + '</a>';
            }
            return '-';
        }
    },
    no_of_line_items: {
        key: 'no_of_line_items',
        label: "Line Items",
        class: "col-lg-1 a_no_of_line_items",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return (item.no_of_line_items ? item.no_of_line_items : 0) + item.no_of_assets;
        }
    },
    assets_value: {
        key: 'assets_value',
        label: "Total",
        class: "col-lg-1 a_assets_value",
        show_on_mobile: false,
        case: 'custom_tooltip',
        title_func: function () {},
        value_func: function () {}
    },
    reconciled: {
        key: 'reconciled',
        label: "Reconciled",
        class: "col-lg-1 a_reconciled",
        show_on_mobile: false,
        case: 'custom_html',
        value_func: function (item, key) {
            return item[key] === 1 ? 'Yes' : 'No';
        }
    },


};
