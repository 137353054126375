var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-wrap list-show manage-tags"},[_c('a',{class:'manage_tags_btn set_tags' + (_vm.list_is_shown ? ' set_tags_open' : ''),on:{"click":function($event){return _vm.showTagsManager($event)}}},[_c('i',{staticClass:"fas fa-tag"}),_vm._v(" Tags")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.list_is_shown),expression:"list_is_shown"}],ref:'tags_block' + _vm.asset_key,staticClass:"tags-manager"},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":"","no-add-on-enter":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var tagVariant = ref.tagVariant;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [(_vm.hasPermission('add_tags'))?_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New tag"},model:{value:(_vm.new_tag_name),callback:function ($$v) {_vm.new_tag_name=$$v},expression:"new_tag_name"}},'b-form-input',inputAttrs,false),inputHandlers)),_vm._v(" "),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.myAddTag(inputAttrs, _vm.product, _vm.asset_key)}}},[_vm._v("Add")])],1)],1):_vm._e(),_vm._v(" "),_vm._l((tags),function(tag,tagKey){return (tags.length > 0)?_c('span',{staticClass:"badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 ",class:_vm.isSelectedTag(_vm.received_all_tags_list[tagKey], (_vm.product ? _vm.product.tag_ids : '')) ? 'badge-primary' : ('badge-' + tagVariant),attrs:{"title":JSON.parse(tag).name},on:{"click":function($event){_vm.myRemoveTag(JSON.parse(tag).id, _vm.product, _vm.asset_key, tagKey)}}},[_c('span',{staticClass:"b-form-tag-content flex-grow-1 text-truncate"},[_vm._v(_vm._s(JSON.parse(tag).name))]),_vm._v(" "),(_vm.isSelectedTag(_vm.received_all_tags_list[tagKey], (_vm.product ? _vm.product.tag_ids : '')))?_c('button',{staticClass:"close b-form-tag-remove ml-1",attrs:{"type":"button"}},[_c('i',{staticClass:"bx bx-check"})]):_c('button',{staticClass:"close b-form-tag-remove ml-1",attrs:{"type":"button"}},[_vm._v("×")])]):_c('b-form-text',[_vm._v("\n                    There are no tags specified. Add a new tag above.\n                ")])})]}}]),model:{value:(_vm.received_all_tags_list),callback:function ($$v) {_vm.received_all_tags_list=$$v},expression:"received_all_tags_list"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }