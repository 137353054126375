<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">

            <b-form-group label="Choose Service Provider" label-for="sps_list">
                <b-form-select
                    id="sps_list"
                    name="sp"
                    v-model="$v.form.item.$model"
                    :options="items"
                    :disabled="listLoading"
                    :state="validateState('item')"
                    aria-describedby="live-feedback"
                    value-field="id"
                    text-field="name"
                >
                    <template v-slot:first>
                        <b-form-select-option value="" disabled>-- Choose --</b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback id="live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>


            <b-form-group v-if="this.item_type === 'customers'" label="Enter Account No." label-for="account_no">
                <b-form-input id="account_no" v-model="form.account_no" trim></b-form-input>
            </b-form-group>

            <div class="text-right">
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button variant="outline-danger" @click="$bvModal.hide(modal_id ? modal_id : 'sps-modal')">Cancel</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";

    export default {
        name: "AssignToSp",
        mixins: [validationMixin],
        props: {
            item_id: Number,
            items: Array,
            item_type: String,
            modal_id: String,
            listLoading: '',
        },
        data() {
            return {
                selected_item: "",
                form: {
                    item: "",
                    account_no: ""
                }
            }
        },
        validations: {
            form: {
                item: {
                    required
                }
            }
        },
        created() {
        },

        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    item: "",
                    account_no: ""
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            submit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                console.log("Form submitted! " + this.form.item + " >> " + this.item_id);

                this.$emit('assigned-sp', this.form);
                this.resetForm();

            },

        }
    }
</script>
