<template>
    <div class="mt-1">
        <div v-if="completed_tasks_list.length > 0">
            <h3>Completed:</h3>
            <po-lines
                :items="completed_tasks_list"
                :current_page_type="item_info.current_page_type"
                :object_type="'assets'"
                :keys_list="completed_sustainability_key_list">
            </po-lines>


            <div class="total_score-block text-right">
                <div class="total_score-score_details">
                    <span class="total_score-text">Total Score</span>
                    <span class="total_score-total">{{total_score}}</span>
                </div>
            </div>

        </div>
        <div v-if="uncompleted_tasks_list.length > 0">
            <h3>Not Completed:</h3>
            <po-lines
                :items="uncompleted_tasks_list"
                :current_page_type="item_info.current_page_type"
                :object_type="'assets'"
                :keys_list="uncompleted_sustainability_key_list">
            </po-lines>

            <div class="total_missed-score-block text-right">
                <div class="total_missed-score-score_details">
                    <span class="total_missed-score-text">Missing Score</span>
                    <span class="total_missed-score-total">{{total_missed_score}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PoLines from "../PoLines";
export default {
    name: "TaskList",
    components: {PoLines},
    props: ['completed_tasks_list', 'uncompleted_tasks_list', 'item_info'],
    data(){
        return {
            uncompleted_sustainability_key_list:{},
            total_score:0,
            total_missed_score: 0,
            completed_sustainability_key_list: {
                title: {
                    key: 'title',
                    label: "Task",
                    class: "col-lg s_task-title",
                    show_on_mobile: true,
                    case: 'custom',
                    used_func: function (value){
                        return Vue.options.filters.titleize(value);
                    }
                },
                completed_at: {
                    key: 'completed_at',
                    label: "Date Completed",
                    class: "col-lg-2 s_task-completed_at",
                    show_on_mobile: true,
                    case: 'custom',
                    used_func: function (value){
                        return value ? Vue.options.filters.moment(value, "dddd, MMMM Do YYYY") : '';
                    }
                },
                completed_by: {
                    key: 'completed_by',
                    label: "Completed By",
                    class: "col-lg-2 s_task-completed_by",
                    show_on_mobile: true,
                    case: 'custom_html',
                    value_func: function (item, key) {}
                },
                is_checked: {
                    key: 'is_checked',
                    label: "Status",
                    class: "col-lg-2 a_is_checked",
                    show_on_mobile: false,
                    case: 'custom_html',
                    value_func: function (item, key) {
                        return '<i class="bx font-size-22 text-' + (item.is_checked ? 'success bx-check' : 'danger bx-x') + '"></i>';
                    }
                },
                score: {
                    key: 'score',
                    label: "Score",
                    class: "col-lg-2 s_score",
                    show_on_mobile: true,
                    case: 'custom',
                    used_func: function (value){
                        return value + '%';
                    }
                },

            }
        }
    },
    created() {

        this.uncompleted_sustainability_key_list = Object.assign({}, this.completed_sustainability_key_list);
        delete this.uncompleted_sustainability_key_list.completed_at;
        delete this.uncompleted_sustainability_key_list.completed_by;

        if (this.completed_tasks_list){
            this.completed_tasks_list.sort((a, b) => {
                return this.$moment(a.completed_at).diff(b.completed_at);
            });
        }

        this.total_score = _.sumBy(this.completed_tasks_list, item => Number(item.score));
        this.total_missed_score = 100 - this.total_score;

        this.total_score = (this.total_score > 99 ? 100 : this.total_score).toFixed(1) + '%';
        this.total_missed_score = (this.total_missed_score > 99 ? 100 : this.total_missed_score).toFixed(1) + '%';

        let current_page_type = this.item_info.current_page_type;
        this.completed_sustainability_key_list.completed_by.value_func = function (item, key) {
            return item.completed_by_user ? '<a href="/' + current_page_type + '/users/' + item.completed_by_user.id + '/view"><img src="' + item.completed_by_user.avatar + '"' + ' alt="' + item.completed_by_user.name + '" class="rounded-circle item-post-image" width="20" height="20"> <b class="task-by-username">' + item.completed_by_user.name + '</b></a>' : '';
        }
    },
    methods:{
        getTotalScore(){
            return this.total_score;
        }
    }
}
</script>


