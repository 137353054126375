export const DashboardTemplates = [
    {
        id: 0,
        name: 'Default',
    },
    {
        id: 1,
        name: 'Administration',
    },
    {
        id: 2,
        name: 'Warehouse Management',
    },
    {
        id: 3,
        name: 'Storage Management',
    }
];
