<template>
    <div class="card-body">

        <add-asset-to-order
            ref="add-ms-to-order"
            received_modal_id="add-ms-to-order"
            :item_type="item_info.current_page_type"
            :object_type="object_type"
            @on-modal-save="refreshPage('add-ms-to-order')"
            @on-modal-cancel="selected_object_id = null"
        />

        <EditManagedStorageAsset
            ref="edit-managed_storage-asset"
            :received_modal_id="'managed_storage-asset'"
            modal_title="Edit Asset"
            @on-new-item-adding="refreshAsset"
            @on-modal-cancel="selected_object_id = null"
        >
        </EditManagedStorageAsset>

        <div class="product-filter-option-wrapper clearfix d-flex">

            <div class="total_view_item" v-if="request_meta && total !== 0">Items <span>{{
                    request_meta.from + '-' + request_meta.to
                }}</span> of <span>{{ request_meta.total }}</span></div>

            <div class="filter-wrap d-flex align-items-center ml-auto">

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Sort: <b>{{ sorting.sortType.text }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in sorting.sortOptions"
                        :key="option.value"
                        @click="saveSorting(option, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

                <b-dropdown class="option" variant="outline-secondary">
                    <template slot="button-content">
                        Show: <b>{{
                            perpage.perPageStr + (perpage.perPage !== 0 ? ' per page' : '')
                        }}</b>
                        <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button
                        v-for="option in perpage.pageOptions"
                        :key="option.value"
                        @click="savePerPage(option.value, $event)">
                        {{ option.text | titleize }}
                    </b-dropdown-item-button>
                </b-dropdown>

            </div>

            <div class="grid-list-button d-flex align-items-center" style="float: right;">
                <div
                    v-for="(layout, key) in supportedLayouts"
                    :key="key"
                    :id="'grid-list-'+layout.name.toLowerCase()"
                    :class="layout.class + (selectedLayout === layout.value ? ' active' : '')">
                </div>
            </div>

        </div>

        <LotsOfItemsAlert :perpage="perpage"/>

        <div v-if="listLoading" class="products text-center">
            <b-spinner class="text-af-accent m-2"></b-spinner>
            <h4>Fetching Managed Storage...</h4>
        </div>
        <div v-else-if="!managed_storagesData || total === 0 || managed_storagesData.length === 0"
             class="products text-center">
            <i class="display-4 text-muted bx bx-error-circle mb-2"></i>
            <h4>No Managed Storage found here at the moment.</h4>
        </div>

        <div v-else-if="managed_storagesData" class="managed_objects">

            <div v-if="selectedLayout === 2" class="cm_list_view_wrapper">


                <div class="popup-gallery">
                    <vue-easy-lightbox
                        :visible="visibleCaption"
                        :imgs="captionimgs"
                        :index="index"
                        @hide="handleCaptionHide">
                    </vue-easy-lightbox>
                </div>

                <ChangeAssetStorage
                    ref="change_asset_storage"
                    modal_id="change_asset_storage"
                    modal_title="Change Asset Storage"
                    :item_type="item_info.current_page_type"
                    @on-storage-update="refreshStorageAndLocation"
                    @on-modal-cancel="selected_object_id = null"
                />
                <ManagedObjectListView
                    v-for="(asset, assetKey) in sortedItemsList"
                    :key="assetKey"
                    :item_type="item_info.current_page_type"
                    :object_type="object_type"
                    :asset="asset"
                    :asset-key="assetKey"
                    :show-captiom-img="showCaptiomImg"
                    :get-managed-object-status="getManagedObjectStatus"
                    :get-html-title="getHtmlTitle"
                    :perform-action="performAction"
                    :get-storage-block="getStorageBlock"
                    :get-s-p-location-block="getSPLocationBlock"
                    :get-location-block="getLocationBlock"
                    :get-colour-h-t-m-l="getColourHTML"
                    :get-condition-text="getConditionText"
                    :order-select-details="orderSelectDetails"
                    :get-numbers="getNumbers"
                    :get-post-text="getPostText"
                    :without-actions="true"
                    :with-orders="true"
                    :received_all_tags_list="getTagsListFor(asset.item_info)"
                    :my-add-tag="myAddTag"
                    :my-remove-tag="myRemoveTag"

                    :is_actions_bar_opened="is_actions_bar_opened"
                    :is_selecting_items="is_selecting_items"
                    :selected_ids="selected_ids"
                    :addToSelectedList="addToSelectedList"
                    :should_hide_unselected="should_hide_unselected"
                    :preform-bulk-action="preformBulkAction"
                    :bulk_actions_customer_id="bulk_actions_customer_id"
                />
            </div>
        </div>


        <!-- Product Section End-->
        <div class="pagination">
            <b-pagination
                v-if="perpage.perPage !== 0"
                v-model="perpage.currentPage"
                :total-rows="request_meta.total"
                @change="perpage.currentPage = $event; getList()"
                class="pagination d-flex align-items-center justify-content-center"
                :per-page="perpage.perPage"
            ></b-pagination>
        </div>
    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines"
import Resource from '@/api/resource';
const managed_storageResource = new Resource('api/assetflow/managed_storages');

import {ItemsPerPage} from "@/views/assetflow/form_data/ItemsPerPage";
import {GroupsSortOptions} from "@/views/assetflow/form_data/GroupsSortOptions";

import LotsOfItemsAlert from "../assets/subItems/LotsOfItemsAlert";
import ImageGallery from "@/mixins/ImageGallery.mixin";
import TagsFunctions from "@/mixins/TagsFunctions.mixin";
import ManagedObjectListView from "../customers/managed_storages/ManagedObjectListView";


import ManagedStorageShared from "@/mixins/ManagedStorageShared.mixin";
import AddAssetToOrder from "../customers/extras/AddAssetToOrder";
import EditManagedStorageAsset from "../customers/extras/EditManagedStorageAsset";
import ChangeAssetStorage from "../customers/managed_storages/ChangeAssetStorage";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
    name: 'ObjectManagedStorageList',
    components: {
        VueEasyLightbox,
        ChangeAssetStorage,
        EditManagedStorageAsset,
        AddAssetToOrder,
        ManagedObjectListView,
        LotsOfItemsAlert,
        PoLines},
    props: {
        parent_object_type: '',
        item_info: {},

        is_actions_bar_opened: {},
        is_selecting_items: {},
        selected_ids: {},
        addToSelectedList: {},
        should_hide_unselected: {},
        preformBulkAction: {},
        bulk_actions_customer_id: {}
    },
    mixins:[ImageGallery, TagsFunctions, ManagedStorageShared],
    data(){
        return {
            selectedLayout: 2,
            supportedLayouts: {
                list: {
                    name: 'List',
                    value: 2,
                    class: 'list',
                    isActive: false
                },
            },
            selected_object_id: null,
            permission_name: 'managed_storages',
            object_type: 'managed_storages',
            managed_storagesData: [],
            listLoading: false,
            total: 0,
            listQuery: {
                page: 1,
                limit: 100,
                item_type: 'managed_storages'
            },
            request_meta: {},
            perpage: {
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                perPageStr: '10',
                pageOptions: ItemsPerPage
            },
            sorting: {
                sortType: {
                    value: 3,
                    text: 'Created Date: New to Old',
                    name: 'created_at',
                    reversed: true
                },
                sortOptions: GroupsSortOptions,
            },
        }
    },
    computed: {
        sortedItemsList: {
            get() {
                return this.sortItemsList();
            },
            set(newValue) {
                return newValue;
            }
        }
    },
    created() {
        this.getList();
    },
    methods: {
        sortItemsList() {
            return this.managed_storagesData;
        },
        saveSorting(sortType) {
            let message = ''; // "Saved Sorting {" + sortType.text + "} Successfully";
            this.sorting.sortType = sortType;
            this.set_usermeta(this.permission_name+'_preferred_sorting', sortType, message);
            this.refreshContent();
        },
        savePerPage(perPage) {
            this.perpage.perPageStr = perPage === 0 ? 'All' : perPage;
            let message = "Saved Items Per Page {" + this.perpage.perPageStr + "} Successfully";
            this.perpage.perPage = perPage;
            this.set_usermeta('preferred_'+this.permission_name+'s_per_page', perPage, message);
            this.refreshContent();
        },
        async getList() {
            this.listLoading = true;
            this.listQuery = { page: 1, limit: 100, item_type: 'managed_storages'};
            this.listQuery = this.addPaginationParams(this.listQuery);
            if (['folders', 'categories', 'tags'].includes(this.parent_object_type)){
                switch (this.parent_object_type){
                    case 'folders':
                        this.listQuery.folder_id = this.item_info.id;
                        break;
                    case 'categories':
                        this.listQuery.category_id = this.item_info.id;
                        break;
                    case 'tags':
                        this.listQuery.tag_id = this.item_info.id;
                        break;
                }
            }else{
                this.listQuery.item_id = this.item_info.id;
                this.listQuery.object_type = this.parent_object_type;
            }

            if (this.item_info.current_page_type === 'sp' && this.current_sp){
                this.listQuery.warehouse_id = this.current_sp.id;

                if (this.current_module && this.current_module.id === 16){
                    let top_filter_selected_customer = this.getUserMetaValueIfExist('top_filter_selected_customer');
                    if (top_filter_selected_customer){
                        this.listQuery.customer_id = top_filter_selected_customer;
                    }
                }
            }
            if (this.item_info.current_page_type === 'customer' && this.current_customer) {
                this.listQuery.customer_id = this.current_customer.id;
            }
            this.listQuery.with_all_info = true;
            this.listQuery.status_id = 2;
            const {data} = await managed_storageResource.list(this.listQuery);
            if (data.hasOwnProperty('meta')){
                this.total = data.meta.total;
                this.managed_storagesData = data.data.items;


                let unique_customer_ids;
                if (this.item_type === 'customer'){
                    unique_customer_ids = [this.current_customer.id];
                }else{
                    let customer_ids = this.managed_storagesData.map(function(managed_storage) { return managed_storage.hasOwnProperty('customer_info') ? managed_storage.customer_info.id : managed_storage.customer_id });
                    unique_customer_ids = customer_ids.filter((value, index, array) => array.indexOf(value) === index);
                }
                this.getTagsList(unique_customer_ids, true);

                this.request_meta = data.meta;
                this.listLoading = false;
            }else{
                // console.log('Response Error');
                this.listLoading = false;
            }
            this.$emit('when-list-is-loaded', this.total, false);

        },
        getUserMetaValueIfExist(meta_key){

            let current_user_info = this.user_info;
            if (current_user_info && current_user_info.hasOwnProperty('user_meta') &&
                current_user_info.user_meta &&
                current_user_info.user_meta.hasOwnProperty(meta_key) &&
                current_user_info.user_meta[meta_key] !== ''){
                return current_user_info.user_meta[meta_key];
            }
            return '';
        },
    }
}
</script>
