<template>
    <b-modal :id="modal_id"
             size="lg"
             :ref="'create-'+item_type"
             title-class="font-18"
             @hide="resetSelectedItem"
             hide-footer>

        <template #modal-title>
            <div v-html="current_modal_title"></div>
        </template>

        <div class="row" v-for="(required_section, k) in required_sections" :key="k">

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header af-soft-accent text-af-primary">
                        {{ required_section.section_name }}
                    </div>

                    <div class="card-body">
                        <ChangeCommonFields :add-form="addForm" :required_fields="required_section.required_items"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="text-right">
            <b-button type="submit"
                      variant="success"
                      v-on:click="addItem($event)">
                {{ selected_item ? (archive_unarchive_title + ' {' + selected_item.name + '}') : 'Create'}}</b-button>
            <b-button variant="outline-danger" @click="cancelClicked">Cancel</b-button>
        </div>
    </b-modal>
</template>

<script>
import Resource from '@/api/resource';
const updateStorageResource = new Resource('api/assetflow/sp_storages/updateStorage');
import ChangeCommonFields from "../extras/ChangeCommonFields";

export default {
    name: "ArchiveStorageLocation",
    props: ['modal_id', 'modal_title', 'item_type'],
    components: {ChangeCommonFields},
    data(){
        return {
            addForm:{
                archive_storage: null,
            },
            required_sections:{
                storage_setup: {
                    section_name: 'Archive\\Unarchive Storage',
                    required_items:{
                        archive_storage: {
                            key: 'archive_storage',
                            label: 'Archive\\Unarchive Storage',
                            type: 'radio-select',
                            options:[
                                {
                                    id: 1,
                                    name: 'Yes'
                                },
                                {
                                    id: 0,
                                    name: 'No'
                                }
                            ]
                        }
                    },
                },
            },
            selected_item: null,
            lists_values: {}
        }
    },
    computed:{
        current_modal_title(){
            return this.selected_item && this.selected_item.hasOwnProperty('name') ? ( (this.selected_item.hasOwnProperty('storage_wrapper_info') ? ('<i class="' + this.selected_item.storage_wrapper_info.icon +'"></i> ') : '')+this.selected_item.name + '') : this.modal_title;
        },
        archive_unarchive_title(){
            if (this.selected_item){
                return (this.selected_item.status === 0 ? 'Archive' : 'Unarchive');
            }
            return '';
        }
    },
    methods:{
        resetSelectedItem(){
            this.setSelectedItem({});
            this.cancelClicked();
        },
        fillSelectedItem() {
            if (this.selected_item) {


                this.required_sections.storage_setup.section_name = this.archive_unarchive_title + ' Storage';
                this.required_sections.storage_setup.required_items.archive_storage.label = this.archive_unarchive_title + ' Storage';

                this.required_sections.storage_setup.required_items.archive_storage.html_label = this.archive_unarchive_title + ' {<span class="storage-label-name">' + this.selected_item.name + '</span>}?';
            }
        },
        setSelectedItem(item){
            this.addForm.archive_storage = this.required_sections.storage_setup.required_items.archive_storage.options[0];
            if (item){
                this.selected_item = item;
                this.fillSelectedItem();
            }else{
                this.selected_item = null;
            }
        },

        async addItem(event){
            this.isAddingItem = true;

            if (this.addForm.archive_storage && !this.addForm.archive_storage.id){
                this.presentToast("Error!", 'Cancelled',
                    'danger',
                    1500,
                    true,
                    true);
                this.isAddingItem = false;
                this.resetSelectedItem();
                this.$bvModal.hide(this.modal_id);
                return;
            }
            let params = {
                archive_storage: this.addForm.archive_storage ? this.addForm.archive_storage.id : null,
                action: 'archive_unarchive_storage'
            };
            if (this.selected_item){
                params.item_id = this.selected_item.id;
            }

            const { data } = await updateStorageResource.store(params);

            let new_item = data.data.items;
            this.presentToast((new_item ? "Success!" : "Error!"),
                new_item ? ((this.selected_item ? 'Edited' : 'Added') + ' Successfully') : data.data.msg,
                (new_item ? 'success' : 'danger'),
                3000,
                true,
                true);
            this.isAddingItem = false;
            this.resetSelectedItem();
            this.$emit('on-remove-storage', this.modal_id, new_item);
        }
    }
}
</script>


