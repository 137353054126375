<template>
    <component :is="component_name" :id="id" :item_id="item_id" :other_item_id="other_item_id" :item_type="item_type" :action="action" :other_info_string="other_info_string" />
</template>
<script>
export default {
    props: {
        other_info_string:{
            type: String,
            required: false,
            default: () => ""
        },
        shared_info_string:{
            type: String,
            required: false,
            default: () => ""
        },
        component: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: () => ""
        },
        item_id: {
            type: String,
            required: false,
            default: () => ""
        },
        other_item_id: {
            type: String,
            required: false,
            default: () => ""
        },
        item_type: {
            type: String,
            required: false,
            default: () => ""
        },
        action: {
            type: String,
            required: false,
            default: () => ""
        },
        form_lists:{
            type: String,
            required: false,
            default: () => ""
        }
    },
    created() {
        if (this.form_lists !== ''){
            this.$root.setFormLists(this.form_lists);
        }
        if (this.shared_info_string !== ''){
            this.$root.setSharedInfo(this.shared_info_string);
        }
    },
    computed: {
        component_name() {
            return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
        }
    }
};
</script>
