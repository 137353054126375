<template>
    <b-card-body>
        <p><label>{{ label }}</label></p>
        <span
            v-if="filtered_tags_list && filtered_tags_list.length > 0"
            v-for="(tag, index) in filtered_tags_list"
            :key="index"
            :title="tag.name"
            class="badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 "
            :class="isSelected(tag.id) ? 'badge-primary' : 'badge-secondary'"
            @click="toggleTag(tag.id)"
        >
            <span class="b-form-tag-content flex-grow-1 text-truncate">{{tag.name}}</span>
                <button v-if="isSelected(tag.id)" type="button"
                        class="close b-form-tag-remove ml-1"><i
                    class="bx bx-check"></i></button>
                <button v-else type="button" class="close b-form-tag-remove ml-1">×</button>
            </span>
        <b-form-text v-else>
            There are no tags specified. <span v-if="hasPermission('browse_tags')"> Add a new tag<a
            :href="'/' + item_type + '/tags'"> here.</a></span>
        </b-form-text>


        <div class="tag-untag-buttons">
            <button v-if="selected_tags && selected_tags.length > 0"
                    @click="performBulkAction(action_name, $event)"
                    class="btn btn-link af-btn-accent af-accent btn-secondary">
                {{ action_text }} {{ selected_items_mode === 1 ? 'all' : selected_objects.length }} records
            </button>
            <button v-else class="btn btn-link af-btn-accent af-accent btn-secondary" disabled="disabled">{{ action_text }} {{ selected_items_mode === 1 ? 'all' : selected_objects.length }} records</button>
        </div>
    </b-card-body>
</template>
<script>
export default {
    name: 'BulkActionsTagsBlock',
    props: {
        label: '',
        action_name: '',
        action_text: '',
        filtered_tags_list: {},
        isSelected: {},
        item_type: {},
        performBulkAction: {},
        selected_tags: {},
        selected_objects: {},
        selected_items_mode: false,
        toggleTag: {}
    }
}
</script>
